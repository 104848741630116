export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        AiPreviewProgramCreatedPayload: [
            "AiPreviewProgramCreatedPayloadError",
            "AiPreviewProgramCreatedPayloadKeepAlive",
            "AiPreviewProgramCreatedPayloadPending",
            "AiPreviewProgramCreatedPayloadSuccess"
        ],
        AiVideoFromImageGeneratedPayload: [
            "AiVideoFromImageGeneratedPayloadError",
            "AiVideoFromImageGeneratedPayloadKeepAlive",
            "AiVideoFromImageGeneratedPayloadPending",
            "AiVideoFromImageGeneratedPayloadSuccess"
        ],
        CcAnimatedWireframeVersionPlaceholdersSettings: ["CcAnimatedWireframeVersionMediaPlaceholdersSettings", "CcAnimatedWireframeVersionTextPlaceholdersSettings"],
        CompleteFirstLoginOutput: ["CompleteFirstLoginOutputRefusal", "CompleteFirstLoginOutputSuccess"],
        ConvertFeedDataElementToDerivedOutput: ["ConvertFeedDataElementToDerivedOutputConflict", "ConvertFeedDataElementToDerivedOutputSuccess"],
        CreateEditorAccountUserOutput: ["CreateEditorAccountUserOutputRefusal", "CreateEditorAccountUserOutputSuccess"],
        CreateEditorAssetFolderOutput: ["CreateEditorAssetFolderOutputAlreadyExists", "CreateEditorAssetFolderOutputSuccess"],
        CreateOrUpdateEditorApplicationOutput: ["CreateOrUpdateEditorApplicationOutputConflict", "CreateOrUpdateEditorApplicationOutputSuccess"],
        CreateRFRVersionOutput: ["CreateRFRVersionOutputConflict", "CreateRFRVersionOutputSuccess"],
        CustomAvatarCreationStartedPayload: [
            "CustomAvatarCreationStartedPayloadError",
            "CustomAvatarCreationStartedPayloadKeepAlive",
            "CustomAvatarCreationStartedPayloadPending",
            "CustomAvatarCreationStartedPayloadSuccess"
        ],
        DataLibraryImportDataUpdatedPayload: [
            "DataLibraryImportDataUpdatedPayloadError",
            "DataLibraryImportDataUpdatedPayloadKeepAlive",
            "DataLibraryImportDataUpdatedPayloadPending",
            "DataLibraryImportDataUpdatedPayloadSuccess"
        ],
        DeleteDataElementOutput: ["DeleteDataElementOutputRefusal", "DeleteDataElementOutputSuccess"],
        DeleteEditorAssetFolderOutput: ["DeleteEditorAssetFolderOutputFailure", "DeleteEditorAssetFolderOutputSuccess"],
        DeleteEditorAssetMediaOutput: ["DeleteEditorAssetMediaOutputRefusal", "DeleteEditorAssetMediaOutputSuccess"],
        EditorAssetEditedPayload: ["EditorAssetEditedPayloadError", "EditorAssetEditedPayloadKeepAlive", "EditorAssetEditedPayloadPending", "EditorAssetEditedPayloadSuccess"],
        EditorAssetMediaUploadedPayload: [
            "EditorAssetMediaUploadedPayloadError",
            "EditorAssetMediaUploadedPayloadKeepAlive",
            "EditorAssetMediaUploadedPayloadPending",
            "EditorAssetMediaUploadedPayloadSuccess"
        ],
        GenericDataElement: ["CreativeDataElement", "DerivedDataElement", "FeedDataElement"],
        GettyMediaMetadata: ["GettyImageMetadata", "GettyVideoMetadata"],
        GoogleSheetResultOutput: ["GoogleSheetResultOutputError", "GoogleSheetResultOutputSuccess"],
        NextRecommendedVideoCreatedPayload: [
            "NextRecommendedVideoCreatedPayloadError",
            "NextRecommendedVideoCreatedPayloadKeepAlive",
            "NextRecommendedVideoCreatedPayloadPending",
            "NextRecommendedVideoCreatedPayloadSuccess"
        ],
        OutputProgram: ["EditorProgram", "Program"],
        Personalization: ["AudiencePersonalization", "ChannelPersonalization", "TouchpointPersonalization"],
        ProgramEditorPublishedPayload: ["ProgramEditorPublishedPayloadError", "ProgramEditorPublishedPayloadKeepAlive", "ProgramEditorPublishedPayloadPending", "ProgramEditorPublishedPayloadSuccess"],
        ProgramVersionNarrationRecordingStateCalculatedPayload: [
            "ProgramVersionNarrationRecordingStateCalculatedPayloadError",
            "ProgramVersionNarrationRecordingStateCalculatedPayloadKeepAlive",
            "ProgramVersionNarrationRecordingStateCalculatedPayloadPending",
            "ProgramVersionNarrationRecordingStateCalculatedPayloadSuccess"
        ],
        RecorderMediaUploadedToS3Payload: [
            "RecorderMediaUploadedToS3PayloadError",
            "RecorderMediaUploadedToS3PayloadKeepAlive",
            "RecorderMediaUploadedToS3PayloadPending",
            "RecorderMediaUploadedToS3PayloadSuccess"
        ],
        RequestedAvatarAnimationStatusPayload: ["RequestedAvatarAnimationStatusPayloadError", "RequestedAvatarAnimationStatusPayloadPending", "RequestedAvatarAnimationStatusPayloadReady"],
        ResolveAnimationOutput: ["ResolveAnimationOutputNotMigrated", "ResolveAnimationOutputSuccess"],
        ResolveAnimationsOutput: ["ResolveAnimationsOutputNotMigrated", "ResolveAnimationsOutputSuccess"],
        ResolveAssetOutput: ["ResolveAssetOutputNotMigrated", "ResolveAssetOutputSuccess"],
        ResolveAssetsOutput: ["ResolveAssetsOutputNotMigrated", "ResolveAssetsOutputSuccess"],
        ResolveDataTablesOutput: ["ResolveDataTablesOutputNotMigrated", "ResolveDataTablesOutputSuccess"],
        ResolveNarratorsOutput: ["ResolveNarratorsOutputNotMigrated", "ResolveNarratorsOutputSuccess"],
        SceneFromPromptCreatedPayload: ["SceneFromPromptCreatedPayloadError", "SceneFromPromptCreatedPayloadKeepAlive", "SceneFromPromptCreatedPayloadPending", "SceneFromPromptCreatedPayloadSuccess"],
        SetCcActiveSnapshotOutput: ["SetCcActiveSnapshotOutputMigrationFailed", "SetCcActiveSnapshotOutputSuccessful"],
        UpdateAccountDataLibraryConnectorTypeAndMappingOutput: ["UpdateAccountDataLibraryConnectorTypeAndMappingOutputConflict", "UpdateAccountDataLibraryConnectorTypeAndMappingOutputSuccess"],
        UpdateAccountDataLibraryMetadataOutput: ["UpdateAccountDataLibraryMetadataOutputConflict", "UpdateAccountDataLibraryMetadataOutputSuccess"],
        UpdateAccountReportOutput: ["UpdateAccountReportOutputConflict", "UpdateAccountReportOutputSuccess"],
        UpdateAnimationOutput: ["UpdateAnimationOutputConflict", "UpdateAnimationOutputSuccess"],
        UpdateAssetOutput: ["UpdateAssetOutputConflict", "UpdateAssetOutputSuccess"],
        UpdateAudienceOutput: ["UpdateAudienceOutputConflict", "UpdateAudienceOutputSuccess"],
        UpdateAudienceWithDataElementsOutput: ["UpdateAudienceWithDataElementsOutputConflict", "UpdateAudienceWithDataElementsOutputSuccess"],
        UpdateBasicFeedDataElementOutput: ["UpdateBasicFeedDataElementOutputConflict", "UpdateBasicFeedDataElementOutputSuccess"],
        UpdateBcCollectionWithColorsAndTextStylesOutput: ["UpdateBcCollectionWithColorsAndTextStylesOutputConflict", "UpdateBcCollectionWithColorsAndTextStylesOutputSuccess"],
        UpdateCcAnimatedWireframeOutput: ["UpdateCcAnimatedWireframeOutputConflict", "UpdateCcAnimatedWireframeOutputSuccess"],
        UpdateCcThemeOutput: ["UpdateCcThemeOutputConflict", "UpdateCcThemeOutputSuccess"],
        UpdateCcWireframeOutput: ["UpdateCcWireframeOutputConflict", "UpdateCcWireframeOutputSuccess"],
        UpdateChannelOutput: ["UpdateChannelOutputConflict", "UpdateChannelOutputSuccess"],
        UpdateCreativeAssetOutput: ["UpdateCreativeAssetOutputConflict", "UpdateCreativeAssetOutputSuccess"],
        UpdateCreativeDataElementOutput: ["UpdateCreativeDataElementOutputConflict", "UpdateCreativeDataElementOutputSuccess"],
        UpdateCreativeDraftDefinitionsOutput: ["UpdateCreativeDraftDefinitionsOutputConflict", "UpdateCreativeDraftDefinitionsOutputSuccess"],
        UpdateCreativeDraftOutput: ["UpdateCreativeDraftOutputConflict", "UpdateCreativeDraftOutputSuccess"],
        UpdateCreativeNarrationRecordingOutput: ["UpdateCreativeNarrationRecordingOutputConflict", "UpdateCreativeNarrationRecordingOutputSuccess"],
        UpdateCreativeNarrationRecordingVersionOutput: ["UpdateCreativeNarrationRecordingVersionOutputConflict", "UpdateCreativeNarrationRecordingVersionOutputSuccess"],
        UpdateCreativeOutput: ["UpdateCreativeOutputConflict", "UpdateCreativeOutputSuccess"],
        UpdateCustomAnalyticFieldOutput: ["UpdateCustomAnalyticFieldOutputConflict", "UpdateCustomAnalyticFieldOutputSuccess"],
        UpdateDataTableOutput: ["UpdateDataTableOutputConflict", "UpdateDataTableOutputSuccess"],
        UpdateDerivedDataElementOutput: ["UpdateDerivedDataElementOutputConflict", "UpdateDerivedDataElementOutputSuccess"],
        UpdateEditorAccountUserOutput: ["UpdateEditorAccountUserOutputRefusal", "UpdateEditorAccountUserOutputSuccess"],
        UpdateEditorIsArchivedOutput: ["UpdateEditorIsArchivedOutputConflict", "UpdateEditorIsArchivedOutputSuccess"],
        UpdateEditorPlaceholderOutput: ["UpdateEditorPlaceholderOutputConflict", "UpdateEditorPlaceholderOutputSuccess"],
        UpdateEditorProgramLifecycleStageOutput: ["UpdateEditorProgramLifecycleStageOutputConflict", "UpdateEditorProgramLifecycleStageOutputSuccess"],
        UpdateEditorProgramNameOutput: ["UpdateEditorProgramNameOutputConflict", "UpdateEditorProgramNameOutputSuccess"],
        UpdateEditorProgramThemeOutput: ["UpdateEditorProgramThemeOutputConflict", "UpdateEditorProgramThemeOutputSuccess"],
        UpdateEditorProgramVersionAspectRatioOutput: ["UpdateEditorProgramVersionAspectRatioOutputConflict", "UpdateEditorProgramVersionAspectRatioOutputSuccess"],
        UpdateEditorSceneOutput: ["UpdateEditorSceneOutputConflict", "UpdateEditorSceneOutputSuccess"],
        UpdateEditorSceneSelectedAnimatedWireframeOutput: ["UpdateEditorSceneSelectedAnimatedWireframeOutputConflict", "UpdateEditorSceneSelectedAnimatedWireframeOutputSuccess"],
        UpdateEditorSelectedBcCollectionOutput: ["UpdateEditorSelectedBcCollectionOutputConflict", "UpdateEditorSelectedBcCollectionOutputSuccess"],
        UpdateEditorTtsFullEnablementOutput: ["UpdateEditorTtsFullEnablementOutputConflict", "UpdateEditorTtsFullEnablementOutputSuccess"],
        UpdateEditorViewerProfileOutput: ["UpdateEditorViewerProfileOutputConflict", "UpdateEditorViewerProfileOutputSuccess"],
        UpdateFeedDataElementOutput: ["UpdateFeedDataElementOutputConflict", "UpdateFeedDataElementOutputSuccess"],
        UpdateGoalWithKPIsOutput: ["UpdateGoalWithKPIsOutputConflict", "UpdateGoalWithKPIsOutputSuccess"],
        UpdateMasterAnimationGuidelineOutput: ["UpdateMasterAnimationGuidelineOutputConflict", "UpdateMasterAnimationGuidelineOutputSuccess"],
        UpdateMasterDataOutput: ["UpdateMasterDataOutputConflict", "UpdateMasterDataOutputSuccess"],
        UpdateNarrationOrderOutput: ["UpdateNarrationOrderConflict", "UpdateNarrationOrderSuccess"],
        UpdateNarrationOutput: ["UpdateNarrationOutputConflict", "UpdateNarrationOutputSuccess"],
        UpdateNarrationRecordingOutput: ["UpdateNarrationRecordingOutputConflict", "UpdateNarrationRecordingOutputSuccess"],
        UpdateNarrationRecordingVersionOutput: ["UpdateNarrationRecordingVersionOutputConflict", "UpdateNarrationRecordingVersionOutputSuccess"],
        UpdateNarrationsDtaasPrefixKeyOutput: ["UpdateNarrationsDtaasPrefixKeyOutputConflict", "UpdateNarrationsDtaasPrefixKeyOutputSuccess"],
        UpdateNarratorOutput: ["UpdateNarratorOutputConflict", "UpdateNarratorOutputSuccess"],
        UpdatePlaceholderGroupLogicOutput: ["UpdatePlaceholderGroupLogicOutputConflict", "UpdatePlaceholderGroupLogicOutputSuccess"],
        UpdatePresetOutput: ["UpdatePresetOutputConflict", "UpdatePresetOutputSuccess"],
        UpdatePrioritizedListLogicOutput: ["UpdatePrioritizedListLogicOutputConflict", "UpdatePrioritizedListLogicOutputSuccess"],
        UpdateProgramAndDraftOutput: ["UpdateProgramAndDraftOutputConflict", "UpdateProgramAndDraftOutputSuccess"],
        UpdateProgramOutput: ["UpdateProgramOutputConflict", "UpdateProgramOutputSuccess"],
        UpdateProgramVersionDraftOutput: ["UpdateProgramVersionDraftOutputConflict", "UpdateProgramVersionDraftOutputSuccess"],
        UpdateProgramVersionSnapshotOutput: ["UpdateProgramVersionSnapshotOutputConflict", "UpdateProgramVersionSnapshotOutputSuccess"],
        UpdateSceneEntityLogicOutput: ["UpdateSceneEntityLogicOutputConflict", "UpdateSceneEntityLogicOutputSuccess"],
        UpdateSceneOutput: ["UpdateSceneOutputConflict", "UpdateSceneOutputSuccess"],
        UpdateScenePartOutput: ["UpdateScenePartOutputConflict", "UpdateScenePartOutputSuccess"],
        UpdateScenePartWithAllPlaceholdersOutput: ["UpdateScenePartWithAllPlaceholdersOutputConflict", "UpdateScenePartWithAllPlaceholdersOutputSuccess"],
        UpdateScenePlaceholdersAndAnimatedWireframeOutput: ["UpdateScenePlaceholdersAndAnimatedWireframeOutputConflict", "UpdateScenePlaceholdersAndAnimatedWireframeOutputSuccess"],
        UpdateSceneWithKPIsPersonalizationsAndDataElementsOutput: [
            "UpdateSceneWithKPIsPersonalizationsAndDataElementsOutputConflict",
            "UpdateSceneWithKPIsPersonalizationsAndDataElementsOutputSuccess"
        ],
        UpdateStoryForBuilderOutput: ["UpdateStoryForBuilderOutputConflict", "UpdateStoryForBuilderOutputSuccess"],
        UpdateStoryForFrameworkOutput: ["UpdateStoryForFrameworkOutputConflict", "UpdateStoryForFrameworkOutputSuccess"],
        UpdateStoryWithAllDecisionPointsOutput: ["UpdateStoryWithAllDecisionPointsOutputConflict", "UpdateStoryWithAllDecisionPointsOutputSuccess"],
        UpdateStoryWithDecisionPointsOutput: ["UpdateStoryWithDecisionPointsOutputConflict", "UpdateStoryWithDecisionPointsOutputSuccess"],
        UpdateStoryWithNewSceneOutput: ["UpdateStoryWithNewSceneOutputConflict", "UpdateStoryWithNewSceneOutputSuccess"],
        UpdateStoryWithReachAndScenesOutput: ["UpdateStoryWithReachAndScenesOutputConflict", "UpdateStoryWithReachAndScenesOutputSuccess"],
        UpdateSurveyOutput: ["UpdateSurveyOutputConflict", "UpdateSurveyOutputSuccess"],
        UpdateTouchpointOutput: ["UpdateTouchpointOutputConflict", "UpdateTouchpointOutputSuccess"]
    }
};
export default result;
