import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    JSON: { input: any; output: any };
    Upload: { input: any; output: any };
};

export type GqlClientAccount = {
    __typename?: "Account";
    accountAnalytics: GqlClientAccountAnalytics;
    accountConnections: Array<GqlClientAccountConnection>;
    accountFeatureData?: Maybe<Array<GqlClientAccountFeatureData>>;
    accountServiceConnections: Array<GqlClientAccountServiceConnection>;
    builderPrograms: Array<GqlClientProgram>;
    campaigns: Array<GqlClientCampaignSettings>;
    ccLibraryVersion?: Maybe<GqlClientCcLibraryVersion>;
    copilotOtherUsersBriefs: Array<GqlClientAccountCopilotBrief>;
    copilotUserBriefs: Array<GqlClientAccountCopilotBrief>;
    dataLibraries: Array<GqlClientAccountDataLibrary>;
    displayName?: Maybe<Scalars["String"]["output"]>;
    editorPrograms: Array<GqlClientEditorProgram>;
    editorProgramsIncludingRemoved: Array<GqlClientEditorProgram>;
    folders: Array<GqlClientAccountProgramFolder>;
    id: Scalars["ID"]["output"];
    maxCapacityReached: Scalars["Boolean"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
    programs: Array<GqlClientProgram>;
    reports: Array<GqlClientAccountReport>;
    type?: Maybe<Scalars["String"]["output"]>;
    users: Array<GqlClientAccountUser>;
};

export type GqlClientAccountAnalytics = {
    __typename?: "AccountAnalytics";
    accountOverview?: Maybe<GqlClientAccountAnalyticsOverview>;
    analyticsFilters?: Maybe<Array<GqlClientAnalyticsFilter>>;
    dailyTrend?: Maybe<Array<GqlClientAccountAnalyticsDailyTrend>>;
    videoOverview?: Maybe<Array<GqlClientAccountAnalyticsVideoOverview>>;
};

export type GqlClientAccountAnalyticsAccountOverviewArgs = {
    endDate: Scalars["String"]["input"];
    filters?: InputMaybe<Array<InputMaybe<GqlClientAnalyticsFilterInput>>>;
    startDate: Scalars["String"]["input"];
};

export type GqlClientAccountAnalyticsAnalyticsFiltersArgs = {
    endDate: Scalars["String"]["input"];
    startDate: Scalars["String"]["input"];
};

export type GqlClientAccountAnalyticsDailyTrendArgs = {
    endDate: Scalars["String"]["input"];
    filters?: InputMaybe<Array<InputMaybe<GqlClientAnalyticsFilterInput>>>;
    startDate: Scalars["String"]["input"];
};

export type GqlClientAccountAnalyticsVideoOverviewArgs = {
    endDate: Scalars["String"]["input"];
    filters?: InputMaybe<Array<InputMaybe<GqlClientAnalyticsFilterInput>>>;
    startDate: Scalars["String"]["input"];
};

export type GqlClientAccountAnalyticsDailyTrend = {
    __typename?: "AccountAnalyticsDailyTrend";
    avv: Scalars["Float"]["output"];
    clicks: Scalars["Int"]["output"];
    date: Scalars["String"]["output"];
    minutesViewed: Scalars["Float"]["output"];
    views: Scalars["Int"]["output"];
};

export type GqlClientAccountAnalyticsOverview = {
    __typename?: "AccountAnalyticsOverview";
    avv: Scalars["Float"]["output"];
    clickRate: Scalars["Float"]["output"];
    hasPublishedVideos: Scalars["Boolean"]["output"];
    minutesViewed: Scalars["Float"]["output"];
    views: Scalars["Int"]["output"];
    viewsWithClient: Scalars["Int"]["output"];
    viewsWithSurveyDisplayed: Scalars["Int"]["output"];
    viewsWithSurveySubmitted: Scalars["Int"]["output"];
};

export type GqlClientAccountAnalyticsVideoOverview = {
    __typename?: "AccountAnalyticsVideoOverview";
    avv: Scalars["Float"]["output"];
    clickRate: Scalars["Float"]["output"];
    clicks: Scalars["Int"]["output"];
    isEditor?: Maybe<Scalars["Boolean"]["output"]>;
    lastPublished?: Maybe<Scalars["String"]["output"]>;
    minutesPerViewer: Scalars["Float"]["output"];
    minutesViewed: Scalars["Float"]["output"];
    videoId?: Maybe<Scalars["String"]["output"]>;
    videoName: Scalars["String"]["output"];
    views: Scalars["Int"]["output"];
    viewsWithCtaDisplayed: Scalars["Int"]["output"];
    viewsWithPlayer: Scalars["Int"]["output"];
};

export type GqlClientAccountConnection = {
    __typename?: "AccountConnection";
    accountId: Scalars["ID"]["output"];
    connectionType: GqlClientConnectionType;
    connectionUpdated: Scalars["String"]["output"];
    connectionUpdatedBy: Scalars["String"]["output"];
    connectionUrl?: Maybe<Scalars["String"]["output"]>;
    connectorType: Scalars["String"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    isConnected: Scalars["Boolean"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAccountCopilotBrief = {
    __typename?: "AccountCopilotBrief";
    briefData: Scalars["JSON"]["output"];
    created: Scalars["String"]["output"];
    createdBy: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage: GqlClientAssetLifecycleStage;
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAccountDataField = {
    __typename?: "AccountDataField";
    accountDataLibraryVersion: GqlClientAccountDataLibraryVersion;
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    data: Scalars["JSON"]["output"];
    id: Scalars["ID"]["output"];
    localId: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAccountDataLibrary = {
    __typename?: "AccountDataLibrary";
    accountDataLibraryVersions: Array<GqlClientAccountDataLibraryVersion>;
    accountId: Scalars["ID"]["output"];
    anyUpdated: Scalars["String"]["output"];
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    draftAccountDataLibraryVersion: GqlClientAccountDataLibraryVersion;
    id: Scalars["ID"]["output"];
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage: GqlClientAssetLifecycleStage;
    name: Scalars["String"]["output"];
    publishedVideosTargetLibrary: Array<GqlClientEditorProgram>;
    publishedVideosUsingLibrary: Array<GqlClientEditorProgram>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAccountDataLibraryVersion = {
    __typename?: "AccountDataLibraryVersion";
    accountDataLibrary: GqlClientAccountDataLibrary;
    accountId: Scalars["ID"]["output"];
    anyUpdated: Scalars["String"]["output"];
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    data: Scalars["JSON"]["output"];
    dataFields: Array<GqlClientAccountDataField>;
    id: Scalars["ID"]["output"];
    isDraft?: Maybe<Scalars["Boolean"]["output"]>;
    localId: Scalars["String"]["output"];
    snapshotNumber?: Maybe<Scalars["Int"]["output"]>;
    snapshotted?: Maybe<Scalars["String"]["output"]>;
    snapshottedBy?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    usedInVideos: Array<GqlClientEditorProgram>;
};

export type GqlClientAccountFeatureData = {
    __typename?: "AccountFeatureData";
    accountId: Scalars["String"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    featureCurrentUsageInfo?: Maybe<Scalars["JSON"]["output"]>;
    featureDefinition: GqlClientFeatureDefinition;
    featureEndDate?: Maybe<Scalars["String"]["output"]>;
    featureRestrictionValue?: Maybe<Scalars["JSON"]["output"]>;
    featureSource: GqlClientFeatureSource;
    featureStartDate?: Maybe<Scalars["String"]["output"]>;
    featureTypeDefinition: GqlClientFeatureTypeDefinition;
    id: Scalars["ID"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAccountFeatureDataForMgmt = {
    __typename?: "AccountFeatureDataForMgmt";
    features: Array<GqlClientAccountFeatureData>;
    id: Scalars["ID"]["output"];
};

export type GqlClientAccountFeatureDataInput = {
    featureId: Scalars["ID"]["input"];
    newValue: Scalars["JSON"]["input"];
    source: GqlClientFeatureSource;
};

export enum GqlClientAccountInvitationStatus {
    ACCEPTED = "accepted",
    PENDING = "pending"
}

export type GqlClientAccountProgramFolder = {
    __typename?: "AccountProgramFolder";
    created: Scalars["String"]["output"];
    createdBy: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    isRoot: Scalars["Boolean"]["output"];
    name: Scalars["String"]["output"];
    parentFolder?: Maybe<GqlClientAccountProgramFolder>;
    updated: Scalars["String"]["output"];
    updatedBy: Scalars["String"]["output"];
};

export type GqlClientAccountPronunciationDefinition = {
    __typename?: "AccountPronunciationDefinition";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    pronunciationLibraryVersion: GqlClientAccountPronunciationLibraryVersion;
    source: Scalars["String"]["output"];
    target: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAccountPronunciationLibraryVersion = {
    __typename?: "AccountPronunciationLibraryVersion";
    accountId: Scalars["ID"]["output"];
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    definitions: Array<GqlClientAccountPronunciationDefinition>;
    id: Scalars["ID"]["output"];
    isDraft: Scalars["Boolean"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAccountReport = {
    __typename?: "AccountReport";
    account: GqlClientAccount;
    campaigns: Array<GqlClientCampaignSettings>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    filters: Array<GqlClientAccountReportFilter>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    programs: Array<GqlClientProgram>;
    relevantPrograms: GqlClientRelevantPrograms;
    reportElements: Array<GqlClientReportElement>;
    reportProgramType: GqlClientReportProgramType;
    schedules: Array<GqlClientAccountSchedule>;
    timezone?: Maybe<Scalars["String"]["output"]>;
    type: GqlClientAccountReportType;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAccountReportElementInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    elementType: GqlClientReportElementType;
    formatType: GqlClientReportElementFormatType;
    formatter?: InputMaybe<Scalars["JSON"]["input"]>;
    name: Scalars["String"]["input"];
    placeHolder?: InputMaybe<Scalars["String"]["input"]>;
    reportLibraryConfigKey: Scalars["String"]["input"];
};

export type GqlClientAccountReportFilter = {
    __typename?: "AccountReportFilter";
    filterType?: Maybe<GqlClientAccountReportFilterType>;
    values: Array<Scalars["String"]["output"]>;
};

export type GqlClientAccountReportFilterInput = {
    filterType?: InputMaybe<GqlClientAccountReportFilterType>;
    values: Array<Scalars["String"]["input"]>;
};

export enum GqlClientAccountReportFilterType {
    INTERACTIONTYPE = "interactionType",
    VIEWERACTIVITY = "viewerActivity"
}

export enum GqlClientAccountReportType {
    PAIDMEDIAREPORT = "paidMediaReport",
    VIEWERSESSIONSREPORT = "viewerSessionsReport",
    VIEWERSESSIONSREPORTBYINTERACTIONS = "viewerSessionsReportByInteractions"
}

export type GqlClientAccountSchedule = {
    __typename?: "AccountSchedule";
    accountId: Scalars["ID"]["output"];
    active?: Maybe<Scalars["Boolean"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    deliveryChannel?: Maybe<Scalars["JSON"]["output"]>;
    endPeriod?: Maybe<Scalars["String"]["output"]>;
    filenameTemplate?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    occurrence?: Maybe<GqlClientReportScheduleOccurrence>;
    period?: Maybe<GqlClientReportSchedulePeriod>;
    periodType?: Maybe<GqlClientReportSchedulePeriodType>;
    prefix?: Maybe<Scalars["String"]["output"]>;
    report: GqlClientAccountReport;
    sourceEnvironments?: Maybe<Array<Scalars["String"]["output"]>>;
    startPeriod?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAccountServiceConnection = {
    __typename?: "AccountServiceConnection";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    serviceType: GqlClientServiceType;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAccountUser = {
    __typename?: "AccountUser";
    account: GqlClientAccount;
    id: Scalars["ID"]["output"];
    invitationStatus: GqlClientAccountInvitationStatus;
    roles: Array<GqlClientUserAccountRole>;
    user: GqlClientEditorAccountUser;
};

export type GqlClientAccountsSyncTriggerOutput = {
    __typename?: "AccountsSyncTriggerOutput";
    result: GqlClientAccountsSyncTriggerResult;
};

export enum GqlClientAccountsSyncTriggerResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientAcquiaAssetMedia = {
    __typename?: "AcquiaAssetMedia";
    created?: Maybe<Scalars["String"]["output"]>;
    downloadUri: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    largeThumbnailUrl?: Maybe<Scalars["String"]["output"]>;
    mediaMetadata: GqlClientAcquiaAssetMetadata;
    mediaUrl: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    thumbnailUri: Scalars["String"]["output"];
    type: GqlClientAcquiaAssetType;
    updated?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientAcquiaAssetMediaType {
    IMAGES = "images",
    VIDEOS = "videos"
}

export type GqlClientAcquiaAssetMetadata = {
    __typename?: "AcquiaAssetMetadata";
    duration?: Maybe<Scalars["Float"]["output"]>;
    format: Scalars["String"]["output"];
    height: Scalars["Int"]["output"];
    sizeInKb: Scalars["Int"]["output"];
    width: Scalars["Int"]["output"];
};

export enum GqlClientAcquiaAssetOrientation {
    HORIZONTAL = "horizontal",
    SQUARE = "square",
    VERTICAL = "vertical"
}

export enum GqlClientAcquiaAssetSortBy {
    A_Z = "a_z",
    LAST_ADDED = "last_added"
}

export enum GqlClientAcquiaAssetSortDirection {
    ASC = "asc",
    DESC = "desc"
}

export enum GqlClientAcquiaAssetType {
    IMAGE = "IMAGE",
    VIDEO = "VIDEO"
}

export type GqlClientAcquiaAssetsOutput = {
    __typename?: "AcquiaAssetsOutput";
    assets: Array<GqlClientAcquiaAssetMedia>;
    resultCount: Scalars["Int"]["output"];
};

export type GqlClientAcquiaCategory = {
    __typename?: "AcquiaCategory";
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    parentCategoryPath?: Maybe<Scalars["String"]["output"]>;
    path: Scalars["String"]["output"];
    subCategories: Array<GqlClientAcquiaCategory>;
};

export type GqlClientAddCreativeLifecycleHistoryEntryInput = {
    creativeId: Scalars["ID"]["input"];
    lifecycleStage: GqlClientLifecycleStage;
    programRuntimeId: Scalars["String"]["input"];
    snapshotNumber: Scalars["Int"]["input"];
};

export type GqlClientAddCreativeLifecycleHistoryEntryOutput = {
    __typename?: "AddCreativeLifecycleHistoryEntryOutput";
    creativeLifecycleHistoryEntry?: Maybe<GqlClientCreativeLifecycleHistoryEntry>;
    result: GqlClientAddCreativeLifecycleHistoryEntryResult;
};

export enum GqlClientAddCreativeLifecycleHistoryEntryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientAddLifecycleHistoryEntryInput = {
    lifecycleStage: GqlClientLifecycleStage;
    programId: Scalars["ID"]["input"];
    snapshotNumber: Scalars["Int"]["input"];
};

export type GqlClientAddLifecycleHistoryEntryOutput = {
    __typename?: "AddLifecycleHistoryEntryOutput";
    lifecycleHistoryEntry?: Maybe<GqlClientLifecycleHistoryEntry>;
    result: GqlClientAddLifecycleHistoryEntryResult;
};

export enum GqlClientAddLifecycleHistoryEntryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientAddStoryTemplateToProductionGalleryInput = {
    storyTemplateVersionId: Scalars["ID"]["input"];
};

export type GqlClientAddStoryTemplateToProductionGalleryOutput = {
    __typename?: "AddStoryTemplateToProductionGalleryOutput";
    result: GqlClientAddStoryTemplateToProductionGalleryResult;
};

export enum GqlClientAddStoryTemplateToProductionGalleryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientAdsAccount = {
    __typename?: "AdsAccount";
    id: Scalars["ID"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
    version?: Maybe<Scalars["Int"]["output"]>;
};

export type GqlClientAdsAudience = {
    __typename?: "AdsAudience";
    enabled: Scalars["Boolean"]["output"];
    id: Scalars["ID"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
    priorityIndex?: Maybe<Scalars["Int"]["output"]>;
    programId?: Maybe<Scalars["String"]["output"]>;
    superGroups?: Maybe<Array<GqlClientAudienceRuleSuperGroup>>;
};

export type GqlClientAdsFieldData = {
    section: Scalars["String"]["input"];
    type: Scalars["String"]["input"];
    validationName: GqlClientFileValidationName;
    validationPrefix?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientAdsFileResult = {
    __typename?: "AdsFileResult";
    buffer?: Maybe<Scalars["JSON"]["output"]>;
    contentType?: Maybe<Scalars["String"]["output"]>;
    fileName?: Maybe<Scalars["String"]["output"]>;
    returnCode: GqlClientAdsReturnCode;
};

export enum GqlClientAdsReturnCode {
    BAD_INPUT = "BAD_INPUT",
    DATA_ERROR = "DATA_ERROR",
    INTERNAL_ERROR = "INTERNAL_ERROR",
    SUCCESS = "SUCCESS"
}

export enum GqlClientAdsTestType {
    DISABLED = "DISABLED",
    ENABLED = "ENABLED",
    GHOST = "GHOST"
}

export enum GqlClientAiAspectRatio {
    LANDSCAPE = "LANDSCAPE",
    PORTRAIT = "PORTRAIT",
    SQUARE = "SQUARE"
}

export type GqlClientAiGeneratedMediaDetails = {
    prompt: Scalars["String"]["input"];
    system: Scalars["String"]["input"];
};

export type GqlClientAiImageResult = {
    __typename?: "AiImageResult";
    indexInBatch: Scalars["Int"]["output"];
    sessionId: Scalars["String"]["output"];
    url: Scalars["String"]["output"];
};

export enum GqlClientAiImageService {
    GETTY_IMAGES = "getty_images",
    GOOGLE = "google"
}

export enum GqlClientAiImageStyle {
    ABSTRACT = "ABSTRACT",
    AERIAL = "AERIAL",
    BLACK_AND_WHITE = "BLACK_AND_WHITE",
    BLACK_AND_WHITE_PHOTO = "BLACK_AND_WHITE_PHOTO",
    CINEMATIC_PHOTO = "CINEMATIC_PHOTO",
    CLOSE_UP = "CLOSE_UP",
    DIGITAL = "DIGITAL",
    ICON = "ICON",
    LANDSCAPE = "LANDSCAPE",
    MONOCHROME = "MONOCHROME",
    NONE = "NONE",
    PHOTOGRAPH = "PHOTOGRAPH",
    PIXEL_ART = "PIXEL_ART",
    POP_ART = "POP_ART",
    SKETCH = "SKETCH",
    THREE_D_ILLUSTRATION = "THREE_D_ILLUSTRATION",
    TWO_D_ILLUSTRATION = "TWO_D_ILLUSTRATION",
    WATERCOLOR = "WATERCOLOR"
}

export type GqlClientAiImageToVideoInput = {
    aspectRatio: GqlClientAiAspectRatio;
    assetId: Scalars["ID"]["input"];
    prompt: Scalars["String"]["input"];
};

export type GqlClientAiPreviewProgramCreatedPayload = {
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    sessionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientAiPreviewProgramCreatedPayloadData = {
    __typename?: "AiPreviewProgramCreatedPayloadData";
    dimensions: GqlClientDimension;
    playerToken: Scalars["String"]["output"];
    previewId: Scalars["String"]["output"];
};

export type GqlClientAiPreviewProgramCreatedPayloadError = GqlClientAiPreviewProgramCreatedPayload & {
    __typename?: "AiPreviewProgramCreatedPayloadError";
    error: Scalars["JSON"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    sessionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientAiPreviewProgramCreatedPayloadKeepAlive = GqlClientAiPreviewProgramCreatedPayload & {
    __typename?: "AiPreviewProgramCreatedPayloadKeepAlive";
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    sessionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientAiPreviewProgramCreatedPayloadPending = GqlClientAiPreviewProgramCreatedPayload & {
    __typename?: "AiPreviewProgramCreatedPayloadPending";
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    progress: Scalars["Float"]["output"];
    sessionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientAiPreviewProgramCreatedPayloadSuccess = GqlClientAiPreviewProgramCreatedPayload & {
    __typename?: "AiPreviewProgramCreatedPayloadSuccess";
    data: GqlClientAiPreviewProgramCreatedPayloadData;
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    sessionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientAiTextEditingInput = {
    intent: Scalars["String"]["input"];
    mode: Scalars["String"]["input"];
    originalText: Scalars["String"]["input"];
};

export enum GqlClientAiTextEditingInputResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientAiTextEditingOutput = {
    __typename?: "AiTextEditingOutput";
    modifiedText: Scalars["String"]["output"];
    result: GqlClientAiTextEditingInputResult;
};

export type GqlClientAiTextToImageInput = {
    aspectRatio: GqlClientAiAspectRatio;
    numberOfImages: Scalars["Int"]["input"];
    prompt: Scalars["String"]["input"];
    service: GqlClientAiImageService;
    style?: InputMaybe<GqlClientAiImageStyle>;
};

export type GqlClientAiVideoFromImageGeneratedPayload = {
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientAiVideoFromImageGeneratedPayloadData = {
    __typename?: "AiVideoFromImageGeneratedPayloadData";
    video: GqlClientAiVideoResult;
};

export type GqlClientAiVideoFromImageGeneratedPayloadError = GqlClientAiVideoFromImageGeneratedPayload & {
    __typename?: "AiVideoFromImageGeneratedPayloadError";
    error: Scalars["JSON"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientAiVideoFromImageGeneratedPayloadKeepAlive = GqlClientAiVideoFromImageGeneratedPayload & {
    __typename?: "AiVideoFromImageGeneratedPayloadKeepAlive";
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientAiVideoFromImageGeneratedPayloadPending = GqlClientAiVideoFromImageGeneratedPayload & {
    __typename?: "AiVideoFromImageGeneratedPayloadPending";
    mutationId: Scalars["String"]["output"];
    progress: Scalars["Float"]["output"];
    status: GqlClientStatus;
};

export type GqlClientAiVideoFromImageGeneratedPayloadSuccess = GqlClientAiVideoFromImageGeneratedPayload & {
    __typename?: "AiVideoFromImageGeneratedPayloadSuccess";
    data: GqlClientAiVideoFromImageGeneratedPayloadData;
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientAiVideoResult = {
    __typename?: "AiVideoResult";
    indexInBatch: Scalars["Int"]["output"];
    sessionId: Scalars["String"]["output"];
    url: Scalars["String"]["output"];
};

export type GqlClientAnalyticsDashboard = {
    __typename?: "AnalyticsDashboard";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    enabled: Scalars["Boolean"]["output"];
    externalDashboardId: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAnalyticsFilter = {
    __typename?: "AnalyticsFilter";
    filterId: Scalars["String"]["output"];
    filterName: Scalars["String"]["output"];
    filterValue: Scalars["String"]["output"];
    percentage: Scalars["Float"]["output"];
    totalCount: Scalars["Int"]["output"];
};

export type GqlClientAnalyticsFilterInput = {
    filterId: Scalars["String"]["input"];
    filterValue: Scalars["String"]["input"];
};

export type GqlClientAnimation = {
    __typename?: "Animation";
    accountId: Scalars["ID"]["output"];
    animationActiveVersion: GqlClientAnimationVersion;
    animationVersion?: Maybe<GqlClientAnimationVersion>;
    animationVersions: Array<GqlClientAnimationVersion>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    lastSyncedFolderVersion: Scalars["Int"]["output"];
    lastVersionNumber: Scalars["Int"]["output"];
    legacyAnimationId: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    programVersion: GqlClientProgramVersion;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAnimationAnimationVersionArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientAnimationGuideline = {
    __typename?: "AnimationGuideline";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    logic: GqlClientSceneEntityLogic;
    scenePart: GqlClientScenePart;
    type: GqlClientAnimationGuidelineType;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientAnimationGuidelineType {
    COLOR = "color",
    TEXT = "text"
}

export type GqlClientAnimationVersion = {
    __typename?: "AnimationVersion";
    accountId: Scalars["ID"]["output"];
    aeFilename?: Maybe<Scalars["String"]["output"]>;
    animation: GqlClientAnimation;
    comment?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    location: Scalars["String"]["output"];
    transition?: Maybe<Scalars["JSON"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    version: Scalars["Int"]["output"];
};

export type GqlClientApplicationFlavor = {
    __typename?: "ApplicationFlavor";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage: GqlClientAssetLifecycleStage;
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientApplicationLifecycleHistory = {
    __typename?: "ApplicationLifecycleHistory";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    galleryType: GqlClientStoryTemplatesGalleryType;
    id: Scalars["ID"]["output"];
    snapshotNumber: Scalars["Int"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientApproveAccountInvitationInput = {
    accountId: Scalars["String"]["input"];
};

export type GqlClientApproveAccountInvitationOutput = {
    __typename?: "ApproveAccountInvitationOutput";
    accountUser?: Maybe<GqlClientAccountUser>;
    result: GqlClientApproveAccountInvitationResult;
};

export enum GqlClientApproveAccountInvitationResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientAsset = {
    __typename?: "Asset";
    accountId: Scalars["ID"]["output"];
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    assetActiveVersion: GqlClientAssetVersion;
    assetVersion?: Maybe<GqlClientAssetVersion>;
    assetVersions: Array<GqlClientAssetVersion>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    legacyAssetId: Scalars["String"]["output"];
    legacyData?: Maybe<Scalars["JSON"]["output"]>;
    name: Scalars["String"]["output"];
    programVersion: GqlClientProgramVersion;
    type: GqlClientAssetType;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAssetAssetVersionArgs = {
    id: Scalars["ID"]["input"];
};

export enum GqlClientAssetLifecycleStage {
    ACTIVE = "active",
    DISABLED = "disabled",
    ENABLED = "enabled",
    FAILED = "failed",
    IN_TRANSIT = "in_transit",
    PRE_SUBMITTED = "pre_submitted",
    PREVIEW = "preview",
    REMOVED = "removed",
    SUBMITTED = "submitted",
    TERMINATED = "terminated",
    TEST = "test"
}

export enum GqlClientAssetStatus {
    APPROVED = "approved",
    NOTREADY = "notReady",
    READY = "ready",
    REQUEST = "request"
}

export enum GqlClientAssetType {
    APPLICATION = "application",
    AUDIO = "audio",
    IMAGE = "image",
    TEXT = "text",
    VIDEO = "video"
}

export type GqlClientAssetVersion = {
    __typename?: "AssetVersion";
    accountId: Scalars["ID"]["output"];
    asset: GqlClientAsset;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    filename?: Maybe<Scalars["String"]["output"]>;
    format?: Maybe<Scalars["String"]["output"]>;
    height?: Maybe<Scalars["Int"]["output"]>;
    id: Scalars["ID"]["output"];
    location?: Maybe<Scalars["String"]["output"]>;
    mediaSubType?: Maybe<Scalars["String"]["output"]>;
    size?: Maybe<Scalars["Int"]["output"]>;
    thumbnailLocation?: Maybe<Scalars["String"]["output"]>;
    thumbnailUrl?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    url?: Maybe<Scalars["String"]["output"]>;
    version: Scalars["Int"]["output"];
    width?: Maybe<Scalars["Int"]["output"]>;
};

export type GqlClientAudience = {
    __typename?: "Audience";
    accountId: Scalars["ID"]["output"];
    audiencePersonalizations: Array<GqlClientAudiencePersonalization>;
    audienceValue?: Maybe<GqlClientAudienceValue>;
    audienceValues: Array<GqlClientAudienceValue>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    feedDataElements: Array<GqlClientFeedDataElement>;
    id: Scalars["ID"]["output"];
    impactsVolume?: Maybe<Scalars["Boolean"]["output"]>;
    name: Scalars["String"]["output"];
    programVersion: GqlClientProgramVersion;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAudiencePersonalization = GqlClientPersonalization & {
    __typename?: "AudiencePersonalization";
    accountId: Scalars["ID"]["output"];
    audience: GqlClientAudience;
    audiencePersonalizationValues: Array<GqlClientAudiencePersonalizationValue>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    scene: GqlClientScene;
    type: Array<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAudiencePersonalizationValue = {
    __typename?: "AudiencePersonalizationValue";
    accountId: Scalars["ID"]["output"];
    audiencePersonalization: GqlClientAudiencePersonalization;
    audienceValue: GqlClientAudienceValue;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAudienceRule = {
    __typename?: "AudienceRule";
    id: Scalars["ID"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
    operator?: Maybe<GqlClientAudienceRuleOperator>;
    value?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientAudienceRuleGroup = {
    __typename?: "AudienceRuleGroup";
    id: Scalars["ID"]["output"];
    matchType?: Maybe<GqlClientRuleMatchType>;
    maxAgeDays?: Maybe<Scalars["Int"]["output"]>;
    minAgeDays?: Maybe<Scalars["Int"]["output"]>;
    rules?: Maybe<Array<GqlClientAudienceRule>>;
};

export enum GqlClientAudienceRuleOperator {
    DOES_NOT_EQUAL = "DOES_NOT_EQUAL",
    DOES_NOT_EXIST = "DOES_NOT_EXIST",
    EQUALS = "EQUALS",
    EXISTS = "EXISTS",
    NOT_ONE_OF = "NOT_ONE_OF",
    ONE_OF = "ONE_OF",
    STRING_CONTAINS = "STRING_CONTAINS",
    STRING_DOES_NOT_CONTAIN = "STRING_DOES_NOT_CONTAIN"
}

export type GqlClientAudienceRuleSuperGroup = {
    __typename?: "AudienceRuleSuperGroup";
    id: Scalars["ID"]["output"];
    matchType?: Maybe<GqlClientRuleMatchType>;
    ruleGroups?: Maybe<Array<GqlClientAudienceRuleGroup>>;
    ruleType?: Maybe<GqlClientRuleType>;
};

export type GqlClientAudienceValue = {
    __typename?: "AudienceValue";
    accountId: Scalars["ID"]["output"];
    audience: GqlClientAudience;
    audiencePersonalizationValues: Array<GqlClientAudiencePersonalizationValue>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    subAudiences: Array<GqlClientAudience>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    volume?: Maybe<Scalars["Int"]["output"]>;
};

export enum GqlClientAuthService {
    AUTH0 = "AUTH0",
    COGNITO = "COGNITO",
    OKTA = "OKTA"
}

export type GqlClientAvatarInfo = {
    id: Scalars["String"]["input"];
    type: Scalars["String"]["input"];
};

export type GqlClientBiProgram = {
    __typename?: "BIProgram";
    accountId: Scalars["ID"]["output"];
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    isArchive?: Maybe<Scalars["Boolean"]["output"]>;
    isLegacy?: Maybe<Scalars["Boolean"]["output"]>;
    legacyBuilderProgramId?: Maybe<Scalars["String"]["output"]>;
    legacyFrameworkProgramId?: Maybe<Scalars["String"]["output"]>;
    legacyOverviewProgramId?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    programType?: Maybe<GqlClientProgramType>;
    saleForceId?: Maybe<Scalars["String"]["output"]>;
    serial?: Maybe<Scalars["Int"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientBackgroundAssetType {
    IMAGE = "image",
    VIDEO = "video"
}

export type GqlClientBcColor = {
    __typename?: "BcColor";
    brandConfigurationCollection: GqlClientBrandConfigurationCollection;
    ccDof: GqlClientCcDof;
    color: Scalars["String"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientBcTextStyle = {
    __typename?: "BcTextStyle";
    accountFont?: Maybe<GqlClientEditorAssetFont>;
    brandConfigurationCollection: GqlClientBrandConfigurationCollection;
    ccFont?: Maybe<GqlClientCcFont>;
    ccTextStyle: GqlClientCcTextStyle;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    fontSource: GqlClientEditorAssetSource;
    id: Scalars["ID"]["output"];
    letterCase: GqlClientTextStyleLetterCase;
    letterSpacing: Scalars["Int"]["output"];
    lineSpacing: Scalars["Int"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientBrandConfigurationCollection = {
    __typename?: "BrandConfigurationCollection";
    accountId: Scalars["ID"]["output"];
    brandConfigurationVersion: GqlClientBrandConfigurationVersion;
    buttonShape: GqlClientButtonShapeValues;
    colors: Array<GqlClientBcColor>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    localId: Scalars["ID"]["output"];
    logoLocation?: Maybe<Scalars["String"]["output"]>;
    logoMedia?: Maybe<GqlClientEditorAssetMedia>;
    logoUrl?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    textStyles: Array<GqlClientBcTextStyle>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    usedInPrograms: Array<GqlClientEditorProgram>;
};

export type GqlClientBrandConfigurationVersion = {
    __typename?: "BrandConfigurationVersion";
    accountId: Scalars["ID"]["output"];
    brandConfigurationCollections: Array<GqlClientBrandConfigurationCollection>;
    ccLibraryVersion: GqlClientCcLibraryVersion;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    defaultBrandConfigurationCollection: GqlClientBrandConfigurationCollection;
    id: Scalars["ID"]["output"];
    isDraft: Scalars["Boolean"]["output"];
    snapshotComment?: Maybe<Scalars["String"]["output"]>;
    snapshotName?: Maybe<Scalars["String"]["output"]>;
    snapshotNumber?: Maybe<Scalars["Int"]["output"]>;
    snapshotted?: Maybe<Scalars["String"]["output"]>;
    snapshottedBy?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientBrandSafetyMode {
    CUSTOM_SEGMENTS = "CUSTOM_SEGMENTS",
    OFF = "OFF",
    STANDARD = "STANDARD"
}

export enum GqlClientBusinessRole {
    CREATIVE = "creative",
    CREATIVEAGENCY = "creativeAgency",
    DATAANALYST = "dataAnalyst",
    INTEGRATOR = "integrator",
    MARKETING = "marketing",
    OTHER = "other"
}

export enum GqlClientButtonShapeValues {
    DEFAULT = "default",
    PILL = "pill",
    RECTANGULAR = "rectangular",
    ROUNDED = "rounded"
}

export enum GqlClientCacheControlScope {
    PRIVATE = "PRIVATE",
    PUBLIC = "PUBLIC"
}

export type GqlClientCampaignSettings = {
    __typename?: "CampaignSettings";
    URLFilter?: Maybe<Scalars["String"]["output"]>;
    accountId: Scalars["Int"]["output"];
    advertiserCreativeId?: Maybe<Scalars["String"]["output"]>;
    advertiserWebsite?: Maybe<Scalars["String"]["output"]>;
    allowDesktop?: Maybe<Scalars["Boolean"]["output"]>;
    allowMobileApp?: Maybe<Scalars["Boolean"]["output"]>;
    allowMobileWeb?: Maybe<Scalars["Boolean"]["output"]>;
    allowPurchased?: Maybe<Scalars["Boolean"]["output"]>;
    allowThirdPartySegmentsInOptimization?: Maybe<Scalars["Boolean"]["output"]>;
    appNameFilter?: Maybe<Scalars["String"]["output"]>;
    audienceDescriptors?: Maybe<Array<GqlClientAdsAudience>>;
    brandSafetyCustomSegmentsLocation?: Maybe<Scalars["String"]["output"]>;
    brandSafetyMode?: Maybe<GqlClientBrandSafetyMode>;
    campaignName: Scalars["String"]["output"];
    controlGroupMode?: Maybe<GqlClientAdsTestType>;
    controlGroupPercent?: Maybe<Scalars["Float"]["output"]>;
    creativeDefinitions?: Maybe<Array<GqlClientCreativeDefinitions>>;
    dailyFrequencyCap?: Maybe<Scalars["Int"]["output"]>;
    desktopMinBudget?: Maybe<Scalars["Float"]["output"]>;
    desktopPlayerSizes?: Maybe<Array<GqlClientPlayerSize>>;
    deviceMode?: Maybe<GqlClientDeviceMode>;
    enabled: Scalars["Boolean"]["output"];
    exchangeBlacklist?: Maybe<Array<Scalars["String"]["output"]>>;
    fraudMode?: Maybe<GqlClientFraudMode>;
    googleClickThroughURL?: Maybe<Scalars["String"]["output"]>;
    hourlyFrequencyCap?: Maybe<Scalars["Int"]["output"]>;
    iabCategoriesFilter?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    isCPCV: Scalars["Boolean"]["output"];
    keywordsFilter?: Maybe<Scalars["String"]["output"]>;
    locationFile?: Maybe<Scalars["String"]["output"]>;
    locationFilters?: Maybe<Array<Scalars["String"]["output"]>>;
    minimumViewability?: Maybe<Scalars["Float"]["output"]>;
    mobileMinBudget?: Maybe<Scalars["Float"]["output"]>;
    mobilePlayerSizes?: Maybe<Array<GqlClientPlayerSize>>;
    monthlyFrequencyCap?: Maybe<Scalars["Int"]["output"]>;
    pixelRulesLocation?: Maybe<Scalars["String"]["output"]>;
    placements?: Maybe<Array<GqlClientPlacement>>;
    productCatalogName?: Maybe<Scalars["String"]["output"]>;
    productCatalogSkuTemplate?: Maybe<Scalars["String"]["output"]>;
    productFilters?: Maybe<Array<GqlClientProductFilter>>;
    productSelectors?: Maybe<Array<GqlClientProductSelector>>;
    reportingDefinitions?: Maybe<Array<GqlClientReportingDefinitions>>;
    restrictedContentAlcohol?: Maybe<Scalars["Boolean"]["output"]>;
    rulesNewMode?: Maybe<Scalars["Boolean"]["output"]>;
    salesforceId?: Maybe<Scalars["String"]["output"]>;
    schedulesAndTargets?: Maybe<Array<GqlClientFlight>>;
    useCrossDevice?: Maybe<Scalars["Boolean"]["output"]>;
    verificationAuthority?: Maybe<GqlClientVerificationAuthority>;
    viewabilityGoal?: Maybe<Scalars["Float"]["output"]>;
    viewabilityMode?: Maybe<GqlClientViewabilityMode>;
    weeklyFrequencyCap?: Maybe<Scalars["Int"]["output"]>;
};

export type GqlClientCcAnimatedWireframe = {
    __typename?: "CcAnimatedWireframe";
    animatedWireframeApprovedVersion: GqlClientCcAnimatedWireframeVersion;
    animatedWireframeDraftVersion: GqlClientCcAnimatedWireframeVersion;
    animatedWireframeVersions: Array<GqlClientCcAnimatedWireframeVersion>;
    aspectRatio: GqlClientEnumAspectRatio;
    compatibility?: Maybe<GqlClientEnumAnimationWireframeCompatibility>;
    composition?: Maybe<GqlClientCcComposition>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    defaultButtonAlignment?: Maybe<GqlClientEnumHorizontalAlignment>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    libraryVersion: GqlClientCcLibraryVersion;
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage: GqlClientAssetLifecycleStage;
    m2AnimatedWireframe?: Maybe<GqlClientCcAnimatedWireframe>;
    mappedAnimatedWireframes: Array<GqlClientCcAnimatedWireframe>;
    name: Scalars["String"]["output"];
    placeholdersSettings: Array<GqlClientCcAnimatedWireframeVersionPlaceholdersSettings>;
    theme: GqlClientCcTheme;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    vsmlLocation: Scalars["String"]["output"];
    vsmlUrl: Scalars["String"]["output"];
    wireframe: GqlClientCcWireframe;
};

export type GqlClientCcAnimatedWireframeVersion = {
    __typename?: "CcAnimatedWireframeVersion";
    animatedWireframe: GqlClientCcAnimatedWireframe;
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    defaultButtonAlignment?: Maybe<GqlClientEnumHorizontalAlignment>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    libraryVersion: GqlClientCcLibraryVersion;
    snapshotNumber?: Maybe<Scalars["Int"]["output"]>;
    snapshotted?: Maybe<Scalars["String"]["output"]>;
    snapshottedBy?: Maybe<Scalars["String"]["output"]>;
    sourceFile?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    vsmlLocation: Scalars["String"]["output"];
    vsmlUrl: Scalars["String"]["output"];
};

export type GqlClientCcAnimatedWireframeVersionMediaPlaceholdersSettings = {
    __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
    animatedWireframeVersion: GqlClientCcAnimatedWireframeVersion;
    canvasName?: Maybe<Scalars["String"]["output"]>;
    ccPlaceholder: GqlClientCcPlaceholder;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    horizontalAlignment?: Maybe<GqlClientEnumHorizontalAlignment>;
    id: Scalars["ID"]["output"];
    libraryVersion: GqlClientCcLibraryVersion;
    mediaAdjustment?: Maybe<GqlClientEnumMediaAdjustment>;
    placeholderType: GqlClientCcPlaceholderType;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    verticalAlignment?: Maybe<GqlClientEnumVerticalAlignment>;
};

export type GqlClientCcAnimatedWireframeVersionPlaceholdersSettings = GqlClientCcAnimatedWireframeVersionMediaPlaceholdersSettings | GqlClientCcAnimatedWireframeVersionTextPlaceholdersSettings;

export type GqlClientCcAnimatedWireframeVersionTextPlaceholdersSettings = {
    __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
    animatedWireframeVersion: GqlClientCcAnimatedWireframeVersion;
    canvasName?: Maybe<Scalars["String"]["output"]>;
    ccPlaceholder: GqlClientCcPlaceholder;
    colorCcDof?: Maybe<GqlClientCcDof>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    horizontalAlignment?: Maybe<GqlClientEnumHorizontalAlignment>;
    id: Scalars["ID"]["output"];
    libraryVersion: GqlClientCcLibraryVersion;
    placeholderType: GqlClientCcPlaceholderType;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    verticalAlignment?: Maybe<GqlClientEnumVerticalAlignment>;
};

export type GqlClientCcAvatar = {
    __typename?: "CcAvatar";
    avatarSystemName: Scalars["String"]["output"];
    closeUpCrop: GqlClientCcAvatarCrop;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    fullBodyCrop: GqlClientCcAvatarCrop;
    id: Scalars["ID"]["output"];
    language?: Maybe<Scalars["String"]["output"]>;
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage: GqlClientAssetLifecycleStage;
    localId: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    narrator?: Maybe<GqlClientCcNarrator>;
    sampleLocation: Scalars["String"]["output"];
    sampleTranscript: Scalars["String"]["output"];
    sampleUrl: Scalars["String"]["output"];
    sampleVideoMetadata: Scalars["JSON"]["output"];
    thumbnailLocation: Scalars["String"]["output"];
    thumbnailUrl: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientCcAvatarCrop = {
    __typename?: "CcAvatarCrop";
    bottom: Scalars["Int"]["output"];
    left: Scalars["Int"]["output"];
    right: Scalars["Int"]["output"];
    top: Scalars["Int"]["output"];
};

export type GqlClientCcComposition = {
    __typename?: "CcComposition";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    matchingAnimatedWireframePatterns: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    supportsUserMediaOverMedia: Scalars["Boolean"]["output"];
    supportsUserTextOnMedia: Scalars["Boolean"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientCcDof = {
    __typename?: "CcDof";
    allThemes: Scalars["Boolean"]["output"];
    category: GqlClientCcDofCategory;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    defaultValue: Scalars["String"]["output"];
    description?: Maybe<Scalars["String"]["output"]>;
    displayName: Scalars["String"]["output"];
    editorOrder?: Maybe<Scalars["Int"]["output"]>;
    id: Scalars["ID"]["output"];
    libraryVersion: GqlClientCcLibraryVersion;
    localId: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    type: GqlClientCcDofType;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientCcDofCategory {
    ANIMATION = "animation",
    COLOR = "color"
}

export enum GqlClientCcDofType {
    BREATHING = "breathing",
    COLOR = "color"
}

export type GqlClientCcDofValue = {
    __typename?: "CcDofValue";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    dof: GqlClientCcDof;
    id: Scalars["ID"]["output"];
    theme: GqlClientCcTheme;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    value: Scalars["String"]["output"];
};

export type GqlClientCcFont = {
    __typename?: "CcFont";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    familyName?: Maybe<Scalars["String"]["output"]>;
    hasSmallCaps?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    isBold?: Maybe<Scalars["Boolean"]["output"]>;
    isItalic?: Maybe<Scalars["Boolean"]["output"]>;
    libraryVersion: GqlClientCcLibraryVersion;
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage?: Maybe<GqlClientAssetLifecycleStage>;
    location: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    url: Scalars["String"]["output"];
    webFontLocation?: Maybe<Scalars["String"]["output"]>;
    webFontUrl?: Maybe<Scalars["String"]["output"]>;
    weight?: Maybe<Scalars["Int"]["output"]>;
};

export type GqlClientCcFrame = {
    __typename?: "CcFrame";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    indexInWireframe: Scalars["Int"]["output"];
    name: Scalars["String"]["output"];
    placeholders: Array<GqlClientCcPlaceholder>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    wireframe: GqlClientCcWireframe;
};

export type GqlClientCcFrameInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    placeholders: Array<GqlClientCcPlaceholderInput>;
};

export type GqlClientCcLibrary = {
    __typename?: "CcLibrary";
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    libraryVersion?: Maybe<GqlClientCcLibraryVersion>;
    libraryVersionActive?: Maybe<GqlClientCcLibraryVersion>;
    libraryVersionDraft?: Maybe<GqlClientCcLibraryVersion>;
    libraryVersionSnapshots: Array<GqlClientCcLibraryVersion>;
    libraryVersions: Array<GqlClientCcLibraryVersion>;
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientCcLibraryLibraryVersionArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientCcLibraryVersion = {
    __typename?: "CcLibraryVersion";
    animatedWireframes: Array<GqlClientCcAnimatedWireframe>;
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    avatars: Array<GqlClientCcAvatar>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    dofs: Array<GqlClientCcDof>;
    fonts: Array<GqlClientCcFont>;
    id: Scalars["ID"]["output"];
    isActive?: Maybe<Scalars["Boolean"]["output"]>;
    isDraft?: Maybe<Scalars["Boolean"]["output"]>;
    lastActivation?: Maybe<Scalars["String"]["output"]>;
    lastDeactivation?: Maybe<Scalars["String"]["output"]>;
    library: GqlClientCcLibrary;
    music: Array<GqlClientCcMusic>;
    narrators: Array<GqlClientCcNarrator>;
    placeholderIntents: Array<GqlClientCcPlaceholderIntent>;
    snapshotComment?: Maybe<Scalars["String"]["output"]>;
    snapshotName?: Maybe<Scalars["String"]["output"]>;
    snapshotNumber?: Maybe<Scalars["Int"]["output"]>;
    snapshotted?: Maybe<Scalars["String"]["output"]>;
    snapshottedBy?: Maybe<Scalars["String"]["output"]>;
    textStyles: Array<GqlClientCcTextStyle>;
    themes: Array<GqlClientCcTheme>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    usedInAccounts: Array<Scalars["String"]["output"]>;
    wireframes: Array<GqlClientCcWireframe>;
};

export type GqlClientCcMusic = {
    __typename?: "CcMusic";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    libraryVersion: GqlClientCcLibraryVersion;
    location: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    url: Scalars["String"]["output"];
};

export type GqlClientCcNarrationSystemMetadata = {
    __typename?: "CcNarrationSystemMetadata";
    age?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Array<Scalars["String"]["output"]>>;
    gender: Scalars["String"]["output"];
    language: Scalars["String"]["output"];
    rate?: Maybe<Scalars["Float"]["output"]>;
    volume?: Maybe<Scalars["Float"]["output"]>;
};

export type GqlClientCcNarrationSystemMetadataInput = {
    gender: Scalars["String"]["input"];
    language: Scalars["String"]["input"];
    rate?: InputMaybe<Scalars["Float"]["input"]>;
    volume?: InputMaybe<Scalars["Float"]["input"]>;
};

export type GqlClientCcNarrator = {
    __typename?: "CcNarrator";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    intent: GqlClientCcNarratorIntent;
    libraryVersion: GqlClientCcLibraryVersion;
    name: Scalars["String"]["output"];
    narrationSystemMetadata: GqlClientCcNarrationSystemMetadata;
    narrationSystemName: Scalars["String"]["output"];
    narrationSystemVoice: Scalars["String"]["output"];
    sampleLocation: Scalars["String"]["output"];
    sampleTranscript: Scalars["String"]["output"];
    sampleUrl: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientCcNarratorIntent {
    AVATAR = "avatar",
    GENERAL = "general",
    NARRATOR = "narrator",
    PROMO = "promo"
}

export type GqlClientCcPlaceholder = {
    __typename?: "CcPlaceholder";
    category: GqlClientCcPlaceholderCategory;
    content?: Maybe<Scalars["JSON"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    descriptionOrder?: Maybe<Scalars["Int"]["output"]>;
    frame: GqlClientCcFrame;
    id: Scalars["ID"]["output"];
    intent: GqlClientCcPlaceholderIntent;
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientCcPlaceholderCategory {
    IMPLICITLY_USER_DEFINABLE = "implicitly_user_definable",
    PRE_DEFINED = "pre_defined",
    USER_DEFINABLE = "user_definable"
}

export type GqlClientCcPlaceholderInput = {
    category: GqlClientCcPlaceholderCategory;
    content?: InputMaybe<Scalars["JSON"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    descriptionOrder?: InputMaybe<Scalars["Int"]["input"]>;
    intentId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
};

export type GqlClientCcPlaceholderIntent = {
    __typename?: "CcPlaceholderIntent";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    defaultValue?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    libraryVersion: GqlClientCcLibraryVersion;
    name: Scalars["String"]["output"];
    textStyle?: Maybe<GqlClientCcTextStyle>;
    type: GqlClientCcPlaceholderType;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientCcPlaceholderType {
    AVATAR = "avatar",
    BUTTON = "button",
    IMAGE = "image",
    MEDIA = "media",
    NARRATION = "narration",
    TEXT = "text",
    VIDEO = "video"
}

export type GqlClientCcTextStyle = {
    __typename?: "CcTextStyle";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    defaultValues?: Maybe<Scalars["String"]["output"]>;
    editorOrder?: Maybe<Scalars["Int"]["output"]>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientCcTheme = {
    __typename?: "CcTheme";
    animatedWireframes: Array<GqlClientCcAnimatedWireframe>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    dofValues: Array<GqlClientCcDofValue>;
    editorOrder?: Maybe<Scalars["Int"]["output"]>;
    id: Scalars["ID"]["output"];
    isComingSoon?: Maybe<Scalars["Boolean"]["output"]>;
    libraryVersion: GqlClientCcLibraryVersion;
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage: GqlClientAssetLifecycleStage;
    name: Scalars["String"]["output"];
    posterLocation?: Maybe<Scalars["String"]["output"]>;
    posterUrl?: Maybe<Scalars["String"]["output"]>;
    supportedAspectRatios: Array<GqlClientEnumAspectRatio>;
    supportsBackgroundMedia: Scalars["Boolean"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientCcWireframe = {
    __typename?: "CcWireframe";
    allWireframesMapping: Array<GqlClientCcWireframeAllWireframesData>;
    animatedWireframes: Array<GqlClientCcAnimatedWireframe>;
    category: GqlClientCcWireframeCategory;
    comment?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    frames: Array<GqlClientCcFrame>;
    id: Scalars["ID"]["output"];
    libraryVersion: GqlClientCcLibraryVersion;
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage: GqlClientAssetLifecycleStage;
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    useToCreateDefaultScene: Scalars["Boolean"]["output"];
    usedInSnapshot?: Maybe<Scalars["Boolean"]["output"]>;
};

export type GqlClientCcWireframeAllWireframesData = {
    __typename?: "CcWireframeAllWireframesData";
    isLosingData: Scalars["Boolean"]["output"];
    placeholdersData: Array<Maybe<GqlClientCcWireframePlaceholderData>>;
    wireframe: GqlClientCcWireframe;
};

export enum GqlClientCcWireframeCategory {
    CLASSIC = "classic",
    CUSTOM = "custom"
}

export type GqlClientCcWireframePlaceholderData = {
    __typename?: "CcWireframePlaceholderData";
    originalCcPlaceholder?: Maybe<GqlClientCcPlaceholder>;
    targetCcPlaceholder: GqlClientCcPlaceholder;
};

export type GqlClientChannel = {
    __typename?: "Channel";
    accountId: Scalars["ID"]["output"];
    channelPersonalizations: Array<GqlClientChannelPersonalization>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    platforms?: Maybe<Array<Scalars["String"]["output"]>>;
    programVersion: GqlClientProgramVersion;
    status?: Maybe<Scalars["String"]["output"]>;
    stories: Array<GqlClientStory>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientChannelPersonalization = GqlClientPersonalization & {
    __typename?: "ChannelPersonalization";
    accountId: Scalars["ID"]["output"];
    channel: GqlClientChannel;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    scene: GqlClientScene;
    type: Array<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientClearUndoStackOutput = {
    __typename?: "ClearUndoStackOutput";
    result: GqlClientClearUndoStackResult;
    undoStacks: GqlClientUndoStacks;
};

export enum GqlClientClearUndoStackResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientClicksAnalyticsButton = {
    __typename?: "ClicksAnalyticsButton";
    ctaClicks: Scalars["Int"]["output"];
    ctaViews: Scalars["Int"]["output"];
    landingPage: Scalars["Boolean"]["output"];
    operation: Scalars["String"]["output"];
    screenText: Scalars["String"]["output"];
};

export type GqlClientCompleteFirstLoginInput = {
    businessRole: GqlClientBusinessRole;
    firstName: Scalars["String"]["input"];
    lastName: Scalars["String"]["input"];
};

export type GqlClientCompleteFirstLoginOutput = {
    result: GqlClientCompleteFirstLoginResult;
};

export type GqlClientCompleteFirstLoginOutputRefusal = GqlClientCompleteFirstLoginOutput & {
    __typename?: "CompleteFirstLoginOutputRefusal";
    result: GqlClientCompleteFirstLoginResult;
};

export type GqlClientCompleteFirstLoginOutputSuccess = GqlClientCompleteFirstLoginOutput & {
    __typename?: "CompleteFirstLoginOutputSuccess";
    acceptedAccountId: Scalars["String"]["output"];
    result: GqlClientCompleteFirstLoginResult;
};

export enum GqlClientCompleteFirstLoginResult {
    INVITATION_DOES_NOT_EXIST = "INVITATION_DOES_NOT_EXIST",
    SUCCESS = "SUCCESS"
}

export enum GqlClientConnectionType {
    TRAYGOOGLESHEETS = "TrayGoogleSheets",
    TRAYHUBSPOT = "TrayHubspot",
    TRAYMARKETO = "TrayMarketo",
    TRAYSALESFORCE = "TraySalesforce"
}

export type GqlClientConvertFeedDataElementToDerivedInput = {
    id: Scalars["ID"]["input"];
    logic?: InputMaybe<Scalars["JSON"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientConvertFeedDataElementToDerivedOutput = {
    result: GqlClientConvertFeedDataElementToDerivedResult;
};

export type GqlClientConvertFeedDataElementToDerivedOutputConflict = GqlClientConvertFeedDataElementToDerivedOutput & {
    __typename?: "ConvertFeedDataElementToDerivedOutputConflict";
    feedDataElement?: Maybe<GqlClientFeedDataElement>;
    result: GqlClientConvertFeedDataElementToDerivedResult;
};

export type GqlClientConvertFeedDataElementToDerivedOutputSuccess = GqlClientConvertFeedDataElementToDerivedOutput & {
    __typename?: "ConvertFeedDataElementToDerivedOutputSuccess";
    derivedDataElement?: Maybe<GqlClientDerivedDataElement>;
    result: GqlClientConvertFeedDataElementToDerivedResult;
};

export enum GqlClientConvertFeedDataElementToDerivedResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientCopyAccountReportInput = {
    sourceId: Scalars["ID"]["input"];
    targetName: Scalars["String"]["input"];
};

export type GqlClientCopyAccountReportOutput = {
    __typename?: "CopyAccountReportOutput";
    result: GqlClientCopyAccountReportResult;
    sourceReport?: Maybe<GqlClientAccountReport>;
    targetReport?: Maybe<GqlClientAccountReport>;
};

export enum GqlClientCopyAccountReportResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCopyCreativeVersionDraftInput = {
    programRuntimeId: Scalars["String"]["input"];
    programSnapshotNumber: Scalars["Int"]["input"];
    sourceId: Scalars["ID"]["input"];
    targetDescription?: InputMaybe<Scalars["String"]["input"]>;
    targetName: Scalars["String"]["input"];
    validationData?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type GqlClientCopyCreativeVersionDraftOutput = {
    __typename?: "CopyCreativeVersionDraftOutput";
    result: GqlClientCopyCreativeVersionDraftResult;
    sourceCreativeVersion?: Maybe<GqlClientCreativeVersion>;
    targetCreativeVersion?: Maybe<GqlClientCreativeVersion>;
};

export enum GqlClientCopyCreativeVersionDraftResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCopyCreativeWithDraftInput = {
    sourceCreativeVersionId: Scalars["ID"]["input"];
    targetCreativeDDEValue: Scalars["String"]["input"];
    targetName: Scalars["String"]["input"];
    targetStoryId: Scalars["String"]["input"];
};

export type GqlClientCopyCreativeWithDraftOutput = {
    __typename?: "CopyCreativeWithDraftOutput";
    result: GqlClientCopyCreativeWithDraftResult;
    targetCreative?: Maybe<GqlClientCreative>;
};

export enum GqlClientCopyCreativeWithDraftResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCopyDraftEditorProgramBetweenAccountsInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    sourceDraftEditorProgramId: Scalars["ID"]["input"];
    targetAccount: Scalars["String"]["input"];
};

export type GqlClientCopyDraftEditorProgramBetweenAccountsOutput = {
    __typename?: "CopyDraftEditorProgramBetweenAccountsOutput";
    editorProgram?: Maybe<GqlClientEditorProgram>;
    result: GqlClientCopyDraftEditorProgramBetweenAccountsResult;
};

export enum GqlClientCopyDraftEditorProgramBetweenAccountsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCopyEditorProgramAsEditorOutput = {
    __typename?: "CopyEditorProgramAsEditorOutput";
    result: GqlClientCopyEditorProgramAsEditorResult;
    targetProgram?: Maybe<GqlClientEditorProgram>;
};

export enum GqlClientCopyEditorProgramAsEditorResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCopyNarrationInput = {
    sourceId: Scalars["ID"]["input"];
};

export type GqlClientCopyNarrationOutput = {
    __typename?: "CopyNarrationOutput";
    result: GqlClientCopyNarrationResult;
    sourceNarration?: Maybe<GqlClientNarration>;
    targetNarration?: Maybe<GqlClientNarration>;
};

export enum GqlClientCopyNarrationResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCopyProgramInput = {
    copyStoryTemplateData?: InputMaybe<Scalars["Boolean"]["input"]>;
    deleteLogicAndRemoveDataLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
    sourceProgramId?: InputMaybe<Scalars["ID"]["input"]>;
    sourceProgramVersionId?: InputMaybe<Scalars["ID"]["input"]>;
    targetAccountId?: InputMaybe<Scalars["String"]["input"]>;
    targetProgramDescription?: InputMaybe<Scalars["String"]["input"]>;
    targetProgramName: Scalars["String"]["input"];
};

export type GqlClientCopyProgramOutput = {
    __typename?: "CopyProgramOutput";
    result: GqlClientCopyProgramResult;
    targetProgram?: Maybe<GqlClientProgram>;
};

export enum GqlClientCopyProgramResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCopyProgramVersionDraftInput = {
    sourceId: Scalars["ID"]["input"];
    targetDescription?: InputMaybe<Scalars["String"]["input"]>;
    targetName: Scalars["String"]["input"];
};

export type GqlClientCopyProgramVersionDraftOutput = {
    __typename?: "CopyProgramVersionDraftOutput";
    result: GqlClientCopyProgramVersionDraftResult;
    sourceProgramVersion?: Maybe<GqlClientProgramVersion>;
    targetProgramVersion?: Maybe<GqlClientProgramVersion>;
};

export enum GqlClientCopyProgramVersionDraftResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCopySceneInput = {
    sourceId: Scalars["ID"]["input"];
    targetName: Scalars["String"]["input"];
};

export type GqlClientCopySceneOutput = {
    __typename?: "CopySceneOutput";
    result: GqlClientCopySceneResult;
    sourceScene?: Maybe<GqlClientScene>;
    targetScene?: Maybe<GqlClientScene>;
};

export type GqlClientCopyScenePartInput = {
    sourceId: Scalars["ID"]["input"];
};

export type GqlClientCopyScenePartOutput = {
    __typename?: "CopyScenePartOutput";
    result: GqlClientCopyScenePartResult;
    sourceScenePart?: Maybe<GqlClientScenePart>;
    targetScenePart?: Maybe<GqlClientScenePart>;
};

export enum GqlClientCopyScenePartResult {
    SUCCESS = "SUCCESS"
}

export enum GqlClientCopySceneResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCopyStoryInput = {
    sourceId: Scalars["ID"]["input"];
    targetName: Scalars["String"]["input"];
};

export type GqlClientCopyStoryOutput = {
    __typename?: "CopyStoryOutput";
    result: GqlClientCopyStoryResult;
    sourceStory?: Maybe<GqlClientStory>;
    targetStory?: Maybe<GqlClientStory>;
};

export enum GqlClientCopyStoryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateAccountCopilotBriefInput = {
    data: Scalars["JSON"]["input"];
    name: Scalars["String"]["input"];
};

export type GqlClientCreateAccountCopilotBriefOutput = {
    __typename?: "CreateAccountCopilotBriefOutput";
    account?: Maybe<GqlClientAccount>;
    brief?: Maybe<GqlClientAccountCopilotBrief>;
    result?: Maybe<GqlClientCreateAccountCopilotBriefResult>;
};

export enum GqlClientCreateAccountCopilotBriefResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateAccountProgramFolderInput = {
    name: Scalars["String"]["input"];
    parentFolderId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GqlClientCreateAccountProgramFolderOutput = {
    __typename?: "CreateAccountProgramFolderOutput";
    account?: Maybe<GqlClientAccount>;
    folder?: Maybe<GqlClientAccountProgramFolder>;
    result?: Maybe<GqlClientCreateAccountProgramFolderResult>;
};

export enum GqlClientCreateAccountProgramFolderResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateAccountReportInput = {
    customCampaignIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    customProgramIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    filters?: InputMaybe<Array<GqlClientAccountReportFilterInput>>;
    name: Scalars["String"]["input"];
    relevantPrograms: GqlClientRelevantPrograms;
    reportElements?: InputMaybe<Array<GqlClientAccountReportElementInput>>;
    reportProgramType: GqlClientReportProgramType;
    schedules?: InputMaybe<Array<GqlClientCreateAccountReportScheduleInput>>;
    timezone: Scalars["String"]["input"];
    type: GqlClientAccountReportType;
};

export type GqlClientCreateAccountReportOutput = {
    __typename?: "CreateAccountReportOutput";
    accountReport?: Maybe<GqlClientAccountReport>;
    result: GqlClientCreateAccountReportResult;
};

export enum GqlClientCreateAccountReportResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateAccountReportScheduleInput = {
    active?: InputMaybe<Scalars["Boolean"]["input"]>;
    deliveryChannel?: InputMaybe<Scalars["JSON"]["input"]>;
    endPeriod?: InputMaybe<Scalars["String"]["input"]>;
    filenameTemplate?: InputMaybe<Scalars["String"]["input"]>;
    occurrence?: InputMaybe<GqlClientReportScheduleOccurrence>;
    period?: InputMaybe<GqlClientReportSchedulePeriod>;
    periodType?: InputMaybe<GqlClientReportSchedulePeriodType>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    sourceEnvironments?: InputMaybe<Array<Scalars["String"]["input"]>>;
    startPeriod?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateAdsAudienceInput = {
    audienceName: Scalars["String"]["input"];
    comment?: InputMaybe<Scalars["String"]["input"]>;
    enabled: Scalars["Boolean"]["input"];
    priorityIndex?: InputMaybe<Scalars["Int"]["input"]>;
    programId: Scalars["String"]["input"];
    superGroups?: InputMaybe<Array<GqlClientCreateAudienceRuleSuperGroupInput>>;
};

export type GqlClientCreateAiPreviewProgramInput = {
    sessionId: Scalars["String"]["input"];
};

export type GqlClientCreateAiPreviewProgramOutput = {
    __typename?: "CreateAiPreviewProgramOutput";
    pollingTaskCreationResponse: GqlClientPollingTaskCreationResponse;
    result: GqlClientCreateAiPreviewProgramResult;
};

export enum GqlClientCreateAiPreviewProgramResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateAnimationVersionsInput = {
    animationId: Scalars["ID"]["input"];
    versions: Array<GqlClientCreateAnimationVersionsInputVersion>;
};

export type GqlClientCreateAnimationVersionsInputVersion = {
    aeFilename?: InputMaybe<Scalars["String"]["input"]>;
    comment?: InputMaybe<Scalars["String"]["input"]>;
    folderVersion: Scalars["Int"]["input"];
    location: Scalars["String"]["input"];
    transition?: InputMaybe<Scalars["JSON"]["input"]>;
    uploadedBy?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateAnimationVersionsOutput = {
    __typename?: "CreateAnimationVersionsOutput";
    animation?: Maybe<GqlClientAnimation>;
    result: GqlClientCreateAnimationVersionsResult;
};

export enum GqlClientCreateAnimationVersionsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateAnimationWithVersionsInput = {
    legacyBuilderId?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    programVersionId: Scalars["ID"]["input"];
    versions: Array<GqlClientCreateAnimationWithVersionsInputVersion>;
};

export type GqlClientCreateAnimationWithVersionsInputVersion = {
    aeFilename?: InputMaybe<Scalars["String"]["input"]>;
    comment?: InputMaybe<Scalars["String"]["input"]>;
    folderVersion: Scalars["Int"]["input"];
    location: Scalars["String"]["input"];
    transition?: InputMaybe<Scalars["JSON"]["input"]>;
    uploadedBy?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateAnimationWithVersionsOutput = {
    __typename?: "CreateAnimationWithVersionsOutput";
    animation?: Maybe<GqlClientAnimation>;
    result: GqlClientCreateAnimationWithVersionsResult;
};

export enum GqlClientCreateAnimationWithVersionsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateAssetRequestInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    legacyData: Scalars["JSON"]["input"];
    name: Scalars["String"]["input"];
    programVersionId: Scalars["ID"]["input"];
    type: GqlClientAssetType;
};

export type GqlClientCreateAssetRequestOutput = {
    __typename?: "CreateAssetRequestOutput";
    asset?: Maybe<GqlClientAsset>;
    result: GqlClientCreateAssetRequestResult;
};

export enum GqlClientCreateAssetRequestResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateAssetVersionInput = {
    assetId: Scalars["ID"]["input"];
    file: Scalars["Upload"]["input"];
    fileType: Scalars["String"]["input"];
    filename: Scalars["String"]["input"];
};

export type GqlClientCreateAssetVersionOutput = {
    __typename?: "CreateAssetVersionOutput";
    assetVersion?: Maybe<GqlClientAssetVersion>;
    result: GqlClientCreateAssetVersionResult;
};

export enum GqlClientCreateAssetVersionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateAssetWithVersionInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    file: Scalars["Upload"]["input"];
    fileType: Scalars["String"]["input"];
    filename: Scalars["String"]["input"];
    legacyData: Scalars["JSON"]["input"];
    name: Scalars["String"]["input"];
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientCreateAssetWithVersionOutput = {
    __typename?: "CreateAssetWithVersionOutput";
    asset?: Maybe<GqlClientAsset>;
    result: GqlClientCreateAssetWithVersionResult;
};

export enum GqlClientCreateAssetWithVersionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateAudienceInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    impactsVolume?: InputMaybe<Scalars["Boolean"]["input"]>;
    name: Scalars["String"]["input"];
    newAudienceValues?: InputMaybe<Array<GqlClientCreateAudienceNewAudienceValueInput>>;
};

export type GqlClientCreateAudienceNewAudienceValueInput = {
    name: Scalars["String"]["input"];
    newSubAudiences?: InputMaybe<Array<InputMaybe<GqlClientCreateAudienceInput>>>;
    volume?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GqlClientCreateAudienceOutput = {
    __typename?: "CreateAudienceOutput";
    audience?: Maybe<GqlClientAudience>;
    result: GqlClientCreateAudienceResult;
};

export enum GqlClientCreateAudienceResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateAudienceRuleGroupInput = {
    audienceRules?: InputMaybe<Array<GqlClientCreateAudienceRuleInput>>;
    matchType?: InputMaybe<GqlClientRuleMatchType>;
    maxAgeDays?: InputMaybe<Scalars["Int"]["input"]>;
    minAgeDays?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GqlClientCreateAudienceRuleInput = {
    name?: InputMaybe<Scalars["String"]["input"]>;
    operator?: InputMaybe<GqlClientAudienceRuleOperator>;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateAudienceRuleSuperGroupInput = {
    matchType?: InputMaybe<GqlClientRuleMatchType>;
    ruleType?: InputMaybe<GqlClientRuleType>;
    superGroupRules?: InputMaybe<Array<GqlClientCreateAudienceRuleGroupInput>>;
};

export type GqlClientCreateAudienceWithDataElementsInput = {
    dataElementIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    impactsVolume?: InputMaybe<Scalars["Boolean"]["input"]>;
    name: Scalars["String"]["input"];
    newAudienceValues?: InputMaybe<Array<GqlClientCreateAudienceNewAudienceValueInput>>;
    newFeedDataElements?: InputMaybe<Array<GqlClientCreateAudienceWithDataElementsNewFeedDataElementInput>>;
};

export type GqlClientCreateAudienceWithDataElementsNewAudienceValueInput = {
    name: Scalars["String"]["input"];
    newSubAudiences?: InputMaybe<Array<InputMaybe<GqlClientCreateAudienceInput>>>;
    volume?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GqlClientCreateAudienceWithDataElementsNewFeedDataElementInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    source?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<GqlClientFeedDataElementStatus>;
};

export type GqlClientCreateAudienceWithDataElementsOutput = {
    __typename?: "CreateAudienceWithDataElementsOutput";
    audience?: Maybe<GqlClientAudience>;
    result: GqlClientCreateAudienceWithDataElementsResult;
};

export enum GqlClientCreateAudienceWithDataElementsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateBasicFeedDataElementInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    source?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<GqlClientFeedDataElementStatus>;
};

export type GqlClientCreateBasicFeedDataElementOutput = {
    __typename?: "CreateBasicFeedDataElementOutput";
    feedDataElement?: Maybe<GqlClientFeedDataElement>;
    result: GqlClientCreateBasicFeedDataElementResult;
};

export enum GqlClientCreateBasicFeedDataElementResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateBcCollectionInput = {
    buttonShape?: InputMaybe<GqlClientButtonShapeValues>;
    colors?: InputMaybe<Array<GqlClientCreateColor>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    logoLocation?: InputMaybe<Scalars["String"]["input"]>;
    logoMediaId?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    textStyles?: InputMaybe<Array<GqlClientCreateTextStyle>>;
};

export type GqlClientCreateBcCollectionOutput = {
    __typename?: "CreateBcCollectionOutput";
    brandConfigurationCollection?: Maybe<GqlClientBrandConfigurationCollection>;
    result: GqlClientCreateBcCollectionResult;
};

export enum GqlClientCreateBcCollectionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCampaignAdsAudienceInput = {
    audienceName: Scalars["String"]["input"];
    enabled: Scalars["Boolean"]["input"];
    priorityIndex: Scalars["Int"]["input"];
    productSelector?: InputMaybe<GqlClientCreateProductSelectorInput>;
    superGroups?: InputMaybe<Array<GqlClientCreateAudienceRuleSuperGroupInput>>;
};

export type GqlClientCreateCampaignSettingsInput = {
    accountId?: InputMaybe<Scalars["Int"]["input"]>;
    advertiserCreativeId: Scalars["String"]["input"];
    advertiserWebsite: Scalars["String"]["input"];
    allowDesktop: Scalars["Boolean"]["input"];
    allowMobileApp: Scalars["Boolean"]["input"];
    allowMobileWeb: Scalars["Boolean"]["input"];
    allowPurchased?: InputMaybe<Scalars["Boolean"]["input"]>;
    allowThirdPartySegmentsInOptimization?: InputMaybe<Scalars["Boolean"]["input"]>;
    appsFilter?: InputMaybe<Scalars["String"]["input"]>;
    brandSafetyCustomSegmentsLocation?: InputMaybe<Scalars["String"]["input"]>;
    brandSafetyMode?: InputMaybe<GqlClientBrandSafetyMode>;
    campaignName: Scalars["String"]["input"];
    comment?: InputMaybe<Scalars["String"]["input"]>;
    controlGroupMode?: InputMaybe<GqlClientAdsTestType>;
    controlGroupPercent?: InputMaybe<Scalars["Float"]["input"]>;
    dailyFrequencyCap: Scalars["Int"]["input"];
    desktopMinBudget?: InputMaybe<Scalars["Float"]["input"]>;
    desktopPlayerSizes?: InputMaybe<Array<GqlClientPlayerSize>>;
    deviceMode?: InputMaybe<GqlClientDeviceMode>;
    enabled: Scalars["Boolean"]["input"];
    exchangeBlacklist?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    fraudMode?: InputMaybe<GqlClientFraudMode>;
    hourlyFrequencyCap: Scalars["Int"]["input"];
    iabCategoriesFilter?: InputMaybe<Scalars["String"]["input"]>;
    isCPCV?: InputMaybe<Scalars["Boolean"]["input"]>;
    keywordsFilter?: InputMaybe<Scalars["String"]["input"]>;
    locationFile?: InputMaybe<Scalars["String"]["input"]>;
    locationFilters?: InputMaybe<Array<Scalars["String"]["input"]>>;
    minimumViewability?: InputMaybe<Scalars["Float"]["input"]>;
    mobileMinBudget?: InputMaybe<Scalars["Float"]["input"]>;
    mobilePlayerSizes?: InputMaybe<Array<GqlClientPlayerSize>>;
    monthlyFrequencyCap: Scalars["Int"]["input"];
    newAudiences?: InputMaybe<Array<GqlClientCreateCampaignAdsAudienceInput>>;
    newCreativeDefinitions: Array<GqlClientCreateCreativeDefinitionsInput>;
    newProductFilters?: InputMaybe<Array<GqlClientCreateProductFilterInput>>;
    newProductSelectors?: InputMaybe<Array<GqlClientCreateProductSelectorInput>>;
    newReportingDefinitions?: InputMaybe<Array<GqlClientCreateReportingDefinitionsInput>>;
    newSchedulesAndTargets?: InputMaybe<Array<GqlClientCreateFlightInput>>;
    pixelRulesLocation?: InputMaybe<Scalars["String"]["input"]>;
    productCatalogIdTemplate: Scalars["String"]["input"];
    productCatalogName?: InputMaybe<Scalars["String"]["input"]>;
    restrictedContentAlcohol?: InputMaybe<Scalars["Boolean"]["input"]>;
    urlFilter?: InputMaybe<Scalars["String"]["input"]>;
    useCrossDevice?: InputMaybe<Scalars["Boolean"]["input"]>;
    verificationAuthority?: InputMaybe<GqlClientVerificationAuthority>;
    viewabilityGoal?: InputMaybe<Scalars["Float"]["input"]>;
    viewabilityMode?: InputMaybe<GqlClientViewabilityMode>;
    weeklyFrequencyCap: Scalars["Int"]["input"];
};

export type GqlClientCreateCcAnimatedWireframeInput = {
    aspectRatio?: InputMaybe<GqlClientEnumAspectRatio>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    libraryVersionId: Scalars["ID"]["input"];
    lifecycleStage?: InputMaybe<GqlClientAssetLifecycleStage>;
    name: Scalars["String"]["input"];
    themeId: Scalars["String"]["input"];
    vsmlLocation: Scalars["String"]["input"];
    wireframeId: Scalars["String"]["input"];
};

export type GqlClientCreateCcAnimatedWireframeOutput = {
    __typename?: "CreateCcAnimatedWireframeOutput";
    animatedWireframe?: Maybe<GqlClientCcAnimatedWireframe>;
    result: GqlClientCreateCcAnimatedWireframeResult;
};

export enum GqlClientCreateCcAnimatedWireframeResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCcLibraryDraftInput = {
    libraryId: Scalars["ID"]["input"];
};

export type GqlClientCreateCcLibraryDraftOutput = {
    __typename?: "CreateCcLibraryDraftOutput";
    libraryDraft?: Maybe<GqlClientCcLibraryVersion>;
    result: GqlClientCreateCcLibraryDraftResult;
};

export enum GqlClientCreateCcLibraryDraftResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCcLibraryInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
};

export type GqlClientCreateCcLibraryOutput = {
    __typename?: "CreateCcLibraryOutput";
    library?: Maybe<GqlClientCcLibrary>;
    result: GqlClientCreateCcLibraryResult;
};

export enum GqlClientCreateCcLibraryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCcMusicInput = {
    libraryVersionId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
};

export type GqlClientCreateCcMusicOutput = {
    __typename?: "CreateCcMusicOutput";
    music?: Maybe<GqlClientCcMusic>;
    result: GqlClientCreateCcMusicResult;
};

export enum GqlClientCreateCcMusicResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCcNarratorInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    intent: GqlClientCcNarratorIntent;
    libraryVersionId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    narrationSystemMetadata: GqlClientCcNarrationSystemMetadataInput;
    narrationSystemName: Scalars["String"]["input"];
    narrationSystemVoice: Scalars["String"]["input"];
    sampleLocation: Scalars["String"]["input"];
    sampleTranscript: Scalars["String"]["input"];
};

export type GqlClientCreateCcNarratorOutput = {
    __typename?: "CreateCcNarratorOutput";
    narrator?: Maybe<GqlClientCcNarrator>;
    result: GqlClientCreateCcNarratorResult;
};

export enum GqlClientCreateCcNarratorResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCcPlaceholderIntentInput = {
    defaultValue?: InputMaybe<Scalars["String"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    libraryVersionId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    textStyleId?: InputMaybe<Scalars["String"]["input"]>;
    type: GqlClientCcPlaceholderType;
};

export type GqlClientCreateCcPlaceholderIntentOutput = {
    __typename?: "CreateCcPlaceholderIntentOutput";
    placeholderIntent?: Maybe<GqlClientCcPlaceholderIntent>;
    result: GqlClientCreateCcPlaceholderIntentResult;
};

export enum GqlClientCreateCcPlaceholderIntentResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCcSnapshotInput = {
    comment?: InputMaybe<Scalars["String"]["input"]>;
    libraryVersionId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
};

export type GqlClientCreateCcSnapshotOutput = {
    __typename?: "CreateCcSnapshotOutput";
    editorUpdateErrors: Array<Scalars["String"]["output"]>;
    result: GqlClientCreateCcSnapshotResult;
    snapshot?: Maybe<GqlClientCcLibraryVersion>;
    sourceLibraryVersion?: Maybe<GqlClientCcLibraryVersion>;
};

export enum GqlClientCreateCcSnapshotResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCcThemeInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    editorOrder?: InputMaybe<Scalars["Int"]["input"]>;
    isComingSoon: Scalars["Boolean"]["input"];
    libraryVersionId: Scalars["ID"]["input"];
    lifecycleStage: GqlClientAssetLifecycleStage;
    name: Scalars["String"]["input"];
    posterLocation?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateCcThemeOutput = {
    __typename?: "CreateCcThemeOutput";
    result: GqlClientCreateCcThemeResult;
    theme?: Maybe<GqlClientCcTheme>;
};

export enum GqlClientCreateCcThemeResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCcWireframeInput = {
    category: GqlClientCcWireframeCategory;
    comment?: InputMaybe<Scalars["String"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    frames: Array<GqlClientCcFrameInput>;
    libraryVersionId: Scalars["ID"]["input"];
    lifecycleStage: GqlClientAssetLifecycleStage;
    name: Scalars["String"]["input"];
    useToCreateDefaultScene: Scalars["Boolean"]["input"];
};

export type GqlClientCreateCcWireframeOutput = {
    __typename?: "CreateCcWireframeOutput";
    result: GqlClientCreateCcWireframeResult;
    wireframe?: Maybe<GqlClientCcWireframe>;
};

export enum GqlClientCreateCcWireframeResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateChannelInput = {
    name: Scalars["String"]["input"];
    platforms?: InputMaybe<Array<Scalars["String"]["input"]>>;
    status?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateChannelOutput = {
    __typename?: "CreateChannelOutput";
    channel?: Maybe<GqlClientChannel>;
    result: GqlClientCreateChannelResult;
};

export enum GqlClientCreateChannelResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateColor = {
    color: Scalars["String"]["input"];
    id: Scalars["ID"]["input"];
};

export type GqlClientCreateCreativeAndDraftInput = {
    creativeDDEValue: Scalars["String"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
    /** @deprecated(reason: "For migration only") */
    id?: InputMaybe<Scalars["ID"]["input"]>;
    name: Scalars["String"]["input"];
    programId: Scalars["ID"]["input"];
    storyId: Scalars["String"]["input"];
};

export type GqlClientCreateCreativeAndDraftOutput = {
    __typename?: "CreateCreativeAndDraftOutput";
    creative?: Maybe<GqlClientCreative>;
    result: GqlClientCreateCreativeAndDraftResult;
};

export enum GqlClientCreateCreativeAndDraftResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCreativeAssetInput = {
    creativeVersionId: Scalars["ID"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    status?: InputMaybe<GqlClientAssetStatus>;
    type: GqlClientAssetType;
};

export type GqlClientCreateCreativeAssetOutput = {
    __typename?: "CreateCreativeAssetOutput";
    creativeAsset?: Maybe<GqlClientCreativeAsset>;
    result: GqlClientCreateCreativeAssetResult;
};

export enum GqlClientCreateCreativeAssetResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCreativeAssetVersionInput = {
    creativeAssetId: Scalars["ID"]["input"];
    file: Scalars["Upload"]["input"];
    fileType: Scalars["String"]["input"];
    filename: Scalars["String"]["input"];
};

export type GqlClientCreateCreativeAssetVersionOutput = {
    __typename?: "CreateCreativeAssetVersionOutput";
    creativeAssetVersion?: Maybe<GqlClientCreativeAssetVersion>;
    result: GqlClientCreateCreativeAssetVersionResult;
};

export enum GqlClientCreateCreativeAssetVersionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCreativeAssetWithVersionInput = {
    creativeVersionId: Scalars["ID"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
    file: Scalars["Upload"]["input"];
    fileType: Scalars["String"]["input"];
    filename: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    status?: InputMaybe<GqlClientAssetStatus>;
};

export type GqlClientCreateCreativeAssetWithVersionOutput = {
    __typename?: "CreateCreativeAssetWithVersionOutput";
    creativeAsset?: Maybe<GqlClientCreativeAsset>;
    result: GqlClientCreateCreativeAssetWithVersionResult;
};

export enum GqlClientCreateCreativeAssetWithVersionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCreativeDataElementInput = {
    assignedToAllStories?: InputMaybe<Scalars["Boolean"]["input"]>;
    dataType?: InputMaybe<GqlClientDataElementType>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    pii?: InputMaybe<Scalars["Boolean"]["input"]>;
    storyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    useValueSet?: InputMaybe<Scalars["Boolean"]["input"]>;
    valueSet?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
};

export type GqlClientCreateCreativeDataElementOutput = {
    __typename?: "CreateCreativeDataElementOutput";
    creativeDataElement?: Maybe<GqlClientCreativeDataElement>;
    result: GqlClientCreateCreativeDataElementResult;
};

export enum GqlClientCreateCreativeDataElementResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCreativeDefinitionsInput = {
    allowedDurations: Array<GqlClientVideoDuration>;
    numSKU: Scalars["Int"]["input"];
    storyName: Scalars["String"]["input"];
    supportedRatios: Array<GqlClientRatio>;
    videoParams?: InputMaybe<Scalars["String"]["input"]>;
    videoQualityProfile: GqlClientVideoQuality;
};

export type GqlClientCreateCreativeNarrationRecordingVersionInput = {
    creativeNarrationRecordingId: Scalars["ID"]["input"];
    file: Scalars["Upload"]["input"];
    fileType: Scalars["String"]["input"];
    filename: Scalars["String"]["input"];
};

export type GqlClientCreateCreativeNarrationRecordingVersionOutput = {
    __typename?: "CreateCreativeNarrationRecordingVersionOutput";
    creativeNarrationRecordingVersion?: Maybe<GqlClientCreativeNarrationRecordingVersion>;
    result: GqlClientCreateCreativeNarrationRecordingVersionResult;
};

export enum GqlClientCreateCreativeNarrationRecordingVersionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCreativeVersionDraftInput = {
    creativeId: Scalars["ID"]["input"];
    /** @deprecated(reason: "For migration only") */
    id?: InputMaybe<Scalars["ID"]["input"]>;
    name: Scalars["String"]["input"];
    programSnapshotNumber: Scalars["Int"]["input"];
};

export type GqlClientCreateCreativeVersionDraftOutput = {
    __typename?: "CreateCreativeVersionDraftOutput";
    creativeVersion?: Maybe<GqlClientCreativeVersion>;
    result: GqlClientCreateCreativeVersionDraftResult;
};

export enum GqlClientCreateCreativeVersionDraftResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateCustomAnalyticFieldInput = {
    logic?: InputMaybe<Scalars["JSON"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    programId: Scalars["String"]["input"];
};

export type GqlClientCreateCustomAnalyticFieldOutput = {
    __typename?: "CreateCustomAnalyticFieldOutput";
    customAnalyticField?: Maybe<GqlClientCustomAnalyticField>;
    result: GqlClientCreateCustomAnalyticFieldResult;
};

export enum GqlClientCreateCustomAnalyticFieldResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateDataLibraryInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientCreateDataLibraryOutput = {
    __typename?: "CreateDataLibraryOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientCreateDataLibraryResult;
};

export enum GqlClientCreateDataLibraryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateDataTableInput = {
    data: GqlClientDataTableBody;
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientCreateDataTableOutput = {
    __typename?: "CreateDataTableOutput";
    dataTable?: Maybe<GqlClientDataTable>;
    result: GqlClientCreateDataTableResult;
};

export enum GqlClientCreateDataTableResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateDerivedDataElementInput = {
    dataType?: InputMaybe<GqlClientDataElementType>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    logic?: InputMaybe<Scalars["JSON"]["input"]>;
    name: Scalars["String"]["input"];
    pii?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GqlClientCreateDerivedDataElementOutput = {
    __typename?: "CreateDerivedDataElementOutput";
    derivedDataElement?: Maybe<GqlClientDerivedDataElement>;
    result: GqlClientCreateDerivedDataElementResult;
};

export enum GqlClientCreateDerivedDataElementResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateEditorAccountUserInput = {
    accountRoles: Array<GqlClientUserManagementRole>;
    email: Scalars["String"]["input"];
};

export type GqlClientCreateEditorAccountUserOutput = {
    result: GqlClientCreateEditorAccountUserResult;
};

export type GqlClientCreateEditorAccountUserOutputRefusal = GqlClientCreateEditorAccountUserOutput & {
    __typename?: "CreateEditorAccountUserOutputRefusal";
    result: GqlClientCreateEditorAccountUserResult;
};

export type GqlClientCreateEditorAccountUserOutputSuccess = GqlClientCreateEditorAccountUserOutput & {
    __typename?: "CreateEditorAccountUserOutputSuccess";
    accountUser: GqlClientAccountUser;
    result: GqlClientCreateEditorAccountUserResult;
};

export enum GqlClientCreateEditorAccountUserResult {
    ACCOUNT_LIMIT_REACHED_REFUSAL = "ACCOUNT_LIMIT_REACHED_REFUSAL",
    INVALID_EMAIL_REFUSAL = "INVALID_EMAIL_REFUSAL",
    INVALID_ROLE_REFUSAL = "INVALID_ROLE_REFUSAL",
    NEW_SUNDAYSKY_USER_REFUSAL = "NEW_SUNDAYSKY_USER_REFUSAL",
    SUCCESS = "SUCCESS",
    USER_EXIST_REFUSAL = "USER_EXIST_REFUSAL"
}

export type GqlClientCreateEditorAssetFolderInput = {
    name: Scalars["String"]["input"];
    parentId: Scalars["ID"]["input"];
};

export type GqlClientCreateEditorAssetFolderOutput = {
    result: GqlClientCreateEditorAssetFolderResult;
};

export type GqlClientCreateEditorAssetFolderOutputAlreadyExists = GqlClientCreateEditorAssetFolderOutput & {
    __typename?: "CreateEditorAssetFolderOutputAlreadyExists";
    result: GqlClientCreateEditorAssetFolderResult;
};

export type GqlClientCreateEditorAssetFolderOutputSuccess = GqlClientCreateEditorAssetFolderOutput & {
    __typename?: "CreateEditorAssetFolderOutputSuccess";
    editorAssetLibraryVersion: GqlClientEditorAssetLibraryVersion;
    folder: GqlClientEditorAssetFolder;
    result: GqlClientCreateEditorAssetFolderResult;
};

export enum GqlClientCreateEditorAssetFolderResult {
    ALREADY_EXISTS = "ALREADY_EXISTS",
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateEditorAvatarInput = {
    avatarId: Scalars["ID"]["input"];
    avatarLibrary: Scalars["String"]["input"];
    frameId: Scalars["ID"]["input"];
    height: Scalars["Float"]["input"];
    left: Scalars["Float"]["input"];
    sceneId: Scalars["ID"]["input"];
    top: Scalars["Float"]["input"];
    width: Scalars["Float"]["input"];
};

export type GqlClientCreateEditorAvatarOutput = {
    __typename?: "CreateEditorAvatarOutput";
    editorPlaceholder?: Maybe<GqlClientEditorPlaceholder>;
    result: GqlClientCreateEditorAvatarResult;
};

export enum GqlClientCreateEditorAvatarResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateEditorFlexiblePlaceholderInput = {
    ccPlaceholderId: Scalars["ID"]["input"];
    sceneId: Scalars["ID"]["input"];
};

export type GqlClientCreateEditorFlexiblePlaceholderOutput = {
    __typename?: "CreateEditorFlexiblePlaceholderOutput";
    editorPlaceholder?: Maybe<GqlClientEditorPlaceholder>;
    result: GqlClientCreateEditorFlexiblePlaceholderResult;
};

export enum GqlClientCreateEditorFlexiblePlaceholderResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateEditorFlexiblePlaceholderWithDataInput = {
    editorFlexiblePlaceholderData: Scalars["JSON"]["input"];
};

export type GqlClientCreateEditorFlexiblePlaceholderWithDataOutput = {
    __typename?: "CreateEditorFlexiblePlaceholderWithDataOutput";
    editorPlaceholder: GqlClientEditorPlaceholder;
    result: GqlClientCreateEditorFlexiblePlaceholderWithDataResult;
};

export enum GqlClientCreateEditorFlexiblePlaceholderWithDataResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateEditorLifecycleEntryInput = {
    lifecycleStage: GqlClientLifecycleStage;
    programId: Scalars["ID"]["input"];
    snapshotNumber: Scalars["Int"]["input"];
};

export type GqlClientCreateEditorLifecycleEntryOutput = {
    __typename?: "CreateEditorLifecycleEntryOutput";
    lifecycleHistoryEntry?: Maybe<GqlClientLifecycleHistoryEntry>;
    result: GqlClientCreateEditorLifecycleEntryResult;
};

export enum GqlClientCreateEditorLifecycleEntryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateEditorOverlayPlaceholderInput = {
    height: Scalars["Float"]["input"];
    left: Scalars["Float"]["input"];
    parentPlaceholderId: Scalars["ID"]["input"];
    placeholderType: GqlClientEditorOverlayPlaceholderType;
    top: Scalars["Float"]["input"];
    width: Scalars["Float"]["input"];
};

export type GqlClientCreateEditorOverlayPlaceholderOutput = {
    __typename?: "CreateEditorOverlayPlaceholderOutput";
    editorPlaceholder?: Maybe<GqlClientEditorPlaceholder>;
    result: GqlClientCreateEditorOverlayPlaceholderResult;
};

export enum GqlClientCreateEditorOverlayPlaceholderResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateEditorProgramFromStoryTemplateInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    sourceStoryTemplateProgramVersionId: Scalars["String"]["input"];
};

export type GqlClientCreateEditorProgramFromStoryTemplateOutput = {
    __typename?: "CreateEditorProgramFromStoryTemplateOutput";
    editorProgram?: Maybe<GqlClientEditorProgram>;
    result: GqlClientCreateEditorProgramFromStoryTemplateResult;
};

export enum GqlClientCreateEditorProgramFromStoryTemplateResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateEditorProgramInput = {
    accountCcLibraryId: Scalars["ID"]["input"];
    aspectRatio?: InputMaybe<GqlClientEnumAspectRatio>;
    name: Scalars["String"]["input"];
    parentFolderId?: InputMaybe<Scalars["String"]["input"]>;
    type: GqlClientEditorProgramType;
};

export type GqlClientCreateEditorProgramOutput = {
    __typename?: "CreateEditorProgramOutput";
    editorProgram?: Maybe<GqlClientEditorProgram>;
    result: GqlClientCreateEditorProgramResult;
};

export enum GqlClientCreateEditorProgramResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateEditorProgramSnapshotInput = {
    editorProgramVersionId: Scalars["ID"]["input"];
    snapshotComment?: InputMaybe<Scalars["String"]["input"]>;
    snapshotName?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateEditorProgramSnapshotOutput = {
    __typename?: "CreateEditorProgramSnapshotOutput";
    result: GqlClientCreateEditorProgramSnapshotResult;
};

export enum GqlClientCreateEditorProgramSnapshotResult {
    PARTIAL_SUCCESS = "PARTIAL_SUCCESS",
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateEditorSceneInput = {
    ccAnimatedWireframeId: Scalars["ID"]["input"];
    colorCombination: GqlClientEditorSceneColorCombination;
};

export type GqlClientCreateEditorScenesInput = {
    editorProgramVersionId: Scalars["ID"]["input"];
    editorScenes: Array<GqlClientCreateEditorSceneInput>;
    lineupIndex: Scalars["Int"]["input"];
};

export type GqlClientCreateEditorScenesOutput = {
    __typename?: "CreateEditorScenesOutput";
    editorProgramVersion: GqlClientEditorProgramVersion;
    editorScenes: Array<GqlClientEditorScene>;
    result: GqlClientCreateEditorScenesResult;
};

export enum GqlClientCreateEditorScenesResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateEditorViewerProfileInput = {
    content: Scalars["JSON"]["input"];
    name: Scalars["String"]["input"];
    program_id: Scalars["String"]["input"];
};

export type GqlClientCreateEditorViewerProfileOutput = {
    __typename?: "CreateEditorViewerProfileOutput";
    result: GqlClientCreateEditorViewerProfileResult;
    viewerProfile?: Maybe<GqlClientViewerProfile>;
};

export enum GqlClientCreateEditorViewerProfileResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateEditorViewerProfileWithDataInput = {
    content: Scalars["JSON"]["input"];
    name: Scalars["String"]["input"];
    viewerProfileId: Scalars["ID"]["input"];
};

export type GqlClientCreateEditorViewerProfileWithDataOutput = {
    __typename?: "CreateEditorViewerProfileWithDataOutput";
    result: GqlClientCreateEditorViewerProfileWithDataResult;
    viewerProfile?: Maybe<GqlClientViewerProfile>;
};

export enum GqlClientCreateEditorViewerProfileWithDataResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateExperimentInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    enabled: Scalars["Boolean"]["input"];
    endTime?: InputMaybe<Scalars["Float"]["input"]>;
    experimentComponent: Scalars["String"]["input"];
    experimentName: Scalars["String"]["input"];
    experimentType: Scalars["String"]["input"];
    metric: Scalars["String"]["input"];
    programId: Scalars["ID"]["input"];
    startTime: Scalars["Float"]["input"];
    variants: Array<GqlClientCreateExperimentVariantInput>;
};

export type GqlClientCreateExperimentOutput = {
    __typename?: "CreateExperimentOutput";
    program?: Maybe<GqlClientProgram>;
    result: GqlClientCreateExperimentResult;
};

export enum GqlClientCreateExperimentResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateExperimentVariantInput = {
    fraction: Scalars["Float"]["input"];
    variantId: Scalars["String"]["input"];
    variantName: Scalars["String"]["input"];
};

export type GqlClientCreateFeedDataElementInput = {
    dataType?: InputMaybe<GqlClientDataElementType>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    pii?: InputMaybe<Scalars["Boolean"]["input"]>;
    source?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<GqlClientFeedDataElementStatus>;
    useValueSet?: InputMaybe<Scalars["Boolean"]["input"]>;
    valueSet?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
};

export type GqlClientCreateFeedDataElementOutput = {
    __typename?: "CreateFeedDataElementOutput";
    feedDataElement?: Maybe<GqlClientFeedDataElement>;
    result: GqlClientCreateFeedDataElementResult;
};

export enum GqlClientCreateFeedDataElementResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateFlightInput = {
    CPCV?: InputMaybe<Scalars["Float"]["input"]>;
    CPM?: InputMaybe<Scalars["Float"]["input"]>;
    completedViews?: InputMaybe<Scalars["Float"]["input"]>;
    endDate: Scalars["String"]["input"];
    impressions?: InputMaybe<Scalars["Float"]["input"]>;
    insertionOrder: Scalars["String"]["input"];
    margin: Scalars["Float"]["input"];
    startDate: Scalars["String"]["input"];
};

export type GqlClientCreateGoalWithKpIsInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    newKpis?: InputMaybe<Array<GqlClientCreateGoalWithKpIsNewKpiInput>>;
    priority?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GqlClientCreateGoalWithKpIsNewKpiInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    objective?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateGoalWithKpIsOutput = {
    __typename?: "CreateGoalWithKPIsOutput";
    goal?: Maybe<GqlClientGoal>;
    result: GqlClientCreateGoalWithKpIsResult;
};

export enum GqlClientCreateGoalWithKpIsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateMasterAnimationGuidelineInput = {
    name: Scalars["String"]["input"];
    programVersionId: Scalars["ID"]["input"];
    type: GqlClientAnimationGuidelineType;
};

export type GqlClientCreateMasterAnimationGuidelineOutput = {
    __typename?: "CreateMasterAnimationGuidelineOutput";
    masterAnimationGuideline?: Maybe<GqlClientMasterAnimationGuideline>;
    result: GqlClientCreateMasterAnimationGuidelineResult;
};

export enum GqlClientCreateMasterAnimationGuidelineResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateNarrationInput = {
    dimensionData?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
    mergeData?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
    representativeKey?: InputMaybe<Scalars["String"]["input"]>;
    scenePartId: Scalars["ID"]["input"];
    supportsCreativeDataElementOverride?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GqlClientCreateNarrationOutput = {
    __typename?: "CreateNarrationOutput";
    narration?: Maybe<GqlClientNarration>;
    result: GqlClientCreateNarrationResult;
};

export type GqlClientCreateNarrationRecordingVersionInput = {
    file: Scalars["Upload"]["input"];
    fileType: Scalars["String"]["input"];
    narrationRecordingId: Scalars["ID"]["input"];
};

export type GqlClientCreateNarrationRecordingVersionOutput = {
    __typename?: "CreateNarrationRecordingVersionOutput";
    narrationRecordingVersion?: Maybe<GqlClientNarrationRecordingVersion>;
    result: GqlClientCreateNarrationRecordingVersionResult;
};

export enum GqlClientCreateNarrationRecordingVersionResult {
    SUCCESS = "SUCCESS"
}

export enum GqlClientCreateNarrationResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateNarratorInput = {
    carUrl: Scalars["String"]["input"];
    decisionTablesLoadTaskId?: InputMaybe<Scalars["String"]["input"]>;
    dtaasPrefix: Scalars["String"]["input"];
    legacyNarratorId: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    programVersionId: Scalars["ID"]["input"];
    status: GqlClientNarratorStatus;
};

export type GqlClientCreateNarratorOutput = {
    __typename?: "CreateNarratorOutput";
    narrator?: Maybe<GqlClientNarrator>;
    result: GqlClientCreateNarratorResult;
};

export enum GqlClientCreateNarratorResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateNextRecommendedVideoInput = {
    accountId: Scalars["String"]["input"];
    emails?: InputMaybe<Array<Scalars["String"]["input"]>>;
    sessionId: Scalars["String"]["input"];
};

export type GqlClientCreateNextRecommendedVideoOutput = {
    __typename?: "CreateNextRecommendedVideoOutput";
    pollingTaskCreationResponse: GqlClientPollingTaskCreationResponse;
    result: GqlClientCreateNextRecommendedVideoResult;
};

export enum GqlClientCreateNextRecommendedVideoResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateOrUpdateAccountDataFieldsInput = {
    accountDataLibraryVersionId: Scalars["ID"]["input"];
    newDynamicElements: Array<Scalars["JSON"]["input"]>;
    removedDynamicElements: Array<Scalars["ID"]["input"]>;
    updatedDynamicElements: Array<GqlClientUpdatedDynamicElement>;
};

export type GqlClientCreateOrUpdateAccountDataFieldsOutput = {
    __typename?: "CreateOrUpdateAccountDataFieldsOutput";
    accountDataLibraryVersion?: Maybe<GqlClientAccountDataLibraryVersion>;
    result: GqlClientCreateOrUpdateAccountDataFieldsResult;
};

export enum GqlClientCreateOrUpdateAccountDataFieldsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateOrUpdateEditorApplicationInput = {
    channelIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
    creativeStyleFitIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
    editorProgramVersionId: Scalars["ID"]["input"];
    flavorId?: InputMaybe<Scalars["ID"]["input"]>;
    industryId?: InputMaybe<Scalars["ID"]["input"]>;
    metadata?: InputMaybe<Scalars["JSON"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    parentId?: InputMaybe<Scalars["ID"]["input"]>;
    updated?: InputMaybe<Scalars["String"]["input"]>;
    useApplication: Scalars["Boolean"]["input"];
    useCaseIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type GqlClientCreateOrUpdateEditorApplicationOutput = {
    result: GqlClientCreateOrUpdateEditorApplicationResult;
};

export type GqlClientCreateOrUpdateEditorApplicationOutputConflict = GqlClientCreateOrUpdateEditorApplicationOutput & {
    __typename?: "CreateOrUpdateEditorApplicationOutputConflict";
    editorApplication?: Maybe<GqlClientEditorApplication>;
    result: GqlClientCreateOrUpdateEditorApplicationResult;
};

export type GqlClientCreateOrUpdateEditorApplicationOutputSuccess = GqlClientCreateOrUpdateEditorApplicationOutput & {
    __typename?: "CreateOrUpdateEditorApplicationOutputSuccess";
    editorApplication?: Maybe<GqlClientEditorApplication>;
    result: GqlClientCreateOrUpdateEditorApplicationResult;
};

export enum GqlClientCreateOrUpdateEditorApplicationResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateOrUpdateEmbedVideoCodeInput = {
    configuration: Scalars["JSON"]["input"];
    connectorType: Scalars["String"]["input"];
    embed_video_code_id?: InputMaybe<Scalars["String"]["input"]>;
    fsUrl?: InputMaybe<Scalars["String"]["input"]>;
    program_id: Scalars["String"]["input"];
};

export type GqlClientCreateOrUpdateEmbedVideoCodeOutput = {
    __typename?: "CreateOrUpdateEmbedVideoCodeOutput";
    embedVideoCode?: Maybe<GqlClientEmbedVideoCode>;
    result: GqlClientCreateOrUpdateEmbedVideoCodeResult;
};

export enum GqlClientCreateOrUpdateEmbedVideoCodeResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateOrUpdateLandingPageInput = {
    configuration: Scalars["JSON"]["input"];
    fsUrl?: InputMaybe<Scalars["String"]["input"]>;
    is_active: Scalars["Boolean"]["input"];
    landing_page_id?: InputMaybe<Scalars["String"]["input"]>;
    lifecycle_stage?: InputMaybe<GqlClientAssetLifecycleStage>;
    program_id: Scalars["String"]["input"];
};

export type GqlClientCreateOrUpdateLandingPageOutput = {
    __typename?: "CreateOrUpdateLandingPageOutput";
    landingPage?: Maybe<GqlClientLandingPage>;
    result: GqlClientCreateOrUpdateLandingPageResult;
};

export enum GqlClientCreateOrUpdateLandingPageResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreatePreSubmittedAvatarOutput = {
    __typename?: "CreatePreSubmittedAvatarOutput";
    avatarId: Scalars["ID"]["output"];
    result: GqlClientCreatePreSubmittedAvatarResult;
};

export enum GqlClientCreatePreSubmittedAvatarResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreatePresetDataElementValueInput = {
    dataElementId: Scalars["ID"]["input"];
    type?: InputMaybe<Scalars["String"]["input"]>;
    value?: InputMaybe<Scalars["String"]["input"]>;
    valueId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreatePresetInput = {
    dataElementValues: Array<GqlClientCreatePresetDataElementValueInput>;
    isCreative: Scalars["Boolean"]["input"];
    name: Scalars["String"]["input"];
    systemElementValues: Array<GqlClientCreatePresetSystemElementValueInput>;
};

export type GqlClientCreatePresetOutput = {
    __typename?: "CreatePresetOutput";
    preset?: Maybe<GqlClientPreset>;
    result: GqlClientCreatePresetResult;
};

export enum GqlClientCreatePresetResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreatePresetSystemElementValueInput = {
    systemElementId: Scalars["String"]["input"];
    type?: InputMaybe<Scalars["String"]["input"]>;
    value?: InputMaybe<Scalars["String"]["input"]>;
    valueId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateProductFilterInput = {
    attName?: InputMaybe<Scalars["String"]["input"]>;
    operator: GqlClientProductFilterOperator;
    type: GqlClientProductFilterFieldType;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateProductSelectorInput = {
    audienceId?: InputMaybe<Scalars["Int"]["input"]>;
    extraData?: InputMaybe<Scalars["String"]["input"]>;
    type?: InputMaybe<GqlClientProductSelectorFieldType>;
};

export type GqlClientCreateProgramAndBuilderDraftInput = {
    name: Scalars["String"]["input"];
    type: GqlClientProgramType;
};

export type GqlClientCreateProgramAndBuilderDraftOutput = {
    __typename?: "CreateProgramAndBuilderDraftOutput";
    program?: Maybe<GqlClientProgram>;
    result: GqlClientCreateProgramAndBuilderDraftResult;
};

export enum GqlClientCreateProgramAndBuilderDraftResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateProgramAndDraftInput = {
    audienceSize?: InputMaybe<Scalars["String"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    industry?: InputMaybe<Scalars["String"]["input"]>;
    legacyBuilderProgramId?: InputMaybe<Scalars["String"]["input"]>;
    legacyFrameworkProgramId?: InputMaybe<Scalars["String"]["input"]>;
    legacyOverviewProgramId?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    objective?: InputMaybe<Scalars["String"]["input"]>;
    useCase?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateProgramAndDraftOutput = {
    __typename?: "CreateProgramAndDraftOutput";
    program?: Maybe<GqlClientProgram>;
    result: GqlClientCreateProgramAndDraftResult;
};

export enum GqlClientCreateProgramAndDraftResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateProgramInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    legacyBuilderProgramId?: InputMaybe<Scalars["String"]["input"]>;
    legacyFrameworkProgramId?: InputMaybe<Scalars["String"]["input"]>;
    legacyOverviewProgramId?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
};

export type GqlClientCreateProgramOutput = {
    __typename?: "CreateProgramOutput";
    program?: Maybe<GqlClientProgram>;
    result: GqlClientCreateProgramResult;
};

export enum GqlClientCreateProgramResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateProgramReviewInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    programVersionId: Scalars["ID"]["input"];
    reviewers: Array<Scalars["String"]["input"]>;
    sceneName?: InputMaybe<Scalars["String"]["input"]>;
    viewerProfileName?: InputMaybe<Scalars["String"]["input"]>;
    viewerProfiles: Array<Scalars["JSON"]["input"]>;
};

export type GqlClientCreateProgramReviewOutput = {
    __typename?: "CreateProgramReviewOutput";
    programReview?: Maybe<GqlClientProgramReview>;
    result: GqlClientCreateProgramReviewResult;
};

export enum GqlClientCreateProgramReviewResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateProgramVersionDraftFromSnapshotInput = {
    sourceId: Scalars["ID"]["input"];
};

export type GqlClientCreateProgramVersionDraftFromSnapshotOutput = {
    __typename?: "CreateProgramVersionDraftFromSnapshotOutput";
    result: GqlClientCreateProgramVersionDraftFromSnapshotResult;
    targetProgramVersion?: Maybe<GqlClientProgramVersion>;
};

export enum GqlClientCreateProgramVersionDraftFromSnapshotResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateProgramVersionDraftInput = {
    audienceSize?: InputMaybe<Scalars["String"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    industry?: InputMaybe<Scalars["String"]["input"]>;
    isBuilder?: InputMaybe<Scalars["Boolean"]["input"]>;
    name: Scalars["String"]["input"];
    objective?: InputMaybe<Scalars["String"]["input"]>;
    type?: InputMaybe<GqlClientProgramType>;
    useCase?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateProgramVersionDraftOutput = {
    __typename?: "CreateProgramVersionDraftOutput";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientCreateProgramVersionDraftResult;
};

export enum GqlClientCreateProgramVersionDraftResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateProgramVersionSnapshotInput = {
    snapshotComment?: InputMaybe<Scalars["String"]["input"]>;
    snapshotName: Scalars["String"]["input"];
    snapshotNumber: Scalars["Int"]["input"];
    snapshotValidationData?: InputMaybe<Scalars["JSON"]["input"]>;
    sourceId: Scalars["ID"]["input"];
};

export type GqlClientCreateProgramVersionSnapshotOutput = {
    __typename?: "CreateProgramVersionSnapshotOutput";
    result: GqlClientCreateProgramVersionSnapshotResult;
    sourceProgramVersion?: Maybe<GqlClientProgramVersion>;
    targetProgramVersion?: Maybe<GqlClientProgramVersion>;
};

export enum GqlClientCreateProgramVersionSnapshotResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreatePronunciationDefinitionInput = {
    pronunciationLibraryVersionId: Scalars["ID"]["input"];
    source: Scalars["String"]["input"];
    target: Scalars["String"]["input"];
};

export type GqlClientCreatePronunciationDefinitionOutput = {
    __typename?: "CreatePronunciationDefinitionOutput";
    pronunciationDefinition?: Maybe<GqlClientAccountPronunciationDefinition>;
    result: GqlClientCreatePronunciationDefinitionResult;
};

export enum GqlClientCreatePronunciationDefinitionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreatePronunciationDefinitionWithDataInput = {
    pronunciationDefinitionId: Scalars["ID"]["input"];
    source: Scalars["String"]["input"];
    target: Scalars["String"]["input"];
};

export type GqlClientCreatePronunciationDefinitionWithDataOutput = {
    __typename?: "CreatePronunciationDefinitionWithDataOutput";
    pronunciationDefinition?: Maybe<GqlClientAccountPronunciationDefinition>;
    result: GqlClientCreatePronunciationDefinitionWithDataResult;
};

export enum GqlClientCreatePronunciationDefinitionWithDataResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateRfrVersionInput = {
    RFR: Scalars["Int"]["input"];
    narrationRecordingsToUpsert: Array<GqlClientNarrationRecordingToUpsert>;
    narrationUpdates: Array<GqlClientNarrationUpdates>;
    programVersionId: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientCreateRfrVersionOutput = {
    result: GqlClientCreateRfrVersionResult;
};

export type GqlClientCreateRfrVersionOutputConflict = GqlClientCreateRfrVersionOutput & {
    __typename?: "CreateRFRVersionOutputConflict";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientCreateRfrVersionResult;
};

export type GqlClientCreateRfrVersionOutputSuccess = GqlClientCreateRfrVersionOutput & {
    __typename?: "CreateRFRVersionOutputSuccess";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientCreateRfrVersionResult;
};

export enum GqlClientCreateRfrVersionResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateRecordingDocInput = {
    programName: Scalars["String"]["input"];
    programVersionId: Scalars["ID"]["input"];
    recordingAssets: Array<Scalars["JSON"]["input"]>;
};

export type GqlClientCreateRecordingDocOutput = {
    __typename?: "CreateRecordingDocOutput";
    recordingDoc?: Maybe<GqlClientRecordingDocEntry>;
    result: GqlClientCreateRecordingDocResult;
};

export enum GqlClientCreateRecordingDocResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateReportingDefinitionsInput = {
    attributeType: Scalars["String"]["input"];
    fieldValue?: InputMaybe<Scalars["String"]["input"]>;
    tagName?: InputMaybe<Scalars["String"]["input"]>;
    targetField: Scalars["String"]["input"];
};

export type GqlClientCreateSceneFromPromptInput = {
    editorProgramVersionId: Scalars["ID"]["input"];
    lineupIndex: Scalars["Int"]["input"];
    prompt: Scalars["String"]["input"];
};

export type GqlClientCreateSceneFromPromptOutput = {
    __typename?: "CreateSceneFromPromptOutput";
    result: GqlClientCreateSceneFromPromptResult;
};

export enum GqlClientCreateSceneFromPromptResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateScenePartInput = {
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
    sceneId: Scalars["ID"]["input"];
};

export type GqlClientCreateScenePartOutput = {
    __typename?: "CreateScenePartOutput";
    result: GqlClientCreateScenePartResult;
    scenePart?: Maybe<GqlClientScenePart>;
};

export enum GqlClientCreateScenePartResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsInput = {
    dataElementIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    kpiIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    name: Scalars["String"]["input"];
    newAudiencePersonalizations?: InputMaybe<Array<GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewAudiencePersonalizationInput>>;
    newChannelPersonalizations?: InputMaybe<Array<GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewChannelPersonalizationInput>>;
    newFeedDataElements?: InputMaybe<Array<GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewFeedDataElementInput>>;
    newTouchpointPersonalizations?: InputMaybe<Array<GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewTouchpointPersonalizationInput>>;
};

export type GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewAudiencePersonalizationInput = {
    audienceId: Scalars["ID"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
    newAudiencePersonalizationValues: Array<GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewAudiencePersonalizationValueInput>;
    type: Array<Scalars["String"]["input"]>;
};

export type GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewAudiencePersonalizationValueInput = {
    audienceValueId: Scalars["ID"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewChannelPersonalizationInput = {
    channelId: Scalars["ID"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
    type: Array<Scalars["String"]["input"]>;
};

export type GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewFeedDataElementInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    source?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<GqlClientFeedDataElementStatus>;
};

export type GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewTouchpointPersonalizationInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    touchpointId: Scalars["ID"]["input"];
    type: Array<Scalars["String"]["input"]>;
};

export type GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsOutput = {
    __typename?: "CreateSceneWithKPIsPersonalizationsAndDataElementsOutput";
    result: GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsResult;
    scene?: Maybe<GqlClientScene>;
};

export enum GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateSceneWithScenePartInput = {
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    programVersionId: Scalars["ID"]["input"];
    scenePartLegacyId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientCreateSceneWithScenePartOutput = {
    __typename?: "CreateSceneWithScenePartOutput";
    result: GqlClientCreateSceneWithScenePartResult;
    scene?: Maybe<GqlClientScene>;
};

export enum GqlClientCreateSceneWithScenePartResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateShareHistoryRecordInput = {
    programVersionId: Scalars["ID"]["input"];
    shareType: GqlClientProgramShareType;
};

export type GqlClientCreateShareHistoryRecordOutput = {
    __typename?: "CreateShareHistoryRecordOutput";
    result?: Maybe<GqlClientCreateShareHistoryRecordResult>;
    shareHistoryRecord: GqlClientProgramShareHistory;
};

export enum GqlClientCreateShareHistoryRecordResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateStoryInitialDecisionPoint = {
    localId: Scalars["String"]["input"];
    logic: Scalars["JSON"]["input"];
};

export type GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsInput = {
    activateCaching?: InputMaybe<Scalars["Boolean"]["input"]>;
    backgroundAssetType: GqlClientBackgroundAssetType;
    backgroundAudioLogic: Scalars["JSON"]["input"];
    backgroundImageLogic: Scalars["JSON"]["input"];
    backgroundVideoLogic: Scalars["JSON"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
    displaySceneOrder: Array<Scalars["String"]["input"]>;
    initialDecisionPoint: GqlClientCreateStoryInitialDecisionPoint;
    name: Scalars["String"]["input"];
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsOutput = {
    __typename?: "CreateStoryWithDecisionPointAndBackgroundAssetsOutput";
    result: GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsResult;
    story?: Maybe<GqlClientStory>;
};

export enum GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateStoryWithReachAndScenesInput = {
    channelIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    sceneIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    touchpointIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type GqlClientCreateStoryWithReachAndScenesOutput = {
    __typename?: "CreateStoryWithReachAndScenesOutput";
    result: GqlClientCreateStoryWithReachAndScenesResult;
    story?: Maybe<GqlClientStory>;
};

export enum GqlClientCreateStoryWithReachAndScenesResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateSurveyInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    externalId: Scalars["String"]["input"];
    programId: Scalars["ID"]["input"];
    questions?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type GqlClientCreateSurveyOutput = {
    __typename?: "CreateSurveyOutput";
    result: GqlClientCreateSurveyResult;
    survey?: Maybe<GqlClientSurvey>;
};

export enum GqlClientCreateSurveyResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreateTextStyle = {
    accountFontId?: InputMaybe<Scalars["ID"]["input"]>;
    ccFontId?: InputMaybe<Scalars["ID"]["input"]>;
    fontSource?: InputMaybe<GqlClientEditorAssetSource>;
    id: Scalars["ID"]["input"];
    letterCase?: InputMaybe<GqlClientTextStyleLetterCase>;
    letterSpacing?: InputMaybe<Scalars["Int"]["input"]>;
    lineSpacing?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GqlClientCreateTouchpointInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
};

export type GqlClientCreateTouchpointOutput = {
    __typename?: "CreateTouchpointOutput";
    result: GqlClientCreateTouchpointResult;
    touchpoint?: Maybe<GqlClientTouchpoint>;
};

export enum GqlClientCreateTouchpointResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientCreative = {
    __typename?: "Creative";
    accountId: Scalars["ID"]["output"];
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    creativeLifecycleHistory: Array<GqlClientCreativeLifecycleHistoryEntry>;
    creativeVersion?: Maybe<GqlClientCreativeVersion>;
    creativeVersionDraft?: Maybe<GqlClientCreativeVersion>;
    creativeVersions: Array<GqlClientCreativeVersion>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    legacyBuilderCreativeId?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    program: GqlClientProgram;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientCreativeCreativeVersionArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientCreativeAsset = {
    __typename?: "CreativeAsset";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    creativeAssetActiveVersion: GqlClientCreativeAssetVersion;
    creativeAssetVersion?: Maybe<GqlClientCreativeAssetVersion>;
    creativeAssetVersions: Array<GqlClientCreativeAssetVersion>;
    creativeVersion: GqlClientCreativeVersion;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    status?: Maybe<GqlClientAssetStatus>;
    type: GqlClientAssetType;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientCreativeAssetCreativeAssetVersionArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientCreativeAssetVersion = {
    __typename?: "CreativeAssetVersion";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    creativeAsset: GqlClientCreativeAsset;
    filename: Scalars["String"]["output"];
    format: Scalars["String"]["output"];
    height?: Maybe<Scalars["Int"]["output"]>;
    id: Scalars["ID"]["output"];
    location: Scalars["String"]["output"];
    mediaSubType: Scalars["String"]["output"];
    mediaType: Scalars["String"]["output"];
    size: Scalars["Int"]["output"];
    thumbnailLocation?: Maybe<Scalars["String"]["output"]>;
    thumbnailUrl?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    url: Scalars["String"]["output"];
    width?: Maybe<Scalars["Int"]["output"]>;
};

export type GqlClientCreativeDataElement = GqlClientGenericDataElement & {
    __typename?: "CreativeDataElement";
    accountId: Scalars["ID"]["output"];
    assignedToAllStories?: Maybe<Scalars["Boolean"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    dataType?: Maybe<GqlClientDataElementType>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    pii?: Maybe<Scalars["Boolean"]["output"]>;
    programVersion: GqlClientProgramVersion;
    stories: Array<GqlClientStory>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    useValueSet?: Maybe<Scalars["Boolean"]["output"]>;
    valueSet: Array<Scalars["JSON"]["output"]>;
};

export type GqlClientCreativeDefinitions = {
    __typename?: "CreativeDefinitions";
    allowedDurations: Array<GqlClientVideoDuration>;
    id: Scalars["ID"]["output"];
    numSKU: Scalars["Int"]["output"];
    storyName: Scalars["String"]["output"];
    supportedRatios: Array<GqlClientRatio>;
    videoParams: Scalars["String"]["output"];
    videoQualityProfile: GqlClientVideoQuality;
};

export type GqlClientCreativeLifecycleHistoryEntry = {
    __typename?: "CreativeLifecycleHistoryEntry";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    creative: GqlClientCreative;
    id: Scalars["ID"]["output"];
    lifecycleStage?: Maybe<GqlClientLifecycleStage>;
    snapshotNumber?: Maybe<Scalars["Int"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientCreativeNarrationRecording = {
    __typename?: "CreativeNarrationRecording";
    accountId: Scalars["ID"]["output"];
    cdeId: Scalars["String"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    creativeNarrationRecordingActiveVersion?: Maybe<GqlClientCreativeNarrationRecordingVersion>;
    creativeNarrationRecordingVersion?: Maybe<GqlClientCreativeNarrationRecordingVersion>;
    creativeNarrationRecordingVersions: Array<GqlClientCreativeNarrationRecordingVersion>;
    creativeVersion: GqlClientCreativeVersion;
    filename?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    mockNarrationUrl: Scalars["String"]["output"];
    narrationText: Scalars["String"]["output"];
    rfr: Scalars["Int"]["output"];
    serial: Scalars["Int"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientCreativeNarrationRecordingCreativeNarrationRecordingVersionArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientCreativeNarrationRecordingVersion = {
    __typename?: "CreativeNarrationRecordingVersion";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    creativeNarrationRecording: GqlClientCreativeNarrationRecording;
    errorReason?: Maybe<GqlClientNarrationRecordingErrorReason>;
    format: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    location: Scalars["String"]["output"];
    mediaSubType: Scalars["String"]["output"];
    mediaType: Scalars["String"]["output"];
    size: Scalars["Int"]["output"];
    status?: Maybe<GqlClientNarrationRecordingStatus>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    url: Scalars["String"]["output"];
};

export type GqlClientCreativeVersion = {
    __typename?: "CreativeVersion";
    accountId: Scalars["ID"]["output"];
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    creative: GqlClientCreative;
    creativeAsset?: Maybe<GqlClientCreativeAsset>;
    creativeAssets: Array<GqlClientCreativeAsset>;
    creativeDDEValue?: Maybe<Scalars["String"]["output"]>;
    creativeData?: Maybe<Scalars["JSON"]["output"]>;
    creativeNarrationRecording?: Maybe<GqlClientCreativeNarrationRecording>;
    creativeNarrationRecordings: Array<GqlClientCreativeNarrationRecording>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    isDraft: Scalars["Boolean"]["output"];
    name: Scalars["String"]["output"];
    programSnapshotNumber?: Maybe<Scalars["Int"]["output"]>;
    rfr: Scalars["Int"]["output"];
    snapshotNumber?: Maybe<Scalars["Int"]["output"]>;
    storyId?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    validationData?: Maybe<Scalars["JSON"]["output"]>;
};

export type GqlClientCreativeVersionCreativeAssetArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientCreativeVersionCreativeNarrationRecordingArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientCustomAnalyticField = {
    __typename?: "CustomAnalyticField";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    logic?: Maybe<Scalars["JSON"]["output"]>;
    name?: Maybe<Scalars["String"]["output"]>;
    program: GqlClientProgram;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientCustomAvatarCreationStartedPayload = {
    avatarId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientCustomAvatarCreationStartedPayloadData = {
    __typename?: "CustomAvatarCreationStartedPayloadData";
    avatar: GqlClientEditorAccountLevelAvatar;
};

export type GqlClientCustomAvatarCreationStartedPayloadError = GqlClientCustomAvatarCreationStartedPayload & {
    __typename?: "CustomAvatarCreationStartedPayloadError";
    avatarId: Scalars["ID"]["output"];
    error: Scalars["JSON"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientCustomAvatarCreationStartedPayloadKeepAlive = GqlClientCustomAvatarCreationStartedPayload & {
    __typename?: "CustomAvatarCreationStartedPayloadKeepAlive";
    avatarId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientCustomAvatarCreationStartedPayloadPending = GqlClientCustomAvatarCreationStartedPayload & {
    __typename?: "CustomAvatarCreationStartedPayloadPending";
    avatarId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    progress: Scalars["Float"]["output"];
    status: GqlClientStatus;
};

export type GqlClientCustomAvatarCreationStartedPayloadSuccess = GqlClientCustomAvatarCreationStartedPayload & {
    __typename?: "CustomAvatarCreationStartedPayloadSuccess";
    avatarId: Scalars["ID"]["output"];
    data: GqlClientCustomAvatarCreationStartedPayloadData;
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientCustomAvatarsCreditBalanceEmailInput = {
    accountName: Scalars["String"]["input"];
    creationRemaining: Scalars["Int"]["input"];
    generationMinutesRemaining: Scalars["Float"]["input"];
};

export type GqlClientCustomAvatarsCreditBalanceEmailOutput = {
    __typename?: "CustomAvatarsCreditBalanceEmailOutput";
    result: GqlClientCustomAvatarsCreditBalanceEmailResult;
};

export enum GqlClientCustomAvatarsCreditBalanceEmailResult {
    SUCCESS = "SUCCESS"
}

export enum GqlClientDataElementType {
    AUDIO = "audio",
    BOOLEAN = "boolean",
    COLOR = "color",
    DATETIME = "datetime",
    IMAGE = "image",
    NARRATION = "narration",
    NUMBER = "number",
    STRING = "string",
    URL = "url",
    VIDEO = "video"
}

export type GqlClientDataLibraryGenerateAllImportLinksInput = {
    dataLibraryId: Scalars["ID"]["input"];
    idFieldName: Scalars["String"]["input"];
    landingPageUrl: Scalars["String"]["input"];
};

export type GqlClientDataLibraryGenerateLinksFromSpecificImportsInput = {
    idFieldName: Scalars["String"]["input"];
    importIds: Array<Scalars["ID"]["input"]>;
    landingPageUrl: Scalars["String"]["input"];
    programId: Scalars["ID"]["input"];
};

export type GqlClientDataLibraryImportDataInput = {
    file: Scalars["Upload"]["input"];
    fileName: Scalars["String"]["input"];
    fileType: Scalars["String"]["input"];
    programId: Scalars["ID"]["input"];
    stage: GqlClientLifecycleStage;
};

export type GqlClientDataLibraryImportDataOutput = {
    __typename?: "DataLibraryImportDataOutput";
    dataLibraryId: Scalars["ID"]["output"];
    importDataId: Scalars["ID"]["output"];
    result: GqlClientDataLibraryImportDataResult;
};

export enum GqlClientDataLibraryImportDataResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDataLibraryImportDataUpdatedPayload = {
    dataLibraryId: Scalars["String"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientDataLibraryImportDataUpdatedPayloadData = {
    __typename?: "DataLibraryImportDataUpdatedPayloadData";
    numFailedUsers: Scalars["Int"]["output"];
    numSuccessfulUsers: Scalars["Int"]["output"];
};

export type GqlClientDataLibraryImportDataUpdatedPayloadError = GqlClientDataLibraryImportDataUpdatedPayload & {
    __typename?: "DataLibraryImportDataUpdatedPayloadError";
    dataLibraryId: Scalars["String"]["output"];
    error: Scalars["JSON"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientDataLibraryImportDataUpdatedPayloadKeepAlive = GqlClientDataLibraryImportDataUpdatedPayload & {
    __typename?: "DataLibraryImportDataUpdatedPayloadKeepAlive";
    dataLibraryId: Scalars["String"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientDataLibraryImportDataUpdatedPayloadPending = GqlClientDataLibraryImportDataUpdatedPayload & {
    __typename?: "DataLibraryImportDataUpdatedPayloadPending";
    dataLibraryId: Scalars["String"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientDataLibraryImportDataUpdatedPayloadSuccess = GqlClientDataLibraryImportDataUpdatedPayload & {
    __typename?: "DataLibraryImportDataUpdatedPayloadSuccess";
    data: GqlClientDataLibraryImportDataUpdatedPayloadData;
    dataLibraryId: Scalars["String"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientDataLibraryImportHistory = {
    __typename?: "DataLibraryImportHistory";
    dataLibraryId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    importStatusMetadataItems: Array<GqlClientDataLibraryImportHistoryItem>;
};

export type GqlClientDataLibraryImportHistoryItem = {
    __typename?: "DataLibraryImportHistoryItem";
    countFailures?: Maybe<Scalars["Int"]["output"]>;
    countSuccesses?: Maybe<Scalars["Int"]["output"]>;
    date: Scalars["String"]["output"];
    fileName: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    status: GqlClientImportTaskStatus;
    user: Scalars["String"]["output"];
};

export type GqlClientDataTable = {
    __typename?: "DataTable";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    downloadUrl?: Maybe<Scalars["String"]["output"]>;
    dtaasPrefix?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    legacyDataTableId?: Maybe<Scalars["String"]["output"]>;
    location?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    programVersion: GqlClientProgramVersion;
    schema?: Maybe<Scalars["JSON"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientDataTableBody = {
    data: Array<InputMaybe<Scalars["JSON"]["input"]>>;
    scheme: GqlClientDataTableScheme;
};

export type GqlClientDataTableScheme = {
    inputs: Array<InputMaybe<GqlClientDataTableSchemeInput>>;
    outputs: Array<InputMaybe<GqlClientDataTableSchemeOutput>>;
};

export type GqlClientDataTableSchemeInput = {
    displayName?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    type?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientDataTableSchemeOutput = {
    id: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    type?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientDecisionPoint = {
    __typename?: "DecisionPoint";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    logic: Scalars["JSON"]["output"];
    story: GqlClientStory;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientDeleteAccountCopilotBriefInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteAccountCopilotBriefOutput = {
    __typename?: "DeleteAccountCopilotBriefOutput";
    account?: Maybe<GqlClientAccount>;
    result?: Maybe<GqlClientDeleteAccountCopilotBriefResult>;
};

export enum GqlClientDeleteAccountCopilotBriefResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteAccountInvitationInput = {
    accountId: Scalars["String"]["input"];
};

export type GqlClientDeleteAccountInvitationOutput = {
    __typename?: "DeleteAccountInvitationOutput";
    loggedInEditorAccountUser?: Maybe<GqlClientEditorAccountUser>;
    result: GqlClientDeleteAccountInvitationResult;
};

export enum GqlClientDeleteAccountInvitationResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteAccountProgramFolderInput = {
    folderId: Scalars["ID"]["input"];
};

export type GqlClientDeleteAccountProgramFolderOutput = {
    __typename?: "DeleteAccountProgramFolderOutput";
    account: GqlClientAccount;
    result: GqlClientDeleteAccountProgramFolderResult;
};

export enum GqlClientDeleteAccountProgramFolderResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteAccountReportInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteAccountReportOutput = {
    __typename?: "DeleteAccountReportOutput";
    account?: Maybe<GqlClientAccount>;
    result: GqlClientDeleteAccountReportResult;
};

export enum GqlClientDeleteAccountReportResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteAudienceInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteAudienceOutput = {
    __typename?: "DeleteAudienceOutput";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientDeleteAudienceResult;
};

export enum GqlClientDeleteAudienceResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteBrandCollectionInput = {
    brandConfigurationCollectionId: Scalars["ID"]["input"];
};

export type GqlClientDeleteBrandCollectionOutput = {
    __typename?: "DeleteBrandCollectionOutput";
    brandConfigurationVersion?: Maybe<GqlClientBrandConfigurationVersion>;
    result: GqlClientDeleteBrandCollectionResult;
};

export enum GqlClientDeleteBrandCollectionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingInput = {
    sourceAnimatedWireframeId: Scalars["ID"]["input"];
    targetAspectRatio: GqlClientEnumAspectRatio;
    targetThemeId: Scalars["ID"]["input"];
};

export type GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingOutput = {
    __typename?: "DeleteCcAnimatedWireframeCrossAspectRatioMappingOutput";
    animatedWireframe?: Maybe<GqlClientCcAnimatedWireframe>;
    result: GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingResult;
};

export enum GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteCcAnimatedWireframeInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteCcAnimatedWireframeOutput = {
    __typename?: "DeleteCcAnimatedWireframeOutput";
    ccLibraryVersion?: Maybe<GqlClientCcLibraryVersion>;
    result: GqlClientDeleteCcAnimatedWireframeResult;
};

export enum GqlClientDeleteCcAnimatedWireframeResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteCcThemeInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteCcThemeOutput = {
    __typename?: "DeleteCcThemeOutput";
    ccLibraryVersion?: Maybe<GqlClientCcLibraryVersion>;
    result: GqlClientDeleteCcThemeResult;
};

export enum GqlClientDeleteCcThemeResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteCcWireframeInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteCcWireframeOutput = {
    __typename?: "DeleteCcWireframeOutput";
    ccLibraryVersion?: Maybe<GqlClientCcLibraryVersion>;
    result: GqlClientDeleteCcWireframeResult;
};

export enum GqlClientDeleteCcWireframeResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteChannelInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteChannelOutput = {
    __typename?: "DeleteChannelOutput";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientDeleteChannelResult;
};

export enum GqlClientDeleteChannelResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteCreativeAssetInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteCreativeAssetOutput = {
    __typename?: "DeleteCreativeAssetOutput";
    creativeVersion?: Maybe<GqlClientCreativeVersion>;
    result: GqlClientDeleteCreativeAssetResult;
};

export enum GqlClientDeleteCreativeAssetResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteCreativeVersionInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteCreativeVersionOutput = {
    __typename?: "DeleteCreativeVersionOutput";
    creative?: Maybe<GqlClientCreative>;
    result: GqlClientDeleteCreativeVersionResult;
};

export enum GqlClientDeleteCreativeVersionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteCreativesInput = {
    ids: Array<Scalars["ID"]["input"]>;
};

export type GqlClientDeleteCreativesOutput = {
    __typename?: "DeleteCreativesOutput";
    program?: Maybe<GqlClientProgram>;
    result: GqlClientDeleteCreativesResult;
};

export enum GqlClientDeleteCreativesResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteCustomAnalyticFieldInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteCustomAnalyticFieldOutput = {
    __typename?: "DeleteCustomAnalyticFieldOutput";
    program?: Maybe<GqlClientProgram>;
    result: GqlClientDeleteCustomAnalyticFieldResult;
};

export enum GqlClientDeleteCustomAnalyticFieldResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteDataElementInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteDataElementOutput = {
    result: GqlClientDeleteDataElementResult;
};

export type GqlClientDeleteDataElementOutputRefusal = GqlClientDeleteDataElementOutput & {
    __typename?: "DeleteDataElementOutputRefusal";
    result: GqlClientDeleteDataElementResult;
    usedInData?: Maybe<Scalars["JSON"]["output"]>;
};

export type GqlClientDeleteDataElementOutputSuccess = GqlClientDeleteDataElementOutput & {
    __typename?: "DeleteDataElementOutputSuccess";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientDeleteDataElementResult;
};

export enum GqlClientDeleteDataElementResult {
    SUCCESS = "SUCCESS",
    USED_IN_REFUSAL = "USED_IN_REFUSAL"
}

export type GqlClientDeleteDataLibraryInput = {
    accountDataLibraryVersionId: Scalars["ID"]["input"];
};

export type GqlClientDeleteDataLibraryOutput = {
    __typename?: "DeleteDataLibraryOutput";
    account: GqlClientAccount;
    result: GqlClientDeleteDataLibraryResult;
};

export enum GqlClientDeleteDataLibraryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteDataTableInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteDataTableOutput = {
    __typename?: "DeleteDataTableOutput";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientDeleteDataTableResult;
};

export enum GqlClientDeleteDataTableResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteEditorAccountUserInput = {
    email: Scalars["String"]["input"];
};

export type GqlClientDeleteEditorAccountUserOutput = {
    __typename?: "DeleteEditorAccountUserOutput";
    result: GqlClientDeleteEditorAccountUserResult;
};

export enum GqlClientDeleteEditorAccountUserResult {
    PRIVILEGES_REFUSAL = "PRIVILEGES_REFUSAL",
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteEditorAssetFolderInput = {
    folderId: Scalars["ID"]["input"];
};

export type GqlClientDeleteEditorAssetFolderOutput = {
    result: GqlClientDeleteEditorAssetFolderResult;
};

export type GqlClientDeleteEditorAssetFolderOutputFailure = GqlClientDeleteEditorAssetFolderOutput & {
    __typename?: "DeleteEditorAssetFolderOutputFailure";
    result: GqlClientDeleteEditorAssetFolderResult;
};

export type GqlClientDeleteEditorAssetFolderOutputSuccess = GqlClientDeleteEditorAssetFolderOutput & {
    __typename?: "DeleteEditorAssetFolderOutputSuccess";
    editorAssetLibraryVersion: GqlClientEditorAssetLibraryVersion;
    result: GqlClientDeleteEditorAssetFolderResult;
};

export enum GqlClientDeleteEditorAssetFolderResult {
    FAILURE = "FAILURE",
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteEditorAssetMediaInput = {
    id: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientDeleteEditorAssetMediaOutput = {
    result: GqlClientDeleteEditorAssetMediaResult;
};

export type GqlClientDeleteEditorAssetMediaOutputRefusal = GqlClientDeleteEditorAssetMediaOutput & {
    __typename?: "DeleteEditorAssetMediaOutputRefusal";
    result: GqlClientDeleteEditorAssetMediaResult;
};

export type GqlClientDeleteEditorAssetMediaOutputSuccess = GqlClientDeleteEditorAssetMediaOutput & {
    __typename?: "DeleteEditorAssetMediaOutputSuccess";
    editorAssetLibraryVersion: GqlClientEditorAssetLibraryVersion;
    result: GqlClientDeleteEditorAssetMediaResult;
};

export enum GqlClientDeleteEditorAssetMediaResult {
    SUCCESS = "SUCCESS",
    USED_IN_REFUSAL = "USED_IN_REFUSAL"
}

export type GqlClientDeleteEditorFlexiblePlaceholderInput = {
    placeholderId: Scalars["ID"]["input"];
};

export type GqlClientDeleteEditorFlexiblePlaceholderOutput = {
    __typename?: "DeleteEditorFlexiblePlaceholderOutput";
    editorScenes: Array<GqlClientEditorScene>;
    result: GqlClientDeleteEditorFlexiblePlaceholderResult;
};

export enum GqlClientDeleteEditorFlexiblePlaceholderResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteEditorProgramAllVersionsInput = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientDeleteEditorProgramAllVersionsOutput = {
    __typename?: "DeleteEditorProgramAllVersionsOutput";
    account?: Maybe<GqlClientAccount>;
    result: GqlClientDeleteEditorProgramAllVersionsResult;
};

export enum GqlClientDeleteEditorProgramAllVersionsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteEditorProgramInput = {
    deleteDataLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
    programId: Scalars["ID"]["input"];
};

export type GqlClientDeleteEditorProgramOutput = {
    __typename?: "DeleteEditorProgramOutput";
    account?: Maybe<GqlClientAccount>;
    result: GqlClientDeleteEditorProgramResult;
};

export enum GqlClientDeleteEditorProgramResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteEditorSceneInput = {
    sceneId: Scalars["ID"]["input"];
};

export type GqlClientDeleteEditorSceneOutput = {
    __typename?: "DeleteEditorSceneOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientDeleteEditorSceneResult;
};

export enum GqlClientDeleteEditorSceneResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteEditorViewerProfileInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteEditorViewerProfileOutput = {
    __typename?: "DeleteEditorViewerProfileOutput";
    editorProgram: GqlClientEditorProgram;
    result: GqlClientDeleteEditorViewerProfileResult;
};

export enum GqlClientDeleteEditorViewerProfileResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteFailedAvatarInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteFailedAvatarOutput = {
    __typename?: "DeleteFailedAvatarOutput";
    avatar: GqlClientEditorAccountLevelAvatar;
    result: GqlClientDeleteFailedAvatarResult;
};

export enum GqlClientDeleteFailedAvatarResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteGoalInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteGoalOutput = {
    __typename?: "DeleteGoalOutput";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientDeleteGoalResult;
};

export enum GqlClientDeleteGoalResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteMasterAnimationGuidelineInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteMasterAnimationGuidelineOutput = {
    __typename?: "DeleteMasterAnimationGuidelineOutput";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientDeleteMasterAnimationGuidelineResult;
};

export enum GqlClientDeleteMasterAnimationGuidelineResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteNarrationInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteNarrationOutput = {
    __typename?: "DeleteNarrationOutput";
    result: GqlClientDeleteNarrationResult;
    scenePart?: Maybe<GqlClientScenePart>;
};

export enum GqlClientDeleteNarrationResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeletePlaceholderGroupLogicInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeletePlaceholderGroupLogicOutput = {
    __typename?: "DeletePlaceholderGroupLogicOutput";
    result: GqlClientDeletePlaceholderGroupLogicResult;
    scene?: Maybe<GqlClientScene>;
};

export enum GqlClientDeletePlaceholderGroupLogicResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeletePreSubmittedAvatarInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeletePreSubmittedAvatarOutput = {
    __typename?: "DeletePreSubmittedAvatarOutput";
    result: GqlClientDeletePreSubmittedAvatarResult;
};

export enum GqlClientDeletePreSubmittedAvatarResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeletePresetInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeletePresetOutput = {
    __typename?: "DeletePresetOutput";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientDeletePresetResult;
};

export enum GqlClientDeletePresetResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeletePrioritizedListLogicInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeletePrioritizedListLogicOutput = {
    __typename?: "DeletePrioritizedListLogicOutput";
    result: GqlClientDeletePrioritizedListLogicResult;
    scene?: Maybe<GqlClientScene>;
};

export enum GqlClientDeletePrioritizedListLogicResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteProgramAndDraftInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteProgramAndDraftOutput = {
    __typename?: "DeleteProgramAndDraftOutput";
    result: GqlClientDeleteProgramAndDraftResult;
};

export enum GqlClientDeleteProgramAndDraftResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteProgramInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteProgramOutput = {
    __typename?: "DeleteProgramOutput";
    result: GqlClientDeleteProgramResult;
};

export enum GqlClientDeleteProgramResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteProgramVersionDraftInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteProgramVersionDraftOutput = {
    __typename?: "DeleteProgramVersionDraftOutput";
    program?: Maybe<GqlClientProgram>;
    result: GqlClientDeleteProgramVersionDraftResult;
};

export enum GqlClientDeleteProgramVersionDraftResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeletePronunciationDefinitionInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeletePronunciationDefinitionOutput = {
    __typename?: "DeletePronunciationDefinitionOutput";
    pronunciationLibraryVersion?: Maybe<GqlClientAccountPronunciationLibraryVersion>;
    result: GqlClientDeletePronunciationDefinitionResult;
};

export enum GqlClientDeletePronunciationDefinitionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteSceneEntityLogicInput = {
    id: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
};

export type GqlClientDeleteSceneEntityLogicOutput = {
    __typename?: "DeleteSceneEntityLogicOutput";
    result: GqlClientDeleteSceneEntityLogicResult;
    scene?: Maybe<GqlClientScene>;
};

export enum GqlClientDeleteSceneEntityLogicResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteSceneInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteSceneOutput = {
    __typename?: "DeleteSceneOutput";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientDeleteSceneResult;
};

export type GqlClientDeleteScenePartInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteScenePartOutput = {
    __typename?: "DeleteScenePartOutput";
    result: GqlClientDeleteScenePartResult;
    scene?: Maybe<GqlClientScene>;
};

export enum GqlClientDeleteScenePartResult {
    SUCCESS = "SUCCESS"
}

export enum GqlClientDeleteSceneResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteStoryInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteStoryOutput = {
    __typename?: "DeleteStoryOutput";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientDeleteStoryResult;
};

export enum GqlClientDeleteStoryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteSurveyInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteSurveyOutput = {
    __typename?: "DeleteSurveyOutput";
    program?: Maybe<GqlClientProgram>;
    result: GqlClientDeleteSurveyResult;
};

export enum GqlClientDeleteSurveyResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDeleteTouchpointInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientDeleteTouchpointOutput = {
    __typename?: "DeleteTouchpointOutput";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientDeleteTouchpointResult;
};

export enum GqlClientDeleteTouchpointResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDerivedDataElement = GqlClientGenericDataElement & {
    __typename?: "DerivedDataElement";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    dataType?: Maybe<GqlClientDataElementType>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    localId: Scalars["String"]["output"];
    logic?: Maybe<Scalars["JSON"]["output"]>;
    name: Scalars["String"]["output"];
    pii?: Maybe<Scalars["Boolean"]["output"]>;
    programVersion: GqlClientProgramVersion;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    valueSet?: Maybe<Array<Maybe<Scalars["JSON"]["output"]>>>;
};

export enum GqlClientDeviceMode {
    DESKTOP_MOBILE = "DESKTOP_MOBILE",
    TV = "TV"
}

export type GqlClientDimension = {
    __typename?: "Dimension";
    height?: Maybe<Scalars["Int"]["output"]>;
    width?: Maybe<Scalars["Int"]["output"]>;
};

export type GqlClientDisconnectTrayConnectorInput = {
    connectorType: Scalars["String"]["input"];
};

export type GqlClientDisconnectTrayConnectorOutput = {
    __typename?: "DisconnectTrayConnectorOutput";
    result: GqlClientDisconnectTrayConnectorResult;
};

export enum GqlClientDisconnectTrayConnectorResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientDuplicateCampaignSettingsInput = {
    duplicateName: Scalars["String"]["input"];
    id: Scalars["ID"]["input"];
};

export type GqlClientDuplicateEditorSceneInLineupInput = {
    sourceSceneId: Scalars["ID"]["input"];
    targetSceneLineupIndex: Scalars["Int"]["input"];
    targetSceneName: Scalars["String"]["input"];
};

export type GqlClientDuplicateEditorSceneInLineupOutput = {
    __typename?: "DuplicateEditorSceneInLineupOutput";
    editorScene?: Maybe<GqlClientEditorScene>;
    result: GqlClientDuplicateEditorSceneInLineupResult;
};

export enum GqlClientDuplicateEditorSceneInLineupResult {
    SUCCESS = "SUCCESS"
}

export enum GqlClientDynamoMigrationStatus {
    DONE = "done",
    NOTMIGRATED = "notMigrated",
    TESTING = "testing"
}

export type GqlClientEditEditorAssetMediaInput = {
    parentMediaId: Scalars["ID"]["input"];
    postEditingData?: InputMaybe<GqlClientPostMediaProcessingData>;
    presentedMediaName: Scalars["String"]["input"];
    processingData: GqlClientVideoProcessingData;
    programId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GqlClientEditEditorAssetMediaOutput = {
    __typename?: "EditEditorAssetMediaOutput";
    editorAssetMedia?: Maybe<GqlClientEditorAssetMedia>;
    editorPlaceholder?: Maybe<GqlClientEditorPlaceholder>;
    result: GqlClientEditEditorAssetMediaResult;
};

export enum GqlClientEditEditorAssetMediaResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientEditorAccountLevelAvatar = {
    __typename?: "EditorAccountLevelAvatar";
    accountId?: Maybe<Scalars["String"]["output"]>;
    avatarSystemName: Scalars["String"]["output"];
    closeUpCrop: GqlClientCcAvatarCrop;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    editorAssetLibraryVersion: GqlClientEditorAssetLibraryVersion;
    fullBodyCrop: GqlClientCcAvatarCrop;
    id: Scalars["ID"]["output"];
    language?: Maybe<Scalars["String"]["output"]>;
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage: GqlClientAssetLifecycleStage;
    localId: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    narrator?: Maybe<GqlClientEditorAccountLevelNarrator>;
    sampleLocation?: Maybe<Scalars["String"]["output"]>;
    sampleTranscript?: Maybe<Scalars["String"]["output"]>;
    sampleUrl?: Maybe<Scalars["String"]["output"]>;
    sampleVideoMetadata?: Maybe<Scalars["JSON"]["output"]>;
    thumbnailLocation?: Maybe<Scalars["String"]["output"]>;
    thumbnailUrl?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientEditorAccountLevelData = {
    __typename?: "EditorAccountLevelData";
    assetLibraryVersion?: Maybe<GqlClientEditorAssetLibraryVersion>;
    brandConfigurationVersion?: Maybe<GqlClientBrandConfigurationVersion>;
    id: Scalars["ID"]["output"];
};

export type GqlClientEditorAccountLevelNarrator = {
    __typename?: "EditorAccountLevelNarrator";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    editorAssetLibraryVersion: GqlClientEditorAssetLibraryVersion;
    id: Scalars["ID"]["output"];
    language?: Maybe<Scalars["String"]["output"]>;
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage: GqlClientAssetLifecycleStage;
    localId: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    narrationSystemMetadata?: Maybe<Scalars["JSON"]["output"]>;
    narrationSystemName?: Maybe<Scalars["String"]["output"]>;
    narrationSystemVoice?: Maybe<Scalars["String"]["output"]>;
    sampleLocation?: Maybe<Scalars["String"]["output"]>;
    sampleTranscript?: Maybe<Scalars["String"]["output"]>;
    sampleUrl?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientEditorAccountUser = {
    __typename?: "EditorAccountUser";
    accounts: Array<GqlClientAccountUser>;
    authService?: Maybe<GqlClientAuthService>;
    businessRole?: Maybe<GqlClientBusinessRole>;
    created?: Maybe<Scalars["String"]["output"]>;
    email: Scalars["String"]["output"];
    externalLibraries?: Maybe<Scalars["JSON"]["output"]>;
    firstName?: Maybe<Scalars["String"]["output"]>;
    hasMultipleAccounts?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    lastAccessedAccount?: Maybe<Scalars["String"]["output"]>;
    lastLogin?: Maybe<Scalars["String"]["output"]>;
    lastName?: Maybe<Scalars["String"]["output"]>;
    roles?: Maybe<Array<GqlClientUserRole>>;
    socialNetworks?: Maybe<GqlClientSocialNetworks>;
    storyTemplates?: Maybe<Scalars["JSON"]["output"]>;
    videosArchive?: Maybe<Scalars["JSON"]["output"]>;
    yourVideos?: Maybe<Scalars["JSON"]["output"]>;
};

export type GqlClientEditorApplication = {
    __typename?: "EditorApplication";
    accountId: Scalars["ID"]["output"];
    channels?: Maybe<Array<Maybe<GqlClientEditorChannel>>>;
    children?: Maybe<Array<Maybe<GqlClientEditorApplication>>>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    creativeStyleFits?: Maybe<Array<Maybe<GqlClientEditorCreativeStyleFit>>>;
    customThumbnailUrl?: Maybe<Scalars["String"]["output"]>;
    flavor?: Maybe<GqlClientApplicationFlavor>;
    id: Scalars["ID"]["output"];
    industry?: Maybe<GqlClientEditorIndustry>;
    isParent?: Maybe<Scalars["Boolean"]["output"]>;
    lifecycleStage: GqlClientAssetLifecycleStage;
    metadata?: Maybe<Scalars["JSON"]["output"]>;
    name?: Maybe<Scalars["String"]["output"]>;
    newStoryTemplate?: Maybe<Scalars["Boolean"]["output"]>;
    parent?: Maybe<GqlClientEditorApplication>;
    posterImageUrl?: Maybe<Scalars["String"]["output"]>;
    previewVideoUrl?: Maybe<Scalars["String"]["output"]>;
    programVersion?: Maybe<GqlClientEditorProgramVersion>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    useApplication: Scalars["Boolean"]["output"];
    useCases?: Maybe<Array<Maybe<GqlClientEditorUseCase>>>;
};

export type GqlClientEditorAssetEditedPayload = {
    accountId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    originalName: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientEditorAssetEditedPayloadData = {
    __typename?: "EditorAssetEditedPayloadData";
    editorAsset: GqlClientEditorAssetMedia;
    editorPlaceholder?: Maybe<GqlClientEditorPlaceholder>;
};

export type GqlClientEditorAssetEditedPayloadError = GqlClientEditorAssetEditedPayload & {
    __typename?: "EditorAssetEditedPayloadError";
    accountId: Scalars["ID"]["output"];
    error: Scalars["JSON"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    originalName: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientEditorAssetEditedPayloadKeepAlive = GqlClientEditorAssetEditedPayload & {
    __typename?: "EditorAssetEditedPayloadKeepAlive";
    accountId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    originalName: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientEditorAssetEditedPayloadPending = GqlClientEditorAssetEditedPayload & {
    __typename?: "EditorAssetEditedPayloadPending";
    accountId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    originalName: Scalars["String"]["output"];
    progress: Scalars["Float"]["output"];
    status: GqlClientStatus;
};

export type GqlClientEditorAssetEditedPayloadSuccess = GqlClientEditorAssetEditedPayload & {
    __typename?: "EditorAssetEditedPayloadSuccess";
    accountId: Scalars["ID"]["output"];
    data: GqlClientEditorAssetEditedPayloadData;
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    originalName: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientEditorAssetFolder = {
    __typename?: "EditorAssetFolder";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    parentFolder?: Maybe<GqlClientEditorAssetFolder>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientEditorAssetFont = {
    __typename?: "EditorAssetFont";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    editorAssetLibraryVersion: GqlClientEditorAssetLibraryVersion;
    familyName?: Maybe<Scalars["String"]["output"]>;
    hasSmallCaps?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    isBold?: Maybe<Scalars["Boolean"]["output"]>;
    isItalic?: Maybe<Scalars["Boolean"]["output"]>;
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage?: Maybe<GqlClientAssetLifecycleStage>;
    location: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    uploadId?: Maybe<Scalars["String"]["output"]>;
    url?: Maybe<Scalars["String"]["output"]>;
    webFontUrl?: Maybe<Scalars["String"]["output"]>;
    weight?: Maybe<Scalars["Int"]["output"]>;
};

export type GqlClientEditorAssetLibraryVersion = {
    __typename?: "EditorAssetLibraryVersion";
    accountId: Scalars["ID"]["output"];
    avatars: Array<Maybe<GqlClientEditorAccountLevelAvatar>>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    fonts: Array<Maybe<GqlClientEditorAssetFont>>;
    id: Scalars["ID"]["output"];
    isDraft: Scalars["Boolean"]["output"];
    media: Array<GqlClientEditorAssetMedia>;
    mediaFolders: Array<GqlClientEditorAssetFolder>;
    music: Array<Maybe<GqlClientEditorAssetMusic>>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientEditorAssetMedia = {
    __typename?: "EditorAssetMedia";
    accountId?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    createdByUser?: Maybe<GqlClientEditorAccountUser>;
    description?: Maybe<Scalars["String"]["output"]>;
    directAncestorId?: Maybe<Scalars["ID"]["output"]>;
    editorAssetLibraryVersion: GqlClientEditorAssetLibraryVersion;
    externalLibrary?: Maybe<GqlClientExternalLibrary>;
    externalLibraryAssetId?: Maybe<Scalars["String"]["output"]>;
    externalLibraryUniqueId?: Maybe<Scalars["String"]["output"]>;
    externalSublibrary?: Maybe<GqlClientExternalSubLibrary>;
    folder?: Maybe<GqlClientEditorAssetFolder>;
    id: Scalars["ID"]["output"];
    lifecycleStage: GqlClientAssetLifecycleStage;
    localId: Scalars["String"]["output"];
    mediaAgifUrl?: Maybe<Scalars["String"]["output"]>;
    mediaClassification: GqlClientMediaClassification;
    mediaFramesThumbnailsUrl?: Maybe<Scalars["String"]["output"]>;
    mediaLocation: Scalars["String"]["output"];
    mediaMetadata: Scalars["JSON"]["output"];
    mediaOrigin: GqlClientMediaOrigin;
    mediaPreviewLocation: Scalars["String"]["output"];
    mediaPreviewUrl: Scalars["String"]["output"];
    mediaProcessingInfo?: Maybe<Scalars["JSON"]["output"]>;
    mediaSri?: Maybe<Scalars["String"]["output"]>;
    mediaUrl: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    originalEditInformation?: Maybe<Scalars["JSON"]["output"]>;
    originalMediaId?: Maybe<Scalars["ID"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientEditorAssetMediaSaveAsInput = {
    name: Scalars["String"]["input"];
    parentFolderId: Scalars["ID"]["input"];
    parentMediaId: Scalars["ID"]["input"];
};

export type GqlClientEditorAssetMediaSaveAsOutput = {
    __typename?: "EditorAssetMediaSaveAsOutput";
    editorAssetMedia?: Maybe<GqlClientEditorAssetMedia>;
    result: GqlClientEditorAssetMediaSaveAsResult;
};

export enum GqlClientEditorAssetMediaSaveAsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientEditorAssetMediaUploadedPayload = {
    accountId: Scalars["ID"]["output"];
    fileName: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientEditorAssetMediaUploadedPayloadData = {
    __typename?: "EditorAssetMediaUploadedPayloadData";
    editorAsset: GqlClientEditorAssetMedia;
};

export type GqlClientEditorAssetMediaUploadedPayloadError = GqlClientEditorAssetMediaUploadedPayload & {
    __typename?: "EditorAssetMediaUploadedPayloadError";
    accountId: Scalars["ID"]["output"];
    error: Scalars["JSON"]["output"];
    fileName: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientEditorAssetMediaUploadedPayloadKeepAlive = GqlClientEditorAssetMediaUploadedPayload & {
    __typename?: "EditorAssetMediaUploadedPayloadKeepAlive";
    accountId: Scalars["ID"]["output"];
    fileName: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientEditorAssetMediaUploadedPayloadPending = GqlClientEditorAssetMediaUploadedPayload & {
    __typename?: "EditorAssetMediaUploadedPayloadPending";
    accountId: Scalars["ID"]["output"];
    fileName: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    progress: Scalars["Float"]["output"];
    status: GqlClientStatus;
};

export type GqlClientEditorAssetMediaUploadedPayloadSuccess = GqlClientEditorAssetMediaUploadedPayload & {
    __typename?: "EditorAssetMediaUploadedPayloadSuccess";
    accountId: Scalars["ID"]["output"];
    data: GqlClientEditorAssetMediaUploadedPayloadData;
    fileName: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientEditorAssetMusic = {
    __typename?: "EditorAssetMusic";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    editorAssetLibraryVersion: GqlClientEditorAssetLibraryVersion;
    externalLibrary?: Maybe<GqlClientExternalLibrary>;
    externalLibraryAssetId?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    lifecycleStage: GqlClientAssetLifecycleStage;
    location: Scalars["String"]["output"];
    musicOrigin: GqlClientMusicOrigin;
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    url: Scalars["String"]["output"];
};

export enum GqlClientEditorAssetSource {
    ACCOUNT = "account",
    CC = "cc"
}

export type GqlClientEditorChannel = {
    __typename?: "EditorChannel";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage?: Maybe<GqlClientAssetLifecycleStage>;
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientEditorCreativeStyleFit = {
    __typename?: "EditorCreativeStyleFit";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage?: Maybe<GqlClientAssetLifecycleStage>;
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientEditorFlexiblePlaceholderParentType {
    CANVAS = "canvas",
    FOREGROUND = "foreground",
    PLACEHOLDER = "placeholder"
}

export type GqlClientEditorFlexiblePlaceholderSettings = {
    __typename?: "EditorFlexiblePlaceholderSettings";
    height: Scalars["Float"]["output"];
    left: Scalars["Float"]["output"];
    top: Scalars["Float"]["output"];
    width: Scalars["Float"]["output"];
};

export type GqlClientEditorFlexiblePlaceholderSettingsInput = {
    height: Scalars["Float"]["input"];
    left: Scalars["Float"]["input"];
    top: Scalars["Float"]["input"];
    width: Scalars["Float"]["input"];
};

export type GqlClientEditorIndustry = {
    __typename?: "EditorIndustry";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage?: Maybe<GqlClientAssetLifecycleStage>;
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientEditorOverlayPlaceholderType {
    MEDIA_PLACEHOLDER = "media_placeholder",
    TEXT_PLACEHOLDER = "text_placeholder"
}

export type GqlClientEditorPlaceholder = {
    __typename?: "EditorPlaceholder";
    accountId: Scalars["ID"]["output"];
    ccPlaceholder: GqlClientCcPlaceholder;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    flexibleSettings?: Maybe<GqlClientEditorFlexiblePlaceholderSettings>;
    id: Scalars["ID"]["output"];
    parentPlaceholder?: Maybe<GqlClientEditorPlaceholder>;
    parentType?: Maybe<GqlClientEditorFlexiblePlaceholderParentType>;
    placeholderContent?: Maybe<Scalars["JSON"]["output"]>;
    placeholderSettings?: Maybe<Scalars["JSON"]["output"]>;
    scene: GqlClientEditorScene;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientEditorProgram = {
    __typename?: "EditorProgram";
    account?: Maybe<GqlClientAccount>;
    accountId: Scalars["ID"]["output"];
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    creationMethod?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    embedVideoCode?: Maybe<GqlClientEmbedVideoCode>;
    firstProdVersion?: Maybe<GqlClientLifecycleHistoryEntry>;
    firstViewedDate?: Maybe<Scalars["String"]["output"]>;
    folder?: Maybe<GqlClientAccountProgramFolder>;
    id: Scalars["ID"]["output"];
    isArchive?: Maybe<Scalars["Boolean"]["output"]>;
    isLive?: Maybe<Scalars["Boolean"]["output"]>;
    isReadyToGoLive: Scalars["Boolean"]["output"];
    landingPage?: Maybe<GqlClientLandingPage>;
    landingPageUrl?: Maybe<Scalars["String"]["output"]>;
    lastArchived?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage?: Maybe<GqlClientAssetLifecycleStage>;
    name: Scalars["String"]["output"];
    originProgramId?: Maybe<Scalars["ID"]["output"]>;
    originalStoryTemplateProgramId?: Maybe<Scalars["ID"]["output"]>;
    originalStoryTemplateProgramVersionId?: Maybe<Scalars["ID"]["output"]>;
    prodVersion?: Maybe<GqlClientLifecycleHistoryEntry>;
    programReviews: Array<GqlClientProgramReview>;
    programType: GqlClientEditorProgramType;
    programVersion?: Maybe<GqlClientEditorProgramVersion>;
    programVersionDraft: GqlClientEditorProgramVersion;
    programVersionSnapshots: Array<GqlClientEditorProgramVersion>;
    programVersions: Array<GqlClientEditorProgramVersion>;
    publishTarget: GqlClientLifecycleStage;
    saleForceId?: Maybe<Scalars["String"]["output"]>;
    shareHistory: Array<GqlClientProgramShareHistory>;
    totalViews?: Maybe<Scalars["Int"]["output"]>;
    uatVersion?: Maybe<GqlClientLifecycleHistoryEntry>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    viewerProfiles: Array<GqlClientViewerProfile>;
};

export type GqlClientEditorProgramProgramVersionArgs = {
    id: Scalars["ID"]["input"];
};

export enum GqlClientEditorProgramType {
    ADS = "ads",
    CRM = "crm"
}

export type GqlClientEditorProgramVersion = {
    __typename?: "EditorProgramVersion";
    accountDataLibraryVersion?: Maybe<GqlClientAccountDataLibraryVersion>;
    accountId: Scalars["ID"]["output"];
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    applicationData?: Maybe<GqlClientEditorApplication>;
    aspectRatio: GqlClientEnumAspectRatio;
    assetLibrary: GqlClientEditorAssetLibraryVersion;
    audioSettings?: Maybe<Scalars["JSON"]["output"]>;
    bcRules?: Maybe<Scalars["JSON"]["output"]>;
    bcUseRules?: Maybe<Scalars["Boolean"]["output"]>;
    brandConfigurationVersion: GqlClientBrandConfigurationVersion;
    ccLibraryVersion?: Maybe<GqlClientCcLibraryVersion>;
    ccTheme: GqlClientCcTheme;
    chapteringEnabled?: Maybe<Scalars["Boolean"]["output"]>;
    compatibility?: Maybe<GqlClientEnumAnimationWireframeCompatibility>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    editorDataConnectorEnabled?: Maybe<Scalars["Boolean"]["output"]>;
    editorTtsFullEnablement?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    isAvatarRendering: Scalars["Boolean"]["output"];
    isDraft?: Maybe<Scalars["Boolean"]["output"]>;
    legacyAssetLocationBasePath?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    narrationRecordingsState?: Maybe<GqlClientNarrationRecordingsState>;
    program: GqlClientEditorProgram;
    programType: GqlClientEditorProgramType;
    pronunciationLibraryVersion?: Maybe<GqlClientAccountPronunciationLibraryVersion>;
    publishable?: Maybe<Scalars["JSON"]["output"]>;
    scene?: Maybe<GqlClientEditorScene>;
    sceneLineup: Array<Scalars["ID"]["output"]>;
    scenes: Array<GqlClientEditorScene>;
    selectedAccountMusic?: Maybe<GqlClientEditorAssetMusic>;
    selectedBrandConfiguration: GqlClientBrandConfigurationCollection;
    selectedCcMusic?: Maybe<GqlClientCcMusic>;
    selectedMusicSource: GqlClientEditorAssetSource;
    selectedNarrator?: Maybe<GqlClientCcNarrator>;
    snapshotComment?: Maybe<Scalars["String"]["output"]>;
    snapshotName?: Maybe<Scalars["String"]["output"]>;
    snapshotNumber?: Maybe<Scalars["Int"]["output"]>;
    snapshotValidationData?: Maybe<Scalars["JSON"]["output"]>;
    snapshotted?: Maybe<Scalars["String"]["output"]>;
    snapshottedBy?: Maybe<Scalars["String"]["output"]>;
    studioElements: Array<GqlClientDerivedDataElement>;
    thumbnail?: Maybe<GqlClientThumbnailAndStatus>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    useCustomVideoBackground: Scalars["Boolean"]["output"];
    usedElements?: Maybe<Scalars["JSON"]["output"]>;
    usedPremiumGettyMediaData: Array<GqlClientUsedMediaData>;
    videoBackgroundMedia?: Maybe<GqlClientEditorAssetMedia>;
};

export type GqlClientEditorProgramVersionSceneArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientEditorScene = {
    __typename?: "EditorScene";
    accountId: Scalars["ID"]["output"];
    avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
    avatarVideoUrl?: Maybe<Scalars["String"]["output"]>;
    buttonAlignment?: Maybe<GqlClientEnumHorizontalAlignment>;
    ccAnimatedWireframe?: Maybe<GqlClientCcAnimatedWireframe>;
    ccWireframe: GqlClientCcWireframe;
    colorCombination: GqlClientEditorSceneColorCombination;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    hasTransition?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    minimumDuration?: Maybe<Scalars["Float"]["output"]>;
    name: Scalars["String"]["output"];
    placeholders: Array<GqlClientEditorPlaceholder>;
    programVersion: GqlClientEditorProgramVersion;
    skipSceneAudience?: Maybe<Scalars["JSON"]["output"]>;
    skipSceneMode?: Maybe<GqlClientEditorSceneSkippingMode>;
    transitionName?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    usedMediaIds: Array<Scalars["ID"]["output"]>;
};

export enum GqlClientEditorSceneAvatarVideoStatus {
    AVATAR_VIDEO_MISSING = "avatar_video_missing",
    AVATAR_VIDEO_RENDERED = "avatar_video_rendered",
    NO_AVATAR_IN_SCENE = "no_avatar_in_scene"
}

export enum GqlClientEditorSceneColorCombination {
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E"
}

export enum GqlClientEditorSceneSkippingMode {
    ALWAYS = "always",
    BY_AUDIENCE = "by_audience",
    NEVER = "never",
    SHOW_BY_AUDIENCE = "show_by_audience"
}

export type GqlClientEditorStockMusic = {
    __typename?: "EditorStockMusic";
    artists?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    durationInSeconds: Scalars["Int"]["output"];
    id: Scalars["ID"]["output"];
    previewUrl?: Maybe<Scalars["String"]["output"]>;
    tags?: Maybe<Array<Maybe<GqlClientEditorStockMusicTag>>>;
    title: Scalars["String"]["output"];
};

export type GqlClientEditorStockMusicSearchResults = {
    __typename?: "EditorStockMusicSearchResults";
    numberOfResults: Scalars["Int"]["output"];
    tracks?: Maybe<Array<Maybe<GqlClientEditorStockMusic>>>;
};

export type GqlClientEditorStockMusicTag = {
    __typename?: "EditorStockMusicTag";
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
};

export type GqlClientEditorUseCase = {
    __typename?: "EditorUseCase";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage?: Maybe<GqlClientAssetLifecycleStage>;
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientEmbedVideoCode = {
    __typename?: "EmbedVideoCode";
    accountId: Scalars["ID"]["output"];
    configuration?: Maybe<Scalars["JSON"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    createdByUser?: Maybe<GqlClientEditorAccountUser>;
    editorProgram: GqlClientEditorProgram;
    id: Scalars["ID"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    updatedByUser?: Maybe<GqlClientEditorAccountUser>;
    urlSuffix?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientEnumAnimationWireframeCompatibility {
    M2 = "m2"
}

export enum GqlClientEnumAspectRatio {
    AR_1_1 = "ar_1_1",
    AR_4_5 = "ar_4_5",
    AR_9_16 = "ar_9_16",
    AR_14_9 = "ar_14_9",
    AR_16_9 = "ar_16_9",
    AR_191_100 = "ar_191_100"
}

export enum GqlClientEnumHorizontalAlignment {
    CENTER = "center",
    LEFT = "left",
    RIGHT = "right"
}

export enum GqlClientEnumMediaAdjustment {
    FIT_LARGER = "fit_larger",
    FIT_SMALLER = "fit_smaller",
    STRETCH = "stretch"
}

export enum GqlClientEnumVerticalAlignment {
    BOTTOM = "bottom",
    CENTER = "center",
    TOP = "top"
}

export type GqlClientExperiment = {
    __typename?: "Experiment";
    created: Scalars["Float"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    enabled: Scalars["Boolean"]["output"];
    endTime?: Maybe<Scalars["Float"]["output"]>;
    experimentComponent: Scalars["String"]["output"];
    experimentName: Scalars["String"]["output"];
    experimentType: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    metric: Scalars["String"]["output"];
    startTime: Scalars["Float"]["output"];
    updated: Scalars["Float"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    variants: Array<GqlClientExperimentVariant>;
};

export type GqlClientExperimentVariant = {
    __typename?: "ExperimentVariant";
    fraction: Scalars["Float"]["output"];
    variantId: Scalars["String"]["output"];
    variantName: Scalars["String"]["output"];
};

export type GqlClientExportAccountDataInput = {
    accountIds: Array<Scalars["String"]["input"]>;
    maxCacheAgeHours?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GqlClientExportAccountDataOutput = {
    __typename?: "ExportAccountDataOutput";
    exportFile: Scalars["String"]["output"];
    result: GqlClientExportAccountDataResult;
};

export enum GqlClientExportAccountDataResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientExternalAssetDetails = {
    externalLibrary: GqlClientExternalLibrary;
    externalLibraryAssetId: Scalars["String"]["input"];
};

export enum GqlClientExternalLibrary {
    ACQUIA = "acquia",
    GETTY = "getty",
    STORYBLOCKS = "storyblocks"
}

export enum GqlClientExternalSubLibrary {
    PREMIUM = "premium",
    WHOLLY_OWNED = "wholly_owned"
}

export type GqlClientFeatureDefinition = {
    __typename?: "FeatureDefinition";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    errorMessage: Scalars["String"]["output"];
    featureRestrictionScope: GqlClientFeatureRestrictionScope;
    featureRestrictionType: GqlClientRestrictionType;
    featureTypeDefinition: GqlClientFeatureTypeDefinition;
    id: Scalars["ID"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    userControlled: Scalars["Boolean"]["output"];
};

export enum GqlClientFeatureRestrictionScope {
    ACCOUNT = "account",
    PROGRAM = "program"
}

export enum GqlClientFeatureSource {
    MANUAL_OVERRIDE = "manual_override",
    SCHEMA = "schema"
}

export enum GqlClientFeatureTypeDefinition {
    LIMIT_AVATAR_GENERATION_MINUTES = "limit_avatar_generation_minutes",
    LIMIT_CUSTOM_AVATAR_CREATIONS = "limit_custom_avatar_creations",
    LIMIT_USERS_NUMBER = "limit_users_number",
    USER_BLOCK_AI_AVATARS = "user_block_ai_avatars",
    USER_BLOCK_AI_CUSTOM_AVATARS = "user_block_ai_custom_avatars",
    USER_BLOCK_AI_MEDIA_GENERATION = "user_block_ai_media_generation",
    USER_BLOCK_AI_MUSIC_RECOMMENDATION = "user_block_ai_music_recommendation",
    USER_BLOCK_AI_NEW_FEATURES = "user_block_ai_new_features",
    USER_BLOCK_AI_VIDEO_COPILOT = "user_block_ai_video_copilot"
}

export type GqlClientFeedDataElement = GqlClientGenericDataElement & {
    __typename?: "FeedDataElement";
    accountId: Scalars["ID"]["output"];
    audiences: Array<GqlClientAudience>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    dataType?: Maybe<GqlClientDataElementType>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    pii?: Maybe<Scalars["Boolean"]["output"]>;
    programVersion: GqlClientProgramVersion;
    scenes: Array<GqlClientScene>;
    source?: Maybe<Scalars["String"]["output"]>;
    status?: Maybe<GqlClientFeedDataElementStatus>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    useValueSet?: Maybe<Scalars["Boolean"]["output"]>;
    valueSet: Array<Scalars["JSON"]["output"]>;
};

export enum GqlClientFeedDataElementStatus {
    APPROVED = "approved",
    MISSING = "missing",
    PENDING = "pending",
    REJECTED = "rejected"
}

export type GqlClientFileInput = {
    file: Scalars["Upload"]["input"];
    fileSize: Scalars["Int"]["input"];
    fileType: Scalars["String"]["input"];
    filename: Scalars["String"]["input"];
};

export enum GqlClientFileValidationName {
    APPS = "APPS",
    CATEGORIES = "CATEGORIES",
    KEYWORDS = "KEYWORDS",
    LOCATIONS = "LOCATIONS",
    PRODUCTS = "PRODUCTS",
    TRACKERS = "TRACKERS",
    URLS = "URLS"
}

export type GqlClientFlight = {
    __typename?: "Flight";
    CPCV?: Maybe<Scalars["Float"]["output"]>;
    CPM?: Maybe<Scalars["Float"]["output"]>;
    completedViews?: Maybe<Scalars["Float"]["output"]>;
    endDate: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    impressions?: Maybe<Scalars["Float"]["output"]>;
    insertionOrder: Scalars["String"]["output"];
    margin: Scalars["Float"]["output"];
    startDate: Scalars["String"]["output"];
};

export enum GqlClientFraudMode {
    OFF = "OFF",
    STANDARD = "STANDARD"
}

export type GqlClientGenerateAiImageFromTextOutput = {
    __typename?: "GenerateAiImageFromTextOutput";
    badPrompt?: Maybe<Scalars["Boolean"]["output"]>;
    error?: Maybe<Scalars["String"]["output"]>;
    images?: Maybe<Array<GqlClientAiImageResult>>;
};

export type GqlClientGenerateAiVideoFromImageOutput = {
    __typename?: "GenerateAiVideoFromImageOutput";
    result: GqlClientGenerateAiVideoFromImageResult;
};

export enum GqlClientGenerateAiVideoFromImageResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientGenericDataElement = {
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    dataType?: Maybe<GqlClientDataElementType>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    pii?: Maybe<Scalars["Boolean"]["output"]>;
    programVersion: GqlClientProgramVersion;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientGetCampaignsResponse = {
    __typename?: "GetCampaignsResponse";
    campaigns?: Maybe<Array<GqlClientCampaignSettings>>;
    message?: Maybe<Scalars["String"]["output"]>;
    returnCode: GqlClientAdsReturnCode;
    totalSFBudget?: Maybe<Scalars["Float"]["output"]>;
};

export type GqlClientGettyImageDownloadMetadata = {
    bytes: Scalars["String"]["input"];
    contentType: Scalars["String"]["input"];
    height: Scalars["String"]["input"];
};

export type GqlClientGettyImageDownloadSize = {
    __typename?: "GettyImageDownloadSize";
    bytes: Scalars["JSON"]["output"];
    height: Scalars["Int"]["output"];
    media_type: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    width: Scalars["Int"]["output"];
};

export type GqlClientGettyImageMetadata = {
    __typename?: "GettyImageMetadata";
    downloadSizes: Array<GqlClientGettyImageDownloadSize>;
    id: Scalars["ID"]["output"];
    largePreviewPosterUri?: Maybe<Scalars["String"]["output"]>;
    largePreviewUri?: Maybe<Scalars["String"]["output"]>;
    previewUri?: Maybe<Scalars["String"]["output"]>;
    thumbUri?: Maybe<Scalars["String"]["output"]>;
    title: Scalars["String"]["output"];
};

export type GqlClientGettyMediaMetadata = GqlClientGettyImageMetadata | GqlClientGettyVideoMetadata;

export enum GqlClientGettyOrientation {
    HORIZONTAL = "horizontal",
    VERTICAL = "vertical"
}

export type GqlClientGettySearchMetadataOutput = {
    __typename?: "GettySearchMetadataOutput";
    assets: Array<GqlClientGettyMediaMetadata>;
    resultCount: Scalars["Int"]["output"];
};

export enum GqlClientGettySortOrder {
    BEST_MATCH = "best_match",
    MOST_POPULAR = "most_popular",
    NEWEST = "newest",
    OLDEST = "oldest",
    RANDOM = "random"
}

export type GqlClientGettyVideoDownloadMetadata = {
    bytes: Scalars["String"]["input"];
    contentType: Scalars["String"]["input"];
    format: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
};

export type GqlClientGettyVideoDownloadSize = {
    __typename?: "GettyVideoDownloadSize";
    bytes: Scalars["JSON"]["output"];
    content_type: Scalars["String"]["output"];
    description: Scalars["String"]["output"];
    format: Scalars["String"]["output"];
    height: Scalars["Int"]["output"];
    name: Scalars["String"]["output"];
    width: Scalars["Int"]["output"];
};

export type GqlClientGettyVideoMetadata = {
    __typename?: "GettyVideoMetadata";
    downloadSizes: Array<GqlClientGettyVideoDownloadSize>;
    duration: Scalars["Int"]["output"];
    id: Scalars["ID"]["output"];
    largePreviewPosterUri?: Maybe<Scalars["String"]["output"]>;
    largePreviewUri?: Maybe<Scalars["String"]["output"]>;
    previewUri?: Maybe<Scalars["String"]["output"]>;
    thumbUri?: Maybe<Scalars["String"]["output"]>;
    title: Scalars["String"]["output"];
};

export type GqlClientGoal = {
    __typename?: "Goal";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    kpis: Array<GqlClientKpi>;
    name: Scalars["String"]["output"];
    priority?: Maybe<Scalars["Int"]["output"]>;
    programVersion: GqlClientProgramVersion;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientGoogleColumn = {
    __typename?: "GoogleColumn";
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
};

export type GqlClientGoogleSheet = {
    __typename?: "GoogleSheet";
    columns?: Maybe<Array<GqlClientGoogleColumn>>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
};

export enum GqlClientGoogleSheetError {
    PERMISSION_DENIED = "PERMISSION_DENIED",
    RESOURCE_NOT_FOUND = "RESOURCE_NOT_FOUND"
}

export enum GqlClientGoogleSheetResult {
    ERROR = "ERROR",
    SUCCESS = "SUCCESS"
}

export type GqlClientGoogleSheetResultOutput = {
    result: GqlClientGoogleSheetResult;
};

export type GqlClientGoogleSheetResultOutputError = GqlClientGoogleSheetResultOutput & {
    __typename?: "GoogleSheetResultOutputError";
    error: GqlClientGoogleSheetError;
    result: GqlClientGoogleSheetResult;
};

export type GqlClientGoogleSheetResultOutputSuccess = GqlClientGoogleSheetResultOutput & {
    __typename?: "GoogleSheetResultOutputSuccess";
    result: GqlClientGoogleSheetResult;
    sheets: Array<GqlClientGoogleSheet>;
};

export type GqlClientGoogleSpreadSheet = {
    __typename?: "GoogleSpreadSheet";
    sheetId: Scalars["ID"]["output"];
    sheets?: Maybe<Array<GqlClientGoogleSheet>>;
};

export type GqlClientHelpCenterEntry = {
    __typename?: "HelpCenterEntry";
    articleId: Scalars["String"]["output"];
    buttonId: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    sectionId?: Maybe<Scalars["String"]["output"]>;
    variant?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientImageCroppingData = {
    height: Scalars["Float"]["input"];
    rotate: Scalars["Float"]["input"];
    scaleX: Scalars["Float"]["input"];
    scaleY: Scalars["Float"]["input"];
    width: Scalars["Float"]["input"];
    x: Scalars["Float"]["input"];
    y: Scalars["Float"]["input"];
};

export enum GqlClientImportTaskStatus {
    FAILURE = "Failure",
    RUNNING = "Running",
    SUCCESS = "Success"
}

export type GqlClientInput = {
    __typename?: "Input";
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    type: GqlClientInputType;
};

export enum GqlClientInputType {
    COLOR = "color",
    HOTSPOT = "hotspot",
    IMAGE = "image",
    TEXT = "text",
    VIDEO = "video"
}

export type GqlClientKpi = {
    __typename?: "KPI";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    goal: GqlClientGoal;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    objective?: Maybe<Scalars["String"]["output"]>;
    scenes: Array<GqlClientScene>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientLandingPage = {
    __typename?: "LandingPage";
    accountId: Scalars["ID"]["output"];
    configuration?: Maybe<Scalars["JSON"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    createdByUser?: Maybe<GqlClientEditorAccountUser>;
    editorProgram: GqlClientEditorProgram;
    id: Scalars["ID"]["output"];
    isActive?: Maybe<Scalars["Boolean"]["output"]>;
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage?: Maybe<GqlClientAssetLifecycleStage>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    updatedByUser?: Maybe<GqlClientEditorAccountUser>;
    urlSuffix?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientLibraryViewType {
    GRID = "grid",
    LIST = "list"
}

export type GqlClientLifecycleHistoryEntry = {
    __typename?: "LifecycleHistoryEntry";
    accountDataLibraryVersion?: Maybe<GqlClientAccountDataLibraryVersion>;
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    id: Scalars["ID"]["output"];
    lifecycleStage?: Maybe<GqlClientLifecycleStage>;
    program: GqlClientProgram;
    snapshotName?: Maybe<Scalars["String"]["output"]>;
    snapshotNumber?: Maybe<Scalars["Int"]["output"]>;
    snapshotValidationData?: Maybe<Scalars["JSON"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientLifecycleStage {
    DEV = "dev",
    PROD = "prod",
    UAT = "uat"
}

export type GqlClientLocationFilter = {
    __typename?: "LocationFilter";
    excluded?: Maybe<Array<Scalars["String"]["output"]>>;
    included?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type GqlClientMasterAnimationGuideline = {
    __typename?: "MasterAnimationGuideline";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    logic?: Maybe<Scalars["JSON"]["output"]>;
    name: Scalars["String"]["output"];
    programVersion: GqlClientProgramVersion;
    type?: Maybe<GqlClientAnimationGuidelineType>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientMediaClassification {
    HIDDEN = "hidden",
    ORIGINAL = "original",
    SAVED = "saved"
}

export type GqlClientMediaCropHints = {
    __typename?: "MediaCropHints";
    suggestedCrop: GqlClientRectangle;
};

export enum GqlClientMediaOrigin {
    AI_GENERATED = "ai_generated",
    CLONED_BETWEEN_ACCOUNTS = "cloned_between_accounts",
    EXTERNAL = "external",
    INTERNAL = "internal",
    RECORDER = "recorder",
    SURROGATE = "surrogate",
    USER = "user"
}

export enum GqlClientMediaType {
    IMAGE = "image",
    VIDEO = "video"
}

export type GqlClientMoveAccountProgramFolderToFolderInput = {
    folderId: Scalars["ID"]["input"];
    folderName: Scalars["String"]["input"];
    targetFolderId: Scalars["ID"]["input"];
};

export type GqlClientMoveAccountProgramFolderToFolderOutput = {
    __typename?: "MoveAccountProgramFolderToFolderOutput";
    folder: GqlClientAccountProgramFolder;
    result: GqlClientMoveAccountProgramFolderToFolderResult;
};

export enum GqlClientMoveAccountProgramFolderToFolderResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientMoveCampaignSettingsInput = {
    id: Scalars["ID"]["input"];
    moveToSfid: Scalars["String"]["input"];
};

export type GqlClientMoveEditorAssetToFolderInput = {
    assetId: Scalars["ID"]["input"];
    assetName: Scalars["String"]["input"];
    destinationFolderId: Scalars["ID"]["input"];
};

export type GqlClientMoveEditorAssetToFolderOutput = {
    __typename?: "MoveEditorAssetToFolderOutput";
    editorAssetMedia: GqlClientEditorAssetMedia;
    result: GqlClientMoveEditorAssetToFolderResult;
};

export enum GqlClientMoveEditorAssetToFolderResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientMoveEditorFolderToFolderInput = {
    destinationFolderId: Scalars["ID"]["input"];
    folderId: Scalars["ID"]["input"];
    folderName: Scalars["String"]["input"];
};

export type GqlClientMoveEditorFolderToFolderOutput = {
    __typename?: "MoveEditorFolderToFolderOutput";
    folder: GqlClientEditorAssetFolder;
    result: GqlClientMoveEditorFolderToFolderResult;
};

export enum GqlClientMoveEditorFolderToFolderResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientMoveProgramToFolderInput = {
    programId: Scalars["ID"]["input"];
    programName: Scalars["String"]["input"];
    targetFolderId: Scalars["ID"]["input"];
};

export type GqlClientMoveProgramToFolderOutput = {
    __typename?: "MoveProgramToFolderOutput";
    program: GqlClientOutputProgram;
    result: GqlClientMoveProgramToFolderResult;
};

export enum GqlClientMoveProgramToFolderResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientMoveProgramVersionDraftInput = {
    sourceId: Scalars["ID"]["input"];
    targetAccountId: Scalars["ID"]["input"];
    targetProgramId: Scalars["ID"]["input"];
};

export type GqlClientMoveProgramVersionDraftOutput = {
    __typename?: "MoveProgramVersionDraftOutput";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientMoveProgramVersionDraftResult;
    sourceProgram?: Maybe<GqlClientProgram>;
};

export enum GqlClientMoveProgramVersionDraftResult {
    SUCCESS = "SUCCESS"
}

export enum GqlClientMusicOrigin {
    EXTERNAL = "external",
    USER = "user"
}

export type GqlClientMutation = {
    __typename?: "Mutation";
    _?: Maybe<Scalars["Boolean"]["output"]>;
    accountsSyncTrigger?: Maybe<GqlClientAccountsSyncTriggerOutput>;
    addCreativeLifecycleHistoryEntry: GqlClientAddCreativeLifecycleHistoryEntryOutput;
    addLifecycleHistoryEntry: GqlClientAddLifecycleHistoryEntryOutput;
    addStoryTemplateToProductionGallery: GqlClientAddStoryTemplateToProductionGalleryOutput;
    approveAccountInvitation: GqlClientApproveAccountInvitationOutput;
    clearUndoStack?: Maybe<GqlClientClearUndoStackOutput>;
    completeFirstLogin: GqlClientCompleteFirstLoginOutput;
    convertFeedDataElementToDerived: GqlClientConvertFeedDataElementToDerivedOutput;
    copyAccountReport: GqlClientCopyAccountReportOutput;
    copyCreativeVersionDraft: GqlClientCopyCreativeVersionDraftOutput;
    copyCreativeWithDraft: GqlClientCopyCreativeWithDraftOutput;
    copyDraftEditorProgramBetweenAccounts: GqlClientCopyDraftEditorProgramBetweenAccountsOutput;
    copyEditorProgram: GqlClientCopyProgramOutput;
    copyEditorProgramAsEditor: GqlClientCopyEditorProgramAsEditorOutput;
    copyNarration: GqlClientCopyNarrationOutput;
    copyProgram: GqlClientCopyProgramOutput;
    copyProgramVersionDraft: GqlClientCopyProgramVersionDraftOutput;
    copyScene: GqlClientCopySceneOutput;
    copyScenePart: GqlClientCopyScenePartOutput;
    copyStory: GqlClientCopyStoryOutput;
    createAccountCopilotBrief: GqlClientCreateAccountCopilotBriefOutput;
    createAccountProgramFolder: GqlClientCreateAccountProgramFolderOutput;
    createAccountReport: GqlClientCreateAccountReportOutput;
    createAdsAudience: GqlClientAdsAudience;
    createAiPreviewProgram: GqlClientCreateAiPreviewProgramOutput;
    createAnimationVersions: GqlClientCreateAnimationVersionsOutput;
    createAnimationWithVersions: GqlClientCreateAnimationWithVersionsOutput;
    createAssetRequest: GqlClientCreateAssetRequestOutput;
    createAssetVersion: GqlClientCreateAssetVersionOutput;
    createAssetWithVersion: GqlClientCreateAssetWithVersionOutput;
    createAudience: GqlClientCreateAudienceOutput;
    createAudienceWithDataElements: GqlClientCreateAudienceOutput;
    createBasicFeedDataElement: GqlClientCreateBasicFeedDataElementOutput;
    createBcCollection: GqlClientCreateBcCollectionOutput;
    createCampaignSettings: GqlClientCampaignSettings;
    /** @deprecated This mutation is used for test purposes only */
    createCcAnimatedWireframe: GqlClientCreateCcAnimatedWireframeOutput;
    createCcLibrary: GqlClientCreateCcLibraryOutput;
    createCcLibraryDraft: GqlClientCreateCcLibraryDraftOutput;
    /** @deprecated This mutation is used for test purposes only */
    createCcMusic: GqlClientCreateCcMusicOutput;
    /** @deprecated This mutation is used for test purposes only */
    createCcNarrator: GqlClientCreateCcNarratorOutput;
    /** @deprecated This mutation is used for test purposes only */
    createCcPlaceholderIntent: GqlClientCreateCcPlaceholderIntentOutput;
    createCcSnapshot: GqlClientCreateCcSnapshotOutput;
    createCcTheme: GqlClientCreateCcThemeOutput;
    createCcWireframe: GqlClientCreateCcWireframeOutput;
    createChannel: GqlClientCreateChannelOutput;
    createCreativeAndDraft: GqlClientCreateCreativeAndDraftOutput;
    createCreativeAsset: GqlClientCreateCreativeAssetOutput;
    createCreativeAssetVersion: GqlClientCreateCreativeAssetVersionOutput;
    createCreativeAssetWithVersion: GqlClientCreateCreativeAssetWithVersionOutput;
    createCreativeDataElement: GqlClientCreateCreativeDataElementOutput;
    createCreativeNarrationRecordingVersion: GqlClientCreateCreativeNarrationRecordingVersionOutput;
    createCreativeVersionDraft: GqlClientCreateCreativeVersionDraftOutput;
    createCustomAnalyticField: GqlClientCreateCustomAnalyticFieldOutput;
    createDataLibrary: GqlClientCreateDataLibraryOutput;
    createDataTable: GqlClientCreateDataTableOutput;
    createDerivedDataElement: GqlClientCreateDerivedDataElementOutput;
    createEditorAccountUser: GqlClientCreateEditorAccountUserOutput;
    createEditorAssetFolder: GqlClientCreateEditorAssetFolderOutput;
    createEditorAvatar: GqlClientCreateEditorAvatarOutput;
    createEditorFlexiblePlaceholder: GqlClientCreateEditorFlexiblePlaceholderOutput;
    createEditorFlexiblePlaceholderWithData: GqlClientCreateEditorFlexiblePlaceholderWithDataOutput;
    createEditorLifecycleEntry: GqlClientCreateEditorLifecycleEntryOutput;
    createEditorOverlayPlaceholder: GqlClientCreateEditorOverlayPlaceholderOutput;
    createEditorProgram: GqlClientCreateEditorProgramOutput;
    createEditorProgramFromStoryTemplate: GqlClientCreateEditorProgramFromStoryTemplateOutput;
    createEditorProgramSnapshot: GqlClientCreateEditorProgramSnapshotOutput;
    createEditorSceneWithData: GqlClientCreateEditorSceneWithDataOutput;
    createEditorScenes: GqlClientCreateEditorScenesOutput;
    createEditorViewerProfile: GqlClientCreateEditorViewerProfileOutput;
    createEditorViewerProfileWithData: GqlClientCreateEditorViewerProfileWithDataOutput;
    createExperiment: GqlClientCreateExperimentOutput;
    createFeedDataElement: GqlClientCreateFeedDataElementOutput;
    createGoalWithKPIs: GqlClientCreateGoalWithKpIsOutput;
    createMasterAnimationGuideline: GqlClientCreateMasterAnimationGuidelineOutput;
    createNarration: GqlClientCreateNarrationOutput;
    createNarrationRecordingVersion: GqlClientCreateNarrationRecordingVersionOutput;
    createNarrator: GqlClientCreateNarratorOutput;
    createNextRecommendedVideo: GqlClientCreateNextRecommendedVideoOutput;
    createOrUpdateAccountDataFields: GqlClientCreateOrUpdateAccountDataFieldsOutput;
    createOrUpdateEditorApplication: GqlClientCreateOrUpdateEditorApplicationOutput;
    createOrUpdateEmbedVideoCode: GqlClientCreateOrUpdateEmbedVideoCodeOutput;
    createOrUpdateLandingPage: GqlClientCreateOrUpdateLandingPageOutput;
    createPreSubmittedAvatar: GqlClientCreatePreSubmittedAvatarOutput;
    createPreset: GqlClientCreatePresetOutput;
    createProgram: GqlClientCreateProgramOutput;
    createProgramAndBuilderDraft: GqlClientCreateProgramAndBuilderDraftOutput;
    createProgramAndDraft: GqlClientCreateProgramAndDraftOutput;
    createProgramReview: GqlClientCreateProgramReviewOutput;
    createProgramVersionDraft: GqlClientCreateProgramVersionDraftOutput;
    createProgramVersionDraftFromSnapshot: GqlClientCreateProgramVersionDraftFromSnapshotOutput;
    createProgramVersionSnapshot: GqlClientCreateProgramVersionSnapshotOutput;
    createPronunciationDefinition: GqlClientCreatePronunciationDefinitionOutput;
    createPronunciationDefinitionWithData: GqlClientCreatePronunciationDefinitionWithDataOutput;
    createRFRVersion: GqlClientCreateRfrVersionOutput;
    createRecordingDoc: GqlClientCreateRecordingDocOutput;
    createSceneFromPrompt: GqlClientCreateSceneFromPromptOutput;
    createScenePart: GqlClientCreateScenePartOutput;
    createSceneWithKPIsPersonalizationsAndDataElements: GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsOutput;
    createSceneWithScenePart: GqlClientCreateSceneWithScenePartOutput;
    createShareHistoryRecord: GqlClientCreateShareHistoryRecordOutput;
    createStoryWithDecisionPointAndBackgroundAssets: GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsOutput;
    createStoryWithReachAndScenes: GqlClientCreateStoryWithReachAndScenesOutput;
    createSurvey: GqlClientCreateSurveyOutput;
    createTouchpoint: GqlClientCreateTouchpointOutput;
    customAvatarsCreditBalanceEmail: GqlClientCustomAvatarsCreditBalanceEmailOutput;
    dataLibraryImportData: GqlClientDataLibraryImportDataOutput;
    deleteAccountCopilotBrief: GqlClientDeleteAccountCopilotBriefOutput;
    deleteAccountInvitation: GqlClientDeleteAccountInvitationOutput;
    deleteAccountProgramFolder: GqlClientDeleteAccountProgramFolderOutput;
    deleteAccountReport: GqlClientDeleteAccountReportOutput;
    deleteAudience: GqlClientDeleteAudienceOutput;
    deleteBrandCollection: GqlClientDeleteBrandCollectionOutput;
    deleteCcAnimatedWireframe: GqlClientDeleteCcAnimatedWireframeOutput;
    deleteCcAnimatedWireframeCrossAspectRatioMapping: GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingOutput;
    deleteCcTheme: GqlClientDeleteCcThemeOutput;
    deleteCcWireframe: GqlClientDeleteCcWireframeOutput;
    deleteChannel: GqlClientDeleteChannelOutput;
    deleteCreativeAsset: GqlClientDeleteCreativeAssetOutput;
    deleteCreativeVersion: GqlClientDeleteCreativeVersionOutput;
    deleteCreatives: GqlClientDeleteCreativesOutput;
    deleteCustomAnalyticField: GqlClientDeleteCustomAnalyticFieldOutput;
    deleteDataElement: GqlClientDeleteDataElementOutput;
    deleteDataLibrary: GqlClientDeleteDataLibraryOutput;
    deleteDataTable: GqlClientDeleteDataTableOutput;
    deleteEditorAccountUser: GqlClientDeleteEditorAccountUserOutput;
    deleteEditorAssetFolder: GqlClientDeleteEditorAssetFolderOutput;
    deleteEditorAssetMedia: GqlClientDeleteEditorAssetMediaOutput;
    deleteEditorFlexiblePlaceholder: GqlClientDeleteEditorFlexiblePlaceholderOutput;
    deleteEditorProgram: GqlClientDeleteEditorProgramOutput;
    deleteEditorProgramAllVersions: GqlClientDeleteEditorProgramAllVersionsOutput;
    deleteEditorScene: GqlClientDeleteEditorSceneOutput;
    deleteEditorViewerProfile: GqlClientDeleteEditorViewerProfileOutput;
    deleteFailedAvatar: GqlClientDeleteFailedAvatarOutput;
    deleteGoal: GqlClientDeleteGoalOutput;
    deleteMasterAnimationGuideline: GqlClientDeleteMasterAnimationGuidelineOutput;
    deleteNarration: GqlClientDeleteNarrationOutput;
    deletePlaceholderGroupLogic: GqlClientDeletePlaceholderGroupLogicOutput;
    deletePreSubmittedAvatar: GqlClientDeletePreSubmittedAvatarOutput;
    deletePreset: GqlClientDeletePresetOutput;
    deletePrioritizedListLogic: GqlClientDeletePrioritizedListLogicOutput;
    deleteProgram: GqlClientDeleteProgramOutput;
    deleteProgramAndDraft: GqlClientDeleteProgramAndDraftOutput;
    deleteProgramVersionDraft: GqlClientDeleteProgramVersionDraftOutput;
    deletePronunciationDefinition: GqlClientDeletePronunciationDefinitionOutput;
    deleteScene: GqlClientDeleteSceneOutput;
    deleteSceneEntityLogic: GqlClientDeleteSceneEntityLogicOutput;
    deleteScenePart: GqlClientDeleteScenePartOutput;
    deleteStory: GqlClientDeleteStoryOutput;
    deleteSurvey: GqlClientDeleteSurveyOutput;
    deleteTouchpoint: GqlClientDeleteTouchpointOutput;
    disconnectTrayConnector: GqlClientDisconnectTrayConnectorOutput;
    duplicateCampaignSettings: GqlClientCampaignSettings;
    duplicateEditorSceneInLineup: GqlClientDuplicateEditorSceneInLineupOutput;
    editEditorAssetMedia: GqlClientEditEditorAssetMediaOutput;
    editorAssetMediaSaveAs: GqlClientEditorAssetMediaSaveAsOutput;
    exportAccountData: GqlClientExportAccountDataOutput;
    generateAiVideoFromImage?: Maybe<GqlClientGenerateAiVideoFromImageOutput>;
    moveAccountProgramFolderToFolder: GqlClientMoveAccountProgramFolderToFolderOutput;
    moveCampaignSettings: GqlClientCampaignSettings;
    moveEditorAssetToFolder: GqlClientMoveEditorAssetToFolderOutput;
    moveEditorFolderToFolder: GqlClientMoveEditorFolderToFolderOutput;
    moveProgramToFolder: GqlClientMoveProgramToFolderOutput;
    moveProgramVersionDraft: GqlClientMoveProgramVersionDraftOutput;
    populateMissingAccountFeatureData: GqlClientPopulateMissingAccountFeatureDataOutput;
    postToSocialNetwork: GqlClientPostToSocialNetworkOutput;
    promotePreSubmittedAvatar: GqlClientPromotePreSubmittedAvatarOutput;
    promoteSubmittedAvatar: GqlClientPromoteSubmittedAvatarOutput;
    recalculateEditorNarrationRecordingState: GqlClientRecalculateEditorNarrationRecordingStateOutput;
    removeAllExternalUsersFromAccount: GqlClientRemoveAllExternalUsersFromAccountOutput;
    removeConnectorFromAccount: GqlClientRemoveConnectorFromAccountOutput;
    removeEditorProgramVersionLogicAndDataLibrary: GqlClientRemoveEditorProgramVersionLogicAndDataLibraryOutput;
    removeServiceFromAccount: GqlClientRemoveServiceFromAccountOutput;
    removeStoryTemplateFromGallery: GqlClientRemoveStoryTemplateFromGalleryOutput;
    renameAccountProgramFolder: GqlClientRenameAccountProgramFolderOutput;
    renameEditorAssetFolder: GqlClientRenameEditorAssetFolderOutput;
    renameProgramReview: GqlClientRenameProgramReviewOutput;
    saveUserRecentSearch: GqlClientSaveUserRecentSearchOutput;
    selectDataLibrary: GqlClientSelectDataLibraryOutput;
    sendAccountReport: GqlClientSendAccountReportOutput;
    setAnalyticsDashboard: GqlClientSetAnalyticsDashboardOutput;
    setCcActiveSnapshot: GqlClientSetCcActiveSnapshotOutput;
    setSurveys: GqlClientSetSurveysOutput;
    setTrackingParameters: GqlClientSetTrackingParametersOutput;
    switchBcCollectionAndDeleteCurrent: GqlClientSwitchBcCollectionAndDeleteCurrentOutput;
    undoAction: GqlClientUndoActionOutput;
    undoDeleteBcCollection: GqlClientUndoDeleteBcCollectionOutput;
    undoDeleteEditorAssetFolder: GqlClientUndoDeleteEditorAssetFolderOutputSuccess;
    undoDeleteEditorAssetMedia: GqlClientUndoDeleteEditorAssetMediaOutputSuccess;
    undoUpdateEditorProgramTheme: GqlClientUndoUpdateEditorProgramThemeOutput;
    unselectDataLibrary: GqlClientUnselectDataLibraryOutput;
    updateAccountCopilotBrief: GqlClientUpdateAccountCopilotBriefOutput;
    updateAccountDataLibraryConnectorTypeAndMapping: GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingOutput;
    updateAccountDataLibraryMetadata: GqlClientUpdateAccountDataLibraryMetadataOutput;
    updateAccountFeatureData: GqlClientUpdateAccountFeatureDataOutput;
    updateAccountReport: GqlClientUpdateAccountReportOutput;
    updateAccountUserControlledFeatureData: GqlClientUpdateAccountUserControlledFeatureDataOutput;
    updateAdsAudience: GqlClientAdsAudience;
    updateAllAccountEditorProgramVersionThumbnailIfNeeded: GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededOutput;
    updateAnimation: GqlClientUpdateAnimationOutput;
    updateAsset: GqlClientUpdateAssetOutput;
    updateAudience: GqlClientUpdateAudienceOutput;
    updateAudienceWithDataElements: GqlClientUpdateAudienceWithDataElementsOutput;
    updateBasicFeedDataElement: GqlClientUpdateBasicFeedDataElementOutput;
    updateBcCollectionWithColorsAndTextStyles: GqlClientUpdateBcCollectionWithColorsAndTextStylesOutput;
    updateCampaignSettings: GqlClientCampaignSettings;
    updateCampaignStatus: GqlClientCampaignSettings;
    updateCcAnimatedWireframe: GqlClientUpdateCcAnimatedWireframeOutput;
    updateCcAnimatedWireframeCrossAspectRatioMapping: GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingOutput;
    updateCcAnimatedWireframeSetApprovedVersionFromDraft: GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftOutput;
    updateCcAnimatedWireframeSetDraftVersionFromApproved: GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedOutput;
    updateCcTheme: GqlClientUpdateCcThemeOutput;
    updateCcWireframe: GqlClientUpdateCcWireframeOutput;
    updateChannel: GqlClientUpdateChannelOutput;
    updateCreative: GqlClientUpdateCreativeOutput;
    updateCreativeAsset: GqlClientUpdateCreativeAssetOutput;
    updateCreativeDataElement: GqlClientUpdateCreativeDataElementOutput;
    updateCreativeDraft: GqlClientUpdateCreativeDraftOutput;
    updateCreativeDraftDefinitions: GqlClientUpdateCreativeDraftDefinitionsOutput;
    updateCreativeNarrationRecording: GqlClientUpdateCreativeNarrationRecordingOutput;
    updateCreativeNarrationRecordingVersion: GqlClientUpdateCreativeNarrationRecordingVersionOutput;
    updateCustomAnalyticField: GqlClientUpdateCustomAnalyticFieldOutput;
    updateDataTable: GqlClientUpdateDataTableOutput;
    updateDerivedDataElement: GqlClientUpdateDerivedDataElementOutput;
    updateEditorAccountUser: GqlClientUpdateEditorAccountUserOutput;
    updateEditorAssetMedia: GqlClientUpdateEditorAssetMediaOutput;
    updateEditorAvatarPlaceholder: GqlClientUpdateEditorAvatarPlaceholderOutput;
    updateEditorChaptering: GqlClientUpdateEditorChapteringOutput;
    updateEditorIsArchived: GqlClientUpdateEditorIsArchivedOutput;
    updateEditorIsLive: GqlClientUpdateEditorIsLiveOutput;
    updateEditorLineup: GqlClientUpdateEditorLineupOutput;
    updateEditorNarrationLibrary: GqlClientUpdateEditorNarrationLibraryOutput;
    updateEditorPlaceholder: GqlClientUpdateEditorPlaceholderOutput;
    updateEditorProgramLifecycleStage: GqlClientUpdateEditorProgramLifecycleStageOutput;
    updateEditorProgramName: GqlClientUpdateEditorProgramNameOutput;
    updateEditorProgramTheme: GqlClientUpdateEditorProgramThemeOutput;
    updateEditorProgramVersionAspectRatio: GqlClientUpdateEditorProgramVersionAspectRatioOutput;
    updateEditorProgramVersionBrandRules: GqlClientUpdateEditorProgramVersionBrandRulesOutput;
    updateEditorProgramVersionThumbnailIfNeeded: GqlClientUpdateEditorProgramVersionThumbnailIfNeededOutput;
    updateEditorScene: GqlClientUpdateEditorSceneOutput;
    updateEditorSceneSelectedAnimatedWireframe: GqlClientUpdateEditorSceneSelectedAnimatedWireframeOutput;
    updateEditorSceneWithData: GqlClientUpdateEditorSceneWithDataOutput;
    updateEditorSelectedBcCollection: GqlClientUpdateEditorSelectedBcCollectionOutput;
    updateEditorSelectedMusic: GqlClientUpdateEditorSelectedMusicOutput;
    updateEditorSelectedNarrator: GqlClientUpdateEditorSelectedNarratorOutput;
    updateEditorTtsFullEnablement: GqlClientUpdateEditorTtsFullEnablementOutput;
    updateEditorVideoCustomBackground: GqlClientUpdateEditorVideoCustomBackgroundOutput;
    updateEditorViewerProfile: GqlClientUpdateEditorViewerProfileOutput;
    updateExperiment: GqlClientUpdateExperimentOutput;
    updateFeedDataElement: GqlClientUpdateFeedDataElementOutput;
    updateGoalWithKPIs: GqlClientUpdateGoalWithKpIsOutput;
    updateMasterAnimationGuideline: GqlClientUpdateMasterAnimationGuidelineOutput;
    updateMasterData: GqlClientUpdateMasterDataOutput;
    updateMediaLibraryGridColumns: GqlClientUpdateMediaLibraryGridColumnsOutput;
    updateMediaLibraryViewType: GqlClientUpdateMediaLibraryViewTypeOutput;
    updateNarration: GqlClientUpdateNarrationOutput;
    updateNarrationOrder: GqlClientUpdateNarrationOrderOutput;
    updateNarrationRecording: GqlClientUpdateNarrationRecordingOutput;
    updateNarrationRecordingVersion: GqlClientUpdateNarrationRecordingVersionOutput;
    updateNarrationsDtaasPrefixKey: GqlClientUpdateNarrationsDtaasPrefixKeyOutput;
    updateNarrator: GqlClientUpdateNarratorOutput;
    updatePlaceholderGroupLogic: GqlClientUpdatePlaceholderGroupLogicOutput;
    updatePreset: GqlClientUpdatePresetOutput;
    updatePrioritizedListLogic: GqlClientUpdatePrioritizedListLogicOutput;
    updateProgram: GqlClientUpdateProgramOutput;
    updateProgramAndDraft: GqlClientUpdateProgramAndDraftOutput;
    updateProgramLastSnapshotNumber: GqlClientUpdateProgramLastSnapshotNumberOutput;
    updateProgramReview: GqlClientUpdateProgramReviewOutput;
    updateProgramVersionDraft: GqlClientUpdateProgramVersionDraftOutput;
    updateProgramVersionSnapshot: GqlClientUpdateProgramVersionSnapshotOutput;
    updateProjectAudioSettings: GqlClientUpdateProjectAudioSettingsOutput;
    updatePronunciationDefinition: GqlClientUpdatePronunciationDefinitionOutput;
    updateScene: GqlClientUpdateSceneOutput;
    updateSceneEntityLogic: GqlClientUpdateSceneEntityLogicOutput;
    updateScenePart: GqlClientUpdateScenePartOutput;
    updateScenePartWithAllPlaceholders: GqlClientUpdateScenePartWithAllPlaceholdersOutput;
    updateScenePlaceholdersAndAnimatedWireframe: GqlClientUpdateScenePlaceholdersAndAnimatedWireframeOutput;
    updateSceneWithKPIsPersonalizationsAndDataElements: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsOutput;
    updateStoryForBuilder: GqlClientUpdateStoryForBuilderOutput;
    updateStoryForFramework: GqlClientUpdateStoryForFrameworkOutput;
    updateStoryWithAllDecisionPoints: GqlClientUpdateStoryWithAllDecisionPointsOutput;
    updateStoryWithDecisionPoints: GqlClientUpdateStoryWithDecisionPointsOutput;
    updateStoryWithNewScene: GqlClientUpdateStoryWithNewSceneOutput;
    updateStoryWithReachAndScenes: GqlClientUpdateStoryWithReachAndScenesOutput;
    updateSurvey: GqlClientUpdateSurveyOutput;
    updateTierFeatureDefinition: GqlClientUpdateTierFeatureDefinitionOutput;
    updateTouchpoint: GqlClientUpdateTouchpointOutput;
    updateTrayConnectorAuthentication: GqlClientUpdateTrayConnectorAuthenticationOutput;
    upgradeEditorProgramToM2Layouts: GqlClientUpgradeEditorProgramToM2LayoutsOutput;
    uploadAdsFile: GqlClientUploadAdsFileOutput;
    uploadCustomThumbnail: GqlClientUploadCustomThumbnailOutput;
    uploadEditorAssetFont: GqlClientUploadEditorAssetFontOutput;
    uploadEditorAssetMedia: GqlClientUploadEditorAssetMediaOutput;
    uploadEditorAssetMediaFromUrl: GqlClientUploadEditorAssetMediaFromUrlOutput;
    uploadEditorAssetMusic: GqlClientUploadEditorAssetMusicOutput;
    uploadExternalEditorAssetImage: GqlClientUploadExternalEditorAssetMediaOutput;
    uploadExternalEditorAssetVideo: GqlClientUploadExternalEditorAssetMediaOutput;
    uploadOptimizedEditorAssetMedia: GqlClientUploadOptimizedEditorAssetMediaOutput;
    uploadRecordingToS3: GqlClientUploadRecordingToS3Output;
    uploadStockEditorAssetMusic: GqlClientUploadStockEditorAssetMusicOutput;
    upsertHelpCenterEntry?: Maybe<GqlClientUpsertHelpCenterEntryOutput>;
    upsertRPTVUser?: Maybe<GqlClientUpsertRptvUserOutput>;
    upsertUserSettings: GqlClientUpsertUserSettingsOutput;
};

export type GqlClientMutationAccountsSyncTriggerArgs = {
    isFullSync: Scalars["Boolean"]["input"];
};

export type GqlClientMutationAddCreativeLifecycleHistoryEntryArgs = {
    input: GqlClientAddCreativeLifecycleHistoryEntryInput;
};

export type GqlClientMutationAddLifecycleHistoryEntryArgs = {
    input: GqlClientAddLifecycleHistoryEntryInput;
};

export type GqlClientMutationAddStoryTemplateToProductionGalleryArgs = {
    input: GqlClientAddStoryTemplateToProductionGalleryInput;
};

export type GqlClientMutationApproveAccountInvitationArgs = {
    input: GqlClientApproveAccountInvitationInput;
};

export type GqlClientMutationCompleteFirstLoginArgs = {
    input: GqlClientCompleteFirstLoginInput;
};

export type GqlClientMutationConvertFeedDataElementToDerivedArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientConvertFeedDataElementToDerivedInput;
};

export type GqlClientMutationCopyAccountReportArgs = {
    input: GqlClientCopyAccountReportInput;
};

export type GqlClientMutationCopyCreativeVersionDraftArgs = {
    input: GqlClientCopyCreativeVersionDraftInput;
};

export type GqlClientMutationCopyCreativeWithDraftArgs = {
    input: GqlClientCopyCreativeWithDraftInput;
};

export type GqlClientMutationCopyDraftEditorProgramBetweenAccountsArgs = {
    input: GqlClientCopyDraftEditorProgramBetweenAccountsInput;
};

export type GqlClientMutationCopyEditorProgramArgs = {
    input: GqlClientCopyProgramInput;
};

export type GqlClientMutationCopyEditorProgramAsEditorArgs = {
    input: GqlClientCopyProgramInput;
};

export type GqlClientMutationCopyNarrationArgs = {
    input: GqlClientCopyNarrationInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationCopyProgramArgs = {
    input: GqlClientCopyProgramInput;
};

export type GqlClientMutationCopyProgramVersionDraftArgs = {
    input: GqlClientCopyProgramVersionDraftInput;
};

export type GqlClientMutationCopySceneArgs = {
    input: GqlClientCopySceneInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationCopyScenePartArgs = {
    input: GqlClientCopyScenePartInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationCopyStoryArgs = {
    input: GqlClientCopyStoryInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationCreateAccountCopilotBriefArgs = {
    input: GqlClientCreateAccountCopilotBriefInput;
};

export type GqlClientMutationCreateAccountProgramFolderArgs = {
    input: GqlClientCreateAccountProgramFolderInput;
};

export type GqlClientMutationCreateAccountReportArgs = {
    input: GqlClientCreateAccountReportInput;
};

export type GqlClientMutationCreateAdsAudienceArgs = {
    input: GqlClientCreateAdsAudienceInput;
};

export type GqlClientMutationCreateAiPreviewProgramArgs = {
    input: GqlClientCreateAiPreviewProgramInput;
    mutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationCreateAnimationVersionsArgs = {
    input: GqlClientCreateAnimationVersionsInput;
};

export type GqlClientMutationCreateAnimationWithVersionsArgs = {
    input: GqlClientCreateAnimationWithVersionsInput;
};

export type GqlClientMutationCreateAssetRequestArgs = {
    input: GqlClientCreateAssetRequestInput;
};

export type GqlClientMutationCreateAssetVersionArgs = {
    input: GqlClientCreateAssetVersionInput;
};

export type GqlClientMutationCreateAssetWithVersionArgs = {
    input: GqlClientCreateAssetWithVersionInput;
};

export type GqlClientMutationCreateAudienceArgs = {
    input: GqlClientCreateAudienceInput;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientMutationCreateAudienceWithDataElementsArgs = {
    input: GqlClientCreateAudienceWithDataElementsInput;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientMutationCreateBasicFeedDataElementArgs = {
    input: GqlClientCreateBasicFeedDataElementInput;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientMutationCreateBcCollectionArgs = {
    input: GqlClientCreateBcCollectionInput;
};

export type GqlClientMutationCreateCampaignSettingsArgs = {
    input: GqlClientCreateCampaignSettingsInput;
    programSfId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationCreateCcAnimatedWireframeArgs = {
    input: GqlClientCreateCcAnimatedWireframeInput;
};

export type GqlClientMutationCreateCcLibraryArgs = {
    input: GqlClientCreateCcLibraryInput;
};

export type GqlClientMutationCreateCcLibraryDraftArgs = {
    input?: InputMaybe<GqlClientCreateCcLibraryDraftInput>;
};

export type GqlClientMutationCreateCcMusicArgs = {
    input: GqlClientCreateCcMusicInput;
};

export type GqlClientMutationCreateCcNarratorArgs = {
    input: GqlClientCreateCcNarratorInput;
};

export type GqlClientMutationCreateCcPlaceholderIntentArgs = {
    input: GqlClientCreateCcPlaceholderIntentInput;
};

export type GqlClientMutationCreateCcSnapshotArgs = {
    input: GqlClientCreateCcSnapshotInput;
};

export type GqlClientMutationCreateCcThemeArgs = {
    input: GqlClientCreateCcThemeInput;
};

export type GqlClientMutationCreateCcWireframeArgs = {
    input: GqlClientCreateCcWireframeInput;
};

export type GqlClientMutationCreateChannelArgs = {
    input: GqlClientCreateChannelInput;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientMutationCreateCreativeAndDraftArgs = {
    input: GqlClientCreateCreativeAndDraftInput;
};

export type GqlClientMutationCreateCreativeAssetArgs = {
    input: GqlClientCreateCreativeAssetInput;
};

export type GqlClientMutationCreateCreativeAssetVersionArgs = {
    input: GqlClientCreateCreativeAssetVersionInput;
};

export type GqlClientMutationCreateCreativeAssetWithVersionArgs = {
    input: GqlClientCreateCreativeAssetWithVersionInput;
};

export type GqlClientMutationCreateCreativeDataElementArgs = {
    input: GqlClientCreateCreativeDataElementInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientMutationCreateCreativeNarrationRecordingVersionArgs = {
    input: GqlClientCreateCreativeNarrationRecordingVersionInput;
};

export type GqlClientMutationCreateCreativeVersionDraftArgs = {
    input: GqlClientCreateCreativeVersionDraftInput;
};

export type GqlClientMutationCreateCustomAnalyticFieldArgs = {
    input: GqlClientCreateCustomAnalyticFieldInput;
};

export type GqlClientMutationCreateDataLibraryArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientCreateDataLibraryInput;
};

export type GqlClientMutationCreateDataTableArgs = {
    input: GqlClientCreateDataTableInput;
};

export type GqlClientMutationCreateDerivedDataElementArgs = {
    input: GqlClientCreateDerivedDataElementInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientMutationCreateEditorAccountUserArgs = {
    input: GqlClientCreateEditorAccountUserInput;
};

export type GqlClientMutationCreateEditorAssetFolderArgs = {
    input: GqlClientCreateEditorAssetFolderInput;
};

export type GqlClientMutationCreateEditorAvatarArgs = {
    input: GqlClientCreateEditorAvatarInput;
};

export type GqlClientMutationCreateEditorFlexiblePlaceholderArgs = {
    input: GqlClientCreateEditorFlexiblePlaceholderInput;
};

export type GqlClientMutationCreateEditorFlexiblePlaceholderWithDataArgs = {
    input: GqlClientCreateEditorFlexiblePlaceholderWithDataInput;
};

export type GqlClientMutationCreateEditorLifecycleEntryArgs = {
    input: GqlClientCreateEditorLifecycleEntryInput;
};

export type GqlClientMutationCreateEditorOverlayPlaceholderArgs = {
    input: GqlClientCreateEditorOverlayPlaceholderInput;
};

export type GqlClientMutationCreateEditorProgramArgs = {
    input: GqlClientCreateEditorProgramInput;
};

export type GqlClientMutationCreateEditorProgramFromStoryTemplateArgs = {
    input: GqlClientCreateEditorProgramFromStoryTemplateInput;
};

export type GqlClientMutationCreateEditorProgramSnapshotArgs = {
    input: GqlClientCreateEditorProgramSnapshotInput;
    mutationId: Scalars["String"]["input"];
};

export type GqlClientMutationCreateEditorSceneWithDataArgs = {
    input: GqlClientCreateEditorSceneWithDataInput;
};

export type GqlClientMutationCreateEditorScenesArgs = {
    input: GqlClientCreateEditorScenesInput;
};

export type GqlClientMutationCreateEditorViewerProfileArgs = {
    input: GqlClientCreateEditorViewerProfileInput;
};

export type GqlClientMutationCreateEditorViewerProfileWithDataArgs = {
    input: GqlClientCreateEditorViewerProfileWithDataInput;
};

export type GqlClientMutationCreateExperimentArgs = {
    input: GqlClientCreateExperimentInput;
};

export type GqlClientMutationCreateFeedDataElementArgs = {
    input: GqlClientCreateFeedDataElementInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientMutationCreateGoalWithKpIsArgs = {
    input: GqlClientCreateGoalWithKpIsInput;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientMutationCreateMasterAnimationGuidelineArgs = {
    input: GqlClientCreateMasterAnimationGuidelineInput;
};

export type GqlClientMutationCreateNarrationArgs = {
    input: GqlClientCreateNarrationInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationCreateNarrationRecordingVersionArgs = {
    input: GqlClientCreateNarrationRecordingVersionInput;
};

export type GqlClientMutationCreateNarratorArgs = {
    input: GqlClientCreateNarratorInput;
};

export type GqlClientMutationCreateNextRecommendedVideoArgs = {
    input: GqlClientCreateNextRecommendedVideoInput;
    mutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationCreateOrUpdateAccountDataFieldsArgs = {
    input: GqlClientCreateOrUpdateAccountDataFieldsInput;
};

export type GqlClientMutationCreateOrUpdateEditorApplicationArgs = {
    input: GqlClientCreateOrUpdateEditorApplicationInput;
};

export type GqlClientMutationCreateOrUpdateEmbedVideoCodeArgs = {
    input: GqlClientCreateOrUpdateEmbedVideoCodeInput;
};

export type GqlClientMutationCreateOrUpdateLandingPageArgs = {
    input: GqlClientCreateOrUpdateLandingPageInput;
};

export type GqlClientMutationCreatePresetArgs = {
    input: GqlClientCreatePresetInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientMutationCreateProgramArgs = {
    input: GqlClientCreateProgramInput;
};

export type GqlClientMutationCreateProgramAndBuilderDraftArgs = {
    input: GqlClientCreateProgramAndBuilderDraftInput;
    legacyAudienceDataElementId?: InputMaybe<Scalars["String"]["input"]>;
    legacyBuilderProgramId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationCreateProgramAndDraftArgs = {
    input: GqlClientCreateProgramAndDraftInput;
};

export type GqlClientMutationCreateProgramReviewArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientCreateProgramReviewInput;
};

export type GqlClientMutationCreateProgramVersionDraftArgs = {
    input: GqlClientCreateProgramVersionDraftInput;
    legacyAudienceDataElementId?: InputMaybe<Scalars["String"]["input"]>;
    legacyBuilderProgramId?: InputMaybe<Scalars["String"]["input"]>;
    programId: Scalars["ID"]["input"];
};

export type GqlClientMutationCreateProgramVersionDraftFromSnapshotArgs = {
    input: GqlClientCreateProgramVersionDraftFromSnapshotInput;
};

export type GqlClientMutationCreateProgramVersionSnapshotArgs = {
    input: GqlClientCreateProgramVersionSnapshotInput;
};

export type GqlClientMutationCreatePronunciationDefinitionArgs = {
    input: GqlClientCreatePronunciationDefinitionInput;
};

export type GqlClientMutationCreatePronunciationDefinitionWithDataArgs = {
    input: GqlClientCreatePronunciationDefinitionWithDataInput;
};

export type GqlClientMutationCreateRfrVersionArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientCreateRfrVersionInput;
};

export type GqlClientMutationCreateRecordingDocArgs = {
    input: GqlClientCreateRecordingDocInput;
};

export type GqlClientMutationCreateSceneFromPromptArgs = {
    input: GqlClientCreateSceneFromPromptInput;
    mutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationCreateScenePartArgs = {
    input: GqlClientCreateScenePartInput;
};

export type GqlClientMutationCreateSceneWithKpIsPersonalizationsAndDataElementsArgs = {
    input: GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsInput;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientMutationCreateSceneWithScenePartArgs = {
    input: GqlClientCreateSceneWithScenePartInput;
};

export type GqlClientMutationCreateShareHistoryRecordArgs = {
    input: GqlClientCreateShareHistoryRecordInput;
};

export type GqlClientMutationCreateStoryWithDecisionPointAndBackgroundAssetsArgs = {
    input: GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationCreateStoryWithReachAndScenesArgs = {
    input: GqlClientCreateStoryWithReachAndScenesInput;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientMutationCreateSurveyArgs = {
    input: GqlClientCreateSurveyInput;
};

export type GqlClientMutationCreateTouchpointArgs = {
    input: GqlClientCreateTouchpointInput;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientMutationCustomAvatarsCreditBalanceEmailArgs = {
    input: GqlClientCustomAvatarsCreditBalanceEmailInput;
};

export type GqlClientMutationDataLibraryImportDataArgs = {
    input: GqlClientDataLibraryImportDataInput;
    mutationId: Scalars["String"]["input"];
};

export type GqlClientMutationDeleteAccountCopilotBriefArgs = {
    input: GqlClientDeleteAccountCopilotBriefInput;
};

export type GqlClientMutationDeleteAccountInvitationArgs = {
    input: GqlClientDeleteAccountInvitationInput;
};

export type GqlClientMutationDeleteAccountProgramFolderArgs = {
    input: GqlClientDeleteAccountProgramFolderInput;
};

export type GqlClientMutationDeleteAccountReportArgs = {
    input: GqlClientDeleteAccountReportInput;
};

export type GqlClientMutationDeleteAudienceArgs = {
    input: GqlClientDeleteAudienceInput;
};

export type GqlClientMutationDeleteBrandCollectionArgs = {
    input: GqlClientDeleteBrandCollectionInput;
};

export type GqlClientMutationDeleteCcAnimatedWireframeArgs = {
    input: GqlClientDeleteCcAnimatedWireframeInput;
};

export type GqlClientMutationDeleteCcAnimatedWireframeCrossAspectRatioMappingArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingInput;
};

export type GqlClientMutationDeleteCcThemeArgs = {
    input: GqlClientDeleteCcThemeInput;
};

export type GqlClientMutationDeleteCcWireframeArgs = {
    input: GqlClientDeleteCcWireframeInput;
};

export type GqlClientMutationDeleteChannelArgs = {
    input: GqlClientDeleteChannelInput;
};

export type GqlClientMutationDeleteCreativeAssetArgs = {
    input: GqlClientDeleteCreativeAssetInput;
};

export type GqlClientMutationDeleteCreativeVersionArgs = {
    input: GqlClientDeleteCreativeVersionInput;
};

export type GqlClientMutationDeleteCreativesArgs = {
    input: GqlClientDeleteCreativesInput;
};

export type GqlClientMutationDeleteCustomAnalyticFieldArgs = {
    input: GqlClientDeleteCustomAnalyticFieldInput;
};

export type GqlClientMutationDeleteDataElementArgs = {
    input: GqlClientDeleteDataElementInput;
};

export type GqlClientMutationDeleteDataLibraryArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientDeleteDataLibraryInput;
};

export type GqlClientMutationDeleteDataTableArgs = {
    input: GqlClientDeleteDataTableInput;
};

export type GqlClientMutationDeleteEditorAccountUserArgs = {
    input: GqlClientDeleteEditorAccountUserInput;
};

export type GqlClientMutationDeleteEditorAssetFolderArgs = {
    input: GqlClientDeleteEditorAssetFolderInput;
};

export type GqlClientMutationDeleteEditorAssetMediaArgs = {
    input: GqlClientDeleteEditorAssetMediaInput;
};

export type GqlClientMutationDeleteEditorFlexiblePlaceholderArgs = {
    input: GqlClientDeleteEditorFlexiblePlaceholderInput;
};

export type GqlClientMutationDeleteEditorProgramArgs = {
    input: GqlClientDeleteEditorProgramInput;
};

export type GqlClientMutationDeleteEditorProgramAllVersionsArgs = {
    input: GqlClientDeleteEditorProgramAllVersionsInput;
};

export type GqlClientMutationDeleteEditorSceneArgs = {
    input: GqlClientDeleteEditorSceneInput;
};

export type GqlClientMutationDeleteEditorViewerProfileArgs = {
    input: GqlClientDeleteEditorViewerProfileInput;
};

export type GqlClientMutationDeleteFailedAvatarArgs = {
    input?: InputMaybe<GqlClientDeleteFailedAvatarInput>;
};

export type GqlClientMutationDeleteGoalArgs = {
    input: GqlClientDeleteGoalInput;
};

export type GqlClientMutationDeleteMasterAnimationGuidelineArgs = {
    input: GqlClientDeleteMasterAnimationGuidelineInput;
};

export type GqlClientMutationDeleteNarrationArgs = {
    input: GqlClientDeleteNarrationInput;
};

export type GqlClientMutationDeletePlaceholderGroupLogicArgs = {
    input: GqlClientDeletePlaceholderGroupLogicInput;
};

export type GqlClientMutationDeletePreSubmittedAvatarArgs = {
    input?: InputMaybe<GqlClientDeletePreSubmittedAvatarInput>;
};

export type GqlClientMutationDeletePresetArgs = {
    input: GqlClientDeletePresetInput;
};

export type GqlClientMutationDeletePrioritizedListLogicArgs = {
    input: GqlClientDeletePrioritizedListLogicInput;
};

export type GqlClientMutationDeleteProgramArgs = {
    input: GqlClientDeleteProgramInput;
};

export type GqlClientMutationDeleteProgramAndDraftArgs = {
    input: GqlClientDeleteProgramAndDraftInput;
};

export type GqlClientMutationDeleteProgramVersionDraftArgs = {
    input: GqlClientDeleteProgramVersionDraftInput;
};

export type GqlClientMutationDeletePronunciationDefinitionArgs = {
    input: GqlClientDeletePronunciationDefinitionInput;
};

export type GqlClientMutationDeleteSceneArgs = {
    input: GqlClientDeleteSceneInput;
};

export type GqlClientMutationDeleteSceneEntityLogicArgs = {
    input: GqlClientDeleteSceneEntityLogicInput;
};

export type GqlClientMutationDeleteScenePartArgs = {
    input: GqlClientDeleteScenePartInput;
};

export type GqlClientMutationDeleteStoryArgs = {
    input: GqlClientDeleteStoryInput;
};

export type GqlClientMutationDeleteSurveyArgs = {
    input: GqlClientDeleteSurveyInput;
};

export type GqlClientMutationDeleteTouchpointArgs = {
    input: GqlClientDeleteTouchpointInput;
};

export type GqlClientMutationDisconnectTrayConnectorArgs = {
    input: GqlClientDisconnectTrayConnectorInput;
};

export type GqlClientMutationDuplicateCampaignSettingsArgs = {
    input: GqlClientDuplicateCampaignSettingsInput;
};

export type GqlClientMutationDuplicateEditorSceneInLineupArgs = {
    input: GqlClientDuplicateEditorSceneInLineupInput;
};

export type GqlClientMutationEditEditorAssetMediaArgs = {
    input: GqlClientEditEditorAssetMediaInput;
    mutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationEditorAssetMediaSaveAsArgs = {
    input: GqlClientEditorAssetMediaSaveAsInput;
};

export type GqlClientMutationExportAccountDataArgs = {
    input: GqlClientExportAccountDataInput;
};

export type GqlClientMutationGenerateAiVideoFromImageArgs = {
    input: GqlClientAiImageToVideoInput;
    mutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationMoveAccountProgramFolderToFolderArgs = {
    input: GqlClientMoveAccountProgramFolderToFolderInput;
};

export type GqlClientMutationMoveCampaignSettingsArgs = {
    input: GqlClientMoveCampaignSettingsInput;
};

export type GqlClientMutationMoveEditorAssetToFolderArgs = {
    input: GqlClientMoveEditorAssetToFolderInput;
};

export type GqlClientMutationMoveEditorFolderToFolderArgs = {
    input: GqlClientMoveEditorFolderToFolderInput;
};

export type GqlClientMutationMoveProgramToFolderArgs = {
    input: GqlClientMoveProgramToFolderInput;
};

export type GqlClientMutationMoveProgramVersionDraftArgs = {
    input: GqlClientMoveProgramVersionDraftInput;
};

export type GqlClientMutationPostToSocialNetworkArgs = {
    input: GqlClientPostToSocialNetworkInput;
};

export type GqlClientMutationPromotePreSubmittedAvatarArgs = {
    input?: InputMaybe<GqlClientPromotePreSubmittedAvatarInput>;
};

export type GqlClientMutationPromoteSubmittedAvatarArgs = {
    input: GqlClientPromoteSubmittedAvatarInput;
    mutationId: Scalars["String"]["input"];
};

export type GqlClientMutationRecalculateEditorNarrationRecordingStateArgs = {
    input: GqlClientRecalculateEditorNarrationRecordingStateInput;
    mutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationRemoveConnectorFromAccountArgs = {
    input: GqlClientRemoveConnectorFromAccountInput;
};

export type GqlClientMutationRemoveEditorProgramVersionLogicAndDataLibraryArgs = {
    input: GqlClientRemoveEditorProgramVersionLogicAndDataLibraryInput;
};

export type GqlClientMutationRemoveServiceFromAccountArgs = {
    input: GqlClientRemoveServiceFromAccountInput;
};

export type GqlClientMutationRemoveStoryTemplateFromGalleryArgs = {
    input: GqlClientRemoveStoryTemplateFromGalleryInput;
};

export type GqlClientMutationRenameAccountProgramFolderArgs = {
    input: GqlClientRenameAccountProgramFolderInput;
};

export type GqlClientMutationRenameEditorAssetFolderArgs = {
    input: GqlClientRenameEditorAssetFolderInput;
};

export type GqlClientMutationRenameProgramReviewArgs = {
    input: GqlClientRenameProgramReviewInput;
};

export type GqlClientMutationSaveUserRecentSearchArgs = {
    input: GqlClientSaveUserRecentSearchInput;
};

export type GqlClientMutationSelectDataLibraryArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientSelectDataLibraryInput;
};

export type GqlClientMutationSendAccountReportArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientSendAccountReportInput;
};

export type GqlClientMutationSetAnalyticsDashboardArgs = {
    input: GqlClientSetAnalyticsDashboardInput;
};

export type GqlClientMutationSetCcActiveSnapshotArgs = {
    input: GqlClientSetCcActiveSnapshotInput;
};

export type GqlClientMutationSetSurveysArgs = {
    input: GqlClientSetSurveysInput;
};

export type GqlClientMutationSetTrackingParametersArgs = {
    input: GqlClientSetTrackingParametersInput;
};

export type GqlClientMutationSwitchBcCollectionAndDeleteCurrentArgs = {
    input: GqlClientSwitchBcCollectionAndDeleteCurrentInput;
};

export type GqlClientMutationUndoActionArgs = {
    input: GqlClientUndoActionInput;
};

export type GqlClientMutationUndoDeleteBcCollectionArgs = {
    input: GqlClientUndoDeleteBcCollectionInput;
};

export type GqlClientMutationUndoDeleteEditorAssetFolderArgs = {
    input: GqlClientUndoDeleteEditorAssetFolderInput;
};

export type GqlClientMutationUndoDeleteEditorAssetMediaArgs = {
    input: GqlClientUndoDeleteEditorAssetMediaInput;
};

export type GqlClientMutationUndoUpdateEditorProgramThemeArgs = {
    input: GqlClientUndoUpdateEditorProgramThemeInput;
};

export type GqlClientMutationUnselectDataLibraryArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUnselectDataLibraryInput;
};

export type GqlClientMutationUpdateAccountCopilotBriefArgs = {
    input: GqlClientUpdateAccountCopilotBriefInput;
};

export type GqlClientMutationUpdateAccountDataLibraryConnectorTypeAndMappingArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingInput;
};

export type GqlClientMutationUpdateAccountDataLibraryMetadataArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateAccountDataLibraryMetadataInput;
};

export type GqlClientMutationUpdateAccountFeatureDataArgs = {
    input: GqlClientUpdateAccountFeatureDataInput;
};

export type GqlClientMutationUpdateAccountReportArgs = {
    input: GqlClientUpdateAccountReportInput;
};

export type GqlClientMutationUpdateAccountUserControlledFeatureDataArgs = {
    input: GqlClientUpdateAccountUserControlledFeatureDataInput;
};

export type GqlClientMutationUpdateAdsAudienceArgs = {
    input: GqlClientUpdateAdsAudienceInput;
};

export type GqlClientMutationUpdateAnimationArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateAnimationInput;
};

export type GqlClientMutationUpdateAssetArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateAssetInput;
};

export type GqlClientMutationUpdateAudienceArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateAudienceInput;
};

export type GqlClientMutationUpdateAudienceWithDataElementsArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateAudienceWithDataElementsInput;
};

export type GqlClientMutationUpdateBasicFeedDataElementArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateBasicFeedDataElementInput;
};

export type GqlClientMutationUpdateBcCollectionWithColorsAndTextStylesArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateBcCollectionWithColorsAndTextStylesInput;
};

export type GqlClientMutationUpdateCampaignSettingsArgs = {
    input: GqlClientUpdateCampaignSettingsInput;
};

export type GqlClientMutationUpdateCampaignStatusArgs = {
    input: GqlClientUpdateCampaignStatusInput;
};

export type GqlClientMutationUpdateCcAnimatedWireframeArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCcAnimatedWireframeInput;
};

export type GqlClientMutationUpdateCcAnimatedWireframeCrossAspectRatioMappingArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingInput;
};

export type GqlClientMutationUpdateCcAnimatedWireframeSetApprovedVersionFromDraftArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftInput;
};

export type GqlClientMutationUpdateCcAnimatedWireframeSetDraftVersionFromApprovedArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedInput;
};

export type GqlClientMutationUpdateCcThemeArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCcThemeInput;
};

export type GqlClientMutationUpdateCcWireframeArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCcWireframeInput;
};

export type GqlClientMutationUpdateChannelArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateChannelInput;
};

export type GqlClientMutationUpdateCreativeArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCreativeInput;
};

export type GqlClientMutationUpdateCreativeAssetArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCreativeAssetInput;
};

export type GqlClientMutationUpdateCreativeDataElementArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCreativeDataElementInput;
};

export type GqlClientMutationUpdateCreativeDraftArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCreativeDraftInput;
};

export type GqlClientMutationUpdateCreativeDraftDefinitionsArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCreativeDraftDefinitionsInput;
};

export type GqlClientMutationUpdateCreativeNarrationRecordingArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCreativeNarrationRecordingInput;
};

export type GqlClientMutationUpdateCreativeNarrationRecordingVersionArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCreativeNarrationRecordingVersionInput;
};

export type GqlClientMutationUpdateCustomAnalyticFieldArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateCustomAnalyticFieldInput;
};

export type GqlClientMutationUpdateDataTableArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateDataTableInput;
};

export type GqlClientMutationUpdateDerivedDataElementArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateDerivedDataElementInput;
};

export type GqlClientMutationUpdateEditorAccountUserArgs = {
    input: GqlClientUpdateEditorAccountUserInput;
};

export type GqlClientMutationUpdateEditorAssetMediaArgs = {
    input: GqlClientUpdateEditorAssetMediaInput;
    mutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationUpdateEditorAvatarPlaceholderArgs = {
    input: GqlClientUpdateEditorAvatarPlaceholderInput;
};

export type GqlClientMutationUpdateEditorChapteringArgs = {
    input: GqlClientUpdateEditorChapteringInput;
};

export type GqlClientMutationUpdateEditorIsArchivedArgs = {
    input: GqlClientUpdateEditorIsArchivedInput;
};

export type GqlClientMutationUpdateEditorIsLiveArgs = {
    input: GqlClientUpdateEditorIsLiveInput;
};

export type GqlClientMutationUpdateEditorLineupArgs = {
    input: GqlClientUpdateEditorLineupInput;
};

export type GqlClientMutationUpdateEditorNarrationLibraryArgs = {
    input: GqlClientUpdateEditorNarrationLibraryInput;
};

export type GqlClientMutationUpdateEditorPlaceholderArgs = {
    input: GqlClientUpdateEditorPlaceholderInput;
};

export type GqlClientMutationUpdateEditorProgramLifecycleStageArgs = {
    input: GqlClientUpdateEditorProgramLifecycleStageInput;
};

export type GqlClientMutationUpdateEditorProgramNameArgs = {
    input: GqlClientUpdateEditorProgramNameInput;
};

export type GqlClientMutationUpdateEditorProgramThemeArgs = {
    input: GqlClientUpdateEditorProgramThemeInput;
};

export type GqlClientMutationUpdateEditorProgramVersionAspectRatioArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateEditorProgramVersionAspectRatioInput;
};

export type GqlClientMutationUpdateEditorProgramVersionBrandRulesArgs = {
    input: GqlClientUpdateEditorProgramVersionBrandRulesInput;
};

export type GqlClientMutationUpdateEditorProgramVersionThumbnailIfNeededArgs = {
    input: GqlClientUpdateEditorProgramVersionThumbnailIfNeededInput;
};

export type GqlClientMutationUpdateEditorSceneArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateEditorSceneInput;
};

export type GqlClientMutationUpdateEditorSceneSelectedAnimatedWireframeArgs = {
    input: GqlClientUpdateEditorSceneSelectedAnimatedWireframeInput;
};

export type GqlClientMutationUpdateEditorSceneWithDataArgs = {
    input: GqlClientUpdateEditorSceneWithDataInput;
};

export type GqlClientMutationUpdateEditorSelectedBcCollectionArgs = {
    input: GqlClientUpdateEditorSelectedBcCollectionInput;
};

export type GqlClientMutationUpdateEditorSelectedMusicArgs = {
    input: GqlClientUpdateEditorSelectedMusicInput;
};

export type GqlClientMutationUpdateEditorSelectedNarratorArgs = {
    input: GqlClientUpdateEditorSelectedNarratorInput;
};

export type GqlClientMutationUpdateEditorTtsFullEnablementArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateEditorTtsFullEnablementInput;
};

export type GqlClientMutationUpdateEditorVideoCustomBackgroundArgs = {
    input: GqlClientVideoCustomBackgroundInput;
};

export type GqlClientMutationUpdateEditorViewerProfileArgs = {
    input: GqlClientUpdateEditorViewerProfileInput;
};

export type GqlClientMutationUpdateExperimentArgs = {
    input: GqlClientUpdateExperimentInput;
};

export type GqlClientMutationUpdateFeedDataElementArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateFeedDataElementInput;
};

export type GqlClientMutationUpdateGoalWithKpIsArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateGoalWithKpIsInput;
};

export type GqlClientMutationUpdateMasterAnimationGuidelineArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateMasterAnimationGuidelineInput;
};

export type GqlClientMutationUpdateMasterDataArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateMasterDataInput;
};

export type GqlClientMutationUpdateMediaLibraryGridColumnsArgs = {
    columns: Scalars["Int"]["input"];
};

export type GqlClientMutationUpdateMediaLibraryViewTypeArgs = {
    input: GqlClientUpdateMediaLibraryViewTypeInput;
};

export type GqlClientMutationUpdateNarrationArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateNarrationInput;
};

export type GqlClientMutationUpdateNarrationOrderArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateNarrationOrderInput;
};

export type GqlClientMutationUpdateNarrationRecordingArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateNarrationRecordingInput;
};

export type GqlClientMutationUpdateNarrationRecordingVersionArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateNarrationRecordingVersionInput;
};

export type GqlClientMutationUpdateNarrationsDtaasPrefixKeyArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateNarrationsDtaasPrefixKeyInput;
};

export type GqlClientMutationUpdateNarratorArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateNarratorInput;
};

export type GqlClientMutationUpdatePlaceholderGroupLogicArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdatePlaceholderGroupLogicInput;
};

export type GqlClientMutationUpdatePresetArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdatePresetInput;
};

export type GqlClientMutationUpdatePrioritizedListLogicArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdatePrioritizedListLogicInput;
};

export type GqlClientMutationUpdateProgramArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateProgramInput;
};

export type GqlClientMutationUpdateProgramAndDraftArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateProgramAndDraftInput;
};

export type GqlClientMutationUpdateProgramLastSnapshotNumberArgs = {
    input: GqlClientUpdateProgramLastSnapshotNumberInput;
};

export type GqlClientMutationUpdateProgramReviewArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateProgramReviewInput;
};

export type GqlClientMutationUpdateProgramVersionDraftArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateProgramVersionDraftInput;
};

export type GqlClientMutationUpdateProgramVersionSnapshotArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateProgramVersionSnapshotInput;
};

export type GqlClientMutationUpdateProjectAudioSettingsArgs = {
    input: GqlClientUpdateProjectAudioSettingsInput;
};

export type GqlClientMutationUpdatePronunciationDefinitionArgs = {
    input: GqlClientUpdatePronunciationDefinitionInput;
};

export type GqlClientMutationUpdateSceneArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateSceneInput;
};

export type GqlClientMutationUpdateSceneEntityLogicArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateSceneEntityLogicInput;
};

export type GqlClientMutationUpdateScenePartArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateScenePartInput;
};

export type GqlClientMutationUpdateScenePartWithAllPlaceholdersArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateScenePartWithAllPlaceholdersInput;
};

export type GqlClientMutationUpdateScenePlaceholdersAndAnimatedWireframeArgs = {
    input: GqlClientUpdateScenePlaceholdersAndAnimatedWireframeInput;
};

export type GqlClientMutationUpdateSceneWithKpIsPersonalizationsAndDataElementsArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsInput;
};

export type GqlClientMutationUpdateStoryForBuilderArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateStoryForBuilderInput;
};

export type GqlClientMutationUpdateStoryForFrameworkArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateStoryForFrameworkInput;
};

export type GqlClientMutationUpdateStoryWithAllDecisionPointsArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateStoryWithAllDecisionPointsInput;
};

export type GqlClientMutationUpdateStoryWithDecisionPointsArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateStoryWithDecisionPointsInput;
};

export type GqlClientMutationUpdateStoryWithNewSceneArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateStoryWithNewSceneInput;
    sceneLegacyId?: InputMaybe<Scalars["String"]["input"]>;
    scenePartLegacyId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientMutationUpdateStoryWithReachAndScenesArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateStoryWithReachAndScenesInput;
};

export type GqlClientMutationUpdateSurveyArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateSurveyInput;
};

export type GqlClientMutationUpdateTierFeatureDefinitionArgs = {
    input: GqlClientUpdateTierFeatureDefinitionInput;
};

export type GqlClientMutationUpdateTouchpointArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientUpdateTouchpointInput;
};

export type GqlClientMutationUpdateTrayConnectorAuthenticationArgs = {
    input: GqlClientUpdateTrayConnectorAuthenticationInput;
};

export type GqlClientMutationUpgradeEditorProgramToM2LayoutsArgs = {
    input: GqlClientUpgradeEditorProgramToM2LayoutsInput;
};

export type GqlClientMutationUploadAdsFileArgs = {
    input: GqlClientUploadAdsFileInput;
};

export type GqlClientMutationUploadCustomThumbnailArgs = {
    input: GqlClientUploadCustomThumbnailInput;
};

export type GqlClientMutationUploadEditorAssetFontArgs = {
    input: GqlClientUploadEditorAssetFontInput;
};

export type GqlClientMutationUploadEditorAssetMediaArgs = {
    input: GqlClientUploadEditorAssetMediaInput;
};

export type GqlClientMutationUploadEditorAssetMediaFromUrlArgs = {
    input: GqlClientUploadEditorAssetMediaFromUrlInput;
};

export type GqlClientMutationUploadEditorAssetMusicArgs = {
    input: GqlClientUploadEditorAssetMusicInput;
};

export type GqlClientMutationUploadExternalEditorAssetImageArgs = {
    input: GqlClientUploadExternalEditorAssetImageInput;
};

export type GqlClientMutationUploadExternalEditorAssetVideoArgs = {
    input: GqlClientUploadExternalEditorAssetVideoInput;
};

export type GqlClientMutationUploadOptimizedEditorAssetMediaArgs = {
    input: GqlClientUploadEditorAssetMediaInput;
    mutationId: Scalars["String"]["input"];
};

export type GqlClientMutationUploadRecordingToS3Args = {
    input: GqlClientUploadRecordingToS3Input;
    mutationId: Scalars["String"]["input"];
};

export type GqlClientMutationUploadStockEditorAssetMusicArgs = {
    input: GqlClientUploadStockEditorAssetMusicInput;
};

export type GqlClientMutationUpsertHelpCenterEntryArgs = {
    input?: InputMaybe<GqlClientUpsertHelpCenterEntryInput>;
};

export type GqlClientMutationUpsertRptvUserArgs = {
    email: Scalars["String"]["input"];
    tokens: Scalars["Int"]["input"];
};

export type GqlClientMutationUpsertUserSettingsArgs = {
    input: GqlClientUpsertUserSettingsInput;
};

export type GqlClientNpsSurveyAnalytics = {
    __typename?: "NPSSurveyAnalytics";
    surveyDetractorsAmount: Scalars["Int"]["output"];
    surveyNeutralAmount: Scalars["Int"]["output"];
    surveyPromotersAmount: Scalars["Int"]["output"];
};

export type GqlClientNarration = {
    __typename?: "Narration";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    dimensionData: Array<Scalars["JSON"]["output"]>;
    dtaasPrefixKey?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    lastUsedRecordingFileNumber: Scalars["Int"]["output"];
    mergeData: Array<Scalars["JSON"]["output"]>;
    narrationVariations: Array<GqlClientNarrationVariation>;
    representativeKey?: Maybe<Scalars["String"]["output"]>;
    scenePart?: Maybe<GqlClientScenePart>;
    supportsCreativeDataElementOverride?: Maybe<Scalars["Boolean"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientNarrationDuration = {
    __typename?: "NarrationDuration";
    duration: Scalars["Float"]["output"];
    isExact: Scalars["Boolean"]["output"];
};

export type GqlClientNarrationRecording = {
    __typename?: "NarrationRecording";
    accountId: Scalars["ID"]["output"];
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    filename: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    key: Scalars["String"]["output"];
    lastVersionNumber: Scalars["Int"]["output"];
    metadata?: Maybe<Scalars["JSON"]["output"]>;
    mockNarrationUrl: Scalars["String"]["output"];
    narrationId: Scalars["ID"]["output"];
    narrationRecordingActiveVersion?: Maybe<GqlClientNarrationRecordingVersion>;
    narrationRecordingVersion?: Maybe<GqlClientNarrationRecordingVersion>;
    narrationRecordingVersions: Array<GqlClientNarrationRecordingVersion>;
    programVersion: GqlClientProgramVersion;
    rfr: Scalars["Int"]["output"];
    text: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientNarrationRecordingNarrationRecordingVersionArgs = {
    id: Scalars["ID"]["input"];
};

export enum GqlClientNarrationRecordingErrorReason {
    BADINTONATION = "BadIntonation",
    CORRUPTED = "Corrupted",
    OTHER = "Other",
    TEXTDOESNOTMATCH = "TextDoesNotMatch"
}

export enum GqlClientNarrationRecordingStatus {
    APPROVED = "approved",
    PENDINGAPPROVAL = "pendingApproval",
    REJECTED = "rejected"
}

export type GqlClientNarrationRecordingToUpsert = {
    filename?: InputMaybe<Scalars["String"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadata?: InputMaybe<Scalars["JSON"]["input"]>;
    narrationId?: InputMaybe<Scalars["ID"]["input"]>;
    narrationRecordingId?: InputMaybe<Scalars["ID"]["input"]>;
    narratorId?: InputMaybe<Scalars["String"]["input"]>;
    rfr: Scalars["Int"]["input"];
    text?: InputMaybe<Scalars["String"]["input"]>;
    updated?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientNarrationRecordingVersion = {
    __typename?: "NarrationRecordingVersion";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    errorReason?: Maybe<GqlClientNarrationRecordingErrorReason>;
    format?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    location?: Maybe<Scalars["String"]["output"]>;
    mediaSubType?: Maybe<Scalars["String"]["output"]>;
    narrationRecording: GqlClientNarrationRecording;
    size?: Maybe<Scalars["Int"]["output"]>;
    status?: Maybe<GqlClientNarrationRecordingStatus>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    url?: Maybe<Scalars["String"]["output"]>;
    version: Scalars["Int"]["output"];
};

export enum GqlClientNarrationRecordingsState {
    DONENOCHANGES = "doneNoChanges",
    DONEWITHCHANGES = "doneWithChanges",
    INPROGRESSNOCHANGES = "inProgressNoChanges",
    INPROGRESSWITHCHANGES = "inProgressWithChanges",
    NEVERREQUESTED = "neverRequested"
}

export type GqlClientNarrationUpdates = {
    lastUsedRecordingFileName: Scalars["Int"]["input"];
    narrationId: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientNarrationVariation = {
    __typename?: "NarrationVariation";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    muted?: Maybe<Scalars["Boolean"]["output"]>;
    narration?: Maybe<GqlClientNarration>;
    note?: Maybe<Scalars["String"]["output"]>;
    overridingCreativeDataElement?: Maybe<GqlClientCreativeDataElement>;
    sentence?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    variationKey?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientNarrator = {
    __typename?: "Narrator";
    accountId: Scalars["ID"]["output"];
    carUrl: Scalars["String"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    decisionTablesLoadTaskId?: Maybe<Scalars["String"]["output"]>;
    dtaasPrefix: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    legacyNarratorId: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    programVersion: GqlClientProgramVersion;
    status: GqlClientNarratorStatus;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientNarratorStatus {
    NOTREADY = "notReady",
    READY = "ready"
}

export type GqlClientNextRecommendedVideoCreatedPayload = {
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    sessionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientNextRecommendedVideoCreatedPayloadData = {
    __typename?: "NextRecommendedVideoCreatedPayloadData";
    dimensions: GqlClientDimension;
    playerToken: Scalars["String"]["output"];
    previewId: Scalars["String"]["output"];
};

export type GqlClientNextRecommendedVideoCreatedPayloadError = GqlClientNextRecommendedVideoCreatedPayload & {
    __typename?: "NextRecommendedVideoCreatedPayloadError";
    error: Scalars["JSON"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    sessionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientNextRecommendedVideoCreatedPayloadKeepAlive = GqlClientNextRecommendedVideoCreatedPayload & {
    __typename?: "NextRecommendedVideoCreatedPayloadKeepAlive";
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    sessionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientNextRecommendedVideoCreatedPayloadPending = GqlClientNextRecommendedVideoCreatedPayload & {
    __typename?: "NextRecommendedVideoCreatedPayloadPending";
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    progress: Scalars["Float"]["output"];
    sessionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientNextRecommendedVideoCreatedPayloadSuccess = GqlClientNextRecommendedVideoCreatedPayload & {
    __typename?: "NextRecommendedVideoCreatedPayloadSuccess";
    data: GqlClientNextRecommendedVideoCreatedPayloadData;
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    sessionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientOutputProgram = GqlClientEditorProgram | GqlClientProgram;

export enum GqlClientPermission {
    NONE = "NONE",
    ACCOUNTTIERMANAGEMENT = "accountTierManagement",
    ACCOUNTSSYNCTRIGGER = "accountsSyncTrigger",
    ADMINOPERATIONS = "adminOperations",
    ADSEDITCAMPAIGNSETTINGS = "adsEditCampaignSettings",
    ADSGETCAMPAIGNSETTINGS = "adsGetCampaignSettings",
    ARCHIVEEDITORPROGRAMS = "archiveEditorPrograms",
    BIQUERIES = "biQueries",
    CANVAS = "canvas",
    COPYDRAFTEDITORPROGRAMBETWEENACCOUNTS = "copyDraftEditorProgramBetweenAccounts",
    COPYEDITORPROGRAM = "copyEditorProgram",
    CREATEEDITORPROGRAMS = "createEditorPrograms",
    CREATEPROGRAM = "createProgram",
    DASHBOARDS = "dashboards",
    DELETEEDITORPROGRAMS = "deleteEditorPrograms",
    DELETEENTITY = "deleteEntity",
    EDITACCOUNTUSERS = "editAccountUsers",
    EDITBRANDCONFIGURATIONS = "editBrandConfigurations",
    EDITCC = "editCC",
    EDITEDITORCUSTOMINTEGRATIONS = "editEditorCustomIntegrations",
    EDITEDITORPROGRAMS = "editEditorPrograms",
    EDITEXPERIMENTS = "editExperiments",
    EDITFEATUREFLAGS = "editFeatureFlags",
    EDITHELPCENTER = "editHelpCenter",
    EDITLANDINGPAGEBUILDERAPIGATEWAY = "editLandingPageBuilderApiGateway",
    EDITPROGRAMSETTINGS = "editProgramSettings",
    EDITPROJECTS = "editProjects",
    EDITPROJECTSASSETS = "editProjectsAssets",
    EDITREPORTS = "editReports",
    EDITSTORYTEMPLATES = "editStoryTemplates",
    EDITSTORYTEMPLATESGALLERY = "editStoryTemplatesGallery",
    EDITUSERCONTROLLEDFEATURES = "editUserControlledFeatures",
    EDITUSERS = "editUsers",
    EDITVIEWERPROFILES = "editViewerProfiles",
    EXPORTACCOUNTDATA = "exportAccountData",
    GENERATEVIDEOLINKS = "generateVideoLinks",
    GENERATEVIDEOLINKSPROD = "generateVideoLinksProd",
    GETBRANDCONFIGURATIONS = "getBrandConfigurations",
    GETCC = "getCC",
    GETEDITORPROGRAMS = "getEditorPrograms",
    GETFRAMEWORK = "getFramework",
    GETINPUTS = "getInputs",
    GETPROGRAMSETTINGS = "getProgramSettings",
    GETPROJECTS = "getProjects",
    GETPROJECTSASSETS = "getProjectsAssets",
    GETSCHEDULES = "getSchedules",
    GETSTORYTEMPLATESDATA = "getStoryTemplatesData",
    GETUSERS = "getUsers",
    MANAGEPROMPTTOKENS = "managePromptTokens",
    RAASREQUESTS = "raasRequests",
    RESOLVEQUERIES = "resolveQueries",
    REVIEWEDITORPROGRAMS = "reviewEditorPrograms",
    SYNCPROJECTASSETS = "syncProjectAssets",
    UPDATEENTITY = "updateEntity",
    UPDATEUSERECORDEDVOICE = "updateUseRecordedVoice",
    UPLOADEDITORASSETMEDIA = "uploadEditorAssetMedia"
}

export type GqlClientPersonalization = {
    created: Scalars["String"]["output"];
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    scene: GqlClientScene;
    type?: Maybe<Array<Scalars["String"]["output"]>>;
    updated: Scalars["String"]["output"];
};

export type GqlClientPlaceholder = {
    __typename?: "Placeholder";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    group?: Maybe<GqlClientPlaceholderGroup>;
    height?: Maybe<Scalars["Float"]["output"]>;
    id: Scalars["ID"]["output"];
    logic: GqlClientSceneEntityLogic;
    prioritizedList?: Maybe<GqlClientPrioritizedList>;
    scenePart: GqlClientScenePart;
    type: GqlClientPlaceholderType;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    width?: Maybe<Scalars["Float"]["output"]>;
    x?: Maybe<Scalars["Float"]["output"]>;
    y?: Maybe<Scalars["Float"]["output"]>;
};

export type GqlClientPlaceholderGroup = {
    __typename?: "PlaceholderGroup";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    logic: GqlClientPlaceholderGroupLogic;
    placeholders: Array<GqlClientPlaceholder>;
    prioritizedList?: Maybe<GqlClientPrioritizedList>;
    scenePart: GqlClientScenePart;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientPlaceholderGroupLogic = {
    __typename?: "PlaceholderGroupLogic";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    logic?: Maybe<Scalars["JSON"]["output"]>;
    name?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientPlaceholderType {
    AVATAR = "avatar",
    HOTSPOT = "hotspot",
    IMAGE = "image",
    TEXT = "text",
    VIDEO = "video"
}

export enum GqlClientPlacement {
    DESKTOP = "DESKTOP",
    MOBILE_APP = "MOBILE_APP",
    MOBILE_WEB = "MOBILE_WEB",
    TABLET_APP = "TABLET_APP",
    TABLET_WEB = "TABLET_WEB"
}

export enum GqlClientPlayerSize {
    LARGE = "LARGE",
    MEDIUM = "MEDIUM",
    SMALL = "SMALL",
    X_SMALL = "X_SMALL"
}

export type GqlClientPollingTaskCreationResponse = {
    __typename?: "PollingTaskCreationResponse";
    pollingUrl: Scalars["String"]["output"];
    sessionId: Scalars["ID"]["output"];
};

export type GqlClientPopulateMissingAccountFeatureDataOutput = {
    __typename?: "PopulateMissingAccountFeatureDataOutput";
    result: GqlClientPopulateMissingAccountFeatureDataResult;
};

export enum GqlClientPopulateMissingAccountFeatureDataResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientPostId = {
    __typename?: "PostId";
    id: Scalars["String"]["output"];
    platform: Scalars["String"]["output"];
    postUrl: Scalars["String"]["output"];
    status: Scalars["String"]["output"];
};

export type GqlClientPostMediaProcessingData = {
    editedMediaId: Scalars["ID"]["input"];
    updateEditorPlaceholderInput: GqlClientUpdateEditorPlaceholderInput;
};

export enum GqlClientPostStatus {
    AWAITING_APPROVAL = "AWAITING_APPROVAL",
    DELETED = "DELETED",
    ERROR = "ERROR",
    PENDING = "PENDING",
    SUCCESS = "SUCCESS"
}

export type GqlClientPostToSocialNetworkInput = {
    platforms: Array<Scalars["String"]["input"]>;
    post: Scalars["String"]["input"];
    title?: InputMaybe<Scalars["String"]["input"]>;
    trackingInfo: GqlClientTrackingInfo;
    videoThumbnail?: InputMaybe<Scalars["String"]["input"]>;
    videoUrl: Scalars["String"]["input"];
};

export type GqlClientPostToSocialNetworkOutput = {
    __typename?: "PostToSocialNetworkOutput";
    postUrl: Scalars["String"]["output"];
    result: GqlClientPostToSocialNetworkResult;
};

export enum GqlClientPostToSocialNetworkResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientPreset = {
    __typename?: "Preset";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    dataElementValues: Array<GqlClientPresetDataElementValue>;
    id: Scalars["ID"]["output"];
    isCreative?: Maybe<Scalars["Boolean"]["output"]>;
    name: Scalars["String"]["output"];
    systemElementValues: Array<GqlClientPresetSystemElementValue>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientPresetDataElementValue = {
    __typename?: "PresetDataElementValue";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    dataElement?: Maybe<GqlClientGenericDataElement>;
    id: Scalars["ID"]["output"];
    isCreative?: Maybe<Scalars["Boolean"]["output"]>;
    preset?: Maybe<GqlClientPreset>;
    type?: Maybe<GqlClientDataElementType>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    value?: Maybe<Scalars["String"]["output"]>;
    valueId?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientPresetSystemElementValue = {
    __typename?: "PresetSystemElementValue";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    preset?: Maybe<GqlClientPreset>;
    systemElementId?: Maybe<Scalars["String"]["output"]>;
    type?: Maybe<GqlClientDataElementType>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    value?: Maybe<Scalars["String"]["output"]>;
    valueId?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientPrioritizedList = {
    __typename?: "PrioritizedList";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    groups?: Maybe<Array<GqlClientPlaceholderGroup>>;
    id: Scalars["ID"]["output"];
    logic: GqlClientPrioritizedListLogic;
    placeholders?: Maybe<Array<GqlClientPlaceholder>>;
    scenePart: GqlClientScenePart;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientPrioritizedListLogic = {
    __typename?: "PrioritizedListLogic";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    logic?: Maybe<Scalars["JSON"]["output"]>;
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientProductFilter = {
    __typename?: "ProductFilter";
    attName?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    operator?: Maybe<GqlClientProductFilterOperator>;
    type?: Maybe<GqlClientProductFilterFieldType>;
    value?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientProductFilterFieldType {
    CATEGORY = "CATEGORY",
    PRICE = "PRICE",
    SKU = "SKU",
    TAGS = "TAGS"
}

export enum GqlClientProductFilterOperator {
    CONTAINS = "CONTAINS",
    DOES_NOT_CONTAIN = "DOES_NOT_CONTAIN",
    DOES_NOT_EQUAL = "DOES_NOT_EQUAL",
    EQUALS = "EQUALS",
    GREATER_THAN = "GREATER_THAN"
}

export type GqlClientProductSelector = {
    __typename?: "ProductSelector";
    audienceId?: Maybe<Scalars["Int"]["output"]>;
    extraData?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    type?: Maybe<GqlClientProductSelectorFieldType>;
};

export enum GqlClientProductSelectorFieldType {
    BEST_MATCH = "BEST_MATCH",
    CATEGORY_TOP_SELLER = "CATEGORY_TOP_SELLER",
    LOCATION_TOP_SELLER = "LOCATION_TOP_SELLER",
    NO_PRODUCTS = "NO_PRODUCTS",
    SITE_TOP_SELLER = "SITE_TOP_SELLER",
    SPECIFIC_CATEGORY = "SPECIFIC_CATEGORY",
    SPECIFIC_PRODUCT = "SPECIFIC_PRODUCT"
}

export type GqlClientProgram = {
    __typename?: "Program";
    account: GqlClientAccount;
    accountId: Scalars["ID"]["output"];
    analyticsDashboard?: Maybe<GqlClientAnalyticsDashboard>;
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    apiKeys?: Maybe<Scalars["JSON"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    creative?: Maybe<GqlClientCreative>;
    creatives: Array<GqlClientCreative>;
    customAnalyticFields: Array<GqlClientCustomAnalyticField>;
    customAnalyticsOrder: Array<Scalars["ID"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    experimentIds?: Maybe<Array<Scalars["String"]["output"]>>;
    experiments: Array<GqlClientExperiment>;
    folder?: Maybe<GqlClientAccountProgramFolder>;
    id: Scalars["ID"]["output"];
    isArchive?: Maybe<Scalars["Boolean"]["output"]>;
    isEditor?: Maybe<Scalars["Boolean"]["output"]>;
    isLegacy?: Maybe<Scalars["Boolean"]["output"]>;
    lastArchived?: Maybe<Scalars["String"]["output"]>;
    legacyBuilderProgramId?: Maybe<Scalars["String"]["output"]>;
    legacyFrameworkProgramId?: Maybe<Scalars["String"]["output"]>;
    legacyOverviewProgramId?: Maybe<Scalars["String"]["output"]>;
    lifecycleHistory: Array<GqlClientLifecycleHistoryEntry>;
    lifecycleStage: GqlClientAssetLifecycleStage;
    name: Scalars["String"]["output"];
    pii?: Maybe<Scalars["Boolean"]["output"]>;
    programAnalytics: GqlClientProgramAnalytics;
    programType?: Maybe<GqlClientProgramType>;
    programVersion?: Maybe<GqlClientProgramVersion>;
    programVersionDrafts: Array<GqlClientProgramVersion>;
    programVersionSnapshots: Array<GqlClientProgramVersion>;
    programVersions: Array<GqlClientProgramVersion>;
    publishTarget: GqlClientLifecycleStage;
    saleForceId?: Maybe<Scalars["String"]["output"]>;
    surveys: Array<GqlClientSurvey>;
    trackingParameters: Array<GqlClientTrackingParameter>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientProgramCreativeArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientProgramProgramVersionArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientProgramAnalytics = {
    __typename?: "ProgramAnalytics";
    analyticsFilters?: Maybe<Array<GqlClientAnalyticsFilter>>;
    clicksAnalytics?: Maybe<GqlClientProgramClicksAnalytics>;
    sceneAnalytics: GqlClientProgramSceneDataAnalytics;
    surveyAnalytics?: Maybe<GqlClientProgramSurveyAnalytics>;
    viewsAnalytics?: Maybe<GqlClientProgramViewsAnalytics>;
};

export type GqlClientProgramAnalyticsAnalyticsFiltersArgs = {
    endDate: Scalars["String"]["input"];
    startDate: Scalars["String"]["input"];
};

export type GqlClientProgramAnalyticsClicksAnalyticsArgs = {
    endDate: Scalars["String"]["input"];
    filters?: InputMaybe<Array<InputMaybe<GqlClientAnalyticsFilterInput>>>;
    startDate: Scalars["String"]["input"];
};

export type GqlClientProgramAnalyticsSceneAnalyticsArgs = {
    endDate: Scalars["String"]["input"];
    filters?: InputMaybe<Array<InputMaybe<GqlClientAnalyticsFilterInput>>>;
    startDate: Scalars["String"]["input"];
};

export type GqlClientProgramAnalyticsSurveyAnalyticsArgs = {
    endDate: Scalars["String"]["input"];
    filters?: InputMaybe<Array<InputMaybe<GqlClientAnalyticsFilterInput>>>;
    startDate: Scalars["String"]["input"];
};

export type GqlClientProgramAnalyticsViewsAnalyticsArgs = {
    endDate: Scalars["String"]["input"];
    filters?: InputMaybe<Array<InputMaybe<GqlClientAnalyticsFilterInput>>>;
    startDate: Scalars["String"]["input"];
};

export type GqlClientProgramClicksAnalytics = {
    __typename?: "ProgramClicksAnalytics";
    buttons?: Maybe<Array<GqlClientClicksAnalyticsButton>>;
    totalCTAClicks: Scalars["Int"]["output"];
    totalCTAViews: Scalars["Int"]["output"];
    videoViewsAmount: Scalars["Int"]["output"];
    viewsWithCTAClicked: Scalars["Int"]["output"];
    viewsWithCTADisplayed: Scalars["Int"]["output"];
};

export type GqlClientProgramDraftInputsOutput = {
    __typename?: "ProgramDraftInputsOutput";
    scenes: Array<GqlClientScene>;
    sharedInputs: Array<GqlClientInput>;
};

export type GqlClientProgramDraftScenesOutput = {
    __typename?: "ProgramDraftScenesOutput";
    scenes: Array<GqlClientScene>;
};

export type GqlClientProgramDynamoMigrationStatus = {
    __typename?: "ProgramDynamoMigrationStatus";
    accountId: Scalars["String"]["output"];
    animations: GqlClientDynamoMigrationStatus;
    assets: GqlClientDynamoMigrationStatus;
    created: Scalars["String"]["output"];
    dataTables: GqlClientDynamoMigrationStatus;
    id: Scalars["ID"]["output"];
    lifecycleHistory: GqlClientDynamoMigrationStatus;
    mostEntities: GqlClientDynamoMigrationStatus;
    narrators: GqlClientDynamoMigrationStatus;
    programId: Scalars["String"]["output"];
    recordingDocs: GqlClientDynamoMigrationStatus;
    updated: Scalars["String"]["output"];
};

export type GqlClientProgramDynamoMigrationStatusEx = {
    __typename?: "ProgramDynamoMigrationStatusEx";
    accountName: Scalars["String"]["output"];
    animations: GqlClientDynamoMigrationStatus;
    assets: GqlClientDynamoMigrationStatus;
    dataTables: GqlClientDynamoMigrationStatus;
    id: Scalars["ID"]["output"];
    lifecycleHistory: GqlClientDynamoMigrationStatus;
    mostEntities: GqlClientDynamoMigrationStatus;
    narrators: GqlClientDynamoMigrationStatus;
    programName: Scalars["String"]["output"];
    recordingDocs: GqlClientDynamoMigrationStatus;
};

export type GqlClientProgramEditorPublishedPayload = {
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    programId: Scalars["ID"]["output"];
    snapshotName: Scalars["String"]["output"];
    status: GqlClientStatus;
    userName: Scalars["String"]["output"];
};

export type GqlClientProgramEditorPublishedPayloadData = {
    __typename?: "ProgramEditorPublishedPayloadData";
    editorProgram: GqlClientEditorProgram;
};

export type GqlClientProgramEditorPublishedPayloadError = GqlClientProgramEditorPublishedPayload & {
    __typename?: "ProgramEditorPublishedPayloadError";
    error: Scalars["JSON"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    programId: Scalars["ID"]["output"];
    snapshotName: Scalars["String"]["output"];
    status: GqlClientStatus;
    userName: Scalars["String"]["output"];
};

export type GqlClientProgramEditorPublishedPayloadKeepAlive = GqlClientProgramEditorPublishedPayload & {
    __typename?: "ProgramEditorPublishedPayloadKeepAlive";
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    programId: Scalars["ID"]["output"];
    snapshotName: Scalars["String"]["output"];
    status: GqlClientStatus;
    userName: Scalars["String"]["output"];
};

export type GqlClientProgramEditorPublishedPayloadPending = GqlClientProgramEditorPublishedPayload & {
    __typename?: "ProgramEditorPublishedPayloadPending";
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    programId: Scalars["ID"]["output"];
    progress: Scalars["Float"]["output"];
    snapshotName: Scalars["String"]["output"];
    status: GqlClientStatus;
    userName: Scalars["String"]["output"];
};

export type GqlClientProgramEditorPublishedPayloadSuccess = GqlClientProgramEditorPublishedPayload & {
    __typename?: "ProgramEditorPublishedPayloadSuccess";
    data: GqlClientProgramEditorPublishedPayloadData;
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    programId: Scalars["ID"]["output"];
    snapshotName: Scalars["String"]["output"];
    status: GqlClientStatus;
    userName: Scalars["String"]["output"];
};

export type GqlClientProgramEditorSceneCreatedPayloadData = {
    __typename?: "ProgramEditorSceneCreatedPayloadData";
    editorProgramVersion: GqlClientEditorProgramVersion;
    editorScenes: Array<GqlClientEditorScene>;
};

export type GqlClientProgramEditorScenesUpdatedPayloadData = {
    __typename?: "ProgramEditorScenesUpdatedPayloadData";
    editorProgramVersion: GqlClientEditorProgramVersion;
    editorScenes: Array<GqlClientEditorScene>;
};

export type GqlClientProgramReview = {
    __typename?: "ProgramReview";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    creationResultMessage?: Maybe<Scalars["String"]["output"]>;
    creationStatus: GqlClientReviewCreationStatus;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    lifecycleMessage?: Maybe<Scalars["String"]["output"]>;
    lifecycleStage?: Maybe<GqlClientAssetLifecycleStage>;
    name: Scalars["String"]["output"];
    program: GqlClientEditorProgram;
    reviewLink: Scalars["String"]["output"];
    reviewers: Array<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    videos: Array<GqlClientProgramReviewVideo>;
};

export type GqlClientProgramReviewVideo = {
    __typename?: "ProgramReviewVideo";
    accountId: Scalars["ID"]["output"];
    aspectRatio: GqlClientEnumAspectRatio;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    playerToken: Scalars["String"]["output"];
    reviewLink: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    videoData?: Maybe<Scalars["JSON"]["output"]>;
    videoThumbnailUrl?: Maybe<Scalars["String"]["output"]>;
    viewerProfileName?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientProgramReviewsSummaryOutput = {
    __typename?: "ProgramReviewsSummaryOutput";
    id: Scalars["ID"]["output"];
    programReviews: Array<GqlClientProgramReview>;
};

export type GqlClientProgramSceneAnalytics = {
    __typename?: "ProgramSceneAnalytics";
    data_use: Scalars["Boolean"]["output"];
    sceneId: Scalars["String"]["output"];
    scenePotentialViews: Scalars["Int"]["output"];
    sceneRewatchViews: Scalars["Int"]["output"];
    sceneSequentialViews: Scalars["Int"]["output"];
    sceneSkippedViews: Scalars["Int"]["output"];
};

export type GqlClientProgramSceneDataAnalytics = {
    __typename?: "ProgramSceneDataAnalytics";
    programSceneAnalyticsArray?: Maybe<Array<GqlClientProgramSceneAnalytics>>;
    versionPublishedDate: Scalars["String"]["output"];
};

export type GqlClientProgramShareHistory = {
    __typename?: "ProgramShareHistory";
    accountId: Scalars["String"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    editorProgram: GqlClientEditorProgram;
    id: Scalars["ID"]["output"];
    isExternal?: Maybe<Scalars["Boolean"]["output"]>;
    programVersion: GqlClientEditorProgramVersion;
    shareType: GqlClientProgramShareType;
};

export enum GqlClientProgramShareType {
    DOWNLOAD = "download",
    FACEBOOK = "facebook",
    LINKEDIN = "linkedin"
}

export type GqlClientProgramSurveyAnalytics = {
    __typename?: "ProgramSurveyAnalytics";
    displayedAmount: Scalars["Int"]["output"];
    npsAnalytics?: Maybe<GqlClientNpsSurveyAnalytics>;
    questions: Array<GqlClientSurveyAnalyticsQuestion>;
    submittedAmount: Scalars["Int"]["output"];
    surveyId?: Maybe<Scalars["String"]["output"]>;
    videoViewsAmount: Scalars["Int"]["output"];
};

export enum GqlClientProgramType {
    ADS = "ads",
    CRM = "crm"
}

export type GqlClientProgramVersion = {
    __typename?: "ProgramVersion";
    accountId: Scalars["ID"]["output"];
    animation?: Maybe<GqlClientAnimation>;
    animations: Array<GqlClientAnimation>;
    anyUpdated?: Maybe<Scalars["String"]["output"]>;
    anyUpdatedBy?: Maybe<Scalars["String"]["output"]>;
    asset?: Maybe<GqlClientAsset>;
    assets: Array<GqlClientAsset>;
    audienceSize?: Maybe<Scalars["String"]["output"]>;
    audiences: Array<GqlClientAudience>;
    channels: Array<GqlClientChannel>;
    chapteringEnabled: Scalars["Boolean"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    creativeDataElements: Array<GqlClientCreativeDataElement>;
    creativeDfde?: Maybe<Scalars["ID"]["output"]>;
    creativeNameLabel?: Maybe<Scalars["String"]["output"]>;
    creativeVersion?: Maybe<Scalars["Int"]["output"]>;
    dataTable?: Maybe<GqlClientDataTable>;
    dataTables: Array<GqlClientDataTable>;
    derivedDataElements: Array<GqlClientDerivedDataElement>;
    description?: Maybe<Scalars["String"]["output"]>;
    editorDataConnectorEnabled?: Maybe<Scalars["Boolean"]["output"]>;
    editorDataConnectorType?: Maybe<Scalars["String"]["output"]>;
    editorTtsFullEnablement?: Maybe<Scalars["Boolean"]["output"]>;
    feedDataElements: Array<GqlClientFeedDataElement>;
    goals: Array<GqlClientGoal>;
    id: Scalars["ID"]["output"];
    industry?: Maybe<Scalars["String"]["output"]>;
    isBuilder?: Maybe<Scalars["Boolean"]["output"]>;
    isDraft?: Maybe<Scalars["Boolean"]["output"]>;
    masterAnimationGuidelines: Array<GqlClientMasterAnimationGuideline>;
    name: Scalars["String"]["output"];
    narrationRecording?: Maybe<GqlClientNarrationRecording>;
    narrationRecordings: Array<GqlClientNarrationRecording>;
    narrationsVersion?: Maybe<Scalars["Int"]["output"]>;
    narrator?: Maybe<GqlClientNarrator>;
    narrators: Array<GqlClientNarrator>;
    /** @deprecated Use description instead */
    objective?: Maybe<Scalars["String"]["output"]>;
    parent1ProgramVersion?: Maybe<GqlClientProgramVersion>;
    preset?: Maybe<GqlClientPreset>;
    presets: Array<GqlClientPreset>;
    program: GqlClientProgram;
    programType?: Maybe<GqlClientProgramType>;
    recordingDocs: Array<GqlClientRecordingDocEntry>;
    rfr?: Maybe<Scalars["Int"]["output"]>;
    rfrUpdateTime?: Maybe<Scalars["String"]["output"]>;
    sceneOrder: Array<Scalars["ID"]["output"]>;
    scenes: Array<GqlClientScene>;
    snapshotComment?: Maybe<Scalars["String"]["output"]>;
    snapshotName?: Maybe<Scalars["String"]["output"]>;
    snapshotNumber?: Maybe<Scalars["Int"]["output"]>;
    snapshotRuntimeAssets?: Maybe<Array<Maybe<GqlClientSnapshotRuntimeAsset>>>;
    snapshotValidationData?: Maybe<Scalars["JSON"]["output"]>;
    snapshotted?: Maybe<Scalars["String"]["output"]>;
    snapshottedBy?: Maybe<Scalars["String"]["output"]>;
    stories: Array<GqlClientStory>;
    storyOrder: Array<Scalars["ID"]["output"]>;
    storySelectionLogic?: Maybe<Scalars["JSON"]["output"]>;
    touchpoints: Array<GqlClientTouchpoint>;
    transition?: Maybe<Scalars["JSON"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    useCase?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientProgramVersionAnimationArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientProgramVersionAssetArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientProgramVersionDataTableArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientProgramVersionNarrationRecordingArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientProgramVersionNarratorArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientProgramVersionPresetArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientProgramVersionSnapshotRuntimeAssetsArgs = {
    programRuntimeId: Scalars["String"]["input"];
};

export type GqlClientProgramVersionNarrationRecordingStateCalculatedPayload = {
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    programVersionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientProgramVersionNarrationRecordingStateCalculatedPayloadData = {
    __typename?: "ProgramVersionNarrationRecordingStateCalculatedPayloadData";
    narrationRecordingsState?: Maybe<GqlClientNarrationRecordingsState>;
};

export type GqlClientProgramVersionNarrationRecordingStateCalculatedPayloadError = GqlClientProgramVersionNarrationRecordingStateCalculatedPayload & {
    __typename?: "ProgramVersionNarrationRecordingStateCalculatedPayloadError";
    error: Scalars["JSON"]["output"];
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    programVersionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientProgramVersionNarrationRecordingStateCalculatedPayloadKeepAlive = GqlClientProgramVersionNarrationRecordingStateCalculatedPayload & {
    __typename?: "ProgramVersionNarrationRecordingStateCalculatedPayloadKeepAlive";
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    programVersionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientProgramVersionNarrationRecordingStateCalculatedPayloadPending = GqlClientProgramVersionNarrationRecordingStateCalculatedPayload & {
    __typename?: "ProgramVersionNarrationRecordingStateCalculatedPayloadPending";
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    programVersionId: Scalars["ID"]["output"];
    progress: Scalars["Float"]["output"];
    status: GqlClientStatus;
};

export type GqlClientProgramVersionNarrationRecordingStateCalculatedPayloadSuccess = GqlClientProgramVersionNarrationRecordingStateCalculatedPayload & {
    __typename?: "ProgramVersionNarrationRecordingStateCalculatedPayloadSuccess";
    data: GqlClientProgramVersionNarrationRecordingStateCalculatedPayloadData;
    id: Scalars["ID"]["output"];
    mutationId: Scalars["String"]["output"];
    programVersionId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientProgramVersionsAndBcCollectionsEditorChangedPayload = {
    __typename?: "ProgramVersionsAndBcCollectionsEditorChangedPayload";
    brandConfigurationVersion: GqlClientBrandConfigurationVersion;
    editorProgramVersions: Array<GqlClientEditorProgramVersion>;
};

export type GqlClientProgramViewsAnalytics = {
    __typename?: "ProgramViewsAnalytics";
    activeViewsCount: Scalars["Int"]["output"];
    avv: Scalars["Float"]["output"];
    minutesViewed: Scalars["Float"]["output"];
    newViewsCount: Scalars["Int"]["output"];
    returningViewsCount: Scalars["Int"]["output"];
    videoExposed: Scalars["Int"]["output"];
    views: Scalars["Int"]["output"];
    viewsDailyData?: Maybe<Array<GqlClientProgramViewsAnalyticsDailyData>>;
    viewsWithClient: Scalars["Int"]["output"];
    viewsWithUserId: Scalars["Int"]["output"];
};

export type GqlClientProgramViewsAnalyticsDailyData = {
    __typename?: "ProgramViewsAnalyticsDailyData";
    activeViewsCount: Scalars["Int"]["output"];
    avv: Scalars["Float"]["output"];
    date: Scalars["String"]["output"];
    minutesViewed: Scalars["Float"]["output"];
    newViewsCount: Scalars["Int"]["output"];
    returningViewsCount: Scalars["Int"]["output"];
    videoExposed: Scalars["Int"]["output"];
    views: Scalars["Int"]["output"];
    viewsWithClient: Scalars["Int"]["output"];
    viewsWithUserId: Scalars["Int"]["output"];
};

export type GqlClientPromotePreSubmittedAvatarInput = {
    id: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
};

export type GqlClientPromotePreSubmittedAvatarOutput = {
    __typename?: "PromotePreSubmittedAvatarOutput";
    avatar: GqlClientEditorAccountLevelAvatar;
    result: GqlClientPromotePreSubmittedAvatarResult;
};

export enum GqlClientPromotePreSubmittedAvatarResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientPromoteSubmittedAvatarInput = {
    avatarId: Scalars["ID"]["input"];
    consent: GqlClientFileInput;
    footage: GqlClientFileInput;
};

export type GqlClientPromoteSubmittedAvatarOutput = {
    __typename?: "PromoteSubmittedAvatarOutput";
    avatar: GqlClientEditorAccountLevelAvatar;
    result: GqlClientPromoteSubmittedAvatarResult;
};

export enum GqlClientPromoteSubmittedAvatarResult {
    AVATAR_NOT_CREATED = "AVATAR_NOT_CREATED",
    SUCCESS = "SUCCESS"
}

export type GqlClientQuery = {
    __typename?: "Query";
    _?: Maybe<Scalars["Boolean"]["output"]>;
    account?: Maybe<GqlClientAccount>;
    accountDataLibrary?: Maybe<GqlClientAccountDataLibrary>;
    accountFeatureDataForMgmt: GqlClientAccountFeatureDataForMgmt;
    accountReport?: Maybe<GqlClientAccountReport>;
    accountReportLibraryDataElements: Array<GqlClientReportLibraryElement>;
    accountReportLibraryGeneralElements: Array<GqlClientReportLibraryElement>;
    accountReportLibraryHotSpots: Array<GqlClientReportLibraryElement>;
    accountReportLibraryPaidMediaElements: Array<GqlClientReportLibraryElement>;
    accountReportLibrarySurveys: Array<GqlClientReportLibraryElement>;
    accountReportLibraryTrackingParameters: Array<GqlClientReportLibraryElement>;
    accountReports: Array<GqlClientAccountReport>;
    accountSchedules: Array<GqlClientAccountSchedule>;
    accountTierManagementAuditLog: Array<Maybe<GqlClientTierManagementAuditLogRecord>>;
    accounts: Array<GqlClientAccount>;
    acquiaAssets: GqlClientAcquiaAssetsOutput;
    acquiaCategory: GqlClientAcquiaCategory;
    adsAccount: GqlClientAdsAccount;
    adsAudience: GqlClientAdsAudience;
    aiAccountDataSummary?: Maybe<Scalars["String"]["output"]>;
    aiTextEditing: GqlClientAiTextEditingOutput;
    allProgramsDynamoMigrationStatus: Array<GqlClientProgramDynamoMigrationStatusEx>;
    applicationFlavors: Array<Maybe<GqlClientApplicationFlavor>>;
    biPrograms: Array<GqlClientBiProgram>;
    brandConfigurationCollection: GqlClientBrandConfigurationCollection;
    brandConfigurationCollectionUsedInPrograms: Array<GqlClientEditorProgram>;
    brandConfigurationVersion: GqlClientBrandConfigurationVersion;
    campaignSettings: GqlClientCampaignSettings;
    campaignsAndSfBudget: GqlClientGetCampaignsResponse;
    ccLibraries: Array<GqlClientCcLibrary>;
    ccLibrary?: Maybe<GqlClientCcLibrary>;
    ccThemes: Array<Maybe<GqlClientCcTheme>>;
    ccWireframes: Array<Maybe<GqlClientCcWireframe>>;
    currentAccount?: Maybe<GqlClientAccount>;
    dataLibraryGenerateAllImportLinks: Scalars["String"]["output"];
    dataLibraryGenerateLinksFromSpecificImports: Scalars["String"]["output"];
    dataLibraryGetImportHistory?: Maybe<GqlClientDataLibraryImportHistory>;
    downloadAdsFile: GqlClientAdsFileResult;
    draftEditorApplications: Array<Maybe<GqlClientEditorApplication>>;
    editorAccountLevelData: GqlClientEditorAccountLevelData;
    editorAccountUsers: Array<GqlClientEditorAccountUser>;
    editorChannels: Array<Maybe<GqlClientEditorChannel>>;
    editorCreativeStyleFits: Array<Maybe<GqlClientEditorCreativeStyleFit>>;
    editorIndustries: Array<Maybe<GqlClientEditorIndustry>>;
    editorNarrationDuration?: Maybe<GqlClientNarrationDuration>;
    editorProgram?: Maybe<GqlClientEditorProgram>;
    editorPrograms: Array<GqlClientEditorProgram>;
    editorStockMusicAIRecommendationSearch?: Maybe<GqlClientEditorStockMusicSearchResults>;
    editorStockMusicGetSingleTrackDetails?: Maybe<GqlClientEditorStockMusic>;
    editorStockMusicSearch?: Maybe<GqlClientEditorStockMusicSearchResults>;
    editorUseCases: Array<Maybe<GqlClientEditorUseCase>>;
    galleryStoryTemplates: Array<Maybe<GqlClientEditorApplication>>;
    generateAiImageFromText?: Maybe<GqlClientGenerateAiImageFromTextOutput>;
    gettySearchMetadata: GqlClientGettySearchMetadataOutput;
    helpCenterEntries: Array<GqlClientHelpCenterEntry>;
    internalUser?: Maybe<GqlClientEditorAccountUser>;
    localEditorBrand?: Maybe<GqlClientBrandConfigurationCollection>;
    localEditorPlaceholder?: Maybe<GqlClientEditorPlaceholder>;
    localEditorScene?: Maybe<GqlClientEditorScene>;
    localEditorViewerProfile?: Maybe<GqlClientViewerProfile>;
    loggedInEditorAccountUser: GqlClientEditorAccountUser;
    mediaCropHints?: Maybe<GqlClientMediaCropHints>;
    program?: Maybe<GqlClientProgram>;
    programByLegacyBuilderId?: Maybe<GqlClientProgram>;
    programDraftInputs: GqlClientProgramDraftInputsOutput;
    programDraftScenes: GqlClientProgramDraftScenesOutput;
    programReview: GqlClientProgramReview;
    programReviewsSummaryForUser: GqlClientProgramReviewsSummaryOutput;
    programs: Array<GqlClientProgram>;
    programsDynamoMigrationStatus: Array<GqlClientProgramDynamoMigrationStatus>;
    promptSuggestions: Array<Maybe<Scalars["String"]["output"]>>;
    reportLibraryDataElements: Array<GqlClientReportLibraryElement>;
    reportLibraryGeneralElements: Array<GqlClientReportLibraryElement>;
    reportLibraryHotSpots: Array<GqlClientReportLibraryElement>;
    reportLibrarySurveys: Array<GqlClientReportLibraryElement>;
    reportLibraryTrackingParameters: Array<GqlClientReportLibraryElement>;
    resolveAnimation?: Maybe<GqlClientResolveAnimationOutput>;
    resolveAnimations?: Maybe<GqlClientResolveAnimationsOutput>;
    resolveAsset?: Maybe<GqlClientResolveAssetOutput>;
    resolveAssets?: Maybe<GqlClientResolveAssetsOutput>;
    resolveDataTables?: Maybe<GqlClientResolveDataTablesOutput>;
    resolveNarrators?: Maybe<GqlClientResolveNarratorsOutput>;
    resolveRecording?: Maybe<GqlClientResolveRecordingOutput>;
    rptvUsers: Array<GqlClientRptvUser>;
    storyTemplate?: Maybe<GqlClientEditorApplication>;
    storyTemplatesReviewGalleryStatus: Array<Maybe<GqlClientStoryTemplateStatus>>;
    surveys: Array<GqlClientSurvey>;
    tierAccountDefinitions: Array<Maybe<GqlClientTierAccountDefinition>>;
    tierFeatureDefinitions: Array<Maybe<GqlClientTierFeatureDefinition>>;
    trayGoogleSheetColumns?: Maybe<Array<GqlClientGoogleColumn>>;
    trayGoogleSheets: GqlClientGoogleSheetResultOutput;
    trayServiceAuthUrl?: Maybe<GqlClientTrayServiceAuth>;
    trayServiceConnectorEntities: Array<Maybe<GqlClientTrayServiceConnectorEntity>>;
    trayTestConnectorAuthentication?: Maybe<GqlClientTrayTestConnectorAuthenticationOutput>;
    undoStacks: GqlClientUndoStacks;
    user?: Maybe<GqlClientEditorAccountUser>;
    userSettings?: Maybe<GqlClientUserSettings>;
};

export type GqlClientQueryAccountArgs = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GqlClientQueryAccountDataLibraryArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryAccountFeatureDataForMgmtArgs = {
    accountId: Scalars["String"]["input"];
};

export type GqlClientQueryAccountReportArgs = {
    reportId: Scalars["ID"]["input"];
};

export type GqlClientQueryAccountReportLibraryDataElementsArgs = {
    programIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type GqlClientQueryAccountReportLibraryHotSpotsArgs = {
    programIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type GqlClientQueryAccountReportLibrarySurveysArgs = {
    programIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type GqlClientQueryAccountReportLibraryTrackingParametersArgs = {
    programIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type GqlClientQueryAccountReportsArgs = {
    accountId: Scalars["ID"]["input"];
};

export type GqlClientQueryAccountSchedulesArgs = {
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GqlClientQueryAccountTierManagementAuditLogArgs = {
    accountId: Scalars["String"]["input"];
};

export type GqlClientQueryAcquiaAssetsArgs = {
    categoryPath?: InputMaybe<Scalars["String"]["input"]>;
    mediaType?: InputMaybe<GqlClientAcquiaAssetMediaType>;
    orientation?: InputMaybe<GqlClientAcquiaAssetOrientation>;
    pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
    searchTerm?: InputMaybe<Scalars["String"]["input"]>;
    sortBy?: InputMaybe<GqlClientAcquiaAssetSortBy>;
    sortDirection?: InputMaybe<GqlClientAcquiaAssetSortDirection>;
};

export type GqlClientQueryAcquiaCategoryArgs = {
    categoryPath: Scalars["String"]["input"];
};

export type GqlClientQueryAdsAccountArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryAdsAudienceArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryAiAccountDataSummaryArgs = {
    accountId: Scalars["ID"]["input"];
};

export type GqlClientQueryAiTextEditingArgs = {
    input: GqlClientAiTextEditingInput;
};

export type GqlClientQueryBrandConfigurationCollectionArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryBrandConfigurationCollectionUsedInProgramsArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryBrandConfigurationVersionArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryCampaignSettingsArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryCampaignsAndSfBudgetArgs = {
    programSfId: Scalars["String"]["input"];
};

export type GqlClientQueryCcLibraryArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryCcThemesArgs = {
    libraryVersionId: Scalars["ID"]["input"];
};

export type GqlClientQueryCcWireframesArgs = {
    libraryVersionId: Scalars["ID"]["input"];
};

export type GqlClientQueryDataLibraryGenerateAllImportLinksArgs = {
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    input: GqlClientDataLibraryGenerateAllImportLinksInput;
};

export type GqlClientQueryDataLibraryGenerateLinksFromSpecificImportsArgs = {
    input: GqlClientDataLibraryGenerateLinksFromSpecificImportsInput;
};

export type GqlClientQueryDataLibraryGetImportHistoryArgs = {
    dataLibraryId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GqlClientQueryDownloadAdsFileArgs = {
    location: Scalars["String"]["input"];
};

export type GqlClientQueryDraftEditorApplicationsArgs = {
    includeChildren?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GqlClientQueryEditorNarrationDurationArgs = {
    avatarInfo?: InputMaybe<GqlClientAvatarInfo>;
    narrator: Scalars["String"]["input"];
    rate?: InputMaybe<Scalars["Float"]["input"]>;
    service: Scalars["String"]["input"];
    text: Scalars["String"]["input"];
};

export type GqlClientQueryEditorProgramArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryEditorStockMusicAiRecommendationSearchArgs = {
    page?: InputMaybe<Scalars["Int"]["input"]>;
    programVersionID?: InputMaybe<Scalars["ID"]["input"]>;
    projectID?: InputMaybe<Scalars["ID"]["input"]>;
    userID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GqlClientQueryEditorStockMusicGetSingleTrackDetailsArgs = {
    trackID?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientQueryEditorStockMusicSearchArgs = {
    excludedFilters?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    minDuration?: InputMaybe<Scalars["Int"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    projectID?: InputMaybe<Scalars["ID"]["input"]>;
    resultsPerPage?: InputMaybe<Scalars["Int"]["input"]>;
    searchTerm?: InputMaybe<Scalars["String"]["input"]>;
    tag?: InputMaybe<Scalars["String"]["input"]>;
    userID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GqlClientQueryGalleryStoryTemplatesArgs = {
    galleryType: GqlClientStoryTemplatesGalleryType;
    includeChildren?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GqlClientQueryGenerateAiImageFromTextArgs = {
    input: GqlClientAiTextToImageInput;
};

export type GqlClientQueryGettySearchMetadataArgs = {
    includeImages: Scalars["Boolean"]["input"];
    includeVideos: Scalars["Boolean"]["input"];
    maxClipDuration?: InputMaybe<Scalars["Int"]["input"]>;
    minClipDuration?: InputMaybe<Scalars["Int"]["input"]>;
    orientation?: InputMaybe<GqlClientGettyOrientation>;
    pageNumber: Scalars["Int"]["input"];
    searchTerm: Scalars["String"]["input"];
    sortOrder?: InputMaybe<GqlClientGettySortOrder>;
};

export type GqlClientQueryInternalUserArgs = {
    email: Scalars["String"]["input"];
};

export type GqlClientQueryLocalEditorBrandArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryLocalEditorPlaceholderArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryLocalEditorSceneArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryLocalEditorViewerProfileArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryMediaCropHintsArgs = {
    aspectRatio: Scalars["Float"]["input"];
    url: Scalars["String"]["input"];
};

export type GqlClientQueryProgramArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryProgramByLegacyBuilderIdArgs = {
    legacyBuilderId: Scalars["ID"]["input"];
};

export type GqlClientQueryProgramDraftInputsArgs = {
    programId: Scalars["ID"]["input"];
    sceneIds: Array<Scalars["ID"]["input"]>;
    withShared: Scalars["Boolean"]["input"];
};

export type GqlClientQueryProgramDraftScenesArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientQueryProgramReviewArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientQueryProgramReviewsSummaryForUserArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientQueryReportLibraryDataElementsArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientQueryReportLibraryHotSpotsArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientQueryReportLibrarySurveysArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientQueryReportLibraryTrackingParametersArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientQueryResolveAnimationArgs = {
    resolveAnimationInput: GqlClientResolveAnimationInput;
};

export type GqlClientQueryResolveAnimationsArgs = {
    resolveAnimationsInput: GqlClientResolveAnimationsInput;
};

export type GqlClientQueryResolveAssetArgs = {
    resolveAssetInput: GqlClientResolveAssetInput;
};

export type GqlClientQueryResolveAssetsArgs = {
    resolveAssetsInput: GqlClientResolveAssetsInput;
};

export type GqlClientQueryResolveDataTablesArgs = {
    resolveDataTablesInput: GqlClientResolveDataTablesInput;
};

export type GqlClientQueryResolveNarratorsArgs = {
    resolveNarratorsInput: GqlClientResolveNarratorsInput;
};

export type GqlClientQueryResolveRecordingArgs = {
    resolveRecordingInput: GqlClientResolveRecordingInput;
};

export type GqlClientQueryStoryTemplateArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientQuerySurveysArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientQueryTierFeatureDefinitionsArgs = {
    tierId: Scalars["ID"]["input"];
};

export type GqlClientQueryTrayGoogleSheetColumnsArgs = {
    sheetName: Scalars["String"]["input"];
    spreadSheetId: Scalars["ID"]["input"];
};

export type GqlClientQueryTrayGoogleSheetsArgs = {
    spreadSheetId: Scalars["ID"]["input"];
};

export type GqlClientQueryTrayServiceAuthUrlArgs = {
    connectorType?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientQueryTrayServiceConnectorEntitiesArgs = {
    authLastUpdated?: InputMaybe<Scalars["String"]["input"]>;
    connectorType: Scalars["String"]["input"];
    dataLibraryId: Scalars["String"]["input"];
    parentEntityId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientQueryTrayTestConnectorAuthenticationArgs = {
    connectorType?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientQueryUserArgs = {
    email: Scalars["String"]["input"];
};

export type GqlClientQueryUserSettingsArgs = {
    id: Scalars["ID"]["input"];
};

export type GqlClientRptvUser = {
    __typename?: "RPTVUser";
    id: Scalars["ID"]["output"];
    tokens: Scalars["Int"]["output"];
};

export enum GqlClientRatio {
    HORIZONTAL = "HORIZONTAL",
    SQUARE = "SQUARE",
    VERTICAL = "VERTICAL"
}

export type GqlClientRationAndQualityOption = {
    __typename?: "RationAndQualityOption";
    quality?: Maybe<Scalars["String"]["output"]>;
    ratio?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientRecalculateEditorNarrationRecordingStateInput = {
    editorProgramVersionId: Scalars["ID"]["input"];
};

export type GqlClientRecalculateEditorNarrationRecordingStateOutput = {
    __typename?: "RecalculateEditorNarrationRecordingStateOutput";
    result: GqlClientRecalculateEditorNarrationRecordingStateResult;
};

export enum GqlClientRecalculateEditorNarrationRecordingStateResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientRecorderMediaUploadedToS3Payload = {
    mutationId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientRecorderMediaUploadedToS3PayloadData = {
    __typename?: "RecorderMediaUploadedToS3PayloadData";
    assetURL: Scalars["String"]["output"];
};

export type GqlClientRecorderMediaUploadedToS3PayloadError = GqlClientRecorderMediaUploadedToS3Payload & {
    __typename?: "RecorderMediaUploadedToS3PayloadError";
    error: Scalars["JSON"]["output"];
    mutationId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientRecorderMediaUploadedToS3PayloadKeepAlive = GqlClientRecorderMediaUploadedToS3Payload & {
    __typename?: "RecorderMediaUploadedToS3PayloadKeepAlive";
    mutationId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientRecorderMediaUploadedToS3PayloadPending = GqlClientRecorderMediaUploadedToS3Payload & {
    __typename?: "RecorderMediaUploadedToS3PayloadPending";
    mutationId: Scalars["ID"]["output"];
    progress: Scalars["Float"]["output"];
    status: GqlClientStatus;
};

export type GqlClientRecorderMediaUploadedToS3PayloadSuccess = GqlClientRecorderMediaUploadedToS3Payload & {
    __typename?: "RecorderMediaUploadedToS3PayloadSuccess";
    data: GqlClientRecorderMediaUploadedToS3PayloadData;
    mutationId: Scalars["ID"]["output"];
    status: GqlClientStatus;
};

export type GqlClientRecordingDocEntry = {
    __typename?: "RecordingDocEntry";
    accountId: Scalars["ID"]["output"];
    audioFileUrl: Scalars["String"]["output"];
    audioFilename: Scalars["String"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    folderLocation: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    programVersion: GqlClientProgramVersion;
    scriptFileUrl: Scalars["String"]["output"];
    scriptFilename: Scalars["String"]["output"];
    totalRecordingFiles: Scalars["Int"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientRectangle = {
    __typename?: "Rectangle";
    height: Scalars["Float"]["output"];
    width: Scalars["Float"]["output"];
    x: Scalars["Float"]["output"];
    y: Scalars["Float"]["output"];
};

export enum GqlClientRelevantPrograms {
    ALL = "all",
    CUSTOM = "custom"
}

export type GqlClientRemoveAllExternalUsersFromAccountOutput = {
    __typename?: "RemoveAllExternalUsersFromAccountOutput";
    result: GqlClientRemoveAllExternalUsersFromAccountResult;
    userIds: Array<Scalars["String"]["output"]>;
};

export enum GqlClientRemoveAllExternalUsersFromAccountResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientRemoveConnectorFromAccountInput = {
    connectorType: Scalars["String"]["input"];
};

export type GqlClientRemoveConnectorFromAccountOutput = {
    __typename?: "RemoveConnectorFromAccountOutput";
    result: GqlClientRemoveConnectorFromAccountResult;
};

export enum GqlClientRemoveConnectorFromAccountResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientRemoveEditorProgramVersionLogicAndDataLibraryInput = {
    editorProgramVersionId: Scalars["ID"]["input"];
};

export type GqlClientRemoveEditorProgramVersionLogicAndDataLibraryOutput = {
    __typename?: "RemoveEditorProgramVersionLogicAndDataLibraryOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientRemoveEditorProgramVersionLogicAndDataLibraryResult;
};

export enum GqlClientRemoveEditorProgramVersionLogicAndDataLibraryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientRemoveServiceFromAccountInput = {
    serviceType: GqlClientServiceType;
};

export type GqlClientRemoveServiceFromAccountOutput = {
    __typename?: "RemoveServiceFromAccountOutput";
    result: GqlClientRemoveServiceFromAccountResult;
};

export enum GqlClientRemoveServiceFromAccountResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientRemoveStoryTemplateFromGalleryInput = {
    galleryType: GqlClientStoryTemplatesGalleryType;
    storyTemplateVersionId: Scalars["ID"]["input"];
};

export type GqlClientRemoveStoryTemplateFromGalleryOutput = {
    __typename?: "RemoveStoryTemplateFromGalleryOutput";
    result: GqlClientRemoveStoryTemplateFromGalleryResult;
};

export enum GqlClientRemoveStoryTemplateFromGalleryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientRenameAccountProgramFolderInput = {
    id: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
};

export type GqlClientRenameAccountProgramFolderOutput = {
    __typename?: "RenameAccountProgramFolderOutput";
    folder?: Maybe<GqlClientAccountProgramFolder>;
    result?: Maybe<GqlClientRenameAccountProgramFolderResult>;
};

export enum GqlClientRenameAccountProgramFolderResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientRenameEditorAssetFolderInput = {
    folderId: Scalars["ID"]["input"];
    folderName: Scalars["String"]["input"];
    parentFolderId: Scalars["ID"]["input"];
};

export type GqlClientRenameEditorAssetFolderOutput = {
    __typename?: "RenameEditorAssetFolderOutput";
    folder: GqlClientEditorAssetFolder;
    result: GqlClientRenameEditorAssetFolderResult;
};

export enum GqlClientRenameEditorAssetFolderResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientRenameProgramReviewInput = {
    id: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
};

export type GqlClientRenameProgramReviewOutput = {
    __typename?: "RenameProgramReviewOutput";
    programReview?: Maybe<GqlClientProgramReview>;
    result: GqlClientRenameProgramReviewResult;
};

export enum GqlClientRenameProgramReviewResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientReportElement = {
    __typename?: "ReportElement";
    customerFacing?: Maybe<Scalars["Boolean"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    elementType: GqlClientReportElementType;
    formatType: GqlClientReportElementFormatType;
    formatter?: Maybe<Scalars["JSON"]["output"]>;
    name: Scalars["String"]["output"];
    notCustomerFacingInBuilder?: Maybe<Scalars["Boolean"]["output"]>;
    placeHolder?: Maybe<Scalars["String"]["output"]>;
    platformDescription?: Maybe<Scalars["String"]["output"]>;
    platformName?: Maybe<Scalars["String"]["output"]>;
    reportLibraryConfigKey: Scalars["String"]["output"];
};

export enum GqlClientReportElementFormatType {
    BOOLEAN = "boolean",
    DATETIME = "datetime",
    NUMBER = "number",
    STRING = "string"
}

export enum GqlClientReportElementType {
    ATTRIBUTIONMETRIC = "attributionMetric",
    DATAELEMENT = "dataElement",
    DIMENSION = "dimension",
    EMPTY = "empty",
    GENERAL = "general",
    HOTSPOT = "hotspot",
    INTERACTION = "interaction",
    METRIC = "metric",
    SURVEY = "survey",
    TRACKINGGROUP = "trackingGroup"
}

export type GqlClientReportLibraryElement = {
    __typename?: "ReportLibraryElement";
    customerFacing?: Maybe<Scalars["Boolean"]["output"]>;
    dataSamples?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    defaultOrder?: Maybe<Scalars["Int"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    elementType: GqlClientReportElementType;
    formatType: GqlClientReportElementFormatType;
    id?: Maybe<Scalars["ID"]["output"]>;
    isDefaultColumn?: Maybe<Scalars["Boolean"]["output"]>;
    name: Scalars["String"]["output"];
    notCustomerFacingInBuilder?: Maybe<Scalars["Boolean"]["output"]>;
    placeHolder?: Maybe<Scalars["String"]["output"]>;
    platformDescription?: Maybe<Scalars["String"]["output"]>;
    platformName?: Maybe<Scalars["String"]["output"]>;
    reportLibraryConfigKey: Scalars["String"]["output"];
    subElements?: Maybe<Array<GqlClientReportLibraryElement>>;
};

export type GqlClientReportLibraryGeneralElement = {
    __typename?: "ReportLibraryGeneralElement";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    formatType?: Maybe<GqlClientReportElementFormatType>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientReportProgramType {
    BUILDER = "builder",
    EDITOR = "editor",
    PAIDMEDIA = "paidMedia"
}

export enum GqlClientReportScheduleOccurrence {
    DAILY = "daily",
    MONTHLY = "monthly",
    WEEKLY = "weekly",
    YEARLY = "yearly"
}

export enum GqlClientReportSchedulePeriod {
    PAST_MONTH = "past_month",
    PAST_THREE_MONTHS = "past_three_months",
    PAST_TWO_MONTHS = "past_two_months",
    PAST_WEEK = "past_week",
    PREVIOUS_DAY = "previous_day"
}

export enum GqlClientReportSchedulePeriodType {
    CUMULATIVE = "cumulative",
    CUSTOM = "custom",
    RELATIVE = "relative"
}

export enum GqlClientReportingDataSource {
    OPPORTUNITY = "OPPORTUNITY",
    RECOMMENDATION_ENGINE = "RECOMMENDATION_ENGINE",
    USER_DATA = "USER_DATA",
    USER_EVENT = "USER_EVENT"
}

export type GqlClientReportingDefinitions = {
    __typename?: "ReportingDefinitions";
    attributeType: Scalars["String"]["output"];
    fieldValue?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    tagName?: Maybe<Scalars["String"]["output"]>;
    targetField: Scalars["String"]["output"];
};

export enum GqlClientReportingTargetField {
    A1 = "A1",
    A2 = "A2",
    A3 = "A3",
    A4 = "A4",
    A5 = "A5",
    A6 = "A6",
    A7 = "A7",
    A8 = "A8",
    A9 = "A9",
    A10 = "A10",
    X1 = "X1",
    X2 = "X2",
    X3 = "X3",
    X4 = "X4",
    X5 = "X5",
    X6 = "X6",
    X7 = "X7",
    X8 = "X8",
    X9 = "X9",
    X10 = "X10"
}

export enum GqlClientRequestedAvatarAnimationStatus {
    ERROR = "ERROR",
    PENDING = "PENDING",
    READY = "READY"
}

export type GqlClientRequestedAvatarAnimationStatusPayload = {
    status: GqlClientRequestedAvatarAnimationStatus;
};

export type GqlClientRequestedAvatarAnimationStatusPayloadError = GqlClientRequestedAvatarAnimationStatusPayload & {
    __typename?: "RequestedAvatarAnimationStatusPayloadError";
    status: GqlClientRequestedAvatarAnimationStatus;
};

export type GqlClientRequestedAvatarAnimationStatusPayloadPending = GqlClientRequestedAvatarAnimationStatusPayload & {
    __typename?: "RequestedAvatarAnimationStatusPayloadPending";
    progress: Scalars["Int"]["output"];
    status: GqlClientRequestedAvatarAnimationStatus;
    url: Scalars["String"]["output"];
};

export type GqlClientRequestedAvatarAnimationStatusPayloadReady = GqlClientRequestedAvatarAnimationStatusPayload & {
    __typename?: "RequestedAvatarAnimationStatusPayloadReady";
    status: GqlClientRequestedAvatarAnimationStatus;
    url: Scalars["String"]["output"];
};

export type GqlClientResolveAnimationInput = {
    legacyAnimationName: Scalars["String"]["input"];
    programId: Scalars["String"]["input"];
};

export type GqlClientResolveAnimationOutput = {
    result: GqlClientResolveAnimationResult;
};

export type GqlClientResolveAnimationOutputNotMigrated = GqlClientResolveAnimationOutput & {
    __typename?: "ResolveAnimationOutputNotMigrated";
    result: GqlClientResolveAnimationResult;
};

export type GqlClientResolveAnimationOutputSuccess = GqlClientResolveAnimationOutput & {
    __typename?: "ResolveAnimationOutputSuccess";
    location: Scalars["String"]["output"];
    result: GqlClientResolveAnimationResult;
};

export enum GqlClientResolveAnimationResult {
    NOT_MIGRATED = "NOT_MIGRATED",
    SUCCESS = "SUCCESS"
}

export type GqlClientResolveAnimationsInput = {
    legacyAnimationNames: Array<Scalars["String"]["input"]>;
    programId: Scalars["String"]["input"];
};

export type GqlClientResolveAnimationsOutput = {
    result: GqlClientResolveAnimationsResult;
};

export type GqlClientResolveAnimationsOutputNotMigrated = GqlClientResolveAnimationsOutput & {
    __typename?: "ResolveAnimationsOutputNotMigrated";
    result: GqlClientResolveAnimationsResult;
};

export type GqlClientResolveAnimationsOutputSuccess = GqlClientResolveAnimationsOutput & {
    __typename?: "ResolveAnimationsOutputSuccess";
    locations: Array<Maybe<Scalars["String"]["output"]>>;
    result: GqlClientResolveAnimationsResult;
};

export enum GqlClientResolveAnimationsResult {
    NOT_MIGRATED = "NOT_MIGRATED",
    SUCCESS = "SUCCESS"
}

export type GqlClientResolveAssetInput = {
    legacyAssetName: Scalars["String"]["input"];
    programId: Scalars["String"]["input"];
};

export type GqlClientResolveAssetOutput = {
    result: GqlClientResolveAssetResult;
};

export type GqlClientResolveAssetOutputNotMigrated = GqlClientResolveAssetOutput & {
    __typename?: "ResolveAssetOutputNotMigrated";
    result: GqlClientResolveAssetResult;
};

export type GqlClientResolveAssetOutputSuccess = GqlClientResolveAssetOutput & {
    __typename?: "ResolveAssetOutputSuccess";
    location: Scalars["String"]["output"];
    result: GqlClientResolveAssetResult;
};

export enum GqlClientResolveAssetResult {
    NOT_MIGRATED = "NOT_MIGRATED",
    SUCCESS = "SUCCESS"
}

export type GqlClientResolveAssetsInput = {
    legacyAssetNames: Array<Scalars["String"]["input"]>;
    programId: Scalars["String"]["input"];
};

export type GqlClientResolveAssetsOutput = {
    result: GqlClientResolveAssetsResult;
};

export type GqlClientResolveAssetsOutputNotMigrated = GqlClientResolveAssetsOutput & {
    __typename?: "ResolveAssetsOutputNotMigrated";
    result: GqlClientResolveAssetsResult;
};

export type GqlClientResolveAssetsOutputSuccess = GqlClientResolveAssetsOutput & {
    __typename?: "ResolveAssetsOutputSuccess";
    locations: Array<Maybe<Scalars["String"]["output"]>>;
    result: GqlClientResolveAssetsResult;
};

export enum GqlClientResolveAssetsResult {
    NOT_MIGRATED = "NOT_MIGRATED",
    SUCCESS = "SUCCESS"
}

export type GqlClientResolveDataTablesInput = {
    programId: Scalars["String"]["input"];
};

export type GqlClientResolveDataTablesOutput = {
    result: GqlClientResolveDataTablesResult;
};

export type GqlClientResolveDataTablesOutputDataTable = {
    __typename?: "ResolveDataTablesOutputDataTable";
    assetId: Scalars["String"]["output"];
    decisionTablesLocation: Scalars["String"]["output"];
};

export type GqlClientResolveDataTablesOutputNotMigrated = GqlClientResolveDataTablesOutput & {
    __typename?: "ResolveDataTablesOutputNotMigrated";
    result: GqlClientResolveDataTablesResult;
};

export type GqlClientResolveDataTablesOutputSuccess = GqlClientResolveDataTablesOutput & {
    __typename?: "ResolveDataTablesOutputSuccess";
    dataTables: Array<GqlClientResolveDataTablesOutputDataTable>;
    result: GqlClientResolveDataTablesResult;
};

export enum GqlClientResolveDataTablesResult {
    NOT_MIGRATED = "NOT_MIGRATED",
    SUCCESS = "SUCCESS"
}

export type GqlClientResolveNarratorsInput = {
    programId: Scalars["String"]["input"];
};

export type GqlClientResolveNarratorsOutput = {
    result: GqlClientResolveNarratorsResult;
};

export type GqlClientResolveNarratorsOutputNarrator = {
    __typename?: "ResolveNarratorsOutputNarrator";
    assetId: Scalars["String"]["output"];
    decisionTablesLocation: Scalars["String"]["output"];
    location: Scalars["String"]["output"];
};

export type GqlClientResolveNarratorsOutputNotMigrated = GqlClientResolveNarratorsOutput & {
    __typename?: "ResolveNarratorsOutputNotMigrated";
    result: GqlClientResolveNarratorsResult;
};

export type GqlClientResolveNarratorsOutputSuccess = GqlClientResolveNarratorsOutput & {
    __typename?: "ResolveNarratorsOutputSuccess";
    narrators: Array<GqlClientResolveNarratorsOutputNarrator>;
    result: GqlClientResolveNarratorsResult;
};

export enum GqlClientResolveNarratorsResult {
    NOT_MIGRATED = "NOT_MIGRATED",
    SUCCESS = "SUCCESS"
}

export type GqlClientResolveRecordingInput = {
    programId: Scalars["String"]["input"];
    recordingRuntimeName: Scalars["String"]["input"];
};

export type GqlClientResolveRecordingOutput = {
    __typename?: "ResolveRecordingOutput";
    location: Scalars["String"]["output"];
};

export enum GqlClientRestrictionType {
    BOOLEAN = "boolean",
    COUNTER = "counter"
}

export enum GqlClientRestrictions {
    ACCOUNT_RESTRICTED = "ACCOUNT_RESTRICTED",
    NOT_RESTRICTED = "NOT_RESTRICTED",
    USER_RESTRICTED = "USER_RESTRICTED"
}

export enum GqlClientReviewCreationStatus {
    FAILED = "failed",
    IN_PROGRESS = "in_progress",
    SUCCESSFUL = "successful"
}

export enum GqlClientRole {
    ACCOUNTOWNER = "accountOwner",
    ADMIN = "admin",
    ADSSUNDAYSKYVIEWER = "adsSundayskyViewer",
    ADS_ADMIN = "ads_admin",
    ADS_USER = "ads_user",
    ALLACCOUNTSMANAGER = "allAccountsManager",
    ANIMATOR = "animator",
    AUTHORINGSERVER = "authoringServer",
    BI = "bi",
    BUILDEREDITOR = "builderEditor",
    BUILDEROVERVIEW = "builderOverview",
    BUILDERREPORTING = "builderReporting",
    BUILDERVIEWER = "builderViewer",
    EXPORTACCOUNTDATA = "exportAccountData",
    EXTERNAL = "external",
    FRAMEWORKEDITOR = "frameworkEditor",
    FRAMEWORKVIEWER = "frameworkViewer",
    INTERNAL = "internal",
    PERFORMANCETOOLRESULTUPDATE = "performanceToolResultUpdate",
    PLATFORMEDITOR = "platformEditor",
    PLATFORMVIEWER = "platformViewer",
    PRODUCTCREATIVE = "productCreative",
    PROFESSIONALSERVICES = "professionalServices",
    REPORTINGSERVER = "reportingServer",
    REPORTSBYINTERACTIONADMIN = "reportsByInteractionAdmin",
    RESOLVER = "resolver",
    REVIEWER = "reviewer",
    SHAREDFRAMEWORKVIEWER = "sharedFrameworkViewer",
    SHOPIFYSERVER = "shopifyServer",
    STORYTEMPLATESEDITOR = "storyTemplatesEditor"
}

export enum GqlClientRuleMatchType {
    MATCH_ALL_RULES = "MATCH_ALL_RULES",
    MATCH_ANY_RULE = "MATCH_ANY_RULE"
}

export enum GqlClientRuleType {
    DMP_SEGMENT = "DMP_SEGMENT",
    IMPRESSION = "IMPRESSION",
    SITE_VISITOR = "SITE_VISITOR"
}

export type GqlClientSaveUserRecentSearchInput = {
    searchTerm: Scalars["String"]["input"];
};

export type GqlClientSaveUserRecentSearchOutput = {
    __typename?: "SaveUserRecentSearchOutput";
    result: GqlClientSaveUserRecentSearchResult;
    userSettings: GqlClientUserSettings;
};

export enum GqlClientSaveUserRecentSearchResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientScene = {
    __typename?: "Scene";
    accountId: Scalars["ID"]["output"];
    animationLogic?: Maybe<Scalars["JSON"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    descriptiveTranscriptLogic?: Maybe<Scalars["JSON"]["output"]>;
    feedDataElements: Array<GqlClientFeedDataElement>;
    id: Scalars["ID"]["output"];
    inputs: Array<GqlClientInput>;
    kpis: Array<GqlClientKpi>;
    name: Scalars["String"]["output"];
    personalizations: Array<GqlClientPersonalization>;
    programVersion: GqlClientProgramVersion;
    scenePartOrder: Array<Scalars["ID"]["output"]>;
    sceneParts: Array<GqlClientScenePart>;
    stories: Array<GqlClientStory>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    validationLogic?: Maybe<Scalars["JSON"]["output"]>;
};

export type GqlClientSceneEntityLogic = {
    __typename?: "SceneEntityLogic";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    logic?: Maybe<Scalars["JSON"]["output"]>;
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientSceneFromPromptCreatedPayload = {
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientSceneFromPromptCreatedPayloadData = {
    __typename?: "SceneFromPromptCreatedPayloadData";
    editorScenes: Array<Maybe<GqlClientEditorScene>>;
};

export type GqlClientSceneFromPromptCreatedPayloadError = GqlClientSceneFromPromptCreatedPayload & {
    __typename?: "SceneFromPromptCreatedPayloadError";
    error: Scalars["JSON"]["output"];
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientSceneFromPromptCreatedPayloadKeepAlive = GqlClientSceneFromPromptCreatedPayload & {
    __typename?: "SceneFromPromptCreatedPayloadKeepAlive";
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientSceneFromPromptCreatedPayloadPending = GqlClientSceneFromPromptCreatedPayload & {
    __typename?: "SceneFromPromptCreatedPayloadPending";
    mutationId: Scalars["String"]["output"];
    progress: Scalars["Float"]["output"];
    status: GqlClientStatus;
};

export type GqlClientSceneFromPromptCreatedPayloadSuccess = GqlClientSceneFromPromptCreatedPayload & {
    __typename?: "SceneFromPromptCreatedPayloadSuccess";
    data: GqlClientSceneFromPromptCreatedPayloadData;
    mutationId: Scalars["String"]["output"];
    status: GqlClientStatus;
};

export type GqlClientScenePart = {
    __typename?: "ScenePart";
    accountId: Scalars["ID"]["output"];
    animationGuidelines: Array<GqlClientAnimationGuideline>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    narrationPartOrder: Array<Scalars["ID"]["output"]>;
    narrations: Array<GqlClientNarration>;
    oldNarrationData: Array<Scalars["JSON"]["output"]>;
    placeholderGroups: Array<GqlClientPlaceholderGroup>;
    placeholders: Array<GqlClientPlaceholder>;
    prioritizedLists: Array<GqlClientPrioritizedList>;
    scene: GqlClientScene;
    thumbnailLocation?: Maybe<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientSelectDataLibraryInput = {
    accountDataLibraryVersionId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSelectDataLibraryOutput = {
    __typename?: "SelectDataLibraryOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientSelectDataLibraryResult;
};

export enum GqlClientSelectDataLibraryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientSendAccountReportInput = {
    deliveryChannel?: InputMaybe<Scalars["JSON"]["input"]>;
    endDate?: InputMaybe<Scalars["String"]["input"]>;
    reportId: Scalars["ID"]["input"];
    sourceEnvironments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    startDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientSendAccountReportOutput = {
    __typename?: "SendAccountReportOutput";
    result: GqlClientSendAccountReportResult;
};

export enum GqlClientSendAccountReportResult {
    SUCCESS = "SUCCESS"
}

export enum GqlClientServiceType {
    ACQUIA = "acquia"
}

export type GqlClientSetAnalyticsDashboardInput = {
    enabled: Scalars["Boolean"]["input"];
    externalDashboardId: Scalars["String"]["input"];
    programId: Scalars["ID"]["input"];
};

export type GqlClientSetAnalyticsDashboardOutput = {
    __typename?: "SetAnalyticsDashboardOutput";
    program: GqlClientProgram;
    result: GqlClientSetAnalyticsDashboardResult;
};

export enum GqlClientSetAnalyticsDashboardResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientSetCcActiveSnapshotInput = {
    libraryVersionId: Scalars["ID"]["input"];
};

export type GqlClientSetCcActiveSnapshotOutput = {
    result: GqlClientSetCcActiveSnapshotResult;
};

export type GqlClientSetCcActiveSnapshotOutputMigrationFailed = GqlClientSetCcActiveSnapshotOutput & {
    __typename?: "SetCcActiveSnapshotOutputMigrationFailed";
    errors: Array<Scalars["String"]["output"]>;
    result: GqlClientSetCcActiveSnapshotResult;
};

export type GqlClientSetCcActiveSnapshotOutputSuccessful = GqlClientSetCcActiveSnapshotOutput & {
    __typename?: "SetCcActiveSnapshotOutputSuccessful";
    activeSnapshot?: Maybe<GqlClientCcLibraryVersion>;
    result: GqlClientSetCcActiveSnapshotResult;
};

export enum GqlClientSetCcActiveSnapshotResult {
    MIGRATION_FAILURE = "MIGRATION_FAILURE",
    SUCCESS = "SUCCESS"
}

export type GqlClientSetSurveysInput = {
    programId: Scalars["ID"]["input"];
    surveys: Array<GqlClientSetSurveysSurveyInput>;
};

export type GqlClientSetSurveysOutput = {
    __typename?: "SetSurveysOutput";
    program: GqlClientProgram;
    result: GqlClientSetSurveysResult;
};

export enum GqlClientSetSurveysResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientSetSurveysSurveyInput = {
    externalId: Scalars["String"]["input"];
    questions?: InputMaybe<Scalars["JSON"]["input"]>;
    source: GqlClientSurveySource;
};

export type GqlClientSetTrackingParametersInput = {
    programId: Scalars["ID"]["input"];
    trackingParameters: Array<GqlClientSetTrackingParametersTrackingParameterInput>;
};

export type GqlClientSetTrackingParametersOutput = {
    __typename?: "SetTrackingParametersOutput";
    program: GqlClientProgram;
    result: GqlClientSetTrackingParametersResult;
};

export enum GqlClientSetTrackingParametersResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientSetTrackingParametersTrackingParameterInput = {
    name: Scalars["String"]["input"];
};

export type GqlClientSnapshotRuntimeAsset = {
    __typename?: "SnapshotRuntimeAsset";
    decisionTablesLocation?: Maybe<Scalars["String"]["output"]>;
    location: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    thumbnailUrl?: Maybe<Scalars["String"]["output"]>;
    type: Scalars["String"]["output"];
    url?: Maybe<Scalars["String"]["output"]>;
    version: Scalars["Int"]["output"];
};

export type GqlClientSocialNetworkPostHistory = {
    __typename?: "SocialNetworkPostHistory";
    created: Scalars["String"]["output"];
    errors?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    postDescription: Scalars["String"]["output"];
    postId?: Maybe<GqlClientPostId>;
    postStatus: Scalars["String"]["output"];
};

export type GqlClientSocialNetworkStatus = {
    __typename?: "SocialNetworkStatus";
    displayName?: Maybe<Scalars["String"]["output"]>;
    platform?: Maybe<Scalars["String"]["output"]>;
    userImage?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientSocialNetworks = {
    __typename?: "SocialNetworks";
    manageSocialNetworksUrl: Scalars["String"]["output"];
    postsHistory: Array<GqlClientSocialNetworkPostHistory>;
    statuses: Array<GqlClientSocialNetworkStatus>;
};

export enum GqlClientStatus {
    ERROR = "ERROR",
    KEEP_ALIVE = "KEEP_ALIVE",
    PARTIAL_SUCCESS = "PARTIAL_SUCCESS",
    PENDING = "PENDING",
    SUCCESS = "SUCCESS"
}

export type GqlClientStory = {
    __typename?: "Story";
    accountId: Scalars["ID"]["output"];
    activateCaching?: Maybe<Scalars["Boolean"]["output"]>;
    backgroundAssetType?: Maybe<GqlClientBackgroundAssetType>;
    backgroundAudioLogic?: Maybe<Scalars["JSON"]["output"]>;
    backgroundImageLogic?: Maybe<Scalars["JSON"]["output"]>;
    backgroundVideoLogic?: Maybe<Scalars["JSON"]["output"]>;
    channels: Array<GqlClientChannel>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    decisionPoints: Array<GqlClientDecisionPoint>;
    description?: Maybe<Scalars["String"]["output"]>;
    displaySceneOrder: Array<Scalars["ID"]["output"]>;
    durationOptions?: Maybe<Array<Scalars["Int"]["output"]>>;
    id: Scalars["ID"]["output"];
    initialDecisionPoint?: Maybe<GqlClientDecisionPoint>;
    name: Scalars["String"]["output"];
    narratorName?: Maybe<Scalars["String"]["output"]>;
    numberOfProducts?: Maybe<Array<Scalars["Int"]["output"]>>;
    programVersion: GqlClientProgramVersion;
    ratioAndQualityLogic?: Maybe<Scalars["JSON"]["output"]>;
    ratioAndQualityOptions?: Maybe<Array<GqlClientRationAndQualityOption>>;
    scenes: Array<GqlClientScene>;
    touchpoints: Array<GqlClientTouchpoint>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientStoryTemplateStatus = {
    __typename?: "StoryTemplateStatus";
    accountId: Scalars["String"]["output"];
    indication: GqlClientStoryTemplatesReviewGalleryIndication;
    programIdOnly: Scalars["String"]["output"];
    storyTemplateId: Scalars["ID"]["output"];
};

export enum GqlClientStoryTemplatesGalleryType {
    PRODUCTION = "production",
    QA = "qa"
}

export enum GqlClientStoryTemplatesReviewGalleryIndication {
    NOT_PUBLISHED = "not_published",
    PUBLISHED = "published",
    UPDATE_REQUIRED = "update_required"
}

export enum GqlClientSubComponent {
    ACCOUNT = "ACCOUNT",
    ASPECT_RATIO = "ASPECT_RATIO",
    ASSET = "ASSET",
    BRAND = "BRAND",
    CHAPTERS = "CHAPTERS",
    COPILOT = "COPILOT",
    CUSTOM_AVATAR = "CUSTOM_AVATAR",
    DATA = "DATA",
    DATA_TABLE = "DATA_TABLE",
    EDITOR = "EDITOR",
    FEATURE_FLAG = "FEATURE_FLAG",
    FLEXIBLE_PLACEHOLDER = "FLEXIBLE_PLACEHOLDER",
    FONT = "FONT",
    HELP_CENTER = "HELP_CENTER",
    LANDING_PAGE = "LANDING_PAGE",
    LAYOUTS = "LAYOUTS",
    LIFECYCLE = "LIFECYCLE",
    MUSIC = "MUSIC",
    NARRATION = "NARRATION",
    NONE = "NONE",
    OVERVIEW = "OVERVIEW",
    PLACEHOLDER = "PLACEHOLDER",
    PROGRAM = "PROGRAM",
    PROGRAM_SHARE_HISTORY = "PROGRAM_SHARE_HISTORY",
    PROGRAM_THUMBNAIL = "PROGRAM_THUMBNAIL",
    PUBLISH = "PUBLISH",
    RECORDING = "RECORDING",
    REPORT_AND_ANALYTICS = "REPORT_AND_ANALYTICS",
    REVIEW = "REVIEW",
    RPTV = "RPTV",
    SCENE = "SCENE",
    STORY_TEMPLATE = "STORY_TEMPLATE",
    SUBSCRIPTION = "SUBSCRIPTION",
    SURVEY = "SURVEY",
    THEME = "THEME",
    TTS = "TTS",
    UNDO = "UNDO",
    USER_MANAGEMENT = "USER_MANAGEMENT",
    VIDEO_CUSTOM_BACKGROUND = "VIDEO_CUSTOM_BACKGROUND",
    VIDEO_FOLDERS = "VIDEO_FOLDERS",
    VIEWER_PROFILE = "VIEWER_PROFILE"
}

export type GqlClientSubscription = {
    __typename?: "Subscription";
    _?: Maybe<Scalars["Boolean"]["output"]>;
    accountAvatarLibraryChanged?: Maybe<GqlClientEditorAssetLibraryVersion>;
    accountCcLibraryVersionChanged?: Maybe<GqlClientCcLibraryVersion>;
    accountConnectionsChanged?: Maybe<GqlClientAccount>;
    accountCopilotBriefChanged?: Maybe<GqlClientAccountCopilotBrief>;
    accountCopilotBriefListChanged?: Maybe<GqlClientAccount>;
    accountDataLibraryForImportDataChanged?: Maybe<GqlClientAccountDataLibrary>;
    accountDataLibraryListChanged?: Maybe<GqlClientAccount>;
    accountDataLibraryProgramListChanged?: Maybe<GqlClientAccountDataLibraryVersion>;
    accountDataLibraryProgramUsedInChanged?: Maybe<GqlClientEditorProgram>;
    accountDataLibraryVersionChanged?: Maybe<GqlClientAccountDataLibraryVersion>;
    accountEditorProgramDeleted?: Maybe<GqlClientAccount>;
    accountEditorProgramUpdated?: Maybe<GqlClientEditorProgram>;
    accountFeaturesDataChanged?: Maybe<GqlClientAccount>;
    accountFontLibraryChanged?: Maybe<GqlClientEditorAssetLibraryVersion>;
    accountMediaAssetLibraryChanged?: Maybe<GqlClientEditorAssetLibraryVersion>;
    accountMusicLibraryChanged?: Maybe<GqlClientEditorAssetLibraryVersion>;
    accountProgramFolderChanged?: Maybe<GqlClientAccountProgramFolder>;
    accountProgramFolderDeleted?: Maybe<GqlClientAccount>;
    accountProgramFoldersCountChanged?: Maybe<GqlClientAccount>;
    accountPronunciationDefinitionChanged?: Maybe<GqlClientAccountPronunciationDefinition>;
    accountPronunciationLibraryVersionChanged?: Maybe<GqlClientAccountPronunciationLibraryVersion>;
    accountServiceConnectionsChanged: GqlClientAccount;
    aiPreviewProgramCreated?: Maybe<GqlClientAiPreviewProgramCreatedPayload>;
    aiVideoFromImageGenerated?: Maybe<GqlClientAiVideoFromImageGeneratedPayload>;
    brandConfigurationCollectionChanged?: Maybe<GqlClientBrandConfigurationCollection>;
    brandConfigurationVersionChanged?: Maybe<GqlClientBrandConfigurationVersion>;
    customAvatarCreationStarted?: Maybe<GqlClientCustomAvatarCreationStartedPayload>;
    dataLibraryImportDataUpdated?: Maybe<GqlClientDataLibraryImportDataUpdatedPayload>;
    dataLibraryImportHistoryChanged?: Maybe<GqlClientDataLibraryImportHistory>;
    editorAssetEdited?: Maybe<GqlClientEditorAssetEditedPayload>;
    editorAssetMediaUploaded?: Maybe<GqlClientEditorAssetMediaUploadedPayload>;
    editorProgramVersionLogicRemoved?: Maybe<GqlClientEditorProgramVersion>;
    nextRecommendedVideoCreated?: Maybe<GqlClientNextRecommendedVideoCreatedPayload>;
    programEditorPublished?: Maybe<GqlClientProgramEditorPublishedPayload>;
    programEmbedVideoCodeUpdated?: Maybe<GqlClientEmbedVideoCode>;
    programLandingPageUpdated?: Maybe<GqlClientLandingPage>;
    programReviewCreated: GqlClientProgramReviewsSummaryOutput;
    programShareHistoryRecordCreated: GqlClientEditorProgram;
    programVersionAnimatedWireframesCompatabilityChanged?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorAccountDataLibraryChanged?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorAspectRatioUpdated?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorAudioSettingsUpdated?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorAvatarRenderingUpdated?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorBrandRulesUpdated?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorChanged?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorCustomBackgroundUpdated?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorDataLibraryChanged?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorLineupUpdated?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorPlaceholderUpdated?: Maybe<GqlClientEditorPlaceholder>;
    programVersionEditorSceneCreated?: Maybe<GqlClientEditorScene>;
    programVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdated?: Maybe<GqlClientEditorScene>;
    programVersionEditorScenePlaceholdersUpdated?: Maybe<GqlClientEditorScene>;
    programVersionEditorSceneSelectedAnimatedWireframeUpdated?: Maybe<GqlClientEditorScene>;
    programVersionEditorSceneUpdated?: Maybe<GqlClientEditorScene>;
    programVersionEditorScenesCreated?: Maybe<GqlClientProgramEditorSceneCreatedPayloadData>;
    programVersionEditorScenesUpdated?: Maybe<GqlClientProgramEditorScenesUpdatedPayloadData>;
    programVersionEditorSelectedBcCollectionUpdated?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorSelectedMusicUpdated?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorSelectedNarratorUpdated?: Maybe<GqlClientEditorProgramVersion>;
    programVersionEditorThemeUpdated?: Maybe<GqlClientEditorProgramVersion>;
    programVersionNarrationRecordingStateCalculated?: Maybe<GqlClientProgramVersionNarrationRecordingStateCalculatedPayload>;
    programVersionThumbnailChanged?: Maybe<GqlClientEditorProgramVersion>;
    programVersionsAndBcCollectionsEditorChanged?: Maybe<GqlClientProgramVersionsAndBcCollectionsEditorChangedPayload>;
    programViewerProfilesUpdated?: Maybe<GqlClientEditorProgram>;
    recorderMediaUploadedToS3?: Maybe<GqlClientRecorderMediaUploadedToS3Payload>;
    requestedAvatarAnimationStatus?: Maybe<GqlClientRequestedAvatarAnimationStatusPayload>;
    sceneFromPromptCreated?: Maybe<GqlClientSceneFromPromptCreatedPayload>;
    undoStacksUpdated?: Maybe<GqlClientUndoStacks>;
};

export type GqlClientSubscriptionAccountCcLibraryVersionChangedArgs = {
    libraryId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAccountConnectionsChangedArgs = {
    account: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAccountCopilotBriefChangedArgs = {
    briefId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAccountCopilotBriefListChangedArgs = {
    account: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAccountDataLibraryForImportDataChangedArgs = {
    dataLibraryId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAccountDataLibraryListChangedArgs = {
    account: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAccountDataLibraryProgramListChangedArgs = {
    accountDataLibraryVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAccountDataLibraryProgramUsedInChangedArgs = {
    accountDataLibraryVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAccountDataLibraryVersionChangedArgs = {
    accountDataLibraryVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAccountFeaturesDataChangedArgs = {
    account: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAccountPronunciationDefinitionChangedArgs = {
    accountId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAccountPronunciationLibraryVersionChangedArgs = {
    accountId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAiPreviewProgramCreatedArgs = {
    sessionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionAiVideoFromImageGeneratedArgs = {
    mutationId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionBrandConfigurationVersionChangedArgs = {
    accountId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionCustomAvatarCreationStartedArgs = {
    mutationId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionDataLibraryImportDataUpdatedArgs = {
    dataLibraryId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionDataLibraryImportHistoryChangedArgs = {
    dataLibraryId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionEditorAssetEditedArgs = {
    accountId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionEditorAssetMediaUploadedArgs = {
    accountId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionEditorProgramVersionLogicRemovedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionNextRecommendedVideoCreatedArgs = {
    sessionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramEditorPublishedArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramEmbedVideoCodeUpdatedArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramLandingPageUpdatedArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramReviewCreatedArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramShareHistoryRecordCreatedArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionAnimatedWireframesCompatabilityChangedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorAccountDataLibraryChangedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorAspectRatioUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorAudioSettingsUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorAvatarRenderingUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorBrandRulesUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorChangedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorCustomBackgroundUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorDataLibraryChangedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorLineupUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorPlaceholderUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorSceneCreatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorScenePlaceholdersUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorSceneUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorScenesCreatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorScenesUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorSelectedBcCollectionUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorSelectedMusicUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorSelectedNarratorUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionEditorThemeUpdatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionNarrationRecordingStateCalculatedArgs = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramVersionThumbnailChangedArgs = {
    accountId: Scalars["String"]["input"];
};

export type GqlClientSubscriptionProgramVersionsAndBcCollectionsEditorChangedArgs = {
    accountId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionProgramViewerProfilesUpdatedArgs = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionRecorderMediaUploadedToS3Args = {
    mutationId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionRequestedAvatarAnimationStatusArgs = {
    videoId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionSceneFromPromptCreatedArgs = {
    mutationId: Scalars["ID"]["input"];
};

export type GqlClientSubscriptionUndoStacksUpdatedArgs = {
    userId: Scalars["ID"]["input"];
};

export type GqlClientSurvey = {
    __typename?: "Survey";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    externalId: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    program: GqlClientProgram;
    questions: Array<GqlClientSurveyQuestion>;
    source: GqlClientSurveySource;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientSurveyAnalyticsAnswer = {
    __typename?: "SurveyAnalyticsAnswer";
    answerText: Scalars["String"]["output"];
    responseCount: Scalars["Int"]["output"];
};

export type GqlClientSurveyAnalyticsQuestion = {
    __typename?: "SurveyAnalyticsQuestion";
    answers: Array<GqlClientSurveyAnalyticsAnswer>;
    answersTotal: Scalars["Int"]["output"];
    text?: Maybe<Scalars["String"]["output"]>;
    type?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientSurveyQuestion = {
    __typename?: "SurveyQuestion";
    externalId: Scalars["String"]["output"];
    text?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientSurveySource {
    FORMSTACK = "formstack",
    SURVEYDB = "surveydb"
}

export type GqlClientSwitchBcCollectionAndDeleteCurrentInput = {
    currentBcCollectionId: Scalars["ID"]["input"];
    editorProgramVersionIds: Array<Scalars["ID"]["input"]>;
    targetBcCollectionId: Scalars["ID"]["input"];
};

export type GqlClientSwitchBcCollectionAndDeleteCurrentOutput = {
    __typename?: "SwitchBcCollectionAndDeleteCurrentOutput";
    brandConfigurationVersion: GqlClientBrandConfigurationVersion;
    editorProgramVersions: Array<GqlClientEditorProgramVersion>;
    result: GqlClientSwitchBcCollectionAndDeleteCurrentResult;
};

export enum GqlClientSwitchBcCollectionAndDeleteCurrentResult {
    SUCCESS = "SUCCESS"
}

export enum GqlClientTextStyleLetterCase {
    CAPEACHWORD = "capEachWord",
    CAPFIRSTWORD = "capFirstWord",
    LOWERCASE = "lowercase",
    NONE = "none",
    SMALLCAPS = "smallCaps",
    UPPERCASE = "uppercase"
}

export type GqlClientThumbnailAndStatus = {
    __typename?: "ThumbnailAndStatus";
    url?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientTierAccountDefinition = {
    __typename?: "TierAccountDefinition";
    accountDisplayName?: Maybe<Scalars["String"]["output"]>;
    accountId: Scalars["String"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    tierDefinition: GqlClientTierDefinition;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientTierDefinition = {
    __typename?: "TierDefinition";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    displayName: Scalars["String"]["output"];
    id: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientTierFeatureDefinition = {
    __typename?: "TierFeatureDefinition";
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    featureDefinition: GqlClientFeatureDefinition;
    featureRestrictionValue?: Maybe<Scalars["JSON"]["output"]>;
    id: Scalars["ID"]["output"];
    tierDefinition: GqlClientTierDefinition;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientTierManagementAuditLogActionSource {
    SALESFORCE_UPDATE = "salesforce_update",
    SCRIPT = "script",
    TIER_MANAGEMENT_PAGE = "tier_management_page"
}

export enum GqlClientTierManagementAuditLogEntityType {
    ACCOUNT_FEATURE_DATA = "account_feature_data",
    ACCOUNT_TIER_DEFINITION = "account_tier_definition",
    FEATURE_DEFINITION = "feature_definition",
    TIER_FEATURE_DEFINITION = "tier_feature_definition"
}

export type GqlClientTierManagementAuditLogRecord = {
    __typename?: "TierManagementAuditLogRecord";
    accountId?: Maybe<Scalars["String"]["output"]>;
    actionSource: GqlClientTierManagementAuditLogActionSource;
    actionType: Scalars["String"]["output"];
    created: Scalars["String"]["output"];
    createdBy: Scalars["String"]["output"];
    entityType: GqlClientTierManagementAuditLogEntityType;
    featureId?: Maybe<Scalars["String"]["output"]>;
    featureName?: Maybe<Scalars["String"]["output"]>;
    fieldValueAfter?: Maybe<Scalars["String"]["output"]>;
    fieldValueBefore?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    tierId?: Maybe<Scalars["String"]["output"]>;
    tierName?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientTouchpoint = {
    __typename?: "Touchpoint";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    programVersion: GqlClientProgramVersion;
    stories: Array<GqlClientStory>;
    touchpointPersonalizations: Array<GqlClientTouchpointPersonalization>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientTouchpointPersonalization = GqlClientPersonalization & {
    __typename?: "TouchpointPersonalization";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    scene: GqlClientScene;
    touchpoint: GqlClientTouchpoint;
    type: Array<Scalars["String"]["output"]>;
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientTrackingInfo = {
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
    resourceTrackingData: Scalars["JSON"]["input"];
    sceneOrder: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type GqlClientTrackingParameter = {
    __typename?: "TrackingParameter";
    accountId: Scalars["ID"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientTrayServiceConnectorEntity = {
    __typename?: "TrayServiceConnectorEntity";
    id: Scalars["ID"]["output"];
    isField: Scalars["Boolean"]["output"];
    name: Scalars["String"]["output"];
    parentId?: Maybe<Scalars["ID"]["output"]>;
    sampleValue?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientUndoActionInput = {
    isUndo: Scalars["Boolean"]["input"];
};

export type GqlClientUndoActionOutput = {
    __typename?: "UndoActionOutput";
    message: Scalars["String"]["output"];
    result: GqlClientUndoActionResult;
    undoStacks: GqlClientUndoStacks;
};

export enum GqlClientUndoActionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUndoDeleteBcCollectionInput = {
    bcCollectionId: Scalars["ID"]["input"];
    buttonShape?: InputMaybe<GqlClientButtonShapeValues>;
    colors?: InputMaybe<Array<GqlClientCreateColor>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    editorProgramVersionIdsToSwitchToBrand?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    logoLocation?: InputMaybe<Scalars["String"]["input"]>;
    logoMediaId?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    textStyles?: InputMaybe<Array<GqlClientCreateTextStyle>>;
};

export type GqlClientUndoDeleteBcCollectionOutput = {
    __typename?: "UndoDeleteBcCollectionOutput";
    brandConfigurationCollection?: Maybe<GqlClientBrandConfigurationCollection>;
    result: GqlClientUndoDeleteBcCollectionResult;
};

export enum GqlClientUndoDeleteBcCollectionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUndoDeleteEditorAssetFolderInput = {
    folderData: Scalars["JSON"]["input"];
    folderId: Scalars["ID"]["input"];
};

export type GqlClientUndoDeleteEditorAssetFolderOutputSuccess = {
    __typename?: "UndoDeleteEditorAssetFolderOutputSuccess";
    folder: GqlClientEditorAssetFolder;
    result: GqlClientUndoDeleteEditorAssetFolderResult;
};

export enum GqlClientUndoDeleteEditorAssetFolderResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUndoDeleteEditorAssetMediaInput = {
    media: Scalars["JSON"]["input"];
    mediaId: Scalars["ID"]["input"];
};

export type GqlClientUndoDeleteEditorAssetMediaOutputSuccess = {
    __typename?: "UndoDeleteEditorAssetMediaOutputSuccess";
    media: GqlClientEditorAssetMedia;
    result: GqlClientUndoDeleteEditorAssetMediaResult;
};

export enum GqlClientUndoDeleteEditorAssetMediaResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUndoStacks = {
    __typename?: "UndoStacks";
    firstRedoActionName?: Maybe<Scalars["String"]["output"]>;
    firstUndoActionName?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    redoStackSize: Scalars["Int"]["output"];
    undoStackSize: Scalars["Int"]["output"];
};

export type GqlClientUndoUpdateEditorProgramThemeInput = {
    programVersionId: Scalars["ID"]["input"];
    theme: Scalars["ID"]["input"];
    useCustomBackground: Scalars["Boolean"]["input"];
};

export type GqlClientUndoUpdateEditorProgramThemeOutput = {
    __typename?: "UndoUpdateEditorProgramThemeOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUndoUpdateEditorProgramThemeResult;
};

export enum GqlClientUndoUpdateEditorProgramThemeResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUnselectDataLibraryInput = {
    accountDataLibraryVersionId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientUnselectDataLibraryOutput = {
    __typename?: "UnselectDataLibraryOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUnselectDataLibraryResult;
};

export enum GqlClientUnselectDataLibraryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateAccountCopilotBriefInput = {
    id: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
};

export type GqlClientUpdateAccountCopilotBriefOutput = {
    __typename?: "UpdateAccountCopilotBriefOutput";
    account?: Maybe<GqlClientAccount>;
    brief?: Maybe<GqlClientAccountCopilotBrief>;
    result?: Maybe<GqlClientUpdateAccountCopilotBriefResult>;
};

export enum GqlClientUpdateAccountCopilotBriefResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingInput = {
    accountDataLibraryVersionId: Scalars["ID"]["input"];
    dataConnectorType: Scalars["String"]["input"];
    extractorConfiguration?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingOutput = {
    result: GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingResult;
};

export type GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingOutputConflict = GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingOutput & {
    __typename?: "UpdateAccountDataLibraryConnectorTypeAndMappingOutputConflict";
    accountDataLibraryVersion?: Maybe<GqlClientAccountDataLibraryVersion>;
    result: GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingResult;
};

export type GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingOutputSuccess = GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingOutput & {
    __typename?: "UpdateAccountDataLibraryConnectorTypeAndMappingOutputSuccess";
    accountDataLibraryVersion?: Maybe<GqlClientAccountDataLibraryVersion>;
    result: GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingResult;
};

export enum GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateAccountDataLibraryMetadataInput = {
    accountDataLibraryVersionId: Scalars["ID"]["input"];
    accountLibraryDescription?: InputMaybe<Scalars["String"]["input"]>;
    accountLibraryName?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdateAccountDataLibraryMetadataOutput = {
    result: GqlClientUpdateAccountDataLibraryMetadataResult;
};

export type GqlClientUpdateAccountDataLibraryMetadataOutputConflict = GqlClientUpdateAccountDataLibraryMetadataOutput & {
    __typename?: "UpdateAccountDataLibraryMetadataOutputConflict";
    accountDataLibraryVersion?: Maybe<GqlClientAccountDataLibraryVersion>;
    result: GqlClientUpdateAccountDataLibraryMetadataResult;
};

export type GqlClientUpdateAccountDataLibraryMetadataOutputSuccess = GqlClientUpdateAccountDataLibraryMetadataOutput & {
    __typename?: "UpdateAccountDataLibraryMetadataOutputSuccess";
    accountDataLibraryVersion?: Maybe<GqlClientAccountDataLibraryVersion>;
    result: GqlClientUpdateAccountDataLibraryMetadataResult;
};

export enum GqlClientUpdateAccountDataLibraryMetadataResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateAccountFeatureDataInput = {
    accountId: Scalars["String"]["input"];
    features: Array<GqlClientAccountFeatureDataInput>;
};

export type GqlClientUpdateAccountFeatureDataOutput = {
    __typename?: "UpdateAccountFeatureDataOutput";
    features: Array<GqlClientAccountFeatureData>;
    result: GqlClientUpdateAccountFeatureDataResult;
};

export enum GqlClientUpdateAccountFeatureDataResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateAccountReportInput = {
    customCampaignIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    customProgramIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    filters?: InputMaybe<Array<GqlClientAccountReportFilterInput>>;
    id: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    newSchedules?: InputMaybe<Array<GqlClientCreateAccountReportScheduleInput>>;
    relevantPrograms: GqlClientRelevantPrograms;
    reportElements?: InputMaybe<Array<GqlClientAccountReportElementInput>>;
    schedules?: InputMaybe<Array<GqlClientUpdateAccountReportScheduleInput>>;
    timezone: Scalars["String"]["input"];
    type: GqlClientAccountReportType;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateAccountReportOutput = {
    result: GqlClientUpdateAccountReportResult;
};

export type GqlClientUpdateAccountReportOutputConflict = GqlClientUpdateAccountReportOutput & {
    __typename?: "UpdateAccountReportOutputConflict";
    accountReport?: Maybe<GqlClientAccountReport>;
    result: GqlClientUpdateAccountReportResult;
};

export type GqlClientUpdateAccountReportOutputSuccess = GqlClientUpdateAccountReportOutput & {
    __typename?: "UpdateAccountReportOutputSuccess";
    accountReport?: Maybe<GqlClientAccountReport>;
    result: GqlClientUpdateAccountReportResult;
};

export enum GqlClientUpdateAccountReportResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateAccountReportScheduleInput = {
    active?: InputMaybe<Scalars["Boolean"]["input"]>;
    deliveryChannel?: InputMaybe<Scalars["JSON"]["input"]>;
    endPeriod?: InputMaybe<Scalars["String"]["input"]>;
    filenameTemplate?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    occurrence?: InputMaybe<GqlClientReportScheduleOccurrence>;
    period?: InputMaybe<GqlClientReportSchedulePeriod>;
    periodType?: InputMaybe<GqlClientReportSchedulePeriodType>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    sourceEnvironments?: InputMaybe<Array<Scalars["String"]["input"]>>;
    startPeriod?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateAccountUserControlledFeatureDataInput = {
    features: Array<GqlClientAccountFeatureDataInput>;
};

export type GqlClientUpdateAccountUserControlledFeatureDataOutput = {
    __typename?: "UpdateAccountUserControlledFeatureDataOutput";
    features: Array<GqlClientAccountFeatureData>;
    result: GqlClientUpdateAccountUserControlledFeatureDataResult;
};

export enum GqlClientUpdateAccountUserControlledFeatureDataResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateAdsAudienceInput = {
    audienceName: Scalars["String"]["input"];
    comment?: InputMaybe<Scalars["String"]["input"]>;
    enabled: Scalars["Boolean"]["input"];
    id: Scalars["ID"]["input"];
    newSuperGroups?: InputMaybe<Array<GqlClientCreateAudienceRuleSuperGroupInput>>;
    priorityIndex: Scalars["Int"]["input"];
    updatedSuperGroups?: InputMaybe<Array<GqlClientUpdateAudienceRuleSuperGroupInput>>;
};

export type GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededOutput = {
    __typename?: "UpdateAllAccountEditorProgramVersionThumbnailIfNeededOutput";
    result: GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededResult;
};

export enum GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateAllDecisionPoint = {
    localId: Scalars["String"]["input"];
    logic: Scalars["JSON"]["input"];
};

export type GqlClientUpdateAnimationInput = {
    activeAnimationVersionId?: InputMaybe<Scalars["ID"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateAnimationOutput = {
    result: GqlClientUpdateAnimationResult;
};

export type GqlClientUpdateAnimationOutputConflict = GqlClientUpdateAnimationOutput & {
    __typename?: "UpdateAnimationOutputConflict";
    animation?: Maybe<GqlClientAnimation>;
    result: GqlClientUpdateAnimationResult;
};

export type GqlClientUpdateAnimationOutputSuccess = GqlClientUpdateAnimationOutput & {
    __typename?: "UpdateAnimationOutputSuccess";
    animation?: Maybe<GqlClientAnimation>;
    result: GqlClientUpdateAnimationResult;
};

export enum GqlClientUpdateAnimationResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateAssetInput = {
    activeAssetVersionId?: InputMaybe<Scalars["ID"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    legacyData?: InputMaybe<Scalars["JSON"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateAssetOutput = {
    result: GqlClientUpdateAssetResult;
};

export type GqlClientUpdateAssetOutputConflict = GqlClientUpdateAssetOutput & {
    __typename?: "UpdateAssetOutputConflict";
    asset?: Maybe<GqlClientAsset>;
    result: GqlClientUpdateAssetResult;
};

export type GqlClientUpdateAssetOutputSuccess = GqlClientUpdateAssetOutput & {
    __typename?: "UpdateAssetOutputSuccess";
    asset?: Maybe<GqlClientAsset>;
    result: GqlClientUpdateAssetResult;
};

export enum GqlClientUpdateAssetResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateAudienceAudienceValueInput = {
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    newSubAudiences?: InputMaybe<Array<InputMaybe<GqlClientUpdateAudienceNewAudienceInput>>>;
    subAudiences?: InputMaybe<Array<InputMaybe<GqlClientUpdateAudienceInput>>>;
    updated: Scalars["String"]["input"];
    volume?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GqlClientUpdateAudienceInput = {
    audienceValues?: InputMaybe<Array<GqlClientUpdateAudienceAudienceValueInput>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    impactsVolume?: InputMaybe<Scalars["Boolean"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    newAudienceValues?: InputMaybe<Array<GqlClientUpdateAudienceNewAudienceValueInput>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateAudienceNewAudienceInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    impactsVolume?: InputMaybe<Scalars["Boolean"]["input"]>;
    name: Scalars["String"]["input"];
    newAudienceValues?: InputMaybe<Array<GqlClientUpdateAudienceNewAudienceValueInput>>;
};

export type GqlClientUpdateAudienceNewAudienceValueInput = {
    name: Scalars["String"]["input"];
    newSubAudiences?: InputMaybe<Array<InputMaybe<GqlClientUpdateAudienceNewAudienceInput>>>;
    volume?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GqlClientUpdateAudienceOutput = {
    result: GqlClientUpdateAudienceResult;
};

export type GqlClientUpdateAudienceOutputConflict = GqlClientUpdateAudienceOutput & {
    __typename?: "UpdateAudienceOutputConflict";
    audience?: Maybe<GqlClientAudience>;
    result: GqlClientUpdateAudienceResult;
};

export type GqlClientUpdateAudienceOutputSuccess = GqlClientUpdateAudienceOutput & {
    __typename?: "UpdateAudienceOutputSuccess";
    audience?: Maybe<GqlClientAudience>;
    result: GqlClientUpdateAudienceResult;
};

export enum GqlClientUpdateAudienceResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateAudienceRuleGroupInput = {
    id: Scalars["ID"]["input"];
    matchType?: InputMaybe<GqlClientRuleMatchType>;
    maxAgeDays?: InputMaybe<Scalars["Int"]["input"]>;
    minAgeDays?: InputMaybe<Scalars["Int"]["input"]>;
    newAudienceRules?: InputMaybe<Array<GqlClientCreateAudienceRuleInput>>;
    updatedAudienceRules?: InputMaybe<Array<GqlClientUpdateAudienceRuleInput>>;
};

export type GqlClientUpdateAudienceRuleInput = {
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    operator?: InputMaybe<GqlClientAudienceRuleOperator>;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdateAudienceRuleSuperGroupInput = {
    id: Scalars["ID"]["input"];
    matchType?: InputMaybe<GqlClientRuleMatchType>;
    newRuleGroups?: InputMaybe<Array<GqlClientCreateAudienceRuleGroupInput>>;
    ruleType?: InputMaybe<GqlClientRuleType>;
    updatedRuleGroups?: InputMaybe<Array<GqlClientUpdateAudienceRuleGroupInput>>;
};

export type GqlClientUpdateAudienceStateInput = {
    enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
    id: Scalars["ID"]["input"];
    priorityIndex?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GqlClientUpdateAudienceWithDataElementsAudienceValueInput = {
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    newSubAudiences?: InputMaybe<Array<InputMaybe<GqlClientUpdateAudienceWithDataElementsNewAudienceInput>>>;
    subAudiences?: InputMaybe<Array<InputMaybe<GqlClientUpdateAudienceWithDataElementsInput>>>;
    updated: Scalars["String"]["input"];
    volume?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GqlClientUpdateAudienceWithDataElementsInput = {
    audienceValues?: InputMaybe<Array<GqlClientUpdateAudienceAudienceValueInput>>;
    dataElementIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    impactsVolume?: InputMaybe<Scalars["Boolean"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    newAudienceValues?: InputMaybe<Array<GqlClientUpdateAudienceNewAudienceValueInput>>;
    newFeedDataElements?: InputMaybe<Array<GqlClientUpdateAudienceWithDataElementsNewFeedDataElementInput>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateAudienceWithDataElementsNewAudienceInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    impactsVolume?: InputMaybe<Scalars["Boolean"]["input"]>;
    name: Scalars["String"]["input"];
    newAudienceValues?: InputMaybe<Array<GqlClientUpdateAudienceWithDataElementsNewAudienceValueInput>>;
};

export type GqlClientUpdateAudienceWithDataElementsNewAudienceValueInput = {
    name: Scalars["String"]["input"];
    newSubAudiences?: InputMaybe<Array<InputMaybe<GqlClientUpdateAudienceWithDataElementsNewAudienceInput>>>;
    volume?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GqlClientUpdateAudienceWithDataElementsNewFeedDataElementInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    source?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<GqlClientFeedDataElementStatus>;
};

export type GqlClientUpdateAudienceWithDataElementsOutput = {
    result: GqlClientUpdateAudienceWithDataElementsResult;
};

export type GqlClientUpdateAudienceWithDataElementsOutputConflict = GqlClientUpdateAudienceWithDataElementsOutput & {
    __typename?: "UpdateAudienceWithDataElementsOutputConflict";
    audience?: Maybe<GqlClientAudience>;
    result: GqlClientUpdateAudienceWithDataElementsResult;
};

export type GqlClientUpdateAudienceWithDataElementsOutputSuccess = GqlClientUpdateAudienceWithDataElementsOutput & {
    __typename?: "UpdateAudienceWithDataElementsOutputSuccess";
    audience?: Maybe<GqlClientAudience>;
    result: GqlClientUpdateAudienceWithDataElementsResult;
};

export enum GqlClientUpdateAudienceWithDataElementsResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateBasicFeedDataElementInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    source?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<GqlClientFeedDataElementStatus>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateBasicFeedDataElementOutput = {
    result: GqlClientUpdateBasicFeedDataElementResult;
};

export type GqlClientUpdateBasicFeedDataElementOutputConflict = GqlClientUpdateBasicFeedDataElementOutput & {
    __typename?: "UpdateBasicFeedDataElementOutputConflict";
    feedDataElement?: Maybe<GqlClientFeedDataElement>;
    result: GqlClientUpdateBasicFeedDataElementResult;
};

export type GqlClientUpdateBasicFeedDataElementOutputSuccess = GqlClientUpdateBasicFeedDataElementOutput & {
    __typename?: "UpdateBasicFeedDataElementOutputSuccess";
    feedDataElement?: Maybe<GqlClientFeedDataElement>;
    result: GqlClientUpdateBasicFeedDataElementResult;
};

export enum GqlClientUpdateBasicFeedDataElementResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateBcCollectionWithColorsAndTextStylesInput = {
    buttonShape?: InputMaybe<GqlClientButtonShapeValues>;
    colors?: InputMaybe<Array<GqlClientUpdateColor>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    logoLocation?: InputMaybe<Scalars["String"]["input"]>;
    logoMediaId?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    textStyles?: InputMaybe<Array<GqlClientUpdateTextStyle>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateBcCollectionWithColorsAndTextStylesOutput = {
    result: GqlClientUpdateBcCollectionWithColorsAndTextStylesResult;
};

export type GqlClientUpdateBcCollectionWithColorsAndTextStylesOutputConflict = GqlClientUpdateBcCollectionWithColorsAndTextStylesOutput & {
    __typename?: "UpdateBcCollectionWithColorsAndTextStylesOutputConflict";
    brandConfigurationCollection?: Maybe<GqlClientBrandConfigurationCollection>;
    result: GqlClientUpdateBcCollectionWithColorsAndTextStylesResult;
};

export type GqlClientUpdateBcCollectionWithColorsAndTextStylesOutputSuccess = GqlClientUpdateBcCollectionWithColorsAndTextStylesOutput & {
    __typename?: "UpdateBcCollectionWithColorsAndTextStylesOutputSuccess";
    brandConfigurationCollection?: Maybe<GqlClientBrandConfigurationCollection>;
    result: GqlClientUpdateBcCollectionWithColorsAndTextStylesResult;
};

export enum GqlClientUpdateBcCollectionWithColorsAndTextStylesResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCampaignSettingsInput = {
    accountId?: InputMaybe<Scalars["Int"]["input"]>;
    advertiserCreativeId?: InputMaybe<Scalars["String"]["input"]>;
    advertiserWebsite?: InputMaybe<Scalars["String"]["input"]>;
    allowDesktop?: InputMaybe<Scalars["Boolean"]["input"]>;
    allowMobileApp?: InputMaybe<Scalars["Boolean"]["input"]>;
    allowMobileWeb?: InputMaybe<Scalars["Boolean"]["input"]>;
    allowPurchased?: InputMaybe<Scalars["Boolean"]["input"]>;
    allowThirdPartySegmentsInOptimization?: InputMaybe<Scalars["Boolean"]["input"]>;
    appsFilter?: InputMaybe<Scalars["String"]["input"]>;
    audiences?: InputMaybe<Array<GqlClientUpdateAudienceStateInput>>;
    brandSafetyCustomSegmentsLocation?: InputMaybe<Scalars["String"]["input"]>;
    brandSafetyMode?: InputMaybe<GqlClientBrandSafetyMode>;
    comment?: InputMaybe<Scalars["String"]["input"]>;
    controlGroupMode?: InputMaybe<GqlClientAdsTestType>;
    controlGroupPercent?: InputMaybe<Scalars["Float"]["input"]>;
    dailyFrequencyCap?: InputMaybe<Scalars["Int"]["input"]>;
    desktopMinBudget?: InputMaybe<Scalars["Float"]["input"]>;
    desktopPlayerSizes?: InputMaybe<Array<GqlClientPlayerSize>>;
    deviceMode?: InputMaybe<GqlClientDeviceMode>;
    enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
    exchangeBlacklist?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    fraudMode?: InputMaybe<GqlClientFraudMode>;
    hourlyFrequencyCap?: InputMaybe<Scalars["Int"]["input"]>;
    iabCategoriesFilter?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    isCPCV?: InputMaybe<Scalars["Boolean"]["input"]>;
    keywordsFilter?: InputMaybe<Scalars["String"]["input"]>;
    locationFile?: InputMaybe<Scalars["String"]["input"]>;
    locationFilters?: InputMaybe<Array<Scalars["String"]["input"]>>;
    minimumViewability?: InputMaybe<Scalars["Float"]["input"]>;
    mobileMinBudget?: InputMaybe<Scalars["Float"]["input"]>;
    mobilePlayerSizes?: InputMaybe<Array<GqlClientPlayerSize>>;
    monthlyFrequencyCap?: InputMaybe<Scalars["Int"]["input"]>;
    newCreativeDefinitions?: InputMaybe<Array<GqlClientCreateCreativeDefinitionsInput>>;
    newProductFilters?: InputMaybe<Array<GqlClientCreateProductFilterInput>>;
    newProductSelectors?: InputMaybe<Array<GqlClientCreateProductSelectorInput>>;
    newReportingDefinitions?: InputMaybe<Array<GqlClientCreateReportingDefinitionsInput>>;
    newSchedulesAndTargets?: InputMaybe<Array<GqlClientCreateFlightInput>>;
    pixelRulesLocation?: InputMaybe<Scalars["String"]["input"]>;
    productCatalogIdTemplate?: InputMaybe<Scalars["String"]["input"]>;
    productCatalogName?: InputMaybe<Scalars["String"]["input"]>;
    restrictedContentAlcohol?: InputMaybe<Scalars["Boolean"]["input"]>;
    updatedCreativeDefinitions?: InputMaybe<Array<GqlClientUpdateCreativeDefinitionsInput>>;
    updatedProductFilters?: InputMaybe<Array<GqlClientUpdateProductFilterInput>>;
    updatedProductSelectors?: InputMaybe<Array<GqlClientUpdateProductSelectorInput>>;
    updatedReportingDefinitions?: InputMaybe<Array<GqlClientUpdateReportingDefinitionsInput>>;
    updatedSchedulesAndTargets?: InputMaybe<Array<GqlClientUpdateFlightInput>>;
    urlFilter?: InputMaybe<Scalars["String"]["input"]>;
    useCrossDevice?: InputMaybe<Scalars["Boolean"]["input"]>;
    verificationAuthority?: InputMaybe<GqlClientVerificationAuthority>;
    viewabilityGoal?: InputMaybe<Scalars["Float"]["input"]>;
    viewabilityMode?: InputMaybe<GqlClientViewabilityMode>;
    weeklyFrequencyCap?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GqlClientUpdateCampaignStatusInput = {
    enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
    id: Scalars["ID"]["input"];
};

export type GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingInput = {
    sourceAnimatedWireframeId: Scalars["ID"]["input"];
    targetAnimatedWireframeId: Scalars["ID"]["input"];
};

export type GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingOutput = {
    __typename?: "UpdateCcAnimatedWireframeCrossAspectRatioMappingOutput";
    animatedWireframe?: Maybe<GqlClientCcAnimatedWireframe>;
    result: GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingResult;
};

export enum GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCcAnimatedWireframeInput = {
    id: Scalars["ID"]["input"];
    lifecycleStage: GqlClientAssetLifecycleStage;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateCcAnimatedWireframeOutput = {
    result: GqlClientUpdateCcAnimatedWireframeResult;
};

export type GqlClientUpdateCcAnimatedWireframeOutputConflict = GqlClientUpdateCcAnimatedWireframeOutput & {
    __typename?: "UpdateCcAnimatedWireframeOutputConflict";
    animatedWireframe?: Maybe<GqlClientCcAnimatedWireframe>;
    result: GqlClientUpdateCcAnimatedWireframeResult;
};

export type GqlClientUpdateCcAnimatedWireframeOutputSuccess = GqlClientUpdateCcAnimatedWireframeOutput & {
    __typename?: "UpdateCcAnimatedWireframeOutputSuccess";
    animatedWireframe?: Maybe<GqlClientCcAnimatedWireframe>;
    result: GqlClientUpdateCcAnimatedWireframeResult;
};

export enum GqlClientUpdateCcAnimatedWireframeResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftInput = {
    animatedWireframeIds: Array<Scalars["ID"]["input"]>;
};

export type GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftOutput = {
    __typename?: "UpdateCcAnimatedWireframeSetApprovedVersionFromDraftOutput";
    animatedWireframes: Array<GqlClientCcAnimatedWireframe>;
    result: GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftResult;
};

export enum GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedInput = {
    animatedWireframeIds: Array<Scalars["ID"]["input"]>;
};

export type GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedOutput = {
    __typename?: "UpdateCcAnimatedWireframeSetDraftVersionFromApprovedOutput";
    animatedWireframes: Array<GqlClientCcAnimatedWireframe>;
    result: GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedResult;
};

export enum GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCcThemeInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    isComingSoon?: InputMaybe<Scalars["Boolean"]["input"]>;
    lifecycleStage?: InputMaybe<GqlClientAssetLifecycleStage>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    posterLocation?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateCcThemeOutput = {
    result: GqlClientUpdateCcThemeResult;
};

export type GqlClientUpdateCcThemeOutputConflict = GqlClientUpdateCcThemeOutput & {
    __typename?: "UpdateCcThemeOutputConflict";
    result: GqlClientUpdateCcThemeResult;
    theme?: Maybe<GqlClientCcTheme>;
};

export type GqlClientUpdateCcThemeOutputSuccess = GqlClientUpdateCcThemeOutput & {
    __typename?: "UpdateCcThemeOutputSuccess";
    result: GqlClientUpdateCcThemeResult;
    theme?: Maybe<GqlClientCcTheme>;
};

export enum GqlClientUpdateCcThemeResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCcWireframeFrameInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    frameIndex: Scalars["Int"]["input"];
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    newPlaceholders?: InputMaybe<Array<GqlClientUpdateCcWireframeNewPlaceholderInput>>;
    placeholders?: InputMaybe<Array<GqlClientUpdateCcWireframePlaceholderInput>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateCcWireframeInput = {
    category?: InputMaybe<GqlClientCcWireframeCategory>;
    comment?: InputMaybe<Scalars["String"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    frames?: InputMaybe<Array<GqlClientUpdateCcWireframeFrameInput>>;
    id: Scalars["ID"]["input"];
    lifecycleStage?: InputMaybe<GqlClientAssetLifecycleStage>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    newFrames?: InputMaybe<Array<GqlClientUpdateCcWireframeNewFrameInput>>;
    updated: Scalars["String"]["input"];
    useToCreateDefaultScene?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GqlClientUpdateCcWireframeNewFrameInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    frameIndex: Scalars["Int"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    placeholders: Array<GqlClientUpdateCcWireframeNewPlaceholderInput>;
};

export type GqlClientUpdateCcWireframeNewPlaceholderInput = {
    category: GqlClientCcPlaceholderCategory;
    content?: InputMaybe<Scalars["JSON"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    descriptionOrder?: InputMaybe<Scalars["Int"]["input"]>;
    intentId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
};

export type GqlClientUpdateCcWireframeOutput = {
    result: GqlClientUpdateCcWireframeResult;
};

export type GqlClientUpdateCcWireframeOutputConflict = GqlClientUpdateCcWireframeOutput & {
    __typename?: "UpdateCcWireframeOutputConflict";
    result: GqlClientUpdateCcWireframeResult;
    wireframe?: Maybe<GqlClientCcWireframe>;
};

export type GqlClientUpdateCcWireframeOutputSuccess = GqlClientUpdateCcWireframeOutput & {
    __typename?: "UpdateCcWireframeOutputSuccess";
    result: GqlClientUpdateCcWireframeResult;
    wireframe?: Maybe<GqlClientCcWireframe>;
};

export type GqlClientUpdateCcWireframePlaceholderInput = {
    category?: InputMaybe<GqlClientCcPlaceholderCategory>;
    content?: InputMaybe<Scalars["JSON"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    descriptionOrder?: InputMaybe<Scalars["Int"]["input"]>;
    id: Scalars["ID"]["input"];
    intentId?: InputMaybe<Scalars["ID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export enum GqlClientUpdateCcWireframeResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateChannelInput = {
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    platforms?: InputMaybe<Array<Scalars["String"]["input"]>>;
    status?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateChannelOutput = {
    result: GqlClientUpdateChannelResult;
};

export type GqlClientUpdateChannelOutputConflict = GqlClientUpdateChannelOutput & {
    __typename?: "UpdateChannelOutputConflict";
    channel?: Maybe<GqlClientChannel>;
    result: GqlClientUpdateChannelResult;
};

export type GqlClientUpdateChannelOutputSuccess = GqlClientUpdateChannelOutput & {
    __typename?: "UpdateChannelOutputSuccess";
    channel?: Maybe<GqlClientChannel>;
    result: GqlClientUpdateChannelResult;
};

export enum GqlClientUpdateChannelResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateColor = {
    color: Scalars["String"]["input"];
    id: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateCreateAndDeleteDecisionPointToUpdate = {
    id: Scalars["ID"]["input"];
    logic: Scalars["JSON"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateCreateAndDeleteNewDecisionPoint = {
    localId: Scalars["String"]["input"];
    logic: Scalars["JSON"]["input"];
};

export type GqlClientUpdateCreativeAssetInput = {
    activeCreativeAssetVersionId?: InputMaybe<Scalars["ID"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<GqlClientAssetStatus>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateCreativeAssetOutput = {
    result: GqlClientUpdateCreativeAssetResult;
};

export type GqlClientUpdateCreativeAssetOutputConflict = GqlClientUpdateCreativeAssetOutput & {
    __typename?: "UpdateCreativeAssetOutputConflict";
    creativeAsset?: Maybe<GqlClientCreativeAsset>;
    result: GqlClientUpdateCreativeAssetResult;
};

export type GqlClientUpdateCreativeAssetOutputSuccess = GqlClientUpdateCreativeAssetOutput & {
    __typename?: "UpdateCreativeAssetOutputSuccess";
    creativeAsset?: Maybe<GqlClientCreativeAsset>;
    result: GqlClientUpdateCreativeAssetResult;
};

export enum GqlClientUpdateCreativeAssetResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCreativeDataElementInput = {
    assignedToAllStories?: InputMaybe<Scalars["Boolean"]["input"]>;
    dataType?: InputMaybe<GqlClientDataElementType>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    pii?: InputMaybe<Scalars["Boolean"]["input"]>;
    storyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    updated: Scalars["String"]["input"];
    useValueSet?: InputMaybe<Scalars["Boolean"]["input"]>;
    valueSet?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
};

export type GqlClientUpdateCreativeDataElementOutput = {
    result: GqlClientUpdateCreativeDataElementResult;
};

export type GqlClientUpdateCreativeDataElementOutputConflict = GqlClientUpdateCreativeDataElementOutput & {
    __typename?: "UpdateCreativeDataElementOutputConflict";
    creativeDataElement?: Maybe<GqlClientCreativeDataElement>;
    result: GqlClientUpdateCreativeDataElementResult;
};

export type GqlClientUpdateCreativeDataElementOutputSuccess = GqlClientUpdateCreativeDataElementOutput & {
    __typename?: "UpdateCreativeDataElementOutputSuccess";
    creativeDataElement?: Maybe<GqlClientCreativeDataElement>;
    result: GqlClientUpdateCreativeDataElementResult;
};

export enum GqlClientUpdateCreativeDataElementResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCreativeDefinitionsInput = {
    allowedDurations: Array<GqlClientVideoDuration>;
    id: Scalars["ID"]["input"];
    numSKU: Scalars["Int"]["input"];
    storyName: Scalars["String"]["input"];
    supportedRatios: Array<GqlClientRatio>;
    videoParams?: InputMaybe<Scalars["String"]["input"]>;
    videoQualityProfile: GqlClientVideoQuality;
};

export type GqlClientUpdateCreativeDraftDefinitionsInput = {
    creativeDDEValue?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    storyId?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateCreativeDraftDefinitionsOutput = {
    result: GqlClientUpdateCreativeDraftDefinitionsResult;
};

export type GqlClientUpdateCreativeDraftDefinitionsOutputConflict = GqlClientUpdateCreativeDraftDefinitionsOutput & {
    __typename?: "UpdateCreativeDraftDefinitionsOutputConflict";
    creativeDraft?: Maybe<GqlClientCreativeVersion>;
    result: GqlClientUpdateCreativeDraftDefinitionsResult;
};

export type GqlClientUpdateCreativeDraftDefinitionsOutputSuccess = GqlClientUpdateCreativeDraftDefinitionsOutput & {
    __typename?: "UpdateCreativeDraftDefinitionsOutputSuccess";
    creativeDraft?: Maybe<GqlClientCreativeVersion>;
    result: GqlClientUpdateCreativeDraftDefinitionsResult;
};

export enum GqlClientUpdateCreativeDraftDefinitionsResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCreativeDraftInput = {
    creativeDDEValue?: InputMaybe<Scalars["String"]["input"]>;
    creativeData?: InputMaybe<Scalars["JSON"]["input"]>;
    id: Scalars["ID"]["input"];
    programSnapshotNumber?: InputMaybe<Scalars["Int"]["input"]>;
    storyId?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateCreativeDraftOutput = {
    result: GqlClientUpdateCreativeDraftResult;
};

export type GqlClientUpdateCreativeDraftOutputConflict = GqlClientUpdateCreativeDraftOutput & {
    __typename?: "UpdateCreativeDraftOutputConflict";
    creativeDraft?: Maybe<GqlClientCreativeVersion>;
    result: GqlClientUpdateCreativeDraftResult;
};

export type GqlClientUpdateCreativeDraftOutputSuccess = GqlClientUpdateCreativeDraftOutput & {
    __typename?: "UpdateCreativeDraftOutputSuccess";
    creativeDraft?: Maybe<GqlClientCreativeVersion>;
    result: GqlClientUpdateCreativeDraftResult;
};

export enum GqlClientUpdateCreativeDraftResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCreativeInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateCreativeNarrationRecordingInput = {
    activeCreativeNarrationRecordingVersionId: Scalars["ID"]["input"];
    id: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateCreativeNarrationRecordingOutput = {
    result: GqlClientUpdateCreativeNarrationRecordingResult;
};

export type GqlClientUpdateCreativeNarrationRecordingOutputConflict = GqlClientUpdateCreativeNarrationRecordingOutput & {
    __typename?: "UpdateCreativeNarrationRecordingOutputConflict";
    creativeNarrationRecording?: Maybe<GqlClientCreativeNarrationRecording>;
    result: GqlClientUpdateCreativeNarrationRecordingResult;
};

export type GqlClientUpdateCreativeNarrationRecordingOutputSuccess = GqlClientUpdateCreativeNarrationRecordingOutput & {
    __typename?: "UpdateCreativeNarrationRecordingOutputSuccess";
    creativeNarrationRecording?: Maybe<GqlClientCreativeNarrationRecording>;
    result: GqlClientUpdateCreativeNarrationRecordingResult;
};

export enum GqlClientUpdateCreativeNarrationRecordingResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCreativeNarrationRecordingVersionInput = {
    errorReason?: InputMaybe<GqlClientNarrationRecordingErrorReason>;
    id: Scalars["ID"]["input"];
    status?: InputMaybe<GqlClientNarrationRecordingStatus>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateCreativeNarrationRecordingVersionOutput = {
    result: GqlClientUpdateCreativeNarrationRecordingVersionResult;
};

export type GqlClientUpdateCreativeNarrationRecordingVersionOutputConflict = GqlClientUpdateCreativeNarrationRecordingVersionOutput & {
    __typename?: "UpdateCreativeNarrationRecordingVersionOutputConflict";
    creativeNarrationRecordingVersion?: Maybe<GqlClientCreativeNarrationRecordingVersion>;
    result: GqlClientUpdateCreativeNarrationRecordingVersionResult;
};

export type GqlClientUpdateCreativeNarrationRecordingVersionOutputSuccess = GqlClientUpdateCreativeNarrationRecordingVersionOutput & {
    __typename?: "UpdateCreativeNarrationRecordingVersionOutputSuccess";
    creativeNarrationRecordingVersion?: Maybe<GqlClientCreativeNarrationRecordingVersion>;
    result: GqlClientUpdateCreativeNarrationRecordingVersionResult;
};

export enum GqlClientUpdateCreativeNarrationRecordingVersionResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCreativeOutput = {
    result: GqlClientUpdateCreativeResult;
};

export type GqlClientUpdateCreativeOutputConflict = GqlClientUpdateCreativeOutput & {
    __typename?: "UpdateCreativeOutputConflict";
    creative?: Maybe<GqlClientCreative>;
    result: GqlClientUpdateCreativeResult;
};

export type GqlClientUpdateCreativeOutputSuccess = GqlClientUpdateCreativeOutput & {
    __typename?: "UpdateCreativeOutputSuccess";
    creative?: Maybe<GqlClientCreative>;
    result: GqlClientUpdateCreativeResult;
};

export enum GqlClientUpdateCreativeResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateCustomAnalyticFieldInput = {
    id: Scalars["ID"]["input"];
    logic?: InputMaybe<Scalars["JSON"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateCustomAnalyticFieldOutput = {
    result: GqlClientUpdateCustomAnalyticFieldResult;
};

export type GqlClientUpdateCustomAnalyticFieldOutputConflict = GqlClientUpdateCustomAnalyticFieldOutput & {
    __typename?: "UpdateCustomAnalyticFieldOutputConflict";
    customAnalyticField?: Maybe<GqlClientCustomAnalyticField>;
    result: GqlClientUpdateCustomAnalyticFieldResult;
};

export type GqlClientUpdateCustomAnalyticFieldOutputSuccess = GqlClientUpdateCustomAnalyticFieldOutput & {
    __typename?: "UpdateCustomAnalyticFieldOutputSuccess";
    customAnalyticField?: Maybe<GqlClientCustomAnalyticField>;
    result: GqlClientUpdateCustomAnalyticFieldResult;
};

export enum GqlClientUpdateCustomAnalyticFieldResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateDataTableInput = {
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateDataTableOutput = {
    result: GqlClientUpdateDataTableResult;
};

export type GqlClientUpdateDataTableOutputConflict = GqlClientUpdateDataTableOutput & {
    __typename?: "UpdateDataTableOutputConflict";
    dataTable?: Maybe<GqlClientDataTable>;
    result: GqlClientUpdateDataTableResult;
};

export type GqlClientUpdateDataTableOutputSuccess = GqlClientUpdateDataTableOutput & {
    __typename?: "UpdateDataTableOutputSuccess";
    dataTable?: Maybe<GqlClientDataTable>;
    result: GqlClientUpdateDataTableResult;
};

export enum GqlClientUpdateDataTableResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateDerivedDataElementInput = {
    dataType?: InputMaybe<GqlClientDataElementType>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    logic?: InputMaybe<Scalars["JSON"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    pii?: InputMaybe<Scalars["Boolean"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateDerivedDataElementOutput = {
    result: GqlClientUpdateDerivedDataElementResult;
};

export type GqlClientUpdateDerivedDataElementOutputConflict = GqlClientUpdateDerivedDataElementOutput & {
    __typename?: "UpdateDerivedDataElementOutputConflict";
    derivedDataElement?: Maybe<GqlClientDerivedDataElement>;
    result: GqlClientUpdateDerivedDataElementResult;
};

export type GqlClientUpdateDerivedDataElementOutputSuccess = GqlClientUpdateDerivedDataElementOutput & {
    __typename?: "UpdateDerivedDataElementOutputSuccess";
    derivedDataElement?: Maybe<GqlClientDerivedDataElement>;
    result: GqlClientUpdateDerivedDataElementResult;
};

export enum GqlClientUpdateDerivedDataElementResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorAccountUserInput = {
    accountRoles: Array<GqlClientUserManagementRole>;
    email: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorAccountUserOutput = {
    result: GqlClientUpdateEditorAccountUserResult;
};

export type GqlClientUpdateEditorAccountUserOutputRefusal = GqlClientUpdateEditorAccountUserOutput & {
    __typename?: "UpdateEditorAccountUserOutputRefusal";
    result: GqlClientUpdateEditorAccountUserResult;
};

export type GqlClientUpdateEditorAccountUserOutputSuccess = GqlClientUpdateEditorAccountUserOutput & {
    __typename?: "UpdateEditorAccountUserOutputSuccess";
    accountUser: GqlClientAccountUser;
    result: GqlClientUpdateEditorAccountUserResult;
};

export enum GqlClientUpdateEditorAccountUserResult {
    INVALID_ROLE_REFUSAL = "INVALID_ROLE_REFUSAL",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorAssetMediaInput = {
    assetId: Scalars["String"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdateEditorAssetMediaOutput = {
    __typename?: "UpdateEditorAssetMediaOutput";
    asset?: Maybe<GqlClientEditorAssetMedia>;
    result: GqlClientUpdateEditorAssetMediaResult;
};

export enum GqlClientUpdateEditorAssetMediaResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorAvatarPlaceholderInput = {
    id: Scalars["ID"]["input"];
    placeholderContent?: InputMaybe<Scalars["JSON"]["input"]>;
    placeholderSettings?: InputMaybe<Scalars["JSON"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorAvatarPlaceholderOutput = {
    __typename?: "UpdateEditorAvatarPlaceholderOutput";
    editorPlaceholders: Array<GqlClientEditorPlaceholder>;
    result: GqlClientUpdateEditorAvatarPlaceholderResult;
};

export enum GqlClientUpdateEditorAvatarPlaceholderResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorChapteringInput = {
    chapteringEnabled: Scalars["Boolean"]["input"];
    editorProgramVersionId: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorChapteringOutput = {
    __typename?: "UpdateEditorChapteringOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorChapteringResult;
};

export enum GqlClientUpdateEditorChapteringResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorIsArchivedInput = {
    id: Scalars["ID"]["input"];
    isArchive: Scalars["Boolean"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorIsArchivedOutput = {
    result: GqlClientUpdateEditorIsArchivedResult;
};

export type GqlClientUpdateEditorIsArchivedOutputConflict = GqlClientUpdateEditorIsArchivedOutput & {
    __typename?: "UpdateEditorIsArchivedOutputConflict";
    editorProgram?: Maybe<GqlClientEditorProgram>;
    result: GqlClientUpdateEditorIsArchivedResult;
};

export type GqlClientUpdateEditorIsArchivedOutputSuccess = GqlClientUpdateEditorIsArchivedOutput & {
    __typename?: "UpdateEditorIsArchivedOutputSuccess";
    editorProgram?: Maybe<GqlClientEditorProgram>;
    result: GqlClientUpdateEditorIsArchivedResult;
};

export enum GqlClientUpdateEditorIsArchivedResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorIsLiveInput = {
    programId: Scalars["ID"]["input"];
};

export type GqlClientUpdateEditorIsLiveOutput = {
    __typename?: "UpdateEditorIsLiveOutput";
    editorProgram?: Maybe<GqlClientEditorProgram>;
    result: GqlClientUpdateEditorIsLiveResult;
};

export enum GqlClientUpdateEditorIsLiveResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorLineupInput = {
    editorProgramVersionId: Scalars["ID"]["input"];
    sceneLineup: Array<Scalars["String"]["input"]>;
};

export type GqlClientUpdateEditorLineupOutput = {
    __typename?: "UpdateEditorLineupOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorLineupResult;
};

export enum GqlClientUpdateEditorLineupResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorNarrationLibraryInput = {
    editorProgramVersionId: Scalars["ID"]["input"];
};

export type GqlClientUpdateEditorNarrationLibraryOutput = {
    __typename?: "UpdateEditorNarrationLibraryOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorNarrationLibraryResult;
};

export enum GqlClientUpdateEditorNarrationLibraryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorPlaceholderInput = {
    flexibleSettings?: InputMaybe<GqlClientEditorFlexiblePlaceholderSettingsInput>;
    id: Scalars["ID"]["input"];
    placeholderContent?: InputMaybe<Scalars["JSON"]["input"]>;
    placeholderSettings?: InputMaybe<Scalars["JSON"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorPlaceholderOutput = {
    result: GqlClientUpdateEditorPlaceholderResult;
};

export type GqlClientUpdateEditorPlaceholderOutputConflict = GqlClientUpdateEditorPlaceholderOutput & {
    __typename?: "UpdateEditorPlaceholderOutputConflict";
    editorPlaceholder?: Maybe<GqlClientEditorPlaceholder>;
    result: GqlClientUpdateEditorPlaceholderResult;
};

export type GqlClientUpdateEditorPlaceholderOutputSuccess = GqlClientUpdateEditorPlaceholderOutput & {
    __typename?: "UpdateEditorPlaceholderOutputSuccess";
    editorPlaceholder?: Maybe<GqlClientEditorPlaceholder>;
    result: GqlClientUpdateEditorPlaceholderResult;
};

export enum GqlClientUpdateEditorPlaceholderResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorProgramLifecycleStageInput = {
    id: Scalars["ID"]["input"];
    lifecycleStage: GqlClientAssetLifecycleStage;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorProgramLifecycleStageOutput = {
    result: GqlClientUpdateEditorProgramLifecycleStageResult;
};

export type GqlClientUpdateEditorProgramLifecycleStageOutputConflict = GqlClientUpdateEditorProgramLifecycleStageOutput & {
    __typename?: "UpdateEditorProgramLifecycleStageOutputConflict";
    editorProgram?: Maybe<GqlClientEditorProgram>;
    result: GqlClientUpdateEditorProgramLifecycleStageResult;
};

export type GqlClientUpdateEditorProgramLifecycleStageOutputSuccess = GqlClientUpdateEditorProgramLifecycleStageOutput & {
    __typename?: "UpdateEditorProgramLifecycleStageOutputSuccess";
    editorProgram?: Maybe<GqlClientEditorProgram>;
    result: GqlClientUpdateEditorProgramLifecycleStageResult;
};

export enum GqlClientUpdateEditorProgramLifecycleStageResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorProgramNameInput = {
    name: Scalars["String"]["input"];
    programId: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorProgramNameOutput = {
    result: GqlClientUpdateEditorProgramNameResult;
};

export type GqlClientUpdateEditorProgramNameOutputConflict = GqlClientUpdateEditorProgramNameOutput & {
    __typename?: "UpdateEditorProgramNameOutputConflict";
    editorProgram?: Maybe<GqlClientEditorProgram>;
    result: GqlClientUpdateEditorProgramNameResult;
};

export type GqlClientUpdateEditorProgramNameOutputSuccess = GqlClientUpdateEditorProgramNameOutput & {
    __typename?: "UpdateEditorProgramNameOutputSuccess";
    editorProgram?: Maybe<GqlClientEditorProgram>;
    result: GqlClientUpdateEditorProgramNameResult;
};

export enum GqlClientUpdateEditorProgramNameResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorProgramThemeInput = {
    programVersionId: Scalars["ID"]["input"];
    theme: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorProgramThemeOutput = {
    result: GqlClientUpdateEditorProgramThemeResult;
};

export type GqlClientUpdateEditorProgramThemeOutputConflict = GqlClientUpdateEditorProgramThemeOutput & {
    __typename?: "UpdateEditorProgramThemeOutputConflict";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorProgramThemeResult;
};

export type GqlClientUpdateEditorProgramThemeOutputSuccess = GqlClientUpdateEditorProgramThemeOutput & {
    __typename?: "UpdateEditorProgramThemeOutputSuccess";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorProgramThemeResult;
};

export enum GqlClientUpdateEditorProgramThemeResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorProgramVersionAspectRatioInput = {
    aspectRatio: GqlClientEnumAspectRatio;
    id: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorProgramVersionAspectRatioOutput = {
    result: GqlClientUpdateEditorProgramVersionAspectRatioResult;
};

export type GqlClientUpdateEditorProgramVersionAspectRatioOutputConflict = GqlClientUpdateEditorProgramVersionAspectRatioOutput & {
    __typename?: "UpdateEditorProgramVersionAspectRatioOutputConflict";
    programVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorProgramVersionAspectRatioResult;
};

export type GqlClientUpdateEditorProgramVersionAspectRatioOutputSuccess = GqlClientUpdateEditorProgramVersionAspectRatioOutput & {
    __typename?: "UpdateEditorProgramVersionAspectRatioOutputSuccess";
    programVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorProgramVersionAspectRatioResult;
};

export enum GqlClientUpdateEditorProgramVersionAspectRatioResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorProgramVersionBrandRulesInput = {
    brandRules: Scalars["JSON"]["input"];
    id: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
    useRules: Scalars["Boolean"]["input"];
};

export type GqlClientUpdateEditorProgramVersionBrandRulesOutput = {
    __typename?: "UpdateEditorProgramVersionBrandRulesOutput";
    programVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorProgramVersionBrandRulesResult;
};

export enum GqlClientUpdateEditorProgramVersionBrandRulesResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorProgramVersionThumbnailIfNeededInput = {
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientUpdateEditorProgramVersionThumbnailIfNeededOutput = {
    __typename?: "UpdateEditorProgramVersionThumbnailIfNeededOutput";
    result: GqlClientUpdateEditorProgramVersionThumbnailIfNeededResult;
};

export enum GqlClientUpdateEditorProgramVersionThumbnailIfNeededResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorSceneInput = {
    buttonAlignment?: InputMaybe<GqlClientEnumHorizontalAlignment>;
    colorCombination?: InputMaybe<GqlClientEditorSceneColorCombination>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    hasTransition?: InputMaybe<Scalars["Boolean"]["input"]>;
    id: Scalars["ID"]["input"];
    minimumDuration?: InputMaybe<Scalars["Float"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    skipSceneAudience?: InputMaybe<Scalars["JSON"]["input"]>;
    skipSceneMode?: InputMaybe<GqlClientEditorSceneSkippingMode>;
    transitionName?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorSceneOutput = {
    result: GqlClientUpdateEditorSceneResult;
};

export type GqlClientUpdateEditorSceneOutputConflict = GqlClientUpdateEditorSceneOutput & {
    __typename?: "UpdateEditorSceneOutputConflict";
    editorScene?: Maybe<GqlClientEditorScene>;
    result: GqlClientUpdateEditorSceneResult;
};

export type GqlClientUpdateEditorSceneOutputSuccess = GqlClientUpdateEditorSceneOutput & {
    __typename?: "UpdateEditorSceneOutputSuccess";
    editorScene?: Maybe<GqlClientEditorScene>;
    result: GqlClientUpdateEditorSceneResult;
};

export enum GqlClientUpdateEditorSceneResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorSceneSelectedAnimatedWireframeInput = {
    animatedWireframeId: Scalars["ID"]["input"];
    sceneId: Scalars["ID"]["input"];
    themeId: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorSceneSelectedAnimatedWireframeOutput = {
    result: GqlClientUpdateEditorSceneSelectedAnimatedWireframeResult;
};

export type GqlClientUpdateEditorSceneSelectedAnimatedWireframeOutputConflict = GqlClientUpdateEditorSceneSelectedAnimatedWireframeOutput & {
    __typename?: "UpdateEditorSceneSelectedAnimatedWireframeOutputConflict";
    editorScene?: Maybe<GqlClientEditorScene>;
    result: GqlClientUpdateEditorSceneSelectedAnimatedWireframeResult;
};

export type GqlClientUpdateEditorSceneSelectedAnimatedWireframeOutputSuccess = GqlClientUpdateEditorSceneSelectedAnimatedWireframeOutput & {
    __typename?: "UpdateEditorSceneSelectedAnimatedWireframeOutputSuccess";
    editorScene?: Maybe<GqlClientEditorScene>;
    result: GqlClientUpdateEditorSceneSelectedAnimatedWireframeResult;
};

export enum GqlClientUpdateEditorSceneSelectedAnimatedWireframeResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorSelectedBcCollectionInput = {
    editorProgramVersionId: Scalars["ID"]["input"];
    selectedBcCollectionId: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorSelectedBcCollectionOutput = {
    result: GqlClientUpdateEditorSelectedBcCollectionResult;
};

export type GqlClientUpdateEditorSelectedBcCollectionOutputConflict = GqlClientUpdateEditorSelectedBcCollectionOutput & {
    __typename?: "UpdateEditorSelectedBcCollectionOutputConflict";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorSelectedBcCollectionResult;
};

export type GqlClientUpdateEditorSelectedBcCollectionOutputSuccess = GqlClientUpdateEditorSelectedBcCollectionOutput & {
    __typename?: "UpdateEditorSelectedBcCollectionOutputSuccess";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorSelectedBcCollectionResult;
};

export enum GqlClientUpdateEditorSelectedBcCollectionResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorSelectedMusicInput = {
    editorProgramVersionId: Scalars["ID"]["input"];
    selectedMusicId: Scalars["ID"]["input"];
    selectedMusicSource: GqlClientEditorAssetSource;
};

export type GqlClientUpdateEditorSelectedMusicOutput = {
    __typename?: "UpdateEditorSelectedMusicOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorSelectedMusicResult;
};

export enum GqlClientUpdateEditorSelectedMusicResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorSelectedNarratorInput = {
    editorProgramVersionId: Scalars["ID"]["input"];
    selectedNarratorId: Scalars["ID"]["input"];
};

export type GqlClientUpdateEditorSelectedNarratorOutput = {
    __typename?: "UpdateEditorSelectedNarratorOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorSelectedNarratorResult;
};

export enum GqlClientUpdateEditorSelectedNarratorResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorTtsFullEnablementInput = {
    editorProgramVersionId: Scalars["ID"]["input"];
    isEnabled: Scalars["Boolean"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorTtsFullEnablementOutput = {
    result: GqlClientUpdateEditorTtsFullEnablementResult;
};

export type GqlClientUpdateEditorTtsFullEnablementOutputConflict = GqlClientUpdateEditorTtsFullEnablementOutput & {
    __typename?: "UpdateEditorTtsFullEnablementOutputConflict";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorTtsFullEnablementResult;
};

export type GqlClientUpdateEditorTtsFullEnablementOutputSuccess = GqlClientUpdateEditorTtsFullEnablementOutput & {
    __typename?: "UpdateEditorTtsFullEnablementOutputSuccess";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorTtsFullEnablementResult;
};

export enum GqlClientUpdateEditorTtsFullEnablementResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorVideoCustomBackgroundOutput = {
    __typename?: "UpdateEditorVideoCustomBackgroundOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateEditorVideoCustomBackgroundResult;
};

export enum GqlClientUpdateEditorVideoCustomBackgroundResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateEditorViewerProfileInput = {
    content?: InputMaybe<Scalars["JSON"]["input"]>;
    id: Scalars["String"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateEditorViewerProfileOutput = {
    result: GqlClientUpdateEditorViewerProfileResult;
};

export type GqlClientUpdateEditorViewerProfileOutputConflict = GqlClientUpdateEditorViewerProfileOutput & {
    __typename?: "UpdateEditorViewerProfileOutputConflict";
    result: GqlClientUpdateEditorViewerProfileResult;
    viewerProfile?: Maybe<GqlClientViewerProfile>;
};

export type GqlClientUpdateEditorViewerProfileOutputSuccess = GqlClientUpdateEditorViewerProfileOutput & {
    __typename?: "UpdateEditorViewerProfileOutputSuccess";
    result: GqlClientUpdateEditorViewerProfileResult;
    viewerProfile?: Maybe<GqlClientViewerProfile>;
};

export enum GqlClientUpdateEditorViewerProfileResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateExperimentInput = {
    created: Scalars["Float"]["input"];
    createdBy?: InputMaybe<Scalars["String"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    enabled: Scalars["Boolean"]["input"];
    endTime?: InputMaybe<Scalars["Float"]["input"]>;
    experimentComponent: Scalars["String"]["input"];
    experimentId: Scalars["ID"]["input"];
    experimentName: Scalars["String"]["input"];
    experimentType: Scalars["String"]["input"];
    metric: Scalars["String"]["input"];
    startTime: Scalars["Float"]["input"];
    variants: Array<GqlClientUpdateExperimentVariantInput>;
};

export type GqlClientUpdateExperimentOutput = {
    __typename?: "UpdateExperimentOutput";
    experiment?: Maybe<GqlClientExperiment>;
    result: GqlClientUpdateExperimentResult;
};

export enum GqlClientUpdateExperimentResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateExperimentVariantInput = {
    fraction: Scalars["Float"]["input"];
    variantId: Scalars["String"]["input"];
    variantName: Scalars["String"]["input"];
};

export type GqlClientUpdateFeedDataElementInput = {
    dataType?: InputMaybe<GqlClientDataElementType>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    pii?: InputMaybe<Scalars["Boolean"]["input"]>;
    source?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<GqlClientFeedDataElementStatus>;
    updated: Scalars["String"]["input"];
    useValueSet?: InputMaybe<Scalars["Boolean"]["input"]>;
    valueSet?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
};

export type GqlClientUpdateFeedDataElementOutput = {
    result: GqlClientUpdateFeedDataElementResult;
};

export type GqlClientUpdateFeedDataElementOutputConflict = GqlClientUpdateFeedDataElementOutput & {
    __typename?: "UpdateFeedDataElementOutputConflict";
    feedDataElement?: Maybe<GqlClientFeedDataElement>;
    result: GqlClientUpdateFeedDataElementResult;
};

export type GqlClientUpdateFeedDataElementOutputSuccess = GqlClientUpdateFeedDataElementOutput & {
    __typename?: "UpdateFeedDataElementOutputSuccess";
    feedDataElement?: Maybe<GqlClientFeedDataElement>;
    result: GqlClientUpdateFeedDataElementResult;
};

export enum GqlClientUpdateFeedDataElementResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateFlightInput = {
    CPCV?: InputMaybe<Scalars["Float"]["input"]>;
    CPM?: InputMaybe<Scalars["Float"]["input"]>;
    completedViews?: InputMaybe<Scalars["Float"]["input"]>;
    endDate: Scalars["String"]["input"];
    id: Scalars["ID"]["input"];
    impressions?: InputMaybe<Scalars["Float"]["input"]>;
    insertionOrder: Scalars["String"]["input"];
    margin: Scalars["Float"]["input"];
    startDate: Scalars["String"]["input"];
};

export type GqlClientUpdateGoalWithKpIsInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    kpis?: InputMaybe<Array<GqlClientUpdateGoalWithKpIsKpiInput>>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    newKpis?: InputMaybe<Array<GqlClientUpdateGoalWithKpIsNewKpiInput>>;
    priority?: InputMaybe<Scalars["Int"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateGoalWithKpIsKpiInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    objective?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateGoalWithKpIsNewKpiInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    objective?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdateGoalWithKpIsOutput = {
    result: GqlClientUpdateGoalWithKpIsResult;
};

export type GqlClientUpdateGoalWithKpIsOutputConflict = GqlClientUpdateGoalWithKpIsOutput & {
    __typename?: "UpdateGoalWithKPIsOutputConflict";
    goal?: Maybe<GqlClientGoal>;
    result: GqlClientUpdateGoalWithKpIsResult;
};

export type GqlClientUpdateGoalWithKpIsOutputSuccess = GqlClientUpdateGoalWithKpIsOutput & {
    __typename?: "UpdateGoalWithKPIsOutputSuccess";
    goal?: Maybe<GqlClientGoal>;
    result: GqlClientUpdateGoalWithKpIsResult;
};

export enum GqlClientUpdateGoalWithKpIsResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateMasterAnimationGuidelineInput = {
    id: Scalars["ID"]["input"];
    isLegacy?: InputMaybe<Scalars["Boolean"]["input"]>;
    logic?: InputMaybe<Scalars["JSON"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateMasterAnimationGuidelineOutput = {
    result: GqlClientUpdateMasterAnimationGuidelineResult;
};

export type GqlClientUpdateMasterAnimationGuidelineOutputConflict = GqlClientUpdateMasterAnimationGuidelineOutput & {
    __typename?: "UpdateMasterAnimationGuidelineOutputConflict";
    masterAnimationGuideline?: Maybe<GqlClientMasterAnimationGuideline>;
    result: GqlClientUpdateMasterAnimationGuidelineResult;
};

export type GqlClientUpdateMasterAnimationGuidelineOutputSuccess = GqlClientUpdateMasterAnimationGuidelineOutput & {
    __typename?: "UpdateMasterAnimationGuidelineOutputSuccess";
    masterAnimationGuideline?: Maybe<GqlClientMasterAnimationGuideline>;
    result: GqlClientUpdateMasterAnimationGuidelineResult;
};

export enum GqlClientUpdateMasterAnimationGuidelineResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateMasterDataInput = {
    masterData?: InputMaybe<Scalars["JSON"]["input"]>;
    programVersionId: Scalars["ID"]["input"];
};

export type GqlClientUpdateMasterDataOutput = {
    result: GqlClientUpdateMasterDataResult;
};

export type GqlClientUpdateMasterDataOutputConflict = GqlClientUpdateMasterDataOutput & {
    __typename?: "UpdateMasterDataOutputConflict";
    masterAnimationGuidelines: Array<GqlClientMasterAnimationGuideline>;
    result: GqlClientUpdateMasterDataResult;
};

export type GqlClientUpdateMasterDataOutputSuccess = GqlClientUpdateMasterDataOutput & {
    __typename?: "UpdateMasterDataOutputSuccess";
    masterAnimationGuidelines: Array<GqlClientMasterAnimationGuideline>;
    result: GqlClientUpdateMasterDataResult;
};

export enum GqlClientUpdateMasterDataResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateMediaLibraryGridColumnsOutput = {
    __typename?: "UpdateMediaLibraryGridColumnsOutput";
    result: GqlClientUpdateMediaLibraryGridColumnsResult;
    userSettings: GqlClientUserSettings;
};

export enum GqlClientUpdateMediaLibraryGridColumnsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateMediaLibraryViewTypeInput = {
    mediaLibraryViewType: GqlClientLibraryViewType;
};

export type GqlClientUpdateMediaLibraryViewTypeOutput = {
    __typename?: "UpdateMediaLibraryViewTypeOutput";
    result: GqlClientUpdateMediaLibraryViewTypeResult;
    userSettings: GqlClientUserSettings;
};

export enum GqlClientUpdateMediaLibraryViewTypeResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateNarrationDtaasPrefixKeyInput = {
    dtaasPrefixKey: Scalars["String"]["input"];
    id: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateNarrationInput = {
    dimensionData?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
    dtaasPrefixKey?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    lastUsedRecordingFileNumber?: InputMaybe<Scalars["Int"]["input"]>;
    mergeData?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
    representativeKey?: InputMaybe<Scalars["String"]["input"]>;
    supportsCreativeDataElementOverride?: InputMaybe<Scalars["Boolean"]["input"]>;
    updated: Scalars["String"]["input"];
    variationsToDelete?: InputMaybe<Array<GqlClientUpdateNarrationVariationDelete>>;
    variationsToUpsert?: InputMaybe<Array<GqlClientUpdateNarrationVariationUpsert>>;
};

export type GqlClientUpdateNarrationOrderConflict = GqlClientUpdateNarrationOrderOutput & {
    __typename?: "UpdateNarrationOrderConflict";
    originalScenePart?: Maybe<GqlClientScenePart>;
    result: GqlClientUpdateNarrationOrderResult;
};

export type GqlClientUpdateNarrationOrderInput = {
    id: Scalars["ID"]["input"];
    newNarrationId?: InputMaybe<Scalars["ID"]["input"]>;
    sourceScenePartNarrationOrder: Array<Scalars["ID"]["input"]>;
    targetScenePartId?: InputMaybe<Scalars["ID"]["input"]>;
    targetScenePartNarrationOrder?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateNarrationOrderOutput = {
    result: GqlClientUpdateNarrationOrderResult;
};

export enum GqlClientUpdateNarrationOrderResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateNarrationOrderSuccess = GqlClientUpdateNarrationOrderOutput & {
    __typename?: "UpdateNarrationOrderSuccess";
    originalScenePart?: Maybe<GqlClientScenePart>;
    result: GqlClientUpdateNarrationOrderResult;
    targetScenePart?: Maybe<GqlClientScenePart>;
};

export type GqlClientUpdateNarrationOutput = {
    result: GqlClientUpdateNarrationResult;
};

export type GqlClientUpdateNarrationOutputConflict = GqlClientUpdateNarrationOutput & {
    __typename?: "UpdateNarrationOutputConflict";
    narration?: Maybe<GqlClientNarration>;
    result: GqlClientUpdateNarrationResult;
};

export type GqlClientUpdateNarrationOutputSuccess = GqlClientUpdateNarrationOutput & {
    __typename?: "UpdateNarrationOutputSuccess";
    narration?: Maybe<GqlClientNarration>;
    result: GqlClientUpdateNarrationResult;
};

export type GqlClientUpdateNarrationRecordingInput = {
    activeNarrationRecordingVersionId: Scalars["ID"]["input"];
    id: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateNarrationRecordingOutput = {
    result: GqlClientUpdateNarrationRecordingResult;
};

export type GqlClientUpdateNarrationRecordingOutputConflict = GqlClientUpdateNarrationRecordingOutput & {
    __typename?: "UpdateNarrationRecordingOutputConflict";
    narrationRecording?: Maybe<GqlClientNarrationRecording>;
    result: GqlClientUpdateNarrationRecordingResult;
};

export type GqlClientUpdateNarrationRecordingOutputSuccess = GqlClientUpdateNarrationRecordingOutput & {
    __typename?: "UpdateNarrationRecordingOutputSuccess";
    narrationRecording?: Maybe<GqlClientNarrationRecording>;
    result: GqlClientUpdateNarrationRecordingResult;
};

export enum GqlClientUpdateNarrationRecordingResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateNarrationRecordingVersionInput = {
    errorReason?: InputMaybe<GqlClientNarrationRecordingErrorReason>;
    id: Scalars["ID"]["input"];
    narrationRecordingId: Scalars["ID"]["input"];
    status?: InputMaybe<GqlClientNarrationRecordingStatus>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateNarrationRecordingVersionOutput = {
    result: GqlClientUpdateNarrationRecordingVersionResult;
};

export type GqlClientUpdateNarrationRecordingVersionOutputConflict = GqlClientUpdateNarrationRecordingVersionOutput & {
    __typename?: "UpdateNarrationRecordingVersionOutputConflict";
    narrationRecordingVersion?: Maybe<GqlClientNarrationRecordingVersion>;
    result: GqlClientUpdateNarrationRecordingVersionResult;
};

export type GqlClientUpdateNarrationRecordingVersionOutputSuccess = GqlClientUpdateNarrationRecordingVersionOutput & {
    __typename?: "UpdateNarrationRecordingVersionOutputSuccess";
    narrationRecordingVersion?: Maybe<GqlClientNarrationRecordingVersion>;
    result: GqlClientUpdateNarrationRecordingVersionResult;
};

export enum GqlClientUpdateNarrationRecordingVersionResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export enum GqlClientUpdateNarrationResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateNarrationVariationDelete = {
    variationKey?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdateNarrationVariationUpsert = {
    muted?: InputMaybe<Scalars["Boolean"]["input"]>;
    note?: InputMaybe<Scalars["String"]["input"]>;
    overridingCreativeDataElementId?: InputMaybe<Scalars["ID"]["input"]>;
    sentence?: InputMaybe<Scalars["String"]["input"]>;
    variationKey?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdateNarrationsDtaasPrefixKeyInput = {
    narrationsToUpdate: Array<GqlClientUpdateNarrationDtaasPrefixKeyInput>;
    programVersionId: Scalars["String"]["input"];
};

export type GqlClientUpdateNarrationsDtaasPrefixKeyOutput = {
    result: GqlClientUpdateNarrationsDtaasPrefixKeyResult;
};

export type GqlClientUpdateNarrationsDtaasPrefixKeyOutputConflict = GqlClientUpdateNarrationsDtaasPrefixKeyOutput & {
    __typename?: "UpdateNarrationsDtaasPrefixKeyOutputConflict";
    narrations: Array<GqlClientNarration>;
    result: GqlClientUpdateNarrationsDtaasPrefixKeyResult;
};

export type GqlClientUpdateNarrationsDtaasPrefixKeyOutputSuccess = GqlClientUpdateNarrationsDtaasPrefixKeyOutput & {
    __typename?: "UpdateNarrationsDtaasPrefixKeyOutputSuccess";
    narrations: Array<GqlClientNarration>;
    result: GqlClientUpdateNarrationsDtaasPrefixKeyResult;
};

export enum GqlClientUpdateNarrationsDtaasPrefixKeyResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateNarratorInput = {
    carUrl?: InputMaybe<Scalars["String"]["input"]>;
    decisionTablesLoadTaskId?: InputMaybe<Scalars["String"]["input"]>;
    dtaasPrefix?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    status?: InputMaybe<GqlClientNarratorStatus>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateNarratorOutput = {
    result: GqlClientUpdateNarratorResult;
};

export type GqlClientUpdateNarratorOutputConflict = GqlClientUpdateNarratorOutput & {
    __typename?: "UpdateNarratorOutputConflict";
    narrator?: Maybe<GqlClientNarrator>;
    result: GqlClientUpdateNarratorResult;
};

export type GqlClientUpdateNarratorOutputSuccess = GqlClientUpdateNarratorOutput & {
    __typename?: "UpdateNarratorOutputSuccess";
    narrator?: Maybe<GqlClientNarrator>;
    result: GqlClientUpdateNarratorResult;
};

export enum GqlClientUpdateNarratorResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdatePlaceholderGroupLogicInput = {
    id: Scalars["ID"]["input"];
    logic?: InputMaybe<Scalars["JSON"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdatePlaceholderGroupLogicOutput = {
    result: GqlClientUpdatePlaceholderGroupLogicResult;
};

export type GqlClientUpdatePlaceholderGroupLogicOutputConflict = GqlClientUpdatePlaceholderGroupLogicOutput & {
    __typename?: "UpdatePlaceholderGroupLogicOutputConflict";
    logic?: Maybe<GqlClientPlaceholderGroupLogic>;
    result: GqlClientUpdatePlaceholderGroupLogicResult;
};

export type GqlClientUpdatePlaceholderGroupLogicOutputSuccess = GqlClientUpdatePlaceholderGroupLogicOutput & {
    __typename?: "UpdatePlaceholderGroupLogicOutputSuccess";
    logic?: Maybe<GqlClientPlaceholderGroupLogic>;
    result: GqlClientUpdatePlaceholderGroupLogicResult;
};

export enum GqlClientUpdatePlaceholderGroupLogicResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdatePresetDataElementValueInput = {
    dataElementId: Scalars["ID"]["input"];
    type?: InputMaybe<Scalars["String"]["input"]>;
    value?: InputMaybe<Scalars["String"]["input"]>;
    valueId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdatePresetInput = {
    dataElementValues?: InputMaybe<Array<GqlClientUpdatePresetDataElementValueInput>>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    systemElementValues?: InputMaybe<Array<GqlClientUpdatePresetSystemElementValueInput>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdatePresetOutput = {
    result: GqlClientUpdatePresetResult;
};

export type GqlClientUpdatePresetOutputConflict = GqlClientUpdatePresetOutput & {
    __typename?: "UpdatePresetOutputConflict";
    preset?: Maybe<GqlClientPreset>;
    result: GqlClientUpdatePresetResult;
};

export type GqlClientUpdatePresetOutputSuccess = GqlClientUpdatePresetOutput & {
    __typename?: "UpdatePresetOutputSuccess";
    preset?: Maybe<GqlClientPreset>;
    result: GqlClientUpdatePresetResult;
};

export enum GqlClientUpdatePresetResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdatePresetSystemElementValueInput = {
    systemElementId: Scalars["String"]["input"];
    type?: InputMaybe<Scalars["String"]["input"]>;
    value?: InputMaybe<Scalars["String"]["input"]>;
    valueId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdatePrioritizedListLogicInput = {
    id: Scalars["ID"]["input"];
    logic?: InputMaybe<Scalars["JSON"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdatePrioritizedListLogicOutput = {
    result: GqlClientUpdatePrioritizedListLogicResult;
};

export type GqlClientUpdatePrioritizedListLogicOutputConflict = GqlClientUpdatePrioritizedListLogicOutput & {
    __typename?: "UpdatePrioritizedListLogicOutputConflict";
    logic?: Maybe<GqlClientPrioritizedListLogic>;
    result: GqlClientUpdatePrioritizedListLogicResult;
};

export type GqlClientUpdatePrioritizedListLogicOutputSuccess = GqlClientUpdatePrioritizedListLogicOutput & {
    __typename?: "UpdatePrioritizedListLogicOutputSuccess";
    logic?: Maybe<GqlClientPrioritizedListLogic>;
    result: GqlClientUpdatePrioritizedListLogicResult;
};

export enum GqlClientUpdatePrioritizedListLogicResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateProductFilterInput = {
    attName?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    operator: GqlClientProductFilterOperator;
    type: GqlClientProductFilterFieldType;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdateProductSelectorInput = {
    audienceId?: InputMaybe<Scalars["Int"]["input"]>;
    extraData?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    type?: InputMaybe<GqlClientProductSelectorFieldType>;
};

export type GqlClientUpdateProgramAndDraftInput = {
    audienceSize?: InputMaybe<Scalars["String"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    industry?: InputMaybe<Scalars["String"]["input"]>;
    legacyBuilderProgramId?: InputMaybe<Scalars["String"]["input"]>;
    legacyFrameworkProgramId?: InputMaybe<Scalars["String"]["input"]>;
    legacyOverviewProgramId?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    objective?: InputMaybe<Scalars["String"]["input"]>;
    programUpdated: Scalars["String"]["input"];
    programVersionUpdated: Scalars["String"]["input"];
    sceneOrder?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    storyOrder?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    useCase?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdateProgramAndDraftOutput = {
    result: GqlClientUpdateProgramAndDraftResult;
};

export type GqlClientUpdateProgramAndDraftOutputConflict = GqlClientUpdateProgramAndDraftOutput & {
    __typename?: "UpdateProgramAndDraftOutputConflict";
    program?: Maybe<GqlClientProgram>;
    result: GqlClientUpdateProgramAndDraftResult;
};

export type GqlClientUpdateProgramAndDraftOutputSuccess = GqlClientUpdateProgramAndDraftOutput & {
    __typename?: "UpdateProgramAndDraftOutputSuccess";
    program?: Maybe<GqlClientProgram>;
    result: GqlClientUpdateProgramAndDraftResult;
};

export enum GqlClientUpdateProgramAndDraftResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateProgramInput = {
    customAnalyticsOrder?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    isArchive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isLegacy?: InputMaybe<Scalars["Boolean"]["input"]>;
    legacyBuilderProgramId?: InputMaybe<Scalars["String"]["input"]>;
    legacyFrameworkProgramId?: InputMaybe<Scalars["String"]["input"]>;
    legacyOverviewProgramId?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    pii?: InputMaybe<Scalars["Boolean"]["input"]>;
    publishTarget?: InputMaybe<GqlClientLifecycleStage>;
    salesforceId?: InputMaybe<Scalars["String"]["input"]>;
    updated?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdateProgramLastSnapshotNumberInput = {
    id: Scalars["ID"]["input"];
};

export type GqlClientUpdateProgramLastSnapshotNumberOutput = {
    __typename?: "UpdateProgramLastSnapshotNumberOutput";
    result: GqlClientUpdateProgramLastSnapshotNumberResult;
    snapshotNumber: Scalars["Int"]["output"];
};

export enum GqlClientUpdateProgramLastSnapshotNumberResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateProgramOutput = {
    result: GqlClientUpdateProgramResult;
};

export type GqlClientUpdateProgramOutputConflict = GqlClientUpdateProgramOutput & {
    __typename?: "UpdateProgramOutputConflict";
    program?: Maybe<GqlClientProgram>;
    result: GqlClientUpdateProgramResult;
};

export type GqlClientUpdateProgramOutputSuccess = GqlClientUpdateProgramOutput & {
    __typename?: "UpdateProgramOutputSuccess";
    program?: Maybe<GqlClientProgram>;
    result: GqlClientUpdateProgramResult;
};

export enum GqlClientUpdateProgramResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateProgramReviewInput = {
    addedReviewers: Array<Scalars["String"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
};

export type GqlClientUpdateProgramReviewOutput = {
    __typename?: "UpdateProgramReviewOutput";
    programReview?: Maybe<GqlClientProgramReview>;
    result: GqlClientUpdateProgramReviewResult;
};

export enum GqlClientUpdateProgramReviewResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateProgramVersionDraftInput = {
    audienceSize?: InputMaybe<Scalars["String"]["input"]>;
    chapteringEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
    creativeDDE?: InputMaybe<Scalars["String"]["input"]>;
    creativeNameLabel?: InputMaybe<Scalars["String"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    editorDataConnectorEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
    id: Scalars["ID"]["input"];
    industry?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    objective?: InputMaybe<Scalars["String"]["input"]>;
    programType?: InputMaybe<GqlClientProgramType>;
    sceneOrder?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    storyOrder?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    storySelectionLogic?: InputMaybe<Scalars["JSON"]["input"]>;
    supportsCreatives?: InputMaybe<Scalars["Boolean"]["input"]>;
    updated?: InputMaybe<Scalars["String"]["input"]>;
    useCase?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdateProgramVersionDraftOutput = {
    result: GqlClientUpdateProgramVersionDraftResult;
};

export type GqlClientUpdateProgramVersionDraftOutputConflict = GqlClientUpdateProgramVersionDraftOutput & {
    __typename?: "UpdateProgramVersionDraftOutputConflict";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientUpdateProgramVersionDraftResult;
};

export type GqlClientUpdateProgramVersionDraftOutputSuccess = GqlClientUpdateProgramVersionDraftOutput & {
    __typename?: "UpdateProgramVersionDraftOutputSuccess";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientUpdateProgramVersionDraftResult;
};

export enum GqlClientUpdateProgramVersionDraftResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateProgramVersionSnapshotInput = {
    id: Scalars["ID"]["input"];
    snapshotComment?: InputMaybe<Scalars["String"]["input"]>;
    snapshotName?: InputMaybe<Scalars["String"]["input"]>;
    updated?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdateProgramVersionSnapshotOutput = {
    result: GqlClientUpdateProgramVersionSnapshotResult;
};

export type GqlClientUpdateProgramVersionSnapshotOutputConflict = GqlClientUpdateProgramVersionSnapshotOutput & {
    __typename?: "UpdateProgramVersionSnapshotOutputConflict";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientUpdateProgramVersionSnapshotResult;
};

export type GqlClientUpdateProgramVersionSnapshotOutputSuccess = GqlClientUpdateProgramVersionSnapshotOutput & {
    __typename?: "UpdateProgramVersionSnapshotOutputSuccess";
    programVersion?: Maybe<GqlClientProgramVersion>;
    result: GqlClientUpdateProgramVersionSnapshotResult;
};

export enum GqlClientUpdateProgramVersionSnapshotResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateProjectAudioSettingsInput = {
    audioSettings?: InputMaybe<Scalars["JSON"]["input"]>;
    editorProgramVersionId: Scalars["ID"]["input"];
};

export type GqlClientUpdateProjectAudioSettingsOutput = {
    __typename?: "UpdateProjectAudioSettingsOutput";
    editorProgramVersion?: Maybe<GqlClientEditorProgramVersion>;
    result: GqlClientUpdateProjectAudioSettingsResult;
};

export enum GqlClientUpdateProjectAudioSettingsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdatePronunciationDefinitionInput = {
    pronunciationDefinitionId: Scalars["ID"]["input"];
    source: Scalars["String"]["input"];
    target: Scalars["String"]["input"];
};

export type GqlClientUpdatePronunciationDefinitionOutput = {
    __typename?: "UpdatePronunciationDefinitionOutput";
    pronunciationDefinition?: Maybe<GqlClientAccountPronunciationDefinition>;
    result: GqlClientUpdatePronunciationDefinitionResult;
};

export enum GqlClientUpdatePronunciationDefinitionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateReportingDefinitionsInput = {
    attributeType: Scalars["String"]["input"];
    fieldValue?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    tagName?: InputMaybe<Scalars["String"]["input"]>;
    targetField: Scalars["String"]["input"];
};

export type GqlClientUpdateSceneEntityLogicInput = {
    id: Scalars["ID"]["input"];
    logic?: InputMaybe<Scalars["JSON"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateSceneEntityLogicOutput = {
    result: GqlClientUpdateSceneEntityLogicResult;
};

export type GqlClientUpdateSceneEntityLogicOutputConflict = GqlClientUpdateSceneEntityLogicOutput & {
    __typename?: "UpdateSceneEntityLogicOutputConflict";
    logic?: Maybe<GqlClientSceneEntityLogic>;
    result: GqlClientUpdateSceneEntityLogicResult;
};

export type GqlClientUpdateSceneEntityLogicOutputSuccess = GqlClientUpdateSceneEntityLogicOutput & {
    __typename?: "UpdateSceneEntityLogicOutputSuccess";
    logic?: Maybe<GqlClientSceneEntityLogic>;
    result: GqlClientUpdateSceneEntityLogicResult;
};

export enum GqlClientUpdateSceneEntityLogicResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateSceneInput = {
    animationLogic?: InputMaybe<Scalars["JSON"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    descriptiveTranscriptLogic?: InputMaybe<Scalars["JSON"]["input"]>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    scenePartOrder?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    updated: Scalars["String"]["input"];
    validationLogic?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type GqlClientUpdateSceneOutput = {
    result: GqlClientUpdateSceneResult;
};

export type GqlClientUpdateSceneOutputConflict = GqlClientUpdateSceneOutput & {
    __typename?: "UpdateSceneOutputConflict";
    result: GqlClientUpdateSceneResult;
    scene?: Maybe<GqlClientScene>;
};

export type GqlClientUpdateSceneOutputSuccess = GqlClientUpdateSceneOutput & {
    __typename?: "UpdateSceneOutputSuccess";
    result: GqlClientUpdateSceneResult;
    scene?: Maybe<GqlClientScene>;
};

export type GqlClientUpdateScenePartInput = {
    id: Scalars["ID"]["input"];
    oldNarrationData?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
    thumbnailLocation?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateScenePartOutput = {
    result: GqlClientUpdateScenePartResult;
};

export type GqlClientUpdateScenePartOutputConflict = GqlClientUpdateScenePartOutput & {
    __typename?: "UpdateScenePartOutputConflict";
    result: GqlClientUpdateScenePartResult;
    scenePart?: Maybe<GqlClientScenePart>;
};

export type GqlClientUpdateScenePartOutputSuccess = GqlClientUpdateScenePartOutput & {
    __typename?: "UpdateScenePartOutputSuccess";
    result: GqlClientUpdateScenePartResult;
    scenePart?: Maybe<GqlClientScenePart>;
};

export enum GqlClientUpdateScenePartResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateScenePartWithAllPlaceholdersInput = {
    id: Scalars["ID"]["input"];
    scenePartData?: InputMaybe<Scalars["JSON"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateScenePartWithAllPlaceholdersOutput = {
    result: GqlClientUpdateScenePartWithAllPlaceholdersResult;
};

export type GqlClientUpdateScenePartWithAllPlaceholdersOutputConflict = GqlClientUpdateScenePartWithAllPlaceholdersOutput & {
    __typename?: "UpdateScenePartWithAllPlaceholdersOutputConflict";
    result: GqlClientUpdateScenePartWithAllPlaceholdersResult;
    scenePart?: Maybe<GqlClientScenePart>;
};

export type GqlClientUpdateScenePartWithAllPlaceholdersOutputSuccess = GqlClientUpdateScenePartWithAllPlaceholdersOutput & {
    __typename?: "UpdateScenePartWithAllPlaceholdersOutputSuccess";
    result: GqlClientUpdateScenePartWithAllPlaceholdersResult;
    scenePart?: Maybe<GqlClientScenePart>;
};

export enum GqlClientUpdateScenePartWithAllPlaceholdersResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateScenePlaceholdersAndAnimatedWireframeInput = {
    animatedWireframeId: Scalars["ID"]["input"];
    colorCombination: GqlClientEditorSceneColorCombination;
    placeholdersData: Array<GqlClientUpdateScenePlaceholdersAndAnimatedWireframePlaceholderData>;
    sceneId: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateScenePlaceholdersAndAnimatedWireframeOutput = {
    result: GqlClientUpdateScenePlaceholdersAndAnimatedWireframeResult;
};

export type GqlClientUpdateScenePlaceholdersAndAnimatedWireframeOutputConflict = GqlClientUpdateScenePlaceholdersAndAnimatedWireframeOutput & {
    __typename?: "UpdateScenePlaceholdersAndAnimatedWireframeOutputConflict";
    editorScene?: Maybe<GqlClientEditorScene>;
    result: GqlClientUpdateScenePlaceholdersAndAnimatedWireframeResult;
};

export type GqlClientUpdateScenePlaceholdersAndAnimatedWireframeOutputSuccess = GqlClientUpdateScenePlaceholdersAndAnimatedWireframeOutput & {
    __typename?: "UpdateScenePlaceholdersAndAnimatedWireframeOutputSuccess";
    editorScene?: Maybe<GqlClientEditorScene>;
    result: GqlClientUpdateScenePlaceholdersAndAnimatedWireframeResult;
};

export type GqlClientUpdateScenePlaceholdersAndAnimatedWireframePlaceholderData = {
    ccPlaceholderId: Scalars["ID"]["input"];
    editorPlaceholderId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum GqlClientUpdateScenePlaceholdersAndAnimatedWireframeResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export enum GqlClientUpdateSceneResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsAudiencePersonalizationInput = {
    audienceId?: InputMaybe<Scalars["ID"]["input"]>;
    audiencePersonalizationValues?: InputMaybe<Array<GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsAudiencePersonalizationValueInput>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    newAudiencePersonalizationValues?: InputMaybe<Array<GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsNewAudiencePersonalizationValueInput>>;
    type?: InputMaybe<Array<Scalars["String"]["input"]>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsAudiencePersonalizationValueInput = {
    audienceValueId: Scalars["ID"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsChannelPersonalizationInput = {
    channelId?: InputMaybe<Scalars["ID"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    type?: InputMaybe<Array<Scalars["String"]["input"]>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsInput = {
    audiencePersonalizations?: InputMaybe<Array<GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsAudiencePersonalizationInput>>;
    channelPersonalizations?: InputMaybe<Array<GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsChannelPersonalizationInput>>;
    dataElementIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    kpiIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    newAudiencePersonalizations?: InputMaybe<Array<GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsNewAudiencePersonalizationInput>>;
    newChannelPersonalizations?: InputMaybe<Array<GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsNewChannelPersonalizationInput>>;
    newFeedDataElements?: InputMaybe<Array<GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsNewFeedDataElementInput>>;
    newTouchpointPersonalizations?: InputMaybe<Array<GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsNewTouchpointPersonalizationInput>>;
    touchpointPersonalizations?: InputMaybe<Array<GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsTouchpointPersonalizationInput>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsNewAudiencePersonalizationInput = {
    audienceId: Scalars["ID"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
    newAudiencePersonalizationValues: Array<GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsNewAudiencePersonalizationValueInput>;
    type: Array<Scalars["String"]["input"]>;
};

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsNewAudiencePersonalizationValueInput = {
    audienceValueId: Scalars["ID"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsNewChannelPersonalizationInput = {
    channelId: Scalars["ID"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
    type: Array<Scalars["String"]["input"]>;
};

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsNewFeedDataElementInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    source?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<GqlClientFeedDataElementStatus>;
};

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsNewTouchpointPersonalizationInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    touchpointId: Scalars["ID"]["input"];
    type: Array<Scalars["String"]["input"]>;
};

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsOutput = {
    result: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsResult;
};

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsOutputConflict = GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsOutput & {
    __typename?: "UpdateSceneWithKPIsPersonalizationsAndDataElementsOutputConflict";
    result: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsResult;
    scene?: Maybe<GqlClientScene>;
};

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsOutputSuccess = GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsOutput & {
    __typename?: "UpdateSceneWithKPIsPersonalizationsAndDataElementsOutputSuccess";
    result: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsResult;
    scene?: Maybe<GqlClientScene>;
};

export enum GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsTouchpointPersonalizationInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    touchpointId?: InputMaybe<Scalars["ID"]["input"]>;
    type?: InputMaybe<Array<Scalars["String"]["input"]>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateStoryForBuilderInput = {
    activateCaching?: InputMaybe<Scalars["Boolean"]["input"]>;
    backgroundAssetType?: InputMaybe<GqlClientBackgroundAssetType>;
    backgroundAudioLogic?: InputMaybe<Scalars["JSON"]["input"]>;
    backgroundImageLogic?: InputMaybe<Scalars["JSON"]["input"]>;
    backgroundVideoLogic?: InputMaybe<Scalars["JSON"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    displaySceneOrder?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    durationOptions?: InputMaybe<Array<Scalars["Int"]["input"]>>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    narratorName?: InputMaybe<Scalars["String"]["input"]>;
    numberOfProducts?: InputMaybe<Array<Scalars["Int"]["input"]>>;
    ratioAndQualityLogic?: InputMaybe<Scalars["JSON"]["input"]>;
    ratioAndQualityOptions?: InputMaybe<Array<GqlClientUpdateStoryForBuilderRationAndQualityOptionInput>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateStoryForBuilderOutput = {
    result: GqlClientUpdateStoryForBuilderResult;
};

export type GqlClientUpdateStoryForBuilderOutputConflict = GqlClientUpdateStoryForBuilderOutput & {
    __typename?: "UpdateStoryForBuilderOutputConflict";
    result: GqlClientUpdateStoryForBuilderResult;
    story?: Maybe<GqlClientStory>;
};

export type GqlClientUpdateStoryForBuilderOutputSuccess = GqlClientUpdateStoryForBuilderOutput & {
    __typename?: "UpdateStoryForBuilderOutputSuccess";
    result: GqlClientUpdateStoryForBuilderResult;
    story?: Maybe<GqlClientStory>;
};

export type GqlClientUpdateStoryForBuilderRationAndQualityOptionInput = {
    quality?: InputMaybe<Scalars["String"]["input"]>;
    ratio?: InputMaybe<Scalars["String"]["input"]>;
};

export enum GqlClientUpdateStoryForBuilderResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateStoryForFrameworkInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    displaySceneOrder?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateStoryForFrameworkOutput = {
    result: GqlClientUpdateStoryForFrameworkResult;
};

export type GqlClientUpdateStoryForFrameworkOutputConflict = GqlClientUpdateStoryForFrameworkOutput & {
    __typename?: "UpdateStoryForFrameworkOutputConflict";
    result: GqlClientUpdateStoryForFrameworkResult;
    story?: Maybe<GqlClientStory>;
};

export type GqlClientUpdateStoryForFrameworkOutputSuccess = GqlClientUpdateStoryForFrameworkOutput & {
    __typename?: "UpdateStoryForFrameworkOutputSuccess";
    result: GqlClientUpdateStoryForFrameworkResult;
    story?: Maybe<GqlClientStory>;
};

export enum GqlClientUpdateStoryForFrameworkResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateStoryWithAllDecisionPointsInput = {
    decisionPoints?: InputMaybe<Array<GqlClientUpdateAllDecisionPoint>>;
    id: Scalars["ID"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateStoryWithAllDecisionPointsOutput = {
    result: GqlClientUpdateStoryWithAllDecisionPointsResult;
};

export type GqlClientUpdateStoryWithAllDecisionPointsOutputConflict = GqlClientUpdateStoryWithAllDecisionPointsOutput & {
    __typename?: "UpdateStoryWithAllDecisionPointsOutputConflict";
    result: GqlClientUpdateStoryWithAllDecisionPointsResult;
    story?: Maybe<GqlClientStory>;
};

export type GqlClientUpdateStoryWithAllDecisionPointsOutputSuccess = GqlClientUpdateStoryWithAllDecisionPointsOutput & {
    __typename?: "UpdateStoryWithAllDecisionPointsOutputSuccess";
    result: GqlClientUpdateStoryWithAllDecisionPointsResult;
    story?: Maybe<GqlClientStory>;
};

export enum GqlClientUpdateStoryWithAllDecisionPointsResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateStoryWithDecisionPointsInput = {
    decisionPointsToDeleteIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    decisionPointsToUpdate?: InputMaybe<Array<GqlClientUpdateCreateAndDeleteDecisionPointToUpdate>>;
    id: Scalars["ID"]["input"];
    newDecisionPoints?: InputMaybe<Array<GqlClientUpdateCreateAndDeleteNewDecisionPoint>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateStoryWithDecisionPointsOutput = {
    result: GqlClientUpdateStoryWithDecisionPointsResult;
};

export type GqlClientUpdateStoryWithDecisionPointsOutputConflict = GqlClientUpdateStoryWithDecisionPointsOutput & {
    __typename?: "UpdateStoryWithDecisionPointsOutputConflict";
    result: GqlClientUpdateStoryWithDecisionPointsResult;
    story?: Maybe<GqlClientStory>;
};

export type GqlClientUpdateStoryWithDecisionPointsOutputSuccess = GqlClientUpdateStoryWithDecisionPointsOutput & {
    __typename?: "UpdateStoryWithDecisionPointsOutputSuccess";
    result: GqlClientUpdateStoryWithDecisionPointsResult;
    story?: Maybe<GqlClientStory>;
};

export enum GqlClientUpdateStoryWithDecisionPointsResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateStoryWithNewSceneDecisionPointToUpdate = {
    id: Scalars["ID"]["input"];
    logic: Scalars["JSON"]["input"];
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateStoryWithNewSceneInput = {
    decisionPointToUpdate: GqlClientUpdateStoryWithNewSceneDecisionPointToUpdate;
    id: Scalars["ID"]["input"];
    newDecisionPoint?: InputMaybe<GqlClientUpdateStoryWithNewSceneNewDecisionPoint>;
    newScene: GqlClientUpdateStoryWithNewSceneNewScene;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateStoryWithNewSceneNewDecisionPoint = {
    localId: Scalars["String"]["input"];
    logic: Scalars["JSON"]["input"];
};

export type GqlClientUpdateStoryWithNewSceneNewScene = {
    name: Scalars["String"]["input"];
};

export type GqlClientUpdateStoryWithNewSceneOutput = {
    result: GqlClientUpdateStoryWithNewSceneResult;
};

export type GqlClientUpdateStoryWithNewSceneOutputConflict = GqlClientUpdateStoryWithNewSceneOutput & {
    __typename?: "UpdateStoryWithNewSceneOutputConflict";
    result: GqlClientUpdateStoryWithNewSceneResult;
    story?: Maybe<GqlClientStory>;
};

export type GqlClientUpdateStoryWithNewSceneOutputSuccess = GqlClientUpdateStoryWithNewSceneOutput & {
    __typename?: "UpdateStoryWithNewSceneOutputSuccess";
    result: GqlClientUpdateStoryWithNewSceneResult;
    scene?: Maybe<GqlClientScene>;
    story?: Maybe<GqlClientStory>;
};

export enum GqlClientUpdateStoryWithNewSceneResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateStoryWithReachAndScenesInput = {
    channelIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    sceneIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    touchpointIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateStoryWithReachAndScenesOutput = {
    result: GqlClientUpdateStoryWithReachAndScenesResult;
};

export type GqlClientUpdateStoryWithReachAndScenesOutputConflict = GqlClientUpdateStoryWithReachAndScenesOutput & {
    __typename?: "UpdateStoryWithReachAndScenesOutputConflict";
    result: GqlClientUpdateStoryWithReachAndScenesResult;
    story?: Maybe<GqlClientStory>;
};

export type GqlClientUpdateStoryWithReachAndScenesOutputSuccess = GqlClientUpdateStoryWithReachAndScenesOutput & {
    __typename?: "UpdateStoryWithReachAndScenesOutputSuccess";
    result: GqlClientUpdateStoryWithReachAndScenesResult;
    story?: Maybe<GqlClientStory>;
};

export enum GqlClientUpdateStoryWithReachAndScenesResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateSurveyInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    externalId?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    questions?: InputMaybe<Scalars["JSON"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateSurveyOutput = {
    result: GqlClientUpdateSurveyResult;
};

export type GqlClientUpdateSurveyOutputConflict = GqlClientUpdateSurveyOutput & {
    __typename?: "UpdateSurveyOutputConflict";
    result: GqlClientUpdateSurveyResult;
    survey?: Maybe<GqlClientSurvey>;
};

export type GqlClientUpdateSurveyOutputSuccess = GqlClientUpdateSurveyOutput & {
    __typename?: "UpdateSurveyOutputSuccess";
    result: GqlClientUpdateSurveyResult;
    survey?: Maybe<GqlClientSurvey>;
};

export enum GqlClientUpdateSurveyResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateTextStyle = {
    accountFontId?: InputMaybe<Scalars["ID"]["input"]>;
    ccFontId?: InputMaybe<Scalars["ID"]["input"]>;
    fontSource?: InputMaybe<GqlClientEditorAssetSource>;
    id: Scalars["ID"]["input"];
    letterCase?: InputMaybe<GqlClientTextStyleLetterCase>;
    letterSpacing?: InputMaybe<Scalars["Int"]["input"]>;
    lineSpacing?: InputMaybe<Scalars["Int"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateTierFeatureDefinitionInput = {
    featureId: Scalars["ID"]["input"];
    newValue: Scalars["JSON"]["input"];
};

export type GqlClientUpdateTierFeatureDefinitionOutput = {
    __typename?: "UpdateTierFeatureDefinitionOutput";
    result: GqlClientUpdateTierFeatureDefinitionResult;
    tierFeatureDefinition: GqlClientTierFeatureDefinition;
};

export enum GqlClientUpdateTierFeatureDefinitionResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateTouchpointInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["ID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    updated: Scalars["String"]["input"];
};

export type GqlClientUpdateTouchpointOutput = {
    result: GqlClientUpdateTouchpointResult;
};

export type GqlClientUpdateTouchpointOutputConflict = GqlClientUpdateTouchpointOutput & {
    __typename?: "UpdateTouchpointOutputConflict";
    result: GqlClientUpdateTouchpointResult;
    touchpoint?: Maybe<GqlClientTouchpoint>;
};

export type GqlClientUpdateTouchpointOutputSuccess = GqlClientUpdateTouchpointOutput & {
    __typename?: "UpdateTouchpointOutputSuccess";
    result: GqlClientUpdateTouchpointResult;
    touchpoint?: Maybe<GqlClientTouchpoint>;
};

export enum GqlClientUpdateTouchpointResult {
    CONFLICT = "CONFLICT",
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateTrayConnectorAuthenticationInput = {
    authId: Scalars["String"]["input"];
    connectorType: Scalars["String"]["input"];
};

export type GqlClientUpdateTrayConnectorAuthenticationOutput = {
    __typename?: "UpdateTrayConnectorAuthenticationOutput";
    result: GqlClientUpdateTrayConnectorAuthenticationResult;
    solutionInstanceTriggerUrl?: Maybe<Scalars["String"]["output"]>;
};

export enum GqlClientUpdateTrayConnectorAuthenticationResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdatedDynamicElement = {
    dynamicElementData: Scalars["JSON"]["input"];
    id: Scalars["ID"]["input"];
};

export type GqlClientUpgradeEditorProgramToM2LayoutsInput = {
    allowPartialMapping: Scalars["Boolean"]["input"];
    editorProgramVersionId: Scalars["ID"]["input"];
};

export type GqlClientUpgradeEditorProgramToM2LayoutsOutput = {
    __typename?: "UpgradeEditorProgramToM2LayoutsOutput";
    editorProgramVersion: GqlClientEditorProgramVersion;
    result: GqlClientUpgradeEditorProgramToM2LayoutsResult;
};

export enum GqlClientUpgradeEditorProgramToM2LayoutsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUploadAdsFileInput = {
    campaignName: Scalars["String"]["input"];
    fieldData: GqlClientAdsFieldData;
    file: Scalars["Upload"]["input"];
    id: Scalars["ID"]["input"];
    programSfId: Scalars["String"]["input"];
};

export type GqlClientUploadAdsFileOutput = {
    __typename?: "UploadAdsFileOutput";
    id: Scalars["ID"]["output"];
    location?: Maybe<Scalars["String"]["output"]>;
    returnCode: GqlClientAdsReturnCode;
};

export enum GqlClientUploadAdsFileResult {
    FAILURE = "FAILURE",
    SUCCESS = "SUCCESS"
}

export type GqlClientUploadEditorAssetFontInput = {
    file: Scalars["Upload"]["input"];
    fileType: Scalars["String"]["input"];
    filename: Scalars["String"]["input"];
    uploadId: Scalars["String"]["input"];
};

export type GqlClientUploadEditorAssetFontOutput = {
    __typename?: "UploadEditorAssetFontOutput";
    font?: Maybe<GqlClientEditorAssetFont>;
    result: GqlClientUploadEditorAssetFontResult;
};

export enum GqlClientUploadEditorAssetFontResult {
    SUCCESS = "SUCCESS"
}

export enum GqlClientUploadEditorAssetFromUrlMediaSource {
    AI_GENERATED = "AI_GENERATED",
    EXTERNAL = "EXTERNAL",
    RECORDER = "RECORDER"
}

export type GqlClientUploadEditorAssetMediaFromUrlInput = {
    aiGeneratedMediaDetails?: InputMaybe<GqlClientAiGeneratedMediaDetails>;
    externalAssetDetails?: InputMaybe<GqlClientExternalAssetDetails>;
    filenameWithoutExtension: Scalars["String"]["input"];
    isInternalSource?: InputMaybe<Scalars["Boolean"]["input"]>;
    mediaSource: GqlClientUploadEditorAssetFromUrlMediaSource;
    originalAsset?: InputMaybe<Scalars["String"]["input"]>;
    parentFolderId?: InputMaybe<Scalars["ID"]["input"]>;
    url: Scalars["String"]["input"];
};

export type GqlClientUploadEditorAssetMediaFromUrlOutput = {
    __typename?: "UploadEditorAssetMediaFromUrlOutput";
    editorAssetMedia?: Maybe<GqlClientEditorAssetMedia>;
    result: GqlClientUploadEditorAssetMediaFromUrlResult;
};

export type GqlClientUploadEditorAssetMediaInput = {
    enhancedProcessingData?: InputMaybe<Scalars["String"]["input"]>;
    file: Scalars["Upload"]["input"];
    fileType: Scalars["String"]["input"];
    filename: Scalars["String"]["input"];
    isInternalSource?: InputMaybe<Scalars["Boolean"]["input"]>;
    mediaOrigin?: InputMaybe<GqlClientMediaOrigin>;
    parentFolderId?: InputMaybe<Scalars["ID"]["input"]>;
    parentMediaId?: InputMaybe<Scalars["ID"]["input"]>;
    postUploadingData?: InputMaybe<GqlClientPostMediaProcessingData>;
    processingData?: InputMaybe<GqlClientImageCroppingData>;
    programId?: InputMaybe<Scalars["ID"]["input"]>;
    saveEditedMediaToLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GqlClientUploadEditorAssetMediaOutput = {
    __typename?: "UploadEditorAssetMediaOutput";
    editorAssetMedia?: Maybe<GqlClientEditorAssetMedia>;
    editorPlaceholder?: Maybe<GqlClientEditorPlaceholder>;
    result: GqlClientUploadEditorAssetMediaResult;
};

export enum GqlClientUploadEditorAssetMediaResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUploadEditorAssetMusicInput = {
    file: Scalars["Upload"]["input"];
    fileType: Scalars["String"]["input"];
    filename: Scalars["String"]["input"];
};

export type GqlClientUploadEditorAssetMusicOutput = {
    __typename?: "UploadEditorAssetMusicOutput";
    editorAssetMusic?: Maybe<GqlClientEditorAssetMusic>;
    result: GqlClientUploadEditorAssetMusicResult;
};

export enum GqlClientUploadEditorAssetMusicResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUploadExternalEditorAssetImageInput = {
    assetId: Scalars["String"]["input"];
    downloadMetadata: Array<GqlClientGettyImageDownloadMetadata>;
    libraryType: GqlClientExternalLibrary;
    parentFolderId?: InputMaybe<Scalars["ID"]["input"]>;
    programId?: InputMaybe<Scalars["ID"]["input"]>;
    subLibraryType?: InputMaybe<GqlClientExternalSubLibrary>;
    title?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUploadExternalEditorAssetMediaOutput = {
    __typename?: "UploadExternalEditorAssetMediaOutput";
    editorAssetMedia?: Maybe<GqlClientEditorAssetMedia>;
    result: GqlClientUploadExternalEditorAssetMediaResult;
};

export enum GqlClientUploadExternalEditorAssetMediaResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUploadExternalEditorAssetVideoInput = {
    assetId: Scalars["String"]["input"];
    downloadMetadata: Array<GqlClientGettyVideoDownloadMetadata>;
    libraryType: Scalars["String"]["input"];
    parentFolderId?: InputMaybe<Scalars["ID"]["input"]>;
    programId?: InputMaybe<Scalars["ID"]["input"]>;
    subLibraryType?: InputMaybe<GqlClientExternalSubLibrary>;
    title?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUploadOptimizedEditorAssetMediaOutput = {
    __typename?: "UploadOptimizedEditorAssetMediaOutput";
    result: GqlClientUploadOptimizedEditorAssetMediaResult;
};

export enum GqlClientUploadOptimizedEditorAssetMediaResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUploadRecordingToS3Input = {
    file: Scalars["Upload"]["input"];
    fileName: Scalars["String"]["input"];
    fileSize: Scalars["Int"]["input"];
    fileType: Scalars["String"]["input"];
};

export type GqlClientUploadRecordingToS3Output = {
    __typename?: "UploadRecordingToS3Output";
    result: GqlClientUploadRecordingToS3Result;
};

export enum GqlClientUploadRecordingToS3Result {
    SUCCESS = "SUCCESS"
}

export type GqlClientUploadStockEditorAssetMusicInput = {
    assetId: Scalars["String"]["input"];
    projectID?: InputMaybe<Scalars["ID"]["input"]>;
    title: Scalars["String"]["input"];
    userID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GqlClientUploadStockEditorAssetMusicOutput = {
    __typename?: "UploadStockEditorAssetMusicOutput";
    editorAssetMusic?: Maybe<GqlClientEditorAssetMusic>;
    result: GqlClientUploadStockEditorAssetMusicResult;
};

export enum GqlClientUploadStockEditorAssetMusicResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpsertHelpCenterEntryInput = {
    articleId: Scalars["String"]["input"];
    buttonId: Scalars["String"]["input"];
    sectionId?: InputMaybe<Scalars["String"]["input"]>;
    variant?: InputMaybe<Scalars["String"]["input"]>;
};

export type GqlClientUpsertHelpCenterEntryOutput = {
    __typename?: "UpsertHelpCenterEntryOutput";
    entry?: Maybe<GqlClientHelpCenterEntry>;
    result?: Maybe<GqlClientUpsertHelpCenterEntryResult>;
};

export enum GqlClientUpsertHelpCenterEntryResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpsertRptvUserOutput = {
    __typename?: "UpsertRPTVUserOutput";
    result?: Maybe<GqlClientUpsertRptvUserResult>;
    user?: Maybe<GqlClientRptvUser>;
};

export enum GqlClientUpsertRptvUserResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpsertUserSettingsInput = {
    acceptedAiTerms?: InputMaybe<Scalars["Boolean"]["input"]>;
    acceptedGenerativeAiTerms?: InputMaybe<Scalars["Boolean"]["input"]>;
    externalLibraries?: InputMaybe<Scalars["JSON"]["input"]>;
    storyTemplates?: InputMaybe<Scalars["JSON"]["input"]>;
    videosArchive?: InputMaybe<Scalars["JSON"]["input"]>;
    yourVideos?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type GqlClientUpsertUserSettingsOutput = {
    __typename?: "UpsertUserSettingsOutput";
    result: GqlClientUpsertUserSettingsResult;
    userSettings: GqlClientUserSettings;
};

export enum GqlClientUpsertUserSettingsResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUsedMediaData = {
    __typename?: "UsedMediaData";
    assetMedia?: Maybe<GqlClientEditorAssetMedia>;
    usedLocations: Array<Scalars["String"]["output"]>;
};

export enum GqlClientUserAccountRole {
    ACCOUNTOWNER = "accountOwner",
    ADSADMIN = "adsAdmin",
    ADSSUNDAYSKYVIEWER = "adsSundayskyViewer",
    ADSUSER = "adsUser",
    ANIMATOR = "animator",
    BUILDEREDITOR = "builderEditor",
    BUILDEROVERVIEW = "builderOverview",
    BUILDERREPORTING = "builderReporting",
    BUILDERVIEWER = "builderViewer",
    FRAMEWORKEDITOR = "frameworkEditor",
    FRAMEWORKVIEWER = "frameworkViewer",
    PLATFORMEDITOR = "platformEditor",
    PLATFORMVIEWER = "platformViewer",
    PROFESSIONALSERVICES = "professionalServices",
    REVIEWER = "reviewer",
    SHAREDFRAMEWORKVIEWER = "sharedFrameworkViewer",
    SHOPIFYSERVER = "shopifyServer",
    STORYTEMPLATESEDITOR = "storyTemplatesEditor"
}

export enum GqlClientUserManagementRole {
    ACCOUNTOWNER = "accountOwner",
    ADSADMIN = "adsAdmin",
    ADSUSER = "adsUser",
    ANIMATOR = "animator",
    BUILDEREDITOR = "builderEditor",
    BUILDEROVERVIEW = "builderOverview",
    BUILDERREPORTING = "builderReporting",
    BUILDERVIEWER = "builderViewer",
    PLATFORMEDITOR = "platformEditor",
    PLATFORMVIEWER = "platformViewer",
    PROFESSIONALSERVICES = "professionalServices",
    REVIEWER = "reviewer"
}

export enum GqlClientUserRole {
    ADMIN = "admin",
    ALLACCOUNTSMANAGER = "allAccountsManager",
    AUTHORINGSERVER = "authoringServer",
    BI = "bi",
    EXPORTACCOUNTDATA = "exportAccountData",
    EXTERNAL = "external",
    HELPCENTEREDITOR = "helpCenterEditor",
    INTERNAL = "internal",
    PERFORMANCETOOLRESULTUPDATE = "performanceToolResultUpdate",
    PRODUCTCREATIVE = "productCreative",
    PROMPTTOKENMANAGER = "promptTokenManager",
    REPORTINGSERVER = "reportingServer",
    REPORTSBYINTERACTIONADMIN = "reportsByInteractionAdmin",
    RESOLVER = "resolver"
}

export type GqlClientUserSettings = {
    __typename?: "UserSettings";
    acceptedAiTerms?: Maybe<Scalars["Boolean"]["output"]>;
    acceptedGenerativeAiTerms?: Maybe<Scalars["Boolean"]["output"]>;
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    externalLibraries: Scalars["JSON"]["output"];
    id: Scalars["ID"]["output"];
    mediaLibraryGridColumns: Scalars["Int"]["output"];
    mediaLibraryViewType: GqlClientLibraryViewType;
    recentSearches: Array<Scalars["String"]["output"]>;
    storyTemplates: Scalars["JSON"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
    userId: Scalars["ID"]["output"];
    videosArchive?: Maybe<Scalars["JSON"]["output"]>;
    yourVideos?: Maybe<Scalars["JSON"]["output"]>;
};

export enum GqlClientVerificationAuthority {
    IAS = "IAS"
}

export type GqlClientVideoCroppingData = {
    flipH: Scalars["Boolean"]["input"];
    flipV: Scalars["Boolean"]["input"];
    height: Scalars["Float"]["input"];
    width: Scalars["Float"]["input"];
    x: Scalars["Float"]["input"];
    y: Scalars["Float"]["input"];
};

export type GqlClientVideoCustomBackgroundInput = {
    /** ID of the editor asset media to use as background. This field is optional, since the mutation can be used only to to toggle the feature on/off, without changing the custom background media */
    customBackgroundMediaId?: InputMaybe<Scalars["ID"]["input"]>;
    editorProgramVersionId: Scalars["ID"]["input"];
    /** Whether to use a custom background or not. This field is optional, since the mutation can be used only to change the custom background media, without toggling it on/off */
    useCustomBackground?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum GqlClientVideoDuration {
    D15 = "D15",
    D30 = "D30",
    D45 = "D45",
    D60 = "D60"
}

export type GqlClientVideoProcessingData = {
    croppingData?: InputMaybe<GqlClientVideoCroppingData>;
    trimmingData?: InputMaybe<GqlClientVideoTrimmingData>;
};

export enum GqlClientVideoQuality {
    HD = "HD",
    HD_READY = "HD_READY",
    HD_READY_HIGH = "HD_READY_HIGH",
    SD = "SD",
    SD_HIGH = "SD_HIGH",
    SD_INTERMEDIATE = "SD_INTERMEDIATE"
}

export type GqlClientVideoTrimmingData = {
    endTimeInSeconds: Scalars["Float"]["input"];
    startTimeInSeconds: Scalars["Float"]["input"];
};

export enum GqlClientViewabilityMode {
    GROUP_M = "GROUP_M",
    IAS = "IAS",
    OFF = "OFF",
    PUBLICIS = "PUBLICIS"
}

export type GqlClientViewerProfile = {
    __typename?: "ViewerProfile";
    accountId: Scalars["ID"]["output"];
    content: Scalars["JSON"]["output"];
    created: Scalars["String"]["output"];
    createdBy?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    program: GqlClientEditorProgram;
    serial: Scalars["Int"]["output"];
    updated: Scalars["String"]["output"];
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type GqlClientCreateEditorSceneWithDataInput = {
    editorSceneData: Scalars["JSON"]["input"];
    lineupIndex: Scalars["Int"]["input"];
};

export type GqlClientCreateEditorSceneWithDataOutput = {
    __typename?: "createEditorSceneWithDataOutput";
    editorScene: GqlClientEditorScene;
    result: GqlClientCreateEditorSceneWithDataResult;
};

export enum GqlClientCreateEditorSceneWithDataResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientTrayServiceAuth = {
    __typename?: "trayServiceAuth";
    authUrl: Scalars["String"]["output"];
};

export type GqlClientTrayTestConnectorAuthenticationOutput = {
    __typename?: "trayTestConnectorAuthenticationOutput";
    authenticated: Scalars["Boolean"]["output"];
};

export type GqlClientUpdateEditorSceneWithDataInput = {
    editorSceneData: Scalars["JSON"]["input"];
};

export type GqlClientUpdateEditorSceneWithDataOutput = {
    __typename?: "updateEditorSceneWithDataOutput";
    editorScene: GqlClientEditorScene;
    result: GqlClientUpdateEditorSceneWithDataResult;
};

export enum GqlClientUpdateEditorSceneWithDataResult {
    SUCCESS = "SUCCESS"
}

export enum GqlClientUploadCustomThumbnailIResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUploadCustomThumbnailInput = {
    editorProgramVersionId: Scalars["ID"]["input"];
    file: Scalars["Upload"]["input"];
    fileName: Scalars["String"]["input"];
    fileType: Scalars["String"]["input"];
};

export type GqlClientUploadCustomThumbnailOutput = {
    __typename?: "uploadCustomThumbnailOutput";
    editorApplication?: Maybe<GqlClientEditorApplication>;
    result: GqlClientUploadCustomThumbnailIResult;
};

export enum GqlClientUploadEditorAssetMediaFromUrlResult {
    SUCCESS = "SUCCESS"
}

export type GqlClientUpdateAccountFeatureDataMutationVariables = Exact<{
    input: GqlClientUpdateAccountFeatureDataInput;
}>;

export type GqlClientUpdateAccountFeatureDataMutation = {
    __typename?: "Mutation";
    updateAccountFeatureData: {
        __typename?: "UpdateAccountFeatureDataOutput";
        result: GqlClientUpdateAccountFeatureDataResult;
        features: Array<{
            __typename?: "AccountFeatureData";
            id: string;
            featureRestrictionValue?: any | null;
            featureTypeDefinition: GqlClientFeatureTypeDefinition;
            featureSource: GqlClientFeatureSource;
            featureDefinition: {
                __typename?: "FeatureDefinition";
                id: string;
                featureTypeDefinition: GqlClientFeatureTypeDefinition;
                featureRestrictionScope: GqlClientFeatureRestrictionScope;
                featureRestrictionType: GqlClientRestrictionType;
                errorMessage: string;
                userControlled: boolean;
            };
        }>;
    };
};

export type GqlClientAccountFeatureDataForMgmtQueryVariables = Exact<{
    accountId: Scalars["String"]["input"];
}>;

export type GqlClientAccountFeatureDataForMgmtQuery = {
    __typename?: "Query";
    accountFeatureDataForMgmt: {
        __typename?: "AccountFeatureDataForMgmt";
        id: string;
        features: Array<{
            __typename?: "AccountFeatureData";
            id: string;
            featureRestrictionValue?: any | null;
            featureTypeDefinition: GqlClientFeatureTypeDefinition;
            featureSource: GqlClientFeatureSource;
            featureDefinition: {
                __typename?: "FeatureDefinition";
                id: string;
                featureTypeDefinition: GqlClientFeatureTypeDefinition;
                featureRestrictionScope: GqlClientFeatureRestrictionScope;
                featureRestrictionType: GqlClientRestrictionType;
                errorMessage: string;
                userControlled: boolean;
            };
        }>;
    };
};

export type GqlClientAccountTierManagementAuditLogQueryVariables = Exact<{
    accountId: Scalars["String"]["input"];
}>;

export type GqlClientAccountTierManagementAuditLogQuery = {
    __typename?: "Query";
    accountTierManagementAuditLog: Array<{
        __typename?: "TierManagementAuditLogRecord";
        id: string;
        actionType: string;
        actionSource: GqlClientTierManagementAuditLogActionSource;
        entityType: GqlClientTierManagementAuditLogEntityType;
        fieldValueBefore?: string | null;
        fieldValueAfter?: string | null;
        tierName?: string | null;
        featureName?: string | null;
        created: string;
        createdBy: string;
    } | null>;
};

export type GqlClientTierAccountDefinitionsQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientTierAccountDefinitionsQuery = {
    __typename?: "Query";
    tierAccountDefinitions: Array<{
        __typename?: "TierAccountDefinition";
        id: string;
        accountId: string;
        accountDisplayName?: string | null;
        tierDefinition: { __typename?: "TierDefinition"; id: string; displayName: string };
    } | null>;
};

export type GqlClientTierFeatureDefinitionsQueryVariables = Exact<{
    tierId: Scalars["ID"]["input"];
}>;

export type GqlClientTierFeatureDefinitionsQuery = {
    __typename?: "Query";
    tierFeatureDefinitions: Array<{
        __typename?: "TierFeatureDefinition";
        id: string;
        featureRestrictionValue?: any | null;
        tierDefinition: { __typename?: "TierDefinition"; id: string; displayName: string };
        featureDefinition: {
            __typename?: "FeatureDefinition";
            id: string;
            featureTypeDefinition: GqlClientFeatureTypeDefinition;
            featureRestrictionScope: GqlClientFeatureRestrictionScope;
            featureRestrictionType: GqlClientRestrictionType;
            errorMessage: string;
            userControlled: boolean;
        };
    } | null>;
};

export type GqlClientCreateAdsAudienceMutationVariables = Exact<{
    data: GqlClientCreateAdsAudienceInput;
}>;

export type GqlClientCreateAdsAudienceMutation = { __typename?: "Mutation"; createAdsAudience: { __typename?: "AdsAudience"; id: string } };

export type GqlClientGetAudienceQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientGetAudienceQuery = {
    __typename?: "Query";
    adsAudience: {
        __typename?: "AdsAudience";
        id: string;
        name?: string | null;
        enabled: boolean;
        priorityIndex?: number | null;
        superGroups?: Array<{
            __typename?: "AudienceRuleSuperGroup";
            id: string;
            matchType?: GqlClientRuleMatchType | null;
            ruleType?: GqlClientRuleType | null;
            ruleGroups?: Array<{
                __typename?: "AudienceRuleGroup";
                id: string;
                minAgeDays?: number | null;
                maxAgeDays?: number | null;
                matchType?: GqlClientRuleMatchType | null;
                rules?: Array<{ __typename?: "AudienceRule"; id: string; name?: string | null; value?: string | null; operator?: GqlClientAudienceRuleOperator | null }> | null;
            }> | null;
        }> | null;
    };
};

export type GqlClientUpdateAdsAudienceMutationVariables = Exact<{
    data: GqlClientUpdateAdsAudienceInput;
}>;

export type GqlClientUpdateAdsAudienceMutation = { __typename?: "Mutation"; updateAdsAudience: { __typename?: "AdsAudience"; id: string } };

export type GqlClientCampaignSettingsFragment = {
    __typename?: "CampaignSettings";
    id: string;
    accountId: number;
    campaignName: string;
    advertiserWebsite?: string | null;
    advertiserCreativeId?: string | null;
    enabled: boolean;
    isCPCV: boolean;
    hourlyFrequencyCap?: number | null;
    dailyFrequencyCap?: number | null;
    weeklyFrequencyCap?: number | null;
    monthlyFrequencyCap?: number | null;
    locationFilters?: Array<string> | null;
    locationFile?: string | null;
    allowThirdPartySegmentsInOptimization?: boolean | null;
    desktopPlayerSizes?: Array<GqlClientPlayerSize> | null;
    mobilePlayerSizes?: Array<GqlClientPlayerSize> | null;
    useCrossDevice?: boolean | null;
    deviceMode?: GqlClientDeviceMode | null;
    allowDesktop?: boolean | null;
    allowMobileApp?: boolean | null;
    allowMobileWeb?: boolean | null;
    desktopMinBudget?: number | null;
    mobileMinBudget?: number | null;
    URLFilter?: string | null;
    keywordsFilter?: string | null;
    appNameFilter?: string | null;
    iabCategoriesFilter?: string | null;
    viewabilityGoal?: number | null;
    minimumViewability?: number | null;
    exchangeBlacklist?: Array<string> | null;
    brandSafetyCustomSegmentsLocation?: string | null;
    brandSafetyMode?: GqlClientBrandSafetyMode | null;
    fraudMode?: GqlClientFraudMode | null;
    verificationAuthority?: GqlClientVerificationAuthority | null;
    viewabilityMode?: GqlClientViewabilityMode | null;
    restrictedContentAlcohol?: boolean | null;
    productCatalogName?: string | null;
    productCatalogSkuTemplate?: string | null;
    controlGroupPercent?: number | null;
    controlGroupMode?: GqlClientAdsTestType | null;
    pixelRulesLocation?: string | null;
    allowPurchased?: boolean | null;
    rulesNewMode?: boolean | null;
    schedulesAndTargets?: Array<{
        __typename?: "Flight";
        id: string;
        impressions?: number | null;
        CPM?: number | null;
        completedViews?: number | null;
        CPCV?: number | null;
        margin: number;
        startDate: string;
        endDate: string;
        insertionOrder: string;
    }> | null;
    audienceDescriptors?: Array<{ __typename?: "AdsAudience"; id: string; name?: string | null; enabled: boolean; priorityIndex?: number | null }> | null;
    productFilters?: Array<{
        __typename?: "ProductFilter";
        id: string;
        type?: GqlClientProductFilterFieldType | null;
        operator?: GqlClientProductFilterOperator | null;
        value?: string | null;
        attName?: string | null;
    }> | null;
    productSelectors?: Array<{ __typename?: "ProductSelector"; id: string; type?: GqlClientProductSelectorFieldType | null; extraData?: string | null; audienceId?: number | null }> | null;
    creativeDefinitions?: Array<{
        __typename?: "CreativeDefinitions";
        id: string;
        storyName: string;
        numSKU: number;
        videoParams: string;
        supportedRatios: Array<GqlClientRatio>;
        videoQualityProfile: GqlClientVideoQuality;
        allowedDurations: Array<GqlClientVideoDuration>;
    }> | null;
    reportingDefinitions?: Array<{ __typename?: "ReportingDefinitions"; id: string; attributeType: string; targetField: string; tagName?: string | null; fieldValue?: string | null }> | null;
};

export type GqlClientCreateCampaignSettingsMutationVariables = Exact<{
    data: GqlClientCreateCampaignSettingsInput;
    programSfId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCreateCampaignSettingsMutation = { __typename?: "Mutation"; createCampaignSettings: { __typename?: "CampaignSettings"; id: string } };

export type GqlClientDownloadAdsFileQueryVariables = Exact<{
    location: Scalars["String"]["input"];
}>;

export type GqlClientDownloadAdsFileQuery = {
    __typename?: "Query";
    downloadAdsFile: { __typename?: "AdsFileResult"; buffer?: any | null; fileName?: string | null; contentType?: string | null; returnCode: GqlClientAdsReturnCode };
};

export type GqlClientDuplicateCampaignSettingsMutationVariables = Exact<{
    data: GqlClientDuplicateCampaignSettingsInput;
}>;

export type GqlClientDuplicateCampaignSettingsMutation = { __typename?: "Mutation"; duplicateCampaignSettings: { __typename?: "CampaignSettings"; id: string } };

export type GqlClientGetAudienceDescriptorsQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientGetAudienceDescriptorsQuery = {
    __typename?: "Query";
    campaignSettings: {
        __typename?: "CampaignSettings";
        id: string;
        audienceDescriptors?: Array<{ __typename?: "AdsAudience"; id: string; name?: string | null; enabled: boolean; priorityIndex?: number | null }> | null;
    };
};

export type GqlClientGetCampaignSettingsQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientGetCampaignSettingsQuery = {
    __typename?: "Query";
    campaignSettings: {
        __typename?: "CampaignSettings";
        id: string;
        accountId: number;
        campaignName: string;
        advertiserWebsite?: string | null;
        advertiserCreativeId?: string | null;
        enabled: boolean;
        isCPCV: boolean;
        hourlyFrequencyCap?: number | null;
        dailyFrequencyCap?: number | null;
        weeklyFrequencyCap?: number | null;
        monthlyFrequencyCap?: number | null;
        locationFilters?: Array<string> | null;
        locationFile?: string | null;
        allowThirdPartySegmentsInOptimization?: boolean | null;
        desktopPlayerSizes?: Array<GqlClientPlayerSize> | null;
        mobilePlayerSizes?: Array<GqlClientPlayerSize> | null;
        useCrossDevice?: boolean | null;
        deviceMode?: GqlClientDeviceMode | null;
        allowDesktop?: boolean | null;
        allowMobileApp?: boolean | null;
        allowMobileWeb?: boolean | null;
        desktopMinBudget?: number | null;
        mobileMinBudget?: number | null;
        URLFilter?: string | null;
        keywordsFilter?: string | null;
        appNameFilter?: string | null;
        iabCategoriesFilter?: string | null;
        viewabilityGoal?: number | null;
        minimumViewability?: number | null;
        exchangeBlacklist?: Array<string> | null;
        brandSafetyCustomSegmentsLocation?: string | null;
        brandSafetyMode?: GqlClientBrandSafetyMode | null;
        fraudMode?: GqlClientFraudMode | null;
        verificationAuthority?: GqlClientVerificationAuthority | null;
        viewabilityMode?: GqlClientViewabilityMode | null;
        restrictedContentAlcohol?: boolean | null;
        productCatalogName?: string | null;
        productCatalogSkuTemplate?: string | null;
        controlGroupPercent?: number | null;
        controlGroupMode?: GqlClientAdsTestType | null;
        pixelRulesLocation?: string | null;
        allowPurchased?: boolean | null;
        rulesNewMode?: boolean | null;
        schedulesAndTargets?: Array<{
            __typename?: "Flight";
            id: string;
            impressions?: number | null;
            CPM?: number | null;
            completedViews?: number | null;
            CPCV?: number | null;
            margin: number;
            startDate: string;
            endDate: string;
            insertionOrder: string;
        }> | null;
        audienceDescriptors?: Array<{ __typename?: "AdsAudience"; id: string; name?: string | null; enabled: boolean; priorityIndex?: number | null }> | null;
        productFilters?: Array<{
            __typename?: "ProductFilter";
            id: string;
            type?: GqlClientProductFilterFieldType | null;
            operator?: GqlClientProductFilterOperator | null;
            value?: string | null;
            attName?: string | null;
        }> | null;
        productSelectors?: Array<{ __typename?: "ProductSelector"; id: string; type?: GqlClientProductSelectorFieldType | null; extraData?: string | null; audienceId?: number | null }> | null;
        creativeDefinitions?: Array<{
            __typename?: "CreativeDefinitions";
            id: string;
            storyName: string;
            numSKU: number;
            videoParams: string;
            supportedRatios: Array<GqlClientRatio>;
            videoQualityProfile: GqlClientVideoQuality;
            allowedDurations: Array<GqlClientVideoDuration>;
        }> | null;
        reportingDefinitions?: Array<{ __typename?: "ReportingDefinitions"; id: string; attributeType: string; targetField: string; tagName?: string | null; fieldValue?: string | null }> | null;
    };
};

export type GqlClientMoveCampaignSettingsMutationVariables = Exact<{
    data: GqlClientMoveCampaignSettingsInput;
}>;

export type GqlClientMoveCampaignSettingsMutation = { __typename?: "Mutation"; moveCampaignSettings: { __typename?: "CampaignSettings"; id: string } };

export type GqlClientUpdateCampaignSettingsMutationVariables = Exact<{
    data: GqlClientUpdateCampaignSettingsInput;
}>;

export type GqlClientUpdateCampaignSettingsMutation = {
    __typename?: "Mutation";
    updateCampaignSettings: {
        __typename?: "CampaignSettings";
        id: string;
        accountId: number;
        campaignName: string;
        advertiserWebsite?: string | null;
        advertiserCreativeId?: string | null;
        enabled: boolean;
        isCPCV: boolean;
        hourlyFrequencyCap?: number | null;
        dailyFrequencyCap?: number | null;
        weeklyFrequencyCap?: number | null;
        monthlyFrequencyCap?: number | null;
        locationFilters?: Array<string> | null;
        locationFile?: string | null;
        allowThirdPartySegmentsInOptimization?: boolean | null;
        desktopPlayerSizes?: Array<GqlClientPlayerSize> | null;
        mobilePlayerSizes?: Array<GqlClientPlayerSize> | null;
        useCrossDevice?: boolean | null;
        deviceMode?: GqlClientDeviceMode | null;
        allowDesktop?: boolean | null;
        allowMobileApp?: boolean | null;
        allowMobileWeb?: boolean | null;
        desktopMinBudget?: number | null;
        mobileMinBudget?: number | null;
        URLFilter?: string | null;
        keywordsFilter?: string | null;
        appNameFilter?: string | null;
        iabCategoriesFilter?: string | null;
        viewabilityGoal?: number | null;
        minimumViewability?: number | null;
        exchangeBlacklist?: Array<string> | null;
        brandSafetyCustomSegmentsLocation?: string | null;
        brandSafetyMode?: GqlClientBrandSafetyMode | null;
        fraudMode?: GqlClientFraudMode | null;
        verificationAuthority?: GqlClientVerificationAuthority | null;
        viewabilityMode?: GqlClientViewabilityMode | null;
        restrictedContentAlcohol?: boolean | null;
        productCatalogName?: string | null;
        productCatalogSkuTemplate?: string | null;
        controlGroupPercent?: number | null;
        controlGroupMode?: GqlClientAdsTestType | null;
        pixelRulesLocation?: string | null;
        allowPurchased?: boolean | null;
        rulesNewMode?: boolean | null;
        schedulesAndTargets?: Array<{
            __typename?: "Flight";
            id: string;
            impressions?: number | null;
            CPM?: number | null;
            completedViews?: number | null;
            CPCV?: number | null;
            margin: number;
            startDate: string;
            endDate: string;
            insertionOrder: string;
        }> | null;
        audienceDescriptors?: Array<{ __typename?: "AdsAudience"; id: string; name?: string | null; enabled: boolean; priorityIndex?: number | null }> | null;
        productFilters?: Array<{
            __typename?: "ProductFilter";
            id: string;
            type?: GqlClientProductFilterFieldType | null;
            operator?: GqlClientProductFilterOperator | null;
            value?: string | null;
            attName?: string | null;
        }> | null;
        productSelectors?: Array<{ __typename?: "ProductSelector"; id: string; type?: GqlClientProductSelectorFieldType | null; extraData?: string | null; audienceId?: number | null }> | null;
        creativeDefinitions?: Array<{
            __typename?: "CreativeDefinitions";
            id: string;
            storyName: string;
            numSKU: number;
            videoParams: string;
            supportedRatios: Array<GqlClientRatio>;
            videoQualityProfile: GqlClientVideoQuality;
            allowedDurations: Array<GqlClientVideoDuration>;
        }> | null;
        reportingDefinitions?: Array<{ __typename?: "ReportingDefinitions"; id: string; attributeType: string; targetField: string; tagName?: string | null; fieldValue?: string | null }> | null;
    };
};

export type GqlClientUpdateCampaignStatusMutationVariables = Exact<{
    data: GqlClientUpdateCampaignStatusInput;
}>;

export type GqlClientUpdateCampaignStatusMutation = { __typename?: "Mutation"; updateCampaignStatus: { __typename?: "CampaignSettings"; id: string; enabled: boolean } };

export type GqlClientUploadAdsFileMutationVariables = Exact<{
    input: GqlClientUploadAdsFileInput;
}>;

export type GqlClientUploadAdsFileMutation = {
    __typename?: "Mutation";
    uploadAdsFile: { __typename?: "UploadAdsFileOutput"; id: string; returnCode: GqlClientAdsReturnCode; location?: string | null };
};

export type GqlClientGetCampaignsAndSfBudgetQueryVariables = Exact<{
    programSfId: Scalars["String"]["input"];
}>;

export type GqlClientGetCampaignsAndSfBudgetQuery = {
    __typename?: "Query";
    campaignsAndSfBudget: {
        __typename?: "GetCampaignsResponse";
        totalSFBudget?: number | null;
        message?: string | null;
        returnCode: GqlClientAdsReturnCode;
        campaigns?: Array<{
            __typename?: "CampaignSettings";
            campaignName: string;
            salesforceId?: string | null;
            id: string;
            accountId: number;
            enabled: boolean;
            isCPCV: boolean;
            viewabilityGoal?: number | null;
            pixelRulesLocation?: string | null;
            schedulesAndTargets?: Array<{
                __typename?: "Flight";
                id: string;
                impressions?: number | null;
                CPM?: number | null;
                completedViews?: number | null;
                CPCV?: number | null;
                insertionOrder: string;
                startDate: string;
                endDate: string;
            }> | null;
        }> | null;
    };
};

export type GqlClientCcAnimatedWireframeWithVersionsFragment = {
    __typename?: "CcAnimatedWireframe";
    id: string;
    updated: string;
    updatedBy?: string | null;
    name: string;
    lifecycleStage: GqlClientAssetLifecycleStage;
    aspectRatio: GqlClientEnumAspectRatio;
    compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
    theme: { __typename?: "CcTheme"; id: string; name: string };
    wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
    animatedWireframeApprovedVersion: {
        __typename?: "CcAnimatedWireframeVersion";
        id: string;
        updated: string;
        updatedBy?: string | null;
        sourceFile?: string | null;
        anyUpdated?: string | null;
        anyUpdatedBy?: string | null;
        snapshotNumber?: number | null;
    };
    animatedWireframeDraftVersion: {
        __typename?: "CcAnimatedWireframeVersion";
        id: string;
        updated: string;
        updatedBy?: string | null;
        sourceFile?: string | null;
        anyUpdated?: string | null;
        anyUpdatedBy?: string | null;
    };
    mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
};

export type GqlClientCcFrameFragment = {
    __typename?: "CcFrame";
    id: string;
    updated: string;
    placeholders: Array<{
        __typename?: "CcPlaceholder";
        id: string;
        name: string;
        updated: string;
        descriptionOrder?: number | null;
        category: GqlClientCcPlaceholderCategory;
        intent: { __typename?: "CcPlaceholderIntent"; id: string; type: GqlClientCcPlaceholderType };
    }>;
};

export type GqlClientCcPlaceholderFragment = {
    __typename?: "CcPlaceholder";
    id: string;
    name: string;
    updated: string;
    descriptionOrder?: number | null;
    category: GqlClientCcPlaceholderCategory;
    intent: { __typename?: "CcPlaceholderIntent"; id: string; type: GqlClientCcPlaceholderType };
};

export type GqlClientCcPlaceholderIntentFragment = { __typename?: "CcPlaceholderIntent"; id: string; name: string; type: GqlClientCcPlaceholderType };

export type GqlClientCcThemeFragment = {
    __typename?: "CcTheme";
    id: string;
    name: string;
    description?: string | null;
    posterUrl?: string | null;
    isComingSoon?: boolean | null;
    editorOrder?: number | null;
    updated: string;
    supportedAspectRatios: Array<GqlClientEnumAspectRatio>;
    lifecycleStage: GqlClientAssetLifecycleStage;
    animatedWireframes: Array<{
        __typename?: "CcAnimatedWireframe";
        id: string;
        updated: string;
        updatedBy?: string | null;
        name: string;
        lifecycleStage: GqlClientAssetLifecycleStage;
        aspectRatio: GqlClientEnumAspectRatio;
        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
        wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
        theme: { __typename?: "CcTheme"; id: string; name: string };
        animatedWireframeApprovedVersion: {
            __typename?: "CcAnimatedWireframeVersion";
            id: string;
            updated: string;
            updatedBy?: string | null;
            sourceFile?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            snapshotNumber?: number | null;
        };
        animatedWireframeDraftVersion: {
            __typename?: "CcAnimatedWireframeVersion";
            id: string;
            updated: string;
            updatedBy?: string | null;
            sourceFile?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
        };
        mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
    }>;
};

export type GqlClientCcVersionFragment = {
    __typename?: "CcLibraryVersion";
    snapshotName?: string | null;
    snapshotComment?: string | null;
    id: string;
    snapshotNumber?: number | null;
    snapshotted?: string | null;
    snapshottedBy?: string | null;
    usedInAccounts: Array<string>;
};

export type GqlClientCcWireframeFragment = {
    __typename?: "CcWireframe";
    id: string;
    name: string;
    updated: string;
    description?: string | null;
    comment?: string | null;
    category: GqlClientCcWireframeCategory;
    usedInSnapshot?: boolean | null;
    lifecycleStage: GqlClientAssetLifecycleStage;
    useToCreateDefaultScene: boolean;
    frames: Array<{
        __typename?: "CcFrame";
        id: string;
        updated: string;
        placeholders: Array<{
            __typename?: "CcPlaceholder";
            id: string;
            name: string;
            updated: string;
            descriptionOrder?: number | null;
            category: GqlClientCcPlaceholderCategory;
            intent: { __typename?: "CcPlaceholderIntent"; id: string; type: GqlClientCcPlaceholderType };
        }>;
    }>;
    animatedWireframes: Array<{
        __typename?: "CcAnimatedWireframe";
        id: string;
        updated: string;
        updatedBy?: string | null;
        name: string;
        lifecycleStage: GqlClientAssetLifecycleStage;
        aspectRatio: GqlClientEnumAspectRatio;
        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
        theme: { __typename?: "CcTheme"; id: string; name: string };
        wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
        animatedWireframeApprovedVersion: {
            __typename?: "CcAnimatedWireframeVersion";
            id: string;
            updated: string;
            updatedBy?: string | null;
            sourceFile?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            snapshotNumber?: number | null;
        };
        animatedWireframeDraftVersion: {
            __typename?: "CcAnimatedWireframeVersion";
            id: string;
            updated: string;
            updatedBy?: string | null;
            sourceFile?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
        };
        mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
    }>;
};

export type GqlClientSetCcActiveSnapshotMutationVariables = Exact<{
    setCcActiveSnapshotInput: GqlClientSetCcActiveSnapshotInput;
}>;

export type GqlClientSetCcActiveSnapshotMutation = {
    __typename?: "Mutation";
    setCcActiveSnapshot:
        | { __typename?: "SetCcActiveSnapshotOutputMigrationFailed"; errors: Array<string>; result: GqlClientSetCcActiveSnapshotResult }
        | {
              __typename?: "SetCcActiveSnapshotOutputSuccessful";
              result: GqlClientSetCcActiveSnapshotResult;
              activeSnapshot?: {
                  __typename?: "CcLibraryVersion";
                  id: string;
                  library: { __typename?: "CcLibrary"; id: string; libraryVersionActive?: { __typename?: "CcLibraryVersion"; id: string } | null };
              } | null;
          };
};

export type GqlClientCreateCcSnapshotMutationVariables = Exact<{
    createCcSnapshotInput: GqlClientCreateCcSnapshotInput;
}>;

export type GqlClientCreateCcSnapshotMutation = {
    __typename?: "Mutation";
    createCcSnapshot: {
        __typename?: "CreateCcSnapshotOutput";
        result: GqlClientCreateCcSnapshotResult;
        editorUpdateErrors: Array<string>;
        snapshot?: {
            __typename?: "CcLibraryVersion";
            id: string;
            library: {
                __typename?: "CcLibrary";
                id: string;
                libraryVersionSnapshots: Array<{
                    __typename?: "CcLibraryVersion";
                    snapshotName?: string | null;
                    snapshotComment?: string | null;
                    id: string;
                    snapshotNumber?: number | null;
                    snapshotted?: string | null;
                    snapshottedBy?: string | null;
                    usedInAccounts: Array<string>;
                }>;
                libraryVersionDraft?: {
                    __typename?: "CcLibraryVersion";
                    id: string;
                    wireframes: Array<{
                        __typename?: "CcWireframe";
                        id: string;
                        name: string;
                        updated: string;
                        description?: string | null;
                        comment?: string | null;
                        category: GqlClientCcWireframeCategory;
                        usedInSnapshot?: boolean | null;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        useToCreateDefaultScene: boolean;
                        frames: Array<{
                            __typename?: "CcFrame";
                            id: string;
                            updated: string;
                            placeholders: Array<{
                                __typename?: "CcPlaceholder";
                                id: string;
                                name: string;
                                updated: string;
                                descriptionOrder?: number | null;
                                category: GqlClientCcPlaceholderCategory;
                                intent: { __typename?: "CcPlaceholderIntent"; id: string; type: GqlClientCcPlaceholderType };
                            }>;
                        }>;
                        animatedWireframes: Array<{
                            __typename?: "CcAnimatedWireframe";
                            id: string;
                            updated: string;
                            updatedBy?: string | null;
                            name: string;
                            lifecycleStage: GqlClientAssetLifecycleStage;
                            aspectRatio: GqlClientEnumAspectRatio;
                            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                            theme: { __typename?: "CcTheme"; id: string; name: string };
                            wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                            animatedWireframeApprovedVersion: {
                                __typename?: "CcAnimatedWireframeVersion";
                                id: string;
                                updated: string;
                                updatedBy?: string | null;
                                sourceFile?: string | null;
                                anyUpdated?: string | null;
                                anyUpdatedBy?: string | null;
                                snapshotNumber?: number | null;
                            };
                            animatedWireframeDraftVersion: {
                                __typename?: "CcAnimatedWireframeVersion";
                                id: string;
                                updated: string;
                                updatedBy?: string | null;
                                sourceFile?: string | null;
                                anyUpdated?: string | null;
                                anyUpdatedBy?: string | null;
                            };
                            mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
                        }>;
                    }>;
                } | null;
            };
        } | null;
    };
};

export type GqlClientCreateCcThemeMutationVariables = Exact<{
    createCcThemeInput: GqlClientCreateCcThemeInput;
}>;

export type GqlClientCreateCcThemeMutation = {
    __typename?: "Mutation";
    createCcTheme: {
        __typename?: "CreateCcThemeOutput";
        result: GqlClientCreateCcThemeResult;
        theme?: {
            __typename?: "CcTheme";
            id: string;
            libraryVersion: {
                __typename?: "CcLibraryVersion";
                id: string;
                themes: Array<{
                    __typename?: "CcTheme";
                    id: string;
                    name: string;
                    description?: string | null;
                    posterUrl?: string | null;
                    isComingSoon?: boolean | null;
                    editorOrder?: number | null;
                    updated: string;
                    supportedAspectRatios: Array<GqlClientEnumAspectRatio>;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    animatedWireframes: Array<{
                        __typename?: "CcAnimatedWireframe";
                        id: string;
                        updated: string;
                        updatedBy?: string | null;
                        name: string;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        aspectRatio: GqlClientEnumAspectRatio;
                        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                        wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                        theme: { __typename?: "CcTheme"; id: string; name: string };
                        animatedWireframeApprovedVersion: {
                            __typename?: "CcAnimatedWireframeVersion";
                            id: string;
                            updated: string;
                            updatedBy?: string | null;
                            sourceFile?: string | null;
                            anyUpdated?: string | null;
                            anyUpdatedBy?: string | null;
                            snapshotNumber?: number | null;
                        };
                        animatedWireframeDraftVersion: {
                            __typename?: "CcAnimatedWireframeVersion";
                            id: string;
                            updated: string;
                            updatedBy?: string | null;
                            sourceFile?: string | null;
                            anyUpdated?: string | null;
                            anyUpdatedBy?: string | null;
                        };
                        mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
                    }>;
                }>;
            };
        } | null;
    };
};

export type GqlClientCreateCcWireframeMutationVariables = Exact<{
    createCcWireframeInput: GqlClientCreateCcWireframeInput;
}>;

export type GqlClientCreateCcWireframeMutation = {
    __typename?: "Mutation";
    createCcWireframe: {
        __typename?: "CreateCcWireframeOutput";
        result: GqlClientCreateCcWireframeResult;
        wireframe?: {
            __typename?: "CcWireframe";
            id: string;
            libraryVersion: {
                __typename?: "CcLibraryVersion";
                id: string;
                wireframes: Array<{
                    __typename?: "CcWireframe";
                    id: string;
                    name: string;
                    updated: string;
                    description?: string | null;
                    comment?: string | null;
                    category: GqlClientCcWireframeCategory;
                    usedInSnapshot?: boolean | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    useToCreateDefaultScene: boolean;
                    frames: Array<{
                        __typename?: "CcFrame";
                        id: string;
                        updated: string;
                        placeholders: Array<{
                            __typename?: "CcPlaceholder";
                            id: string;
                            name: string;
                            updated: string;
                            descriptionOrder?: number | null;
                            category: GqlClientCcPlaceholderCategory;
                            intent: { __typename?: "CcPlaceholderIntent"; id: string; type: GqlClientCcPlaceholderType };
                        }>;
                    }>;
                    animatedWireframes: Array<{
                        __typename?: "CcAnimatedWireframe";
                        id: string;
                        updated: string;
                        updatedBy?: string | null;
                        name: string;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        aspectRatio: GqlClientEnumAspectRatio;
                        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                        theme: { __typename?: "CcTheme"; id: string; name: string };
                        wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                        animatedWireframeApprovedVersion: {
                            __typename?: "CcAnimatedWireframeVersion";
                            id: string;
                            updated: string;
                            updatedBy?: string | null;
                            sourceFile?: string | null;
                            anyUpdated?: string | null;
                            anyUpdatedBy?: string | null;
                            snapshotNumber?: number | null;
                        };
                        animatedWireframeDraftVersion: {
                            __typename?: "CcAnimatedWireframeVersion";
                            id: string;
                            updated: string;
                            updatedBy?: string | null;
                            sourceFile?: string | null;
                            anyUpdated?: string | null;
                            anyUpdatedBy?: string | null;
                        };
                        mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
                    }>;
                }>;
            };
        } | null;
    };
};

export type GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutationVariables = Exact<{
    deleteCcAnimatedWireframeCrossAspectRatioMappingInput: GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingInput;
}>;

export type GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation = {
    __typename?: "Mutation";
    deleteCcAnimatedWireframeCrossAspectRatioMapping: {
        __typename?: "DeleteCcAnimatedWireframeCrossAspectRatioMappingOutput";
        result: GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingResult;
        animatedWireframe?: {
            __typename?: "CcAnimatedWireframe";
            id: string;
            updated: string;
            updatedBy?: string | null;
            name: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            aspectRatio: GqlClientEnumAspectRatio;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            theme: { __typename?: "CcTheme"; id: string; name: string };
            wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
            animatedWireframeApprovedVersion: {
                __typename?: "CcAnimatedWireframeVersion";
                id: string;
                updated: string;
                updatedBy?: string | null;
                sourceFile?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                snapshotNumber?: number | null;
            };
            animatedWireframeDraftVersion: {
                __typename?: "CcAnimatedWireframeVersion";
                id: string;
                updated: string;
                updatedBy?: string | null;
                sourceFile?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
            };
            mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
        } | null;
    };
};

export type GqlClientUpdateCcAnimatedWireframeMutationVariables = Exact<{
    updateCcAnimatedWireframeInput: GqlClientUpdateCcAnimatedWireframeInput;
}>;

export type GqlClientUpdateCcAnimatedWireframeMutation = {
    __typename?: "Mutation";
    updateCcAnimatedWireframe:
        | {
              __typename?: "UpdateCcAnimatedWireframeOutputConflict";
              result: GqlClientUpdateCcAnimatedWireframeResult;
              animatedWireframe?: {
                  __typename?: "CcAnimatedWireframe";
                  id: string;
                  updated: string;
                  updatedBy?: string | null;
                  name: string;
                  lifecycleStage: GqlClientAssetLifecycleStage;
                  aspectRatio: GqlClientEnumAspectRatio;
                  compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                  theme: { __typename?: "CcTheme"; id: string; name: string };
                  wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                  animatedWireframeApprovedVersion: {
                      __typename?: "CcAnimatedWireframeVersion";
                      id: string;
                      updated: string;
                      updatedBy?: string | null;
                      sourceFile?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                      snapshotNumber?: number | null;
                  };
                  animatedWireframeDraftVersion: {
                      __typename?: "CcAnimatedWireframeVersion";
                      id: string;
                      updated: string;
                      updatedBy?: string | null;
                      sourceFile?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                  };
                  mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
              } | null;
          }
        | {
              __typename?: "UpdateCcAnimatedWireframeOutputSuccess";
              result: GqlClientUpdateCcAnimatedWireframeResult;
              animatedWireframe?: {
                  __typename?: "CcAnimatedWireframe";
                  id: string;
                  updated: string;
                  updatedBy?: string | null;
                  name: string;
                  lifecycleStage: GqlClientAssetLifecycleStage;
                  aspectRatio: GqlClientEnumAspectRatio;
                  compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                  libraryVersion: {
                      __typename?: "CcLibraryVersion";
                      id: string;
                      animatedWireframes: Array<{
                          __typename?: "CcAnimatedWireframe";
                          id: string;
                          updated: string;
                          updatedBy?: string | null;
                          name: string;
                          lifecycleStage: GqlClientAssetLifecycleStage;
                          aspectRatio: GqlClientEnumAspectRatio;
                          compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                          theme: { __typename?: "CcTheme"; id: string; name: string };
                          wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                          animatedWireframeApprovedVersion: {
                              __typename?: "CcAnimatedWireframeVersion";
                              id: string;
                              updated: string;
                              updatedBy?: string | null;
                              sourceFile?: string | null;
                              anyUpdated?: string | null;
                              anyUpdatedBy?: string | null;
                              snapshotNumber?: number | null;
                          };
                          animatedWireframeDraftVersion: {
                              __typename?: "CcAnimatedWireframeVersion";
                              id: string;
                              updated: string;
                              updatedBy?: string | null;
                              sourceFile?: string | null;
                              anyUpdated?: string | null;
                              anyUpdatedBy?: string | null;
                          };
                          mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
                      }>;
                  };
                  theme: { __typename?: "CcTheme"; id: string; name: string };
                  wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                  animatedWireframeApprovedVersion: {
                      __typename?: "CcAnimatedWireframeVersion";
                      id: string;
                      updated: string;
                      updatedBy?: string | null;
                      sourceFile?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                      snapshotNumber?: number | null;
                  };
                  animatedWireframeDraftVersion: {
                      __typename?: "CcAnimatedWireframeVersion";
                      id: string;
                      updated: string;
                      updatedBy?: string | null;
                      sourceFile?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                  };
                  mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
              } | null;
          };
};

export type GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutationVariables = Exact<{
    updateCcAnimatedWireframeCrossAspectRatioMappingInput: GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingInput;
}>;

export type GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation = {
    __typename?: "Mutation";
    updateCcAnimatedWireframeCrossAspectRatioMapping: {
        __typename?: "UpdateCcAnimatedWireframeCrossAspectRatioMappingOutput";
        result: GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingResult;
        animatedWireframe?: {
            __typename?: "CcAnimatedWireframe";
            id: string;
            updated: string;
            updatedBy?: string | null;
            name: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            aspectRatio: GqlClientEnumAspectRatio;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            theme: { __typename?: "CcTheme"; id: string; name: string };
            wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
            animatedWireframeApprovedVersion: {
                __typename?: "CcAnimatedWireframeVersion";
                id: string;
                updated: string;
                updatedBy?: string | null;
                sourceFile?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                snapshotNumber?: number | null;
            };
            animatedWireframeDraftVersion: {
                __typename?: "CcAnimatedWireframeVersion";
                id: string;
                updated: string;
                updatedBy?: string | null;
                sourceFile?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
            };
            mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
        } | null;
    };
};

export type GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutationVariables = Exact<{
    updateCcAnimatedWireframeSetApprovedVersionFromDraftInput: GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftInput;
}>;

export type GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation = {
    __typename?: "Mutation";
    updateCcAnimatedWireframeSetApprovedVersionFromDraft: {
        __typename?: "UpdateCcAnimatedWireframeSetApprovedVersionFromDraftOutput";
        result: GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftResult;
        animatedWireframes: Array<{
            __typename?: "CcAnimatedWireframe";
            id: string;
            updated: string;
            updatedBy?: string | null;
            name: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            aspectRatio: GqlClientEnumAspectRatio;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            theme: { __typename?: "CcTheme"; id: string; name: string };
            wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
            animatedWireframeApprovedVersion: {
                __typename?: "CcAnimatedWireframeVersion";
                id: string;
                updated: string;
                updatedBy?: string | null;
                sourceFile?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                snapshotNumber?: number | null;
            };
            animatedWireframeDraftVersion: {
                __typename?: "CcAnimatedWireframeVersion";
                id: string;
                updated: string;
                updatedBy?: string | null;
                sourceFile?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
            };
            mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
        }>;
    };
};

export type GqlClientUpdateCcThemeMutationVariables = Exact<{
    updateCcThemeInput: GqlClientUpdateCcThemeInput;
}>;

export type GqlClientUpdateCcThemeMutation = {
    __typename?: "Mutation";
    updateCcTheme:
        | {
              __typename?: "UpdateCcThemeOutputConflict";
              result: GqlClientUpdateCcThemeResult;
              theme?: {
                  __typename?: "CcTheme";
                  id: string;
                  libraryVersion: {
                      __typename?: "CcLibraryVersion";
                      id: string;
                      themes: Array<{
                          __typename?: "CcTheme";
                          id: string;
                          name: string;
                          description?: string | null;
                          posterUrl?: string | null;
                          isComingSoon?: boolean | null;
                          editorOrder?: number | null;
                          updated: string;
                          supportedAspectRatios: Array<GqlClientEnumAspectRatio>;
                          lifecycleStage: GqlClientAssetLifecycleStage;
                          animatedWireframes: Array<{
                              __typename?: "CcAnimatedWireframe";
                              id: string;
                              updated: string;
                              updatedBy?: string | null;
                              name: string;
                              lifecycleStage: GqlClientAssetLifecycleStage;
                              aspectRatio: GqlClientEnumAspectRatio;
                              compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                              wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                              theme: { __typename?: "CcTheme"; id: string; name: string };
                              animatedWireframeApprovedVersion: {
                                  __typename?: "CcAnimatedWireframeVersion";
                                  id: string;
                                  updated: string;
                                  updatedBy?: string | null;
                                  sourceFile?: string | null;
                                  anyUpdated?: string | null;
                                  anyUpdatedBy?: string | null;
                                  snapshotNumber?: number | null;
                              };
                              animatedWireframeDraftVersion: {
                                  __typename?: "CcAnimatedWireframeVersion";
                                  id: string;
                                  updated: string;
                                  updatedBy?: string | null;
                                  sourceFile?: string | null;
                                  anyUpdated?: string | null;
                                  anyUpdatedBy?: string | null;
                              };
                              mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
                          }>;
                      }>;
                  };
              } | null;
          }
        | {
              __typename?: "UpdateCcThemeOutputSuccess";
              result: GqlClientUpdateCcThemeResult;
              theme?: {
                  __typename?: "CcTheme";
                  id: string;
                  libraryVersion: {
                      __typename?: "CcLibraryVersion";
                      id: string;
                      themes: Array<{
                          __typename?: "CcTheme";
                          id: string;
                          name: string;
                          description?: string | null;
                          posterUrl?: string | null;
                          isComingSoon?: boolean | null;
                          editorOrder?: number | null;
                          updated: string;
                          supportedAspectRatios: Array<GqlClientEnumAspectRatio>;
                          lifecycleStage: GqlClientAssetLifecycleStage;
                          animatedWireframes: Array<{
                              __typename?: "CcAnimatedWireframe";
                              id: string;
                              updated: string;
                              updatedBy?: string | null;
                              name: string;
                              lifecycleStage: GqlClientAssetLifecycleStage;
                              aspectRatio: GqlClientEnumAspectRatio;
                              compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                              wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                              theme: { __typename?: "CcTheme"; id: string; name: string };
                              animatedWireframeApprovedVersion: {
                                  __typename?: "CcAnimatedWireframeVersion";
                                  id: string;
                                  updated: string;
                                  updatedBy?: string | null;
                                  sourceFile?: string | null;
                                  anyUpdated?: string | null;
                                  anyUpdatedBy?: string | null;
                                  snapshotNumber?: number | null;
                              };
                              animatedWireframeDraftVersion: {
                                  __typename?: "CcAnimatedWireframeVersion";
                                  id: string;
                                  updated: string;
                                  updatedBy?: string | null;
                                  sourceFile?: string | null;
                                  anyUpdated?: string | null;
                                  anyUpdatedBy?: string | null;
                              };
                              mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
                          }>;
                      }>;
                  };
              } | null;
          };
};

export type GqlClientUpdateCcWireframeMutationVariables = Exact<{
    updateCcWireframeInput: GqlClientUpdateCcWireframeInput;
}>;

export type GqlClientUpdateCcWireframeMutation = {
    __typename?: "Mutation";
    updateCcWireframe:
        | {
              __typename?: "UpdateCcWireframeOutputConflict";
              result: GqlClientUpdateCcWireframeResult;
              wireframe?: {
                  __typename?: "CcWireframe";
                  id: string;
                  libraryVersion: {
                      __typename?: "CcLibraryVersion";
                      id: string;
                      wireframes: Array<{
                          __typename?: "CcWireframe";
                          id: string;
                          name: string;
                          updated: string;
                          description?: string | null;
                          comment?: string | null;
                          category: GqlClientCcWireframeCategory;
                          usedInSnapshot?: boolean | null;
                          lifecycleStage: GqlClientAssetLifecycleStage;
                          useToCreateDefaultScene: boolean;
                          frames: Array<{
                              __typename?: "CcFrame";
                              id: string;
                              updated: string;
                              placeholders: Array<{
                                  __typename?: "CcPlaceholder";
                                  id: string;
                                  name: string;
                                  updated: string;
                                  descriptionOrder?: number | null;
                                  category: GqlClientCcPlaceholderCategory;
                                  intent: { __typename?: "CcPlaceholderIntent"; id: string; type: GqlClientCcPlaceholderType };
                              }>;
                          }>;
                          animatedWireframes: Array<{
                              __typename?: "CcAnimatedWireframe";
                              id: string;
                              updated: string;
                              updatedBy?: string | null;
                              name: string;
                              lifecycleStage: GqlClientAssetLifecycleStage;
                              aspectRatio: GqlClientEnumAspectRatio;
                              compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                              theme: { __typename?: "CcTheme"; id: string; name: string };
                              wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                              animatedWireframeApprovedVersion: {
                                  __typename?: "CcAnimatedWireframeVersion";
                                  id: string;
                                  updated: string;
                                  updatedBy?: string | null;
                                  sourceFile?: string | null;
                                  anyUpdated?: string | null;
                                  anyUpdatedBy?: string | null;
                                  snapshotNumber?: number | null;
                              };
                              animatedWireframeDraftVersion: {
                                  __typename?: "CcAnimatedWireframeVersion";
                                  id: string;
                                  updated: string;
                                  updatedBy?: string | null;
                                  sourceFile?: string | null;
                                  anyUpdated?: string | null;
                                  anyUpdatedBy?: string | null;
                              };
                              mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
                          }>;
                      }>;
                  };
              } | null;
          }
        | {
              __typename?: "UpdateCcWireframeOutputSuccess";
              result: GqlClientUpdateCcWireframeResult;
              wireframe?: {
                  __typename?: "CcWireframe";
                  id: string;
                  libraryVersion: {
                      __typename?: "CcLibraryVersion";
                      id: string;
                      wireframes: Array<{
                          __typename?: "CcWireframe";
                          id: string;
                          name: string;
                          updated: string;
                          description?: string | null;
                          comment?: string | null;
                          category: GqlClientCcWireframeCategory;
                          usedInSnapshot?: boolean | null;
                          lifecycleStage: GqlClientAssetLifecycleStage;
                          useToCreateDefaultScene: boolean;
                          frames: Array<{
                              __typename?: "CcFrame";
                              id: string;
                              updated: string;
                              placeholders: Array<{
                                  __typename?: "CcPlaceholder";
                                  id: string;
                                  name: string;
                                  updated: string;
                                  descriptionOrder?: number | null;
                                  category: GqlClientCcPlaceholderCategory;
                                  intent: { __typename?: "CcPlaceholderIntent"; id: string; type: GqlClientCcPlaceholderType };
                              }>;
                          }>;
                          animatedWireframes: Array<{
                              __typename?: "CcAnimatedWireframe";
                              id: string;
                              updated: string;
                              updatedBy?: string | null;
                              name: string;
                              lifecycleStage: GqlClientAssetLifecycleStage;
                              aspectRatio: GqlClientEnumAspectRatio;
                              compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                              theme: { __typename?: "CcTheme"; id: string; name: string };
                              wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                              animatedWireframeApprovedVersion: {
                                  __typename?: "CcAnimatedWireframeVersion";
                                  id: string;
                                  updated: string;
                                  updatedBy?: string | null;
                                  sourceFile?: string | null;
                                  anyUpdated?: string | null;
                                  anyUpdatedBy?: string | null;
                                  snapshotNumber?: number | null;
                              };
                              animatedWireframeDraftVersion: {
                                  __typename?: "CcAnimatedWireframeVersion";
                                  id: string;
                                  updated: string;
                                  updatedBy?: string | null;
                                  sourceFile?: string | null;
                                  anyUpdated?: string | null;
                                  anyUpdatedBy?: string | null;
                              };
                              mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
                          }>;
                      }>;
                  };
              } | null;
          };
};

export type GqlClientGetCcAnimatedWireframesQueryVariables = Exact<{
    libraryId: Scalars["ID"]["input"];
}>;

export type GqlClientGetCcAnimatedWireframesQuery = {
    __typename?: "Query";
    ccLibrary?: {
        __typename?: "CcLibrary";
        id: string;
        libraryVersionDraft?: {
            __typename?: "CcLibraryVersion";
            id: string;
            animatedWireframes: Array<{
                __typename?: "CcAnimatedWireframe";
                id: string;
                updated: string;
                updatedBy?: string | null;
                name: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                aspectRatio: GqlClientEnumAspectRatio;
                compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                theme: { __typename?: "CcTheme"; id: string; name: string };
                wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                animatedWireframeApprovedVersion: {
                    __typename?: "CcAnimatedWireframeVersion";
                    id: string;
                    updated: string;
                    updatedBy?: string | null;
                    sourceFile?: string | null;
                    anyUpdated?: string | null;
                    anyUpdatedBy?: string | null;
                    snapshotNumber?: number | null;
                };
                animatedWireframeDraftVersion: {
                    __typename?: "CcAnimatedWireframeVersion";
                    id: string;
                    updated: string;
                    updatedBy?: string | null;
                    sourceFile?: string | null;
                    anyUpdated?: string | null;
                    anyUpdatedBy?: string | null;
                };
                mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
            }>;
        } | null;
    } | null;
};

export type GqlClientGetAnimatedWireframesAndFilterInfoQueryVariables = Exact<{
    libraryId: Scalars["ID"]["input"];
}>;

export type GqlClientGetAnimatedWireframesAndFilterInfoQuery = {
    __typename?: "Query";
    ccLibrary?: {
        __typename?: "CcLibrary";
        id: string;
        libraryVersionDraft?: {
            __typename?: "CcLibraryVersion";
            id: string;
            themes: Array<{ __typename?: "CcTheme"; id: string; name: string }>;
            wireframes: Array<{ __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage; comment?: string | null }>;
            animatedWireframes: Array<{
                __typename?: "CcAnimatedWireframe";
                id: string;
                updated: string;
                updatedBy?: string | null;
                name: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                aspectRatio: GqlClientEnumAspectRatio;
                compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                theme: { __typename?: "CcTheme"; id: string; name: string };
                wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                animatedWireframeApprovedVersion: {
                    __typename?: "CcAnimatedWireframeVersion";
                    id: string;
                    updated: string;
                    updatedBy?: string | null;
                    sourceFile?: string | null;
                    anyUpdated?: string | null;
                    anyUpdatedBy?: string | null;
                    snapshotNumber?: number | null;
                };
                animatedWireframeDraftVersion: {
                    __typename?: "CcAnimatedWireframeVersion";
                    id: string;
                    updated: string;
                    updatedBy?: string | null;
                    sourceFile?: string | null;
                    anyUpdated?: string | null;
                    anyUpdatedBy?: string | null;
                };
                mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
            }>;
        } | null;
    } | null;
};

export type GqlClientGetCcThemesQueryVariables = Exact<{
    libraryId: Scalars["ID"]["input"];
}>;

export type GqlClientGetCcThemesQuery = {
    __typename?: "Query";
    ccLibrary?: {
        __typename?: "CcLibrary";
        id: string;
        libraryVersionDraft?: {
            __typename?: "CcLibraryVersion";
            id: string;
            themes: Array<{
                __typename?: "CcTheme";
                id: string;
                name: string;
                description?: string | null;
                posterUrl?: string | null;
                isComingSoon?: boolean | null;
                editorOrder?: number | null;
                updated: string;
                supportedAspectRatios: Array<GqlClientEnumAspectRatio>;
                lifecycleStage: GqlClientAssetLifecycleStage;
                animatedWireframes: Array<{
                    __typename?: "CcAnimatedWireframe";
                    id: string;
                    updated: string;
                    updatedBy?: string | null;
                    name: string;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    aspectRatio: GqlClientEnumAspectRatio;
                    compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                    wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                    theme: { __typename?: "CcTheme"; id: string; name: string };
                    animatedWireframeApprovedVersion: {
                        __typename?: "CcAnimatedWireframeVersion";
                        id: string;
                        updated: string;
                        updatedBy?: string | null;
                        sourceFile?: string | null;
                        anyUpdated?: string | null;
                        anyUpdatedBy?: string | null;
                        snapshotNumber?: number | null;
                    };
                    animatedWireframeDraftVersion: {
                        __typename?: "CcAnimatedWireframeVersion";
                        id: string;
                        updated: string;
                        updatedBy?: string | null;
                        sourceFile?: string | null;
                        anyUpdated?: string | null;
                        anyUpdatedBy?: string | null;
                    };
                    mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
                }>;
            }>;
        } | null;
    } | null;
};

export type GqlClientGetCcVersionsQueryVariables = Exact<{
    libraryId: Scalars["ID"]["input"];
}>;

export type GqlClientGetCcVersionsQuery = {
    __typename?: "Query";
    ccLibrary?: {
        __typename?: "CcLibrary";
        id: string;
        libraryVersionSnapshots: Array<{
            __typename?: "CcLibraryVersion";
            snapshotName?: string | null;
            snapshotComment?: string | null;
            id: string;
            snapshotNumber?: number | null;
            snapshotted?: string | null;
            snapshottedBy?: string | null;
            usedInAccounts: Array<string>;
        }>;
        libraryVersionActive?: { __typename?: "CcLibraryVersion"; id: string } | null;
        libraryVersionDraft?: { __typename?: "CcLibraryVersion"; id: string } | null;
    } | null;
};

export type GqlClientGetCcWireframesQueryVariables = Exact<{
    libraryId: Scalars["ID"]["input"];
}>;

export type GqlClientGetCcWireframesQuery = {
    __typename?: "Query";
    ccLibrary?: {
        __typename?: "CcLibrary";
        id: string;
        libraryVersionDraft?: {
            __typename?: "CcLibraryVersion";
            id: string;
            wireframes: Array<{
                __typename?: "CcWireframe";
                id: string;
                name: string;
                updated: string;
                description?: string | null;
                comment?: string | null;
                category: GqlClientCcWireframeCategory;
                usedInSnapshot?: boolean | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                useToCreateDefaultScene: boolean;
                frames: Array<{
                    __typename?: "CcFrame";
                    id: string;
                    updated: string;
                    placeholders: Array<{
                        __typename?: "CcPlaceholder";
                        id: string;
                        name: string;
                        updated: string;
                        descriptionOrder?: number | null;
                        category: GqlClientCcPlaceholderCategory;
                        intent: { __typename?: "CcPlaceholderIntent"; id: string; type: GqlClientCcPlaceholderType };
                    }>;
                }>;
                animatedWireframes: Array<{
                    __typename?: "CcAnimatedWireframe";
                    id: string;
                    updated: string;
                    updatedBy?: string | null;
                    name: string;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    aspectRatio: GqlClientEnumAspectRatio;
                    compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                    theme: { __typename?: "CcTheme"; id: string; name: string };
                    wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
                    animatedWireframeApprovedVersion: {
                        __typename?: "CcAnimatedWireframeVersion";
                        id: string;
                        updated: string;
                        updatedBy?: string | null;
                        sourceFile?: string | null;
                        anyUpdated?: string | null;
                        anyUpdatedBy?: string | null;
                        snapshotNumber?: number | null;
                    };
                    animatedWireframeDraftVersion: {
                        __typename?: "CcAnimatedWireframeVersion";
                        id: string;
                        updated: string;
                        updatedBy?: string | null;
                        sourceFile?: string | null;
                        anyUpdated?: string | null;
                        anyUpdatedBy?: string | null;
                    };
                    mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
                }>;
            }>;
            placeholderIntents: Array<{ __typename?: "CcPlaceholderIntent"; id: string; name: string; type: GqlClientCcPlaceholderType }>;
        } | null;
    } | null;
};

export type GqlClientAccountCcLibraryVersionChangedSubscriptionVariables = Exact<{
    libraryId: Scalars["ID"]["input"];
}>;

export type GqlClientAccountCcLibraryVersionChangedSubscription = {
    __typename?: "Subscription";
    accountCcLibraryVersionChanged?: {
        __typename?: "CcLibraryVersion";
        snapshotName?: string | null;
        snapshotComment?: string | null;
        id: string;
        snapshotNumber?: number | null;
        snapshotted?: string | null;
        snapshottedBy?: string | null;
        usedInAccounts: Array<string>;
    } | null;
};

export type GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutationVariables = Exact<{
    updateCcAnimatedWireframeSetDraftVersionFromApprovedInput: GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedInput;
}>;

export type GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation = {
    __typename?: "Mutation";
    updateCcAnimatedWireframeSetDraftVersionFromApproved: {
        __typename?: "UpdateCcAnimatedWireframeSetDraftVersionFromApprovedOutput";
        result: GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedResult;
        animatedWireframes: Array<{
            __typename?: "CcAnimatedWireframe";
            id: string;
            updated: string;
            updatedBy?: string | null;
            name: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            aspectRatio: GqlClientEnumAspectRatio;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            theme: { __typename?: "CcTheme"; id: string; name: string };
            wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage };
            animatedWireframeApprovedVersion: {
                __typename?: "CcAnimatedWireframeVersion";
                id: string;
                updated: string;
                updatedBy?: string | null;
                sourceFile?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                snapshotNumber?: number | null;
            };
            animatedWireframeDraftVersion: {
                __typename?: "CcAnimatedWireframeVersion";
                id: string;
                updated: string;
                updatedBy?: string | null;
                sourceFile?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
            };
            mappedAnimatedWireframes: Array<{ __typename?: "CcAnimatedWireframe"; id: string; name: string; aspectRatio: GqlClientEnumAspectRatio }>;
        }>;
    };
};

export type GqlClientEmbedVideoCodeFragment = {
    __typename?: "EmbedVideoCode";
    id: string;
    updated: string;
    updatedBy?: string | null;
    created: string;
    createdBy?: string | null;
    configuration?: any | null;
    updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
};

export type GqlClientLandingPageFragment = {
    __typename?: "LandingPage";
    id: string;
    updated: string;
    updatedBy?: string | null;
    created: string;
    createdBy?: string | null;
    configuration?: any | null;
    isActive?: boolean | null;
    lifecycleStage?: GqlClientAssetLifecycleStage | null;
    updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
};

export type GqlClientAccountCopilotBriefFragment = { __typename?: "AccountCopilotBrief"; id: string; name: string; briefData: any; createdBy: string; created: string };

export type GqlClientAccountDataFieldFragment = { __typename?: "AccountDataField"; id: string; localId: string; data: any };

export type GqlClientAccountDataLibraryForImportDataFragment = {
    __typename?: "AccountDataLibrary";
    id: string;
    name: string;
    publishedVideosUsingLibrary: Array<{
        __typename?: "EditorProgram";
        id: string;
        name: string;
        prodVersion?: {
            __typename?: "LifecycleHistoryEntry";
            id: string;
            created: string;
            editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null; thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null } | null;
        } | null;
    }>;
};

export type GqlClientAccountDataLibraryFragment = {
    __typename?: "AccountDataLibrary";
    id: string;
    name: string;
    description?: string | null;
    anyUpdated: string;
    anyUpdatedBy?: string | null;
    draftAccountDataLibraryVersion: {
        __typename?: "AccountDataLibraryVersion";
        id: string;
        localId: string;
        data: any;
        anyUpdated: string;
        anyUpdatedBy?: string | null;
        usedInVideos: Array<{
            __typename?: "EditorProgram";
            id: string;
            name: string;
            isArchive?: boolean | null;
            isLive?: boolean | null;
            programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
            prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
            uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
            shareHistory: Array<{
                __typename?: "ProgramShareHistory";
                id: string;
                created: string;
                createdBy?: string | null;
                accountId: string;
                shareType: GqlClientProgramShareType;
                isExternal?: boolean | null;
            }>;
        }>;
        dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
        accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
    };
};

export type GqlClientAccountDataLibraryPublishedFragment = {
    __typename?: "AccountDataLibrary";
    id: string;
    name: string;
    publishedVideosTargetLibrary: Array<{
        __typename?: "EditorProgram";
        id: string;
        name: string;
        prodVersion?: {
            __typename?: "LifecycleHistoryEntry";
            id: string;
            created: string;
            editorProgramVersion?: {
                __typename?: "EditorProgramVersion";
                id: string;
                accountDataLibraryVersion?: { __typename?: "AccountDataLibraryVersion"; id: string; data: any } | null;
                thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
            } | null;
        } | null;
        shareHistory: Array<{
            __typename?: "ProgramShareHistory";
            id: string;
            created: string;
            createdBy?: string | null;
            accountId: string;
            shareType: GqlClientProgramShareType;
            isExternal?: boolean | null;
        }>;
    }>;
    publishedVideosUsingLibrary: Array<{
        __typename?: "EditorProgram";
        id: string;
        name: string;
        prodVersion?: {
            __typename?: "LifecycleHistoryEntry";
            id: string;
            created: string;
            editorProgramVersion?: {
                __typename?: "EditorProgramVersion";
                id: string;
                usedElements?: any | null;
                accountDataLibraryVersion?: { __typename?: "AccountDataLibraryVersion"; id: string; data: any } | null;
                thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
            } | null;
        } | null;
        shareHistory: Array<{
            __typename?: "ProgramShareHistory";
            id: string;
            created: string;
            createdBy?: string | null;
            accountId: string;
            shareType: GqlClientProgramShareType;
            isExternal?: boolean | null;
        }>;
    }>;
};

export type GqlClientAccountDataLibraryVersionFragment = {
    __typename?: "AccountDataLibraryVersion";
    id: string;
    localId: string;
    data: any;
    anyUpdated: string;
    anyUpdatedBy?: string | null;
    usedInVideos: Array<{
        __typename?: "EditorProgram";
        id: string;
        name: string;
        isArchive?: boolean | null;
        isLive?: boolean | null;
        programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
        prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
        uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
        shareHistory: Array<{
            __typename?: "ProgramShareHistory";
            id: string;
            created: string;
            createdBy?: string | null;
            accountId: string;
            shareType: GqlClientProgramShareType;
            isExternal?: boolean | null;
        }>;
    }>;
    dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
    accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
};

export type GqlClientAccountPronunciationDefinitionFragment = { __typename?: "AccountPronunciationDefinition"; id: string; source: string; target: string };

export type GqlClientAcquiaDamAssetMediaFragment = {
    __typename?: "AcquiaAssetMedia";
    id: string;
    name: string;
    type: GqlClientAcquiaAssetType;
    created?: string | null;
    updated?: string | null;
    mediaUrl: string;
    downloadUri: string;
    thumbnailUri: string;
    largeThumbnailUrl?: string | null;
    mediaMetadata: { __typename?: "AcquiaAssetMetadata"; format: string; sizeInKb: number; width: number; height: number; duration?: number | null };
};

export type GqlClientAcquiaDamCategoryFragment = { __typename?: "AcquiaCategory"; id: string; name: string; path: string; parentCategoryPath?: string | null };

export type GqlClientCcWireframeAllWireframesMappingFragment = {
    __typename?: "CcWireframeAllWireframesData";
    isLosingData: boolean;
    wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage; comment?: string | null; category: GqlClientCcWireframeCategory };
    placeholdersData: Array<{
        __typename?: "CcWireframePlaceholderData";
        targetCcPlaceholder: {
            __typename?: "CcPlaceholder";
            id: string;
            name: string;
            content?: any | null;
            category: GqlClientCcPlaceholderCategory;
            intent: {
                __typename?: "CcPlaceholderIntent";
                id: string;
                name: string;
                type: GqlClientCcPlaceholderType;
                defaultValue?: string | null;
                textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
            };
        };
        originalCcPlaceholder?: { __typename?: "CcPlaceholder"; id: string } | null;
    } | null>;
};

export type GqlClientEditorAnimatedWireframeFragment = {
    __typename?: "CcAnimatedWireframe";
    id: string;
    name: string;
    vsmlUrl: string;
    lifecycleStage: GqlClientAssetLifecycleStage;
    aspectRatio: GqlClientEnumAspectRatio;
    compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
    defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
    theme: { __typename?: "CcTheme"; id: string };
    placeholdersSettings: Array<
        | {
              __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
              id: string;
              horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
              verticalAlignment?: GqlClientEnumVerticalAlignment | null;
              mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
              canvasName?: string | null;
              ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
          }
        | {
              __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
              id: string;
              horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
              verticalAlignment?: GqlClientEnumVerticalAlignment | null;
              canvasName?: string | null;
              colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
              ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
          }
    >;
    m2AnimatedWireframe?: {
        __typename?: "CcAnimatedWireframe";
        id: string;
        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
        vsmlUrl: string;
        defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
    } | null;
    composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
};

export type GqlClientEditorAssetFolderFragment = {
    __typename?: "EditorAssetFolder";
    id: string;
    name: string;
    created: string;
    createdBy?: string | null;
    updated: string;
    updatedBy?: string | null;
    parentFolder?: { __typename?: "EditorAssetFolder"; id: string } | null;
};

export type GqlClientEditorAssetLibraryAvatarFragment = {
    __typename?: "EditorAccountLevelAvatar";
    id: string;
    localId: string;
    name: string;
    description?: string | null;
    avatarSystemName: string;
    thumbnailUrl?: string | null;
    sampleUrl?: string | null;
    lifecycleStage: GqlClientAssetLifecycleStage;
    lifecycleMessage?: string | null;
    created: string;
    sampleVideoMetadata?: any | null;
    fullBodyCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
    closeUpCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
    narrator?: {
        __typename?: "EditorAccountLevelNarrator";
        id: string;
        localId: string;
        name: string;
        language?: string | null;
        description?: string | null;
        sampleUrl?: string | null;
        sampleTranscript?: string | null;
        narrationSystemName?: string | null;
        narrationSystemVoice?: string | null;
        narrationSystemMetadata?: any | null;
        lifecycleStage: GqlClientAssetLifecycleStage;
    } | null;
};

export type GqlClientEditorAssetLibraryFontFragment = {
    __typename?: "EditorAssetFont";
    id: string;
    name: string;
    url?: string | null;
    imageUrl?: string | null;
    webFontUrl?: string | null;
    hasSmallCaps?: boolean | null;
    lifecycleStage?: GqlClientAssetLifecycleStage | null;
    uploadId?: string | null;
    created: string;
    familyName?: string | null;
    isBold?: boolean | null;
    isItalic?: boolean | null;
    weight?: number | null;
};

export type GqlClientEditorAssetLibraryMediaFragment = {
    __typename?: "EditorAssetMedia";
    id: string;
    name: string;
    created: string;
    updated: string;
    mediaLocation: string;
    mediaUrl: string;
    mediaPreviewUrl: string;
    mediaAgifUrl?: string | null;
    mediaOrigin: GqlClientMediaOrigin;
    mediaMetadata: any;
    mediaProcessingInfo?: any | null;
    mediaFramesThumbnailsUrl?: string | null;
    originalEditInformation?: any | null;
    originalMediaId?: string | null;
    lifecycleStage: GqlClientAssetLifecycleStage;
    mediaSri?: string | null;
    externalLibraryAssetId?: string | null;
    externalLibrary?: GqlClientExternalLibrary | null;
    externalSublibrary?: GqlClientExternalSubLibrary | null;
    directAncestorId?: string | null;
    mediaClassification: GqlClientMediaClassification;
    description?: string | null;
    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
    folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
};

export type GqlClientEditorAssetLibraryMusicFragment = {
    __typename?: "EditorAssetMusic";
    id: string;
    name: string;
    url: string;
    lifecycleStage: GqlClientAssetLifecycleStage;
    externalLibraryAssetId?: string | null;
    musicOrigin: GqlClientMusicOrigin;
};

export type GqlClientEditorBrandColorFragment = {
    __typename?: "BcColor";
    id: string;
    updated: string;
    color: string;
    ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
};

export type GqlClientEditorBrandFragment = {
    __typename?: "BrandConfigurationCollection";
    id: string;
    localId: string;
    updated: string;
    updatedBy?: string | null;
    created: string;
    name: string;
    description?: string | null;
    logoUrl?: string | null;
    logoLocation?: string | null;
    buttonShape: GqlClientButtonShapeValues;
    logoMedia?: {
        __typename?: "EditorAssetMedia";
        id: string;
        name: string;
        created: string;
        updated: string;
        mediaLocation: string;
        mediaUrl: string;
        mediaPreviewUrl: string;
        mediaAgifUrl?: string | null;
        mediaOrigin: GqlClientMediaOrigin;
        mediaMetadata: any;
        mediaProcessingInfo?: any | null;
        mediaFramesThumbnailsUrl?: string | null;
        originalEditInformation?: any | null;
        originalMediaId?: string | null;
        lifecycleStage: GqlClientAssetLifecycleStage;
        mediaSri?: string | null;
        externalLibraryAssetId?: string | null;
        externalLibrary?: GqlClientExternalLibrary | null;
        externalSublibrary?: GqlClientExternalSubLibrary | null;
        directAncestorId?: string | null;
        mediaClassification: GqlClientMediaClassification;
        description?: string | null;
        createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
        folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
    } | null;
    colors: Array<{
        __typename?: "BcColor";
        id: string;
        updated: string;
        color: string;
        ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
    }>;
    textStyles: Array<{
        __typename?: "BcTextStyle";
        id: string;
        fontSource: GqlClientEditorAssetSource;
        letterCase: GqlClientTextStyleLetterCase;
        letterSpacing: number;
        lineSpacing: number;
        updated: string;
        ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
        ccFont?: {
            __typename?: "CcFont";
            id: string;
            name: string;
            url: string;
            hasSmallCaps?: boolean | null;
            lifecycleStage?: GqlClientAssetLifecycleStage | null;
            webFontUrl?: string | null;
            created: string;
            familyName?: string | null;
            isBold?: boolean | null;
            isItalic?: boolean | null;
            weight?: number | null;
        } | null;
        accountFont?: {
            __typename?: "EditorAssetFont";
            id: string;
            name: string;
            url?: string | null;
            imageUrl?: string | null;
            webFontUrl?: string | null;
            hasSmallCaps?: boolean | null;
            lifecycleStage?: GqlClientAssetLifecycleStage | null;
            uploadId?: string | null;
            created: string;
            familyName?: string | null;
            isBold?: boolean | null;
            isItalic?: boolean | null;
            weight?: number | null;
        } | null;
    }>;
};

export type GqlClientEditorBrandTextStyleFragment = {
    __typename?: "BcTextStyle";
    id: string;
    fontSource: GqlClientEditorAssetSource;
    letterCase: GqlClientTextStyleLetterCase;
    letterSpacing: number;
    lineSpacing: number;
    updated: string;
    ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
    ccFont?: {
        __typename?: "CcFont";
        id: string;
        name: string;
        url: string;
        hasSmallCaps?: boolean | null;
        lifecycleStage?: GqlClientAssetLifecycleStage | null;
        webFontUrl?: string | null;
        created: string;
        familyName?: string | null;
        isBold?: boolean | null;
        isItalic?: boolean | null;
        weight?: number | null;
    } | null;
    accountFont?: {
        __typename?: "EditorAssetFont";
        id: string;
        name: string;
        url?: string | null;
        imageUrl?: string | null;
        webFontUrl?: string | null;
        hasSmallCaps?: boolean | null;
        lifecycleStage?: GqlClientAssetLifecycleStage | null;
        uploadId?: string | null;
        created: string;
        familyName?: string | null;
        isBold?: boolean | null;
        isItalic?: boolean | null;
        weight?: number | null;
    } | null;
};

export type GqlClientEditorCcLibraryVersionFragment = {
    __typename?: "CcLibraryVersion";
    id: string;
    themes: Array<{
        __typename?: "CcTheme";
        id: string;
        name: string;
        posterUrl?: string | null;
        isComingSoon?: boolean | null;
        editorOrder?: number | null;
        lifecycleStage: GqlClientAssetLifecycleStage;
        supportedAspectRatios: Array<GqlClientEnumAspectRatio>;
        supportsBackgroundMedia: boolean;
        dofValues: Array<{
            __typename?: "CcDofValue";
            id: string;
            value: string;
            dof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
        }>;
    }>;
    wireframes: Array<{
        __typename?: "CcWireframe";
        id: string;
        name: string;
        description?: string | null;
        lifecycleStage: GqlClientAssetLifecycleStage;
        comment?: string | null;
        category: GqlClientCcWireframeCategory;
        frames: Array<{
            __typename?: "CcFrame";
            id: string;
            placeholders: Array<{
                __typename?: "CcPlaceholder";
                id: string;
                name: string;
                content?: any | null;
                category: GqlClientCcPlaceholderCategory;
                intent: {
                    __typename?: "CcPlaceholderIntent";
                    id: string;
                    name: string;
                    type: GqlClientCcPlaceholderType;
                    defaultValue?: string | null;
                    textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                };
            }>;
        }>;
        animatedWireframes: Array<{
            __typename?: "CcAnimatedWireframe";
            id: string;
            name: string;
            vsmlUrl: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            aspectRatio: GqlClientEnumAspectRatio;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
            theme: { __typename?: "CcTheme"; id: string };
            placeholdersSettings: Array<
                | {
                      __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                      id: string;
                      horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                      verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                      mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                      canvasName?: string | null;
                      ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                  }
                | {
                      __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                      id: string;
                      horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                      verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                      canvasName?: string | null;
                      colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                      ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                  }
            >;
            m2AnimatedWireframe?: {
                __typename?: "CcAnimatedWireframe";
                id: string;
                compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                vsmlUrl: string;
                defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
            } | null;
            composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
        }>;
    }>;
    music: Array<{ __typename?: "CcMusic"; id: string; name: string; url: string }>;
    narrators: Array<{
        __typename?: "CcNarrator";
        id: string;
        name: string;
        sampleUrl: string;
        narrationSystemName: string;
        narrationSystemVoice: string;
        intent: GqlClientCcNarratorIntent;
        narrationSystemMetadata: {
            __typename?: "CcNarrationSystemMetadata";
            language: string;
            gender: string;
            rate?: number | null;
            volume?: number | null;
            age?: string | null;
            description?: Array<string> | null;
        };
    }>;
    avatars: Array<{
        __typename?: "CcAvatar";
        id: string;
        name: string;
        sampleUrl: string;
        thumbnailUrl: string;
        localId: string;
        lifecycleStage: GqlClientAssetLifecycleStage;
        sampleVideoMetadata: any;
        narrator?: {
            __typename?: "CcNarrator";
            id: string;
            name: string;
            sampleUrl: string;
            narrationSystemName: string;
            narrationSystemVoice: string;
            intent: GqlClientCcNarratorIntent;
            narrationSystemMetadata: {
                __typename?: "CcNarrationSystemMetadata";
                language: string;
                gender: string;
                rate?: number | null;
                volume?: number | null;
                age?: string | null;
                description?: Array<string> | null;
            };
        } | null;
        fullBodyCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
        closeUpCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
    }>;
    fonts: Array<{
        __typename?: "CcFont";
        id: string;
        name: string;
        url: string;
        hasSmallCaps?: boolean | null;
        lifecycleStage?: GqlClientAssetLifecycleStage | null;
        webFontUrl?: string | null;
        created: string;
        familyName?: string | null;
        isBold?: boolean | null;
        isItalic?: boolean | null;
        weight?: number | null;
    }>;
    textStyles: Array<{ __typename?: "CcTextStyle"; id: string; name: string }>;
    placeholderIntents: Array<{
        __typename?: "CcPlaceholderIntent";
        id: string;
        name: string;
        type: GqlClientCcPlaceholderType;
        defaultValue?: string | null;
        textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
    }>;
};

export type GqlClientEditorLibraryAvatarFragment = {
    __typename?: "CcAvatar";
    id: string;
    name: string;
    sampleUrl: string;
    thumbnailUrl: string;
    localId: string;
    lifecycleStage: GqlClientAssetLifecycleStage;
    sampleVideoMetadata: any;
    narrator?: {
        __typename?: "CcNarrator";
        id: string;
        name: string;
        sampleUrl: string;
        narrationSystemName: string;
        narrationSystemVoice: string;
        intent: GqlClientCcNarratorIntent;
        narrationSystemMetadata: {
            __typename?: "CcNarrationSystemMetadata";
            language: string;
            gender: string;
            rate?: number | null;
            volume?: number | null;
            age?: string | null;
            description?: Array<string> | null;
        };
    } | null;
    fullBodyCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
    closeUpCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
};

export type GqlClientEditorLibraryDofFragment = { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };

export type GqlClientEditorLibraryDofValueFragment = {
    __typename?: "CcDofValue";
    id: string;
    value: string;
    dof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
};

export type GqlClientEditorLibraryFontFragment = {
    __typename?: "CcFont";
    id: string;
    name: string;
    url: string;
    hasSmallCaps?: boolean | null;
    lifecycleStage?: GqlClientAssetLifecycleStage | null;
    webFontUrl?: string | null;
    created: string;
    familyName?: string | null;
    isBold?: boolean | null;
    isItalic?: boolean | null;
    weight?: number | null;
};

export type GqlClientEditorLibraryMusicFragment = { __typename?: "CcMusic"; id: string; name: string; url: string };

export type GqlClientEditorLibraryNarratorFragment = {
    __typename?: "CcNarrator";
    id: string;
    name: string;
    sampleUrl: string;
    narrationSystemName: string;
    narrationSystemVoice: string;
    intent: GqlClientCcNarratorIntent;
    narrationSystemMetadata: {
        __typename?: "CcNarrationSystemMetadata";
        language: string;
        gender: string;
        rate?: number | null;
        volume?: number | null;
        age?: string | null;
        description?: Array<string> | null;
    };
};

export type GqlClientEditorLibraryPlaceholderIntentFragment = {
    __typename?: "CcPlaceholderIntent";
    id: string;
    name: string;
    type: GqlClientCcPlaceholderType;
    defaultValue?: string | null;
    textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
};

export type GqlClientEditorLibraryTextStyleFragment = { __typename?: "CcTextStyle"; id: string; name: string };

export type GqlClientEditorLibraryThemeFragment = {
    __typename?: "CcTheme";
    id: string;
    name: string;
    posterUrl?: string | null;
    isComingSoon?: boolean | null;
    editorOrder?: number | null;
    lifecycleStage: GqlClientAssetLifecycleStage;
    supportedAspectRatios: Array<GqlClientEnumAspectRatio>;
    supportsBackgroundMedia: boolean;
    dofValues: Array<{ __typename?: "CcDofValue"; id: string; value: string; dof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } }>;
};

export type GqlClientEditorLibraryWireframeFragment = {
    __typename?: "CcWireframe";
    id: string;
    name: string;
    description?: string | null;
    lifecycleStage: GqlClientAssetLifecycleStage;
    comment?: string | null;
    category: GqlClientCcWireframeCategory;
    frames: Array<{
        __typename?: "CcFrame";
        id: string;
        placeholders: Array<{
            __typename?: "CcPlaceholder";
            id: string;
            name: string;
            content?: any | null;
            category: GqlClientCcPlaceholderCategory;
            intent: {
                __typename?: "CcPlaceholderIntent";
                id: string;
                name: string;
                type: GqlClientCcPlaceholderType;
                defaultValue?: string | null;
                textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
            };
        }>;
    }>;
    animatedWireframes: Array<{
        __typename?: "CcAnimatedWireframe";
        id: string;
        name: string;
        vsmlUrl: string;
        lifecycleStage: GqlClientAssetLifecycleStage;
        aspectRatio: GqlClientEnumAspectRatio;
        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
        defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
        theme: { __typename?: "CcTheme"; id: string };
        placeholdersSettings: Array<
            | {
                  __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                  id: string;
                  horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                  verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                  mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                  canvasName?: string | null;
                  ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
              }
            | {
                  __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                  id: string;
                  horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                  verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                  canvasName?: string | null;
                  colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                  ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
              }
        >;
        m2AnimatedWireframe?: {
            __typename?: "CcAnimatedWireframe";
            id: string;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            vsmlUrl: string;
            defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
        } | null;
        composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
    }>;
};

export type GqlClientEditorPlaceholderConfigurationFragment = {
    __typename?: "EditorPlaceholder";
    id: string;
    placeholderContent?: any | null;
    placeholderSettings?: any | null;
    parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
    flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
    parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
};

export type GqlClientEditorPlaceholderFragment = {
    __typename?: "EditorPlaceholder";
    id: string;
    placeholderContent?: any | null;
    placeholderSettings?: any | null;
    parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
    ccPlaceholder: {
        __typename?: "CcPlaceholder";
        id: string;
        name: string;
        content?: any | null;
        category: GqlClientCcPlaceholderCategory;
        intent: {
            __typename?: "CcPlaceholderIntent";
            id: string;
            name: string;
            type: GqlClientCcPlaceholderType;
            defaultValue?: string | null;
            textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
        };
    };
    flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
    parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
};

export type GqlClientEditorProgramFragment = {
    __typename?: "EditorProgram";
    id: string;
    name: string;
    description?: string | null;
    saleForceId?: string | null;
    programType: GqlClientEditorProgramType;
    publishTarget: GqlClientLifecycleStage;
    created: string;
    isArchive?: boolean | null;
    isLive?: boolean | null;
    firstViewedDate?: string | null;
    isReadyToGoLive: boolean;
    landingPageUrl?: string | null;
    programVersionDraft: {
        __typename?: "EditorProgramVersion";
        id: string;
        anyUpdatedBy?: string | null;
        anyUpdated?: string | null;
        publishable?: any | null;
        sceneLineup: Array<string>;
        editorDataConnectorEnabled?: boolean | null;
        chapteringEnabled?: boolean | null;
        editorTtsFullEnablement?: boolean | null;
        selectedMusicSource: GqlClientEditorAssetSource;
        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
        accountDataLibraryVersion?: {
            __typename?: "AccountDataLibraryVersion";
            id: string;
            data: any;
            anyUpdatedBy?: string | null;
            anyUpdated: string;
            dataFields: Array<{ __typename?: "AccountDataField"; id: string; data: any; localId: string }>;
            accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string };
        } | null;
        ccLibraryVersion?: { __typename?: "CcLibraryVersion"; id: string; library: { __typename?: "CcLibrary"; id: string } } | null;
        pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
        selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string };
    };
    uatVersion?: {
        __typename?: "LifecycleHistoryEntry";
        id: string;
        created: string;
        createdBy?: string | null;
        snapshotName?: string | null;
        snapshotNumber?: number | null;
        snapshotValidationData?: any | null;
        accountDataLibraryVersion?: {
            __typename?: "AccountDataLibraryVersion";
            id: string;
            localId: string;
            data: any;
            anyUpdated: string;
            anyUpdatedBy?: string | null;
            usedInVideos: Array<{
                __typename?: "EditorProgram";
                id: string;
                name: string;
                isArchive?: boolean | null;
                isLive?: boolean | null;
                programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                shareHistory: Array<{
                    __typename?: "ProgramShareHistory";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    accountId: string;
                    shareType: GqlClientProgramShareType;
                    isExternal?: boolean | null;
                }>;
            }>;
            dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
            accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
        } | null;
        editorProgramVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            editorDataConnectorEnabled?: boolean | null;
            selectedMusicSource: GqlClientEditorAssetSource;
            usedElements?: any | null;
            chapteringEnabled?: boolean | null;
            thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
            pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
            selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string };
        } | null;
    } | null;
    prodVersion?: {
        __typename?: "LifecycleHistoryEntry";
        id: string;
        created: string;
        createdBy?: string | null;
        snapshotName?: string | null;
        snapshotNumber?: number | null;
        snapshotValidationData?: any | null;
        accountDataLibraryVersion?: {
            __typename?: "AccountDataLibraryVersion";
            id: string;
            localId: string;
            data: any;
            anyUpdated: string;
            anyUpdatedBy?: string | null;
            usedInVideos: Array<{
                __typename?: "EditorProgram";
                id: string;
                name: string;
                isArchive?: boolean | null;
                isLive?: boolean | null;
                programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                shareHistory: Array<{
                    __typename?: "ProgramShareHistory";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    accountId: string;
                    shareType: GqlClientProgramShareType;
                    isExternal?: boolean | null;
                }>;
            }>;
            dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
            accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
        } | null;
        editorProgramVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            editorDataConnectorEnabled?: boolean | null;
            selectedMusicSource: GqlClientEditorAssetSource;
            usedElements?: any | null;
            chapteringEnabled?: boolean | null;
            thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
            pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
            selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string };
        } | null;
    } | null;
    firstProdVersion?: {
        __typename?: "LifecycleHistoryEntry";
        id: string;
        created: string;
        createdBy?: string | null;
        snapshotName?: string | null;
        snapshotNumber?: number | null;
        snapshotValidationData?: any | null;
        accountDataLibraryVersion?: {
            __typename?: "AccountDataLibraryVersion";
            id: string;
            localId: string;
            data: any;
            anyUpdated: string;
            anyUpdatedBy?: string | null;
            usedInVideos: Array<{
                __typename?: "EditorProgram";
                id: string;
                name: string;
                isArchive?: boolean | null;
                isLive?: boolean | null;
                programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                shareHistory: Array<{
                    __typename?: "ProgramShareHistory";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    accountId: string;
                    shareType: GqlClientProgramShareType;
                    isExternal?: boolean | null;
                }>;
            }>;
            dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
            accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
        } | null;
        editorProgramVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            editorDataConnectorEnabled?: boolean | null;
            selectedMusicSource: GqlClientEditorAssetSource;
            usedElements?: any | null;
            chapteringEnabled?: boolean | null;
            thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
            pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
            selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string };
        } | null;
    } | null;
    landingPage?: {
        __typename?: "LandingPage";
        id: string;
        updated: string;
        updatedBy?: string | null;
        created: string;
        createdBy?: string | null;
        configuration?: any | null;
        isActive?: boolean | null;
        lifecycleStage?: GqlClientAssetLifecycleStage | null;
        updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
        createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
    } | null;
    embedVideoCode?: {
        __typename?: "EmbedVideoCode";
        id: string;
        updated: string;
        updatedBy?: string | null;
        created: string;
        createdBy?: string | null;
        configuration?: any | null;
        updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
        createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
    } | null;
    shareHistory: Array<{
        __typename?: "ProgramShareHistory";
        id: string;
        created: string;
        createdBy?: string | null;
        accountId: string;
        shareType: GqlClientProgramShareType;
        isExternal?: boolean | null;
    }>;
};

export type GqlClientEditorProgramSelectedMusicFragment = {
    __typename?: "EditorProgramVersion";
    selectedMusicSource: GqlClientEditorAssetSource;
    selectedCcMusic?: { __typename?: "CcMusic"; id: string } | null;
    selectedAccountMusic?: { __typename?: "EditorAssetMusic"; id: string } | null;
};

export type GqlClientEditorProgramShareHistoryFragment = {
    __typename?: "ProgramShareHistory";
    id: string;
    created: string;
    createdBy?: string | null;
    accountId: string;
    shareType: GqlClientProgramShareType;
    isExternal?: boolean | null;
};

export type GqlClientEditorProgramVersionAllLogicFragment = {
    __typename?: "EditorProgramVersion";
    id: string;
    usedElements?: any | null;
    bcRules?: any | null;
    bcUseRules?: boolean | null;
    selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string };
    scenes: Array<{
        __typename?: "EditorScene";
        id: string;
        skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
        skipSceneAudience?: any | null;
        placeholders: Array<{ __typename?: "EditorPlaceholder"; id: string; placeholderContent?: any | null }>;
    }>;
};

export type GqlClientEditorSceneConfigurationFragment = {
    __typename?: "EditorScene";
    id: string;
    name: string;
    description?: string | null;
    skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
    colorCombination: GqlClientEditorSceneColorCombination;
    buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
    skipSceneAudience?: any | null;
    minimumDuration?: number | null;
    hasTransition?: boolean | null;
    avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
    avatarVideoUrl?: string | null;
};

export type GqlClientEditorSceneFragment = {
    __typename?: "EditorScene";
    id: string;
    usedMediaIds: Array<string>;
    name: string;
    description?: string | null;
    skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
    colorCombination: GqlClientEditorSceneColorCombination;
    buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
    skipSceneAudience?: any | null;
    minimumDuration?: number | null;
    hasTransition?: boolean | null;
    avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
    avatarVideoUrl?: string | null;
    placeholders: Array<{
        __typename?: "EditorPlaceholder";
        id: string;
        placeholderContent?: any | null;
        placeholderSettings?: any | null;
        parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
        ccPlaceholder: {
            __typename?: "CcPlaceholder";
            id: string;
            name: string;
            content?: any | null;
            category: GqlClientCcPlaceholderCategory;
            intent: {
                __typename?: "CcPlaceholderIntent";
                id: string;
                name: string;
                type: GqlClientCcPlaceholderType;
                defaultValue?: string | null;
                textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
            };
        };
        flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
        parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
    }>;
    ccWireframe: {
        __typename?: "CcWireframe";
        name: string;
        id: string;
        lifecycleStage: GqlClientAssetLifecycleStage;
        category: GqlClientCcWireframeCategory;
        frames: Array<{ __typename?: "CcFrame"; id: string; placeholders: Array<{ __typename?: "CcPlaceholder"; id: string }> }>;
    };
    ccAnimatedWireframe?: {
        __typename?: "CcAnimatedWireframe";
        id: string;
        name: string;
        vsmlUrl: string;
        lifecycleStage: GqlClientAssetLifecycleStage;
        aspectRatio: GqlClientEnumAspectRatio;
        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
        defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
        theme: { __typename?: "CcTheme"; id: string };
        placeholdersSettings: Array<
            | {
                  __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                  id: string;
                  horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                  verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                  mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                  canvasName?: string | null;
                  ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
              }
            | {
                  __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                  id: string;
                  horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                  verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                  canvasName?: string | null;
                  colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                  ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
              }
        >;
        m2AnimatedWireframe?: {
            __typename?: "CcAnimatedWireframe";
            id: string;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            vsmlUrl: string;
            defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
        } | null;
        composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
    } | null;
};

export type GqlClientEditorSceneAnimatedWireframeFragment = {
    __typename?: "EditorScene";
    id: string;
    ccAnimatedWireframe?: {
        __typename?: "CcAnimatedWireframe";
        id: string;
        name: string;
        vsmlUrl: string;
        lifecycleStage: GqlClientAssetLifecycleStage;
        aspectRatio: GqlClientEnumAspectRatio;
        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
        defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
        theme: { __typename?: "CcTheme"; id: string };
        placeholdersSettings: Array<
            | {
                  __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                  id: string;
                  horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                  verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                  mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                  canvasName?: string | null;
                  ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
              }
            | {
                  __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                  id: string;
                  horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                  verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                  canvasName?: string | null;
                  colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                  ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
              }
        >;
        m2AnimatedWireframe?: {
            __typename?: "CcAnimatedWireframe";
            id: string;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            vsmlUrl: string;
            defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
        } | null;
        composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
    } | null;
};

export type GqlClientEditorScenePlaceholdersSelectedAnimatedWireframeAllWireframesMappingFragment = {
    __typename?: "EditorScene";
    id: string;
    colorCombination: GqlClientEditorSceneColorCombination;
    placeholders: Array<{
        __typename?: "EditorPlaceholder";
        id: string;
        placeholderContent?: any | null;
        placeholderSettings?: any | null;
        parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
        ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
        flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
        parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
    }>;
    ccWireframe: {
        __typename?: "CcWireframe";
        id: string;
        allWireframesMapping: Array<{
            __typename?: "CcWireframeAllWireframesData";
            isLosingData: boolean;
            wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage; comment?: string | null; category: GqlClientCcWireframeCategory };
            placeholdersData: Array<{
                __typename?: "CcWireframePlaceholderData";
                targetCcPlaceholder: {
                    __typename?: "CcPlaceholder";
                    id: string;
                    name: string;
                    content?: any | null;
                    category: GqlClientCcPlaceholderCategory;
                    intent: {
                        __typename?: "CcPlaceholderIntent";
                        id: string;
                        name: string;
                        type: GqlClientCcPlaceholderType;
                        defaultValue?: string | null;
                        textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                    };
                };
                originalCcPlaceholder?: { __typename?: "CcPlaceholder"; id: string } | null;
            } | null>;
        }>;
    };
    ccAnimatedWireframe?: { __typename?: "CcAnimatedWireframe"; id: string } | null;
};

export type GqlClientEditorStockLibraryMusicFragment = {
    __typename?: "EditorStockMusic";
    id: string;
    title: string;
    durationInSeconds: number;
    previewUrl?: string | null;
    artists?: Array<string | null> | null;
    tags?: Array<{ __typename?: "EditorStockMusicTag"; id: string; name: string } | null> | null;
};

export type GqlClientEditorViewerProfileFragment = { __typename?: "ViewerProfile"; id: string; name: string; content: any; updated: string };

export type GqlClientEditorWireframePlaceholderFragment = {
    __typename?: "CcPlaceholder";
    id: string;
    name: string;
    content?: any | null;
    category: GqlClientCcPlaceholderCategory;
    intent: {
        __typename?: "CcPlaceholderIntent";
        id: string;
        name: string;
        type: GqlClientCcPlaceholderType;
        defaultValue?: string | null;
        textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
    };
};

export type GqlClientGalleryStoryTemplateFragment = {
    __typename?: "EditorApplication";
    id: string;
    name?: string | null;
    metadata?: any | null;
    isParent?: boolean | null;
    accountId: string;
    posterImageUrl?: string | null;
    previewVideoUrl?: string | null;
    customThumbnailUrl?: string | null;
    newStoryTemplate?: boolean | null;
    parent?: { __typename?: "EditorApplication"; id: string } | null;
    useCases?: Array<{ __typename?: "EditorUseCase"; id: string; name: string } | null> | null;
    industry?: { __typename?: "EditorIndustry"; id: string; name: string } | null;
    flavor?: { __typename?: "ApplicationFlavor"; id: string; name: string } | null;
    creativeStyleFits?: Array<{ __typename?: "EditorCreativeStyleFit"; id: string; name: string } | null> | null;
    channels?: Array<{ __typename?: "EditorChannel"; id: string; name: string } | null> | null;
    programVersion?: { __typename?: "EditorProgramVersion"; id: string; aspectRatio: GqlClientEnumAspectRatio } | null;
};

export type GqlClientLifecycleHistoryEntryFragment = {
    __typename?: "LifecycleHistoryEntry";
    id: string;
    created: string;
    createdBy?: string | null;
    snapshotName?: string | null;
    snapshotNumber?: number | null;
    snapshotValidationData?: any | null;
    accountDataLibraryVersion?: {
        __typename?: "AccountDataLibraryVersion";
        id: string;
        localId: string;
        data: any;
        anyUpdated: string;
        anyUpdatedBy?: string | null;
        usedInVideos: Array<{
            __typename?: "EditorProgram";
            id: string;
            name: string;
            isArchive?: boolean | null;
            isLive?: boolean | null;
            programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
            prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
            uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
            shareHistory: Array<{
                __typename?: "ProgramShareHistory";
                id: string;
                created: string;
                createdBy?: string | null;
                accountId: string;
                shareType: GqlClientProgramShareType;
                isExternal?: boolean | null;
            }>;
        }>;
        dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
        accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
    } | null;
    editorProgramVersion?: {
        __typename?: "EditorProgramVersion";
        id: string;
        editorDataConnectorEnabled?: boolean | null;
        selectedMusicSource: GqlClientEditorAssetSource;
        usedElements?: any | null;
        chapteringEnabled?: boolean | null;
        thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
        pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
        selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string };
    } | null;
};

export type GqlClientMediaCropHintsFragment = { __typename?: "MediaCropHints"; suggestedCrop: { __typename?: "Rectangle"; x: number; y: number; width: number; height: number } };

export type GqlClientStudioElementFragment = {
    __typename?: "DerivedDataElement";
    id: string;
    localId: string;
    name: string;
    dataType?: GqlClientDataElementType | null;
    valueSet?: Array<any | null> | null;
};

export type GqlClientTrayAccountConnectionFragment = {
    __typename?: "AccountConnection";
    id: string;
    isConnected: boolean;
    connectorType: string;
    connectionUpdated: string;
    connectionUpdatedBy: string;
};

export type GqlClientUndoStacksFragment = {
    __typename?: "UndoStacks";
    id: string;
    undoStackSize: number;
    redoStackSize: number;
    firstUndoActionName?: string | null;
    firstRedoActionName?: string | null;
};

export type GqlClientClearUndoStackMutationVariables = Exact<{ [key: string]: never }>;

export type GqlClientClearUndoStackMutation = {
    __typename?: "Mutation";
    clearUndoStack?: {
        __typename?: "ClearUndoStackOutput";
        result: GqlClientClearUndoStackResult;
        undoStacks: { __typename?: "UndoStacks"; id: string; undoStackSize: number; redoStackSize: number; firstUndoActionName?: string | null; firstRedoActionName?: string | null };
    } | null;
};

export type GqlClientCreateAccountCopilotBriefMutationVariables = Exact<{
    input: GqlClientCreateAccountCopilotBriefInput;
}>;

export type GqlClientCreateAccountCopilotBriefMutation = {
    __typename?: "Mutation";
    createAccountCopilotBrief: {
        __typename?: "CreateAccountCopilotBriefOutput";
        result?: GqlClientCreateAccountCopilotBriefResult | null;
        account?: {
            __typename?: "Account";
            id: string;
            copilotUserBriefs: Array<{ __typename?: "AccountCopilotBrief"; id: string }>;
            copilotOtherUsersBriefs: Array<{ __typename?: "AccountCopilotBrief"; id: string }>;
        } | null;
        brief?: { __typename?: "AccountCopilotBrief"; id: string; name: string; briefData: any; createdBy: string; created: string } | null;
    };
};

export type GqlClientCreateAccountProgramFolderMutationVariables = Exact<{
    input: GqlClientCreateAccountProgramFolderInput;
}>;

export type GqlClientCreateAccountProgramFolderMutation = {
    __typename?: "Mutation";
    createAccountProgramFolder: {
        __typename?: "CreateAccountProgramFolderOutput";
        folder?: {
            __typename?: "AccountProgramFolder";
            id: string;
            isRoot: boolean;
            name: string;
            created: string;
            createdBy: string;
            updated: string;
            updatedBy: string;
            parentFolder?: { __typename?: "AccountProgramFolder"; id: string; name: string; created: string; createdBy: string; updated: string; updatedBy: string; isRoot: boolean } | null;
        } | null;
        account?: { __typename?: "Account"; id: string; folders: Array<{ __typename?: "AccountProgramFolder"; id: string }> } | null;
    };
};

export type GqlClientCreateAiPreviewProgramMutationVariables = Exact<{
    input: GqlClientCreateAiPreviewProgramInput;
    mutationId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCreateAiPreviewProgramMutation = {
    __typename?: "Mutation";
    createAiPreviewProgram: {
        __typename?: "CreateAiPreviewProgramOutput";
        result: GqlClientCreateAiPreviewProgramResult;
        pollingTaskCreationResponse: { __typename?: "PollingTaskCreationResponse"; sessionId: string; pollingUrl: string };
    };
};

export type GqlClientCreateBrandMutationVariables = Exact<{
    input: GqlClientCreateBcCollectionInput;
}>;

export type GqlClientCreateBrandMutation = {
    __typename?: "Mutation";
    createBcCollection: {
        __typename?: "CreateBcCollectionOutput";
        result: GqlClientCreateBcCollectionResult;
        brandConfigurationCollection?: {
            __typename?: "BrandConfigurationCollection";
            id: string;
            localId: string;
            updated: string;
            updatedBy?: string | null;
            created: string;
            name: string;
            description?: string | null;
            logoUrl?: string | null;
            logoLocation?: string | null;
            buttonShape: GqlClientButtonShapeValues;
            logoMedia?: {
                __typename?: "EditorAssetMedia";
                id: string;
                name: string;
                created: string;
                updated: string;
                mediaLocation: string;
                mediaUrl: string;
                mediaPreviewUrl: string;
                mediaAgifUrl?: string | null;
                mediaOrigin: GqlClientMediaOrigin;
                mediaMetadata: any;
                mediaProcessingInfo?: any | null;
                mediaFramesThumbnailsUrl?: string | null;
                originalEditInformation?: any | null;
                originalMediaId?: string | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                mediaSri?: string | null;
                externalLibraryAssetId?: string | null;
                externalLibrary?: GqlClientExternalLibrary | null;
                externalSublibrary?: GqlClientExternalSubLibrary | null;
                directAncestorId?: string | null;
                mediaClassification: GqlClientMediaClassification;
                description?: string | null;
                createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
            } | null;
            colors: Array<{
                __typename?: "BcColor";
                id: string;
                updated: string;
                color: string;
                ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
            }>;
            textStyles: Array<{
                __typename?: "BcTextStyle";
                id: string;
                fontSource: GqlClientEditorAssetSource;
                letterCase: GqlClientTextStyleLetterCase;
                letterSpacing: number;
                lineSpacing: number;
                updated: string;
                ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                ccFont?: {
                    __typename?: "CcFont";
                    id: string;
                    name: string;
                    url: string;
                    hasSmallCaps?: boolean | null;
                    lifecycleStage?: GqlClientAssetLifecycleStage | null;
                    webFontUrl?: string | null;
                    created: string;
                    familyName?: string | null;
                    isBold?: boolean | null;
                    isItalic?: boolean | null;
                    weight?: number | null;
                } | null;
                accountFont?: {
                    __typename?: "EditorAssetFont";
                    id: string;
                    name: string;
                    url?: string | null;
                    imageUrl?: string | null;
                    webFontUrl?: string | null;
                    hasSmallCaps?: boolean | null;
                    lifecycleStage?: GqlClientAssetLifecycleStage | null;
                    uploadId?: string | null;
                    created: string;
                    familyName?: string | null;
                    isBold?: boolean | null;
                    isItalic?: boolean | null;
                    weight?: number | null;
                } | null;
            }>;
        } | null;
    };
};

export type GqlClientCreateDataLibraryMutationVariables = Exact<{
    input: GqlClientCreateDataLibraryInput;
}>;

export type GqlClientCreateDataLibraryMutation = {
    __typename?: "Mutation";
    createDataLibrary: {
        __typename?: "CreateDataLibraryOutput";
        result: GqlClientCreateDataLibraryResult;
        editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; accountDataLibraryVersion?: { __typename?: "AccountDataLibraryVersion"; id: string } | null } | null;
    };
};

export type GqlClientCreateEditorAccountUserMutationVariables = Exact<{
    input: GqlClientCreateEditorAccountUserInput;
}>;

export type GqlClientCreateEditorAccountUserMutation = {
    __typename?: "Mutation";
    createEditorAccountUser:
        | { __typename?: "CreateEditorAccountUserOutputRefusal"; result: GqlClientCreateEditorAccountUserResult }
        | {
              __typename?: "CreateEditorAccountUserOutputSuccess";
              result: GqlClientCreateEditorAccountUserResult;
              accountUser: {
                  __typename?: "AccountUser";
                  id: string;
                  roles: Array<GqlClientUserAccountRole>;
                  invitationStatus: GqlClientAccountInvitationStatus;
                  user: {
                      __typename?: "EditorAccountUser";
                      id: string;
                      email: string;
                      roles?: Array<GqlClientUserRole> | null;
                      lastLogin?: string | null;
                      created?: string | null;
                      hasMultipleAccounts?: boolean | null;
                  };
                  account: { __typename?: "Account"; id: string; maxCapacityReached: boolean };
              };
          };
};

export type GqlClientCreateEditorAssetFolderMutationVariables = Exact<{
    input: GqlClientCreateEditorAssetFolderInput;
}>;

export type GqlClientCreateEditorAssetFolderMutation = {
    __typename?: "Mutation";
    createEditorAssetFolder:
        | { __typename?: "CreateEditorAssetFolderOutputAlreadyExists"; result: GqlClientCreateEditorAssetFolderResult }
        | {
              __typename?: "CreateEditorAssetFolderOutputSuccess";
              result: GqlClientCreateEditorAssetFolderResult;
              folder: {
                  __typename?: "EditorAssetFolder";
                  id: string;
                  name: string;
                  accountId: string;
                  created: string;
                  createdBy?: string | null;
                  updated: string;
                  updatedBy?: string | null;
                  parentFolder?: { __typename?: "EditorAssetFolder"; id: string } | null;
              };
              editorAssetLibraryVersion: {
                  __typename?: "EditorAssetLibraryVersion";
                  id: string;
                  media: Array<{ __typename?: "EditorAssetMedia"; id: string }>;
                  mediaFolders: Array<{ __typename?: "EditorAssetFolder"; id: string }>;
              };
          };
};

export type GqlClientCreateEditorAvatarMutationVariables = Exact<{
    input: GqlClientCreateEditorAvatarInput;
}>;

export type GqlClientCreateEditorAvatarMutation = {
    __typename?: "Mutation";
    createEditorAvatar: {
        __typename?: "CreateEditorAvatarOutput";
        result: GqlClientCreateEditorAvatarResult;
        editorPlaceholder?: {
            __typename?: "EditorPlaceholder";
            id: string;
            placeholderContent?: any | null;
            placeholderSettings?: any | null;
            parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
            scene: {
                __typename?: "EditorScene";
                id: string;
                placeholders: Array<{
                    __typename?: "EditorPlaceholder";
                    id: string;
                    placeholderContent?: any | null;
                    placeholderSettings?: any | null;
                    parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                    ccPlaceholder: {
                        __typename?: "CcPlaceholder";
                        id: string;
                        name: string;
                        content?: any | null;
                        category: GqlClientCcPlaceholderCategory;
                        intent: {
                            __typename?: "CcPlaceholderIntent";
                            id: string;
                            name: string;
                            type: GqlClientCcPlaceholderType;
                            defaultValue?: string | null;
                            textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                        };
                    };
                    flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                    parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
                }>;
            };
            flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
            parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
        } | null;
    };
};

export type GqlClientCreateEditorFlexiblePlaceholderMutationVariables = Exact<{
    input: GqlClientCreateEditorFlexiblePlaceholderInput;
}>;

export type GqlClientCreateEditorFlexiblePlaceholderMutation = {
    __typename?: "Mutation";
    createEditorFlexiblePlaceholder: {
        __typename?: "CreateEditorFlexiblePlaceholderOutput";
        result: GqlClientCreateEditorFlexiblePlaceholderResult;
        editorPlaceholder?: {
            __typename?: "EditorPlaceholder";
            id: string;
            placeholderContent?: any | null;
            placeholderSettings?: any | null;
            parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
            scene: { __typename?: "EditorScene"; id: string; placeholders: Array<{ __typename?: "EditorPlaceholder"; id: string }> };
            ccPlaceholder: {
                __typename?: "CcPlaceholder";
                id: string;
                name: string;
                content?: any | null;
                category: GqlClientCcPlaceholderCategory;
                intent: {
                    __typename?: "CcPlaceholderIntent";
                    id: string;
                    name: string;
                    type: GqlClientCcPlaceholderType;
                    defaultValue?: string | null;
                    textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                };
            };
            flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
            parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
        } | null;
    };
};

export type GqlClientCreateEditorOverlayPlaceholderMutationVariables = Exact<{
    input: GqlClientCreateEditorOverlayPlaceholderInput;
}>;

export type GqlClientCreateEditorOverlayPlaceholderMutation = {
    __typename?: "Mutation";
    createEditorOverlayPlaceholder: {
        __typename?: "CreateEditorOverlayPlaceholderOutput";
        result: GqlClientCreateEditorOverlayPlaceholderResult;
        editorPlaceholder?: {
            __typename?: "EditorPlaceholder";
            id: string;
            placeholderContent?: any | null;
            placeholderSettings?: any | null;
            parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
            scene: { __typename?: "EditorScene"; id: string; placeholders: Array<{ __typename?: "EditorPlaceholder"; id: string }> };
            ccPlaceholder: {
                __typename?: "CcPlaceholder";
                id: string;
                name: string;
                content?: any | null;
                category: GqlClientCcPlaceholderCategory;
                intent: {
                    __typename?: "CcPlaceholderIntent";
                    id: string;
                    name: string;
                    type: GqlClientCcPlaceholderType;
                    defaultValue?: string | null;
                    textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                };
            };
            flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
            parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
        } | null;
    };
};

export type GqlClientCreateEditorProgramSnapshotMutationVariables = Exact<{
    input: GqlClientCreateEditorProgramSnapshotInput;
    mutationId: Scalars["String"]["input"];
}>;

export type GqlClientCreateEditorProgramSnapshotMutation = {
    __typename?: "Mutation";
    createEditorProgramSnapshot: { __typename?: "CreateEditorProgramSnapshotOutput"; result: GqlClientCreateEditorProgramSnapshotResult };
};

export type GqlClientCreateEditorScenesMutationVariables = Exact<{
    input: GqlClientCreateEditorScenesInput;
}>;

export type GqlClientCreateEditorScenesMutation = {
    __typename?: "Mutation";
    createEditorScenes: {
        __typename?: "CreateEditorScenesOutput";
        editorScenes: Array<{
            __typename?: "EditorScene";
            id: string;
            usedMediaIds: Array<string>;
            name: string;
            description?: string | null;
            skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
            colorCombination: GqlClientEditorSceneColorCombination;
            buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
            skipSceneAudience?: any | null;
            minimumDuration?: number | null;
            hasTransition?: boolean | null;
            avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
            avatarVideoUrl?: string | null;
            placeholders: Array<{
                __typename?: "EditorPlaceholder";
                id: string;
                placeholderContent?: any | null;
                placeholderSettings?: any | null;
                parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                ccPlaceholder: {
                    __typename?: "CcPlaceholder";
                    id: string;
                    name: string;
                    content?: any | null;
                    category: GqlClientCcPlaceholderCategory;
                    intent: {
                        __typename?: "CcPlaceholderIntent";
                        id: string;
                        name: string;
                        type: GqlClientCcPlaceholderType;
                        defaultValue?: string | null;
                        textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                    };
                };
                flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
            }>;
            ccWireframe: {
                __typename?: "CcWireframe";
                name: string;
                id: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                category: GqlClientCcWireframeCategory;
                frames: Array<{ __typename?: "CcFrame"; id: string; placeholders: Array<{ __typename?: "CcPlaceholder"; id: string }> }>;
            };
            ccAnimatedWireframe?: {
                __typename?: "CcAnimatedWireframe";
                id: string;
                name: string;
                vsmlUrl: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                aspectRatio: GqlClientEnumAspectRatio;
                compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                theme: { __typename?: "CcTheme"; id: string };
                placeholdersSettings: Array<
                    | {
                          __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                          id: string;
                          horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                          verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                          mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                          canvasName?: string | null;
                          ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                      }
                    | {
                          __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                          id: string;
                          horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                          verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                          canvasName?: string | null;
                          colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                          ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                      }
                >;
                m2AnimatedWireframe?: {
                    __typename?: "CcAnimatedWireframe";
                    id: string;
                    compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                    vsmlUrl: string;
                    defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                } | null;
                composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
            } | null;
        }>;
        editorProgramVersion: { __typename?: "EditorProgramVersion"; id: string; sceneLineup: Array<string>; scenes: Array<{ __typename?: "EditorScene"; id: string }> };
    };
};

export type GqlClientCreateEditorScenesFromPromptMutationVariables = Exact<{
    input: GqlClientCreateSceneFromPromptInput;
    mutationId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCreateEditorScenesFromPromptMutation = {
    __typename?: "Mutation";
    createSceneFromPrompt: { __typename?: "CreateSceneFromPromptOutput"; result: GqlClientCreateSceneFromPromptResult };
};

export type GqlClientCreateEditorViewerProfileMutationVariables = Exact<{
    createEditorViewerProfileInput: GqlClientCreateEditorViewerProfileInput;
}>;

export type GqlClientCreateEditorViewerProfileMutation = {
    __typename?: "Mutation";
    createEditorViewerProfile: {
        __typename?: "CreateEditorViewerProfileOutput";
        result: GqlClientCreateEditorViewerProfileResult;
        viewerProfile?: {
            __typename?: "ViewerProfile";
            id: string;
            name: string;
            content: any;
            updated: string;
            program: { __typename?: "EditorProgram"; id: string; viewerProfiles: Array<{ __typename?: "ViewerProfile"; id: string; name: string; content: any; updated: string }> };
        } | null;
    };
};

export type GqlClientCreateNextRecommendedVideoMutationVariables = Exact<{
    input: GqlClientCreateNextRecommendedVideoInput;
    mutationId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCreateNextRecommendedVideoMutation = {
    __typename?: "Mutation";
    createNextRecommendedVideo: {
        __typename?: "CreateNextRecommendedVideoOutput";
        result: GqlClientCreateNextRecommendedVideoResult;
        pollingTaskCreationResponse: { __typename?: "PollingTaskCreationResponse"; sessionId: string; pollingUrl: string };
    };
};

export type GqlClientCreateOrUpdateAccountDataFieldsMutationVariables = Exact<{
    input: GqlClientCreateOrUpdateAccountDataFieldsInput;
}>;

export type GqlClientCreateOrUpdateAccountDataFieldsMutation = {
    __typename?: "Mutation";
    createOrUpdateAccountDataFields: {
        __typename?: "CreateOrUpdateAccountDataFieldsOutput";
        result: GqlClientCreateOrUpdateAccountDataFieldsResult;
        accountDataLibraryVersion?: {
            __typename?: "AccountDataLibraryVersion";
            id: string;
            localId: string;
            data: any;
            anyUpdated: string;
            anyUpdatedBy?: string | null;
            usedInVideos: Array<{
                __typename?: "EditorProgram";
                id: string;
                name: string;
                isArchive?: boolean | null;
                isLive?: boolean | null;
                programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                shareHistory: Array<{
                    __typename?: "ProgramShareHistory";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    accountId: string;
                    shareType: GqlClientProgramShareType;
                    isExternal?: boolean | null;
                }>;
            }>;
            dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
            accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
        } | null;
    };
};

export type GqlClientCreateOrUpdateEmbedVideoCodeMutationVariables = Exact<{
    createOrUpdateEmbedVideoCodeInput: GqlClientCreateOrUpdateEmbedVideoCodeInput;
}>;

export type GqlClientCreateOrUpdateEmbedVideoCodeMutation = {
    __typename?: "Mutation";
    createOrUpdateEmbedVideoCode: {
        __typename?: "CreateOrUpdateEmbedVideoCodeOutput";
        result: GqlClientCreateOrUpdateEmbedVideoCodeResult;
        embedVideoCode?: {
            __typename?: "EmbedVideoCode";
            id: string;
            editorProgram: {
                __typename?: "EditorProgram";
                id: string;
                embedVideoCode?: {
                    __typename?: "EmbedVideoCode";
                    id: string;
                    updated: string;
                    updatedBy?: string | null;
                    created: string;
                    createdBy?: string | null;
                    configuration?: any | null;
                    updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                } | null;
            };
        } | null;
    };
};

export type GqlClientCreateOrUpdateLandingPageMutationVariables = Exact<{
    createOrUpdateLandingPageInput: GqlClientCreateOrUpdateLandingPageInput;
}>;

export type GqlClientCreateOrUpdateLandingPageMutation = {
    __typename?: "Mutation";
    createOrUpdateLandingPage: {
        __typename?: "CreateOrUpdateLandingPageOutput";
        result: GqlClientCreateOrUpdateLandingPageResult;
        landingPage?: {
            __typename?: "LandingPage";
            id: string;
            editorProgram: {
                __typename?: "EditorProgram";
                id: string;
                landingPage?: {
                    __typename?: "LandingPage";
                    id: string;
                    updated: string;
                    updatedBy?: string | null;
                    created: string;
                    createdBy?: string | null;
                    configuration?: any | null;
                    isActive?: boolean | null;
                    lifecycleStage?: GqlClientAssetLifecycleStage | null;
                    updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                } | null;
            };
        } | null;
    };
};

export type GqlClientCreatePreSubmittedAvatarMutationVariables = Exact<{ [key: string]: never }>;

export type GqlClientCreatePreSubmittedAvatarMutation = {
    __typename?: "Mutation";
    createPreSubmittedAvatar: { __typename?: "CreatePreSubmittedAvatarOutput"; result: GqlClientCreatePreSubmittedAvatarResult; avatarId: string };
};

export type GqlClientCreateProgramReviewMutationVariables = Exact<{
    input: GqlClientCreateProgramReviewInput;
}>;

export type GqlClientCreateProgramReviewMutation = {
    __typename?: "Mutation";
    createProgramReview: { __typename?: "CreateProgramReviewOutput"; result: GqlClientCreateProgramReviewResult; programReview?: { __typename?: "ProgramReview"; id: string } | null };
};

export type GqlClientCreatePronunciationDefinitionMutationVariables = Exact<{
    input: GqlClientCreatePronunciationDefinitionInput;
}>;

export type GqlClientCreatePronunciationDefinitionMutation = {
    __typename?: "Mutation";
    createPronunciationDefinition: {
        __typename?: "CreatePronunciationDefinitionOutput";
        pronunciationDefinition?: {
            __typename?: "AccountPronunciationDefinition";
            id: string;
            source: string;
            target: string;
            pronunciationLibraryVersion: {
                __typename?: "AccountPronunciationLibraryVersion";
                id: string;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                definitions: Array<{ __typename?: "AccountPronunciationDefinition"; id: string }>;
            };
        } | null;
    };
};

export type GqlClientCreateShareHistoryRecordMutationVariables = Exact<{
    input: GqlClientCreateShareHistoryRecordInput;
}>;

export type GqlClientCreateShareHistoryRecordMutation = {
    __typename?: "Mutation";
    createShareHistoryRecord: {
        __typename?: "CreateShareHistoryRecordOutput";
        shareHistoryRecord: {
            __typename?: "ProgramShareHistory";
            id: string;
            shareType: GqlClientProgramShareType;
            created: string;
            createdBy?: string | null;
            programVersion: { __typename?: "EditorProgramVersion"; id: string };
        };
    };
};

export type GqlClientCustomAvatarsCreditBalanceEmailMutationVariables = Exact<{
    input: GqlClientCustomAvatarsCreditBalanceEmailInput;
}>;

export type GqlClientCustomAvatarsCreditBalanceEmailMutation = {
    __typename?: "Mutation";
    customAvatarsCreditBalanceEmail: { __typename?: "CustomAvatarsCreditBalanceEmailOutput"; result: GqlClientCustomAvatarsCreditBalanceEmailResult };
};

export type GqlClientDataLibraryImportDataMutationVariables = Exact<{
    input: GqlClientDataLibraryImportDataInput;
    mutationId: Scalars["String"]["input"];
}>;

export type GqlClientDataLibraryImportDataMutation = {
    __typename?: "Mutation";
    dataLibraryImportData: { __typename?: "DataLibraryImportDataOutput"; result: GqlClientDataLibraryImportDataResult; importDataId: string; dataLibraryId: string };
};

export type GqlClientDeleteAccountCopilotBriefMutationVariables = Exact<{
    input: GqlClientDeleteAccountCopilotBriefInput;
}>;

export type GqlClientDeleteAccountCopilotBriefMutation = {
    __typename?: "Mutation";
    deleteAccountCopilotBrief: {
        __typename?: "DeleteAccountCopilotBriefOutput";
        result?: GqlClientDeleteAccountCopilotBriefResult | null;
        account?: { __typename?: "Account"; id: string; copilotUserBriefs: Array<{ __typename?: "AccountCopilotBrief"; id: string }> } | null;
    };
};

export type GqlClientDeleteAccountProgramFolderMutationVariables = Exact<{
    input: GqlClientDeleteAccountProgramFolderInput;
}>;

export type GqlClientDeleteAccountProgramFolderMutation = {
    __typename?: "Mutation";
    deleteAccountProgramFolder: {
        __typename?: "DeleteAccountProgramFolderOutput";
        result: GqlClientDeleteAccountProgramFolderResult;
        account: {
            __typename?: "Account";
            id: string;
            folders: Array<{ __typename?: "AccountProgramFolder"; id: string; parentFolder?: { __typename?: "AccountProgramFolder"; id: string } | null }>;
            builderPrograms: Array<{ __typename?: "Program"; id: string; folder?: { __typename?: "AccountProgramFolder"; id: string } | null }>;
            editorPrograms: Array<{ __typename?: "EditorProgram"; id: string; folder?: { __typename?: "AccountProgramFolder"; id: string } | null }>;
        };
    };
};

export type GqlClientDeleteBrandCollectionMutationVariables = Exact<{
    input: GqlClientDeleteBrandCollectionInput;
}>;

export type GqlClientDeleteBrandCollectionMutation = {
    __typename?: "Mutation";
    deleteBrandCollection: {
        __typename?: "DeleteBrandCollectionOutput";
        result: GqlClientDeleteBrandCollectionResult;
        brandConfigurationVersion?: {
            __typename?: "BrandConfigurationVersion";
            id: string;
            brandConfigurationCollections: Array<{
                __typename?: "BrandConfigurationCollection";
                id: string;
                localId: string;
                updated: string;
                updatedBy?: string | null;
                created: string;
                name: string;
                description?: string | null;
                logoUrl?: string | null;
                logoLocation?: string | null;
                buttonShape: GqlClientButtonShapeValues;
                logoMedia?: {
                    __typename?: "EditorAssetMedia";
                    id: string;
                    name: string;
                    created: string;
                    updated: string;
                    mediaLocation: string;
                    mediaUrl: string;
                    mediaPreviewUrl: string;
                    mediaAgifUrl?: string | null;
                    mediaOrigin: GqlClientMediaOrigin;
                    mediaMetadata: any;
                    mediaProcessingInfo?: any | null;
                    mediaFramesThumbnailsUrl?: string | null;
                    originalEditInformation?: any | null;
                    originalMediaId?: string | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    mediaSri?: string | null;
                    externalLibraryAssetId?: string | null;
                    externalLibrary?: GqlClientExternalLibrary | null;
                    externalSublibrary?: GqlClientExternalSubLibrary | null;
                    directAncestorId?: string | null;
                    mediaClassification: GqlClientMediaClassification;
                    description?: string | null;
                    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                    folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                } | null;
                colors: Array<{
                    __typename?: "BcColor";
                    id: string;
                    updated: string;
                    color: string;
                    ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
                }>;
                textStyles: Array<{
                    __typename?: "BcTextStyle";
                    id: string;
                    fontSource: GqlClientEditorAssetSource;
                    letterCase: GqlClientTextStyleLetterCase;
                    letterSpacing: number;
                    lineSpacing: number;
                    updated: string;
                    ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                    ccFont?: {
                        __typename?: "CcFont";
                        id: string;
                        name: string;
                        url: string;
                        hasSmallCaps?: boolean | null;
                        lifecycleStage?: GqlClientAssetLifecycleStage | null;
                        webFontUrl?: string | null;
                        created: string;
                        familyName?: string | null;
                        isBold?: boolean | null;
                        isItalic?: boolean | null;
                        weight?: number | null;
                    } | null;
                    accountFont?: {
                        __typename?: "EditorAssetFont";
                        id: string;
                        name: string;
                        url?: string | null;
                        imageUrl?: string | null;
                        webFontUrl?: string | null;
                        hasSmallCaps?: boolean | null;
                        lifecycleStage?: GqlClientAssetLifecycleStage | null;
                        uploadId?: string | null;
                        created: string;
                        familyName?: string | null;
                        isBold?: boolean | null;
                        isItalic?: boolean | null;
                        weight?: number | null;
                    } | null;
                }>;
            }>;
            defaultBrandConfigurationCollection: { __typename?: "BrandConfigurationCollection"; id: string };
        } | null;
    };
};

export type GqlClientDeleteDataLibraryMutationVariables = Exact<{
    input: GqlClientDeleteDataLibraryInput;
}>;

export type GqlClientDeleteDataLibraryMutation = {
    __typename?: "Mutation";
    deleteDataLibrary: {
        __typename?: "DeleteDataLibraryOutput";
        result: GqlClientDeleteDataLibraryResult;
        account: { __typename?: "Account"; id: string; dataLibraries: Array<{ __typename?: "AccountDataLibrary"; id: string }> };
    };
};

export type GqlClientDeleteEditorAccountUserMutationVariables = Exact<{
    input: GqlClientDeleteEditorAccountUserInput;
}>;

export type GqlClientDeleteEditorAccountUserMutation = {
    __typename?: "Mutation";
    deleteEditorAccountUser: { __typename?: "DeleteEditorAccountUserOutput"; result: GqlClientDeleteEditorAccountUserResult };
};

export type GqlClientDeleteEditorAssetFolderMutationVariables = Exact<{
    input: GqlClientDeleteEditorAssetFolderInput;
}>;

export type GqlClientDeleteEditorAssetFolderMutation = {
    __typename?: "Mutation";
    deleteEditorAssetFolder:
        | { __typename?: "DeleteEditorAssetFolderOutputFailure"; result: GqlClientDeleteEditorAssetFolderResult }
        | {
              __typename?: "DeleteEditorAssetFolderOutputSuccess";
              result: GqlClientDeleteEditorAssetFolderResult;
              editorAssetLibraryVersion: {
                  __typename?: "EditorAssetLibraryVersion";
                  id: string;
                  media: Array<{ __typename?: "EditorAssetMedia"; id: string; lifecycleStage: GqlClientAssetLifecycleStage }>;
                  mediaFolders: Array<{
                      __typename?: "EditorAssetFolder";
                      id: string;
                      name: string;
                      created: string;
                      createdBy?: string | null;
                      updated: string;
                      updatedBy?: string | null;
                      parentFolder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                  }>;
              };
          };
};

export type GqlClientDeleteEditorFlexiblePlaceholderMutationVariables = Exact<{
    input: GqlClientDeleteEditorFlexiblePlaceholderInput;
}>;

export type GqlClientDeleteEditorFlexiblePlaceholderMutation = {
    __typename?: "Mutation";
    deleteEditorFlexiblePlaceholder: {
        __typename?: "DeleteEditorFlexiblePlaceholderOutput";
        result: GqlClientDeleteEditorFlexiblePlaceholderResult;
        editorScenes: Array<{
            __typename?: "EditorScene";
            id: string;
            placeholders: Array<{
                __typename?: "EditorPlaceholder";
                id: string;
                placeholderContent?: any | null;
                placeholderSettings?: any | null;
                parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                ccPlaceholder: {
                    __typename?: "CcPlaceholder";
                    id: string;
                    name: string;
                    content?: any | null;
                    category: GqlClientCcPlaceholderCategory;
                    intent: {
                        __typename?: "CcPlaceholderIntent";
                        id: string;
                        name: string;
                        type: GqlClientCcPlaceholderType;
                        defaultValue?: string | null;
                        textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                    };
                };
                flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
            }>;
        }>;
    };
};

export type GqlClientDeleteEditorLibraryAssetMediaMutationVariables = Exact<{
    input: GqlClientDeleteEditorAssetMediaInput;
}>;

export type GqlClientDeleteEditorLibraryAssetMediaMutation = {
    __typename?: "Mutation";
    deleteEditorAssetMedia:
        | { __typename?: "DeleteEditorAssetMediaOutputRefusal"; result: GqlClientDeleteEditorAssetMediaResult }
        | {
              __typename?: "DeleteEditorAssetMediaOutputSuccess";
              result: GqlClientDeleteEditorAssetMediaResult;
              editorAssetLibraryVersion: {
                  __typename?: "EditorAssetLibraryVersion";
                  id: string;
                  media: Array<{ __typename?: "EditorAssetMedia"; id: string; lifecycleStage: GqlClientAssetLifecycleStage }>;
              };
          };
};

export type GqlClientDeleteEditorProgramAllVersionsMutationVariables = Exact<{
    deleteEditorProgramAllVersionsInput: GqlClientDeleteEditorProgramAllVersionsInput;
}>;

export type GqlClientDeleteEditorProgramAllVersionsMutation = {
    __typename?: "Mutation";
    deleteEditorProgramAllVersions: { __typename?: "DeleteEditorProgramAllVersionsOutput"; result: GqlClientDeleteEditorProgramAllVersionsResult };
};

export type GqlClientDeleteEditorSceneMutationVariables = Exact<{
    input: GqlClientDeleteEditorSceneInput;
}>;

export type GqlClientDeleteEditorSceneMutation = {
    __typename?: "Mutation";
    deleteEditorScene: {
        __typename?: "DeleteEditorSceneOutput";
        result: GqlClientDeleteEditorSceneResult;
        editorProgramVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            sceneLineup: Array<string>;
            scenes: Array<{
                __typename?: "EditorScene";
                id: string;
                placeholders: Array<{
                    __typename?: "EditorPlaceholder";
                    id: string;
                    placeholderContent?: any | null;
                    placeholderSettings?: any | null;
                    parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                    flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                    parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
                }>;
            }>;
        } | null;
    };
};

export type GqlClientDeleteEditorViewerProfileMutationVariables = Exact<{
    input: GqlClientDeleteEditorViewerProfileInput;
}>;

export type GqlClientDeleteEditorViewerProfileMutation = {
    __typename?: "Mutation";
    deleteEditorViewerProfile: {
        __typename?: "DeleteEditorViewerProfileOutput";
        result: GqlClientDeleteEditorViewerProfileResult;
        editorProgram: { __typename?: "EditorProgram"; id: string; viewerProfiles: Array<{ __typename?: "ViewerProfile"; id: string; name: string; content: any; updated: string }> };
    };
};

export type GqlClientDeleteFailedAvatarMutationVariables = Exact<{
    deleteFailedAvatar: GqlClientDeleteFailedAvatarInput;
}>;

export type GqlClientDeleteFailedAvatarMutation = { __typename?: "Mutation"; deleteFailedAvatar: { __typename?: "DeleteFailedAvatarOutput"; result: GqlClientDeleteFailedAvatarResult } };

export type GqlClientDeletePreSubmittedAvatarMutationVariables = Exact<{
    input: GqlClientDeletePreSubmittedAvatarInput;
}>;

export type GqlClientDeletePreSubmittedAvatarMutation = {
    __typename?: "Mutation";
    deletePreSubmittedAvatar: { __typename?: "DeletePreSubmittedAvatarOutput"; result: GqlClientDeletePreSubmittedAvatarResult };
};

export type GqlClientDeletePronunciationDefinitionMutationVariables = Exact<{
    input: GqlClientDeletePronunciationDefinitionInput;
}>;

export type GqlClientDeletePronunciationDefinitionMutation = {
    __typename?: "Mutation";
    deletePronunciationDefinition: {
        __typename?: "DeletePronunciationDefinitionOutput";
        pronunciationLibraryVersion?: {
            __typename?: "AccountPronunciationLibraryVersion";
            id: string;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            definitions: Array<{ __typename?: "AccountPronunciationDefinition"; id: string; source: string; target: string }>;
        } | null;
    };
};

export type GqlClientDisconnectTrayConnectorMutationVariables = Exact<{
    disconnectTrayConnector: GqlClientDisconnectTrayConnectorInput;
}>;

export type GqlClientDisconnectTrayConnectorMutation = {
    __typename?: "Mutation";
    disconnectTrayConnector: { __typename?: "DisconnectTrayConnectorOutput"; result: GqlClientDisconnectTrayConnectorResult };
};

export type GqlClientDuplicateEditorSceneInLineupMutationVariables = Exact<{
    input: GqlClientDuplicateEditorSceneInLineupInput;
}>;

export type GqlClientDuplicateEditorSceneInLineupMutation = {
    __typename?: "Mutation";
    duplicateEditorSceneInLineup: {
        __typename?: "DuplicateEditorSceneInLineupOutput";
        result: GqlClientDuplicateEditorSceneInLineupResult;
        editorScene?: {
            __typename?: "EditorScene";
            id: string;
            usedMediaIds: Array<string>;
            name: string;
            description?: string | null;
            skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
            colorCombination: GqlClientEditorSceneColorCombination;
            buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
            skipSceneAudience?: any | null;
            minimumDuration?: number | null;
            hasTransition?: boolean | null;
            avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
            avatarVideoUrl?: string | null;
            programVersion: { __typename?: "EditorProgramVersion"; id: string; sceneLineup: Array<string>; scenes: Array<{ __typename?: "EditorScene"; id: string }> };
            placeholders: Array<{
                __typename?: "EditorPlaceholder";
                id: string;
                placeholderContent?: any | null;
                placeholderSettings?: any | null;
                parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                ccPlaceholder: {
                    __typename?: "CcPlaceholder";
                    id: string;
                    name: string;
                    content?: any | null;
                    category: GqlClientCcPlaceholderCategory;
                    intent: {
                        __typename?: "CcPlaceholderIntent";
                        id: string;
                        name: string;
                        type: GqlClientCcPlaceholderType;
                        defaultValue?: string | null;
                        textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                    };
                };
                flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
            }>;
            ccWireframe: {
                __typename?: "CcWireframe";
                name: string;
                id: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                category: GqlClientCcWireframeCategory;
                frames: Array<{ __typename?: "CcFrame"; id: string; placeholders: Array<{ __typename?: "CcPlaceholder"; id: string }> }>;
            };
            ccAnimatedWireframe?: {
                __typename?: "CcAnimatedWireframe";
                id: string;
                name: string;
                vsmlUrl: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                aspectRatio: GqlClientEnumAspectRatio;
                compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                theme: { __typename?: "CcTheme"; id: string };
                placeholdersSettings: Array<
                    | {
                          __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                          id: string;
                          horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                          verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                          mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                          canvasName?: string | null;
                          ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                      }
                    | {
                          __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                          id: string;
                          horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                          verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                          canvasName?: string | null;
                          colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                          ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                      }
                >;
                m2AnimatedWireframe?: {
                    __typename?: "CcAnimatedWireframe";
                    id: string;
                    compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                    vsmlUrl: string;
                    defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                } | null;
                composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
            } | null;
        } | null;
    };
};

export type GqlClientEditEditorAssetMediaMutationVariables = Exact<{
    input: GqlClientEditEditorAssetMediaInput;
    mutationId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientEditEditorAssetMediaMutation = {
    __typename?: "Mutation";
    editEditorAssetMedia: {
        __typename?: "EditEditorAssetMediaOutput";
        result: GqlClientEditEditorAssetMediaResult;
        editorAssetMedia?: {
            __typename?: "EditorAssetMedia";
            id: string;
            name: string;
            created: string;
            updated: string;
            mediaLocation: string;
            mediaUrl: string;
            mediaPreviewUrl: string;
            mediaAgifUrl?: string | null;
            mediaOrigin: GqlClientMediaOrigin;
            mediaMetadata: any;
            mediaProcessingInfo?: any | null;
            mediaFramesThumbnailsUrl?: string | null;
            originalEditInformation?: any | null;
            originalMediaId?: string | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            mediaSri?: string | null;
            externalLibraryAssetId?: string | null;
            externalLibrary?: GqlClientExternalLibrary | null;
            externalSublibrary?: GqlClientExternalSubLibrary | null;
            directAncestorId?: string | null;
            mediaClassification: GqlClientMediaClassification;
            description?: string | null;
            editorAssetLibraryVersion: { __typename?: "EditorAssetLibraryVersion"; id: string; media: Array<{ __typename?: "EditorAssetMedia"; id: string }> };
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
        } | null;
    };
};

export type GqlClientEditorAssetMediaSaveAsMutationVariables = Exact<{
    input: GqlClientEditorAssetMediaSaveAsInput;
}>;

export type GqlClientEditorAssetMediaSaveAsMutation = {
    __typename?: "Mutation";
    editorAssetMediaSaveAs: {
        __typename?: "EditorAssetMediaSaveAsOutput";
        result: GqlClientEditorAssetMediaSaveAsResult;
        editorAssetMedia?: {
            __typename?: "EditorAssetMedia";
            id: string;
            name: string;
            created: string;
            updated: string;
            mediaLocation: string;
            mediaUrl: string;
            mediaPreviewUrl: string;
            mediaAgifUrl?: string | null;
            mediaOrigin: GqlClientMediaOrigin;
            mediaMetadata: any;
            mediaProcessingInfo?: any | null;
            mediaFramesThumbnailsUrl?: string | null;
            originalEditInformation?: any | null;
            originalMediaId?: string | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            mediaSri?: string | null;
            externalLibraryAssetId?: string | null;
            externalLibrary?: GqlClientExternalLibrary | null;
            externalSublibrary?: GqlClientExternalSubLibrary | null;
            directAncestorId?: string | null;
            mediaClassification: GqlClientMediaClassification;
            description?: string | null;
            editorAssetLibraryVersion: { __typename?: "EditorAssetLibraryVersion"; id: string; media: Array<{ __typename?: "EditorAssetMedia"; id: string }> };
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
        } | null;
    };
};

export type GqlClientGenerateAiVideoFromImageMutationVariables = Exact<{
    input: GqlClientAiImageToVideoInput;
    mutationId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientGenerateAiVideoFromImageMutation = {
    __typename?: "Mutation";
    generateAiVideoFromImage?: { __typename?: "GenerateAiVideoFromImageOutput"; result: GqlClientGenerateAiVideoFromImageResult } | null;
};

export type GqlClientMoveAccountProgramFolderToFolderMutationVariables = Exact<{
    input: GqlClientMoveAccountProgramFolderToFolderInput;
}>;

export type GqlClientMoveAccountProgramFolderToFolderMutation = {
    __typename?: "Mutation";
    moveAccountProgramFolderToFolder: {
        __typename?: "MoveAccountProgramFolderToFolderOutput";
        result: GqlClientMoveAccountProgramFolderToFolderResult;
        folder: { __typename?: "AccountProgramFolder"; id: string; name: string; parentFolder?: { __typename?: "AccountProgramFolder"; id: string } | null };
    };
};

export type GqlClientMoveEditorAssetToFolderMutationVariables = Exact<{
    input: GqlClientMoveEditorAssetToFolderInput;
}>;

export type GqlClientMoveEditorAssetToFolderMutation = {
    __typename?: "Mutation";
    moveEditorAssetToFolder: {
        __typename?: "MoveEditorAssetToFolderOutput";
        result: GqlClientMoveEditorAssetToFolderResult;
        editorAssetMedia: {
            __typename?: "EditorAssetMedia";
            id: string;
            name: string;
            created: string;
            updated: string;
            mediaLocation: string;
            mediaUrl: string;
            mediaPreviewUrl: string;
            mediaAgifUrl?: string | null;
            mediaOrigin: GqlClientMediaOrigin;
            mediaMetadata: any;
            mediaProcessingInfo?: any | null;
            mediaFramesThumbnailsUrl?: string | null;
            originalEditInformation?: any | null;
            originalMediaId?: string | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            mediaSri?: string | null;
            externalLibraryAssetId?: string | null;
            externalLibrary?: GqlClientExternalLibrary | null;
            externalSublibrary?: GqlClientExternalSubLibrary | null;
            directAncestorId?: string | null;
            mediaClassification: GqlClientMediaClassification;
            description?: string | null;
            editorAssetLibraryVersion: { __typename?: "EditorAssetLibraryVersion"; id: string; media: Array<{ __typename?: "EditorAssetMedia"; id: string }> };
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
        };
    };
};

export type GqlClientMoveEditorFolderToFolderMutationVariables = Exact<{
    input: GqlClientMoveEditorFolderToFolderInput;
}>;

export type GqlClientMoveEditorFolderToFolderMutation = {
    __typename?: "Mutation";
    moveEditorFolderToFolder: {
        __typename?: "MoveEditorFolderToFolderOutput";
        result: GqlClientMoveEditorFolderToFolderResult;
        folder: { __typename?: "EditorAssetFolder"; id: string; name: string; accountId: string; parentFolder?: { __typename?: "EditorAssetFolder"; id: string } | null };
    };
};

export type GqlClientMoveProgramToFolderMutationVariables = Exact<{
    input: GqlClientMoveProgramToFolderInput;
}>;

export type GqlClientMoveProgramToFolderMutation = {
    __typename?: "Mutation";
    moveProgramToFolder: {
        __typename?: "MoveProgramToFolderOutput";
        result: GqlClientMoveProgramToFolderResult;
        program:
            | { __typename?: "EditorProgram"; id: string; name: string; folder?: { __typename?: "AccountProgramFolder"; id: string } | null }
            | { __typename?: "Program"; id: string; name: string; folder?: { __typename?: "AccountProgramFolder"; id: string } | null };
    };
};

export type GqlClientPostToSocialNetworkMutationVariables = Exact<{
    input: GqlClientPostToSocialNetworkInput;
}>;

export type GqlClientPostToSocialNetworkMutation = { __typename?: "Mutation"; postToSocialNetwork: { __typename?: "PostToSocialNetworkOutput"; postUrl: string } };

export type GqlClientPromotePreSubmittedAvatarMutationVariables = Exact<{
    input: GqlClientPromotePreSubmittedAvatarInput;
}>;

export type GqlClientPromotePreSubmittedAvatarMutation = {
    __typename?: "Mutation";
    promotePreSubmittedAvatar: {
        __typename?: "PromotePreSubmittedAvatarOutput";
        result: GqlClientPromotePreSubmittedAvatarResult;
        avatar: { __typename?: "EditorAccountLevelAvatar"; accountId?: string | null; avatarSystemName: string; created: string; id: string; name: string; updatedBy?: string | null };
    };
};

export type GqlClientPromoteSubmittedAvatarMutationVariables = Exact<{
    input: GqlClientPromoteSubmittedAvatarInput;
    mutationId: Scalars["String"]["input"];
}>;

export type GqlClientPromoteSubmittedAvatarMutation = {
    __typename?: "Mutation";
    promoteSubmittedAvatar: { __typename?: "PromoteSubmittedAvatarOutput"; result: GqlClientPromoteSubmittedAvatarResult };
};

export type GqlClientPublishStoryTemplateMutationVariables = Exact<{
    input: GqlClientAddStoryTemplateToProductionGalleryInput;
}>;

export type GqlClientPublishStoryTemplateMutation = {
    __typename?: "Mutation";
    addStoryTemplateToProductionGallery: { __typename?: "AddStoryTemplateToProductionGalleryOutput"; result: GqlClientAddStoryTemplateToProductionGalleryResult };
};

export type GqlClientRecalculateEditorNarrationRecordingStateMutationVariables = Exact<{
    input: GqlClientRecalculateEditorNarrationRecordingStateInput;
    mutationId: Scalars["String"]["input"];
}>;

export type GqlClientRecalculateEditorNarrationRecordingStateMutation = {
    __typename?: "Mutation";
    recalculateEditorNarrationRecordingState: { __typename?: "RecalculateEditorNarrationRecordingStateOutput"; result: GqlClientRecalculateEditorNarrationRecordingStateResult };
};

export type GqlClientRemoveAllExternalUsersFromAccountMutationVariables = Exact<{ [key: string]: never }>;

export type GqlClientRemoveAllExternalUsersFromAccountMutation = {
    __typename?: "Mutation";
    removeAllExternalUsersFromAccount: { __typename?: "RemoveAllExternalUsersFromAccountOutput"; result: GqlClientRemoveAllExternalUsersFromAccountResult };
};

export type GqlClientRemoveConnectorFromAccountMutationVariables = Exact<{
    removeConnectorFromAccount: GqlClientRemoveConnectorFromAccountInput;
}>;

export type GqlClientRemoveConnectorFromAccountMutation = {
    __typename?: "Mutation";
    removeConnectorFromAccount: { __typename?: "RemoveConnectorFromAccountOutput"; result: GqlClientRemoveConnectorFromAccountResult };
};

export type GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutationVariables = Exact<{
    input: GqlClientRemoveEditorProgramVersionLogicAndDataLibraryInput;
}>;

export type GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutation = {
    __typename?: "Mutation";
    removeEditorProgramVersionLogicAndDataLibrary: {
        __typename?: "RemoveEditorProgramVersionLogicAndDataLibraryOutput";
        editorProgramVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            usedElements?: any | null;
            bcRules?: any | null;
            bcUseRules?: boolean | null;
            selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string };
            scenes: Array<{
                __typename?: "EditorScene";
                id: string;
                skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
                skipSceneAudience?: any | null;
                placeholders: Array<{ __typename?: "EditorPlaceholder"; id: string; placeholderContent?: any | null }>;
            }>;
        } | null;
    };
};

export type GqlClientRemoveServiceFromAccountMutationVariables = Exact<{
    removeServiceFromAccount: GqlClientRemoveServiceFromAccountInput;
}>;

export type GqlClientRemoveServiceFromAccountMutation = {
    __typename?: "Mutation";
    removeServiceFromAccount: { __typename?: "RemoveServiceFromAccountOutput"; result: GqlClientRemoveServiceFromAccountResult };
};

export type GqlClientRemoveStoryTemplateMutationVariables = Exact<{
    input: GqlClientRemoveStoryTemplateFromGalleryInput;
}>;

export type GqlClientRemoveStoryTemplateMutation = {
    __typename?: "Mutation";
    removeStoryTemplateFromGallery: { __typename?: "RemoveStoryTemplateFromGalleryOutput"; result: GqlClientRemoveStoryTemplateFromGalleryResult };
};

export type GqlClientRenameAccountProgramFolderMutationVariables = Exact<{
    input: GqlClientRenameAccountProgramFolderInput;
}>;

export type GqlClientRenameAccountProgramFolderMutation = {
    __typename?: "Mutation";
    renameAccountProgramFolder: {
        __typename?: "RenameAccountProgramFolderOutput";
        folder?: {
            __typename?: "AccountProgramFolder";
            id: string;
            isRoot: boolean;
            name: string;
            created: string;
            createdBy: string;
            updated: string;
            updatedBy: string;
            parentFolder?: { __typename?: "AccountProgramFolder"; id: string; name: string; created: string; createdBy: string; updated: string; updatedBy: string; isRoot: boolean } | null;
        } | null;
    };
};

export type GqlClientRenameEditorAssetFolderMutationVariables = Exact<{
    input: GqlClientRenameEditorAssetFolderInput;
}>;

export type GqlClientRenameEditorAssetFolderMutation = {
    __typename?: "Mutation";
    renameEditorAssetFolder: {
        __typename?: "RenameEditorAssetFolderOutput";
        result: GqlClientRenameEditorAssetFolderResult;
        folder: { __typename?: "EditorAssetFolder"; id: string; name: string; accountId: string; created: string; createdBy?: string | null; updated: string; updatedBy?: string | null };
    };
};

export type GqlClientRenameProgramReviewMutationVariables = Exact<{
    input: GqlClientRenameProgramReviewInput;
}>;

export type GqlClientRenameProgramReviewMutation = {
    __typename?: "Mutation";
    renameProgramReview: { __typename?: "RenameProgramReviewOutput"; result: GqlClientRenameProgramReviewResult; programReview?: { __typename?: "ProgramReview"; id: string; name: string } | null };
};

export type GqlClientSelectDataLibraryMutationVariables = Exact<{
    input: GqlClientSelectDataLibraryInput;
}>;

export type GqlClientSelectDataLibraryMutation = {
    __typename?: "Mutation";
    selectDataLibrary: {
        __typename?: "SelectDataLibraryOutput";
        result: GqlClientSelectDataLibraryResult;
        editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; accountDataLibraryVersion?: { __typename?: "AccountDataLibraryVersion"; id: string } | null } | null;
    };
};

export type GqlClientSwitchBcCollectionAndDeleteCurrentMutationVariables = Exact<{
    input: GqlClientSwitchBcCollectionAndDeleteCurrentInput;
}>;

export type GqlClientSwitchBcCollectionAndDeleteCurrentMutation = {
    __typename?: "Mutation";
    switchBcCollectionAndDeleteCurrent: {
        __typename?: "SwitchBcCollectionAndDeleteCurrentOutput";
        result: GqlClientSwitchBcCollectionAndDeleteCurrentResult;
        brandConfigurationVersion: {
            __typename?: "BrandConfigurationVersion";
            id: string;
            brandConfigurationCollections: Array<{
                __typename?: "BrandConfigurationCollection";
                id: string;
                localId: string;
                updated: string;
                updatedBy?: string | null;
                created: string;
                name: string;
                description?: string | null;
                logoUrl?: string | null;
                logoLocation?: string | null;
                buttonShape: GqlClientButtonShapeValues;
                logoMedia?: {
                    __typename?: "EditorAssetMedia";
                    id: string;
                    name: string;
                    created: string;
                    updated: string;
                    mediaLocation: string;
                    mediaUrl: string;
                    mediaPreviewUrl: string;
                    mediaAgifUrl?: string | null;
                    mediaOrigin: GqlClientMediaOrigin;
                    mediaMetadata: any;
                    mediaProcessingInfo?: any | null;
                    mediaFramesThumbnailsUrl?: string | null;
                    originalEditInformation?: any | null;
                    originalMediaId?: string | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    mediaSri?: string | null;
                    externalLibraryAssetId?: string | null;
                    externalLibrary?: GqlClientExternalLibrary | null;
                    externalSublibrary?: GqlClientExternalSubLibrary | null;
                    directAncestorId?: string | null;
                    mediaClassification: GqlClientMediaClassification;
                    description?: string | null;
                    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                    folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                } | null;
                colors: Array<{
                    __typename?: "BcColor";
                    id: string;
                    updated: string;
                    color: string;
                    ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
                }>;
                textStyles: Array<{
                    __typename?: "BcTextStyle";
                    id: string;
                    fontSource: GqlClientEditorAssetSource;
                    letterCase: GqlClientTextStyleLetterCase;
                    letterSpacing: number;
                    lineSpacing: number;
                    updated: string;
                    ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                    ccFont?: {
                        __typename?: "CcFont";
                        id: string;
                        name: string;
                        url: string;
                        hasSmallCaps?: boolean | null;
                        lifecycleStage?: GqlClientAssetLifecycleStage | null;
                        webFontUrl?: string | null;
                        created: string;
                        familyName?: string | null;
                        isBold?: boolean | null;
                        isItalic?: boolean | null;
                        weight?: number | null;
                    } | null;
                    accountFont?: {
                        __typename?: "EditorAssetFont";
                        id: string;
                        name: string;
                        url?: string | null;
                        imageUrl?: string | null;
                        webFontUrl?: string | null;
                        hasSmallCaps?: boolean | null;
                        lifecycleStage?: GqlClientAssetLifecycleStage | null;
                        uploadId?: string | null;
                        created: string;
                        familyName?: string | null;
                        isBold?: boolean | null;
                        isItalic?: boolean | null;
                        weight?: number | null;
                    } | null;
                }>;
            }>;
            defaultBrandConfigurationCollection: { __typename?: "BrandConfigurationCollection"; id: string };
        };
        editorProgramVersions: Array<{
            __typename?: "EditorProgramVersion";
            id: string;
            bcUseRules?: boolean | null;
            bcRules?: any | null;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string };
        }>;
    };
};

export type GqlClientUndoActionMutationVariables = Exact<{
    undoActionInput: GqlClientUndoActionInput;
}>;

export type GqlClientUndoActionMutation = {
    __typename?: "Mutation";
    undoAction: {
        __typename?: "UndoActionOutput";
        result: GqlClientUndoActionResult;
        message: string;
        undoStacks: { __typename?: "UndoStacks"; id: string; undoStackSize: number; redoStackSize: number; firstUndoActionName?: string | null; firstRedoActionName?: string | null };
    };
};

export type GqlClientUnselectDataLibraryMutationVariables = Exact<{
    input: GqlClientUnselectDataLibraryInput;
}>;

export type GqlClientUnselectDataLibraryMutation = {
    __typename?: "Mutation";
    unselectDataLibrary: {
        __typename?: "UnselectDataLibraryOutput";
        result: GqlClientUnselectDataLibraryResult;
        editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; accountDataLibraryVersion?: { __typename?: "AccountDataLibraryVersion"; id: string } | null } | null;
    };
};

export type GqlClientUpdateAccountCopilotBriefMutationVariables = Exact<{
    input: GqlClientUpdateAccountCopilotBriefInput;
}>;

export type GqlClientUpdateAccountCopilotBriefMutation = {
    __typename?: "Mutation";
    updateAccountCopilotBrief: {
        __typename?: "UpdateAccountCopilotBriefOutput";
        result?: GqlClientUpdateAccountCopilotBriefResult | null;
        brief?: { __typename?: "AccountCopilotBrief"; id: string; name: string; briefData: any; createdBy: string; created: string } | null;
    };
};

export type GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutationVariables = Exact<{
    input: GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingInput;
}>;

export type GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutation = {
    __typename?: "Mutation";
    updateAccountDataLibraryConnectorTypeAndMapping:
        | { __typename?: "UpdateAccountDataLibraryConnectorTypeAndMappingOutputConflict"; result: GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingResult }
        | {
              __typename?: "UpdateAccountDataLibraryConnectorTypeAndMappingOutputSuccess";
              result: GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingResult;
              accountDataLibraryVersion?: {
                  __typename?: "AccountDataLibraryVersion";
                  id: string;
                  localId: string;
                  data: any;
                  anyUpdated: string;
                  anyUpdatedBy?: string | null;
                  usedInVideos: Array<{
                      __typename?: "EditorProgram";
                      id: string;
                      name: string;
                      isArchive?: boolean | null;
                      isLive?: boolean | null;
                      programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                      prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                      uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                      shareHistory: Array<{
                          __typename?: "ProgramShareHistory";
                          id: string;
                          created: string;
                          createdBy?: string | null;
                          accountId: string;
                          shareType: GqlClientProgramShareType;
                          isExternal?: boolean | null;
                      }>;
                  }>;
                  dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                  accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
              } | null;
          };
};

export type GqlClientUpdateAccountDataLibraryMetadataMutationVariables = Exact<{
    input: GqlClientUpdateAccountDataLibraryMetadataInput;
}>;

export type GqlClientUpdateAccountDataLibraryMetadataMutation = {
    __typename?: "Mutation";
    updateAccountDataLibraryMetadata:
        | { __typename?: "UpdateAccountDataLibraryMetadataOutputConflict"; result: GqlClientUpdateAccountDataLibraryMetadataResult }
        | {
              __typename?: "UpdateAccountDataLibraryMetadataOutputSuccess";
              result: GqlClientUpdateAccountDataLibraryMetadataResult;
              accountDataLibraryVersion?: {
                  __typename?: "AccountDataLibraryVersion";
                  id: string;
                  localId: string;
                  data: any;
                  anyUpdated: string;
                  anyUpdatedBy?: string | null;
                  usedInVideos: Array<{
                      __typename?: "EditorProgram";
                      id: string;
                      name: string;
                      isArchive?: boolean | null;
                      isLive?: boolean | null;
                      programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                      prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                      uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                      shareHistory: Array<{
                          __typename?: "ProgramShareHistory";
                          id: string;
                          created: string;
                          createdBy?: string | null;
                          accountId: string;
                          shareType: GqlClientProgramShareType;
                          isExternal?: boolean | null;
                      }>;
                  }>;
                  dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                  accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
              } | null;
          };
};

export type GqlClientUpdateBrandMutationVariables = Exact<{
    input: GqlClientUpdateBcCollectionWithColorsAndTextStylesInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateBrandMutation = {
    __typename?: "Mutation";
    updateBcCollectionWithColorsAndTextStyles:
        | {
              __typename?: "UpdateBcCollectionWithColorsAndTextStylesOutputConflict";
              result: GqlClientUpdateBcCollectionWithColorsAndTextStylesResult;
              brandConfigurationCollection?: {
                  __typename?: "BrandConfigurationCollection";
                  id: string;
                  localId: string;
                  updated: string;
                  updatedBy?: string | null;
                  created: string;
                  name: string;
                  description?: string | null;
                  logoUrl?: string | null;
                  logoLocation?: string | null;
                  buttonShape: GqlClientButtonShapeValues;
                  logoMedia?: {
                      __typename?: "EditorAssetMedia";
                      id: string;
                      name: string;
                      created: string;
                      updated: string;
                      mediaLocation: string;
                      mediaUrl: string;
                      mediaPreviewUrl: string;
                      mediaAgifUrl?: string | null;
                      mediaOrigin: GqlClientMediaOrigin;
                      mediaMetadata: any;
                      mediaProcessingInfo?: any | null;
                      mediaFramesThumbnailsUrl?: string | null;
                      originalEditInformation?: any | null;
                      originalMediaId?: string | null;
                      lifecycleStage: GqlClientAssetLifecycleStage;
                      mediaSri?: string | null;
                      externalLibraryAssetId?: string | null;
                      externalLibrary?: GqlClientExternalLibrary | null;
                      externalSublibrary?: GqlClientExternalSubLibrary | null;
                      directAncestorId?: string | null;
                      mediaClassification: GqlClientMediaClassification;
                      description?: string | null;
                      createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                      folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                  } | null;
                  colors: Array<{
                      __typename?: "BcColor";
                      id: string;
                      updated: string;
                      color: string;
                      ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
                  }>;
                  textStyles: Array<{
                      __typename?: "BcTextStyle";
                      id: string;
                      fontSource: GqlClientEditorAssetSource;
                      letterCase: GqlClientTextStyleLetterCase;
                      letterSpacing: number;
                      lineSpacing: number;
                      updated: string;
                      ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                      ccFont?: {
                          __typename?: "CcFont";
                          id: string;
                          name: string;
                          url: string;
                          hasSmallCaps?: boolean | null;
                          lifecycleStage?: GqlClientAssetLifecycleStage | null;
                          webFontUrl?: string | null;
                          created: string;
                          familyName?: string | null;
                          isBold?: boolean | null;
                          isItalic?: boolean | null;
                          weight?: number | null;
                      } | null;
                      accountFont?: {
                          __typename?: "EditorAssetFont";
                          id: string;
                          name: string;
                          url?: string | null;
                          imageUrl?: string | null;
                          webFontUrl?: string | null;
                          hasSmallCaps?: boolean | null;
                          lifecycleStage?: GqlClientAssetLifecycleStage | null;
                          uploadId?: string | null;
                          created: string;
                          familyName?: string | null;
                          isBold?: boolean | null;
                          isItalic?: boolean | null;
                          weight?: number | null;
                      } | null;
                  }>;
              } | null;
          }
        | {
              __typename?: "UpdateBcCollectionWithColorsAndTextStylesOutputSuccess";
              result: GqlClientUpdateBcCollectionWithColorsAndTextStylesResult;
              brandConfigurationCollection?: {
                  __typename?: "BrandConfigurationCollection";
                  id: string;
                  localId: string;
                  updated: string;
                  updatedBy?: string | null;
                  created: string;
                  name: string;
                  description?: string | null;
                  logoUrl?: string | null;
                  logoLocation?: string | null;
                  buttonShape: GqlClientButtonShapeValues;
                  logoMedia?: {
                      __typename?: "EditorAssetMedia";
                      id: string;
                      name: string;
                      created: string;
                      updated: string;
                      mediaLocation: string;
                      mediaUrl: string;
                      mediaPreviewUrl: string;
                      mediaAgifUrl?: string | null;
                      mediaOrigin: GqlClientMediaOrigin;
                      mediaMetadata: any;
                      mediaProcessingInfo?: any | null;
                      mediaFramesThumbnailsUrl?: string | null;
                      originalEditInformation?: any | null;
                      originalMediaId?: string | null;
                      lifecycleStage: GqlClientAssetLifecycleStage;
                      mediaSri?: string | null;
                      externalLibraryAssetId?: string | null;
                      externalLibrary?: GqlClientExternalLibrary | null;
                      externalSublibrary?: GqlClientExternalSubLibrary | null;
                      directAncestorId?: string | null;
                      mediaClassification: GqlClientMediaClassification;
                      description?: string | null;
                      createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                      folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                  } | null;
                  colors: Array<{
                      __typename?: "BcColor";
                      id: string;
                      updated: string;
                      color: string;
                      ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
                  }>;
                  textStyles: Array<{
                      __typename?: "BcTextStyle";
                      id: string;
                      fontSource: GqlClientEditorAssetSource;
                      letterCase: GqlClientTextStyleLetterCase;
                      letterSpacing: number;
                      lineSpacing: number;
                      updated: string;
                      ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                      ccFont?: {
                          __typename?: "CcFont";
                          id: string;
                          name: string;
                          url: string;
                          hasSmallCaps?: boolean | null;
                          lifecycleStage?: GqlClientAssetLifecycleStage | null;
                          webFontUrl?: string | null;
                          created: string;
                          familyName?: string | null;
                          isBold?: boolean | null;
                          isItalic?: boolean | null;
                          weight?: number | null;
                      } | null;
                      accountFont?: {
                          __typename?: "EditorAssetFont";
                          id: string;
                          name: string;
                          url?: string | null;
                          imageUrl?: string | null;
                          webFontUrl?: string | null;
                          hasSmallCaps?: boolean | null;
                          lifecycleStage?: GqlClientAssetLifecycleStage | null;
                          uploadId?: string | null;
                          created: string;
                          familyName?: string | null;
                          isBold?: boolean | null;
                          isItalic?: boolean | null;
                          weight?: number | null;
                      } | null;
                  }>;
              } | null;
          };
};

export type GqlClientUpdateProjectAudioSettingsMutationVariables = Exact<{
    updateProjectAudioSettingsInput: GqlClientUpdateProjectAudioSettingsInput;
}>;

export type GqlClientUpdateProjectAudioSettingsMutation = {
    __typename?: "Mutation";
    updateProjectAudioSettings: { __typename?: "UpdateProjectAudioSettingsOutput"; editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; audioSettings?: any | null } | null };
};

export type GqlClientUpdateEditorAvatarPlaceholderMutationVariables = Exact<{
    input: GqlClientUpdateEditorAvatarPlaceholderInput;
}>;

export type GqlClientUpdateEditorAvatarPlaceholderMutation = {
    __typename?: "Mutation";
    updateEditorAvatarPlaceholder: {
        __typename?: "UpdateEditorAvatarPlaceholderOutput";
        result: GqlClientUpdateEditorAvatarPlaceholderResult;
        editorPlaceholders: Array<{
            __typename?: "EditorPlaceholder";
            id: string;
            placeholderContent?: any | null;
            placeholderSettings?: any | null;
            parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
            flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
            parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
        }>;
    };
};

export type GqlClientUpdateEditorProgramVersionBrandRulesMutationVariables = Exact<{
    updateEditorProgramVersionBrandRulesInput: GqlClientUpdateEditorProgramVersionBrandRulesInput;
}>;

export type GqlClientUpdateEditorProgramVersionBrandRulesMutation = {
    __typename?: "Mutation";
    updateEditorProgramVersionBrandRules: {
        __typename?: "UpdateEditorProgramVersionBrandRulesOutput";
        programVersion?: { __typename?: "EditorProgramVersion"; id: string; bcUseRules?: boolean | null; bcRules?: any | null } | null;
    };
};

export type GqlClientUpdateEditorChapteringMutationVariables = Exact<{
    updateEditorChapteringInput: GqlClientUpdateEditorChapteringInput;
}>;

export type GqlClientUpdateEditorChapteringMutation = {
    __typename?: "Mutation";
    updateEditorChaptering: { __typename?: "UpdateEditorChapteringOutput"; editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; chapteringEnabled?: boolean | null } | null };
};

export type GqlClientUpdateEditorLineupMutationVariables = Exact<{
    input: GqlClientUpdateEditorLineupInput;
}>;

export type GqlClientUpdateEditorLineupMutation = {
    __typename?: "Mutation";
    updateEditorLineup: {
        __typename?: "UpdateEditorLineupOutput";
        result: GqlClientUpdateEditorLineupResult;
        editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; sceneLineup: Array<string> } | null;
    };
};

export type GqlClientUpdateEditorNarrationLibraryMutationVariables = Exact<{
    input: GqlClientUpdateEditorNarrationLibraryInput;
}>;

export type GqlClientUpdateEditorNarrationLibraryMutation = {
    __typename?: "Mutation";
    updateEditorNarrationLibrary: { __typename?: "UpdateEditorNarrationLibraryOutput"; result: GqlClientUpdateEditorNarrationLibraryResult };
};

export type GqlClientUpdateEditorPlaceholderMutationVariables = Exact<{
    updateEditorPlaceholderInput: GqlClientUpdateEditorPlaceholderInput;
}>;

export type GqlClientUpdateEditorPlaceholderMutation = {
    __typename?: "Mutation";
    updateEditorPlaceholder:
        | {
              __typename?: "UpdateEditorPlaceholderOutputConflict";
              result: GqlClientUpdateEditorPlaceholderResult;
              editorPlaceholder?: {
                  __typename?: "EditorPlaceholder";
                  id: string;
                  placeholderContent?: any | null;
                  placeholderSettings?: any | null;
                  parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                  flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                  parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
              } | null;
          }
        | {
              __typename?: "UpdateEditorPlaceholderOutputSuccess";
              result: GqlClientUpdateEditorPlaceholderResult;
              editorPlaceholder?: {
                  __typename?: "EditorPlaceholder";
                  id: string;
                  placeholderContent?: any | null;
                  placeholderSettings?: any | null;
                  parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                  flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                  parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
              } | null;
          };
};

export type GqlClientUpdateEditorProgramVersionAspectRatioMutationVariables = Exact<{
    input: GqlClientUpdateEditorProgramVersionAspectRatioInput;
}>;

export type GqlClientUpdateEditorProgramVersionAspectRatioMutation = {
    __typename?: "Mutation";
    updateEditorProgramVersionAspectRatio:
        | {
              __typename?: "UpdateEditorProgramVersionAspectRatioOutputConflict";
              result: GqlClientUpdateEditorProgramVersionAspectRatioResult;
              programVersion?: {
                  __typename?: "EditorProgramVersion";
                  id: string;
                  aspectRatio: GqlClientEnumAspectRatio;
                  scenes: Array<{
                      __typename?: "EditorScene";
                      id: string;
                      ccAnimatedWireframe?: {
                          __typename?: "CcAnimatedWireframe";
                          id: string;
                          name: string;
                          vsmlUrl: string;
                          lifecycleStage: GqlClientAssetLifecycleStage;
                          aspectRatio: GqlClientEnumAspectRatio;
                          compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                          defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                          theme: { __typename?: "CcTheme"; id: string };
                          placeholdersSettings: Array<
                              | {
                                    __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                                    id: string;
                                    horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                    verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                    mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                                    canvasName?: string | null;
                                    ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                                }
                              | {
                                    __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                                    id: string;
                                    horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                    verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                    canvasName?: string | null;
                                    colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                                    ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                                }
                          >;
                          m2AnimatedWireframe?: {
                              __typename?: "CcAnimatedWireframe";
                              id: string;
                              compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                              vsmlUrl: string;
                              defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                          } | null;
                          composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
                      } | null;
                  }>;
              } | null;
          }
        | {
              __typename?: "UpdateEditorProgramVersionAspectRatioOutputSuccess";
              result: GqlClientUpdateEditorProgramVersionAspectRatioResult;
              programVersion?: {
                  __typename?: "EditorProgramVersion";
                  id: string;
                  aspectRatio: GqlClientEnumAspectRatio;
                  scenes: Array<{
                      __typename?: "EditorScene";
                      id: string;
                      ccAnimatedWireframe?: {
                          __typename?: "CcAnimatedWireframe";
                          id: string;
                          name: string;
                          vsmlUrl: string;
                          lifecycleStage: GqlClientAssetLifecycleStage;
                          aspectRatio: GqlClientEnumAspectRatio;
                          compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                          defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                          theme: { __typename?: "CcTheme"; id: string };
                          placeholdersSettings: Array<
                              | {
                                    __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                                    id: string;
                                    horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                    verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                    mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                                    canvasName?: string | null;
                                    ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                                }
                              | {
                                    __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                                    id: string;
                                    horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                    verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                    canvasName?: string | null;
                                    colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                                    ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                                }
                          >;
                          m2AnimatedWireframe?: {
                              __typename?: "CcAnimatedWireframe";
                              id: string;
                              compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                              vsmlUrl: string;
                              defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                          } | null;
                          composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
                      } | null;
                  }>;
              } | null;
          };
};

export type GqlClientUpdateEditorSceneMutationVariables = Exact<{
    updateEditorSceneInput: GqlClientUpdateEditorSceneInput;
}>;

export type GqlClientUpdateEditorSceneMutation = {
    __typename?: "Mutation";
    updateEditorScene:
        | {
              __typename?: "UpdateEditorSceneOutputConflict";
              result: GqlClientUpdateEditorSceneResult;
              editorScene?: {
                  __typename?: "EditorScene";
                  id: string;
                  name: string;
                  description?: string | null;
                  skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
                  colorCombination: GqlClientEditorSceneColorCombination;
                  buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
                  skipSceneAudience?: any | null;
                  minimumDuration?: number | null;
                  hasTransition?: boolean | null;
                  avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
                  avatarVideoUrl?: string | null;
              } | null;
          }
        | {
              __typename?: "UpdateEditorSceneOutputSuccess";
              result: GqlClientUpdateEditorSceneResult;
              editorScene?: {
                  __typename?: "EditorScene";
                  id: string;
                  name: string;
                  description?: string | null;
                  skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
                  colorCombination: GqlClientEditorSceneColorCombination;
                  buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
                  skipSceneAudience?: any | null;
                  minimumDuration?: number | null;
                  hasTransition?: boolean | null;
                  avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
                  avatarVideoUrl?: string | null;
              } | null;
          };
};

export type GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutationVariables = Exact<{
    input: GqlClientUpdateEditorSceneSelectedAnimatedWireframeInput;
}>;

export type GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutation = {
    __typename?: "Mutation";
    updateEditorSceneSelectedAnimatedWireframe:
        | {
              __typename?: "UpdateEditorSceneSelectedAnimatedWireframeOutputConflict";
              result: GqlClientUpdateEditorSceneSelectedAnimatedWireframeResult;
              editorScene?: {
                  __typename?: "EditorScene";
                  id: string;
                  ccAnimatedWireframe?: {
                      __typename?: "CcAnimatedWireframe";
                      id: string;
                      name: string;
                      vsmlUrl: string;
                      lifecycleStage: GqlClientAssetLifecycleStage;
                      aspectRatio: GqlClientEnumAspectRatio;
                      compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                      defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                      theme: { __typename?: "CcTheme"; id: string };
                      placeholdersSettings: Array<
                          | {
                                __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                                id: string;
                                horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                                canvasName?: string | null;
                                ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                            }
                          | {
                                __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                                id: string;
                                horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                canvasName?: string | null;
                                colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                                ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                            }
                      >;
                      m2AnimatedWireframe?: {
                          __typename?: "CcAnimatedWireframe";
                          id: string;
                          compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                          vsmlUrl: string;
                          defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                      } | null;
                      composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
                  } | null;
              } | null;
          }
        | {
              __typename?: "UpdateEditorSceneSelectedAnimatedWireframeOutputSuccess";
              result: GqlClientUpdateEditorSceneSelectedAnimatedWireframeResult;
              editorScene?: {
                  __typename?: "EditorScene";
                  id: string;
                  ccAnimatedWireframe?: {
                      __typename?: "CcAnimatedWireframe";
                      id: string;
                      name: string;
                      vsmlUrl: string;
                      lifecycleStage: GqlClientAssetLifecycleStage;
                      aspectRatio: GqlClientEnumAspectRatio;
                      compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                      defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                      theme: { __typename?: "CcTheme"; id: string };
                      placeholdersSettings: Array<
                          | {
                                __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                                id: string;
                                horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                                canvasName?: string | null;
                                ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                            }
                          | {
                                __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                                id: string;
                                horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                canvasName?: string | null;
                                colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                                ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                            }
                      >;
                      m2AnimatedWireframe?: {
                          __typename?: "CcAnimatedWireframe";
                          id: string;
                          compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                          vsmlUrl: string;
                          defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                      } | null;
                      composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
                  } | null;
              } | null;
          };
};

export type GqlClientUpdateEditorSelectedBrandMutationVariables = Exact<{
    input: GqlClientUpdateEditorSelectedBcCollectionInput;
}>;

export type GqlClientUpdateEditorSelectedBrandMutation = {
    __typename?: "Mutation";
    updateEditorSelectedBcCollection:
        | {
              __typename?: "UpdateEditorSelectedBcCollectionOutputConflict";
              result: GqlClientUpdateEditorSelectedBcCollectionResult;
              editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string } } | null;
          }
        | {
              __typename?: "UpdateEditorSelectedBcCollectionOutputSuccess";
              result: GqlClientUpdateEditorSelectedBcCollectionResult;
              editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string } } | null;
          };
};

export type GqlClientUpdateEditorSelectedMusicMutationVariables = Exact<{
    updateEditorSelectedMusicInput: GqlClientUpdateEditorSelectedMusicInput;
}>;

export type GqlClientUpdateEditorSelectedMusicMutation = {
    __typename?: "Mutation";
    updateEditorSelectedMusic: {
        __typename?: "UpdateEditorSelectedMusicOutput";
        editorProgramVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            selectedMusicSource: GqlClientEditorAssetSource;
            selectedCcMusic?: { __typename?: "CcMusic"; id: string } | null;
            selectedAccountMusic?: { __typename?: "EditorAssetMusic"; id: string } | null;
        } | null;
    };
};

export type GqlClientUpdateEditorSelectedNarratorMutationVariables = Exact<{
    updateEditorSelectedNarratorInput: GqlClientUpdateEditorSelectedNarratorInput;
}>;

export type GqlClientUpdateEditorSelectedNarratorMutation = {
    __typename?: "Mutation";
    updateEditorSelectedNarrator: {
        __typename?: "UpdateEditorSelectedNarratorOutput";
        editorProgramVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            selectedNarrator?: {
                __typename?: "CcNarrator";
                id: string;
                name: string;
                sampleUrl: string;
                narrationSystemName: string;
                narrationSystemVoice: string;
                intent: GqlClientCcNarratorIntent;
                narrationSystemMetadata: {
                    __typename?: "CcNarrationSystemMetadata";
                    language: string;
                    gender: string;
                    rate?: number | null;
                    volume?: number | null;
                    age?: string | null;
                    description?: Array<string> | null;
                };
            } | null;
        } | null;
    };
};

export type GqlClientUpdateEditorTtsFullEnablementMutationVariables = Exact<{
    updateEditorTtsFullEnablementInput: GqlClientUpdateEditorTtsFullEnablementInput;
}>;

export type GqlClientUpdateEditorTtsFullEnablementMutation = {
    __typename?: "Mutation";
    updateEditorTtsFullEnablement:
        | {
              __typename?: "UpdateEditorTtsFullEnablementOutputConflict";
              result: GqlClientUpdateEditorTtsFullEnablementResult;
              editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; editorTtsFullEnablement?: boolean | null } | null;
          }
        | {
              __typename?: "UpdateEditorTtsFullEnablementOutputSuccess";
              result: GqlClientUpdateEditorTtsFullEnablementResult;
              editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; editorTtsFullEnablement?: boolean | null } | null;
          };
};

export type GqlClientUpdateEditorVideoCustomBackgroundMutationVariables = Exact<{
    input: GqlClientVideoCustomBackgroundInput;
}>;

export type GqlClientUpdateEditorVideoCustomBackgroundMutation = {
    __typename?: "Mutation";
    updateEditorVideoCustomBackground: {
        __typename?: "UpdateEditorVideoCustomBackgroundOutput";
        result: GqlClientUpdateEditorVideoCustomBackgroundResult;
        editorProgramVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            useCustomVideoBackground: boolean;
            videoBackgroundMedia?: { __typename?: "EditorAssetMedia"; id: string } | null;
        } | null;
    };
};

export type GqlClientUpdateEditorViewerProfileMutationVariables = Exact<{
    updateEditorViewerProfileInput: GqlClientUpdateEditorViewerProfileInput;
}>;

export type GqlClientUpdateEditorViewerProfileMutation = {
    __typename?: "Mutation";
    updateEditorViewerProfile:
        | {
              __typename?: "UpdateEditorViewerProfileOutputConflict";
              result: GqlClientUpdateEditorViewerProfileResult;
              viewerProfile?: { __typename?: "ViewerProfile"; id: string; name: string; content: any; updated: string } | null;
          }
        | {
              __typename?: "UpdateEditorViewerProfileOutputSuccess";
              result: GqlClientUpdateEditorViewerProfileResult;
              viewerProfile?: { __typename?: "ViewerProfile"; id: string; name: string; content: any; updated: string } | null;
          };
};

export type GqlClientUpdateMediaLibraryGridColumnsMutationVariables = Exact<{
    columns: Scalars["Int"]["input"];
}>;

export type GqlClientUpdateMediaLibraryGridColumnsMutation = {
    __typename?: "Mutation";
    updateMediaLibraryGridColumns: {
        __typename?: "UpdateMediaLibraryGridColumnsOutput";
        result: GqlClientUpdateMediaLibraryGridColumnsResult;
        userSettings: { __typename?: "UserSettings"; id: string; mediaLibraryGridColumns: number };
    };
};

export type GqlClientUpdateMediaLibraryViewTypeMutationVariables = Exact<{
    input: GqlClientUpdateMediaLibraryViewTypeInput;
}>;

export type GqlClientUpdateMediaLibraryViewTypeMutation = {
    __typename?: "Mutation";
    updateMediaLibraryViewType: {
        __typename?: "UpdateMediaLibraryViewTypeOutput";
        result: GqlClientUpdateMediaLibraryViewTypeResult;
        userSettings: { __typename?: "UserSettings"; id: string; mediaLibraryViewType: GqlClientLibraryViewType };
    };
};

export type GqlClientUpdateProgramReviewMutationVariables = Exact<{
    input: GqlClientUpdateProgramReviewInput;
}>;

export type GqlClientUpdateProgramReviewMutation = {
    __typename?: "Mutation";
    updateProgramReview: {
        __typename?: "UpdateProgramReviewOutput";
        result: GqlClientUpdateProgramReviewResult;
        programReview?: { __typename?: "ProgramReview"; id: string; reviewers: Array<string>; description?: string | null } | null;
    };
};

export type GqlClientUpdatePronunciationDefinitionMutationVariables = Exact<{
    input: GqlClientUpdatePronunciationDefinitionInput;
}>;

export type GqlClientUpdatePronunciationDefinitionMutation = {
    __typename?: "Mutation";
    updatePronunciationDefinition: {
        __typename?: "UpdatePronunciationDefinitionOutput";
        pronunciationDefinition?: {
            __typename?: "AccountPronunciationDefinition";
            id: string;
            source: string;
            target: string;
            pronunciationLibraryVersion: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null; anyUpdatedBy?: string | null };
        } | null;
    };
};

export type GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutationVariables = Exact<{
    input: GqlClientUpdateScenePlaceholdersAndAnimatedWireframeInput;
}>;

export type GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutation = {
    __typename?: "Mutation";
    updateScenePlaceholdersAndAnimatedWireframe:
        | { __typename?: "UpdateScenePlaceholdersAndAnimatedWireframeOutputConflict" }
        | {
              __typename?: "UpdateScenePlaceholdersAndAnimatedWireframeOutputSuccess";
              result: GqlClientUpdateScenePlaceholdersAndAnimatedWireframeResult;
              editorScene?: {
                  __typename?: "EditorScene";
                  id: string;
                  colorCombination: GqlClientEditorSceneColorCombination;
                  placeholders: Array<{
                      __typename?: "EditorPlaceholder";
                      id: string;
                      placeholderContent?: any | null;
                      placeholderSettings?: any | null;
                      parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                      ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                      flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                      parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
                  }>;
                  ccWireframe: {
                      __typename?: "CcWireframe";
                      id: string;
                      allWireframesMapping: Array<{
                          __typename?: "CcWireframeAllWireframesData";
                          isLosingData: boolean;
                          wireframe: {
                              __typename?: "CcWireframe";
                              id: string;
                              name: string;
                              lifecycleStage: GqlClientAssetLifecycleStage;
                              comment?: string | null;
                              category: GqlClientCcWireframeCategory;
                          };
                          placeholdersData: Array<{
                              __typename?: "CcWireframePlaceholderData";
                              targetCcPlaceholder: {
                                  __typename?: "CcPlaceholder";
                                  id: string;
                                  name: string;
                                  content?: any | null;
                                  category: GqlClientCcPlaceholderCategory;
                                  intent: {
                                      __typename?: "CcPlaceholderIntent";
                                      id: string;
                                      name: string;
                                      type: GqlClientCcPlaceholderType;
                                      defaultValue?: string | null;
                                      textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                                  };
                              };
                              originalCcPlaceholder?: { __typename?: "CcPlaceholder"; id: string } | null;
                          } | null>;
                      }>;
                  };
                  ccAnimatedWireframe?: { __typename?: "CcAnimatedWireframe"; id: string } | null;
              } | null;
          };
};

export type GqlClientUpdateTrayConnectorAuthenticationMutationVariables = Exact<{
    updateTrayConnectorAuthentication: GqlClientUpdateTrayConnectorAuthenticationInput;
}>;

export type GqlClientUpdateTrayConnectorAuthenticationMutation = {
    __typename?: "Mutation";
    updateTrayConnectorAuthentication: { __typename?: "UpdateTrayConnectorAuthenticationOutput"; result: GqlClientUpdateTrayConnectorAuthenticationResult; solutionInstanceTriggerUrl?: string | null };
};

export type GqlClientUpgradeEditorProgramToM2LayoutsMutationVariables = Exact<{
    input: GqlClientUpgradeEditorProgramToM2LayoutsInput;
}>;

export type GqlClientUpgradeEditorProgramToM2LayoutsMutation = {
    __typename?: "Mutation";
    upgradeEditorProgramToM2Layouts: {
        __typename?: "UpgradeEditorProgramToM2LayoutsOutput";
        result: GqlClientUpgradeEditorProgramToM2LayoutsResult;
        editorProgramVersion: {
            __typename?: "EditorProgramVersion";
            id: string;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            scenes: Array<{ __typename?: "EditorScene"; id: string; ccAnimatedWireframe?: { __typename?: "CcAnimatedWireframe"; id: string } | null }>;
        };
    };
};

export type GqlClientUploadCustomThumbnailMutationVariables = Exact<{
    input: GqlClientUploadCustomThumbnailInput;
}>;

export type GqlClientUploadCustomThumbnailMutation = {
    __typename?: "Mutation";
    uploadCustomThumbnail: {
        __typename?: "uploadCustomThumbnailOutput";
        result: GqlClientUploadCustomThumbnailIResult;
        editorApplication?: { __typename?: "EditorApplication"; id: string; metadata?: any | null; customThumbnailUrl?: string | null } | null;
    };
};

export type GqlClientUploadEditorAssetFontMutationVariables = Exact<{
    input: GqlClientUploadEditorAssetFontInput;
}>;

export type GqlClientUploadEditorAssetFontMutation = {
    __typename?: "Mutation";
    uploadEditorAssetFont: {
        __typename?: "UploadEditorAssetFontOutput";
        result: GqlClientUploadEditorAssetFontResult;
        font?: {
            __typename?: "EditorAssetFont";
            id: string;
            editorAssetLibraryVersion: {
                __typename?: "EditorAssetLibraryVersion";
                id: string;
                fonts: Array<{
                    __typename?: "EditorAssetFont";
                    id: string;
                    name: string;
                    url?: string | null;
                    imageUrl?: string | null;
                    webFontUrl?: string | null;
                    hasSmallCaps?: boolean | null;
                    lifecycleStage?: GqlClientAssetLifecycleStage | null;
                    uploadId?: string | null;
                    created: string;
                    familyName?: string | null;
                    isBold?: boolean | null;
                    isItalic?: boolean | null;
                    weight?: number | null;
                } | null>;
            };
        } | null;
    };
};

export type GqlClientUploadEditorAssetMediaMutationVariables = Exact<{
    input: GqlClientUploadEditorAssetMediaInput;
}>;

export type GqlClientUploadEditorAssetMediaMutation = {
    __typename?: "Mutation";
    uploadEditorAssetMedia: {
        __typename?: "UploadEditorAssetMediaOutput";
        result: GqlClientUploadEditorAssetMediaResult;
        editorAssetMedia?: {
            __typename?: "EditorAssetMedia";
            id: string;
            name: string;
            created: string;
            updated: string;
            mediaLocation: string;
            mediaUrl: string;
            mediaPreviewUrl: string;
            mediaAgifUrl?: string | null;
            mediaOrigin: GqlClientMediaOrigin;
            mediaMetadata: any;
            mediaProcessingInfo?: any | null;
            mediaFramesThumbnailsUrl?: string | null;
            originalEditInformation?: any | null;
            originalMediaId?: string | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            mediaSri?: string | null;
            externalLibraryAssetId?: string | null;
            externalLibrary?: GqlClientExternalLibrary | null;
            externalSublibrary?: GqlClientExternalSubLibrary | null;
            directAncestorId?: string | null;
            mediaClassification: GqlClientMediaClassification;
            description?: string | null;
            editorAssetLibraryVersion: { __typename?: "EditorAssetLibraryVersion"; id: string; media: Array<{ __typename?: "EditorAssetMedia"; id: string }> };
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
        } | null;
        editorPlaceholder?: {
            __typename?: "EditorPlaceholder";
            id: string;
            placeholderContent?: any | null;
            placeholderSettings?: any | null;
            parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
            flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
            parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
        } | null;
    };
};

export type GqlClientUploadEditorAssetMediaFromUrlMutationVariables = Exact<{
    input: GqlClientUploadEditorAssetMediaFromUrlInput;
}>;

export type GqlClientUploadEditorAssetMediaFromUrlMutation = {
    __typename?: "Mutation";
    uploadEditorAssetMediaFromUrl: {
        __typename?: "UploadEditorAssetMediaFromUrlOutput";
        result: GqlClientUploadEditorAssetMediaFromUrlResult;
        editorAssetMedia?: {
            __typename?: "EditorAssetMedia";
            id: string;
            name: string;
            created: string;
            updated: string;
            mediaLocation: string;
            mediaUrl: string;
            mediaPreviewUrl: string;
            mediaAgifUrl?: string | null;
            mediaOrigin: GqlClientMediaOrigin;
            mediaMetadata: any;
            mediaProcessingInfo?: any | null;
            mediaFramesThumbnailsUrl?: string | null;
            originalEditInformation?: any | null;
            originalMediaId?: string | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            mediaSri?: string | null;
            externalLibraryAssetId?: string | null;
            externalLibrary?: GqlClientExternalLibrary | null;
            externalSublibrary?: GqlClientExternalSubLibrary | null;
            directAncestorId?: string | null;
            mediaClassification: GqlClientMediaClassification;
            description?: string | null;
            editorAssetLibraryVersion: { __typename?: "EditorAssetLibraryVersion"; id: string; media: Array<{ __typename?: "EditorAssetMedia"; id: string }> };
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
        } | null;
    };
};

export type GqlClientUploadEditorAssetMusicMutationVariables = Exact<{
    input: GqlClientUploadEditorAssetMusicInput;
}>;

export type GqlClientUploadEditorAssetMusicMutation = {
    __typename?: "Mutation";
    uploadEditorAssetMusic: {
        __typename?: "UploadEditorAssetMusicOutput";
        result: GqlClientUploadEditorAssetMusicResult;
        editorAssetMusic?: {
            __typename?: "EditorAssetMusic";
            id: string;
            name: string;
            url: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            externalLibraryAssetId?: string | null;
            musicOrigin: GqlClientMusicOrigin;
            editorAssetLibraryVersion: {
                __typename?: "EditorAssetLibraryVersion";
                id: string;
                music: Array<{
                    __typename?: "EditorAssetMusic";
                    id: string;
                    name: string;
                    url: string;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    externalLibraryAssetId?: string | null;
                    musicOrigin: GqlClientMusicOrigin;
                } | null>;
            };
        } | null;
    };
};

export type GqlClientUploadExternalEditorAssetImageMutationVariables = Exact<{
    input: GqlClientUploadExternalEditorAssetImageInput;
}>;

export type GqlClientUploadExternalEditorAssetImageMutation = {
    __typename?: "Mutation";
    uploadExternalEditorAssetImage: {
        __typename?: "UploadExternalEditorAssetMediaOutput";
        result: GqlClientUploadExternalEditorAssetMediaResult;
        editorAssetMedia?: {
            __typename?: "EditorAssetMedia";
            id: string;
            name: string;
            created: string;
            updated: string;
            mediaLocation: string;
            mediaUrl: string;
            mediaPreviewUrl: string;
            mediaAgifUrl?: string | null;
            mediaOrigin: GqlClientMediaOrigin;
            mediaMetadata: any;
            mediaProcessingInfo?: any | null;
            mediaFramesThumbnailsUrl?: string | null;
            originalEditInformation?: any | null;
            originalMediaId?: string | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            mediaSri?: string | null;
            externalLibraryAssetId?: string | null;
            externalLibrary?: GqlClientExternalLibrary | null;
            externalSublibrary?: GqlClientExternalSubLibrary | null;
            directAncestorId?: string | null;
            mediaClassification: GqlClientMediaClassification;
            description?: string | null;
            editorAssetLibraryVersion: { __typename?: "EditorAssetLibraryVersion"; id: string; media: Array<{ __typename?: "EditorAssetMedia"; id: string }> };
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
        } | null;
    };
};

export type GqlClientUploadExternalEditorAssetVideoMutationVariables = Exact<{
    input: GqlClientUploadExternalEditorAssetVideoInput;
}>;

export type GqlClientUploadExternalEditorAssetVideoMutation = {
    __typename?: "Mutation";
    uploadExternalEditorAssetVideo: {
        __typename?: "UploadExternalEditorAssetMediaOutput";
        result: GqlClientUploadExternalEditorAssetMediaResult;
        editorAssetMedia?: {
            __typename?: "EditorAssetMedia";
            id: string;
            name: string;
            created: string;
            updated: string;
            mediaLocation: string;
            mediaUrl: string;
            mediaPreviewUrl: string;
            mediaAgifUrl?: string | null;
            mediaOrigin: GqlClientMediaOrigin;
            mediaMetadata: any;
            mediaProcessingInfo?: any | null;
            mediaFramesThumbnailsUrl?: string | null;
            originalEditInformation?: any | null;
            originalMediaId?: string | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            mediaSri?: string | null;
            externalLibraryAssetId?: string | null;
            externalLibrary?: GqlClientExternalLibrary | null;
            externalSublibrary?: GqlClientExternalSubLibrary | null;
            directAncestorId?: string | null;
            mediaClassification: GqlClientMediaClassification;
            description?: string | null;
            editorAssetLibraryVersion: { __typename?: "EditorAssetLibraryVersion"; id: string; media: Array<{ __typename?: "EditorAssetMedia"; id: string }> };
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
        } | null;
    };
};

export type GqlClientUploadOptimizedEditorAssetMediaMutationVariables = Exact<{
    input: GqlClientUploadEditorAssetMediaInput;
    mutationId: Scalars["String"]["input"];
}>;

export type GqlClientUploadOptimizedEditorAssetMediaMutation = {
    __typename?: "Mutation";
    uploadOptimizedEditorAssetMedia: { __typename?: "UploadOptimizedEditorAssetMediaOutput"; result: GqlClientUploadOptimizedEditorAssetMediaResult };
};

export type GqlClientUploadRecordingToS3MutationVariables = Exact<{
    input: GqlClientUploadRecordingToS3Input;
    mutationId: Scalars["String"]["input"];
}>;

export type GqlClientUploadRecordingToS3Mutation = { __typename?: "Mutation"; uploadRecordingToS3: { __typename?: "UploadRecordingToS3Output"; result: GqlClientUploadRecordingToS3Result } };

export type GqlClientUploadStockEditorAssetMusicMutationVariables = Exact<{
    input: GqlClientUploadStockEditorAssetMusicInput;
}>;

export type GqlClientUploadStockEditorAssetMusicMutation = {
    __typename?: "Mutation";
    uploadStockEditorAssetMusic: {
        __typename?: "UploadStockEditorAssetMusicOutput";
        result: GqlClientUploadStockEditorAssetMusicResult;
        editorAssetMusic?: {
            __typename?: "EditorAssetMusic";
            id: string;
            name: string;
            url: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            externalLibraryAssetId?: string | null;
            musicOrigin: GqlClientMusicOrigin;
            editorAssetLibraryVersion: {
                __typename?: "EditorAssetLibraryVersion";
                id: string;
                music: Array<{
                    __typename?: "EditorAssetMusic";
                    id: string;
                    name: string;
                    url: string;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    externalLibraryAssetId?: string | null;
                    musicOrigin: GqlClientMusicOrigin;
                } | null>;
            };
        } | null;
    };
};

export type GqlClientUpsertUserSettingsMutationVariables = Exact<{
    upsertUserSettingsInput: GqlClientUpsertUserSettingsInput;
}>;

export type GqlClientUpsertUserSettingsMutation = {
    __typename?: "Mutation";
    upsertUserSettings: {
        __typename?: "UpsertUserSettingsOutput";
        result: GqlClientUpsertUserSettingsResult;
        userSettings: { __typename?: "UserSettings"; id: string; externalLibraries: any; acceptedAiTerms?: boolean | null; acceptedGenerativeAiTerms?: boolean | null };
    };
};

export type GqlClientUpsertHelpCenterEntryMutationVariables = Exact<{
    input: GqlClientUpsertHelpCenterEntryInput;
}>;

export type GqlClientUpsertHelpCenterEntryMutation = {
    __typename?: "Mutation";
    upsertHelpCenterEntry?: {
        __typename?: "UpsertHelpCenterEntryOutput";
        result?: GqlClientUpsertHelpCenterEntryResult | null;
        entry?: { __typename?: "HelpCenterEntry"; id: string; articleId: string; sectionId?: string | null } | null;
    } | null;
};

export type GqlClientUpsertRptvUserMutationVariables = Exact<{
    email: Scalars["String"]["input"];
    tokens: Scalars["Int"]["input"];
}>;

export type GqlClientUpsertRptvUserMutation = {
    __typename?: "Mutation";
    upsertRPTVUser?: { __typename?: "UpsertRPTVUserOutput"; result?: GqlClientUpsertRptvUserResult | null; user?: { __typename?: "RPTVUser"; id: string; tokens: number } | null } | null;
};

export type GqlClientStoryTemplatesReviewGalleryStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientStoryTemplatesReviewGalleryStatusQuery = {
    __typename?: "Query";
    storyTemplatesReviewGalleryStatus: Array<{ __typename?: "StoryTemplateStatus"; storyTemplateId: string; indication: GqlClientStoryTemplatesReviewGalleryIndication } | null>;
};

export type GqlClientAccountCcLibraryVersionQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountCcLibraryVersionQuery = {
    __typename?: "Query";
    account?: { __typename?: "Account"; id: string; ccLibraryVersion?: { __typename?: "CcLibraryVersion"; id: string; library: { __typename?: "CcLibrary"; id: string } } | null } | null;
};

export type GqlClientAccountCopilotBriefsQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountCopilotBriefsQuery = {
    __typename?: "Query";
    account?: {
        __typename?: "Account";
        id: string;
        copilotUserBriefs: Array<{ __typename?: "AccountCopilotBrief"; id: string; name: string; briefData: any; createdBy: string; created: string }>;
        copilotOtherUsersBriefs: Array<{ __typename?: "AccountCopilotBrief"; id: string; name: string; briefData: any; createdBy: string; created: string }>;
    } | null;
};

export type GqlClientAccountDataLibrariesQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountDataLibrariesQuery = {
    __typename?: "Query";
    account?: {
        __typename?: "Account";
        id: string;
        dataLibraries: Array<{
            __typename?: "AccountDataLibrary";
            id: string;
            name: string;
            description?: string | null;
            anyUpdated: string;
            anyUpdatedBy?: string | null;
            draftAccountDataLibraryVersion: {
                __typename?: "AccountDataLibraryVersion";
                id: string;
                localId: string;
                data: any;
                anyUpdated: string;
                anyUpdatedBy?: string | null;
                usedInVideos: Array<{
                    __typename?: "EditorProgram";
                    id: string;
                    name: string;
                    isArchive?: boolean | null;
                    isLive?: boolean | null;
                    programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                    prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    shareHistory: Array<{
                        __typename?: "ProgramShareHistory";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        accountId: string;
                        shareType: GqlClientProgramShareType;
                        isExternal?: boolean | null;
                    }>;
                }>;
                dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
            };
        }>;
    } | null;
};

export type GqlClientAccountDataLibrariesPublishedVideosQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountDataLibrariesPublishedVideosQuery = {
    __typename?: "Query";
    account?: {
        __typename?: "Account";
        id: string;
        dataLibraries: Array<{
            __typename?: "AccountDataLibrary";
            id: string;
            name: string;
            publishedVideosTargetLibrary: Array<{
                __typename?: "EditorProgram";
                id: string;
                name: string;
                prodVersion?: {
                    __typename?: "LifecycleHistoryEntry";
                    id: string;
                    created: string;
                    editorProgramVersion?: {
                        __typename?: "EditorProgramVersion";
                        id: string;
                        accountDataLibraryVersion?: { __typename?: "AccountDataLibraryVersion"; id: string; data: any } | null;
                        thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
                    } | null;
                } | null;
                shareHistory: Array<{
                    __typename?: "ProgramShareHistory";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    accountId: string;
                    shareType: GqlClientProgramShareType;
                    isExternal?: boolean | null;
                }>;
            }>;
            publishedVideosUsingLibrary: Array<{
                __typename?: "EditorProgram";
                id: string;
                name: string;
                prodVersion?: {
                    __typename?: "LifecycleHistoryEntry";
                    id: string;
                    created: string;
                    editorProgramVersion?: {
                        __typename?: "EditorProgramVersion";
                        id: string;
                        usedElements?: any | null;
                        accountDataLibraryVersion?: { __typename?: "AccountDataLibraryVersion"; id: string; data: any } | null;
                        thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
                    } | null;
                } | null;
                shareHistory: Array<{
                    __typename?: "ProgramShareHistory";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    accountId: string;
                    shareType: GqlClientProgramShareType;
                    isExternal?: boolean | null;
                }>;
            }>;
        }>;
    } | null;
};

export type GqlClientAccountDataLibraryForImportDataQueryVariables = Exact<{
    libraryId: Scalars["ID"]["input"];
}>;

export type GqlClientAccountDataLibraryForImportDataQuery = {
    __typename?: "Query";
    accountDataLibrary?: {
        __typename?: "AccountDataLibrary";
        id: string;
        name: string;
        publishedVideosUsingLibrary: Array<{
            __typename?: "EditorProgram";
            id: string;
            name: string;
            prodVersion?: {
                __typename?: "LifecycleHistoryEntry";
                id: string;
                created: string;
                editorProgramVersion?: {
                    __typename?: "EditorProgramVersion";
                    id: string;
                    usedElements?: any | null;
                    thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
                } | null;
            } | null;
        }>;
    } | null;
};

export type GqlClientAccountServiceConnectionsQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountServiceConnectionsQuery = {
    __typename?: "Query";
    account?: { __typename?: "Account"; id: string; accountServiceConnections: Array<{ __typename?: "AccountServiceConnection"; id: string; serviceType: GqlClientServiceType }> } | null;
};

export type GqlClientAcquiaDamAssetsQueryVariables = Exact<{
    categoryPath?: InputMaybe<Scalars["String"]["input"]>;
    pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
    sortBy?: InputMaybe<GqlClientAcquiaAssetSortBy>;
    sortDirection?: InputMaybe<GqlClientAcquiaAssetSortDirection>;
    mediaType?: InputMaybe<GqlClientAcquiaAssetMediaType>;
    orientation?: InputMaybe<GqlClientAcquiaAssetOrientation>;
    searchTerm?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientAcquiaDamAssetsQuery = {
    __typename?: "Query";
    acquiaAssets: {
        __typename?: "AcquiaAssetsOutput";
        resultCount: number;
        assets: Array<{
            __typename?: "AcquiaAssetMedia";
            id: string;
            name: string;
            type: GqlClientAcquiaAssetType;
            created?: string | null;
            updated?: string | null;
            mediaUrl: string;
            downloadUri: string;
            thumbnailUri: string;
            largeThumbnailUrl?: string | null;
            mediaMetadata: { __typename?: "AcquiaAssetMetadata"; format: string; sizeInKb: number; width: number; height: number; duration?: number | null };
        }>;
    };
};

export type GqlClientAcquiaDamSubCategoriesQueryVariables = Exact<{
    path: Scalars["String"]["input"];
}>;

export type GqlClientAcquiaDamSubCategoriesQuery = {
    __typename?: "Query";
    acquiaCategory: {
        __typename?: "AcquiaCategory";
        id: string;
        name: string;
        path: string;
        parentCategoryPath?: string | null;
        subCategories: Array<{ __typename?: "AcquiaCategory"; id: string; name: string; path: string; parentCategoryPath?: string | null }>;
    };
};

export type GqlClientAiTextEditingQueryVariables = Exact<{
    input: GqlClientAiTextEditingInput;
}>;

export type GqlClientAiTextEditingQuery = { __typename?: "Query"; aiTextEditing: { __typename?: "AiTextEditingOutput"; modifiedText: string; result: GqlClientAiTextEditingInputResult } };

export type GqlClientBrandConfigurationCollectionUsedInProgramsQueryVariables = Exact<{
    bcCollectionId: Scalars["ID"]["input"];
}>;

export type GqlClientBrandConfigurationCollectionUsedInProgramsQuery = {
    __typename?: "Query";
    brandConfigurationCollectionUsedInPrograms: Array<{
        __typename?: "EditorProgram";
        id: string;
        name: string;
        isArchive?: boolean | null;
        programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; aspectRatio: GqlClientEnumAspectRatio; thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null };
        prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
        uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
    }>;
};

export type GqlClientBrandConfigurationCollectionsUsedInProgramsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientBrandConfigurationCollectionsUsedInProgramsQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            brandConfigurationVersion: {
                __typename?: "BrandConfigurationVersion";
                id: string;
                brandConfigurationCollections: Array<{
                    __typename?: "BrandConfigurationCollection";
                    id: string;
                    usedInPrograms: Array<{ __typename?: "EditorProgram"; id: string; programVersionDraft: { __typename?: "EditorProgramVersion"; id: string } }>;
                }>;
            };
        } | null;
    } | null;
};

export type GqlClientCcLibraryVersionQueryVariables = Exact<{
    libraryId: Scalars["ID"]["input"];
    libraryVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientCcLibraryVersionQuery = {
    __typename?: "Query";
    ccLibrary?: {
        __typename?: "CcLibrary";
        id: string;
        libraryVersion?: {
            __typename?: "CcLibraryVersion";
            id: string;
            themes: Array<{
                __typename?: "CcTheme";
                id: string;
                name: string;
                posterUrl?: string | null;
                isComingSoon?: boolean | null;
                editorOrder?: number | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                supportedAspectRatios: Array<GqlClientEnumAspectRatio>;
                supportsBackgroundMedia: boolean;
                dofValues: Array<{
                    __typename?: "CcDofValue";
                    id: string;
                    value: string;
                    dof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
                }>;
            }>;
            wireframes: Array<{
                __typename?: "CcWireframe";
                id: string;
                name: string;
                description?: string | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                comment?: string | null;
                category: GqlClientCcWireframeCategory;
                frames: Array<{
                    __typename?: "CcFrame";
                    id: string;
                    placeholders: Array<{
                        __typename?: "CcPlaceholder";
                        id: string;
                        name: string;
                        content?: any | null;
                        category: GqlClientCcPlaceholderCategory;
                        intent: {
                            __typename?: "CcPlaceholderIntent";
                            id: string;
                            name: string;
                            type: GqlClientCcPlaceholderType;
                            defaultValue?: string | null;
                            textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                        };
                    }>;
                }>;
                animatedWireframes: Array<{
                    __typename?: "CcAnimatedWireframe";
                    id: string;
                    name: string;
                    vsmlUrl: string;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    aspectRatio: GqlClientEnumAspectRatio;
                    compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                    defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                    theme: { __typename?: "CcTheme"; id: string };
                    placeholdersSettings: Array<
                        | {
                              __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                              id: string;
                              horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                              verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                              mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                              canvasName?: string | null;
                              ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                          }
                        | {
                              __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                              id: string;
                              horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                              verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                              canvasName?: string | null;
                              colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                              ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                          }
                    >;
                    m2AnimatedWireframe?: {
                        __typename?: "CcAnimatedWireframe";
                        id: string;
                        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                        vsmlUrl: string;
                        defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                    } | null;
                    composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
                }>;
            }>;
            music: Array<{ __typename?: "CcMusic"; id: string; name: string; url: string }>;
            narrators: Array<{
                __typename?: "CcNarrator";
                id: string;
                name: string;
                sampleUrl: string;
                narrationSystemName: string;
                narrationSystemVoice: string;
                intent: GqlClientCcNarratorIntent;
                narrationSystemMetadata: {
                    __typename?: "CcNarrationSystemMetadata";
                    language: string;
                    gender: string;
                    rate?: number | null;
                    volume?: number | null;
                    age?: string | null;
                    description?: Array<string> | null;
                };
            }>;
            avatars: Array<{
                __typename?: "CcAvatar";
                id: string;
                name: string;
                sampleUrl: string;
                thumbnailUrl: string;
                localId: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                sampleVideoMetadata: any;
                narrator?: {
                    __typename?: "CcNarrator";
                    id: string;
                    name: string;
                    sampleUrl: string;
                    narrationSystemName: string;
                    narrationSystemVoice: string;
                    intent: GqlClientCcNarratorIntent;
                    narrationSystemMetadata: {
                        __typename?: "CcNarrationSystemMetadata";
                        language: string;
                        gender: string;
                        rate?: number | null;
                        volume?: number | null;
                        age?: string | null;
                        description?: Array<string> | null;
                    };
                } | null;
                fullBodyCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
                closeUpCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
            }>;
            fonts: Array<{
                __typename?: "CcFont";
                id: string;
                name: string;
                url: string;
                hasSmallCaps?: boolean | null;
                lifecycleStage?: GqlClientAssetLifecycleStage | null;
                webFontUrl?: string | null;
                created: string;
                familyName?: string | null;
                isBold?: boolean | null;
                isItalic?: boolean | null;
                weight?: number | null;
            }>;
            textStyles: Array<{ __typename?: "CcTextStyle"; id: string; name: string }>;
            placeholderIntents: Array<{
                __typename?: "CcPlaceholderIntent";
                id: string;
                name: string;
                type: GqlClientCcPlaceholderType;
                defaultValue?: string | null;
                textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
            }>;
        } | null;
    } | null;
};

export type GqlClientCcWireframeAllWireframesMappingQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
    sceneId: Scalars["ID"]["input"];
}>;

export type GqlClientCcWireframeAllWireframesMappingQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            scene?: {
                __typename?: "EditorScene";
                id: string;
                ccWireframe: {
                    __typename?: "CcWireframe";
                    id: string;
                    allWireframesMapping: Array<{
                        __typename?: "CcWireframeAllWireframesData";
                        isLosingData: boolean;
                        wireframe: {
                            __typename?: "CcWireframe";
                            id: string;
                            name: string;
                            lifecycleStage: GqlClientAssetLifecycleStage;
                            comment?: string | null;
                            category: GqlClientCcWireframeCategory;
                        };
                        placeholdersData: Array<{
                            __typename?: "CcWireframePlaceholderData";
                            targetCcPlaceholder: {
                                __typename?: "CcPlaceholder";
                                id: string;
                                name: string;
                                content?: any | null;
                                category: GqlClientCcPlaceholderCategory;
                                intent: {
                                    __typename?: "CcPlaceholderIntent";
                                    id: string;
                                    name: string;
                                    type: GqlClientCcPlaceholderType;
                                    defaultValue?: string | null;
                                    textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                                };
                            };
                            originalCcPlaceholder?: { __typename?: "CcPlaceholder"; id: string } | null;
                        } | null>;
                    }>;
                };
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorAccountLevelDataAvatarsQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientEditorAccountLevelDataAvatarsQuery = {
    __typename?: "Query";
    editorAccountLevelData: {
        __typename?: "EditorAccountLevelData";
        id: string;
        assetLibraryVersion?: {
            __typename?: "EditorAssetLibraryVersion";
            id: string;
            avatars: Array<{
                __typename?: "EditorAccountLevelAvatar";
                id: string;
                localId: string;
                name: string;
                description?: string | null;
                avatarSystemName: string;
                thumbnailUrl?: string | null;
                sampleUrl?: string | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                lifecycleMessage?: string | null;
                created: string;
                sampleVideoMetadata?: any | null;
                fullBodyCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
                closeUpCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
                narrator?: {
                    __typename?: "EditorAccountLevelNarrator";
                    id: string;
                    localId: string;
                    name: string;
                    language?: string | null;
                    description?: string | null;
                    sampleUrl?: string | null;
                    sampleTranscript?: string | null;
                    narrationSystemName?: string | null;
                    narrationSystemVoice?: string | null;
                    narrationSystemMetadata?: any | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                } | null;
            } | null>;
        } | null;
    };
};

export type GqlClientEditorAccountLevelDataBrandsQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientEditorAccountLevelDataBrandsQuery = {
    __typename?: "Query";
    editorAccountLevelData: {
        __typename?: "EditorAccountLevelData";
        id: string;
        brandConfigurationVersion?: {
            __typename?: "BrandConfigurationVersion";
            id: string;
            brandConfigurationCollections: Array<{
                __typename?: "BrandConfigurationCollection";
                id: string;
                localId: string;
                updated: string;
                updatedBy?: string | null;
                created: string;
                name: string;
                description?: string | null;
                logoUrl?: string | null;
                logoLocation?: string | null;
                buttonShape: GqlClientButtonShapeValues;
                logoMedia?: {
                    __typename?: "EditorAssetMedia";
                    id: string;
                    name: string;
                    created: string;
                    updated: string;
                    mediaLocation: string;
                    mediaUrl: string;
                    mediaPreviewUrl: string;
                    mediaAgifUrl?: string | null;
                    mediaOrigin: GqlClientMediaOrigin;
                    mediaMetadata: any;
                    mediaProcessingInfo?: any | null;
                    mediaFramesThumbnailsUrl?: string | null;
                    originalEditInformation?: any | null;
                    originalMediaId?: string | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    mediaSri?: string | null;
                    externalLibraryAssetId?: string | null;
                    externalLibrary?: GqlClientExternalLibrary | null;
                    externalSublibrary?: GqlClientExternalSubLibrary | null;
                    directAncestorId?: string | null;
                    mediaClassification: GqlClientMediaClassification;
                    description?: string | null;
                    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                    folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                } | null;
                colors: Array<{
                    __typename?: "BcColor";
                    id: string;
                    updated: string;
                    color: string;
                    ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
                }>;
                textStyles: Array<{
                    __typename?: "BcTextStyle";
                    id: string;
                    fontSource: GqlClientEditorAssetSource;
                    letterCase: GqlClientTextStyleLetterCase;
                    letterSpacing: number;
                    lineSpacing: number;
                    updated: string;
                    ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                    ccFont?: {
                        __typename?: "CcFont";
                        id: string;
                        name: string;
                        url: string;
                        hasSmallCaps?: boolean | null;
                        lifecycleStage?: GqlClientAssetLifecycleStage | null;
                        webFontUrl?: string | null;
                        created: string;
                        familyName?: string | null;
                        isBold?: boolean | null;
                        isItalic?: boolean | null;
                        weight?: number | null;
                    } | null;
                    accountFont?: {
                        __typename?: "EditorAssetFont";
                        id: string;
                        name: string;
                        url?: string | null;
                        imageUrl?: string | null;
                        webFontUrl?: string | null;
                        hasSmallCaps?: boolean | null;
                        lifecycleStage?: GqlClientAssetLifecycleStage | null;
                        uploadId?: string | null;
                        created: string;
                        familyName?: string | null;
                        isBold?: boolean | null;
                        isItalic?: boolean | null;
                        weight?: number | null;
                    } | null;
                }>;
            }>;
            defaultBrandConfigurationCollection: { __typename?: "BrandConfigurationCollection"; id: string };
        } | null;
    };
};

export type GqlClientEditorAccountLevelDataFontsQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientEditorAccountLevelDataFontsQuery = {
    __typename?: "Query";
    editorAccountLevelData: {
        __typename?: "EditorAccountLevelData";
        id: string;
        assetLibraryVersion?: {
            __typename?: "EditorAssetLibraryVersion";
            id: string;
            fonts: Array<{
                __typename?: "EditorAssetFont";
                id: string;
                name: string;
                url?: string | null;
                imageUrl?: string | null;
                webFontUrl?: string | null;
                hasSmallCaps?: boolean | null;
                lifecycleStage?: GqlClientAssetLifecycleStage | null;
                uploadId?: string | null;
                created: string;
                familyName?: string | null;
                isBold?: boolean | null;
                isItalic?: boolean | null;
                weight?: number | null;
            } | null>;
        } | null;
    };
};

export type GqlClientEditorAccountLevelDataMediaQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientEditorAccountLevelDataMediaQuery = {
    __typename?: "Query";
    editorAccountLevelData: {
        __typename?: "EditorAccountLevelData";
        id: string;
        assetLibraryVersion?: {
            __typename?: "EditorAssetLibraryVersion";
            id: string;
            media: Array<{
                __typename?: "EditorAssetMedia";
                id: string;
                name: string;
                created: string;
                updated: string;
                mediaLocation: string;
                mediaUrl: string;
                mediaPreviewUrl: string;
                mediaAgifUrl?: string | null;
                mediaOrigin: GqlClientMediaOrigin;
                mediaMetadata: any;
                mediaProcessingInfo?: any | null;
                mediaFramesThumbnailsUrl?: string | null;
                originalEditInformation?: any | null;
                originalMediaId?: string | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                mediaSri?: string | null;
                externalLibraryAssetId?: string | null;
                externalLibrary?: GqlClientExternalLibrary | null;
                externalSublibrary?: GqlClientExternalSubLibrary | null;
                directAncestorId?: string | null;
                mediaClassification: GqlClientMediaClassification;
                description?: string | null;
                createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
            }>;
            mediaFolders: Array<{
                __typename?: "EditorAssetFolder";
                id: string;
                name: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                updatedBy?: string | null;
                parentFolder?: { __typename?: "EditorAssetFolder"; id: string } | null;
            }>;
        } | null;
    };
};

export type GqlClientEditorAccountLevelDataMusicQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientEditorAccountLevelDataMusicQuery = {
    __typename?: "Query";
    editorAccountLevelData: {
        __typename?: "EditorAccountLevelData";
        id: string;
        assetLibraryVersion?: {
            __typename?: "EditorAssetLibraryVersion";
            id: string;
            music: Array<{
                __typename?: "EditorAssetMusic";
                id: string;
                name: string;
                url: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                externalLibraryAssetId?: string | null;
                musicOrigin: GqlClientMusicOrigin;
            } | null>;
        } | null;
    };
};

export type GqlClientEditorAccountUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientEditorAccountUsersQuery = {
    __typename?: "Query";
    currentAccount?: {
        __typename?: "Account";
        id: string;
        maxCapacityReached: boolean;
        users: Array<{
            __typename?: "AccountUser";
            id: string;
            roles: Array<GqlClientUserAccountRole>;
            invitationStatus: GqlClientAccountInvitationStatus;
            user: {
                __typename?: "EditorAccountUser";
                id: string;
                email: string;
                firstName?: string | null;
                lastName?: string | null;
                businessRole?: GqlClientBusinessRole | null;
                roles?: Array<GqlClientUserRole> | null;
                lastLogin?: string | null;
                created?: string | null;
                hasMultipleAccounts?: boolean | null;
            };
        }>;
    } | null;
};

export type GqlClientEditorAssetLibraryAccountMediaQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorAssetLibraryAccountMediaQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            assetLibrary: {
                __typename?: "EditorAssetLibraryVersion";
                id: string;
                media: Array<{
                    __typename?: "EditorAssetMedia";
                    id: string;
                    name: string;
                    created: string;
                    updated: string;
                    mediaLocation: string;
                    mediaUrl: string;
                    mediaPreviewUrl: string;
                    mediaAgifUrl?: string | null;
                    mediaOrigin: GqlClientMediaOrigin;
                    mediaMetadata: any;
                    mediaProcessingInfo?: any | null;
                    mediaFramesThumbnailsUrl?: string | null;
                    originalEditInformation?: any | null;
                    originalMediaId?: string | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    mediaSri?: string | null;
                    externalLibraryAssetId?: string | null;
                    externalLibrary?: GqlClientExternalLibrary | null;
                    externalSublibrary?: GqlClientExternalSubLibrary | null;
                    directAncestorId?: string | null;
                    mediaClassification: GqlClientMediaClassification;
                    description?: string | null;
                    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                    folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                }>;
                mediaFolders: Array<{
                    __typename?: "EditorAssetFolder";
                    id: string;
                    name: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    parentFolder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                }>;
            };
        } | null;
    } | null;
};

export type GqlClientEditorAssetLibraryMusicQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorAssetLibraryMusicQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            assetLibrary: {
                __typename?: "EditorAssetLibraryVersion";
                id: string;
                music: Array<{
                    __typename?: "EditorAssetMusic";
                    id: string;
                    name: string;
                    url: string;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    externalLibraryAssetId?: string | null;
                    musicOrigin: GqlClientMusicOrigin;
                } | null>;
            };
        } | null;
    } | null;
};

export type GqlClientProjectAudioSettingsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProjectAudioSettingsQuery = {
    __typename?: "Query";
    editorProgram?: { __typename?: "EditorProgram"; id: string; programVersion?: { __typename?: "EditorProgramVersion"; id: string; audioSettings?: any | null } | null } | null;
};

export type GqlClientEditorBrandsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorBrandsQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            brandConfigurationVersion: {
                __typename?: "BrandConfigurationVersion";
                id: string;
                brandConfigurationCollections: Array<{
                    __typename?: "BrandConfigurationCollection";
                    id: string;
                    localId: string;
                    updated: string;
                    updatedBy?: string | null;
                    created: string;
                    name: string;
                    description?: string | null;
                    logoUrl?: string | null;
                    logoLocation?: string | null;
                    buttonShape: GqlClientButtonShapeValues;
                    logoMedia?: {
                        __typename?: "EditorAssetMedia";
                        id: string;
                        name: string;
                        created: string;
                        updated: string;
                        mediaLocation: string;
                        mediaUrl: string;
                        mediaPreviewUrl: string;
                        mediaAgifUrl?: string | null;
                        mediaOrigin: GqlClientMediaOrigin;
                        mediaMetadata: any;
                        mediaProcessingInfo?: any | null;
                        mediaFramesThumbnailsUrl?: string | null;
                        originalEditInformation?: any | null;
                        originalMediaId?: string | null;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        mediaSri?: string | null;
                        externalLibraryAssetId?: string | null;
                        externalLibrary?: GqlClientExternalLibrary | null;
                        externalSublibrary?: GqlClientExternalSubLibrary | null;
                        directAncestorId?: string | null;
                        mediaClassification: GqlClientMediaClassification;
                        description?: string | null;
                        createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                        folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                    } | null;
                    colors: Array<{
                        __typename?: "BcColor";
                        id: string;
                        updated: string;
                        color: string;
                        ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
                    }>;
                    textStyles: Array<{
                        __typename?: "BcTextStyle";
                        id: string;
                        fontSource: GqlClientEditorAssetSource;
                        letterCase: GqlClientTextStyleLetterCase;
                        letterSpacing: number;
                        lineSpacing: number;
                        updated: string;
                        ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                        ccFont?: {
                            __typename?: "CcFont";
                            id: string;
                            name: string;
                            url: string;
                            hasSmallCaps?: boolean | null;
                            lifecycleStage?: GqlClientAssetLifecycleStage | null;
                            webFontUrl?: string | null;
                            created: string;
                            familyName?: string | null;
                            isBold?: boolean | null;
                            isItalic?: boolean | null;
                            weight?: number | null;
                        } | null;
                        accountFont?: {
                            __typename?: "EditorAssetFont";
                            id: string;
                            name: string;
                            url?: string | null;
                            imageUrl?: string | null;
                            webFontUrl?: string | null;
                            hasSmallCaps?: boolean | null;
                            lifecycleStage?: GqlClientAssetLifecycleStage | null;
                            uploadId?: string | null;
                            created: string;
                            familyName?: string | null;
                            isBold?: boolean | null;
                            isItalic?: boolean | null;
                            weight?: number | null;
                        } | null;
                    }>;
                }>;
            };
        } | null;
    } | null;
};

export type GqlClientEditorDataLibraryGenerateLinksQueryVariables = Exact<{
    input: GqlClientDataLibraryGenerateLinksFromSpecificImportsInput;
}>;

export type GqlClientEditorDataLibraryGenerateLinksQuery = { __typename?: "Query"; dataLibraryGenerateLinksFromSpecificImports: string };

export type GqlClientEditorDataLibraryImportHistoryQueryVariables = Exact<{
    dataLibraryId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorDataLibraryImportHistoryQuery = {
    __typename?: "Query";
    dataLibraryGetImportHistory?: {
        __typename?: "DataLibraryImportHistory";
        id: string;
        dataLibraryId: string;
        importStatusMetadataItems: Array<{
            __typename?: "DataLibraryImportHistoryItem";
            id: string;
            status: GqlClientImportTaskStatus;
            user: string;
            fileName: string;
            date: string;
            countFailures?: number | null;
            countSuccesses?: number | null;
        }>;
    } | null;
};

export type GqlClientEditorDefaultBrandIdQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorDefaultBrandIdQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            brandConfigurationVersion: {
                __typename?: "BrandConfigurationVersion";
                id: string;
                defaultBrandConfigurationCollection: { __typename?: "BrandConfigurationCollection"; id: string; localId: string };
            };
        } | null;
    } | null;
};

export type GqlClientEditorGettyMediaQueryVariables = Exact<{
    includeImages: Scalars["Boolean"]["input"];
    includeVideos: Scalars["Boolean"]["input"];
    searchTerm: Scalars["String"]["input"];
    orientation?: InputMaybe<GqlClientGettyOrientation>;
    minClipDuration?: InputMaybe<Scalars["Int"]["input"]>;
    maxClipDuration?: InputMaybe<Scalars["Int"]["input"]>;
    pageNumber: Scalars["Int"]["input"];
    sortOrder: GqlClientGettySortOrder;
}>;

export type GqlClientEditorGettyMediaQuery = {
    __typename?: "Query";
    gettySearchMetadata: {
        __typename?: "GettySearchMetadataOutput";
        resultCount: number;
        assets: Array<
            | {
                  __typename?: "GettyImageMetadata";
                  id: string;
                  title: string;
                  thumbUri?: string | null;
                  previewUri?: string | null;
                  largePreviewUri?: string | null;
                  largePreviewPosterUri?: string | null;
                  downloadSizes: Array<{ __typename?: "GettyImageDownloadSize"; name: string; bytes: any; height: number; media_type: string; width: number }>;
              }
            | {
                  __typename?: "GettyVideoMetadata";
                  id: string;
                  title: string;
                  thumbUri?: string | null;
                  previewUri?: string | null;
                  largePreviewUri?: string | null;
                  largePreviewPosterUri?: string | null;
                  duration: number;
                  downloadSizes: Array<{ __typename?: "GettyVideoDownloadSize"; width: number; height: number; bytes: any; name: string; content_type: string; description: string; format: string }>;
              }
        >;
    };
};

export type GqlClientLegacyAssetsBasePathQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientLegacyAssetsBasePathQuery = {
    __typename?: "Query";
    editorProgram?: { __typename?: "EditorProgram"; id: string; programVersion?: { __typename?: "EditorProgramVersion"; id: string; legacyAssetLocationBasePath?: string | null } | null } | null;
};

export type GqlClientEditorLibraryAnimatedWireframeQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorLibraryAnimatedWireframeQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            ccLibraryVersion?: {
                __typename?: "CcLibraryVersion";
                id: string;
                wireframes: Array<{
                    __typename?: "CcWireframe";
                    id: string;
                    animatedWireframes: Array<{
                        __typename?: "CcAnimatedWireframe";
                        id: string;
                        name: string;
                        vsmlUrl: string;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        aspectRatio: GqlClientEnumAspectRatio;
                        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                        defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                        theme: { __typename?: "CcTheme"; id: string };
                        placeholdersSettings: Array<
                            | {
                                  __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                                  id: string;
                                  horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                  verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                  mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                                  canvasName?: string | null;
                                  ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                              }
                            | {
                                  __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                                  id: string;
                                  horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                  verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                  canvasName?: string | null;
                                  colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                                  ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                              }
                        >;
                        m2AnimatedWireframe?: {
                            __typename?: "CcAnimatedWireframe";
                            id: string;
                            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                            vsmlUrl: string;
                            defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                        } | null;
                        composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
                    }>;
                }>;
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorLibraryAvatarsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorLibraryAvatarsQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            ccLibraryVersion?: {
                __typename?: "CcLibraryVersion";
                id: string;
                avatars: Array<{
                    __typename?: "CcAvatar";
                    id: string;
                    name: string;
                    sampleUrl: string;
                    thumbnailUrl: string;
                    localId: string;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    sampleVideoMetadata: any;
                    narrator?: {
                        __typename?: "CcNarrator";
                        id: string;
                        name: string;
                        sampleUrl: string;
                        narrationSystemName: string;
                        narrationSystemVoice: string;
                        intent: GqlClientCcNarratorIntent;
                        narrationSystemMetadata: {
                            __typename?: "CcNarrationSystemMetadata";
                            language: string;
                            gender: string;
                            rate?: number | null;
                            volume?: number | null;
                            age?: string | null;
                            description?: Array<string> | null;
                        };
                    } | null;
                    fullBodyCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
                    closeUpCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
                }>;
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorLibraryDofsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorLibraryDofsQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            ccLibraryVersion?: {
                __typename?: "CcLibraryVersion";
                id: string;
                dofs: Array<{ __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType }>;
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorLibraryMusicQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorLibraryMusicQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            ccLibraryVersion?: { __typename?: "CcLibraryVersion"; id: string; music: Array<{ __typename?: "CcMusic"; id: string; name: string; url: string }> } | null;
        } | null;
    } | null;
};

export type GqlClientEditorLibraryNarratorsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorLibraryNarratorsQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            ccLibraryVersion?: {
                __typename?: "CcLibraryVersion";
                id: string;
                narrators: Array<{
                    __typename?: "CcNarrator";
                    id: string;
                    name: string;
                    sampleUrl: string;
                    narrationSystemName: string;
                    narrationSystemVoice: string;
                    intent: GqlClientCcNarratorIntent;
                    narrationSystemMetadata: {
                        __typename?: "CcNarrationSystemMetadata";
                        language: string;
                        gender: string;
                        rate?: number | null;
                        volume?: number | null;
                        age?: string | null;
                        description?: Array<string> | null;
                    };
                }>;
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorLibraryPlaceholderIntentsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorLibraryPlaceholderIntentsQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            ccLibraryVersion?: {
                __typename?: "CcLibraryVersion";
                id: string;
                placeholderIntents: Array<{
                    __typename?: "CcPlaceholderIntent";
                    id: string;
                    name: string;
                    type: GqlClientCcPlaceholderType;
                    defaultValue?: string | null;
                    textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                }>;
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorLibraryTextStylesQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorLibraryTextStylesQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            ccLibraryVersion?: { __typename?: "CcLibraryVersion"; id: string; textStyles: Array<{ __typename?: "CcTextStyle"; id: string; name: string }> } | null;
        } | null;
    } | null;
};

export type GqlClientEditorLibraryThemesQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorLibraryThemesQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            ccLibraryVersion?: {
                __typename?: "CcLibraryVersion";
                id: string;
                themes: Array<{
                    __typename?: "CcTheme";
                    id: string;
                    name: string;
                    posterUrl?: string | null;
                    isComingSoon?: boolean | null;
                    editorOrder?: number | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    supportedAspectRatios: Array<GqlClientEnumAspectRatio>;
                    supportsBackgroundMedia: boolean;
                    dofValues: Array<{
                        __typename?: "CcDofValue";
                        id: string;
                        value: string;
                        dof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
                    }>;
                }>;
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorLibraryWireframesQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorLibraryWireframesQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            ccLibraryVersion?: {
                __typename?: "CcLibraryVersion";
                id: string;
                wireframes: Array<{
                    __typename?: "CcWireframe";
                    id: string;
                    name: string;
                    description?: string | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    comment?: string | null;
                    category: GqlClientCcWireframeCategory;
                    frames: Array<{
                        __typename?: "CcFrame";
                        id: string;
                        placeholders: Array<{
                            __typename?: "CcPlaceholder";
                            id: string;
                            name: string;
                            content?: any | null;
                            category: GqlClientCcPlaceholderCategory;
                            intent: {
                                __typename?: "CcPlaceholderIntent";
                                id: string;
                                name: string;
                                type: GqlClientCcPlaceholderType;
                                defaultValue?: string | null;
                                textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                            };
                        }>;
                    }>;
                    animatedWireframes: Array<{
                        __typename?: "CcAnimatedWireframe";
                        id: string;
                        name: string;
                        vsmlUrl: string;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        aspectRatio: GqlClientEnumAspectRatio;
                        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                        defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                        theme: { __typename?: "CcTheme"; id: string };
                        placeholdersSettings: Array<
                            | {
                                  __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                                  id: string;
                                  horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                  verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                  mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                                  canvasName?: string | null;
                                  ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                              }
                            | {
                                  __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                                  id: string;
                                  horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                  verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                  canvasName?: string | null;
                                  colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                                  ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                              }
                        >;
                        m2AnimatedWireframe?: {
                            __typename?: "CcAnimatedWireframe";
                            id: string;
                            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                            vsmlUrl: string;
                            defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                        } | null;
                        composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
                    }>;
                }>;
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorNarrationDurationQueryVariables = Exact<{
    text: Scalars["String"]["input"];
    narrator: Scalars["String"]["input"];
    service: Scalars["String"]["input"];
    rate?: InputMaybe<Scalars["Float"]["input"]>;
    avatarInfo?: InputMaybe<GqlClientAvatarInfo>;
}>;

export type GqlClientEditorNarrationDurationQuery = { __typename?: "Query"; editorNarrationDuration?: { __typename?: "NarrationDuration"; duration: number; isExact: boolean } | null };

export type GqlClientEditorProgramQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        lastArchived?: string | null;
        creationMethod?: string | null;
        originProgramId?: string | null;
        originalStoryTemplateProgramId?: string | null;
        originalStoryTemplateProgramVersionId?: string | null;
        id: string;
        name: string;
        description?: string | null;
        saleForceId?: string | null;
        programType: GqlClientEditorProgramType;
        publishTarget: GqlClientLifecycleStage;
        created: string;
        isArchive?: boolean | null;
        isLive?: boolean | null;
        firstViewedDate?: string | null;
        isReadyToGoLive: boolean;
        landingPageUrl?: string | null;
        folder?: { __typename?: "AccountProgramFolder"; id: string; name: string } | null;
        programVersionDraft: {
            __typename?: "EditorProgramVersion";
            id: string;
            aspectRatio: GqlClientEnumAspectRatio;
            isAvatarRendering: boolean;
            anyUpdatedBy?: string | null;
            anyUpdated?: string | null;
            publishable?: any | null;
            sceneLineup: Array<string>;
            editorDataConnectorEnabled?: boolean | null;
            chapteringEnabled?: boolean | null;
            editorTtsFullEnablement?: boolean | null;
            selectedMusicSource: GqlClientEditorAssetSource;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
            selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; updated: string; updatedBy?: string | null; name: string };
            selectedNarrator?: { __typename?: "CcNarrator"; id: string; narrationSystemName: string } | null;
            accountDataLibraryVersion?: {
                __typename?: "AccountDataLibraryVersion";
                id: string;
                data: any;
                anyUpdatedBy?: string | null;
                anyUpdated: string;
                dataFields: Array<{ __typename?: "AccountDataField"; id: string; data: any; localId: string }>;
                accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string };
            } | null;
            ccLibraryVersion?: { __typename?: "CcLibraryVersion"; id: string; library: { __typename?: "CcLibrary"; id: string } } | null;
            pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
        };
        uatVersion?: {
            __typename?: "LifecycleHistoryEntry";
            id: string;
            created: string;
            createdBy?: string | null;
            snapshotName?: string | null;
            snapshotNumber?: number | null;
            snapshotValidationData?: any | null;
            accountDataLibraryVersion?: {
                __typename?: "AccountDataLibraryVersion";
                id: string;
                localId: string;
                data: any;
                anyUpdated: string;
                anyUpdatedBy?: string | null;
                usedInVideos: Array<{
                    __typename?: "EditorProgram";
                    id: string;
                    name: string;
                    isArchive?: boolean | null;
                    isLive?: boolean | null;
                    programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                    prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    shareHistory: Array<{
                        __typename?: "ProgramShareHistory";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        accountId: string;
                        shareType: GqlClientProgramShareType;
                        isExternal?: boolean | null;
                    }>;
                }>;
                dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
            } | null;
            editorProgramVersion?: {
                __typename?: "EditorProgramVersion";
                id: string;
                editorDataConnectorEnabled?: boolean | null;
                selectedMusicSource: GqlClientEditorAssetSource;
                usedElements?: any | null;
                chapteringEnabled?: boolean | null;
                thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
                pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
                selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string };
            } | null;
        } | null;
        prodVersion?: {
            __typename?: "LifecycleHistoryEntry";
            id: string;
            created: string;
            createdBy?: string | null;
            snapshotName?: string | null;
            snapshotNumber?: number | null;
            snapshotValidationData?: any | null;
            accountDataLibraryVersion?: {
                __typename?: "AccountDataLibraryVersion";
                id: string;
                localId: string;
                data: any;
                anyUpdated: string;
                anyUpdatedBy?: string | null;
                usedInVideos: Array<{
                    __typename?: "EditorProgram";
                    id: string;
                    name: string;
                    isArchive?: boolean | null;
                    isLive?: boolean | null;
                    programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                    prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    shareHistory: Array<{
                        __typename?: "ProgramShareHistory";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        accountId: string;
                        shareType: GqlClientProgramShareType;
                        isExternal?: boolean | null;
                    }>;
                }>;
                dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
            } | null;
            editorProgramVersion?: {
                __typename?: "EditorProgramVersion";
                id: string;
                editorDataConnectorEnabled?: boolean | null;
                selectedMusicSource: GqlClientEditorAssetSource;
                usedElements?: any | null;
                chapteringEnabled?: boolean | null;
                thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
                pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
                selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string };
            } | null;
        } | null;
        firstProdVersion?: {
            __typename?: "LifecycleHistoryEntry";
            id: string;
            created: string;
            createdBy?: string | null;
            snapshotName?: string | null;
            snapshotNumber?: number | null;
            snapshotValidationData?: any | null;
            accountDataLibraryVersion?: {
                __typename?: "AccountDataLibraryVersion";
                id: string;
                localId: string;
                data: any;
                anyUpdated: string;
                anyUpdatedBy?: string | null;
                usedInVideos: Array<{
                    __typename?: "EditorProgram";
                    id: string;
                    name: string;
                    isArchive?: boolean | null;
                    isLive?: boolean | null;
                    programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                    prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    shareHistory: Array<{
                        __typename?: "ProgramShareHistory";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        accountId: string;
                        shareType: GqlClientProgramShareType;
                        isExternal?: boolean | null;
                    }>;
                }>;
                dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
            } | null;
            editorProgramVersion?: {
                __typename?: "EditorProgramVersion";
                id: string;
                editorDataConnectorEnabled?: boolean | null;
                selectedMusicSource: GqlClientEditorAssetSource;
                usedElements?: any | null;
                chapteringEnabled?: boolean | null;
                thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
                pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
                selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string };
            } | null;
        } | null;
        landingPage?: {
            __typename?: "LandingPage";
            id: string;
            updated: string;
            updatedBy?: string | null;
            created: string;
            createdBy?: string | null;
            configuration?: any | null;
            isActive?: boolean | null;
            lifecycleStage?: GqlClientAssetLifecycleStage | null;
            updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
        } | null;
        embedVideoCode?: {
            __typename?: "EmbedVideoCode";
            id: string;
            updated: string;
            updatedBy?: string | null;
            created: string;
            createdBy?: string | null;
            configuration?: any | null;
            updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
        } | null;
        shareHistory: Array<{
            __typename?: "ProgramShareHistory";
            id: string;
            created: string;
            createdBy?: string | null;
            accountId: string;
            shareType: GqlClientProgramShareType;
            isExternal?: boolean | null;
        }>;
    } | null;
};

export type GqlClientEditorProgramAspectRatioQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramAspectRatioQuery = {
    __typename?: "Query";
    editorProgram?: { __typename?: "EditorProgram"; id: string; programVersion?: { __typename?: "EditorProgramVersion"; id: string; aspectRatio: GqlClientEnumAspectRatio } | null } | null;
};

export type GqlClientEditorProgramBackgroundMediaQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramBackgroundMediaQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            useCustomVideoBackground: boolean;
            videoBackgroundMedia?: {
                __typename?: "EditorAssetMedia";
                id: string;
                name: string;
                created: string;
                updated: string;
                mediaLocation: string;
                mediaUrl: string;
                mediaPreviewUrl: string;
                mediaAgifUrl?: string | null;
                mediaOrigin: GqlClientMediaOrigin;
                mediaMetadata: any;
                mediaProcessingInfo?: any | null;
                mediaFramesThumbnailsUrl?: string | null;
                originalEditInformation?: any | null;
                originalMediaId?: string | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                mediaSri?: string | null;
                externalLibraryAssetId?: string | null;
                externalLibrary?: GqlClientExternalLibrary | null;
                externalSublibrary?: GqlClientExternalSubLibrary | null;
                directAncestorId?: string | null;
                mediaClassification: GqlClientMediaClassification;
                description?: string | null;
                createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorProgramCustomBackgroundDataQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramCustomBackgroundDataQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            useCustomVideoBackground: boolean;
            videoBackgroundMedia?: {
                __typename?: "EditorAssetMedia";
                id: string;
                name: string;
                created: string;
                updated: string;
                mediaLocation: string;
                mediaUrl: string;
                mediaPreviewUrl: string;
                mediaAgifUrl?: string | null;
                mediaOrigin: GqlClientMediaOrigin;
                mediaMetadata: any;
                mediaProcessingInfo?: any | null;
                mediaFramesThumbnailsUrl?: string | null;
                originalEditInformation?: any | null;
                originalMediaId?: string | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                mediaSri?: string | null;
                externalLibraryAssetId?: string | null;
                externalLibrary?: GqlClientExternalLibrary | null;
                externalSublibrary?: GqlClientExternalSubLibrary | null;
                directAncestorId?: string | null;
                mediaClassification: GqlClientMediaClassification;
                description?: string | null;
                createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorProgramDataConnectorQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramDataConnectorQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            accountDataLibraryVersion?: {
                __typename?: "AccountDataLibraryVersion";
                id: string;
                localId: string;
                data: any;
                anyUpdated: string;
                anyUpdatedBy?: string | null;
                usedInVideos: Array<{
                    __typename?: "EditorProgram";
                    id: string;
                    name: string;
                    isArchive?: boolean | null;
                    isLive?: boolean | null;
                    programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                    prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    shareHistory: Array<{
                        __typename?: "ProgramShareHistory";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        accountId: string;
                        shareType: GqlClientProgramShareType;
                        isExternal?: boolean | null;
                    }>;
                }>;
                dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorProgramForEmbedVideoBuilderQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramForEmbedVideoBuilderQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        name: string;
        saleForceId?: string | null;
        programVersionDraft: {
            __typename?: "EditorProgramVersion";
            id: string;
            aspectRatio: GqlClientEnumAspectRatio;
            ccLibraryVersion?: {
                __typename?: "CcLibraryVersion";
                id: string;
                fonts: Array<{
                    __typename?: "CcFont";
                    id: string;
                    name: string;
                    url: string;
                    hasSmallCaps?: boolean | null;
                    lifecycleStage?: GqlClientAssetLifecycleStage | null;
                    webFontUrl?: string | null;
                    created: string;
                    familyName?: string | null;
                    isBold?: boolean | null;
                    isItalic?: boolean | null;
                    weight?: number | null;
                }>;
            } | null;
            assetLibrary: {
                __typename?: "EditorAssetLibraryVersion";
                id: string;
                media: Array<{
                    __typename?: "EditorAssetMedia";
                    id: string;
                    name: string;
                    created: string;
                    updated: string;
                    mediaLocation: string;
                    mediaUrl: string;
                    mediaPreviewUrl: string;
                    mediaAgifUrl?: string | null;
                    mediaOrigin: GqlClientMediaOrigin;
                    mediaMetadata: any;
                    mediaProcessingInfo?: any | null;
                    mediaFramesThumbnailsUrl?: string | null;
                    originalEditInformation?: any | null;
                    originalMediaId?: string | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    mediaSri?: string | null;
                    externalLibraryAssetId?: string | null;
                    externalLibrary?: GqlClientExternalLibrary | null;
                    externalSublibrary?: GqlClientExternalSubLibrary | null;
                    directAncestorId?: string | null;
                    mediaClassification: GqlClientMediaClassification;
                    description?: string | null;
                    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                    folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                }>;
            };
            selectedBrandConfiguration: {
                __typename?: "BrandConfigurationCollection";
                id: string;
                localId: string;
                updated: string;
                updatedBy?: string | null;
                created: string;
                name: string;
                description?: string | null;
                logoUrl?: string | null;
                logoLocation?: string | null;
                buttonShape: GqlClientButtonShapeValues;
                logoMedia?: {
                    __typename?: "EditorAssetMedia";
                    id: string;
                    name: string;
                    created: string;
                    updated: string;
                    mediaLocation: string;
                    mediaUrl: string;
                    mediaPreviewUrl: string;
                    mediaAgifUrl?: string | null;
                    mediaOrigin: GqlClientMediaOrigin;
                    mediaMetadata: any;
                    mediaProcessingInfo?: any | null;
                    mediaFramesThumbnailsUrl?: string | null;
                    originalEditInformation?: any | null;
                    originalMediaId?: string | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    mediaSri?: string | null;
                    externalLibraryAssetId?: string | null;
                    externalLibrary?: GqlClientExternalLibrary | null;
                    externalSublibrary?: GqlClientExternalSubLibrary | null;
                    directAncestorId?: string | null;
                    mediaClassification: GqlClientMediaClassification;
                    description?: string | null;
                    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                    folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                } | null;
                colors: Array<{
                    __typename?: "BcColor";
                    id: string;
                    updated: string;
                    color: string;
                    ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
                }>;
                textStyles: Array<{
                    __typename?: "BcTextStyle";
                    id: string;
                    fontSource: GqlClientEditorAssetSource;
                    letterCase: GqlClientTextStyleLetterCase;
                    letterSpacing: number;
                    lineSpacing: number;
                    updated: string;
                    ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                    ccFont?: {
                        __typename?: "CcFont";
                        id: string;
                        name: string;
                        url: string;
                        hasSmallCaps?: boolean | null;
                        lifecycleStage?: GqlClientAssetLifecycleStage | null;
                        webFontUrl?: string | null;
                        created: string;
                        familyName?: string | null;
                        isBold?: boolean | null;
                        isItalic?: boolean | null;
                        weight?: number | null;
                    } | null;
                    accountFont?: {
                        __typename?: "EditorAssetFont";
                        id: string;
                        name: string;
                        url?: string | null;
                        imageUrl?: string | null;
                        webFontUrl?: string | null;
                        hasSmallCaps?: boolean | null;
                        lifecycleStage?: GqlClientAssetLifecycleStage | null;
                        uploadId?: string | null;
                        created: string;
                        familyName?: string | null;
                        isBold?: boolean | null;
                        isItalic?: boolean | null;
                        weight?: number | null;
                    } | null;
                }>;
            };
        };
        embedVideoCode?: {
            __typename?: "EmbedVideoCode";
            id: string;
            updated: string;
            updatedBy?: string | null;
            created: string;
            createdBy?: string | null;
            configuration?: any | null;
            updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
        } | null;
    } | null;
};

export type GqlClientEditorProgramForLandingPageBuilderQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramForLandingPageBuilderQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        name: string;
        saleForceId?: string | null;
        programVersionDraft: {
            __typename?: "EditorProgramVersion";
            id: string;
            aspectRatio: GqlClientEnumAspectRatio;
            ccLibraryVersion?: {
                __typename?: "CcLibraryVersion";
                id: string;
                fonts: Array<{
                    __typename?: "CcFont";
                    id: string;
                    name: string;
                    url: string;
                    hasSmallCaps?: boolean | null;
                    lifecycleStage?: GqlClientAssetLifecycleStage | null;
                    webFontUrl?: string | null;
                    created: string;
                    familyName?: string | null;
                    isBold?: boolean | null;
                    isItalic?: boolean | null;
                    weight?: number | null;
                }>;
                textStyles: Array<{ __typename?: "CcTextStyle"; id: string; name: string }>;
            } | null;
            assetLibrary: {
                __typename?: "EditorAssetLibraryVersion";
                id: string;
                fonts: Array<{
                    __typename?: "EditorAssetFont";
                    id: string;
                    name: string;
                    url?: string | null;
                    imageUrl?: string | null;
                    webFontUrl?: string | null;
                    hasSmallCaps?: boolean | null;
                    lifecycleStage?: GqlClientAssetLifecycleStage | null;
                    uploadId?: string | null;
                    created: string;
                    familyName?: string | null;
                    isBold?: boolean | null;
                    isItalic?: boolean | null;
                    weight?: number | null;
                } | null>;
                media: Array<{
                    __typename?: "EditorAssetMedia";
                    id: string;
                    name: string;
                    created: string;
                    updated: string;
                    mediaLocation: string;
                    mediaUrl: string;
                    mediaPreviewUrl: string;
                    mediaAgifUrl?: string | null;
                    mediaOrigin: GqlClientMediaOrigin;
                    mediaMetadata: any;
                    mediaProcessingInfo?: any | null;
                    mediaFramesThumbnailsUrl?: string | null;
                    originalEditInformation?: any | null;
                    originalMediaId?: string | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    mediaSri?: string | null;
                    externalLibraryAssetId?: string | null;
                    externalLibrary?: GqlClientExternalLibrary | null;
                    externalSublibrary?: GqlClientExternalSubLibrary | null;
                    directAncestorId?: string | null;
                    mediaClassification: GqlClientMediaClassification;
                    description?: string | null;
                    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                    folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                }>;
            };
            selectedBrandConfiguration: {
                __typename?: "BrandConfigurationCollection";
                id: string;
                localId: string;
                updated: string;
                updatedBy?: string | null;
                created: string;
                name: string;
                description?: string | null;
                logoUrl?: string | null;
                logoLocation?: string | null;
                buttonShape: GqlClientButtonShapeValues;
                logoMedia?: {
                    __typename?: "EditorAssetMedia";
                    id: string;
                    name: string;
                    created: string;
                    updated: string;
                    mediaLocation: string;
                    mediaUrl: string;
                    mediaPreviewUrl: string;
                    mediaAgifUrl?: string | null;
                    mediaOrigin: GqlClientMediaOrigin;
                    mediaMetadata: any;
                    mediaProcessingInfo?: any | null;
                    mediaFramesThumbnailsUrl?: string | null;
                    originalEditInformation?: any | null;
                    originalMediaId?: string | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    mediaSri?: string | null;
                    externalLibraryAssetId?: string | null;
                    externalLibrary?: GqlClientExternalLibrary | null;
                    externalSublibrary?: GqlClientExternalSubLibrary | null;
                    directAncestorId?: string | null;
                    mediaClassification: GqlClientMediaClassification;
                    description?: string | null;
                    createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                    folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                } | null;
                colors: Array<{
                    __typename?: "BcColor";
                    id: string;
                    updated: string;
                    color: string;
                    ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
                }>;
                textStyles: Array<{
                    __typename?: "BcTextStyle";
                    id: string;
                    fontSource: GqlClientEditorAssetSource;
                    letterCase: GqlClientTextStyleLetterCase;
                    letterSpacing: number;
                    lineSpacing: number;
                    updated: string;
                    ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                    ccFont?: {
                        __typename?: "CcFont";
                        id: string;
                        name: string;
                        url: string;
                        hasSmallCaps?: boolean | null;
                        lifecycleStage?: GqlClientAssetLifecycleStage | null;
                        webFontUrl?: string | null;
                        created: string;
                        familyName?: string | null;
                        isBold?: boolean | null;
                        isItalic?: boolean | null;
                        weight?: number | null;
                    } | null;
                    accountFont?: {
                        __typename?: "EditorAssetFont";
                        id: string;
                        name: string;
                        url?: string | null;
                        imageUrl?: string | null;
                        webFontUrl?: string | null;
                        hasSmallCaps?: boolean | null;
                        lifecycleStage?: GqlClientAssetLifecycleStage | null;
                        uploadId?: string | null;
                        created: string;
                        familyName?: string | null;
                        isBold?: boolean | null;
                        isItalic?: boolean | null;
                        weight?: number | null;
                    } | null;
                }>;
            };
        };
        landingPage?: {
            __typename?: "LandingPage";
            id: string;
            updated: string;
            updatedBy?: string | null;
            created: string;
            createdBy?: string | null;
            configuration?: any | null;
            isActive?: boolean | null;
            lifecycleStage?: GqlClientAssetLifecycleStage | null;
            updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
        } | null;
    } | null;
};

export type GqlClientEditorProgramForReviewPageBuilderQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramForReviewPageBuilderQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; aspectRatio: GqlClientEnumAspectRatio; thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null };
    } | null;
};

export type GqlClientEditorProgramLandingPageUrlQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramLandingPageUrlQuery = { __typename?: "Query"; editorProgram?: { __typename?: "EditorProgram"; id: string; landingPageUrl?: string | null } | null };

export type GqlClientEditorProgramThemeIdQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramThemeIdQuery = {
    __typename?: "Query";
    editorProgram?: { __typename?: "EditorProgram"; id: string; programVersion?: { __typename?: "EditorProgramVersion"; id: string; ccTheme: { __typename?: "CcTheme"; id: string } } | null } | null;
};

export type GqlClientEditorProgramThemeNameQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramThemeNameQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: { __typename?: "EditorProgramVersion"; id: string; ccTheme: { __typename?: "CcTheme"; id: string; name: string } } | null;
    } | null;
};

export type GqlClientEditorProgramTotalViewsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramTotalViewsQuery = { __typename?: "Query"; editorProgram?: { __typename?: "EditorProgram"; id: string; totalViews?: number | null } | null };

export type GqlClientEditorProgramVersionQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramVersionQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        name: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            editorDataConnectorEnabled?: boolean | null;
            aspectRatio: GqlClientEnumAspectRatio;
            sceneLineup: Array<string>;
            audioSettings?: any | null;
            legacyAssetLocationBasePath?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            editorTtsFullEnablement?: boolean | null;
            bcUseRules?: boolean | null;
            bcRules?: any | null;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            useCustomVideoBackground: boolean;
            isAvatarRendering: boolean;
            selectedMusicSource: GqlClientEditorAssetSource;
            ccTheme: { __typename?: "CcTheme"; id: string };
            assetLibrary: { __typename?: "EditorAssetLibraryVersion"; id: string };
            scenes: Array<{
                __typename?: "EditorScene";
                id: string;
                usedMediaIds: Array<string>;
                name: string;
                description?: string | null;
                skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
                colorCombination: GqlClientEditorSceneColorCombination;
                buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
                skipSceneAudience?: any | null;
                minimumDuration?: number | null;
                hasTransition?: boolean | null;
                avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
                avatarVideoUrl?: string | null;
                ccAnimatedWireframe?: { __typename?: "CcAnimatedWireframe"; id: string } | null;
                placeholders: Array<{
                    __typename?: "EditorPlaceholder";
                    id: string;
                    placeholderContent?: any | null;
                    placeholderSettings?: any | null;
                    parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                    ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                    flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                    parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
                }>;
                ccWireframe: { __typename?: "CcWireframe"; id: string };
            }>;
            selectedNarrator?: { __typename?: "CcNarrator"; id: string } | null;
            studioElements: Array<{ __typename?: "DerivedDataElement"; id: string; localId: string; name: string; dataType?: GqlClientDataElementType | null; valueSet?: Array<any | null> | null }>;
            brandConfigurationVersion: { __typename?: "BrandConfigurationVersion"; id: string };
            selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string; updatedBy?: string | null };
            accountDataLibraryVersion?: {
                __typename?: "AccountDataLibraryVersion";
                id: string;
                localId: string;
                data: any;
                anyUpdated: string;
                anyUpdatedBy?: string | null;
                usedInVideos: Array<{
                    __typename?: "EditorProgram";
                    id: string;
                    name: string;
                    isArchive?: boolean | null;
                    isLive?: boolean | null;
                    programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                    prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    shareHistory: Array<{
                        __typename?: "ProgramShareHistory";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        accountId: string;
                        shareType: GqlClientProgramShareType;
                        isExternal?: boolean | null;
                    }>;
                }>;
                dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
            } | null;
            pronunciationLibraryVersion?: {
                __typename?: "AccountPronunciationLibraryVersion";
                id: string;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                definitions: Array<{ __typename?: "AccountPronunciationDefinition"; id: string; source: string; target: string }>;
            } | null;
            videoBackgroundMedia?: { __typename?: "EditorAssetMedia"; id: string } | null;
            selectedCcMusic?: { __typename?: "CcMusic"; id: string } | null;
            selectedAccountMusic?: { __typename?: "EditorAssetMusic"; id: string } | null;
        } | null;
        viewerProfiles: Array<{ __typename?: "ViewerProfile"; id: string; name: string; content: any; updated: string }>;
    } | null;
};

export type GqlClientEditorSceneWithoutCcDataFragment = {
    __typename?: "EditorScene";
    id: string;
    usedMediaIds: Array<string>;
    name: string;
    description?: string | null;
    skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
    colorCombination: GqlClientEditorSceneColorCombination;
    buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
    skipSceneAudience?: any | null;
    minimumDuration?: number | null;
    hasTransition?: boolean | null;
    avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
    avatarVideoUrl?: string | null;
    ccAnimatedWireframe?: { __typename?: "CcAnimatedWireframe"; id: string } | null;
    placeholders: Array<{
        __typename?: "EditorPlaceholder";
        id: string;
        placeholderContent?: any | null;
        placeholderSettings?: any | null;
        parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
        ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
        flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
        parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
    }>;
    ccWireframe: { __typename?: "CcWireframe"; id: string };
};

export type GqlClientEditorProgramVersionBrandByAudienceQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramVersionBrandByAudienceQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            bcRules?: any | null;
            bcUseRules?: boolean | null;
            brandConfigurationVersion: {
                __typename?: "BrandConfigurationVersion";
                id: string;
                defaultBrandConfigurationCollection: { __typename?: "BrandConfigurationCollection"; id: string; localId: string };
            };
        } | null;
    } | null;
};

export type GqlClientEditorProgramVersionGetCompatibilityQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramVersionGetCompatibilityQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: { __typename?: "EditorProgramVersion"; id: string; compatibility?: GqlClientEnumAnimationWireframeCompatibility | null } | null;
    } | null;
};

export type GqlClientEditorProgramVersionLastEditedQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramVersionLastEditedQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            accountDataLibraryVersion?: { __typename?: "AccountDataLibraryVersion"; id: string; anyUpdatedBy?: string | null; anyUpdated: string } | null;
            selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; updated: string; updatedBy?: string | null };
            pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null; anyUpdatedBy?: string | null } | null;
        } | null;
    } | null;
};

export type GqlClientEditorProgramVersionSceneLineupAndScenesQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramVersionSceneLineupAndScenesQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: { __typename?: "EditorProgramVersion"; id: string; sceneLineup: Array<string>; scenes: Array<{ __typename?: "EditorScene"; id: string }> } | null;
    } | null;
};

export type GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            ccTheme: { __typename?: "CcTheme"; id: string; name: string };
            scenes: Array<{ __typename?: "EditorScene"; id: string; ccWireframe: { __typename?: "CcWireframe"; id: string; lifecycleStage: GqlClientAssetLifecycleStage } }>;
        } | null;
    } | null;
};

export type GqlClientEditorProgramVersionThumbnailInformationQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramVersionThumbnailInformationQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; aspectRatio: GqlClientEnumAspectRatio; thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null };
    } | null;
};

export type GqlClientEditorProgramVersionUsedDataFieldsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramVersionUsedDataFieldsQuery = {
    __typename?: "Query";
    editorProgram?: { __typename?: "EditorProgram"; id: string; programVersion?: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null } | null } | null;
};

export type GqlClientEditorPronunciationDefinitionsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorPronunciationDefinitionsQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        name: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            pronunciationLibraryVersion?: {
                __typename?: "AccountPronunciationLibraryVersion";
                id: string;
                definitions: Array<{ __typename?: "AccountPronunciationDefinition"; id: string; source: string; target: string }>;
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorSceneLineupQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorSceneLineupQuery = {
    __typename?: "Query";
    editorProgram?: { __typename?: "EditorProgram"; id: string; programVersion?: { __typename?: "EditorProgramVersion"; id: string; sceneLineup: Array<string> } | null } | null;
};

export type GqlClientEditorScenesQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorScenesQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            scenes: Array<{
                __typename?: "EditorScene";
                id: string;
                usedMediaIds: Array<string>;
                name: string;
                description?: string | null;
                skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
                colorCombination: GqlClientEditorSceneColorCombination;
                buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
                skipSceneAudience?: any | null;
                minimumDuration?: number | null;
                hasTransition?: boolean | null;
                avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
                avatarVideoUrl?: string | null;
                placeholders: Array<{
                    __typename?: "EditorPlaceholder";
                    id: string;
                    placeholderContent?: any | null;
                    placeholderSettings?: any | null;
                    parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                    ccPlaceholder: {
                        __typename?: "CcPlaceholder";
                        id: string;
                        name: string;
                        content?: any | null;
                        category: GqlClientCcPlaceholderCategory;
                        intent: {
                            __typename?: "CcPlaceholderIntent";
                            id: string;
                            name: string;
                            type: GqlClientCcPlaceholderType;
                            defaultValue?: string | null;
                            textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                        };
                    };
                    flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                    parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
                }>;
                ccWireframe: {
                    __typename?: "CcWireframe";
                    name: string;
                    id: string;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    category: GqlClientCcWireframeCategory;
                    frames: Array<{ __typename?: "CcFrame"; id: string; placeholders: Array<{ __typename?: "CcPlaceholder"; id: string }> }>;
                };
                ccAnimatedWireframe?: {
                    __typename?: "CcAnimatedWireframe";
                    id: string;
                    name: string;
                    vsmlUrl: string;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    aspectRatio: GqlClientEnumAspectRatio;
                    compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                    defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                    theme: { __typename?: "CcTheme"; id: string };
                    placeholdersSettings: Array<
                        | {
                              __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                              id: string;
                              horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                              verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                              mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                              canvasName?: string | null;
                              ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                          }
                        | {
                              __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                              id: string;
                              horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                              verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                              canvasName?: string | null;
                              colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                              ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                          }
                    >;
                    m2AnimatedWireframe?: {
                        __typename?: "CcAnimatedWireframe";
                        id: string;
                        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                        vsmlUrl: string;
                        defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                    } | null;
                    composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
                } | null;
            }>;
        } | null;
    } | null;
};

export type GqlClientEditorScenesAvatarRenderedStatusQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorScenesAvatarRenderedStatusQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            scenes: Array<{ __typename?: "EditorScene"; id: string; avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus; skipSceneMode?: GqlClientEditorSceneSkippingMode | null }>;
        } | null;
    } | null;
};

export type GqlClientEditorSelectedBrandIdQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorSelectedBrandIdQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: { __typename?: "EditorProgramVersion"; id: string; selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string } } | null;
    } | null;
};

export type GqlClientEditorSelectedMusicIdQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorSelectedMusicIdQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            selectedMusicSource: GqlClientEditorAssetSource;
            selectedCcMusic?: { __typename?: "CcMusic"; id: string } | null;
            selectedAccountMusic?: { __typename?: "EditorAssetMusic"; id: string } | null;
        } | null;
    } | null;
};

export type GqlClientEditorSelectedNarratorQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorSelectedNarratorQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            selectedNarrator?: {
                __typename?: "CcNarrator";
                id: string;
                name: string;
                sampleUrl: string;
                narrationSystemName: string;
                narrationSystemVoice: string;
                intent: GqlClientCcNarratorIntent;
                narrationSystemMetadata: {
                    __typename?: "CcNarrationSystemMetadata";
                    language: string;
                    gender: string;
                    rate?: number | null;
                    volume?: number | null;
                    age?: string | null;
                    description?: Array<string> | null;
                };
            } | null;
        } | null;
    } | null;
};

export type GqlClientEditorStockMusicSearchQueryVariables = Exact<{
    searchTerm?: InputMaybe<Scalars["String"]["input"]>;
    tag?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    projectID?: InputMaybe<Scalars["ID"]["input"]>;
    userID?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type GqlClientEditorStockMusicSearchQuery = {
    __typename?: "Query";
    editorStockMusicSearch?: {
        __typename?: "EditorStockMusicSearchResults";
        numberOfResults: number;
        tracks?: Array<{
            __typename?: "EditorStockMusic";
            id: string;
            title: string;
            durationInSeconds: number;
            previewUrl?: string | null;
            artists?: Array<string | null> | null;
            tags?: Array<{ __typename?: "EditorStockMusicTag"; id: string; name: string } | null> | null;
        } | null> | null;
    } | null;
};

export type GqlClientEditorStockMusicAiRecommendationSearchQueryVariables = Exact<{
    projectID?: InputMaybe<Scalars["ID"]["input"]>;
    userID?: InputMaybe<Scalars["ID"]["input"]>;
    programVersionID?: InputMaybe<Scalars["ID"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GqlClientEditorStockMusicAiRecommendationSearchQuery = {
    __typename?: "Query";
    editorStockMusicAIRecommendationSearch?: {
        __typename?: "EditorStockMusicSearchResults";
        numberOfResults: number;
        tracks?: Array<{
            __typename?: "EditorStockMusic";
            id: string;
            title: string;
            durationInSeconds: number;
            previewUrl?: string | null;
            artists?: Array<string | null> | null;
            tags?: Array<{ __typename?: "EditorStockMusicTag"; id: string; name: string } | null> | null;
        } | null> | null;
    } | null;
};

export type GqlClientEditorStockMusicGetSingleTrackDetailsQueryVariables = Exact<{
    trackID?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientEditorStockMusicGetSingleTrackDetailsQuery = {
    __typename?: "Query";
    editorStockMusicGetSingleTrackDetails?: {
        __typename?: "EditorStockMusic";
        id: string;
        title: string;
        durationInSeconds: number;
        previewUrl?: string | null;
        artists?: Array<string | null> | null;
        tags?: Array<{ __typename?: "EditorStockMusicTag"; id: string; name: string } | null> | null;
    } | null;
};

export type GqlClientEditorStudioElementsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorStudioElementsQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersion?: {
            __typename?: "EditorProgramVersion";
            id: string;
            studioElements: Array<{ __typename?: "DerivedDataElement"; id: string; localId: string; name: string; dataType?: GqlClientDataElementType | null; valueSet?: Array<any | null> | null }>;
        } | null;
    } | null;
};

export type GqlClientEditorTtsFullEnablementQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorTtsFullEnablementQuery = {
    __typename?: "Query";
    editorProgram?: { __typename?: "EditorProgram"; id: string; programVersion?: { __typename?: "EditorProgramVersion"; id: string; editorTtsFullEnablement?: boolean | null } | null } | null;
};

export type GqlClientUserSettingsQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientUserSettingsQuery = {
    __typename?: "Query";
    userSettings?: {
        __typename?: "UserSettings";
        id: string;
        externalLibraries: any;
        storyTemplates: any;
        acceptedAiTerms?: boolean | null;
        mediaLibraryViewType: GqlClientLibraryViewType;
        mediaLibraryGridColumns: number;
        acceptedGenerativeAiTerms?: boolean | null;
    } | null;
};

export type GqlClientEditorViewerProfilesQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorViewerProfilesQuery = {
    __typename?: "Query";
    editorProgram?: { __typename?: "EditorProgram"; id: string; viewerProfiles: Array<{ __typename?: "ViewerProfile"; id: string; name: string; content: any; updated: string }> } | null;
};

export type GqlClientGetGalleryStoryTemplatesQueryVariables = Exact<{
    galleryType: GqlClientStoryTemplatesGalleryType;
    includeChildren?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientGetGalleryStoryTemplatesQuery = {
    __typename?: "Query";
    galleryStoryTemplates: Array<{
        __typename?: "EditorApplication";
        id: string;
        name?: string | null;
        metadata?: any | null;
        isParent?: boolean | null;
        accountId: string;
        posterImageUrl?: string | null;
        previewVideoUrl?: string | null;
        customThumbnailUrl?: string | null;
        newStoryTemplate?: boolean | null;
        parent?: { __typename?: "EditorApplication"; id: string } | null;
        useCases?: Array<{ __typename?: "EditorUseCase"; id: string; name: string } | null> | null;
        industry?: { __typename?: "EditorIndustry"; id: string; name: string } | null;
        flavor?: { __typename?: "ApplicationFlavor"; id: string; name: string } | null;
        creativeStyleFits?: Array<{ __typename?: "EditorCreativeStyleFit"; id: string; name: string } | null> | null;
        channels?: Array<{ __typename?: "EditorChannel"; id: string; name: string } | null> | null;
        programVersion?: { __typename?: "EditorProgramVersion"; id: string; aspectRatio: GqlClientEnumAspectRatio } | null;
    } | null>;
};

export type GqlClientGenerateAiImageQueryVariables = Exact<{
    input: GqlClientAiTextToImageInput;
}>;

export type GqlClientGenerateAiImageQuery = {
    __typename?: "Query";
    generateAiImageFromText?: {
        __typename?: "GenerateAiImageFromTextOutput";
        error?: string | null;
        badPrompt?: boolean | null;
        images?: Array<{ __typename?: "AiImageResult"; url: string; sessionId: string; indexInBatch: number }> | null;
    } | null;
};

export type GqlClientHelpCenterEntriesQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientHelpCenterEntriesQuery = {
    __typename?: "Query";
    helpCenterEntries: Array<{ __typename?: "HelpCenterEntry"; id: string; buttonId: string; variant?: string | null; articleId: string; sectionId?: string | null }>;
};

export type GqlClientLocalEditorBrandQueryVariables = Exact<{
    brandId: Scalars["ID"]["input"];
}>;

export type GqlClientLocalEditorBrandQuery = {
    __typename?: "Query";
    localEditorBrand?: {
        __typename?: "BrandConfigurationCollection";
        id: string;
        localId: string;
        updated: string;
        updatedBy?: string | null;
        created: string;
        name: string;
        description?: string | null;
        logoUrl?: string | null;
        logoLocation?: string | null;
        buttonShape: GqlClientButtonShapeValues;
        logoMedia?: {
            __typename?: "EditorAssetMedia";
            id: string;
            name: string;
            created: string;
            updated: string;
            mediaLocation: string;
            mediaUrl: string;
            mediaPreviewUrl: string;
            mediaAgifUrl?: string | null;
            mediaOrigin: GqlClientMediaOrigin;
            mediaMetadata: any;
            mediaProcessingInfo?: any | null;
            mediaFramesThumbnailsUrl?: string | null;
            originalEditInformation?: any | null;
            originalMediaId?: string | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            mediaSri?: string | null;
            externalLibraryAssetId?: string | null;
            externalLibrary?: GqlClientExternalLibrary | null;
            externalSublibrary?: GqlClientExternalSubLibrary | null;
            directAncestorId?: string | null;
            mediaClassification: GqlClientMediaClassification;
            description?: string | null;
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
        } | null;
        colors: Array<{
            __typename?: "BcColor";
            id: string;
            updated: string;
            color: string;
            ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
        }>;
        textStyles: Array<{
            __typename?: "BcTextStyle";
            id: string;
            fontSource: GqlClientEditorAssetSource;
            letterCase: GqlClientTextStyleLetterCase;
            letterSpacing: number;
            lineSpacing: number;
            updated: string;
            ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
            ccFont?: {
                __typename?: "CcFont";
                id: string;
                name: string;
                url: string;
                hasSmallCaps?: boolean | null;
                lifecycleStage?: GqlClientAssetLifecycleStage | null;
                webFontUrl?: string | null;
                created: string;
                familyName?: string | null;
                isBold?: boolean | null;
                isItalic?: boolean | null;
                weight?: number | null;
            } | null;
            accountFont?: {
                __typename?: "EditorAssetFont";
                id: string;
                name: string;
                url?: string | null;
                imageUrl?: string | null;
                webFontUrl?: string | null;
                hasSmallCaps?: boolean | null;
                lifecycleStage?: GqlClientAssetLifecycleStage | null;
                uploadId?: string | null;
                created: string;
                familyName?: string | null;
                isBold?: boolean | null;
                isItalic?: boolean | null;
                weight?: number | null;
            } | null;
        }>;
    } | null;
};

export type GqlClientLocalEditorPlaceholderQueryVariables = Exact<{
    placeholderId: Scalars["ID"]["input"];
}>;

export type GqlClientLocalEditorPlaceholderQuery = {
    __typename?: "Query";
    localEditorPlaceholder?: {
        __typename?: "EditorPlaceholder";
        id: string;
        placeholderContent?: any | null;
        placeholderSettings?: any | null;
        parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
        ccPlaceholder: {
            __typename?: "CcPlaceholder";
            id: string;
            name: string;
            content?: any | null;
            category: GqlClientCcPlaceholderCategory;
            intent: {
                __typename?: "CcPlaceholderIntent";
                id: string;
                name: string;
                type: GqlClientCcPlaceholderType;
                defaultValue?: string | null;
                textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
            };
        };
        flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
        parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
    } | null;
};

export type GqlClientLocalEditorSceneQueryVariables = Exact<{
    sceneId: Scalars["ID"]["input"];
}>;

export type GqlClientLocalEditorSceneQuery = {
    __typename?: "Query";
    localEditorScene?: {
        __typename?: "EditorScene";
        id: string;
        usedMediaIds: Array<string>;
        name: string;
        description?: string | null;
        skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
        colorCombination: GqlClientEditorSceneColorCombination;
        buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
        skipSceneAudience?: any | null;
        minimumDuration?: number | null;
        hasTransition?: boolean | null;
        avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
        avatarVideoUrl?: string | null;
        placeholders: Array<{
            __typename?: "EditorPlaceholder";
            id: string;
            placeholderContent?: any | null;
            placeholderSettings?: any | null;
            parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
            ccPlaceholder: {
                __typename?: "CcPlaceholder";
                id: string;
                name: string;
                content?: any | null;
                category: GqlClientCcPlaceholderCategory;
                intent: {
                    __typename?: "CcPlaceholderIntent";
                    id: string;
                    name: string;
                    type: GqlClientCcPlaceholderType;
                    defaultValue?: string | null;
                    textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                };
            };
            flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
            parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
        }>;
        ccWireframe: {
            __typename?: "CcWireframe";
            name: string;
            id: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            category: GqlClientCcWireframeCategory;
            frames: Array<{ __typename?: "CcFrame"; id: string; placeholders: Array<{ __typename?: "CcPlaceholder"; id: string }> }>;
        };
        ccAnimatedWireframe?: {
            __typename?: "CcAnimatedWireframe";
            id: string;
            name: string;
            vsmlUrl: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            aspectRatio: GqlClientEnumAspectRatio;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
            theme: { __typename?: "CcTheme"; id: string };
            placeholdersSettings: Array<
                | {
                      __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                      id: string;
                      horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                      verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                      mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                      canvasName?: string | null;
                      ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                  }
                | {
                      __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                      id: string;
                      horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                      verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                      canvasName?: string | null;
                      colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                      ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                  }
            >;
            m2AnimatedWireframe?: {
                __typename?: "CcAnimatedWireframe";
                id: string;
                compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                vsmlUrl: string;
                defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
            } | null;
            composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
        } | null;
    } | null;
};

export type GqlClientLocalEditorSceneWithoutCcDataQueryVariables = Exact<{
    sceneId: Scalars["ID"]["input"];
}>;

export type GqlClientLocalEditorSceneWithoutCcDataQuery = {
    __typename?: "Query";
    localEditorScene?: {
        __typename?: "EditorScene";
        id: string;
        usedMediaIds: Array<string>;
        name: string;
        description?: string | null;
        skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
        colorCombination: GqlClientEditorSceneColorCombination;
        buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
        skipSceneAudience?: any | null;
        minimumDuration?: number | null;
        hasTransition?: boolean | null;
        avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
        avatarVideoUrl?: string | null;
        ccAnimatedWireframe?: { __typename?: "CcAnimatedWireframe"; id: string } | null;
        placeholders: Array<{
            __typename?: "EditorPlaceholder";
            id: string;
            placeholderContent?: any | null;
            placeholderSettings?: any | null;
            parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
            ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
            flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
            parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
        }>;
        ccWireframe: { __typename?: "CcWireframe"; id: string };
    } | null;
};

export type GqlClientLocalEditorViewerProfileQueryVariables = Exact<{
    viewerProfileId: Scalars["ID"]["input"];
}>;

export type GqlClientLocalEditorViewerProfileQuery = {
    __typename?: "Query";
    localEditorViewerProfile?: { __typename?: "ViewerProfile"; id: string; name: string; content: any; updated: string } | null;
};

export type GqlClientLoggedInEditorAccountUserSocialNetworksQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientLoggedInEditorAccountUserSocialNetworksQuery = {
    __typename?: "Query";
    loggedInEditorAccountUser: {
        __typename?: "EditorAccountUser";
        id: string;
        socialNetworks?: {
            __typename?: "SocialNetworks";
            manageSocialNetworksUrl: string;
            statuses: Array<{ __typename?: "SocialNetworkStatus"; userImage?: string | null; displayName?: string | null; platform?: string | null }>;
        } | null;
    };
};

export type GqlClientMediaCropHintsQueryVariables = Exact<{
    url: Scalars["String"]["input"];
    aspectRatio: Scalars["Float"]["input"];
}>;

export type GqlClientMediaCropHintsQuery = {
    __typename?: "Query";
    mediaCropHints?: { __typename?: "MediaCropHints"; suggestedCrop: { __typename?: "Rectangle"; x: number; y: number; width: number; height: number } } | null;
};

export type GqlClientRecentVideosAccountUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientRecentVideosAccountUsersQuery = {
    __typename?: "Query";
    currentAccount?: {
        __typename?: "Account";
        id: string;
        users: Array<{ __typename?: "AccountUser"; id: string; user: { __typename?: "EditorAccountUser"; id: string; firstName?: string | null; lastName?: string | null } }>;
    } | null;
};

export type GqlClientRptvUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientRptvUsersQuery = { __typename?: "Query"; rptvUsers: Array<{ __typename?: "RPTVUser"; id: string; tokens: number }> };

export type GqlClientTrayAccountConnectionsQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientTrayAccountConnectionsQuery = {
    __typename?: "Query";
    account?: {
        __typename?: "Account";
        id: string;
        accountConnections: Array<{ __typename?: "AccountConnection"; id: string; isConnected: boolean; connectorType: string; connectionUpdated: string; connectionUpdatedBy: string }>;
    } | null;
};

export type GqlClientTrayGoogleSheetColumnsQueryVariables = Exact<{
    spreadSheetId: Scalars["ID"]["input"];
    sheetName: Scalars["String"]["input"];
}>;

export type GqlClientTrayGoogleSheetColumnsQuery = { __typename?: "Query"; trayGoogleSheetColumns?: Array<{ __typename?: "GoogleColumn"; id: string; name: string }> | null };

export type GqlClientTrayGoogleSheetsQueryVariables = Exact<{
    spreadSheetId: Scalars["ID"]["input"];
}>;

export type GqlClientTrayGoogleSheetsQuery = {
    __typename?: "Query";
    trayGoogleSheets:
        | { __typename?: "GoogleSheetResultOutputError"; error: GqlClientGoogleSheetError; result: GqlClientGoogleSheetResult }
        | { __typename?: "GoogleSheetResultOutputSuccess"; result: GqlClientGoogleSheetResult; sheets: Array<{ __typename?: "GoogleSheet"; id: string; name: string }> };
};

export type GqlClientTrayServiceAuthUrlQueryVariables = Exact<{
    connectorType: Scalars["String"]["input"];
}>;

export type GqlClientTrayServiceAuthUrlQuery = { __typename?: "Query"; trayServiceAuthUrl?: { __typename?: "trayServiceAuth"; authUrl: string } | null };

export type GqlClientTrayServiceConnectorEntitiesQueryVariables = Exact<{
    dataLibraryId: Scalars["String"]["input"];
    connectorType: Scalars["String"]["input"];
    parentEntityId?: InputMaybe<Scalars["String"]["input"]>;
    authLastUpdated?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientTrayServiceConnectorEntitiesQuery = {
    __typename?: "Query";
    trayServiceConnectorEntities: Array<{ __typename?: "TrayServiceConnectorEntity"; id: string; name: string; isField: boolean; sampleValue?: string | null } | null>;
};

export type GqlClientTrayTestConnectorAuthenticationQueryVariables = Exact<{
    connectorType: Scalars["String"]["input"];
}>;

export type GqlClientTrayTestConnectorAuthenticationQuery = {
    __typename?: "Query";
    trayTestConnectorAuthentication?: { __typename?: "trayTestConnectorAuthenticationOutput"; authenticated: boolean } | null;
};

export type GqlClientUndoStacksQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientUndoStacksQuery = {
    __typename?: "Query";
    undoStacks: { __typename?: "UndoStacks"; id: string; undoStackSize: number; redoStackSize: number; firstUndoActionName?: string | null; firstRedoActionName?: string | null };
};

export type GqlClientAccountAvatarCreatedSubscriptionVariables = Exact<{
    mutationId: Scalars["ID"]["input"];
}>;

export type GqlClientAccountAvatarCreatedSubscription = {
    __typename?: "Subscription";
    customAvatarCreationStarted?:
        | { __typename: "CustomAvatarCreationStartedPayloadError"; error: any; id: string; status: GqlClientStatus; avatarId: string; mutationId: string }
        | { __typename: "CustomAvatarCreationStartedPayloadKeepAlive"; id: string; status: GqlClientStatus; avatarId: string; mutationId: string }
        | { __typename: "CustomAvatarCreationStartedPayloadPending"; progress: number; id: string; status: GqlClientStatus; avatarId: string; mutationId: string }
        | {
              __typename: "CustomAvatarCreationStartedPayloadSuccess";
              id: string;
              status: GqlClientStatus;
              avatarId: string;
              mutationId: string;
              data: { __typename?: "CustomAvatarCreationStartedPayloadData"; avatar: { __typename?: "EditorAccountLevelAvatar"; id: string; name: string } };
          }
        | null;
};

export type GqlClientAccountAvatarLibraryChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountAvatarLibraryChangedSubscription = {
    __typename?: "Subscription";
    accountAvatarLibraryChanged?: {
        __typename?: "EditorAssetLibraryVersion";
        id: string;
        avatars: Array<{
            __typename?: "EditorAccountLevelAvatar";
            id: string;
            localId: string;
            name: string;
            description?: string | null;
            avatarSystemName: string;
            thumbnailUrl?: string | null;
            sampleUrl?: string | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            lifecycleMessage?: string | null;
            created: string;
            sampleVideoMetadata?: any | null;
            fullBodyCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
            closeUpCrop: { __typename?: "CcAvatarCrop"; top: number; right: number; bottom: number; left: number };
            narrator?: {
                __typename?: "EditorAccountLevelNarrator";
                id: string;
                localId: string;
                name: string;
                language?: string | null;
                description?: string | null;
                sampleUrl?: string | null;
                sampleTranscript?: string | null;
                narrationSystemName?: string | null;
                narrationSystemVoice?: string | null;
                narrationSystemMetadata?: any | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
            } | null;
        } | null>;
    } | null;
};

export type GqlClientAccountConnectionsChangedSubscriptionVariables = Exact<{
    accountId: Scalars["ID"]["input"];
}>;

export type GqlClientAccountConnectionsChangedSubscription = {
    __typename?: "Subscription";
    accountConnectionsChanged?: {
        __typename?: "Account";
        id: string;
        accountConnections: Array<{ __typename?: "AccountConnection"; id: string; isConnected: boolean; connectorType: string; connectionUpdated: string; connectionUpdatedBy: string }>;
    } | null;
};

export type GqlClientAccountCopilotBriefListChangedSubscriptionVariables = Exact<{
    accountId: Scalars["ID"]["input"];
}>;

export type GqlClientAccountCopilotBriefListChangedSubscription = {
    __typename?: "Subscription";
    accountCopilotBriefListChanged?: {
        __typename?: "Account";
        id: string;
        copilotOtherUsersBriefs: Array<{ __typename?: "AccountCopilotBrief"; id: string; name: string; briefData: any; createdBy: string; created: string }>;
        copilotUserBriefs: Array<{ __typename?: "AccountCopilotBrief"; id: string; name: string; briefData: any; createdBy: string; created: string }>;
    } | null;
};

export type GqlClientAccountDataLibraryForImportDataChangedSubscriptionVariables = Exact<{
    dataLibraryId: Scalars["ID"]["input"];
}>;

export type GqlClientAccountDataLibraryForImportDataChangedSubscription = {
    __typename?: "Subscription";
    accountDataLibraryForImportDataChanged?: {
        __typename?: "AccountDataLibrary";
        id: string;
        name: string;
        publishedVideosUsingLibrary: Array<{
            __typename?: "EditorProgram";
            id: string;
            name: string;
            prodVersion?: {
                __typename?: "LifecycleHistoryEntry";
                id: string;
                created: string;
                editorProgramVersion?: {
                    __typename?: "EditorProgramVersion";
                    id: string;
                    usedElements?: any | null;
                    thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
                } | null;
            } | null;
        }>;
    } | null;
};

export type GqlClientAccountDataLibraryListChangedSubscriptionVariables = Exact<{
    accountId: Scalars["ID"]["input"];
}>;

export type GqlClientAccountDataLibraryListChangedSubscription = {
    __typename?: "Subscription";
    accountDataLibraryListChanged?: {
        __typename?: "Account";
        id: string;
        dataLibraries: Array<{
            __typename?: "AccountDataLibrary";
            id: string;
            name: string;
            description?: string | null;
            anyUpdated: string;
            anyUpdatedBy?: string | null;
            draftAccountDataLibraryVersion: {
                __typename?: "AccountDataLibraryVersion";
                id: string;
                localId: string;
                data: any;
                anyUpdated: string;
                anyUpdatedBy?: string | null;
                usedInVideos: Array<{
                    __typename?: "EditorProgram";
                    id: string;
                    name: string;
                    isArchive?: boolean | null;
                    isLive?: boolean | null;
                    programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                    prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                    shareHistory: Array<{
                        __typename?: "ProgramShareHistory";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        accountId: string;
                        shareType: GqlClientProgramShareType;
                        isExternal?: boolean | null;
                    }>;
                }>;
                dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
            };
        }>;
    } | null;
};

export type GqlClientAccountDataLibraryProgramListChangedSubscriptionVariables = Exact<{
    accountDataLibraryVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientAccountDataLibraryProgramListChangedSubscription = {
    __typename?: "Subscription";
    accountDataLibraryProgramListChanged?: {
        __typename?: "AccountDataLibraryVersion";
        id: string;
        accountDataLibrary: {
            __typename?: "AccountDataLibrary";
            id: string;
            draftAccountDataLibraryVersion: { __typename?: "AccountDataLibraryVersion"; id: string; usedInVideos: Array<{ __typename?: "EditorProgram"; id: string; name: string }> };
        };
    } | null;
};

export type GqlClientAccountDataLibraryProgramUsedInChangedSubscriptionVariables = Exact<{
    accountDataLibraryVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientAccountDataLibraryProgramUsedInChangedSubscription = {
    __typename?: "Subscription";
    accountDataLibraryProgramUsedInChanged?: { __typename?: "EditorProgram"; id: string; programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null } } | null;
};

export type GqlClientAccountDataLibraryVersionChangedSubscriptionVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientAccountDataLibraryVersionChangedSubscription = {
    __typename?: "Subscription";
    accountDataLibraryVersionChanged?: {
        __typename?: "AccountDataLibraryVersion";
        id: string;
        localId: string;
        data: any;
        anyUpdated: string;
        anyUpdatedBy?: string | null;
        usedInVideos: Array<{
            __typename?: "EditorProgram";
            id: string;
            name: string;
            isArchive?: boolean | null;
            isLive?: boolean | null;
            programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
            prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
            uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
            shareHistory: Array<{
                __typename?: "ProgramShareHistory";
                id: string;
                created: string;
                createdBy?: string | null;
                accountId: string;
                shareType: GqlClientProgramShareType;
                isExternal?: boolean | null;
            }>;
        }>;
        dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
        accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
    } | null;
};

export type GqlClientAccountEditorProgramDeletedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountEditorProgramDeletedSubscription = {
    __typename?: "Subscription";
    accountEditorProgramDeleted?: {
        __typename?: "Account";
        id: string;
        editorPrograms: Array<{ __typename?: "EditorProgram"; id: string }>;
        editorProgramsIncludingRemoved: Array<{ __typename?: "EditorProgram"; id: string; lifecycleStage?: GqlClientAssetLifecycleStage | null }>;
    } | null;
};

export type GqlClientAccountEditorProgramUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountEditorProgramUpdatedSubscription = {
    __typename?: "Subscription";
    accountEditorProgramUpdated?: {
        __typename?: "EditorProgram";
        id: string;
        name: string;
        isArchive?: boolean | null;
        lastArchived?: string | null;
        lifecycleStage?: GqlClientAssetLifecycleStage | null;
        anyUpdated?: string | null;
        folder?: { __typename?: "AccountProgramFolder"; id: string } | null;
    } | null;
};

export type GqlClientAccountFeaturesDataChangesChangedSubscriptionVariables = Exact<{
    accountId: Scalars["ID"]["input"];
}>;

export type GqlClientAccountFeaturesDataChangesChangedSubscription = {
    __typename?: "Subscription";
    accountFeaturesDataChanged?: {
        __typename?: "Account";
        id: string;
        accountFeatureData?: Array<{
            __typename?: "AccountFeatureData";
            id: string;
            featureSource: GqlClientFeatureSource;
            featureRestrictionValue?: any | null;
            featureCurrentUsageInfo?: any | null;
        }> | null;
    } | null;
};

export type GqlClientAccountFontLibraryChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountFontLibraryChangedSubscription = {
    __typename?: "Subscription";
    accountFontLibraryChanged?: {
        __typename?: "EditorAssetLibraryVersion";
        id: string;
        fonts: Array<{
            __typename?: "EditorAssetFont";
            id: string;
            name: string;
            url?: string | null;
            imageUrl?: string | null;
            webFontUrl?: string | null;
            hasSmallCaps?: boolean | null;
            lifecycleStage?: GqlClientAssetLifecycleStage | null;
            uploadId?: string | null;
            created: string;
            familyName?: string | null;
            isBold?: boolean | null;
            isItalic?: boolean | null;
            weight?: number | null;
        } | null>;
    } | null;
};

export type GqlClientAccountMediaAssetLibraryChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountMediaAssetLibraryChangedSubscription = {
    __typename?: "Subscription";
    accountMediaAssetLibraryChanged?: {
        __typename?: "EditorAssetLibraryVersion";
        id: string;
        media: Array<{
            __typename?: "EditorAssetMedia";
            id: string;
            name: string;
            created: string;
            updated: string;
            mediaLocation: string;
            mediaUrl: string;
            mediaPreviewUrl: string;
            mediaAgifUrl?: string | null;
            mediaOrigin: GqlClientMediaOrigin;
            mediaMetadata: any;
            mediaProcessingInfo?: any | null;
            mediaFramesThumbnailsUrl?: string | null;
            originalEditInformation?: any | null;
            originalMediaId?: string | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            mediaSri?: string | null;
            externalLibraryAssetId?: string | null;
            externalLibrary?: GqlClientExternalLibrary | null;
            externalSublibrary?: GqlClientExternalSubLibrary | null;
            directAncestorId?: string | null;
            mediaClassification: GqlClientMediaClassification;
            description?: string | null;
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
        }>;
        mediaFolders: Array<{
            __typename?: "EditorAssetFolder";
            id: string;
            name: string;
            created: string;
            createdBy?: string | null;
            updated: string;
            updatedBy?: string | null;
            parentFolder?: { __typename?: "EditorAssetFolder"; id: string } | null;
        }>;
    } | null;
};

export type GqlClientAccountMusicLibraryChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountMusicLibraryChangedSubscription = {
    __typename?: "Subscription";
    accountMusicLibraryChanged?: {
        __typename?: "EditorAssetLibraryVersion";
        id: string;
        music: Array<{
            __typename?: "EditorAssetMusic";
            id: string;
            name: string;
            url: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            externalLibraryAssetId?: string | null;
            musicOrigin: GqlClientMusicOrigin;
        } | null>;
    } | null;
};

export type GqlClientAccountProgramFolderChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountProgramFolderChangedSubscription = {
    __typename?: "Subscription";
    accountProgramFolderChanged?: { __typename?: "AccountProgramFolder"; id: string; name: string; parentFolder?: { __typename?: "AccountProgramFolder"; id: string } | null } | null;
};

export type GqlClientAccountProgramFolderDeletedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountProgramFolderDeletedSubscription = {
    __typename?: "Subscription";
    accountProgramFolderDeleted?: {
        __typename?: "Account";
        id: string;
        editorPrograms: Array<{ __typename?: "EditorProgram"; id: string; folder?: { __typename?: "AccountProgramFolder"; id: string } | null }>;
        folders: Array<{ __typename?: "AccountProgramFolder"; id: string; parentFolder?: { __typename?: "AccountProgramFolder"; id: string } | null }>;
    } | null;
};

export type GqlClientAccountProgramFoldersCountChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountProgramFoldersCountChangedSubscription = {
    __typename?: "Subscription";
    accountProgramFoldersCountChanged?: {
        __typename?: "Account";
        id: string;
        folders: Array<{
            __typename?: "AccountProgramFolder";
            id: string;
            isRoot: boolean;
            name: string;
            created: string;
            createdBy: string;
            updated: string;
            updatedBy: string;
            parentFolder?: { __typename?: "AccountProgramFolder"; id: string; name: string; created: string; createdBy: string; updated: string; updatedBy: string; isRoot: boolean } | null;
        }>;
    } | null;
};

export type GqlClientAccountPronunciationDefinitionChangedSubscriptionVariables = Exact<{
    accountId: Scalars["ID"]["input"];
}>;

export type GqlClientAccountPronunciationDefinitionChangedSubscription = {
    __typename?: "Subscription";
    accountPronunciationDefinitionChanged?: {
        __typename?: "AccountPronunciationDefinition";
        id: string;
        source: string;
        target: string;
        pronunciationLibraryVersion: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null; anyUpdatedBy?: string | null };
    } | null;
};

export type GqlClientAccountPronunciationLibraryVersionChangedSubscriptionVariables = Exact<{
    accountId: Scalars["ID"]["input"];
}>;

export type GqlClientAccountPronunciationLibraryVersionChangedSubscription = {
    __typename?: "Subscription";
    accountPronunciationLibraryVersionChanged?: {
        __typename?: "AccountPronunciationLibraryVersion";
        id: string;
        anyUpdatedBy?: string | null;
        anyUpdated?: string | null;
        definitions: Array<{ __typename?: "AccountPronunciationDefinition"; id: string; source: string; target: string }>;
    } | null;
};

export type GqlClientAccountServiceConnectionsChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountServiceConnectionsChangedSubscription = {
    __typename?: "Subscription";
    accountServiceConnectionsChanged: {
        __typename?: "Account";
        id: string;
        accountServiceConnections: Array<{ __typename?: "AccountServiceConnection"; id: string; serviceType: GqlClientServiceType }>;
    };
};

export type GqlClientAiPreviewProgramCreatedSubscriptionVariables = Exact<{
    sessionId: Scalars["ID"]["input"];
}>;

export type GqlClientAiPreviewProgramCreatedSubscription = {
    __typename?: "Subscription";
    aiPreviewProgramCreated?:
        | { __typename?: "AiPreviewProgramCreatedPayloadError"; id: string; status: GqlClientStatus; sessionId: string; mutationId: string }
        | { __typename?: "AiPreviewProgramCreatedPayloadKeepAlive"; id: string; status: GqlClientStatus; sessionId: string; mutationId: string }
        | { __typename?: "AiPreviewProgramCreatedPayloadPending"; progress: number; id: string; status: GqlClientStatus; sessionId: string; mutationId: string }
        | {
              __typename?: "AiPreviewProgramCreatedPayloadSuccess";
              id: string;
              status: GqlClientStatus;
              sessionId: string;
              mutationId: string;
              data: {
                  __typename?: "AiPreviewProgramCreatedPayloadData";
                  playerToken: string;
                  previewId: string;
                  dimensions: { __typename?: "Dimension"; width?: number | null; height?: number | null };
              };
          }
        | null;
};

export type GqlClientAiVideoFromImageGeneratedSubscriptionVariables = Exact<{
    mutationId: Scalars["ID"]["input"];
}>;

export type GqlClientAiVideoFromImageGeneratedSubscription = {
    __typename?: "Subscription";
    aiVideoFromImageGenerated?:
        | { __typename?: "AiVideoFromImageGeneratedPayloadError"; error: any; mutationId: string; status: GqlClientStatus }
        | { __typename?: "AiVideoFromImageGeneratedPayloadKeepAlive"; mutationId: string; status: GqlClientStatus }
        | { __typename?: "AiVideoFromImageGeneratedPayloadPending"; progress: number; mutationId: string; status: GqlClientStatus }
        | {
              __typename?: "AiVideoFromImageGeneratedPayloadSuccess";
              mutationId: string;
              status: GqlClientStatus;
              data: { __typename?: "AiVideoFromImageGeneratedPayloadData"; video: { __typename?: "AiVideoResult"; url: string } };
          }
        | null;
};

export type GqlClientBrandConfigurationCollectionChangedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GqlClientBrandConfigurationCollectionChangedSubscription = {
    __typename?: "Subscription";
    brandConfigurationCollectionChanged?: {
        __typename?: "BrandConfigurationCollection";
        id: string;
        localId: string;
        updated: string;
        updatedBy?: string | null;
        created: string;
        name: string;
        description?: string | null;
        logoUrl?: string | null;
        logoLocation?: string | null;
        buttonShape: GqlClientButtonShapeValues;
        logoMedia?: {
            __typename?: "EditorAssetMedia";
            id: string;
            name: string;
            created: string;
            updated: string;
            mediaLocation: string;
            mediaUrl: string;
            mediaPreviewUrl: string;
            mediaAgifUrl?: string | null;
            mediaOrigin: GqlClientMediaOrigin;
            mediaMetadata: any;
            mediaProcessingInfo?: any | null;
            mediaFramesThumbnailsUrl?: string | null;
            originalEditInformation?: any | null;
            originalMediaId?: string | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            mediaSri?: string | null;
            externalLibraryAssetId?: string | null;
            externalLibrary?: GqlClientExternalLibrary | null;
            externalSublibrary?: GqlClientExternalSubLibrary | null;
            directAncestorId?: string | null;
            mediaClassification: GqlClientMediaClassification;
            description?: string | null;
            createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
        } | null;
        colors: Array<{
            __typename?: "BcColor";
            id: string;
            updated: string;
            color: string;
            ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
        }>;
        textStyles: Array<{
            __typename?: "BcTextStyle";
            id: string;
            fontSource: GqlClientEditorAssetSource;
            letterCase: GqlClientTextStyleLetterCase;
            letterSpacing: number;
            lineSpacing: number;
            updated: string;
            ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
            ccFont?: {
                __typename?: "CcFont";
                id: string;
                name: string;
                url: string;
                hasSmallCaps?: boolean | null;
                lifecycleStage?: GqlClientAssetLifecycleStage | null;
                webFontUrl?: string | null;
                created: string;
                familyName?: string | null;
                isBold?: boolean | null;
                isItalic?: boolean | null;
                weight?: number | null;
            } | null;
            accountFont?: {
                __typename?: "EditorAssetFont";
                id: string;
                name: string;
                url?: string | null;
                imageUrl?: string | null;
                webFontUrl?: string | null;
                hasSmallCaps?: boolean | null;
                lifecycleStage?: GqlClientAssetLifecycleStage | null;
                uploadId?: string | null;
                created: string;
                familyName?: string | null;
                isBold?: boolean | null;
                isItalic?: boolean | null;
                weight?: number | null;
            } | null;
        }>;
    } | null;
};

export type GqlClientBrandConfigurationVersionChangedSubscriptionVariables = Exact<{
    accountId: Scalars["ID"]["input"];
}>;

export type GqlClientBrandConfigurationVersionChangedSubscription = {
    __typename?: "Subscription";
    brandConfigurationVersionChanged?: {
        __typename?: "BrandConfigurationVersion";
        id: string;
        brandConfigurationCollections: Array<{
            __typename?: "BrandConfigurationCollection";
            id: string;
            localId: string;
            updated: string;
            updatedBy?: string | null;
            created: string;
            name: string;
            description?: string | null;
            logoUrl?: string | null;
            logoLocation?: string | null;
            buttonShape: GqlClientButtonShapeValues;
            logoMedia?: {
                __typename?: "EditorAssetMedia";
                id: string;
                name: string;
                created: string;
                updated: string;
                mediaLocation: string;
                mediaUrl: string;
                mediaPreviewUrl: string;
                mediaAgifUrl?: string | null;
                mediaOrigin: GqlClientMediaOrigin;
                mediaMetadata: any;
                mediaProcessingInfo?: any | null;
                mediaFramesThumbnailsUrl?: string | null;
                originalEditInformation?: any | null;
                originalMediaId?: string | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                mediaSri?: string | null;
                externalLibraryAssetId?: string | null;
                externalLibrary?: GqlClientExternalLibrary | null;
                externalSublibrary?: GqlClientExternalSubLibrary | null;
                directAncestorId?: string | null;
                mediaClassification: GqlClientMediaClassification;
                description?: string | null;
                createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
            } | null;
            colors: Array<{
                __typename?: "BcColor";
                id: string;
                updated: string;
                color: string;
                ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
            }>;
            textStyles: Array<{
                __typename?: "BcTextStyle";
                id: string;
                fontSource: GqlClientEditorAssetSource;
                letterCase: GqlClientTextStyleLetterCase;
                letterSpacing: number;
                lineSpacing: number;
                updated: string;
                ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                ccFont?: {
                    __typename?: "CcFont";
                    id: string;
                    name: string;
                    url: string;
                    hasSmallCaps?: boolean | null;
                    lifecycleStage?: GqlClientAssetLifecycleStage | null;
                    webFontUrl?: string | null;
                    created: string;
                    familyName?: string | null;
                    isBold?: boolean | null;
                    isItalic?: boolean | null;
                    weight?: number | null;
                } | null;
                accountFont?: {
                    __typename?: "EditorAssetFont";
                    id: string;
                    name: string;
                    url?: string | null;
                    imageUrl?: string | null;
                    webFontUrl?: string | null;
                    hasSmallCaps?: boolean | null;
                    lifecycleStage?: GqlClientAssetLifecycleStage | null;
                    uploadId?: string | null;
                    created: string;
                    familyName?: string | null;
                    isBold?: boolean | null;
                    isItalic?: boolean | null;
                    weight?: number | null;
                } | null;
            }>;
        }>;
        defaultBrandConfigurationCollection: { __typename?: "BrandConfigurationCollection"; id: string };
    } | null;
};

export type GqlClientDataLibraryImportDataUpdatedSubscriptionVariables = Exact<{
    dataLibraryId: Scalars["ID"]["input"];
}>;

export type GqlClientDataLibraryImportDataUpdatedSubscription = {
    __typename?: "Subscription";
    dataLibraryImportDataUpdated?:
        | { __typename: "DataLibraryImportDataUpdatedPayloadError"; error: any; dataLibraryId: string; status: GqlClientStatus; mutationId: string }
        | { __typename: "DataLibraryImportDataUpdatedPayloadKeepAlive"; dataLibraryId: string; status: GqlClientStatus; mutationId: string }
        | { __typename: "DataLibraryImportDataUpdatedPayloadPending"; dataLibraryId: string; status: GqlClientStatus; mutationId: string }
        | {
              __typename: "DataLibraryImportDataUpdatedPayloadSuccess";
              dataLibraryId: string;
              status: GqlClientStatus;
              mutationId: string;
              data: { __typename?: "DataLibraryImportDataUpdatedPayloadData"; numFailedUsers: number; numSuccessfulUsers: number };
          }
        | null;
};

export type GqlClientDataLibraryImportHistoryChangedSubscriptionVariables = Exact<{
    dataLibraryId: Scalars["ID"]["input"];
}>;

export type GqlClientDataLibraryImportHistoryChangedSubscription = {
    __typename?: "Subscription";
    dataLibraryImportHistoryChanged?: {
        __typename?: "DataLibraryImportHistory";
        id: string;
        dataLibraryId: string;
        importStatusMetadataItems: Array<{
            __typename?: "DataLibraryImportHistoryItem";
            id: string;
            status: GqlClientImportTaskStatus;
            user: string;
            fileName: string;
            date: string;
            countFailures?: number | null;
            countSuccesses?: number | null;
        }>;
    } | null;
};

export type GqlClientEditorAssetEditedSubscriptionVariables = Exact<{
    accountId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorAssetEditedSubscription = {
    __typename?: "Subscription";
    editorAssetEdited?:
        | { __typename: "EditorAssetEditedPayloadError"; error: any; id: string; status: GqlClientStatus; mutationId: string; originalName: string }
        | { __typename: "EditorAssetEditedPayloadKeepAlive"; id: string; status: GqlClientStatus; mutationId: string; originalName: string }
        | { __typename: "EditorAssetEditedPayloadPending"; progress: number; id: string; status: GqlClientStatus; mutationId: string; originalName: string }
        | {
              __typename: "EditorAssetEditedPayloadSuccess";
              id: string;
              status: GqlClientStatus;
              mutationId: string;
              originalName: string;
              data: {
                  __typename?: "EditorAssetEditedPayloadData";
                  editorAsset: {
                      __typename?: "EditorAssetMedia";
                      id: string;
                      name: string;
                      created: string;
                      updated: string;
                      mediaLocation: string;
                      mediaUrl: string;
                      mediaPreviewUrl: string;
                      mediaAgifUrl?: string | null;
                      mediaOrigin: GqlClientMediaOrigin;
                      mediaMetadata: any;
                      mediaProcessingInfo?: any | null;
                      mediaFramesThumbnailsUrl?: string | null;
                      originalEditInformation?: any | null;
                      originalMediaId?: string | null;
                      lifecycleStage: GqlClientAssetLifecycleStage;
                      mediaSri?: string | null;
                      externalLibraryAssetId?: string | null;
                      externalLibrary?: GqlClientExternalLibrary | null;
                      externalSublibrary?: GqlClientExternalSubLibrary | null;
                      directAncestorId?: string | null;
                      mediaClassification: GqlClientMediaClassification;
                      description?: string | null;
                      editorAssetLibraryVersion: { __typename?: "EditorAssetLibraryVersion"; id: string; media: Array<{ __typename?: "EditorAssetMedia"; id: string }> };
                      createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                      folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                  };
                  editorPlaceholder?: {
                      __typename?: "EditorPlaceholder";
                      id: string;
                      placeholderContent?: any | null;
                      placeholderSettings?: any | null;
                      parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                      flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                      parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
                  } | null;
              };
          }
        | null;
};

export type GqlClientEditorAssetMediaUploadedSubscriptionVariables = Exact<{
    accountId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorAssetMediaUploadedSubscription = {
    __typename?: "Subscription";
    editorAssetMediaUploaded?:
        | { __typename: "EditorAssetMediaUploadedPayloadError"; error: any; id: string; status: GqlClientStatus; fileName: string; mutationId: string }
        | { __typename: "EditorAssetMediaUploadedPayloadKeepAlive"; id: string; status: GqlClientStatus; fileName: string; mutationId: string }
        | { __typename: "EditorAssetMediaUploadedPayloadPending"; progress: number; id: string; status: GqlClientStatus; fileName: string; mutationId: string }
        | {
              __typename: "EditorAssetMediaUploadedPayloadSuccess";
              id: string;
              status: GqlClientStatus;
              fileName: string;
              mutationId: string;
              data: {
                  __typename?: "EditorAssetMediaUploadedPayloadData";
                  editorAsset: {
                      __typename?: "EditorAssetMedia";
                      id: string;
                      name: string;
                      created: string;
                      updated: string;
                      mediaLocation: string;
                      mediaUrl: string;
                      mediaPreviewUrl: string;
                      mediaAgifUrl?: string | null;
                      mediaOrigin: GqlClientMediaOrigin;
                      mediaMetadata: any;
                      mediaProcessingInfo?: any | null;
                      mediaFramesThumbnailsUrl?: string | null;
                      originalEditInformation?: any | null;
                      originalMediaId?: string | null;
                      lifecycleStage: GqlClientAssetLifecycleStage;
                      mediaSri?: string | null;
                      externalLibraryAssetId?: string | null;
                      externalLibrary?: GqlClientExternalLibrary | null;
                      externalSublibrary?: GqlClientExternalSubLibrary | null;
                      directAncestorId?: string | null;
                      mediaClassification: GqlClientMediaClassification;
                      description?: string | null;
                      editorAssetLibraryVersion: { __typename?: "EditorAssetLibraryVersion"; id: string; media: Array<{ __typename?: "EditorAssetMedia"; id: string }> };
                      createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                      folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                  };
              };
          }
        | null;
};

export type GqlClientEditorProgramVersionLogicRemovedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientEditorProgramVersionLogicRemovedSubscription = {
    __typename?: "Subscription";
    editorProgramVersionLogicRemoved?: {
        __typename?: "EditorProgramVersion";
        id: string;
        usedElements?: any | null;
        bcRules?: any | null;
        bcUseRules?: boolean | null;
        selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string };
        scenes: Array<{
            __typename?: "EditorScene";
            id: string;
            skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
            skipSceneAudience?: any | null;
            placeholders: Array<{ __typename?: "EditorPlaceholder"; id: string; placeholderContent?: any | null }>;
        }>;
    } | null;
};

export type GqlClientProgramEditorPublishedSubscriptionVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramEditorPublishedSubscription = {
    __typename?: "Subscription";
    programEditorPublished?:
        | { __typename: "ProgramEditorPublishedPayloadError"; error: any; id: string; status: GqlClientStatus; userName: string; snapshotName: string; mutationId: string }
        | { __typename: "ProgramEditorPublishedPayloadKeepAlive"; id: string; status: GqlClientStatus; userName: string; snapshotName: string; mutationId: string }
        | { __typename: "ProgramEditorPublishedPayloadPending"; progress: number; id: string; status: GqlClientStatus; userName: string; snapshotName: string; mutationId: string }
        | {
              __typename: "ProgramEditorPublishedPayloadSuccess";
              id: string;
              status: GqlClientStatus;
              userName: string;
              snapshotName: string;
              mutationId: string;
              data: {
                  __typename?: "ProgramEditorPublishedPayloadData";
                  editorProgram: {
                      __typename?: "EditorProgram";
                      id: string;
                      name: string;
                      description?: string | null;
                      saleForceId?: string | null;
                      programType: GqlClientEditorProgramType;
                      publishTarget: GqlClientLifecycleStage;
                      created: string;
                      isArchive?: boolean | null;
                      isLive?: boolean | null;
                      firstViewedDate?: string | null;
                      isReadyToGoLive: boolean;
                      landingPageUrl?: string | null;
                      programVersionDraft: {
                          __typename?: "EditorProgramVersion";
                          id: string;
                          anyUpdatedBy?: string | null;
                          anyUpdated?: string | null;
                          publishable?: any | null;
                          sceneLineup: Array<string>;
                          editorDataConnectorEnabled?: boolean | null;
                          chapteringEnabled?: boolean | null;
                          editorTtsFullEnablement?: boolean | null;
                          selectedMusicSource: GqlClientEditorAssetSource;
                          compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                          accountDataLibraryVersion?: {
                              __typename?: "AccountDataLibraryVersion";
                              id: string;
                              data: any;
                              anyUpdatedBy?: string | null;
                              anyUpdated: string;
                              dataFields: Array<{ __typename?: "AccountDataField"; id: string; data: any; localId: string }>;
                              accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string };
                          } | null;
                          ccLibraryVersion?: { __typename?: "CcLibraryVersion"; id: string; library: { __typename?: "CcLibrary"; id: string } } | null;
                          pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
                          selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string };
                      };
                      uatVersion?: {
                          __typename?: "LifecycleHistoryEntry";
                          id: string;
                          created: string;
                          createdBy?: string | null;
                          snapshotName?: string | null;
                          snapshotNumber?: number | null;
                          snapshotValidationData?: any | null;
                          accountDataLibraryVersion?: {
                              __typename?: "AccountDataLibraryVersion";
                              id: string;
                              localId: string;
                              data: any;
                              anyUpdated: string;
                              anyUpdatedBy?: string | null;
                              usedInVideos: Array<{
                                  __typename?: "EditorProgram";
                                  id: string;
                                  name: string;
                                  isArchive?: boolean | null;
                                  isLive?: boolean | null;
                                  programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                                  prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                                  uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                                  shareHistory: Array<{
                                      __typename?: "ProgramShareHistory";
                                      id: string;
                                      created: string;
                                      createdBy?: string | null;
                                      accountId: string;
                                      shareType: GqlClientProgramShareType;
                                      isExternal?: boolean | null;
                                  }>;
                              }>;
                              dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                              accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
                          } | null;
                          editorProgramVersion?: {
                              __typename?: "EditorProgramVersion";
                              id: string;
                              editorDataConnectorEnabled?: boolean | null;
                              selectedMusicSource: GqlClientEditorAssetSource;
                              usedElements?: any | null;
                              chapteringEnabled?: boolean | null;
                              thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
                              pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
                              selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string };
                          } | null;
                      } | null;
                      prodVersion?: {
                          __typename?: "LifecycleHistoryEntry";
                          id: string;
                          created: string;
                          createdBy?: string | null;
                          snapshotName?: string | null;
                          snapshotNumber?: number | null;
                          snapshotValidationData?: any | null;
                          accountDataLibraryVersion?: {
                              __typename?: "AccountDataLibraryVersion";
                              id: string;
                              localId: string;
                              data: any;
                              anyUpdated: string;
                              anyUpdatedBy?: string | null;
                              usedInVideos: Array<{
                                  __typename?: "EditorProgram";
                                  id: string;
                                  name: string;
                                  isArchive?: boolean | null;
                                  isLive?: boolean | null;
                                  programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                                  prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                                  uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                                  shareHistory: Array<{
                                      __typename?: "ProgramShareHistory";
                                      id: string;
                                      created: string;
                                      createdBy?: string | null;
                                      accountId: string;
                                      shareType: GqlClientProgramShareType;
                                      isExternal?: boolean | null;
                                  }>;
                              }>;
                              dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                              accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
                          } | null;
                          editorProgramVersion?: {
                              __typename?: "EditorProgramVersion";
                              id: string;
                              editorDataConnectorEnabled?: boolean | null;
                              selectedMusicSource: GqlClientEditorAssetSource;
                              usedElements?: any | null;
                              chapteringEnabled?: boolean | null;
                              thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
                              pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
                              selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string };
                          } | null;
                      } | null;
                      firstProdVersion?: {
                          __typename?: "LifecycleHistoryEntry";
                          id: string;
                          created: string;
                          createdBy?: string | null;
                          snapshotName?: string | null;
                          snapshotNumber?: number | null;
                          snapshotValidationData?: any | null;
                          accountDataLibraryVersion?: {
                              __typename?: "AccountDataLibraryVersion";
                              id: string;
                              localId: string;
                              data: any;
                              anyUpdated: string;
                              anyUpdatedBy?: string | null;
                              usedInVideos: Array<{
                                  __typename?: "EditorProgram";
                                  id: string;
                                  name: string;
                                  isArchive?: boolean | null;
                                  isLive?: boolean | null;
                                  programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                                  prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                                  uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                                  shareHistory: Array<{
                                      __typename?: "ProgramShareHistory";
                                      id: string;
                                      created: string;
                                      createdBy?: string | null;
                                      accountId: string;
                                      shareType: GqlClientProgramShareType;
                                      isExternal?: boolean | null;
                                  }>;
                              }>;
                              dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                              accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
                          } | null;
                          editorProgramVersion?: {
                              __typename?: "EditorProgramVersion";
                              id: string;
                              editorDataConnectorEnabled?: boolean | null;
                              selectedMusicSource: GqlClientEditorAssetSource;
                              usedElements?: any | null;
                              chapteringEnabled?: boolean | null;
                              thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
                              pronunciationLibraryVersion?: { __typename?: "AccountPronunciationLibraryVersion"; id: string; anyUpdated?: string | null } | null;
                              selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; localId: string; name: string; updated: string };
                          } | null;
                      } | null;
                      landingPage?: {
                          __typename?: "LandingPage";
                          id: string;
                          updated: string;
                          updatedBy?: string | null;
                          created: string;
                          createdBy?: string | null;
                          configuration?: any | null;
                          isActive?: boolean | null;
                          lifecycleStage?: GqlClientAssetLifecycleStage | null;
                          updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                          createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                      } | null;
                      embedVideoCode?: {
                          __typename?: "EmbedVideoCode";
                          id: string;
                          updated: string;
                          updatedBy?: string | null;
                          created: string;
                          createdBy?: string | null;
                          configuration?: any | null;
                          updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                          createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                      } | null;
                      shareHistory: Array<{
                          __typename?: "ProgramShareHistory";
                          id: string;
                          created: string;
                          createdBy?: string | null;
                          accountId: string;
                          shareType: GqlClientProgramShareType;
                          isExternal?: boolean | null;
                      }>;
                  };
              };
          }
        | null;
};

export type GqlClientProgramEmbedVideoCodeUpdatedSubscriptionVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramEmbedVideoCodeUpdatedSubscription = {
    __typename?: "Subscription";
    programEmbedVideoCodeUpdated?: {
        __typename?: "EmbedVideoCode";
        id: string;
        editorProgram: {
            __typename?: "EditorProgram";
            id: string;
            embedVideoCode?: {
                __typename?: "EmbedVideoCode";
                id: string;
                updated: string;
                updatedBy?: string | null;
                created: string;
                createdBy?: string | null;
                configuration?: any | null;
                updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            } | null;
        };
    } | null;
};

export type GqlClientProgramLandingPageUpdatedSubscriptionVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramLandingPageUpdatedSubscription = {
    __typename?: "Subscription";
    programLandingPageUpdated?: {
        __typename?: "LandingPage";
        id: string;
        editorProgram: {
            __typename?: "EditorProgram";
            id: string;
            landingPage?: {
                __typename?: "LandingPage";
                id: string;
                updated: string;
                updatedBy?: string | null;
                created: string;
                createdBy?: string | null;
                configuration?: any | null;
                isActive?: boolean | null;
                lifecycleStage?: GqlClientAssetLifecycleStage | null;
                updatedByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
            } | null;
        };
    } | null;
};

export type GqlClientProgramReviewCreatedSubscriptionVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramReviewCreatedSubscription = {
    __typename?: "Subscription";
    programReviewCreated: {
        __typename?: "ProgramReviewsSummaryOutput";
        id: string;
        programReviews: Array<{
            __typename?: "ProgramReview";
            id: string;
            reviewers: Array<string>;
            created: string;
            name: string;
            createdBy?: string | null;
            reviewLink: string;
            description?: string | null;
            videos: Array<{
                __typename?: "ProgramReviewVideo";
                id: string;
                videoData?: any | null;
                viewerProfileName?: string | null;
                videoThumbnailUrl?: string | null;
                aspectRatio: GqlClientEnumAspectRatio;
            }>;
        }>;
    };
};

export type GqlClientProgramShareHistoryRecordCreatedSubscriptionVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramShareHistoryRecordCreatedSubscription = {
    __typename?: "Subscription";
    programShareHistoryRecordCreated: {
        __typename?: "EditorProgram";
        id: string;
        shareHistory: Array<{
            __typename?: "ProgramShareHistory";
            id: string;
            created: string;
            createdBy?: string | null;
            accountId: string;
            shareType: GqlClientProgramShareType;
            isExternal?: boolean | null;
        }>;
    };
};

export type GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscription = {
    __typename?: "Subscription";
    programVersionAnimatedWireframesCompatabilityChanged?: {
        __typename?: "EditorProgramVersion";
        id: string;
        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
        scenes: Array<{ __typename?: "EditorScene"; id: string; ccAnimatedWireframe?: { __typename?: "CcAnimatedWireframe"; id: string } | null }>;
    } | null;
};

export type GqlClientProgramVersionEditorAudioSettingsUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorAudioSettingsUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorAudioSettingsUpdated?: { __typename?: "EditorProgramVersion"; id: string; audioSettings?: any | null } | null;
};

export type GqlClientProgramVersionEditorAccountDataLibraryChangedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorAccountDataLibraryChangedSubscription = {
    __typename?: "Subscription";
    programVersionEditorAccountDataLibraryChanged?: {
        __typename?: "EditorProgramVersion";
        id: string;
        accountDataLibraryVersion?: {
            __typename?: "AccountDataLibraryVersion";
            id: string;
            localId: string;
            data: any;
            anyUpdated: string;
            anyUpdatedBy?: string | null;
            accountDataLibrary: {
                __typename?: "AccountDataLibrary";
                id: string;
                name: string;
                description?: string | null;
                draftAccountDataLibraryVersion: {
                    __typename?: "AccountDataLibraryVersion";
                    id: string;
                    localId: string;
                    data: any;
                    anyUpdated: string;
                    anyUpdatedBy?: string | null;
                    usedInVideos: Array<{
                        __typename?: "EditorProgram";
                        id: string;
                        name: string;
                        isArchive?: boolean | null;
                        isLive?: boolean | null;
                        programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                        prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                        uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                        shareHistory: Array<{
                            __typename?: "ProgramShareHistory";
                            id: string;
                            created: string;
                            createdBy?: string | null;
                            accountId: string;
                            shareType: GqlClientProgramShareType;
                            isExternal?: boolean | null;
                        }>;
                    }>;
                    dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
                    accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
                };
            };
            usedInVideos: Array<{
                __typename?: "EditorProgram";
                id: string;
                name: string;
                isArchive?: boolean | null;
                isLive?: boolean | null;
                programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null };
                prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
                shareHistory: Array<{
                    __typename?: "ProgramShareHistory";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    accountId: string;
                    shareType: GqlClientProgramShareType;
                    isExternal?: boolean | null;
                }>;
            }>;
            dataFields: Array<{ __typename?: "AccountDataField"; id: string; localId: string; data: any }>;
        } | null;
    } | null;
};

export type GqlClientProgramVersionEditorAspectRatioUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorAspectRatioUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorAspectRatioUpdated?: {
        __typename?: "EditorProgramVersion";
        id: string;
        aspectRatio: GqlClientEnumAspectRatio;
        scenes: Array<{
            __typename?: "EditorScene";
            id: string;
            ccAnimatedWireframe?: {
                __typename?: "CcAnimatedWireframe";
                id: string;
                name: string;
                vsmlUrl: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                aspectRatio: GqlClientEnumAspectRatio;
                compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                theme: { __typename?: "CcTheme"; id: string };
                placeholdersSettings: Array<
                    | {
                          __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                          id: string;
                          horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                          verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                          mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                          canvasName?: string | null;
                          ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                      }
                    | {
                          __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                          id: string;
                          horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                          verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                          canvasName?: string | null;
                          colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                          ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                      }
                >;
                m2AnimatedWireframe?: {
                    __typename?: "CcAnimatedWireframe";
                    id: string;
                    compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                    vsmlUrl: string;
                    defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                } | null;
                composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
            } | null;
        }>;
    } | null;
};

export type GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorAvatarRenderingUpdated?: { __typename?: "EditorProgramVersion"; id: string; isAvatarRendering: boolean } | null;
};

export type GqlClientProgramVersionEditorBrandRulesUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorBrandRulesUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorBrandRulesUpdated?: {
        __typename?: "EditorProgramVersion";
        id: string;
        bcUseRules?: boolean | null;
        bcRules?: any | null;
        selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string };
    } | null;
};

export type GqlClientProgramVersionEditorChangedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorChangedSubscription = {
    __typename?: "Subscription";
    programVersionEditorChanged?: { __typename?: "EditorProgramVersion"; anyUpdated?: string | null; anyUpdatedBy?: string | null; id: string } | null;
};

export type GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorCustomBackgroundUpdated?: {
        __typename?: "EditorProgramVersion";
        id: string;
        useCustomVideoBackground: boolean;
        videoBackgroundMedia?: { __typename?: "EditorAssetMedia"; id: string } | null;
    } | null;
};

export type GqlClientProgramVersionEditorDataLibraryChangedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorDataLibraryChangedSubscription = {
    __typename?: "Subscription";
    programVersionEditorDataLibraryChanged?: {
        __typename?: "EditorProgramVersion";
        id: string;
        studioElements: Array<{ __typename?: "DerivedDataElement"; id: string; localId: string; name: string; dataType?: GqlClientDataElementType | null; valueSet?: Array<any | null> | null }>;
    } | null;
};

export type GqlClientProgramVersionEditorLineupUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorLineupUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorLineupUpdated?: { __typename?: "EditorProgramVersion"; id: string; sceneLineup: Array<string> } | null;
};

export type GqlClientProgramVersionEditorPlaceholderUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorPlaceholderUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorPlaceholderUpdated?: {
        __typename?: "EditorPlaceholder";
        id: string;
        placeholderContent?: any | null;
        placeholderSettings?: any | null;
        parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
        flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
        parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
    } | null;
};

export type GqlClientProgramVersionEditorSceneCreatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorSceneCreatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorSceneCreated?: {
        __typename?: "EditorScene";
        id: string;
        usedMediaIds: Array<string>;
        name: string;
        description?: string | null;
        skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
        colorCombination: GqlClientEditorSceneColorCombination;
        buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
        skipSceneAudience?: any | null;
        minimumDuration?: number | null;
        hasTransition?: boolean | null;
        avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
        avatarVideoUrl?: string | null;
        programVersion: { __typename?: "EditorProgramVersion"; id: string; sceneLineup: Array<string>; scenes: Array<{ __typename?: "EditorScene"; id: string }> };
        placeholders: Array<{
            __typename?: "EditorPlaceholder";
            id: string;
            placeholderContent?: any | null;
            placeholderSettings?: any | null;
            parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
            ccPlaceholder: {
                __typename?: "CcPlaceholder";
                id: string;
                name: string;
                content?: any | null;
                category: GqlClientCcPlaceholderCategory;
                intent: {
                    __typename?: "CcPlaceholderIntent";
                    id: string;
                    name: string;
                    type: GqlClientCcPlaceholderType;
                    defaultValue?: string | null;
                    textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                };
            };
            flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
            parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
        }>;
        ccWireframe: {
            __typename?: "CcWireframe";
            name: string;
            id: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            category: GqlClientCcWireframeCategory;
            frames: Array<{ __typename?: "CcFrame"; id: string; placeholders: Array<{ __typename?: "CcPlaceholder"; id: string }> }>;
        };
        ccAnimatedWireframe?: {
            __typename?: "CcAnimatedWireframe";
            id: string;
            name: string;
            vsmlUrl: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            aspectRatio: GqlClientEnumAspectRatio;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
            theme: { __typename?: "CcTheme"; id: string };
            placeholdersSettings: Array<
                | {
                      __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                      id: string;
                      horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                      verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                      mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                      canvasName?: string | null;
                      ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                  }
                | {
                      __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                      id: string;
                      horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                      verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                      canvasName?: string | null;
                      colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                      ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                  }
            >;
            m2AnimatedWireframe?: {
                __typename?: "CcAnimatedWireframe";
                id: string;
                compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                vsmlUrl: string;
                defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
            } | null;
            composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
        } | null;
    } | null;
};

export type GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdated?: {
        __typename?: "EditorScene";
        id: string;
        colorCombination: GqlClientEditorSceneColorCombination;
        placeholders: Array<{
            __typename?: "EditorPlaceholder";
            id: string;
            placeholderContent?: any | null;
            placeholderSettings?: any | null;
            parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
            ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
            flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
            parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
        }>;
        ccWireframe: {
            __typename?: "CcWireframe";
            id: string;
            allWireframesMapping: Array<{
                __typename?: "CcWireframeAllWireframesData";
                isLosingData: boolean;
                wireframe: { __typename?: "CcWireframe"; id: string; name: string; lifecycleStage: GqlClientAssetLifecycleStage; comment?: string | null; category: GqlClientCcWireframeCategory };
                placeholdersData: Array<{
                    __typename?: "CcWireframePlaceholderData";
                    targetCcPlaceholder: {
                        __typename?: "CcPlaceholder";
                        id: string;
                        name: string;
                        content?: any | null;
                        category: GqlClientCcPlaceholderCategory;
                        intent: {
                            __typename?: "CcPlaceholderIntent";
                            id: string;
                            name: string;
                            type: GqlClientCcPlaceholderType;
                            defaultValue?: string | null;
                            textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                        };
                    };
                    originalCcPlaceholder?: { __typename?: "CcPlaceholder"; id: string } | null;
                } | null>;
            }>;
        };
        ccAnimatedWireframe?: { __typename?: "CcAnimatedWireframe"; id: string } | null;
    } | null;
};

export type GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorScenePlaceholdersUpdated?: {
        __typename?: "EditorScene";
        id: string;
        placeholders: Array<{
            __typename?: "EditorPlaceholder";
            id: string;
            placeholderContent?: any | null;
            placeholderSettings?: any | null;
            parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
            ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
            flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
            parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
        }>;
    } | null;
};

export type GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorSceneSelectedAnimatedWireframeUpdated?: {
        __typename?: "EditorScene";
        id: string;
        ccAnimatedWireframe?: {
            __typename?: "CcAnimatedWireframe";
            id: string;
            name: string;
            vsmlUrl: string;
            lifecycleStage: GqlClientAssetLifecycleStage;
            aspectRatio: GqlClientEnumAspectRatio;
            compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
            defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
            theme: { __typename?: "CcTheme"; id: string };
            placeholdersSettings: Array<
                | {
                      __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                      id: string;
                      horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                      verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                      mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                      canvasName?: string | null;
                      ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                  }
                | {
                      __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                      id: string;
                      horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                      verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                      canvasName?: string | null;
                      colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                      ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                  }
            >;
            m2AnimatedWireframe?: {
                __typename?: "CcAnimatedWireframe";
                id: string;
                compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                vsmlUrl: string;
                defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
            } | null;
            composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
        } | null;
    } | null;
};

export type GqlClientProgramVersionEditorSceneUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorSceneUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorSceneUpdated?: {
        __typename?: "EditorScene";
        id: string;
        name: string;
        description?: string | null;
        skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
        colorCombination: GqlClientEditorSceneColorCombination;
        buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
        skipSceneAudience?: any | null;
        minimumDuration?: number | null;
        hasTransition?: boolean | null;
        avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
        avatarVideoUrl?: string | null;
    } | null;
};

export type GqlClientProgramVersionEditorScenesCreatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorScenesCreatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorScenesCreated?: {
        __typename?: "ProgramEditorSceneCreatedPayloadData";
        editorScenes: Array<{
            __typename?: "EditorScene";
            id: string;
            usedMediaIds: Array<string>;
            name: string;
            description?: string | null;
            skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
            colorCombination: GqlClientEditorSceneColorCombination;
            buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
            skipSceneAudience?: any | null;
            minimumDuration?: number | null;
            hasTransition?: boolean | null;
            avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
            avatarVideoUrl?: string | null;
            placeholders: Array<{
                __typename?: "EditorPlaceholder";
                id: string;
                placeholderContent?: any | null;
                placeholderSettings?: any | null;
                parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                ccPlaceholder: {
                    __typename?: "CcPlaceholder";
                    id: string;
                    name: string;
                    content?: any | null;
                    category: GqlClientCcPlaceholderCategory;
                    intent: {
                        __typename?: "CcPlaceholderIntent";
                        id: string;
                        name: string;
                        type: GqlClientCcPlaceholderType;
                        defaultValue?: string | null;
                        textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                    };
                };
                flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
            }>;
            ccWireframe: {
                __typename?: "CcWireframe";
                name: string;
                id: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                category: GqlClientCcWireframeCategory;
                frames: Array<{ __typename?: "CcFrame"; id: string; placeholders: Array<{ __typename?: "CcPlaceholder"; id: string }> }>;
            };
            ccAnimatedWireframe?: {
                __typename?: "CcAnimatedWireframe";
                id: string;
                name: string;
                vsmlUrl: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                aspectRatio: GqlClientEnumAspectRatio;
                compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                theme: { __typename?: "CcTheme"; id: string };
                placeholdersSettings: Array<
                    | {
                          __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                          id: string;
                          horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                          verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                          mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                          canvasName?: string | null;
                          ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                      }
                    | {
                          __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                          id: string;
                          horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                          verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                          canvasName?: string | null;
                          colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                          ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                      }
                >;
                m2AnimatedWireframe?: {
                    __typename?: "CcAnimatedWireframe";
                    id: string;
                    compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                    vsmlUrl: string;
                    defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                } | null;
                composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
            } | null;
        }>;
        editorProgramVersion: { __typename?: "EditorProgramVersion"; id: string; sceneLineup: Array<string>; scenes: Array<{ __typename?: "EditorScene"; id: string }> };
    } | null;
};

export type GqlClientProgramVersionEditorScenesUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorScenesUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorScenesUpdated?: {
        __typename?: "ProgramEditorScenesUpdatedPayloadData";
        editorScenes: Array<{
            __typename?: "EditorScene";
            id: string;
            usedMediaIds: Array<string>;
            name: string;
            description?: string | null;
            skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
            colorCombination: GqlClientEditorSceneColorCombination;
            buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
            skipSceneAudience?: any | null;
            minimumDuration?: number | null;
            hasTransition?: boolean | null;
            avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
            avatarVideoUrl?: string | null;
            placeholders: Array<{
                __typename?: "EditorPlaceholder";
                id: string;
                placeholderContent?: any | null;
                placeholderSettings?: any | null;
                parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                ccPlaceholder: {
                    __typename?: "CcPlaceholder";
                    id: string;
                    name: string;
                    content?: any | null;
                    category: GqlClientCcPlaceholderCategory;
                    intent: {
                        __typename?: "CcPlaceholderIntent";
                        id: string;
                        name: string;
                        type: GqlClientCcPlaceholderType;
                        defaultValue?: string | null;
                        textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                    };
                };
                flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
            }>;
            ccWireframe: {
                __typename?: "CcWireframe";
                name: string;
                id: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                category: GqlClientCcWireframeCategory;
                frames: Array<{ __typename?: "CcFrame"; id: string; placeholders: Array<{ __typename?: "CcPlaceholder"; id: string }> }>;
            };
            ccAnimatedWireframe?: {
                __typename?: "CcAnimatedWireframe";
                id: string;
                name: string;
                vsmlUrl: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                aspectRatio: GqlClientEnumAspectRatio;
                compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                theme: { __typename?: "CcTheme"; id: string };
                placeholdersSettings: Array<
                    | {
                          __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                          id: string;
                          horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                          verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                          mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                          canvasName?: string | null;
                          ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                      }
                    | {
                          __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                          id: string;
                          horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                          verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                          canvasName?: string | null;
                          colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                          ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                      }
                >;
                m2AnimatedWireframe?: {
                    __typename?: "CcAnimatedWireframe";
                    id: string;
                    compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                    vsmlUrl: string;
                    defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                } | null;
                composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
            } | null;
        }>;
        editorProgramVersion: { __typename?: "EditorProgramVersion"; id: string; scenes: Array<{ __typename?: "EditorScene"; id: string }> };
    } | null;
};

export type GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorSelectedBcCollectionUpdated?: {
        __typename?: "EditorProgramVersion";
        id: string;
        selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string; updated: string; updatedBy?: string | null };
    } | null;
};

export type GqlClientProgramVersionEditorSelectedMusicUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorSelectedMusicUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorSelectedMusicUpdated?: {
        __typename?: "EditorProgramVersion";
        id: string;
        selectedMusicSource: GqlClientEditorAssetSource;
        selectedCcMusic?: { __typename?: "CcMusic"; id: string } | null;
        selectedAccountMusic?: { __typename?: "EditorAssetMusic"; id: string } | null;
    } | null;
};

export type GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorSelectedNarratorUpdated?: { __typename?: "EditorProgramVersion"; id: string; selectedNarrator?: { __typename?: "CcNarrator"; id: string } | null } | null;
};

export type GqlClientProgramVersionEditorThemeUpdatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionEditorThemeUpdatedSubscription = {
    __typename?: "Subscription";
    programVersionEditorThemeUpdated?: {
        __typename?: "EditorProgramVersion";
        id: string;
        ccTheme: { __typename?: "CcTheme"; id: string };
        scenes: Array<{ __typename?: "EditorScene"; id: string; ccAnimatedWireframe?: { __typename?: "CcAnimatedWireframe"; id: string } | null }>;
    } | null;
};

export type GqlClientProgramVersionNarrationRecordingStateCalculatedSubscriptionVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionNarrationRecordingStateCalculatedSubscription = {
    __typename?: "Subscription";
    programVersionNarrationRecordingStateCalculated?:
        | { __typename: "ProgramVersionNarrationRecordingStateCalculatedPayloadError"; error: any; id: string; status: GqlClientStatus; mutationId: string }
        | { __typename: "ProgramVersionNarrationRecordingStateCalculatedPayloadKeepAlive"; id: string; status: GqlClientStatus; mutationId: string }
        | { __typename: "ProgramVersionNarrationRecordingStateCalculatedPayloadPending"; id: string; status: GqlClientStatus; mutationId: string }
        | {
              __typename: "ProgramVersionNarrationRecordingStateCalculatedPayloadSuccess";
              id: string;
              status: GqlClientStatus;
              mutationId: string;
              data: { __typename?: "ProgramVersionNarrationRecordingStateCalculatedPayloadData"; narrationRecordingsState?: GqlClientNarrationRecordingsState | null };
          }
        | null;
};

export type GqlClientProgramVersionThumbnailChangedSubscriptionVariables = Exact<{
    accountId: Scalars["String"]["input"];
}>;

export type GqlClientProgramVersionThumbnailChangedSubscription = {
    __typename?: "Subscription";
    programVersionThumbnailChanged?: { __typename?: "EditorProgramVersion"; id: string; thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null } | null;
};

export type GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscriptionVariables = Exact<{
    accountId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscription = {
    __typename?: "Subscription";
    programVersionsAndBcCollectionsEditorChanged?: {
        __typename?: "ProgramVersionsAndBcCollectionsEditorChangedPayload";
        brandConfigurationVersion: {
            __typename?: "BrandConfigurationVersion";
            id: string;
            brandConfigurationCollections: Array<{ __typename?: "BrandConfigurationCollection"; id: string }>;
            defaultBrandConfigurationCollection: { __typename?: "BrandConfigurationCollection"; id: string };
        };
        editorProgramVersions: Array<{
            __typename?: "EditorProgramVersion";
            id: string;
            bcUseRules?: boolean | null;
            bcRules?: any | null;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            selectedBrandConfiguration: { __typename?: "BrandConfigurationCollection"; id: string };
        }>;
    } | null;
};

export type GqlClientProgramViewerProfilesUpdatedSubscriptionVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramViewerProfilesUpdatedSubscription = {
    __typename?: "Subscription";
    programViewerProfilesUpdated?: {
        __typename?: "EditorProgram";
        id: string;
        viewerProfiles: Array<{ __typename?: "ViewerProfile"; id: string; name: string; content: any; updated: string }>;
    } | null;
};

export type GqlClientRecorderMediaUploadedToS3SubscriptionVariables = Exact<{
    mutationId: Scalars["ID"]["input"];
}>;

export type GqlClientRecorderMediaUploadedToS3Subscription = {
    __typename?: "Subscription";
    recorderMediaUploadedToS3?:
        | { __typename: "RecorderMediaUploadedToS3PayloadError"; error: any; status: GqlClientStatus; mutationId: string }
        | { __typename: "RecorderMediaUploadedToS3PayloadKeepAlive"; status: GqlClientStatus; mutationId: string }
        | { __typename: "RecorderMediaUploadedToS3PayloadPending"; progress: number; status: GqlClientStatus; mutationId: string }
        | { __typename: "RecorderMediaUploadedToS3PayloadSuccess"; status: GqlClientStatus; mutationId: string; data: { __typename?: "RecorderMediaUploadedToS3PayloadData"; assetURL: string } }
        | null;
};

export type GqlClientRequestedAvatarAnimationStatusSubscriptionVariables = Exact<{
    videoId: Scalars["ID"]["input"];
}>;

export type GqlClientRequestedAvatarAnimationStatusSubscription = {
    __typename?: "Subscription";
    requestedAvatarAnimationStatus?:
        | { __typename?: "RequestedAvatarAnimationStatusPayloadError"; status: GqlClientRequestedAvatarAnimationStatus }
        | { __typename?: "RequestedAvatarAnimationStatusPayloadPending"; url: string; progress: number; status: GqlClientRequestedAvatarAnimationStatus }
        | { __typename?: "RequestedAvatarAnimationStatusPayloadReady"; url: string; status: GqlClientRequestedAvatarAnimationStatus }
        | null;
};

export type GqlClientSceneFromPromptCreatedSubscriptionVariables = Exact<{
    mutationId: Scalars["ID"]["input"];
}>;

export type GqlClientSceneFromPromptCreatedSubscription = {
    __typename?: "Subscription";
    sceneFromPromptCreated?:
        | { __typename: "SceneFromPromptCreatedPayloadError"; error: any; mutationId: string; status: GqlClientStatus }
        | { __typename: "SceneFromPromptCreatedPayloadKeepAlive"; mutationId: string; status: GqlClientStatus }
        | { __typename: "SceneFromPromptCreatedPayloadPending"; progress: number; mutationId: string; status: GqlClientStatus }
        | {
              __typename: "SceneFromPromptCreatedPayloadSuccess";
              mutationId: string;
              status: GqlClientStatus;
              data: {
                  __typename?: "SceneFromPromptCreatedPayloadData";
                  editorScenes: Array<{
                      __typename?: "EditorScene";
                      id: string;
                      usedMediaIds: Array<string>;
                      name: string;
                      description?: string | null;
                      skipSceneMode?: GqlClientEditorSceneSkippingMode | null;
                      colorCombination: GqlClientEditorSceneColorCombination;
                      buttonAlignment?: GqlClientEnumHorizontalAlignment | null;
                      skipSceneAudience?: any | null;
                      minimumDuration?: number | null;
                      hasTransition?: boolean | null;
                      avatarVideoStatus: GqlClientEditorSceneAvatarVideoStatus;
                      avatarVideoUrl?: string | null;
                      placeholders: Array<{
                          __typename?: "EditorPlaceholder";
                          id: string;
                          placeholderContent?: any | null;
                          placeholderSettings?: any | null;
                          parentType?: GqlClientEditorFlexiblePlaceholderParentType | null;
                          ccPlaceholder: {
                              __typename?: "CcPlaceholder";
                              id: string;
                              name: string;
                              content?: any | null;
                              category: GqlClientCcPlaceholderCategory;
                              intent: {
                                  __typename?: "CcPlaceholderIntent";
                                  id: string;
                                  name: string;
                                  type: GqlClientCcPlaceholderType;
                                  defaultValue?: string | null;
                                  textStyle?: { __typename?: "CcTextStyle"; id: string } | null;
                              };
                          };
                          flexibleSettings?: { __typename?: "EditorFlexiblePlaceholderSettings"; top: number; left: number; width: number; height: number } | null;
                          parentPlaceholder?: { __typename?: "EditorPlaceholder"; id: string } | null;
                      }>;
                      ccWireframe: {
                          __typename?: "CcWireframe";
                          name: string;
                          id: string;
                          lifecycleStage: GqlClientAssetLifecycleStage;
                          category: GqlClientCcWireframeCategory;
                          frames: Array<{ __typename?: "CcFrame"; id: string; placeholders: Array<{ __typename?: "CcPlaceholder"; id: string }> }>;
                      };
                      ccAnimatedWireframe?: {
                          __typename?: "CcAnimatedWireframe";
                          id: string;
                          name: string;
                          vsmlUrl: string;
                          lifecycleStage: GqlClientAssetLifecycleStage;
                          aspectRatio: GqlClientEnumAspectRatio;
                          compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                          defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                          theme: { __typename?: "CcTheme"; id: string };
                          placeholdersSettings: Array<
                              | {
                                    __typename?: "CcAnimatedWireframeVersionMediaPlaceholdersSettings";
                                    id: string;
                                    horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                    verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                    mediaAdjustment?: GqlClientEnumMediaAdjustment | null;
                                    canvasName?: string | null;
                                    ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                                }
                              | {
                                    __typename?: "CcAnimatedWireframeVersionTextPlaceholdersSettings";
                                    id: string;
                                    horizontalAlignment?: GqlClientEnumHorizontalAlignment | null;
                                    verticalAlignment?: GqlClientEnumVerticalAlignment | null;
                                    canvasName?: string | null;
                                    colorCcDof?: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType } | null;
                                    ccPlaceholder: { __typename?: "CcPlaceholder"; id: string };
                                }
                          >;
                          m2AnimatedWireframe?: {
                              __typename?: "CcAnimatedWireframe";
                              id: string;
                              compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                              vsmlUrl: string;
                              defaultButtonAlignment?: GqlClientEnumHorizontalAlignment | null;
                          } | null;
                          composition?: { __typename?: "CcComposition"; id: string; name: string; supportsUserTextOnMedia: boolean; supportsUserMediaOverMedia: boolean } | null;
                      } | null;
                  } | null>;
              };
          }
        | null;
};

export type GqlClientUndoStacksUpdatedSubscriptionVariables = Exact<{
    userId: Scalars["ID"]["input"];
}>;

export type GqlClientUndoStacksUpdatedSubscription = {
    __typename?: "Subscription";
    undoStacksUpdated?: { __typename?: "UndoStacks"; id: string; undoStackSize: number; redoStackSize: number; firstUndoActionName?: string | null; firstRedoActionName?: string | null } | null;
};

export type GqlClientGetReportNameQueryVariables = Exact<{
    reportId: Scalars["ID"]["input"];
}>;

export type GqlClientGetReportNameQuery = { __typename?: "Query"; accountReport?: { __typename?: "AccountReport"; id: string; name: string } | null };

export type GqlClientGetVideoNameQueryVariables = Exact<{
    videoId: Scalars["ID"]["input"];
}>;

export type GqlClientGetVideoNameQuery = {
    __typename?: "Query";
    editorProgram?: { __typename?: "EditorProgram"; id: string; name: string; folder?: { __typename?: "AccountProgramFolder"; id: string; name: string } | null } | null;
};

export type GqlClientProgramFolderFragment = {
    __typename?: "AccountProgramFolder";
    id: string;
    isRoot: boolean;
    name: string;
    created: string;
    createdBy: string;
    updated: string;
    updatedBy: string;
    parentFolder?: { __typename?: "AccountProgramFolder"; id: string; name: string; created: string; createdBy: string; updated: string; updatedBy: string; isRoot: boolean } | null;
};

export type GqlClientEditorProgramVideoFragment = {
    __typename?: "EditorProgram";
    id: string;
    name: string;
    isArchive?: boolean | null;
    isLive?: boolean | null;
    created: string;
    createdBy?: string | null;
    creationMethod?: string | null;
    originProgramId?: string | null;
    originalStoryTemplateProgramId?: string | null;
    originalStoryTemplateProgramVersionId?: string | null;
    lifecycleStage?: GqlClientAssetLifecycleStage | null;
    publishTarget: GqlClientLifecycleStage;
    lastArchived?: string | null;
    prodVersion?: {
        __typename?: "LifecycleHistoryEntry";
        id: string;
        created: string;
        createdBy?: string | null;
        snapshotName?: string | null;
        snapshotNumber?: number | null;
        editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null; editorDataConnectorEnabled?: boolean | null } | null;
    } | null;
    uatVersion?: {
        __typename?: "LifecycleHistoryEntry";
        id: string;
        created: string;
        createdBy?: string | null;
        snapshotName?: string | null;
        snapshotNumber?: number | null;
        editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null } | null;
    } | null;
    programVersionDraft: {
        __typename?: "EditorProgramVersion";
        id: string;
        aspectRatio: GqlClientEnumAspectRatio;
        anyUpdated?: string | null;
        anyUpdatedBy?: string | null;
        compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
        usedElements?: any | null;
        editorDataConnectorEnabled?: boolean | null;
        editorTtsFullEnablement?: boolean | null;
        thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
        selectedNarrator?: { __typename?: "CcNarrator"; id: string; narrationSystemName: string } | null;
    };
    folder?: { __typename?: "AccountProgramFolder"; id: string; name: string } | null;
    landingPage?: { __typename?: "LandingPage"; id: string; isActive?: boolean | null } | null;
    embedVideoCode?: { __typename?: "EmbedVideoCode"; id: string } | null;
    shareHistory: Array<{
        __typename?: "ProgramShareHistory";
        id: string;
        created: string;
        createdBy?: string | null;
        accountId: string;
        shareType: GqlClientProgramShareType;
        isExternal?: boolean | null;
    }>;
};

export type GqlClientEditorProgramVideoOldFragment = {
    __typename?: "EditorProgram";
    id: string;
    name: string;
    anyUpdated?: string | null;
    anyUpdatedBy?: string | null;
    isArchive?: boolean | null;
    created: string;
    createdBy?: string | null;
    lastArchived?: string | null;
    prodVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string; createdBy?: string | null; snapshotName?: string | null; snapshotNumber?: number | null } | null;
    programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; aspectRatio: GqlClientEnumAspectRatio; thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null };
};

export type GqlClientApproveAccountInvitationMutationVariables = Exact<{
    input: GqlClientApproveAccountInvitationInput;
}>;

export type GqlClientApproveAccountInvitationMutation = {
    __typename?: "Mutation";
    approveAccountInvitation: {
        __typename?: "ApproveAccountInvitationOutput";
        result: GqlClientApproveAccountInvitationResult;
        accountUser?: { __typename?: "AccountUser"; id: string; invitationStatus: GqlClientAccountInvitationStatus } | null;
    };
};

export type GqlClientDeleteAccountInvitationMutationVariables = Exact<{
    input: GqlClientDeleteAccountInvitationInput;
}>;

export type GqlClientDeleteAccountInvitationMutation = {
    __typename?: "Mutation";
    deleteAccountInvitation: {
        __typename?: "DeleteAccountInvitationOutput";
        result: GqlClientDeleteAccountInvitationResult;
        loggedInEditorAccountUser?: {
            __typename?: "EditorAccountUser";
            id: string;
            accounts: Array<{ __typename?: "AccountUser"; id: string; invitationStatus: GqlClientAccountInvitationStatus }>;
        } | null;
    };
};

export type GqlClientSaveUserRecentSearchMutationVariables = Exact<{
    input: GqlClientSaveUserRecentSearchInput;
}>;

export type GqlClientSaveUserRecentSearchMutation = {
    __typename?: "Mutation";
    saveUserRecentSearch: {
        __typename?: "SaveUserRecentSearchOutput";
        result: GqlClientSaveUserRecentSearchResult;
        userSettings: { __typename?: "UserSettings"; id: string; recentSearches: Array<string> };
    };
};

export type GqlClientUpdateAccountUserControlledFeatureDataMutationVariables = Exact<{
    input: GqlClientUpdateAccountUserControlledFeatureDataInput;
}>;

export type GqlClientUpdateAccountUserControlledFeatureDataMutation = {
    __typename?: "Mutation";
    updateAccountUserControlledFeatureData: {
        __typename?: "UpdateAccountUserControlledFeatureDataOutput";
        result: GqlClientUpdateAccountUserControlledFeatureDataResult;
        features: Array<{
            __typename?: "AccountFeatureData";
            id: string;
            featureRestrictionValue?: any | null;
            featureTypeDefinition: GqlClientFeatureTypeDefinition;
            featureSource: GqlClientFeatureSource;
            featureDefinition: {
                __typename?: "FeatureDefinition";
                id: string;
                featureTypeDefinition: GqlClientFeatureTypeDefinition;
                featureRestrictionScope: GqlClientFeatureRestrictionScope;
                featureRestrictionType: GqlClientRestrictionType;
                errorMessage: string;
                userControlled: boolean;
            };
        }>;
    };
};

export type GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutationVariables = Exact<{ [key: string]: never }>;

export type GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation = {
    __typename?: "Mutation";
    updateAllAccountEditorProgramVersionThumbnailIfNeeded: {
        __typename?: "UpdateAllAccountEditorProgramVersionThumbnailIfNeededOutput";
        result: GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededResult;
    };
};

export type GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutationVariables = Exact<{
    input: GqlClientUpdateEditorProgramVersionThumbnailIfNeededInput;
}>;

export type GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutation = {
    __typename?: "Mutation";
    updateEditorProgramVersionThumbnailIfNeeded: { __typename?: "UpdateEditorProgramVersionThumbnailIfNeededOutput"; result: GqlClientUpdateEditorProgramVersionThumbnailIfNeededResult };
};

export type GqlClientUpdateUserMutationVariables = Exact<{
    input: GqlClientUpdateEditorAccountUserInput;
}>;

export type GqlClientUpdateUserMutation = {
    __typename?: "Mutation";
    updateEditorAccountUser:
        | { __typename?: "UpdateEditorAccountUserOutputRefusal"; result: GqlClientUpdateEditorAccountUserResult }
        | {
              __typename?: "UpdateEditorAccountUserOutputSuccess";
              result: GqlClientUpdateEditorAccountUserResult;
              accountUser: {
                  __typename?: "AccountUser";
                  id: string;
                  roles: Array<GqlClientUserAccountRole>;
                  user: { __typename?: "EditorAccountUser"; id: string; email: string; roles?: Array<GqlClientUserRole> | null; lastLogin?: string | null; created?: string | null };
              };
          };
};

export type GqlClientUpsertYourVideosUserSettingsMutationVariables = Exact<{
    upsertUserSettingsInput: GqlClientUpsertUserSettingsInput;
}>;

export type GqlClientUpsertYourVideosUserSettingsMutation = {
    __typename?: "Mutation";
    upsertUserSettings: {
        __typename?: "UpsertUserSettingsOutput";
        result: GqlClientUpsertUserSettingsResult;
        userSettings: { __typename?: "UserSettings"; id: string; yourVideos?: any | null; videosArchive?: any | null };
    };
};

export type GqlClientAccountFeatureFullDataQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountFeatureFullDataQuery = {
    __typename?: "Query";
    account?: {
        __typename?: "Account";
        id: string;
        accountFeatureData?: Array<{
            __typename?: "AccountFeatureData";
            featureCurrentUsageInfo?: any | null;
            id: string;
            featureRestrictionValue?: any | null;
            featureTypeDefinition: GqlClientFeatureTypeDefinition;
            featureSource: GqlClientFeatureSource;
            featureDefinition: {
                __typename?: "FeatureDefinition";
                id: string;
                featureTypeDefinition: GqlClientFeatureTypeDefinition;
                featureRestrictionScope: GqlClientFeatureRestrictionScope;
                featureRestrictionType: GqlClientRestrictionType;
                errorMessage: string;
                userControlled: boolean;
            };
        }> | null;
    } | null;
};

export type GqlClientAccountVideosAndFoldersNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountVideosAndFoldersNamesQuery = {
    __typename?: "Query";
    account?: {
        __typename?: "Account";
        id: string;
        folders: Array<{ __typename?: "AccountProgramFolder"; id: string; isRoot: boolean; name: string; parentFolder?: { __typename?: "AccountProgramFolder"; id: string } | null }>;
        builderPrograms: Array<{
            __typename?: "Program";
            id: string;
            name: string;
            anyUpdated?: string | null;
            isArchive?: boolean | null;
            folder?: { __typename?: "AccountProgramFolder"; id: string } | null;
        }>;
        editorPrograms: Array<{
            __typename?: "EditorProgram";
            id: string;
            name: string;
            isArchive?: boolean | null;
            programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; anyUpdated?: string | null };
            folder?: { __typename?: "AccountProgramFolder"; id: string } | null;
        }>;
    } | null;
};

export type GqlClientDataLibraryInfoForDeleteVideoQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientDataLibraryInfoForDeleteVideoQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersionDraft: {
            __typename?: "EditorProgramVersion";
            id: string;
            accountDataLibraryVersion?: {
                __typename?: "AccountDataLibraryVersion";
                id: string;
                data: any;
                usedInVideos: Array<{ __typename?: "EditorProgram"; id: string }>;
                dataFields: Array<{ __typename?: "AccountDataField"; id: string; data: any }>;
                accountDataLibrary: { __typename?: "AccountDataLibrary"; id: string; name: string; description?: string | null };
            } | null;
        };
    } | null;
};

export type GqlClientAccountsInvitationQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientAccountsInvitationQuery = {
    __typename?: "Query";
    loggedInEditorAccountUser: {
        __typename?: "EditorAccountUser";
        id: string;
        accounts: Array<{ __typename?: "AccountUser"; id: string; invitationStatus: GqlClientAccountInvitationStatus; account: { __typename?: "Account"; id: string; displayName?: string | null } }>;
    };
};

export type GqlClientGetAccountVideosNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientGetAccountVideosNamesQuery = {
    __typename?: "Query";
    account?: {
        __typename?: "Account";
        id: string;
        builderPrograms: Array<{ __typename?: "Program"; id: string; name: string }>;
        editorPrograms: Array<{ __typename?: "EditorProgram"; id: string; name: string }>;
    } | null;
};

export type GqlClientInternalUserNameAndRolesQueryVariables = Exact<{
    email: Scalars["String"]["input"];
}>;

export type GqlClientInternalUserNameAndRolesQuery = {
    __typename?: "Query";
    internalUser?: {
        __typename?: "EditorAccountUser";
        id: string;
        accounts: Array<{ __typename?: "AccountUser"; id: string; roles: Array<GqlClientUserAccountRole>; account: { __typename?: "Account"; id: string } }>;
    } | null;
};

export type GqlClientGetProgramReviewsVideoSummaryQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientGetProgramReviewsVideoSummaryQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programReviews: Array<{
            __typename?: "ProgramReview";
            id: string;
            reviewers: Array<string>;
            created: string;
            name: string;
            createdBy?: string | null;
            reviewLink: string;
            description?: string | null;
            videos: Array<{
                __typename?: "ProgramReviewVideo";
                id: string;
                videoData?: any | null;
                viewerProfileName?: string | null;
                videoThumbnailUrl?: string | null;
                aspectRatio: GqlClientEnumAspectRatio;
            }>;
        }>;
    } | null;
};

export type GqlClientPromptSuggestionsQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientPromptSuggestionsQuery = { __typename?: "Query"; promptSuggestions: Array<string | null> };

export type GqlClientGetVideoIdListQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientGetVideoIdListQuery = {
    __typename?: "Query";
    account?: {
        __typename?: "Account";
        id: string;
        builderPrograms: Array<{ __typename?: "Program"; id: string; isArchive?: boolean | null }>;
        editorPrograms: Array<{ __typename?: "EditorProgram"; id: string; isArchive?: boolean | null }>;
    } | null;
};

export type GqlClientGetVideoListQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientGetVideoListQuery = {
    __typename?: "Query";
    account?: {
        __typename?: "Account";
        id: string;
        folders: Array<{
            __typename?: "AccountProgramFolder";
            id: string;
            isRoot: boolean;
            name: string;
            created: string;
            createdBy: string;
            updated: string;
            updatedBy: string;
            parentFolder?: { __typename?: "AccountProgramFolder"; id: string; name: string; created: string; createdBy: string; updated: string; updatedBy: string; isRoot: boolean } | null;
        }>;
        builderPrograms: Array<{
            __typename?: "Program";
            id: string;
            name: string;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            created: string;
            createdBy?: string | null;
            isArchive?: boolean | null;
            legacyBuilderProgramId?: string | null;
            lastArchived?: string | null;
            folder?: { __typename?: "AccountProgramFolder"; id: string; name: string } | null;
        }>;
        editorPrograms: Array<{
            __typename?: "EditorProgram";
            id: string;
            name: string;
            isArchive?: boolean | null;
            isLive?: boolean | null;
            created: string;
            createdBy?: string | null;
            creationMethod?: string | null;
            originProgramId?: string | null;
            originalStoryTemplateProgramId?: string | null;
            originalStoryTemplateProgramVersionId?: string | null;
            lifecycleStage?: GqlClientAssetLifecycleStage | null;
            publishTarget: GqlClientLifecycleStage;
            lastArchived?: string | null;
            prodVersion?: {
                __typename?: "LifecycleHistoryEntry";
                id: string;
                created: string;
                createdBy?: string | null;
                snapshotName?: string | null;
                snapshotNumber?: number | null;
                editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null; editorDataConnectorEnabled?: boolean | null } | null;
            } | null;
            uatVersion?: {
                __typename?: "LifecycleHistoryEntry";
                id: string;
                created: string;
                createdBy?: string | null;
                snapshotName?: string | null;
                snapshotNumber?: number | null;
                editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; usedElements?: any | null } | null;
            } | null;
            programVersionDraft: {
                __typename?: "EditorProgramVersion";
                id: string;
                aspectRatio: GqlClientEnumAspectRatio;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                compatibility?: GqlClientEnumAnimationWireframeCompatibility | null;
                usedElements?: any | null;
                editorDataConnectorEnabled?: boolean | null;
                editorTtsFullEnablement?: boolean | null;
                thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null;
                selectedNarrator?: { __typename?: "CcNarrator"; id: string; narrationSystemName: string } | null;
            };
            folder?: { __typename?: "AccountProgramFolder"; id: string; name: string } | null;
            landingPage?: { __typename?: "LandingPage"; id: string; isActive?: boolean | null } | null;
            embedVideoCode?: { __typename?: "EmbedVideoCode"; id: string } | null;
            shareHistory: Array<{
                __typename?: "ProgramShareHistory";
                id: string;
                created: string;
                createdBy?: string | null;
                accountId: string;
                shareType: GqlClientProgramShareType;
                isExternal?: boolean | null;
            }>;
        }>;
    } | null;
};

export type GqlClientGetVideoSummaryQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientGetVideoSummaryQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        name: string;
        isArchive?: boolean | null;
        programVersionDraft: { __typename?: "EditorProgramVersion"; id: string; sceneLineup: Array<string> };
        programReviews: Array<{
            __typename?: "ProgramReview";
            id: string;
            reviewers: Array<string>;
            created: string;
            name: string;
            createdBy?: string | null;
            reviewLink: string;
            description?: string | null;
            videos: Array<{
                __typename?: "ProgramReviewVideo";
                id: string;
                videoData?: any | null;
                viewerProfileName?: string | null;
                videoThumbnailUrl?: string | null;
                aspectRatio: GqlClientEnumAspectRatio;
            }>;
        }>;
    } | null;
};

export type GqlClientGetVideoUsedGettyPremiumQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientGetVideoUsedGettyPremiumQuery = {
    __typename?: "Query";
    editorProgram?: {
        __typename?: "EditorProgram";
        id: string;
        programVersionDraft: {
            __typename?: "EditorProgramVersion";
            id: string;
            usedPremiumGettyMediaData: Array<{
                __typename?: "UsedMediaData";
                usedLocations: Array<string>;
                assetMedia?: { __typename?: "EditorAssetMedia"; id: string; name: string; mediaUrl: string; mediaPreviewUrl: string; description?: string | null; mediaMetadata: any } | null;
            }>;
        };
    } | null;
};

export type GqlClientYourVideosUserSettingsQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientYourVideosUserSettingsQuery = { __typename?: "Query"; userSettings?: { __typename?: "UserSettings"; id: string; yourVideos?: any | null; videosArchive?: any | null } | null };

export type GqlClientProgramReviewsSummaryForUserQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientProgramReviewsSummaryForUserQuery = {
    __typename?: "Query";
    programReviewsSummaryForUser: {
        __typename?: "ProgramReviewsSummaryOutput";
        id: string;
        programReviews: Array<{
            __typename?: "ProgramReview";
            id: string;
            reviewers: Array<string>;
            created: string;
            name: string;
            createdBy?: string | null;
            reviewLink: string;
            description?: string | null;
            videos: Array<{
                __typename?: "ProgramReviewVideo";
                id: string;
                videoData?: any | null;
                viewerProfileName?: string | null;
                videoThumbnailUrl?: string | null;
                aspectRatio: GqlClientEnumAspectRatio;
            }>;
        }>;
    };
};

export type GqlClientUserSettingsRecentSearchesQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientUserSettingsRecentSearchesQuery = { __typename?: "Query"; userSettings?: { __typename?: "UserSettings"; id: string; recentSearches: Array<string> } | null };

export type GqlClientProgramReviewQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientProgramReviewQuery = {
    __typename?: "Query";
    programReview: {
        __typename?: "ProgramReview";
        id: string;
        name: string;
        createdBy?: string | null;
        reviewers: Array<string>;
        videos: Array<{
            __typename?: "ProgramReviewVideo";
            id: string;
            playerToken: string;
            videoData?: any | null;
            aspectRatio: GqlClientEnumAspectRatio;
            viewerProfileName?: string | null;
            videoThumbnailUrl?: string | null;
        }>;
        program: { __typename?: "EditorProgram"; id: string; name: string };
    };
};

export type GqlClientAccountFeatureDataFragment = {
    __typename?: "AccountFeatureData";
    id: string;
    featureRestrictionValue?: any | null;
    featureTypeDefinition: GqlClientFeatureTypeDefinition;
    featureSource: GqlClientFeatureSource;
    featureDefinition: {
        __typename?: "FeatureDefinition";
        id: string;
        featureTypeDefinition: GqlClientFeatureTypeDefinition;
        featureRestrictionScope: GqlClientFeatureRestrictionScope;
        featureRestrictionType: GqlClientRestrictionType;
        errorMessage: string;
        userControlled: boolean;
    };
};

export type GqlClientAccountFeatureFullDataFragment = {
    __typename?: "AccountFeatureData";
    featureCurrentUsageInfo?: any | null;
    id: string;
    featureRestrictionValue?: any | null;
    featureTypeDefinition: GqlClientFeatureTypeDefinition;
    featureSource: GqlClientFeatureSource;
    featureDefinition: {
        __typename?: "FeatureDefinition";
        id: string;
        featureTypeDefinition: GqlClientFeatureTypeDefinition;
        featureRestrictionScope: GqlClientFeatureRestrictionScope;
        featureRestrictionType: GqlClientRestrictionType;
        errorMessage: string;
        userControlled: boolean;
    };
};

export type GqlClientAnimationFragment = {
    __typename?: "Animation";
    id: string;
    legacyAnimationId: string;
    name: string;
    description?: string | null;
    updated: string;
    animationActiveVersion: { __typename?: "AnimationVersion"; id: string; version: number; created: string; createdBy?: string | null; aeFilename?: string | null; comment?: string | null };
};

export type GqlClientAnimationVersionFragment = {
    __typename?: "AnimationVersion";
    id: string;
    version: number;
    created: string;
    createdBy?: string | null;
    aeFilename?: string | null;
    comment?: string | null;
};

export type GqlClientAssetFragment = {
    __typename?: "Asset";
    id: string;
    legacyAssetId: string;
    accountId: string;
    name: string;
    description?: string | null;
    type: GqlClientAssetType;
    created: string;
    anyUpdated?: string | null;
    legacyData?: any | null;
    assetActiveVersion: {
        __typename?: "AssetVersion";
        id: string;
        url?: string | null;
        thumbnailUrl?: string | null;
        filename?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
        size?: number | null;
        version: number;
        created: string;
        createdBy?: string | null;
    };
};

export type GqlClientAssetVersionFragment = {
    __typename?: "AssetVersion";
    id: string;
    url?: string | null;
    thumbnailUrl?: string | null;
    filename?: string | null;
    width?: number | null;
    height?: number | null;
    format?: string | null;
    size?: number | null;
    version: number;
    created: string;
    createdBy?: string | null;
};

export type GqlClientCustomAnalyticFieldFragment = {
    __typename: "CustomAnalyticField";
    id: string;
    accountId: string;
    updated: string;
    updatedBy?: string | null;
    name?: string | null;
    logic?: any | null;
    program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
};

export type GqlClientDataTableFragment = {
    __typename?: "DataTable";
    id: string;
    accountId: string;
    legacyDataTableId?: string | null;
    name: string;
    description?: string | null;
    updated: string;
    created: string;
    dtaasPrefix?: string | null;
    location?: string | null;
    schema?: any | null;
    downloadUrl?: string | null;
};

export type GqlClientFeatureDefinitionFragment = {
    __typename?: "FeatureDefinition";
    id: string;
    featureTypeDefinition: GqlClientFeatureTypeDefinition;
    featureRestrictionScope: GqlClientFeatureRestrictionScope;
    featureRestrictionType: GqlClientRestrictionType;
    errorMessage: string;
    userControlled: boolean;
};

export type GqlClientNarrationRecordingFragment = {
    __typename?: "NarrationRecording";
    id: string;
    created: string;
    createdBy?: string | null;
    updated: string;
    key: string;
    filename: string;
    text: string;
    rfr: number;
    metadata?: any | null;
    lastVersionNumber: number;
    narrationId: string;
    narrationRecordingActiveVersion?: {
        __typename?: "NarrationRecordingVersion";
        id: string;
        created: string;
        createdBy?: string | null;
        url?: string | null;
        format?: string | null;
        location?: string | null;
        status?: GqlClientNarrationRecordingStatus | null;
        errorReason?: GqlClientNarrationRecordingErrorReason | null;
        version: number;
    } | null;
};

export type GqlClientNarrationRecordingVersionFragment = {
    __typename?: "NarrationRecordingVersion";
    id: string;
    created: string;
    createdBy?: string | null;
    url?: string | null;
    format?: string | null;
    location?: string | null;
    status?: GqlClientNarrationRecordingStatus | null;
    errorReason?: GqlClientNarrationRecordingErrorReason | null;
    version: number;
};

export type GqlClientNarratorFragment = {
    __typename?: "Narrator";
    id: string;
    created: string;
    createdBy?: string | null;
    updated: string;
    updatedBy?: string | null;
    legacyNarratorId: string;
    name: string;
    carUrl: string;
    dtaasPrefix: string;
    status: GqlClientNarratorStatus;
    decisionTablesLoadTaskId?: string | null;
};

export type GqlClientNewlyCreatedEditorProgramFragment = {
    __typename?: "EditorProgram";
    id: string;
    name: string;
    accountId: string;
    created: string;
    createdBy?: string | null;
    programVersionDraft: {
        __typename?: "EditorProgramVersion";
        id: string;
        brandConfigurationVersion: {
            __typename?: "BrandConfigurationVersion";
            id: string;
            defaultBrandConfigurationCollection: { __typename?: "BrandConfigurationCollection"; id: string; localId: string };
            brandConfigurationCollections: Array<{ __typename?: "BrandConfigurationCollection"; id: string }>;
        };
        selectedBrandConfiguration: {
            __typename?: "BrandConfigurationCollection";
            id: string;
            localId: string;
            updated: string;
            updatedBy?: string | null;
            created: string;
            name: string;
            description?: string | null;
            logoUrl?: string | null;
            logoLocation?: string | null;
            buttonShape: GqlClientButtonShapeValues;
            logoMedia?: {
                __typename?: "EditorAssetMedia";
                id: string;
                name: string;
                created: string;
                updated: string;
                mediaLocation: string;
                mediaUrl: string;
                mediaPreviewUrl: string;
                mediaAgifUrl?: string | null;
                mediaOrigin: GqlClientMediaOrigin;
                mediaMetadata: any;
                mediaProcessingInfo?: any | null;
                mediaFramesThumbnailsUrl?: string | null;
                originalEditInformation?: any | null;
                originalMediaId?: string | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                mediaSri?: string | null;
                externalLibraryAssetId?: string | null;
                externalLibrary?: GqlClientExternalLibrary | null;
                externalSublibrary?: GqlClientExternalSubLibrary | null;
                directAncestorId?: string | null;
                mediaClassification: GqlClientMediaClassification;
                description?: string | null;
                createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
            } | null;
            colors: Array<{
                __typename?: "BcColor";
                id: string;
                updated: string;
                color: string;
                ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
            }>;
            textStyles: Array<{
                __typename?: "BcTextStyle";
                id: string;
                fontSource: GqlClientEditorAssetSource;
                letterCase: GqlClientTextStyleLetterCase;
                letterSpacing: number;
                lineSpacing: number;
                updated: string;
                ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                ccFont?: {
                    __typename?: "CcFont";
                    id: string;
                    name: string;
                    url: string;
                    hasSmallCaps?: boolean | null;
                    lifecycleStage?: GqlClientAssetLifecycleStage | null;
                    webFontUrl?: string | null;
                    created: string;
                    familyName?: string | null;
                    isBold?: boolean | null;
                    isItalic?: boolean | null;
                    weight?: number | null;
                } | null;
                accountFont?: {
                    __typename?: "EditorAssetFont";
                    id: string;
                    name: string;
                    url?: string | null;
                    imageUrl?: string | null;
                    webFontUrl?: string | null;
                    hasSmallCaps?: boolean | null;
                    lifecycleStage?: GqlClientAssetLifecycleStage | null;
                    uploadId?: string | null;
                    created: string;
                    familyName?: string | null;
                    isBold?: boolean | null;
                    isItalic?: boolean | null;
                    weight?: number | null;
                } | null;
            }>;
        };
        assetLibrary: {
            __typename?: "EditorAssetLibraryVersion";
            id: string;
            fonts: Array<{
                __typename?: "EditorAssetFont";
                id: string;
                name: string;
                url?: string | null;
                imageUrl?: string | null;
                webFontUrl?: string | null;
                hasSmallCaps?: boolean | null;
                lifecycleStage?: GqlClientAssetLifecycleStage | null;
                uploadId?: string | null;
                created: string;
                familyName?: string | null;
                isBold?: boolean | null;
                isItalic?: boolean | null;
                weight?: number | null;
            } | null>;
            music: Array<{
                __typename?: "EditorAssetMusic";
                id: string;
                name: string;
                url: string;
                lifecycleStage: GqlClientAssetLifecycleStage;
                externalLibraryAssetId?: string | null;
                musicOrigin: GqlClientMusicOrigin;
            } | null>;
            media: Array<{
                __typename?: "EditorAssetMedia";
                id: string;
                name: string;
                created: string;
                updated: string;
                mediaLocation: string;
                mediaUrl: string;
                mediaPreviewUrl: string;
                mediaAgifUrl?: string | null;
                mediaOrigin: GqlClientMediaOrigin;
                mediaMetadata: any;
                mediaProcessingInfo?: any | null;
                mediaFramesThumbnailsUrl?: string | null;
                originalEditInformation?: any | null;
                originalMediaId?: string | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                mediaSri?: string | null;
                externalLibraryAssetId?: string | null;
                externalLibrary?: GqlClientExternalLibrary | null;
                externalSublibrary?: GqlClientExternalSubLibrary | null;
                directAncestorId?: string | null;
                mediaClassification: GqlClientMediaClassification;
                description?: string | null;
                createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
            }>;
            mediaFolders: Array<{
                __typename?: "EditorAssetFolder";
                id: string;
                name: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                updatedBy?: string | null;
                parentFolder?: { __typename?: "EditorAssetFolder"; id: string } | null;
            }>;
        };
    };
    account?: { __typename?: "Account"; id: string; editorPrograms: Array<{ __typename?: "EditorProgram"; id: string }> } | null;
};

export type GqlClientPresetFragment = {
    __typename?: "Preset";
    id: string;
    name: string;
    isCreative?: boolean | null;
    updated: string;
    dataElementValues: Array<{ __typename?: "PresetDataElementValue"; id: string; type?: GqlClientDataElementType | null; value?: string | null; valueId?: string | null }>;
    systemElementValues: Array<{
        __typename?: "PresetSystemElementValue";
        id: string;
        systemElementId?: string | null;
        type?: GqlClientDataElementType | null;
        value?: string | null;
        valueId?: string | null;
    }>;
};

export type GqlClientPresetWithoutValuesFragment = { __typename?: "Preset"; id: string; name: string; isCreative?: boolean | null; updated: string };

export type GqlClientProgramVersionSnapshotDetailsFragment = {
    __typename?: "ProgramVersion";
    id: string;
    snapshotNumber?: number | null;
    snapshotName?: string | null;
    snapshotComment?: string | null;
    snapshotValidationData?: any | null;
    snapshotted?: string | null;
    snapshottedBy?: string | null;
    parent1ProgramVersion?: { __typename?: "ProgramVersion"; id: string } | null;
};

export type GqlClientRecordingDocFragment = {
    __typename?: "RecordingDocEntry";
    id: string;
    audioFileUrl: string;
    audioFilename: string;
    scriptFileUrl: string;
    scriptFilename: string;
    totalRecordingFiles: number;
    created: string;
    createdBy?: string | null;
    folderLocation: string;
};

export type GqlClientSceneForBuilderFragment = {
    __typename?: "Scene";
    id: string;
    name: string;
    created: string;
    updated: string;
    animationLogic?: any | null;
    validationLogic?: any | null;
    descriptiveTranscriptLogic?: any | null;
    scenePartOrder: Array<string>;
    sceneParts: Array<{
        __typename?: "ScenePart";
        id: string;
        updated: string;
        created: string;
        thumbnailLocation?: string | null;
        oldNarrationData: Array<any>;
        narrationPartOrder: Array<string>;
        placeholders: Array<{
            __typename?: "Placeholder";
            id: string;
            updated: string;
            x?: number | null;
            y?: number | null;
            type: GqlClientPlaceholderType;
            height?: number | null;
            width?: number | null;
            logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
        }>;
        placeholderGroups: Array<{
            __typename?: "PlaceholderGroup";
            id: string;
            updated: string;
            logic: { __typename?: "PlaceholderGroupLogic"; id: string; name?: string | null; logic?: any | null };
            placeholders: Array<{
                __typename?: "Placeholder";
                id: string;
                updated: string;
                x?: number | null;
                y?: number | null;
                type: GqlClientPlaceholderType;
                height?: number | null;
                width?: number | null;
                logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
            }>;
        }>;
        prioritizedLists: Array<{
            __typename?: "PrioritizedList";
            id: string;
            updated: string;
            logic: { __typename?: "PrioritizedListLogic"; id: string; name: string; logic?: any | null };
            groups?: Array<{
                __typename?: "PlaceholderGroup";
                id: string;
                updated: string;
                logic: { __typename?: "PlaceholderGroupLogic"; id: string; name?: string | null; logic?: any | null };
                placeholders: Array<{
                    __typename?: "Placeholder";
                    id: string;
                    x?: number | null;
                    y?: number | null;
                    type: GqlClientPlaceholderType;
                    height?: number | null;
                    width?: number | null;
                    logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
                }>;
            }> | null;
            placeholders?: Array<{
                __typename?: "Placeholder";
                id: string;
                updated: string;
                x?: number | null;
                y?: number | null;
                type: GqlClientPlaceholderType;
                height?: number | null;
                width?: number | null;
                logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
            }> | null;
        }>;
        animationGuidelines: Array<{
            __typename?: "AnimationGuideline";
            id: string;
            updated: string;
            type: GqlClientAnimationGuidelineType;
            logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
        }>;
        narrations: Array<{
            __typename?: "Narration";
            id: string;
            updated: string;
            representativeKey?: string | null;
            supportsCreativeDataElementOverride?: boolean | null;
            mergeData: Array<any>;
            dimensionData: Array<any>;
            dtaasPrefixKey?: string | null;
            lastUsedRecordingFileNumber: number;
            narrationVariations: Array<{
                __typename?: "NarrationVariation";
                id: string;
                updated: string;
                variationKey?: string | null;
                sentence?: string | null;
                muted?: boolean | null;
                note?: string | null;
                overridingCreativeDataElement?: { __typename?: "CreativeDataElement"; id: string } | null;
            }>;
        }>;
    }>;
};

export type GqlClientScenePartFragment = {
    __typename?: "ScenePart";
    id: string;
    updated: string;
    created: string;
    thumbnailLocation?: string | null;
    oldNarrationData: Array<any>;
    narrationPartOrder: Array<string>;
    placeholders: Array<{
        __typename?: "Placeholder";
        id: string;
        updated: string;
        x?: number | null;
        y?: number | null;
        type: GqlClientPlaceholderType;
        height?: number | null;
        width?: number | null;
        logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
    }>;
    placeholderGroups: Array<{
        __typename?: "PlaceholderGroup";
        id: string;
        updated: string;
        logic: { __typename?: "PlaceholderGroupLogic"; id: string; name?: string | null; logic?: any | null };
        placeholders: Array<{
            __typename?: "Placeholder";
            id: string;
            updated: string;
            x?: number | null;
            y?: number | null;
            type: GqlClientPlaceholderType;
            height?: number | null;
            width?: number | null;
            logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
        }>;
    }>;
    prioritizedLists: Array<{
        __typename?: "PrioritizedList";
        id: string;
        updated: string;
        logic: { __typename?: "PrioritizedListLogic"; id: string; name: string; logic?: any | null };
        groups?: Array<{
            __typename?: "PlaceholderGroup";
            id: string;
            updated: string;
            logic: { __typename?: "PlaceholderGroupLogic"; id: string; name?: string | null; logic?: any | null };
            placeholders: Array<{
                __typename?: "Placeholder";
                id: string;
                x?: number | null;
                y?: number | null;
                type: GqlClientPlaceholderType;
                height?: number | null;
                width?: number | null;
                logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
            }>;
        }> | null;
        placeholders?: Array<{
            __typename?: "Placeholder";
            id: string;
            updated: string;
            x?: number | null;
            y?: number | null;
            type: GqlClientPlaceholderType;
            height?: number | null;
            width?: number | null;
            logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
        }> | null;
    }>;
    animationGuidelines: Array<{
        __typename?: "AnimationGuideline";
        id: string;
        updated: string;
        type: GqlClientAnimationGuidelineType;
        logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
    }>;
    narrations: Array<{
        __typename?: "Narration";
        id: string;
        updated: string;
        representativeKey?: string | null;
        supportsCreativeDataElementOverride?: boolean | null;
        mergeData: Array<any>;
        dimensionData: Array<any>;
        dtaasPrefixKey?: string | null;
        lastUsedRecordingFileNumber: number;
        narrationVariations: Array<{
            __typename?: "NarrationVariation";
            id: string;
            updated: string;
            variationKey?: string | null;
            sentence?: string | null;
            muted?: boolean | null;
            note?: string | null;
            overridingCreativeDataElement?: { __typename?: "CreativeDataElement"; id: string } | null;
        }>;
    }>;
};

export type GqlClientScenePartNarrationsFragment = {
    __typename?: "ScenePart";
    id: string;
    narrationPartOrder: Array<string>;
    narrations: Array<{
        __typename?: "Narration";
        id: string;
        updated: string;
        representativeKey?: string | null;
        supportsCreativeDataElementOverride?: boolean | null;
        mergeData: Array<any>;
        dimensionData: Array<any>;
        dtaasPrefixKey?: string | null;
        lastUsedRecordingFileNumber: number;
        narrationVariations: Array<{
            __typename?: "NarrationVariation";
            id: string;
            updated: string;
            variationKey?: string | null;
            sentence?: string | null;
            muted?: boolean | null;
            note?: string | null;
            overridingCreativeDataElement?: { __typename?: "CreativeDataElement"; id: string } | null;
        }>;
    }>;
};

export type GqlClientTierFeatureDefinitionFragment = {
    __typename?: "TierFeatureDefinition";
    id: string;
    featureRestrictionValue?: any | null;
    tierDefinition: { __typename?: "TierDefinition"; id: string; displayName: string };
    featureDefinition: {
        __typename?: "FeatureDefinition";
        id: string;
        featureTypeDefinition: GqlClientFeatureTypeDefinition;
        featureRestrictionScope: GqlClientFeatureRestrictionScope;
        featureRestrictionType: GqlClientRestrictionType;
        errorMessage: string;
        userControlled: boolean;
    };
};

export type GqlClientCompleteFirstLoginMutationVariables = Exact<{
    input: GqlClientCompleteFirstLoginInput;
}>;

export type GqlClientCompleteFirstLoginMutation = {
    __typename?: "Mutation";
    completeFirstLogin:
        | { __typename?: "CompleteFirstLoginOutputRefusal"; result: GqlClientCompleteFirstLoginResult }
        | { __typename?: "CompleteFirstLoginOutputSuccess"; acceptedAccountId: string; result: GqlClientCompleteFirstLoginResult };
};

export type GqlClientCopyDraftEditorProgramBetweenAccountsMutationVariables = Exact<{
    input: GqlClientCopyDraftEditorProgramBetweenAccountsInput;
}>;

export type GqlClientCopyDraftEditorProgramBetweenAccountsMutation = {
    __typename?: "Mutation";
    copyDraftEditorProgramBetweenAccounts: {
        __typename?: "CopyDraftEditorProgramBetweenAccountsOutput";
        result: GqlClientCopyDraftEditorProgramBetweenAccountsResult;
        editorProgram?: {
            __typename?: "EditorProgram";
            accountId: string;
            id: string;
            account?: {
                __typename?: "Account";
                id: string;
                displayName?: string | null;
                name?: string | null;
                programs: Array<{
                    __typename: "Program";
                    id: string;
                    programType?: GqlClientProgramType | null;
                    saleForceId?: string | null;
                    accountId: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    anyUpdated?: string | null;
                    updatedBy?: string | null;
                    anyUpdatedBy?: string | null;
                    name: string;
                    description?: string | null;
                    legacyBuilderProgramId?: string | null;
                    legacyFrameworkProgramId?: string | null;
                    legacyOverviewProgramId?: string | null;
                    isEditor?: boolean | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    isArchive?: boolean | null;
                    customAnalyticFields: Array<{
                        __typename: "CustomAnalyticField";
                        id: string;
                        accountId: string;
                        updated: string;
                        updatedBy?: string | null;
                        name?: string | null;
                        logic?: any | null;
                        program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                    }>;
                    surveys: Array<{
                        __typename?: "Survey";
                        id: string;
                        externalId: string;
                        updated: string;
                        questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                    }>;
                }>;
                campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                reports: Array<{
                    __typename?: "AccountReport";
                    id: string;
                    name: string;
                    type: GqlClientAccountReportType;
                    timezone?: string | null;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    reportProgramType: GqlClientReportProgramType;
                    relevantPrograms: GqlClientRelevantPrograms;
                    filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
                    programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
                    campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                    reportElements: Array<{
                        __typename?: "ReportElement";
                        name: string;
                        platformName?: string | null;
                        description?: string | null;
                        platformDescription?: string | null;
                        elementType: GqlClientReportElementType;
                        formatType: GqlClientReportElementFormatType;
                        formatter?: any | null;
                        reportLibraryConfigKey: string;
                        placeHolder?: string | null;
                    }>;
                    schedules: Array<{
                        __typename?: "AccountSchedule";
                        id: string;
                        active?: boolean | null;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        deliveryChannel?: any | null;
                        occurrence?: GqlClientReportScheduleOccurrence | null;
                        period?: GqlClientReportSchedulePeriod | null;
                        periodType?: GqlClientReportSchedulePeriodType | null;
                        startPeriod?: string | null;
                        sourceEnvironments?: Array<string> | null;
                        filenameTemplate?: string | null;
                    }>;
                }>;
            } | null;
        } | null;
    };
};

export type GqlClientCopyEditorProgramMutationVariables = Exact<{
    input: GqlClientCopyProgramInput;
}>;

export type GqlClientCopyEditorProgramMutation = {
    __typename?: "Mutation";
    copyEditorProgram: {
        __typename?: "CopyProgramOutput";
        result: GqlClientCopyProgramResult;
        targetProgram?: {
            __typename?: "Program";
            accountId: string;
            id: string;
            legacyBuilderProgramId?: string | null;
            account: {
                __typename?: "Account";
                id: string;
                displayName?: string | null;
                name?: string | null;
                programs: Array<{
                    __typename: "Program";
                    id: string;
                    programType?: GqlClientProgramType | null;
                    saleForceId?: string | null;
                    accountId: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    anyUpdated?: string | null;
                    updatedBy?: string | null;
                    anyUpdatedBy?: string | null;
                    name: string;
                    description?: string | null;
                    legacyBuilderProgramId?: string | null;
                    legacyFrameworkProgramId?: string | null;
                    legacyOverviewProgramId?: string | null;
                    isEditor?: boolean | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    isArchive?: boolean | null;
                    customAnalyticFields: Array<{
                        __typename: "CustomAnalyticField";
                        id: string;
                        accountId: string;
                        updated: string;
                        updatedBy?: string | null;
                        name?: string | null;
                        logic?: any | null;
                        program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                    }>;
                    surveys: Array<{
                        __typename?: "Survey";
                        id: string;
                        externalId: string;
                        updated: string;
                        questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                    }>;
                }>;
                campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                reports: Array<{
                    __typename?: "AccountReport";
                    id: string;
                    name: string;
                    type: GqlClientAccountReportType;
                    timezone?: string | null;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    reportProgramType: GqlClientReportProgramType;
                    relevantPrograms: GqlClientRelevantPrograms;
                    filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
                    programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
                    campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                    reportElements: Array<{
                        __typename?: "ReportElement";
                        name: string;
                        platformName?: string | null;
                        description?: string | null;
                        platformDescription?: string | null;
                        elementType: GqlClientReportElementType;
                        formatType: GqlClientReportElementFormatType;
                        formatter?: any | null;
                        reportLibraryConfigKey: string;
                        placeHolder?: string | null;
                    }>;
                    schedules: Array<{
                        __typename?: "AccountSchedule";
                        id: string;
                        active?: boolean | null;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        deliveryChannel?: any | null;
                        occurrence?: GqlClientReportScheduleOccurrence | null;
                        period?: GqlClientReportSchedulePeriod | null;
                        periodType?: GqlClientReportSchedulePeriodType | null;
                        startPeriod?: string | null;
                        sourceEnvironments?: Array<string> | null;
                        filenameTemplate?: string | null;
                    }>;
                }>;
            };
        } | null;
    };
};

export type GqlClientCopyEditorProgramAsEditorMutationVariables = Exact<{
    input: GqlClientCopyProgramInput;
}>;

export type GqlClientCopyEditorProgramAsEditorMutation = {
    __typename?: "Mutation";
    copyEditorProgramAsEditor: {
        __typename?: "CopyEditorProgramAsEditorOutput";
        result: GqlClientCopyEditorProgramAsEditorResult;
        targetProgram?: {
            __typename?: "EditorProgram";
            id: string;
            name: string;
            anyUpdated?: string | null;
            isArchive?: boolean | null;
            programVersions: Array<{ __typename?: "EditorProgramVersion"; id: string }>;
            account?: { __typename?: "Account"; id: string; editorPrograms: Array<{ __typename?: "EditorProgram"; id: string }> } | null;
        } | null;
    };
};

export type GqlClientCopyProgramMutationVariables = Exact<{
    input: GqlClientCopyProgramInput;
}>;

export type GqlClientCopyProgramMutation = {
    __typename?: "Mutation";
    copyProgram: {
        __typename?: "CopyProgramOutput";
        result: GqlClientCopyProgramResult;
        targetProgram?: {
            __typename?: "Program";
            accountId: string;
            id: string;
            legacyBuilderProgramId?: string | null;
            account: {
                __typename?: "Account";
                id: string;
                displayName?: string | null;
                name?: string | null;
                programs: Array<{
                    __typename: "Program";
                    id: string;
                    programType?: GqlClientProgramType | null;
                    saleForceId?: string | null;
                    accountId: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    anyUpdated?: string | null;
                    updatedBy?: string | null;
                    anyUpdatedBy?: string | null;
                    name: string;
                    description?: string | null;
                    legacyBuilderProgramId?: string | null;
                    legacyFrameworkProgramId?: string | null;
                    legacyOverviewProgramId?: string | null;
                    isEditor?: boolean | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    isArchive?: boolean | null;
                    customAnalyticFields: Array<{
                        __typename: "CustomAnalyticField";
                        id: string;
                        accountId: string;
                        updated: string;
                        updatedBy?: string | null;
                        name?: string | null;
                        logic?: any | null;
                        program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                    }>;
                    surveys: Array<{
                        __typename?: "Survey";
                        id: string;
                        externalId: string;
                        updated: string;
                        questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                    }>;
                }>;
                campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                reports: Array<{
                    __typename?: "AccountReport";
                    id: string;
                    name: string;
                    type: GqlClientAccountReportType;
                    timezone?: string | null;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    reportProgramType: GqlClientReportProgramType;
                    relevantPrograms: GqlClientRelevantPrograms;
                    filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
                    programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
                    campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                    reportElements: Array<{
                        __typename?: "ReportElement";
                        name: string;
                        platformName?: string | null;
                        description?: string | null;
                        platformDescription?: string | null;
                        elementType: GqlClientReportElementType;
                        formatType: GqlClientReportElementFormatType;
                        formatter?: any | null;
                        reportLibraryConfigKey: string;
                        placeHolder?: string | null;
                    }>;
                    schedules: Array<{
                        __typename?: "AccountSchedule";
                        id: string;
                        active?: boolean | null;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        deliveryChannel?: any | null;
                        occurrence?: GqlClientReportScheduleOccurrence | null;
                        period?: GqlClientReportSchedulePeriod | null;
                        periodType?: GqlClientReportSchedulePeriodType | null;
                        startPeriod?: string | null;
                        sourceEnvironments?: Array<string> | null;
                        filenameTemplate?: string | null;
                    }>;
                }>;
            };
        } | null;
    };
};

export type GqlClientCopySceneMutationVariables = Exact<{
    input: GqlClientCopySceneInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCopySceneMutation = {
    __typename?: "Mutation";
    copyScene: {
        __typename?: "CopySceneOutput";
        result: GqlClientCopySceneResult;
        targetScene?: {
            __typename?: "Scene";
            id: string;
            name: string;
            created: string;
            updated: string;
            animationLogic?: any | null;
            validationLogic?: any | null;
            descriptiveTranscriptLogic?: any | null;
            scenePartOrder: Array<string>;
            sceneParts: Array<{
                __typename?: "ScenePart";
                id: string;
                updated: string;
                created: string;
                thumbnailLocation?: string | null;
                oldNarrationData: Array<any>;
                narrationPartOrder: Array<string>;
                placeholders: Array<{
                    __typename?: "Placeholder";
                    id: string;
                    updated: string;
                    x?: number | null;
                    y?: number | null;
                    type: GqlClientPlaceholderType;
                    height?: number | null;
                    width?: number | null;
                    logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
                }>;
                placeholderGroups: Array<{
                    __typename?: "PlaceholderGroup";
                    id: string;
                    updated: string;
                    logic: { __typename?: "PlaceholderGroupLogic"; id: string; name?: string | null; logic?: any | null };
                    placeholders: Array<{
                        __typename?: "Placeholder";
                        id: string;
                        updated: string;
                        x?: number | null;
                        y?: number | null;
                        type: GqlClientPlaceholderType;
                        height?: number | null;
                        width?: number | null;
                        logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
                    }>;
                }>;
                prioritizedLists: Array<{
                    __typename?: "PrioritizedList";
                    id: string;
                    updated: string;
                    logic: { __typename?: "PrioritizedListLogic"; id: string; name: string; logic?: any | null };
                    groups?: Array<{
                        __typename?: "PlaceholderGroup";
                        id: string;
                        updated: string;
                        logic: { __typename?: "PlaceholderGroupLogic"; id: string; name?: string | null; logic?: any | null };
                        placeholders: Array<{
                            __typename?: "Placeholder";
                            id: string;
                            x?: number | null;
                            y?: number | null;
                            type: GqlClientPlaceholderType;
                            height?: number | null;
                            width?: number | null;
                            logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
                        }>;
                    }> | null;
                    placeholders?: Array<{
                        __typename?: "Placeholder";
                        id: string;
                        updated: string;
                        x?: number | null;
                        y?: number | null;
                        type: GqlClientPlaceholderType;
                        height?: number | null;
                        width?: number | null;
                        logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
                    }> | null;
                }>;
                animationGuidelines: Array<{
                    __typename?: "AnimationGuideline";
                    id: string;
                    updated: string;
                    type: GqlClientAnimationGuidelineType;
                    logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
                }>;
                narrations: Array<{
                    __typename?: "Narration";
                    id: string;
                    updated: string;
                    representativeKey?: string | null;
                    supportsCreativeDataElementOverride?: boolean | null;
                    mergeData: Array<any>;
                    dimensionData: Array<any>;
                    dtaasPrefixKey?: string | null;
                    lastUsedRecordingFileNumber: number;
                    narrationVariations: Array<{
                        __typename?: "NarrationVariation";
                        id: string;
                        updated: string;
                        variationKey?: string | null;
                        sentence?: string | null;
                        muted?: boolean | null;
                        note?: string | null;
                        overridingCreativeDataElement?: { __typename?: "CreativeDataElement"; id: string } | null;
                    }>;
                }>;
            }>;
        } | null;
    };
};

export type GqlClientCopyScenePartMutationVariables = Exact<{
    input: GqlClientCopyScenePartInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCopyScenePartMutation = {
    __typename?: "Mutation";
    copyScenePart: {
        __typename?: "CopyScenePartOutput";
        result: GqlClientCopyScenePartResult;
        sourceScenePart?: { __typename?: "ScenePart"; id: string } | null;
        targetScenePart?: {
            __typename?: "ScenePart";
            id: string;
            updated: string;
            narrationPartOrder: Array<string>;
            narrations: Array<{
                __typename?: "Narration";
                id: string;
                updated: string;
                representativeKey?: string | null;
                supportsCreativeDataElementOverride?: boolean | null;
                mergeData: Array<any>;
                dimensionData: Array<any>;
                dtaasPrefixKey?: string | null;
                lastUsedRecordingFileNumber: number;
                narrationVariations: Array<{
                    __typename?: "NarrationVariation";
                    id: string;
                    updated: string;
                    variationKey?: string | null;
                    sentence?: string | null;
                    muted?: boolean | null;
                    note?: string | null;
                    overridingCreativeDataElement?: { __typename?: "CreativeDataElement"; id: string } | null;
                }>;
            }>;
        } | null;
    };
};

export type GqlClientCreateAssetRequestMutationVariables = Exact<{
    input: GqlClientCreateAssetRequestInput;
}>;

export type GqlClientCreateAssetRequestMutation = {
    __typename?: "Mutation";
    createAssetRequest: {
        __typename?: "CreateAssetRequestOutput";
        result: GqlClientCreateAssetRequestResult;
        asset?: {
            __typename?: "Asset";
            id: string;
            legacyAssetId: string;
            accountId: string;
            name: string;
            description?: string | null;
            type: GqlClientAssetType;
            created: string;
            anyUpdated?: string | null;
            legacyData?: any | null;
            assetActiveVersion: {
                __typename?: "AssetVersion";
                id: string;
                url?: string | null;
                thumbnailUrl?: string | null;
                filename?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
                size?: number | null;
                version: number;
                created: string;
                createdBy?: string | null;
            };
        } | null;
    };
};

export type GqlClientCreateAssetVersionMutationVariables = Exact<{
    input: GqlClientCreateAssetVersionInput;
}>;

export type GqlClientCreateAssetVersionMutation = {
    __typename?: "Mutation";
    createAssetVersion: {
        __typename?: "CreateAssetVersionOutput";
        result: GqlClientCreateAssetVersionResult;
        assetVersion?: {
            __typename?: "AssetVersion";
            id: string;
            asset: {
                __typename?: "Asset";
                id: string;
                legacyAssetId: string;
                accountId: string;
                name: string;
                description?: string | null;
                type: GqlClientAssetType;
                created: string;
                anyUpdated?: string | null;
                legacyData?: any | null;
                assetVersions: Array<{
                    __typename?: "AssetVersion";
                    id: string;
                    url?: string | null;
                    thumbnailUrl?: string | null;
                    filename?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                    size?: number | null;
                    version: number;
                    created: string;
                    createdBy?: string | null;
                }>;
                assetActiveVersion: {
                    __typename?: "AssetVersion";
                    id: string;
                    url?: string | null;
                    thumbnailUrl?: string | null;
                    filename?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                    size?: number | null;
                    version: number;
                    created: string;
                    createdBy?: string | null;
                };
            };
        } | null;
    };
};

export type GqlClientCreateAssetWithVersionMutationVariables = Exact<{
    input: GqlClientCreateAssetWithVersionInput;
}>;

export type GqlClientCreateAssetWithVersionMutation = {
    __typename?: "Mutation";
    createAssetWithVersion: {
        __typename?: "CreateAssetWithVersionOutput";
        result: GqlClientCreateAssetWithVersionResult;
        asset?: {
            __typename?: "Asset";
            id: string;
            legacyAssetId: string;
            accountId: string;
            name: string;
            description?: string | null;
            type: GqlClientAssetType;
            created: string;
            anyUpdated?: string | null;
            legacyData?: any | null;
            assetActiveVersion: {
                __typename?: "AssetVersion";
                id: string;
                url?: string | null;
                thumbnailUrl?: string | null;
                filename?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
                size?: number | null;
                version: number;
                created: string;
                createdBy?: string | null;
            };
        } | null;
    };
};

export type GqlClientCreateCustomAnalyticFieldMutationVariables = Exact<{
    input: GqlClientCreateCustomAnalyticFieldInput;
}>;

export type GqlClientCreateCustomAnalyticFieldMutation = {
    __typename?: "Mutation";
    createCustomAnalyticField: {
        __typename?: "CreateCustomAnalyticFieldOutput";
        result: GqlClientCreateCustomAnalyticFieldResult;
        customAnalyticField?: {
            __typename: "CustomAnalyticField";
            id: string;
            accountId: string;
            updated: string;
            updatedBy?: string | null;
            name?: string | null;
            logic?: any | null;
            program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
        } | null;
    };
};

export type GqlClientCreateDataTableMutationVariables = Exact<{
    input: GqlClientCreateDataTableInput;
}>;

export type GqlClientCreateDataTableMutation = {
    __typename?: "Mutation";
    createDataTable: {
        __typename?: "CreateDataTableOutput";
        result: GqlClientCreateDataTableResult;
        dataTable?: {
            __typename?: "DataTable";
            id: string;
            accountId: string;
            legacyDataTableId?: string | null;
            name: string;
            description?: string | null;
            updated: string;
            created: string;
            dtaasPrefix?: string | null;
            location?: string | null;
            schema?: any | null;
            downloadUrl?: string | null;
        } | null;
    };
};

export type GqlClientCreateEditorProgramMutationVariables = Exact<{
    input: GqlClientCreateEditorProgramInput;
}>;

export type GqlClientCreateEditorProgramMutation = {
    __typename?: "Mutation";
    createEditorProgram: {
        __typename?: "CreateEditorProgramOutput";
        result: GqlClientCreateEditorProgramResult;
        editorProgram?: {
            __typename?: "EditorProgram";
            id: string;
            name: string;
            accountId: string;
            created: string;
            createdBy?: string | null;
            programVersionDraft: {
                __typename?: "EditorProgramVersion";
                id: string;
                brandConfigurationVersion: {
                    __typename?: "BrandConfigurationVersion";
                    id: string;
                    defaultBrandConfigurationCollection: { __typename?: "BrandConfigurationCollection"; id: string; localId: string };
                    brandConfigurationCollections: Array<{ __typename?: "BrandConfigurationCollection"; id: string }>;
                };
                selectedBrandConfiguration: {
                    __typename?: "BrandConfigurationCollection";
                    id: string;
                    localId: string;
                    updated: string;
                    updatedBy?: string | null;
                    created: string;
                    name: string;
                    description?: string | null;
                    logoUrl?: string | null;
                    logoLocation?: string | null;
                    buttonShape: GqlClientButtonShapeValues;
                    logoMedia?: {
                        __typename?: "EditorAssetMedia";
                        id: string;
                        name: string;
                        created: string;
                        updated: string;
                        mediaLocation: string;
                        mediaUrl: string;
                        mediaPreviewUrl: string;
                        mediaAgifUrl?: string | null;
                        mediaOrigin: GqlClientMediaOrigin;
                        mediaMetadata: any;
                        mediaProcessingInfo?: any | null;
                        mediaFramesThumbnailsUrl?: string | null;
                        originalEditInformation?: any | null;
                        originalMediaId?: string | null;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        mediaSri?: string | null;
                        externalLibraryAssetId?: string | null;
                        externalLibrary?: GqlClientExternalLibrary | null;
                        externalSublibrary?: GqlClientExternalSubLibrary | null;
                        directAncestorId?: string | null;
                        mediaClassification: GqlClientMediaClassification;
                        description?: string | null;
                        createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                        folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                    } | null;
                    colors: Array<{
                        __typename?: "BcColor";
                        id: string;
                        updated: string;
                        color: string;
                        ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
                    }>;
                    textStyles: Array<{
                        __typename?: "BcTextStyle";
                        id: string;
                        fontSource: GqlClientEditorAssetSource;
                        letterCase: GqlClientTextStyleLetterCase;
                        letterSpacing: number;
                        lineSpacing: number;
                        updated: string;
                        ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                        ccFont?: {
                            __typename?: "CcFont";
                            id: string;
                            name: string;
                            url: string;
                            hasSmallCaps?: boolean | null;
                            lifecycleStage?: GqlClientAssetLifecycleStage | null;
                            webFontUrl?: string | null;
                            created: string;
                            familyName?: string | null;
                            isBold?: boolean | null;
                            isItalic?: boolean | null;
                            weight?: number | null;
                        } | null;
                        accountFont?: {
                            __typename?: "EditorAssetFont";
                            id: string;
                            name: string;
                            url?: string | null;
                            imageUrl?: string | null;
                            webFontUrl?: string | null;
                            hasSmallCaps?: boolean | null;
                            lifecycleStage?: GqlClientAssetLifecycleStage | null;
                            uploadId?: string | null;
                            created: string;
                            familyName?: string | null;
                            isBold?: boolean | null;
                            isItalic?: boolean | null;
                            weight?: number | null;
                        } | null;
                    }>;
                };
                assetLibrary: {
                    __typename?: "EditorAssetLibraryVersion";
                    id: string;
                    fonts: Array<{
                        __typename?: "EditorAssetFont";
                        id: string;
                        name: string;
                        url?: string | null;
                        imageUrl?: string | null;
                        webFontUrl?: string | null;
                        hasSmallCaps?: boolean | null;
                        lifecycleStage?: GqlClientAssetLifecycleStage | null;
                        uploadId?: string | null;
                        created: string;
                        familyName?: string | null;
                        isBold?: boolean | null;
                        isItalic?: boolean | null;
                        weight?: number | null;
                    } | null>;
                    music: Array<{
                        __typename?: "EditorAssetMusic";
                        id: string;
                        name: string;
                        url: string;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        externalLibraryAssetId?: string | null;
                        musicOrigin: GqlClientMusicOrigin;
                    } | null>;
                    media: Array<{
                        __typename?: "EditorAssetMedia";
                        id: string;
                        name: string;
                        created: string;
                        updated: string;
                        mediaLocation: string;
                        mediaUrl: string;
                        mediaPreviewUrl: string;
                        mediaAgifUrl?: string | null;
                        mediaOrigin: GqlClientMediaOrigin;
                        mediaMetadata: any;
                        mediaProcessingInfo?: any | null;
                        mediaFramesThumbnailsUrl?: string | null;
                        originalEditInformation?: any | null;
                        originalMediaId?: string | null;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        mediaSri?: string | null;
                        externalLibraryAssetId?: string | null;
                        externalLibrary?: GqlClientExternalLibrary | null;
                        externalSublibrary?: GqlClientExternalSubLibrary | null;
                        directAncestorId?: string | null;
                        mediaClassification: GqlClientMediaClassification;
                        description?: string | null;
                        createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                        folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                    }>;
                    mediaFolders: Array<{
                        __typename?: "EditorAssetFolder";
                        id: string;
                        name: string;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        updatedBy?: string | null;
                        parentFolder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                    }>;
                };
            };
            account?: { __typename?: "Account"; id: string; editorPrograms: Array<{ __typename?: "EditorProgram"; id: string }> } | null;
        } | null;
    };
};

export type GqlClientCreateEditorProgramFromStoryTemplateMutationVariables = Exact<{
    input: GqlClientCreateEditorProgramFromStoryTemplateInput;
}>;

export type GqlClientCreateEditorProgramFromStoryTemplateMutation = {
    __typename?: "Mutation";
    createEditorProgramFromStoryTemplate: {
        __typename?: "CreateEditorProgramFromStoryTemplateOutput";
        result: GqlClientCreateEditorProgramFromStoryTemplateResult;
        editorProgram?: {
            __typename?: "EditorProgram";
            id: string;
            name: string;
            accountId: string;
            created: string;
            createdBy?: string | null;
            programVersionDraft: {
                __typename?: "EditorProgramVersion";
                id: string;
                brandConfigurationVersion: {
                    __typename?: "BrandConfigurationVersion";
                    id: string;
                    defaultBrandConfigurationCollection: { __typename?: "BrandConfigurationCollection"; id: string; localId: string };
                    brandConfigurationCollections: Array<{ __typename?: "BrandConfigurationCollection"; id: string }>;
                };
                selectedBrandConfiguration: {
                    __typename?: "BrandConfigurationCollection";
                    id: string;
                    localId: string;
                    updated: string;
                    updatedBy?: string | null;
                    created: string;
                    name: string;
                    description?: string | null;
                    logoUrl?: string | null;
                    logoLocation?: string | null;
                    buttonShape: GqlClientButtonShapeValues;
                    logoMedia?: {
                        __typename?: "EditorAssetMedia";
                        id: string;
                        name: string;
                        created: string;
                        updated: string;
                        mediaLocation: string;
                        mediaUrl: string;
                        mediaPreviewUrl: string;
                        mediaAgifUrl?: string | null;
                        mediaOrigin: GqlClientMediaOrigin;
                        mediaMetadata: any;
                        mediaProcessingInfo?: any | null;
                        mediaFramesThumbnailsUrl?: string | null;
                        originalEditInformation?: any | null;
                        originalMediaId?: string | null;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        mediaSri?: string | null;
                        externalLibraryAssetId?: string | null;
                        externalLibrary?: GqlClientExternalLibrary | null;
                        externalSublibrary?: GqlClientExternalSubLibrary | null;
                        directAncestorId?: string | null;
                        mediaClassification: GqlClientMediaClassification;
                        description?: string | null;
                        createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                        folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                    } | null;
                    colors: Array<{
                        __typename?: "BcColor";
                        id: string;
                        updated: string;
                        color: string;
                        ccDof: { __typename?: "CcDof"; id: string; localId: string; name: string; displayName: string; type: GqlClientCcDofType };
                    }>;
                    textStyles: Array<{
                        __typename?: "BcTextStyle";
                        id: string;
                        fontSource: GqlClientEditorAssetSource;
                        letterCase: GqlClientTextStyleLetterCase;
                        letterSpacing: number;
                        lineSpacing: number;
                        updated: string;
                        ccTextStyle: { __typename?: "CcTextStyle"; id: string; name: string };
                        ccFont?: {
                            __typename?: "CcFont";
                            id: string;
                            name: string;
                            url: string;
                            hasSmallCaps?: boolean | null;
                            lifecycleStage?: GqlClientAssetLifecycleStage | null;
                            webFontUrl?: string | null;
                            created: string;
                            familyName?: string | null;
                            isBold?: boolean | null;
                            isItalic?: boolean | null;
                            weight?: number | null;
                        } | null;
                        accountFont?: {
                            __typename?: "EditorAssetFont";
                            id: string;
                            name: string;
                            url?: string | null;
                            imageUrl?: string | null;
                            webFontUrl?: string | null;
                            hasSmallCaps?: boolean | null;
                            lifecycleStage?: GqlClientAssetLifecycleStage | null;
                            uploadId?: string | null;
                            created: string;
                            familyName?: string | null;
                            isBold?: boolean | null;
                            isItalic?: boolean | null;
                            weight?: number | null;
                        } | null;
                    }>;
                };
                assetLibrary: {
                    __typename?: "EditorAssetLibraryVersion";
                    id: string;
                    fonts: Array<{
                        __typename?: "EditorAssetFont";
                        id: string;
                        name: string;
                        url?: string | null;
                        imageUrl?: string | null;
                        webFontUrl?: string | null;
                        hasSmallCaps?: boolean | null;
                        lifecycleStage?: GqlClientAssetLifecycleStage | null;
                        uploadId?: string | null;
                        created: string;
                        familyName?: string | null;
                        isBold?: boolean | null;
                        isItalic?: boolean | null;
                        weight?: number | null;
                    } | null>;
                    music: Array<{
                        __typename?: "EditorAssetMusic";
                        id: string;
                        name: string;
                        url: string;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        externalLibraryAssetId?: string | null;
                        musicOrigin: GqlClientMusicOrigin;
                    } | null>;
                    media: Array<{
                        __typename?: "EditorAssetMedia";
                        id: string;
                        name: string;
                        created: string;
                        updated: string;
                        mediaLocation: string;
                        mediaUrl: string;
                        mediaPreviewUrl: string;
                        mediaAgifUrl?: string | null;
                        mediaOrigin: GqlClientMediaOrigin;
                        mediaMetadata: any;
                        mediaProcessingInfo?: any | null;
                        mediaFramesThumbnailsUrl?: string | null;
                        originalEditInformation?: any | null;
                        originalMediaId?: string | null;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        mediaSri?: string | null;
                        externalLibraryAssetId?: string | null;
                        externalLibrary?: GqlClientExternalLibrary | null;
                        externalSublibrary?: GqlClientExternalSubLibrary | null;
                        directAncestorId?: string | null;
                        mediaClassification: GqlClientMediaClassification;
                        description?: string | null;
                        createdByUser?: { __typename?: "EditorAccountUser"; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
                        folder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                    }>;
                    mediaFolders: Array<{
                        __typename?: "EditorAssetFolder";
                        id: string;
                        name: string;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        updatedBy?: string | null;
                        parentFolder?: { __typename?: "EditorAssetFolder"; id: string } | null;
                    }>;
                };
            };
            account?: { __typename?: "Account"; id: string; editorPrograms: Array<{ __typename?: "EditorProgram"; id: string }> } | null;
        } | null;
    };
};

export type GqlClientCreateMasterAnimationGuidelineMutationVariables = Exact<{
    input: GqlClientCreateMasterAnimationGuidelineInput;
}>;

export type GqlClientCreateMasterAnimationGuidelineMutation = {
    __typename?: "Mutation";
    createMasterAnimationGuideline: {
        __typename?: "CreateMasterAnimationGuidelineOutput";
        result: GqlClientCreateMasterAnimationGuidelineResult;
        masterAnimationGuideline?: { __typename?: "MasterAnimationGuideline"; id: string; updated: string } | null;
    };
};

export type GqlClientCreateNarrationRecordingVersionMutationVariables = Exact<{
    input: GqlClientCreateNarrationRecordingVersionInput;
}>;

export type GqlClientCreateNarrationRecordingVersionMutation = {
    __typename?: "Mutation";
    createNarrationRecordingVersion: {
        __typename?: "CreateNarrationRecordingVersionOutput";
        result: GqlClientCreateNarrationRecordingVersionResult;
        narrationRecordingVersion?: {
            __typename?: "NarrationRecordingVersion";
            id: string;
            narrationRecording: {
                __typename?: "NarrationRecording";
                id: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                key: string;
                filename: string;
                text: string;
                rfr: number;
                metadata?: any | null;
                lastVersionNumber: number;
                narrationId: string;
                narrationRecordingVersions: Array<{
                    __typename?: "NarrationRecordingVersion";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    url?: string | null;
                    format?: string | null;
                    location?: string | null;
                    status?: GqlClientNarrationRecordingStatus | null;
                    errorReason?: GqlClientNarrationRecordingErrorReason | null;
                    version: number;
                }>;
                narrationRecordingActiveVersion?: {
                    __typename?: "NarrationRecordingVersion";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    url?: string | null;
                    format?: string | null;
                    location?: string | null;
                    status?: GqlClientNarrationRecordingStatus | null;
                    errorReason?: GqlClientNarrationRecordingErrorReason | null;
                    version: number;
                } | null;
            };
        } | null;
    };
};

export type GqlClientCreateOrUpdateEditorApplicationMutationVariables = Exact<{
    input: GqlClientCreateOrUpdateEditorApplicationInput;
}>;

export type GqlClientCreateOrUpdateEditorApplicationMutation = {
    __typename?: "Mutation";
    createOrUpdateEditorApplication:
        | { __typename?: "CreateOrUpdateEditorApplicationOutputConflict"; result: GqlClientCreateOrUpdateEditorApplicationResult }
        | {
              __typename?: "CreateOrUpdateEditorApplicationOutputSuccess";
              result: GqlClientCreateOrUpdateEditorApplicationResult;
              editorApplication?: {
                  __typename?: "EditorApplication";
                  id: string;
                  name?: string | null;
                  metadata?: any | null;
                  accountId: string;
                  isParent?: boolean | null;
                  updated: string;
                  industry?: { __typename?: "EditorIndustry"; id: string; name: string } | null;
                  useCases?: Array<{ __typename?: "EditorUseCase"; id: string; name: string } | null> | null;
                  creativeStyleFits?: Array<{ __typename?: "EditorCreativeStyleFit"; id: string; name: string } | null> | null;
                  channels?: Array<{ __typename?: "EditorChannel"; id: string; name: string } | null> | null;
                  flavor?: { __typename?: "ApplicationFlavor"; id: string; name: string } | null;
                  parent?: { __typename?: "EditorApplication"; id: string; name?: string | null } | null;
              } | null;
          };
};

export type GqlClientCreatePresetMutationVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
    input: GqlClientCreatePresetInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCreatePresetMutation = {
    __typename?: "Mutation";
    createPreset: { __typename?: "CreatePresetOutput"; result: GqlClientCreatePresetResult; preset?: { __typename?: "Preset"; id: string; updated: string } | null };
};

export type GqlClientCreateProgramAndBuilderDraftMutationVariables = Exact<{
    input: GqlClientCreateProgramAndBuilderDraftInput;
    legacyBuilderProgramId?: InputMaybe<Scalars["String"]["input"]>;
    legacyAudienceDataElementId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCreateProgramAndBuilderDraftMutation = {
    __typename?: "Mutation";
    createProgramAndBuilderDraft: {
        __typename?: "CreateProgramAndBuilderDraftOutput";
        result: GqlClientCreateProgramAndBuilderDraftResult;
        program?: {
            __typename?: "Program";
            id: string;
            created: string;
            createdBy?: string | null;
            name: string;
            accountId: string;
            legacyBuilderProgramId?: string | null;
            programVersions: Array<{ __typename?: "ProgramVersion"; id: string }>;
            account: {
                __typename?: "Account";
                id: string;
                displayName?: string | null;
                name?: string | null;
                programs: Array<{
                    __typename: "Program";
                    id: string;
                    programType?: GqlClientProgramType | null;
                    saleForceId?: string | null;
                    accountId: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    anyUpdated?: string | null;
                    updatedBy?: string | null;
                    anyUpdatedBy?: string | null;
                    name: string;
                    description?: string | null;
                    legacyBuilderProgramId?: string | null;
                    legacyFrameworkProgramId?: string | null;
                    legacyOverviewProgramId?: string | null;
                    isEditor?: boolean | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    isArchive?: boolean | null;
                    customAnalyticFields: Array<{
                        __typename: "CustomAnalyticField";
                        id: string;
                        accountId: string;
                        updated: string;
                        updatedBy?: string | null;
                        name?: string | null;
                        logic?: any | null;
                        program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                    }>;
                    surveys: Array<{
                        __typename?: "Survey";
                        id: string;
                        externalId: string;
                        updated: string;
                        questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                    }>;
                }>;
                campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                reports: Array<{
                    __typename?: "AccountReport";
                    id: string;
                    name: string;
                    type: GqlClientAccountReportType;
                    timezone?: string | null;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    reportProgramType: GqlClientReportProgramType;
                    relevantPrograms: GqlClientRelevantPrograms;
                    filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
                    programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
                    campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                    reportElements: Array<{
                        __typename?: "ReportElement";
                        name: string;
                        platformName?: string | null;
                        description?: string | null;
                        platformDescription?: string | null;
                        elementType: GqlClientReportElementType;
                        formatType: GqlClientReportElementFormatType;
                        formatter?: any | null;
                        reportLibraryConfigKey: string;
                        placeHolder?: string | null;
                    }>;
                    schedules: Array<{
                        __typename?: "AccountSchedule";
                        id: string;
                        active?: boolean | null;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        deliveryChannel?: any | null;
                        occurrence?: GqlClientReportScheduleOccurrence | null;
                        period?: GqlClientReportSchedulePeriod | null;
                        periodType?: GqlClientReportSchedulePeriodType | null;
                        startPeriod?: string | null;
                        sourceEnvironments?: Array<string> | null;
                        filenameTemplate?: string | null;
                    }>;
                }>;
            };
        } | null;
    };
};

export type GqlClientCreateProgramVersionDraftMutationVariables = Exact<{
    programId: Scalars["ID"]["input"];
    input: GqlClientCreateProgramVersionDraftInput;
    legacyBuilderProgramId?: InputMaybe<Scalars["String"]["input"]>;
    legacyAudienceDataElementId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCreateProgramVersionDraftMutation = {
    __typename?: "Mutation";
    createProgramVersionDraft: {
        __typename?: "CreateProgramVersionDraftOutput";
        result: GqlClientCreateProgramVersionDraftResult;
        programVersion?: {
            __typename: "ProgramVersion";
            sceneOrder: Array<string>;
            storyOrder: Array<string>;
            id: string;
            name: string;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            useCase?: string | null;
            audienceSize?: string | null;
            industry?: string | null;
            description?: string | null;
            program: {
                __typename: "Program";
                id: string;
                programType?: GqlClientProgramType | null;
                saleForceId?: string | null;
                accountId: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                anyUpdated?: string | null;
                updatedBy?: string | null;
                anyUpdatedBy?: string | null;
                name: string;
                description?: string | null;
                legacyBuilderProgramId?: string | null;
                legacyFrameworkProgramId?: string | null;
                legacyOverviewProgramId?: string | null;
                isEditor?: boolean | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                isArchive?: boolean | null;
                programVersionDrafts: Array<{
                    __typename: "ProgramVersion";
                    sceneOrder: Array<string>;
                    storyOrder: Array<string>;
                    id: string;
                    name: string;
                    updated: string;
                    updatedBy?: string | null;
                    anyUpdated?: string | null;
                    anyUpdatedBy?: string | null;
                    useCase?: string | null;
                    audienceSize?: string | null;
                    industry?: string | null;
                    description?: string | null;
                    goals: Array<{
                        __typename: "Goal";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        priority?: number | null;
                        kpis: Array<{
                            __typename: "KPI";
                            id: string;
                            updated: string;
                            name: string;
                            description?: string | null;
                            objective?: string | null;
                            scenes: Array<{ __typename?: "Scene"; id: string }>;
                            goal: { __typename?: "Goal"; id: string };
                        }>;
                    }>;
                    stories: Array<{
                        __typename: "Story";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        displaySceneOrder: Array<string>;
                        scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                        touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                        channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                    }>;
                    scenes: Array<{
                        __typename: "Scene";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                        kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                        personalizations: Array<
                            | {
                                  __typename: "AudiencePersonalization";
                                  id: string;
                                  updated: string;
                                  type: Array<string>;
                                  description?: string | null;
                                  audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                  audiencePersonalizationValues: Array<{
                                      __typename?: "AudiencePersonalizationValue";
                                      id: string;
                                      updated: string;
                                      description?: string | null;
                                      audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                  }>;
                              }
                            | {
                                  __typename: "ChannelPersonalization";
                                  id: string;
                                  updated: string;
                                  type: Array<string>;
                                  description?: string | null;
                                  channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                              }
                            | {
                                  __typename: "TouchpointPersonalization";
                                  id: string;
                                  updated: string;
                                  type: Array<string>;
                                  description?: string | null;
                                  touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                              }
                        >;
                        stories: Array<{ __typename?: "Story"; id: string }>;
                    }>;
                    audiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        impactsVolume?: boolean | null;
                        audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                        audienceValues: Array<{
                            __typename: "AudienceValue";
                            id: string;
                            updated: string;
                            name: string;
                            volume?: number | null;
                            audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                            subAudiences: Array<{
                                __typename: "Audience";
                                id: string;
                                updated: string;
                                name: string;
                                audienceValues: Array<{
                                    __typename: "AudienceValue";
                                    id: string;
                                    updated: string;
                                    name: string;
                                    volume?: number | null;
                                    audience: { __typename?: "Audience"; id: string };
                                }>;
                                audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                            }>;
                            audiencePersonalizationValues: Array<{
                                __typename?: "AudiencePersonalizationValue";
                                id: string;
                                description?: string | null;
                                audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                            }>;
                        }>;
                        feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    }>;
                    channels: Array<{
                        __typename: "Channel";
                        id: string;
                        updated: string;
                        name: string;
                        platforms?: Array<string> | null;
                        status?: string | null;
                        channelPersonalizations: Array<{
                            __typename?: "ChannelPersonalization";
                            id: string;
                            description?: string | null;
                            type: Array<string>;
                            scene: { __typename?: "Scene"; id: string; name: string };
                        }>;
                        stories: Array<{ __typename?: "Story"; id: string }>;
                    }>;
                    touchpoints: Array<{
                        __typename: "Touchpoint";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        touchpointPersonalizations: Array<{
                            __typename?: "TouchpointPersonalization";
                            id: string;
                            description?: string | null;
                            type: Array<string>;
                            scene: { __typename?: "Scene"; id: string; name: string };
                        }>;
                        stories: Array<{ __typename?: "Story"; id: string }>;
                    }>;
                    feedDataElements: Array<{
                        __typename: "FeedDataElement";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        source?: string | null;
                        status?: GqlClientFeedDataElementStatus | null;
                        scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                        audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                    }>;
                }>;
                customAnalyticFields: Array<{
                    __typename: "CustomAnalyticField";
                    id: string;
                    accountId: string;
                    updated: string;
                    updatedBy?: string | null;
                    name?: string | null;
                    logic?: any | null;
                    program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                }>;
                surveys: Array<{
                    __typename?: "Survey";
                    id: string;
                    externalId: string;
                    updated: string;
                    questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                }>;
            };
            goals: Array<{
                __typename: "Goal";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                priority?: number | null;
                kpis: Array<{
                    __typename: "KPI";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    objective?: string | null;
                    scenes: Array<{ __typename?: "Scene"; id: string }>;
                    goal: { __typename?: "Goal"; id: string };
                }>;
            }>;
            stories: Array<{
                __typename: "Story";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                displaySceneOrder: Array<string>;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
            }>;
            scenes: Array<{
                __typename: "Scene";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                personalizations: Array<
                    | {
                          __typename: "AudiencePersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                          audiencePersonalizationValues: Array<{
                              __typename?: "AudiencePersonalizationValue";
                              id: string;
                              updated: string;
                              description?: string | null;
                              audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                          }>;
                      }
                    | {
                          __typename: "ChannelPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                      }
                    | {
                          __typename: "TouchpointPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                      }
                >;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            audiences: Array<{
                __typename: "Audience";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                impactsVolume?: boolean | null;
                audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                audienceValues: Array<{
                    __typename: "AudienceValue";
                    id: string;
                    updated: string;
                    name: string;
                    volume?: number | null;
                    audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                    subAudiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                        audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                    }>;
                    audiencePersonalizationValues: Array<{
                        __typename?: "AudiencePersonalizationValue";
                        id: string;
                        description?: string | null;
                        audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                    }>;
                }>;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
            }>;
            channels: Array<{
                __typename: "Channel";
                id: string;
                updated: string;
                name: string;
                platforms?: Array<string> | null;
                status?: string | null;
                channelPersonalizations: Array<{
                    __typename?: "ChannelPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            touchpoints: Array<{
                __typename: "Touchpoint";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                touchpointPersonalizations: Array<{
                    __typename?: "TouchpointPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            feedDataElements: Array<{
                __typename: "FeedDataElement";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                source?: string | null;
                status?: GqlClientFeedDataElementStatus | null;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
            }>;
        } | null;
    };
};

export type GqlClientCreateProgramVersionDraftFromSnapshotMutationVariables = Exact<{
    input: GqlClientCreateProgramVersionDraftFromSnapshotInput;
}>;

export type GqlClientCreateProgramVersionDraftFromSnapshotMutation = {
    __typename?: "Mutation";
    createProgramVersionDraftFromSnapshot: { __typename?: "CreateProgramVersionDraftFromSnapshotOutput"; result: GqlClientCreateProgramVersionDraftFromSnapshotResult };
};

export type GqlClientCreateProgramVersionSnapshotMutationVariables = Exact<{
    input: GqlClientCreateProgramVersionSnapshotInput;
}>;

export type GqlClientCreateProgramVersionSnapshotMutation = {
    __typename?: "Mutation";
    createProgramVersionSnapshot: {
        __typename?: "CreateProgramVersionSnapshotOutput";
        result: GqlClientCreateProgramVersionSnapshotResult;
        sourceProgramVersion?: { __typename?: "ProgramVersion"; id: string; parent1ProgramVersion?: { __typename?: "ProgramVersion"; id: string } | null } | null;
        targetProgramVersion?: { __typename?: "ProgramVersion"; id: string } | null;
    };
};

export type GqlClientCreateRfrVersionMutationVariables = Exact<{
    input: GqlClientCreateRfrVersionInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientCreateRfrVersionMutation = {
    __typename?: "Mutation";
    createRFRVersion:
        | { __typename?: "CreateRFRVersionOutputConflict"; result: GqlClientCreateRfrVersionResult }
        | {
              __typename?: "CreateRFRVersionOutputSuccess";
              result: GqlClientCreateRfrVersionResult;
              programVersion?: {
                  __typename?: "ProgramVersion";
                  id: string;
                  updated: string;
                  narrationRecordings: Array<{ __typename?: "NarrationRecording"; id: string; key: string; narrationId: string; updated: string; lastVersionNumber: number }>;
              } | null;
          };
};

export type GqlClientCreateRecordingDocMutationVariables = Exact<{
    input: GqlClientCreateRecordingDocInput;
}>;

export type GqlClientCreateRecordingDocMutation = {
    __typename?: "Mutation";
    createRecordingDoc: {
        __typename?: "CreateRecordingDocOutput";
        result: GqlClientCreateRecordingDocResult;
        recordingDoc?: {
            __typename?: "RecordingDocEntry";
            id: string;
            audioFileUrl: string;
            audioFilename: string;
            scriptFileUrl: string;
            scriptFilename: string;
            totalRecordingFiles: number;
            created: string;
            createdBy?: string | null;
            folderLocation: string;
        } | null;
    };
};

export type GqlClientCreateScenePartMutationVariables = Exact<{
    input: GqlClientCreateScenePartInput;
}>;

export type GqlClientCreateScenePartMutation = {
    __typename?: "Mutation";
    createScenePart: { __typename?: "CreateScenePartOutput"; result: GqlClientCreateScenePartResult; scenePart?: { __typename?: "ScenePart"; id: string; updated: string } | null };
};

export type GqlClientDeleteCustomAnalyticFieldMutationVariables = Exact<{
    input: GqlClientDeleteCustomAnalyticFieldInput;
}>;

export type GqlClientDeleteCustomAnalyticFieldMutation = {
    __typename?: "Mutation";
    deleteCustomAnalyticField: {
        __typename?: "DeleteCustomAnalyticFieldOutput";
        result: GqlClientDeleteCustomAnalyticFieldResult;
        program?: {
            __typename: "Program";
            id: string;
            programType?: GqlClientProgramType | null;
            saleForceId?: string | null;
            accountId: string;
            created: string;
            createdBy?: string | null;
            updated: string;
            anyUpdated?: string | null;
            updatedBy?: string | null;
            anyUpdatedBy?: string | null;
            name: string;
            description?: string | null;
            legacyBuilderProgramId?: string | null;
            legacyFrameworkProgramId?: string | null;
            legacyOverviewProgramId?: string | null;
            isEditor?: boolean | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            isArchive?: boolean | null;
            customAnalyticFields: Array<{
                __typename: "CustomAnalyticField";
                id: string;
                accountId: string;
                updated: string;
                updatedBy?: string | null;
                name?: string | null;
                logic?: any | null;
                program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
            }>;
            surveys: Array<{ __typename?: "Survey"; id: string; externalId: string; updated: string; questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }> }>;
        } | null;
    };
};

export type GqlClientDeleteDataTableMutationVariables = Exact<{
    input: GqlClientDeleteDataTableInput;
}>;

export type GqlClientDeleteDataTableMutation = { __typename?: "Mutation"; deleteDataTable: { __typename?: "DeleteDataTableOutput"; result: GqlClientDeleteDataTableResult } };

export type GqlClientDeleteEditorProgramMutationVariables = Exact<{
    input: GqlClientDeleteEditorProgramInput;
}>;

export type GqlClientDeleteEditorProgramMutation = {
    __typename?: "Mutation";
    deleteEditorProgram: {
        __typename?: "DeleteEditorProgramOutput";
        result: GqlClientDeleteEditorProgramResult;
        account?: { __typename?: "Account"; id: string; editorPrograms: Array<{ __typename?: "EditorProgram"; id: string }> } | null;
    };
};

export type GqlClientDeleteMasterAnimationGuidelineMutationVariables = Exact<{
    input: GqlClientDeleteMasterAnimationGuidelineInput;
}>;

export type GqlClientDeleteMasterAnimationGuidelineMutation = {
    __typename?: "Mutation";
    deleteMasterAnimationGuideline: { __typename?: "DeleteMasterAnimationGuidelineOutput"; result: GqlClientDeleteMasterAnimationGuidelineResult };
};

export type GqlClientDeletePlaceholderGroupLogicMutationVariables = Exact<{
    input: GqlClientDeletePlaceholderGroupLogicInput;
}>;

export type GqlClientDeletePlaceholderGroupLogicMutation = {
    __typename?: "Mutation";
    deletePlaceholderGroupLogic: { __typename?: "DeletePlaceholderGroupLogicOutput"; result: GqlClientDeletePlaceholderGroupLogicResult };
};

export type GqlClientDeletePresetMutationVariables = Exact<{
    input: GqlClientDeletePresetInput;
}>;

export type GqlClientDeletePresetMutation = { __typename?: "Mutation"; deletePreset: { __typename?: "DeletePresetOutput"; result: GqlClientDeletePresetResult } };

export type GqlClientDeletePrioritizedListLogicMutationVariables = Exact<{
    input: GqlClientDeletePrioritizedListLogicInput;
}>;

export type GqlClientDeletePrioritizedListLogicMutation = {
    __typename?: "Mutation";
    deletePrioritizedListLogic: { __typename?: "DeletePrioritizedListLogicOutput"; result: GqlClientDeletePrioritizedListLogicResult };
};

export type GqlClientDeleteSceneMutationVariables = Exact<{
    input: GqlClientDeleteSceneInput;
}>;

export type GqlClientDeleteSceneMutation = {
    __typename?: "Mutation";
    deleteScene: {
        __typename?: "DeleteSceneOutput";
        result: GqlClientDeleteSceneResult;
        programVersion?: {
            __typename: "ProgramVersion";
            sceneOrder: Array<string>;
            storyOrder: Array<string>;
            id: string;
            name: string;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            useCase?: string | null;
            audienceSize?: string | null;
            industry?: string | null;
            description?: string | null;
            goals: Array<{
                __typename: "Goal";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                priority?: number | null;
                kpis: Array<{
                    __typename: "KPI";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    objective?: string | null;
                    scenes: Array<{ __typename?: "Scene"; id: string }>;
                    goal: { __typename?: "Goal"; id: string };
                }>;
            }>;
            stories: Array<{
                __typename: "Story";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                displaySceneOrder: Array<string>;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
            }>;
            scenes: Array<{
                __typename: "Scene";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                personalizations: Array<
                    | {
                          __typename: "AudiencePersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                          audiencePersonalizationValues: Array<{
                              __typename?: "AudiencePersonalizationValue";
                              id: string;
                              updated: string;
                              description?: string | null;
                              audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                          }>;
                      }
                    | {
                          __typename: "ChannelPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                      }
                    | {
                          __typename: "TouchpointPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                      }
                >;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            audiences: Array<{
                __typename: "Audience";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                impactsVolume?: boolean | null;
                audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                audienceValues: Array<{
                    __typename: "AudienceValue";
                    id: string;
                    updated: string;
                    name: string;
                    volume?: number | null;
                    audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                    subAudiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                        audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                    }>;
                    audiencePersonalizationValues: Array<{
                        __typename?: "AudiencePersonalizationValue";
                        id: string;
                        description?: string | null;
                        audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                    }>;
                }>;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
            }>;
            channels: Array<{
                __typename: "Channel";
                id: string;
                updated: string;
                name: string;
                platforms?: Array<string> | null;
                status?: string | null;
                channelPersonalizations: Array<{
                    __typename?: "ChannelPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            touchpoints: Array<{
                __typename: "Touchpoint";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                touchpointPersonalizations: Array<{
                    __typename?: "TouchpointPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            feedDataElements: Array<{
                __typename: "FeedDataElement";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                source?: string | null;
                status?: GqlClientFeedDataElementStatus | null;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
            }>;
        } | null;
    };
};

export type GqlClientDeleteSceneEntityLogicMutationVariables = Exact<{
    input: GqlClientDeleteSceneEntityLogicInput;
}>;

export type GqlClientDeleteSceneEntityLogicMutation = {
    __typename?: "Mutation";
    deleteSceneEntityLogic: { __typename?: "DeleteSceneEntityLogicOutput"; result: GqlClientDeleteSceneEntityLogicResult };
};

export type GqlClientDeleteScenePartMutationVariables = Exact<{
    input: GqlClientDeleteScenePartInput;
}>;

export type GqlClientDeleteScenePartMutation = {
    __typename?: "Mutation";
    deleteScenePart: {
        __typename?: "DeleteScenePartOutput";
        result: GqlClientDeleteScenePartResult;
        scene?: {
            __typename: "Scene";
            id: string;
            updated: string;
            name: string;
            description?: string | null;
            feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
            kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
            personalizations: Array<
                | {
                      __typename: "AudiencePersonalization";
                      id: string;
                      updated: string;
                      type: Array<string>;
                      description?: string | null;
                      audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                      audiencePersonalizationValues: Array<{
                          __typename?: "AudiencePersonalizationValue";
                          id: string;
                          updated: string;
                          description?: string | null;
                          audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                      }>;
                  }
                | {
                      __typename: "ChannelPersonalization";
                      id: string;
                      updated: string;
                      type: Array<string>;
                      description?: string | null;
                      channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                  }
                | {
                      __typename: "TouchpointPersonalization";
                      id: string;
                      updated: string;
                      type: Array<string>;
                      description?: string | null;
                      touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                  }
            >;
            stories: Array<{ __typename?: "Story"; id: string }>;
        } | null;
    };
};

export type GqlClientUpdateAnimationMutationVariables = Exact<{
    input: GqlClientUpdateAnimationInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateAnimationMutation = {
    __typename?: "Mutation";
    updateAnimation:
        | { __typename?: "UpdateAnimationOutputConflict"; result: GqlClientUpdateAnimationResult }
        | {
              __typename?: "UpdateAnimationOutputSuccess";
              result: GqlClientUpdateAnimationResult;
              animation?: {
                  __typename?: "Animation";
                  id: string;
                  legacyAnimationId: string;
                  name: string;
                  description?: string | null;
                  updated: string;
                  animationActiveVersion: {
                      __typename?: "AnimationVersion";
                      id: string;
                      version: number;
                      created: string;
                      createdBy?: string | null;
                      aeFilename?: string | null;
                      comment?: string | null;
                  };
              } | null;
          };
};

export type GqlClientUpdateAssetMutationVariables = Exact<{
    input: GqlClientUpdateAssetInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateAssetMutation = {
    __typename?: "Mutation";
    updateAsset:
        | { __typename?: "UpdateAssetOutputConflict"; result: GqlClientUpdateAssetResult }
        | {
              __typename?: "UpdateAssetOutputSuccess";
              result: GqlClientUpdateAssetResult;
              asset?: {
                  __typename?: "Asset";
                  id: string;
                  legacyAssetId: string;
                  accountId: string;
                  name: string;
                  description?: string | null;
                  type: GqlClientAssetType;
                  created: string;
                  anyUpdated?: string | null;
                  legacyData?: any | null;
                  assetActiveVersion: {
                      __typename?: "AssetVersion";
                      id: string;
                      url?: string | null;
                      thumbnailUrl?: string | null;
                      filename?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                      size?: number | null;
                      version: number;
                      created: string;
                      createdBy?: string | null;
                  };
              } | null;
          };
};

export type GqlClientUpdateBuildersSceneMutationVariables = Exact<{
    input: GqlClientUpdateSceneInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateBuildersSceneMutation = {
    __typename?: "Mutation";
    updateScene:
        | { __typename?: "UpdateSceneOutputConflict"; result: GqlClientUpdateSceneResult }
        | {
              __typename?: "UpdateSceneOutputSuccess";
              result: GqlClientUpdateSceneResult;
              scene?: {
                  __typename: "Scene";
                  id: string;
                  updated: string;
                  name: string;
                  description?: string | null;
                  programVersion: {
                      __typename: "ProgramVersion";
                      sceneOrder: Array<string>;
                      storyOrder: Array<string>;
                      id: string;
                      name: string;
                      updated: string;
                      updatedBy?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                      useCase?: string | null;
                      audienceSize?: string | null;
                      industry?: string | null;
                      description?: string | null;
                      goals: Array<{
                          __typename: "Goal";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          priority?: number | null;
                          kpis: Array<{
                              __typename: "KPI";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              objective?: string | null;
                              scenes: Array<{ __typename?: "Scene"; id: string }>;
                              goal: { __typename?: "Goal"; id: string };
                          }>;
                      }>;
                      stories: Array<{
                          __typename: "Story";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          displaySceneOrder: Array<string>;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                          channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                      }>;
                      scenes: Array<{
                          __typename: "Scene";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                          kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                          personalizations: Array<
                              | {
                                    __typename: "AudiencePersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                    audiencePersonalizationValues: Array<{
                                        __typename?: "AudiencePersonalizationValue";
                                        id: string;
                                        updated: string;
                                        description?: string | null;
                                        audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                    }>;
                                }
                              | {
                                    __typename: "ChannelPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                                }
                              | {
                                    __typename: "TouchpointPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                                }
                          >;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      audiences: Array<{
                          __typename: "Audience";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          impactsVolume?: boolean | null;
                          audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                          audienceValues: Array<{
                              __typename: "AudienceValue";
                              id: string;
                              updated: string;
                              name: string;
                              volume?: number | null;
                              audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              subAudiences: Array<{
                                  __typename: "Audience";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  audienceValues: Array<{
                                      __typename: "AudienceValue";
                                      id: string;
                                      updated: string;
                                      name: string;
                                      volume?: number | null;
                                      audience: { __typename?: "Audience"; id: string };
                                  }>;
                                  audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                              }>;
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  description?: string | null;
                                  audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                              }>;
                          }>;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                      }>;
                      channels: Array<{
                          __typename: "Channel";
                          id: string;
                          updated: string;
                          name: string;
                          platforms?: Array<string> | null;
                          status?: string | null;
                          channelPersonalizations: Array<{
                              __typename?: "ChannelPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      touchpoints: Array<{
                          __typename: "Touchpoint";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          touchpointPersonalizations: Array<{
                              __typename?: "TouchpointPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      feedDataElements: Array<{
                          __typename: "FeedDataElement";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          source?: string | null;
                          status?: GqlClientFeedDataElementStatus | null;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                      }>;
                  };
                  feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                  kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                  personalizations: Array<
                      | {
                            __typename: "AudiencePersonalization";
                            id: string;
                            updated: string;
                            type: Array<string>;
                            description?: string | null;
                            audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                            audiencePersonalizationValues: Array<{
                                __typename?: "AudiencePersonalizationValue";
                                id: string;
                                updated: string;
                                description?: string | null;
                                audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                            }>;
                        }
                      | {
                            __typename: "ChannelPersonalization";
                            id: string;
                            updated: string;
                            type: Array<string>;
                            description?: string | null;
                            channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                        }
                      | {
                            __typename: "TouchpointPersonalization";
                            id: string;
                            updated: string;
                            type: Array<string>;
                            description?: string | null;
                            touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                        }
                  >;
                  stories: Array<{ __typename?: "Story"; id: string }>;
              } | null;
          };
};

export type GqlClientUpdateCustomAnalyticFieldMutationVariables = Exact<{
    input: GqlClientUpdateCustomAnalyticFieldInput;
}>;

export type GqlClientUpdateCustomAnalyticFieldMutation = {
    __typename?: "Mutation";
    updateCustomAnalyticField:
        | { __typename?: "UpdateCustomAnalyticFieldOutputConflict"; result: GqlClientUpdateCustomAnalyticFieldResult }
        | {
              __typename?: "UpdateCustomAnalyticFieldOutputSuccess";
              result: GqlClientUpdateCustomAnalyticFieldResult;
              customAnalyticField?: {
                  __typename: "CustomAnalyticField";
                  id: string;
                  accountId: string;
                  updated: string;
                  updatedBy?: string | null;
                  name?: string | null;
                  logic?: any | null;
                  program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
              } | null;
          };
};

export type GqlClientUpdateDataTableMutationVariables = Exact<{
    input: GqlClientUpdateDataTableInput;
}>;

export type GqlClientUpdateDataTableMutation = {
    __typename?: "Mutation";
    updateDataTable:
        | { __typename?: "UpdateDataTableOutputConflict"; result: GqlClientUpdateDataTableResult }
        | {
              __typename?: "UpdateDataTableOutputSuccess";
              result: GqlClientUpdateDataTableResult;
              dataTable?: {
                  __typename?: "DataTable";
                  id: string;
                  accountId: string;
                  legacyDataTableId?: string | null;
                  name: string;
                  description?: string | null;
                  updated: string;
                  created: string;
                  dtaasPrefix?: string | null;
                  location?: string | null;
                  schema?: any | null;
                  downloadUrl?: string | null;
              } | null;
          };
};

export type GqlClientUpdateEditorAssetMediaMutationVariables = Exact<{
    input: GqlClientUpdateEditorAssetMediaInput;
}>;

export type GqlClientUpdateEditorAssetMediaMutation = {
    __typename?: "Mutation";
    updateEditorAssetMedia: {
        __typename?: "UpdateEditorAssetMediaOutput";
        result: GqlClientUpdateEditorAssetMediaResult;
        asset?: { __typename?: "EditorAssetMedia"; id: string; name: string; description?: string | null } | null;
    };
};

export type GqlClientUpdateEditorIsArchivedMutationVariables = Exact<{
    input: GqlClientUpdateEditorIsArchivedInput;
}>;

export type GqlClientUpdateEditorIsArchivedMutation = {
    __typename?: "Mutation";
    updateEditorIsArchived:
        | { __typename?: "UpdateEditorIsArchivedOutputConflict"; result: GqlClientUpdateEditorIsArchivedResult }
        | {
              __typename?: "UpdateEditorIsArchivedOutputSuccess";
              result: GqlClientUpdateEditorIsArchivedResult;
              editorProgram?: {
                  __typename?: "EditorProgram";
                  id: string;
                  updated: string;
                  updatedBy?: string | null;
                  anyUpdated?: string | null;
                  anyUpdatedBy?: string | null;
                  isArchive?: boolean | null;
                  lastArchived?: string | null;
                  landingPage?: { __typename?: "LandingPage"; id: string; isActive?: boolean | null } | null;
              } | null;
          };
};

export type GqlClientUpdateEditorIsLiveMutationVariables = Exact<{
    input: GqlClientUpdateEditorIsLiveInput;
}>;

export type GqlClientUpdateEditorIsLiveMutation = {
    __typename?: "Mutation";
    updateEditorIsLive: {
        __typename?: "UpdateEditorIsLiveOutput";
        result: GqlClientUpdateEditorIsLiveResult;
        editorProgram?: {
            __typename?: "EditorProgram";
            id: string;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            isLive?: boolean | null;
        } | null;
    };
};

export type GqlClientUpdateEditorProgramLifecycleStageMutationVariables = Exact<{
    input: GqlClientUpdateEditorProgramLifecycleStageInput;
}>;

export type GqlClientUpdateEditorProgramLifecycleStageMutation = {
    __typename?: "Mutation";
    updateEditorProgramLifecycleStage:
        | { __typename?: "UpdateEditorProgramLifecycleStageOutputConflict"; result: GqlClientUpdateEditorProgramLifecycleStageResult }
        | {
              __typename?: "UpdateEditorProgramLifecycleStageOutputSuccess";
              result: GqlClientUpdateEditorProgramLifecycleStageResult;
              editorProgram?: { __typename?: "EditorProgram"; id: string; lifecycleStage?: GqlClientAssetLifecycleStage | null } | null;
          };
};

export type GqlClientUpdateEditorProgramNameMutationVariables = Exact<{
    input: GqlClientUpdateEditorProgramNameInput;
}>;

export type GqlClientUpdateEditorProgramNameMutation = {
    __typename?: "Mutation";
    updateEditorProgramName:
        | { __typename?: "UpdateEditorProgramNameOutputConflict"; result: GqlClientUpdateEditorProgramNameResult }
        | { __typename?: "UpdateEditorProgramNameOutputSuccess"; result: GqlClientUpdateEditorProgramNameResult; editorProgram?: { __typename?: "EditorProgram"; id: string; name: string } | null };
};

export type GqlClientUpdateEditorProgramThemeMutationVariables = Exact<{
    input: GqlClientUpdateEditorProgramThemeInput;
}>;

export type GqlClientUpdateEditorProgramThemeMutation = {
    __typename?: "Mutation";
    updateEditorProgramTheme:
        | { __typename?: "UpdateEditorProgramThemeOutputConflict"; result: GqlClientUpdateEditorProgramThemeResult }
        | {
              __typename?: "UpdateEditorProgramThemeOutputSuccess";
              result: GqlClientUpdateEditorProgramThemeResult;
              editorProgramVersion?: {
                  __typename?: "EditorProgramVersion";
                  id: string;
                  ccTheme: { __typename?: "CcTheme"; id: string };
                  scenes: Array<{ __typename?: "EditorScene"; id: string; ccAnimatedWireframe?: { __typename?: "CcAnimatedWireframe"; id: string } | null }>;
              } | null;
          };
};

export type GqlClientUpdateMasterAnimationGuidelineMutationVariables = Exact<{
    input: GqlClientUpdateMasterAnimationGuidelineInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateMasterAnimationGuidelineMutation = {
    __typename?: "Mutation";
    updateMasterAnimationGuideline:
        | { __typename?: "UpdateMasterAnimationGuidelineOutputConflict"; result: GqlClientUpdateMasterAnimationGuidelineResult }
        | {
              __typename?: "UpdateMasterAnimationGuidelineOutputSuccess";
              result: GqlClientUpdateMasterAnimationGuidelineResult;
              masterAnimationGuideline?: {
                  __typename?: "MasterAnimationGuideline";
                  id: string;
                  updated: string;
                  name: string;
                  type?: GqlClientAnimationGuidelineType | null;
                  logic?: any | null;
              } | null;
          };
};

export type GqlClientUpdateMasterDataMutationVariables = Exact<{
    input: GqlClientUpdateMasterDataInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateMasterDataMutation = {
    __typename?: "Mutation";
    updateMasterData:
        | { __typename?: "UpdateMasterDataOutputConflict"; result: GqlClientUpdateMasterDataResult }
        | {
              __typename?: "UpdateMasterDataOutputSuccess";
              result: GqlClientUpdateMasterDataResult;
              masterAnimationGuidelines: Array<{ __typename?: "MasterAnimationGuideline"; id: string; name: string; type?: GqlClientAnimationGuidelineType | null; logic?: any | null }>;
          };
};

export type GqlClientUpdateNarrationOrderMutationVariables = Exact<{
    input: GqlClientUpdateNarrationOrderInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateNarrationOrderMutation = {
    __typename?: "Mutation";
    updateNarrationOrder:
        | { __typename?: "UpdateNarrationOrderConflict"; result: GqlClientUpdateNarrationOrderResult }
        | {
              __typename?: "UpdateNarrationOrderSuccess";
              result: GqlClientUpdateNarrationOrderResult;
              originalScenePart?: { __typename?: "ScenePart"; id: string; updated: string } | null;
              targetScenePart?: { __typename?: "ScenePart"; id: string; updated: string } | null;
          };
};

export type GqlClientUpdateNarrationRecordingMutationVariables = Exact<{
    input: GqlClientUpdateNarrationRecordingInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateNarrationRecordingMutation = {
    __typename?: "Mutation";
    updateNarrationRecording:
        | { __typename?: "UpdateNarrationRecordingOutputConflict"; result: GqlClientUpdateNarrationRecordingResult }
        | {
              __typename?: "UpdateNarrationRecordingOutputSuccess";
              result: GqlClientUpdateNarrationRecordingResult;
              narrationRecording?: {
                  __typename?: "NarrationRecording";
                  id: string;
                  created: string;
                  createdBy?: string | null;
                  updated: string;
                  key: string;
                  filename: string;
                  text: string;
                  rfr: number;
                  metadata?: any | null;
                  lastVersionNumber: number;
                  narrationId: string;
                  narrationRecordingVersions: Array<{
                      __typename?: "NarrationRecordingVersion";
                      id: string;
                      created: string;
                      createdBy?: string | null;
                      url?: string | null;
                      format?: string | null;
                      location?: string | null;
                      status?: GqlClientNarrationRecordingStatus | null;
                      errorReason?: GqlClientNarrationRecordingErrorReason | null;
                      version: number;
                  }>;
                  narrationRecordingActiveVersion?: {
                      __typename?: "NarrationRecordingVersion";
                      id: string;
                      created: string;
                      createdBy?: string | null;
                      url?: string | null;
                      format?: string | null;
                      location?: string | null;
                      status?: GqlClientNarrationRecordingStatus | null;
                      errorReason?: GqlClientNarrationRecordingErrorReason | null;
                      version: number;
                  } | null;
              } | null;
          };
};

export type GqlClientUpdateNarrationRecordingVersionMutationVariables = Exact<{
    input: GqlClientUpdateNarrationRecordingVersionInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateNarrationRecordingVersionMutation = {
    __typename?: "Mutation";
    updateNarrationRecordingVersion:
        | { __typename?: "UpdateNarrationRecordingVersionOutputConflict"; result: GqlClientUpdateNarrationRecordingVersionResult }
        | { __typename?: "UpdateNarrationRecordingVersionOutputSuccess"; result: GqlClientUpdateNarrationRecordingVersionResult };
};

export type GqlClientUpdateNarrationsDtaasPrefixKeyMutationVariables = Exact<{
    input: GqlClientUpdateNarrationsDtaasPrefixKeyInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateNarrationsDtaasPrefixKeyMutation = {
    __typename?: "Mutation";
    updateNarrationsDtaasPrefixKey:
        | { __typename?: "UpdateNarrationsDtaasPrefixKeyOutputConflict"; result: GqlClientUpdateNarrationsDtaasPrefixKeyResult }
        | { __typename?: "UpdateNarrationsDtaasPrefixKeyOutputSuccess"; result: GqlClientUpdateNarrationsDtaasPrefixKeyResult };
};

export type GqlClientUpdatePlaceholderGroupLogicMutationVariables = Exact<{
    input: GqlClientUpdatePlaceholderGroupLogicInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdatePlaceholderGroupLogicMutation = {
    __typename?: "Mutation";
    updatePlaceholderGroupLogic:
        | { __typename?: "UpdatePlaceholderGroupLogicOutputConflict"; result: GqlClientUpdatePlaceholderGroupLogicResult }
        | { __typename?: "UpdatePlaceholderGroupLogicOutputSuccess"; result: GqlClientUpdatePlaceholderGroupLogicResult };
};

export type GqlClientUpdatePresetMutationVariables = Exact<{
    input: GqlClientUpdatePresetInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdatePresetMutation = {
    __typename?: "Mutation";
    updatePreset:
        | { __typename?: "UpdatePresetOutputConflict"; result: GqlClientUpdatePresetResult }
        | { __typename?: "UpdatePresetOutputSuccess"; result: GqlClientUpdatePresetResult; preset?: { __typename?: "Preset"; id: string; updated: string } | null };
};

export type GqlClientUpdatePrioritizedListLogicMutationVariables = Exact<{
    input: GqlClientUpdatePrioritizedListLogicInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdatePrioritizedListLogicMutation = {
    __typename?: "Mutation";
    updatePrioritizedListLogic:
        | { __typename?: "UpdatePrioritizedListLogicOutputConflict"; result: GqlClientUpdatePrioritizedListLogicResult }
        | { __typename?: "UpdatePrioritizedListLogicOutputSuccess"; result: GqlClientUpdatePrioritizedListLogicResult };
};

export type GqlClientUpdateProgramMutationVariables = Exact<{
    input: GqlClientUpdateProgramInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateProgramMutation = {
    __typename?: "Mutation";
    updateProgram:
        | { __typename?: "UpdateProgramOutputConflict"; result: GqlClientUpdateProgramResult }
        | {
              __typename?: "UpdateProgramOutputSuccess";
              result: GqlClientUpdateProgramResult;
              program?: {
                  __typename?: "Program";
                  id: string;
                  updated: string;
                  updatedBy?: string | null;
                  anyUpdated?: string | null;
                  anyUpdatedBy?: string | null;
                  isArchive?: boolean | null;
                  lastArchived?: string | null;
                  account: {
                      __typename?: "Account";
                      id: string;
                      displayName?: string | null;
                      name?: string | null;
                      programs: Array<{
                          __typename: "Program";
                          id: string;
                          programType?: GqlClientProgramType | null;
                          saleForceId?: string | null;
                          accountId: string;
                          created: string;
                          createdBy?: string | null;
                          updated: string;
                          anyUpdated?: string | null;
                          updatedBy?: string | null;
                          anyUpdatedBy?: string | null;
                          name: string;
                          description?: string | null;
                          legacyBuilderProgramId?: string | null;
                          legacyFrameworkProgramId?: string | null;
                          legacyOverviewProgramId?: string | null;
                          isEditor?: boolean | null;
                          lifecycleStage: GqlClientAssetLifecycleStage;
                          isArchive?: boolean | null;
                          customAnalyticFields: Array<{
                              __typename: "CustomAnalyticField";
                              id: string;
                              accountId: string;
                              updated: string;
                              updatedBy?: string | null;
                              name?: string | null;
                              logic?: any | null;
                              program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                          }>;
                          surveys: Array<{
                              __typename?: "Survey";
                              id: string;
                              externalId: string;
                              updated: string;
                              questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                          }>;
                      }>;
                      campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                      reports: Array<{
                          __typename?: "AccountReport";
                          id: string;
                          name: string;
                          type: GqlClientAccountReportType;
                          timezone?: string | null;
                          created: string;
                          createdBy?: string | null;
                          updated: string;
                          updatedBy?: string | null;
                          reportProgramType: GqlClientReportProgramType;
                          relevantPrograms: GqlClientRelevantPrograms;
                          filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
                          programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
                          campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                          reportElements: Array<{
                              __typename?: "ReportElement";
                              name: string;
                              platformName?: string | null;
                              description?: string | null;
                              platformDescription?: string | null;
                              elementType: GqlClientReportElementType;
                              formatType: GqlClientReportElementFormatType;
                              formatter?: any | null;
                              reportLibraryConfigKey: string;
                              placeHolder?: string | null;
                          }>;
                          schedules: Array<{
                              __typename?: "AccountSchedule";
                              id: string;
                              active?: boolean | null;
                              created: string;
                              createdBy?: string | null;
                              updated: string;
                              deliveryChannel?: any | null;
                              occurrence?: GqlClientReportScheduleOccurrence | null;
                              period?: GqlClientReportSchedulePeriod | null;
                              periodType?: GqlClientReportSchedulePeriodType | null;
                              startPeriod?: string | null;
                              sourceEnvironments?: Array<string> | null;
                              filenameTemplate?: string | null;
                          }>;
                      }>;
                  };
              } | null;
          };
};

export type GqlClientUpdateProgramAndDraftMutationVariables = Exact<{
    input: GqlClientUpdateProgramAndDraftInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateProgramAndDraftMutation = {
    __typename?: "Mutation";
    updateProgramAndDraft:
        | { __typename?: "UpdateProgramAndDraftOutputConflict"; result: GqlClientUpdateProgramAndDraftResult }
        | {
              __typename?: "UpdateProgramAndDraftOutputSuccess";
              result: GqlClientUpdateProgramAndDraftResult;
              program?: {
                  __typename: "Program";
                  id: string;
                  programType?: GqlClientProgramType | null;
                  saleForceId?: string | null;
                  accountId: string;
                  created: string;
                  createdBy?: string | null;
                  updated: string;
                  anyUpdated?: string | null;
                  updatedBy?: string | null;
                  anyUpdatedBy?: string | null;
                  name: string;
                  description?: string | null;
                  legacyBuilderProgramId?: string | null;
                  legacyFrameworkProgramId?: string | null;
                  legacyOverviewProgramId?: string | null;
                  isEditor?: boolean | null;
                  lifecycleStage: GqlClientAssetLifecycleStage;
                  isArchive?: boolean | null;
                  programVersions: Array<{
                      __typename: "ProgramVersion";
                      sceneOrder: Array<string>;
                      storyOrder: Array<string>;
                      id: string;
                      name: string;
                      updated: string;
                      updatedBy?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                      useCase?: string | null;
                      audienceSize?: string | null;
                      industry?: string | null;
                      description?: string | null;
                      goals: Array<{
                          __typename: "Goal";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          priority?: number | null;
                          kpis: Array<{
                              __typename: "KPI";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              objective?: string | null;
                              scenes: Array<{ __typename?: "Scene"; id: string }>;
                              goal: { __typename?: "Goal"; id: string };
                          }>;
                      }>;
                      stories: Array<{
                          __typename: "Story";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          displaySceneOrder: Array<string>;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                          channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                      }>;
                      scenes: Array<{
                          __typename: "Scene";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                          kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                          personalizations: Array<
                              | {
                                    __typename: "AudiencePersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                    audiencePersonalizationValues: Array<{
                                        __typename?: "AudiencePersonalizationValue";
                                        id: string;
                                        updated: string;
                                        description?: string | null;
                                        audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                    }>;
                                }
                              | {
                                    __typename: "ChannelPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                                }
                              | {
                                    __typename: "TouchpointPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                                }
                          >;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      audiences: Array<{
                          __typename: "Audience";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          impactsVolume?: boolean | null;
                          audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                          audienceValues: Array<{
                              __typename: "AudienceValue";
                              id: string;
                              updated: string;
                              name: string;
                              volume?: number | null;
                              audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              subAudiences: Array<{
                                  __typename: "Audience";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  audienceValues: Array<{
                                      __typename: "AudienceValue";
                                      id: string;
                                      updated: string;
                                      name: string;
                                      volume?: number | null;
                                      audience: { __typename?: "Audience"; id: string };
                                  }>;
                                  audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                              }>;
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  description?: string | null;
                                  audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                              }>;
                          }>;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                      }>;
                      channels: Array<{
                          __typename: "Channel";
                          id: string;
                          updated: string;
                          name: string;
                          platforms?: Array<string> | null;
                          status?: string | null;
                          channelPersonalizations: Array<{
                              __typename?: "ChannelPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      touchpoints: Array<{
                          __typename: "Touchpoint";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          touchpointPersonalizations: Array<{
                              __typename?: "TouchpointPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      feedDataElements: Array<{
                          __typename: "FeedDataElement";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          source?: string | null;
                          status?: GqlClientFeedDataElementStatus | null;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                      }>;
                  }>;
                  customAnalyticFields: Array<{
                      __typename: "CustomAnalyticField";
                      id: string;
                      accountId: string;
                      updated: string;
                      updatedBy?: string | null;
                      name?: string | null;
                      logic?: any | null;
                      program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                  }>;
                  surveys: Array<{
                      __typename?: "Survey";
                      id: string;
                      externalId: string;
                      updated: string;
                      questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                  }>;
              } | null;
          };
};

export type GqlClientUpdateProgramLastSnapshotNumberMutationVariables = Exact<{
    input: GqlClientUpdateProgramLastSnapshotNumberInput;
}>;

export type GqlClientUpdateProgramLastSnapshotNumberMutation = {
    __typename?: "Mutation";
    updateProgramLastSnapshotNumber: { __typename?: "UpdateProgramLastSnapshotNumberOutput"; result: GqlClientUpdateProgramLastSnapshotNumberResult; snapshotNumber: number };
};

export type GqlClientUpdateProgramVersionMutationVariables = Exact<{
    input: GqlClientUpdateProgramVersionDraftInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateProgramVersionMutation = {
    __typename?: "Mutation";
    updateProgramVersionDraft:
        | { __typename?: "UpdateProgramVersionDraftOutputConflict"; result: GqlClientUpdateProgramVersionDraftResult }
        | {
              __typename?: "UpdateProgramVersionDraftOutputSuccess";
              result: GqlClientUpdateProgramVersionDraftResult;
              programVersion?: {
                  __typename?: "ProgramVersion";
                  id: string;
                  updated: string;
                  updatedBy?: string | null;
                  program: { __typename?: "Program"; id: string; updated: string; updatedBy?: string | null };
              } | null;
          };
};

export type GqlClientUpdateProgramVersionSnapshotMutationVariables = Exact<{
    input: GqlClientUpdateProgramVersionSnapshotInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateProgramVersionSnapshotMutation = {
    __typename?: "Mutation";
    updateProgramVersionSnapshot:
        | { __typename?: "UpdateProgramVersionSnapshotOutputConflict"; result: GqlClientUpdateProgramVersionSnapshotResult }
        | {
              __typename?: "UpdateProgramVersionSnapshotOutputSuccess";
              result: GqlClientUpdateProgramVersionSnapshotResult;
              programVersion?: { __typename?: "ProgramVersion"; id: string; updated: string } | null;
          };
};

export type GqlClientUpdateSceneEntityLogicMutationVariables = Exact<{
    input: GqlClientUpdateSceneEntityLogicInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateSceneEntityLogicMutation = {
    __typename?: "Mutation";
    updateSceneEntityLogic:
        | { __typename?: "UpdateSceneEntityLogicOutputConflict"; result: GqlClientUpdateSceneEntityLogicResult }
        | { __typename?: "UpdateSceneEntityLogicOutputSuccess"; result: GqlClientUpdateSceneEntityLogicResult };
};

export type GqlClientUpdateScenePartMutationVariables = Exact<{
    input: GqlClientUpdateScenePartInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateScenePartMutation = {
    __typename?: "Mutation";
    updateScenePart:
        | { __typename?: "UpdateScenePartOutputConflict"; result: GqlClientUpdateScenePartResult }
        | { __typename?: "UpdateScenePartOutputSuccess"; result: GqlClientUpdateScenePartResult; scenePart?: { __typename?: "ScenePart"; id: string; updated: string } | null };
};

export type GqlClientUpdateScenePartWithAllPlaceholdersMutationVariables = Exact<{
    input: GqlClientUpdateScenePartWithAllPlaceholdersInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateScenePartWithAllPlaceholdersMutation = {
    __typename?: "Mutation";
    updateScenePartWithAllPlaceholders:
        | { __typename?: "UpdateScenePartWithAllPlaceholdersOutputConflict"; result: GqlClientUpdateScenePartWithAllPlaceholdersResult }
        | {
              __typename?: "UpdateScenePartWithAllPlaceholdersOutputSuccess";
              result: GqlClientUpdateScenePartWithAllPlaceholdersResult;
              scenePart?: { __typename?: "ScenePart"; id: string; updated: string } | null;
          };
};

export type GqlClientGetAllDraftsQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientGetAllDraftsQuery = {
    __typename?: "Query";
    accounts: Array<{
        __typename?: "Account";
        id: string;
        name?: string | null;
        displayName?: string | null;
        programs: Array<{ __typename?: "Program"; id: string; name: string; programVersionDrafts: Array<{ __typename?: "ProgramVersion"; id: string; name: string }> }>;
    }>;
};

export type GqlClientGetAllProgramsQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientGetAllProgramsQuery = {
    __typename?: "Query";
    accounts: Array<{
        __typename?: "Account";
        id: string;
        name?: string | null;
        displayName?: string | null;
        type?: string | null;
        programs: Array<{ __typename?: "Program"; id: string; name: string; legacyBuilderProgramId?: string | null; isEditor?: boolean | null }>;
    }>;
};

export type GqlClientGetAnimationVersionsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
    animationId: Scalars["ID"]["input"];
}>;

export type GqlClientGetAnimationVersionsQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        programVersion?: {
            __typename?: "ProgramVersion";
            id: string;
            animation?: {
                __typename?: "Animation";
                id: string;
                legacyAnimationId: string;
                name: string;
                description?: string | null;
                updated: string;
                animationVersions: Array<{
                    __typename?: "AnimationVersion";
                    id: string;
                    version: number;
                    created: string;
                    createdBy?: string | null;
                    aeFilename?: string | null;
                    comment?: string | null;
                }>;
                animationActiveVersion: {
                    __typename?: "AnimationVersion";
                    id: string;
                    version: number;
                    created: string;
                    createdBy?: string | null;
                    aeFilename?: string | null;
                    comment?: string | null;
                };
            } | null;
        } | null;
    } | null;
};

export type GqlClientGetAssetVersionsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
    assetId: Scalars["ID"]["input"];
}>;

export type GqlClientGetAssetVersionsQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        programVersion?: {
            __typename?: "ProgramVersion";
            id: string;
            asset?: {
                __typename?: "Asset";
                id: string;
                legacyAssetId: string;
                accountId: string;
                name: string;
                description?: string | null;
                type: GqlClientAssetType;
                created: string;
                anyUpdated?: string | null;
                legacyData?: any | null;
                assetVersions: Array<{
                    __typename?: "AssetVersion";
                    id: string;
                    url?: string | null;
                    thumbnailUrl?: string | null;
                    filename?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                    size?: number | null;
                    version: number;
                    created: string;
                    createdBy?: string | null;
                }>;
                assetActiveVersion: {
                    __typename?: "AssetVersion";
                    id: string;
                    url?: string | null;
                    thumbnailUrl?: string | null;
                    filename?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                    size?: number | null;
                    version: number;
                    created: string;
                    createdBy?: string | null;
                };
            } | null;
        } | null;
    } | null;
};

export type GqlClientGetBuilderProgramQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
    versionId: Scalars["ID"]["input"];
}>;

export type GqlClientGetBuilderProgramQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        accountId: string;
        id: string;
        name: string;
        description?: string | null;
        updated: string;
        updatedBy?: string | null;
        isLegacy?: boolean | null;
        isEditor?: boolean | null;
        saleForceId?: string | null;
        customAnalyticsOrder: Array<string>;
        legacyBuilderProgramId?: string | null;
        publishTarget: GqlClientLifecycleStage;
        customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null; logic?: any | null; updated: string }>;
        folder?: { __typename?: "AccountProgramFolder"; id: string; name: string } | null;
        programVersion?: {
            __typename?: "ProgramVersion";
            id: string;
            transition?: any | null;
            storySelectionLogic?: any | null;
            programType?: GqlClientProgramType | null;
            rfr?: number | null;
            rfrUpdateTime?: string | null;
            narrationsVersion?: number | null;
            creativeVersion?: number | null;
            creativeDfde?: string | null;
            creativeNameLabel?: string | null;
            parent1ProgramVersion?: { __typename?: "ProgramVersion"; id: string } | null;
            scenes: Array<{
                __typename?: "Scene";
                id: string;
                name: string;
                created: string;
                updated: string;
                animationLogic?: any | null;
                validationLogic?: any | null;
                descriptiveTranscriptLogic?: any | null;
                scenePartOrder: Array<string>;
                sceneParts: Array<{
                    __typename?: "ScenePart";
                    id: string;
                    updated: string;
                    created: string;
                    thumbnailLocation?: string | null;
                    oldNarrationData: Array<any>;
                    narrationPartOrder: Array<string>;
                    placeholders: Array<{
                        __typename?: "Placeholder";
                        id: string;
                        updated: string;
                        x?: number | null;
                        y?: number | null;
                        type: GqlClientPlaceholderType;
                        height?: number | null;
                        width?: number | null;
                        logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
                    }>;
                    placeholderGroups: Array<{
                        __typename?: "PlaceholderGroup";
                        id: string;
                        updated: string;
                        logic: { __typename?: "PlaceholderGroupLogic"; id: string; name?: string | null; logic?: any | null };
                        placeholders: Array<{
                            __typename?: "Placeholder";
                            id: string;
                            updated: string;
                            x?: number | null;
                            y?: number | null;
                            type: GqlClientPlaceholderType;
                            height?: number | null;
                            width?: number | null;
                            logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
                        }>;
                    }>;
                    prioritizedLists: Array<{
                        __typename?: "PrioritizedList";
                        id: string;
                        updated: string;
                        logic: { __typename?: "PrioritizedListLogic"; id: string; name: string; logic?: any | null };
                        groups?: Array<{
                            __typename?: "PlaceholderGroup";
                            id: string;
                            updated: string;
                            logic: { __typename?: "PlaceholderGroupLogic"; id: string; name?: string | null; logic?: any | null };
                            placeholders: Array<{
                                __typename?: "Placeholder";
                                id: string;
                                x?: number | null;
                                y?: number | null;
                                type: GqlClientPlaceholderType;
                                height?: number | null;
                                width?: number | null;
                                logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
                            }>;
                        }> | null;
                        placeholders?: Array<{
                            __typename?: "Placeholder";
                            id: string;
                            updated: string;
                            x?: number | null;
                            y?: number | null;
                            type: GqlClientPlaceholderType;
                            height?: number | null;
                            width?: number | null;
                            logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
                        }> | null;
                    }>;
                    animationGuidelines: Array<{
                        __typename?: "AnimationGuideline";
                        id: string;
                        updated: string;
                        type: GqlClientAnimationGuidelineType;
                        logic: { __typename?: "SceneEntityLogic"; id: string; name: string; logic?: any | null };
                    }>;
                    narrations: Array<{
                        __typename?: "Narration";
                        id: string;
                        updated: string;
                        representativeKey?: string | null;
                        supportsCreativeDataElementOverride?: boolean | null;
                        mergeData: Array<any>;
                        dimensionData: Array<any>;
                        dtaasPrefixKey?: string | null;
                        lastUsedRecordingFileNumber: number;
                        narrationVariations: Array<{
                            __typename?: "NarrationVariation";
                            id: string;
                            updated: string;
                            variationKey?: string | null;
                            sentence?: string | null;
                            muted?: boolean | null;
                            note?: string | null;
                            overridingCreativeDataElement?: { __typename?: "CreativeDataElement"; id: string } | null;
                        }>;
                    }>;
                }>;
            }>;
            stories: Array<{
                __typename?: "Story";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                activateCaching?: boolean | null;
                displaySceneOrder: Array<string>;
                backgroundAssetType?: GqlClientBackgroundAssetType | null;
                backgroundVideoLogic?: any | null;
                backgroundImageLogic?: any | null;
                backgroundAudioLogic?: any | null;
                ratioAndQualityLogic?: any | null;
                numberOfProducts?: Array<number> | null;
                durationOptions?: Array<number> | null;
                narratorName?: string | null;
                initialDecisionPoint?: { __typename?: "DecisionPoint"; id: string } | null;
                decisionPoints: Array<{ __typename?: "DecisionPoint"; id: string; logic: any }>;
                ratioAndQualityOptions?: Array<{ __typename?: "RationAndQualityOption"; ratio?: string | null; quality?: string | null }> | null;
            }>;
            masterAnimationGuidelines: Array<{ __typename?: "MasterAnimationGuideline"; id: string; updated: string; type?: GqlClientAnimationGuidelineType | null; name: string; logic?: any | null }>;
            feedDataElements: Array<{
                __typename?: "FeedDataElement";
                id: string;
                updated: string;
                created: string;
                valueSet: Array<any>;
                name: string;
                useValueSet?: boolean | null;
                description?: string | null;
                dataType?: GqlClientDataElementType | null;
                status?: GqlClientFeedDataElementStatus | null;
                pii?: boolean | null;
            }>;
            derivedDataElements: Array<{
                __typename?: "DerivedDataElement";
                id: string;
                updated: string;
                created: string;
                name: string;
                description?: string | null;
                dataType?: GqlClientDataElementType | null;
                pii?: boolean | null;
                logic?: any | null;
            }>;
            creativeDataElements: Array<{
                __typename?: "CreativeDataElement";
                id: string;
                updated: string;
                created: string;
                name: string;
                description?: string | null;
                dataType?: GqlClientDataElementType | null;
                pii?: boolean | null;
                valueSet: Array<any>;
                useValueSet?: boolean | null;
                assignedToAllStories?: boolean | null;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
        } | null;
    } | null;
};

export type GqlClientGetNarrationRecordingVersionsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
    narrationRecordingId: Scalars["ID"]["input"];
}>;

export type GqlClientGetNarrationRecordingVersionsQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        programVersion?: {
            __typename?: "ProgramVersion";
            id: string;
            narrationRecording?: {
                __typename?: "NarrationRecording";
                id: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                key: string;
                filename: string;
                text: string;
                rfr: number;
                metadata?: any | null;
                lastVersionNumber: number;
                narrationId: string;
                narrationRecordingVersions: Array<{
                    __typename?: "NarrationRecordingVersion";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    url?: string | null;
                    format?: string | null;
                    location?: string | null;
                    status?: GqlClientNarrationRecordingStatus | null;
                    errorReason?: GqlClientNarrationRecordingErrorReason | null;
                    version: number;
                }>;
                narrationRecordingActiveVersion?: {
                    __typename?: "NarrationRecordingVersion";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    url?: string | null;
                    format?: string | null;
                    location?: string | null;
                    status?: GqlClientNarrationRecordingStatus | null;
                    errorReason?: GqlClientNarrationRecordingErrorReason | null;
                    version: number;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type GqlClientGetNarrationRecordingsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientGetNarrationRecordingsQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        programVersion?: {
            __typename?: "ProgramVersion";
            id: string;
            narrationRecordings: Array<{
                __typename?: "NarrationRecording";
                id: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                key: string;
                filename: string;
                text: string;
                rfr: number;
                metadata?: any | null;
                lastVersionNumber: number;
                narrationId: string;
                narrationRecordingActiveVersion?: {
                    __typename?: "NarrationRecordingVersion";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    url?: string | null;
                    format?: string | null;
                    location?: string | null;
                    status?: GqlClientNarrationRecordingStatus | null;
                    errorReason?: GqlClientNarrationRecordingErrorReason | null;
                    version: number;
                } | null;
            }>;
        } | null;
    } | null;
};

export type GqlClientGetProgramLifecycleHistoryQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientGetProgramLifecycleHistoryQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        accountId: string;
        id: string;
        name: string;
        lifecycleHistory: Array<{
            __typename?: "LifecycleHistoryEntry";
            id: string;
            lifecycleStage?: GqlClientLifecycleStage | null;
            snapshotNumber?: number | null;
            created: string;
            createdBy?: string | null;
        }>;
    } | null;
};

export type GqlClientGetProgramPresetValuesQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
    presetId: Scalars["ID"]["input"];
}>;

export type GqlClientGetProgramPresetValuesQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        accountId: string;
        id: string;
        name: string;
        programVersion?: {
            __typename?: "ProgramVersion";
            id: string;
            preset?: {
                __typename?: "Preset";
                id: string;
                name: string;
                isCreative?: boolean | null;
                updated: string;
                dataElementValues: Array<{ __typename?: "PresetDataElementValue"; id: string; type?: GqlClientDataElementType | null; value?: string | null; valueId?: string | null }>;
                systemElementValues: Array<{
                    __typename?: "PresetSystemElementValue";
                    id: string;
                    systemElementId?: string | null;
                    type?: GqlClientDataElementType | null;
                    value?: string | null;
                    valueId?: string | null;
                }>;
            } | null;
        } | null;
    } | null;
};

export type GqlClientGetProgramPresetsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientGetProgramPresetsQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        accountId: string;
        id: string;
        name: string;
        programVersion?: {
            __typename?: "ProgramVersion";
            id: string;
            presets: Array<{
                __typename?: "Preset";
                id: string;
                name: string;
                isCreative?: boolean | null;
                updated: string;
                dataElementValues: Array<{ __typename?: "PresetDataElementValue"; id: string; type?: GqlClientDataElementType | null; value?: string | null; valueId?: string | null }>;
                systemElementValues: Array<{
                    __typename?: "PresetSystemElementValue";
                    id: string;
                    systemElementId?: string | null;
                    type?: GqlClientDataElementType | null;
                    value?: string | null;
                    valueId?: string | null;
                }>;
            }>;
        } | null;
    } | null;
};

export type GqlClientGetProgramPresetsWithoutValuesQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientGetProgramPresetsWithoutValuesQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        accountId: string;
        id: string;
        name: string;
        programVersion?: { __typename?: "ProgramVersion"; id: string; presets: Array<{ __typename?: "Preset"; id: string; name: string; isCreative?: boolean | null; updated: string }> } | null;
    } | null;
};

export type GqlClientGetProgramVersionSnapshotWithAssetsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientGetProgramVersionSnapshotWithAssetsQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        programVersion?: {
            __typename?: "ProgramVersion";
            id: string;
            snapshotNumber?: number | null;
            snapshotName?: string | null;
            snapshotComment?: string | null;
            snapshotValidationData?: any | null;
            snapshotted?: string | null;
            snapshottedBy?: string | null;
            assets: Array<{
                __typename?: "Asset";
                id: string;
                legacyAssetId: string;
                accountId: string;
                name: string;
                description?: string | null;
                type: GqlClientAssetType;
                created: string;
                anyUpdated?: string | null;
                legacyData?: any | null;
                assetActiveVersion: {
                    __typename?: "AssetVersion";
                    id: string;
                    url?: string | null;
                    thumbnailUrl?: string | null;
                    filename?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                    size?: number | null;
                    version: number;
                    created: string;
                    createdBy?: string | null;
                };
            }>;
            narrators: Array<{
                __typename?: "Narrator";
                id: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                updatedBy?: string | null;
                legacyNarratorId: string;
                name: string;
                carUrl: string;
                dtaasPrefix: string;
                status: GqlClientNarratorStatus;
                decisionTablesLoadTaskId?: string | null;
            }>;
            animations: Array<{
                __typename?: "Animation";
                id: string;
                legacyAnimationId: string;
                name: string;
                description?: string | null;
                updated: string;
                animationActiveVersion: {
                    __typename?: "AnimationVersion";
                    id: string;
                    version: number;
                    created: string;
                    createdBy?: string | null;
                    aeFilename?: string | null;
                    comment?: string | null;
                };
            }>;
            dataTables: Array<{
                __typename?: "DataTable";
                id: string;
                accountId: string;
                legacyDataTableId?: string | null;
                name: string;
                description?: string | null;
                updated: string;
                created: string;
                dtaasPrefix?: string | null;
                location?: string | null;
                schema?: any | null;
                downloadUrl?: string | null;
            }>;
            narrationRecordings: Array<{
                __typename?: "NarrationRecording";
                id: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                key: string;
                filename: string;
                text: string;
                rfr: number;
                metadata?: any | null;
                lastVersionNumber: number;
                narrationId: string;
                narrationRecordingActiveVersion?: {
                    __typename?: "NarrationRecordingVersion";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    url?: string | null;
                    format?: string | null;
                    location?: string | null;
                    status?: GqlClientNarrationRecordingStatus | null;
                    errorReason?: GqlClientNarrationRecordingErrorReason | null;
                    version: number;
                } | null;
            }>;
            parent1ProgramVersion?: { __typename?: "ProgramVersion"; id: string } | null;
        } | null;
    } | null;
};

export type GqlClientGetProgramVersionSnapshotsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientGetProgramVersionSnapshotsQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        programVersionSnapshots: Array<{
            __typename?: "ProgramVersion";
            id: string;
            snapshotNumber?: number | null;
            snapshotName?: string | null;
            snapshotComment?: string | null;
            snapshotValidationData?: any | null;
            snapshotted?: string | null;
            snapshottedBy?: string | null;
            parent1ProgramVersion?: { __typename?: "ProgramVersion"; id: string } | null;
        }>;
    } | null;
};

export type GqlClientGetProgramWithAssetsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    programVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientGetProgramWithAssetsQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        accountId: string;
        id: string;
        name: string;
        description?: string | null;
        updated: string;
        updatedBy?: string | null;
        isLegacy?: boolean | null;
        isEditor?: boolean | null;
        saleForceId?: string | null;
        customAnalyticsOrder: Array<string>;
        legacyBuilderProgramId?: string | null;
        pii?: boolean | null;
        publishTarget: GqlClientLifecycleStage;
        folder?: { __typename?: "AccountProgramFolder"; id: string; name: string } | null;
        programVersion?: {
            __typename?: "ProgramVersion";
            id: string;
            snapshotNumber?: number | null;
            snapshotName?: string | null;
            snapshotComment?: string | null;
            snapshotValidationData?: any | null;
            snapshotted?: string | null;
            snapshottedBy?: string | null;
            transition?: any | null;
            storySelectionLogic?: any | null;
            programType?: GqlClientProgramType | null;
            rfr?: number | null;
            rfrUpdateTime?: string | null;
            narrationsVersion?: number | null;
            creativeVersion?: number | null;
            creativeDfde?: string | null;
            creativeNameLabel?: string | null;
            editorDataConnectorEnabled?: boolean | null;
            editorDataConnectorType?: string | null;
            chapteringEnabled: boolean;
            assets: Array<{
                __typename?: "Asset";
                id: string;
                legacyAssetId: string;
                accountId: string;
                name: string;
                description?: string | null;
                type: GqlClientAssetType;
                created: string;
                anyUpdated?: string | null;
                legacyData?: any | null;
                assetActiveVersion: {
                    __typename?: "AssetVersion";
                    id: string;
                    url?: string | null;
                    thumbnailUrl?: string | null;
                    filename?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                    size?: number | null;
                    version: number;
                    created: string;
                    createdBy?: string | null;
                };
            }>;
            narrators: Array<{
                __typename?: "Narrator";
                id: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                updatedBy?: string | null;
                legacyNarratorId: string;
                name: string;
                carUrl: string;
                dtaasPrefix: string;
                status: GqlClientNarratorStatus;
                decisionTablesLoadTaskId?: string | null;
            }>;
            animations: Array<{
                __typename?: "Animation";
                id: string;
                legacyAnimationId: string;
                name: string;
                description?: string | null;
                updated: string;
                animationActiveVersion: {
                    __typename?: "AnimationVersion";
                    id: string;
                    version: number;
                    created: string;
                    createdBy?: string | null;
                    aeFilename?: string | null;
                    comment?: string | null;
                };
            }>;
            dataTables: Array<{
                __typename?: "DataTable";
                id: string;
                accountId: string;
                legacyDataTableId?: string | null;
                name: string;
                description?: string | null;
                updated: string;
                created: string;
                dtaasPrefix?: string | null;
                location?: string | null;
                schema?: any | null;
                downloadUrl?: string | null;
            }>;
            recordingDocs: Array<{
                __typename?: "RecordingDocEntry";
                id: string;
                audioFileUrl: string;
                audioFilename: string;
                scriptFileUrl: string;
                scriptFilename: string;
                totalRecordingFiles: number;
                created: string;
                createdBy?: string | null;
                folderLocation: string;
            }>;
            narrationRecordings: Array<{
                __typename?: "NarrationRecording";
                id: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                key: string;
                filename: string;
                text: string;
                rfr: number;
                metadata?: any | null;
                lastVersionNumber: number;
                narrationId: string;
                narrationRecordingActiveVersion?: {
                    __typename?: "NarrationRecordingVersion";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    url?: string | null;
                    format?: string | null;
                    location?: string | null;
                    status?: GqlClientNarrationRecordingStatus | null;
                    errorReason?: GqlClientNarrationRecordingErrorReason | null;
                    version: number;
                } | null;
            }>;
        } | null;
    } | null;
};

export type GqlClientGetProgramsDynamoMigrationStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientGetProgramsDynamoMigrationStatusQuery = {
    __typename?: "Query";
    programsDynamoMigrationStatus: Array<{
        __typename?: "ProgramDynamoMigrationStatus";
        id: string;
        programId: string;
        accountId: string;
        updated: string;
        created: string;
        mostEntities: GqlClientDynamoMigrationStatus;
        assets: GqlClientDynamoMigrationStatus;
        animations: GqlClientDynamoMigrationStatus;
        recordingDocs: GqlClientDynamoMigrationStatus;
        narrators: GqlClientDynamoMigrationStatus;
        lifecycleHistory: GqlClientDynamoMigrationStatus;
        dataTables: GqlClientDynamoMigrationStatus;
    }>;
};

export type GqlClientAddLifecycleHistoryEntryMutationVariables = Exact<{
    input: GqlClientAddLifecycleHistoryEntryInput;
}>;

export type GqlClientAddLifecycleHistoryEntryMutation = {
    __typename?: "Mutation";
    addLifecycleHistoryEntry: {
        __typename?: "AddLifecycleHistoryEntryOutput";
        result: GqlClientAddLifecycleHistoryEntryResult;
        lifecycleHistoryEntry?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
    };
};

export type GqlClientConvertFeedDataElementToDerivedMutationVariables = Exact<{
    input: GqlClientConvertFeedDataElementToDerivedInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientConvertFeedDataElementToDerivedMutation = {
    __typename?: "Mutation";
    convertFeedDataElementToDerived:
        | { __typename?: "ConvertFeedDataElementToDerivedOutputConflict"; result: GqlClientConvertFeedDataElementToDerivedResult }
        | {
              __typename?: "ConvertFeedDataElementToDerivedOutputSuccess";
              result: GqlClientConvertFeedDataElementToDerivedResult;
              derivedDataElement?: { __typename?: "DerivedDataElement"; id: string; updated: string; updatedBy?: string | null } | null;
          };
};

export type GqlClientCopyNarrationMutationVariables = Exact<{
    input: GqlClientCopyNarrationInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCopyNarrationMutation = {
    __typename?: "Mutation";
    copyNarration: { __typename?: "CopyNarrationOutput"; result: GqlClientCopyNarrationResult; targetNarration?: { __typename?: "Narration"; id: string; updated: string } | null };
};

export type GqlClientCopyStoryMutationVariables = Exact<{
    input: GqlClientCopyStoryInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCopyStoryMutation = {
    __typename?: "Mutation";
    copyStory: {
        __typename?: "CopyStoryOutput";
        result: GqlClientCopyStoryResult;
        sourceStory?: { __typename?: "Story"; id: string; name: string } | null;
        targetStory?: { __typename?: "Story"; id: string; updated: string; name: string } | null;
    };
};

export type GqlClientCreateCreativeDataElementMutationVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
    input: GqlClientCreateCreativeDataElementInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCreateCreativeDataElementMutation = {
    __typename?: "Mutation";
    createCreativeDataElement: {
        __typename?: "CreateCreativeDataElementOutput";
        result: GqlClientCreateCreativeDataElementResult;
        creativeDataElement?: { __typename?: "CreativeDataElement"; id: string; updated: string; updatedBy?: string | null } | null;
    };
};

export type GqlClientCreateDerivedDataElementMutationVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
    input: GqlClientCreateDerivedDataElementInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCreateDerivedDataElementMutation = {
    __typename?: "Mutation";
    createDerivedDataElement: {
        __typename?: "CreateDerivedDataElementOutput";
        result: GqlClientCreateDerivedDataElementResult;
        derivedDataElement?: { __typename?: "DerivedDataElement"; id: string; updated: string; updatedBy?: string | null } | null;
    };
};

export type GqlClientCreateEditorLifecycleEntryMutationVariables = Exact<{
    input: GqlClientCreateEditorLifecycleEntryInput;
}>;

export type GqlClientCreateEditorLifecycleEntryMutation = {
    __typename?: "Mutation";
    createEditorLifecycleEntry: {
        __typename?: "CreateEditorLifecycleEntryOutput";
        result: GqlClientCreateEditorLifecycleEntryResult;
        lifecycleHistoryEntry?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
    };
};

export type GqlClientCreateFeedDataElementMutationVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
    input: GqlClientCreateFeedDataElementInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCreateFeedDataElementMutation = {
    __typename?: "Mutation";
    createFeedDataElement: {
        __typename?: "CreateFeedDataElementOutput";
        result: GqlClientCreateFeedDataElementResult;
        feedDataElement?: { __typename?: "FeedDataElement"; id: string; updated: string; updatedBy?: string | null } | null;
    };
};

export type GqlClientCreateNarrationMutationVariables = Exact<{
    input: GqlClientCreateNarrationInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCreateNarrationMutation = {
    __typename?: "Mutation";
    createNarration: { __typename?: "CreateNarrationOutput"; result: GqlClientCreateNarrationResult; narration?: { __typename?: "Narration"; id: string; updated: string } | null };
};

export type GqlClientCreateSceneWithScenePartMutationVariables = Exact<{
    input: GqlClientCreateSceneWithScenePartInput;
}>;

export type GqlClientCreateSceneWithScenePartMutation = {
    __typename?: "Mutation";
    createSceneWithScenePart: {
        __typename?: "CreateSceneWithScenePartOutput";
        result: GqlClientCreateSceneWithScenePartResult;
        scene?: { __typename?: "Scene"; id: string; updated: string; name: string; created: string; sceneParts: Array<{ __typename?: "ScenePart"; id: string; updated: string }> } | null;
    };
};

export type GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutationVariables = Exact<{
    input: GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsInput;
    legacyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutation = {
    __typename?: "Mutation";
    createStoryWithDecisionPointAndBackgroundAssets: {
        __typename?: "CreateStoryWithDecisionPointAndBackgroundAssetsOutput";
        result: GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsResult;
        story?: { __typename?: "Story"; id: string; updated: string } | null;
    };
};

export type GqlClientDeleteNarrationMutationVariables = Exact<{
    input: GqlClientDeleteNarrationInput;
}>;

export type GqlClientDeleteNarrationMutation = { __typename?: "Mutation"; deleteNarration: { __typename?: "DeleteNarrationOutput"; result: GqlClientDeleteNarrationResult } };

export type GqlClientUpdateCreativeDataElementMutationVariables = Exact<{
    input: GqlClientUpdateCreativeDataElementInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateCreativeDataElementMutation = {
    __typename?: "Mutation";
    updateCreativeDataElement:
        | { __typename?: "UpdateCreativeDataElementOutputConflict"; result: GqlClientUpdateCreativeDataElementResult }
        | {
              __typename?: "UpdateCreativeDataElementOutputSuccess";
              result: GqlClientUpdateCreativeDataElementResult;
              creativeDataElement?: { __typename?: "CreativeDataElement"; id: string; updated: string; updatedBy?: string | null } | null;
          };
};

export type GqlClientUpdateDerivedDataElementMutationVariables = Exact<{
    input: GqlClientUpdateDerivedDataElementInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateDerivedDataElementMutation = {
    __typename?: "Mutation";
    updateDerivedDataElement:
        | { __typename?: "UpdateDerivedDataElementOutputConflict"; result: GqlClientUpdateDerivedDataElementResult }
        | {
              __typename?: "UpdateDerivedDataElementOutputSuccess";
              result: GqlClientUpdateDerivedDataElementResult;
              derivedDataElement?: { __typename?: "DerivedDataElement"; id: string; updated: string; updatedBy?: string | null } | null;
          };
};

export type GqlClientUpdateFeedDataElementMutationVariables = Exact<{
    input: GqlClientUpdateFeedDataElementInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateFeedDataElementMutation = {
    __typename?: "Mutation";
    updateFeedDataElement:
        | { __typename?: "UpdateFeedDataElementOutputConflict"; result: GqlClientUpdateFeedDataElementResult }
        | {
              __typename?: "UpdateFeedDataElementOutputSuccess";
              result: GqlClientUpdateFeedDataElementResult;
              feedDataElement?: { __typename?: "FeedDataElement"; id: string; updated: string; updatedBy?: string | null } | null;
          };
};

export type GqlClientUpdateNarrationMutationVariables = Exact<{
    input: GqlClientUpdateNarrationInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateNarrationMutation = {
    __typename?: "Mutation";
    updateNarration:
        | { __typename?: "UpdateNarrationOutputConflict"; result: GqlClientUpdateNarrationResult }
        | { __typename?: "UpdateNarrationOutputSuccess"; result: GqlClientUpdateNarrationResult; narration?: { __typename?: "Narration"; id: string; updated: string } | null };
};

export type GqlClientUpdateStoryForBuilderMutationVariables = Exact<{
    input: GqlClientUpdateStoryForBuilderInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateStoryForBuilderMutation = {
    __typename?: "Mutation";
    updateStoryForBuilder:
        | { __typename?: "UpdateStoryForBuilderOutputConflict"; result: GqlClientUpdateStoryForBuilderResult }
        | { __typename?: "UpdateStoryForBuilderOutputSuccess"; result: GqlClientUpdateStoryForBuilderResult; story?: { __typename?: "Story"; id: string; updated: string } | null };
};

export type GqlClientUpdateStoryWithDecisionPointsMutationVariables = Exact<{
    input: GqlClientUpdateStoryWithDecisionPointsInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateStoryWithDecisionPointsMutation = {
    __typename?: "Mutation";
    updateStoryWithDecisionPoints:
        | { __typename?: "UpdateStoryWithDecisionPointsOutputConflict"; result: GqlClientUpdateStoryWithDecisionPointsResult }
        | { __typename?: "UpdateStoryWithDecisionPointsOutputSuccess"; result: GqlClientUpdateStoryWithDecisionPointsResult; story?: { __typename?: "Story"; id: string; updated: string } | null };
};

export type GqlClientUpdateStoryWithNewSceneMutationVariables = Exact<{
    input: GqlClientUpdateStoryWithNewSceneInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
    sceneLegacyId?: InputMaybe<Scalars["String"]["input"]>;
    scenePartLegacyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GqlClientUpdateStoryWithNewSceneMutation = {
    __typename?: "Mutation";
    updateStoryWithNewScene:
        | { __typename?: "UpdateStoryWithNewSceneOutputConflict"; result: GqlClientUpdateStoryWithNewSceneResult }
        | {
              __typename?: "UpdateStoryWithNewSceneOutputSuccess";
              result: GqlClientUpdateStoryWithNewSceneResult;
              story?: { __typename?: "Story"; id: string; updated: string } | null;
              scene?: { __typename?: "Scene"; id: string; created: string; updated: string; sceneParts: Array<{ __typename?: "ScenePart"; id: string; updated: string }> } | null;
          };
};

export type GqlClientAddCreativeLifecycleHistoryEntryMutationVariables = Exact<{
    input: GqlClientAddCreativeLifecycleHistoryEntryInput;
}>;

export type GqlClientAddCreativeLifecycleHistoryEntryMutation = {
    __typename?: "Mutation";
    addCreativeLifecycleHistoryEntry: {
        __typename?: "AddCreativeLifecycleHistoryEntryOutput";
        result: GqlClientAddCreativeLifecycleHistoryEntryResult;
        creativeLifecycleHistoryEntry?: {
            __typename?: "CreativeLifecycleHistoryEntry";
            id: string;
            creative: {
                __typename?: "Creative";
                id: string;
                creativeLifecycleHistory: Array<{
                    __typename?: "CreativeLifecycleHistoryEntry";
                    id: string;
                    snapshotNumber?: number | null;
                    lifecycleStage?: GqlClientLifecycleStage | null;
                    created: string;
                    createdBy?: string | null;
                }>;
            };
        } | null;
    };
};

export type GqlClientCopyCreativeWithDraftMutationVariables = Exact<{
    input: GqlClientCopyCreativeWithDraftInput;
}>;

export type GqlClientCopyCreativeWithDraftMutation = {
    __typename?: "Mutation";
    copyCreativeWithDraft: {
        __typename?: "CopyCreativeWithDraftOutput";
        result: GqlClientCopyCreativeWithDraftResult;
        targetCreative?: {
            __typename?: "Creative";
            id: string;
            program: {
                __typename?: "Program";
                id: string;
                creatives: Array<{
                    __typename?: "Creative";
                    id: string;
                    legacyBuilderCreativeId?: string | null;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    anyUpdated?: string | null;
                    anyUpdatedBy?: string | null;
                    name: string;
                    description?: string | null;
                    creativeVersionDraft?: {
                        __typename?: "CreativeVersion";
                        id: string;
                        description?: string | null;
                        storyId?: string | null;
                        creativeDDEValue?: string | null;
                        updated: string;
                    } | null;
                }>;
            };
        } | null;
    };
};

export type GqlClientCreateCreativeAndDraftMutationVariables = Exact<{
    input: GqlClientCreateCreativeAndDraftInput;
}>;

export type GqlClientCreateCreativeAndDraftMutation = {
    __typename?: "Mutation";
    createCreativeAndDraft: {
        __typename?: "CreateCreativeAndDraftOutput";
        result: GqlClientCreateCreativeAndDraftResult;
        creative?: {
            __typename?: "Creative";
            id: string;
            program: {
                __typename?: "Program";
                id: string;
                creatives: Array<{
                    __typename?: "Creative";
                    id: string;
                    legacyBuilderCreativeId?: string | null;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    anyUpdated?: string | null;
                    anyUpdatedBy?: string | null;
                    name: string;
                    description?: string | null;
                    creativeVersionDraft?: {
                        __typename?: "CreativeVersion";
                        id: string;
                        description?: string | null;
                        storyId?: string | null;
                        creativeDDEValue?: string | null;
                        updated: string;
                    } | null;
                }>;
            };
        } | null;
    };
};

export type GqlClientCreativeInfoFragment = {
    __typename?: "Creative";
    id: string;
    legacyBuilderCreativeId?: string | null;
    created: string;
    createdBy?: string | null;
    updated: string;
    anyUpdated?: string | null;
    anyUpdatedBy?: string | null;
    name: string;
    description?: string | null;
    creativeVersionDraft?: { __typename?: "CreativeVersion"; id: string; description?: string | null; storyId?: string | null; creativeDDEValue?: string | null; updated: string } | null;
};

export type GqlClientCreativeLifecycleHistoryFragment = {
    __typename?: "CreativeLifecycleHistoryEntry";
    id: string;
    snapshotNumber?: number | null;
    lifecycleStage?: GqlClientLifecycleStage | null;
    created: string;
    createdBy?: string | null;
};

export type GqlClientCreativeVersionInfoFragment = {
    __typename?: "CreativeVersion";
    id: string;
    description?: string | null;
    snapshotNumber?: number | null;
    name: string;
    validationData?: any | null;
    updated: string;
    updatedBy?: string | null;
};

export type GqlClientDeleteCreativesMutationVariables = Exact<{
    input: GqlClientDeleteCreativesInput;
}>;

export type GqlClientDeleteCreativesMutation = {
    __typename?: "Mutation";
    deleteCreatives: {
        __typename?: "DeleteCreativesOutput";
        result: GqlClientDeleteCreativesResult;
        program?: {
            __typename?: "Program";
            id: string;
            creatives: Array<{
                __typename?: "Creative";
                id: string;
                legacyBuilderCreativeId?: string | null;
                created: string;
                createdBy?: string | null;
                updated: string;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                name: string;
                description?: string | null;
                creativeVersionDraft?: { __typename?: "CreativeVersion"; id: string; description?: string | null; storyId?: string | null; creativeDDEValue?: string | null; updated: string } | null;
            }>;
        } | null;
    };
};

export type GqlClientGetAllProgramCreativesQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientGetAllProgramCreativesQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        creatives: Array<{
            __typename?: "Creative";
            id: string;
            legacyBuilderCreativeId?: string | null;
            created: string;
            createdBy?: string | null;
            updated: string;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            name: string;
            description?: string | null;
            creativeVersionDraft?: { __typename?: "CreativeVersion"; id: string; description?: string | null; storyId?: string | null; creativeDDEValue?: string | null; updated: string } | null;
        }>;
    } | null;
};

export type GqlClientGetCreativeSnapshotsAndLifecycleQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    creativeId: Scalars["ID"]["input"];
}>;

export type GqlClientGetCreativeSnapshotsAndLifecycleQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        creative?: {
            __typename?: "Creative";
            id: string;
            creativeVersions: Array<{
                __typename?: "CreativeVersion";
                id: string;
                description?: string | null;
                snapshotNumber?: number | null;
                name: string;
                validationData?: any | null;
                updated: string;
                updatedBy?: string | null;
            }>;
            creativeLifecycleHistory: Array<{
                __typename?: "CreativeLifecycleHistoryEntry";
                id: string;
                snapshotNumber?: number | null;
                lifecycleStage?: GqlClientLifecycleStage | null;
                created: string;
                createdBy?: string | null;
            }>;
        } | null;
    } | null;
};

export type GqlClientUpdateCreativeMutationVariables = Exact<{
    input: GqlClientUpdateCreativeInput;
}>;

export type GqlClientUpdateCreativeMutation = {
    __typename?: "Mutation";
    updateCreative:
        | { __typename?: "UpdateCreativeOutputConflict"; result: GqlClientUpdateCreativeResult }
        | {
              __typename?: "UpdateCreativeOutputSuccess";
              result: GqlClientUpdateCreativeResult;
              creative?: {
                  __typename?: "Creative";
                  id: string;
                  legacyBuilderCreativeId?: string | null;
                  created: string;
                  createdBy?: string | null;
                  updated: string;
                  anyUpdated?: string | null;
                  anyUpdatedBy?: string | null;
                  name: string;
                  description?: string | null;
                  creativeVersionDraft?: { __typename?: "CreativeVersion"; id: string; description?: string | null; storyId?: string | null; creativeDDEValue?: string | null; updated: string } | null;
              } | null;
          };
};

export type GqlClientCreateCreativeAssetWithVersionMutationVariables = Exact<{
    input: GqlClientCreateCreativeAssetWithVersionInput;
}>;

export type GqlClientCreateCreativeAssetWithVersionMutation = {
    __typename?: "Mutation";
    createCreativeAssetWithVersion: {
        __typename?: "CreateCreativeAssetWithVersionOutput";
        result: GqlClientCreateCreativeAssetWithVersionResult;
        creativeAsset?: {
            __typename?: "CreativeAsset";
            id: string;
            name: string;
            creativeVersion: {
                __typename?: "CreativeVersion";
                id: string;
                updated: string;
                rfr: number;
                creativeData?: any | null;
                creativeAssets: Array<{
                    __typename?: "CreativeAsset";
                    id: string;
                    name: string;
                    creativeAssetActiveVersion: {
                        __typename?: "CreativeAssetVersion";
                        id: string;
                        filename: string;
                        location: string;
                        thumbnailLocation?: string | null;
                        mediaType: string;
                        url: string;
                        thumbnailUrl?: string | null;
                        width?: number | null;
                        height?: number | null;
                    };
                }>;
                creativeNarrationRecordings: Array<{
                    __typename?: "CreativeNarrationRecording";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    narrationText: string;
                    cdeId: string;
                    rfr: number;
                    filename?: string | null;
                    mockNarrationUrl: string;
                    creativeNarrationRecordingActiveVersion?: {
                        __typename?: "CreativeNarrationRecordingVersion";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        updatedBy?: string | null;
                        status?: GqlClientNarrationRecordingStatus | null;
                        errorReason?: GqlClientNarrationRecordingErrorReason | null;
                        format: string;
                        location: string;
                        mediaType: string;
                        mediaSubType: string;
                        url: string;
                        size: number;
                    } | null;
                    creativeNarrationRecordingVersions: Array<{
                        __typename?: "CreativeNarrationRecordingVersion";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        updatedBy?: string | null;
                        status?: GqlClientNarrationRecordingStatus | null;
                        errorReason?: GqlClientNarrationRecordingErrorReason | null;
                        format: string;
                        location: string;
                        mediaType: string;
                        mediaSubType: string;
                        url: string;
                        size: number;
                    }>;
                }>;
            };
            creativeAssetActiveVersion: {
                __typename?: "CreativeAssetVersion";
                id: string;
                filename: string;
                location: string;
                thumbnailLocation?: string | null;
                mediaType: string;
                url: string;
                thumbnailUrl?: string | null;
                width?: number | null;
                height?: number | null;
            };
        } | null;
    };
};

export type GqlClientCreativeAssetFragment = {
    __typename?: "CreativeAsset";
    id: string;
    name: string;
    creativeAssetActiveVersion: {
        __typename?: "CreativeAssetVersion";
        id: string;
        filename: string;
        location: string;
        thumbnailLocation?: string | null;
        mediaType: string;
        url: string;
        thumbnailUrl?: string | null;
        width?: number | null;
        height?: number | null;
    };
};

export type GqlClientCreateCreativeAssetVersionMutationVariables = Exact<{
    input: GqlClientCreateCreativeAssetVersionInput;
}>;

export type GqlClientCreateCreativeAssetVersionMutation = {
    __typename?: "Mutation";
    createCreativeAssetVersion: {
        __typename?: "CreateCreativeAssetVersionOutput";
        result: GqlClientCreateCreativeAssetVersionResult;
        creativeAssetVersion?: {
            __typename?: "CreativeAssetVersion";
            id: string;
            creativeAsset: {
                __typename?: "CreativeAsset";
                id: string;
                name: string;
                creativeAssetActiveVersion: {
                    __typename?: "CreativeAssetVersion";
                    id: string;
                    filename: string;
                    location: string;
                    thumbnailLocation?: string | null;
                    mediaType: string;
                    url: string;
                    thumbnailUrl?: string | null;
                    width?: number | null;
                    height?: number | null;
                };
            };
        } | null;
    };
};

export type GqlClientCreativeAssetVersionFragment = {
    __typename?: "CreativeAssetVersion";
    id: string;
    filename: string;
    location: string;
    thumbnailLocation?: string | null;
    mediaType: string;
    url: string;
    thumbnailUrl?: string | null;
    width?: number | null;
    height?: number | null;
};

export type GqlClientCreativeNarrationRecordingFragment = {
    __typename?: "CreativeNarrationRecording";
    id: string;
    created: string;
    createdBy?: string | null;
    updated: string;
    updatedBy?: string | null;
    narrationText: string;
    cdeId: string;
    rfr: number;
    filename?: string | null;
    mockNarrationUrl: string;
    creativeNarrationRecordingActiveVersion?: {
        __typename?: "CreativeNarrationRecordingVersion";
        id: string;
        created: string;
        createdBy?: string | null;
        updated: string;
        updatedBy?: string | null;
        status?: GqlClientNarrationRecordingStatus | null;
        errorReason?: GqlClientNarrationRecordingErrorReason | null;
        format: string;
        location: string;
        mediaType: string;
        mediaSubType: string;
        url: string;
        size: number;
    } | null;
    creativeNarrationRecordingVersions: Array<{
        __typename?: "CreativeNarrationRecordingVersion";
        id: string;
        created: string;
        createdBy?: string | null;
        updated: string;
        updatedBy?: string | null;
        status?: GqlClientNarrationRecordingStatus | null;
        errorReason?: GqlClientNarrationRecordingErrorReason | null;
        format: string;
        location: string;
        mediaType: string;
        mediaSubType: string;
        url: string;
        size: number;
    }>;
};

export type GqlClientGetAllCreativesNarrationRecordingsQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientGetAllCreativesNarrationRecordingsQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        creatives: Array<{
            __typename?: "Creative";
            id: string;
            name: string;
            updated: string;
            createdBy?: string | null;
            creativeVersionDraft?: {
                __typename?: "CreativeVersion";
                id: string;
                rfr: number;
                storyId?: string | null;
                creativeNarrationRecordings: Array<{
                    __typename?: "CreativeNarrationRecording";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    narrationText: string;
                    cdeId: string;
                    rfr: number;
                    filename?: string | null;
                    mockNarrationUrl: string;
                    creativeNarrationRecordingActiveVersion?: {
                        __typename?: "CreativeNarrationRecordingVersion";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        updatedBy?: string | null;
                        status?: GqlClientNarrationRecordingStatus | null;
                        errorReason?: GqlClientNarrationRecordingErrorReason | null;
                        format: string;
                        location: string;
                        mediaType: string;
                        mediaSubType: string;
                        url: string;
                        size: number;
                    } | null;
                    creativeNarrationRecordingVersions: Array<{
                        __typename?: "CreativeNarrationRecordingVersion";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        updatedBy?: string | null;
                        status?: GqlClientNarrationRecordingStatus | null;
                        errorReason?: GqlClientNarrationRecordingErrorReason | null;
                        format: string;
                        location: string;
                        mediaType: string;
                        mediaSubType: string;
                        url: string;
                        size: number;
                    }>;
                }>;
            } | null;
        }>;
    } | null;
};

export type GqlClientGetCreativeNarrationRecordingsQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
    creativeId: Scalars["ID"]["input"];
}>;

export type GqlClientGetCreativeNarrationRecordingsQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        creative?: {
            __typename?: "Creative";
            id: string;
            name: string;
            creativeVersionDraft?: {
                __typename?: "CreativeVersion";
                id: string;
                rfr: number;
                creativeNarrationRecordings: Array<{
                    __typename?: "CreativeNarrationRecording";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    narrationText: string;
                    cdeId: string;
                    rfr: number;
                    filename?: string | null;
                    mockNarrationUrl: string;
                    creativeNarrationRecordingActiveVersion?: {
                        __typename?: "CreativeNarrationRecordingVersion";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        updatedBy?: string | null;
                        status?: GqlClientNarrationRecordingStatus | null;
                        errorReason?: GqlClientNarrationRecordingErrorReason | null;
                        format: string;
                        location: string;
                        mediaType: string;
                        mediaSubType: string;
                        url: string;
                        size: number;
                    } | null;
                    creativeNarrationRecordingVersions: Array<{
                        __typename?: "CreativeNarrationRecordingVersion";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        updatedBy?: string | null;
                        status?: GqlClientNarrationRecordingStatus | null;
                        errorReason?: GqlClientNarrationRecordingErrorReason | null;
                        format: string;
                        location: string;
                        mediaType: string;
                        mediaSubType: string;
                        url: string;
                        size: number;
                    }>;
                }>;
            } | null;
        } | null;
    } | null;
};

export type GqlClientUpdateCreativeNarrationRecordingMutationVariables = Exact<{
    input: GqlClientUpdateCreativeNarrationRecordingInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateCreativeNarrationRecordingMutation = {
    __typename?: "Mutation";
    updateCreativeNarrationRecording:
        | { __typename?: "UpdateCreativeNarrationRecordingOutputConflict"; result: GqlClientUpdateCreativeNarrationRecordingResult }
        | {
              __typename?: "UpdateCreativeNarrationRecordingOutputSuccess";
              result: GqlClientUpdateCreativeNarrationRecordingResult;
              creativeNarrationRecording?: {
                  __typename?: "CreativeNarrationRecording";
                  id: string;
                  created: string;
                  createdBy?: string | null;
                  updated: string;
                  updatedBy?: string | null;
                  narrationText: string;
                  cdeId: string;
                  rfr: number;
                  filename?: string | null;
                  mockNarrationUrl: string;
                  creativeNarrationRecordingActiveVersion?: {
                      __typename?: "CreativeNarrationRecordingVersion";
                      id: string;
                      created: string;
                      createdBy?: string | null;
                      updated: string;
                      updatedBy?: string | null;
                      status?: GqlClientNarrationRecordingStatus | null;
                      errorReason?: GqlClientNarrationRecordingErrorReason | null;
                      format: string;
                      location: string;
                      mediaType: string;
                      mediaSubType: string;
                      url: string;
                      size: number;
                  } | null;
                  creativeNarrationRecordingVersions: Array<{
                      __typename?: "CreativeNarrationRecordingVersion";
                      id: string;
                      created: string;
                      createdBy?: string | null;
                      updated: string;
                      updatedBy?: string | null;
                      status?: GqlClientNarrationRecordingStatus | null;
                      errorReason?: GqlClientNarrationRecordingErrorReason | null;
                      format: string;
                      location: string;
                      mediaType: string;
                      mediaSubType: string;
                      url: string;
                      size: number;
                  }>;
              } | null;
          };
};

export type GqlClientCreateCreativeNarrationRecordingVersionMutationVariables = Exact<{
    input: GqlClientCreateCreativeNarrationRecordingVersionInput;
}>;

export type GqlClientCreateCreativeNarrationRecordingVersionMutation = {
    __typename?: "Mutation";
    createCreativeNarrationRecordingVersion: {
        __typename?: "CreateCreativeNarrationRecordingVersionOutput";
        result: GqlClientCreateCreativeNarrationRecordingVersionResult;
        creativeNarrationRecordingVersion?: {
            __typename?: "CreativeNarrationRecordingVersion";
            id: string;
            creativeNarrationRecording: {
                __typename?: "CreativeNarrationRecording";
                id: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                updatedBy?: string | null;
                narrationText: string;
                cdeId: string;
                rfr: number;
                filename?: string | null;
                mockNarrationUrl: string;
                creativeNarrationRecordingActiveVersion?: {
                    __typename?: "CreativeNarrationRecordingVersion";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    status?: GqlClientNarrationRecordingStatus | null;
                    errorReason?: GqlClientNarrationRecordingErrorReason | null;
                    format: string;
                    location: string;
                    mediaType: string;
                    mediaSubType: string;
                    url: string;
                    size: number;
                } | null;
                creativeNarrationRecordingVersions: Array<{
                    __typename?: "CreativeNarrationRecordingVersion";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    status?: GqlClientNarrationRecordingStatus | null;
                    errorReason?: GqlClientNarrationRecordingErrorReason | null;
                    format: string;
                    location: string;
                    mediaType: string;
                    mediaSubType: string;
                    url: string;
                    size: number;
                }>;
            };
        } | null;
    };
};

export type GqlClientCreativeNarrationRecordingVersionFragment = {
    __typename?: "CreativeNarrationRecordingVersion";
    id: string;
    created: string;
    createdBy?: string | null;
    updated: string;
    updatedBy?: string | null;
    status?: GqlClientNarrationRecordingStatus | null;
    errorReason?: GqlClientNarrationRecordingErrorReason | null;
    format: string;
    location: string;
    mediaType: string;
    mediaSubType: string;
    url: string;
    size: number;
};

export type GqlClientUpdateCreativeNarrationRecordingVersionMutationVariables = Exact<{
    input: GqlClientUpdateCreativeNarrationRecordingVersionInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateCreativeNarrationRecordingVersionMutation = {
    __typename?: "Mutation";
    updateCreativeNarrationRecordingVersion:
        | { __typename?: "UpdateCreativeNarrationRecordingVersionOutputConflict"; result: GqlClientUpdateCreativeNarrationRecordingVersionResult }
        | {
              __typename?: "UpdateCreativeNarrationRecordingVersionOutputSuccess";
              result: GqlClientUpdateCreativeNarrationRecordingVersionResult;
              creativeNarrationRecordingVersion?: {
                  __typename?: "CreativeNarrationRecordingVersion";
                  id: string;
                  creativeNarrationRecording: {
                      __typename?: "CreativeNarrationRecording";
                      id: string;
                      created: string;
                      createdBy?: string | null;
                      updated: string;
                      updatedBy?: string | null;
                      narrationText: string;
                      cdeId: string;
                      rfr: number;
                      filename?: string | null;
                      mockNarrationUrl: string;
                      creativeNarrationRecordingActiveVersion?: {
                          __typename?: "CreativeNarrationRecordingVersion";
                          id: string;
                          created: string;
                          createdBy?: string | null;
                          updated: string;
                          updatedBy?: string | null;
                          status?: GqlClientNarrationRecordingStatus | null;
                          errorReason?: GqlClientNarrationRecordingErrorReason | null;
                          format: string;
                          location: string;
                          mediaType: string;
                          mediaSubType: string;
                          url: string;
                          size: number;
                      } | null;
                      creativeNarrationRecordingVersions: Array<{
                          __typename?: "CreativeNarrationRecordingVersion";
                          id: string;
                          created: string;
                          createdBy?: string | null;
                          updated: string;
                          updatedBy?: string | null;
                          status?: GqlClientNarrationRecordingStatus | null;
                          errorReason?: GqlClientNarrationRecordingErrorReason | null;
                          format: string;
                          location: string;
                          mediaType: string;
                          mediaSubType: string;
                          url: string;
                          size: number;
                      }>;
                  };
              } | null;
          };
};

export type GqlClientCopyCreativeVersionDraftMutationVariables = Exact<{
    input: GqlClientCopyCreativeVersionDraftInput;
}>;

export type GqlClientCopyCreativeVersionDraftMutation = {
    __typename?: "Mutation";
    copyCreativeVersionDraft: {
        __typename?: "CopyCreativeVersionDraftOutput";
        result: GqlClientCopyCreativeVersionDraftResult;
        targetCreativeVersion?: {
            __typename?: "CreativeVersion";
            id: string;
            snapshotNumber?: number | null;
            creative: {
                __typename?: "Creative";
                id: string;
                legacyBuilderCreativeId?: string | null;
                created: string;
                createdBy?: string | null;
                updated: string;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                name: string;
                description?: string | null;
                creativeVersions: Array<{
                    __typename?: "CreativeVersion";
                    id: string;
                    description?: string | null;
                    snapshotNumber?: number | null;
                    name: string;
                    validationData?: any | null;
                    updated: string;
                    updatedBy?: string | null;
                }>;
                creativeVersionDraft?: { __typename?: "CreativeVersion"; id: string; description?: string | null; storyId?: string | null; creativeDDEValue?: string | null; updated: string } | null;
            };
        } | null;
    };
};

export type GqlClientCreativeDraftInfoFragment = {
    __typename?: "CreativeVersion";
    id: string;
    description?: string | null;
    storyId?: string | null;
    creativeDDEValue?: string | null;
    updated: string;
};

export type GqlClientCreativeVersionFragment = {
    __typename?: "CreativeVersion";
    id: string;
    updated: string;
    rfr: number;
    creativeData?: any | null;
    creativeAssets: Array<{
        __typename?: "CreativeAsset";
        id: string;
        name: string;
        creativeAssetActiveVersion: {
            __typename?: "CreativeAssetVersion";
            id: string;
            filename: string;
            location: string;
            thumbnailLocation?: string | null;
            mediaType: string;
            url: string;
            thumbnailUrl?: string | null;
            width?: number | null;
            height?: number | null;
        };
    }>;
    creativeNarrationRecordings: Array<{
        __typename?: "CreativeNarrationRecording";
        id: string;
        created: string;
        createdBy?: string | null;
        updated: string;
        updatedBy?: string | null;
        narrationText: string;
        cdeId: string;
        rfr: number;
        filename?: string | null;
        mockNarrationUrl: string;
        creativeNarrationRecordingActiveVersion?: {
            __typename?: "CreativeNarrationRecordingVersion";
            id: string;
            created: string;
            createdBy?: string | null;
            updated: string;
            updatedBy?: string | null;
            status?: GqlClientNarrationRecordingStatus | null;
            errorReason?: GqlClientNarrationRecordingErrorReason | null;
            format: string;
            location: string;
            mediaType: string;
            mediaSubType: string;
            url: string;
            size: number;
        } | null;
        creativeNarrationRecordingVersions: Array<{
            __typename?: "CreativeNarrationRecordingVersion";
            id: string;
            created: string;
            createdBy?: string | null;
            updated: string;
            updatedBy?: string | null;
            status?: GqlClientNarrationRecordingStatus | null;
            errorReason?: GqlClientNarrationRecordingErrorReason | null;
            format: string;
            location: string;
            mediaType: string;
            mediaSubType: string;
            url: string;
            size: number;
        }>;
    }>;
};

export type GqlClientGetCreativeVersionQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    creativeId: Scalars["ID"]["input"];
    creativeVersionId: Scalars["ID"]["input"];
}>;

export type GqlClientGetCreativeVersionQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        creative?: {
            __typename?: "Creative";
            name: string;
            id: string;
            updated: string;
            creativeVersion?: {
                __typename?: "CreativeVersion";
                id: string;
                updated: string;
                rfr: number;
                creativeData?: any | null;
                creativeAssets: Array<{
                    __typename?: "CreativeAsset";
                    id: string;
                    name: string;
                    creativeAssetActiveVersion: {
                        __typename?: "CreativeAssetVersion";
                        id: string;
                        filename: string;
                        location: string;
                        thumbnailLocation?: string | null;
                        mediaType: string;
                        url: string;
                        thumbnailUrl?: string | null;
                        width?: number | null;
                        height?: number | null;
                    };
                }>;
                creativeNarrationRecordings: Array<{
                    __typename?: "CreativeNarrationRecording";
                    id: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    narrationText: string;
                    cdeId: string;
                    rfr: number;
                    filename?: string | null;
                    mockNarrationUrl: string;
                    creativeNarrationRecordingActiveVersion?: {
                        __typename?: "CreativeNarrationRecordingVersion";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        updatedBy?: string | null;
                        status?: GqlClientNarrationRecordingStatus | null;
                        errorReason?: GqlClientNarrationRecordingErrorReason | null;
                        format: string;
                        location: string;
                        mediaType: string;
                        mediaSubType: string;
                        url: string;
                        size: number;
                    } | null;
                    creativeNarrationRecordingVersions: Array<{
                        __typename?: "CreativeNarrationRecordingVersion";
                        id: string;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        updatedBy?: string | null;
                        status?: GqlClientNarrationRecordingStatus | null;
                        errorReason?: GqlClientNarrationRecordingErrorReason | null;
                        format: string;
                        location: string;
                        mediaType: string;
                        mediaSubType: string;
                        url: string;
                        size: number;
                    }>;
                }>;
            } | null;
        } | null;
    } | null;
};

export type GqlClientUpdateCreativeDraftMutationVariables = Exact<{
    input: GqlClientUpdateCreativeDraftInput;
}>;

export type GqlClientUpdateCreativeDraftMutation = {
    __typename?: "Mutation";
    updateCreativeDraft:
        | { __typename?: "UpdateCreativeDraftOutputConflict"; result: GqlClientUpdateCreativeDraftResult }
        | {
              __typename?: "UpdateCreativeDraftOutputSuccess";
              result: GqlClientUpdateCreativeDraftResult;
              creativeDraft?: {
                  __typename?: "CreativeVersion";
                  id: string;
                  updated: string;
                  rfr: number;
                  creativeData?: any | null;
                  creativeAssets: Array<{
                      __typename?: "CreativeAsset";
                      id: string;
                      name: string;
                      creativeAssetActiveVersion: {
                          __typename?: "CreativeAssetVersion";
                          id: string;
                          filename: string;
                          location: string;
                          thumbnailLocation?: string | null;
                          mediaType: string;
                          url: string;
                          thumbnailUrl?: string | null;
                          width?: number | null;
                          height?: number | null;
                      };
                  }>;
                  creativeNarrationRecordings: Array<{
                      __typename?: "CreativeNarrationRecording";
                      id: string;
                      created: string;
                      createdBy?: string | null;
                      updated: string;
                      updatedBy?: string | null;
                      narrationText: string;
                      cdeId: string;
                      rfr: number;
                      filename?: string | null;
                      mockNarrationUrl: string;
                      creativeNarrationRecordingActiveVersion?: {
                          __typename?: "CreativeNarrationRecordingVersion";
                          id: string;
                          created: string;
                          createdBy?: string | null;
                          updated: string;
                          updatedBy?: string | null;
                          status?: GqlClientNarrationRecordingStatus | null;
                          errorReason?: GqlClientNarrationRecordingErrorReason | null;
                          format: string;
                          location: string;
                          mediaType: string;
                          mediaSubType: string;
                          url: string;
                          size: number;
                      } | null;
                      creativeNarrationRecordingVersions: Array<{
                          __typename?: "CreativeNarrationRecordingVersion";
                          id: string;
                          created: string;
                          createdBy?: string | null;
                          updated: string;
                          updatedBy?: string | null;
                          status?: GqlClientNarrationRecordingStatus | null;
                          errorReason?: GqlClientNarrationRecordingErrorReason | null;
                          format: string;
                          location: string;
                          mediaType: string;
                          mediaSubType: string;
                          url: string;
                          size: number;
                      }>;
                  }>;
              } | null;
          };
};

export type GqlClientUpdateCreativeDraftDefinitionsMutationVariables = Exact<{
    input: GqlClientUpdateCreativeDraftDefinitionsInput;
}>;

export type GqlClientUpdateCreativeDraftDefinitionsMutation = {
    __typename?: "Mutation";
    updateCreativeDraftDefinitions:
        | { __typename?: "UpdateCreativeDraftDefinitionsOutputConflict"; result: GqlClientUpdateCreativeDraftDefinitionsResult }
        | {
              __typename?: "UpdateCreativeDraftDefinitionsOutputSuccess";
              result: GqlClientUpdateCreativeDraftDefinitionsResult;
              creativeDraft?: { __typename?: "CreativeVersion"; id: string; description?: string | null; storyId?: string | null; creativeDDEValue?: string | null; updated: string } | null;
          };
};

export type GqlClientCreateExperimentMutationVariables = Exact<{
    input: GqlClientCreateExperimentInput;
}>;

export type GqlClientCreateExperimentMutation = {
    __typename?: "Mutation";
    createExperiment: {
        __typename?: "CreateExperimentOutput";
        result: GqlClientCreateExperimentResult;
        program?: {
            __typename?: "Program";
            id: string;
            experiments: Array<{
                __typename?: "Experiment";
                id: string;
                experimentName: string;
                experimentComponent: string;
                experimentType: string;
                description?: string | null;
                startTime: number;
                endTime?: number | null;
                enabled: boolean;
                metric: string;
                created: number;
                createdBy?: string | null;
                updated: number;
                updatedBy?: string | null;
                variants: Array<{ __typename?: "ExperimentVariant"; variantId: string; variantName: string; fraction: number }>;
            }>;
        } | null;
    };
};

export type GqlClientExperimentFragment = {
    __typename?: "Experiment";
    id: string;
    experimentName: string;
    experimentComponent: string;
    experimentType: string;
    description?: string | null;
    startTime: number;
    endTime?: number | null;
    enabled: boolean;
    metric: string;
    created: number;
    createdBy?: string | null;
    updated: number;
    updatedBy?: string | null;
    variants: Array<{ __typename?: "ExperimentVariant"; variantId: string; variantName: string; fraction: number }>;
};

export type GqlClientGetExperimentsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientGetExperimentsQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        experiments: Array<{
            __typename?: "Experiment";
            id: string;
            experimentName: string;
            experimentComponent: string;
            experimentType: string;
            description?: string | null;
            startTime: number;
            endTime?: number | null;
            enabled: boolean;
            metric: string;
            created: number;
            createdBy?: string | null;
            updated: number;
            updatedBy?: string | null;
            variants: Array<{ __typename?: "ExperimentVariant"; variantId: string; variantName: string; fraction: number }>;
        }>;
    } | null;
};

export type GqlClientUpdateExperimentMutationVariables = Exact<{
    input: GqlClientUpdateExperimentInput;
}>;

export type GqlClientUpdateExperimentMutation = {
    __typename?: "Mutation";
    updateExperiment: {
        __typename?: "UpdateExperimentOutput";
        result: GqlClientUpdateExperimentResult;
        experiment?: {
            __typename?: "Experiment";
            id: string;
            experimentName: string;
            experimentComponent: string;
            experimentType: string;
            description?: string | null;
            startTime: number;
            endTime?: number | null;
            enabled: boolean;
            metric: string;
            created: number;
            createdBy?: string | null;
            updated: number;
            updatedBy?: string | null;
            variants: Array<{ __typename?: "ExperimentVariant"; variantId: string; variantName: string; fraction: number }>;
        } | null;
    };
};

export type GqlClientAudienceFragment = {
    __typename: "Audience";
    id: string;
    updated: string;
    name: string;
    description?: string | null;
    impactsVolume?: boolean | null;
    audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
    audienceValues: Array<{
        __typename: "AudienceValue";
        id: string;
        updated: string;
        name: string;
        volume?: number | null;
        audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
        subAudiences: Array<{
            __typename: "Audience";
            id: string;
            updated: string;
            name: string;
            audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
            audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
        }>;
        audiencePersonalizationValues: Array<{
            __typename?: "AudiencePersonalizationValue";
            id: string;
            description?: string | null;
            audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
        }>;
    }>;
    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
};

export type GqlClientAudienceSupportersFragment = {
    __typename?: "Scene";
    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
    stories: Array<{ __typename?: "Story"; id: string }>;
};

export type GqlClientCreateAudienceMutationVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
    input: GqlClientCreateAudienceWithDataElementsInput;
}>;

export type GqlClientCreateAudienceMutation = {
    __typename?: "Mutation";
    createAudienceWithDataElements: {
        __typename?: "CreateAudienceOutput";
        result: GqlClientCreateAudienceResult;
        audience?: {
            __typename: "Audience";
            id: string;
            updated: string;
            name: string;
            description?: string | null;
            impactsVolume?: boolean | null;
            programVersion: {
                __typename: "ProgramVersion";
                sceneOrder: Array<string>;
                storyOrder: Array<string>;
                id: string;
                name: string;
                updated: string;
                updatedBy?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                useCase?: string | null;
                audienceSize?: string | null;
                industry?: string | null;
                description?: string | null;
                goals: Array<{
                    __typename: "Goal";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    priority?: number | null;
                    kpis: Array<{
                        __typename: "KPI";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        objective?: string | null;
                        scenes: Array<{ __typename?: "Scene"; id: string }>;
                        goal: { __typename?: "Goal"; id: string };
                    }>;
                }>;
                stories: Array<{
                    __typename: "Story";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    displaySceneOrder: Array<string>;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                    channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                }>;
                scenes: Array<{
                    __typename: "Scene";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                    personalizations: Array<
                        | {
                              __typename: "AudiencePersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  updated: string;
                                  description?: string | null;
                                  audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                              }>;
                          }
                        | {
                              __typename: "ChannelPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                          }
                        | {
                              __typename: "TouchpointPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                          }
                    >;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                audiences: Array<{
                    __typename: "Audience";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    impactsVolume?: boolean | null;
                    audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                    audienceValues: Array<{
                        __typename: "AudienceValue";
                        id: string;
                        updated: string;
                        name: string;
                        volume?: number | null;
                        audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                        subAudiences: Array<{
                            __typename: "Audience";
                            id: string;
                            updated: string;
                            name: string;
                            audienceValues: Array<{
                                __typename: "AudienceValue";
                                id: string;
                                updated: string;
                                name: string;
                                volume?: number | null;
                                audience: { __typename?: "Audience"; id: string };
                            }>;
                            audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                        }>;
                        audiencePersonalizationValues: Array<{
                            __typename?: "AudiencePersonalizationValue";
                            id: string;
                            description?: string | null;
                            audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                        }>;
                    }>;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                }>;
                channels: Array<{
                    __typename: "Channel";
                    id: string;
                    updated: string;
                    name: string;
                    platforms?: Array<string> | null;
                    status?: string | null;
                    channelPersonalizations: Array<{
                        __typename?: "ChannelPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                touchpoints: Array<{
                    __typename: "Touchpoint";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    touchpointPersonalizations: Array<{
                        __typename?: "TouchpointPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                feedDataElements: Array<{
                    __typename: "FeedDataElement";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    source?: string | null;
                    status?: GqlClientFeedDataElementStatus | null;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                }>;
            };
            audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
            audienceValues: Array<{
                __typename: "AudienceValue";
                id: string;
                updated: string;
                name: string;
                volume?: number | null;
                audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                subAudiences: Array<{
                    __typename: "Audience";
                    id: string;
                    updated: string;
                    name: string;
                    audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                    audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                }>;
                audiencePersonalizationValues: Array<{
                    __typename?: "AudiencePersonalizationValue";
                    id: string;
                    description?: string | null;
                    audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                }>;
            }>;
            feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
        } | null;
    };
};

export type GqlClientDeleteAudienceMutationVariables = Exact<{
    input: GqlClientDeleteAudienceInput;
}>;

export type GqlClientDeleteAudienceMutation = {
    __typename?: "Mutation";
    deleteAudience: {
        __typename?: "DeleteAudienceOutput";
        result: GqlClientDeleteAudienceResult;
        programVersion?: {
            __typename: "ProgramVersion";
            sceneOrder: Array<string>;
            storyOrder: Array<string>;
            id: string;
            name: string;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            useCase?: string | null;
            audienceSize?: string | null;
            industry?: string | null;
            description?: string | null;
            goals: Array<{
                __typename: "Goal";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                priority?: number | null;
                kpis: Array<{
                    __typename: "KPI";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    objective?: string | null;
                    scenes: Array<{ __typename?: "Scene"; id: string }>;
                    goal: { __typename?: "Goal"; id: string };
                }>;
            }>;
            stories: Array<{
                __typename: "Story";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                displaySceneOrder: Array<string>;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
            }>;
            scenes: Array<{
                __typename: "Scene";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                personalizations: Array<
                    | {
                          __typename: "AudiencePersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                          audiencePersonalizationValues: Array<{
                              __typename?: "AudiencePersonalizationValue";
                              id: string;
                              updated: string;
                              description?: string | null;
                              audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                          }>;
                      }
                    | {
                          __typename: "ChannelPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                      }
                    | {
                          __typename: "TouchpointPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                      }
                >;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            audiences: Array<{
                __typename: "Audience";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                impactsVolume?: boolean | null;
                audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                audienceValues: Array<{
                    __typename: "AudienceValue";
                    id: string;
                    updated: string;
                    name: string;
                    volume?: number | null;
                    audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                    subAudiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                        audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                    }>;
                    audiencePersonalizationValues: Array<{
                        __typename?: "AudiencePersonalizationValue";
                        id: string;
                        description?: string | null;
                        audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                    }>;
                }>;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
            }>;
            channels: Array<{
                __typename: "Channel";
                id: string;
                updated: string;
                name: string;
                platforms?: Array<string> | null;
                status?: string | null;
                channelPersonalizations: Array<{
                    __typename?: "ChannelPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            touchpoints: Array<{
                __typename: "Touchpoint";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                touchpointPersonalizations: Array<{
                    __typename?: "TouchpointPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            feedDataElements: Array<{
                __typename: "FeedDataElement";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                source?: string | null;
                status?: GqlClientFeedDataElementStatus | null;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
            }>;
        } | null;
    };
};

export type GqlClientUpdateAudienceMutationVariables = Exact<{
    input: GqlClientUpdateAudienceWithDataElementsInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateAudienceMutation = {
    __typename?: "Mutation";
    updateAudienceWithDataElements:
        | { __typename?: "UpdateAudienceWithDataElementsOutputConflict"; result: GqlClientUpdateAudienceWithDataElementsResult }
        | {
              __typename?: "UpdateAudienceWithDataElementsOutputSuccess";
              result: GqlClientUpdateAudienceWithDataElementsResult;
              audience?: {
                  __typename: "Audience";
                  id: string;
                  updated: string;
                  name: string;
                  description?: string | null;
                  impactsVolume?: boolean | null;
                  programVersion: {
                      __typename: "ProgramVersion";
                      sceneOrder: Array<string>;
                      storyOrder: Array<string>;
                      id: string;
                      name: string;
                      updated: string;
                      updatedBy?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                      useCase?: string | null;
                      audienceSize?: string | null;
                      industry?: string | null;
                      description?: string | null;
                      goals: Array<{
                          __typename: "Goal";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          priority?: number | null;
                          kpis: Array<{
                              __typename: "KPI";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              objective?: string | null;
                              scenes: Array<{ __typename?: "Scene"; id: string }>;
                              goal: { __typename?: "Goal"; id: string };
                          }>;
                      }>;
                      stories: Array<{
                          __typename: "Story";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          displaySceneOrder: Array<string>;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                          channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                      }>;
                      scenes: Array<{
                          __typename: "Scene";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                          kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                          personalizations: Array<
                              | {
                                    __typename: "AudiencePersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                    audiencePersonalizationValues: Array<{
                                        __typename?: "AudiencePersonalizationValue";
                                        id: string;
                                        updated: string;
                                        description?: string | null;
                                        audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                    }>;
                                }
                              | {
                                    __typename: "ChannelPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                                }
                              | {
                                    __typename: "TouchpointPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                                }
                          >;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      audiences: Array<{
                          __typename: "Audience";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          impactsVolume?: boolean | null;
                          audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                          audienceValues: Array<{
                              __typename: "AudienceValue";
                              id: string;
                              updated: string;
                              name: string;
                              volume?: number | null;
                              audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              subAudiences: Array<{
                                  __typename: "Audience";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  audienceValues: Array<{
                                      __typename: "AudienceValue";
                                      id: string;
                                      updated: string;
                                      name: string;
                                      volume?: number | null;
                                      audience: { __typename?: "Audience"; id: string };
                                  }>;
                                  audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                              }>;
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  description?: string | null;
                                  audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                              }>;
                          }>;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                      }>;
                      channels: Array<{
                          __typename: "Channel";
                          id: string;
                          updated: string;
                          name: string;
                          platforms?: Array<string> | null;
                          status?: string | null;
                          channelPersonalizations: Array<{
                              __typename?: "ChannelPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      touchpoints: Array<{
                          __typename: "Touchpoint";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          touchpointPersonalizations: Array<{
                              __typename?: "TouchpointPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      feedDataElements: Array<{
                          __typename: "FeedDataElement";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          source?: string | null;
                          status?: GqlClientFeedDataElementStatus | null;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                      }>;
                  };
                  audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                  audienceValues: Array<{
                      __typename: "AudienceValue";
                      id: string;
                      updated: string;
                      name: string;
                      volume?: number | null;
                      audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                      subAudiences: Array<{
                          __typename: "Audience";
                          id: string;
                          updated: string;
                          name: string;
                          audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                          audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                      }>;
                      audiencePersonalizationValues: Array<{
                          __typename?: "AudiencePersonalizationValue";
                          id: string;
                          description?: string | null;
                          audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                      }>;
                  }>;
                  feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
              } | null;
          };
};

export type GqlClientAudienceValueFragment = {
    __typename: "AudienceValue";
    id: string;
    updated: string;
    name: string;
    volume?: number | null;
    audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
    subAudiences: Array<{
        __typename: "Audience";
        id: string;
        updated: string;
        name: string;
        audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
        audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
    }>;
    audiencePersonalizationValues: Array<{
        __typename?: "AudiencePersonalizationValue";
        id: string;
        description?: string | null;
        audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
    }>;
};

export type GqlClientAudienceValueSupportersFragment = {
    __typename?: "Scene";
    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
    stories: Array<{ __typename?: "Story"; id: string }>;
};

export type GqlClientChannelFragment = {
    __typename: "Channel";
    id: string;
    updated: string;
    name: string;
    platforms?: Array<string> | null;
    status?: string | null;
    channelPersonalizations: Array<{ __typename?: "ChannelPersonalization"; id: string; description?: string | null; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } }>;
    stories: Array<{ __typename?: "Story"; id: string }>;
};

export type GqlClientChannelSupportersFragment = {
    __typename?: "Scene";
    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
    stories: Array<{ __typename?: "Story"; id: string }>;
};

export type GqlClientCreateChannelMutationVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
    input: GqlClientCreateChannelInput;
}>;

export type GqlClientCreateChannelMutation = {
    __typename?: "Mutation";
    createChannel: {
        __typename?: "CreateChannelOutput";
        result: GqlClientCreateChannelResult;
        channel?: {
            __typename: "Channel";
            id: string;
            updated: string;
            name: string;
            platforms?: Array<string> | null;
            status?: string | null;
            programVersion: {
                __typename: "ProgramVersion";
                sceneOrder: Array<string>;
                storyOrder: Array<string>;
                id: string;
                name: string;
                updated: string;
                updatedBy?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                useCase?: string | null;
                audienceSize?: string | null;
                industry?: string | null;
                description?: string | null;
                goals: Array<{
                    __typename: "Goal";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    priority?: number | null;
                    kpis: Array<{
                        __typename: "KPI";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        objective?: string | null;
                        scenes: Array<{ __typename?: "Scene"; id: string }>;
                        goal: { __typename?: "Goal"; id: string };
                    }>;
                }>;
                stories: Array<{
                    __typename: "Story";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    displaySceneOrder: Array<string>;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                    channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                }>;
                scenes: Array<{
                    __typename: "Scene";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                    personalizations: Array<
                        | {
                              __typename: "AudiencePersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  updated: string;
                                  description?: string | null;
                                  audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                              }>;
                          }
                        | {
                              __typename: "ChannelPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                          }
                        | {
                              __typename: "TouchpointPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                          }
                    >;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                audiences: Array<{
                    __typename: "Audience";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    impactsVolume?: boolean | null;
                    audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                    audienceValues: Array<{
                        __typename: "AudienceValue";
                        id: string;
                        updated: string;
                        name: string;
                        volume?: number | null;
                        audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                        subAudiences: Array<{
                            __typename: "Audience";
                            id: string;
                            updated: string;
                            name: string;
                            audienceValues: Array<{
                                __typename: "AudienceValue";
                                id: string;
                                updated: string;
                                name: string;
                                volume?: number | null;
                                audience: { __typename?: "Audience"; id: string };
                            }>;
                            audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                        }>;
                        audiencePersonalizationValues: Array<{
                            __typename?: "AudiencePersonalizationValue";
                            id: string;
                            description?: string | null;
                            audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                        }>;
                    }>;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                }>;
                channels: Array<{
                    __typename: "Channel";
                    id: string;
                    updated: string;
                    name: string;
                    platforms?: Array<string> | null;
                    status?: string | null;
                    channelPersonalizations: Array<{
                        __typename?: "ChannelPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                touchpoints: Array<{
                    __typename: "Touchpoint";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    touchpointPersonalizations: Array<{
                        __typename?: "TouchpointPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                feedDataElements: Array<{
                    __typename: "FeedDataElement";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    source?: string | null;
                    status?: GqlClientFeedDataElementStatus | null;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                }>;
            };
            channelPersonalizations: Array<{
                __typename?: "ChannelPersonalization";
                id: string;
                description?: string | null;
                type: Array<string>;
                scene: { __typename?: "Scene"; id: string; name: string };
            }>;
            stories: Array<{ __typename?: "Story"; id: string }>;
        } | null;
    };
};

export type GqlClientDeleteChannelMutationVariables = Exact<{
    input: GqlClientDeleteChannelInput;
}>;

export type GqlClientDeleteChannelMutation = {
    __typename?: "Mutation";
    deleteChannel: {
        __typename?: "DeleteChannelOutput";
        result: GqlClientDeleteChannelResult;
        programVersion?: {
            __typename: "ProgramVersion";
            sceneOrder: Array<string>;
            storyOrder: Array<string>;
            id: string;
            name: string;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            useCase?: string | null;
            audienceSize?: string | null;
            industry?: string | null;
            description?: string | null;
            goals: Array<{
                __typename: "Goal";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                priority?: number | null;
                kpis: Array<{
                    __typename: "KPI";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    objective?: string | null;
                    scenes: Array<{ __typename?: "Scene"; id: string }>;
                    goal: { __typename?: "Goal"; id: string };
                }>;
            }>;
            stories: Array<{
                __typename: "Story";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                displaySceneOrder: Array<string>;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
            }>;
            scenes: Array<{
                __typename: "Scene";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                personalizations: Array<
                    | {
                          __typename: "AudiencePersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                          audiencePersonalizationValues: Array<{
                              __typename?: "AudiencePersonalizationValue";
                              id: string;
                              updated: string;
                              description?: string | null;
                              audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                          }>;
                      }
                    | {
                          __typename: "ChannelPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                      }
                    | {
                          __typename: "TouchpointPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                      }
                >;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            audiences: Array<{
                __typename: "Audience";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                impactsVolume?: boolean | null;
                audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                audienceValues: Array<{
                    __typename: "AudienceValue";
                    id: string;
                    updated: string;
                    name: string;
                    volume?: number | null;
                    audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                    subAudiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                        audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                    }>;
                    audiencePersonalizationValues: Array<{
                        __typename?: "AudiencePersonalizationValue";
                        id: string;
                        description?: string | null;
                        audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                    }>;
                }>;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
            }>;
            channels: Array<{
                __typename: "Channel";
                id: string;
                updated: string;
                name: string;
                platforms?: Array<string> | null;
                status?: string | null;
                channelPersonalizations: Array<{
                    __typename?: "ChannelPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            touchpoints: Array<{
                __typename: "Touchpoint";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                touchpointPersonalizations: Array<{
                    __typename?: "TouchpointPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            feedDataElements: Array<{
                __typename: "FeedDataElement";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                source?: string | null;
                status?: GqlClientFeedDataElementStatus | null;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
            }>;
        } | null;
    };
};

export type GqlClientUpdateChannelMutationVariables = Exact<{
    input: GqlClientUpdateChannelInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateChannelMutation = {
    __typename?: "Mutation";
    updateChannel:
        | { __typename?: "UpdateChannelOutputConflict"; result: GqlClientUpdateChannelResult }
        | {
              __typename?: "UpdateChannelOutputSuccess";
              result: GqlClientUpdateChannelResult;
              channel?: {
                  __typename: "Channel";
                  id: string;
                  updated: string;
                  name: string;
                  platforms?: Array<string> | null;
                  status?: string | null;
                  programVersion: {
                      __typename: "ProgramVersion";
                      sceneOrder: Array<string>;
                      storyOrder: Array<string>;
                      id: string;
                      name: string;
                      updated: string;
                      updatedBy?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                      useCase?: string | null;
                      audienceSize?: string | null;
                      industry?: string | null;
                      description?: string | null;
                      goals: Array<{
                          __typename: "Goal";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          priority?: number | null;
                          kpis: Array<{
                              __typename: "KPI";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              objective?: string | null;
                              scenes: Array<{ __typename?: "Scene"; id: string }>;
                              goal: { __typename?: "Goal"; id: string };
                          }>;
                      }>;
                      stories: Array<{
                          __typename: "Story";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          displaySceneOrder: Array<string>;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                          channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                      }>;
                      scenes: Array<{
                          __typename: "Scene";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                          kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                          personalizations: Array<
                              | {
                                    __typename: "AudiencePersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                    audiencePersonalizationValues: Array<{
                                        __typename?: "AudiencePersonalizationValue";
                                        id: string;
                                        updated: string;
                                        description?: string | null;
                                        audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                    }>;
                                }
                              | {
                                    __typename: "ChannelPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                                }
                              | {
                                    __typename: "TouchpointPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                                }
                          >;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      audiences: Array<{
                          __typename: "Audience";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          impactsVolume?: boolean | null;
                          audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                          audienceValues: Array<{
                              __typename: "AudienceValue";
                              id: string;
                              updated: string;
                              name: string;
                              volume?: number | null;
                              audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              subAudiences: Array<{
                                  __typename: "Audience";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  audienceValues: Array<{
                                      __typename: "AudienceValue";
                                      id: string;
                                      updated: string;
                                      name: string;
                                      volume?: number | null;
                                      audience: { __typename?: "Audience"; id: string };
                                  }>;
                                  audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                              }>;
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  description?: string | null;
                                  audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                              }>;
                          }>;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                      }>;
                      channels: Array<{
                          __typename: "Channel";
                          id: string;
                          updated: string;
                          name: string;
                          platforms?: Array<string> | null;
                          status?: string | null;
                          channelPersonalizations: Array<{
                              __typename?: "ChannelPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      touchpoints: Array<{
                          __typename: "Touchpoint";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          touchpointPersonalizations: Array<{
                              __typename?: "TouchpointPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      feedDataElements: Array<{
                          __typename: "FeedDataElement";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          source?: string | null;
                          status?: GqlClientFeedDataElementStatus | null;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                      }>;
                  };
                  channelPersonalizations: Array<{
                      __typename?: "ChannelPersonalization";
                      id: string;
                      description?: string | null;
                      type: Array<string>;
                      scene: { __typename?: "Scene"; id: string; name: string };
                  }>;
                  stories: Array<{ __typename?: "Story"; id: string }>;
              } | null;
          };
};

export type GqlClientCreateBasicFeedDataElementMutationVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
    input: GqlClientCreateBasicFeedDataElementInput;
}>;

export type GqlClientCreateBasicFeedDataElementMutation = {
    __typename?: "Mutation";
    createBasicFeedDataElement: {
        __typename?: "CreateBasicFeedDataElementOutput";
        result: GqlClientCreateBasicFeedDataElementResult;
        feedDataElement?: {
            __typename: "FeedDataElement";
            id: string;
            updated: string;
            name: string;
            description?: string | null;
            source?: string | null;
            status?: GqlClientFeedDataElementStatus | null;
            programVersion: {
                __typename: "ProgramVersion";
                sceneOrder: Array<string>;
                storyOrder: Array<string>;
                id: string;
                name: string;
                updated: string;
                updatedBy?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                useCase?: string | null;
                audienceSize?: string | null;
                industry?: string | null;
                description?: string | null;
                goals: Array<{
                    __typename: "Goal";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    priority?: number | null;
                    kpis: Array<{
                        __typename: "KPI";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        objective?: string | null;
                        scenes: Array<{ __typename?: "Scene"; id: string }>;
                        goal: { __typename?: "Goal"; id: string };
                    }>;
                }>;
                stories: Array<{
                    __typename: "Story";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    displaySceneOrder: Array<string>;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                    channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                }>;
                scenes: Array<{
                    __typename: "Scene";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                    personalizations: Array<
                        | {
                              __typename: "AudiencePersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  updated: string;
                                  description?: string | null;
                                  audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                              }>;
                          }
                        | {
                              __typename: "ChannelPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                          }
                        | {
                              __typename: "TouchpointPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                          }
                    >;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                audiences: Array<{
                    __typename: "Audience";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    impactsVolume?: boolean | null;
                    audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                    audienceValues: Array<{
                        __typename: "AudienceValue";
                        id: string;
                        updated: string;
                        name: string;
                        volume?: number | null;
                        audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                        subAudiences: Array<{
                            __typename: "Audience";
                            id: string;
                            updated: string;
                            name: string;
                            audienceValues: Array<{
                                __typename: "AudienceValue";
                                id: string;
                                updated: string;
                                name: string;
                                volume?: number | null;
                                audience: { __typename?: "Audience"; id: string };
                            }>;
                            audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                        }>;
                        audiencePersonalizationValues: Array<{
                            __typename?: "AudiencePersonalizationValue";
                            id: string;
                            description?: string | null;
                            audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                        }>;
                    }>;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                }>;
                channels: Array<{
                    __typename: "Channel";
                    id: string;
                    updated: string;
                    name: string;
                    platforms?: Array<string> | null;
                    status?: string | null;
                    channelPersonalizations: Array<{
                        __typename?: "ChannelPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                touchpoints: Array<{
                    __typename: "Touchpoint";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    touchpointPersonalizations: Array<{
                        __typename?: "TouchpointPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                feedDataElements: Array<{
                    __typename: "FeedDataElement";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    source?: string | null;
                    status?: GqlClientFeedDataElementStatus | null;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                }>;
            };
            scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
            audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
        } | null;
    };
};

export type GqlClientDataElementFragment = {
    __typename: "FeedDataElement";
    id: string;
    updated: string;
    name: string;
    description?: string | null;
    source?: string | null;
    status?: GqlClientFeedDataElementStatus | null;
    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
    audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
};

export type GqlClientDataElementSupportersFragment = {
    __typename?: "Scene";
    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
    stories: Array<{ __typename?: "Story"; id: string }>;
};

export type GqlClientDeleteDataElementMutationVariables = Exact<{
    input: GqlClientDeleteDataElementInput;
}>;

export type GqlClientDeleteDataElementMutation = {
    __typename?: "Mutation";
    deleteDataElement:
        | { __typename?: "DeleteDataElementOutputRefusal"; result: GqlClientDeleteDataElementResult }
        | {
              __typename?: "DeleteDataElementOutputSuccess";
              result: GqlClientDeleteDataElementResult;
              programVersion?: {
                  __typename: "ProgramVersion";
                  sceneOrder: Array<string>;
                  storyOrder: Array<string>;
                  id: string;
                  name: string;
                  updated: string;
                  updatedBy?: string | null;
                  anyUpdated?: string | null;
                  anyUpdatedBy?: string | null;
                  useCase?: string | null;
                  audienceSize?: string | null;
                  industry?: string | null;
                  description?: string | null;
                  goals: Array<{
                      __typename: "Goal";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      priority?: number | null;
                      kpis: Array<{
                          __typename: "KPI";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          objective?: string | null;
                          scenes: Array<{ __typename?: "Scene"; id: string }>;
                          goal: { __typename?: "Goal"; id: string };
                      }>;
                  }>;
                  stories: Array<{
                      __typename: "Story";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      displaySceneOrder: Array<string>;
                      scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                      touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                      channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                  }>;
                  scenes: Array<{
                      __typename: "Scene";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                      kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                      personalizations: Array<
                          | {
                                __typename: "AudiencePersonalization";
                                id: string;
                                updated: string;
                                type: Array<string>;
                                description?: string | null;
                                audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                audiencePersonalizationValues: Array<{
                                    __typename?: "AudiencePersonalizationValue";
                                    id: string;
                                    updated: string;
                                    description?: string | null;
                                    audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                }>;
                            }
                          | {
                                __typename: "ChannelPersonalization";
                                id: string;
                                updated: string;
                                type: Array<string>;
                                description?: string | null;
                                channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                            }
                          | {
                                __typename: "TouchpointPersonalization";
                                id: string;
                                updated: string;
                                type: Array<string>;
                                description?: string | null;
                                touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                            }
                      >;
                      stories: Array<{ __typename?: "Story"; id: string }>;
                  }>;
                  audiences: Array<{
                      __typename: "Audience";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      impactsVolume?: boolean | null;
                      audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                      audienceValues: Array<{
                          __typename: "AudienceValue";
                          id: string;
                          updated: string;
                          name: string;
                          volume?: number | null;
                          audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                          subAudiences: Array<{
                              __typename: "Audience";
                              id: string;
                              updated: string;
                              name: string;
                              audienceValues: Array<{
                                  __typename: "AudienceValue";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  volume?: number | null;
                                  audience: { __typename?: "Audience"; id: string };
                              }>;
                              audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                          }>;
                          audiencePersonalizationValues: Array<{
                              __typename?: "AudiencePersonalizationValue";
                              id: string;
                              description?: string | null;
                              audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                          }>;
                      }>;
                      feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                  }>;
                  channels: Array<{
                      __typename: "Channel";
                      id: string;
                      updated: string;
                      name: string;
                      platforms?: Array<string> | null;
                      status?: string | null;
                      channelPersonalizations: Array<{
                          __typename?: "ChannelPersonalization";
                          id: string;
                          description?: string | null;
                          type: Array<string>;
                          scene: { __typename?: "Scene"; id: string; name: string };
                      }>;
                      stories: Array<{ __typename?: "Story"; id: string }>;
                  }>;
                  touchpoints: Array<{
                      __typename: "Touchpoint";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      touchpointPersonalizations: Array<{
                          __typename?: "TouchpointPersonalization";
                          id: string;
                          description?: string | null;
                          type: Array<string>;
                          scene: { __typename?: "Scene"; id: string; name: string };
                      }>;
                      stories: Array<{ __typename?: "Story"; id: string }>;
                  }>;
                  feedDataElements: Array<{
                      __typename: "FeedDataElement";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      source?: string | null;
                      status?: GqlClientFeedDataElementStatus | null;
                      scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                      audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                  }>;
              } | null;
          };
};

export type GqlClientUpdateBasicFeedDataElementMutationVariables = Exact<{
    input: GqlClientUpdateBasicFeedDataElementInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateBasicFeedDataElementMutation = {
    __typename?: "Mutation";
    updateBasicFeedDataElement:
        | { __typename?: "UpdateBasicFeedDataElementOutputConflict"; result: GqlClientUpdateBasicFeedDataElementResult }
        | {
              __typename?: "UpdateBasicFeedDataElementOutputSuccess";
              result: GqlClientUpdateBasicFeedDataElementResult;
              feedDataElement?: {
                  __typename: "FeedDataElement";
                  id: string;
                  updated: string;
                  name: string;
                  description?: string | null;
                  source?: string | null;
                  status?: GqlClientFeedDataElementStatus | null;
                  programVersion: {
                      __typename: "ProgramVersion";
                      sceneOrder: Array<string>;
                      storyOrder: Array<string>;
                      id: string;
                      name: string;
                      updated: string;
                      updatedBy?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                      useCase?: string | null;
                      audienceSize?: string | null;
                      industry?: string | null;
                      description?: string | null;
                      goals: Array<{
                          __typename: "Goal";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          priority?: number | null;
                          kpis: Array<{
                              __typename: "KPI";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              objective?: string | null;
                              scenes: Array<{ __typename?: "Scene"; id: string }>;
                              goal: { __typename?: "Goal"; id: string };
                          }>;
                      }>;
                      stories: Array<{
                          __typename: "Story";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          displaySceneOrder: Array<string>;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                          channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                      }>;
                      scenes: Array<{
                          __typename: "Scene";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                          kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                          personalizations: Array<
                              | {
                                    __typename: "AudiencePersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                    audiencePersonalizationValues: Array<{
                                        __typename?: "AudiencePersonalizationValue";
                                        id: string;
                                        updated: string;
                                        description?: string | null;
                                        audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                    }>;
                                }
                              | {
                                    __typename: "ChannelPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                                }
                              | {
                                    __typename: "TouchpointPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                                }
                          >;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      audiences: Array<{
                          __typename: "Audience";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          impactsVolume?: boolean | null;
                          audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                          audienceValues: Array<{
                              __typename: "AudienceValue";
                              id: string;
                              updated: string;
                              name: string;
                              volume?: number | null;
                              audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              subAudiences: Array<{
                                  __typename: "Audience";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  audienceValues: Array<{
                                      __typename: "AudienceValue";
                                      id: string;
                                      updated: string;
                                      name: string;
                                      volume?: number | null;
                                      audience: { __typename?: "Audience"; id: string };
                                  }>;
                                  audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                              }>;
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  description?: string | null;
                                  audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                              }>;
                          }>;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                      }>;
                      channels: Array<{
                          __typename: "Channel";
                          id: string;
                          updated: string;
                          name: string;
                          platforms?: Array<string> | null;
                          status?: string | null;
                          channelPersonalizations: Array<{
                              __typename?: "ChannelPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      touchpoints: Array<{
                          __typename: "Touchpoint";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          touchpointPersonalizations: Array<{
                              __typename?: "TouchpointPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      feedDataElements: Array<{
                          __typename: "FeedDataElement";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          source?: string | null;
                          status?: GqlClientFeedDataElementStatus | null;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                      }>;
                  };
                  scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                  audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
              } | null;
          };
};

export type GqlClientCreateGoalMutationVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
    input: GqlClientCreateGoalWithKpIsInput;
}>;

export type GqlClientCreateGoalMutation = {
    __typename?: "Mutation";
    createGoalWithKPIs: {
        __typename?: "CreateGoalWithKPIsOutput";
        result: GqlClientCreateGoalWithKpIsResult;
        goal?: {
            __typename: "Goal";
            id: string;
            updated: string;
            name: string;
            description?: string | null;
            priority?: number | null;
            programVersion: {
                __typename: "ProgramVersion";
                sceneOrder: Array<string>;
                storyOrder: Array<string>;
                id: string;
                name: string;
                updated: string;
                updatedBy?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                useCase?: string | null;
                audienceSize?: string | null;
                industry?: string | null;
                description?: string | null;
                goals: Array<{
                    __typename: "Goal";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    priority?: number | null;
                    kpis: Array<{
                        __typename: "KPI";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        objective?: string | null;
                        scenes: Array<{ __typename?: "Scene"; id: string }>;
                        goal: { __typename?: "Goal"; id: string };
                    }>;
                }>;
                stories: Array<{
                    __typename: "Story";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    displaySceneOrder: Array<string>;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                    channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                }>;
                scenes: Array<{
                    __typename: "Scene";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                    personalizations: Array<
                        | {
                              __typename: "AudiencePersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  updated: string;
                                  description?: string | null;
                                  audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                              }>;
                          }
                        | {
                              __typename: "ChannelPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                          }
                        | {
                              __typename: "TouchpointPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                          }
                    >;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                audiences: Array<{
                    __typename: "Audience";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    impactsVolume?: boolean | null;
                    audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                    audienceValues: Array<{
                        __typename: "AudienceValue";
                        id: string;
                        updated: string;
                        name: string;
                        volume?: number | null;
                        audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                        subAudiences: Array<{
                            __typename: "Audience";
                            id: string;
                            updated: string;
                            name: string;
                            audienceValues: Array<{
                                __typename: "AudienceValue";
                                id: string;
                                updated: string;
                                name: string;
                                volume?: number | null;
                                audience: { __typename?: "Audience"; id: string };
                            }>;
                            audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                        }>;
                        audiencePersonalizationValues: Array<{
                            __typename?: "AudiencePersonalizationValue";
                            id: string;
                            description?: string | null;
                            audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                        }>;
                    }>;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                }>;
                channels: Array<{
                    __typename: "Channel";
                    id: string;
                    updated: string;
                    name: string;
                    platforms?: Array<string> | null;
                    status?: string | null;
                    channelPersonalizations: Array<{
                        __typename?: "ChannelPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                touchpoints: Array<{
                    __typename: "Touchpoint";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    touchpointPersonalizations: Array<{
                        __typename?: "TouchpointPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                feedDataElements: Array<{
                    __typename: "FeedDataElement";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    source?: string | null;
                    status?: GqlClientFeedDataElementStatus | null;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                }>;
            };
            kpis: Array<{
                __typename: "KPI";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                objective?: string | null;
                scenes: Array<{ __typename?: "Scene"; id: string }>;
                goal: { __typename?: "Goal"; id: string };
            }>;
        } | null;
    };
};

export type GqlClientDeleteGoalMutationVariables = Exact<{
    input: GqlClientDeleteGoalInput;
}>;

export type GqlClientDeleteGoalMutation = {
    __typename?: "Mutation";
    deleteGoal: {
        __typename?: "DeleteGoalOutput";
        result: GqlClientDeleteGoalResult;
        programVersion?: {
            __typename: "ProgramVersion";
            sceneOrder: Array<string>;
            storyOrder: Array<string>;
            id: string;
            name: string;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            useCase?: string | null;
            audienceSize?: string | null;
            industry?: string | null;
            description?: string | null;
            goals: Array<{
                __typename: "Goal";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                priority?: number | null;
                kpis: Array<{
                    __typename: "KPI";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    objective?: string | null;
                    scenes: Array<{ __typename?: "Scene"; id: string }>;
                    goal: { __typename?: "Goal"; id: string };
                }>;
            }>;
            stories: Array<{
                __typename: "Story";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                displaySceneOrder: Array<string>;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
            }>;
            scenes: Array<{
                __typename: "Scene";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                personalizations: Array<
                    | {
                          __typename: "AudiencePersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                          audiencePersonalizationValues: Array<{
                              __typename?: "AudiencePersonalizationValue";
                              id: string;
                              updated: string;
                              description?: string | null;
                              audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                          }>;
                      }
                    | {
                          __typename: "ChannelPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                      }
                    | {
                          __typename: "TouchpointPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                      }
                >;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            audiences: Array<{
                __typename: "Audience";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                impactsVolume?: boolean | null;
                audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                audienceValues: Array<{
                    __typename: "AudienceValue";
                    id: string;
                    updated: string;
                    name: string;
                    volume?: number | null;
                    audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                    subAudiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                        audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                    }>;
                    audiencePersonalizationValues: Array<{
                        __typename?: "AudiencePersonalizationValue";
                        id: string;
                        description?: string | null;
                        audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                    }>;
                }>;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
            }>;
            channels: Array<{
                __typename: "Channel";
                id: string;
                updated: string;
                name: string;
                platforms?: Array<string> | null;
                status?: string | null;
                channelPersonalizations: Array<{
                    __typename?: "ChannelPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            touchpoints: Array<{
                __typename: "Touchpoint";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                touchpointPersonalizations: Array<{
                    __typename?: "TouchpointPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            feedDataElements: Array<{
                __typename: "FeedDataElement";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                source?: string | null;
                status?: GqlClientFeedDataElementStatus | null;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
            }>;
        } | null;
    };
};

export type GqlClientGoalFragment = {
    __typename: "Goal";
    id: string;
    updated: string;
    name: string;
    description?: string | null;
    priority?: number | null;
    kpis: Array<{
        __typename: "KPI";
        id: string;
        updated: string;
        name: string;
        description?: string | null;
        objective?: string | null;
        scenes: Array<{ __typename?: "Scene"; id: string }>;
        goal: { __typename?: "Goal"; id: string };
    }>;
};

export type GqlClientGoalSupportersFragment = {
    __typename?: "Scene";
    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }>;
    stories: Array<{ __typename?: "Story"; id: string; touchpoints: Array<{ __typename?: "Touchpoint"; id: string }>; channels: Array<{ __typename?: "Channel"; id: string }> }>;
    personalizations: Array<
        | {
              __typename?: "AudiencePersonalization";
              audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
              audiencePersonalizationValues: Array<{ __typename?: "AudiencePersonalizationValue"; audienceValue: { __typename?: "AudienceValue"; id: string } }>;
          }
        | { __typename?: "ChannelPersonalization"; channel: { __typename?: "Channel"; id: string } }
        | { __typename?: "TouchpointPersonalization"; touchpoint: { __typename?: "Touchpoint"; id: string } }
    >;
};

export type GqlClientUpdateGoalMutationVariables = Exact<{
    input: GqlClientUpdateGoalWithKpIsInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateGoalMutation = {
    __typename?: "Mutation";
    updateGoalWithKPIs:
        | { __typename?: "UpdateGoalWithKPIsOutputConflict"; result: GqlClientUpdateGoalWithKpIsResult }
        | {
              __typename?: "UpdateGoalWithKPIsOutputSuccess";
              result: GqlClientUpdateGoalWithKpIsResult;
              goal?: {
                  __typename: "Goal";
                  id: string;
                  updated: string;
                  name: string;
                  description?: string | null;
                  priority?: number | null;
                  programVersion: {
                      __typename: "ProgramVersion";
                      sceneOrder: Array<string>;
                      storyOrder: Array<string>;
                      id: string;
                      name: string;
                      updated: string;
                      updatedBy?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                      useCase?: string | null;
                      audienceSize?: string | null;
                      industry?: string | null;
                      description?: string | null;
                      goals: Array<{
                          __typename: "Goal";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          priority?: number | null;
                          kpis: Array<{
                              __typename: "KPI";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              objective?: string | null;
                              scenes: Array<{ __typename?: "Scene"; id: string }>;
                              goal: { __typename?: "Goal"; id: string };
                          }>;
                      }>;
                      stories: Array<{
                          __typename: "Story";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          displaySceneOrder: Array<string>;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                          channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                      }>;
                      scenes: Array<{
                          __typename: "Scene";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                          kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                          personalizations: Array<
                              | {
                                    __typename: "AudiencePersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                    audiencePersonalizationValues: Array<{
                                        __typename?: "AudiencePersonalizationValue";
                                        id: string;
                                        updated: string;
                                        description?: string | null;
                                        audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                    }>;
                                }
                              | {
                                    __typename: "ChannelPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                                }
                              | {
                                    __typename: "TouchpointPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                                }
                          >;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      audiences: Array<{
                          __typename: "Audience";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          impactsVolume?: boolean | null;
                          audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                          audienceValues: Array<{
                              __typename: "AudienceValue";
                              id: string;
                              updated: string;
                              name: string;
                              volume?: number | null;
                              audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              subAudiences: Array<{
                                  __typename: "Audience";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  audienceValues: Array<{
                                      __typename: "AudienceValue";
                                      id: string;
                                      updated: string;
                                      name: string;
                                      volume?: number | null;
                                      audience: { __typename?: "Audience"; id: string };
                                  }>;
                                  audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                              }>;
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  description?: string | null;
                                  audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                              }>;
                          }>;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                      }>;
                      channels: Array<{
                          __typename: "Channel";
                          id: string;
                          updated: string;
                          name: string;
                          platforms?: Array<string> | null;
                          status?: string | null;
                          channelPersonalizations: Array<{
                              __typename?: "ChannelPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      touchpoints: Array<{
                          __typename: "Touchpoint";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          touchpointPersonalizations: Array<{
                              __typename?: "TouchpointPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      feedDataElements: Array<{
                          __typename: "FeedDataElement";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          source?: string | null;
                          status?: GqlClientFeedDataElementStatus | null;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                      }>;
                  };
                  kpis: Array<{
                      __typename: "KPI";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      objective?: string | null;
                      scenes: Array<{ __typename?: "Scene"; id: string }>;
                      goal: { __typename?: "Goal"; id: string };
                  }>;
              } | null;
          };
};

export type GqlClientKpiFragment = {
    __typename: "KPI";
    id: string;
    updated: string;
    name: string;
    description?: string | null;
    objective?: string | null;
    scenes: Array<{ __typename?: "Scene"; id: string }>;
    goal: { __typename?: "Goal"; id: string };
};

export type GqlClientKpiSupportersFragment = {
    __typename?: "Scene";
    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }>;
    stories: Array<{ __typename?: "Story"; id: string; touchpoints: Array<{ __typename?: "Touchpoint"; id: string }>; channels: Array<{ __typename?: "Channel"; id: string }> }>;
    personalizations: Array<
        | {
              __typename?: "AudiencePersonalization";
              audience: { __typename?: "Audience"; id: string };
              audiencePersonalizationValues: Array<{ __typename?: "AudiencePersonalizationValue"; audienceValue: { __typename?: "AudienceValue"; id: string } }>;
          }
        | { __typename?: "ChannelPersonalization"; channel: { __typename?: "Channel"; id: string } }
        | { __typename?: "TouchpointPersonalization"; touchpoint: { __typename?: "Touchpoint"; id: string } }
    >;
};

export type GqlClientCreateProgramMutationVariables = Exact<{
    input: GqlClientCreateProgramInput;
}>;

export type GqlClientCreateProgramMutation = {
    __typename?: "Mutation";
    createProgram: {
        __typename?: "CreateProgramOutput";
        result: GqlClientCreateProgramResult;
        program?: {
            __typename: "Program";
            id: string;
            programType?: GqlClientProgramType | null;
            saleForceId?: string | null;
            accountId: string;
            created: string;
            createdBy?: string | null;
            updated: string;
            anyUpdated?: string | null;
            updatedBy?: string | null;
            anyUpdatedBy?: string | null;
            name: string;
            description?: string | null;
            legacyBuilderProgramId?: string | null;
            legacyFrameworkProgramId?: string | null;
            legacyOverviewProgramId?: string | null;
            isEditor?: boolean | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            isArchive?: boolean | null;
            customAnalyticFields: Array<{
                __typename: "CustomAnalyticField";
                id: string;
                accountId: string;
                updated: string;
                updatedBy?: string | null;
                name?: string | null;
                logic?: any | null;
                program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
            }>;
            surveys: Array<{ __typename?: "Survey"; id: string; externalId: string; updated: string; questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }> }>;
        } | null;
    };
};

export type GqlClientCreateProgramAndDraftMutationVariables = Exact<{
    input: GqlClientCreateProgramAndDraftInput;
}>;

export type GqlClientCreateProgramAndDraftMutation = {
    __typename?: "Mutation";
    createProgramAndDraft: {
        __typename?: "CreateProgramAndDraftOutput";
        result: GqlClientCreateProgramAndDraftResult;
        program?: {
            __typename: "Program";
            id: string;
            programType?: GqlClientProgramType | null;
            saleForceId?: string | null;
            accountId: string;
            created: string;
            createdBy?: string | null;
            updated: string;
            anyUpdated?: string | null;
            updatedBy?: string | null;
            anyUpdatedBy?: string | null;
            name: string;
            description?: string | null;
            legacyBuilderProgramId?: string | null;
            legacyFrameworkProgramId?: string | null;
            legacyOverviewProgramId?: string | null;
            isEditor?: boolean | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            isArchive?: boolean | null;
            programVersions: Array<{
                __typename: "ProgramVersion";
                sceneOrder: Array<string>;
                storyOrder: Array<string>;
                id: string;
                name: string;
                updated: string;
                updatedBy?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                useCase?: string | null;
                audienceSize?: string | null;
                industry?: string | null;
                description?: string | null;
                goals: Array<{
                    __typename: "Goal";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    priority?: number | null;
                    kpis: Array<{
                        __typename: "KPI";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        objective?: string | null;
                        scenes: Array<{ __typename?: "Scene"; id: string }>;
                        goal: { __typename?: "Goal"; id: string };
                    }>;
                }>;
                stories: Array<{
                    __typename: "Story";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    displaySceneOrder: Array<string>;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                    channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                }>;
                scenes: Array<{
                    __typename: "Scene";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                    personalizations: Array<
                        | {
                              __typename: "AudiencePersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  updated: string;
                                  description?: string | null;
                                  audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                              }>;
                          }
                        | {
                              __typename: "ChannelPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                          }
                        | {
                              __typename: "TouchpointPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                          }
                    >;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                audiences: Array<{
                    __typename: "Audience";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    impactsVolume?: boolean | null;
                    audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                    audienceValues: Array<{
                        __typename: "AudienceValue";
                        id: string;
                        updated: string;
                        name: string;
                        volume?: number | null;
                        audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                        subAudiences: Array<{
                            __typename: "Audience";
                            id: string;
                            updated: string;
                            name: string;
                            audienceValues: Array<{
                                __typename: "AudienceValue";
                                id: string;
                                updated: string;
                                name: string;
                                volume?: number | null;
                                audience: { __typename?: "Audience"; id: string };
                            }>;
                            audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                        }>;
                        audiencePersonalizationValues: Array<{
                            __typename?: "AudiencePersonalizationValue";
                            id: string;
                            description?: string | null;
                            audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                        }>;
                    }>;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                }>;
                channels: Array<{
                    __typename: "Channel";
                    id: string;
                    updated: string;
                    name: string;
                    platforms?: Array<string> | null;
                    status?: string | null;
                    channelPersonalizations: Array<{
                        __typename?: "ChannelPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                touchpoints: Array<{
                    __typename: "Touchpoint";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    touchpointPersonalizations: Array<{
                        __typename?: "TouchpointPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                feedDataElements: Array<{
                    __typename: "FeedDataElement";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    source?: string | null;
                    status?: GqlClientFeedDataElementStatus | null;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                }>;
            }>;
            customAnalyticFields: Array<{
                __typename: "CustomAnalyticField";
                id: string;
                accountId: string;
                updated: string;
                updatedBy?: string | null;
                name?: string | null;
                logic?: any | null;
                program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
            }>;
            surveys: Array<{ __typename?: "Survey"; id: string; externalId: string; updated: string; questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }> }>;
        } | null;
    };
};

export type GqlClientDeleteProgramMutationVariables = Exact<{
    input: GqlClientDeleteProgramInput;
}>;

export type GqlClientDeleteProgramMutation = { __typename?: "Mutation"; deleteProgram: { __typename?: "DeleteProgramOutput"; result: GqlClientDeleteProgramResult } };

export type GqlClientGetProgramQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientGetProgramQuery = {
    __typename?: "Query";
    program?: {
        __typename: "Program";
        id: string;
        programType?: GqlClientProgramType | null;
        saleForceId?: string | null;
        accountId: string;
        created: string;
        createdBy?: string | null;
        updated: string;
        anyUpdated?: string | null;
        updatedBy?: string | null;
        anyUpdatedBy?: string | null;
        name: string;
        description?: string | null;
        legacyBuilderProgramId?: string | null;
        legacyFrameworkProgramId?: string | null;
        legacyOverviewProgramId?: string | null;
        isEditor?: boolean | null;
        lifecycleStage: GqlClientAssetLifecycleStage;
        isArchive?: boolean | null;
        programVersionDrafts: Array<{
            __typename: "ProgramVersion";
            sceneOrder: Array<string>;
            storyOrder: Array<string>;
            id: string;
            name: string;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            useCase?: string | null;
            audienceSize?: string | null;
            industry?: string | null;
            description?: string | null;
            goals: Array<{
                __typename: "Goal";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                priority?: number | null;
                kpis: Array<{
                    __typename: "KPI";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    objective?: string | null;
                    scenes: Array<{ __typename?: "Scene"; id: string }>;
                    goal: { __typename?: "Goal"; id: string };
                }>;
            }>;
            stories: Array<{
                __typename: "Story";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                displaySceneOrder: Array<string>;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
            }>;
            scenes: Array<{
                __typename: "Scene";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                personalizations: Array<
                    | {
                          __typename: "AudiencePersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                          audiencePersonalizationValues: Array<{
                              __typename?: "AudiencePersonalizationValue";
                              id: string;
                              updated: string;
                              description?: string | null;
                              audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                          }>;
                      }
                    | {
                          __typename: "ChannelPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                      }
                    | {
                          __typename: "TouchpointPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                      }
                >;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            audiences: Array<{
                __typename: "Audience";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                impactsVolume?: boolean | null;
                audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                audienceValues: Array<{
                    __typename: "AudienceValue";
                    id: string;
                    updated: string;
                    name: string;
                    volume?: number | null;
                    audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                    subAudiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                        audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                    }>;
                    audiencePersonalizationValues: Array<{
                        __typename?: "AudiencePersonalizationValue";
                        id: string;
                        description?: string | null;
                        audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                    }>;
                }>;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
            }>;
            channels: Array<{
                __typename: "Channel";
                id: string;
                updated: string;
                name: string;
                platforms?: Array<string> | null;
                status?: string | null;
                channelPersonalizations: Array<{
                    __typename?: "ChannelPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            touchpoints: Array<{
                __typename: "Touchpoint";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                touchpointPersonalizations: Array<{
                    __typename?: "TouchpointPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            feedDataElements: Array<{
                __typename: "FeedDataElement";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                source?: string | null;
                status?: GqlClientFeedDataElementStatus | null;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
            }>;
        }>;
        customAnalyticFields: Array<{
            __typename: "CustomAnalyticField";
            id: string;
            accountId: string;
            updated: string;
            updatedBy?: string | null;
            name?: string | null;
            logic?: any | null;
            program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
        }>;
        surveys: Array<{ __typename?: "Survey"; id: string; externalId: string; updated: string; questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }> }>;
    } | null;
};

export type GqlClientGetProgramMetadataQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type GqlClientGetProgramMetadataQuery = {
    __typename?: "Query";
    program?: {
        __typename: "Program";
        id: string;
        programType?: GqlClientProgramType | null;
        saleForceId?: string | null;
        accountId: string;
        created: string;
        createdBy?: string | null;
        updated: string;
        anyUpdated?: string | null;
        updatedBy?: string | null;
        anyUpdatedBy?: string | null;
        name: string;
        description?: string | null;
        legacyBuilderProgramId?: string | null;
        legacyFrameworkProgramId?: string | null;
        legacyOverviewProgramId?: string | null;
        isEditor?: boolean | null;
        lifecycleStage: GqlClientAssetLifecycleStage;
        isArchive?: boolean | null;
        programVersionDrafts: Array<{
            __typename: "ProgramVersion";
            id: string;
            name: string;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            useCase?: string | null;
            audienceSize?: string | null;
            industry?: string | null;
            description?: string | null;
        }>;
        customAnalyticFields: Array<{
            __typename: "CustomAnalyticField";
            id: string;
            accountId: string;
            updated: string;
            updatedBy?: string | null;
            name?: string | null;
            logic?: any | null;
            program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
        }>;
        surveys: Array<{ __typename?: "Survey"; id: string; externalId: string; updated: string; questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }> }>;
    } | null;
};

export type GqlClientProgramFragment = {
    __typename: "Program";
    id: string;
    programType?: GqlClientProgramType | null;
    saleForceId?: string | null;
    accountId: string;
    created: string;
    createdBy?: string | null;
    updated: string;
    anyUpdated?: string | null;
    updatedBy?: string | null;
    anyUpdatedBy?: string | null;
    name: string;
    description?: string | null;
    legacyBuilderProgramId?: string | null;
    legacyFrameworkProgramId?: string | null;
    legacyOverviewProgramId?: string | null;
    isEditor?: boolean | null;
    lifecycleStage: GqlClientAssetLifecycleStage;
    isArchive?: boolean | null;
    customAnalyticFields: Array<{
        __typename: "CustomAnalyticField";
        id: string;
        accountId: string;
        updated: string;
        updatedBy?: string | null;
        name?: string | null;
        logic?: any | null;
        program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
    }>;
    surveys: Array<{ __typename?: "Survey"; id: string; externalId: string; updated: string; questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }> }>;
};

export type GqlClientUpdateFrameworkProgramMutationVariables = Exact<{
    input: GqlClientUpdateProgramInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateFrameworkProgramMutation = {
    __typename?: "Mutation";
    updateProgram:
        | { __typename?: "UpdateProgramOutputConflict"; result: GqlClientUpdateProgramResult }
        | {
              __typename?: "UpdateProgramOutputSuccess";
              result: GqlClientUpdateProgramResult;
              program?: {
                  __typename: "Program";
                  id: string;
                  programType?: GqlClientProgramType | null;
                  saleForceId?: string | null;
                  accountId: string;
                  created: string;
                  createdBy?: string | null;
                  updated: string;
                  anyUpdated?: string | null;
                  updatedBy?: string | null;
                  anyUpdatedBy?: string | null;
                  name: string;
                  description?: string | null;
                  legacyBuilderProgramId?: string | null;
                  legacyFrameworkProgramId?: string | null;
                  legacyOverviewProgramId?: string | null;
                  isEditor?: boolean | null;
                  lifecycleStage: GqlClientAssetLifecycleStage;
                  isArchive?: boolean | null;
                  account: {
                      __typename?: "Account";
                      id: string;
                      displayName?: string | null;
                      name?: string | null;
                      programs: Array<{
                          __typename: "Program";
                          id: string;
                          programType?: GqlClientProgramType | null;
                          saleForceId?: string | null;
                          accountId: string;
                          created: string;
                          createdBy?: string | null;
                          updated: string;
                          anyUpdated?: string | null;
                          updatedBy?: string | null;
                          anyUpdatedBy?: string | null;
                          name: string;
                          description?: string | null;
                          legacyBuilderProgramId?: string | null;
                          legacyFrameworkProgramId?: string | null;
                          legacyOverviewProgramId?: string | null;
                          isEditor?: boolean | null;
                          lifecycleStage: GqlClientAssetLifecycleStage;
                          isArchive?: boolean | null;
                          customAnalyticFields: Array<{
                              __typename: "CustomAnalyticField";
                              id: string;
                              accountId: string;
                              updated: string;
                              updatedBy?: string | null;
                              name?: string | null;
                              logic?: any | null;
                              program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                          }>;
                          surveys: Array<{
                              __typename?: "Survey";
                              id: string;
                              externalId: string;
                              updated: string;
                              questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                          }>;
                      }>;
                      campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                      reports: Array<{
                          __typename?: "AccountReport";
                          id: string;
                          name: string;
                          type: GqlClientAccountReportType;
                          timezone?: string | null;
                          created: string;
                          createdBy?: string | null;
                          updated: string;
                          updatedBy?: string | null;
                          reportProgramType: GqlClientReportProgramType;
                          relevantPrograms: GqlClientRelevantPrograms;
                          filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
                          programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
                          campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                          reportElements: Array<{
                              __typename?: "ReportElement";
                              name: string;
                              platformName?: string | null;
                              description?: string | null;
                              platformDescription?: string | null;
                              elementType: GqlClientReportElementType;
                              formatType: GqlClientReportElementFormatType;
                              formatter?: any | null;
                              reportLibraryConfigKey: string;
                              placeHolder?: string | null;
                          }>;
                          schedules: Array<{
                              __typename?: "AccountSchedule";
                              id: string;
                              active?: boolean | null;
                              created: string;
                              createdBy?: string | null;
                              updated: string;
                              deliveryChannel?: any | null;
                              occurrence?: GqlClientReportScheduleOccurrence | null;
                              period?: GqlClientReportSchedulePeriod | null;
                              periodType?: GqlClientReportSchedulePeriodType | null;
                              startPeriod?: string | null;
                              sourceEnvironments?: Array<string> | null;
                              filenameTemplate?: string | null;
                          }>;
                      }>;
                  };
                  customAnalyticFields: Array<{
                      __typename: "CustomAnalyticField";
                      id: string;
                      accountId: string;
                      updated: string;
                      updatedBy?: string | null;
                      name?: string | null;
                      logic?: any | null;
                      program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                  }>;
                  surveys: Array<{
                      __typename?: "Survey";
                      id: string;
                      externalId: string;
                      updated: string;
                      questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                  }>;
              } | null;
          };
};

export type GqlClientCopyProgramVersionDraftMutationVariables = Exact<{
    input: GqlClientCopyProgramVersionDraftInput;
}>;

export type GqlClientCopyProgramVersionDraftMutation = {
    __typename?: "Mutation";
    copyProgramVersionDraft: {
        __typename?: "CopyProgramVersionDraftOutput";
        result: GqlClientCopyProgramVersionDraftResult;
        targetProgramVersion?: {
            __typename: "ProgramVersion";
            sceneOrder: Array<string>;
            storyOrder: Array<string>;
            id: string;
            name: string;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            useCase?: string | null;
            audienceSize?: string | null;
            industry?: string | null;
            description?: string | null;
            program: {
                __typename: "Program";
                id: string;
                programType?: GqlClientProgramType | null;
                saleForceId?: string | null;
                accountId: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                anyUpdated?: string | null;
                updatedBy?: string | null;
                anyUpdatedBy?: string | null;
                name: string;
                description?: string | null;
                legacyBuilderProgramId?: string | null;
                legacyFrameworkProgramId?: string | null;
                legacyOverviewProgramId?: string | null;
                isEditor?: boolean | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                isArchive?: boolean | null;
                account: {
                    __typename?: "Account";
                    id: string;
                    displayName?: string | null;
                    name?: string | null;
                    programs: Array<{
                        __typename: "Program";
                        id: string;
                        programType?: GqlClientProgramType | null;
                        saleForceId?: string | null;
                        accountId: string;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        anyUpdated?: string | null;
                        updatedBy?: string | null;
                        anyUpdatedBy?: string | null;
                        name: string;
                        description?: string | null;
                        legacyBuilderProgramId?: string | null;
                        legacyFrameworkProgramId?: string | null;
                        legacyOverviewProgramId?: string | null;
                        isEditor?: boolean | null;
                        lifecycleStage: GqlClientAssetLifecycleStage;
                        isArchive?: boolean | null;
                        customAnalyticFields: Array<{
                            __typename: "CustomAnalyticField";
                            id: string;
                            accountId: string;
                            updated: string;
                            updatedBy?: string | null;
                            name?: string | null;
                            logic?: any | null;
                            program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                        }>;
                        surveys: Array<{
                            __typename?: "Survey";
                            id: string;
                            externalId: string;
                            updated: string;
                            questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                        }>;
                    }>;
                    campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                    reports: Array<{
                        __typename?: "AccountReport";
                        id: string;
                        name: string;
                        type: GqlClientAccountReportType;
                        timezone?: string | null;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        updatedBy?: string | null;
                        reportProgramType: GqlClientReportProgramType;
                        relevantPrograms: GqlClientRelevantPrograms;
                        filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
                        programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
                        campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                        reportElements: Array<{
                            __typename?: "ReportElement";
                            name: string;
                            platformName?: string | null;
                            description?: string | null;
                            platformDescription?: string | null;
                            elementType: GqlClientReportElementType;
                            formatType: GqlClientReportElementFormatType;
                            formatter?: any | null;
                            reportLibraryConfigKey: string;
                            placeHolder?: string | null;
                        }>;
                        schedules: Array<{
                            __typename?: "AccountSchedule";
                            id: string;
                            active?: boolean | null;
                            created: string;
                            createdBy?: string | null;
                            updated: string;
                            deliveryChannel?: any | null;
                            occurrence?: GqlClientReportScheduleOccurrence | null;
                            period?: GqlClientReportSchedulePeriod | null;
                            periodType?: GqlClientReportSchedulePeriodType | null;
                            startPeriod?: string | null;
                            sourceEnvironments?: Array<string> | null;
                            filenameTemplate?: string | null;
                        }>;
                    }>;
                };
                programVersionDrafts: Array<{
                    __typename: "ProgramVersion";
                    sceneOrder: Array<string>;
                    storyOrder: Array<string>;
                    id: string;
                    name: string;
                    updated: string;
                    updatedBy?: string | null;
                    anyUpdated?: string | null;
                    anyUpdatedBy?: string | null;
                    useCase?: string | null;
                    audienceSize?: string | null;
                    industry?: string | null;
                    description?: string | null;
                    goals: Array<{
                        __typename: "Goal";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        priority?: number | null;
                        kpis: Array<{
                            __typename: "KPI";
                            id: string;
                            updated: string;
                            name: string;
                            description?: string | null;
                            objective?: string | null;
                            scenes: Array<{ __typename?: "Scene"; id: string }>;
                            goal: { __typename?: "Goal"; id: string };
                        }>;
                    }>;
                    stories: Array<{
                        __typename: "Story";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        displaySceneOrder: Array<string>;
                        scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                        touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                        channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                    }>;
                    scenes: Array<{
                        __typename: "Scene";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                        kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                        personalizations: Array<
                            | {
                                  __typename: "AudiencePersonalization";
                                  id: string;
                                  updated: string;
                                  type: Array<string>;
                                  description?: string | null;
                                  audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                  audiencePersonalizationValues: Array<{
                                      __typename?: "AudiencePersonalizationValue";
                                      id: string;
                                      updated: string;
                                      description?: string | null;
                                      audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                  }>;
                              }
                            | {
                                  __typename: "ChannelPersonalization";
                                  id: string;
                                  updated: string;
                                  type: Array<string>;
                                  description?: string | null;
                                  channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                              }
                            | {
                                  __typename: "TouchpointPersonalization";
                                  id: string;
                                  updated: string;
                                  type: Array<string>;
                                  description?: string | null;
                                  touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                              }
                        >;
                        stories: Array<{ __typename?: "Story"; id: string }>;
                    }>;
                    audiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        impactsVolume?: boolean | null;
                        audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                        audienceValues: Array<{
                            __typename: "AudienceValue";
                            id: string;
                            updated: string;
                            name: string;
                            volume?: number | null;
                            audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                            subAudiences: Array<{
                                __typename: "Audience";
                                id: string;
                                updated: string;
                                name: string;
                                audienceValues: Array<{
                                    __typename: "AudienceValue";
                                    id: string;
                                    updated: string;
                                    name: string;
                                    volume?: number | null;
                                    audience: { __typename?: "Audience"; id: string };
                                }>;
                                audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                            }>;
                            audiencePersonalizationValues: Array<{
                                __typename?: "AudiencePersonalizationValue";
                                id: string;
                                description?: string | null;
                                audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                            }>;
                        }>;
                        feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    }>;
                    channels: Array<{
                        __typename: "Channel";
                        id: string;
                        updated: string;
                        name: string;
                        platforms?: Array<string> | null;
                        status?: string | null;
                        channelPersonalizations: Array<{
                            __typename?: "ChannelPersonalization";
                            id: string;
                            description?: string | null;
                            type: Array<string>;
                            scene: { __typename?: "Scene"; id: string; name: string };
                        }>;
                        stories: Array<{ __typename?: "Story"; id: string }>;
                    }>;
                    touchpoints: Array<{
                        __typename: "Touchpoint";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        touchpointPersonalizations: Array<{
                            __typename?: "TouchpointPersonalization";
                            id: string;
                            description?: string | null;
                            type: Array<string>;
                            scene: { __typename?: "Scene"; id: string; name: string };
                        }>;
                        stories: Array<{ __typename?: "Story"; id: string }>;
                    }>;
                    feedDataElements: Array<{
                        __typename: "FeedDataElement";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        source?: string | null;
                        status?: GqlClientFeedDataElementStatus | null;
                        scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                        audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                    }>;
                }>;
                customAnalyticFields: Array<{
                    __typename: "CustomAnalyticField";
                    id: string;
                    accountId: string;
                    updated: string;
                    updatedBy?: string | null;
                    name?: string | null;
                    logic?: any | null;
                    program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                }>;
                surveys: Array<{
                    __typename?: "Survey";
                    id: string;
                    externalId: string;
                    updated: string;
                    questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                }>;
            };
            goals: Array<{
                __typename: "Goal";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                priority?: number | null;
                kpis: Array<{
                    __typename: "KPI";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    objective?: string | null;
                    scenes: Array<{ __typename?: "Scene"; id: string }>;
                    goal: { __typename?: "Goal"; id: string };
                }>;
            }>;
            stories: Array<{
                __typename: "Story";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                displaySceneOrder: Array<string>;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
            }>;
            scenes: Array<{
                __typename: "Scene";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                personalizations: Array<
                    | {
                          __typename: "AudiencePersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                          audiencePersonalizationValues: Array<{
                              __typename?: "AudiencePersonalizationValue";
                              id: string;
                              updated: string;
                              description?: string | null;
                              audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                          }>;
                      }
                    | {
                          __typename: "ChannelPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                      }
                    | {
                          __typename: "TouchpointPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                      }
                >;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            audiences: Array<{
                __typename: "Audience";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                impactsVolume?: boolean | null;
                audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                audienceValues: Array<{
                    __typename: "AudienceValue";
                    id: string;
                    updated: string;
                    name: string;
                    volume?: number | null;
                    audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                    subAudiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                        audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                    }>;
                    audiencePersonalizationValues: Array<{
                        __typename?: "AudiencePersonalizationValue";
                        id: string;
                        description?: string | null;
                        audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                    }>;
                }>;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
            }>;
            channels: Array<{
                __typename: "Channel";
                id: string;
                updated: string;
                name: string;
                platforms?: Array<string> | null;
                status?: string | null;
                channelPersonalizations: Array<{
                    __typename?: "ChannelPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            touchpoints: Array<{
                __typename: "Touchpoint";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                touchpointPersonalizations: Array<{
                    __typename?: "TouchpointPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            feedDataElements: Array<{
                __typename: "FeedDataElement";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                source?: string | null;
                status?: GqlClientFeedDataElementStatus | null;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
            }>;
        } | null;
    };
};

export type GqlClientDeleteProgramVersionDraftMutationVariables = Exact<{
    input: GqlClientDeleteProgramVersionDraftInput;
}>;

export type GqlClientDeleteProgramVersionDraftMutation = {
    __typename?: "Mutation";
    deleteProgramVersionDraft: {
        __typename?: "DeleteProgramVersionDraftOutput";
        result: GqlClientDeleteProgramVersionDraftResult;
        program?: {
            __typename: "Program";
            id: string;
            programType?: GqlClientProgramType | null;
            saleForceId?: string | null;
            accountId: string;
            created: string;
            createdBy?: string | null;
            updated: string;
            anyUpdated?: string | null;
            updatedBy?: string | null;
            anyUpdatedBy?: string | null;
            name: string;
            description?: string | null;
            legacyBuilderProgramId?: string | null;
            legacyFrameworkProgramId?: string | null;
            legacyOverviewProgramId?: string | null;
            isEditor?: boolean | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            isArchive?: boolean | null;
            programVersionDrafts: Array<{
                __typename: "ProgramVersion";
                sceneOrder: Array<string>;
                storyOrder: Array<string>;
                id: string;
                name: string;
                updated: string;
                updatedBy?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                useCase?: string | null;
                audienceSize?: string | null;
                industry?: string | null;
                description?: string | null;
                goals: Array<{
                    __typename: "Goal";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    priority?: number | null;
                    kpis: Array<{
                        __typename: "KPI";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        objective?: string | null;
                        scenes: Array<{ __typename?: "Scene"; id: string }>;
                        goal: { __typename?: "Goal"; id: string };
                    }>;
                }>;
                stories: Array<{
                    __typename: "Story";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    displaySceneOrder: Array<string>;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                    channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                }>;
                scenes: Array<{
                    __typename: "Scene";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                    personalizations: Array<
                        | {
                              __typename: "AudiencePersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  updated: string;
                                  description?: string | null;
                                  audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                              }>;
                          }
                        | {
                              __typename: "ChannelPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                          }
                        | {
                              __typename: "TouchpointPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                          }
                    >;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                audiences: Array<{
                    __typename: "Audience";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    impactsVolume?: boolean | null;
                    audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                    audienceValues: Array<{
                        __typename: "AudienceValue";
                        id: string;
                        updated: string;
                        name: string;
                        volume?: number | null;
                        audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                        subAudiences: Array<{
                            __typename: "Audience";
                            id: string;
                            updated: string;
                            name: string;
                            audienceValues: Array<{
                                __typename: "AudienceValue";
                                id: string;
                                updated: string;
                                name: string;
                                volume?: number | null;
                                audience: { __typename?: "Audience"; id: string };
                            }>;
                            audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                        }>;
                        audiencePersonalizationValues: Array<{
                            __typename?: "AudiencePersonalizationValue";
                            id: string;
                            description?: string | null;
                            audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                        }>;
                    }>;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                }>;
                channels: Array<{
                    __typename: "Channel";
                    id: string;
                    updated: string;
                    name: string;
                    platforms?: Array<string> | null;
                    status?: string | null;
                    channelPersonalizations: Array<{
                        __typename?: "ChannelPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                touchpoints: Array<{
                    __typename: "Touchpoint";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    touchpointPersonalizations: Array<{
                        __typename?: "TouchpointPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                feedDataElements: Array<{
                    __typename: "FeedDataElement";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    source?: string | null;
                    status?: GqlClientFeedDataElementStatus | null;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                }>;
            }>;
            customAnalyticFields: Array<{
                __typename: "CustomAnalyticField";
                id: string;
                accountId: string;
                updated: string;
                updatedBy?: string | null;
                name?: string | null;
                logic?: any | null;
                program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
            }>;
            surveys: Array<{ __typename?: "Survey"; id: string; externalId: string; updated: string; questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }> }>;
        } | null;
    };
};

export type GqlClientMovePorgramVersionDraftMutationVariables = Exact<{
    input: GqlClientMoveProgramVersionDraftInput;
}>;

export type GqlClientMovePorgramVersionDraftMutation = {
    __typename?: "Mutation";
    moveProgramVersionDraft: {
        __typename?: "MoveProgramVersionDraftOutput";
        result: GqlClientMoveProgramVersionDraftResult;
        programVersion?: {
            __typename: "ProgramVersion";
            sceneOrder: Array<string>;
            storyOrder: Array<string>;
            id: string;
            name: string;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            useCase?: string | null;
            audienceSize?: string | null;
            industry?: string | null;
            description?: string | null;
            program: {
                __typename: "Program";
                id: string;
                programType?: GqlClientProgramType | null;
                saleForceId?: string | null;
                accountId: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                anyUpdated?: string | null;
                updatedBy?: string | null;
                anyUpdatedBy?: string | null;
                name: string;
                description?: string | null;
                legacyBuilderProgramId?: string | null;
                legacyFrameworkProgramId?: string | null;
                legacyOverviewProgramId?: string | null;
                isEditor?: boolean | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                isArchive?: boolean | null;
                programVersionDrafts: Array<{
                    __typename: "ProgramVersion";
                    sceneOrder: Array<string>;
                    storyOrder: Array<string>;
                    id: string;
                    name: string;
                    updated: string;
                    updatedBy?: string | null;
                    anyUpdated?: string | null;
                    anyUpdatedBy?: string | null;
                    useCase?: string | null;
                    audienceSize?: string | null;
                    industry?: string | null;
                    description?: string | null;
                    goals: Array<{
                        __typename: "Goal";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        priority?: number | null;
                        kpis: Array<{
                            __typename: "KPI";
                            id: string;
                            updated: string;
                            name: string;
                            description?: string | null;
                            objective?: string | null;
                            scenes: Array<{ __typename?: "Scene"; id: string }>;
                            goal: { __typename?: "Goal"; id: string };
                        }>;
                    }>;
                    stories: Array<{
                        __typename: "Story";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        displaySceneOrder: Array<string>;
                        scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                        touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                        channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                    }>;
                    scenes: Array<{
                        __typename: "Scene";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                        kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                        personalizations: Array<
                            | {
                                  __typename: "AudiencePersonalization";
                                  id: string;
                                  updated: string;
                                  type: Array<string>;
                                  description?: string | null;
                                  audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                  audiencePersonalizationValues: Array<{
                                      __typename?: "AudiencePersonalizationValue";
                                      id: string;
                                      updated: string;
                                      description?: string | null;
                                      audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                  }>;
                              }
                            | {
                                  __typename: "ChannelPersonalization";
                                  id: string;
                                  updated: string;
                                  type: Array<string>;
                                  description?: string | null;
                                  channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                              }
                            | {
                                  __typename: "TouchpointPersonalization";
                                  id: string;
                                  updated: string;
                                  type: Array<string>;
                                  description?: string | null;
                                  touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                              }
                        >;
                        stories: Array<{ __typename?: "Story"; id: string }>;
                    }>;
                    audiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        impactsVolume?: boolean | null;
                        audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                        audienceValues: Array<{
                            __typename: "AudienceValue";
                            id: string;
                            updated: string;
                            name: string;
                            volume?: number | null;
                            audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                            subAudiences: Array<{
                                __typename: "Audience";
                                id: string;
                                updated: string;
                                name: string;
                                audienceValues: Array<{
                                    __typename: "AudienceValue";
                                    id: string;
                                    updated: string;
                                    name: string;
                                    volume?: number | null;
                                    audience: { __typename?: "Audience"; id: string };
                                }>;
                                audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                            }>;
                            audiencePersonalizationValues: Array<{
                                __typename?: "AudiencePersonalizationValue";
                                id: string;
                                description?: string | null;
                                audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                            }>;
                        }>;
                        feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    }>;
                    channels: Array<{
                        __typename: "Channel";
                        id: string;
                        updated: string;
                        name: string;
                        platforms?: Array<string> | null;
                        status?: string | null;
                        channelPersonalizations: Array<{
                            __typename?: "ChannelPersonalization";
                            id: string;
                            description?: string | null;
                            type: Array<string>;
                            scene: { __typename?: "Scene"; id: string; name: string };
                        }>;
                        stories: Array<{ __typename?: "Story"; id: string }>;
                    }>;
                    touchpoints: Array<{
                        __typename: "Touchpoint";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        touchpointPersonalizations: Array<{
                            __typename?: "TouchpointPersonalization";
                            id: string;
                            description?: string | null;
                            type: Array<string>;
                            scene: { __typename?: "Scene"; id: string; name: string };
                        }>;
                        stories: Array<{ __typename?: "Story"; id: string }>;
                    }>;
                    feedDataElements: Array<{
                        __typename: "FeedDataElement";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        source?: string | null;
                        status?: GqlClientFeedDataElementStatus | null;
                        scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                        audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                    }>;
                }>;
                customAnalyticFields: Array<{
                    __typename: "CustomAnalyticField";
                    id: string;
                    accountId: string;
                    updated: string;
                    updatedBy?: string | null;
                    name?: string | null;
                    logic?: any | null;
                    program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                }>;
                surveys: Array<{
                    __typename?: "Survey";
                    id: string;
                    externalId: string;
                    updated: string;
                    questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                }>;
            };
            goals: Array<{
                __typename: "Goal";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                priority?: number | null;
                kpis: Array<{
                    __typename: "KPI";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    objective?: string | null;
                    scenes: Array<{ __typename?: "Scene"; id: string }>;
                    goal: { __typename?: "Goal"; id: string };
                }>;
            }>;
            stories: Array<{
                __typename: "Story";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                displaySceneOrder: Array<string>;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
            }>;
            scenes: Array<{
                __typename: "Scene";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                personalizations: Array<
                    | {
                          __typename: "AudiencePersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                          audiencePersonalizationValues: Array<{
                              __typename?: "AudiencePersonalizationValue";
                              id: string;
                              updated: string;
                              description?: string | null;
                              audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                          }>;
                      }
                    | {
                          __typename: "ChannelPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                      }
                    | {
                          __typename: "TouchpointPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                      }
                >;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            audiences: Array<{
                __typename: "Audience";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                impactsVolume?: boolean | null;
                audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                audienceValues: Array<{
                    __typename: "AudienceValue";
                    id: string;
                    updated: string;
                    name: string;
                    volume?: number | null;
                    audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                    subAudiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                        audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                    }>;
                    audiencePersonalizationValues: Array<{
                        __typename?: "AudiencePersonalizationValue";
                        id: string;
                        description?: string | null;
                        audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                    }>;
                }>;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
            }>;
            channels: Array<{
                __typename: "Channel";
                id: string;
                updated: string;
                name: string;
                platforms?: Array<string> | null;
                status?: string | null;
                channelPersonalizations: Array<{
                    __typename?: "ChannelPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            touchpoints: Array<{
                __typename: "Touchpoint";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                touchpointPersonalizations: Array<{
                    __typename?: "TouchpointPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            feedDataElements: Array<{
                __typename: "FeedDataElement";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                source?: string | null;
                status?: GqlClientFeedDataElementStatus | null;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
            }>;
        } | null;
        sourceProgram?: {
            __typename: "Program";
            id: string;
            programType?: GqlClientProgramType | null;
            saleForceId?: string | null;
            accountId: string;
            created: string;
            createdBy?: string | null;
            updated: string;
            anyUpdated?: string | null;
            updatedBy?: string | null;
            anyUpdatedBy?: string | null;
            name: string;
            description?: string | null;
            legacyBuilderProgramId?: string | null;
            legacyFrameworkProgramId?: string | null;
            legacyOverviewProgramId?: string | null;
            isEditor?: boolean | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            isArchive?: boolean | null;
            programVersionDrafts: Array<{
                __typename: "ProgramVersion";
                sceneOrder: Array<string>;
                storyOrder: Array<string>;
                id: string;
                name: string;
                updated: string;
                updatedBy?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                useCase?: string | null;
                audienceSize?: string | null;
                industry?: string | null;
                description?: string | null;
                goals: Array<{
                    __typename: "Goal";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    priority?: number | null;
                    kpis: Array<{
                        __typename: "KPI";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        objective?: string | null;
                        scenes: Array<{ __typename?: "Scene"; id: string }>;
                        goal: { __typename?: "Goal"; id: string };
                    }>;
                }>;
                stories: Array<{
                    __typename: "Story";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    displaySceneOrder: Array<string>;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                    channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                }>;
                scenes: Array<{
                    __typename: "Scene";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                    personalizations: Array<
                        | {
                              __typename: "AudiencePersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  updated: string;
                                  description?: string | null;
                                  audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                              }>;
                          }
                        | {
                              __typename: "ChannelPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                          }
                        | {
                              __typename: "TouchpointPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                          }
                    >;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                audiences: Array<{
                    __typename: "Audience";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    impactsVolume?: boolean | null;
                    audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                    audienceValues: Array<{
                        __typename: "AudienceValue";
                        id: string;
                        updated: string;
                        name: string;
                        volume?: number | null;
                        audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                        subAudiences: Array<{
                            __typename: "Audience";
                            id: string;
                            updated: string;
                            name: string;
                            audienceValues: Array<{
                                __typename: "AudienceValue";
                                id: string;
                                updated: string;
                                name: string;
                                volume?: number | null;
                                audience: { __typename?: "Audience"; id: string };
                            }>;
                            audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                        }>;
                        audiencePersonalizationValues: Array<{
                            __typename?: "AudiencePersonalizationValue";
                            id: string;
                            description?: string | null;
                            audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                        }>;
                    }>;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                }>;
                channels: Array<{
                    __typename: "Channel";
                    id: string;
                    updated: string;
                    name: string;
                    platforms?: Array<string> | null;
                    status?: string | null;
                    channelPersonalizations: Array<{
                        __typename?: "ChannelPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                touchpoints: Array<{
                    __typename: "Touchpoint";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    touchpointPersonalizations: Array<{
                        __typename?: "TouchpointPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                feedDataElements: Array<{
                    __typename: "FeedDataElement";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    source?: string | null;
                    status?: GqlClientFeedDataElementStatus | null;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                }>;
            }>;
            customAnalyticFields: Array<{
                __typename: "CustomAnalyticField";
                id: string;
                accountId: string;
                updated: string;
                updatedBy?: string | null;
                name?: string | null;
                logic?: any | null;
                program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
            }>;
            surveys: Array<{ __typename?: "Survey"; id: string; externalId: string; updated: string; questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }> }>;
        } | null;
    };
};

export type GqlClientProgramVersionFragment = {
    __typename: "ProgramVersion";
    sceneOrder: Array<string>;
    storyOrder: Array<string>;
    id: string;
    name: string;
    updated: string;
    updatedBy?: string | null;
    anyUpdated?: string | null;
    anyUpdatedBy?: string | null;
    useCase?: string | null;
    audienceSize?: string | null;
    industry?: string | null;
    description?: string | null;
    goals: Array<{
        __typename: "Goal";
        id: string;
        updated: string;
        name: string;
        description?: string | null;
        priority?: number | null;
        kpis: Array<{
            __typename: "KPI";
            id: string;
            updated: string;
            name: string;
            description?: string | null;
            objective?: string | null;
            scenes: Array<{ __typename?: "Scene"; id: string }>;
            goal: { __typename?: "Goal"; id: string };
        }>;
    }>;
    stories: Array<{
        __typename: "Story";
        id: string;
        updated: string;
        name: string;
        description?: string | null;
        displaySceneOrder: Array<string>;
        scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
        touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
        channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
    }>;
    scenes: Array<{
        __typename: "Scene";
        id: string;
        updated: string;
        name: string;
        description?: string | null;
        feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
        kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
        personalizations: Array<
            | {
                  __typename: "AudiencePersonalization";
                  id: string;
                  updated: string;
                  type: Array<string>;
                  description?: string | null;
                  audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                  audiencePersonalizationValues: Array<{
                      __typename?: "AudiencePersonalizationValue";
                      id: string;
                      updated: string;
                      description?: string | null;
                      audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                  }>;
              }
            | {
                  __typename: "ChannelPersonalization";
                  id: string;
                  updated: string;
                  type: Array<string>;
                  description?: string | null;
                  channel: { __typename?: "Channel"; id: string; updated: string; name: string };
              }
            | {
                  __typename: "TouchpointPersonalization";
                  id: string;
                  updated: string;
                  type: Array<string>;
                  description?: string | null;
                  touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
              }
        >;
        stories: Array<{ __typename?: "Story"; id: string }>;
    }>;
    audiences: Array<{
        __typename: "Audience";
        id: string;
        updated: string;
        name: string;
        description?: string | null;
        impactsVolume?: boolean | null;
        audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
        audienceValues: Array<{
            __typename: "AudienceValue";
            id: string;
            updated: string;
            name: string;
            volume?: number | null;
            audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
            subAudiences: Array<{
                __typename: "Audience";
                id: string;
                updated: string;
                name: string;
                audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
            }>;
            audiencePersonalizationValues: Array<{
                __typename?: "AudiencePersonalizationValue";
                id: string;
                description?: string | null;
                audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
            }>;
        }>;
        feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
    }>;
    channels: Array<{
        __typename: "Channel";
        id: string;
        updated: string;
        name: string;
        platforms?: Array<string> | null;
        status?: string | null;
        channelPersonalizations: Array<{
            __typename?: "ChannelPersonalization";
            id: string;
            description?: string | null;
            type: Array<string>;
            scene: { __typename?: "Scene"; id: string; name: string };
        }>;
        stories: Array<{ __typename?: "Story"; id: string }>;
    }>;
    touchpoints: Array<{
        __typename: "Touchpoint";
        id: string;
        updated: string;
        name: string;
        description?: string | null;
        touchpointPersonalizations: Array<{
            __typename?: "TouchpointPersonalization";
            id: string;
            description?: string | null;
            type: Array<string>;
            scene: { __typename?: "Scene"; id: string; name: string };
        }>;
        stories: Array<{ __typename?: "Story"; id: string }>;
    }>;
    feedDataElements: Array<{
        __typename: "FeedDataElement";
        id: string;
        updated: string;
        name: string;
        description?: string | null;
        source?: string | null;
        status?: GqlClientFeedDataElementStatus | null;
        scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
        audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
    }>;
};

export type GqlClientProgramVersionMetadataFragment = {
    __typename: "ProgramVersion";
    id: string;
    name: string;
    updated: string;
    updatedBy?: string | null;
    anyUpdated?: string | null;
    anyUpdatedBy?: string | null;
    useCase?: string | null;
    audienceSize?: string | null;
    industry?: string | null;
    description?: string | null;
};

export type GqlClientUpdateProgramVersionDraftMutationVariables = Exact<{
    input: GqlClientUpdateProgramVersionDraftInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateProgramVersionDraftMutation = {
    __typename?: "Mutation";
    updateProgramVersionDraft:
        | { __typename?: "UpdateProgramVersionDraftOutputConflict"; result: GqlClientUpdateProgramVersionDraftResult }
        | {
              __typename?: "UpdateProgramVersionDraftOutputSuccess";
              result: GqlClientUpdateProgramVersionDraftResult;
              programVersion?: {
                  __typename: "ProgramVersion";
                  sceneOrder: Array<string>;
                  storyOrder: Array<string>;
                  id: string;
                  name: string;
                  updated: string;
                  updatedBy?: string | null;
                  anyUpdated?: string | null;
                  anyUpdatedBy?: string | null;
                  useCase?: string | null;
                  audienceSize?: string | null;
                  industry?: string | null;
                  description?: string | null;
                  program: {
                      __typename: "Program";
                      id: string;
                      programType?: GqlClientProgramType | null;
                      saleForceId?: string | null;
                      accountId: string;
                      created: string;
                      createdBy?: string | null;
                      updated: string;
                      anyUpdated?: string | null;
                      updatedBy?: string | null;
                      anyUpdatedBy?: string | null;
                      name: string;
                      description?: string | null;
                      legacyBuilderProgramId?: string | null;
                      legacyFrameworkProgramId?: string | null;
                      legacyOverviewProgramId?: string | null;
                      isEditor?: boolean | null;
                      lifecycleStage: GqlClientAssetLifecycleStage;
                      isArchive?: boolean | null;
                      programVersionDrafts: Array<{
                          __typename: "ProgramVersion";
                          sceneOrder: Array<string>;
                          storyOrder: Array<string>;
                          id: string;
                          name: string;
                          updated: string;
                          updatedBy?: string | null;
                          anyUpdated?: string | null;
                          anyUpdatedBy?: string | null;
                          useCase?: string | null;
                          audienceSize?: string | null;
                          industry?: string | null;
                          description?: string | null;
                          goals: Array<{
                              __typename: "Goal";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              priority?: number | null;
                              kpis: Array<{
                                  __typename: "KPI";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  description?: string | null;
                                  objective?: string | null;
                                  scenes: Array<{ __typename?: "Scene"; id: string }>;
                                  goal: { __typename?: "Goal"; id: string };
                              }>;
                          }>;
                          stories: Array<{
                              __typename: "Story";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              displaySceneOrder: Array<string>;
                              scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                              touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                              channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                          }>;
                          scenes: Array<{
                              __typename: "Scene";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                              kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                              personalizations: Array<
                                  | {
                                        __typename: "AudiencePersonalization";
                                        id: string;
                                        updated: string;
                                        type: Array<string>;
                                        description?: string | null;
                                        audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                        audiencePersonalizationValues: Array<{
                                            __typename?: "AudiencePersonalizationValue";
                                            id: string;
                                            updated: string;
                                            description?: string | null;
                                            audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                        }>;
                                    }
                                  | {
                                        __typename: "ChannelPersonalization";
                                        id: string;
                                        updated: string;
                                        type: Array<string>;
                                        description?: string | null;
                                        channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                                    }
                                  | {
                                        __typename: "TouchpointPersonalization";
                                        id: string;
                                        updated: string;
                                        type: Array<string>;
                                        description?: string | null;
                                        touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                                    }
                              >;
                              stories: Array<{ __typename?: "Story"; id: string }>;
                          }>;
                          audiences: Array<{
                              __typename: "Audience";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              impactsVolume?: boolean | null;
                              audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                              audienceValues: Array<{
                                  __typename: "AudienceValue";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  volume?: number | null;
                                  audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                  subAudiences: Array<{
                                      __typename: "Audience";
                                      id: string;
                                      updated: string;
                                      name: string;
                                      audienceValues: Array<{
                                          __typename: "AudienceValue";
                                          id: string;
                                          updated: string;
                                          name: string;
                                          volume?: number | null;
                                          audience: { __typename?: "Audience"; id: string };
                                      }>;
                                      audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                                  }>;
                                  audiencePersonalizationValues: Array<{
                                      __typename?: "AudiencePersonalizationValue";
                                      id: string;
                                      description?: string | null;
                                      audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                                  }>;
                              }>;
                              feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                          }>;
                          channels: Array<{
                              __typename: "Channel";
                              id: string;
                              updated: string;
                              name: string;
                              platforms?: Array<string> | null;
                              status?: string | null;
                              channelPersonalizations: Array<{
                                  __typename?: "ChannelPersonalization";
                                  id: string;
                                  description?: string | null;
                                  type: Array<string>;
                                  scene: { __typename?: "Scene"; id: string; name: string };
                              }>;
                              stories: Array<{ __typename?: "Story"; id: string }>;
                          }>;
                          touchpoints: Array<{
                              __typename: "Touchpoint";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              touchpointPersonalizations: Array<{
                                  __typename?: "TouchpointPersonalization";
                                  id: string;
                                  description?: string | null;
                                  type: Array<string>;
                                  scene: { __typename?: "Scene"; id: string; name: string };
                              }>;
                              stories: Array<{ __typename?: "Story"; id: string }>;
                          }>;
                          feedDataElements: Array<{
                              __typename: "FeedDataElement";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              source?: string | null;
                              status?: GqlClientFeedDataElementStatus | null;
                              scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                              audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                          }>;
                      }>;
                      customAnalyticFields: Array<{
                          __typename: "CustomAnalyticField";
                          id: string;
                          accountId: string;
                          updated: string;
                          updatedBy?: string | null;
                          name?: string | null;
                          logic?: any | null;
                          program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                      }>;
                      surveys: Array<{
                          __typename?: "Survey";
                          id: string;
                          externalId: string;
                          updated: string;
                          questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                      }>;
                  };
                  goals: Array<{
                      __typename: "Goal";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      priority?: number | null;
                      kpis: Array<{
                          __typename: "KPI";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          objective?: string | null;
                          scenes: Array<{ __typename?: "Scene"; id: string }>;
                          goal: { __typename?: "Goal"; id: string };
                      }>;
                  }>;
                  stories: Array<{
                      __typename: "Story";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      displaySceneOrder: Array<string>;
                      scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                      touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                      channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                  }>;
                  scenes: Array<{
                      __typename: "Scene";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                      kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                      personalizations: Array<
                          | {
                                __typename: "AudiencePersonalization";
                                id: string;
                                updated: string;
                                type: Array<string>;
                                description?: string | null;
                                audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                audiencePersonalizationValues: Array<{
                                    __typename?: "AudiencePersonalizationValue";
                                    id: string;
                                    updated: string;
                                    description?: string | null;
                                    audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                }>;
                            }
                          | {
                                __typename: "ChannelPersonalization";
                                id: string;
                                updated: string;
                                type: Array<string>;
                                description?: string | null;
                                channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                            }
                          | {
                                __typename: "TouchpointPersonalization";
                                id: string;
                                updated: string;
                                type: Array<string>;
                                description?: string | null;
                                touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                            }
                      >;
                      stories: Array<{ __typename?: "Story"; id: string }>;
                  }>;
                  audiences: Array<{
                      __typename: "Audience";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      impactsVolume?: boolean | null;
                      audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                      audienceValues: Array<{
                          __typename: "AudienceValue";
                          id: string;
                          updated: string;
                          name: string;
                          volume?: number | null;
                          audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                          subAudiences: Array<{
                              __typename: "Audience";
                              id: string;
                              updated: string;
                              name: string;
                              audienceValues: Array<{
                                  __typename: "AudienceValue";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  volume?: number | null;
                                  audience: { __typename?: "Audience"; id: string };
                              }>;
                              audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                          }>;
                          audiencePersonalizationValues: Array<{
                              __typename?: "AudiencePersonalizationValue";
                              id: string;
                              description?: string | null;
                              audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                          }>;
                      }>;
                      feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                  }>;
                  channels: Array<{
                      __typename: "Channel";
                      id: string;
                      updated: string;
                      name: string;
                      platforms?: Array<string> | null;
                      status?: string | null;
                      channelPersonalizations: Array<{
                          __typename?: "ChannelPersonalization";
                          id: string;
                          description?: string | null;
                          type: Array<string>;
                          scene: { __typename?: "Scene"; id: string; name: string };
                      }>;
                      stories: Array<{ __typename?: "Story"; id: string }>;
                  }>;
                  touchpoints: Array<{
                      __typename: "Touchpoint";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      touchpointPersonalizations: Array<{
                          __typename?: "TouchpointPersonalization";
                          id: string;
                          description?: string | null;
                          type: Array<string>;
                          scene: { __typename?: "Scene"; id: string; name: string };
                      }>;
                      stories: Array<{ __typename?: "Story"; id: string }>;
                  }>;
                  feedDataElements: Array<{
                      __typename: "FeedDataElement";
                      id: string;
                      updated: string;
                      name: string;
                      description?: string | null;
                      source?: string | null;
                      status?: GqlClientFeedDataElementStatus | null;
                      scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                      audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                  }>;
              } | null;
          };
};

export type GqlClientCreateSceneMutationVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
    input: GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsInput;
}>;

export type GqlClientCreateSceneMutation = {
    __typename?: "Mutation";
    createSceneWithKPIsPersonalizationsAndDataElements: {
        __typename?: "CreateSceneWithKPIsPersonalizationsAndDataElementsOutput";
        result: GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsResult;
        scene?: {
            __typename: "Scene";
            id: string;
            updated: string;
            name: string;
            description?: string | null;
            programVersion: {
                __typename: "ProgramVersion";
                sceneOrder: Array<string>;
                storyOrder: Array<string>;
                id: string;
                name: string;
                updated: string;
                updatedBy?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                useCase?: string | null;
                audienceSize?: string | null;
                industry?: string | null;
                description?: string | null;
                goals: Array<{
                    __typename: "Goal";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    priority?: number | null;
                    kpis: Array<{
                        __typename: "KPI";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        objective?: string | null;
                        scenes: Array<{ __typename?: "Scene"; id: string }>;
                        goal: { __typename?: "Goal"; id: string };
                    }>;
                }>;
                stories: Array<{
                    __typename: "Story";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    displaySceneOrder: Array<string>;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                    channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                }>;
                scenes: Array<{
                    __typename: "Scene";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                    personalizations: Array<
                        | {
                              __typename: "AudiencePersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  updated: string;
                                  description?: string | null;
                                  audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                              }>;
                          }
                        | {
                              __typename: "ChannelPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                          }
                        | {
                              __typename: "TouchpointPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                          }
                    >;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                audiences: Array<{
                    __typename: "Audience";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    impactsVolume?: boolean | null;
                    audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                    audienceValues: Array<{
                        __typename: "AudienceValue";
                        id: string;
                        updated: string;
                        name: string;
                        volume?: number | null;
                        audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                        subAudiences: Array<{
                            __typename: "Audience";
                            id: string;
                            updated: string;
                            name: string;
                            audienceValues: Array<{
                                __typename: "AudienceValue";
                                id: string;
                                updated: string;
                                name: string;
                                volume?: number | null;
                                audience: { __typename?: "Audience"; id: string };
                            }>;
                            audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                        }>;
                        audiencePersonalizationValues: Array<{
                            __typename?: "AudiencePersonalizationValue";
                            id: string;
                            description?: string | null;
                            audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                        }>;
                    }>;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                }>;
                channels: Array<{
                    __typename: "Channel";
                    id: string;
                    updated: string;
                    name: string;
                    platforms?: Array<string> | null;
                    status?: string | null;
                    channelPersonalizations: Array<{
                        __typename?: "ChannelPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                touchpoints: Array<{
                    __typename: "Touchpoint";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    touchpointPersonalizations: Array<{
                        __typename?: "TouchpointPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                feedDataElements: Array<{
                    __typename: "FeedDataElement";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    source?: string | null;
                    status?: GqlClientFeedDataElementStatus | null;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                }>;
            };
            feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
            kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
            personalizations: Array<
                | {
                      __typename: "AudiencePersonalization";
                      id: string;
                      updated: string;
                      type: Array<string>;
                      description?: string | null;
                      audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                      audiencePersonalizationValues: Array<{
                          __typename?: "AudiencePersonalizationValue";
                          id: string;
                          updated: string;
                          description?: string | null;
                          audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                      }>;
                  }
                | {
                      __typename: "ChannelPersonalization";
                      id: string;
                      updated: string;
                      type: Array<string>;
                      description?: string | null;
                      channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                  }
                | {
                      __typename: "TouchpointPersonalization";
                      id: string;
                      updated: string;
                      type: Array<string>;
                      description?: string | null;
                      touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                  }
            >;
            stories: Array<{ __typename?: "Story"; id: string }>;
        } | null;
    };
};

type GqlClientPersonalizationAudiencePersonalizationFragment = {
    __typename: "AudiencePersonalization";
    id: string;
    updated: string;
    type: Array<string>;
    description?: string | null;
    audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
    audiencePersonalizationValues: Array<{
        __typename?: "AudiencePersonalizationValue";
        id: string;
        updated: string;
        description?: string | null;
        audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
    }>;
};

type GqlClientPersonalizationChannelPersonalizationFragment = {
    __typename: "ChannelPersonalization";
    id: string;
    updated: string;
    type: Array<string>;
    description?: string | null;
    channel: { __typename?: "Channel"; id: string; updated: string; name: string };
};

type GqlClientPersonalizationTouchpointPersonalizationFragment = {
    __typename: "TouchpointPersonalization";
    id: string;
    updated: string;
    type: Array<string>;
    description?: string | null;
    touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
};

export type GqlClientPersonalizationFragment =
    | GqlClientPersonalizationAudiencePersonalizationFragment
    | GqlClientPersonalizationChannelPersonalizationFragment
    | GqlClientPersonalizationTouchpointPersonalizationFragment;

export type GqlClientSceneFragment = {
    __typename: "Scene";
    id: string;
    updated: string;
    name: string;
    description?: string | null;
    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
    personalizations: Array<
        | {
              __typename: "AudiencePersonalization";
              id: string;
              updated: string;
              type: Array<string>;
              description?: string | null;
              audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
              audiencePersonalizationValues: Array<{
                  __typename?: "AudiencePersonalizationValue";
                  id: string;
                  updated: string;
                  description?: string | null;
                  audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
              }>;
          }
        | {
              __typename: "ChannelPersonalization";
              id: string;
              updated: string;
              type: Array<string>;
              description?: string | null;
              channel: { __typename?: "Channel"; id: string; updated: string; name: string };
          }
        | {
              __typename: "TouchpointPersonalization";
              id: string;
              updated: string;
              type: Array<string>;
              description?: string | null;
              touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
          }
    >;
    stories: Array<{ __typename?: "Story"; id: string }>;
};

export type GqlClientSceneSupportersFragment = { __typename?: "Story"; touchpoints: Array<{ __typename?: "Touchpoint"; id: string }>; channels: Array<{ __typename?: "Channel"; id: string }> };

export type GqlClientUpdateSceneMutationVariables = Exact<{
    input: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateSceneMutation = {
    __typename?: "Mutation";
    updateSceneWithKPIsPersonalizationsAndDataElements:
        | { __typename?: "UpdateSceneWithKPIsPersonalizationsAndDataElementsOutputConflict"; result: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsResult }
        | {
              __typename?: "UpdateSceneWithKPIsPersonalizationsAndDataElementsOutputSuccess";
              result: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsResult;
              scene?: {
                  __typename: "Scene";
                  id: string;
                  updated: string;
                  name: string;
                  description?: string | null;
                  programVersion: {
                      __typename: "ProgramVersion";
                      sceneOrder: Array<string>;
                      storyOrder: Array<string>;
                      id: string;
                      name: string;
                      updated: string;
                      updatedBy?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                      useCase?: string | null;
                      audienceSize?: string | null;
                      industry?: string | null;
                      description?: string | null;
                      goals: Array<{
                          __typename: "Goal";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          priority?: number | null;
                          kpis: Array<{
                              __typename: "KPI";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              objective?: string | null;
                              scenes: Array<{ __typename?: "Scene"; id: string }>;
                              goal: { __typename?: "Goal"; id: string };
                          }>;
                      }>;
                      stories: Array<{
                          __typename: "Story";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          displaySceneOrder: Array<string>;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                          channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                      }>;
                      scenes: Array<{
                          __typename: "Scene";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                          kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                          personalizations: Array<
                              | {
                                    __typename: "AudiencePersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                    audiencePersonalizationValues: Array<{
                                        __typename?: "AudiencePersonalizationValue";
                                        id: string;
                                        updated: string;
                                        description?: string | null;
                                        audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                    }>;
                                }
                              | {
                                    __typename: "ChannelPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                                }
                              | {
                                    __typename: "TouchpointPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                                }
                          >;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      audiences: Array<{
                          __typename: "Audience";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          impactsVolume?: boolean | null;
                          audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                          audienceValues: Array<{
                              __typename: "AudienceValue";
                              id: string;
                              updated: string;
                              name: string;
                              volume?: number | null;
                              audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              subAudiences: Array<{
                                  __typename: "Audience";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  audienceValues: Array<{
                                      __typename: "AudienceValue";
                                      id: string;
                                      updated: string;
                                      name: string;
                                      volume?: number | null;
                                      audience: { __typename?: "Audience"; id: string };
                                  }>;
                                  audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                              }>;
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  description?: string | null;
                                  audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                              }>;
                          }>;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                      }>;
                      channels: Array<{
                          __typename: "Channel";
                          id: string;
                          updated: string;
                          name: string;
                          platforms?: Array<string> | null;
                          status?: string | null;
                          channelPersonalizations: Array<{
                              __typename?: "ChannelPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      touchpoints: Array<{
                          __typename: "Touchpoint";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          touchpointPersonalizations: Array<{
                              __typename?: "TouchpointPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      feedDataElements: Array<{
                          __typename: "FeedDataElement";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          source?: string | null;
                          status?: GqlClientFeedDataElementStatus | null;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                      }>;
                  };
                  feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                  kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                  personalizations: Array<
                      | {
                            __typename: "AudiencePersonalization";
                            id: string;
                            updated: string;
                            type: Array<string>;
                            description?: string | null;
                            audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                            audiencePersonalizationValues: Array<{
                                __typename?: "AudiencePersonalizationValue";
                                id: string;
                                updated: string;
                                description?: string | null;
                                audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                            }>;
                        }
                      | {
                            __typename: "ChannelPersonalization";
                            id: string;
                            updated: string;
                            type: Array<string>;
                            description?: string | null;
                            channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                        }
                      | {
                            __typename: "TouchpointPersonalization";
                            id: string;
                            updated: string;
                            type: Array<string>;
                            description?: string | null;
                            touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                        }
                  >;
                  stories: Array<{ __typename?: "Story"; id: string }>;
              } | null;
          };
};

export type GqlClientCreateStoryMutationVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
    input: GqlClientCreateStoryWithReachAndScenesInput;
}>;

export type GqlClientCreateStoryMutation = {
    __typename?: "Mutation";
    createStoryWithReachAndScenes: {
        __typename?: "CreateStoryWithReachAndScenesOutput";
        result: GqlClientCreateStoryWithReachAndScenesResult;
        story?: {
            __typename: "Story";
            id: string;
            updated: string;
            name: string;
            description?: string | null;
            displaySceneOrder: Array<string>;
            programVersion: {
                __typename: "ProgramVersion";
                sceneOrder: Array<string>;
                storyOrder: Array<string>;
                id: string;
                name: string;
                updated: string;
                updatedBy?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                useCase?: string | null;
                audienceSize?: string | null;
                industry?: string | null;
                description?: string | null;
                goals: Array<{
                    __typename: "Goal";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    priority?: number | null;
                    kpis: Array<{
                        __typename: "KPI";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        objective?: string | null;
                        scenes: Array<{ __typename?: "Scene"; id: string }>;
                        goal: { __typename?: "Goal"; id: string };
                    }>;
                }>;
                stories: Array<{
                    __typename: "Story";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    displaySceneOrder: Array<string>;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                    channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                }>;
                scenes: Array<{
                    __typename: "Scene";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                    personalizations: Array<
                        | {
                              __typename: "AudiencePersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  updated: string;
                                  description?: string | null;
                                  audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                              }>;
                          }
                        | {
                              __typename: "ChannelPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                          }
                        | {
                              __typename: "TouchpointPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                          }
                    >;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                audiences: Array<{
                    __typename: "Audience";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    impactsVolume?: boolean | null;
                    audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                    audienceValues: Array<{
                        __typename: "AudienceValue";
                        id: string;
                        updated: string;
                        name: string;
                        volume?: number | null;
                        audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                        subAudiences: Array<{
                            __typename: "Audience";
                            id: string;
                            updated: string;
                            name: string;
                            audienceValues: Array<{
                                __typename: "AudienceValue";
                                id: string;
                                updated: string;
                                name: string;
                                volume?: number | null;
                                audience: { __typename?: "Audience"; id: string };
                            }>;
                            audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                        }>;
                        audiencePersonalizationValues: Array<{
                            __typename?: "AudiencePersonalizationValue";
                            id: string;
                            description?: string | null;
                            audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                        }>;
                    }>;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                }>;
                channels: Array<{
                    __typename: "Channel";
                    id: string;
                    updated: string;
                    name: string;
                    platforms?: Array<string> | null;
                    status?: string | null;
                    channelPersonalizations: Array<{
                        __typename?: "ChannelPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                touchpoints: Array<{
                    __typename: "Touchpoint";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    touchpointPersonalizations: Array<{
                        __typename?: "TouchpointPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                feedDataElements: Array<{
                    __typename: "FeedDataElement";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    source?: string | null;
                    status?: GqlClientFeedDataElementStatus | null;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                }>;
            };
            scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
            touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
            channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
        } | null;
    };
};

export type GqlClientDeleteStoryMutationVariables = Exact<{
    input: GqlClientDeleteStoryInput;
}>;

export type GqlClientDeleteStoryMutation = {
    __typename?: "Mutation";
    deleteStory: {
        __typename?: "DeleteStoryOutput";
        result: GqlClientDeleteStoryResult;
        programVersion?: {
            __typename: "ProgramVersion";
            sceneOrder: Array<string>;
            storyOrder: Array<string>;
            id: string;
            name: string;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            useCase?: string | null;
            audienceSize?: string | null;
            industry?: string | null;
            description?: string | null;
            goals: Array<{
                __typename: "Goal";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                priority?: number | null;
                kpis: Array<{
                    __typename: "KPI";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    objective?: string | null;
                    scenes: Array<{ __typename?: "Scene"; id: string }>;
                    goal: { __typename?: "Goal"; id: string };
                }>;
            }>;
            stories: Array<{
                __typename: "Story";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                displaySceneOrder: Array<string>;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
            }>;
            scenes: Array<{
                __typename: "Scene";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                personalizations: Array<
                    | {
                          __typename: "AudiencePersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                          audiencePersonalizationValues: Array<{
                              __typename?: "AudiencePersonalizationValue";
                              id: string;
                              updated: string;
                              description?: string | null;
                              audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                          }>;
                      }
                    | {
                          __typename: "ChannelPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                      }
                    | {
                          __typename: "TouchpointPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                      }
                >;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            audiences: Array<{
                __typename: "Audience";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                impactsVolume?: boolean | null;
                audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                audienceValues: Array<{
                    __typename: "AudienceValue";
                    id: string;
                    updated: string;
                    name: string;
                    volume?: number | null;
                    audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                    subAudiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                        audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                    }>;
                    audiencePersonalizationValues: Array<{
                        __typename?: "AudiencePersonalizationValue";
                        id: string;
                        description?: string | null;
                        audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                    }>;
                }>;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
            }>;
            channels: Array<{
                __typename: "Channel";
                id: string;
                updated: string;
                name: string;
                platforms?: Array<string> | null;
                status?: string | null;
                channelPersonalizations: Array<{
                    __typename?: "ChannelPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            touchpoints: Array<{
                __typename: "Touchpoint";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                touchpointPersonalizations: Array<{
                    __typename?: "TouchpointPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            feedDataElements: Array<{
                __typename: "FeedDataElement";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                source?: string | null;
                status?: GqlClientFeedDataElementStatus | null;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
            }>;
        } | null;
    };
};

export type GqlClientStoryFragment = {
    __typename: "Story";
    id: string;
    updated: string;
    name: string;
    description?: string | null;
    displaySceneOrder: Array<string>;
    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
    touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
    channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
};

export type GqlClientStorySupportersFragment = {
    __typename?: "Scene";
    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }>;
    personalizations: Array<
        | {
              __typename?: "AudiencePersonalization";
              audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
              audiencePersonalizationValues: Array<{ __typename?: "AudiencePersonalizationValue"; audienceValue: { __typename?: "AudienceValue"; id: string } }>;
          }
        | { __typename?: "ChannelPersonalization"; channel: { __typename?: "Channel"; id: string } }
        | { __typename?: "TouchpointPersonalization"; touchpoint: { __typename?: "Touchpoint"; id: string } }
    >;
};

export type GqlClientUpdateStoryMutationVariables = Exact<{
    input: GqlClientUpdateStoryWithReachAndScenesInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateStoryMutation = {
    __typename?: "Mutation";
    updateStoryWithReachAndScenes:
        | { __typename?: "UpdateStoryWithReachAndScenesOutputConflict"; result: GqlClientUpdateStoryWithReachAndScenesResult }
        | {
              __typename?: "UpdateStoryWithReachAndScenesOutputSuccess";
              result: GqlClientUpdateStoryWithReachAndScenesResult;
              story?: {
                  __typename: "Story";
                  id: string;
                  updated: string;
                  name: string;
                  description?: string | null;
                  displaySceneOrder: Array<string>;
                  programVersion: {
                      __typename: "ProgramVersion";
                      sceneOrder: Array<string>;
                      storyOrder: Array<string>;
                      id: string;
                      name: string;
                      updated: string;
                      updatedBy?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                      useCase?: string | null;
                      audienceSize?: string | null;
                      industry?: string | null;
                      description?: string | null;
                      goals: Array<{
                          __typename: "Goal";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          priority?: number | null;
                          kpis: Array<{
                              __typename: "KPI";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              objective?: string | null;
                              scenes: Array<{ __typename?: "Scene"; id: string }>;
                              goal: { __typename?: "Goal"; id: string };
                          }>;
                      }>;
                      stories: Array<{
                          __typename: "Story";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          displaySceneOrder: Array<string>;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                          channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                      }>;
                      scenes: Array<{
                          __typename: "Scene";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                          kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                          personalizations: Array<
                              | {
                                    __typename: "AudiencePersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                    audiencePersonalizationValues: Array<{
                                        __typename?: "AudiencePersonalizationValue";
                                        id: string;
                                        updated: string;
                                        description?: string | null;
                                        audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                    }>;
                                }
                              | {
                                    __typename: "ChannelPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                                }
                              | {
                                    __typename: "TouchpointPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                                }
                          >;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      audiences: Array<{
                          __typename: "Audience";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          impactsVolume?: boolean | null;
                          audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                          audienceValues: Array<{
                              __typename: "AudienceValue";
                              id: string;
                              updated: string;
                              name: string;
                              volume?: number | null;
                              audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              subAudiences: Array<{
                                  __typename: "Audience";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  audienceValues: Array<{
                                      __typename: "AudienceValue";
                                      id: string;
                                      updated: string;
                                      name: string;
                                      volume?: number | null;
                                      audience: { __typename?: "Audience"; id: string };
                                  }>;
                                  audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                              }>;
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  description?: string | null;
                                  audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                              }>;
                          }>;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                      }>;
                      channels: Array<{
                          __typename: "Channel";
                          id: string;
                          updated: string;
                          name: string;
                          platforms?: Array<string> | null;
                          status?: string | null;
                          channelPersonalizations: Array<{
                              __typename?: "ChannelPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      touchpoints: Array<{
                          __typename: "Touchpoint";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          touchpointPersonalizations: Array<{
                              __typename?: "TouchpointPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      feedDataElements: Array<{
                          __typename: "FeedDataElement";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          source?: string | null;
                          status?: GqlClientFeedDataElementStatus | null;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                      }>;
                  };
                  scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                  touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                  channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
              } | null;
          };
};

export type GqlClientUpdateStoryForFrameworkMutationVariables = Exact<{
    input: GqlClientUpdateStoryForFrameworkInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateStoryForFrameworkMutation = {
    __typename?: "Mutation";
    updateStoryForFramework:
        | { __typename?: "UpdateStoryForFrameworkOutputConflict"; result: GqlClientUpdateStoryForFrameworkResult }
        | {
              __typename?: "UpdateStoryForFrameworkOutputSuccess";
              result: GqlClientUpdateStoryForFrameworkResult;
              story?: {
                  __typename: "Story";
                  id: string;
                  updated: string;
                  name: string;
                  description?: string | null;
                  displaySceneOrder: Array<string>;
                  programVersion: {
                      __typename: "ProgramVersion";
                      sceneOrder: Array<string>;
                      storyOrder: Array<string>;
                      id: string;
                      name: string;
                      updated: string;
                      updatedBy?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                      useCase?: string | null;
                      audienceSize?: string | null;
                      industry?: string | null;
                      description?: string | null;
                      goals: Array<{
                          __typename: "Goal";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          priority?: number | null;
                          kpis: Array<{
                              __typename: "KPI";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              objective?: string | null;
                              scenes: Array<{ __typename?: "Scene"; id: string }>;
                              goal: { __typename?: "Goal"; id: string };
                          }>;
                      }>;
                      stories: Array<{
                          __typename: "Story";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          displaySceneOrder: Array<string>;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                          channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                      }>;
                      scenes: Array<{
                          __typename: "Scene";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                          kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                          personalizations: Array<
                              | {
                                    __typename: "AudiencePersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                    audiencePersonalizationValues: Array<{
                                        __typename?: "AudiencePersonalizationValue";
                                        id: string;
                                        updated: string;
                                        description?: string | null;
                                        audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                    }>;
                                }
                              | {
                                    __typename: "ChannelPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                                }
                              | {
                                    __typename: "TouchpointPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                                }
                          >;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      audiences: Array<{
                          __typename: "Audience";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          impactsVolume?: boolean | null;
                          audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                          audienceValues: Array<{
                              __typename: "AudienceValue";
                              id: string;
                              updated: string;
                              name: string;
                              volume?: number | null;
                              audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              subAudiences: Array<{
                                  __typename: "Audience";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  audienceValues: Array<{
                                      __typename: "AudienceValue";
                                      id: string;
                                      updated: string;
                                      name: string;
                                      volume?: number | null;
                                      audience: { __typename?: "Audience"; id: string };
                                  }>;
                                  audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                              }>;
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  description?: string | null;
                                  audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                              }>;
                          }>;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                      }>;
                      channels: Array<{
                          __typename: "Channel";
                          id: string;
                          updated: string;
                          name: string;
                          platforms?: Array<string> | null;
                          status?: string | null;
                          channelPersonalizations: Array<{
                              __typename?: "ChannelPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      touchpoints: Array<{
                          __typename: "Touchpoint";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          touchpointPersonalizations: Array<{
                              __typename?: "TouchpointPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      feedDataElements: Array<{
                          __typename: "FeedDataElement";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          source?: string | null;
                          status?: GqlClientFeedDataElementStatus | null;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                      }>;
                  };
                  scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                  touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                  channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
              } | null;
          };
};

export type GqlClientCreateTouchpointMutationVariables = Exact<{
    programVersionId: Scalars["ID"]["input"];
    input: GqlClientCreateTouchpointInput;
}>;

export type GqlClientCreateTouchpointMutation = {
    __typename?: "Mutation";
    createTouchpoint: {
        __typename?: "CreateTouchpointOutput";
        result: GqlClientCreateTouchpointResult;
        touchpoint?: {
            __typename: "Touchpoint";
            id: string;
            updated: string;
            name: string;
            description?: string | null;
            programVersion: {
                __typename: "ProgramVersion";
                sceneOrder: Array<string>;
                storyOrder: Array<string>;
                id: string;
                name: string;
                updated: string;
                updatedBy?: string | null;
                anyUpdated?: string | null;
                anyUpdatedBy?: string | null;
                useCase?: string | null;
                audienceSize?: string | null;
                industry?: string | null;
                description?: string | null;
                goals: Array<{
                    __typename: "Goal";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    priority?: number | null;
                    kpis: Array<{
                        __typename: "KPI";
                        id: string;
                        updated: string;
                        name: string;
                        description?: string | null;
                        objective?: string | null;
                        scenes: Array<{ __typename?: "Scene"; id: string }>;
                        goal: { __typename?: "Goal"; id: string };
                    }>;
                }>;
                stories: Array<{
                    __typename: "Story";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    displaySceneOrder: Array<string>;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                    channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                }>;
                scenes: Array<{
                    __typename: "Scene";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                    personalizations: Array<
                        | {
                              __typename: "AudiencePersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  updated: string;
                                  description?: string | null;
                                  audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                              }>;
                          }
                        | {
                              __typename: "ChannelPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                          }
                        | {
                              __typename: "TouchpointPersonalization";
                              id: string;
                              updated: string;
                              type: Array<string>;
                              description?: string | null;
                              touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                          }
                    >;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                audiences: Array<{
                    __typename: "Audience";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    impactsVolume?: boolean | null;
                    audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                    audienceValues: Array<{
                        __typename: "AudienceValue";
                        id: string;
                        updated: string;
                        name: string;
                        volume?: number | null;
                        audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                        subAudiences: Array<{
                            __typename: "Audience";
                            id: string;
                            updated: string;
                            name: string;
                            audienceValues: Array<{
                                __typename: "AudienceValue";
                                id: string;
                                updated: string;
                                name: string;
                                volume?: number | null;
                                audience: { __typename?: "Audience"; id: string };
                            }>;
                            audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                        }>;
                        audiencePersonalizationValues: Array<{
                            __typename?: "AudiencePersonalizationValue";
                            id: string;
                            description?: string | null;
                            audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                        }>;
                    }>;
                    feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                }>;
                channels: Array<{
                    __typename: "Channel";
                    id: string;
                    updated: string;
                    name: string;
                    platforms?: Array<string> | null;
                    status?: string | null;
                    channelPersonalizations: Array<{
                        __typename?: "ChannelPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                touchpoints: Array<{
                    __typename: "Touchpoint";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    touchpointPersonalizations: Array<{
                        __typename?: "TouchpointPersonalization";
                        id: string;
                        description?: string | null;
                        type: Array<string>;
                        scene: { __typename?: "Scene"; id: string; name: string };
                    }>;
                    stories: Array<{ __typename?: "Story"; id: string }>;
                }>;
                feedDataElements: Array<{
                    __typename: "FeedDataElement";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    source?: string | null;
                    status?: GqlClientFeedDataElementStatus | null;
                    scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                    audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                }>;
            };
            touchpointPersonalizations: Array<{
                __typename?: "TouchpointPersonalization";
                id: string;
                description?: string | null;
                type: Array<string>;
                scene: { __typename?: "Scene"; id: string; name: string };
            }>;
            stories: Array<{ __typename?: "Story"; id: string }>;
        } | null;
    };
};

export type GqlClientDeleteTouchpointMutationVariables = Exact<{
    input: GqlClientDeleteTouchpointInput;
}>;

export type GqlClientDeleteTouchpointMutation = {
    __typename?: "Mutation";
    deleteTouchpoint: {
        __typename?: "DeleteTouchpointOutput";
        result: GqlClientDeleteTouchpointResult;
        programVersion?: {
            __typename: "ProgramVersion";
            sceneOrder: Array<string>;
            storyOrder: Array<string>;
            id: string;
            name: string;
            updated: string;
            updatedBy?: string | null;
            anyUpdated?: string | null;
            anyUpdatedBy?: string | null;
            useCase?: string | null;
            audienceSize?: string | null;
            industry?: string | null;
            description?: string | null;
            goals: Array<{
                __typename: "Goal";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                priority?: number | null;
                kpis: Array<{
                    __typename: "KPI";
                    id: string;
                    updated: string;
                    name: string;
                    description?: string | null;
                    objective?: string | null;
                    scenes: Array<{ __typename?: "Scene"; id: string }>;
                    goal: { __typename?: "Goal"; id: string };
                }>;
            }>;
            stories: Array<{
                __typename: "Story";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                displaySceneOrder: Array<string>;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
            }>;
            scenes: Array<{
                __typename: "Scene";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                personalizations: Array<
                    | {
                          __typename: "AudiencePersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                          audiencePersonalizationValues: Array<{
                              __typename?: "AudiencePersonalizationValue";
                              id: string;
                              updated: string;
                              description?: string | null;
                              audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                          }>;
                      }
                    | {
                          __typename: "ChannelPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                      }
                    | {
                          __typename: "TouchpointPersonalization";
                          id: string;
                          updated: string;
                          type: Array<string>;
                          description?: string | null;
                          touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                      }
                >;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            audiences: Array<{
                __typename: "Audience";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                impactsVolume?: boolean | null;
                audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                audienceValues: Array<{
                    __typename: "AudienceValue";
                    id: string;
                    updated: string;
                    name: string;
                    volume?: number | null;
                    audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                    subAudiences: Array<{
                        __typename: "Audience";
                        id: string;
                        updated: string;
                        name: string;
                        audienceValues: Array<{ __typename: "AudienceValue"; id: string; updated: string; name: string; volume?: number | null; audience: { __typename?: "Audience"; id: string } }>;
                        audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                    }>;
                    audiencePersonalizationValues: Array<{
                        __typename?: "AudiencePersonalizationValue";
                        id: string;
                        description?: string | null;
                        audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                    }>;
                }>;
                feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
            }>;
            channels: Array<{
                __typename: "Channel";
                id: string;
                updated: string;
                name: string;
                platforms?: Array<string> | null;
                status?: string | null;
                channelPersonalizations: Array<{
                    __typename?: "ChannelPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            touchpoints: Array<{
                __typename: "Touchpoint";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                touchpointPersonalizations: Array<{
                    __typename?: "TouchpointPersonalization";
                    id: string;
                    description?: string | null;
                    type: Array<string>;
                    scene: { __typename?: "Scene"; id: string; name: string };
                }>;
                stories: Array<{ __typename?: "Story"; id: string }>;
            }>;
            feedDataElements: Array<{
                __typename: "FeedDataElement";
                id: string;
                updated: string;
                name: string;
                description?: string | null;
                source?: string | null;
                status?: GqlClientFeedDataElementStatus | null;
                scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
            }>;
        } | null;
    };
};

export type GqlClientTouchpointFragment = {
    __typename: "Touchpoint";
    id: string;
    updated: string;
    name: string;
    description?: string | null;
    touchpointPersonalizations: Array<{
        __typename?: "TouchpointPersonalization";
        id: string;
        description?: string | null;
        type: Array<string>;
        scene: { __typename?: "Scene"; id: string; name: string };
    }>;
    stories: Array<{ __typename?: "Story"; id: string }>;
};

export type GqlClientTouchpointSupportersFragment = {
    __typename?: "Scene";
    kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
    stories: Array<{ __typename?: "Story"; id: string }>;
};

export type GqlClientUpdateTouchpointMutationVariables = Exact<{
    input: GqlClientUpdateTouchpointInput;
    dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GqlClientUpdateTouchpointMutation = {
    __typename?: "Mutation";
    updateTouchpoint:
        | { __typename?: "UpdateTouchpointOutputConflict"; result: GqlClientUpdateTouchpointResult }
        | {
              __typename?: "UpdateTouchpointOutputSuccess";
              result: GqlClientUpdateTouchpointResult;
              touchpoint?: {
                  __typename: "Touchpoint";
                  id: string;
                  updated: string;
                  name: string;
                  description?: string | null;
                  programVersion: {
                      __typename: "ProgramVersion";
                      sceneOrder: Array<string>;
                      storyOrder: Array<string>;
                      id: string;
                      name: string;
                      updated: string;
                      updatedBy?: string | null;
                      anyUpdated?: string | null;
                      anyUpdatedBy?: string | null;
                      useCase?: string | null;
                      audienceSize?: string | null;
                      industry?: string | null;
                      description?: string | null;
                      goals: Array<{
                          __typename: "Goal";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          priority?: number | null;
                          kpis: Array<{
                              __typename: "KPI";
                              id: string;
                              updated: string;
                              name: string;
                              description?: string | null;
                              objective?: string | null;
                              scenes: Array<{ __typename?: "Scene"; id: string }>;
                              goal: { __typename?: "Goal"; id: string };
                          }>;
                      }>;
                      stories: Array<{
                          __typename: "Story";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          displaySceneOrder: Array<string>;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          touchpoints: Array<{ __typename?: "Touchpoint"; id: string; name: string }>;
                          channels: Array<{ __typename?: "Channel"; id: string; name: string }>;
                      }>;
                      scenes: Array<{
                          __typename: "Scene";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                          kpis: Array<{ __typename?: "KPI"; id: string; goal: { __typename?: "Goal"; id: string } }>;
                          personalizations: Array<
                              | {
                                    __typename: "AudiencePersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    audience: { __typename?: "Audience"; id: string; updated: string; name: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                                    audiencePersonalizationValues: Array<{
                                        __typename?: "AudiencePersonalizationValue";
                                        id: string;
                                        updated: string;
                                        description?: string | null;
                                        audienceValue: { __typename?: "AudienceValue"; id: string; updated: string; name: string };
                                    }>;
                                }
                              | {
                                    __typename: "ChannelPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    channel: { __typename?: "Channel"; id: string; updated: string; name: string };
                                }
                              | {
                                    __typename: "TouchpointPersonalization";
                                    id: string;
                                    updated: string;
                                    type: Array<string>;
                                    description?: string | null;
                                    touchpoint: { __typename?: "Touchpoint"; id: string; updated: string; name: string };
                                }
                          >;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      audiences: Array<{
                          __typename: "Audience";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          impactsVolume?: boolean | null;
                          audiencePersonalizations: Array<{ __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string } }>;
                          audienceValues: Array<{
                              __typename: "AudienceValue";
                              id: string;
                              updated: string;
                              name: string;
                              volume?: number | null;
                              audience: { __typename?: "Audience"; id: string; feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string }> };
                              subAudiences: Array<{
                                  __typename: "Audience";
                                  id: string;
                                  updated: string;
                                  name: string;
                                  audienceValues: Array<{
                                      __typename: "AudienceValue";
                                      id: string;
                                      updated: string;
                                      name: string;
                                      volume?: number | null;
                                      audience: { __typename?: "Audience"; id: string };
                                  }>;
                                  audienceValue?: { __typename?: "AudienceValue"; id: string; updated: string; name: string } | null;
                              }>;
                              audiencePersonalizationValues: Array<{
                                  __typename?: "AudiencePersonalizationValue";
                                  id: string;
                                  description?: string | null;
                                  audiencePersonalization: { __typename?: "AudiencePersonalization"; id: string; type: Array<string>; scene: { __typename?: "Scene"; id: string; name: string } };
                              }>;
                          }>;
                          feedDataElements: Array<{ __typename?: "FeedDataElement"; id: string; name: string; updated: string }>;
                      }>;
                      channels: Array<{
                          __typename: "Channel";
                          id: string;
                          updated: string;
                          name: string;
                          platforms?: Array<string> | null;
                          status?: string | null;
                          channelPersonalizations: Array<{
                              __typename?: "ChannelPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      touchpoints: Array<{
                          __typename: "Touchpoint";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          touchpointPersonalizations: Array<{
                              __typename?: "TouchpointPersonalization";
                              id: string;
                              description?: string | null;
                              type: Array<string>;
                              scene: { __typename?: "Scene"; id: string; name: string };
                          }>;
                          stories: Array<{ __typename?: "Story"; id: string }>;
                      }>;
                      feedDataElements: Array<{
                          __typename: "FeedDataElement";
                          id: string;
                          updated: string;
                          name: string;
                          description?: string | null;
                          source?: string | null;
                          status?: GqlClientFeedDataElementStatus | null;
                          scenes: Array<{ __typename?: "Scene"; id: string; name: string }>;
                          audiences: Array<{ __typename?: "Audience"; id: string; name: string }>;
                      }>;
                  };
                  touchpointPersonalizations: Array<{
                      __typename?: "TouchpointPersonalization";
                      id: string;
                      description?: string | null;
                      type: Array<string>;
                      scene: { __typename?: "Scene"; id: string; name: string };
                  }>;
                  stories: Array<{ __typename?: "Story"; id: string }>;
              } | null;
          };
};

export type GqlClientAccountFragment = {
    __typename?: "Account";
    id: string;
    displayName?: string | null;
    name?: string | null;
    programs: Array<{
        __typename: "Program";
        id: string;
        programType?: GqlClientProgramType | null;
        saleForceId?: string | null;
        accountId: string;
        created: string;
        createdBy?: string | null;
        updated: string;
        anyUpdated?: string | null;
        updatedBy?: string | null;
        anyUpdatedBy?: string | null;
        name: string;
        description?: string | null;
        legacyBuilderProgramId?: string | null;
        legacyFrameworkProgramId?: string | null;
        legacyOverviewProgramId?: string | null;
        isEditor?: boolean | null;
        lifecycleStage: GqlClientAssetLifecycleStage;
        isArchive?: boolean | null;
        customAnalyticFields: Array<{
            __typename: "CustomAnalyticField";
            id: string;
            accountId: string;
            updated: string;
            updatedBy?: string | null;
            name?: string | null;
            logic?: any | null;
            program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
        }>;
        surveys: Array<{ __typename?: "Survey"; id: string; externalId: string; updated: string; questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }> }>;
    }>;
    campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
    reports: Array<{
        __typename?: "AccountReport";
        id: string;
        name: string;
        type: GqlClientAccountReportType;
        timezone?: string | null;
        created: string;
        createdBy?: string | null;
        updated: string;
        updatedBy?: string | null;
        reportProgramType: GqlClientReportProgramType;
        relevantPrograms: GqlClientRelevantPrograms;
        filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
        programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
        campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
        reportElements: Array<{
            __typename?: "ReportElement";
            name: string;
            platformName?: string | null;
            description?: string | null;
            platformDescription?: string | null;
            elementType: GqlClientReportElementType;
            formatType: GqlClientReportElementFormatType;
            formatter?: any | null;
            reportLibraryConfigKey: string;
            placeHolder?: string | null;
        }>;
        schedules: Array<{
            __typename?: "AccountSchedule";
            id: string;
            active?: boolean | null;
            created: string;
            createdBy?: string | null;
            updated: string;
            deliveryChannel?: any | null;
            occurrence?: GqlClientReportScheduleOccurrence | null;
            period?: GqlClientReportSchedulePeriod | null;
            periodType?: GqlClientReportSchedulePeriodType | null;
            startPeriod?: string | null;
            sourceEnvironments?: Array<string> | null;
            filenameTemplate?: string | null;
        }>;
    }>;
};

export type GqlClientAccountReportFragment = {
    __typename?: "AccountReport";
    id: string;
    name: string;
    type: GqlClientAccountReportType;
    timezone?: string | null;
    created: string;
    createdBy?: string | null;
    updated: string;
    updatedBy?: string | null;
    reportProgramType: GqlClientReportProgramType;
    relevantPrograms: GqlClientRelevantPrograms;
    filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
    programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
    campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
    reportElements: Array<{
        __typename?: "ReportElement";
        name: string;
        platformName?: string | null;
        description?: string | null;
        platformDescription?: string | null;
        elementType: GqlClientReportElementType;
        formatType: GqlClientReportElementFormatType;
        formatter?: any | null;
        reportLibraryConfigKey: string;
        placeHolder?: string | null;
    }>;
    schedules: Array<{
        __typename?: "AccountSchedule";
        id: string;
        active?: boolean | null;
        created: string;
        createdBy?: string | null;
        updated: string;
        deliveryChannel?: any | null;
        occurrence?: GqlClientReportScheduleOccurrence | null;
        period?: GqlClientReportSchedulePeriod | null;
        periodType?: GqlClientReportSchedulePeriodType | null;
        startPeriod?: string | null;
        sourceEnvironments?: Array<string> | null;
        filenameTemplate?: string | null;
    }>;
};

export type GqlClientCopyAccountReportMutationVariables = Exact<{
    input: GqlClientCopyAccountReportInput;
}>;

export type GqlClientCopyAccountReportMutation = {
    __typename?: "Mutation";
    copyAccountReport: {
        __typename?: "CopyAccountReportOutput";
        result: GqlClientCopyAccountReportResult;
        targetReport?: {
            __typename?: "AccountReport";
            id: string;
            name: string;
            type: GqlClientAccountReportType;
            timezone?: string | null;
            created: string;
            createdBy?: string | null;
            updated: string;
            updatedBy?: string | null;
            reportProgramType: GqlClientReportProgramType;
            relevantPrograms: GqlClientRelevantPrograms;
            account: {
                __typename?: "Account";
                id: string;
                displayName?: string | null;
                name?: string | null;
                programs: Array<{
                    __typename: "Program";
                    id: string;
                    programType?: GqlClientProgramType | null;
                    saleForceId?: string | null;
                    accountId: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    anyUpdated?: string | null;
                    updatedBy?: string | null;
                    anyUpdatedBy?: string | null;
                    name: string;
                    description?: string | null;
                    legacyBuilderProgramId?: string | null;
                    legacyFrameworkProgramId?: string | null;
                    legacyOverviewProgramId?: string | null;
                    isEditor?: boolean | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    isArchive?: boolean | null;
                    customAnalyticFields: Array<{
                        __typename: "CustomAnalyticField";
                        id: string;
                        accountId: string;
                        updated: string;
                        updatedBy?: string | null;
                        name?: string | null;
                        logic?: any | null;
                        program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                    }>;
                    surveys: Array<{
                        __typename?: "Survey";
                        id: string;
                        externalId: string;
                        updated: string;
                        questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                    }>;
                }>;
                campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                reports: Array<{
                    __typename?: "AccountReport";
                    id: string;
                    name: string;
                    type: GqlClientAccountReportType;
                    timezone?: string | null;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    reportProgramType: GqlClientReportProgramType;
                    relevantPrograms: GqlClientRelevantPrograms;
                    filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
                    programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
                    campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                    reportElements: Array<{
                        __typename?: "ReportElement";
                        name: string;
                        platformName?: string | null;
                        description?: string | null;
                        platformDescription?: string | null;
                        elementType: GqlClientReportElementType;
                        formatType: GqlClientReportElementFormatType;
                        formatter?: any | null;
                        reportLibraryConfigKey: string;
                        placeHolder?: string | null;
                    }>;
                    schedules: Array<{
                        __typename?: "AccountSchedule";
                        id: string;
                        active?: boolean | null;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        deliveryChannel?: any | null;
                        occurrence?: GqlClientReportScheduleOccurrence | null;
                        period?: GqlClientReportSchedulePeriod | null;
                        periodType?: GqlClientReportSchedulePeriodType | null;
                        startPeriod?: string | null;
                        sourceEnvironments?: Array<string> | null;
                        filenameTemplate?: string | null;
                    }>;
                }>;
            };
            filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
            programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
            campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
            reportElements: Array<{
                __typename?: "ReportElement";
                name: string;
                platformName?: string | null;
                description?: string | null;
                platformDescription?: string | null;
                elementType: GqlClientReportElementType;
                formatType: GqlClientReportElementFormatType;
                formatter?: any | null;
                reportLibraryConfigKey: string;
                placeHolder?: string | null;
            }>;
            schedules: Array<{
                __typename?: "AccountSchedule";
                id: string;
                active?: boolean | null;
                created: string;
                createdBy?: string | null;
                updated: string;
                deliveryChannel?: any | null;
                occurrence?: GqlClientReportScheduleOccurrence | null;
                period?: GqlClientReportSchedulePeriod | null;
                periodType?: GqlClientReportSchedulePeriodType | null;
                startPeriod?: string | null;
                sourceEnvironments?: Array<string> | null;
                filenameTemplate?: string | null;
            }>;
        } | null;
    };
};

export type GqlClientCreateAccountReportMutationVariables = Exact<{
    input: GqlClientCreateAccountReportInput;
}>;

export type GqlClientCreateAccountReportMutation = {
    __typename?: "Mutation";
    createAccountReport: {
        __typename?: "CreateAccountReportOutput";
        result: GqlClientCreateAccountReportResult;
        accountReport?: {
            __typename?: "AccountReport";
            id: string;
            name: string;
            type: GqlClientAccountReportType;
            timezone?: string | null;
            created: string;
            createdBy?: string | null;
            updated: string;
            updatedBy?: string | null;
            reportProgramType: GqlClientReportProgramType;
            relevantPrograms: GqlClientRelevantPrograms;
            account: {
                __typename?: "Account";
                id: string;
                displayName?: string | null;
                name?: string | null;
                programs: Array<{
                    __typename: "Program";
                    id: string;
                    programType?: GqlClientProgramType | null;
                    saleForceId?: string | null;
                    accountId: string;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    anyUpdated?: string | null;
                    updatedBy?: string | null;
                    anyUpdatedBy?: string | null;
                    name: string;
                    description?: string | null;
                    legacyBuilderProgramId?: string | null;
                    legacyFrameworkProgramId?: string | null;
                    legacyOverviewProgramId?: string | null;
                    isEditor?: boolean | null;
                    lifecycleStage: GqlClientAssetLifecycleStage;
                    isArchive?: boolean | null;
                    customAnalyticFields: Array<{
                        __typename: "CustomAnalyticField";
                        id: string;
                        accountId: string;
                        updated: string;
                        updatedBy?: string | null;
                        name?: string | null;
                        logic?: any | null;
                        program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                    }>;
                    surveys: Array<{
                        __typename?: "Survey";
                        id: string;
                        externalId: string;
                        updated: string;
                        questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                    }>;
                }>;
                campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                reports: Array<{
                    __typename?: "AccountReport";
                    id: string;
                    name: string;
                    type: GqlClientAccountReportType;
                    timezone?: string | null;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    updatedBy?: string | null;
                    reportProgramType: GqlClientReportProgramType;
                    relevantPrograms: GqlClientRelevantPrograms;
                    filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
                    programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
                    campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                    reportElements: Array<{
                        __typename?: "ReportElement";
                        name: string;
                        platformName?: string | null;
                        description?: string | null;
                        platformDescription?: string | null;
                        elementType: GqlClientReportElementType;
                        formatType: GqlClientReportElementFormatType;
                        formatter?: any | null;
                        reportLibraryConfigKey: string;
                        placeHolder?: string | null;
                    }>;
                    schedules: Array<{
                        __typename?: "AccountSchedule";
                        id: string;
                        active?: boolean | null;
                        created: string;
                        createdBy?: string | null;
                        updated: string;
                        deliveryChannel?: any | null;
                        occurrence?: GqlClientReportScheduleOccurrence | null;
                        period?: GqlClientReportSchedulePeriod | null;
                        periodType?: GqlClientReportSchedulePeriodType | null;
                        startPeriod?: string | null;
                        sourceEnvironments?: Array<string> | null;
                        filenameTemplate?: string | null;
                    }>;
                }>;
            };
            filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
            programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
            campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
            reportElements: Array<{
                __typename?: "ReportElement";
                name: string;
                platformName?: string | null;
                description?: string | null;
                platformDescription?: string | null;
                elementType: GqlClientReportElementType;
                formatType: GqlClientReportElementFormatType;
                formatter?: any | null;
                reportLibraryConfigKey: string;
                placeHolder?: string | null;
            }>;
            schedules: Array<{
                __typename?: "AccountSchedule";
                id: string;
                active?: boolean | null;
                created: string;
                createdBy?: string | null;
                updated: string;
                deliveryChannel?: any | null;
                occurrence?: GqlClientReportScheduleOccurrence | null;
                period?: GqlClientReportSchedulePeriod | null;
                periodType?: GqlClientReportSchedulePeriodType | null;
                startPeriod?: string | null;
                sourceEnvironments?: Array<string> | null;
                filenameTemplate?: string | null;
            }>;
        } | null;
    };
};

export type GqlClientDeleteAccountReportMutationVariables = Exact<{
    input: GqlClientDeleteAccountReportInput;
}>;

export type GqlClientDeleteAccountReportMutation = {
    __typename?: "Mutation";
    deleteAccountReport: {
        __typename?: "DeleteAccountReportOutput";
        result: GqlClientDeleteAccountReportResult;
        account?: {
            __typename?: "Account";
            id: string;
            displayName?: string | null;
            name?: string | null;
            programs: Array<{
                __typename: "Program";
                id: string;
                programType?: GqlClientProgramType | null;
                saleForceId?: string | null;
                accountId: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                anyUpdated?: string | null;
                updatedBy?: string | null;
                anyUpdatedBy?: string | null;
                name: string;
                description?: string | null;
                legacyBuilderProgramId?: string | null;
                legacyFrameworkProgramId?: string | null;
                legacyOverviewProgramId?: string | null;
                isEditor?: boolean | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                isArchive?: boolean | null;
                customAnalyticFields: Array<{
                    __typename: "CustomAnalyticField";
                    id: string;
                    accountId: string;
                    updated: string;
                    updatedBy?: string | null;
                    name?: string | null;
                    logic?: any | null;
                    program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                }>;
                surveys: Array<{
                    __typename?: "Survey";
                    id: string;
                    externalId: string;
                    updated: string;
                    questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                }>;
            }>;
            campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
            reports: Array<{
                __typename?: "AccountReport";
                id: string;
                name: string;
                type: GqlClientAccountReportType;
                timezone?: string | null;
                created: string;
                createdBy?: string | null;
                updated: string;
                updatedBy?: string | null;
                reportProgramType: GqlClientReportProgramType;
                relevantPrograms: GqlClientRelevantPrograms;
                filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
                programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
                campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                reportElements: Array<{
                    __typename?: "ReportElement";
                    name: string;
                    platformName?: string | null;
                    description?: string | null;
                    platformDescription?: string | null;
                    elementType: GqlClientReportElementType;
                    formatType: GqlClientReportElementFormatType;
                    formatter?: any | null;
                    reportLibraryConfigKey: string;
                    placeHolder?: string | null;
                }>;
                schedules: Array<{
                    __typename?: "AccountSchedule";
                    id: string;
                    active?: boolean | null;
                    created: string;
                    createdBy?: string | null;
                    updated: string;
                    deliveryChannel?: any | null;
                    occurrence?: GqlClientReportScheduleOccurrence | null;
                    period?: GqlClientReportSchedulePeriod | null;
                    periodType?: GqlClientReportSchedulePeriodType | null;
                    startPeriod?: string | null;
                    sourceEnvironments?: Array<string> | null;
                    filenameTemplate?: string | null;
                }>;
            }>;
        } | null;
    };
};

export type GqlClientGetAccountAnalyticsQueryVariables = Exact<{
    startDate: Scalars["String"]["input"];
    endDate: Scalars["String"]["input"];
    filters?: InputMaybe<Array<InputMaybe<GqlClientAnalyticsFilterInput>> | InputMaybe<GqlClientAnalyticsFilterInput>>;
}>;

export type GqlClientGetAccountAnalyticsQuery = {
    __typename?: "Query";
    account?: {
        __typename?: "Account";
        id: string;
        accountAnalytics: {
            __typename?: "AccountAnalytics";
            videoOverview?: Array<{
                __typename?: "AccountAnalyticsVideoOverview";
                videoName: string;
                videoId?: string | null;
                isEditor?: boolean | null;
                views: number;
                clicks: number;
                minutesViewed: number;
                avv: number;
                minutesPerViewer: number;
                clickRate: number;
                lastPublished?: string | null;
                viewsWithCtaDisplayed: number;
                viewsWithPlayer: number;
            }> | null;
            dailyTrend?: Array<{ __typename?: "AccountAnalyticsDailyTrend"; date: string; views: number; clicks: number; minutesViewed: number; avv: number }> | null;
            accountOverview?: {
                __typename?: "AccountAnalyticsOverview";
                views: number;
                viewsWithClient: number;
                viewsWithSurveyDisplayed: number;
                viewsWithSurveySubmitted: number;
                minutesViewed: number;
                avv: number;
                hasPublishedVideos: boolean;
                clickRate: number;
            } | null;
            analyticsFilters?: Array<{ __typename?: "AnalyticsFilter"; filterName: string; filterId: string; filterValue: string; totalCount: number; percentage: number }> | null;
        };
        editorProgramsIncludingRemoved: Array<{
            __typename?: "EditorProgram";
            name: string;
            id: string;
            isArchive?: boolean | null;
            isLive?: boolean | null;
            lifecycleStage?: GqlClientAssetLifecycleStage | null;
            prodVersion?: {
                __typename?: "LifecycleHistoryEntry";
                id: string;
                created: string;
                editorProgramVersion?: { __typename?: "EditorProgramVersion"; id: string; thumbnail?: { __typename?: "ThumbnailAndStatus"; url?: string | null } | null } | null;
            } | null;
            uatVersion?: { __typename?: "LifecycleHistoryEntry"; id: string; created: string } | null;
            shareHistory: Array<{ __typename?: "ProgramShareHistory"; id: string; created: string; shareType: GqlClientProgramShareType; isExternal?: boolean | null; accountId: string }>;
        }>;
    } | null;
};

export type GqlClientGetAccountReportsQueryVariables = Exact<{ [key: string]: never }>;

export type GqlClientGetAccountReportsQuery = {
    __typename?: "Query";
    account?: {
        __typename?: "Account";
        id: string;
        displayName?: string | null;
        name?: string | null;
        programs: Array<{
            __typename: "Program";
            id: string;
            programType?: GqlClientProgramType | null;
            saleForceId?: string | null;
            accountId: string;
            created: string;
            createdBy?: string | null;
            updated: string;
            anyUpdated?: string | null;
            updatedBy?: string | null;
            anyUpdatedBy?: string | null;
            name: string;
            description?: string | null;
            legacyBuilderProgramId?: string | null;
            legacyFrameworkProgramId?: string | null;
            legacyOverviewProgramId?: string | null;
            isEditor?: boolean | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            isArchive?: boolean | null;
            customAnalyticFields: Array<{
                __typename: "CustomAnalyticField";
                id: string;
                accountId: string;
                updated: string;
                updatedBy?: string | null;
                name?: string | null;
                logic?: any | null;
                program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
            }>;
            surveys: Array<{ __typename?: "Survey"; id: string; externalId: string; updated: string; questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }> }>;
        }>;
        campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
        reports: Array<{
            __typename?: "AccountReport";
            id: string;
            name: string;
            type: GqlClientAccountReportType;
            timezone?: string | null;
            created: string;
            createdBy?: string | null;
            updated: string;
            updatedBy?: string | null;
            reportProgramType: GqlClientReportProgramType;
            relevantPrograms: GqlClientRelevantPrograms;
            filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
            programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
            campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
            reportElements: Array<{
                __typename?: "ReportElement";
                name: string;
                platformName?: string | null;
                description?: string | null;
                platformDescription?: string | null;
                elementType: GqlClientReportElementType;
                formatType: GqlClientReportElementFormatType;
                formatter?: any | null;
                reportLibraryConfigKey: string;
                placeHolder?: string | null;
            }>;
            schedules: Array<{
                __typename?: "AccountSchedule";
                id: string;
                active?: boolean | null;
                created: string;
                createdBy?: string | null;
                updated: string;
                deliveryChannel?: any | null;
                occurrence?: GqlClientReportScheduleOccurrence | null;
                period?: GqlClientReportSchedulePeriod | null;
                periodType?: GqlClientReportSchedulePeriodType | null;
                startPeriod?: string | null;
                sourceEnvironments?: Array<string> | null;
                filenameTemplate?: string | null;
            }>;
        }>;
    } | null;
};

export type GqlClientSendAccountReportMutationVariables = Exact<{
    input: GqlClientSendAccountReportInput;
}>;

export type GqlClientSendAccountReportMutation = { __typename?: "Mutation"; sendAccountReport: { __typename?: "SendAccountReportOutput"; result: GqlClientSendAccountReportResult } };

export type GqlClientUpdateAccountReportMutationVariables = Exact<{
    input: GqlClientUpdateAccountReportInput;
}>;

export type GqlClientUpdateAccountReportMutation = {
    __typename?: "Mutation";
    updateAccountReport:
        | { __typename?: "UpdateAccountReportOutputConflict"; result: GqlClientUpdateAccountReportResult }
        | {
              __typename?: "UpdateAccountReportOutputSuccess";
              result: GqlClientUpdateAccountReportResult;
              accountReport?: {
                  __typename?: "AccountReport";
                  id: string;
                  name: string;
                  type: GqlClientAccountReportType;
                  timezone?: string | null;
                  created: string;
                  createdBy?: string | null;
                  updated: string;
                  updatedBy?: string | null;
                  reportProgramType: GqlClientReportProgramType;
                  relevantPrograms: GqlClientRelevantPrograms;
                  filters: Array<{ __typename?: "AccountReportFilter"; filterType?: GqlClientAccountReportFilterType | null; values: Array<string> }>;
                  programs: Array<{ __typename?: "Program"; id: string; saleForceId?: string | null; name: string; lifecycleStage: GqlClientAssetLifecycleStage; isArchive?: boolean | null }>;
                  campaigns: Array<{ __typename?: "CampaignSettings"; id: string; campaignName: string }>;
                  reportElements: Array<{
                      __typename?: "ReportElement";
                      name: string;
                      platformName?: string | null;
                      description?: string | null;
                      platformDescription?: string | null;
                      elementType: GqlClientReportElementType;
                      formatType: GqlClientReportElementFormatType;
                      formatter?: any | null;
                      reportLibraryConfigKey: string;
                      placeHolder?: string | null;
                  }>;
                  schedules: Array<{
                      __typename?: "AccountSchedule";
                      id: string;
                      active?: boolean | null;
                      created: string;
                      createdBy?: string | null;
                      updated: string;
                      deliveryChannel?: any | null;
                      occurrence?: GqlClientReportScheduleOccurrence | null;
                      period?: GqlClientReportSchedulePeriod | null;
                      periodType?: GqlClientReportSchedulePeriodType | null;
                      startPeriod?: string | null;
                      sourceEnvironments?: Array<string> | null;
                      filenameTemplate?: string | null;
                  }>;
              } | null;
          };
};

export type GqlClientReportElementFragment = {
    __typename?: "ReportElement";
    name: string;
    platformName?: string | null;
    description?: string | null;
    platformDescription?: string | null;
    elementType: GqlClientReportElementType;
    formatType: GqlClientReportElementFormatType;
    formatter?: any | null;
    reportLibraryConfigKey: string;
    placeHolder?: string | null;
};

export type GqlClientGetAllAccountReportElementsQueryVariables = Exact<{
    programIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type GqlClientGetAllAccountReportElementsQuery = {
    __typename?: "Query";
    accountReportLibraryDataElements: Array<{
        __typename?: "ReportLibraryElement";
        id?: string | null;
        reportLibraryConfigKey: string;
        name: string;
        platformName?: string | null;
        description?: string | null;
        platformDescription?: string | null;
        elementType: GqlClientReportElementType;
        formatType: GqlClientReportElementFormatType;
        placeHolder?: string | null;
        customerFacing?: boolean | null;
        notCustomerFacingInBuilder?: boolean | null;
        isDefaultColumn?: boolean | null;
        defaultOrder?: number | null;
        dataSamples?: Array<string | null> | null;
    }>;
    accountReportLibraryGeneralElements: Array<{
        __typename?: "ReportLibraryElement";
        id?: string | null;
        reportLibraryConfigKey: string;
        name: string;
        platformName?: string | null;
        description?: string | null;
        platformDescription?: string | null;
        elementType: GqlClientReportElementType;
        formatType: GqlClientReportElementFormatType;
        placeHolder?: string | null;
        customerFacing?: boolean | null;
        notCustomerFacingInBuilder?: boolean | null;
        isDefaultColumn?: boolean | null;
        defaultOrder?: number | null;
        dataSamples?: Array<string | null> | null;
    }>;
    accountReportLibraryHotSpots: Array<{
        __typename?: "ReportLibraryElement";
        id?: string | null;
        reportLibraryConfigKey: string;
        name: string;
        platformName?: string | null;
        description?: string | null;
        platformDescription?: string | null;
        elementType: GqlClientReportElementType;
        formatType: GqlClientReportElementFormatType;
        placeHolder?: string | null;
        customerFacing?: boolean | null;
        notCustomerFacingInBuilder?: boolean | null;
        isDefaultColumn?: boolean | null;
        defaultOrder?: number | null;
        dataSamples?: Array<string | null> | null;
        subElements?: Array<{
            __typename?: "ReportLibraryElement";
            id?: string | null;
            reportLibraryConfigKey: string;
            name: string;
            platformName?: string | null;
            description?: string | null;
            platformDescription?: string | null;
            elementType: GqlClientReportElementType;
            formatType: GqlClientReportElementFormatType;
            placeHolder?: string | null;
            customerFacing?: boolean | null;
            notCustomerFacingInBuilder?: boolean | null;
            isDefaultColumn?: boolean | null;
            defaultOrder?: number | null;
            dataSamples?: Array<string | null> | null;
        }> | null;
    }>;
    accountReportLibrarySurveys: Array<{
        __typename?: "ReportLibraryElement";
        id?: string | null;
        reportLibraryConfigKey: string;
        name: string;
        platformName?: string | null;
        description?: string | null;
        platformDescription?: string | null;
        elementType: GqlClientReportElementType;
        formatType: GqlClientReportElementFormatType;
        placeHolder?: string | null;
        customerFacing?: boolean | null;
        notCustomerFacingInBuilder?: boolean | null;
        isDefaultColumn?: boolean | null;
        defaultOrder?: number | null;
        dataSamples?: Array<string | null> | null;
        subElements?: Array<{
            __typename?: "ReportLibraryElement";
            id?: string | null;
            reportLibraryConfigKey: string;
            name: string;
            platformName?: string | null;
            description?: string | null;
            platformDescription?: string | null;
            elementType: GqlClientReportElementType;
            formatType: GqlClientReportElementFormatType;
            placeHolder?: string | null;
            customerFacing?: boolean | null;
            notCustomerFacingInBuilder?: boolean | null;
            isDefaultColumn?: boolean | null;
            defaultOrder?: number | null;
            dataSamples?: Array<string | null> | null;
        }> | null;
    }>;
    accountReportLibraryTrackingParameters: Array<{
        __typename?: "ReportLibraryElement";
        id?: string | null;
        reportLibraryConfigKey: string;
        name: string;
        platformName?: string | null;
        description?: string | null;
        platformDescription?: string | null;
        elementType: GqlClientReportElementType;
        formatType: GqlClientReportElementFormatType;
        placeHolder?: string | null;
        customerFacing?: boolean | null;
        notCustomerFacingInBuilder?: boolean | null;
        isDefaultColumn?: boolean | null;
        defaultOrder?: number | null;
        dataSamples?: Array<string | null> | null;
    }>;
    accountReportLibraryPaidMediaElements: Array<{
        __typename?: "ReportLibraryElement";
        id?: string | null;
        reportLibraryConfigKey: string;
        name: string;
        platformName?: string | null;
        description?: string | null;
        platformDescription?: string | null;
        elementType: GqlClientReportElementType;
        formatType: GqlClientReportElementFormatType;
        placeHolder?: string | null;
        customerFacing?: boolean | null;
        notCustomerFacingInBuilder?: boolean | null;
        isDefaultColumn?: boolean | null;
        defaultOrder?: number | null;
        dataSamples?: Array<string | null> | null;
    }>;
};

export type GqlClientGetAllReportElementsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientGetAllReportElementsQuery = {
    __typename?: "Query";
    reportLibraryDataElements: Array<{
        __typename?: "ReportLibraryElement";
        id?: string | null;
        reportLibraryConfigKey: string;
        name: string;
        platformName?: string | null;
        description?: string | null;
        platformDescription?: string | null;
        elementType: GqlClientReportElementType;
        formatType: GqlClientReportElementFormatType;
        placeHolder?: string | null;
        customerFacing?: boolean | null;
        notCustomerFacingInBuilder?: boolean | null;
        isDefaultColumn?: boolean | null;
        defaultOrder?: number | null;
        dataSamples?: Array<string | null> | null;
    }>;
    reportLibraryGeneralElements: Array<{
        __typename?: "ReportLibraryElement";
        id?: string | null;
        reportLibraryConfigKey: string;
        name: string;
        platformName?: string | null;
        description?: string | null;
        platformDescription?: string | null;
        elementType: GqlClientReportElementType;
        formatType: GqlClientReportElementFormatType;
        placeHolder?: string | null;
        customerFacing?: boolean | null;
        notCustomerFacingInBuilder?: boolean | null;
        isDefaultColumn?: boolean | null;
        defaultOrder?: number | null;
        dataSamples?: Array<string | null> | null;
    }>;
    reportLibraryHotSpots: Array<{
        __typename?: "ReportLibraryElement";
        id?: string | null;
        reportLibraryConfigKey: string;
        name: string;
        platformName?: string | null;
        description?: string | null;
        platformDescription?: string | null;
        elementType: GqlClientReportElementType;
        formatType: GqlClientReportElementFormatType;
        placeHolder?: string | null;
        customerFacing?: boolean | null;
        notCustomerFacingInBuilder?: boolean | null;
        isDefaultColumn?: boolean | null;
        defaultOrder?: number | null;
        dataSamples?: Array<string | null> | null;
        subElements?: Array<{
            __typename?: "ReportLibraryElement";
            id?: string | null;
            reportLibraryConfigKey: string;
            name: string;
            platformName?: string | null;
            description?: string | null;
            platformDescription?: string | null;
            elementType: GqlClientReportElementType;
            formatType: GqlClientReportElementFormatType;
            placeHolder?: string | null;
            customerFacing?: boolean | null;
            notCustomerFacingInBuilder?: boolean | null;
            isDefaultColumn?: boolean | null;
            defaultOrder?: number | null;
            dataSamples?: Array<string | null> | null;
        }> | null;
    }>;
    reportLibrarySurveys: Array<{
        __typename?: "ReportLibraryElement";
        id?: string | null;
        reportLibraryConfigKey: string;
        name: string;
        platformName?: string | null;
        description?: string | null;
        platformDescription?: string | null;
        elementType: GqlClientReportElementType;
        formatType: GqlClientReportElementFormatType;
        placeHolder?: string | null;
        customerFacing?: boolean | null;
        notCustomerFacingInBuilder?: boolean | null;
        isDefaultColumn?: boolean | null;
        defaultOrder?: number | null;
        dataSamples?: Array<string | null> | null;
        subElements?: Array<{
            __typename?: "ReportLibraryElement";
            id?: string | null;
            reportLibraryConfigKey: string;
            name: string;
            platformName?: string | null;
            description?: string | null;
            platformDescription?: string | null;
            elementType: GqlClientReportElementType;
            formatType: GqlClientReportElementFormatType;
            placeHolder?: string | null;
            customerFacing?: boolean | null;
            notCustomerFacingInBuilder?: boolean | null;
            isDefaultColumn?: boolean | null;
            defaultOrder?: number | null;
            dataSamples?: Array<string | null> | null;
        }> | null;
    }>;
    reportLibraryTrackingParameters: Array<{
        __typename?: "ReportLibraryElement";
        id?: string | null;
        reportLibraryConfigKey: string;
        name: string;
        platformName?: string | null;
        description?: string | null;
        platformDescription?: string | null;
        elementType: GqlClientReportElementType;
        formatType: GqlClientReportElementFormatType;
        placeHolder?: string | null;
        customerFacing?: boolean | null;
        notCustomerFacingInBuilder?: boolean | null;
        isDefaultColumn?: boolean | null;
        defaultOrder?: number | null;
        dataSamples?: Array<string | null> | null;
    }>;
};

export type GqlClientReportLibraryElementFragment = {
    __typename?: "ReportLibraryElement";
    id?: string | null;
    reportLibraryConfigKey: string;
    name: string;
    platformName?: string | null;
    description?: string | null;
    platformDescription?: string | null;
    elementType: GqlClientReportElementType;
    formatType: GqlClientReportElementFormatType;
    placeHolder?: string | null;
    customerFacing?: boolean | null;
    notCustomerFacingInBuilder?: boolean | null;
    isDefaultColumn?: boolean | null;
    defaultOrder?: number | null;
    dataSamples?: Array<string | null> | null;
};

export type GqlClientAccountScheduleFragment = {
    __typename?: "AccountSchedule";
    id: string;
    active?: boolean | null;
    created: string;
    createdBy?: string | null;
    updated: string;
    deliveryChannel?: any | null;
    occurrence?: GqlClientReportScheduleOccurrence | null;
    period?: GqlClientReportSchedulePeriod | null;
    periodType?: GqlClientReportSchedulePeriodType | null;
    startPeriod?: string | null;
    sourceEnvironments?: Array<string> | null;
    filenameTemplate?: string | null;
};

export type GqlClientCreateSurveyMutationVariables = Exact<{
    input: GqlClientCreateSurveyInput;
}>;

export type GqlClientCreateSurveyMutation = {
    __typename?: "Mutation";
    createSurvey: {
        __typename?: "CreateSurveyOutput";
        result: GqlClientCreateSurveyResult;
        survey?: {
            __typename?: "Survey";
            id: string;
            program: {
                __typename: "Program";
                id: string;
                programType?: GqlClientProgramType | null;
                saleForceId?: string | null;
                accountId: string;
                created: string;
                createdBy?: string | null;
                updated: string;
                anyUpdated?: string | null;
                updatedBy?: string | null;
                anyUpdatedBy?: string | null;
                name: string;
                description?: string | null;
                legacyBuilderProgramId?: string | null;
                legacyFrameworkProgramId?: string | null;
                legacyOverviewProgramId?: string | null;
                isEditor?: boolean | null;
                lifecycleStage: GqlClientAssetLifecycleStage;
                isArchive?: boolean | null;
                customAnalyticFields: Array<{
                    __typename: "CustomAnalyticField";
                    id: string;
                    accountId: string;
                    updated: string;
                    updatedBy?: string | null;
                    name?: string | null;
                    logic?: any | null;
                    program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                }>;
                surveys: Array<{
                    __typename?: "Survey";
                    id: string;
                    externalId: string;
                    updated: string;
                    questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                }>;
            };
        } | null;
    };
};

export type GqlClientDeleteSurveyMutationVariables = Exact<{
    input: GqlClientDeleteSurveyInput;
}>;

export type GqlClientDeleteSurveyMutation = {
    __typename?: "Mutation";
    deleteSurvey: {
        __typename?: "DeleteSurveyOutput";
        result: GqlClientDeleteSurveyResult;
        program?: {
            __typename: "Program";
            id: string;
            programType?: GqlClientProgramType | null;
            saleForceId?: string | null;
            accountId: string;
            created: string;
            createdBy?: string | null;
            updated: string;
            anyUpdated?: string | null;
            updatedBy?: string | null;
            anyUpdatedBy?: string | null;
            name: string;
            description?: string | null;
            legacyBuilderProgramId?: string | null;
            legacyFrameworkProgramId?: string | null;
            legacyOverviewProgramId?: string | null;
            isEditor?: boolean | null;
            lifecycleStage: GqlClientAssetLifecycleStage;
            isArchive?: boolean | null;
            customAnalyticFields: Array<{
                __typename: "CustomAnalyticField";
                id: string;
                accountId: string;
                updated: string;
                updatedBy?: string | null;
                name?: string | null;
                logic?: any | null;
                program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
            }>;
            surveys: Array<{ __typename?: "Survey"; id: string; externalId: string; updated: string; questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }> }>;
        } | null;
    };
};

export type GqlClientGetProgramAnalyticsQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
    startDate: Scalars["String"]["input"];
    endDate: Scalars["String"]["input"];
    filters?: InputMaybe<Array<InputMaybe<GqlClientAnalyticsFilterInput>> | InputMaybe<GqlClientAnalyticsFilterInput>>;
}>;

export type GqlClientGetProgramAnalyticsQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        programAnalytics: {
            __typename?: "ProgramAnalytics";
            surveyAnalytics?: {
                __typename?: "ProgramSurveyAnalytics";
                surveyId?: string | null;
                displayedAmount: number;
                submittedAmount: number;
                videoViewsAmount: number;
                questions: Array<{
                    __typename?: "SurveyAnalyticsQuestion";
                    text?: string | null;
                    type?: string | null;
                    answersTotal: number;
                    answers: Array<{ __typename?: "SurveyAnalyticsAnswer"; answerText: string; responseCount: number }>;
                }>;
                npsAnalytics?: { __typename?: "NPSSurveyAnalytics"; surveyDetractorsAmount: number; surveyNeutralAmount: number; surveyPromotersAmount: number } | null;
            } | null;
            clicksAnalytics?: {
                __typename?: "ProgramClicksAnalytics";
                viewsWithCTADisplayed: number;
                viewsWithCTAClicked: number;
                totalCTAViews: number;
                totalCTAClicks: number;
                videoViewsAmount: number;
                buttons?: Array<{ __typename?: "ClicksAnalyticsButton"; operation: string; screenText: string; landingPage: boolean; ctaViews: number; ctaClicks: number }> | null;
            } | null;
            viewsAnalytics?: {
                __typename?: "ProgramViewsAnalytics";
                views: number;
                viewsWithClient: number;
                videoExposed: number;
                minutesViewed: number;
                avv: number;
                viewsWithUserId: number;
                newViewsCount: number;
                returningViewsCount: number;
                activeViewsCount: number;
                viewsDailyData?: Array<{
                    __typename?: "ProgramViewsAnalyticsDailyData";
                    date: string;
                    views: number;
                    viewsWithClient: number;
                    videoExposed: number;
                    minutesViewed: number;
                    avv: number;
                    viewsWithUserId: number;
                    newViewsCount: number;
                    returningViewsCount: number;
                    activeViewsCount: number;
                }> | null;
            } | null;
            sceneAnalytics: {
                __typename?: "ProgramSceneDataAnalytics";
                versionPublishedDate: string;
                programSceneAnalyticsArray?: Array<{
                    __typename?: "ProgramSceneAnalytics";
                    sceneId: string;
                    scenePotentialViews: number;
                    sceneSequentialViews: number;
                    sceneSkippedViews: number;
                    sceneRewatchViews: number;
                    data_use: boolean;
                }> | null;
            };
            analyticsFilters?: Array<{ __typename?: "AnalyticsFilter"; filterName: string; filterId: string; filterValue: string; totalCount: number; percentage: number }> | null;
        };
    } | null;
};

export type GqlClientGetSurveysQueryVariables = Exact<{
    programId: Scalars["ID"]["input"];
}>;

export type GqlClientGetSurveysQuery = {
    __typename?: "Query";
    program?: {
        __typename?: "Program";
        id: string;
        surveys: Array<{ __typename?: "Survey"; id: string; externalId: string; updated: string; questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }> }>;
    } | null;
};

export type GqlClientSurveyFragment = {
    __typename?: "Survey";
    id: string;
    externalId: string;
    updated: string;
    questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
};

export type GqlClientSurveyQuestionFragment = { __typename?: "SurveyQuestion"; externalId: string; text?: string | null };

export type GqlClientUpdateSurveyMutationVariables = Exact<{
    input: GqlClientUpdateSurveyInput;
}>;

export type GqlClientUpdateSurveyMutation = {
    __typename?: "Mutation";
    updateSurvey:
        | { __typename?: "UpdateSurveyOutputConflict"; result: GqlClientUpdateSurveyResult }
        | {
              __typename?: "UpdateSurveyOutputSuccess";
              result: GqlClientUpdateSurveyResult;
              survey?: {
                  __typename?: "Survey";
                  id: string;
                  program: {
                      __typename: "Program";
                      id: string;
                      programType?: GqlClientProgramType | null;
                      saleForceId?: string | null;
                      accountId: string;
                      created: string;
                      createdBy?: string | null;
                      updated: string;
                      anyUpdated?: string | null;
                      updatedBy?: string | null;
                      anyUpdatedBy?: string | null;
                      name: string;
                      description?: string | null;
                      legacyBuilderProgramId?: string | null;
                      legacyFrameworkProgramId?: string | null;
                      legacyOverviewProgramId?: string | null;
                      isEditor?: boolean | null;
                      lifecycleStage: GqlClientAssetLifecycleStage;
                      isArchive?: boolean | null;
                      customAnalyticFields: Array<{
                          __typename: "CustomAnalyticField";
                          id: string;
                          accountId: string;
                          updated: string;
                          updatedBy?: string | null;
                          name?: string | null;
                          logic?: any | null;
                          program: { __typename?: "Program"; id: string; customAnalyticFields: Array<{ __typename?: "CustomAnalyticField"; id: string; name?: string | null }> };
                      }>;
                      surveys: Array<{
                          __typename?: "Survey";
                          id: string;
                          externalId: string;
                          updated: string;
                          questions: Array<{ __typename?: "SurveyQuestion"; externalId: string; text?: string | null }>;
                      }>;
                  };
              } | null;
          };
};

export const CampaignSettingsFragmentDoc = gql`
    fragment CampaignSettings on CampaignSettings {
        id
        accountId
        campaignName
        advertiserWebsite
        advertiserCreativeId
        enabled
        isCPCV
        schedulesAndTargets {
            id
            impressions
            CPM
            completedViews
            CPCV
            margin
            startDate
            endDate
            insertionOrder
        }
        hourlyFrequencyCap
        dailyFrequencyCap
        weeklyFrequencyCap
        monthlyFrequencyCap
        locationFilters
        locationFile
        allowThirdPartySegmentsInOptimization
        audienceDescriptors {
            id
            name
            enabled
            priorityIndex
        }
        desktopPlayerSizes
        mobilePlayerSizes
        useCrossDevice
        deviceMode
        allowDesktop
        allowMobileApp
        allowMobileWeb
        desktopMinBudget
        mobileMinBudget
        URLFilter
        keywordsFilter
        appNameFilter
        iabCategoriesFilter
        viewabilityGoal
        minimumViewability
        exchangeBlacklist
        brandSafetyCustomSegmentsLocation
        brandSafetyMode
        fraudMode
        verificationAuthority
        viewabilityMode
        restrictedContentAlcohol
        productCatalogName
        productCatalogSkuTemplate
        controlGroupPercent
        controlGroupMode
        productFilters {
            id
            type
            operator
            value
            attName
        }
        productSelectors {
            id
            type
            extraData
            audienceId
        }
        creativeDefinitions {
            id
            storyName
            numSKU
            videoParams
            supportedRatios
            videoQualityProfile
            allowedDurations
        }
        reportingDefinitions {
            id
            attributeType
            targetField
            tagName
            fieldValue
        }
        pixelRulesLocation
        allowPurchased
        rulesNewMode
    }
`;
export const CcPlaceholderIntentFragmentDoc = gql`
    fragment CcPlaceholderIntent on CcPlaceholderIntent {
        id
        name
        type
    }
`;
export const CcAnimatedWireframeWithVersionsFragmentDoc = gql`
    fragment CcAnimatedWireframeWithVersions on CcAnimatedWireframe {
        id
        updated
        updatedBy
        name
        lifecycleStage
        aspectRatio
        compatibility
        theme {
            id
            name
        }
        wireframe {
            id
            name
            lifecycleStage
        }
        animatedWireframeApprovedVersion {
            id
            updated
            updatedBy
            sourceFile
            anyUpdated
            anyUpdatedBy
            snapshotNumber
        }
        animatedWireframeDraftVersion {
            id
            updated
            updatedBy
            sourceFile
            anyUpdated
            anyUpdatedBy
        }
        mappedAnimatedWireframes {
            id
            name
            aspectRatio
        }
    }
`;
export const CcThemeFragmentDoc = gql`
    fragment CcTheme on CcTheme {
        id
        name
        description
        posterUrl
        isComingSoon
        editorOrder
        updated
        supportedAspectRatios
        lifecycleStage
        animatedWireframes {
            ...CcAnimatedWireframeWithVersions
            wireframe {
                id
                name
            }
        }
    }
    ${CcAnimatedWireframeWithVersionsFragmentDoc}
`;
export const CcVersionFragmentDoc = gql`
    fragment CcVersion on CcLibraryVersion {
        snapshotName
        snapshotComment
        id
        snapshotNumber
        snapshotted
        snapshottedBy
        usedInAccounts
    }
`;
export const CcPlaceholderFragmentDoc = gql`
    fragment CcPlaceholder on CcPlaceholder {
        id
        name
        updated
        intent {
            id
            type
        }
        descriptionOrder
        category
    }
`;
export const CcFrameFragmentDoc = gql`
    fragment CcFrame on CcFrame {
        id
        updated
        placeholders {
            ...CcPlaceholder
        }
    }
    ${CcPlaceholderFragmentDoc}
`;
export const CcWireframeFragmentDoc = gql`
    fragment CcWireframe on CcWireframe {
        id
        name
        updated
        description
        comment
        category
        usedInSnapshot
        lifecycleStage
        useToCreateDefaultScene
        frames {
            ...CcFrame
        }
        animatedWireframes {
            ...CcAnimatedWireframeWithVersions
            theme {
                id
                name
            }
        }
    }
    ${CcFrameFragmentDoc}
    ${CcAnimatedWireframeWithVersionsFragmentDoc}
`;
export const AccountCopilotBriefFragmentDoc = gql`
    fragment AccountCopilotBrief on AccountCopilotBrief {
        id
        name
        briefData
        createdBy
        created
    }
`;
export const AccountDataLibraryForImportDataFragmentDoc = gql`
    fragment AccountDataLibraryForImportData on AccountDataLibrary {
        id
        name
        publishedVideosUsingLibrary {
            id
            name
            prodVersion {
                id
                created
                editorProgramVersion {
                    id
                    usedElements
                    thumbnail {
                        url
                    }
                }
            }
        }
    }
`;
export const EditorProgramShareHistoryFragmentDoc = gql`
    fragment EditorProgramShareHistory on ProgramShareHistory {
        id
        created
        createdBy
        accountId
        shareType
        isExternal
    }
`;
export const AccountDataFieldFragmentDoc = gql`
    fragment AccountDataField on AccountDataField {
        id
        localId
        data
    }
`;
export const AccountDataLibraryVersionFragmentDoc = gql`
    fragment AccountDataLibraryVersion on AccountDataLibraryVersion {
        id
        localId
        data
        anyUpdated
        anyUpdatedBy
        usedInVideos {
            id
            name
            isArchive
            isLive
            programVersionDraft {
                id
                usedElements
            }
            prodVersion {
                id
                created
            }
            uatVersion {
                id
                created
            }
            shareHistory {
                ...EditorProgramShareHistory
            }
        }
        dataFields {
            ...AccountDataField
        }
        accountDataLibrary {
            id
            name
            description
        }
    }
    ${EditorProgramShareHistoryFragmentDoc}
    ${AccountDataFieldFragmentDoc}
`;
export const AccountDataLibraryFragmentDoc = gql`
    fragment AccountDataLibrary on AccountDataLibrary {
        id
        name
        description
        anyUpdated
        anyUpdatedBy
        draftAccountDataLibraryVersion {
            ...AccountDataLibraryVersion
        }
    }
    ${AccountDataLibraryVersionFragmentDoc}
`;
export const AccountDataLibraryPublishedFragmentDoc = gql`
    fragment AccountDataLibraryPublished on AccountDataLibrary {
        id
        name
        publishedVideosTargetLibrary {
            id
            name
            prodVersion {
                id
                created
                editorProgramVersion {
                    id
                    accountDataLibraryVersion {
                        id
                        data
                    }
                    thumbnail {
                        url
                    }
                }
            }
            shareHistory {
                ...EditorProgramShareHistory
            }
        }
        publishedVideosUsingLibrary {
            id
            name
            prodVersion {
                id
                created
                editorProgramVersion {
                    id
                    accountDataLibraryVersion {
                        id
                        data
                    }
                    usedElements
                    thumbnail {
                        url
                    }
                }
            }
            shareHistory {
                ...EditorProgramShareHistory
            }
        }
    }
    ${EditorProgramShareHistoryFragmentDoc}
`;
export const AccountPronunciationDefinitionFragmentDoc = gql`
    fragment AccountPronunciationDefinition on AccountPronunciationDefinition {
        id
        source
        target
    }
`;
export const AcquiaDamAssetMediaFragmentDoc = gql`
    fragment AcquiaDamAssetMedia on AcquiaAssetMedia {
        id
        name
        type
        created
        updated
        mediaUrl
        downloadUri
        thumbnailUri
        largeThumbnailUrl
        mediaMetadata {
            format
            sizeInKb
            width
            height
            duration
        }
    }
`;
export const AcquiaDamCategoryFragmentDoc = gql`
    fragment AcquiaDamCategory on AcquiaCategory {
        id
        name
        path
        parentCategoryPath
    }
`;
export const EditorAssetLibraryAvatarFragmentDoc = gql`
    fragment EditorAssetLibraryAvatar on EditorAccountLevelAvatar {
        id
        localId
        name
        description
        avatarSystemName
        thumbnailUrl
        sampleUrl
        lifecycleStage
        lifecycleMessage
        created
        fullBodyCrop {
            top
            right
            bottom
            left
        }
        closeUpCrop {
            top
            right
            bottom
            left
        }
        narrator {
            id
            localId
            name
            language
            description
            sampleUrl
            sampleTranscript
            narrationSystemName
            narrationSystemVoice
            narrationSystemMetadata
            lifecycleStage
        }
        sampleVideoMetadata
    }
`;
export const EditorLibraryDofFragmentDoc = gql`
    fragment EditorLibraryDof on CcDof {
        id
        localId
        name
        displayName
        type
    }
`;
export const EditorLibraryDofValueFragmentDoc = gql`
    fragment EditorLibraryDofValue on CcDofValue {
        id
        value
        dof {
            ...EditorLibraryDof
        }
    }
    ${EditorLibraryDofFragmentDoc}
`;
export const EditorLibraryThemeFragmentDoc = gql`
    fragment EditorLibraryTheme on CcTheme {
        id
        name
        posterUrl
        isComingSoon
        editorOrder
        lifecycleStage
        supportedAspectRatios
        supportsBackgroundMedia
        dofValues {
            ...EditorLibraryDofValue
        }
    }
    ${EditorLibraryDofValueFragmentDoc}
`;
export const EditorLibraryPlaceholderIntentFragmentDoc = gql`
    fragment EditorLibraryPlaceholderIntent on CcPlaceholderIntent {
        id
        name
        type
        defaultValue
        textStyle {
            id
        }
    }
`;
export const EditorWireframePlaceholderFragmentDoc = gql`
    fragment EditorWireframePlaceholder on CcPlaceholder {
        id
        name
        content
        category
        intent {
            ...EditorLibraryPlaceholderIntent
        }
    }
    ${EditorLibraryPlaceholderIntentFragmentDoc}
`;
export const EditorAnimatedWireframeFragmentDoc = gql`
    fragment EditorAnimatedWireframe on CcAnimatedWireframe {
        id
        name
        vsmlUrl
        lifecycleStage
        theme {
            id
        }
        aspectRatio
        compatibility
        defaultButtonAlignment
        placeholdersSettings {
            ... on CcAnimatedWireframeVersionTextPlaceholdersSettings {
                id
                horizontalAlignment
                verticalAlignment
                colorCcDof {
                    ...EditorLibraryDof
                }
                ccPlaceholder {
                    id
                }
                canvasName
            }
            ... on CcAnimatedWireframeVersionMediaPlaceholdersSettings {
                id
                horizontalAlignment
                verticalAlignment
                mediaAdjustment
                ccPlaceholder {
                    id
                }
                canvasName
            }
        }
        m2AnimatedWireframe {
            id
            compatibility
            vsmlUrl
            defaultButtonAlignment
        }
        composition {
            id
            name
            supportsUserTextOnMedia
            supportsUserMediaOverMedia
        }
    }
    ${EditorLibraryDofFragmentDoc}
`;
export const EditorLibraryWireframeFragmentDoc = gql`
    fragment EditorLibraryWireframe on CcWireframe {
        id
        name
        description
        lifecycleStage
        comment
        category
        frames {
            id
            placeholders {
                ...EditorWireframePlaceholder
            }
        }
        animatedWireframes {
            ...EditorAnimatedWireframe
        }
    }
    ${EditorWireframePlaceholderFragmentDoc}
    ${EditorAnimatedWireframeFragmentDoc}
`;
export const EditorLibraryMusicFragmentDoc = gql`
    fragment EditorLibraryMusic on CcMusic {
        id
        name
        url
    }
`;
export const EditorLibraryNarratorFragmentDoc = gql`
    fragment EditorLibraryNarrator on CcNarrator {
        id
        name
        sampleUrl
        narrationSystemName
        narrationSystemVoice
        intent
        narrationSystemMetadata {
            language
            gender
            rate
            volume
            age
            description
        }
    }
`;
export const EditorLibraryAvatarFragmentDoc = gql`
    fragment EditorLibraryAvatar on CcAvatar {
        id
        name
        sampleUrl
        thumbnailUrl
        localId
        narrator {
            ...EditorLibraryNarrator
        }
        lifecycleStage
        fullBodyCrop {
            top
            right
            bottom
            left
        }
        closeUpCrop {
            top
            right
            bottom
            left
        }
        sampleVideoMetadata
    }
    ${EditorLibraryNarratorFragmentDoc}
`;
export const EditorLibraryFontFragmentDoc = gql`
    fragment EditorLibraryFont on CcFont {
        id
        name
        url
        hasSmallCaps
        lifecycleStage
        webFontUrl
        created
        familyName
        isBold
        isItalic
        weight
    }
`;
export const EditorLibraryTextStyleFragmentDoc = gql`
    fragment EditorLibraryTextStyle on CcTextStyle {
        id
        name
    }
`;
export const EditorCcLibraryVersionFragmentDoc = gql`
    fragment EditorCcLibraryVersion on CcLibraryVersion {
        id
        themes {
            ...EditorLibraryTheme
        }
        wireframes {
            ...EditorLibraryWireframe
        }
        music {
            ...EditorLibraryMusic
        }
        narrators {
            ...EditorLibraryNarrator
        }
        avatars {
            ...EditorLibraryAvatar
        }
        fonts {
            ...EditorLibraryFont
        }
        textStyles {
            ...EditorLibraryTextStyle
        }
        placeholderIntents {
            ...EditorLibraryPlaceholderIntent
        }
    }
    ${EditorLibraryThemeFragmentDoc}
    ${EditorLibraryWireframeFragmentDoc}
    ${EditorLibraryMusicFragmentDoc}
    ${EditorLibraryNarratorFragmentDoc}
    ${EditorLibraryAvatarFragmentDoc}
    ${EditorLibraryFontFragmentDoc}
    ${EditorLibraryTextStyleFragmentDoc}
    ${EditorLibraryPlaceholderIntentFragmentDoc}
`;
export const LifecycleHistoryEntryFragmentDoc = gql`
    fragment LifecycleHistoryEntry on LifecycleHistoryEntry {
        id
        created
        createdBy
        snapshotName
        snapshotNumber
        snapshotValidationData
        accountDataLibraryVersion {
            ...AccountDataLibraryVersion
        }
        editorProgramVersion {
            id
            editorDataConnectorEnabled
            selectedMusicSource
            usedElements
            thumbnail {
                url
            }
            pronunciationLibraryVersion {
                id
                anyUpdated
            }
            selectedBrandConfiguration {
                id
                localId
                name
                updated
            }
            chapteringEnabled
        }
    }
    ${AccountDataLibraryVersionFragmentDoc}
`;
export const LandingPageFragmentDoc = gql`
    fragment LandingPage on LandingPage {
        id
        updated
        updatedBy
        updatedByUser {
            id
            email
            firstName
            lastName
        }
        created
        createdBy
        createdByUser {
            id
            email
            firstName
            lastName
        }
        configuration
        isActive
        lifecycleStage
    }
`;
export const EmbedVideoCodeFragmentDoc = gql`
    fragment EmbedVideoCode on EmbedVideoCode {
        id
        updated
        updatedBy
        updatedByUser {
            id
            email
            firstName
            lastName
        }
        created
        createdBy
        createdByUser {
            id
            email
            firstName
            lastName
        }
        configuration
    }
`;
export const EditorProgramFragmentDoc = gql`
    fragment EditorProgram on EditorProgram {
        id
        name
        description
        saleForceId
        programType
        publishTarget
        created
        isArchive
        isLive
        firstViewedDate
        isReadyToGoLive
        landingPageUrl
        programVersionDraft {
            id
            anyUpdatedBy
            anyUpdated
            publishable
            sceneLineup
            editorDataConnectorEnabled
            chapteringEnabled
            editorTtsFullEnablement
            selectedMusicSource
            accountDataLibraryVersion {
                id
                data
                anyUpdatedBy
                anyUpdated
                dataFields {
                    id
                    data
                    localId
                }
                accountDataLibrary {
                    id
                    name
                }
            }
            ccLibraryVersion {
                id
                library {
                    id
                }
            }
            compatibility
            pronunciationLibraryVersion {
                id
                anyUpdated
            }
            selectedBrandConfiguration {
                id
                localId
                name
                updated
            }
        }
        uatVersion {
            ...LifecycleHistoryEntry
        }
        prodVersion {
            ...LifecycleHistoryEntry
        }
        firstProdVersion {
            ...LifecycleHistoryEntry
        }
        landingPage {
            ...LandingPage
        }
        embedVideoCode {
            ...EmbedVideoCode
        }
        shareHistory {
            ...EditorProgramShareHistory
        }
    }
    ${LifecycleHistoryEntryFragmentDoc}
    ${LandingPageFragmentDoc}
    ${EmbedVideoCodeFragmentDoc}
    ${EditorProgramShareHistoryFragmentDoc}
`;
export const EditorProgramSelectedMusicFragmentDoc = gql`
    fragment EditorProgramSelectedMusic on EditorProgramVersion {
        selectedMusicSource
        selectedCcMusic {
            id
        }
        selectedAccountMusic {
            id
        }
    }
`;
export const EditorProgramVersionAllLogicFragmentDoc = gql`
    fragment EditorProgramVersionAllLogic on EditorProgramVersion {
        id
        usedElements
        bcRules
        bcUseRules
        selectedBrandConfiguration {
            id
        }
        scenes {
            id
            skipSceneMode
            skipSceneAudience
            placeholders {
                id
                placeholderContent
            }
        }
    }
`;
export const EditorSceneConfigurationFragmentDoc = gql`
    fragment EditorSceneConfiguration on EditorScene {
        id
        name
        description
        skipSceneMode
        colorCombination
        buttonAlignment
        skipSceneAudience
        minimumDuration
        hasTransition
        avatarVideoStatus
        avatarVideoUrl
    }
`;
export const EditorSceneAnimatedWireframeFragmentDoc = gql`
    fragment EditorSceneAnimatedWireframe on EditorScene {
        id
        ccAnimatedWireframe {
            ...EditorAnimatedWireframe
        }
    }
    ${EditorAnimatedWireframeFragmentDoc}
`;
export const EditorPlaceholderConfigurationFragmentDoc = gql`
    fragment EditorPlaceholderConfiguration on EditorPlaceholder {
        id
        placeholderContent
        placeholderSettings
        flexibleSettings {
            top
            left
            width
            height
        }
        parentType
        parentPlaceholder {
            id
        }
    }
`;
export const EditorPlaceholderFragmentDoc = gql`
    fragment EditorPlaceholder on EditorPlaceholder {
        ...EditorPlaceholderConfiguration
        ccPlaceholder {
            ...EditorWireframePlaceholder
        }
    }
    ${EditorPlaceholderConfigurationFragmentDoc}
    ${EditorWireframePlaceholderFragmentDoc}
`;
export const EditorSceneFragmentDoc = gql`
    fragment EditorScene on EditorScene {
        id
        ...EditorSceneConfiguration
        ...EditorSceneAnimatedWireframe
        placeholders {
            ...EditorPlaceholder
        }
        ccWireframe {
            name
            id
            lifecycleStage
            category
            frames {
                id
                placeholders {
                    id
                }
            }
        }
        usedMediaIds
    }
    ${EditorSceneConfigurationFragmentDoc}
    ${EditorSceneAnimatedWireframeFragmentDoc}
    ${EditorPlaceholderFragmentDoc}
`;
export const CcWireframeAllWireframesMappingFragmentDoc = gql`
    fragment CcWireframeAllWireframesMapping on CcWireframeAllWireframesData {
        wireframe {
            id
            name
            lifecycleStage
            comment
            category
        }
        placeholdersData {
            targetCcPlaceholder {
                ...EditorWireframePlaceholder
            }
            originalCcPlaceholder {
                id
            }
        }
        isLosingData
    }
    ${EditorWireframePlaceholderFragmentDoc}
`;
export const EditorScenePlaceholdersSelectedAnimatedWireframeAllWireframesMappingFragmentDoc = gql`
    fragment EditorScenePlaceholdersSelectedAnimatedWireframeAllWireframesMapping on EditorScene {
        id
        placeholders {
            ...EditorPlaceholderConfiguration
            ccPlaceholder {
                id
            }
        }
        ccWireframe {
            id
            allWireframesMapping {
                ...CcWireframeAllWireframesMapping
            }
        }
        ccAnimatedWireframe {
            id
        }
        colorCombination
    }
    ${EditorPlaceholderConfigurationFragmentDoc}
    ${CcWireframeAllWireframesMappingFragmentDoc}
`;
export const EditorStockLibraryMusicFragmentDoc = gql`
    fragment EditorStockLibraryMusic on EditorStockMusic {
        id
        title
        durationInSeconds
        previewUrl
        artists
        tags {
            id
            name
        }
    }
`;
export const EditorViewerProfileFragmentDoc = gql`
    fragment EditorViewerProfile on ViewerProfile {
        id
        name
        content
        updated
    }
`;
export const GalleryStoryTemplateFragmentDoc = gql`
    fragment GalleryStoryTemplate on EditorApplication {
        id
        name
        metadata
        isParent
        accountId
        parent {
            id
        }
        useCases {
            id
            name
        }
        industry {
            id
            name
        }
        flavor {
            id
            name
        }
        creativeStyleFits {
            id
            name
        }
        channels {
            id
            name
        }
        posterImageUrl
        previewVideoUrl
        customThumbnailUrl
        newStoryTemplate
        programVersion {
            id
            aspectRatio
        }
    }
`;
export const MediaCropHintsFragmentDoc = gql`
    fragment MediaCropHints on MediaCropHints {
        suggestedCrop {
            x
            y
            width
            height
        }
    }
`;
export const StudioElementFragmentDoc = gql`
    fragment StudioElement on DerivedDataElement {
        id
        localId
        name
        dataType
        valueSet
    }
`;
export const TrayAccountConnectionFragmentDoc = gql`
    fragment TrayAccountConnection on AccountConnection {
        id
        isConnected
        connectorType
        connectionUpdated
        connectionUpdatedBy
    }
`;
export const UndoStacksFragmentDoc = gql`
    fragment UndoStacks on UndoStacks {
        id
        undoStackSize
        redoStackSize
        firstUndoActionName
        firstRedoActionName
    }
`;
export const EditorSceneWithoutCcDataFragmentDoc = gql`
    fragment EditorSceneWithoutCcData on EditorScene {
        id
        ...EditorSceneConfiguration
        ccAnimatedWireframe {
            id
        }
        placeholders {
            ...EditorPlaceholderConfiguration
            ccPlaceholder {
                id
            }
        }
        ccWireframe {
            id
        }
        usedMediaIds
    }
    ${EditorSceneConfigurationFragmentDoc}
    ${EditorPlaceholderConfigurationFragmentDoc}
`;
export const ProgramFolderFragmentDoc = gql`
    fragment ProgramFolder on AccountProgramFolder {
        id
        isRoot
        name
        created
        createdBy
        updated
        updatedBy
        parentFolder {
            id
            name
            created
            createdBy
            updated
            updatedBy
            isRoot
        }
    }
`;
export const EditorProgramVideoFragmentDoc = gql`
    fragment EditorProgramVideo on EditorProgram {
        id
        name
        isArchive
        isLive
        created
        createdBy
        creationMethod
        originProgramId
        originalStoryTemplateProgramId
        originalStoryTemplateProgramVersionId
        lifecycleStage
        publishTarget
        prodVersion {
            id
            created
            createdBy
            snapshotName
            snapshotNumber
            editorProgramVersion {
                id
                usedElements
                editorDataConnectorEnabled
            }
        }
        uatVersion {
            id
            created
            createdBy
            snapshotName
            snapshotNumber
            editorProgramVersion {
                id
                usedElements
            }
        }
        programVersionDraft {
            id
            thumbnail {
                url
            }
            aspectRatio
            anyUpdated
            anyUpdatedBy
            compatibility
            usedElements
            editorDataConnectorEnabled
            selectedNarrator {
                id
                narrationSystemName
            }
            editorTtsFullEnablement
        }
        lastArchived
        folder {
            id
            name
        }
        landingPage {
            id
            isActive
        }
        embedVideoCode {
            id
        }
        shareHistory {
            ...EditorProgramShareHistory
        }
    }
    ${EditorProgramShareHistoryFragmentDoc}
`;
export const EditorProgramVideoOldFragmentDoc = gql`
    fragment EditorProgramVideoOld on EditorProgram {
        id
        name
        anyUpdated
        anyUpdatedBy
        isArchive
        created
        createdBy
        prodVersion {
            id
            created
            createdBy
            snapshotName
            snapshotNumber
        }
        programVersionDraft {
            id
            thumbnail {
                url
            }
            aspectRatio
        }
        lastArchived
    }
`;
export const FeatureDefinitionFragmentDoc = gql`
    fragment FeatureDefinition on FeatureDefinition {
        id
        featureTypeDefinition
        featureRestrictionScope
        featureRestrictionType
        errorMessage
        userControlled
    }
`;
export const AccountFeatureDataFragmentDoc = gql`
    fragment AccountFeatureData on AccountFeatureData {
        id
        featureRestrictionValue
        featureTypeDefinition
        featureSource
        featureDefinition {
            ...FeatureDefinition
        }
    }
    ${FeatureDefinitionFragmentDoc}
`;
export const AccountFeatureFullDataFragmentDoc = gql`
    fragment AccountFeatureFullData on AccountFeatureData {
        ...AccountFeatureData
        featureCurrentUsageInfo
    }
    ${AccountFeatureDataFragmentDoc}
`;
export const AnimationVersionFragmentDoc = gql`
    fragment AnimationVersion on AnimationVersion {
        id
        version
        created
        createdBy
        aeFilename
        comment
    }
`;
export const AnimationFragmentDoc = gql`
    fragment Animation on Animation {
        id
        legacyAnimationId
        name
        description
        updated
        animationActiveVersion {
            ...AnimationVersion
        }
    }
    ${AnimationVersionFragmentDoc}
`;
export const AssetVersionFragmentDoc = gql`
    fragment AssetVersion on AssetVersion {
        id
        url
        thumbnailUrl
        filename
        width
        height
        format
        size
        version
        created
        createdBy
    }
`;
export const AssetFragmentDoc = gql`
    fragment Asset on Asset {
        id
        legacyAssetId
        accountId
        name
        description
        type
        created
        anyUpdated
        legacyData
        assetActiveVersion {
            ...AssetVersion
        }
    }
    ${AssetVersionFragmentDoc}
`;
export const DataTableFragmentDoc = gql`
    fragment DataTable on DataTable {
        id
        accountId
        legacyDataTableId
        name
        description
        updated
        created
        dtaasPrefix
        location
        schema
        downloadUrl
    }
`;
export const NarrationRecordingVersionFragmentDoc = gql`
    fragment NarrationRecordingVersion on NarrationRecordingVersion {
        id
        created
        createdBy
        url
        format
        location
        status
        errorReason
        version
    }
`;
export const NarrationRecordingFragmentDoc = gql`
    fragment NarrationRecording on NarrationRecording {
        id
        created
        createdBy
        updated
        key
        filename
        text
        rfr
        metadata
        lastVersionNumber
        narrationId
        narrationRecordingActiveVersion {
            ...NarrationRecordingVersion
        }
    }
    ${NarrationRecordingVersionFragmentDoc}
`;
export const NarratorFragmentDoc = gql`
    fragment Narrator on Narrator {
        id
        created
        createdBy
        updated
        updatedBy
        legacyNarratorId
        name
        carUrl
        dtaasPrefix
        status
        decisionTablesLoadTaskId
    }
`;
export const EditorAssetLibraryMediaFragmentDoc = gql`
    fragment EditorAssetLibraryMedia on EditorAssetMedia {
        id
        name
        created
        createdByUser {
            id
            email
            firstName
            lastName
        }
        updated
        mediaLocation
        mediaUrl
        mediaPreviewUrl
        mediaAgifUrl
        mediaOrigin
        mediaMetadata
        mediaProcessingInfo
        mediaFramesThumbnailsUrl
        originalEditInformation
        originalMediaId
        lifecycleStage
        mediaSri
        externalLibraryAssetId
        externalLibrary
        externalSublibrary
        directAncestorId
        mediaClassification
        description
        folder {
            id
        }
    }
`;
export const EditorBrandColorFragmentDoc = gql`
    fragment EditorBrandColor on BcColor {
        id
        updated
        color
        ccDof {
            ...EditorLibraryDof
        }
    }
    ${EditorLibraryDofFragmentDoc}
`;
export const EditorAssetLibraryFontFragmentDoc = gql`
    fragment EditorAssetLibraryFont on EditorAssetFont {
        id
        name
        url
        imageUrl
        webFontUrl
        hasSmallCaps
        lifecycleStage
        uploadId
        created
        familyName
        isBold
        isItalic
        weight
    }
`;
export const EditorBrandTextStyleFragmentDoc = gql`
    fragment EditorBrandTextStyle on BcTextStyle {
        id
        ccTextStyle {
            ...EditorLibraryTextStyle
        }
        ccFont {
            ...EditorLibraryFont
        }
        accountFont {
            ...EditorAssetLibraryFont
        }
        fontSource
        letterCase
        letterSpacing
        lineSpacing
        updated
    }
    ${EditorLibraryTextStyleFragmentDoc}
    ${EditorLibraryFontFragmentDoc}
    ${EditorAssetLibraryFontFragmentDoc}
`;
export const EditorBrandFragmentDoc = gql`
    fragment EditorBrand on BrandConfigurationCollection {
        id
        localId
        updated
        updatedBy
        created
        name
        description
        logoUrl
        logoMedia {
            ...EditorAssetLibraryMedia
        }
        logoLocation
        colors {
            ...EditorBrandColor
        }
        textStyles {
            ...EditorBrandTextStyle
        }
        buttonShape
    }
    ${EditorAssetLibraryMediaFragmentDoc}
    ${EditorBrandColorFragmentDoc}
    ${EditorBrandTextStyleFragmentDoc}
`;
export const EditorAssetLibraryMusicFragmentDoc = gql`
    fragment EditorAssetLibraryMusic on EditorAssetMusic {
        id
        name
        url
        lifecycleStage
        externalLibraryAssetId
        musicOrigin
    }
`;
export const EditorAssetFolderFragmentDoc = gql`
    fragment EditorAssetFolder on EditorAssetFolder {
        id
        name
        parentFolder {
            id
        }
        created
        createdBy
        updated
        updatedBy
    }
`;
export const NewlyCreatedEditorProgramFragmentDoc = gql`
    fragment NewlyCreatedEditorProgram on EditorProgram {
        id
        name
        accountId
        created
        createdBy
        programVersionDraft {
            id
            brandConfigurationVersion {
                id
                defaultBrandConfigurationCollection {
                    id
                    localId
                }
                brandConfigurationCollections {
                    id
                }
            }
            selectedBrandConfiguration {
                ...EditorBrand
            }
            assetLibrary {
                id
                fonts {
                    ...EditorAssetLibraryFont
                }
                music {
                    ...EditorAssetLibraryMusic
                }
                media {
                    ...EditorAssetLibraryMedia
                }
                mediaFolders {
                    ...EditorAssetFolder
                }
            }
        }
        account {
            id
            editorPrograms {
                id
            }
        }
    }
    ${EditorBrandFragmentDoc}
    ${EditorAssetLibraryFontFragmentDoc}
    ${EditorAssetLibraryMusicFragmentDoc}
    ${EditorAssetLibraryMediaFragmentDoc}
    ${EditorAssetFolderFragmentDoc}
`;
export const PresetFragmentDoc = gql`
    fragment Preset on Preset {
        id
        name
        isCreative
        updated
        dataElementValues {
            id
            type
            value
            valueId
        }
        systemElementValues {
            id
            systemElementId
            type
            value
            valueId
        }
    }
`;
export const PresetWithoutValuesFragmentDoc = gql`
    fragment PresetWithoutValues on Preset {
        id
        name
        isCreative
        updated
    }
`;
export const ProgramVersionSnapshotDetailsFragmentDoc = gql`
    fragment ProgramVersionSnapshotDetails on ProgramVersion {
        id
        snapshotNumber
        snapshotName
        snapshotComment
        snapshotValidationData
        snapshotted
        snapshottedBy
        parent1ProgramVersion {
            id
        }
    }
`;
export const RecordingDocFragmentDoc = gql`
    fragment RecordingDoc on RecordingDocEntry {
        id
        audioFileUrl
        audioFilename
        scriptFileUrl
        scriptFilename
        totalRecordingFiles
        created
        createdBy
        folderLocation
    }
`;
export const ScenePartNarrationsFragmentDoc = gql`
    fragment ScenePartNarrations on ScenePart {
        id
        narrationPartOrder
        narrations {
            id
            updated
            representativeKey
            supportsCreativeDataElementOverride
            mergeData
            dimensionData
            dtaasPrefixKey
            lastUsedRecordingFileNumber
            narrationVariations {
                id
                updated
                variationKey
                sentence
                muted
                note
                overridingCreativeDataElement {
                    id
                }
            }
        }
    }
`;
export const ScenePartFragmentDoc = gql`
    fragment ScenePart on ScenePart {
        id
        updated
        created
        thumbnailLocation
        ...ScenePartNarrations
        placeholders {
            id
            updated
            x
            y
            type
            height
            width
            logic {
                id
                name
                logic
            }
        }
        placeholderGroups {
            id
            updated
            logic {
                id
                name
                logic
            }
            placeholders {
                id
                updated
                x
                y
                type
                height
                width
                logic {
                    id
                    name
                    logic
                }
            }
        }
        prioritizedLists {
            id
            updated
            logic {
                id
                name
                logic
            }
            groups {
                id
                updated
                logic {
                    id
                    name
                    logic
                }
                placeholders {
                    id
                    x
                    y
                    type
                    height
                    width
                    logic {
                        id
                        name
                        logic
                    }
                }
            }
            placeholders {
                id
                updated
                x
                y
                type
                height
                width
                logic {
                    id
                    name
                    logic
                }
            }
        }
        animationGuidelines {
            id
            updated
            type
            logic {
                id
                name
                logic
            }
        }
        oldNarrationData
    }
    ${ScenePartNarrationsFragmentDoc}
`;
export const SceneForBuilderFragmentDoc = gql`
    fragment SceneForBuilder on Scene {
        id
        name
        created
        updated
        animationLogic
        validationLogic
        descriptiveTranscriptLogic
        scenePartOrder
        sceneParts {
            ...ScenePart
        }
    }
    ${ScenePartFragmentDoc}
`;
export const TierFeatureDefinitionFragmentDoc = gql`
    fragment TierFeatureDefinition on TierFeatureDefinition {
        id
        tierDefinition {
            id
            displayName
        }
        featureDefinition {
            ...FeatureDefinition
        }
        featureRestrictionValue
    }
    ${FeatureDefinitionFragmentDoc}
`;
export const CreativeDraftInfoFragmentDoc = gql`
    fragment CreativeDraftInfo on CreativeVersion {
        id
        description
        storyId
        creativeDDEValue
        updated
    }
`;
export const CreativeInfoFragmentDoc = gql`
    fragment CreativeInfo on Creative {
        id
        legacyBuilderCreativeId
        created
        createdBy
        updated
        anyUpdated
        anyUpdatedBy
        name
        description
        creativeVersionDraft {
            ...CreativeDraftInfo
        }
    }
    ${CreativeDraftInfoFragmentDoc}
`;
export const CreativeLifecycleHistoryFragmentDoc = gql`
    fragment CreativeLifecycleHistory on CreativeLifecycleHistoryEntry {
        id
        snapshotNumber
        lifecycleStage
        created
        createdBy
    }
`;
export const CreativeVersionInfoFragmentDoc = gql`
    fragment CreativeVersionInfo on CreativeVersion {
        id
        description
        snapshotNumber
        name
        validationData
        updated
        updatedBy
    }
`;
export const CreativeAssetVersionFragmentDoc = gql`
    fragment CreativeAssetVersion on CreativeAssetVersion {
        id
        filename
        location
        thumbnailLocation
        mediaType
        url
        thumbnailUrl
        width
        height
    }
`;
export const CreativeAssetFragmentDoc = gql`
    fragment CreativeAsset on CreativeAsset {
        id
        name
        creativeAssetActiveVersion {
            ...CreativeAssetVersion
        }
    }
    ${CreativeAssetVersionFragmentDoc}
`;
export const CreativeNarrationRecordingVersionFragmentDoc = gql`
    fragment CreativeNarrationRecordingVersion on CreativeNarrationRecordingVersion {
        id
        created
        createdBy
        updated
        updatedBy
        status
        errorReason
        format
        location
        mediaType
        mediaSubType
        url
        size
    }
`;
export const CreativeNarrationRecordingFragmentDoc = gql`
    fragment CreativeNarrationRecording on CreativeNarrationRecording {
        id
        created
        createdBy
        updated
        updatedBy
        narrationText
        cdeId
        rfr
        filename
        mockNarrationUrl
        creativeNarrationRecordingActiveVersion {
            ...CreativeNarrationRecordingVersion
        }
        creativeNarrationRecordingVersions {
            ...CreativeNarrationRecordingVersion
        }
    }
    ${CreativeNarrationRecordingVersionFragmentDoc}
`;
export const CreativeVersionFragmentDoc = gql`
    fragment CreativeVersion on CreativeVersion {
        id
        updated
        rfr
        creativeData
        creativeAssets {
            ...CreativeAsset
        }
        creativeNarrationRecordings {
            ...CreativeNarrationRecording
        }
    }
    ${CreativeAssetFragmentDoc}
    ${CreativeNarrationRecordingFragmentDoc}
`;
export const ExperimentFragmentDoc = gql`
    fragment Experiment on Experiment {
        id
        experimentName
        experimentComponent
        experimentType
        description
        startTime
        endTime
        enabled
        metric
        variants {
            variantId
            variantName
            fraction
        }
        created
        createdBy
        updated
        updatedBy
    }
`;
export const AudienceSupportersFragmentDoc = gql`
    fragment AudienceSupporters on Scene {
        kpis {
            id
            goal {
                id
            }
        }
        stories {
            id
        }
    }
`;
export const AudienceValueSupportersFragmentDoc = gql`
    fragment AudienceValueSupporters on Scene {
        kpis {
            id
            goal {
                id
            }
        }
        stories {
            id
        }
    }
`;
export const ChannelSupportersFragmentDoc = gql`
    fragment ChannelSupporters on Scene {
        kpis {
            id
            goal {
                id
            }
        }
        stories {
            id
        }
    }
`;
export const DataElementSupportersFragmentDoc = gql`
    fragment DataElementSupporters on Scene {
        kpis {
            id
            goal {
                id
            }
        }
        stories {
            id
        }
    }
`;
export const GoalSupportersFragmentDoc = gql`
    fragment GoalSupporters on Scene {
        feedDataElements {
            id
        }
        stories {
            id
            touchpoints {
                id
            }
            channels {
                id
            }
        }
        personalizations {
            ... on AudiencePersonalization {
                audience {
                    id
                    feedDataElements {
                        id
                    }
                }
                audiencePersonalizationValues {
                    audienceValue {
                        id
                    }
                }
            }
            ... on ChannelPersonalization {
                channel {
                    id
                }
            }
            ... on TouchpointPersonalization {
                touchpoint {
                    id
                }
            }
        }
    }
`;
export const KpiSupportersFragmentDoc = gql`
    fragment KPISupporters on Scene {
        feedDataElements {
            id
        }
        stories {
            id
            touchpoints {
                id
            }
            channels {
                id
            }
        }
        personalizations {
            ... on AudiencePersonalization {
                audience {
                    id
                }
                audiencePersonalizationValues {
                    audienceValue {
                        id
                    }
                }
            }
            ... on ChannelPersonalization {
                channel {
                    id
                }
            }
            ... on TouchpointPersonalization {
                touchpoint {
                    id
                }
            }
        }
    }
`;
export const ProgramVersionMetadataFragmentDoc = gql`
    fragment ProgramVersionMetadata on ProgramVersion {
        id
        name
        updated
        updatedBy
        anyUpdated
        anyUpdatedBy
        __typename
        useCase
        audienceSize
        industry
        description
    }
`;
export const KpiFragmentDoc = gql`
    fragment KPI on KPI {
        id
        updated
        __typename
        name
        description
        objective
        scenes {
            id
        }
        goal {
            id
        }
    }
`;
export const GoalFragmentDoc = gql`
    fragment Goal on Goal {
        id
        updated
        __typename
        name
        description
        priority
        kpis {
            ...KPI
        }
    }
    ${KpiFragmentDoc}
`;
export const StoryFragmentDoc = gql`
    fragment Story on Story {
        id
        updated
        __typename
        name
        description
        displaySceneOrder
        scenes {
            id
            name
        }
        touchpoints {
            id
            name
        }
        channels {
            id
            name
        }
    }
`;
export const PersonalizationFragmentDoc = gql`
    fragment Personalization on Personalization {
        id
        updated
        __typename
        type
        description
        ... on AudiencePersonalization {
            audience {
                id
                updated
                name
                feedDataElements {
                    id
                }
            }
            audiencePersonalizationValues {
                id
                updated
                description
                audienceValue {
                    id
                    updated
                    name
                }
            }
        }
        ... on ChannelPersonalization {
            channel {
                id
                updated
                name
            }
        }
        ... on TouchpointPersonalization {
            touchpoint {
                id
                updated
                name
            }
        }
    }
`;
export const SceneFragmentDoc = gql`
    fragment Scene on Scene {
        id
        updated
        __typename
        name
        description
        feedDataElements {
            id
            name
            updated
        }
        kpis {
            id
            goal {
                id
            }
        }
        personalizations {
            ...Personalization
        }
        stories {
            id
        }
    }
    ${PersonalizationFragmentDoc}
`;
export const AudienceValueFragmentDoc = gql`
    fragment AudienceValue on AudienceValue {
        id
        updated
        __typename
        name
        volume
        audience {
            id
            feedDataElements {
                id
            }
        }
        subAudiences {
            id
            updated
            __typename
            name
            audienceValues {
                id
                updated
                __typename
                name
                volume
                audience {
                    id
                }
            }
            audienceValue {
                id
                updated
                name
            }
        }
        audiencePersonalizationValues {
            id
            description
            audiencePersonalization {
                id
                type
                scene {
                    id
                    name
                }
            }
        }
    }
`;
export const AudienceFragmentDoc = gql`
    fragment Audience on Audience {
        id
        updated
        __typename
        name
        description
        impactsVolume
        audiencePersonalizations {
            id
            type
            scene {
                id
            }
        }
        audienceValues {
            ...AudienceValue
        }
        feedDataElements {
            id
            name
            updated
        }
    }
    ${AudienceValueFragmentDoc}
`;
export const ChannelFragmentDoc = gql`
    fragment Channel on Channel {
        id
        updated
        __typename
        name
        platforms
        status
        channelPersonalizations {
            id
            description
            type
            scene {
                id
                name
            }
        }
        stories {
            id
        }
    }
`;
export const TouchpointFragmentDoc = gql`
    fragment Touchpoint on Touchpoint {
        id
        updated
        __typename
        name
        description
        touchpointPersonalizations {
            id
            description
            type
            scene {
                id
                name
            }
        }
        stories {
            id
        }
    }
`;
export const DataElementFragmentDoc = gql`
    fragment DataElement on FeedDataElement {
        id
        __typename
        updated
        name
        description
        source
        status
        scenes {
            id
            name
        }
        audiences {
            id
            name
        }
    }
`;
export const ProgramVersionFragmentDoc = gql`
    fragment ProgramVersion on ProgramVersion {
        ...ProgramVersionMetadata
        sceneOrder
        storyOrder
        goals {
            ...Goal
        }
        stories {
            ...Story
        }
        scenes {
            ...Scene
        }
        audiences {
            ...Audience
        }
        channels {
            ...Channel
        }
        touchpoints {
            ...Touchpoint
        }
        feedDataElements {
            ...DataElement
        }
    }
    ${ProgramVersionMetadataFragmentDoc}
    ${GoalFragmentDoc}
    ${StoryFragmentDoc}
    ${SceneFragmentDoc}
    ${AudienceFragmentDoc}
    ${ChannelFragmentDoc}
    ${TouchpointFragmentDoc}
    ${DataElementFragmentDoc}
`;
export const SceneSupportersFragmentDoc = gql`
    fragment SceneSupporters on Story {
        touchpoints {
            id
        }
        channels {
            id
        }
    }
`;
export const StorySupportersFragmentDoc = gql`
    fragment StorySupporters on Scene {
        kpis {
            id
            goal {
                id
            }
        }
        feedDataElements {
            id
        }
        personalizations {
            ... on AudiencePersonalization {
                audience {
                    id
                    feedDataElements {
                        id
                    }
                }
                audiencePersonalizationValues {
                    audienceValue {
                        id
                    }
                }
            }
            ... on ChannelPersonalization {
                channel {
                    id
                }
            }
            ... on TouchpointPersonalization {
                touchpoint {
                    id
                }
            }
        }
    }
`;
export const TouchpointSupportersFragmentDoc = gql`
    fragment TouchpointSupporters on Scene {
        kpis {
            id
            goal {
                id
            }
        }
        stories {
            id
        }
    }
`;
export const CustomAnalyticFieldFragmentDoc = gql`
    fragment CustomAnalyticField on CustomAnalyticField {
        id
        accountId
        updated
        updatedBy
        __typename
        name
        logic
        program {
            id
            customAnalyticFields {
                id
                name
            }
        }
    }
`;
export const SurveyQuestionFragmentDoc = gql`
    fragment SurveyQuestion on SurveyQuestion {
        externalId
        text
    }
`;
export const SurveyFragmentDoc = gql`
    fragment Survey on Survey {
        id
        externalId
        updated
        questions {
            ...SurveyQuestion
        }
    }
    ${SurveyQuestionFragmentDoc}
`;
export const ProgramFragmentDoc = gql`
    fragment Program on Program {
        id
        programType
        saleForceId
        accountId
        created
        createdBy
        updated
        anyUpdated
        updatedBy
        anyUpdatedBy
        __typename
        name
        description
        legacyBuilderProgramId
        legacyFrameworkProgramId
        legacyOverviewProgramId
        isEditor
        customAnalyticFields {
            ...CustomAnalyticField
        }
        surveys {
            ...Survey
        }
        lifecycleStage
        isArchive
    }
    ${CustomAnalyticFieldFragmentDoc}
    ${SurveyFragmentDoc}
`;
export const ReportElementFragmentDoc = gql`
    fragment ReportElement on ReportElement {
        name
        platformName
        description
        platformDescription
        elementType
        formatType
        formatter
        reportLibraryConfigKey
        placeHolder
    }
`;
export const AccountScheduleFragmentDoc = gql`
    fragment AccountSchedule on AccountSchedule {
        id
        active
        created
        createdBy
        updated
        deliveryChannel
        occurrence
        period
        periodType
        startPeriod
        sourceEnvironments
        filenameTemplate
    }
`;
export const AccountReportFragmentDoc = gql`
    fragment AccountReport on AccountReport {
        id
        name
        type
        timezone
        created
        createdBy
        updated
        updatedBy
        reportProgramType
        filters {
            filterType
            values
        }
        relevantPrograms
        programs {
            id
            saleForceId
            name
            lifecycleStage
            isArchive
        }
        campaigns {
            id
            campaignName
        }
        reportElements {
            ...ReportElement
        }
        schedules {
            ...AccountSchedule
        }
    }
    ${ReportElementFragmentDoc}
    ${AccountScheduleFragmentDoc}
`;
export const AccountFragmentDoc = gql`
    fragment Account on Account {
        id
        displayName
        name
        programs {
            ...Program
        }
        campaigns {
            id
            campaignName
        }
        reports {
            ...AccountReport
        }
    }
    ${ProgramFragmentDoc}
    ${AccountReportFragmentDoc}
`;
export const ReportLibraryElementFragmentDoc = gql`
    fragment ReportLibraryElement on ReportLibraryElement {
        id
        reportLibraryConfigKey
        name
        platformName
        description
        platformDescription
        elementType
        formatType
        placeHolder
        customerFacing
        notCustomerFacingInBuilder
        isDefaultColumn
        defaultOrder
        dataSamples
    }
`;
export const UpdateAccountFeatureDataDocument = gql`
    mutation updateAccountFeatureData($input: UpdateAccountFeatureDataInput!) {
        updateAccountFeatureData(input: $input) {
            result
            features {
                ...AccountFeatureData
            }
        }
    }
    ${AccountFeatureDataFragmentDoc}
`;
export type GqlClientUpdateAccountFeatureDataMutationFn = Apollo.MutationFunction<GqlClientUpdateAccountFeatureDataMutation, GqlClientUpdateAccountFeatureDataMutationVariables>;
export type GqlClientUpdateAccountFeatureDataProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateAccountFeatureDataMutation, GqlClientUpdateAccountFeatureDataMutationVariables>;
} & TChildProps;
export function withUpdateAccountFeatureData<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateAccountFeatureDataMutation,
        GqlClientUpdateAccountFeatureDataMutationVariables,
        GqlClientUpdateAccountFeatureDataProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateAccountFeatureDataMutation,
        GqlClientUpdateAccountFeatureDataMutationVariables,
        GqlClientUpdateAccountFeatureDataProps<TChildProps, TDataName>
    >(UpdateAccountFeatureDataDocument, {
        alias: "updateAccountFeatureData",
        ...operationOptions
    });
}

/**
 * __useUpdateAccountFeatureDataMutation__
 *
 * To run a mutation, you first call `useUpdateAccountFeatureDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountFeatureDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountFeatureDataMutation, { data, loading, error }] = useUpdateAccountFeatureDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountFeatureDataMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateAccountFeatureDataMutation, GqlClientUpdateAccountFeatureDataMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateAccountFeatureDataMutation, GqlClientUpdateAccountFeatureDataMutationVariables>(UpdateAccountFeatureDataDocument, options);
}
export type UpdateAccountFeatureDataMutationHookResult = ReturnType<typeof useUpdateAccountFeatureDataMutation>;
export type UpdateAccountFeatureDataMutationResult = Apollo.MutationResult<GqlClientUpdateAccountFeatureDataMutation>;
export type UpdateAccountFeatureDataMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateAccountFeatureDataMutation, GqlClientUpdateAccountFeatureDataMutationVariables>;
export const AccountFeatureDataForMgmtDocument = gql`
    query accountFeatureDataForMgmt($accountId: String!) {
        accountFeatureDataForMgmt(accountId: $accountId) {
            id
            features {
                ...AccountFeatureData
            }
        }
    }
    ${AccountFeatureDataFragmentDoc}
`;
export type GqlClientAccountFeatureDataForMgmtProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountFeatureDataForMgmtQuery, GqlClientAccountFeatureDataForMgmtQueryVariables>;
} & TChildProps;
export function withAccountFeatureDataForMgmt<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountFeatureDataForMgmtQuery,
        GqlClientAccountFeatureDataForMgmtQueryVariables,
        GqlClientAccountFeatureDataForMgmtProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientAccountFeatureDataForMgmtQuery, GqlClientAccountFeatureDataForMgmtQueryVariables, GqlClientAccountFeatureDataForMgmtProps<TChildProps, TDataName>>(
        AccountFeatureDataForMgmtDocument,
        {
            alias: "accountFeatureDataForMgmt",
            ...operationOptions
        }
    );
}

/**
 * __useAccountFeatureDataForMgmtQuery__
 *
 * To run a query within a React component, call `useAccountFeatureDataForMgmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountFeatureDataForMgmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountFeatureDataForMgmtQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountFeatureDataForMgmtQuery(baseOptions: Apollo.QueryHookOptions<GqlClientAccountFeatureDataForMgmtQuery, GqlClientAccountFeatureDataForMgmtQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAccountFeatureDataForMgmtQuery, GqlClientAccountFeatureDataForMgmtQueryVariables>(AccountFeatureDataForMgmtDocument, options);
}
export function useAccountFeatureDataForMgmtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAccountFeatureDataForMgmtQuery, GqlClientAccountFeatureDataForMgmtQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAccountFeatureDataForMgmtQuery, GqlClientAccountFeatureDataForMgmtQueryVariables>(AccountFeatureDataForMgmtDocument, options);
}
export type AccountFeatureDataForMgmtQueryHookResult = ReturnType<typeof useAccountFeatureDataForMgmtQuery>;
export type AccountFeatureDataForMgmtLazyQueryHookResult = ReturnType<typeof useAccountFeatureDataForMgmtLazyQuery>;
export type AccountFeatureDataForMgmtQueryResult = Apollo.QueryResult<GqlClientAccountFeatureDataForMgmtQuery, GqlClientAccountFeatureDataForMgmtQueryVariables>;
export const AccountTierManagementAuditLogDocument = gql`
    query accountTierManagementAuditLog($accountId: String!) {
        accountTierManagementAuditLog(accountId: $accountId) {
            id
            actionType
            actionSource
            entityType
            fieldValueBefore
            fieldValueAfter
            tierName
            featureName
            created
            createdBy
        }
    }
`;
export type GqlClientAccountTierManagementAuditLogProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountTierManagementAuditLogQuery, GqlClientAccountTierManagementAuditLogQueryVariables>;
} & TChildProps;
export function withAccountTierManagementAuditLog<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountTierManagementAuditLogQuery,
        GqlClientAccountTierManagementAuditLogQueryVariables,
        GqlClientAccountTierManagementAuditLogProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientAccountTierManagementAuditLogQuery,
        GqlClientAccountTierManagementAuditLogQueryVariables,
        GqlClientAccountTierManagementAuditLogProps<TChildProps, TDataName>
    >(AccountTierManagementAuditLogDocument, {
        alias: "accountTierManagementAuditLog",
        ...operationOptions
    });
}

/**
 * __useAccountTierManagementAuditLogQuery__
 *
 * To run a query within a React component, call `useAccountTierManagementAuditLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTierManagementAuditLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTierManagementAuditLogQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountTierManagementAuditLogQuery(baseOptions: Apollo.QueryHookOptions<GqlClientAccountTierManagementAuditLogQuery, GqlClientAccountTierManagementAuditLogQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAccountTierManagementAuditLogQuery, GqlClientAccountTierManagementAuditLogQueryVariables>(AccountTierManagementAuditLogDocument, options);
}
export function useAccountTierManagementAuditLogLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAccountTierManagementAuditLogQuery, GqlClientAccountTierManagementAuditLogQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAccountTierManagementAuditLogQuery, GqlClientAccountTierManagementAuditLogQueryVariables>(AccountTierManagementAuditLogDocument, options);
}
export type AccountTierManagementAuditLogQueryHookResult = ReturnType<typeof useAccountTierManagementAuditLogQuery>;
export type AccountTierManagementAuditLogLazyQueryHookResult = ReturnType<typeof useAccountTierManagementAuditLogLazyQuery>;
export type AccountTierManagementAuditLogQueryResult = Apollo.QueryResult<GqlClientAccountTierManagementAuditLogQuery, GqlClientAccountTierManagementAuditLogQueryVariables>;
export const TierAccountDefinitionsDocument = gql`
    query tierAccountDefinitions {
        tierAccountDefinitions {
            id
            accountId
            accountDisplayName
            tierDefinition {
                id
                displayName
            }
        }
    }
`;
export type GqlClientTierAccountDefinitionsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientTierAccountDefinitionsQuery, GqlClientTierAccountDefinitionsQueryVariables>;
} & TChildProps;
export function withTierAccountDefinitions<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientTierAccountDefinitionsQuery,
        GqlClientTierAccountDefinitionsQueryVariables,
        GqlClientTierAccountDefinitionsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientTierAccountDefinitionsQuery, GqlClientTierAccountDefinitionsQueryVariables, GqlClientTierAccountDefinitionsProps<TChildProps, TDataName>>(
        TierAccountDefinitionsDocument,
        {
            alias: "tierAccountDefinitions",
            ...operationOptions
        }
    );
}

/**
 * __useTierAccountDefinitionsQuery__
 *
 * To run a query within a React component, call `useTierAccountDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTierAccountDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTierAccountDefinitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTierAccountDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientTierAccountDefinitionsQuery, GqlClientTierAccountDefinitionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientTierAccountDefinitionsQuery, GqlClientTierAccountDefinitionsQueryVariables>(TierAccountDefinitionsDocument, options);
}
export function useTierAccountDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientTierAccountDefinitionsQuery, GqlClientTierAccountDefinitionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientTierAccountDefinitionsQuery, GqlClientTierAccountDefinitionsQueryVariables>(TierAccountDefinitionsDocument, options);
}
export type TierAccountDefinitionsQueryHookResult = ReturnType<typeof useTierAccountDefinitionsQuery>;
export type TierAccountDefinitionsLazyQueryHookResult = ReturnType<typeof useTierAccountDefinitionsLazyQuery>;
export type TierAccountDefinitionsQueryResult = Apollo.QueryResult<GqlClientTierAccountDefinitionsQuery, GqlClientTierAccountDefinitionsQueryVariables>;
export const TierFeatureDefinitionsDocument = gql`
    query tierFeatureDefinitions($tierId: ID!) {
        tierFeatureDefinitions(tierId: $tierId) {
            ...TierFeatureDefinition
        }
    }
    ${TierFeatureDefinitionFragmentDoc}
`;
export type GqlClientTierFeatureDefinitionsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientTierFeatureDefinitionsQuery, GqlClientTierFeatureDefinitionsQueryVariables>;
} & TChildProps;
export function withTierFeatureDefinitions<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientTierFeatureDefinitionsQuery,
        GqlClientTierFeatureDefinitionsQueryVariables,
        GqlClientTierFeatureDefinitionsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientTierFeatureDefinitionsQuery, GqlClientTierFeatureDefinitionsQueryVariables, GqlClientTierFeatureDefinitionsProps<TChildProps, TDataName>>(
        TierFeatureDefinitionsDocument,
        {
            alias: "tierFeatureDefinitions",
            ...operationOptions
        }
    );
}

/**
 * __useTierFeatureDefinitionsQuery__
 *
 * To run a query within a React component, call `useTierFeatureDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTierFeatureDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTierFeatureDefinitionsQuery({
 *   variables: {
 *      tierId: // value for 'tierId'
 *   },
 * });
 */
export function useTierFeatureDefinitionsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientTierFeatureDefinitionsQuery, GqlClientTierFeatureDefinitionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientTierFeatureDefinitionsQuery, GqlClientTierFeatureDefinitionsQueryVariables>(TierFeatureDefinitionsDocument, options);
}
export function useTierFeatureDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientTierFeatureDefinitionsQuery, GqlClientTierFeatureDefinitionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientTierFeatureDefinitionsQuery, GqlClientTierFeatureDefinitionsQueryVariables>(TierFeatureDefinitionsDocument, options);
}
export type TierFeatureDefinitionsQueryHookResult = ReturnType<typeof useTierFeatureDefinitionsQuery>;
export type TierFeatureDefinitionsLazyQueryHookResult = ReturnType<typeof useTierFeatureDefinitionsLazyQuery>;
export type TierFeatureDefinitionsQueryResult = Apollo.QueryResult<GqlClientTierFeatureDefinitionsQuery, GqlClientTierFeatureDefinitionsQueryVariables>;
export const CreateAdsAudienceDocument = gql`
    mutation createAdsAudience($data: CreateAdsAudienceInput!) {
        createAdsAudience(input: $data) {
            id
        }
    }
`;
export type GqlClientCreateAdsAudienceMutationFn = Apollo.MutationFunction<GqlClientCreateAdsAudienceMutation, GqlClientCreateAdsAudienceMutationVariables>;
export type GqlClientCreateAdsAudienceProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateAdsAudienceMutation, GqlClientCreateAdsAudienceMutationVariables>;
} & TChildProps;
export function withCreateAdsAudience<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateAdsAudienceMutation, GqlClientCreateAdsAudienceMutationVariables, GqlClientCreateAdsAudienceProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateAdsAudienceMutation, GqlClientCreateAdsAudienceMutationVariables, GqlClientCreateAdsAudienceProps<TChildProps, TDataName>>(
        CreateAdsAudienceDocument,
        {
            alias: "createAdsAudience",
            ...operationOptions
        }
    );
}

/**
 * __useCreateAdsAudienceMutation__
 *
 * To run a mutation, you first call `useCreateAdsAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdsAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdsAudienceMutation, { data, loading, error }] = useCreateAdsAudienceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAdsAudienceMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateAdsAudienceMutation, GqlClientCreateAdsAudienceMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateAdsAudienceMutation, GqlClientCreateAdsAudienceMutationVariables>(CreateAdsAudienceDocument, options);
}
export type CreateAdsAudienceMutationHookResult = ReturnType<typeof useCreateAdsAudienceMutation>;
export type CreateAdsAudienceMutationResult = Apollo.MutationResult<GqlClientCreateAdsAudienceMutation>;
export type CreateAdsAudienceMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateAdsAudienceMutation, GqlClientCreateAdsAudienceMutationVariables>;
export const GetAudienceDocument = gql`
    query getAudience($id: ID!) {
        adsAudience(id: $id) {
            id
            name
            enabled
            priorityIndex
            superGroups {
                id
                matchType
                ruleType
                ruleGroups {
                    id
                    minAgeDays
                    maxAgeDays
                    matchType
                    rules {
                        id
                        name
                        value
                        operator
                    }
                }
            }
        }
    }
`;
export type GqlClientGetAudienceProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAudienceQuery, GqlClientGetAudienceQueryVariables>;
} & TChildProps;
export function withGetAudience<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetAudienceQuery, GqlClientGetAudienceQueryVariables, GqlClientGetAudienceProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetAudienceQuery, GqlClientGetAudienceQueryVariables, GqlClientGetAudienceProps<TChildProps, TDataName>>(GetAudienceDocument, {
        alias: "getAudience",
        ...operationOptions
    });
}

/**
 * __useGetAudienceQuery__
 *
 * To run a query within a React component, call `useGetAudienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAudienceQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetAudienceQuery, GqlClientGetAudienceQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAudienceQuery, GqlClientGetAudienceQueryVariables>(GetAudienceDocument, options);
}
export function useGetAudienceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAudienceQuery, GqlClientGetAudienceQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAudienceQuery, GqlClientGetAudienceQueryVariables>(GetAudienceDocument, options);
}
export type GetAudienceQueryHookResult = ReturnType<typeof useGetAudienceQuery>;
export type GetAudienceLazyQueryHookResult = ReturnType<typeof useGetAudienceLazyQuery>;
export type GetAudienceQueryResult = Apollo.QueryResult<GqlClientGetAudienceQuery, GqlClientGetAudienceQueryVariables>;
export const UpdateAdsAudienceDocument = gql`
    mutation updateAdsAudience($data: UpdateAdsAudienceInput!) {
        updateAdsAudience(input: $data) {
            id
        }
    }
`;
export type GqlClientUpdateAdsAudienceMutationFn = Apollo.MutationFunction<GqlClientUpdateAdsAudienceMutation, GqlClientUpdateAdsAudienceMutationVariables>;
export type GqlClientUpdateAdsAudienceProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateAdsAudienceMutation, GqlClientUpdateAdsAudienceMutationVariables>;
} & TChildProps;
export function withUpdateAdsAudience<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateAdsAudienceMutation, GqlClientUpdateAdsAudienceMutationVariables, GqlClientUpdateAdsAudienceProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateAdsAudienceMutation, GqlClientUpdateAdsAudienceMutationVariables, GqlClientUpdateAdsAudienceProps<TChildProps, TDataName>>(
        UpdateAdsAudienceDocument,
        {
            alias: "updateAdsAudience",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateAdsAudienceMutation__
 *
 * To run a mutation, you first call `useUpdateAdsAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdsAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdsAudienceMutation, { data, loading, error }] = useUpdateAdsAudienceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAdsAudienceMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateAdsAudienceMutation, GqlClientUpdateAdsAudienceMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateAdsAudienceMutation, GqlClientUpdateAdsAudienceMutationVariables>(UpdateAdsAudienceDocument, options);
}
export type UpdateAdsAudienceMutationHookResult = ReturnType<typeof useUpdateAdsAudienceMutation>;
export type UpdateAdsAudienceMutationResult = Apollo.MutationResult<GqlClientUpdateAdsAudienceMutation>;
export type UpdateAdsAudienceMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateAdsAudienceMutation, GqlClientUpdateAdsAudienceMutationVariables>;
export const CreateCampaignSettingsDocument = gql`
    mutation createCampaignSettings($data: CreateCampaignSettingsInput!, $programSfId: String) {
        createCampaignSettings(input: $data, programSfId: $programSfId) {
            id
        }
    }
`;
export type GqlClientCreateCampaignSettingsMutationFn = Apollo.MutationFunction<GqlClientCreateCampaignSettingsMutation, GqlClientCreateCampaignSettingsMutationVariables>;
export type GqlClientCreateCampaignSettingsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateCampaignSettingsMutation, GqlClientCreateCampaignSettingsMutationVariables>;
} & TChildProps;
export function withCreateCampaignSettings<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateCampaignSettingsMutation,
        GqlClientCreateCampaignSettingsMutationVariables,
        GqlClientCreateCampaignSettingsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateCampaignSettingsMutation, GqlClientCreateCampaignSettingsMutationVariables, GqlClientCreateCampaignSettingsProps<TChildProps, TDataName>>(
        CreateCampaignSettingsDocument,
        {
            alias: "createCampaignSettings",
            ...operationOptions
        }
    );
}

/**
 * __useCreateCampaignSettingsMutation__
 *
 * To run a mutation, you first call `useCreateCampaignSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignSettingsMutation, { data, loading, error }] = useCreateCampaignSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      programSfId: // value for 'programSfId'
 *   },
 * });
 */
export function useCreateCampaignSettingsMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateCampaignSettingsMutation, GqlClientCreateCampaignSettingsMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateCampaignSettingsMutation, GqlClientCreateCampaignSettingsMutationVariables>(CreateCampaignSettingsDocument, options);
}
export type CreateCampaignSettingsMutationHookResult = ReturnType<typeof useCreateCampaignSettingsMutation>;
export type CreateCampaignSettingsMutationResult = Apollo.MutationResult<GqlClientCreateCampaignSettingsMutation>;
export type CreateCampaignSettingsMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateCampaignSettingsMutation, GqlClientCreateCampaignSettingsMutationVariables>;
export const DownloadAdsFileDocument = gql`
    query downloadAdsFile($location: String!) {
        downloadAdsFile(location: $location) {
            buffer
            fileName
            contentType
            returnCode
        }
    }
`;
export type GqlClientDownloadAdsFileProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientDownloadAdsFileQuery, GqlClientDownloadAdsFileQueryVariables>;
} & TChildProps;
export function withDownloadAdsFile<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDownloadAdsFileQuery, GqlClientDownloadAdsFileQueryVariables, GqlClientDownloadAdsFileProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientDownloadAdsFileQuery, GqlClientDownloadAdsFileQueryVariables, GqlClientDownloadAdsFileProps<TChildProps, TDataName>>(DownloadAdsFileDocument, {
        alias: "downloadAdsFile",
        ...operationOptions
    });
}

/**
 * __useDownloadAdsFileQuery__
 *
 * To run a query within a React component, call `useDownloadAdsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadAdsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadAdsFileQuery({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useDownloadAdsFileQuery(baseOptions: Apollo.QueryHookOptions<GqlClientDownloadAdsFileQuery, GqlClientDownloadAdsFileQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientDownloadAdsFileQuery, GqlClientDownloadAdsFileQueryVariables>(DownloadAdsFileDocument, options);
}
export function useDownloadAdsFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientDownloadAdsFileQuery, GqlClientDownloadAdsFileQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientDownloadAdsFileQuery, GqlClientDownloadAdsFileQueryVariables>(DownloadAdsFileDocument, options);
}
export type DownloadAdsFileQueryHookResult = ReturnType<typeof useDownloadAdsFileQuery>;
export type DownloadAdsFileLazyQueryHookResult = ReturnType<typeof useDownloadAdsFileLazyQuery>;
export type DownloadAdsFileQueryResult = Apollo.QueryResult<GqlClientDownloadAdsFileQuery, GqlClientDownloadAdsFileQueryVariables>;
export const DuplicateCampaignSettingsDocument = gql`
    mutation duplicateCampaignSettings($data: DuplicateCampaignSettingsInput!) {
        duplicateCampaignSettings(input: $data) {
            id
        }
    }
`;
export type GqlClientDuplicateCampaignSettingsMutationFn = Apollo.MutationFunction<GqlClientDuplicateCampaignSettingsMutation, GqlClientDuplicateCampaignSettingsMutationVariables>;
export type GqlClientDuplicateCampaignSettingsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDuplicateCampaignSettingsMutation, GqlClientDuplicateCampaignSettingsMutationVariables>;
} & TChildProps;
export function withDuplicateCampaignSettings<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDuplicateCampaignSettingsMutation,
        GqlClientDuplicateCampaignSettingsMutationVariables,
        GqlClientDuplicateCampaignSettingsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDuplicateCampaignSettingsMutation,
        GqlClientDuplicateCampaignSettingsMutationVariables,
        GqlClientDuplicateCampaignSettingsProps<TChildProps, TDataName>
    >(DuplicateCampaignSettingsDocument, {
        alias: "duplicateCampaignSettings",
        ...operationOptions
    });
}

/**
 * __useDuplicateCampaignSettingsMutation__
 *
 * To run a mutation, you first call `useDuplicateCampaignSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCampaignSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCampaignSettingsMutation, { data, loading, error }] = useDuplicateCampaignSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDuplicateCampaignSettingsMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDuplicateCampaignSettingsMutation, GqlClientDuplicateCampaignSettingsMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDuplicateCampaignSettingsMutation, GqlClientDuplicateCampaignSettingsMutationVariables>(DuplicateCampaignSettingsDocument, options);
}
export type DuplicateCampaignSettingsMutationHookResult = ReturnType<typeof useDuplicateCampaignSettingsMutation>;
export type DuplicateCampaignSettingsMutationResult = Apollo.MutationResult<GqlClientDuplicateCampaignSettingsMutation>;
export type DuplicateCampaignSettingsMutationOptions = Apollo.BaseMutationOptions<GqlClientDuplicateCampaignSettingsMutation, GqlClientDuplicateCampaignSettingsMutationVariables>;
export const GetAudienceDescriptorsDocument = gql`
    query getAudienceDescriptors($id: ID!) {
        campaignSettings(id: $id) {
            id
            audienceDescriptors {
                id
                name
                enabled
                priorityIndex
            }
        }
    }
`;
export type GqlClientGetAudienceDescriptorsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAudienceDescriptorsQuery, GqlClientGetAudienceDescriptorsQueryVariables>;
} & TChildProps;
export function withGetAudienceDescriptors<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetAudienceDescriptorsQuery,
        GqlClientGetAudienceDescriptorsQueryVariables,
        GqlClientGetAudienceDescriptorsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetAudienceDescriptorsQuery, GqlClientGetAudienceDescriptorsQueryVariables, GqlClientGetAudienceDescriptorsProps<TChildProps, TDataName>>(
        GetAudienceDescriptorsDocument,
        {
            alias: "getAudienceDescriptors",
            ...operationOptions
        }
    );
}

/**
 * __useGetAudienceDescriptorsQuery__
 *
 * To run a query within a React component, call `useGetAudienceDescriptorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceDescriptorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceDescriptorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAudienceDescriptorsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetAudienceDescriptorsQuery, GqlClientGetAudienceDescriptorsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAudienceDescriptorsQuery, GqlClientGetAudienceDescriptorsQueryVariables>(GetAudienceDescriptorsDocument, options);
}
export function useGetAudienceDescriptorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAudienceDescriptorsQuery, GqlClientGetAudienceDescriptorsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAudienceDescriptorsQuery, GqlClientGetAudienceDescriptorsQueryVariables>(GetAudienceDescriptorsDocument, options);
}
export type GetAudienceDescriptorsQueryHookResult = ReturnType<typeof useGetAudienceDescriptorsQuery>;
export type GetAudienceDescriptorsLazyQueryHookResult = ReturnType<typeof useGetAudienceDescriptorsLazyQuery>;
export type GetAudienceDescriptorsQueryResult = Apollo.QueryResult<GqlClientGetAudienceDescriptorsQuery, GqlClientGetAudienceDescriptorsQueryVariables>;
export const GetCampaignSettingsDocument = gql`
    query getCampaignSettings($id: ID!) {
        campaignSettings(id: $id) {
            id
            accountId
            campaignName
            advertiserWebsite
            advertiserCreativeId
            enabled
            isCPCV
            schedulesAndTargets {
                id
                impressions
                CPM
                completedViews
                CPCV
                margin
                startDate
                endDate
                insertionOrder
            }
            hourlyFrequencyCap
            dailyFrequencyCap
            weeklyFrequencyCap
            monthlyFrequencyCap
            locationFilters
            locationFile
            allowThirdPartySegmentsInOptimization
            audienceDescriptors {
                id
                name
                enabled
                priorityIndex
            }
            desktopPlayerSizes
            mobilePlayerSizes
            useCrossDevice
            deviceMode
            allowDesktop
            allowMobileApp
            allowMobileWeb
            desktopMinBudget
            mobileMinBudget
            URLFilter
            keywordsFilter
            appNameFilter
            iabCategoriesFilter
            viewabilityGoal
            minimumViewability
            exchangeBlacklist
            brandSafetyCustomSegmentsLocation
            brandSafetyMode
            fraudMode
            verificationAuthority
            viewabilityMode
            restrictedContentAlcohol
            productCatalogName
            productCatalogSkuTemplate
            controlGroupPercent
            controlGroupMode
            productFilters {
                id
                type
                operator
                value
                attName
            }
            productSelectors {
                id
                type
                extraData
                audienceId
            }
            creativeDefinitions {
                id
                storyName
                numSKU
                videoParams
                supportedRatios
                videoQualityProfile
                allowedDurations
            }
            reportingDefinitions {
                id
                attributeType
                targetField
                tagName
                fieldValue
            }
            pixelRulesLocation
            allowPurchased
            rulesNewMode
        }
    }
`;
export type GqlClientGetCampaignSettingsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetCampaignSettingsQuery, GqlClientGetCampaignSettingsQueryVariables>;
} & TChildProps;
export function withGetCampaignSettings<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetCampaignSettingsQuery, GqlClientGetCampaignSettingsQueryVariables, GqlClientGetCampaignSettingsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetCampaignSettingsQuery, GqlClientGetCampaignSettingsQueryVariables, GqlClientGetCampaignSettingsProps<TChildProps, TDataName>>(
        GetCampaignSettingsDocument,
        {
            alias: "getCampaignSettings",
            ...operationOptions
        }
    );
}

/**
 * __useGetCampaignSettingsQuery__
 *
 * To run a query within a React component, call `useGetCampaignSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCampaignSettingsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetCampaignSettingsQuery, GqlClientGetCampaignSettingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetCampaignSettingsQuery, GqlClientGetCampaignSettingsQueryVariables>(GetCampaignSettingsDocument, options);
}
export function useGetCampaignSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetCampaignSettingsQuery, GqlClientGetCampaignSettingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetCampaignSettingsQuery, GqlClientGetCampaignSettingsQueryVariables>(GetCampaignSettingsDocument, options);
}
export type GetCampaignSettingsQueryHookResult = ReturnType<typeof useGetCampaignSettingsQuery>;
export type GetCampaignSettingsLazyQueryHookResult = ReturnType<typeof useGetCampaignSettingsLazyQuery>;
export type GetCampaignSettingsQueryResult = Apollo.QueryResult<GqlClientGetCampaignSettingsQuery, GqlClientGetCampaignSettingsQueryVariables>;
export const MoveCampaignSettingsDocument = gql`
    mutation moveCampaignSettings($data: MoveCampaignSettingsInput!) {
        moveCampaignSettings(input: $data) {
            id
        }
    }
`;
export type GqlClientMoveCampaignSettingsMutationFn = Apollo.MutationFunction<GqlClientMoveCampaignSettingsMutation, GqlClientMoveCampaignSettingsMutationVariables>;
export type GqlClientMoveCampaignSettingsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientMoveCampaignSettingsMutation, GqlClientMoveCampaignSettingsMutationVariables>;
} & TChildProps;
export function withMoveCampaignSettings<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientMoveCampaignSettingsMutation,
        GqlClientMoveCampaignSettingsMutationVariables,
        GqlClientMoveCampaignSettingsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientMoveCampaignSettingsMutation, GqlClientMoveCampaignSettingsMutationVariables, GqlClientMoveCampaignSettingsProps<TChildProps, TDataName>>(
        MoveCampaignSettingsDocument,
        {
            alias: "moveCampaignSettings",
            ...operationOptions
        }
    );
}

/**
 * __useMoveCampaignSettingsMutation__
 *
 * To run a mutation, you first call `useMoveCampaignSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveCampaignSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveCampaignSettingsMutation, { data, loading, error }] = useMoveCampaignSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMoveCampaignSettingsMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientMoveCampaignSettingsMutation, GqlClientMoveCampaignSettingsMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientMoveCampaignSettingsMutation, GqlClientMoveCampaignSettingsMutationVariables>(MoveCampaignSettingsDocument, options);
}
export type MoveCampaignSettingsMutationHookResult = ReturnType<typeof useMoveCampaignSettingsMutation>;
export type MoveCampaignSettingsMutationResult = Apollo.MutationResult<GqlClientMoveCampaignSettingsMutation>;
export type MoveCampaignSettingsMutationOptions = Apollo.BaseMutationOptions<GqlClientMoveCampaignSettingsMutation, GqlClientMoveCampaignSettingsMutationVariables>;
export const UpdateCampaignSettingsDocument = gql`
    mutation updateCampaignSettings($data: UpdateCampaignSettingsInput!) {
        updateCampaignSettings(input: $data) {
            id
            accountId
            campaignName
            advertiserWebsite
            advertiserCreativeId
            enabled
            isCPCV
            schedulesAndTargets {
                id
                impressions
                CPM
                completedViews
                CPCV
                margin
                startDate
                endDate
                insertionOrder
            }
            hourlyFrequencyCap
            dailyFrequencyCap
            weeklyFrequencyCap
            monthlyFrequencyCap
            locationFilters
            locationFile
            allowThirdPartySegmentsInOptimization
            audienceDescriptors {
                id
                name
                enabled
                priorityIndex
            }
            desktopPlayerSizes
            mobilePlayerSizes
            useCrossDevice
            deviceMode
            allowDesktop
            allowMobileApp
            allowMobileWeb
            desktopMinBudget
            mobileMinBudget
            URLFilter
            keywordsFilter
            appNameFilter
            iabCategoriesFilter
            viewabilityGoal
            minimumViewability
            exchangeBlacklist
            brandSafetyCustomSegmentsLocation
            brandSafetyMode
            fraudMode
            verificationAuthority
            viewabilityMode
            restrictedContentAlcohol
            productCatalogName
            productCatalogSkuTemplate
            controlGroupPercent
            controlGroupMode
            productFilters {
                id
                type
                operator
                value
                attName
            }
            productSelectors {
                id
                type
                extraData
                audienceId
            }
            creativeDefinitions {
                id
                storyName
                numSKU
                videoParams
                supportedRatios
                videoQualityProfile
                allowedDurations
            }
            reportingDefinitions {
                id
                attributeType
                targetField
                tagName
                fieldValue
            }
            pixelRulesLocation
            allowPurchased
            rulesNewMode
        }
    }
`;
export type GqlClientUpdateCampaignSettingsMutationFn = Apollo.MutationFunction<GqlClientUpdateCampaignSettingsMutation, GqlClientUpdateCampaignSettingsMutationVariables>;
export type GqlClientUpdateCampaignSettingsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCampaignSettingsMutation, GqlClientUpdateCampaignSettingsMutationVariables>;
} & TChildProps;
export function withUpdateCampaignSettings<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateCampaignSettingsMutation,
        GqlClientUpdateCampaignSettingsMutationVariables,
        GqlClientUpdateCampaignSettingsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateCampaignSettingsMutation, GqlClientUpdateCampaignSettingsMutationVariables, GqlClientUpdateCampaignSettingsProps<TChildProps, TDataName>>(
        UpdateCampaignSettingsDocument,
        {
            alias: "updateCampaignSettings",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateCampaignSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignSettingsMutation, { data, loading, error }] = useUpdateCampaignSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCampaignSettingsMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCampaignSettingsMutation, GqlClientUpdateCampaignSettingsMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCampaignSettingsMutation, GqlClientUpdateCampaignSettingsMutationVariables>(UpdateCampaignSettingsDocument, options);
}
export type UpdateCampaignSettingsMutationHookResult = ReturnType<typeof useUpdateCampaignSettingsMutation>;
export type UpdateCampaignSettingsMutationResult = Apollo.MutationResult<GqlClientUpdateCampaignSettingsMutation>;
export type UpdateCampaignSettingsMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateCampaignSettingsMutation, GqlClientUpdateCampaignSettingsMutationVariables>;
export const UpdateCampaignStatusDocument = gql`
    mutation updateCampaignStatus($data: UpdateCampaignStatusInput!) {
        updateCampaignStatus(input: $data) {
            id
            enabled
        }
    }
`;
export type GqlClientUpdateCampaignStatusMutationFn = Apollo.MutationFunction<GqlClientUpdateCampaignStatusMutation, GqlClientUpdateCampaignStatusMutationVariables>;
export type GqlClientUpdateCampaignStatusProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCampaignStatusMutation, GqlClientUpdateCampaignStatusMutationVariables>;
} & TChildProps;
export function withUpdateCampaignStatus<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateCampaignStatusMutation,
        GqlClientUpdateCampaignStatusMutationVariables,
        GqlClientUpdateCampaignStatusProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateCampaignStatusMutation, GqlClientUpdateCampaignStatusMutationVariables, GqlClientUpdateCampaignStatusProps<TChildProps, TDataName>>(
        UpdateCampaignStatusDocument,
        {
            alias: "updateCampaignStatus",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateCampaignStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignStatusMutation, { data, loading, error }] = useUpdateCampaignStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCampaignStatusMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCampaignStatusMutation, GqlClientUpdateCampaignStatusMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCampaignStatusMutation, GqlClientUpdateCampaignStatusMutationVariables>(UpdateCampaignStatusDocument, options);
}
export type UpdateCampaignStatusMutationHookResult = ReturnType<typeof useUpdateCampaignStatusMutation>;
export type UpdateCampaignStatusMutationResult = Apollo.MutationResult<GqlClientUpdateCampaignStatusMutation>;
export type UpdateCampaignStatusMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateCampaignStatusMutation, GqlClientUpdateCampaignStatusMutationVariables>;
export const UploadAdsFileDocument = gql`
    mutation uploadAdsFile($input: UploadAdsFileInput!) {
        uploadAdsFile(input: $input) {
            id
            returnCode
            location
        }
    }
`;
export type GqlClientUploadAdsFileMutationFn = Apollo.MutationFunction<GqlClientUploadAdsFileMutation, GqlClientUploadAdsFileMutationVariables>;
export type GqlClientUploadAdsFileProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUploadAdsFileMutation, GqlClientUploadAdsFileMutationVariables>;
} & TChildProps;
export function withUploadAdsFile<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUploadAdsFileMutation, GqlClientUploadAdsFileMutationVariables, GqlClientUploadAdsFileProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUploadAdsFileMutation, GqlClientUploadAdsFileMutationVariables, GqlClientUploadAdsFileProps<TChildProps, TDataName>>(UploadAdsFileDocument, {
        alias: "uploadAdsFile",
        ...operationOptions
    });
}

/**
 * __useUploadAdsFileMutation__
 *
 * To run a mutation, you first call `useUploadAdsFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAdsFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAdsFileMutation, { data, loading, error }] = useUploadAdsFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadAdsFileMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUploadAdsFileMutation, GqlClientUploadAdsFileMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUploadAdsFileMutation, GqlClientUploadAdsFileMutationVariables>(UploadAdsFileDocument, options);
}
export type UploadAdsFileMutationHookResult = ReturnType<typeof useUploadAdsFileMutation>;
export type UploadAdsFileMutationResult = Apollo.MutationResult<GqlClientUploadAdsFileMutation>;
export type UploadAdsFileMutationOptions = Apollo.BaseMutationOptions<GqlClientUploadAdsFileMutation, GqlClientUploadAdsFileMutationVariables>;
export const GetCampaignsAndSfBudgetDocument = gql`
    query getCampaignsAndSfBudget($programSfId: String!) {
        campaignsAndSfBudget(programSfId: $programSfId) {
            campaigns {
                campaignName
                salesforceId
                id
                accountId
                enabled
                isCPCV
                schedulesAndTargets {
                    id
                    impressions
                    CPM
                    completedViews
                    CPCV
                    insertionOrder
                    startDate
                    endDate
                }
                viewabilityGoal
                pixelRulesLocation
            }
            totalSFBudget
            message
            returnCode
        }
    }
`;
export type GqlClientGetCampaignsAndSfBudgetProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetCampaignsAndSfBudgetQuery, GqlClientGetCampaignsAndSfBudgetQueryVariables>;
} & TChildProps;
export function withGetCampaignsAndSfBudget<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetCampaignsAndSfBudgetQuery,
        GqlClientGetCampaignsAndSfBudgetQueryVariables,
        GqlClientGetCampaignsAndSfBudgetProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetCampaignsAndSfBudgetQuery, GqlClientGetCampaignsAndSfBudgetQueryVariables, GqlClientGetCampaignsAndSfBudgetProps<TChildProps, TDataName>>(
        GetCampaignsAndSfBudgetDocument,
        {
            alias: "getCampaignsAndSfBudget",
            ...operationOptions
        }
    );
}

/**
 * __useGetCampaignsAndSfBudgetQuery__
 *
 * To run a query within a React component, call `useGetCampaignsAndSfBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsAndSfBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsAndSfBudgetQuery({
 *   variables: {
 *      programSfId: // value for 'programSfId'
 *   },
 * });
 */
export function useGetCampaignsAndSfBudgetQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetCampaignsAndSfBudgetQuery, GqlClientGetCampaignsAndSfBudgetQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetCampaignsAndSfBudgetQuery, GqlClientGetCampaignsAndSfBudgetQueryVariables>(GetCampaignsAndSfBudgetDocument, options);
}
export function useGetCampaignsAndSfBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetCampaignsAndSfBudgetQuery, GqlClientGetCampaignsAndSfBudgetQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetCampaignsAndSfBudgetQuery, GqlClientGetCampaignsAndSfBudgetQueryVariables>(GetCampaignsAndSfBudgetDocument, options);
}
export type GetCampaignsAndSfBudgetQueryHookResult = ReturnType<typeof useGetCampaignsAndSfBudgetQuery>;
export type GetCampaignsAndSfBudgetLazyQueryHookResult = ReturnType<typeof useGetCampaignsAndSfBudgetLazyQuery>;
export type GetCampaignsAndSfBudgetQueryResult = Apollo.QueryResult<GqlClientGetCampaignsAndSfBudgetQuery, GqlClientGetCampaignsAndSfBudgetQueryVariables>;
export const SetCcActiveSnapshotDocument = gql`
    mutation setCcActiveSnapshot($setCcActiveSnapshotInput: SetCcActiveSnapshotInput!) {
        setCcActiveSnapshot(input: $setCcActiveSnapshotInput) {
            result
            ... on SetCcActiveSnapshotOutputSuccessful {
                activeSnapshot {
                    id
                    library {
                        id
                        libraryVersionActive {
                            id
                        }
                    }
                }
            }
            ... on SetCcActiveSnapshotOutputMigrationFailed {
                errors
            }
        }
    }
`;
export type GqlClientSetCcActiveSnapshotMutationFn = Apollo.MutationFunction<GqlClientSetCcActiveSnapshotMutation, GqlClientSetCcActiveSnapshotMutationVariables>;
export type GqlClientSetCcActiveSnapshotProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientSetCcActiveSnapshotMutation, GqlClientSetCcActiveSnapshotMutationVariables>;
} & TChildProps;
export function withSetCcActiveSnapshot<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientSetCcActiveSnapshotMutation,
        GqlClientSetCcActiveSnapshotMutationVariables,
        GqlClientSetCcActiveSnapshotProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientSetCcActiveSnapshotMutation, GqlClientSetCcActiveSnapshotMutationVariables, GqlClientSetCcActiveSnapshotProps<TChildProps, TDataName>>(
        SetCcActiveSnapshotDocument,
        {
            alias: "setCcActiveSnapshot",
            ...operationOptions
        }
    );
}

/**
 * __useSetCcActiveSnapshotMutation__
 *
 * To run a mutation, you first call `useSetCcActiveSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCcActiveSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCcActiveSnapshotMutation, { data, loading, error }] = useSetCcActiveSnapshotMutation({
 *   variables: {
 *      setCcActiveSnapshotInput: // value for 'setCcActiveSnapshotInput'
 *   },
 * });
 */
export function useSetCcActiveSnapshotMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientSetCcActiveSnapshotMutation, GqlClientSetCcActiveSnapshotMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientSetCcActiveSnapshotMutation, GqlClientSetCcActiveSnapshotMutationVariables>(SetCcActiveSnapshotDocument, options);
}
export type SetCcActiveSnapshotMutationHookResult = ReturnType<typeof useSetCcActiveSnapshotMutation>;
export type SetCcActiveSnapshotMutationResult = Apollo.MutationResult<GqlClientSetCcActiveSnapshotMutation>;
export type SetCcActiveSnapshotMutationOptions = Apollo.BaseMutationOptions<GqlClientSetCcActiveSnapshotMutation, GqlClientSetCcActiveSnapshotMutationVariables>;
export const CreateCcSnapshotDocument = gql`
    mutation createCcSnapshot($createCcSnapshotInput: CreateCcSnapshotInput!) {
        createCcSnapshot(input: $createCcSnapshotInput) {
            result
            snapshot {
                id
                library {
                    id
                    libraryVersionSnapshots {
                        ...CcVersion
                    }
                    libraryVersionDraft {
                        id
                        wireframes {
                            ...CcWireframe
                        }
                    }
                }
            }
            editorUpdateErrors
        }
    }
    ${CcVersionFragmentDoc}
    ${CcWireframeFragmentDoc}
`;
export type GqlClientCreateCcSnapshotMutationFn = Apollo.MutationFunction<GqlClientCreateCcSnapshotMutation, GqlClientCreateCcSnapshotMutationVariables>;
export type GqlClientCreateCcSnapshotProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateCcSnapshotMutation, GqlClientCreateCcSnapshotMutationVariables>;
} & TChildProps;
export function withCreateCcSnapshot<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateCcSnapshotMutation, GqlClientCreateCcSnapshotMutationVariables, GqlClientCreateCcSnapshotProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateCcSnapshotMutation, GqlClientCreateCcSnapshotMutationVariables, GqlClientCreateCcSnapshotProps<TChildProps, TDataName>>(
        CreateCcSnapshotDocument,
        {
            alias: "createCcSnapshot",
            ...operationOptions
        }
    );
}

/**
 * __useCreateCcSnapshotMutation__
 *
 * To run a mutation, you first call `useCreateCcSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCcSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCcSnapshotMutation, { data, loading, error }] = useCreateCcSnapshotMutation({
 *   variables: {
 *      createCcSnapshotInput: // value for 'createCcSnapshotInput'
 *   },
 * });
 */
export function useCreateCcSnapshotMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateCcSnapshotMutation, GqlClientCreateCcSnapshotMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateCcSnapshotMutation, GqlClientCreateCcSnapshotMutationVariables>(CreateCcSnapshotDocument, options);
}
export type CreateCcSnapshotMutationHookResult = ReturnType<typeof useCreateCcSnapshotMutation>;
export type CreateCcSnapshotMutationResult = Apollo.MutationResult<GqlClientCreateCcSnapshotMutation>;
export type CreateCcSnapshotMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateCcSnapshotMutation, GqlClientCreateCcSnapshotMutationVariables>;
export const CreateCcThemeDocument = gql`
    mutation createCcTheme($createCcThemeInput: CreateCcThemeInput!) {
        createCcTheme(input: $createCcThemeInput) {
            result
            theme {
                id
                libraryVersion {
                    id
                    themes {
                        ...CcTheme
                    }
                }
            }
        }
    }
    ${CcThemeFragmentDoc}
`;
export type GqlClientCreateCcThemeMutationFn = Apollo.MutationFunction<GqlClientCreateCcThemeMutation, GqlClientCreateCcThemeMutationVariables>;
export type GqlClientCreateCcThemeProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateCcThemeMutation, GqlClientCreateCcThemeMutationVariables>;
} & TChildProps;
export function withCreateCcTheme<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateCcThemeMutation, GqlClientCreateCcThemeMutationVariables, GqlClientCreateCcThemeProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateCcThemeMutation, GqlClientCreateCcThemeMutationVariables, GqlClientCreateCcThemeProps<TChildProps, TDataName>>(CreateCcThemeDocument, {
        alias: "createCcTheme",
        ...operationOptions
    });
}

/**
 * __useCreateCcThemeMutation__
 *
 * To run a mutation, you first call `useCreateCcThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCcThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCcThemeMutation, { data, loading, error }] = useCreateCcThemeMutation({
 *   variables: {
 *      createCcThemeInput: // value for 'createCcThemeInput'
 *   },
 * });
 */
export function useCreateCcThemeMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateCcThemeMutation, GqlClientCreateCcThemeMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateCcThemeMutation, GqlClientCreateCcThemeMutationVariables>(CreateCcThemeDocument, options);
}
export type CreateCcThemeMutationHookResult = ReturnType<typeof useCreateCcThemeMutation>;
export type CreateCcThemeMutationResult = Apollo.MutationResult<GqlClientCreateCcThemeMutation>;
export type CreateCcThemeMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateCcThemeMutation, GqlClientCreateCcThemeMutationVariables>;
export const CreateCcWireframeDocument = gql`
    mutation createCcWireframe($createCcWireframeInput: CreateCcWireframeInput!) {
        createCcWireframe(input: $createCcWireframeInput) {
            result
            wireframe {
                id
                libraryVersion {
                    id
                    wireframes {
                        ...CcWireframe
                    }
                }
            }
        }
    }
    ${CcWireframeFragmentDoc}
`;
export type GqlClientCreateCcWireframeMutationFn = Apollo.MutationFunction<GqlClientCreateCcWireframeMutation, GqlClientCreateCcWireframeMutationVariables>;
export type GqlClientCreateCcWireframeProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateCcWireframeMutation, GqlClientCreateCcWireframeMutationVariables>;
} & TChildProps;
export function withCreateCcWireframe<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateCcWireframeMutation, GqlClientCreateCcWireframeMutationVariables, GqlClientCreateCcWireframeProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateCcWireframeMutation, GqlClientCreateCcWireframeMutationVariables, GqlClientCreateCcWireframeProps<TChildProps, TDataName>>(
        CreateCcWireframeDocument,
        {
            alias: "createCcWireframe",
            ...operationOptions
        }
    );
}

/**
 * __useCreateCcWireframeMutation__
 *
 * To run a mutation, you first call `useCreateCcWireframeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCcWireframeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCcWireframeMutation, { data, loading, error }] = useCreateCcWireframeMutation({
 *   variables: {
 *      createCcWireframeInput: // value for 'createCcWireframeInput'
 *   },
 * });
 */
export function useCreateCcWireframeMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateCcWireframeMutation, GqlClientCreateCcWireframeMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateCcWireframeMutation, GqlClientCreateCcWireframeMutationVariables>(CreateCcWireframeDocument, options);
}
export type CreateCcWireframeMutationHookResult = ReturnType<typeof useCreateCcWireframeMutation>;
export type CreateCcWireframeMutationResult = Apollo.MutationResult<GqlClientCreateCcWireframeMutation>;
export type CreateCcWireframeMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateCcWireframeMutation, GqlClientCreateCcWireframeMutationVariables>;
export const DeleteCcAnimatedWireframeCrossAspectRatioMappingDocument = gql`
    mutation deleteCcAnimatedWireframeCrossAspectRatioMapping($deleteCcAnimatedWireframeCrossAspectRatioMappingInput: DeleteCcAnimatedWireframeCrossAspectRatioMappingInput!) {
        deleteCcAnimatedWireframeCrossAspectRatioMapping(input: $deleteCcAnimatedWireframeCrossAspectRatioMappingInput) {
            ... on DeleteCcAnimatedWireframeCrossAspectRatioMappingOutput {
                result
                animatedWireframe {
                    ...CcAnimatedWireframeWithVersions
                }
            }
        }
    }
    ${CcAnimatedWireframeWithVersionsFragmentDoc}
`;
export type GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutationFn = Apollo.MutationFunction<
    GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation,
    GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutationVariables
>;
export type GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation, GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutationVariables>;
} & TChildProps;
export function withDeleteCcAnimatedWireframeCrossAspectRatioMapping<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation,
        GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutationVariables,
        GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation,
        GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutationVariables,
        GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingProps<TChildProps, TDataName>
    >(DeleteCcAnimatedWireframeCrossAspectRatioMappingDocument, {
        alias: "deleteCcAnimatedWireframeCrossAspectRatioMapping",
        ...operationOptions
    });
}

/**
 * __useDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation__
 *
 * To run a mutation, you first call `useDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCcAnimatedWireframeCrossAspectRatioMappingMutation, { data, loading, error }] = useDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation({
 *   variables: {
 *      deleteCcAnimatedWireframeCrossAspectRatioMappingInput: // value for 'deleteCcAnimatedWireframeCrossAspectRatioMappingInput'
 *   },
 * });
 */
export function useDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation, GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation, GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutationVariables>(
        DeleteCcAnimatedWireframeCrossAspectRatioMappingDocument,
        options
    );
}
export type DeleteCcAnimatedWireframeCrossAspectRatioMappingMutationHookResult = ReturnType<typeof useDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation>;
export type DeleteCcAnimatedWireframeCrossAspectRatioMappingMutationResult = Apollo.MutationResult<GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation>;
export type DeleteCcAnimatedWireframeCrossAspectRatioMappingMutationOptions = Apollo.BaseMutationOptions<
    GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutation,
    GqlClientDeleteCcAnimatedWireframeCrossAspectRatioMappingMutationVariables
>;
export const UpdateCcAnimatedWireframeDocument = gql`
    mutation updateCcAnimatedWireframe($updateCcAnimatedWireframeInput: UpdateCcAnimatedWireframeInput!) {
        updateCcAnimatedWireframe(input: $updateCcAnimatedWireframeInput) {
            ... on UpdateCcAnimatedWireframeOutputSuccess {
                result
                animatedWireframe {
                    ...CcAnimatedWireframeWithVersions
                    libraryVersion {
                        id
                        animatedWireframes {
                            ...CcAnimatedWireframeWithVersions
                        }
                    }
                }
            }
            ... on UpdateCcAnimatedWireframeOutputConflict {
                result
                animatedWireframe {
                    ...CcAnimatedWireframeWithVersions
                }
            }
        }
    }
    ${CcAnimatedWireframeWithVersionsFragmentDoc}
`;
export type GqlClientUpdateCcAnimatedWireframeMutationFn = Apollo.MutationFunction<GqlClientUpdateCcAnimatedWireframeMutation, GqlClientUpdateCcAnimatedWireframeMutationVariables>;
export type GqlClientUpdateCcAnimatedWireframeProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCcAnimatedWireframeMutation, GqlClientUpdateCcAnimatedWireframeMutationVariables>;
} & TChildProps;
export function withUpdateCcAnimatedWireframe<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateCcAnimatedWireframeMutation,
        GqlClientUpdateCcAnimatedWireframeMutationVariables,
        GqlClientUpdateCcAnimatedWireframeProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateCcAnimatedWireframeMutation,
        GqlClientUpdateCcAnimatedWireframeMutationVariables,
        GqlClientUpdateCcAnimatedWireframeProps<TChildProps, TDataName>
    >(UpdateCcAnimatedWireframeDocument, {
        alias: "updateCcAnimatedWireframe",
        ...operationOptions
    });
}

/**
 * __useUpdateCcAnimatedWireframeMutation__
 *
 * To run a mutation, you first call `useUpdateCcAnimatedWireframeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCcAnimatedWireframeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCcAnimatedWireframeMutation, { data, loading, error }] = useUpdateCcAnimatedWireframeMutation({
 *   variables: {
 *      updateCcAnimatedWireframeInput: // value for 'updateCcAnimatedWireframeInput'
 *   },
 * });
 */
export function useUpdateCcAnimatedWireframeMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCcAnimatedWireframeMutation, GqlClientUpdateCcAnimatedWireframeMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCcAnimatedWireframeMutation, GqlClientUpdateCcAnimatedWireframeMutationVariables>(UpdateCcAnimatedWireframeDocument, options);
}
export type UpdateCcAnimatedWireframeMutationHookResult = ReturnType<typeof useUpdateCcAnimatedWireframeMutation>;
export type UpdateCcAnimatedWireframeMutationResult = Apollo.MutationResult<GqlClientUpdateCcAnimatedWireframeMutation>;
export type UpdateCcAnimatedWireframeMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateCcAnimatedWireframeMutation, GqlClientUpdateCcAnimatedWireframeMutationVariables>;
export const UpdateCcAnimatedWireframeCrossAspectRatioMappingDocument = gql`
    mutation updateCcAnimatedWireframeCrossAspectRatioMapping($updateCcAnimatedWireframeCrossAspectRatioMappingInput: UpdateCcAnimatedWireframeCrossAspectRatioMappingInput!) {
        updateCcAnimatedWireframeCrossAspectRatioMapping(input: $updateCcAnimatedWireframeCrossAspectRatioMappingInput) {
            ... on UpdateCcAnimatedWireframeCrossAspectRatioMappingOutput {
                result
                animatedWireframe {
                    ...CcAnimatedWireframeWithVersions
                }
            }
        }
    }
    ${CcAnimatedWireframeWithVersionsFragmentDoc}
`;
export type GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutationFn = Apollo.MutationFunction<
    GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation,
    GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutationVariables
>;
export type GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation, GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutationVariables>;
} & TChildProps;
export function withUpdateCcAnimatedWireframeCrossAspectRatioMapping<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation,
        GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutationVariables,
        GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation,
        GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutationVariables,
        GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingProps<TChildProps, TDataName>
    >(UpdateCcAnimatedWireframeCrossAspectRatioMappingDocument, {
        alias: "updateCcAnimatedWireframeCrossAspectRatioMapping",
        ...operationOptions
    });
}

/**
 * __useUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation__
 *
 * To run a mutation, you first call `useUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCcAnimatedWireframeCrossAspectRatioMappingMutation, { data, loading, error }] = useUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation({
 *   variables: {
 *      updateCcAnimatedWireframeCrossAspectRatioMappingInput: // value for 'updateCcAnimatedWireframeCrossAspectRatioMappingInput'
 *   },
 * });
 */
export function useUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation, GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation, GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutationVariables>(
        UpdateCcAnimatedWireframeCrossAspectRatioMappingDocument,
        options
    );
}
export type UpdateCcAnimatedWireframeCrossAspectRatioMappingMutationHookResult = ReturnType<typeof useUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation>;
export type UpdateCcAnimatedWireframeCrossAspectRatioMappingMutationResult = Apollo.MutationResult<GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation>;
export type UpdateCcAnimatedWireframeCrossAspectRatioMappingMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutation,
    GqlClientUpdateCcAnimatedWireframeCrossAspectRatioMappingMutationVariables
>;
export const UpdateCcAnimatedWireframeSetApprovedVersionFromDraftDocument = gql`
    mutation updateCcAnimatedWireframeSetApprovedVersionFromDraft($updateCcAnimatedWireframeSetApprovedVersionFromDraftInput: UpdateCcAnimatedWireframeSetApprovedVersionFromDraftInput!) {
        updateCcAnimatedWireframeSetApprovedVersionFromDraft(input: $updateCcAnimatedWireframeSetApprovedVersionFromDraftInput) {
            ... on UpdateCcAnimatedWireframeSetApprovedVersionFromDraftOutput {
                result
                animatedWireframes {
                    ...CcAnimatedWireframeWithVersions
                }
            }
        }
    }
    ${CcAnimatedWireframeWithVersionsFragmentDoc}
`;
export type GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutationFn = Apollo.MutationFunction<
    GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation,
    GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutationVariables
>;
export type GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation, GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutationVariables>;
} & TChildProps;
export function withUpdateCcAnimatedWireframeSetApprovedVersionFromDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation,
        GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutationVariables,
        GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation,
        GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutationVariables,
        GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftProps<TChildProps, TDataName>
    >(UpdateCcAnimatedWireframeSetApprovedVersionFromDraftDocument, {
        alias: "updateCcAnimatedWireframeSetApprovedVersionFromDraft",
        ...operationOptions
    });
}

/**
 * __useUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation__
 *
 * To run a mutation, you first call `useUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCcAnimatedWireframeSetApprovedVersionFromDraftMutation, { data, loading, error }] = useUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation({
 *   variables: {
 *      updateCcAnimatedWireframeSetApprovedVersionFromDraftInput: // value for 'updateCcAnimatedWireframeSetApprovedVersionFromDraftInput'
 *   },
 * });
 */
export function useUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation, GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation, GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutationVariables>(
        UpdateCcAnimatedWireframeSetApprovedVersionFromDraftDocument,
        options
    );
}
export type UpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutationHookResult = ReturnType<typeof useUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation>;
export type UpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutationResult = Apollo.MutationResult<GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation>;
export type UpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutation,
    GqlClientUpdateCcAnimatedWireframeSetApprovedVersionFromDraftMutationVariables
>;
export const UpdateCcThemeDocument = gql`
    mutation updateCcTheme($updateCcThemeInput: UpdateCcThemeInput!) {
        updateCcTheme(input: $updateCcThemeInput) {
            ... on UpdateCcThemeOutputSuccess {
                result
                theme {
                    id
                    libraryVersion {
                        id
                        themes {
                            ...CcTheme
                        }
                    }
                }
            }
            ... on UpdateCcThemeOutputConflict {
                result
                theme {
                    id
                    libraryVersion {
                        id
                        themes {
                            ...CcTheme
                        }
                    }
                }
            }
        }
    }
    ${CcThemeFragmentDoc}
`;
export type GqlClientUpdateCcThemeMutationFn = Apollo.MutationFunction<GqlClientUpdateCcThemeMutation, GqlClientUpdateCcThemeMutationVariables>;
export type GqlClientUpdateCcThemeProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCcThemeMutation, GqlClientUpdateCcThemeMutationVariables>;
} & TChildProps;
export function withUpdateCcTheme<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateCcThemeMutation, GqlClientUpdateCcThemeMutationVariables, GqlClientUpdateCcThemeProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateCcThemeMutation, GqlClientUpdateCcThemeMutationVariables, GqlClientUpdateCcThemeProps<TChildProps, TDataName>>(UpdateCcThemeDocument, {
        alias: "updateCcTheme",
        ...operationOptions
    });
}

/**
 * __useUpdateCcThemeMutation__
 *
 * To run a mutation, you first call `useUpdateCcThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCcThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCcThemeMutation, { data, loading, error }] = useUpdateCcThemeMutation({
 *   variables: {
 *      updateCcThemeInput: // value for 'updateCcThemeInput'
 *   },
 * });
 */
export function useUpdateCcThemeMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCcThemeMutation, GqlClientUpdateCcThemeMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCcThemeMutation, GqlClientUpdateCcThemeMutationVariables>(UpdateCcThemeDocument, options);
}
export type UpdateCcThemeMutationHookResult = ReturnType<typeof useUpdateCcThemeMutation>;
export type UpdateCcThemeMutationResult = Apollo.MutationResult<GqlClientUpdateCcThemeMutation>;
export type UpdateCcThemeMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateCcThemeMutation, GqlClientUpdateCcThemeMutationVariables>;
export const UpdateCcWireframeDocument = gql`
    mutation updateCcWireframe($updateCcWireframeInput: UpdateCcWireframeInput!) {
        updateCcWireframe(input: $updateCcWireframeInput) {
            ... on UpdateCcWireframeOutputSuccess {
                result
                wireframe {
                    id
                    libraryVersion {
                        id
                        wireframes {
                            ...CcWireframe
                        }
                    }
                }
            }
            ... on UpdateCcWireframeOutputConflict {
                result
                wireframe {
                    id
                    libraryVersion {
                        id
                        wireframes {
                            ...CcWireframe
                        }
                    }
                }
            }
        }
    }
    ${CcWireframeFragmentDoc}
`;
export type GqlClientUpdateCcWireframeMutationFn = Apollo.MutationFunction<GqlClientUpdateCcWireframeMutation, GqlClientUpdateCcWireframeMutationVariables>;
export type GqlClientUpdateCcWireframeProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCcWireframeMutation, GqlClientUpdateCcWireframeMutationVariables>;
} & TChildProps;
export function withUpdateCcWireframe<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateCcWireframeMutation, GqlClientUpdateCcWireframeMutationVariables, GqlClientUpdateCcWireframeProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateCcWireframeMutation, GqlClientUpdateCcWireframeMutationVariables, GqlClientUpdateCcWireframeProps<TChildProps, TDataName>>(
        UpdateCcWireframeDocument,
        {
            alias: "updateCcWireframe",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateCcWireframeMutation__
 *
 * To run a mutation, you first call `useUpdateCcWireframeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCcWireframeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCcWireframeMutation, { data, loading, error }] = useUpdateCcWireframeMutation({
 *   variables: {
 *      updateCcWireframeInput: // value for 'updateCcWireframeInput'
 *   },
 * });
 */
export function useUpdateCcWireframeMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCcWireframeMutation, GqlClientUpdateCcWireframeMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCcWireframeMutation, GqlClientUpdateCcWireframeMutationVariables>(UpdateCcWireframeDocument, options);
}
export type UpdateCcWireframeMutationHookResult = ReturnType<typeof useUpdateCcWireframeMutation>;
export type UpdateCcWireframeMutationResult = Apollo.MutationResult<GqlClientUpdateCcWireframeMutation>;
export type UpdateCcWireframeMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateCcWireframeMutation, GqlClientUpdateCcWireframeMutationVariables>;
export const GetCcAnimatedWireframesDocument = gql`
    query getCcAnimatedWireframes($libraryId: ID!) {
        ccLibrary(id: $libraryId) {
            id
            libraryVersionDraft {
                id
                animatedWireframes {
                    ...CcAnimatedWireframeWithVersions
                }
            }
        }
    }
    ${CcAnimatedWireframeWithVersionsFragmentDoc}
`;
export type GqlClientGetCcAnimatedWireframesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetCcAnimatedWireframesQuery, GqlClientGetCcAnimatedWireframesQueryVariables>;
} & TChildProps;
export function withGetCcAnimatedWireframes<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetCcAnimatedWireframesQuery,
        GqlClientGetCcAnimatedWireframesQueryVariables,
        GqlClientGetCcAnimatedWireframesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetCcAnimatedWireframesQuery, GqlClientGetCcAnimatedWireframesQueryVariables, GqlClientGetCcAnimatedWireframesProps<TChildProps, TDataName>>(
        GetCcAnimatedWireframesDocument,
        {
            alias: "getCcAnimatedWireframes",
            ...operationOptions
        }
    );
}

/**
 * __useGetCcAnimatedWireframesQuery__
 *
 * To run a query within a React component, call `useGetCcAnimatedWireframesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCcAnimatedWireframesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCcAnimatedWireframesQuery({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *   },
 * });
 */
export function useGetCcAnimatedWireframesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetCcAnimatedWireframesQuery, GqlClientGetCcAnimatedWireframesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetCcAnimatedWireframesQuery, GqlClientGetCcAnimatedWireframesQueryVariables>(GetCcAnimatedWireframesDocument, options);
}
export function useGetCcAnimatedWireframesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetCcAnimatedWireframesQuery, GqlClientGetCcAnimatedWireframesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetCcAnimatedWireframesQuery, GqlClientGetCcAnimatedWireframesQueryVariables>(GetCcAnimatedWireframesDocument, options);
}
export type GetCcAnimatedWireframesQueryHookResult = ReturnType<typeof useGetCcAnimatedWireframesQuery>;
export type GetCcAnimatedWireframesLazyQueryHookResult = ReturnType<typeof useGetCcAnimatedWireframesLazyQuery>;
export type GetCcAnimatedWireframesQueryResult = Apollo.QueryResult<GqlClientGetCcAnimatedWireframesQuery, GqlClientGetCcAnimatedWireframesQueryVariables>;
export const GetAnimatedWireframesAndFilterInfoDocument = gql`
    query getAnimatedWireframesAndFilterInfo($libraryId: ID!) {
        ccLibrary(id: $libraryId) {
            id
            libraryVersionDraft {
                id
                themes {
                    id
                    name
                }
                wireframes {
                    id
                    name
                    lifecycleStage
                    comment
                }
                animatedWireframes {
                    ...CcAnimatedWireframeWithVersions
                }
            }
        }
    }
    ${CcAnimatedWireframeWithVersionsFragmentDoc}
`;
export type GqlClientGetAnimatedWireframesAndFilterInfoProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAnimatedWireframesAndFilterInfoQuery, GqlClientGetAnimatedWireframesAndFilterInfoQueryVariables>;
} & TChildProps;
export function withGetAnimatedWireframesAndFilterInfo<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetAnimatedWireframesAndFilterInfoQuery,
        GqlClientGetAnimatedWireframesAndFilterInfoQueryVariables,
        GqlClientGetAnimatedWireframesAndFilterInfoProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientGetAnimatedWireframesAndFilterInfoQuery,
        GqlClientGetAnimatedWireframesAndFilterInfoQueryVariables,
        GqlClientGetAnimatedWireframesAndFilterInfoProps<TChildProps, TDataName>
    >(GetAnimatedWireframesAndFilterInfoDocument, {
        alias: "getAnimatedWireframesAndFilterInfo",
        ...operationOptions
    });
}

/**
 * __useGetAnimatedWireframesAndFilterInfoQuery__
 *
 * To run a query within a React component, call `useGetAnimatedWireframesAndFilterInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnimatedWireframesAndFilterInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnimatedWireframesAndFilterInfoQuery({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *   },
 * });
 */
export function useGetAnimatedWireframesAndFilterInfoQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientGetAnimatedWireframesAndFilterInfoQuery, GqlClientGetAnimatedWireframesAndFilterInfoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAnimatedWireframesAndFilterInfoQuery, GqlClientGetAnimatedWireframesAndFilterInfoQueryVariables>(GetAnimatedWireframesAndFilterInfoDocument, options);
}
export function useGetAnimatedWireframesAndFilterInfoLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAnimatedWireframesAndFilterInfoQuery, GqlClientGetAnimatedWireframesAndFilterInfoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAnimatedWireframesAndFilterInfoQuery, GqlClientGetAnimatedWireframesAndFilterInfoQueryVariables>(GetAnimatedWireframesAndFilterInfoDocument, options);
}
export type GetAnimatedWireframesAndFilterInfoQueryHookResult = ReturnType<typeof useGetAnimatedWireframesAndFilterInfoQuery>;
export type GetAnimatedWireframesAndFilterInfoLazyQueryHookResult = ReturnType<typeof useGetAnimatedWireframesAndFilterInfoLazyQuery>;
export type GetAnimatedWireframesAndFilterInfoQueryResult = Apollo.QueryResult<GqlClientGetAnimatedWireframesAndFilterInfoQuery, GqlClientGetAnimatedWireframesAndFilterInfoQueryVariables>;
export const GetCcThemesDocument = gql`
    query getCcThemes($libraryId: ID!) {
        ccLibrary(id: $libraryId) {
            id
            libraryVersionDraft {
                id
                themes {
                    ...CcTheme
                }
            }
        }
    }
    ${CcThemeFragmentDoc}
`;
export type GqlClientGetCcThemesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetCcThemesQuery, GqlClientGetCcThemesQueryVariables>;
} & TChildProps;
export function withGetCcThemes<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetCcThemesQuery, GqlClientGetCcThemesQueryVariables, GqlClientGetCcThemesProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetCcThemesQuery, GqlClientGetCcThemesQueryVariables, GqlClientGetCcThemesProps<TChildProps, TDataName>>(GetCcThemesDocument, {
        alias: "getCcThemes",
        ...operationOptions
    });
}

/**
 * __useGetCcThemesQuery__
 *
 * To run a query within a React component, call `useGetCcThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCcThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCcThemesQuery({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *   },
 * });
 */
export function useGetCcThemesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetCcThemesQuery, GqlClientGetCcThemesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetCcThemesQuery, GqlClientGetCcThemesQueryVariables>(GetCcThemesDocument, options);
}
export function useGetCcThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetCcThemesQuery, GqlClientGetCcThemesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetCcThemesQuery, GqlClientGetCcThemesQueryVariables>(GetCcThemesDocument, options);
}
export type GetCcThemesQueryHookResult = ReturnType<typeof useGetCcThemesQuery>;
export type GetCcThemesLazyQueryHookResult = ReturnType<typeof useGetCcThemesLazyQuery>;
export type GetCcThemesQueryResult = Apollo.QueryResult<GqlClientGetCcThemesQuery, GqlClientGetCcThemesQueryVariables>;
export const GetCcVersionsDocument = gql`
    query getCcVersions($libraryId: ID!) {
        ccLibrary(id: $libraryId) {
            id
            libraryVersionSnapshots {
                ...CcVersion
            }
            libraryVersionActive {
                id
            }
            libraryVersionDraft {
                id
            }
        }
    }
    ${CcVersionFragmentDoc}
`;
export type GqlClientGetCcVersionsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetCcVersionsQuery, GqlClientGetCcVersionsQueryVariables>;
} & TChildProps;
export function withGetCcVersions<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetCcVersionsQuery, GqlClientGetCcVersionsQueryVariables, GqlClientGetCcVersionsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetCcVersionsQuery, GqlClientGetCcVersionsQueryVariables, GqlClientGetCcVersionsProps<TChildProps, TDataName>>(GetCcVersionsDocument, {
        alias: "getCcVersions",
        ...operationOptions
    });
}

/**
 * __useGetCcVersionsQuery__
 *
 * To run a query within a React component, call `useGetCcVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCcVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCcVersionsQuery({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *   },
 * });
 */
export function useGetCcVersionsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetCcVersionsQuery, GqlClientGetCcVersionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetCcVersionsQuery, GqlClientGetCcVersionsQueryVariables>(GetCcVersionsDocument, options);
}
export function useGetCcVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetCcVersionsQuery, GqlClientGetCcVersionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetCcVersionsQuery, GqlClientGetCcVersionsQueryVariables>(GetCcVersionsDocument, options);
}
export type GetCcVersionsQueryHookResult = ReturnType<typeof useGetCcVersionsQuery>;
export type GetCcVersionsLazyQueryHookResult = ReturnType<typeof useGetCcVersionsLazyQuery>;
export type GetCcVersionsQueryResult = Apollo.QueryResult<GqlClientGetCcVersionsQuery, GqlClientGetCcVersionsQueryVariables>;
export const GetCcWireframesDocument = gql`
    query getCcWireframes($libraryId: ID!) {
        ccLibrary(id: $libraryId) {
            id
            libraryVersionDraft {
                id
                wireframes {
                    ...CcWireframe
                }
                placeholderIntents {
                    ...CcPlaceholderIntent
                }
            }
        }
    }
    ${CcWireframeFragmentDoc}
    ${CcPlaceholderIntentFragmentDoc}
`;
export type GqlClientGetCcWireframesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetCcWireframesQuery, GqlClientGetCcWireframesQueryVariables>;
} & TChildProps;
export function withGetCcWireframes<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetCcWireframesQuery, GqlClientGetCcWireframesQueryVariables, GqlClientGetCcWireframesProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetCcWireframesQuery, GqlClientGetCcWireframesQueryVariables, GqlClientGetCcWireframesProps<TChildProps, TDataName>>(GetCcWireframesDocument, {
        alias: "getCcWireframes",
        ...operationOptions
    });
}

/**
 * __useGetCcWireframesQuery__
 *
 * To run a query within a React component, call `useGetCcWireframesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCcWireframesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCcWireframesQuery({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *   },
 * });
 */
export function useGetCcWireframesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetCcWireframesQuery, GqlClientGetCcWireframesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetCcWireframesQuery, GqlClientGetCcWireframesQueryVariables>(GetCcWireframesDocument, options);
}
export function useGetCcWireframesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetCcWireframesQuery, GqlClientGetCcWireframesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetCcWireframesQuery, GqlClientGetCcWireframesQueryVariables>(GetCcWireframesDocument, options);
}
export type GetCcWireframesQueryHookResult = ReturnType<typeof useGetCcWireframesQuery>;
export type GetCcWireframesLazyQueryHookResult = ReturnType<typeof useGetCcWireframesLazyQuery>;
export type GetCcWireframesQueryResult = Apollo.QueryResult<GqlClientGetCcWireframesQuery, GqlClientGetCcWireframesQueryVariables>;
export const AccountCcLibraryVersionChangedDocument = gql`
    subscription accountCcLibraryVersionChanged($libraryId: ID!) {
        accountCcLibraryVersionChanged(libraryId: $libraryId) {
            ...CcVersion
        }
    }
    ${CcVersionFragmentDoc}
`;
export type GqlClientAccountCcLibraryVersionChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountCcLibraryVersionChangedSubscription, GqlClientAccountCcLibraryVersionChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountCcLibraryVersionChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountCcLibraryVersionChangedSubscription,
        GqlClientAccountCcLibraryVersionChangedSubscriptionVariables,
        GqlClientAccountCcLibraryVersionChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountCcLibraryVersionChangedSubscription,
        GqlClientAccountCcLibraryVersionChangedSubscriptionVariables,
        GqlClientAccountCcLibraryVersionChangedProps<TChildProps, TDataName>
    >(AccountCcLibraryVersionChangedDocument, {
        alias: "accountCcLibraryVersionChanged",
        ...operationOptions
    });
}

/**
 * __useAccountCcLibraryVersionChangedSubscription__
 *
 * To run a query within a React component, call `useAccountCcLibraryVersionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountCcLibraryVersionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCcLibraryVersionChangedSubscription({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *   },
 * });
 */
export function useAccountCcLibraryVersionChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAccountCcLibraryVersionChangedSubscription, GqlClientAccountCcLibraryVersionChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountCcLibraryVersionChangedSubscription, GqlClientAccountCcLibraryVersionChangedSubscriptionVariables>(AccountCcLibraryVersionChangedDocument, options);
}
export type AccountCcLibraryVersionChangedSubscriptionHookResult = ReturnType<typeof useAccountCcLibraryVersionChangedSubscription>;
export type AccountCcLibraryVersionChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountCcLibraryVersionChangedSubscription>;
export const UpdateCcAnimatedWireframeSetDraftVersionFromApprovedDocument = gql`
    mutation updateCcAnimatedWireframeSetDraftVersionFromApproved($updateCcAnimatedWireframeSetDraftVersionFromApprovedInput: UpdateCcAnimatedWireframeSetDraftVersionFromApprovedInput!) {
        updateCcAnimatedWireframeSetDraftVersionFromApproved(input: $updateCcAnimatedWireframeSetDraftVersionFromApprovedInput) {
            ... on UpdateCcAnimatedWireframeSetDraftVersionFromApprovedOutput {
                result
                animatedWireframes {
                    ...CcAnimatedWireframeWithVersions
                }
            }
        }
    }
    ${CcAnimatedWireframeWithVersionsFragmentDoc}
`;
export type GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutationFn = Apollo.MutationFunction<
    GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation,
    GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutationVariables
>;
export type GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation, GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutationVariables>;
} & TChildProps;
export function withUpdateCcAnimatedWireframeSetDraftVersionFromApproved<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation,
        GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutationVariables,
        GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation,
        GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutationVariables,
        GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedProps<TChildProps, TDataName>
    >(UpdateCcAnimatedWireframeSetDraftVersionFromApprovedDocument, {
        alias: "updateCcAnimatedWireframeSetDraftVersionFromApproved",
        ...operationOptions
    });
}

/**
 * __useUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation__
 *
 * To run a mutation, you first call `useUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCcAnimatedWireframeSetDraftVersionFromApprovedMutation, { data, loading, error }] = useUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation({
 *   variables: {
 *      updateCcAnimatedWireframeSetDraftVersionFromApprovedInput: // value for 'updateCcAnimatedWireframeSetDraftVersionFromApprovedInput'
 *   },
 * });
 */
export function useUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation, GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation, GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutationVariables>(
        UpdateCcAnimatedWireframeSetDraftVersionFromApprovedDocument,
        options
    );
}
export type UpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutationHookResult = ReturnType<typeof useUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation>;
export type UpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutationResult = Apollo.MutationResult<GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation>;
export type UpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutation,
    GqlClientUpdateCcAnimatedWireframeSetDraftVersionFromApprovedMutationVariables
>;
export const ClearUndoStackDocument = gql`
    mutation clearUndoStack {
        clearUndoStack {
            result
            undoStacks {
                ...UndoStacks
            }
        }
    }
    ${UndoStacksFragmentDoc}
`;
export type GqlClientClearUndoStackMutationFn = Apollo.MutationFunction<GqlClientClearUndoStackMutation, GqlClientClearUndoStackMutationVariables>;
export type GqlClientClearUndoStackProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientClearUndoStackMutation, GqlClientClearUndoStackMutationVariables>;
} & TChildProps;
export function withClearUndoStack<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientClearUndoStackMutation, GqlClientClearUndoStackMutationVariables, GqlClientClearUndoStackProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientClearUndoStackMutation, GqlClientClearUndoStackMutationVariables, GqlClientClearUndoStackProps<TChildProps, TDataName>>(
        ClearUndoStackDocument,
        {
            alias: "clearUndoStack",
            ...operationOptions
        }
    );
}

/**
 * __useClearUndoStackMutation__
 *
 * To run a mutation, you first call `useClearUndoStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearUndoStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearUndoStackMutation, { data, loading, error }] = useClearUndoStackMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearUndoStackMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientClearUndoStackMutation, GqlClientClearUndoStackMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientClearUndoStackMutation, GqlClientClearUndoStackMutationVariables>(ClearUndoStackDocument, options);
}
export type ClearUndoStackMutationHookResult = ReturnType<typeof useClearUndoStackMutation>;
export type ClearUndoStackMutationResult = Apollo.MutationResult<GqlClientClearUndoStackMutation>;
export type ClearUndoStackMutationOptions = Apollo.BaseMutationOptions<GqlClientClearUndoStackMutation, GqlClientClearUndoStackMutationVariables>;
export const CreateAccountCopilotBriefDocument = gql`
    mutation CreateAccountCopilotBrief($input: CreateAccountCopilotBriefInput!) {
        createAccountCopilotBrief(input: $input) {
            result
            ... on CreateAccountCopilotBriefOutput {
                account {
                    id
                    copilotUserBriefs {
                        id
                    }
                    copilotOtherUsersBriefs {
                        id
                    }
                }
                brief {
                    ...AccountCopilotBrief
                }
            }
        }
    }
    ${AccountCopilotBriefFragmentDoc}
`;
export type GqlClientCreateAccountCopilotBriefMutationFn = Apollo.MutationFunction<GqlClientCreateAccountCopilotBriefMutation, GqlClientCreateAccountCopilotBriefMutationVariables>;
export type GqlClientCreateAccountCopilotBriefProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateAccountCopilotBriefMutation, GqlClientCreateAccountCopilotBriefMutationVariables>;
} & TChildProps;
export function withCreateAccountCopilotBrief<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateAccountCopilotBriefMutation,
        GqlClientCreateAccountCopilotBriefMutationVariables,
        GqlClientCreateAccountCopilotBriefProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateAccountCopilotBriefMutation,
        GqlClientCreateAccountCopilotBriefMutationVariables,
        GqlClientCreateAccountCopilotBriefProps<TChildProps, TDataName>
    >(CreateAccountCopilotBriefDocument, {
        alias: "createAccountCopilotBrief",
        ...operationOptions
    });
}

/**
 * __useCreateAccountCopilotBriefMutation__
 *
 * To run a mutation, you first call `useCreateAccountCopilotBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountCopilotBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountCopilotBriefMutation, { data, loading, error }] = useCreateAccountCopilotBriefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountCopilotBriefMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateAccountCopilotBriefMutation, GqlClientCreateAccountCopilotBriefMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateAccountCopilotBriefMutation, GqlClientCreateAccountCopilotBriefMutationVariables>(CreateAccountCopilotBriefDocument, options);
}
export type CreateAccountCopilotBriefMutationHookResult = ReturnType<typeof useCreateAccountCopilotBriefMutation>;
export type CreateAccountCopilotBriefMutationResult = Apollo.MutationResult<GqlClientCreateAccountCopilotBriefMutation>;
export type CreateAccountCopilotBriefMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateAccountCopilotBriefMutation, GqlClientCreateAccountCopilotBriefMutationVariables>;
export const CreateAccountProgramFolderDocument = gql`
    mutation createAccountProgramFolder($input: CreateAccountProgramFolderInput!) {
        createAccountProgramFolder(input: $input) {
            folder {
                ...ProgramFolder
            }
            account {
                id
                folders {
                    id
                }
            }
        }
    }
    ${ProgramFolderFragmentDoc}
`;
export type GqlClientCreateAccountProgramFolderMutationFn = Apollo.MutationFunction<GqlClientCreateAccountProgramFolderMutation, GqlClientCreateAccountProgramFolderMutationVariables>;
export type GqlClientCreateAccountProgramFolderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateAccountProgramFolderMutation, GqlClientCreateAccountProgramFolderMutationVariables>;
} & TChildProps;
export function withCreateAccountProgramFolder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateAccountProgramFolderMutation,
        GqlClientCreateAccountProgramFolderMutationVariables,
        GqlClientCreateAccountProgramFolderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateAccountProgramFolderMutation,
        GqlClientCreateAccountProgramFolderMutationVariables,
        GqlClientCreateAccountProgramFolderProps<TChildProps, TDataName>
    >(CreateAccountProgramFolderDocument, {
        alias: "createAccountProgramFolder",
        ...operationOptions
    });
}

/**
 * __useCreateAccountProgramFolderMutation__
 *
 * To run a mutation, you first call `useCreateAccountProgramFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountProgramFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountProgramFolderMutation, { data, loading, error }] = useCreateAccountProgramFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountProgramFolderMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateAccountProgramFolderMutation, GqlClientCreateAccountProgramFolderMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateAccountProgramFolderMutation, GqlClientCreateAccountProgramFolderMutationVariables>(CreateAccountProgramFolderDocument, options);
}
export type CreateAccountProgramFolderMutationHookResult = ReturnType<typeof useCreateAccountProgramFolderMutation>;
export type CreateAccountProgramFolderMutationResult = Apollo.MutationResult<GqlClientCreateAccountProgramFolderMutation>;
export type CreateAccountProgramFolderMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateAccountProgramFolderMutation, GqlClientCreateAccountProgramFolderMutationVariables>;
export const CreateAiPreviewProgramDocument = gql`
    mutation createAiPreviewProgram($input: CreateAiPreviewProgramInput!, $mutationId: String) {
        createAiPreviewProgram(input: $input, mutationId: $mutationId) {
            result
            pollingTaskCreationResponse {
                sessionId
                pollingUrl
            }
        }
    }
`;
export type GqlClientCreateAiPreviewProgramMutationFn = Apollo.MutationFunction<GqlClientCreateAiPreviewProgramMutation, GqlClientCreateAiPreviewProgramMutationVariables>;
export type GqlClientCreateAiPreviewProgramProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateAiPreviewProgramMutation, GqlClientCreateAiPreviewProgramMutationVariables>;
} & TChildProps;
export function withCreateAiPreviewProgram<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateAiPreviewProgramMutation,
        GqlClientCreateAiPreviewProgramMutationVariables,
        GqlClientCreateAiPreviewProgramProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateAiPreviewProgramMutation, GqlClientCreateAiPreviewProgramMutationVariables, GqlClientCreateAiPreviewProgramProps<TChildProps, TDataName>>(
        CreateAiPreviewProgramDocument,
        {
            alias: "createAiPreviewProgram",
            ...operationOptions
        }
    );
}

/**
 * __useCreateAiPreviewProgramMutation__
 *
 * To run a mutation, you first call `useCreateAiPreviewProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiPreviewProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiPreviewProgramMutation, { data, loading, error }] = useCreateAiPreviewProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useCreateAiPreviewProgramMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateAiPreviewProgramMutation, GqlClientCreateAiPreviewProgramMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateAiPreviewProgramMutation, GqlClientCreateAiPreviewProgramMutationVariables>(CreateAiPreviewProgramDocument, options);
}
export type CreateAiPreviewProgramMutationHookResult = ReturnType<typeof useCreateAiPreviewProgramMutation>;
export type CreateAiPreviewProgramMutationResult = Apollo.MutationResult<GqlClientCreateAiPreviewProgramMutation>;
export type CreateAiPreviewProgramMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateAiPreviewProgramMutation, GqlClientCreateAiPreviewProgramMutationVariables>;
export const CreateBrandDocument = gql`
    mutation CreateBrand($input: CreateBcCollectionInput!) {
        createBcCollection(input: $input) {
            result
            brandConfigurationCollection {
                ...EditorBrand
            }
        }
    }
    ${EditorBrandFragmentDoc}
`;
export type GqlClientCreateBrandMutationFn = Apollo.MutationFunction<GqlClientCreateBrandMutation, GqlClientCreateBrandMutationVariables>;
export type GqlClientCreateBrandProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateBrandMutation, GqlClientCreateBrandMutationVariables>;
} & TChildProps;
export function withCreateBrand<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateBrandMutation, GqlClientCreateBrandMutationVariables, GqlClientCreateBrandProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateBrandMutation, GqlClientCreateBrandMutationVariables, GqlClientCreateBrandProps<TChildProps, TDataName>>(CreateBrandDocument, {
        alias: "createBrand",
        ...operationOptions
    });
}

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateBrandMutation, GqlClientCreateBrandMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateBrandMutation, GqlClientCreateBrandMutationVariables>(CreateBrandDocument, options);
}
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = Apollo.MutationResult<GqlClientCreateBrandMutation>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateBrandMutation, GqlClientCreateBrandMutationVariables>;
export const CreateDataLibraryDocument = gql`
    mutation createDataLibrary($input: CreateDataLibraryInput!) {
        createDataLibrary(input: $input) {
            result
            editorProgramVersion {
                id
                accountDataLibraryVersion {
                    id
                }
            }
        }
    }
`;
export type GqlClientCreateDataLibraryMutationFn = Apollo.MutationFunction<GqlClientCreateDataLibraryMutation, GqlClientCreateDataLibraryMutationVariables>;
export type GqlClientCreateDataLibraryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateDataLibraryMutation, GqlClientCreateDataLibraryMutationVariables>;
} & TChildProps;
export function withCreateDataLibrary<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateDataLibraryMutation, GqlClientCreateDataLibraryMutationVariables, GqlClientCreateDataLibraryProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateDataLibraryMutation, GqlClientCreateDataLibraryMutationVariables, GqlClientCreateDataLibraryProps<TChildProps, TDataName>>(
        CreateDataLibraryDocument,
        {
            alias: "createDataLibrary",
            ...operationOptions
        }
    );
}

/**
 * __useCreateDataLibraryMutation__
 *
 * To run a mutation, you first call `useCreateDataLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataLibraryMutation, { data, loading, error }] = useCreateDataLibraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataLibraryMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateDataLibraryMutation, GqlClientCreateDataLibraryMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateDataLibraryMutation, GqlClientCreateDataLibraryMutationVariables>(CreateDataLibraryDocument, options);
}
export type CreateDataLibraryMutationHookResult = ReturnType<typeof useCreateDataLibraryMutation>;
export type CreateDataLibraryMutationResult = Apollo.MutationResult<GqlClientCreateDataLibraryMutation>;
export type CreateDataLibraryMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateDataLibraryMutation, GqlClientCreateDataLibraryMutationVariables>;
export const CreateEditorAccountUserDocument = gql`
    mutation CreateEditorAccountUser($input: CreateEditorAccountUserInput!) {
        createEditorAccountUser(input: $input) {
            result
            ... on CreateEditorAccountUserOutputSuccess {
                accountUser {
                    id
                    roles
                    invitationStatus
                    user {
                        id
                        email
                        roles
                        lastLogin
                        created
                        hasMultipleAccounts
                    }
                    account {
                        id
                        maxCapacityReached
                    }
                }
            }
        }
    }
`;
export type GqlClientCreateEditorAccountUserMutationFn = Apollo.MutationFunction<GqlClientCreateEditorAccountUserMutation, GqlClientCreateEditorAccountUserMutationVariables>;
export type GqlClientCreateEditorAccountUserProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateEditorAccountUserMutation, GqlClientCreateEditorAccountUserMutationVariables>;
} & TChildProps;
export function withCreateEditorAccountUser<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateEditorAccountUserMutation,
        GqlClientCreateEditorAccountUserMutationVariables,
        GqlClientCreateEditorAccountUserProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateEditorAccountUserMutation,
        GqlClientCreateEditorAccountUserMutationVariables,
        GqlClientCreateEditorAccountUserProps<TChildProps, TDataName>
    >(CreateEditorAccountUserDocument, {
        alias: "createEditorAccountUser",
        ...operationOptions
    });
}

/**
 * __useCreateEditorAccountUserMutation__
 *
 * To run a mutation, you first call `useCreateEditorAccountUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditorAccountUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditorAccountUserMutation, { data, loading, error }] = useCreateEditorAccountUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditorAccountUserMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateEditorAccountUserMutation, GqlClientCreateEditorAccountUserMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateEditorAccountUserMutation, GqlClientCreateEditorAccountUserMutationVariables>(CreateEditorAccountUserDocument, options);
}
export type CreateEditorAccountUserMutationHookResult = ReturnType<typeof useCreateEditorAccountUserMutation>;
export type CreateEditorAccountUserMutationResult = Apollo.MutationResult<GqlClientCreateEditorAccountUserMutation>;
export type CreateEditorAccountUserMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateEditorAccountUserMutation, GqlClientCreateEditorAccountUserMutationVariables>;
export const CreateEditorAssetFolderDocument = gql`
    mutation CreateEditorAssetFolder($input: CreateEditorAssetFolderInput!) {
        createEditorAssetFolder(input: $input) {
            result
            ... on CreateEditorAssetFolderOutputSuccess {
                folder {
                    id
                    name
                    accountId
                    parentFolder {
                        id
                    }
                    created
                    createdBy
                    updated
                    updatedBy
                }
                editorAssetLibraryVersion {
                    id
                    media {
                        id
                    }
                    mediaFolders {
                        id
                    }
                }
            }
        }
    }
`;
export type GqlClientCreateEditorAssetFolderMutationFn = Apollo.MutationFunction<GqlClientCreateEditorAssetFolderMutation, GqlClientCreateEditorAssetFolderMutationVariables>;
export type GqlClientCreateEditorAssetFolderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateEditorAssetFolderMutation, GqlClientCreateEditorAssetFolderMutationVariables>;
} & TChildProps;
export function withCreateEditorAssetFolder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateEditorAssetFolderMutation,
        GqlClientCreateEditorAssetFolderMutationVariables,
        GqlClientCreateEditorAssetFolderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateEditorAssetFolderMutation,
        GqlClientCreateEditorAssetFolderMutationVariables,
        GqlClientCreateEditorAssetFolderProps<TChildProps, TDataName>
    >(CreateEditorAssetFolderDocument, {
        alias: "createEditorAssetFolder",
        ...operationOptions
    });
}

/**
 * __useCreateEditorAssetFolderMutation__
 *
 * To run a mutation, you first call `useCreateEditorAssetFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditorAssetFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditorAssetFolderMutation, { data, loading, error }] = useCreateEditorAssetFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditorAssetFolderMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateEditorAssetFolderMutation, GqlClientCreateEditorAssetFolderMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateEditorAssetFolderMutation, GqlClientCreateEditorAssetFolderMutationVariables>(CreateEditorAssetFolderDocument, options);
}
export type CreateEditorAssetFolderMutationHookResult = ReturnType<typeof useCreateEditorAssetFolderMutation>;
export type CreateEditorAssetFolderMutationResult = Apollo.MutationResult<GqlClientCreateEditorAssetFolderMutation>;
export type CreateEditorAssetFolderMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateEditorAssetFolderMutation, GqlClientCreateEditorAssetFolderMutationVariables>;
export const CreateEditorAvatarDocument = gql`
    mutation CreateEditorAvatar($input: CreateEditorAvatarInput!) {
        createEditorAvatar(input: $input) {
            result
            editorPlaceholder {
                id
                scene {
                    id
                    placeholders {
                        ...EditorPlaceholder
                    }
                }
                ...EditorPlaceholderConfiguration
            }
        }
    }
    ${EditorPlaceholderFragmentDoc}
    ${EditorPlaceholderConfigurationFragmentDoc}
`;
export type GqlClientCreateEditorAvatarMutationFn = Apollo.MutationFunction<GqlClientCreateEditorAvatarMutation, GqlClientCreateEditorAvatarMutationVariables>;
export type GqlClientCreateEditorAvatarProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateEditorAvatarMutation, GqlClientCreateEditorAvatarMutationVariables>;
} & TChildProps;
export function withCreateEditorAvatar<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateEditorAvatarMutation,
        GqlClientCreateEditorAvatarMutationVariables,
        GqlClientCreateEditorAvatarProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateEditorAvatarMutation, GqlClientCreateEditorAvatarMutationVariables, GqlClientCreateEditorAvatarProps<TChildProps, TDataName>>(
        CreateEditorAvatarDocument,
        {
            alias: "createEditorAvatar",
            ...operationOptions
        }
    );
}

/**
 * __useCreateEditorAvatarMutation__
 *
 * To run a mutation, you first call `useCreateEditorAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditorAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditorAvatarMutation, { data, loading, error }] = useCreateEditorAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditorAvatarMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateEditorAvatarMutation, GqlClientCreateEditorAvatarMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateEditorAvatarMutation, GqlClientCreateEditorAvatarMutationVariables>(CreateEditorAvatarDocument, options);
}
export type CreateEditorAvatarMutationHookResult = ReturnType<typeof useCreateEditorAvatarMutation>;
export type CreateEditorAvatarMutationResult = Apollo.MutationResult<GqlClientCreateEditorAvatarMutation>;
export type CreateEditorAvatarMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateEditorAvatarMutation, GqlClientCreateEditorAvatarMutationVariables>;
export const CreateEditorFlexiblePlaceholderDocument = gql`
    mutation CreateEditorFlexiblePlaceholder($input: CreateEditorFlexiblePlaceholderInput!) {
        createEditorFlexiblePlaceholder(input: $input) {
            result
            editorPlaceholder {
                ...EditorPlaceholder
                scene {
                    id
                    placeholders {
                        id
                    }
                }
            }
        }
    }
    ${EditorPlaceholderFragmentDoc}
`;
export type GqlClientCreateEditorFlexiblePlaceholderMutationFn = Apollo.MutationFunction<GqlClientCreateEditorFlexiblePlaceholderMutation, GqlClientCreateEditorFlexiblePlaceholderMutationVariables>;
export type GqlClientCreateEditorFlexiblePlaceholderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateEditorFlexiblePlaceholderMutation, GqlClientCreateEditorFlexiblePlaceholderMutationVariables>;
} & TChildProps;
export function withCreateEditorFlexiblePlaceholder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateEditorFlexiblePlaceholderMutation,
        GqlClientCreateEditorFlexiblePlaceholderMutationVariables,
        GqlClientCreateEditorFlexiblePlaceholderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateEditorFlexiblePlaceholderMutation,
        GqlClientCreateEditorFlexiblePlaceholderMutationVariables,
        GqlClientCreateEditorFlexiblePlaceholderProps<TChildProps, TDataName>
    >(CreateEditorFlexiblePlaceholderDocument, {
        alias: "createEditorFlexiblePlaceholder",
        ...operationOptions
    });
}

/**
 * __useCreateEditorFlexiblePlaceholderMutation__
 *
 * To run a mutation, you first call `useCreateEditorFlexiblePlaceholderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditorFlexiblePlaceholderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditorFlexiblePlaceholderMutation, { data, loading, error }] = useCreateEditorFlexiblePlaceholderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditorFlexiblePlaceholderMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateEditorFlexiblePlaceholderMutation, GqlClientCreateEditorFlexiblePlaceholderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateEditorFlexiblePlaceholderMutation, GqlClientCreateEditorFlexiblePlaceholderMutationVariables>(CreateEditorFlexiblePlaceholderDocument, options);
}
export type CreateEditorFlexiblePlaceholderMutationHookResult = ReturnType<typeof useCreateEditorFlexiblePlaceholderMutation>;
export type CreateEditorFlexiblePlaceholderMutationResult = Apollo.MutationResult<GqlClientCreateEditorFlexiblePlaceholderMutation>;
export type CreateEditorFlexiblePlaceholderMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateEditorFlexiblePlaceholderMutation, GqlClientCreateEditorFlexiblePlaceholderMutationVariables>;
export const CreateEditorOverlayPlaceholderDocument = gql`
    mutation CreateEditorOverlayPlaceholder($input: CreateEditorOverlayPlaceholderInput!) {
        createEditorOverlayPlaceholder(input: $input) {
            result
            editorPlaceholder {
                ...EditorPlaceholder
                scene {
                    id
                    placeholders {
                        id
                    }
                }
            }
        }
    }
    ${EditorPlaceholderFragmentDoc}
`;
export type GqlClientCreateEditorOverlayPlaceholderMutationFn = Apollo.MutationFunction<GqlClientCreateEditorOverlayPlaceholderMutation, GqlClientCreateEditorOverlayPlaceholderMutationVariables>;
export type GqlClientCreateEditorOverlayPlaceholderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateEditorOverlayPlaceholderMutation, GqlClientCreateEditorOverlayPlaceholderMutationVariables>;
} & TChildProps;
export function withCreateEditorOverlayPlaceholder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateEditorOverlayPlaceholderMutation,
        GqlClientCreateEditorOverlayPlaceholderMutationVariables,
        GqlClientCreateEditorOverlayPlaceholderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateEditorOverlayPlaceholderMutation,
        GqlClientCreateEditorOverlayPlaceholderMutationVariables,
        GqlClientCreateEditorOverlayPlaceholderProps<TChildProps, TDataName>
    >(CreateEditorOverlayPlaceholderDocument, {
        alias: "createEditorOverlayPlaceholder",
        ...operationOptions
    });
}

/**
 * __useCreateEditorOverlayPlaceholderMutation__
 *
 * To run a mutation, you first call `useCreateEditorOverlayPlaceholderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditorOverlayPlaceholderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditorOverlayPlaceholderMutation, { data, loading, error }] = useCreateEditorOverlayPlaceholderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditorOverlayPlaceholderMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateEditorOverlayPlaceholderMutation, GqlClientCreateEditorOverlayPlaceholderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateEditorOverlayPlaceholderMutation, GqlClientCreateEditorOverlayPlaceholderMutationVariables>(CreateEditorOverlayPlaceholderDocument, options);
}
export type CreateEditorOverlayPlaceholderMutationHookResult = ReturnType<typeof useCreateEditorOverlayPlaceholderMutation>;
export type CreateEditorOverlayPlaceholderMutationResult = Apollo.MutationResult<GqlClientCreateEditorOverlayPlaceholderMutation>;
export type CreateEditorOverlayPlaceholderMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateEditorOverlayPlaceholderMutation, GqlClientCreateEditorOverlayPlaceholderMutationVariables>;
export const CreateEditorProgramSnapshotDocument = gql`
    mutation CreateEditorProgramSnapshot($input: CreateEditorProgramSnapshotInput!, $mutationId: String!) {
        createEditorProgramSnapshot(input: $input, mutationId: $mutationId) {
            result
        }
    }
`;
export type GqlClientCreateEditorProgramSnapshotMutationFn = Apollo.MutationFunction<GqlClientCreateEditorProgramSnapshotMutation, GqlClientCreateEditorProgramSnapshotMutationVariables>;
export type GqlClientCreateEditorProgramSnapshotProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateEditorProgramSnapshotMutation, GqlClientCreateEditorProgramSnapshotMutationVariables>;
} & TChildProps;
export function withCreateEditorProgramSnapshot<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateEditorProgramSnapshotMutation,
        GqlClientCreateEditorProgramSnapshotMutationVariables,
        GqlClientCreateEditorProgramSnapshotProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateEditorProgramSnapshotMutation,
        GqlClientCreateEditorProgramSnapshotMutationVariables,
        GqlClientCreateEditorProgramSnapshotProps<TChildProps, TDataName>
    >(CreateEditorProgramSnapshotDocument, {
        alias: "createEditorProgramSnapshot",
        ...operationOptions
    });
}

/**
 * __useCreateEditorProgramSnapshotMutation__
 *
 * To run a mutation, you first call `useCreateEditorProgramSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditorProgramSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditorProgramSnapshotMutation, { data, loading, error }] = useCreateEditorProgramSnapshotMutation({
 *   variables: {
 *      input: // value for 'input'
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useCreateEditorProgramSnapshotMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateEditorProgramSnapshotMutation, GqlClientCreateEditorProgramSnapshotMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateEditorProgramSnapshotMutation, GqlClientCreateEditorProgramSnapshotMutationVariables>(CreateEditorProgramSnapshotDocument, options);
}
export type CreateEditorProgramSnapshotMutationHookResult = ReturnType<typeof useCreateEditorProgramSnapshotMutation>;
export type CreateEditorProgramSnapshotMutationResult = Apollo.MutationResult<GqlClientCreateEditorProgramSnapshotMutation>;
export type CreateEditorProgramSnapshotMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateEditorProgramSnapshotMutation, GqlClientCreateEditorProgramSnapshotMutationVariables>;
export const CreateEditorScenesDocument = gql`
    mutation createEditorScenes($input: CreateEditorScenesInput!) {
        createEditorScenes(input: $input) {
            editorScenes {
                ...EditorScene
            }
            editorProgramVersion {
                id
                sceneLineup
                scenes {
                    id
                }
            }
        }
    }
    ${EditorSceneFragmentDoc}
`;
export type GqlClientCreateEditorScenesMutationFn = Apollo.MutationFunction<GqlClientCreateEditorScenesMutation, GqlClientCreateEditorScenesMutationVariables>;
export type GqlClientCreateEditorScenesProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateEditorScenesMutation, GqlClientCreateEditorScenesMutationVariables>;
} & TChildProps;
export function withCreateEditorScenes<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateEditorScenesMutation,
        GqlClientCreateEditorScenesMutationVariables,
        GqlClientCreateEditorScenesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateEditorScenesMutation, GqlClientCreateEditorScenesMutationVariables, GqlClientCreateEditorScenesProps<TChildProps, TDataName>>(
        CreateEditorScenesDocument,
        {
            alias: "createEditorScenes",
            ...operationOptions
        }
    );
}

/**
 * __useCreateEditorScenesMutation__
 *
 * To run a mutation, you first call `useCreateEditorScenesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditorScenesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditorScenesMutation, { data, loading, error }] = useCreateEditorScenesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditorScenesMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateEditorScenesMutation, GqlClientCreateEditorScenesMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateEditorScenesMutation, GqlClientCreateEditorScenesMutationVariables>(CreateEditorScenesDocument, options);
}
export type CreateEditorScenesMutationHookResult = ReturnType<typeof useCreateEditorScenesMutation>;
export type CreateEditorScenesMutationResult = Apollo.MutationResult<GqlClientCreateEditorScenesMutation>;
export type CreateEditorScenesMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateEditorScenesMutation, GqlClientCreateEditorScenesMutationVariables>;
export const CreateEditorScenesFromPromptDocument = gql`
    mutation createEditorScenesFromPrompt($input: CreateSceneFromPromptInput!, $mutationId: String) {
        createSceneFromPrompt(mutationId: $mutationId, input: $input) {
            result
        }
    }
`;
export type GqlClientCreateEditorScenesFromPromptMutationFn = Apollo.MutationFunction<GqlClientCreateEditorScenesFromPromptMutation, GqlClientCreateEditorScenesFromPromptMutationVariables>;
export type GqlClientCreateEditorScenesFromPromptProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateEditorScenesFromPromptMutation, GqlClientCreateEditorScenesFromPromptMutationVariables>;
} & TChildProps;
export function withCreateEditorScenesFromPrompt<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateEditorScenesFromPromptMutation,
        GqlClientCreateEditorScenesFromPromptMutationVariables,
        GqlClientCreateEditorScenesFromPromptProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateEditorScenesFromPromptMutation,
        GqlClientCreateEditorScenesFromPromptMutationVariables,
        GqlClientCreateEditorScenesFromPromptProps<TChildProps, TDataName>
    >(CreateEditorScenesFromPromptDocument, {
        alias: "createEditorScenesFromPrompt",
        ...operationOptions
    });
}

/**
 * __useCreateEditorScenesFromPromptMutation__
 *
 * To run a mutation, you first call `useCreateEditorScenesFromPromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditorScenesFromPromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditorScenesFromPromptMutation, { data, loading, error }] = useCreateEditorScenesFromPromptMutation({
 *   variables: {
 *      input: // value for 'input'
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useCreateEditorScenesFromPromptMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateEditorScenesFromPromptMutation, GqlClientCreateEditorScenesFromPromptMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateEditorScenesFromPromptMutation, GqlClientCreateEditorScenesFromPromptMutationVariables>(CreateEditorScenesFromPromptDocument, options);
}
export type CreateEditorScenesFromPromptMutationHookResult = ReturnType<typeof useCreateEditorScenesFromPromptMutation>;
export type CreateEditorScenesFromPromptMutationResult = Apollo.MutationResult<GqlClientCreateEditorScenesFromPromptMutation>;
export type CreateEditorScenesFromPromptMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateEditorScenesFromPromptMutation, GqlClientCreateEditorScenesFromPromptMutationVariables>;
export const CreateEditorViewerProfileDocument = gql`
    mutation createEditorViewerProfile($createEditorViewerProfileInput: CreateEditorViewerProfileInput!) {
        createEditorViewerProfile(input: $createEditorViewerProfileInput) {
            result
            viewerProfile {
                ...EditorViewerProfile
                program {
                    id
                    viewerProfiles {
                        ...EditorViewerProfile
                    }
                }
            }
        }
    }
    ${EditorViewerProfileFragmentDoc}
`;
export type GqlClientCreateEditorViewerProfileMutationFn = Apollo.MutationFunction<GqlClientCreateEditorViewerProfileMutation, GqlClientCreateEditorViewerProfileMutationVariables>;
export type GqlClientCreateEditorViewerProfileProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateEditorViewerProfileMutation, GqlClientCreateEditorViewerProfileMutationVariables>;
} & TChildProps;
export function withCreateEditorViewerProfile<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateEditorViewerProfileMutation,
        GqlClientCreateEditorViewerProfileMutationVariables,
        GqlClientCreateEditorViewerProfileProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateEditorViewerProfileMutation,
        GqlClientCreateEditorViewerProfileMutationVariables,
        GqlClientCreateEditorViewerProfileProps<TChildProps, TDataName>
    >(CreateEditorViewerProfileDocument, {
        alias: "createEditorViewerProfile",
        ...operationOptions
    });
}

/**
 * __useCreateEditorViewerProfileMutation__
 *
 * To run a mutation, you first call `useCreateEditorViewerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditorViewerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditorViewerProfileMutation, { data, loading, error }] = useCreateEditorViewerProfileMutation({
 *   variables: {
 *      createEditorViewerProfileInput: // value for 'createEditorViewerProfileInput'
 *   },
 * });
 */
export function useCreateEditorViewerProfileMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateEditorViewerProfileMutation, GqlClientCreateEditorViewerProfileMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateEditorViewerProfileMutation, GqlClientCreateEditorViewerProfileMutationVariables>(CreateEditorViewerProfileDocument, options);
}
export type CreateEditorViewerProfileMutationHookResult = ReturnType<typeof useCreateEditorViewerProfileMutation>;
export type CreateEditorViewerProfileMutationResult = Apollo.MutationResult<GqlClientCreateEditorViewerProfileMutation>;
export type CreateEditorViewerProfileMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateEditorViewerProfileMutation, GqlClientCreateEditorViewerProfileMutationVariables>;
export const CreateNextRecommendedVideoDocument = gql`
    mutation createNextRecommendedVideo($input: CreateNextRecommendedVideoInput!, $mutationId: String) {
        createNextRecommendedVideo(input: $input, mutationId: $mutationId) {
            result
            pollingTaskCreationResponse {
                sessionId
                pollingUrl
            }
        }
    }
`;
export type GqlClientCreateNextRecommendedVideoMutationFn = Apollo.MutationFunction<GqlClientCreateNextRecommendedVideoMutation, GqlClientCreateNextRecommendedVideoMutationVariables>;
export type GqlClientCreateNextRecommendedVideoProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateNextRecommendedVideoMutation, GqlClientCreateNextRecommendedVideoMutationVariables>;
} & TChildProps;
export function withCreateNextRecommendedVideo<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateNextRecommendedVideoMutation,
        GqlClientCreateNextRecommendedVideoMutationVariables,
        GqlClientCreateNextRecommendedVideoProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateNextRecommendedVideoMutation,
        GqlClientCreateNextRecommendedVideoMutationVariables,
        GqlClientCreateNextRecommendedVideoProps<TChildProps, TDataName>
    >(CreateNextRecommendedVideoDocument, {
        alias: "createNextRecommendedVideo",
        ...operationOptions
    });
}

/**
 * __useCreateNextRecommendedVideoMutation__
 *
 * To run a mutation, you first call `useCreateNextRecommendedVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNextRecommendedVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNextRecommendedVideoMutation, { data, loading, error }] = useCreateNextRecommendedVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useCreateNextRecommendedVideoMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateNextRecommendedVideoMutation, GqlClientCreateNextRecommendedVideoMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateNextRecommendedVideoMutation, GqlClientCreateNextRecommendedVideoMutationVariables>(CreateNextRecommendedVideoDocument, options);
}
export type CreateNextRecommendedVideoMutationHookResult = ReturnType<typeof useCreateNextRecommendedVideoMutation>;
export type CreateNextRecommendedVideoMutationResult = Apollo.MutationResult<GqlClientCreateNextRecommendedVideoMutation>;
export type CreateNextRecommendedVideoMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateNextRecommendedVideoMutation, GqlClientCreateNextRecommendedVideoMutationVariables>;
export const CreateOrUpdateAccountDataFieldsDocument = gql`
    mutation CreateOrUpdateAccountDataFields($input: CreateOrUpdateAccountDataFieldsInput!) {
        createOrUpdateAccountDataFields(input: $input) {
            result
            accountDataLibraryVersion {
                ...AccountDataLibraryVersion
            }
        }
    }
    ${AccountDataLibraryVersionFragmentDoc}
`;
export type GqlClientCreateOrUpdateAccountDataFieldsMutationFn = Apollo.MutationFunction<GqlClientCreateOrUpdateAccountDataFieldsMutation, GqlClientCreateOrUpdateAccountDataFieldsMutationVariables>;
export type GqlClientCreateOrUpdateAccountDataFieldsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateOrUpdateAccountDataFieldsMutation, GqlClientCreateOrUpdateAccountDataFieldsMutationVariables>;
} & TChildProps;
export function withCreateOrUpdateAccountDataFields<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateOrUpdateAccountDataFieldsMutation,
        GqlClientCreateOrUpdateAccountDataFieldsMutationVariables,
        GqlClientCreateOrUpdateAccountDataFieldsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateOrUpdateAccountDataFieldsMutation,
        GqlClientCreateOrUpdateAccountDataFieldsMutationVariables,
        GqlClientCreateOrUpdateAccountDataFieldsProps<TChildProps, TDataName>
    >(CreateOrUpdateAccountDataFieldsDocument, {
        alias: "createOrUpdateAccountDataFields",
        ...operationOptions
    });
}

/**
 * __useCreateOrUpdateAccountDataFieldsMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateAccountDataFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateAccountDataFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateAccountDataFieldsMutation, { data, loading, error }] = useCreateOrUpdateAccountDataFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateAccountDataFieldsMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateOrUpdateAccountDataFieldsMutation, GqlClientCreateOrUpdateAccountDataFieldsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateOrUpdateAccountDataFieldsMutation, GqlClientCreateOrUpdateAccountDataFieldsMutationVariables>(CreateOrUpdateAccountDataFieldsDocument, options);
}
export type CreateOrUpdateAccountDataFieldsMutationHookResult = ReturnType<typeof useCreateOrUpdateAccountDataFieldsMutation>;
export type CreateOrUpdateAccountDataFieldsMutationResult = Apollo.MutationResult<GqlClientCreateOrUpdateAccountDataFieldsMutation>;
export type CreateOrUpdateAccountDataFieldsMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateOrUpdateAccountDataFieldsMutation, GqlClientCreateOrUpdateAccountDataFieldsMutationVariables>;
export const CreateOrUpdateEmbedVideoCodeDocument = gql`
    mutation createOrUpdateEmbedVideoCode($createOrUpdateEmbedVideoCodeInput: CreateOrUpdateEmbedVideoCodeInput!) {
        createOrUpdateEmbedVideoCode(input: $createOrUpdateEmbedVideoCodeInput) {
            result
            embedVideoCode {
                id
                editorProgram {
                    id
                    embedVideoCode {
                        ...EmbedVideoCode
                    }
                }
            }
        }
    }
    ${EmbedVideoCodeFragmentDoc}
`;
export type GqlClientCreateOrUpdateEmbedVideoCodeMutationFn = Apollo.MutationFunction<GqlClientCreateOrUpdateEmbedVideoCodeMutation, GqlClientCreateOrUpdateEmbedVideoCodeMutationVariables>;
export type GqlClientCreateOrUpdateEmbedVideoCodeProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateOrUpdateEmbedVideoCodeMutation, GqlClientCreateOrUpdateEmbedVideoCodeMutationVariables>;
} & TChildProps;
export function withCreateOrUpdateEmbedVideoCode<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateOrUpdateEmbedVideoCodeMutation,
        GqlClientCreateOrUpdateEmbedVideoCodeMutationVariables,
        GqlClientCreateOrUpdateEmbedVideoCodeProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateOrUpdateEmbedVideoCodeMutation,
        GqlClientCreateOrUpdateEmbedVideoCodeMutationVariables,
        GqlClientCreateOrUpdateEmbedVideoCodeProps<TChildProps, TDataName>
    >(CreateOrUpdateEmbedVideoCodeDocument, {
        alias: "createOrUpdateEmbedVideoCode",
        ...operationOptions
    });
}

/**
 * __useCreateOrUpdateEmbedVideoCodeMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateEmbedVideoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateEmbedVideoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateEmbedVideoCodeMutation, { data, loading, error }] = useCreateOrUpdateEmbedVideoCodeMutation({
 *   variables: {
 *      createOrUpdateEmbedVideoCodeInput: // value for 'createOrUpdateEmbedVideoCodeInput'
 *   },
 * });
 */
export function useCreateOrUpdateEmbedVideoCodeMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateOrUpdateEmbedVideoCodeMutation, GqlClientCreateOrUpdateEmbedVideoCodeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateOrUpdateEmbedVideoCodeMutation, GqlClientCreateOrUpdateEmbedVideoCodeMutationVariables>(CreateOrUpdateEmbedVideoCodeDocument, options);
}
export type CreateOrUpdateEmbedVideoCodeMutationHookResult = ReturnType<typeof useCreateOrUpdateEmbedVideoCodeMutation>;
export type CreateOrUpdateEmbedVideoCodeMutationResult = Apollo.MutationResult<GqlClientCreateOrUpdateEmbedVideoCodeMutation>;
export type CreateOrUpdateEmbedVideoCodeMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateOrUpdateEmbedVideoCodeMutation, GqlClientCreateOrUpdateEmbedVideoCodeMutationVariables>;
export const CreateOrUpdateLandingPageDocument = gql`
    mutation createOrUpdateLandingPage($createOrUpdateLandingPageInput: CreateOrUpdateLandingPageInput!) {
        createOrUpdateLandingPage(input: $createOrUpdateLandingPageInput) {
            result
            landingPage {
                id
                editorProgram {
                    id
                    landingPage {
                        ...LandingPage
                    }
                }
            }
        }
    }
    ${LandingPageFragmentDoc}
`;
export type GqlClientCreateOrUpdateLandingPageMutationFn = Apollo.MutationFunction<GqlClientCreateOrUpdateLandingPageMutation, GqlClientCreateOrUpdateLandingPageMutationVariables>;
export type GqlClientCreateOrUpdateLandingPageProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateOrUpdateLandingPageMutation, GqlClientCreateOrUpdateLandingPageMutationVariables>;
} & TChildProps;
export function withCreateOrUpdateLandingPage<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateOrUpdateLandingPageMutation,
        GqlClientCreateOrUpdateLandingPageMutationVariables,
        GqlClientCreateOrUpdateLandingPageProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateOrUpdateLandingPageMutation,
        GqlClientCreateOrUpdateLandingPageMutationVariables,
        GqlClientCreateOrUpdateLandingPageProps<TChildProps, TDataName>
    >(CreateOrUpdateLandingPageDocument, {
        alias: "createOrUpdateLandingPage",
        ...operationOptions
    });
}

/**
 * __useCreateOrUpdateLandingPageMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateLandingPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateLandingPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateLandingPageMutation, { data, loading, error }] = useCreateOrUpdateLandingPageMutation({
 *   variables: {
 *      createOrUpdateLandingPageInput: // value for 'createOrUpdateLandingPageInput'
 *   },
 * });
 */
export function useCreateOrUpdateLandingPageMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateOrUpdateLandingPageMutation, GqlClientCreateOrUpdateLandingPageMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateOrUpdateLandingPageMutation, GqlClientCreateOrUpdateLandingPageMutationVariables>(CreateOrUpdateLandingPageDocument, options);
}
export type CreateOrUpdateLandingPageMutationHookResult = ReturnType<typeof useCreateOrUpdateLandingPageMutation>;
export type CreateOrUpdateLandingPageMutationResult = Apollo.MutationResult<GqlClientCreateOrUpdateLandingPageMutation>;
export type CreateOrUpdateLandingPageMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateOrUpdateLandingPageMutation, GqlClientCreateOrUpdateLandingPageMutationVariables>;
export const CreatePreSubmittedAvatarDocument = gql`
    mutation createPreSubmittedAvatar {
        createPreSubmittedAvatar {
            result
            avatarId
        }
    }
`;
export type GqlClientCreatePreSubmittedAvatarMutationFn = Apollo.MutationFunction<GqlClientCreatePreSubmittedAvatarMutation, GqlClientCreatePreSubmittedAvatarMutationVariables>;
export type GqlClientCreatePreSubmittedAvatarProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreatePreSubmittedAvatarMutation, GqlClientCreatePreSubmittedAvatarMutationVariables>;
} & TChildProps;
export function withCreatePreSubmittedAvatar<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreatePreSubmittedAvatarMutation,
        GqlClientCreatePreSubmittedAvatarMutationVariables,
        GqlClientCreatePreSubmittedAvatarProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreatePreSubmittedAvatarMutation,
        GqlClientCreatePreSubmittedAvatarMutationVariables,
        GqlClientCreatePreSubmittedAvatarProps<TChildProps, TDataName>
    >(CreatePreSubmittedAvatarDocument, {
        alias: "createPreSubmittedAvatar",
        ...operationOptions
    });
}

/**
 * __useCreatePreSubmittedAvatarMutation__
 *
 * To run a mutation, you first call `useCreatePreSubmittedAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreSubmittedAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreSubmittedAvatarMutation, { data, loading, error }] = useCreatePreSubmittedAvatarMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePreSubmittedAvatarMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreatePreSubmittedAvatarMutation, GqlClientCreatePreSubmittedAvatarMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreatePreSubmittedAvatarMutation, GqlClientCreatePreSubmittedAvatarMutationVariables>(CreatePreSubmittedAvatarDocument, options);
}
export type CreatePreSubmittedAvatarMutationHookResult = ReturnType<typeof useCreatePreSubmittedAvatarMutation>;
export type CreatePreSubmittedAvatarMutationResult = Apollo.MutationResult<GqlClientCreatePreSubmittedAvatarMutation>;
export type CreatePreSubmittedAvatarMutationOptions = Apollo.BaseMutationOptions<GqlClientCreatePreSubmittedAvatarMutation, GqlClientCreatePreSubmittedAvatarMutationVariables>;
export const CreateProgramReviewDocument = gql`
    mutation CreateProgramReview($input: CreateProgramReviewInput!) {
        createProgramReview(input: $input) {
            result
            programReview {
                id
            }
        }
    }
`;
export type GqlClientCreateProgramReviewMutationFn = Apollo.MutationFunction<GqlClientCreateProgramReviewMutation, GqlClientCreateProgramReviewMutationVariables>;
export type GqlClientCreateProgramReviewProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateProgramReviewMutation, GqlClientCreateProgramReviewMutationVariables>;
} & TChildProps;
export function withCreateProgramReview<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateProgramReviewMutation,
        GqlClientCreateProgramReviewMutationVariables,
        GqlClientCreateProgramReviewProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateProgramReviewMutation, GqlClientCreateProgramReviewMutationVariables, GqlClientCreateProgramReviewProps<TChildProps, TDataName>>(
        CreateProgramReviewDocument,
        {
            alias: "createProgramReview",
            ...operationOptions
        }
    );
}

/**
 * __useCreateProgramReviewMutation__
 *
 * To run a mutation, you first call `useCreateProgramReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramReviewMutation, { data, loading, error }] = useCreateProgramReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgramReviewMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateProgramReviewMutation, GqlClientCreateProgramReviewMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateProgramReviewMutation, GqlClientCreateProgramReviewMutationVariables>(CreateProgramReviewDocument, options);
}
export type CreateProgramReviewMutationHookResult = ReturnType<typeof useCreateProgramReviewMutation>;
export type CreateProgramReviewMutationResult = Apollo.MutationResult<GqlClientCreateProgramReviewMutation>;
export type CreateProgramReviewMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateProgramReviewMutation, GqlClientCreateProgramReviewMutationVariables>;
export const CreatePronunciationDefinitionDocument = gql`
    mutation createPronunciationDefinition($input: CreatePronunciationDefinitionInput!) {
        createPronunciationDefinition(input: $input) {
            pronunciationDefinition {
                ...AccountPronunciationDefinition
                pronunciationLibraryVersion {
                    id
                    anyUpdated
                    anyUpdatedBy
                    definitions {
                        id
                    }
                }
            }
        }
    }
    ${AccountPronunciationDefinitionFragmentDoc}
`;
export type GqlClientCreatePronunciationDefinitionMutationFn = Apollo.MutationFunction<GqlClientCreatePronunciationDefinitionMutation, GqlClientCreatePronunciationDefinitionMutationVariables>;
export type GqlClientCreatePronunciationDefinitionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreatePronunciationDefinitionMutation, GqlClientCreatePronunciationDefinitionMutationVariables>;
} & TChildProps;
export function withCreatePronunciationDefinition<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreatePronunciationDefinitionMutation,
        GqlClientCreatePronunciationDefinitionMutationVariables,
        GqlClientCreatePronunciationDefinitionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreatePronunciationDefinitionMutation,
        GqlClientCreatePronunciationDefinitionMutationVariables,
        GqlClientCreatePronunciationDefinitionProps<TChildProps, TDataName>
    >(CreatePronunciationDefinitionDocument, {
        alias: "createPronunciationDefinition",
        ...operationOptions
    });
}

/**
 * __useCreatePronunciationDefinitionMutation__
 *
 * To run a mutation, you first call `useCreatePronunciationDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePronunciationDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPronunciationDefinitionMutation, { data, loading, error }] = useCreatePronunciationDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePronunciationDefinitionMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreatePronunciationDefinitionMutation, GqlClientCreatePronunciationDefinitionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreatePronunciationDefinitionMutation, GqlClientCreatePronunciationDefinitionMutationVariables>(CreatePronunciationDefinitionDocument, options);
}
export type CreatePronunciationDefinitionMutationHookResult = ReturnType<typeof useCreatePronunciationDefinitionMutation>;
export type CreatePronunciationDefinitionMutationResult = Apollo.MutationResult<GqlClientCreatePronunciationDefinitionMutation>;
export type CreatePronunciationDefinitionMutationOptions = Apollo.BaseMutationOptions<GqlClientCreatePronunciationDefinitionMutation, GqlClientCreatePronunciationDefinitionMutationVariables>;
export const CreateShareHistoryRecordDocument = gql`
    mutation CreateShareHistoryRecord($input: CreateShareHistoryRecordInput!) {
        createShareHistoryRecord(input: $input) {
            shareHistoryRecord {
                id
                programVersion {
                    id
                }
                shareType
                created
                createdBy
            }
        }
    }
`;
export type GqlClientCreateShareHistoryRecordMutationFn = Apollo.MutationFunction<GqlClientCreateShareHistoryRecordMutation, GqlClientCreateShareHistoryRecordMutationVariables>;
export type GqlClientCreateShareHistoryRecordProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateShareHistoryRecordMutation, GqlClientCreateShareHistoryRecordMutationVariables>;
} & TChildProps;
export function withCreateShareHistoryRecord<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateShareHistoryRecordMutation,
        GqlClientCreateShareHistoryRecordMutationVariables,
        GqlClientCreateShareHistoryRecordProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateShareHistoryRecordMutation,
        GqlClientCreateShareHistoryRecordMutationVariables,
        GqlClientCreateShareHistoryRecordProps<TChildProps, TDataName>
    >(CreateShareHistoryRecordDocument, {
        alias: "createShareHistoryRecord",
        ...operationOptions
    });
}

/**
 * __useCreateShareHistoryRecordMutation__
 *
 * To run a mutation, you first call `useCreateShareHistoryRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShareHistoryRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShareHistoryRecordMutation, { data, loading, error }] = useCreateShareHistoryRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShareHistoryRecordMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateShareHistoryRecordMutation, GqlClientCreateShareHistoryRecordMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateShareHistoryRecordMutation, GqlClientCreateShareHistoryRecordMutationVariables>(CreateShareHistoryRecordDocument, options);
}
export type CreateShareHistoryRecordMutationHookResult = ReturnType<typeof useCreateShareHistoryRecordMutation>;
export type CreateShareHistoryRecordMutationResult = Apollo.MutationResult<GqlClientCreateShareHistoryRecordMutation>;
export type CreateShareHistoryRecordMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateShareHistoryRecordMutation, GqlClientCreateShareHistoryRecordMutationVariables>;
export const CustomAvatarsCreditBalanceEmailDocument = gql`
    mutation customAvatarsCreditBalanceEmail($input: CustomAvatarsCreditBalanceEmailInput!) {
        customAvatarsCreditBalanceEmail(input: $input) {
            result
        }
    }
`;
export type GqlClientCustomAvatarsCreditBalanceEmailMutationFn = Apollo.MutationFunction<GqlClientCustomAvatarsCreditBalanceEmailMutation, GqlClientCustomAvatarsCreditBalanceEmailMutationVariables>;
export type GqlClientCustomAvatarsCreditBalanceEmailProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCustomAvatarsCreditBalanceEmailMutation, GqlClientCustomAvatarsCreditBalanceEmailMutationVariables>;
} & TChildProps;
export function withCustomAvatarsCreditBalanceEmail<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCustomAvatarsCreditBalanceEmailMutation,
        GqlClientCustomAvatarsCreditBalanceEmailMutationVariables,
        GqlClientCustomAvatarsCreditBalanceEmailProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCustomAvatarsCreditBalanceEmailMutation,
        GqlClientCustomAvatarsCreditBalanceEmailMutationVariables,
        GqlClientCustomAvatarsCreditBalanceEmailProps<TChildProps, TDataName>
    >(CustomAvatarsCreditBalanceEmailDocument, {
        alias: "customAvatarsCreditBalanceEmail",
        ...operationOptions
    });
}

/**
 * __useCustomAvatarsCreditBalanceEmailMutation__
 *
 * To run a mutation, you first call `useCustomAvatarsCreditBalanceEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomAvatarsCreditBalanceEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customAvatarsCreditBalanceEmailMutation, { data, loading, error }] = useCustomAvatarsCreditBalanceEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomAvatarsCreditBalanceEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCustomAvatarsCreditBalanceEmailMutation, GqlClientCustomAvatarsCreditBalanceEmailMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCustomAvatarsCreditBalanceEmailMutation, GqlClientCustomAvatarsCreditBalanceEmailMutationVariables>(CustomAvatarsCreditBalanceEmailDocument, options);
}
export type CustomAvatarsCreditBalanceEmailMutationHookResult = ReturnType<typeof useCustomAvatarsCreditBalanceEmailMutation>;
export type CustomAvatarsCreditBalanceEmailMutationResult = Apollo.MutationResult<GqlClientCustomAvatarsCreditBalanceEmailMutation>;
export type CustomAvatarsCreditBalanceEmailMutationOptions = Apollo.BaseMutationOptions<GqlClientCustomAvatarsCreditBalanceEmailMutation, GqlClientCustomAvatarsCreditBalanceEmailMutationVariables>;
export const DataLibraryImportDataDocument = gql`
    mutation dataLibraryImportData($input: DataLibraryImportDataInput!, $mutationId: String!) {
        dataLibraryImportData(input: $input, mutationId: $mutationId) {
            result
            importDataId
            dataLibraryId
        }
    }
`;
export type GqlClientDataLibraryImportDataMutationFn = Apollo.MutationFunction<GqlClientDataLibraryImportDataMutation, GqlClientDataLibraryImportDataMutationVariables>;
export type GqlClientDataLibraryImportDataProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDataLibraryImportDataMutation, GqlClientDataLibraryImportDataMutationVariables>;
} & TChildProps;
export function withDataLibraryImportData<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDataLibraryImportDataMutation,
        GqlClientDataLibraryImportDataMutationVariables,
        GqlClientDataLibraryImportDataProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDataLibraryImportDataMutation, GqlClientDataLibraryImportDataMutationVariables, GqlClientDataLibraryImportDataProps<TChildProps, TDataName>>(
        DataLibraryImportDataDocument,
        {
            alias: "dataLibraryImportData",
            ...operationOptions
        }
    );
}

/**
 * __useDataLibraryImportDataMutation__
 *
 * To run a mutation, you first call `useDataLibraryImportDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDataLibraryImportDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dataLibraryImportDataMutation, { data, loading, error }] = useDataLibraryImportDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useDataLibraryImportDataMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDataLibraryImportDataMutation, GqlClientDataLibraryImportDataMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDataLibraryImportDataMutation, GqlClientDataLibraryImportDataMutationVariables>(DataLibraryImportDataDocument, options);
}
export type DataLibraryImportDataMutationHookResult = ReturnType<typeof useDataLibraryImportDataMutation>;
export type DataLibraryImportDataMutationResult = Apollo.MutationResult<GqlClientDataLibraryImportDataMutation>;
export type DataLibraryImportDataMutationOptions = Apollo.BaseMutationOptions<GqlClientDataLibraryImportDataMutation, GqlClientDataLibraryImportDataMutationVariables>;
export const DeleteAccountCopilotBriefDocument = gql`
    mutation deleteAccountCopilotBrief($input: DeleteAccountCopilotBriefInput!) {
        deleteAccountCopilotBrief(input: $input) {
            result
            account {
                id
                copilotUserBriefs {
                    id
                }
            }
        }
    }
`;
export type GqlClientDeleteAccountCopilotBriefMutationFn = Apollo.MutationFunction<GqlClientDeleteAccountCopilotBriefMutation, GqlClientDeleteAccountCopilotBriefMutationVariables>;
export type GqlClientDeleteAccountCopilotBriefProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteAccountCopilotBriefMutation, GqlClientDeleteAccountCopilotBriefMutationVariables>;
} & TChildProps;
export function withDeleteAccountCopilotBrief<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteAccountCopilotBriefMutation,
        GqlClientDeleteAccountCopilotBriefMutationVariables,
        GqlClientDeleteAccountCopilotBriefProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteAccountCopilotBriefMutation,
        GqlClientDeleteAccountCopilotBriefMutationVariables,
        GqlClientDeleteAccountCopilotBriefProps<TChildProps, TDataName>
    >(DeleteAccountCopilotBriefDocument, {
        alias: "deleteAccountCopilotBrief",
        ...operationOptions
    });
}

/**
 * __useDeleteAccountCopilotBriefMutation__
 *
 * To run a mutation, you first call `useDeleteAccountCopilotBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountCopilotBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountCopilotBriefMutation, { data, loading, error }] = useDeleteAccountCopilotBriefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountCopilotBriefMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteAccountCopilotBriefMutation, GqlClientDeleteAccountCopilotBriefMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteAccountCopilotBriefMutation, GqlClientDeleteAccountCopilotBriefMutationVariables>(DeleteAccountCopilotBriefDocument, options);
}
export type DeleteAccountCopilotBriefMutationHookResult = ReturnType<typeof useDeleteAccountCopilotBriefMutation>;
export type DeleteAccountCopilotBriefMutationResult = Apollo.MutationResult<GqlClientDeleteAccountCopilotBriefMutation>;
export type DeleteAccountCopilotBriefMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteAccountCopilotBriefMutation, GqlClientDeleteAccountCopilotBriefMutationVariables>;
export const DeleteAccountProgramFolderDocument = gql`
    mutation DeleteAccountProgramFolder($input: DeleteAccountProgramFolderInput!) {
        deleteAccountProgramFolder(input: $input) {
            result
            account {
                id
                folders {
                    id
                    parentFolder {
                        id
                    }
                }
                builderPrograms {
                    id
                    folder {
                        id
                    }
                }
                editorPrograms {
                    id
                    folder {
                        id
                    }
                }
            }
        }
    }
`;
export type GqlClientDeleteAccountProgramFolderMutationFn = Apollo.MutationFunction<GqlClientDeleteAccountProgramFolderMutation, GqlClientDeleteAccountProgramFolderMutationVariables>;
export type GqlClientDeleteAccountProgramFolderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteAccountProgramFolderMutation, GqlClientDeleteAccountProgramFolderMutationVariables>;
} & TChildProps;
export function withDeleteAccountProgramFolder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteAccountProgramFolderMutation,
        GqlClientDeleteAccountProgramFolderMutationVariables,
        GqlClientDeleteAccountProgramFolderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteAccountProgramFolderMutation,
        GqlClientDeleteAccountProgramFolderMutationVariables,
        GqlClientDeleteAccountProgramFolderProps<TChildProps, TDataName>
    >(DeleteAccountProgramFolderDocument, {
        alias: "deleteAccountProgramFolder",
        ...operationOptions
    });
}

/**
 * __useDeleteAccountProgramFolderMutation__
 *
 * To run a mutation, you first call `useDeleteAccountProgramFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountProgramFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountProgramFolderMutation, { data, loading, error }] = useDeleteAccountProgramFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountProgramFolderMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteAccountProgramFolderMutation, GqlClientDeleteAccountProgramFolderMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteAccountProgramFolderMutation, GqlClientDeleteAccountProgramFolderMutationVariables>(DeleteAccountProgramFolderDocument, options);
}
export type DeleteAccountProgramFolderMutationHookResult = ReturnType<typeof useDeleteAccountProgramFolderMutation>;
export type DeleteAccountProgramFolderMutationResult = Apollo.MutationResult<GqlClientDeleteAccountProgramFolderMutation>;
export type DeleteAccountProgramFolderMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteAccountProgramFolderMutation, GqlClientDeleteAccountProgramFolderMutationVariables>;
export const DeleteBrandCollectionDocument = gql`
    mutation DeleteBrandCollection($input: DeleteBrandCollectionInput!) {
        deleteBrandCollection(input: $input) {
            result
            brandConfigurationVersion {
                id
                brandConfigurationCollections {
                    ...EditorBrand
                }
                defaultBrandConfigurationCollection {
                    id
                }
            }
        }
    }
    ${EditorBrandFragmentDoc}
`;
export type GqlClientDeleteBrandCollectionMutationFn = Apollo.MutationFunction<GqlClientDeleteBrandCollectionMutation, GqlClientDeleteBrandCollectionMutationVariables>;
export type GqlClientDeleteBrandCollectionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteBrandCollectionMutation, GqlClientDeleteBrandCollectionMutationVariables>;
} & TChildProps;
export function withDeleteBrandCollection<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteBrandCollectionMutation,
        GqlClientDeleteBrandCollectionMutationVariables,
        GqlClientDeleteBrandCollectionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteBrandCollectionMutation, GqlClientDeleteBrandCollectionMutationVariables, GqlClientDeleteBrandCollectionProps<TChildProps, TDataName>>(
        DeleteBrandCollectionDocument,
        {
            alias: "deleteBrandCollection",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteBrandCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteBrandCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBrandCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBrandCollectionMutation, { data, loading, error }] = useDeleteBrandCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBrandCollectionMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteBrandCollectionMutation, GqlClientDeleteBrandCollectionMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteBrandCollectionMutation, GqlClientDeleteBrandCollectionMutationVariables>(DeleteBrandCollectionDocument, options);
}
export type DeleteBrandCollectionMutationHookResult = ReturnType<typeof useDeleteBrandCollectionMutation>;
export type DeleteBrandCollectionMutationResult = Apollo.MutationResult<GqlClientDeleteBrandCollectionMutation>;
export type DeleteBrandCollectionMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteBrandCollectionMutation, GqlClientDeleteBrandCollectionMutationVariables>;
export const DeleteDataLibraryDocument = gql`
    mutation deleteDataLibrary($input: DeleteDataLibraryInput!) {
        deleteDataLibrary(input: $input) {
            result
            account {
                id
                dataLibraries {
                    id
                }
            }
        }
    }
`;
export type GqlClientDeleteDataLibraryMutationFn = Apollo.MutationFunction<GqlClientDeleteDataLibraryMutation, GqlClientDeleteDataLibraryMutationVariables>;
export type GqlClientDeleteDataLibraryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteDataLibraryMutation, GqlClientDeleteDataLibraryMutationVariables>;
} & TChildProps;
export function withDeleteDataLibrary<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteDataLibraryMutation, GqlClientDeleteDataLibraryMutationVariables, GqlClientDeleteDataLibraryProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteDataLibraryMutation, GqlClientDeleteDataLibraryMutationVariables, GqlClientDeleteDataLibraryProps<TChildProps, TDataName>>(
        DeleteDataLibraryDocument,
        {
            alias: "deleteDataLibrary",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteDataLibraryMutation__
 *
 * To run a mutation, you first call `useDeleteDataLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataLibraryMutation, { data, loading, error }] = useDeleteDataLibraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDataLibraryMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteDataLibraryMutation, GqlClientDeleteDataLibraryMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteDataLibraryMutation, GqlClientDeleteDataLibraryMutationVariables>(DeleteDataLibraryDocument, options);
}
export type DeleteDataLibraryMutationHookResult = ReturnType<typeof useDeleteDataLibraryMutation>;
export type DeleteDataLibraryMutationResult = Apollo.MutationResult<GqlClientDeleteDataLibraryMutation>;
export type DeleteDataLibraryMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteDataLibraryMutation, GqlClientDeleteDataLibraryMutationVariables>;
export const DeleteEditorAccountUserDocument = gql`
    mutation DeleteEditorAccountUser($input: DeleteEditorAccountUserInput!) {
        deleteEditorAccountUser(input: $input) {
            result
        }
    }
`;
export type GqlClientDeleteEditorAccountUserMutationFn = Apollo.MutationFunction<GqlClientDeleteEditorAccountUserMutation, GqlClientDeleteEditorAccountUserMutationVariables>;
export type GqlClientDeleteEditorAccountUserProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteEditorAccountUserMutation, GqlClientDeleteEditorAccountUserMutationVariables>;
} & TChildProps;
export function withDeleteEditorAccountUser<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteEditorAccountUserMutation,
        GqlClientDeleteEditorAccountUserMutationVariables,
        GqlClientDeleteEditorAccountUserProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteEditorAccountUserMutation,
        GqlClientDeleteEditorAccountUserMutationVariables,
        GqlClientDeleteEditorAccountUserProps<TChildProps, TDataName>
    >(DeleteEditorAccountUserDocument, {
        alias: "deleteEditorAccountUser",
        ...operationOptions
    });
}

/**
 * __useDeleteEditorAccountUserMutation__
 *
 * To run a mutation, you first call `useDeleteEditorAccountUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEditorAccountUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEditorAccountUserMutation, { data, loading, error }] = useDeleteEditorAccountUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEditorAccountUserMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteEditorAccountUserMutation, GqlClientDeleteEditorAccountUserMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteEditorAccountUserMutation, GqlClientDeleteEditorAccountUserMutationVariables>(DeleteEditorAccountUserDocument, options);
}
export type DeleteEditorAccountUserMutationHookResult = ReturnType<typeof useDeleteEditorAccountUserMutation>;
export type DeleteEditorAccountUserMutationResult = Apollo.MutationResult<GqlClientDeleteEditorAccountUserMutation>;
export type DeleteEditorAccountUserMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteEditorAccountUserMutation, GqlClientDeleteEditorAccountUserMutationVariables>;
export const DeleteEditorAssetFolderDocument = gql`
    mutation deleteEditorAssetFolder($input: DeleteEditorAssetFolderInput!) {
        deleteEditorAssetFolder(input: $input) {
            result
            ... on DeleteEditorAssetFolderOutputSuccess {
                editorAssetLibraryVersion {
                    id
                    media {
                        id
                        lifecycleStage
                    }
                    mediaFolders {
                        ...EditorAssetFolder
                    }
                }
            }
        }
    }
    ${EditorAssetFolderFragmentDoc}
`;
export type GqlClientDeleteEditorAssetFolderMutationFn = Apollo.MutationFunction<GqlClientDeleteEditorAssetFolderMutation, GqlClientDeleteEditorAssetFolderMutationVariables>;
export type GqlClientDeleteEditorAssetFolderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteEditorAssetFolderMutation, GqlClientDeleteEditorAssetFolderMutationVariables>;
} & TChildProps;
export function withDeleteEditorAssetFolder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteEditorAssetFolderMutation,
        GqlClientDeleteEditorAssetFolderMutationVariables,
        GqlClientDeleteEditorAssetFolderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteEditorAssetFolderMutation,
        GqlClientDeleteEditorAssetFolderMutationVariables,
        GqlClientDeleteEditorAssetFolderProps<TChildProps, TDataName>
    >(DeleteEditorAssetFolderDocument, {
        alias: "deleteEditorAssetFolder",
        ...operationOptions
    });
}

/**
 * __useDeleteEditorAssetFolderMutation__
 *
 * To run a mutation, you first call `useDeleteEditorAssetFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEditorAssetFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEditorAssetFolderMutation, { data, loading, error }] = useDeleteEditorAssetFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEditorAssetFolderMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteEditorAssetFolderMutation, GqlClientDeleteEditorAssetFolderMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteEditorAssetFolderMutation, GqlClientDeleteEditorAssetFolderMutationVariables>(DeleteEditorAssetFolderDocument, options);
}
export type DeleteEditorAssetFolderMutationHookResult = ReturnType<typeof useDeleteEditorAssetFolderMutation>;
export type DeleteEditorAssetFolderMutationResult = Apollo.MutationResult<GqlClientDeleteEditorAssetFolderMutation>;
export type DeleteEditorAssetFolderMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteEditorAssetFolderMutation, GqlClientDeleteEditorAssetFolderMutationVariables>;
export const DeleteEditorFlexiblePlaceholderDocument = gql`
    mutation DeleteEditorFlexiblePlaceholder($input: DeleteEditorFlexiblePlaceholderInput!) {
        deleteEditorFlexiblePlaceholder(input: $input) {
            result
            editorScenes {
                id
                placeholders {
                    ...EditorPlaceholder
                }
            }
        }
    }
    ${EditorPlaceholderFragmentDoc}
`;
export type GqlClientDeleteEditorFlexiblePlaceholderMutationFn = Apollo.MutationFunction<GqlClientDeleteEditorFlexiblePlaceholderMutation, GqlClientDeleteEditorFlexiblePlaceholderMutationVariables>;
export type GqlClientDeleteEditorFlexiblePlaceholderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteEditorFlexiblePlaceholderMutation, GqlClientDeleteEditorFlexiblePlaceholderMutationVariables>;
} & TChildProps;
export function withDeleteEditorFlexiblePlaceholder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteEditorFlexiblePlaceholderMutation,
        GqlClientDeleteEditorFlexiblePlaceholderMutationVariables,
        GqlClientDeleteEditorFlexiblePlaceholderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteEditorFlexiblePlaceholderMutation,
        GqlClientDeleteEditorFlexiblePlaceholderMutationVariables,
        GqlClientDeleteEditorFlexiblePlaceholderProps<TChildProps, TDataName>
    >(DeleteEditorFlexiblePlaceholderDocument, {
        alias: "deleteEditorFlexiblePlaceholder",
        ...operationOptions
    });
}

/**
 * __useDeleteEditorFlexiblePlaceholderMutation__
 *
 * To run a mutation, you first call `useDeleteEditorFlexiblePlaceholderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEditorFlexiblePlaceholderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEditorFlexiblePlaceholderMutation, { data, loading, error }] = useDeleteEditorFlexiblePlaceholderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEditorFlexiblePlaceholderMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteEditorFlexiblePlaceholderMutation, GqlClientDeleteEditorFlexiblePlaceholderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteEditorFlexiblePlaceholderMutation, GqlClientDeleteEditorFlexiblePlaceholderMutationVariables>(DeleteEditorFlexiblePlaceholderDocument, options);
}
export type DeleteEditorFlexiblePlaceholderMutationHookResult = ReturnType<typeof useDeleteEditorFlexiblePlaceholderMutation>;
export type DeleteEditorFlexiblePlaceholderMutationResult = Apollo.MutationResult<GqlClientDeleteEditorFlexiblePlaceholderMutation>;
export type DeleteEditorFlexiblePlaceholderMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteEditorFlexiblePlaceholderMutation, GqlClientDeleteEditorFlexiblePlaceholderMutationVariables>;
export const DeleteEditorLibraryAssetMediaDocument = gql`
    mutation deleteEditorLibraryAssetMedia($input: DeleteEditorAssetMediaInput!) {
        deleteEditorAssetMedia(input: $input) {
            result
            ... on DeleteEditorAssetMediaOutputSuccess {
                editorAssetLibraryVersion {
                    id
                    media {
                        id
                        lifecycleStage
                    }
                }
            }
        }
    }
`;
export type GqlClientDeleteEditorLibraryAssetMediaMutationFn = Apollo.MutationFunction<GqlClientDeleteEditorLibraryAssetMediaMutation, GqlClientDeleteEditorLibraryAssetMediaMutationVariables>;
export type GqlClientDeleteEditorLibraryAssetMediaProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteEditorLibraryAssetMediaMutation, GqlClientDeleteEditorLibraryAssetMediaMutationVariables>;
} & TChildProps;
export function withDeleteEditorLibraryAssetMedia<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteEditorLibraryAssetMediaMutation,
        GqlClientDeleteEditorLibraryAssetMediaMutationVariables,
        GqlClientDeleteEditorLibraryAssetMediaProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteEditorLibraryAssetMediaMutation,
        GqlClientDeleteEditorLibraryAssetMediaMutationVariables,
        GqlClientDeleteEditorLibraryAssetMediaProps<TChildProps, TDataName>
    >(DeleteEditorLibraryAssetMediaDocument, {
        alias: "deleteEditorLibraryAssetMedia",
        ...operationOptions
    });
}

/**
 * __useDeleteEditorLibraryAssetMediaMutation__
 *
 * To run a mutation, you first call `useDeleteEditorLibraryAssetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEditorLibraryAssetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEditorLibraryAssetMediaMutation, { data, loading, error }] = useDeleteEditorLibraryAssetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEditorLibraryAssetMediaMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteEditorLibraryAssetMediaMutation, GqlClientDeleteEditorLibraryAssetMediaMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteEditorLibraryAssetMediaMutation, GqlClientDeleteEditorLibraryAssetMediaMutationVariables>(DeleteEditorLibraryAssetMediaDocument, options);
}
export type DeleteEditorLibraryAssetMediaMutationHookResult = ReturnType<typeof useDeleteEditorLibraryAssetMediaMutation>;
export type DeleteEditorLibraryAssetMediaMutationResult = Apollo.MutationResult<GqlClientDeleteEditorLibraryAssetMediaMutation>;
export type DeleteEditorLibraryAssetMediaMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteEditorLibraryAssetMediaMutation, GqlClientDeleteEditorLibraryAssetMediaMutationVariables>;
export const DeleteEditorProgramAllVersionsDocument = gql`
    mutation deleteEditorProgramAllVersions($deleteEditorProgramAllVersionsInput: DeleteEditorProgramAllVersionsInput!) {
        deleteEditorProgramAllVersions(input: $deleteEditorProgramAllVersionsInput) {
            result
        }
    }
`;
export type GqlClientDeleteEditorProgramAllVersionsMutationFn = Apollo.MutationFunction<GqlClientDeleteEditorProgramAllVersionsMutation, GqlClientDeleteEditorProgramAllVersionsMutationVariables>;
export type GqlClientDeleteEditorProgramAllVersionsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteEditorProgramAllVersionsMutation, GqlClientDeleteEditorProgramAllVersionsMutationVariables>;
} & TChildProps;
export function withDeleteEditorProgramAllVersions<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteEditorProgramAllVersionsMutation,
        GqlClientDeleteEditorProgramAllVersionsMutationVariables,
        GqlClientDeleteEditorProgramAllVersionsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteEditorProgramAllVersionsMutation,
        GqlClientDeleteEditorProgramAllVersionsMutationVariables,
        GqlClientDeleteEditorProgramAllVersionsProps<TChildProps, TDataName>
    >(DeleteEditorProgramAllVersionsDocument, {
        alias: "deleteEditorProgramAllVersions",
        ...operationOptions
    });
}

/**
 * __useDeleteEditorProgramAllVersionsMutation__
 *
 * To run a mutation, you first call `useDeleteEditorProgramAllVersionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEditorProgramAllVersionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEditorProgramAllVersionsMutation, { data, loading, error }] = useDeleteEditorProgramAllVersionsMutation({
 *   variables: {
 *      deleteEditorProgramAllVersionsInput: // value for 'deleteEditorProgramAllVersionsInput'
 *   },
 * });
 */
export function useDeleteEditorProgramAllVersionsMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteEditorProgramAllVersionsMutation, GqlClientDeleteEditorProgramAllVersionsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteEditorProgramAllVersionsMutation, GqlClientDeleteEditorProgramAllVersionsMutationVariables>(DeleteEditorProgramAllVersionsDocument, options);
}
export type DeleteEditorProgramAllVersionsMutationHookResult = ReturnType<typeof useDeleteEditorProgramAllVersionsMutation>;
export type DeleteEditorProgramAllVersionsMutationResult = Apollo.MutationResult<GqlClientDeleteEditorProgramAllVersionsMutation>;
export type DeleteEditorProgramAllVersionsMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteEditorProgramAllVersionsMutation, GqlClientDeleteEditorProgramAllVersionsMutationVariables>;
export const DeleteEditorSceneDocument = gql`
    mutation DeleteEditorScene($input: DeleteEditorSceneInput!) {
        deleteEditorScene(input: $input) {
            result
            editorProgramVersion {
                id
                sceneLineup
                scenes {
                    id
                    placeholders {
                        ...EditorPlaceholderConfiguration
                    }
                }
            }
        }
    }
    ${EditorPlaceholderConfigurationFragmentDoc}
`;
export type GqlClientDeleteEditorSceneMutationFn = Apollo.MutationFunction<GqlClientDeleteEditorSceneMutation, GqlClientDeleteEditorSceneMutationVariables>;
export type GqlClientDeleteEditorSceneProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteEditorSceneMutation, GqlClientDeleteEditorSceneMutationVariables>;
} & TChildProps;
export function withDeleteEditorScene<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteEditorSceneMutation, GqlClientDeleteEditorSceneMutationVariables, GqlClientDeleteEditorSceneProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteEditorSceneMutation, GqlClientDeleteEditorSceneMutationVariables, GqlClientDeleteEditorSceneProps<TChildProps, TDataName>>(
        DeleteEditorSceneDocument,
        {
            alias: "deleteEditorScene",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteEditorSceneMutation__
 *
 * To run a mutation, you first call `useDeleteEditorSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEditorSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEditorSceneMutation, { data, loading, error }] = useDeleteEditorSceneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEditorSceneMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteEditorSceneMutation, GqlClientDeleteEditorSceneMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteEditorSceneMutation, GqlClientDeleteEditorSceneMutationVariables>(DeleteEditorSceneDocument, options);
}
export type DeleteEditorSceneMutationHookResult = ReturnType<typeof useDeleteEditorSceneMutation>;
export type DeleteEditorSceneMutationResult = Apollo.MutationResult<GqlClientDeleteEditorSceneMutation>;
export type DeleteEditorSceneMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteEditorSceneMutation, GqlClientDeleteEditorSceneMutationVariables>;
export const DeleteEditorViewerProfileDocument = gql`
    mutation deleteEditorViewerProfile($input: DeleteEditorViewerProfileInput!) {
        deleteEditorViewerProfile(input: $input) {
            result
            editorProgram {
                id
                viewerProfiles {
                    ...EditorViewerProfile
                }
            }
        }
    }
    ${EditorViewerProfileFragmentDoc}
`;
export type GqlClientDeleteEditorViewerProfileMutationFn = Apollo.MutationFunction<GqlClientDeleteEditorViewerProfileMutation, GqlClientDeleteEditorViewerProfileMutationVariables>;
export type GqlClientDeleteEditorViewerProfileProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteEditorViewerProfileMutation, GqlClientDeleteEditorViewerProfileMutationVariables>;
} & TChildProps;
export function withDeleteEditorViewerProfile<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteEditorViewerProfileMutation,
        GqlClientDeleteEditorViewerProfileMutationVariables,
        GqlClientDeleteEditorViewerProfileProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteEditorViewerProfileMutation,
        GqlClientDeleteEditorViewerProfileMutationVariables,
        GqlClientDeleteEditorViewerProfileProps<TChildProps, TDataName>
    >(DeleteEditorViewerProfileDocument, {
        alias: "deleteEditorViewerProfile",
        ...operationOptions
    });
}

/**
 * __useDeleteEditorViewerProfileMutation__
 *
 * To run a mutation, you first call `useDeleteEditorViewerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEditorViewerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEditorViewerProfileMutation, { data, loading, error }] = useDeleteEditorViewerProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEditorViewerProfileMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteEditorViewerProfileMutation, GqlClientDeleteEditorViewerProfileMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteEditorViewerProfileMutation, GqlClientDeleteEditorViewerProfileMutationVariables>(DeleteEditorViewerProfileDocument, options);
}
export type DeleteEditorViewerProfileMutationHookResult = ReturnType<typeof useDeleteEditorViewerProfileMutation>;
export type DeleteEditorViewerProfileMutationResult = Apollo.MutationResult<GqlClientDeleteEditorViewerProfileMutation>;
export type DeleteEditorViewerProfileMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteEditorViewerProfileMutation, GqlClientDeleteEditorViewerProfileMutationVariables>;
export const DeleteFailedAvatarDocument = gql`
    mutation deleteFailedAvatar($deleteFailedAvatar: DeleteFailedAvatarInput!) {
        deleteFailedAvatar(input: $deleteFailedAvatar) {
            result
        }
    }
`;
export type GqlClientDeleteFailedAvatarMutationFn = Apollo.MutationFunction<GqlClientDeleteFailedAvatarMutation, GqlClientDeleteFailedAvatarMutationVariables>;
export type GqlClientDeleteFailedAvatarProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteFailedAvatarMutation, GqlClientDeleteFailedAvatarMutationVariables>;
} & TChildProps;
export function withDeleteFailedAvatar<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteFailedAvatarMutation,
        GqlClientDeleteFailedAvatarMutationVariables,
        GqlClientDeleteFailedAvatarProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteFailedAvatarMutation, GqlClientDeleteFailedAvatarMutationVariables, GqlClientDeleteFailedAvatarProps<TChildProps, TDataName>>(
        DeleteFailedAvatarDocument,
        {
            alias: "deleteFailedAvatar",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteFailedAvatarMutation__
 *
 * To run a mutation, you first call `useDeleteFailedAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFailedAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFailedAvatarMutation, { data, loading, error }] = useDeleteFailedAvatarMutation({
 *   variables: {
 *      deleteFailedAvatar: // value for 'deleteFailedAvatar'
 *   },
 * });
 */
export function useDeleteFailedAvatarMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteFailedAvatarMutation, GqlClientDeleteFailedAvatarMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteFailedAvatarMutation, GqlClientDeleteFailedAvatarMutationVariables>(DeleteFailedAvatarDocument, options);
}
export type DeleteFailedAvatarMutationHookResult = ReturnType<typeof useDeleteFailedAvatarMutation>;
export type DeleteFailedAvatarMutationResult = Apollo.MutationResult<GqlClientDeleteFailedAvatarMutation>;
export type DeleteFailedAvatarMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteFailedAvatarMutation, GqlClientDeleteFailedAvatarMutationVariables>;
export const DeletePreSubmittedAvatarDocument = gql`
    mutation deletePreSubmittedAvatar($input: DeletePreSubmittedAvatarInput!) {
        deletePreSubmittedAvatar(input: $input) {
            result
        }
    }
`;
export type GqlClientDeletePreSubmittedAvatarMutationFn = Apollo.MutationFunction<GqlClientDeletePreSubmittedAvatarMutation, GqlClientDeletePreSubmittedAvatarMutationVariables>;
export type GqlClientDeletePreSubmittedAvatarProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeletePreSubmittedAvatarMutation, GqlClientDeletePreSubmittedAvatarMutationVariables>;
} & TChildProps;
export function withDeletePreSubmittedAvatar<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeletePreSubmittedAvatarMutation,
        GqlClientDeletePreSubmittedAvatarMutationVariables,
        GqlClientDeletePreSubmittedAvatarProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeletePreSubmittedAvatarMutation,
        GqlClientDeletePreSubmittedAvatarMutationVariables,
        GqlClientDeletePreSubmittedAvatarProps<TChildProps, TDataName>
    >(DeletePreSubmittedAvatarDocument, {
        alias: "deletePreSubmittedAvatar",
        ...operationOptions
    });
}

/**
 * __useDeletePreSubmittedAvatarMutation__
 *
 * To run a mutation, you first call `useDeletePreSubmittedAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePreSubmittedAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePreSubmittedAvatarMutation, { data, loading, error }] = useDeletePreSubmittedAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePreSubmittedAvatarMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeletePreSubmittedAvatarMutation, GqlClientDeletePreSubmittedAvatarMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeletePreSubmittedAvatarMutation, GqlClientDeletePreSubmittedAvatarMutationVariables>(DeletePreSubmittedAvatarDocument, options);
}
export type DeletePreSubmittedAvatarMutationHookResult = ReturnType<typeof useDeletePreSubmittedAvatarMutation>;
export type DeletePreSubmittedAvatarMutationResult = Apollo.MutationResult<GqlClientDeletePreSubmittedAvatarMutation>;
export type DeletePreSubmittedAvatarMutationOptions = Apollo.BaseMutationOptions<GqlClientDeletePreSubmittedAvatarMutation, GqlClientDeletePreSubmittedAvatarMutationVariables>;
export const DeletePronunciationDefinitionDocument = gql`
    mutation deletePronunciationDefinition($input: DeletePronunciationDefinitionInput!) {
        deletePronunciationDefinition(input: $input) {
            pronunciationLibraryVersion {
                id
                anyUpdated
                anyUpdatedBy
                definitions {
                    ...AccountPronunciationDefinition
                }
            }
        }
    }
    ${AccountPronunciationDefinitionFragmentDoc}
`;
export type GqlClientDeletePronunciationDefinitionMutationFn = Apollo.MutationFunction<GqlClientDeletePronunciationDefinitionMutation, GqlClientDeletePronunciationDefinitionMutationVariables>;
export type GqlClientDeletePronunciationDefinitionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeletePronunciationDefinitionMutation, GqlClientDeletePronunciationDefinitionMutationVariables>;
} & TChildProps;
export function withDeletePronunciationDefinition<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeletePronunciationDefinitionMutation,
        GqlClientDeletePronunciationDefinitionMutationVariables,
        GqlClientDeletePronunciationDefinitionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeletePronunciationDefinitionMutation,
        GqlClientDeletePronunciationDefinitionMutationVariables,
        GqlClientDeletePronunciationDefinitionProps<TChildProps, TDataName>
    >(DeletePronunciationDefinitionDocument, {
        alias: "deletePronunciationDefinition",
        ...operationOptions
    });
}

/**
 * __useDeletePronunciationDefinitionMutation__
 *
 * To run a mutation, you first call `useDeletePronunciationDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePronunciationDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePronunciationDefinitionMutation, { data, loading, error }] = useDeletePronunciationDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePronunciationDefinitionMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientDeletePronunciationDefinitionMutation, GqlClientDeletePronunciationDefinitionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeletePronunciationDefinitionMutation, GqlClientDeletePronunciationDefinitionMutationVariables>(DeletePronunciationDefinitionDocument, options);
}
export type DeletePronunciationDefinitionMutationHookResult = ReturnType<typeof useDeletePronunciationDefinitionMutation>;
export type DeletePronunciationDefinitionMutationResult = Apollo.MutationResult<GqlClientDeletePronunciationDefinitionMutation>;
export type DeletePronunciationDefinitionMutationOptions = Apollo.BaseMutationOptions<GqlClientDeletePronunciationDefinitionMutation, GqlClientDeletePronunciationDefinitionMutationVariables>;
export const DisconnectTrayConnectorDocument = gql`
    mutation disconnectTrayConnector($disconnectTrayConnector: DisconnectTrayConnectorInput!) {
        disconnectTrayConnector(input: $disconnectTrayConnector) {
            result
        }
    }
`;
export type GqlClientDisconnectTrayConnectorMutationFn = Apollo.MutationFunction<GqlClientDisconnectTrayConnectorMutation, GqlClientDisconnectTrayConnectorMutationVariables>;
export type GqlClientDisconnectTrayConnectorProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDisconnectTrayConnectorMutation, GqlClientDisconnectTrayConnectorMutationVariables>;
} & TChildProps;
export function withDisconnectTrayConnector<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDisconnectTrayConnectorMutation,
        GqlClientDisconnectTrayConnectorMutationVariables,
        GqlClientDisconnectTrayConnectorProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDisconnectTrayConnectorMutation,
        GqlClientDisconnectTrayConnectorMutationVariables,
        GqlClientDisconnectTrayConnectorProps<TChildProps, TDataName>
    >(DisconnectTrayConnectorDocument, {
        alias: "disconnectTrayConnector",
        ...operationOptions
    });
}

/**
 * __useDisconnectTrayConnectorMutation__
 *
 * To run a mutation, you first call `useDisconnectTrayConnectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectTrayConnectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectTrayConnectorMutation, { data, loading, error }] = useDisconnectTrayConnectorMutation({
 *   variables: {
 *      disconnectTrayConnector: // value for 'disconnectTrayConnector'
 *   },
 * });
 */
export function useDisconnectTrayConnectorMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDisconnectTrayConnectorMutation, GqlClientDisconnectTrayConnectorMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDisconnectTrayConnectorMutation, GqlClientDisconnectTrayConnectorMutationVariables>(DisconnectTrayConnectorDocument, options);
}
export type DisconnectTrayConnectorMutationHookResult = ReturnType<typeof useDisconnectTrayConnectorMutation>;
export type DisconnectTrayConnectorMutationResult = Apollo.MutationResult<GqlClientDisconnectTrayConnectorMutation>;
export type DisconnectTrayConnectorMutationOptions = Apollo.BaseMutationOptions<GqlClientDisconnectTrayConnectorMutation, GqlClientDisconnectTrayConnectorMutationVariables>;
export const DuplicateEditorSceneInLineupDocument = gql`
    mutation duplicateEditorSceneInLineup($input: DuplicateEditorSceneInLineupInput!) {
        duplicateEditorSceneInLineup(input: $input) {
            result
            editorScene {
                ...EditorScene
                programVersion {
                    id
                    sceneLineup
                    scenes {
                        id
                    }
                }
            }
        }
    }
    ${EditorSceneFragmentDoc}
`;
export type GqlClientDuplicateEditorSceneInLineupMutationFn = Apollo.MutationFunction<GqlClientDuplicateEditorSceneInLineupMutation, GqlClientDuplicateEditorSceneInLineupMutationVariables>;
export type GqlClientDuplicateEditorSceneInLineupProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDuplicateEditorSceneInLineupMutation, GqlClientDuplicateEditorSceneInLineupMutationVariables>;
} & TChildProps;
export function withDuplicateEditorSceneInLineup<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDuplicateEditorSceneInLineupMutation,
        GqlClientDuplicateEditorSceneInLineupMutationVariables,
        GqlClientDuplicateEditorSceneInLineupProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDuplicateEditorSceneInLineupMutation,
        GqlClientDuplicateEditorSceneInLineupMutationVariables,
        GqlClientDuplicateEditorSceneInLineupProps<TChildProps, TDataName>
    >(DuplicateEditorSceneInLineupDocument, {
        alias: "duplicateEditorSceneInLineup",
        ...operationOptions
    });
}

/**
 * __useDuplicateEditorSceneInLineupMutation__
 *
 * To run a mutation, you first call `useDuplicateEditorSceneInLineupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateEditorSceneInLineupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateEditorSceneInLineupMutation, { data, loading, error }] = useDuplicateEditorSceneInLineupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateEditorSceneInLineupMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientDuplicateEditorSceneInLineupMutation, GqlClientDuplicateEditorSceneInLineupMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDuplicateEditorSceneInLineupMutation, GqlClientDuplicateEditorSceneInLineupMutationVariables>(DuplicateEditorSceneInLineupDocument, options);
}
export type DuplicateEditorSceneInLineupMutationHookResult = ReturnType<typeof useDuplicateEditorSceneInLineupMutation>;
export type DuplicateEditorSceneInLineupMutationResult = Apollo.MutationResult<GqlClientDuplicateEditorSceneInLineupMutation>;
export type DuplicateEditorSceneInLineupMutationOptions = Apollo.BaseMutationOptions<GqlClientDuplicateEditorSceneInLineupMutation, GqlClientDuplicateEditorSceneInLineupMutationVariables>;
export const EditEditorAssetMediaDocument = gql`
    mutation editEditorAssetMedia($input: EditEditorAssetMediaInput!, $mutationId: String) {
        editEditorAssetMedia(input: $input, mutationId: $mutationId) {
            result
            editorAssetMedia {
                editorAssetLibraryVersion {
                    id
                    media {
                        id
                    }
                }
                ...EditorAssetLibraryMedia
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
`;
export type GqlClientEditEditorAssetMediaMutationFn = Apollo.MutationFunction<GqlClientEditEditorAssetMediaMutation, GqlClientEditEditorAssetMediaMutationVariables>;
export type GqlClientEditEditorAssetMediaProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientEditEditorAssetMediaMutation, GqlClientEditEditorAssetMediaMutationVariables>;
} & TChildProps;
export function withEditEditorAssetMedia<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditEditorAssetMediaMutation,
        GqlClientEditEditorAssetMediaMutationVariables,
        GqlClientEditEditorAssetMediaProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientEditEditorAssetMediaMutation, GqlClientEditEditorAssetMediaMutationVariables, GqlClientEditEditorAssetMediaProps<TChildProps, TDataName>>(
        EditEditorAssetMediaDocument,
        {
            alias: "editEditorAssetMedia",
            ...operationOptions
        }
    );
}

/**
 * __useEditEditorAssetMediaMutation__
 *
 * To run a mutation, you first call `useEditEditorAssetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEditorAssetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEditorAssetMediaMutation, { data, loading, error }] = useEditEditorAssetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useEditEditorAssetMediaMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientEditEditorAssetMediaMutation, GqlClientEditEditorAssetMediaMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientEditEditorAssetMediaMutation, GqlClientEditEditorAssetMediaMutationVariables>(EditEditorAssetMediaDocument, options);
}
export type EditEditorAssetMediaMutationHookResult = ReturnType<typeof useEditEditorAssetMediaMutation>;
export type EditEditorAssetMediaMutationResult = Apollo.MutationResult<GqlClientEditEditorAssetMediaMutation>;
export type EditEditorAssetMediaMutationOptions = Apollo.BaseMutationOptions<GqlClientEditEditorAssetMediaMutation, GqlClientEditEditorAssetMediaMutationVariables>;
export const EditorAssetMediaSaveAsDocument = gql`
    mutation editorAssetMediaSaveAs($input: EditorAssetMediaSaveAsInput!) {
        editorAssetMediaSaveAs(input: $input) {
            result
            editorAssetMedia {
                editorAssetLibraryVersion {
                    id
                    media {
                        id
                    }
                }
                ...EditorAssetLibraryMedia
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
`;
export type GqlClientEditorAssetMediaSaveAsMutationFn = Apollo.MutationFunction<GqlClientEditorAssetMediaSaveAsMutation, GqlClientEditorAssetMediaSaveAsMutationVariables>;
export type GqlClientEditorAssetMediaSaveAsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientEditorAssetMediaSaveAsMutation, GqlClientEditorAssetMediaSaveAsMutationVariables>;
} & TChildProps;
export function withEditorAssetMediaSaveAs<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorAssetMediaSaveAsMutation,
        GqlClientEditorAssetMediaSaveAsMutationVariables,
        GqlClientEditorAssetMediaSaveAsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientEditorAssetMediaSaveAsMutation, GqlClientEditorAssetMediaSaveAsMutationVariables, GqlClientEditorAssetMediaSaveAsProps<TChildProps, TDataName>>(
        EditorAssetMediaSaveAsDocument,
        {
            alias: "editorAssetMediaSaveAs",
            ...operationOptions
        }
    );
}

/**
 * __useEditorAssetMediaSaveAsMutation__
 *
 * To run a mutation, you first call `useEditorAssetMediaSaveAsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditorAssetMediaSaveAsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editorAssetMediaSaveAsMutation, { data, loading, error }] = useEditorAssetMediaSaveAsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditorAssetMediaSaveAsMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientEditorAssetMediaSaveAsMutation, GqlClientEditorAssetMediaSaveAsMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientEditorAssetMediaSaveAsMutation, GqlClientEditorAssetMediaSaveAsMutationVariables>(EditorAssetMediaSaveAsDocument, options);
}
export type EditorAssetMediaSaveAsMutationHookResult = ReturnType<typeof useEditorAssetMediaSaveAsMutation>;
export type EditorAssetMediaSaveAsMutationResult = Apollo.MutationResult<GqlClientEditorAssetMediaSaveAsMutation>;
export type EditorAssetMediaSaveAsMutationOptions = Apollo.BaseMutationOptions<GqlClientEditorAssetMediaSaveAsMutation, GqlClientEditorAssetMediaSaveAsMutationVariables>;
export const GenerateAiVideoFromImageDocument = gql`
    mutation GenerateAiVideoFromImage($input: AiImageToVideoInput!, $mutationId: String) {
        generateAiVideoFromImage(input: $input, mutationId: $mutationId) {
            result
        }
    }
`;
export type GqlClientGenerateAiVideoFromImageMutationFn = Apollo.MutationFunction<GqlClientGenerateAiVideoFromImageMutation, GqlClientGenerateAiVideoFromImageMutationVariables>;
export type GqlClientGenerateAiVideoFromImageProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientGenerateAiVideoFromImageMutation, GqlClientGenerateAiVideoFromImageMutationVariables>;
} & TChildProps;
export function withGenerateAiVideoFromImage<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGenerateAiVideoFromImageMutation,
        GqlClientGenerateAiVideoFromImageMutationVariables,
        GqlClientGenerateAiVideoFromImageProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientGenerateAiVideoFromImageMutation,
        GqlClientGenerateAiVideoFromImageMutationVariables,
        GqlClientGenerateAiVideoFromImageProps<TChildProps, TDataName>
    >(GenerateAiVideoFromImageDocument, {
        alias: "generateAiVideoFromImage",
        ...operationOptions
    });
}

/**
 * __useGenerateAiVideoFromImageMutation__
 *
 * To run a mutation, you first call `useGenerateAiVideoFromImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAiVideoFromImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAiVideoFromImageMutation, { data, loading, error }] = useGenerateAiVideoFromImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useGenerateAiVideoFromImageMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientGenerateAiVideoFromImageMutation, GqlClientGenerateAiVideoFromImageMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientGenerateAiVideoFromImageMutation, GqlClientGenerateAiVideoFromImageMutationVariables>(GenerateAiVideoFromImageDocument, options);
}
export type GenerateAiVideoFromImageMutationHookResult = ReturnType<typeof useGenerateAiVideoFromImageMutation>;
export type GenerateAiVideoFromImageMutationResult = Apollo.MutationResult<GqlClientGenerateAiVideoFromImageMutation>;
export type GenerateAiVideoFromImageMutationOptions = Apollo.BaseMutationOptions<GqlClientGenerateAiVideoFromImageMutation, GqlClientGenerateAiVideoFromImageMutationVariables>;
export const MoveAccountProgramFolderToFolderDocument = gql`
    mutation moveAccountProgramFolderToFolder($input: MoveAccountProgramFolderToFolderInput!) {
        moveAccountProgramFolderToFolder(input: $input) {
            result
            folder {
                id
                name
                parentFolder {
                    id
                }
            }
        }
    }
`;
export type GqlClientMoveAccountProgramFolderToFolderMutationFn = Apollo.MutationFunction<
    GqlClientMoveAccountProgramFolderToFolderMutation,
    GqlClientMoveAccountProgramFolderToFolderMutationVariables
>;
export type GqlClientMoveAccountProgramFolderToFolderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientMoveAccountProgramFolderToFolderMutation, GqlClientMoveAccountProgramFolderToFolderMutationVariables>;
} & TChildProps;
export function withMoveAccountProgramFolderToFolder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientMoveAccountProgramFolderToFolderMutation,
        GqlClientMoveAccountProgramFolderToFolderMutationVariables,
        GqlClientMoveAccountProgramFolderToFolderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientMoveAccountProgramFolderToFolderMutation,
        GqlClientMoveAccountProgramFolderToFolderMutationVariables,
        GqlClientMoveAccountProgramFolderToFolderProps<TChildProps, TDataName>
    >(MoveAccountProgramFolderToFolderDocument, {
        alias: "moveAccountProgramFolderToFolder",
        ...operationOptions
    });
}

/**
 * __useMoveAccountProgramFolderToFolderMutation__
 *
 * To run a mutation, you first call `useMoveAccountProgramFolderToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveAccountProgramFolderToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveAccountProgramFolderToFolderMutation, { data, loading, error }] = useMoveAccountProgramFolderToFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveAccountProgramFolderToFolderMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientMoveAccountProgramFolderToFolderMutation, GqlClientMoveAccountProgramFolderToFolderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientMoveAccountProgramFolderToFolderMutation, GqlClientMoveAccountProgramFolderToFolderMutationVariables>(MoveAccountProgramFolderToFolderDocument, options);
}
export type MoveAccountProgramFolderToFolderMutationHookResult = ReturnType<typeof useMoveAccountProgramFolderToFolderMutation>;
export type MoveAccountProgramFolderToFolderMutationResult = Apollo.MutationResult<GqlClientMoveAccountProgramFolderToFolderMutation>;
export type MoveAccountProgramFolderToFolderMutationOptions = Apollo.BaseMutationOptions<GqlClientMoveAccountProgramFolderToFolderMutation, GqlClientMoveAccountProgramFolderToFolderMutationVariables>;
export const MoveEditorAssetToFolderDocument = gql`
    mutation moveEditorAssetToFolder($input: MoveEditorAssetToFolderInput!) {
        moveEditorAssetToFolder(input: $input) {
            result
            editorAssetMedia {
                editorAssetLibraryVersion {
                    id
                    media {
                        id
                    }
                }
                ...EditorAssetLibraryMedia
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
`;
export type GqlClientMoveEditorAssetToFolderMutationFn = Apollo.MutationFunction<GqlClientMoveEditorAssetToFolderMutation, GqlClientMoveEditorAssetToFolderMutationVariables>;
export type GqlClientMoveEditorAssetToFolderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientMoveEditorAssetToFolderMutation, GqlClientMoveEditorAssetToFolderMutationVariables>;
} & TChildProps;
export function withMoveEditorAssetToFolder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientMoveEditorAssetToFolderMutation,
        GqlClientMoveEditorAssetToFolderMutationVariables,
        GqlClientMoveEditorAssetToFolderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientMoveEditorAssetToFolderMutation,
        GqlClientMoveEditorAssetToFolderMutationVariables,
        GqlClientMoveEditorAssetToFolderProps<TChildProps, TDataName>
    >(MoveEditorAssetToFolderDocument, {
        alias: "moveEditorAssetToFolder",
        ...operationOptions
    });
}

/**
 * __useMoveEditorAssetToFolderMutation__
 *
 * To run a mutation, you first call `useMoveEditorAssetToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveEditorAssetToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveEditorAssetToFolderMutation, { data, loading, error }] = useMoveEditorAssetToFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveEditorAssetToFolderMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientMoveEditorAssetToFolderMutation, GqlClientMoveEditorAssetToFolderMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientMoveEditorAssetToFolderMutation, GqlClientMoveEditorAssetToFolderMutationVariables>(MoveEditorAssetToFolderDocument, options);
}
export type MoveEditorAssetToFolderMutationHookResult = ReturnType<typeof useMoveEditorAssetToFolderMutation>;
export type MoveEditorAssetToFolderMutationResult = Apollo.MutationResult<GqlClientMoveEditorAssetToFolderMutation>;
export type MoveEditorAssetToFolderMutationOptions = Apollo.BaseMutationOptions<GqlClientMoveEditorAssetToFolderMutation, GqlClientMoveEditorAssetToFolderMutationVariables>;
export const MoveEditorFolderToFolderDocument = gql`
    mutation moveEditorFolderToFolder($input: MoveEditorFolderToFolderInput!) {
        moveEditorFolderToFolder(input: $input) {
            result
            folder {
                id
                name
                parentFolder {
                    id
                }
                accountId
            }
        }
    }
`;
export type GqlClientMoveEditorFolderToFolderMutationFn = Apollo.MutationFunction<GqlClientMoveEditorFolderToFolderMutation, GqlClientMoveEditorFolderToFolderMutationVariables>;
export type GqlClientMoveEditorFolderToFolderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientMoveEditorFolderToFolderMutation, GqlClientMoveEditorFolderToFolderMutationVariables>;
} & TChildProps;
export function withMoveEditorFolderToFolder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientMoveEditorFolderToFolderMutation,
        GqlClientMoveEditorFolderToFolderMutationVariables,
        GqlClientMoveEditorFolderToFolderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientMoveEditorFolderToFolderMutation,
        GqlClientMoveEditorFolderToFolderMutationVariables,
        GqlClientMoveEditorFolderToFolderProps<TChildProps, TDataName>
    >(MoveEditorFolderToFolderDocument, {
        alias: "moveEditorFolderToFolder",
        ...operationOptions
    });
}

/**
 * __useMoveEditorFolderToFolderMutation__
 *
 * To run a mutation, you first call `useMoveEditorFolderToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveEditorFolderToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveEditorFolderToFolderMutation, { data, loading, error }] = useMoveEditorFolderToFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveEditorFolderToFolderMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientMoveEditorFolderToFolderMutation, GqlClientMoveEditorFolderToFolderMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientMoveEditorFolderToFolderMutation, GqlClientMoveEditorFolderToFolderMutationVariables>(MoveEditorFolderToFolderDocument, options);
}
export type MoveEditorFolderToFolderMutationHookResult = ReturnType<typeof useMoveEditorFolderToFolderMutation>;
export type MoveEditorFolderToFolderMutationResult = Apollo.MutationResult<GqlClientMoveEditorFolderToFolderMutation>;
export type MoveEditorFolderToFolderMutationOptions = Apollo.BaseMutationOptions<GqlClientMoveEditorFolderToFolderMutation, GqlClientMoveEditorFolderToFolderMutationVariables>;
export const MoveProgramToFolderDocument = gql`
    mutation moveProgramToFolder($input: MoveProgramToFolderInput!) {
        moveProgramToFolder(input: $input) {
            result
            program {
                ... on Program {
                    id
                    name
                    folder {
                        id
                    }
                }
                ... on EditorProgram {
                    id
                    name
                    folder {
                        id
                    }
                }
            }
        }
    }
`;
export type GqlClientMoveProgramToFolderMutationFn = Apollo.MutationFunction<GqlClientMoveProgramToFolderMutation, GqlClientMoveProgramToFolderMutationVariables>;
export type GqlClientMoveProgramToFolderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientMoveProgramToFolderMutation, GqlClientMoveProgramToFolderMutationVariables>;
} & TChildProps;
export function withMoveProgramToFolder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientMoveProgramToFolderMutation,
        GqlClientMoveProgramToFolderMutationVariables,
        GqlClientMoveProgramToFolderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientMoveProgramToFolderMutation, GqlClientMoveProgramToFolderMutationVariables, GqlClientMoveProgramToFolderProps<TChildProps, TDataName>>(
        MoveProgramToFolderDocument,
        {
            alias: "moveProgramToFolder",
            ...operationOptions
        }
    );
}

/**
 * __useMoveProgramToFolderMutation__
 *
 * To run a mutation, you first call `useMoveProgramToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveProgramToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveProgramToFolderMutation, { data, loading, error }] = useMoveProgramToFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveProgramToFolderMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientMoveProgramToFolderMutation, GqlClientMoveProgramToFolderMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientMoveProgramToFolderMutation, GqlClientMoveProgramToFolderMutationVariables>(MoveProgramToFolderDocument, options);
}
export type MoveProgramToFolderMutationHookResult = ReturnType<typeof useMoveProgramToFolderMutation>;
export type MoveProgramToFolderMutationResult = Apollo.MutationResult<GqlClientMoveProgramToFolderMutation>;
export type MoveProgramToFolderMutationOptions = Apollo.BaseMutationOptions<GqlClientMoveProgramToFolderMutation, GqlClientMoveProgramToFolderMutationVariables>;
export const PostToSocialNetworkDocument = gql`
    mutation PostToSocialNetwork($input: PostToSocialNetworkInput!) {
        postToSocialNetwork(input: $input) {
            postUrl
        }
    }
`;
export type GqlClientPostToSocialNetworkMutationFn = Apollo.MutationFunction<GqlClientPostToSocialNetworkMutation, GqlClientPostToSocialNetworkMutationVariables>;
export type GqlClientPostToSocialNetworkProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientPostToSocialNetworkMutation, GqlClientPostToSocialNetworkMutationVariables>;
} & TChildProps;
export function withPostToSocialNetwork<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientPostToSocialNetworkMutation,
        GqlClientPostToSocialNetworkMutationVariables,
        GqlClientPostToSocialNetworkProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientPostToSocialNetworkMutation, GqlClientPostToSocialNetworkMutationVariables, GqlClientPostToSocialNetworkProps<TChildProps, TDataName>>(
        PostToSocialNetworkDocument,
        {
            alias: "postToSocialNetwork",
            ...operationOptions
        }
    );
}

/**
 * __usePostToSocialNetworkMutation__
 *
 * To run a mutation, you first call `usePostToSocialNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostToSocialNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postToSocialNetworkMutation, { data, loading, error }] = usePostToSocialNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostToSocialNetworkMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientPostToSocialNetworkMutation, GqlClientPostToSocialNetworkMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientPostToSocialNetworkMutation, GqlClientPostToSocialNetworkMutationVariables>(PostToSocialNetworkDocument, options);
}
export type PostToSocialNetworkMutationHookResult = ReturnType<typeof usePostToSocialNetworkMutation>;
export type PostToSocialNetworkMutationResult = Apollo.MutationResult<GqlClientPostToSocialNetworkMutation>;
export type PostToSocialNetworkMutationOptions = Apollo.BaseMutationOptions<GqlClientPostToSocialNetworkMutation, GqlClientPostToSocialNetworkMutationVariables>;
export const PromotePreSubmittedAvatarDocument = gql`
    mutation promotePreSubmittedAvatar($input: PromotePreSubmittedAvatarInput!) {
        promotePreSubmittedAvatar(input: $input) {
            result
            avatar {
                accountId
                avatarSystemName
                created
                id
                name
                updatedBy
            }
        }
    }
`;
export type GqlClientPromotePreSubmittedAvatarMutationFn = Apollo.MutationFunction<GqlClientPromotePreSubmittedAvatarMutation, GqlClientPromotePreSubmittedAvatarMutationVariables>;
export type GqlClientPromotePreSubmittedAvatarProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientPromotePreSubmittedAvatarMutation, GqlClientPromotePreSubmittedAvatarMutationVariables>;
} & TChildProps;
export function withPromotePreSubmittedAvatar<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientPromotePreSubmittedAvatarMutation,
        GqlClientPromotePreSubmittedAvatarMutationVariables,
        GqlClientPromotePreSubmittedAvatarProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientPromotePreSubmittedAvatarMutation,
        GqlClientPromotePreSubmittedAvatarMutationVariables,
        GqlClientPromotePreSubmittedAvatarProps<TChildProps, TDataName>
    >(PromotePreSubmittedAvatarDocument, {
        alias: "promotePreSubmittedAvatar",
        ...operationOptions
    });
}

/**
 * __usePromotePreSubmittedAvatarMutation__
 *
 * To run a mutation, you first call `usePromotePreSubmittedAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromotePreSubmittedAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promotePreSubmittedAvatarMutation, { data, loading, error }] = usePromotePreSubmittedAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePromotePreSubmittedAvatarMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientPromotePreSubmittedAvatarMutation, GqlClientPromotePreSubmittedAvatarMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientPromotePreSubmittedAvatarMutation, GqlClientPromotePreSubmittedAvatarMutationVariables>(PromotePreSubmittedAvatarDocument, options);
}
export type PromotePreSubmittedAvatarMutationHookResult = ReturnType<typeof usePromotePreSubmittedAvatarMutation>;
export type PromotePreSubmittedAvatarMutationResult = Apollo.MutationResult<GqlClientPromotePreSubmittedAvatarMutation>;
export type PromotePreSubmittedAvatarMutationOptions = Apollo.BaseMutationOptions<GqlClientPromotePreSubmittedAvatarMutation, GqlClientPromotePreSubmittedAvatarMutationVariables>;
export const PromoteSubmittedAvatarDocument = gql`
    mutation promoteSubmittedAvatar($input: PromoteSubmittedAvatarInput!, $mutationId: String!) {
        promoteSubmittedAvatar(input: $input, mutationId: $mutationId) {
            result
        }
    }
`;
export type GqlClientPromoteSubmittedAvatarMutationFn = Apollo.MutationFunction<GqlClientPromoteSubmittedAvatarMutation, GqlClientPromoteSubmittedAvatarMutationVariables>;
export type GqlClientPromoteSubmittedAvatarProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientPromoteSubmittedAvatarMutation, GqlClientPromoteSubmittedAvatarMutationVariables>;
} & TChildProps;
export function withPromoteSubmittedAvatar<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientPromoteSubmittedAvatarMutation,
        GqlClientPromoteSubmittedAvatarMutationVariables,
        GqlClientPromoteSubmittedAvatarProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientPromoteSubmittedAvatarMutation, GqlClientPromoteSubmittedAvatarMutationVariables, GqlClientPromoteSubmittedAvatarProps<TChildProps, TDataName>>(
        PromoteSubmittedAvatarDocument,
        {
            alias: "promoteSubmittedAvatar",
            ...operationOptions
        }
    );
}

/**
 * __usePromoteSubmittedAvatarMutation__
 *
 * To run a mutation, you first call `usePromoteSubmittedAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteSubmittedAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteSubmittedAvatarMutation, { data, loading, error }] = usePromoteSubmittedAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function usePromoteSubmittedAvatarMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientPromoteSubmittedAvatarMutation, GqlClientPromoteSubmittedAvatarMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientPromoteSubmittedAvatarMutation, GqlClientPromoteSubmittedAvatarMutationVariables>(PromoteSubmittedAvatarDocument, options);
}
export type PromoteSubmittedAvatarMutationHookResult = ReturnType<typeof usePromoteSubmittedAvatarMutation>;
export type PromoteSubmittedAvatarMutationResult = Apollo.MutationResult<GqlClientPromoteSubmittedAvatarMutation>;
export type PromoteSubmittedAvatarMutationOptions = Apollo.BaseMutationOptions<GqlClientPromoteSubmittedAvatarMutation, GqlClientPromoteSubmittedAvatarMutationVariables>;
export const PublishStoryTemplateDocument = gql`
    mutation PublishStoryTemplate($input: AddStoryTemplateToProductionGalleryInput!) {
        addStoryTemplateToProductionGallery(input: $input) {
            result
        }
    }
`;
export type GqlClientPublishStoryTemplateMutationFn = Apollo.MutationFunction<GqlClientPublishStoryTemplateMutation, GqlClientPublishStoryTemplateMutationVariables>;
export type GqlClientPublishStoryTemplateProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientPublishStoryTemplateMutation, GqlClientPublishStoryTemplateMutationVariables>;
} & TChildProps;
export function withPublishStoryTemplate<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientPublishStoryTemplateMutation,
        GqlClientPublishStoryTemplateMutationVariables,
        GqlClientPublishStoryTemplateProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientPublishStoryTemplateMutation, GqlClientPublishStoryTemplateMutationVariables, GqlClientPublishStoryTemplateProps<TChildProps, TDataName>>(
        PublishStoryTemplateDocument,
        {
            alias: "publishStoryTemplate",
            ...operationOptions
        }
    );
}

/**
 * __usePublishStoryTemplateMutation__
 *
 * To run a mutation, you first call `usePublishStoryTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishStoryTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishStoryTemplateMutation, { data, loading, error }] = usePublishStoryTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishStoryTemplateMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientPublishStoryTemplateMutation, GqlClientPublishStoryTemplateMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientPublishStoryTemplateMutation, GqlClientPublishStoryTemplateMutationVariables>(PublishStoryTemplateDocument, options);
}
export type PublishStoryTemplateMutationHookResult = ReturnType<typeof usePublishStoryTemplateMutation>;
export type PublishStoryTemplateMutationResult = Apollo.MutationResult<GqlClientPublishStoryTemplateMutation>;
export type PublishStoryTemplateMutationOptions = Apollo.BaseMutationOptions<GqlClientPublishStoryTemplateMutation, GqlClientPublishStoryTemplateMutationVariables>;
export const RecalculateEditorNarrationRecordingStateDocument = gql`
    mutation RecalculateEditorNarrationRecordingState($input: RecalculateEditorNarrationRecordingStateInput!, $mutationId: String!) {
        recalculateEditorNarrationRecordingState(input: $input, mutationId: $mutationId) {
            result
        }
    }
`;
export type GqlClientRecalculateEditorNarrationRecordingStateMutationFn = Apollo.MutationFunction<
    GqlClientRecalculateEditorNarrationRecordingStateMutation,
    GqlClientRecalculateEditorNarrationRecordingStateMutationVariables
>;
export type GqlClientRecalculateEditorNarrationRecordingStateProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientRecalculateEditorNarrationRecordingStateMutation, GqlClientRecalculateEditorNarrationRecordingStateMutationVariables>;
} & TChildProps;
export function withRecalculateEditorNarrationRecordingState<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientRecalculateEditorNarrationRecordingStateMutation,
        GqlClientRecalculateEditorNarrationRecordingStateMutationVariables,
        GqlClientRecalculateEditorNarrationRecordingStateProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientRecalculateEditorNarrationRecordingStateMutation,
        GqlClientRecalculateEditorNarrationRecordingStateMutationVariables,
        GqlClientRecalculateEditorNarrationRecordingStateProps<TChildProps, TDataName>
    >(RecalculateEditorNarrationRecordingStateDocument, {
        alias: "recalculateEditorNarrationRecordingState",
        ...operationOptions
    });
}

/**
 * __useRecalculateEditorNarrationRecordingStateMutation__
 *
 * To run a mutation, you first call `useRecalculateEditorNarrationRecordingStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecalculateEditorNarrationRecordingStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recalculateEditorNarrationRecordingStateMutation, { data, loading, error }] = useRecalculateEditorNarrationRecordingStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useRecalculateEditorNarrationRecordingStateMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientRecalculateEditorNarrationRecordingStateMutation, GqlClientRecalculateEditorNarrationRecordingStateMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientRecalculateEditorNarrationRecordingStateMutation, GqlClientRecalculateEditorNarrationRecordingStateMutationVariables>(
        RecalculateEditorNarrationRecordingStateDocument,
        options
    );
}
export type RecalculateEditorNarrationRecordingStateMutationHookResult = ReturnType<typeof useRecalculateEditorNarrationRecordingStateMutation>;
export type RecalculateEditorNarrationRecordingStateMutationResult = Apollo.MutationResult<GqlClientRecalculateEditorNarrationRecordingStateMutation>;
export type RecalculateEditorNarrationRecordingStateMutationOptions = Apollo.BaseMutationOptions<
    GqlClientRecalculateEditorNarrationRecordingStateMutation,
    GqlClientRecalculateEditorNarrationRecordingStateMutationVariables
>;
export const RemoveAllExternalUsersFromAccountDocument = gql`
    mutation RemoveAllExternalUsersFromAccount {
        removeAllExternalUsersFromAccount {
            result
        }
    }
`;
export type GqlClientRemoveAllExternalUsersFromAccountMutationFn = Apollo.MutationFunction<
    GqlClientRemoveAllExternalUsersFromAccountMutation,
    GqlClientRemoveAllExternalUsersFromAccountMutationVariables
>;
export type GqlClientRemoveAllExternalUsersFromAccountProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientRemoveAllExternalUsersFromAccountMutation, GqlClientRemoveAllExternalUsersFromAccountMutationVariables>;
} & TChildProps;
export function withRemoveAllExternalUsersFromAccount<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientRemoveAllExternalUsersFromAccountMutation,
        GqlClientRemoveAllExternalUsersFromAccountMutationVariables,
        GqlClientRemoveAllExternalUsersFromAccountProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientRemoveAllExternalUsersFromAccountMutation,
        GqlClientRemoveAllExternalUsersFromAccountMutationVariables,
        GqlClientRemoveAllExternalUsersFromAccountProps<TChildProps, TDataName>
    >(RemoveAllExternalUsersFromAccountDocument, {
        alias: "removeAllExternalUsersFromAccount",
        ...operationOptions
    });
}

/**
 * __useRemoveAllExternalUsersFromAccountMutation__
 *
 * To run a mutation, you first call `useRemoveAllExternalUsersFromAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllExternalUsersFromAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllExternalUsersFromAccountMutation, { data, loading, error }] = useRemoveAllExternalUsersFromAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveAllExternalUsersFromAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientRemoveAllExternalUsersFromAccountMutation, GqlClientRemoveAllExternalUsersFromAccountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientRemoveAllExternalUsersFromAccountMutation, GqlClientRemoveAllExternalUsersFromAccountMutationVariables>(RemoveAllExternalUsersFromAccountDocument, options);
}
export type RemoveAllExternalUsersFromAccountMutationHookResult = ReturnType<typeof useRemoveAllExternalUsersFromAccountMutation>;
export type RemoveAllExternalUsersFromAccountMutationResult = Apollo.MutationResult<GqlClientRemoveAllExternalUsersFromAccountMutation>;
export type RemoveAllExternalUsersFromAccountMutationOptions = Apollo.BaseMutationOptions<
    GqlClientRemoveAllExternalUsersFromAccountMutation,
    GqlClientRemoveAllExternalUsersFromAccountMutationVariables
>;
export const RemoveConnectorFromAccountDocument = gql`
    mutation removeConnectorFromAccount($removeConnectorFromAccount: RemoveConnectorFromAccountInput!) {
        removeConnectorFromAccount(input: $removeConnectorFromAccount) {
            result
        }
    }
`;
export type GqlClientRemoveConnectorFromAccountMutationFn = Apollo.MutationFunction<GqlClientRemoveConnectorFromAccountMutation, GqlClientRemoveConnectorFromAccountMutationVariables>;
export type GqlClientRemoveConnectorFromAccountProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientRemoveConnectorFromAccountMutation, GqlClientRemoveConnectorFromAccountMutationVariables>;
} & TChildProps;
export function withRemoveConnectorFromAccount<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientRemoveConnectorFromAccountMutation,
        GqlClientRemoveConnectorFromAccountMutationVariables,
        GqlClientRemoveConnectorFromAccountProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientRemoveConnectorFromAccountMutation,
        GqlClientRemoveConnectorFromAccountMutationVariables,
        GqlClientRemoveConnectorFromAccountProps<TChildProps, TDataName>
    >(RemoveConnectorFromAccountDocument, {
        alias: "removeConnectorFromAccount",
        ...operationOptions
    });
}

/**
 * __useRemoveConnectorFromAccountMutation__
 *
 * To run a mutation, you first call `useRemoveConnectorFromAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConnectorFromAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConnectorFromAccountMutation, { data, loading, error }] = useRemoveConnectorFromAccountMutation({
 *   variables: {
 *      removeConnectorFromAccount: // value for 'removeConnectorFromAccount'
 *   },
 * });
 */
export function useRemoveConnectorFromAccountMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientRemoveConnectorFromAccountMutation, GqlClientRemoveConnectorFromAccountMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientRemoveConnectorFromAccountMutation, GqlClientRemoveConnectorFromAccountMutationVariables>(RemoveConnectorFromAccountDocument, options);
}
export type RemoveConnectorFromAccountMutationHookResult = ReturnType<typeof useRemoveConnectorFromAccountMutation>;
export type RemoveConnectorFromAccountMutationResult = Apollo.MutationResult<GqlClientRemoveConnectorFromAccountMutation>;
export type RemoveConnectorFromAccountMutationOptions = Apollo.BaseMutationOptions<GqlClientRemoveConnectorFromAccountMutation, GqlClientRemoveConnectorFromAccountMutationVariables>;
export const RemoveEditorProgramVersionLogicAndDataLibraryDocument = gql`
    mutation removeEditorProgramVersionLogicAndDataLibrary($input: RemoveEditorProgramVersionLogicAndDataLibraryInput!) {
        removeEditorProgramVersionLogicAndDataLibrary(input: $input) {
            editorProgramVersion {
                ...EditorProgramVersionAllLogic
            }
        }
    }
    ${EditorProgramVersionAllLogicFragmentDoc}
`;
export type GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutationFn = Apollo.MutationFunction<
    GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutation,
    GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutationVariables
>;
export type GqlClientRemoveEditorProgramVersionLogicAndDataLibraryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutation, GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutationVariables>;
} & TChildProps;
export function withRemoveEditorProgramVersionLogicAndDataLibrary<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutation,
        GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutationVariables,
        GqlClientRemoveEditorProgramVersionLogicAndDataLibraryProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutation,
        GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutationVariables,
        GqlClientRemoveEditorProgramVersionLogicAndDataLibraryProps<TChildProps, TDataName>
    >(RemoveEditorProgramVersionLogicAndDataLibraryDocument, {
        alias: "removeEditorProgramVersionLogicAndDataLibrary",
        ...operationOptions
    });
}

/**
 * __useRemoveEditorProgramVersionLogicAndDataLibraryMutation__
 *
 * To run a mutation, you first call `useRemoveEditorProgramVersionLogicAndDataLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEditorProgramVersionLogicAndDataLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEditorProgramVersionLogicAndDataLibraryMutation, { data, loading, error }] = useRemoveEditorProgramVersionLogicAndDataLibraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveEditorProgramVersionLogicAndDataLibraryMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutation, GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutation, GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutationVariables>(
        RemoveEditorProgramVersionLogicAndDataLibraryDocument,
        options
    );
}
export type RemoveEditorProgramVersionLogicAndDataLibraryMutationHookResult = ReturnType<typeof useRemoveEditorProgramVersionLogicAndDataLibraryMutation>;
export type RemoveEditorProgramVersionLogicAndDataLibraryMutationResult = Apollo.MutationResult<GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutation>;
export type RemoveEditorProgramVersionLogicAndDataLibraryMutationOptions = Apollo.BaseMutationOptions<
    GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutation,
    GqlClientRemoveEditorProgramVersionLogicAndDataLibraryMutationVariables
>;
export const RemoveServiceFromAccountDocument = gql`
    mutation removeServiceFromAccount($removeServiceFromAccount: RemoveServiceFromAccountInput!) {
        removeServiceFromAccount(input: $removeServiceFromAccount) {
            result
        }
    }
`;
export type GqlClientRemoveServiceFromAccountMutationFn = Apollo.MutationFunction<GqlClientRemoveServiceFromAccountMutation, GqlClientRemoveServiceFromAccountMutationVariables>;
export type GqlClientRemoveServiceFromAccountProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientRemoveServiceFromAccountMutation, GqlClientRemoveServiceFromAccountMutationVariables>;
} & TChildProps;
export function withRemoveServiceFromAccount<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientRemoveServiceFromAccountMutation,
        GqlClientRemoveServiceFromAccountMutationVariables,
        GqlClientRemoveServiceFromAccountProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientRemoveServiceFromAccountMutation,
        GqlClientRemoveServiceFromAccountMutationVariables,
        GqlClientRemoveServiceFromAccountProps<TChildProps, TDataName>
    >(RemoveServiceFromAccountDocument, {
        alias: "removeServiceFromAccount",
        ...operationOptions
    });
}

/**
 * __useRemoveServiceFromAccountMutation__
 *
 * To run a mutation, you first call `useRemoveServiceFromAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveServiceFromAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeServiceFromAccountMutation, { data, loading, error }] = useRemoveServiceFromAccountMutation({
 *   variables: {
 *      removeServiceFromAccount: // value for 'removeServiceFromAccount'
 *   },
 * });
 */
export function useRemoveServiceFromAccountMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientRemoveServiceFromAccountMutation, GqlClientRemoveServiceFromAccountMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientRemoveServiceFromAccountMutation, GqlClientRemoveServiceFromAccountMutationVariables>(RemoveServiceFromAccountDocument, options);
}
export type RemoveServiceFromAccountMutationHookResult = ReturnType<typeof useRemoveServiceFromAccountMutation>;
export type RemoveServiceFromAccountMutationResult = Apollo.MutationResult<GqlClientRemoveServiceFromAccountMutation>;
export type RemoveServiceFromAccountMutationOptions = Apollo.BaseMutationOptions<GqlClientRemoveServiceFromAccountMutation, GqlClientRemoveServiceFromAccountMutationVariables>;
export const RemoveStoryTemplateDocument = gql`
    mutation RemoveStoryTemplate($input: RemoveStoryTemplateFromGalleryInput!) {
        removeStoryTemplateFromGallery(input: $input) {
            result
        }
    }
`;
export type GqlClientRemoveStoryTemplateMutationFn = Apollo.MutationFunction<GqlClientRemoveStoryTemplateMutation, GqlClientRemoveStoryTemplateMutationVariables>;
export type GqlClientRemoveStoryTemplateProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientRemoveStoryTemplateMutation, GqlClientRemoveStoryTemplateMutationVariables>;
} & TChildProps;
export function withRemoveStoryTemplate<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientRemoveStoryTemplateMutation,
        GqlClientRemoveStoryTemplateMutationVariables,
        GqlClientRemoveStoryTemplateProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientRemoveStoryTemplateMutation, GqlClientRemoveStoryTemplateMutationVariables, GqlClientRemoveStoryTemplateProps<TChildProps, TDataName>>(
        RemoveStoryTemplateDocument,
        {
            alias: "removeStoryTemplate",
            ...operationOptions
        }
    );
}

/**
 * __useRemoveStoryTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveStoryTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStoryTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStoryTemplateMutation, { data, loading, error }] = useRemoveStoryTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStoryTemplateMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientRemoveStoryTemplateMutation, GqlClientRemoveStoryTemplateMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientRemoveStoryTemplateMutation, GqlClientRemoveStoryTemplateMutationVariables>(RemoveStoryTemplateDocument, options);
}
export type RemoveStoryTemplateMutationHookResult = ReturnType<typeof useRemoveStoryTemplateMutation>;
export type RemoveStoryTemplateMutationResult = Apollo.MutationResult<GqlClientRemoveStoryTemplateMutation>;
export type RemoveStoryTemplateMutationOptions = Apollo.BaseMutationOptions<GqlClientRemoveStoryTemplateMutation, GqlClientRemoveStoryTemplateMutationVariables>;
export const RenameAccountProgramFolderDocument = gql`
    mutation renameAccountProgramFolder($input: RenameAccountProgramFolderInput!) {
        renameAccountProgramFolder(input: $input) {
            folder {
                ...ProgramFolder
            }
        }
    }
    ${ProgramFolderFragmentDoc}
`;
export type GqlClientRenameAccountProgramFolderMutationFn = Apollo.MutationFunction<GqlClientRenameAccountProgramFolderMutation, GqlClientRenameAccountProgramFolderMutationVariables>;
export type GqlClientRenameAccountProgramFolderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientRenameAccountProgramFolderMutation, GqlClientRenameAccountProgramFolderMutationVariables>;
} & TChildProps;
export function withRenameAccountProgramFolder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientRenameAccountProgramFolderMutation,
        GqlClientRenameAccountProgramFolderMutationVariables,
        GqlClientRenameAccountProgramFolderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientRenameAccountProgramFolderMutation,
        GqlClientRenameAccountProgramFolderMutationVariables,
        GqlClientRenameAccountProgramFolderProps<TChildProps, TDataName>
    >(RenameAccountProgramFolderDocument, {
        alias: "renameAccountProgramFolder",
        ...operationOptions
    });
}

/**
 * __useRenameAccountProgramFolderMutation__
 *
 * To run a mutation, you first call `useRenameAccountProgramFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameAccountProgramFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameAccountProgramFolderMutation, { data, loading, error }] = useRenameAccountProgramFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameAccountProgramFolderMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientRenameAccountProgramFolderMutation, GqlClientRenameAccountProgramFolderMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientRenameAccountProgramFolderMutation, GqlClientRenameAccountProgramFolderMutationVariables>(RenameAccountProgramFolderDocument, options);
}
export type RenameAccountProgramFolderMutationHookResult = ReturnType<typeof useRenameAccountProgramFolderMutation>;
export type RenameAccountProgramFolderMutationResult = Apollo.MutationResult<GqlClientRenameAccountProgramFolderMutation>;
export type RenameAccountProgramFolderMutationOptions = Apollo.BaseMutationOptions<GqlClientRenameAccountProgramFolderMutation, GqlClientRenameAccountProgramFolderMutationVariables>;
export const RenameEditorAssetFolderDocument = gql`
    mutation RenameEditorAssetFolder($input: RenameEditorAssetFolderInput!) {
        renameEditorAssetFolder(input: $input) {
            result
            folder {
                id
                name
                accountId
                created
                createdBy
                updated
                updatedBy
            }
        }
    }
`;
export type GqlClientRenameEditorAssetFolderMutationFn = Apollo.MutationFunction<GqlClientRenameEditorAssetFolderMutation, GqlClientRenameEditorAssetFolderMutationVariables>;
export type GqlClientRenameEditorAssetFolderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientRenameEditorAssetFolderMutation, GqlClientRenameEditorAssetFolderMutationVariables>;
} & TChildProps;
export function withRenameEditorAssetFolder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientRenameEditorAssetFolderMutation,
        GqlClientRenameEditorAssetFolderMutationVariables,
        GqlClientRenameEditorAssetFolderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientRenameEditorAssetFolderMutation,
        GqlClientRenameEditorAssetFolderMutationVariables,
        GqlClientRenameEditorAssetFolderProps<TChildProps, TDataName>
    >(RenameEditorAssetFolderDocument, {
        alias: "renameEditorAssetFolder",
        ...operationOptions
    });
}

/**
 * __useRenameEditorAssetFolderMutation__
 *
 * To run a mutation, you first call `useRenameEditorAssetFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameEditorAssetFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameEditorAssetFolderMutation, { data, loading, error }] = useRenameEditorAssetFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameEditorAssetFolderMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientRenameEditorAssetFolderMutation, GqlClientRenameEditorAssetFolderMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientRenameEditorAssetFolderMutation, GqlClientRenameEditorAssetFolderMutationVariables>(RenameEditorAssetFolderDocument, options);
}
export type RenameEditorAssetFolderMutationHookResult = ReturnType<typeof useRenameEditorAssetFolderMutation>;
export type RenameEditorAssetFolderMutationResult = Apollo.MutationResult<GqlClientRenameEditorAssetFolderMutation>;
export type RenameEditorAssetFolderMutationOptions = Apollo.BaseMutationOptions<GqlClientRenameEditorAssetFolderMutation, GqlClientRenameEditorAssetFolderMutationVariables>;
export const RenameProgramReviewDocument = gql`
    mutation RenameProgramReview($input: RenameProgramReviewInput!) {
        renameProgramReview(input: $input) {
            result
            programReview {
                id
                name
            }
        }
    }
`;
export type GqlClientRenameProgramReviewMutationFn = Apollo.MutationFunction<GqlClientRenameProgramReviewMutation, GqlClientRenameProgramReviewMutationVariables>;
export type GqlClientRenameProgramReviewProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientRenameProgramReviewMutation, GqlClientRenameProgramReviewMutationVariables>;
} & TChildProps;
export function withRenameProgramReview<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientRenameProgramReviewMutation,
        GqlClientRenameProgramReviewMutationVariables,
        GqlClientRenameProgramReviewProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientRenameProgramReviewMutation, GqlClientRenameProgramReviewMutationVariables, GqlClientRenameProgramReviewProps<TChildProps, TDataName>>(
        RenameProgramReviewDocument,
        {
            alias: "renameProgramReview",
            ...operationOptions
        }
    );
}

/**
 * __useRenameProgramReviewMutation__
 *
 * To run a mutation, you first call `useRenameProgramReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameProgramReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameProgramReviewMutation, { data, loading, error }] = useRenameProgramReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameProgramReviewMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientRenameProgramReviewMutation, GqlClientRenameProgramReviewMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientRenameProgramReviewMutation, GqlClientRenameProgramReviewMutationVariables>(RenameProgramReviewDocument, options);
}
export type RenameProgramReviewMutationHookResult = ReturnType<typeof useRenameProgramReviewMutation>;
export type RenameProgramReviewMutationResult = Apollo.MutationResult<GqlClientRenameProgramReviewMutation>;
export type RenameProgramReviewMutationOptions = Apollo.BaseMutationOptions<GqlClientRenameProgramReviewMutation, GqlClientRenameProgramReviewMutationVariables>;
export const SelectDataLibraryDocument = gql`
    mutation selectDataLibrary($input: SelectDataLibraryInput!) {
        selectDataLibrary(input: $input) {
            result
            editorProgramVersion {
                id
                accountDataLibraryVersion {
                    id
                }
            }
        }
    }
`;
export type GqlClientSelectDataLibraryMutationFn = Apollo.MutationFunction<GqlClientSelectDataLibraryMutation, GqlClientSelectDataLibraryMutationVariables>;
export type GqlClientSelectDataLibraryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientSelectDataLibraryMutation, GqlClientSelectDataLibraryMutationVariables>;
} & TChildProps;
export function withSelectDataLibrary<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientSelectDataLibraryMutation, GqlClientSelectDataLibraryMutationVariables, GqlClientSelectDataLibraryProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientSelectDataLibraryMutation, GqlClientSelectDataLibraryMutationVariables, GqlClientSelectDataLibraryProps<TChildProps, TDataName>>(
        SelectDataLibraryDocument,
        {
            alias: "selectDataLibrary",
            ...operationOptions
        }
    );
}

/**
 * __useSelectDataLibraryMutation__
 *
 * To run a mutation, you first call `useSelectDataLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectDataLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectDataLibraryMutation, { data, loading, error }] = useSelectDataLibraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectDataLibraryMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientSelectDataLibraryMutation, GqlClientSelectDataLibraryMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientSelectDataLibraryMutation, GqlClientSelectDataLibraryMutationVariables>(SelectDataLibraryDocument, options);
}
export type SelectDataLibraryMutationHookResult = ReturnType<typeof useSelectDataLibraryMutation>;
export type SelectDataLibraryMutationResult = Apollo.MutationResult<GqlClientSelectDataLibraryMutation>;
export type SelectDataLibraryMutationOptions = Apollo.BaseMutationOptions<GqlClientSelectDataLibraryMutation, GqlClientSelectDataLibraryMutationVariables>;
export const SwitchBcCollectionAndDeleteCurrentDocument = gql`
    mutation SwitchBcCollectionAndDeleteCurrent($input: SwitchBcCollectionAndDeleteCurrentInput!) {
        switchBcCollectionAndDeleteCurrent(input: $input) {
            result
            brandConfigurationVersion {
                id
                brandConfigurationCollections {
                    ...EditorBrand
                }
                defaultBrandConfigurationCollection {
                    id
                }
            }
            editorProgramVersions {
                id
                bcUseRules
                bcRules
                selectedBrandConfiguration {
                    id
                }
                updated
                updatedBy
                anyUpdated
                anyUpdatedBy
            }
        }
    }
    ${EditorBrandFragmentDoc}
`;
export type GqlClientSwitchBcCollectionAndDeleteCurrentMutationFn = Apollo.MutationFunction<
    GqlClientSwitchBcCollectionAndDeleteCurrentMutation,
    GqlClientSwitchBcCollectionAndDeleteCurrentMutationVariables
>;
export type GqlClientSwitchBcCollectionAndDeleteCurrentProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientSwitchBcCollectionAndDeleteCurrentMutation, GqlClientSwitchBcCollectionAndDeleteCurrentMutationVariables>;
} & TChildProps;
export function withSwitchBcCollectionAndDeleteCurrent<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientSwitchBcCollectionAndDeleteCurrentMutation,
        GqlClientSwitchBcCollectionAndDeleteCurrentMutationVariables,
        GqlClientSwitchBcCollectionAndDeleteCurrentProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientSwitchBcCollectionAndDeleteCurrentMutation,
        GqlClientSwitchBcCollectionAndDeleteCurrentMutationVariables,
        GqlClientSwitchBcCollectionAndDeleteCurrentProps<TChildProps, TDataName>
    >(SwitchBcCollectionAndDeleteCurrentDocument, {
        alias: "switchBcCollectionAndDeleteCurrent",
        ...operationOptions
    });
}

/**
 * __useSwitchBcCollectionAndDeleteCurrentMutation__
 *
 * To run a mutation, you first call `useSwitchBcCollectionAndDeleteCurrentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchBcCollectionAndDeleteCurrentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchBcCollectionAndDeleteCurrentMutation, { data, loading, error }] = useSwitchBcCollectionAndDeleteCurrentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwitchBcCollectionAndDeleteCurrentMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientSwitchBcCollectionAndDeleteCurrentMutation, GqlClientSwitchBcCollectionAndDeleteCurrentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientSwitchBcCollectionAndDeleteCurrentMutation, GqlClientSwitchBcCollectionAndDeleteCurrentMutationVariables>(SwitchBcCollectionAndDeleteCurrentDocument, options);
}
export type SwitchBcCollectionAndDeleteCurrentMutationHookResult = ReturnType<typeof useSwitchBcCollectionAndDeleteCurrentMutation>;
export type SwitchBcCollectionAndDeleteCurrentMutationResult = Apollo.MutationResult<GqlClientSwitchBcCollectionAndDeleteCurrentMutation>;
export type SwitchBcCollectionAndDeleteCurrentMutationOptions = Apollo.BaseMutationOptions<
    GqlClientSwitchBcCollectionAndDeleteCurrentMutation,
    GqlClientSwitchBcCollectionAndDeleteCurrentMutationVariables
>;
export const UndoActionDocument = gql`
    mutation undoAction($undoActionInput: UndoActionInput!) {
        undoAction(input: $undoActionInput) {
            result
            undoStacks {
                ...UndoStacks
            }
            message
        }
    }
    ${UndoStacksFragmentDoc}
`;
export type GqlClientUndoActionMutationFn = Apollo.MutationFunction<GqlClientUndoActionMutation, GqlClientUndoActionMutationVariables>;
export type GqlClientUndoActionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUndoActionMutation, GqlClientUndoActionMutationVariables>;
} & TChildProps;
export function withUndoAction<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUndoActionMutation, GqlClientUndoActionMutationVariables, GqlClientUndoActionProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUndoActionMutation, GqlClientUndoActionMutationVariables, GqlClientUndoActionProps<TChildProps, TDataName>>(UndoActionDocument, {
        alias: "undoAction",
        ...operationOptions
    });
}

/**
 * __useUndoActionMutation__
 *
 * To run a mutation, you first call `useUndoActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoActionMutation, { data, loading, error }] = useUndoActionMutation({
 *   variables: {
 *      undoActionInput: // value for 'undoActionInput'
 *   },
 * });
 */
export function useUndoActionMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUndoActionMutation, GqlClientUndoActionMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUndoActionMutation, GqlClientUndoActionMutationVariables>(UndoActionDocument, options);
}
export type UndoActionMutationHookResult = ReturnType<typeof useUndoActionMutation>;
export type UndoActionMutationResult = Apollo.MutationResult<GqlClientUndoActionMutation>;
export type UndoActionMutationOptions = Apollo.BaseMutationOptions<GqlClientUndoActionMutation, GqlClientUndoActionMutationVariables>;
export const UnselectDataLibraryDocument = gql`
    mutation unselectDataLibrary($input: UnselectDataLibraryInput!) {
        unselectDataLibrary(input: $input) {
            result
            editorProgramVersion {
                id
                accountDataLibraryVersion {
                    id
                }
            }
        }
    }
`;
export type GqlClientUnselectDataLibraryMutationFn = Apollo.MutationFunction<GqlClientUnselectDataLibraryMutation, GqlClientUnselectDataLibraryMutationVariables>;
export type GqlClientUnselectDataLibraryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUnselectDataLibraryMutation, GqlClientUnselectDataLibraryMutationVariables>;
} & TChildProps;
export function withUnselectDataLibrary<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUnselectDataLibraryMutation,
        GqlClientUnselectDataLibraryMutationVariables,
        GqlClientUnselectDataLibraryProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUnselectDataLibraryMutation, GqlClientUnselectDataLibraryMutationVariables, GqlClientUnselectDataLibraryProps<TChildProps, TDataName>>(
        UnselectDataLibraryDocument,
        {
            alias: "unselectDataLibrary",
            ...operationOptions
        }
    );
}

/**
 * __useUnselectDataLibraryMutation__
 *
 * To run a mutation, you first call `useUnselectDataLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnselectDataLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unselectDataLibraryMutation, { data, loading, error }] = useUnselectDataLibraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnselectDataLibraryMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUnselectDataLibraryMutation, GqlClientUnselectDataLibraryMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUnselectDataLibraryMutation, GqlClientUnselectDataLibraryMutationVariables>(UnselectDataLibraryDocument, options);
}
export type UnselectDataLibraryMutationHookResult = ReturnType<typeof useUnselectDataLibraryMutation>;
export type UnselectDataLibraryMutationResult = Apollo.MutationResult<GqlClientUnselectDataLibraryMutation>;
export type UnselectDataLibraryMutationOptions = Apollo.BaseMutationOptions<GqlClientUnselectDataLibraryMutation, GqlClientUnselectDataLibraryMutationVariables>;
export const UpdateAccountCopilotBriefDocument = gql`
    mutation UpdateAccountCopilotBrief($input: UpdateAccountCopilotBriefInput!) {
        updateAccountCopilotBrief(input: $input) {
            result
            ... on UpdateAccountCopilotBriefOutput {
                brief {
                    ...AccountCopilotBrief
                }
            }
        }
    }
    ${AccountCopilotBriefFragmentDoc}
`;
export type GqlClientUpdateAccountCopilotBriefMutationFn = Apollo.MutationFunction<GqlClientUpdateAccountCopilotBriefMutation, GqlClientUpdateAccountCopilotBriefMutationVariables>;
export type GqlClientUpdateAccountCopilotBriefProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateAccountCopilotBriefMutation, GqlClientUpdateAccountCopilotBriefMutationVariables>;
} & TChildProps;
export function withUpdateAccountCopilotBrief<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateAccountCopilotBriefMutation,
        GqlClientUpdateAccountCopilotBriefMutationVariables,
        GqlClientUpdateAccountCopilotBriefProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateAccountCopilotBriefMutation,
        GqlClientUpdateAccountCopilotBriefMutationVariables,
        GqlClientUpdateAccountCopilotBriefProps<TChildProps, TDataName>
    >(UpdateAccountCopilotBriefDocument, {
        alias: "updateAccountCopilotBrief",
        ...operationOptions
    });
}

/**
 * __useUpdateAccountCopilotBriefMutation__
 *
 * To run a mutation, you first call `useUpdateAccountCopilotBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountCopilotBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountCopilotBriefMutation, { data, loading, error }] = useUpdateAccountCopilotBriefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountCopilotBriefMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateAccountCopilotBriefMutation, GqlClientUpdateAccountCopilotBriefMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateAccountCopilotBriefMutation, GqlClientUpdateAccountCopilotBriefMutationVariables>(UpdateAccountCopilotBriefDocument, options);
}
export type UpdateAccountCopilotBriefMutationHookResult = ReturnType<typeof useUpdateAccountCopilotBriefMutation>;
export type UpdateAccountCopilotBriefMutationResult = Apollo.MutationResult<GqlClientUpdateAccountCopilotBriefMutation>;
export type UpdateAccountCopilotBriefMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateAccountCopilotBriefMutation, GqlClientUpdateAccountCopilotBriefMutationVariables>;
export const UpdateAccountDataLibraryConnectorTypeAndMappingDocument = gql`
    mutation updateAccountDataLibraryConnectorTypeAndMapping($input: UpdateAccountDataLibraryConnectorTypeAndMappingInput!) {
        updateAccountDataLibraryConnectorTypeAndMapping(input: $input) {
            result
            ... on UpdateAccountDataLibraryConnectorTypeAndMappingOutputSuccess {
                accountDataLibraryVersion {
                    ...AccountDataLibraryVersion
                }
            }
        }
    }
    ${AccountDataLibraryVersionFragmentDoc}
`;
export type GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutationFn = Apollo.MutationFunction<
    GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutation,
    GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutationVariables
>;
export type GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutation, GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutationVariables>;
} & TChildProps;
export function withUpdateAccountDataLibraryConnectorTypeAndMapping<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutation,
        GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutationVariables,
        GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutation,
        GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutationVariables,
        GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingProps<TChildProps, TDataName>
    >(UpdateAccountDataLibraryConnectorTypeAndMappingDocument, {
        alias: "updateAccountDataLibraryConnectorTypeAndMapping",
        ...operationOptions
    });
}

/**
 * __useUpdateAccountDataLibraryConnectorTypeAndMappingMutation__
 *
 * To run a mutation, you first call `useUpdateAccountDataLibraryConnectorTypeAndMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountDataLibraryConnectorTypeAndMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountDataLibraryConnectorTypeAndMappingMutation, { data, loading, error }] = useUpdateAccountDataLibraryConnectorTypeAndMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountDataLibraryConnectorTypeAndMappingMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutation, GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutation, GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutationVariables>(
        UpdateAccountDataLibraryConnectorTypeAndMappingDocument,
        options
    );
}
export type UpdateAccountDataLibraryConnectorTypeAndMappingMutationHookResult = ReturnType<typeof useUpdateAccountDataLibraryConnectorTypeAndMappingMutation>;
export type UpdateAccountDataLibraryConnectorTypeAndMappingMutationResult = Apollo.MutationResult<GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutation>;
export type UpdateAccountDataLibraryConnectorTypeAndMappingMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutation,
    GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutationVariables
>;
export const UpdateAccountDataLibraryMetadataDocument = gql`
    mutation updateAccountDataLibraryMetadata($input: UpdateAccountDataLibraryMetadataInput!) {
        updateAccountDataLibraryMetadata(input: $input) {
            result
            ... on UpdateAccountDataLibraryMetadataOutputSuccess {
                accountDataLibraryVersion {
                    ...AccountDataLibraryVersion
                }
            }
        }
    }
    ${AccountDataLibraryVersionFragmentDoc}
`;
export type GqlClientUpdateAccountDataLibraryMetadataMutationFn = Apollo.MutationFunction<
    GqlClientUpdateAccountDataLibraryMetadataMutation,
    GqlClientUpdateAccountDataLibraryMetadataMutationVariables
>;
export type GqlClientUpdateAccountDataLibraryMetadataProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateAccountDataLibraryMetadataMutation, GqlClientUpdateAccountDataLibraryMetadataMutationVariables>;
} & TChildProps;
export function withUpdateAccountDataLibraryMetadata<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateAccountDataLibraryMetadataMutation,
        GqlClientUpdateAccountDataLibraryMetadataMutationVariables,
        GqlClientUpdateAccountDataLibraryMetadataProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateAccountDataLibraryMetadataMutation,
        GqlClientUpdateAccountDataLibraryMetadataMutationVariables,
        GqlClientUpdateAccountDataLibraryMetadataProps<TChildProps, TDataName>
    >(UpdateAccountDataLibraryMetadataDocument, {
        alias: "updateAccountDataLibraryMetadata",
        ...operationOptions
    });
}

/**
 * __useUpdateAccountDataLibraryMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateAccountDataLibraryMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountDataLibraryMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountDataLibraryMetadataMutation, { data, loading, error }] = useUpdateAccountDataLibraryMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountDataLibraryMetadataMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateAccountDataLibraryMetadataMutation, GqlClientUpdateAccountDataLibraryMetadataMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateAccountDataLibraryMetadataMutation, GqlClientUpdateAccountDataLibraryMetadataMutationVariables>(UpdateAccountDataLibraryMetadataDocument, options);
}
export type UpdateAccountDataLibraryMetadataMutationHookResult = ReturnType<typeof useUpdateAccountDataLibraryMetadataMutation>;
export type UpdateAccountDataLibraryMetadataMutationResult = Apollo.MutationResult<GqlClientUpdateAccountDataLibraryMetadataMutation>;
export type UpdateAccountDataLibraryMetadataMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateAccountDataLibraryMetadataMutation, GqlClientUpdateAccountDataLibraryMetadataMutationVariables>;
export const UpdateBrandDocument = gql`
    mutation UpdateBrand($input: UpdateBcCollectionWithColorsAndTextStylesInput!, $dryRun: Boolean) {
        updateBcCollectionWithColorsAndTextStyles(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateBcCollectionWithColorsAndTextStylesOutputSuccess {
                brandConfigurationCollection {
                    ...EditorBrand
                }
            }
            ... on UpdateBcCollectionWithColorsAndTextStylesOutputConflict {
                brandConfigurationCollection {
                    ...EditorBrand
                }
            }
        }
    }
    ${EditorBrandFragmentDoc}
`;
export type GqlClientUpdateBrandMutationFn = Apollo.MutationFunction<GqlClientUpdateBrandMutation, GqlClientUpdateBrandMutationVariables>;
export type GqlClientUpdateBrandProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateBrandMutation, GqlClientUpdateBrandMutationVariables>;
} & TChildProps;
export function withUpdateBrand<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateBrandMutation, GqlClientUpdateBrandMutationVariables, GqlClientUpdateBrandProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateBrandMutation, GqlClientUpdateBrandMutationVariables, GqlClientUpdateBrandProps<TChildProps, TDataName>>(UpdateBrandDocument, {
        alias: "updateBrand",
        ...operationOptions
    });
}

/**
 * __useUpdateBrandMutation__
 *
 * To run a mutation, you first call `useUpdateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandMutation, { data, loading, error }] = useUpdateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateBrandMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateBrandMutation, GqlClientUpdateBrandMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateBrandMutation, GqlClientUpdateBrandMutationVariables>(UpdateBrandDocument, options);
}
export type UpdateBrandMutationHookResult = ReturnType<typeof useUpdateBrandMutation>;
export type UpdateBrandMutationResult = Apollo.MutationResult<GqlClientUpdateBrandMutation>;
export type UpdateBrandMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateBrandMutation, GqlClientUpdateBrandMutationVariables>;
export const UpdateProjectAudioSettingsDocument = gql`
    mutation updateProjectAudioSettings($updateProjectAudioSettingsInput: UpdateProjectAudioSettingsInput!) {
        updateProjectAudioSettings(input: $updateProjectAudioSettingsInput) {
            editorProgramVersion {
                id
                audioSettings
            }
        }
    }
`;
export type GqlClientUpdateProjectAudioSettingsMutationFn = Apollo.MutationFunction<GqlClientUpdateProjectAudioSettingsMutation, GqlClientUpdateProjectAudioSettingsMutationVariables>;
export type GqlClientUpdateProjectAudioSettingsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateProjectAudioSettingsMutation, GqlClientUpdateProjectAudioSettingsMutationVariables>;
} & TChildProps;
export function withUpdateProjectAudioSettings<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateProjectAudioSettingsMutation,
        GqlClientUpdateProjectAudioSettingsMutationVariables,
        GqlClientUpdateProjectAudioSettingsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateProjectAudioSettingsMutation,
        GqlClientUpdateProjectAudioSettingsMutationVariables,
        GqlClientUpdateProjectAudioSettingsProps<TChildProps, TDataName>
    >(UpdateProjectAudioSettingsDocument, {
        alias: "updateProjectAudioSettings",
        ...operationOptions
    });
}

/**
 * __useUpdateProjectAudioSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectAudioSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectAudioSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectAudioSettingsMutation, { data, loading, error }] = useUpdateProjectAudioSettingsMutation({
 *   variables: {
 *      updateProjectAudioSettingsInput: // value for 'updateProjectAudioSettingsInput'
 *   },
 * });
 */
export function useUpdateProjectAudioSettingsMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateProjectAudioSettingsMutation, GqlClientUpdateProjectAudioSettingsMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateProjectAudioSettingsMutation, GqlClientUpdateProjectAudioSettingsMutationVariables>(UpdateProjectAudioSettingsDocument, options);
}
export type UpdateProjectAudioSettingsMutationHookResult = ReturnType<typeof useUpdateProjectAudioSettingsMutation>;
export type UpdateProjectAudioSettingsMutationResult = Apollo.MutationResult<GqlClientUpdateProjectAudioSettingsMutation>;
export type UpdateProjectAudioSettingsMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateProjectAudioSettingsMutation, GqlClientUpdateProjectAudioSettingsMutationVariables>;
export const UpdateEditorAvatarPlaceholderDocument = gql`
    mutation updateEditorAvatarPlaceholder($input: UpdateEditorAvatarPlaceholderInput!) {
        updateEditorAvatarPlaceholder(input: $input) {
            result
            editorPlaceholders {
                ...EditorPlaceholderConfiguration
            }
        }
    }
    ${EditorPlaceholderConfigurationFragmentDoc}
`;
export type GqlClientUpdateEditorAvatarPlaceholderMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorAvatarPlaceholderMutation, GqlClientUpdateEditorAvatarPlaceholderMutationVariables>;
export type GqlClientUpdateEditorAvatarPlaceholderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorAvatarPlaceholderMutation, GqlClientUpdateEditorAvatarPlaceholderMutationVariables>;
} & TChildProps;
export function withUpdateEditorAvatarPlaceholder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorAvatarPlaceholderMutation,
        GqlClientUpdateEditorAvatarPlaceholderMutationVariables,
        GqlClientUpdateEditorAvatarPlaceholderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorAvatarPlaceholderMutation,
        GqlClientUpdateEditorAvatarPlaceholderMutationVariables,
        GqlClientUpdateEditorAvatarPlaceholderProps<TChildProps, TDataName>
    >(UpdateEditorAvatarPlaceholderDocument, {
        alias: "updateEditorAvatarPlaceholder",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorAvatarPlaceholderMutation__
 *
 * To run a mutation, you first call `useUpdateEditorAvatarPlaceholderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorAvatarPlaceholderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorAvatarPlaceholderMutation, { data, loading, error }] = useUpdateEditorAvatarPlaceholderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorAvatarPlaceholderMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorAvatarPlaceholderMutation, GqlClientUpdateEditorAvatarPlaceholderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorAvatarPlaceholderMutation, GqlClientUpdateEditorAvatarPlaceholderMutationVariables>(UpdateEditorAvatarPlaceholderDocument, options);
}
export type UpdateEditorAvatarPlaceholderMutationHookResult = ReturnType<typeof useUpdateEditorAvatarPlaceholderMutation>;
export type UpdateEditorAvatarPlaceholderMutationResult = Apollo.MutationResult<GqlClientUpdateEditorAvatarPlaceholderMutation>;
export type UpdateEditorAvatarPlaceholderMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorAvatarPlaceholderMutation, GqlClientUpdateEditorAvatarPlaceholderMutationVariables>;
export const UpdateEditorProgramVersionBrandRulesDocument = gql`
    mutation updateEditorProgramVersionBrandRules($updateEditorProgramVersionBrandRulesInput: UpdateEditorProgramVersionBrandRulesInput!) {
        updateEditorProgramVersionBrandRules(input: $updateEditorProgramVersionBrandRulesInput) {
            programVersion {
                id
                bcUseRules
                bcRules
            }
        }
    }
`;
export type GqlClientUpdateEditorProgramVersionBrandRulesMutationFn = Apollo.MutationFunction<
    GqlClientUpdateEditorProgramVersionBrandRulesMutation,
    GqlClientUpdateEditorProgramVersionBrandRulesMutationVariables
>;
export type GqlClientUpdateEditorProgramVersionBrandRulesProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorProgramVersionBrandRulesMutation, GqlClientUpdateEditorProgramVersionBrandRulesMutationVariables>;
} & TChildProps;
export function withUpdateEditorProgramVersionBrandRules<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorProgramVersionBrandRulesMutation,
        GqlClientUpdateEditorProgramVersionBrandRulesMutationVariables,
        GqlClientUpdateEditorProgramVersionBrandRulesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorProgramVersionBrandRulesMutation,
        GqlClientUpdateEditorProgramVersionBrandRulesMutationVariables,
        GqlClientUpdateEditorProgramVersionBrandRulesProps<TChildProps, TDataName>
    >(UpdateEditorProgramVersionBrandRulesDocument, {
        alias: "updateEditorProgramVersionBrandRules",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorProgramVersionBrandRulesMutation__
 *
 * To run a mutation, you first call `useUpdateEditorProgramVersionBrandRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorProgramVersionBrandRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorProgramVersionBrandRulesMutation, { data, loading, error }] = useUpdateEditorProgramVersionBrandRulesMutation({
 *   variables: {
 *      updateEditorProgramVersionBrandRulesInput: // value for 'updateEditorProgramVersionBrandRulesInput'
 *   },
 * });
 */
export function useUpdateEditorProgramVersionBrandRulesMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorProgramVersionBrandRulesMutation, GqlClientUpdateEditorProgramVersionBrandRulesMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorProgramVersionBrandRulesMutation, GqlClientUpdateEditorProgramVersionBrandRulesMutationVariables>(
        UpdateEditorProgramVersionBrandRulesDocument,
        options
    );
}
export type UpdateEditorProgramVersionBrandRulesMutationHookResult = ReturnType<typeof useUpdateEditorProgramVersionBrandRulesMutation>;
export type UpdateEditorProgramVersionBrandRulesMutationResult = Apollo.MutationResult<GqlClientUpdateEditorProgramVersionBrandRulesMutation>;
export type UpdateEditorProgramVersionBrandRulesMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateEditorProgramVersionBrandRulesMutation,
    GqlClientUpdateEditorProgramVersionBrandRulesMutationVariables
>;
export const UpdateEditorChapteringDocument = gql`
    mutation updateEditorChaptering($updateEditorChapteringInput: UpdateEditorChapteringInput!) {
        updateEditorChaptering(input: $updateEditorChapteringInput) {
            editorProgramVersion {
                id
                chapteringEnabled
            }
        }
    }
`;
export type GqlClientUpdateEditorChapteringMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorChapteringMutation, GqlClientUpdateEditorChapteringMutationVariables>;
export type GqlClientUpdateEditorChapteringProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorChapteringMutation, GqlClientUpdateEditorChapteringMutationVariables>;
} & TChildProps;
export function withUpdateEditorChaptering<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorChapteringMutation,
        GqlClientUpdateEditorChapteringMutationVariables,
        GqlClientUpdateEditorChapteringProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateEditorChapteringMutation, GqlClientUpdateEditorChapteringMutationVariables, GqlClientUpdateEditorChapteringProps<TChildProps, TDataName>>(
        UpdateEditorChapteringDocument,
        {
            alias: "updateEditorChaptering",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateEditorChapteringMutation__
 *
 * To run a mutation, you first call `useUpdateEditorChapteringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorChapteringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorChapteringMutation, { data, loading, error }] = useUpdateEditorChapteringMutation({
 *   variables: {
 *      updateEditorChapteringInput: // value for 'updateEditorChapteringInput'
 *   },
 * });
 */
export function useUpdateEditorChapteringMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorChapteringMutation, GqlClientUpdateEditorChapteringMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorChapteringMutation, GqlClientUpdateEditorChapteringMutationVariables>(UpdateEditorChapteringDocument, options);
}
export type UpdateEditorChapteringMutationHookResult = ReturnType<typeof useUpdateEditorChapteringMutation>;
export type UpdateEditorChapteringMutationResult = Apollo.MutationResult<GqlClientUpdateEditorChapteringMutation>;
export type UpdateEditorChapteringMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorChapteringMutation, GqlClientUpdateEditorChapteringMutationVariables>;
export const UpdateEditorLineupDocument = gql`
    mutation UpdateEditorLineup($input: UpdateEditorLineupInput!) {
        updateEditorLineup(input: $input) {
            result
            editorProgramVersion {
                id
                sceneLineup
            }
        }
    }
`;
export type GqlClientUpdateEditorLineupMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorLineupMutation, GqlClientUpdateEditorLineupMutationVariables>;
export type GqlClientUpdateEditorLineupProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorLineupMutation, GqlClientUpdateEditorLineupMutationVariables>;
} & TChildProps;
export function withUpdateEditorLineup<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorLineupMutation,
        GqlClientUpdateEditorLineupMutationVariables,
        GqlClientUpdateEditorLineupProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateEditorLineupMutation, GqlClientUpdateEditorLineupMutationVariables, GqlClientUpdateEditorLineupProps<TChildProps, TDataName>>(
        UpdateEditorLineupDocument,
        {
            alias: "updateEditorLineup",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateEditorLineupMutation__
 *
 * To run a mutation, you first call `useUpdateEditorLineupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorLineupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorLineupMutation, { data, loading, error }] = useUpdateEditorLineupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorLineupMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorLineupMutation, GqlClientUpdateEditorLineupMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorLineupMutation, GqlClientUpdateEditorLineupMutationVariables>(UpdateEditorLineupDocument, options);
}
export type UpdateEditorLineupMutationHookResult = ReturnType<typeof useUpdateEditorLineupMutation>;
export type UpdateEditorLineupMutationResult = Apollo.MutationResult<GqlClientUpdateEditorLineupMutation>;
export type UpdateEditorLineupMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorLineupMutation, GqlClientUpdateEditorLineupMutationVariables>;
export const UpdateEditorNarrationLibraryDocument = gql`
    mutation UpdateEditorNarrationLibrary($input: UpdateEditorNarrationLibraryInput!) {
        updateEditorNarrationLibrary(input: $input) {
            result
        }
    }
`;
export type GqlClientUpdateEditorNarrationLibraryMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorNarrationLibraryMutation, GqlClientUpdateEditorNarrationLibraryMutationVariables>;
export type GqlClientUpdateEditorNarrationLibraryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorNarrationLibraryMutation, GqlClientUpdateEditorNarrationLibraryMutationVariables>;
} & TChildProps;
export function withUpdateEditorNarrationLibrary<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorNarrationLibraryMutation,
        GqlClientUpdateEditorNarrationLibraryMutationVariables,
        GqlClientUpdateEditorNarrationLibraryProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorNarrationLibraryMutation,
        GqlClientUpdateEditorNarrationLibraryMutationVariables,
        GqlClientUpdateEditorNarrationLibraryProps<TChildProps, TDataName>
    >(UpdateEditorNarrationLibraryDocument, {
        alias: "updateEditorNarrationLibrary",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorNarrationLibraryMutation__
 *
 * To run a mutation, you first call `useUpdateEditorNarrationLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorNarrationLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorNarrationLibraryMutation, { data, loading, error }] = useUpdateEditorNarrationLibraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorNarrationLibraryMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorNarrationLibraryMutation, GqlClientUpdateEditorNarrationLibraryMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorNarrationLibraryMutation, GqlClientUpdateEditorNarrationLibraryMutationVariables>(UpdateEditorNarrationLibraryDocument, options);
}
export type UpdateEditorNarrationLibraryMutationHookResult = ReturnType<typeof useUpdateEditorNarrationLibraryMutation>;
export type UpdateEditorNarrationLibraryMutationResult = Apollo.MutationResult<GqlClientUpdateEditorNarrationLibraryMutation>;
export type UpdateEditorNarrationLibraryMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorNarrationLibraryMutation, GqlClientUpdateEditorNarrationLibraryMutationVariables>;
export const UpdateEditorPlaceholderDocument = gql`
    mutation updateEditorPlaceholder($updateEditorPlaceholderInput: UpdateEditorPlaceholderInput!) {
        updateEditorPlaceholder(input: $updateEditorPlaceholderInput) {
            ... on UpdateEditorPlaceholderOutputSuccess {
                result
                editorPlaceholder {
                    ...EditorPlaceholderConfiguration
                }
            }
            ... on UpdateEditorPlaceholderOutputConflict {
                result
                editorPlaceholder {
                    ...EditorPlaceholderConfiguration
                }
            }
        }
    }
    ${EditorPlaceholderConfigurationFragmentDoc}
`;
export type GqlClientUpdateEditorPlaceholderMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorPlaceholderMutation, GqlClientUpdateEditorPlaceholderMutationVariables>;
export type GqlClientUpdateEditorPlaceholderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorPlaceholderMutation, GqlClientUpdateEditorPlaceholderMutationVariables>;
} & TChildProps;
export function withUpdateEditorPlaceholder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorPlaceholderMutation,
        GqlClientUpdateEditorPlaceholderMutationVariables,
        GqlClientUpdateEditorPlaceholderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorPlaceholderMutation,
        GqlClientUpdateEditorPlaceholderMutationVariables,
        GqlClientUpdateEditorPlaceholderProps<TChildProps, TDataName>
    >(UpdateEditorPlaceholderDocument, {
        alias: "updateEditorPlaceholder",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorPlaceholderMutation__
 *
 * To run a mutation, you first call `useUpdateEditorPlaceholderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorPlaceholderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorPlaceholderMutation, { data, loading, error }] = useUpdateEditorPlaceholderMutation({
 *   variables: {
 *      updateEditorPlaceholderInput: // value for 'updateEditorPlaceholderInput'
 *   },
 * });
 */
export function useUpdateEditorPlaceholderMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorPlaceholderMutation, GqlClientUpdateEditorPlaceholderMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorPlaceholderMutation, GqlClientUpdateEditorPlaceholderMutationVariables>(UpdateEditorPlaceholderDocument, options);
}
export type UpdateEditorPlaceholderMutationHookResult = ReturnType<typeof useUpdateEditorPlaceholderMutation>;
export type UpdateEditorPlaceholderMutationResult = Apollo.MutationResult<GqlClientUpdateEditorPlaceholderMutation>;
export type UpdateEditorPlaceholderMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorPlaceholderMutation, GqlClientUpdateEditorPlaceholderMutationVariables>;
export const UpdateEditorProgramVersionAspectRatioDocument = gql`
    mutation updateEditorProgramVersionAspectRatio($input: UpdateEditorProgramVersionAspectRatioInput!) {
        updateEditorProgramVersionAspectRatio(input: $input) {
            ... on UpdateEditorProgramVersionAspectRatioOutputSuccess {
                result
                programVersion {
                    id
                    aspectRatio
                    scenes {
                        id
                        ccAnimatedWireframe {
                            ...EditorAnimatedWireframe
                        }
                    }
                }
            }
            ... on UpdateEditorProgramVersionAspectRatioOutputConflict {
                result
                programVersion {
                    id
                    aspectRatio
                    scenes {
                        id
                        ccAnimatedWireframe {
                            ...EditorAnimatedWireframe
                        }
                    }
                }
            }
        }
    }
    ${EditorAnimatedWireframeFragmentDoc}
`;
export type GqlClientUpdateEditorProgramVersionAspectRatioMutationFn = Apollo.MutationFunction<
    GqlClientUpdateEditorProgramVersionAspectRatioMutation,
    GqlClientUpdateEditorProgramVersionAspectRatioMutationVariables
>;
export type GqlClientUpdateEditorProgramVersionAspectRatioProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorProgramVersionAspectRatioMutation, GqlClientUpdateEditorProgramVersionAspectRatioMutationVariables>;
} & TChildProps;
export function withUpdateEditorProgramVersionAspectRatio<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorProgramVersionAspectRatioMutation,
        GqlClientUpdateEditorProgramVersionAspectRatioMutationVariables,
        GqlClientUpdateEditorProgramVersionAspectRatioProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorProgramVersionAspectRatioMutation,
        GqlClientUpdateEditorProgramVersionAspectRatioMutationVariables,
        GqlClientUpdateEditorProgramVersionAspectRatioProps<TChildProps, TDataName>
    >(UpdateEditorProgramVersionAspectRatioDocument, {
        alias: "updateEditorProgramVersionAspectRatio",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorProgramVersionAspectRatioMutation__
 *
 * To run a mutation, you first call `useUpdateEditorProgramVersionAspectRatioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorProgramVersionAspectRatioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorProgramVersionAspectRatioMutation, { data, loading, error }] = useUpdateEditorProgramVersionAspectRatioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorProgramVersionAspectRatioMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorProgramVersionAspectRatioMutation, GqlClientUpdateEditorProgramVersionAspectRatioMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorProgramVersionAspectRatioMutation, GqlClientUpdateEditorProgramVersionAspectRatioMutationVariables>(
        UpdateEditorProgramVersionAspectRatioDocument,
        options
    );
}
export type UpdateEditorProgramVersionAspectRatioMutationHookResult = ReturnType<typeof useUpdateEditorProgramVersionAspectRatioMutation>;
export type UpdateEditorProgramVersionAspectRatioMutationResult = Apollo.MutationResult<GqlClientUpdateEditorProgramVersionAspectRatioMutation>;
export type UpdateEditorProgramVersionAspectRatioMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateEditorProgramVersionAspectRatioMutation,
    GqlClientUpdateEditorProgramVersionAspectRatioMutationVariables
>;
export const UpdateEditorSceneDocument = gql`
    mutation updateEditorScene($updateEditorSceneInput: UpdateEditorSceneInput!) {
        updateEditorScene(input: $updateEditorSceneInput) {
            ... on UpdateEditorSceneOutputSuccess {
                result
                editorScene {
                    ...EditorSceneConfiguration
                }
            }
            ... on UpdateEditorSceneOutputConflict {
                result
                editorScene {
                    ...EditorSceneConfiguration
                }
            }
        }
    }
    ${EditorSceneConfigurationFragmentDoc}
`;
export type GqlClientUpdateEditorSceneMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorSceneMutation, GqlClientUpdateEditorSceneMutationVariables>;
export type GqlClientUpdateEditorSceneProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorSceneMutation, GqlClientUpdateEditorSceneMutationVariables>;
} & TChildProps;
export function withUpdateEditorScene<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateEditorSceneMutation, GqlClientUpdateEditorSceneMutationVariables, GqlClientUpdateEditorSceneProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateEditorSceneMutation, GqlClientUpdateEditorSceneMutationVariables, GqlClientUpdateEditorSceneProps<TChildProps, TDataName>>(
        UpdateEditorSceneDocument,
        {
            alias: "updateEditorScene",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateEditorSceneMutation__
 *
 * To run a mutation, you first call `useUpdateEditorSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorSceneMutation, { data, loading, error }] = useUpdateEditorSceneMutation({
 *   variables: {
 *      updateEditorSceneInput: // value for 'updateEditorSceneInput'
 *   },
 * });
 */
export function useUpdateEditorSceneMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorSceneMutation, GqlClientUpdateEditorSceneMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorSceneMutation, GqlClientUpdateEditorSceneMutationVariables>(UpdateEditorSceneDocument, options);
}
export type UpdateEditorSceneMutationHookResult = ReturnType<typeof useUpdateEditorSceneMutation>;
export type UpdateEditorSceneMutationResult = Apollo.MutationResult<GqlClientUpdateEditorSceneMutation>;
export type UpdateEditorSceneMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorSceneMutation, GqlClientUpdateEditorSceneMutationVariables>;
export const UpdateEditorSceneSelectedAnimatedWireframeDocument = gql`
    mutation updateEditorSceneSelectedAnimatedWireframe($input: UpdateEditorSceneSelectedAnimatedWireframeInput!) {
        updateEditorSceneSelectedAnimatedWireframe(input: $input) {
            ... on UpdateEditorSceneSelectedAnimatedWireframeOutputSuccess {
                result
                editorScene {
                    ...EditorSceneAnimatedWireframe
                }
            }
            ... on UpdateEditorSceneSelectedAnimatedWireframeOutputConflict {
                result
                editorScene {
                    ...EditorSceneAnimatedWireframe
                }
            }
        }
    }
    ${EditorSceneAnimatedWireframeFragmentDoc}
`;
export type GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutationFn = Apollo.MutationFunction<
    GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutation,
    GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutationVariables
>;
export type GqlClientUpdateEditorSceneSelectedAnimatedWireframeProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutation, GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutationVariables>;
} & TChildProps;
export function withUpdateEditorSceneSelectedAnimatedWireframe<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutation,
        GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutationVariables,
        GqlClientUpdateEditorSceneSelectedAnimatedWireframeProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutation,
        GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutationVariables,
        GqlClientUpdateEditorSceneSelectedAnimatedWireframeProps<TChildProps, TDataName>
    >(UpdateEditorSceneSelectedAnimatedWireframeDocument, {
        alias: "updateEditorSceneSelectedAnimatedWireframe",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorSceneSelectedAnimatedWireframeMutation__
 *
 * To run a mutation, you first call `useUpdateEditorSceneSelectedAnimatedWireframeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorSceneSelectedAnimatedWireframeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorSceneSelectedAnimatedWireframeMutation, { data, loading, error }] = useUpdateEditorSceneSelectedAnimatedWireframeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorSceneSelectedAnimatedWireframeMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutation, GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutation, GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutationVariables>(
        UpdateEditorSceneSelectedAnimatedWireframeDocument,
        options
    );
}
export type UpdateEditorSceneSelectedAnimatedWireframeMutationHookResult = ReturnType<typeof useUpdateEditorSceneSelectedAnimatedWireframeMutation>;
export type UpdateEditorSceneSelectedAnimatedWireframeMutationResult = Apollo.MutationResult<GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutation>;
export type UpdateEditorSceneSelectedAnimatedWireframeMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutation,
    GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutationVariables
>;
export const UpdateEditorSelectedBrandDocument = gql`
    mutation updateEditorSelectedBrand($input: UpdateEditorSelectedBcCollectionInput!) {
        updateEditorSelectedBcCollection(input: $input) {
            ... on UpdateEditorSelectedBcCollectionOutputSuccess {
                result
                editorProgramVersion {
                    id
                    selectedBrandConfiguration {
                        id
                    }
                }
            }
            ... on UpdateEditorSelectedBcCollectionOutputConflict {
                result
                editorProgramVersion {
                    id
                    selectedBrandConfiguration {
                        id
                    }
                }
            }
        }
    }
`;
export type GqlClientUpdateEditorSelectedBrandMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorSelectedBrandMutation, GqlClientUpdateEditorSelectedBrandMutationVariables>;
export type GqlClientUpdateEditorSelectedBrandProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorSelectedBrandMutation, GqlClientUpdateEditorSelectedBrandMutationVariables>;
} & TChildProps;
export function withUpdateEditorSelectedBrand<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorSelectedBrandMutation,
        GqlClientUpdateEditorSelectedBrandMutationVariables,
        GqlClientUpdateEditorSelectedBrandProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorSelectedBrandMutation,
        GqlClientUpdateEditorSelectedBrandMutationVariables,
        GqlClientUpdateEditorSelectedBrandProps<TChildProps, TDataName>
    >(UpdateEditorSelectedBrandDocument, {
        alias: "updateEditorSelectedBrand",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorSelectedBrandMutation__
 *
 * To run a mutation, you first call `useUpdateEditorSelectedBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorSelectedBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorSelectedBrandMutation, { data, loading, error }] = useUpdateEditorSelectedBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorSelectedBrandMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorSelectedBrandMutation, GqlClientUpdateEditorSelectedBrandMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorSelectedBrandMutation, GqlClientUpdateEditorSelectedBrandMutationVariables>(UpdateEditorSelectedBrandDocument, options);
}
export type UpdateEditorSelectedBrandMutationHookResult = ReturnType<typeof useUpdateEditorSelectedBrandMutation>;
export type UpdateEditorSelectedBrandMutationResult = Apollo.MutationResult<GqlClientUpdateEditorSelectedBrandMutation>;
export type UpdateEditorSelectedBrandMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorSelectedBrandMutation, GqlClientUpdateEditorSelectedBrandMutationVariables>;
export const UpdateEditorSelectedMusicDocument = gql`
    mutation updateEditorSelectedMusic($updateEditorSelectedMusicInput: UpdateEditorSelectedMusicInput!) {
        updateEditorSelectedMusic(input: $updateEditorSelectedMusicInput) {
            editorProgramVersion {
                id
                ...EditorProgramSelectedMusic
            }
        }
    }
    ${EditorProgramSelectedMusicFragmentDoc}
`;
export type GqlClientUpdateEditorSelectedMusicMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorSelectedMusicMutation, GqlClientUpdateEditorSelectedMusicMutationVariables>;
export type GqlClientUpdateEditorSelectedMusicProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorSelectedMusicMutation, GqlClientUpdateEditorSelectedMusicMutationVariables>;
} & TChildProps;
export function withUpdateEditorSelectedMusic<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorSelectedMusicMutation,
        GqlClientUpdateEditorSelectedMusicMutationVariables,
        GqlClientUpdateEditorSelectedMusicProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorSelectedMusicMutation,
        GqlClientUpdateEditorSelectedMusicMutationVariables,
        GqlClientUpdateEditorSelectedMusicProps<TChildProps, TDataName>
    >(UpdateEditorSelectedMusicDocument, {
        alias: "updateEditorSelectedMusic",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorSelectedMusicMutation__
 *
 * To run a mutation, you first call `useUpdateEditorSelectedMusicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorSelectedMusicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorSelectedMusicMutation, { data, loading, error }] = useUpdateEditorSelectedMusicMutation({
 *   variables: {
 *      updateEditorSelectedMusicInput: // value for 'updateEditorSelectedMusicInput'
 *   },
 * });
 */
export function useUpdateEditorSelectedMusicMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorSelectedMusicMutation, GqlClientUpdateEditorSelectedMusicMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorSelectedMusicMutation, GqlClientUpdateEditorSelectedMusicMutationVariables>(UpdateEditorSelectedMusicDocument, options);
}
export type UpdateEditorSelectedMusicMutationHookResult = ReturnType<typeof useUpdateEditorSelectedMusicMutation>;
export type UpdateEditorSelectedMusicMutationResult = Apollo.MutationResult<GqlClientUpdateEditorSelectedMusicMutation>;
export type UpdateEditorSelectedMusicMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorSelectedMusicMutation, GqlClientUpdateEditorSelectedMusicMutationVariables>;
export const UpdateEditorSelectedNarratorDocument = gql`
    mutation updateEditorSelectedNarrator($updateEditorSelectedNarratorInput: UpdateEditorSelectedNarratorInput!) {
        updateEditorSelectedNarrator(input: $updateEditorSelectedNarratorInput) {
            editorProgramVersion {
                id
                selectedNarrator {
                    ...EditorLibraryNarrator
                }
            }
        }
    }
    ${EditorLibraryNarratorFragmentDoc}
`;
export type GqlClientUpdateEditorSelectedNarratorMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorSelectedNarratorMutation, GqlClientUpdateEditorSelectedNarratorMutationVariables>;
export type GqlClientUpdateEditorSelectedNarratorProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorSelectedNarratorMutation, GqlClientUpdateEditorSelectedNarratorMutationVariables>;
} & TChildProps;
export function withUpdateEditorSelectedNarrator<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorSelectedNarratorMutation,
        GqlClientUpdateEditorSelectedNarratorMutationVariables,
        GqlClientUpdateEditorSelectedNarratorProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorSelectedNarratorMutation,
        GqlClientUpdateEditorSelectedNarratorMutationVariables,
        GqlClientUpdateEditorSelectedNarratorProps<TChildProps, TDataName>
    >(UpdateEditorSelectedNarratorDocument, {
        alias: "updateEditorSelectedNarrator",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorSelectedNarratorMutation__
 *
 * To run a mutation, you first call `useUpdateEditorSelectedNarratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorSelectedNarratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorSelectedNarratorMutation, { data, loading, error }] = useUpdateEditorSelectedNarratorMutation({
 *   variables: {
 *      updateEditorSelectedNarratorInput: // value for 'updateEditorSelectedNarratorInput'
 *   },
 * });
 */
export function useUpdateEditorSelectedNarratorMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorSelectedNarratorMutation, GqlClientUpdateEditorSelectedNarratorMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorSelectedNarratorMutation, GqlClientUpdateEditorSelectedNarratorMutationVariables>(UpdateEditorSelectedNarratorDocument, options);
}
export type UpdateEditorSelectedNarratorMutationHookResult = ReturnType<typeof useUpdateEditorSelectedNarratorMutation>;
export type UpdateEditorSelectedNarratorMutationResult = Apollo.MutationResult<GqlClientUpdateEditorSelectedNarratorMutation>;
export type UpdateEditorSelectedNarratorMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorSelectedNarratorMutation, GqlClientUpdateEditorSelectedNarratorMutationVariables>;
export const UpdateEditorTtsFullEnablementDocument = gql`
    mutation updateEditorTtsFullEnablement($updateEditorTtsFullEnablementInput: UpdateEditorTtsFullEnablementInput!) {
        updateEditorTtsFullEnablement(input: $updateEditorTtsFullEnablementInput) {
            ... on UpdateEditorTtsFullEnablementOutputSuccess {
                result
                editorProgramVersion {
                    id
                    editorTtsFullEnablement
                }
            }
            ... on UpdateEditorTtsFullEnablementOutputConflict {
                result
                editorProgramVersion {
                    id
                    editorTtsFullEnablement
                }
            }
        }
    }
`;
export type GqlClientUpdateEditorTtsFullEnablementMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorTtsFullEnablementMutation, GqlClientUpdateEditorTtsFullEnablementMutationVariables>;
export type GqlClientUpdateEditorTtsFullEnablementProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorTtsFullEnablementMutation, GqlClientUpdateEditorTtsFullEnablementMutationVariables>;
} & TChildProps;
export function withUpdateEditorTtsFullEnablement<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorTtsFullEnablementMutation,
        GqlClientUpdateEditorTtsFullEnablementMutationVariables,
        GqlClientUpdateEditorTtsFullEnablementProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorTtsFullEnablementMutation,
        GqlClientUpdateEditorTtsFullEnablementMutationVariables,
        GqlClientUpdateEditorTtsFullEnablementProps<TChildProps, TDataName>
    >(UpdateEditorTtsFullEnablementDocument, {
        alias: "updateEditorTtsFullEnablement",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorTtsFullEnablementMutation__
 *
 * To run a mutation, you first call `useUpdateEditorTtsFullEnablementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorTtsFullEnablementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorTtsFullEnablementMutation, { data, loading, error }] = useUpdateEditorTtsFullEnablementMutation({
 *   variables: {
 *      updateEditorTtsFullEnablementInput: // value for 'updateEditorTtsFullEnablementInput'
 *   },
 * });
 */
export function useUpdateEditorTtsFullEnablementMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorTtsFullEnablementMutation, GqlClientUpdateEditorTtsFullEnablementMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorTtsFullEnablementMutation, GqlClientUpdateEditorTtsFullEnablementMutationVariables>(UpdateEditorTtsFullEnablementDocument, options);
}
export type UpdateEditorTtsFullEnablementMutationHookResult = ReturnType<typeof useUpdateEditorTtsFullEnablementMutation>;
export type UpdateEditorTtsFullEnablementMutationResult = Apollo.MutationResult<GqlClientUpdateEditorTtsFullEnablementMutation>;
export type UpdateEditorTtsFullEnablementMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorTtsFullEnablementMutation, GqlClientUpdateEditorTtsFullEnablementMutationVariables>;
export const UpdateEditorVideoCustomBackgroundDocument = gql`
    mutation UpdateEditorVideoCustomBackground($input: VideoCustomBackgroundInput!) {
        updateEditorVideoCustomBackground(input: $input) {
            result
            editorProgramVersion {
                id
                useCustomVideoBackground
                videoBackgroundMedia {
                    id
                }
            }
        }
    }
`;
export type GqlClientUpdateEditorVideoCustomBackgroundMutationFn = Apollo.MutationFunction<
    GqlClientUpdateEditorVideoCustomBackgroundMutation,
    GqlClientUpdateEditorVideoCustomBackgroundMutationVariables
>;
export type GqlClientUpdateEditorVideoCustomBackgroundProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorVideoCustomBackgroundMutation, GqlClientUpdateEditorVideoCustomBackgroundMutationVariables>;
} & TChildProps;
export function withUpdateEditorVideoCustomBackground<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorVideoCustomBackgroundMutation,
        GqlClientUpdateEditorVideoCustomBackgroundMutationVariables,
        GqlClientUpdateEditorVideoCustomBackgroundProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorVideoCustomBackgroundMutation,
        GqlClientUpdateEditorVideoCustomBackgroundMutationVariables,
        GqlClientUpdateEditorVideoCustomBackgroundProps<TChildProps, TDataName>
    >(UpdateEditorVideoCustomBackgroundDocument, {
        alias: "updateEditorVideoCustomBackground",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorVideoCustomBackgroundMutation__
 *
 * To run a mutation, you first call `useUpdateEditorVideoCustomBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorVideoCustomBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorVideoCustomBackgroundMutation, { data, loading, error }] = useUpdateEditorVideoCustomBackgroundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorVideoCustomBackgroundMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorVideoCustomBackgroundMutation, GqlClientUpdateEditorVideoCustomBackgroundMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorVideoCustomBackgroundMutation, GqlClientUpdateEditorVideoCustomBackgroundMutationVariables>(UpdateEditorVideoCustomBackgroundDocument, options);
}
export type UpdateEditorVideoCustomBackgroundMutationHookResult = ReturnType<typeof useUpdateEditorVideoCustomBackgroundMutation>;
export type UpdateEditorVideoCustomBackgroundMutationResult = Apollo.MutationResult<GqlClientUpdateEditorVideoCustomBackgroundMutation>;
export type UpdateEditorVideoCustomBackgroundMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateEditorVideoCustomBackgroundMutation,
    GqlClientUpdateEditorVideoCustomBackgroundMutationVariables
>;
export const UpdateEditorViewerProfileDocument = gql`
    mutation updateEditorViewerProfile($updateEditorViewerProfileInput: UpdateEditorViewerProfileInput!) {
        updateEditorViewerProfile(input: $updateEditorViewerProfileInput) {
            ... on UpdateEditorViewerProfileOutputSuccess {
                result
                viewerProfile {
                    ...EditorViewerProfile
                }
            }
            ... on UpdateEditorViewerProfileOutputConflict {
                result
                viewerProfile {
                    ...EditorViewerProfile
                }
            }
        }
    }
    ${EditorViewerProfileFragmentDoc}
`;
export type GqlClientUpdateEditorViewerProfileMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorViewerProfileMutation, GqlClientUpdateEditorViewerProfileMutationVariables>;
export type GqlClientUpdateEditorViewerProfileProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorViewerProfileMutation, GqlClientUpdateEditorViewerProfileMutationVariables>;
} & TChildProps;
export function withUpdateEditorViewerProfile<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorViewerProfileMutation,
        GqlClientUpdateEditorViewerProfileMutationVariables,
        GqlClientUpdateEditorViewerProfileProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorViewerProfileMutation,
        GqlClientUpdateEditorViewerProfileMutationVariables,
        GqlClientUpdateEditorViewerProfileProps<TChildProps, TDataName>
    >(UpdateEditorViewerProfileDocument, {
        alias: "updateEditorViewerProfile",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorViewerProfileMutation__
 *
 * To run a mutation, you first call `useUpdateEditorViewerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorViewerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorViewerProfileMutation, { data, loading, error }] = useUpdateEditorViewerProfileMutation({
 *   variables: {
 *      updateEditorViewerProfileInput: // value for 'updateEditorViewerProfileInput'
 *   },
 * });
 */
export function useUpdateEditorViewerProfileMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorViewerProfileMutation, GqlClientUpdateEditorViewerProfileMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorViewerProfileMutation, GqlClientUpdateEditorViewerProfileMutationVariables>(UpdateEditorViewerProfileDocument, options);
}
export type UpdateEditorViewerProfileMutationHookResult = ReturnType<typeof useUpdateEditorViewerProfileMutation>;
export type UpdateEditorViewerProfileMutationResult = Apollo.MutationResult<GqlClientUpdateEditorViewerProfileMutation>;
export type UpdateEditorViewerProfileMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorViewerProfileMutation, GqlClientUpdateEditorViewerProfileMutationVariables>;
export const UpdateMediaLibraryGridColumnsDocument = gql`
    mutation updateMediaLibraryGridColumns($columns: Int!) {
        updateMediaLibraryGridColumns(columns: $columns) {
            result
            userSettings {
                id
                mediaLibraryGridColumns
            }
        }
    }
`;
export type GqlClientUpdateMediaLibraryGridColumnsMutationFn = Apollo.MutationFunction<GqlClientUpdateMediaLibraryGridColumnsMutation, GqlClientUpdateMediaLibraryGridColumnsMutationVariables>;
export type GqlClientUpdateMediaLibraryGridColumnsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateMediaLibraryGridColumnsMutation, GqlClientUpdateMediaLibraryGridColumnsMutationVariables>;
} & TChildProps;
export function withUpdateMediaLibraryGridColumns<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateMediaLibraryGridColumnsMutation,
        GqlClientUpdateMediaLibraryGridColumnsMutationVariables,
        GqlClientUpdateMediaLibraryGridColumnsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateMediaLibraryGridColumnsMutation,
        GqlClientUpdateMediaLibraryGridColumnsMutationVariables,
        GqlClientUpdateMediaLibraryGridColumnsProps<TChildProps, TDataName>
    >(UpdateMediaLibraryGridColumnsDocument, {
        alias: "updateMediaLibraryGridColumns",
        ...operationOptions
    });
}

/**
 * __useUpdateMediaLibraryGridColumnsMutation__
 *
 * To run a mutation, you first call `useUpdateMediaLibraryGridColumnsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaLibraryGridColumnsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaLibraryGridColumnsMutation, { data, loading, error }] = useUpdateMediaLibraryGridColumnsMutation({
 *   variables: {
 *      columns: // value for 'columns'
 *   },
 * });
 */
export function useUpdateMediaLibraryGridColumnsMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateMediaLibraryGridColumnsMutation, GqlClientUpdateMediaLibraryGridColumnsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateMediaLibraryGridColumnsMutation, GqlClientUpdateMediaLibraryGridColumnsMutationVariables>(UpdateMediaLibraryGridColumnsDocument, options);
}
export type UpdateMediaLibraryGridColumnsMutationHookResult = ReturnType<typeof useUpdateMediaLibraryGridColumnsMutation>;
export type UpdateMediaLibraryGridColumnsMutationResult = Apollo.MutationResult<GqlClientUpdateMediaLibraryGridColumnsMutation>;
export type UpdateMediaLibraryGridColumnsMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateMediaLibraryGridColumnsMutation, GqlClientUpdateMediaLibraryGridColumnsMutationVariables>;
export const UpdateMediaLibraryViewTypeDocument = gql`
    mutation updateMediaLibraryViewType($input: UpdateMediaLibraryViewTypeInput!) {
        updateMediaLibraryViewType(input: $input) {
            result
            userSettings {
                id
                mediaLibraryViewType
            }
        }
    }
`;
export type GqlClientUpdateMediaLibraryViewTypeMutationFn = Apollo.MutationFunction<GqlClientUpdateMediaLibraryViewTypeMutation, GqlClientUpdateMediaLibraryViewTypeMutationVariables>;
export type GqlClientUpdateMediaLibraryViewTypeProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateMediaLibraryViewTypeMutation, GqlClientUpdateMediaLibraryViewTypeMutationVariables>;
} & TChildProps;
export function withUpdateMediaLibraryViewType<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateMediaLibraryViewTypeMutation,
        GqlClientUpdateMediaLibraryViewTypeMutationVariables,
        GqlClientUpdateMediaLibraryViewTypeProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateMediaLibraryViewTypeMutation,
        GqlClientUpdateMediaLibraryViewTypeMutationVariables,
        GqlClientUpdateMediaLibraryViewTypeProps<TChildProps, TDataName>
    >(UpdateMediaLibraryViewTypeDocument, {
        alias: "updateMediaLibraryViewType",
        ...operationOptions
    });
}

/**
 * __useUpdateMediaLibraryViewTypeMutation__
 *
 * To run a mutation, you first call `useUpdateMediaLibraryViewTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaLibraryViewTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaLibraryViewTypeMutation, { data, loading, error }] = useUpdateMediaLibraryViewTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMediaLibraryViewTypeMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateMediaLibraryViewTypeMutation, GqlClientUpdateMediaLibraryViewTypeMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateMediaLibraryViewTypeMutation, GqlClientUpdateMediaLibraryViewTypeMutationVariables>(UpdateMediaLibraryViewTypeDocument, options);
}
export type UpdateMediaLibraryViewTypeMutationHookResult = ReturnType<typeof useUpdateMediaLibraryViewTypeMutation>;
export type UpdateMediaLibraryViewTypeMutationResult = Apollo.MutationResult<GqlClientUpdateMediaLibraryViewTypeMutation>;
export type UpdateMediaLibraryViewTypeMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateMediaLibraryViewTypeMutation, GqlClientUpdateMediaLibraryViewTypeMutationVariables>;
export const UpdateProgramReviewDocument = gql`
    mutation UpdateProgramReview($input: UpdateProgramReviewInput!) {
        updateProgramReview(input: $input) {
            result
            programReview {
                id
                reviewers
                description
            }
        }
    }
`;
export type GqlClientUpdateProgramReviewMutationFn = Apollo.MutationFunction<GqlClientUpdateProgramReviewMutation, GqlClientUpdateProgramReviewMutationVariables>;
export type GqlClientUpdateProgramReviewProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateProgramReviewMutation, GqlClientUpdateProgramReviewMutationVariables>;
} & TChildProps;
export function withUpdateProgramReview<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateProgramReviewMutation,
        GqlClientUpdateProgramReviewMutationVariables,
        GqlClientUpdateProgramReviewProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateProgramReviewMutation, GqlClientUpdateProgramReviewMutationVariables, GqlClientUpdateProgramReviewProps<TChildProps, TDataName>>(
        UpdateProgramReviewDocument,
        {
            alias: "updateProgramReview",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateProgramReviewMutation__
 *
 * To run a mutation, you first call `useUpdateProgramReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramReviewMutation, { data, loading, error }] = useUpdateProgramReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProgramReviewMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateProgramReviewMutation, GqlClientUpdateProgramReviewMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateProgramReviewMutation, GqlClientUpdateProgramReviewMutationVariables>(UpdateProgramReviewDocument, options);
}
export type UpdateProgramReviewMutationHookResult = ReturnType<typeof useUpdateProgramReviewMutation>;
export type UpdateProgramReviewMutationResult = Apollo.MutationResult<GqlClientUpdateProgramReviewMutation>;
export type UpdateProgramReviewMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateProgramReviewMutation, GqlClientUpdateProgramReviewMutationVariables>;
export const UpdatePronunciationDefinitionDocument = gql`
    mutation updatePronunciationDefinition($input: UpdatePronunciationDefinitionInput!) {
        updatePronunciationDefinition(input: $input) {
            pronunciationDefinition {
                ...AccountPronunciationDefinition
                pronunciationLibraryVersion {
                    id
                    anyUpdated
                    anyUpdatedBy
                }
            }
        }
    }
    ${AccountPronunciationDefinitionFragmentDoc}
`;
export type GqlClientUpdatePronunciationDefinitionMutationFn = Apollo.MutationFunction<GqlClientUpdatePronunciationDefinitionMutation, GqlClientUpdatePronunciationDefinitionMutationVariables>;
export type GqlClientUpdatePronunciationDefinitionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdatePronunciationDefinitionMutation, GqlClientUpdatePronunciationDefinitionMutationVariables>;
} & TChildProps;
export function withUpdatePronunciationDefinition<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdatePronunciationDefinitionMutation,
        GqlClientUpdatePronunciationDefinitionMutationVariables,
        GqlClientUpdatePronunciationDefinitionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdatePronunciationDefinitionMutation,
        GqlClientUpdatePronunciationDefinitionMutationVariables,
        GqlClientUpdatePronunciationDefinitionProps<TChildProps, TDataName>
    >(UpdatePronunciationDefinitionDocument, {
        alias: "updatePronunciationDefinition",
        ...operationOptions
    });
}

/**
 * __useUpdatePronunciationDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdatePronunciationDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePronunciationDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePronunciationDefinitionMutation, { data, loading, error }] = useUpdatePronunciationDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePronunciationDefinitionMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdatePronunciationDefinitionMutation, GqlClientUpdatePronunciationDefinitionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdatePronunciationDefinitionMutation, GqlClientUpdatePronunciationDefinitionMutationVariables>(UpdatePronunciationDefinitionDocument, options);
}
export type UpdatePronunciationDefinitionMutationHookResult = ReturnType<typeof useUpdatePronunciationDefinitionMutation>;
export type UpdatePronunciationDefinitionMutationResult = Apollo.MutationResult<GqlClientUpdatePronunciationDefinitionMutation>;
export type UpdatePronunciationDefinitionMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdatePronunciationDefinitionMutation, GqlClientUpdatePronunciationDefinitionMutationVariables>;
export const UpdateScenePlaceholdersAndAnimatedWireframeDocument = gql`
    mutation updateScenePlaceholdersAndAnimatedWireframe($input: UpdateScenePlaceholdersAndAnimatedWireframeInput!) {
        updateScenePlaceholdersAndAnimatedWireframe(input: $input) {
            ... on UpdateScenePlaceholdersAndAnimatedWireframeOutputSuccess {
                result
                editorScene {
                    ...EditorScenePlaceholdersSelectedAnimatedWireframeAllWireframesMapping
                }
            }
        }
    }
    ${EditorScenePlaceholdersSelectedAnimatedWireframeAllWireframesMappingFragmentDoc}
`;
export type GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutationFn = Apollo.MutationFunction<
    GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutation,
    GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutationVariables
>;
export type GqlClientUpdateScenePlaceholdersAndAnimatedWireframeProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutation, GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutationVariables>;
} & TChildProps;
export function withUpdateScenePlaceholdersAndAnimatedWireframe<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutation,
        GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutationVariables,
        GqlClientUpdateScenePlaceholdersAndAnimatedWireframeProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutation,
        GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutationVariables,
        GqlClientUpdateScenePlaceholdersAndAnimatedWireframeProps<TChildProps, TDataName>
    >(UpdateScenePlaceholdersAndAnimatedWireframeDocument, {
        alias: "updateScenePlaceholdersAndAnimatedWireframe",
        ...operationOptions
    });
}

/**
 * __useUpdateScenePlaceholdersAndAnimatedWireframeMutation__
 *
 * To run a mutation, you first call `useUpdateScenePlaceholdersAndAnimatedWireframeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenePlaceholdersAndAnimatedWireframeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenePlaceholdersAndAnimatedWireframeMutation, { data, loading, error }] = useUpdateScenePlaceholdersAndAnimatedWireframeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScenePlaceholdersAndAnimatedWireframeMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutation, GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutation, GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutationVariables>(
        UpdateScenePlaceholdersAndAnimatedWireframeDocument,
        options
    );
}
export type UpdateScenePlaceholdersAndAnimatedWireframeMutationHookResult = ReturnType<typeof useUpdateScenePlaceholdersAndAnimatedWireframeMutation>;
export type UpdateScenePlaceholdersAndAnimatedWireframeMutationResult = Apollo.MutationResult<GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutation>;
export type UpdateScenePlaceholdersAndAnimatedWireframeMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutation,
    GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutationVariables
>;
export const UpdateTrayConnectorAuthenticationDocument = gql`
    mutation updateTrayConnectorAuthentication($updateTrayConnectorAuthentication: UpdateTrayConnectorAuthenticationInput!) {
        updateTrayConnectorAuthentication(input: $updateTrayConnectorAuthentication) {
            result
            solutionInstanceTriggerUrl
        }
    }
`;
export type GqlClientUpdateTrayConnectorAuthenticationMutationFn = Apollo.MutationFunction<
    GqlClientUpdateTrayConnectorAuthenticationMutation,
    GqlClientUpdateTrayConnectorAuthenticationMutationVariables
>;
export type GqlClientUpdateTrayConnectorAuthenticationProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateTrayConnectorAuthenticationMutation, GqlClientUpdateTrayConnectorAuthenticationMutationVariables>;
} & TChildProps;
export function withUpdateTrayConnectorAuthentication<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateTrayConnectorAuthenticationMutation,
        GqlClientUpdateTrayConnectorAuthenticationMutationVariables,
        GqlClientUpdateTrayConnectorAuthenticationProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateTrayConnectorAuthenticationMutation,
        GqlClientUpdateTrayConnectorAuthenticationMutationVariables,
        GqlClientUpdateTrayConnectorAuthenticationProps<TChildProps, TDataName>
    >(UpdateTrayConnectorAuthenticationDocument, {
        alias: "updateTrayConnectorAuthentication",
        ...operationOptions
    });
}

/**
 * __useUpdateTrayConnectorAuthenticationMutation__
 *
 * To run a mutation, you first call `useUpdateTrayConnectorAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrayConnectorAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrayConnectorAuthenticationMutation, { data, loading, error }] = useUpdateTrayConnectorAuthenticationMutation({
 *   variables: {
 *      updateTrayConnectorAuthentication: // value for 'updateTrayConnectorAuthentication'
 *   },
 * });
 */
export function useUpdateTrayConnectorAuthenticationMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateTrayConnectorAuthenticationMutation, GqlClientUpdateTrayConnectorAuthenticationMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateTrayConnectorAuthenticationMutation, GqlClientUpdateTrayConnectorAuthenticationMutationVariables>(UpdateTrayConnectorAuthenticationDocument, options);
}
export type UpdateTrayConnectorAuthenticationMutationHookResult = ReturnType<typeof useUpdateTrayConnectorAuthenticationMutation>;
export type UpdateTrayConnectorAuthenticationMutationResult = Apollo.MutationResult<GqlClientUpdateTrayConnectorAuthenticationMutation>;
export type UpdateTrayConnectorAuthenticationMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateTrayConnectorAuthenticationMutation,
    GqlClientUpdateTrayConnectorAuthenticationMutationVariables
>;
export const UpgradeEditorProgramToM2LayoutsDocument = gql`
    mutation upgradeEditorProgramToM2Layouts($input: UpgradeEditorProgramToM2LayoutsInput!) {
        upgradeEditorProgramToM2Layouts(input: $input) {
            result
            editorProgramVersion {
                id
                compatibility
                scenes {
                    id
                    ccAnimatedWireframe {
                        id
                    }
                }
            }
        }
    }
`;
export type GqlClientUpgradeEditorProgramToM2LayoutsMutationFn = Apollo.MutationFunction<GqlClientUpgradeEditorProgramToM2LayoutsMutation, GqlClientUpgradeEditorProgramToM2LayoutsMutationVariables>;
export type GqlClientUpgradeEditorProgramToM2LayoutsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpgradeEditorProgramToM2LayoutsMutation, GqlClientUpgradeEditorProgramToM2LayoutsMutationVariables>;
} & TChildProps;
export function withUpgradeEditorProgramToM2Layouts<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpgradeEditorProgramToM2LayoutsMutation,
        GqlClientUpgradeEditorProgramToM2LayoutsMutationVariables,
        GqlClientUpgradeEditorProgramToM2LayoutsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpgradeEditorProgramToM2LayoutsMutation,
        GqlClientUpgradeEditorProgramToM2LayoutsMutationVariables,
        GqlClientUpgradeEditorProgramToM2LayoutsProps<TChildProps, TDataName>
    >(UpgradeEditorProgramToM2LayoutsDocument, {
        alias: "upgradeEditorProgramToM2Layouts",
        ...operationOptions
    });
}

/**
 * __useUpgradeEditorProgramToM2LayoutsMutation__
 *
 * To run a mutation, you first call `useUpgradeEditorProgramToM2LayoutsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeEditorProgramToM2LayoutsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeEditorProgramToM2LayoutsMutation, { data, loading, error }] = useUpgradeEditorProgramToM2LayoutsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpgradeEditorProgramToM2LayoutsMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpgradeEditorProgramToM2LayoutsMutation, GqlClientUpgradeEditorProgramToM2LayoutsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpgradeEditorProgramToM2LayoutsMutation, GqlClientUpgradeEditorProgramToM2LayoutsMutationVariables>(UpgradeEditorProgramToM2LayoutsDocument, options);
}
export type UpgradeEditorProgramToM2LayoutsMutationHookResult = ReturnType<typeof useUpgradeEditorProgramToM2LayoutsMutation>;
export type UpgradeEditorProgramToM2LayoutsMutationResult = Apollo.MutationResult<GqlClientUpgradeEditorProgramToM2LayoutsMutation>;
export type UpgradeEditorProgramToM2LayoutsMutationOptions = Apollo.BaseMutationOptions<GqlClientUpgradeEditorProgramToM2LayoutsMutation, GqlClientUpgradeEditorProgramToM2LayoutsMutationVariables>;
export const UploadCustomThumbnailDocument = gql`
    mutation UploadCustomThumbnail($input: uploadCustomThumbnailInput!) {
        uploadCustomThumbnail(input: $input) {
            result
            editorApplication {
                id
                metadata
                customThumbnailUrl
            }
        }
    }
`;
export type GqlClientUploadCustomThumbnailMutationFn = Apollo.MutationFunction<GqlClientUploadCustomThumbnailMutation, GqlClientUploadCustomThumbnailMutationVariables>;
export type GqlClientUploadCustomThumbnailProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUploadCustomThumbnailMutation, GqlClientUploadCustomThumbnailMutationVariables>;
} & TChildProps;
export function withUploadCustomThumbnail<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUploadCustomThumbnailMutation,
        GqlClientUploadCustomThumbnailMutationVariables,
        GqlClientUploadCustomThumbnailProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUploadCustomThumbnailMutation, GqlClientUploadCustomThumbnailMutationVariables, GqlClientUploadCustomThumbnailProps<TChildProps, TDataName>>(
        UploadCustomThumbnailDocument,
        {
            alias: "uploadCustomThumbnail",
            ...operationOptions
        }
    );
}

/**
 * __useUploadCustomThumbnailMutation__
 *
 * To run a mutation, you first call `useUploadCustomThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCustomThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCustomThumbnailMutation, { data, loading, error }] = useUploadCustomThumbnailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadCustomThumbnailMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUploadCustomThumbnailMutation, GqlClientUploadCustomThumbnailMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUploadCustomThumbnailMutation, GqlClientUploadCustomThumbnailMutationVariables>(UploadCustomThumbnailDocument, options);
}
export type UploadCustomThumbnailMutationHookResult = ReturnType<typeof useUploadCustomThumbnailMutation>;
export type UploadCustomThumbnailMutationResult = Apollo.MutationResult<GqlClientUploadCustomThumbnailMutation>;
export type UploadCustomThumbnailMutationOptions = Apollo.BaseMutationOptions<GqlClientUploadCustomThumbnailMutation, GqlClientUploadCustomThumbnailMutationVariables>;
export const UploadEditorAssetFontDocument = gql`
    mutation uploadEditorAssetFont($input: UploadEditorAssetFontInput!) {
        uploadEditorAssetFont(input: $input) {
            result
            font {
                id
                editorAssetLibraryVersion {
                    id
                    fonts {
                        ...EditorAssetLibraryFont
                    }
                }
            }
        }
    }
    ${EditorAssetLibraryFontFragmentDoc}
`;
export type GqlClientUploadEditorAssetFontMutationFn = Apollo.MutationFunction<GqlClientUploadEditorAssetFontMutation, GqlClientUploadEditorAssetFontMutationVariables>;
export type GqlClientUploadEditorAssetFontProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUploadEditorAssetFontMutation, GqlClientUploadEditorAssetFontMutationVariables>;
} & TChildProps;
export function withUploadEditorAssetFont<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUploadEditorAssetFontMutation,
        GqlClientUploadEditorAssetFontMutationVariables,
        GqlClientUploadEditorAssetFontProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUploadEditorAssetFontMutation, GqlClientUploadEditorAssetFontMutationVariables, GqlClientUploadEditorAssetFontProps<TChildProps, TDataName>>(
        UploadEditorAssetFontDocument,
        {
            alias: "uploadEditorAssetFont",
            ...operationOptions
        }
    );
}

/**
 * __useUploadEditorAssetFontMutation__
 *
 * To run a mutation, you first call `useUploadEditorAssetFontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEditorAssetFontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEditorAssetFontMutation, { data, loading, error }] = useUploadEditorAssetFontMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadEditorAssetFontMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUploadEditorAssetFontMutation, GqlClientUploadEditorAssetFontMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUploadEditorAssetFontMutation, GqlClientUploadEditorAssetFontMutationVariables>(UploadEditorAssetFontDocument, options);
}
export type UploadEditorAssetFontMutationHookResult = ReturnType<typeof useUploadEditorAssetFontMutation>;
export type UploadEditorAssetFontMutationResult = Apollo.MutationResult<GqlClientUploadEditorAssetFontMutation>;
export type UploadEditorAssetFontMutationOptions = Apollo.BaseMutationOptions<GqlClientUploadEditorAssetFontMutation, GqlClientUploadEditorAssetFontMutationVariables>;
export const UploadEditorAssetMediaDocument = gql`
    mutation uploadEditorAssetMedia($input: UploadEditorAssetMediaInput!) {
        uploadEditorAssetMedia(input: $input) {
            result
            editorAssetMedia {
                editorAssetLibraryVersion {
                    id
                    media {
                        id
                    }
                }
                ...EditorAssetLibraryMedia
            }
            editorPlaceholder {
                ...EditorPlaceholderConfiguration
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
    ${EditorPlaceholderConfigurationFragmentDoc}
`;
export type GqlClientUploadEditorAssetMediaMutationFn = Apollo.MutationFunction<GqlClientUploadEditorAssetMediaMutation, GqlClientUploadEditorAssetMediaMutationVariables>;
export type GqlClientUploadEditorAssetMediaProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUploadEditorAssetMediaMutation, GqlClientUploadEditorAssetMediaMutationVariables>;
} & TChildProps;
export function withUploadEditorAssetMedia<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUploadEditorAssetMediaMutation,
        GqlClientUploadEditorAssetMediaMutationVariables,
        GqlClientUploadEditorAssetMediaProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUploadEditorAssetMediaMutation, GqlClientUploadEditorAssetMediaMutationVariables, GqlClientUploadEditorAssetMediaProps<TChildProps, TDataName>>(
        UploadEditorAssetMediaDocument,
        {
            alias: "uploadEditorAssetMedia",
            ...operationOptions
        }
    );
}

/**
 * __useUploadEditorAssetMediaMutation__
 *
 * To run a mutation, you first call `useUploadEditorAssetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEditorAssetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEditorAssetMediaMutation, { data, loading, error }] = useUploadEditorAssetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadEditorAssetMediaMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUploadEditorAssetMediaMutation, GqlClientUploadEditorAssetMediaMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUploadEditorAssetMediaMutation, GqlClientUploadEditorAssetMediaMutationVariables>(UploadEditorAssetMediaDocument, options);
}
export type UploadEditorAssetMediaMutationHookResult = ReturnType<typeof useUploadEditorAssetMediaMutation>;
export type UploadEditorAssetMediaMutationResult = Apollo.MutationResult<GqlClientUploadEditorAssetMediaMutation>;
export type UploadEditorAssetMediaMutationOptions = Apollo.BaseMutationOptions<GqlClientUploadEditorAssetMediaMutation, GqlClientUploadEditorAssetMediaMutationVariables>;
export const UploadEditorAssetMediaFromUrlDocument = gql`
    mutation uploadEditorAssetMediaFromUrl($input: UploadEditorAssetMediaFromUrlInput!) {
        uploadEditorAssetMediaFromUrl(input: $input) {
            result
            editorAssetMedia {
                editorAssetLibraryVersion {
                    id
                    media {
                        id
                    }
                }
                ...EditorAssetLibraryMedia
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
`;
export type GqlClientUploadEditorAssetMediaFromUrlMutationFn = Apollo.MutationFunction<GqlClientUploadEditorAssetMediaFromUrlMutation, GqlClientUploadEditorAssetMediaFromUrlMutationVariables>;
export type GqlClientUploadEditorAssetMediaFromUrlProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUploadEditorAssetMediaFromUrlMutation, GqlClientUploadEditorAssetMediaFromUrlMutationVariables>;
} & TChildProps;
export function withUploadEditorAssetMediaFromUrl<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUploadEditorAssetMediaFromUrlMutation,
        GqlClientUploadEditorAssetMediaFromUrlMutationVariables,
        GqlClientUploadEditorAssetMediaFromUrlProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUploadEditorAssetMediaFromUrlMutation,
        GqlClientUploadEditorAssetMediaFromUrlMutationVariables,
        GqlClientUploadEditorAssetMediaFromUrlProps<TChildProps, TDataName>
    >(UploadEditorAssetMediaFromUrlDocument, {
        alias: "uploadEditorAssetMediaFromUrl",
        ...operationOptions
    });
}

/**
 * __useUploadEditorAssetMediaFromUrlMutation__
 *
 * To run a mutation, you first call `useUploadEditorAssetMediaFromUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEditorAssetMediaFromUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEditorAssetMediaFromUrlMutation, { data, loading, error }] = useUploadEditorAssetMediaFromUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadEditorAssetMediaFromUrlMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUploadEditorAssetMediaFromUrlMutation, GqlClientUploadEditorAssetMediaFromUrlMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUploadEditorAssetMediaFromUrlMutation, GqlClientUploadEditorAssetMediaFromUrlMutationVariables>(UploadEditorAssetMediaFromUrlDocument, options);
}
export type UploadEditorAssetMediaFromUrlMutationHookResult = ReturnType<typeof useUploadEditorAssetMediaFromUrlMutation>;
export type UploadEditorAssetMediaFromUrlMutationResult = Apollo.MutationResult<GqlClientUploadEditorAssetMediaFromUrlMutation>;
export type UploadEditorAssetMediaFromUrlMutationOptions = Apollo.BaseMutationOptions<GqlClientUploadEditorAssetMediaFromUrlMutation, GqlClientUploadEditorAssetMediaFromUrlMutationVariables>;
export const UploadEditorAssetMusicDocument = gql`
    mutation uploadEditorAssetMusic($input: UploadEditorAssetMusicInput!) {
        uploadEditorAssetMusic(input: $input) {
            result
            editorAssetMusic {
                editorAssetLibraryVersion {
                    id
                    music {
                        ...EditorAssetLibraryMusic
                    }
                }
                ...EditorAssetLibraryMusic
            }
        }
    }
    ${EditorAssetLibraryMusicFragmentDoc}
`;
export type GqlClientUploadEditorAssetMusicMutationFn = Apollo.MutationFunction<GqlClientUploadEditorAssetMusicMutation, GqlClientUploadEditorAssetMusicMutationVariables>;
export type GqlClientUploadEditorAssetMusicProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUploadEditorAssetMusicMutation, GqlClientUploadEditorAssetMusicMutationVariables>;
} & TChildProps;
export function withUploadEditorAssetMusic<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUploadEditorAssetMusicMutation,
        GqlClientUploadEditorAssetMusicMutationVariables,
        GqlClientUploadEditorAssetMusicProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUploadEditorAssetMusicMutation, GqlClientUploadEditorAssetMusicMutationVariables, GqlClientUploadEditorAssetMusicProps<TChildProps, TDataName>>(
        UploadEditorAssetMusicDocument,
        {
            alias: "uploadEditorAssetMusic",
            ...operationOptions
        }
    );
}

/**
 * __useUploadEditorAssetMusicMutation__
 *
 * To run a mutation, you first call `useUploadEditorAssetMusicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEditorAssetMusicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEditorAssetMusicMutation, { data, loading, error }] = useUploadEditorAssetMusicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadEditorAssetMusicMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUploadEditorAssetMusicMutation, GqlClientUploadEditorAssetMusicMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUploadEditorAssetMusicMutation, GqlClientUploadEditorAssetMusicMutationVariables>(UploadEditorAssetMusicDocument, options);
}
export type UploadEditorAssetMusicMutationHookResult = ReturnType<typeof useUploadEditorAssetMusicMutation>;
export type UploadEditorAssetMusicMutationResult = Apollo.MutationResult<GqlClientUploadEditorAssetMusicMutation>;
export type UploadEditorAssetMusicMutationOptions = Apollo.BaseMutationOptions<GqlClientUploadEditorAssetMusicMutation, GqlClientUploadEditorAssetMusicMutationVariables>;
export const UploadExternalEditorAssetImageDocument = gql`
    mutation uploadExternalEditorAssetImage($input: UploadExternalEditorAssetImageInput!) {
        uploadExternalEditorAssetImage(input: $input) {
            result
            editorAssetMedia {
                editorAssetLibraryVersion {
                    id
                    media {
                        id
                    }
                }
                ...EditorAssetLibraryMedia
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
`;
export type GqlClientUploadExternalEditorAssetImageMutationFn = Apollo.MutationFunction<GqlClientUploadExternalEditorAssetImageMutation, GqlClientUploadExternalEditorAssetImageMutationVariables>;
export type GqlClientUploadExternalEditorAssetImageProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUploadExternalEditorAssetImageMutation, GqlClientUploadExternalEditorAssetImageMutationVariables>;
} & TChildProps;
export function withUploadExternalEditorAssetImage<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUploadExternalEditorAssetImageMutation,
        GqlClientUploadExternalEditorAssetImageMutationVariables,
        GqlClientUploadExternalEditorAssetImageProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUploadExternalEditorAssetImageMutation,
        GqlClientUploadExternalEditorAssetImageMutationVariables,
        GqlClientUploadExternalEditorAssetImageProps<TChildProps, TDataName>
    >(UploadExternalEditorAssetImageDocument, {
        alias: "uploadExternalEditorAssetImage",
        ...operationOptions
    });
}

/**
 * __useUploadExternalEditorAssetImageMutation__
 *
 * To run a mutation, you first call `useUploadExternalEditorAssetImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadExternalEditorAssetImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadExternalEditorAssetImageMutation, { data, loading, error }] = useUploadExternalEditorAssetImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadExternalEditorAssetImageMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUploadExternalEditorAssetImageMutation, GqlClientUploadExternalEditorAssetImageMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUploadExternalEditorAssetImageMutation, GqlClientUploadExternalEditorAssetImageMutationVariables>(UploadExternalEditorAssetImageDocument, options);
}
export type UploadExternalEditorAssetImageMutationHookResult = ReturnType<typeof useUploadExternalEditorAssetImageMutation>;
export type UploadExternalEditorAssetImageMutationResult = Apollo.MutationResult<GqlClientUploadExternalEditorAssetImageMutation>;
export type UploadExternalEditorAssetImageMutationOptions = Apollo.BaseMutationOptions<GqlClientUploadExternalEditorAssetImageMutation, GqlClientUploadExternalEditorAssetImageMutationVariables>;
export const UploadExternalEditorAssetVideoDocument = gql`
    mutation uploadExternalEditorAssetVideo($input: UploadExternalEditorAssetVideoInput!) {
        uploadExternalEditorAssetVideo(input: $input) {
            result
            editorAssetMedia {
                editorAssetLibraryVersion {
                    id
                    media {
                        id
                    }
                }
                ...EditorAssetLibraryMedia
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
`;
export type GqlClientUploadExternalEditorAssetVideoMutationFn = Apollo.MutationFunction<GqlClientUploadExternalEditorAssetVideoMutation, GqlClientUploadExternalEditorAssetVideoMutationVariables>;
export type GqlClientUploadExternalEditorAssetVideoProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUploadExternalEditorAssetVideoMutation, GqlClientUploadExternalEditorAssetVideoMutationVariables>;
} & TChildProps;
export function withUploadExternalEditorAssetVideo<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUploadExternalEditorAssetVideoMutation,
        GqlClientUploadExternalEditorAssetVideoMutationVariables,
        GqlClientUploadExternalEditorAssetVideoProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUploadExternalEditorAssetVideoMutation,
        GqlClientUploadExternalEditorAssetVideoMutationVariables,
        GqlClientUploadExternalEditorAssetVideoProps<TChildProps, TDataName>
    >(UploadExternalEditorAssetVideoDocument, {
        alias: "uploadExternalEditorAssetVideo",
        ...operationOptions
    });
}

/**
 * __useUploadExternalEditorAssetVideoMutation__
 *
 * To run a mutation, you first call `useUploadExternalEditorAssetVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadExternalEditorAssetVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadExternalEditorAssetVideoMutation, { data, loading, error }] = useUploadExternalEditorAssetVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadExternalEditorAssetVideoMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUploadExternalEditorAssetVideoMutation, GqlClientUploadExternalEditorAssetVideoMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUploadExternalEditorAssetVideoMutation, GqlClientUploadExternalEditorAssetVideoMutationVariables>(UploadExternalEditorAssetVideoDocument, options);
}
export type UploadExternalEditorAssetVideoMutationHookResult = ReturnType<typeof useUploadExternalEditorAssetVideoMutation>;
export type UploadExternalEditorAssetVideoMutationResult = Apollo.MutationResult<GqlClientUploadExternalEditorAssetVideoMutation>;
export type UploadExternalEditorAssetVideoMutationOptions = Apollo.BaseMutationOptions<GqlClientUploadExternalEditorAssetVideoMutation, GqlClientUploadExternalEditorAssetVideoMutationVariables>;
export const UploadOptimizedEditorAssetMediaDocument = gql`
    mutation uploadOptimizedEditorAssetMedia($input: UploadEditorAssetMediaInput!, $mutationId: String!) {
        uploadOptimizedEditorAssetMedia(input: $input, mutationId: $mutationId) {
            result
        }
    }
`;
export type GqlClientUploadOptimizedEditorAssetMediaMutationFn = Apollo.MutationFunction<GqlClientUploadOptimizedEditorAssetMediaMutation, GqlClientUploadOptimizedEditorAssetMediaMutationVariables>;
export type GqlClientUploadOptimizedEditorAssetMediaProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUploadOptimizedEditorAssetMediaMutation, GqlClientUploadOptimizedEditorAssetMediaMutationVariables>;
} & TChildProps;
export function withUploadOptimizedEditorAssetMedia<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUploadOptimizedEditorAssetMediaMutation,
        GqlClientUploadOptimizedEditorAssetMediaMutationVariables,
        GqlClientUploadOptimizedEditorAssetMediaProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUploadOptimizedEditorAssetMediaMutation,
        GqlClientUploadOptimizedEditorAssetMediaMutationVariables,
        GqlClientUploadOptimizedEditorAssetMediaProps<TChildProps, TDataName>
    >(UploadOptimizedEditorAssetMediaDocument, {
        alias: "uploadOptimizedEditorAssetMedia",
        ...operationOptions
    });
}

/**
 * __useUploadOptimizedEditorAssetMediaMutation__
 *
 * To run a mutation, you first call `useUploadOptimizedEditorAssetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOptimizedEditorAssetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOptimizedEditorAssetMediaMutation, { data, loading, error }] = useUploadOptimizedEditorAssetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useUploadOptimizedEditorAssetMediaMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUploadOptimizedEditorAssetMediaMutation, GqlClientUploadOptimizedEditorAssetMediaMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUploadOptimizedEditorAssetMediaMutation, GqlClientUploadOptimizedEditorAssetMediaMutationVariables>(UploadOptimizedEditorAssetMediaDocument, options);
}
export type UploadOptimizedEditorAssetMediaMutationHookResult = ReturnType<typeof useUploadOptimizedEditorAssetMediaMutation>;
export type UploadOptimizedEditorAssetMediaMutationResult = Apollo.MutationResult<GqlClientUploadOptimizedEditorAssetMediaMutation>;
export type UploadOptimizedEditorAssetMediaMutationOptions = Apollo.BaseMutationOptions<GqlClientUploadOptimizedEditorAssetMediaMutation, GqlClientUploadOptimizedEditorAssetMediaMutationVariables>;
export const UploadRecordingToS3Document = gql`
    mutation uploadRecordingToS3($input: UploadRecordingToS3Input!, $mutationId: String!) {
        uploadRecordingToS3(input: $input, mutationId: $mutationId) {
            result
        }
    }
`;
export type GqlClientUploadRecordingToS3MutationFn = Apollo.MutationFunction<GqlClientUploadRecordingToS3Mutation, GqlClientUploadRecordingToS3MutationVariables>;
export type GqlClientUploadRecordingToS3Props<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUploadRecordingToS3Mutation, GqlClientUploadRecordingToS3MutationVariables>;
} & TChildProps;
export function withUploadRecordingToS3<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUploadRecordingToS3Mutation,
        GqlClientUploadRecordingToS3MutationVariables,
        GqlClientUploadRecordingToS3Props<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUploadRecordingToS3Mutation, GqlClientUploadRecordingToS3MutationVariables, GqlClientUploadRecordingToS3Props<TChildProps, TDataName>>(
        UploadRecordingToS3Document,
        {
            alias: "uploadRecordingToS3",
            ...operationOptions
        }
    );
}

/**
 * __useUploadRecordingToS3Mutation__
 *
 * To run a mutation, you first call `useUploadRecordingToS3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadRecordingToS3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadRecordingToS3Mutation, { data, loading, error }] = useUploadRecordingToS3Mutation({
 *   variables: {
 *      input: // value for 'input'
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useUploadRecordingToS3Mutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUploadRecordingToS3Mutation, GqlClientUploadRecordingToS3MutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUploadRecordingToS3Mutation, GqlClientUploadRecordingToS3MutationVariables>(UploadRecordingToS3Document, options);
}
export type UploadRecordingToS3MutationHookResult = ReturnType<typeof useUploadRecordingToS3Mutation>;
export type UploadRecordingToS3MutationResult = Apollo.MutationResult<GqlClientUploadRecordingToS3Mutation>;
export type UploadRecordingToS3MutationOptions = Apollo.BaseMutationOptions<GqlClientUploadRecordingToS3Mutation, GqlClientUploadRecordingToS3MutationVariables>;
export const UploadStockEditorAssetMusicDocument = gql`
    mutation uploadStockEditorAssetMusic($input: UploadStockEditorAssetMusicInput!) {
        uploadStockEditorAssetMusic(input: $input) {
            result
            editorAssetMusic {
                editorAssetLibraryVersion {
                    id
                    music {
                        ...EditorAssetLibraryMusic
                    }
                }
                ...EditorAssetLibraryMusic
            }
        }
    }
    ${EditorAssetLibraryMusicFragmentDoc}
`;
export type GqlClientUploadStockEditorAssetMusicMutationFn = Apollo.MutationFunction<GqlClientUploadStockEditorAssetMusicMutation, GqlClientUploadStockEditorAssetMusicMutationVariables>;
export type GqlClientUploadStockEditorAssetMusicProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUploadStockEditorAssetMusicMutation, GqlClientUploadStockEditorAssetMusicMutationVariables>;
} & TChildProps;
export function withUploadStockEditorAssetMusic<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUploadStockEditorAssetMusicMutation,
        GqlClientUploadStockEditorAssetMusicMutationVariables,
        GqlClientUploadStockEditorAssetMusicProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUploadStockEditorAssetMusicMutation,
        GqlClientUploadStockEditorAssetMusicMutationVariables,
        GqlClientUploadStockEditorAssetMusicProps<TChildProps, TDataName>
    >(UploadStockEditorAssetMusicDocument, {
        alias: "uploadStockEditorAssetMusic",
        ...operationOptions
    });
}

/**
 * __useUploadStockEditorAssetMusicMutation__
 *
 * To run a mutation, you first call `useUploadStockEditorAssetMusicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadStockEditorAssetMusicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadStockEditorAssetMusicMutation, { data, loading, error }] = useUploadStockEditorAssetMusicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadStockEditorAssetMusicMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUploadStockEditorAssetMusicMutation, GqlClientUploadStockEditorAssetMusicMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUploadStockEditorAssetMusicMutation, GqlClientUploadStockEditorAssetMusicMutationVariables>(UploadStockEditorAssetMusicDocument, options);
}
export type UploadStockEditorAssetMusicMutationHookResult = ReturnType<typeof useUploadStockEditorAssetMusicMutation>;
export type UploadStockEditorAssetMusicMutationResult = Apollo.MutationResult<GqlClientUploadStockEditorAssetMusicMutation>;
export type UploadStockEditorAssetMusicMutationOptions = Apollo.BaseMutationOptions<GqlClientUploadStockEditorAssetMusicMutation, GqlClientUploadStockEditorAssetMusicMutationVariables>;
export const UpsertUserSettingsDocument = gql`
    mutation upsertUserSettings($upsertUserSettingsInput: UpsertUserSettingsInput!) {
        upsertUserSettings(input: $upsertUserSettingsInput) {
            result
            userSettings {
                id
                externalLibraries
                acceptedAiTerms
                acceptedGenerativeAiTerms
            }
        }
    }
`;
export type GqlClientUpsertUserSettingsMutationFn = Apollo.MutationFunction<GqlClientUpsertUserSettingsMutation, GqlClientUpsertUserSettingsMutationVariables>;
export type GqlClientUpsertUserSettingsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpsertUserSettingsMutation, GqlClientUpsertUserSettingsMutationVariables>;
} & TChildProps;
export function withUpsertUserSettings<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpsertUserSettingsMutation,
        GqlClientUpsertUserSettingsMutationVariables,
        GqlClientUpsertUserSettingsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpsertUserSettingsMutation, GqlClientUpsertUserSettingsMutationVariables, GqlClientUpsertUserSettingsProps<TChildProps, TDataName>>(
        UpsertUserSettingsDocument,
        {
            alias: "upsertUserSettings",
            ...operationOptions
        }
    );
}

/**
 * __useUpsertUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserSettingsMutation, { data, loading, error }] = useUpsertUserSettingsMutation({
 *   variables: {
 *      upsertUserSettingsInput: // value for 'upsertUserSettingsInput'
 *   },
 * });
 */
export function useUpsertUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpsertUserSettingsMutation, GqlClientUpsertUserSettingsMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpsertUserSettingsMutation, GqlClientUpsertUserSettingsMutationVariables>(UpsertUserSettingsDocument, options);
}
export type UpsertUserSettingsMutationHookResult = ReturnType<typeof useUpsertUserSettingsMutation>;
export type UpsertUserSettingsMutationResult = Apollo.MutationResult<GqlClientUpsertUserSettingsMutation>;
export type UpsertUserSettingsMutationOptions = Apollo.BaseMutationOptions<GqlClientUpsertUserSettingsMutation, GqlClientUpsertUserSettingsMutationVariables>;
export const UpsertHelpCenterEntryDocument = gql`
    mutation upsertHelpCenterEntry($input: UpsertHelpCenterEntryInput!) {
        upsertHelpCenterEntry(input: $input) {
            result
            entry {
                id
                articleId
                sectionId
            }
        }
    }
`;
export type GqlClientUpsertHelpCenterEntryMutationFn = Apollo.MutationFunction<GqlClientUpsertHelpCenterEntryMutation, GqlClientUpsertHelpCenterEntryMutationVariables>;
export type GqlClientUpsertHelpCenterEntryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpsertHelpCenterEntryMutation, GqlClientUpsertHelpCenterEntryMutationVariables>;
} & TChildProps;
export function withUpsertHelpCenterEntry<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpsertHelpCenterEntryMutation,
        GqlClientUpsertHelpCenterEntryMutationVariables,
        GqlClientUpsertHelpCenterEntryProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpsertHelpCenterEntryMutation, GqlClientUpsertHelpCenterEntryMutationVariables, GqlClientUpsertHelpCenterEntryProps<TChildProps, TDataName>>(
        UpsertHelpCenterEntryDocument,
        {
            alias: "upsertHelpCenterEntry",
            ...operationOptions
        }
    );
}

/**
 * __useUpsertHelpCenterEntryMutation__
 *
 * To run a mutation, you first call `useUpsertHelpCenterEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertHelpCenterEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertHelpCenterEntryMutation, { data, loading, error }] = useUpsertHelpCenterEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertHelpCenterEntryMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpsertHelpCenterEntryMutation, GqlClientUpsertHelpCenterEntryMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpsertHelpCenterEntryMutation, GqlClientUpsertHelpCenterEntryMutationVariables>(UpsertHelpCenterEntryDocument, options);
}
export type UpsertHelpCenterEntryMutationHookResult = ReturnType<typeof useUpsertHelpCenterEntryMutation>;
export type UpsertHelpCenterEntryMutationResult = Apollo.MutationResult<GqlClientUpsertHelpCenterEntryMutation>;
export type UpsertHelpCenterEntryMutationOptions = Apollo.BaseMutationOptions<GqlClientUpsertHelpCenterEntryMutation, GqlClientUpsertHelpCenterEntryMutationVariables>;
export const UpsertRptvUserDocument = gql`
    mutation upsertRPTVUser($email: String!, $tokens: Int!) {
        upsertRPTVUser(email: $email, tokens: $tokens) {
            result
            user {
                id
                tokens
            }
        }
    }
`;
export type GqlClientUpsertRptvUserMutationFn = Apollo.MutationFunction<GqlClientUpsertRptvUserMutation, GqlClientUpsertRptvUserMutationVariables>;
export type GqlClientUpsertRptvUserProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpsertRptvUserMutation, GqlClientUpsertRptvUserMutationVariables>;
} & TChildProps;
export function withUpsertRptvUser<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpsertRptvUserMutation, GqlClientUpsertRptvUserMutationVariables, GqlClientUpsertRptvUserProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpsertRptvUserMutation, GqlClientUpsertRptvUserMutationVariables, GqlClientUpsertRptvUserProps<TChildProps, TDataName>>(
        UpsertRptvUserDocument,
        {
            alias: "upsertRptvUser",
            ...operationOptions
        }
    );
}

/**
 * __useUpsertRptvUserMutation__
 *
 * To run a mutation, you first call `useUpsertRptvUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRptvUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRptvUserMutation, { data, loading, error }] = useUpsertRptvUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      tokens: // value for 'tokens'
 *   },
 * });
 */
export function useUpsertRptvUserMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpsertRptvUserMutation, GqlClientUpsertRptvUserMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpsertRptvUserMutation, GqlClientUpsertRptvUserMutationVariables>(UpsertRptvUserDocument, options);
}
export type UpsertRptvUserMutationHookResult = ReturnType<typeof useUpsertRptvUserMutation>;
export type UpsertRptvUserMutationResult = Apollo.MutationResult<GqlClientUpsertRptvUserMutation>;
export type UpsertRptvUserMutationOptions = Apollo.BaseMutationOptions<GqlClientUpsertRptvUserMutation, GqlClientUpsertRptvUserMutationVariables>;
export const StoryTemplatesReviewGalleryStatusDocument = gql`
    query StoryTemplatesReviewGalleryStatus {
        storyTemplatesReviewGalleryStatus {
            storyTemplateId
            indication
        }
    }
`;
export type GqlClientStoryTemplatesReviewGalleryStatusProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientStoryTemplatesReviewGalleryStatusQuery, GqlClientStoryTemplatesReviewGalleryStatusQueryVariables>;
} & TChildProps;
export function withStoryTemplatesReviewGalleryStatus<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientStoryTemplatesReviewGalleryStatusQuery,
        GqlClientStoryTemplatesReviewGalleryStatusQueryVariables,
        GqlClientStoryTemplatesReviewGalleryStatusProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientStoryTemplatesReviewGalleryStatusQuery,
        GqlClientStoryTemplatesReviewGalleryStatusQueryVariables,
        GqlClientStoryTemplatesReviewGalleryStatusProps<TChildProps, TDataName>
    >(StoryTemplatesReviewGalleryStatusDocument, {
        alias: "storyTemplatesReviewGalleryStatus",
        ...operationOptions
    });
}

/**
 * __useStoryTemplatesReviewGalleryStatusQuery__
 *
 * To run a query within a React component, call `useStoryTemplatesReviewGalleryStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryTemplatesReviewGalleryStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryTemplatesReviewGalleryStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoryTemplatesReviewGalleryStatusQuery(
    baseOptions?: Apollo.QueryHookOptions<GqlClientStoryTemplatesReviewGalleryStatusQuery, GqlClientStoryTemplatesReviewGalleryStatusQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientStoryTemplatesReviewGalleryStatusQuery, GqlClientStoryTemplatesReviewGalleryStatusQueryVariables>(StoryTemplatesReviewGalleryStatusDocument, options);
}
export function useStoryTemplatesReviewGalleryStatusLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientStoryTemplatesReviewGalleryStatusQuery, GqlClientStoryTemplatesReviewGalleryStatusQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientStoryTemplatesReviewGalleryStatusQuery, GqlClientStoryTemplatesReviewGalleryStatusQueryVariables>(StoryTemplatesReviewGalleryStatusDocument, options);
}
export type StoryTemplatesReviewGalleryStatusQueryHookResult = ReturnType<typeof useStoryTemplatesReviewGalleryStatusQuery>;
export type StoryTemplatesReviewGalleryStatusLazyQueryHookResult = ReturnType<typeof useStoryTemplatesReviewGalleryStatusLazyQuery>;
export type StoryTemplatesReviewGalleryStatusQueryResult = Apollo.QueryResult<GqlClientStoryTemplatesReviewGalleryStatusQuery, GqlClientStoryTemplatesReviewGalleryStatusQueryVariables>;
export const AccountCcLibraryVersionDocument = gql`
    query AccountCcLibraryVersion {
        account {
            id
            ccLibraryVersion {
                id
                library {
                    id
                }
            }
        }
    }
`;
export type GqlClientAccountCcLibraryVersionProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountCcLibraryVersionQuery, GqlClientAccountCcLibraryVersionQueryVariables>;
} & TChildProps;
export function withAccountCcLibraryVersion<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountCcLibraryVersionQuery,
        GqlClientAccountCcLibraryVersionQueryVariables,
        GqlClientAccountCcLibraryVersionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientAccountCcLibraryVersionQuery, GqlClientAccountCcLibraryVersionQueryVariables, GqlClientAccountCcLibraryVersionProps<TChildProps, TDataName>>(
        AccountCcLibraryVersionDocument,
        {
            alias: "accountCcLibraryVersion",
            ...operationOptions
        }
    );
}

/**
 * __useAccountCcLibraryVersionQuery__
 *
 * To run a query within a React component, call `useAccountCcLibraryVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCcLibraryVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCcLibraryVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountCcLibraryVersionQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientAccountCcLibraryVersionQuery, GqlClientAccountCcLibraryVersionQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAccountCcLibraryVersionQuery, GqlClientAccountCcLibraryVersionQueryVariables>(AccountCcLibraryVersionDocument, options);
}
export function useAccountCcLibraryVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAccountCcLibraryVersionQuery, GqlClientAccountCcLibraryVersionQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAccountCcLibraryVersionQuery, GqlClientAccountCcLibraryVersionQueryVariables>(AccountCcLibraryVersionDocument, options);
}
export type AccountCcLibraryVersionQueryHookResult = ReturnType<typeof useAccountCcLibraryVersionQuery>;
export type AccountCcLibraryVersionLazyQueryHookResult = ReturnType<typeof useAccountCcLibraryVersionLazyQuery>;
export type AccountCcLibraryVersionQueryResult = Apollo.QueryResult<GqlClientAccountCcLibraryVersionQuery, GqlClientAccountCcLibraryVersionQueryVariables>;
export const AccountCopilotBriefsDocument = gql`
    query AccountCopilotBriefs {
        account {
            id
            copilotUserBriefs {
                ...AccountCopilotBrief
            }
            copilotOtherUsersBriefs {
                ...AccountCopilotBrief
            }
        }
    }
    ${AccountCopilotBriefFragmentDoc}
`;
export type GqlClientAccountCopilotBriefsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountCopilotBriefsQuery, GqlClientAccountCopilotBriefsQueryVariables>;
} & TChildProps;
export function withAccountCopilotBriefs<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountCopilotBriefsQuery,
        GqlClientAccountCopilotBriefsQueryVariables,
        GqlClientAccountCopilotBriefsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientAccountCopilotBriefsQuery, GqlClientAccountCopilotBriefsQueryVariables, GqlClientAccountCopilotBriefsProps<TChildProps, TDataName>>(
        AccountCopilotBriefsDocument,
        {
            alias: "accountCopilotBriefs",
            ...operationOptions
        }
    );
}

/**
 * __useAccountCopilotBriefsQuery__
 *
 * To run a query within a React component, call `useAccountCopilotBriefsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCopilotBriefsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCopilotBriefsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountCopilotBriefsQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientAccountCopilotBriefsQuery, GqlClientAccountCopilotBriefsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAccountCopilotBriefsQuery, GqlClientAccountCopilotBriefsQueryVariables>(AccountCopilotBriefsDocument, options);
}
export function useAccountCopilotBriefsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAccountCopilotBriefsQuery, GqlClientAccountCopilotBriefsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAccountCopilotBriefsQuery, GqlClientAccountCopilotBriefsQueryVariables>(AccountCopilotBriefsDocument, options);
}
export type AccountCopilotBriefsQueryHookResult = ReturnType<typeof useAccountCopilotBriefsQuery>;
export type AccountCopilotBriefsLazyQueryHookResult = ReturnType<typeof useAccountCopilotBriefsLazyQuery>;
export type AccountCopilotBriefsQueryResult = Apollo.QueryResult<GqlClientAccountCopilotBriefsQuery, GqlClientAccountCopilotBriefsQueryVariables>;
export const AccountDataLibrariesDocument = gql`
    query AccountDataLibraries {
        account {
            id
            dataLibraries {
                ...AccountDataLibrary
            }
        }
    }
    ${AccountDataLibraryFragmentDoc}
`;
export type GqlClientAccountDataLibrariesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountDataLibrariesQuery, GqlClientAccountDataLibrariesQueryVariables>;
} & TChildProps;
export function withAccountDataLibraries<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountDataLibrariesQuery,
        GqlClientAccountDataLibrariesQueryVariables,
        GqlClientAccountDataLibrariesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientAccountDataLibrariesQuery, GqlClientAccountDataLibrariesQueryVariables, GqlClientAccountDataLibrariesProps<TChildProps, TDataName>>(
        AccountDataLibrariesDocument,
        {
            alias: "accountDataLibraries",
            ...operationOptions
        }
    );
}

/**
 * __useAccountDataLibrariesQuery__
 *
 * To run a query within a React component, call `useAccountDataLibrariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDataLibrariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDataLibrariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountDataLibrariesQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientAccountDataLibrariesQuery, GqlClientAccountDataLibrariesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAccountDataLibrariesQuery, GqlClientAccountDataLibrariesQueryVariables>(AccountDataLibrariesDocument, options);
}
export function useAccountDataLibrariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAccountDataLibrariesQuery, GqlClientAccountDataLibrariesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAccountDataLibrariesQuery, GqlClientAccountDataLibrariesQueryVariables>(AccountDataLibrariesDocument, options);
}
export type AccountDataLibrariesQueryHookResult = ReturnType<typeof useAccountDataLibrariesQuery>;
export type AccountDataLibrariesLazyQueryHookResult = ReturnType<typeof useAccountDataLibrariesLazyQuery>;
export type AccountDataLibrariesQueryResult = Apollo.QueryResult<GqlClientAccountDataLibrariesQuery, GqlClientAccountDataLibrariesQueryVariables>;
export const AccountDataLibrariesPublishedVideosDocument = gql`
    query AccountDataLibrariesPublishedVideos {
        account {
            id
            dataLibraries {
                ...AccountDataLibraryPublished
            }
        }
    }
    ${AccountDataLibraryPublishedFragmentDoc}
`;
export type GqlClientAccountDataLibrariesPublishedVideosProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountDataLibrariesPublishedVideosQuery, GqlClientAccountDataLibrariesPublishedVideosQueryVariables>;
} & TChildProps;
export function withAccountDataLibrariesPublishedVideos<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountDataLibrariesPublishedVideosQuery,
        GqlClientAccountDataLibrariesPublishedVideosQueryVariables,
        GqlClientAccountDataLibrariesPublishedVideosProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientAccountDataLibrariesPublishedVideosQuery,
        GqlClientAccountDataLibrariesPublishedVideosQueryVariables,
        GqlClientAccountDataLibrariesPublishedVideosProps<TChildProps, TDataName>
    >(AccountDataLibrariesPublishedVideosDocument, {
        alias: "accountDataLibrariesPublishedVideos",
        ...operationOptions
    });
}

/**
 * __useAccountDataLibrariesPublishedVideosQuery__
 *
 * To run a query within a React component, call `useAccountDataLibrariesPublishedVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDataLibrariesPublishedVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDataLibrariesPublishedVideosQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountDataLibrariesPublishedVideosQuery(
    baseOptions?: Apollo.QueryHookOptions<GqlClientAccountDataLibrariesPublishedVideosQuery, GqlClientAccountDataLibrariesPublishedVideosQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAccountDataLibrariesPublishedVideosQuery, GqlClientAccountDataLibrariesPublishedVideosQueryVariables>(AccountDataLibrariesPublishedVideosDocument, options);
}
export function useAccountDataLibrariesPublishedVideosLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAccountDataLibrariesPublishedVideosQuery, GqlClientAccountDataLibrariesPublishedVideosQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAccountDataLibrariesPublishedVideosQuery, GqlClientAccountDataLibrariesPublishedVideosQueryVariables>(AccountDataLibrariesPublishedVideosDocument, options);
}
export type AccountDataLibrariesPublishedVideosQueryHookResult = ReturnType<typeof useAccountDataLibrariesPublishedVideosQuery>;
export type AccountDataLibrariesPublishedVideosLazyQueryHookResult = ReturnType<typeof useAccountDataLibrariesPublishedVideosLazyQuery>;
export type AccountDataLibrariesPublishedVideosQueryResult = Apollo.QueryResult<GqlClientAccountDataLibrariesPublishedVideosQuery, GqlClientAccountDataLibrariesPublishedVideosQueryVariables>;
export const AccountDataLibraryForImportDataDocument = gql`
    query AccountDataLibraryForImportData($libraryId: ID!) {
        accountDataLibrary(id: $libraryId) {
            ...AccountDataLibraryForImportData
        }
    }
    ${AccountDataLibraryForImportDataFragmentDoc}
`;
export type GqlClientAccountDataLibraryForImportDataProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountDataLibraryForImportDataQuery, GqlClientAccountDataLibraryForImportDataQueryVariables>;
} & TChildProps;
export function withAccountDataLibraryForImportData<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountDataLibraryForImportDataQuery,
        GqlClientAccountDataLibraryForImportDataQueryVariables,
        GqlClientAccountDataLibraryForImportDataProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientAccountDataLibraryForImportDataQuery,
        GqlClientAccountDataLibraryForImportDataQueryVariables,
        GqlClientAccountDataLibraryForImportDataProps<TChildProps, TDataName>
    >(AccountDataLibraryForImportDataDocument, {
        alias: "accountDataLibraryForImportData",
        ...operationOptions
    });
}

/**
 * __useAccountDataLibraryForImportDataQuery__
 *
 * To run a query within a React component, call `useAccountDataLibraryForImportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDataLibraryForImportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDataLibraryForImportDataQuery({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *   },
 * });
 */
export function useAccountDataLibraryForImportDataQuery(baseOptions: Apollo.QueryHookOptions<GqlClientAccountDataLibraryForImportDataQuery, GqlClientAccountDataLibraryForImportDataQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAccountDataLibraryForImportDataQuery, GqlClientAccountDataLibraryForImportDataQueryVariables>(AccountDataLibraryForImportDataDocument, options);
}
export function useAccountDataLibraryForImportDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAccountDataLibraryForImportDataQuery, GqlClientAccountDataLibraryForImportDataQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAccountDataLibraryForImportDataQuery, GqlClientAccountDataLibraryForImportDataQueryVariables>(AccountDataLibraryForImportDataDocument, options);
}
export type AccountDataLibraryForImportDataQueryHookResult = ReturnType<typeof useAccountDataLibraryForImportDataQuery>;
export type AccountDataLibraryForImportDataLazyQueryHookResult = ReturnType<typeof useAccountDataLibraryForImportDataLazyQuery>;
export type AccountDataLibraryForImportDataQueryResult = Apollo.QueryResult<GqlClientAccountDataLibraryForImportDataQuery, GqlClientAccountDataLibraryForImportDataQueryVariables>;
export const AccountServiceConnectionsDocument = gql`
    query accountServiceConnections {
        account {
            id
            accountServiceConnections {
                id
                serviceType
            }
        }
    }
`;
export type GqlClientAccountServiceConnectionsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountServiceConnectionsQuery, GqlClientAccountServiceConnectionsQueryVariables>;
} & TChildProps;
export function withAccountServiceConnections<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountServiceConnectionsQuery,
        GqlClientAccountServiceConnectionsQueryVariables,
        GqlClientAccountServiceConnectionsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientAccountServiceConnectionsQuery, GqlClientAccountServiceConnectionsQueryVariables, GqlClientAccountServiceConnectionsProps<TChildProps, TDataName>>(
        AccountServiceConnectionsDocument,
        {
            alias: "accountServiceConnections",
            ...operationOptions
        }
    );
}

/**
 * __useAccountServiceConnectionsQuery__
 *
 * To run a query within a React component, call `useAccountServiceConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountServiceConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountServiceConnectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountServiceConnectionsQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientAccountServiceConnectionsQuery, GqlClientAccountServiceConnectionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAccountServiceConnectionsQuery, GqlClientAccountServiceConnectionsQueryVariables>(AccountServiceConnectionsDocument, options);
}
export function useAccountServiceConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAccountServiceConnectionsQuery, GqlClientAccountServiceConnectionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAccountServiceConnectionsQuery, GqlClientAccountServiceConnectionsQueryVariables>(AccountServiceConnectionsDocument, options);
}
export type AccountServiceConnectionsQueryHookResult = ReturnType<typeof useAccountServiceConnectionsQuery>;
export type AccountServiceConnectionsLazyQueryHookResult = ReturnType<typeof useAccountServiceConnectionsLazyQuery>;
export type AccountServiceConnectionsQueryResult = Apollo.QueryResult<GqlClientAccountServiceConnectionsQuery, GqlClientAccountServiceConnectionsQueryVariables>;
export const AcquiaDamAssetsDocument = gql`
    query acquiaDamAssets(
        $categoryPath: String
        $pageNumber: Int
        $sortBy: AcquiaAssetSortBy
        $sortDirection: AcquiaAssetSortDirection
        $mediaType: AcquiaAssetMediaType
        $orientation: AcquiaAssetOrientation
        $searchTerm: String
    ) {
        acquiaAssets(categoryPath: $categoryPath, pageNumber: $pageNumber, sortBy: $sortBy, sortDirection: $sortDirection, mediaType: $mediaType, orientation: $orientation, searchTerm: $searchTerm) {
            resultCount
            assets {
                ...AcquiaDamAssetMedia
            }
        }
    }
    ${AcquiaDamAssetMediaFragmentDoc}
`;
export type GqlClientAcquiaDamAssetsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAcquiaDamAssetsQuery, GqlClientAcquiaDamAssetsQueryVariables>;
} & TChildProps;
export function withAcquiaDamAssets<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientAcquiaDamAssetsQuery, GqlClientAcquiaDamAssetsQueryVariables, GqlClientAcquiaDamAssetsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientAcquiaDamAssetsQuery, GqlClientAcquiaDamAssetsQueryVariables, GqlClientAcquiaDamAssetsProps<TChildProps, TDataName>>(AcquiaDamAssetsDocument, {
        alias: "acquiaDamAssets",
        ...operationOptions
    });
}

/**
 * __useAcquiaDamAssetsQuery__
 *
 * To run a query within a React component, call `useAcquiaDamAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcquiaDamAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcquiaDamAssetsQuery({
 *   variables: {
 *      categoryPath: // value for 'categoryPath'
 *      pageNumber: // value for 'pageNumber'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      mediaType: // value for 'mediaType'
 *      orientation: // value for 'orientation'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useAcquiaDamAssetsQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientAcquiaDamAssetsQuery, GqlClientAcquiaDamAssetsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAcquiaDamAssetsQuery, GqlClientAcquiaDamAssetsQueryVariables>(AcquiaDamAssetsDocument, options);
}
export function useAcquiaDamAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAcquiaDamAssetsQuery, GqlClientAcquiaDamAssetsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAcquiaDamAssetsQuery, GqlClientAcquiaDamAssetsQueryVariables>(AcquiaDamAssetsDocument, options);
}
export type AcquiaDamAssetsQueryHookResult = ReturnType<typeof useAcquiaDamAssetsQuery>;
export type AcquiaDamAssetsLazyQueryHookResult = ReturnType<typeof useAcquiaDamAssetsLazyQuery>;
export type AcquiaDamAssetsQueryResult = Apollo.QueryResult<GqlClientAcquiaDamAssetsQuery, GqlClientAcquiaDamAssetsQueryVariables>;
export const AcquiaDamSubCategoriesDocument = gql`
    query acquiaDamSubCategories($path: String!) {
        acquiaCategory(categoryPath: $path) {
            ...AcquiaDamCategory
            subCategories {
                ...AcquiaDamCategory
            }
        }
    }
    ${AcquiaDamCategoryFragmentDoc}
`;
export type GqlClientAcquiaDamSubCategoriesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAcquiaDamSubCategoriesQuery, GqlClientAcquiaDamSubCategoriesQueryVariables>;
} & TChildProps;
export function withAcquiaDamSubCategories<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAcquiaDamSubCategoriesQuery,
        GqlClientAcquiaDamSubCategoriesQueryVariables,
        GqlClientAcquiaDamSubCategoriesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientAcquiaDamSubCategoriesQuery, GqlClientAcquiaDamSubCategoriesQueryVariables, GqlClientAcquiaDamSubCategoriesProps<TChildProps, TDataName>>(
        AcquiaDamSubCategoriesDocument,
        {
            alias: "acquiaDamSubCategories",
            ...operationOptions
        }
    );
}

/**
 * __useAcquiaDamSubCategoriesQuery__
 *
 * To run a query within a React component, call `useAcquiaDamSubCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcquiaDamSubCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcquiaDamSubCategoriesQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useAcquiaDamSubCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientAcquiaDamSubCategoriesQuery, GqlClientAcquiaDamSubCategoriesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAcquiaDamSubCategoriesQuery, GqlClientAcquiaDamSubCategoriesQueryVariables>(AcquiaDamSubCategoriesDocument, options);
}
export function useAcquiaDamSubCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAcquiaDamSubCategoriesQuery, GqlClientAcquiaDamSubCategoriesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAcquiaDamSubCategoriesQuery, GqlClientAcquiaDamSubCategoriesQueryVariables>(AcquiaDamSubCategoriesDocument, options);
}
export type AcquiaDamSubCategoriesQueryHookResult = ReturnType<typeof useAcquiaDamSubCategoriesQuery>;
export type AcquiaDamSubCategoriesLazyQueryHookResult = ReturnType<typeof useAcquiaDamSubCategoriesLazyQuery>;
export type AcquiaDamSubCategoriesQueryResult = Apollo.QueryResult<GqlClientAcquiaDamSubCategoriesQuery, GqlClientAcquiaDamSubCategoriesQueryVariables>;
export const AiTextEditingDocument = gql`
    query aiTextEditing($input: AiTextEditingInput!) {
        aiTextEditing(input: $input) {
            modifiedText
            result
        }
    }
`;
export type GqlClientAiTextEditingProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAiTextEditingQuery, GqlClientAiTextEditingQueryVariables>;
} & TChildProps;
export function withAiTextEditing<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientAiTextEditingQuery, GqlClientAiTextEditingQueryVariables, GqlClientAiTextEditingProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientAiTextEditingQuery, GqlClientAiTextEditingQueryVariables, GqlClientAiTextEditingProps<TChildProps, TDataName>>(AiTextEditingDocument, {
        alias: "aiTextEditing",
        ...operationOptions
    });
}

/**
 * __useAiTextEditingQuery__
 *
 * To run a query within a React component, call `useAiTextEditingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiTextEditingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiTextEditingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAiTextEditingQuery(baseOptions: Apollo.QueryHookOptions<GqlClientAiTextEditingQuery, GqlClientAiTextEditingQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAiTextEditingQuery, GqlClientAiTextEditingQueryVariables>(AiTextEditingDocument, options);
}
export function useAiTextEditingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAiTextEditingQuery, GqlClientAiTextEditingQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAiTextEditingQuery, GqlClientAiTextEditingQueryVariables>(AiTextEditingDocument, options);
}
export type AiTextEditingQueryHookResult = ReturnType<typeof useAiTextEditingQuery>;
export type AiTextEditingLazyQueryHookResult = ReturnType<typeof useAiTextEditingLazyQuery>;
export type AiTextEditingQueryResult = Apollo.QueryResult<GqlClientAiTextEditingQuery, GqlClientAiTextEditingQueryVariables>;
export const BrandConfigurationCollectionUsedInProgramsDocument = gql`
    query BrandConfigurationCollectionUsedInPrograms($bcCollectionId: ID!) {
        brandConfigurationCollectionUsedInPrograms(id: $bcCollectionId) {
            id
            name
            isArchive
            programVersionDraft {
                id
                aspectRatio
                thumbnail {
                    url
                }
            }
            prodVersion {
                id
                created
            }
            uatVersion {
                id
                created
            }
        }
    }
`;
export type GqlClientBrandConfigurationCollectionUsedInProgramsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientBrandConfigurationCollectionUsedInProgramsQuery, GqlClientBrandConfigurationCollectionUsedInProgramsQueryVariables>;
} & TChildProps;
export function withBrandConfigurationCollectionUsedInPrograms<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientBrandConfigurationCollectionUsedInProgramsQuery,
        GqlClientBrandConfigurationCollectionUsedInProgramsQueryVariables,
        GqlClientBrandConfigurationCollectionUsedInProgramsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientBrandConfigurationCollectionUsedInProgramsQuery,
        GqlClientBrandConfigurationCollectionUsedInProgramsQueryVariables,
        GqlClientBrandConfigurationCollectionUsedInProgramsProps<TChildProps, TDataName>
    >(BrandConfigurationCollectionUsedInProgramsDocument, {
        alias: "brandConfigurationCollectionUsedInPrograms",
        ...operationOptions
    });
}

/**
 * __useBrandConfigurationCollectionUsedInProgramsQuery__
 *
 * To run a query within a React component, call `useBrandConfigurationCollectionUsedInProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandConfigurationCollectionUsedInProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandConfigurationCollectionUsedInProgramsQuery({
 *   variables: {
 *      bcCollectionId: // value for 'bcCollectionId'
 *   },
 * });
 */
export function useBrandConfigurationCollectionUsedInProgramsQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientBrandConfigurationCollectionUsedInProgramsQuery, GqlClientBrandConfigurationCollectionUsedInProgramsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientBrandConfigurationCollectionUsedInProgramsQuery, GqlClientBrandConfigurationCollectionUsedInProgramsQueryVariables>(
        BrandConfigurationCollectionUsedInProgramsDocument,
        options
    );
}
export function useBrandConfigurationCollectionUsedInProgramsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientBrandConfigurationCollectionUsedInProgramsQuery, GqlClientBrandConfigurationCollectionUsedInProgramsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientBrandConfigurationCollectionUsedInProgramsQuery, GqlClientBrandConfigurationCollectionUsedInProgramsQueryVariables>(
        BrandConfigurationCollectionUsedInProgramsDocument,
        options
    );
}
export type BrandConfigurationCollectionUsedInProgramsQueryHookResult = ReturnType<typeof useBrandConfigurationCollectionUsedInProgramsQuery>;
export type BrandConfigurationCollectionUsedInProgramsLazyQueryHookResult = ReturnType<typeof useBrandConfigurationCollectionUsedInProgramsLazyQuery>;
export type BrandConfigurationCollectionUsedInProgramsQueryResult = Apollo.QueryResult<
    GqlClientBrandConfigurationCollectionUsedInProgramsQuery,
    GqlClientBrandConfigurationCollectionUsedInProgramsQueryVariables
>;
export const BrandConfigurationCollectionsUsedInProgramsDocument = gql`
    query BrandConfigurationCollectionsUsedInPrograms($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                brandConfigurationVersion {
                    id
                    brandConfigurationCollections {
                        id
                        usedInPrograms {
                            id
                            programVersionDraft {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;
export type GqlClientBrandConfigurationCollectionsUsedInProgramsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientBrandConfigurationCollectionsUsedInProgramsQuery, GqlClientBrandConfigurationCollectionsUsedInProgramsQueryVariables>;
} & TChildProps;
export function withBrandConfigurationCollectionsUsedInPrograms<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientBrandConfigurationCollectionsUsedInProgramsQuery,
        GqlClientBrandConfigurationCollectionsUsedInProgramsQueryVariables,
        GqlClientBrandConfigurationCollectionsUsedInProgramsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientBrandConfigurationCollectionsUsedInProgramsQuery,
        GqlClientBrandConfigurationCollectionsUsedInProgramsQueryVariables,
        GqlClientBrandConfigurationCollectionsUsedInProgramsProps<TChildProps, TDataName>
    >(BrandConfigurationCollectionsUsedInProgramsDocument, {
        alias: "brandConfigurationCollectionsUsedInPrograms",
        ...operationOptions
    });
}

/**
 * __useBrandConfigurationCollectionsUsedInProgramsQuery__
 *
 * To run a query within a React component, call `useBrandConfigurationCollectionsUsedInProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandConfigurationCollectionsUsedInProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandConfigurationCollectionsUsedInProgramsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useBrandConfigurationCollectionsUsedInProgramsQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientBrandConfigurationCollectionsUsedInProgramsQuery, GqlClientBrandConfigurationCollectionsUsedInProgramsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientBrandConfigurationCollectionsUsedInProgramsQuery, GqlClientBrandConfigurationCollectionsUsedInProgramsQueryVariables>(
        BrandConfigurationCollectionsUsedInProgramsDocument,
        options
    );
}
export function useBrandConfigurationCollectionsUsedInProgramsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientBrandConfigurationCollectionsUsedInProgramsQuery, GqlClientBrandConfigurationCollectionsUsedInProgramsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientBrandConfigurationCollectionsUsedInProgramsQuery, GqlClientBrandConfigurationCollectionsUsedInProgramsQueryVariables>(
        BrandConfigurationCollectionsUsedInProgramsDocument,
        options
    );
}
export type BrandConfigurationCollectionsUsedInProgramsQueryHookResult = ReturnType<typeof useBrandConfigurationCollectionsUsedInProgramsQuery>;
export type BrandConfigurationCollectionsUsedInProgramsLazyQueryHookResult = ReturnType<typeof useBrandConfigurationCollectionsUsedInProgramsLazyQuery>;
export type BrandConfigurationCollectionsUsedInProgramsQueryResult = Apollo.QueryResult<
    GqlClientBrandConfigurationCollectionsUsedInProgramsQuery,
    GqlClientBrandConfigurationCollectionsUsedInProgramsQueryVariables
>;
export const CcLibraryVersionDocument = gql`
    query ccLibraryVersion($libraryId: ID!, $libraryVersionId: ID!) {
        ccLibrary(id: $libraryId) {
            id
            libraryVersion(id: $libraryVersionId) {
                ...EditorCcLibraryVersion
            }
        }
    }
    ${EditorCcLibraryVersionFragmentDoc}
`;
export type GqlClientCcLibraryVersionProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientCcLibraryVersionQuery, GqlClientCcLibraryVersionQueryVariables>;
} & TChildProps;
export function withCcLibraryVersion<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCcLibraryVersionQuery, GqlClientCcLibraryVersionQueryVariables, GqlClientCcLibraryVersionProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientCcLibraryVersionQuery, GqlClientCcLibraryVersionQueryVariables, GqlClientCcLibraryVersionProps<TChildProps, TDataName>>(CcLibraryVersionDocument, {
        alias: "ccLibraryVersion",
        ...operationOptions
    });
}

/**
 * __useCcLibraryVersionQuery__
 *
 * To run a query within a React component, call `useCcLibraryVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCcLibraryVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCcLibraryVersionQuery({
 *   variables: {
 *      libraryId: // value for 'libraryId'
 *      libraryVersionId: // value for 'libraryVersionId'
 *   },
 * });
 */
export function useCcLibraryVersionQuery(baseOptions: Apollo.QueryHookOptions<GqlClientCcLibraryVersionQuery, GqlClientCcLibraryVersionQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientCcLibraryVersionQuery, GqlClientCcLibraryVersionQueryVariables>(CcLibraryVersionDocument, options);
}
export function useCcLibraryVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientCcLibraryVersionQuery, GqlClientCcLibraryVersionQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientCcLibraryVersionQuery, GqlClientCcLibraryVersionQueryVariables>(CcLibraryVersionDocument, options);
}
export type CcLibraryVersionQueryHookResult = ReturnType<typeof useCcLibraryVersionQuery>;
export type CcLibraryVersionLazyQueryHookResult = ReturnType<typeof useCcLibraryVersionLazyQuery>;
export type CcLibraryVersionQueryResult = Apollo.QueryResult<GqlClientCcLibraryVersionQuery, GqlClientCcLibraryVersionQueryVariables>;
export const CcWireframeAllWireframesMappingDocument = gql`
    query ccWireframeAllWireframesMapping($programId: ID!, $programVersionId: ID!, $sceneId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                scene(id: $sceneId) {
                    id
                    ccWireframe {
                        id
                        allWireframesMapping {
                            ...CcWireframeAllWireframesMapping
                        }
                    }
                }
            }
        }
    }
    ${CcWireframeAllWireframesMappingFragmentDoc}
`;
export type GqlClientCcWireframeAllWireframesMappingProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientCcWireframeAllWireframesMappingQuery, GqlClientCcWireframeAllWireframesMappingQueryVariables>;
} & TChildProps;
export function withCcWireframeAllWireframesMapping<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCcWireframeAllWireframesMappingQuery,
        GqlClientCcWireframeAllWireframesMappingQueryVariables,
        GqlClientCcWireframeAllWireframesMappingProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientCcWireframeAllWireframesMappingQuery,
        GqlClientCcWireframeAllWireframesMappingQueryVariables,
        GqlClientCcWireframeAllWireframesMappingProps<TChildProps, TDataName>
    >(CcWireframeAllWireframesMappingDocument, {
        alias: "ccWireframeAllWireframesMapping",
        ...operationOptions
    });
}

/**
 * __useCcWireframeAllWireframesMappingQuery__
 *
 * To run a query within a React component, call `useCcWireframeAllWireframesMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCcWireframeAllWireframesMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCcWireframeAllWireframesMappingQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *      sceneId: // value for 'sceneId'
 *   },
 * });
 */
export function useCcWireframeAllWireframesMappingQuery(baseOptions: Apollo.QueryHookOptions<GqlClientCcWireframeAllWireframesMappingQuery, GqlClientCcWireframeAllWireframesMappingQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientCcWireframeAllWireframesMappingQuery, GqlClientCcWireframeAllWireframesMappingQueryVariables>(CcWireframeAllWireframesMappingDocument, options);
}
export function useCcWireframeAllWireframesMappingLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientCcWireframeAllWireframesMappingQuery, GqlClientCcWireframeAllWireframesMappingQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientCcWireframeAllWireframesMappingQuery, GqlClientCcWireframeAllWireframesMappingQueryVariables>(CcWireframeAllWireframesMappingDocument, options);
}
export type CcWireframeAllWireframesMappingQueryHookResult = ReturnType<typeof useCcWireframeAllWireframesMappingQuery>;
export type CcWireframeAllWireframesMappingLazyQueryHookResult = ReturnType<typeof useCcWireframeAllWireframesMappingLazyQuery>;
export type CcWireframeAllWireframesMappingQueryResult = Apollo.QueryResult<GqlClientCcWireframeAllWireframesMappingQuery, GqlClientCcWireframeAllWireframesMappingQueryVariables>;
export const EditorAccountLevelDataAvatarsDocument = gql`
    query editorAccountLevelDataAvatars {
        editorAccountLevelData {
            id
            assetLibraryVersion {
                id
                avatars {
                    ...EditorAssetLibraryAvatar
                }
            }
        }
    }
    ${EditorAssetLibraryAvatarFragmentDoc}
`;
export type GqlClientEditorAccountLevelDataAvatarsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorAccountLevelDataAvatarsQuery, GqlClientEditorAccountLevelDataAvatarsQueryVariables>;
} & TChildProps;
export function withEditorAccountLevelDataAvatars<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorAccountLevelDataAvatarsQuery,
        GqlClientEditorAccountLevelDataAvatarsQueryVariables,
        GqlClientEditorAccountLevelDataAvatarsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorAccountLevelDataAvatarsQuery,
        GqlClientEditorAccountLevelDataAvatarsQueryVariables,
        GqlClientEditorAccountLevelDataAvatarsProps<TChildProps, TDataName>
    >(EditorAccountLevelDataAvatarsDocument, {
        alias: "editorAccountLevelDataAvatars",
        ...operationOptions
    });
}

/**
 * __useEditorAccountLevelDataAvatarsQuery__
 *
 * To run a query within a React component, call `useEditorAccountLevelDataAvatarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorAccountLevelDataAvatarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorAccountLevelDataAvatarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditorAccountLevelDataAvatarsQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientEditorAccountLevelDataAvatarsQuery, GqlClientEditorAccountLevelDataAvatarsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorAccountLevelDataAvatarsQuery, GqlClientEditorAccountLevelDataAvatarsQueryVariables>(EditorAccountLevelDataAvatarsDocument, options);
}
export function useEditorAccountLevelDataAvatarsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorAccountLevelDataAvatarsQuery, GqlClientEditorAccountLevelDataAvatarsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorAccountLevelDataAvatarsQuery, GqlClientEditorAccountLevelDataAvatarsQueryVariables>(EditorAccountLevelDataAvatarsDocument, options);
}
export type EditorAccountLevelDataAvatarsQueryHookResult = ReturnType<typeof useEditorAccountLevelDataAvatarsQuery>;
export type EditorAccountLevelDataAvatarsLazyQueryHookResult = ReturnType<typeof useEditorAccountLevelDataAvatarsLazyQuery>;
export type EditorAccountLevelDataAvatarsQueryResult = Apollo.QueryResult<GqlClientEditorAccountLevelDataAvatarsQuery, GqlClientEditorAccountLevelDataAvatarsQueryVariables>;
export const EditorAccountLevelDataBrandsDocument = gql`
    query editorAccountLevelDataBrands {
        editorAccountLevelData {
            id
            brandConfigurationVersion {
                id
                brandConfigurationCollections {
                    ...EditorBrand
                }
                defaultBrandConfigurationCollection {
                    id
                }
            }
        }
    }
    ${EditorBrandFragmentDoc}
`;
export type GqlClientEditorAccountLevelDataBrandsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorAccountLevelDataBrandsQuery, GqlClientEditorAccountLevelDataBrandsQueryVariables>;
} & TChildProps;
export function withEditorAccountLevelDataBrands<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorAccountLevelDataBrandsQuery,
        GqlClientEditorAccountLevelDataBrandsQueryVariables,
        GqlClientEditorAccountLevelDataBrandsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorAccountLevelDataBrandsQuery,
        GqlClientEditorAccountLevelDataBrandsQueryVariables,
        GqlClientEditorAccountLevelDataBrandsProps<TChildProps, TDataName>
    >(EditorAccountLevelDataBrandsDocument, {
        alias: "editorAccountLevelDataBrands",
        ...operationOptions
    });
}

/**
 * __useEditorAccountLevelDataBrandsQuery__
 *
 * To run a query within a React component, call `useEditorAccountLevelDataBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorAccountLevelDataBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorAccountLevelDataBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditorAccountLevelDataBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientEditorAccountLevelDataBrandsQuery, GqlClientEditorAccountLevelDataBrandsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorAccountLevelDataBrandsQuery, GqlClientEditorAccountLevelDataBrandsQueryVariables>(EditorAccountLevelDataBrandsDocument, options);
}
export function useEditorAccountLevelDataBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorAccountLevelDataBrandsQuery, GqlClientEditorAccountLevelDataBrandsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorAccountLevelDataBrandsQuery, GqlClientEditorAccountLevelDataBrandsQueryVariables>(EditorAccountLevelDataBrandsDocument, options);
}
export type EditorAccountLevelDataBrandsQueryHookResult = ReturnType<typeof useEditorAccountLevelDataBrandsQuery>;
export type EditorAccountLevelDataBrandsLazyQueryHookResult = ReturnType<typeof useEditorAccountLevelDataBrandsLazyQuery>;
export type EditorAccountLevelDataBrandsQueryResult = Apollo.QueryResult<GqlClientEditorAccountLevelDataBrandsQuery, GqlClientEditorAccountLevelDataBrandsQueryVariables>;
export const EditorAccountLevelDataFontsDocument = gql`
    query editorAccountLevelDataFonts {
        editorAccountLevelData {
            id
            assetLibraryVersion {
                id
                fonts {
                    ...EditorAssetLibraryFont
                }
            }
        }
    }
    ${EditorAssetLibraryFontFragmentDoc}
`;
export type GqlClientEditorAccountLevelDataFontsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorAccountLevelDataFontsQuery, GqlClientEditorAccountLevelDataFontsQueryVariables>;
} & TChildProps;
export function withEditorAccountLevelDataFonts<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorAccountLevelDataFontsQuery,
        GqlClientEditorAccountLevelDataFontsQueryVariables,
        GqlClientEditorAccountLevelDataFontsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorAccountLevelDataFontsQuery,
        GqlClientEditorAccountLevelDataFontsQueryVariables,
        GqlClientEditorAccountLevelDataFontsProps<TChildProps, TDataName>
    >(EditorAccountLevelDataFontsDocument, {
        alias: "editorAccountLevelDataFonts",
        ...operationOptions
    });
}

/**
 * __useEditorAccountLevelDataFontsQuery__
 *
 * To run a query within a React component, call `useEditorAccountLevelDataFontsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorAccountLevelDataFontsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorAccountLevelDataFontsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditorAccountLevelDataFontsQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientEditorAccountLevelDataFontsQuery, GqlClientEditorAccountLevelDataFontsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorAccountLevelDataFontsQuery, GqlClientEditorAccountLevelDataFontsQueryVariables>(EditorAccountLevelDataFontsDocument, options);
}
export function useEditorAccountLevelDataFontsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorAccountLevelDataFontsQuery, GqlClientEditorAccountLevelDataFontsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorAccountLevelDataFontsQuery, GqlClientEditorAccountLevelDataFontsQueryVariables>(EditorAccountLevelDataFontsDocument, options);
}
export type EditorAccountLevelDataFontsQueryHookResult = ReturnType<typeof useEditorAccountLevelDataFontsQuery>;
export type EditorAccountLevelDataFontsLazyQueryHookResult = ReturnType<typeof useEditorAccountLevelDataFontsLazyQuery>;
export type EditorAccountLevelDataFontsQueryResult = Apollo.QueryResult<GqlClientEditorAccountLevelDataFontsQuery, GqlClientEditorAccountLevelDataFontsQueryVariables>;
export const EditorAccountLevelDataMediaDocument = gql`
    query editorAccountLevelDataMedia {
        editorAccountLevelData {
            id
            assetLibraryVersion {
                id
                media {
                    ...EditorAssetLibraryMedia
                }
                mediaFolders {
                    ...EditorAssetFolder
                }
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
    ${EditorAssetFolderFragmentDoc}
`;
export type GqlClientEditorAccountLevelDataMediaProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorAccountLevelDataMediaQuery, GqlClientEditorAccountLevelDataMediaQueryVariables>;
} & TChildProps;
export function withEditorAccountLevelDataMedia<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorAccountLevelDataMediaQuery,
        GqlClientEditorAccountLevelDataMediaQueryVariables,
        GqlClientEditorAccountLevelDataMediaProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorAccountLevelDataMediaQuery,
        GqlClientEditorAccountLevelDataMediaQueryVariables,
        GqlClientEditorAccountLevelDataMediaProps<TChildProps, TDataName>
    >(EditorAccountLevelDataMediaDocument, {
        alias: "editorAccountLevelDataMedia",
        ...operationOptions
    });
}

/**
 * __useEditorAccountLevelDataMediaQuery__
 *
 * To run a query within a React component, call `useEditorAccountLevelDataMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorAccountLevelDataMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorAccountLevelDataMediaQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditorAccountLevelDataMediaQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientEditorAccountLevelDataMediaQuery, GqlClientEditorAccountLevelDataMediaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorAccountLevelDataMediaQuery, GqlClientEditorAccountLevelDataMediaQueryVariables>(EditorAccountLevelDataMediaDocument, options);
}
export function useEditorAccountLevelDataMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorAccountLevelDataMediaQuery, GqlClientEditorAccountLevelDataMediaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorAccountLevelDataMediaQuery, GqlClientEditorAccountLevelDataMediaQueryVariables>(EditorAccountLevelDataMediaDocument, options);
}
export type EditorAccountLevelDataMediaQueryHookResult = ReturnType<typeof useEditorAccountLevelDataMediaQuery>;
export type EditorAccountLevelDataMediaLazyQueryHookResult = ReturnType<typeof useEditorAccountLevelDataMediaLazyQuery>;
export type EditorAccountLevelDataMediaQueryResult = Apollo.QueryResult<GqlClientEditorAccountLevelDataMediaQuery, GqlClientEditorAccountLevelDataMediaQueryVariables>;
export const EditorAccountLevelDataMusicDocument = gql`
    query editorAccountLevelDataMusic {
        editorAccountLevelData {
            id
            assetLibraryVersion {
                id
                music {
                    ...EditorAssetLibraryMusic
                }
            }
        }
    }
    ${EditorAssetLibraryMusicFragmentDoc}
`;
export type GqlClientEditorAccountLevelDataMusicProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorAccountLevelDataMusicQuery, GqlClientEditorAccountLevelDataMusicQueryVariables>;
} & TChildProps;
export function withEditorAccountLevelDataMusic<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorAccountLevelDataMusicQuery,
        GqlClientEditorAccountLevelDataMusicQueryVariables,
        GqlClientEditorAccountLevelDataMusicProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorAccountLevelDataMusicQuery,
        GqlClientEditorAccountLevelDataMusicQueryVariables,
        GqlClientEditorAccountLevelDataMusicProps<TChildProps, TDataName>
    >(EditorAccountLevelDataMusicDocument, {
        alias: "editorAccountLevelDataMusic",
        ...operationOptions
    });
}

/**
 * __useEditorAccountLevelDataMusicQuery__
 *
 * To run a query within a React component, call `useEditorAccountLevelDataMusicQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorAccountLevelDataMusicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorAccountLevelDataMusicQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditorAccountLevelDataMusicQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientEditorAccountLevelDataMusicQuery, GqlClientEditorAccountLevelDataMusicQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorAccountLevelDataMusicQuery, GqlClientEditorAccountLevelDataMusicQueryVariables>(EditorAccountLevelDataMusicDocument, options);
}
export function useEditorAccountLevelDataMusicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorAccountLevelDataMusicQuery, GqlClientEditorAccountLevelDataMusicQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorAccountLevelDataMusicQuery, GqlClientEditorAccountLevelDataMusicQueryVariables>(EditorAccountLevelDataMusicDocument, options);
}
export type EditorAccountLevelDataMusicQueryHookResult = ReturnType<typeof useEditorAccountLevelDataMusicQuery>;
export type EditorAccountLevelDataMusicLazyQueryHookResult = ReturnType<typeof useEditorAccountLevelDataMusicLazyQuery>;
export type EditorAccountLevelDataMusicQueryResult = Apollo.QueryResult<GqlClientEditorAccountLevelDataMusicQuery, GqlClientEditorAccountLevelDataMusicQueryVariables>;
export const EditorAccountUsersDocument = gql`
    query EditorAccountUsers {
        currentAccount {
            id
            maxCapacityReached
            users {
                id
                roles
                invitationStatus
                user {
                    id
                    email
                    firstName
                    lastName
                    businessRole
                    roles
                    lastLogin
                    created
                    hasMultipleAccounts
                }
            }
        }
    }
`;
export type GqlClientEditorAccountUsersProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorAccountUsersQuery, GqlClientEditorAccountUsersQueryVariables>;
} & TChildProps;
export function withEditorAccountUsers<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientEditorAccountUsersQuery, GqlClientEditorAccountUsersQueryVariables, GqlClientEditorAccountUsersProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorAccountUsersQuery, GqlClientEditorAccountUsersQueryVariables, GqlClientEditorAccountUsersProps<TChildProps, TDataName>>(
        EditorAccountUsersDocument,
        {
            alias: "editorAccountUsers",
            ...operationOptions
        }
    );
}

/**
 * __useEditorAccountUsersQuery__
 *
 * To run a query within a React component, call `useEditorAccountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorAccountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorAccountUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditorAccountUsersQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientEditorAccountUsersQuery, GqlClientEditorAccountUsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorAccountUsersQuery, GqlClientEditorAccountUsersQueryVariables>(EditorAccountUsersDocument, options);
}
export function useEditorAccountUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorAccountUsersQuery, GqlClientEditorAccountUsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorAccountUsersQuery, GqlClientEditorAccountUsersQueryVariables>(EditorAccountUsersDocument, options);
}
export type EditorAccountUsersQueryHookResult = ReturnType<typeof useEditorAccountUsersQuery>;
export type EditorAccountUsersLazyQueryHookResult = ReturnType<typeof useEditorAccountUsersLazyQuery>;
export type EditorAccountUsersQueryResult = Apollo.QueryResult<GqlClientEditorAccountUsersQuery, GqlClientEditorAccountUsersQueryVariables>;
export const EditorAssetLibraryAccountMediaDocument = gql`
    query editorAssetLibraryAccountMedia($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                assetLibrary {
                    id
                    media {
                        ...EditorAssetLibraryMedia
                    }
                    mediaFolders {
                        ...EditorAssetFolder
                    }
                }
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
    ${EditorAssetFolderFragmentDoc}
`;
export type GqlClientEditorAssetLibraryAccountMediaProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorAssetLibraryAccountMediaQuery, GqlClientEditorAssetLibraryAccountMediaQueryVariables>;
} & TChildProps;
export function withEditorAssetLibraryAccountMedia<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorAssetLibraryAccountMediaQuery,
        GqlClientEditorAssetLibraryAccountMediaQueryVariables,
        GqlClientEditorAssetLibraryAccountMediaProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorAssetLibraryAccountMediaQuery,
        GqlClientEditorAssetLibraryAccountMediaQueryVariables,
        GqlClientEditorAssetLibraryAccountMediaProps<TChildProps, TDataName>
    >(EditorAssetLibraryAccountMediaDocument, {
        alias: "editorAssetLibraryAccountMedia",
        ...operationOptions
    });
}

/**
 * __useEditorAssetLibraryAccountMediaQuery__
 *
 * To run a query within a React component, call `useEditorAssetLibraryAccountMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorAssetLibraryAccountMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorAssetLibraryAccountMediaQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorAssetLibraryAccountMediaQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorAssetLibraryAccountMediaQuery, GqlClientEditorAssetLibraryAccountMediaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorAssetLibraryAccountMediaQuery, GqlClientEditorAssetLibraryAccountMediaQueryVariables>(EditorAssetLibraryAccountMediaDocument, options);
}
export function useEditorAssetLibraryAccountMediaLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorAssetLibraryAccountMediaQuery, GqlClientEditorAssetLibraryAccountMediaQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorAssetLibraryAccountMediaQuery, GqlClientEditorAssetLibraryAccountMediaQueryVariables>(EditorAssetLibraryAccountMediaDocument, options);
}
export type EditorAssetLibraryAccountMediaQueryHookResult = ReturnType<typeof useEditorAssetLibraryAccountMediaQuery>;
export type EditorAssetLibraryAccountMediaLazyQueryHookResult = ReturnType<typeof useEditorAssetLibraryAccountMediaLazyQuery>;
export type EditorAssetLibraryAccountMediaQueryResult = Apollo.QueryResult<GqlClientEditorAssetLibraryAccountMediaQuery, GqlClientEditorAssetLibraryAccountMediaQueryVariables>;
export const EditorAssetLibraryMusicDocument = gql`
    query editorAssetLibraryMusic($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                assetLibrary {
                    id
                    music {
                        ...EditorAssetLibraryMusic
                    }
                }
            }
        }
    }
    ${EditorAssetLibraryMusicFragmentDoc}
`;
export type GqlClientEditorAssetLibraryMusicProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorAssetLibraryMusicQuery, GqlClientEditorAssetLibraryMusicQueryVariables>;
} & TChildProps;
export function withEditorAssetLibraryMusic<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorAssetLibraryMusicQuery,
        GqlClientEditorAssetLibraryMusicQueryVariables,
        GqlClientEditorAssetLibraryMusicProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorAssetLibraryMusicQuery, GqlClientEditorAssetLibraryMusicQueryVariables, GqlClientEditorAssetLibraryMusicProps<TChildProps, TDataName>>(
        EditorAssetLibraryMusicDocument,
        {
            alias: "editorAssetLibraryMusic",
            ...operationOptions
        }
    );
}

/**
 * __useEditorAssetLibraryMusicQuery__
 *
 * To run a query within a React component, call `useEditorAssetLibraryMusicQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorAssetLibraryMusicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorAssetLibraryMusicQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorAssetLibraryMusicQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorAssetLibraryMusicQuery, GqlClientEditorAssetLibraryMusicQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorAssetLibraryMusicQuery, GqlClientEditorAssetLibraryMusicQueryVariables>(EditorAssetLibraryMusicDocument, options);
}
export function useEditorAssetLibraryMusicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorAssetLibraryMusicQuery, GqlClientEditorAssetLibraryMusicQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorAssetLibraryMusicQuery, GqlClientEditorAssetLibraryMusicQueryVariables>(EditorAssetLibraryMusicDocument, options);
}
export type EditorAssetLibraryMusicQueryHookResult = ReturnType<typeof useEditorAssetLibraryMusicQuery>;
export type EditorAssetLibraryMusicLazyQueryHookResult = ReturnType<typeof useEditorAssetLibraryMusicLazyQuery>;
export type EditorAssetLibraryMusicQueryResult = Apollo.QueryResult<GqlClientEditorAssetLibraryMusicQuery, GqlClientEditorAssetLibraryMusicQueryVariables>;
export const ProjectAudioSettingsDocument = gql`
    query projectAudioSettings($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                audioSettings
            }
        }
    }
`;
export type GqlClientProjectAudioSettingsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProjectAudioSettingsQuery, GqlClientProjectAudioSettingsQueryVariables>;
} & TChildProps;
export function withProjectAudioSettings<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProjectAudioSettingsQuery,
        GqlClientProjectAudioSettingsQueryVariables,
        GqlClientProjectAudioSettingsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientProjectAudioSettingsQuery, GqlClientProjectAudioSettingsQueryVariables, GqlClientProjectAudioSettingsProps<TChildProps, TDataName>>(
        ProjectAudioSettingsDocument,
        {
            alias: "projectAudioSettings",
            ...operationOptions
        }
    );
}

/**
 * __useProjectAudioSettingsQuery__
 *
 * To run a query within a React component, call `useProjectAudioSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAudioSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAudioSettingsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProjectAudioSettingsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientProjectAudioSettingsQuery, GqlClientProjectAudioSettingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientProjectAudioSettingsQuery, GqlClientProjectAudioSettingsQueryVariables>(ProjectAudioSettingsDocument, options);
}
export function useProjectAudioSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientProjectAudioSettingsQuery, GqlClientProjectAudioSettingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientProjectAudioSettingsQuery, GqlClientProjectAudioSettingsQueryVariables>(ProjectAudioSettingsDocument, options);
}
export type ProjectAudioSettingsQueryHookResult = ReturnType<typeof useProjectAudioSettingsQuery>;
export type ProjectAudioSettingsLazyQueryHookResult = ReturnType<typeof useProjectAudioSettingsLazyQuery>;
export type ProjectAudioSettingsQueryResult = Apollo.QueryResult<GqlClientProjectAudioSettingsQuery, GqlClientProjectAudioSettingsQueryVariables>;
export const EditorBrandsDocument = gql`
    query editorBrands($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                brandConfigurationVersion {
                    id
                    brandConfigurationCollections {
                        ...EditorBrand
                    }
                }
            }
        }
    }
    ${EditorBrandFragmentDoc}
`;
export type GqlClientEditorBrandsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorBrandsQuery, GqlClientEditorBrandsQueryVariables>;
} & TChildProps;
export function withEditorBrands<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientEditorBrandsQuery, GqlClientEditorBrandsQueryVariables, GqlClientEditorBrandsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorBrandsQuery, GqlClientEditorBrandsQueryVariables, GqlClientEditorBrandsProps<TChildProps, TDataName>>(EditorBrandsDocument, {
        alias: "editorBrands",
        ...operationOptions
    });
}

/**
 * __useEditorBrandsQuery__
 *
 * To run a query within a React component, call `useEditorBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorBrandsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorBrandsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorBrandsQuery, GqlClientEditorBrandsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorBrandsQuery, GqlClientEditorBrandsQueryVariables>(EditorBrandsDocument, options);
}
export function useEditorBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorBrandsQuery, GqlClientEditorBrandsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorBrandsQuery, GqlClientEditorBrandsQueryVariables>(EditorBrandsDocument, options);
}
export type EditorBrandsQueryHookResult = ReturnType<typeof useEditorBrandsQuery>;
export type EditorBrandsLazyQueryHookResult = ReturnType<typeof useEditorBrandsLazyQuery>;
export type EditorBrandsQueryResult = Apollo.QueryResult<GqlClientEditorBrandsQuery, GqlClientEditorBrandsQueryVariables>;
export const EditorDataLibraryGenerateLinksDocument = gql`
    query editorDataLibraryGenerateLinks($input: DataLibraryGenerateLinksFromSpecificImportsInput!) {
        dataLibraryGenerateLinksFromSpecificImports(input: $input)
    }
`;
export type GqlClientEditorDataLibraryGenerateLinksProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorDataLibraryGenerateLinksQuery, GqlClientEditorDataLibraryGenerateLinksQueryVariables>;
} & TChildProps;
export function withEditorDataLibraryGenerateLinks<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorDataLibraryGenerateLinksQuery,
        GqlClientEditorDataLibraryGenerateLinksQueryVariables,
        GqlClientEditorDataLibraryGenerateLinksProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorDataLibraryGenerateLinksQuery,
        GqlClientEditorDataLibraryGenerateLinksQueryVariables,
        GqlClientEditorDataLibraryGenerateLinksProps<TChildProps, TDataName>
    >(EditorDataLibraryGenerateLinksDocument, {
        alias: "editorDataLibraryGenerateLinks",
        ...operationOptions
    });
}

/**
 * __useEditorDataLibraryGenerateLinksQuery__
 *
 * To run a query within a React component, call `useEditorDataLibraryGenerateLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorDataLibraryGenerateLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorDataLibraryGenerateLinksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditorDataLibraryGenerateLinksQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorDataLibraryGenerateLinksQuery, GqlClientEditorDataLibraryGenerateLinksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorDataLibraryGenerateLinksQuery, GqlClientEditorDataLibraryGenerateLinksQueryVariables>(EditorDataLibraryGenerateLinksDocument, options);
}
export function useEditorDataLibraryGenerateLinksLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorDataLibraryGenerateLinksQuery, GqlClientEditorDataLibraryGenerateLinksQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorDataLibraryGenerateLinksQuery, GqlClientEditorDataLibraryGenerateLinksQueryVariables>(EditorDataLibraryGenerateLinksDocument, options);
}
export type EditorDataLibraryGenerateLinksQueryHookResult = ReturnType<typeof useEditorDataLibraryGenerateLinksQuery>;
export type EditorDataLibraryGenerateLinksLazyQueryHookResult = ReturnType<typeof useEditorDataLibraryGenerateLinksLazyQuery>;
export type EditorDataLibraryGenerateLinksQueryResult = Apollo.QueryResult<GqlClientEditorDataLibraryGenerateLinksQuery, GqlClientEditorDataLibraryGenerateLinksQueryVariables>;
export const EditorDataLibraryImportHistoryDocument = gql`
    query editorDataLibraryImportHistory($dataLibraryId: ID!) {
        dataLibraryGetImportHistory(dataLibraryId: $dataLibraryId) {
            id
            dataLibraryId
            importStatusMetadataItems {
                id
                status
                user
                fileName
                date
                countFailures
                countSuccesses
            }
        }
    }
`;
export type GqlClientEditorDataLibraryImportHistoryProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorDataLibraryImportHistoryQuery, GqlClientEditorDataLibraryImportHistoryQueryVariables>;
} & TChildProps;
export function withEditorDataLibraryImportHistory<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorDataLibraryImportHistoryQuery,
        GqlClientEditorDataLibraryImportHistoryQueryVariables,
        GqlClientEditorDataLibraryImportHistoryProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorDataLibraryImportHistoryQuery,
        GqlClientEditorDataLibraryImportHistoryQueryVariables,
        GqlClientEditorDataLibraryImportHistoryProps<TChildProps, TDataName>
    >(EditorDataLibraryImportHistoryDocument, {
        alias: "editorDataLibraryImportHistory",
        ...operationOptions
    });
}

/**
 * __useEditorDataLibraryImportHistoryQuery__
 *
 * To run a query within a React component, call `useEditorDataLibraryImportHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorDataLibraryImportHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorDataLibraryImportHistoryQuery({
 *   variables: {
 *      dataLibraryId: // value for 'dataLibraryId'
 *   },
 * });
 */
export function useEditorDataLibraryImportHistoryQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorDataLibraryImportHistoryQuery, GqlClientEditorDataLibraryImportHistoryQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorDataLibraryImportHistoryQuery, GqlClientEditorDataLibraryImportHistoryQueryVariables>(EditorDataLibraryImportHistoryDocument, options);
}
export function useEditorDataLibraryImportHistoryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorDataLibraryImportHistoryQuery, GqlClientEditorDataLibraryImportHistoryQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorDataLibraryImportHistoryQuery, GqlClientEditorDataLibraryImportHistoryQueryVariables>(EditorDataLibraryImportHistoryDocument, options);
}
export type EditorDataLibraryImportHistoryQueryHookResult = ReturnType<typeof useEditorDataLibraryImportHistoryQuery>;
export type EditorDataLibraryImportHistoryLazyQueryHookResult = ReturnType<typeof useEditorDataLibraryImportHistoryLazyQuery>;
export type EditorDataLibraryImportHistoryQueryResult = Apollo.QueryResult<GqlClientEditorDataLibraryImportHistoryQuery, GqlClientEditorDataLibraryImportHistoryQueryVariables>;
export const EditorDefaultBrandIdDocument = gql`
    query editorDefaultBrandId($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                brandConfigurationVersion {
                    id
                    defaultBrandConfigurationCollection {
                        id
                        localId
                    }
                }
            }
        }
    }
`;
export type GqlClientEditorDefaultBrandIdProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorDefaultBrandIdQuery, GqlClientEditorDefaultBrandIdQueryVariables>;
} & TChildProps;
export function withEditorDefaultBrandId<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorDefaultBrandIdQuery,
        GqlClientEditorDefaultBrandIdQueryVariables,
        GqlClientEditorDefaultBrandIdProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorDefaultBrandIdQuery, GqlClientEditorDefaultBrandIdQueryVariables, GqlClientEditorDefaultBrandIdProps<TChildProps, TDataName>>(
        EditorDefaultBrandIdDocument,
        {
            alias: "editorDefaultBrandId",
            ...operationOptions
        }
    );
}

/**
 * __useEditorDefaultBrandIdQuery__
 *
 * To run a query within a React component, call `useEditorDefaultBrandIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorDefaultBrandIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorDefaultBrandIdQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorDefaultBrandIdQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorDefaultBrandIdQuery, GqlClientEditorDefaultBrandIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorDefaultBrandIdQuery, GqlClientEditorDefaultBrandIdQueryVariables>(EditorDefaultBrandIdDocument, options);
}
export function useEditorDefaultBrandIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorDefaultBrandIdQuery, GqlClientEditorDefaultBrandIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorDefaultBrandIdQuery, GqlClientEditorDefaultBrandIdQueryVariables>(EditorDefaultBrandIdDocument, options);
}
export type EditorDefaultBrandIdQueryHookResult = ReturnType<typeof useEditorDefaultBrandIdQuery>;
export type EditorDefaultBrandIdLazyQueryHookResult = ReturnType<typeof useEditorDefaultBrandIdLazyQuery>;
export type EditorDefaultBrandIdQueryResult = Apollo.QueryResult<GqlClientEditorDefaultBrandIdQuery, GqlClientEditorDefaultBrandIdQueryVariables>;
export const EditorGettyMediaDocument = gql`
    query editorGettyMedia(
        $includeImages: Boolean!
        $includeVideos: Boolean!
        $searchTerm: String!
        $orientation: GettyOrientation
        $minClipDuration: Int
        $maxClipDuration: Int
        $pageNumber: Int!
        $sortOrder: GettySortOrder!
    ) {
        gettySearchMetadata(
            includeImages: $includeImages
            includeVideos: $includeVideos
            searchTerm: $searchTerm
            orientation: $orientation
            minClipDuration: $minClipDuration
            maxClipDuration: $maxClipDuration
            pageNumber: $pageNumber
            sortOrder: $sortOrder
        ) {
            resultCount
            assets {
                ... on GettyImageMetadata {
                    id
                    title
                    thumbUri
                    previewUri
                    largePreviewUri
                    largePreviewPosterUri
                    downloadSizes {
                        name
                        bytes
                        height
                        media_type
                        width
                    }
                }
                ... on GettyVideoMetadata {
                    id
                    title
                    thumbUri
                    previewUri
                    largePreviewUri
                    largePreviewPosterUri
                    downloadSizes {
                        width
                        height
                        bytes
                        name
                        content_type
                        description
                        format
                    }
                    duration
                }
            }
        }
    }
`;
export type GqlClientEditorGettyMediaProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorGettyMediaQuery, GqlClientEditorGettyMediaQueryVariables>;
} & TChildProps;
export function withEditorGettyMedia<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientEditorGettyMediaQuery, GqlClientEditorGettyMediaQueryVariables, GqlClientEditorGettyMediaProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorGettyMediaQuery, GqlClientEditorGettyMediaQueryVariables, GqlClientEditorGettyMediaProps<TChildProps, TDataName>>(EditorGettyMediaDocument, {
        alias: "editorGettyMedia",
        ...operationOptions
    });
}

/**
 * __useEditorGettyMediaQuery__
 *
 * To run a query within a React component, call `useEditorGettyMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorGettyMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorGettyMediaQuery({
 *   variables: {
 *      includeImages: // value for 'includeImages'
 *      includeVideos: // value for 'includeVideos'
 *      searchTerm: // value for 'searchTerm'
 *      orientation: // value for 'orientation'
 *      minClipDuration: // value for 'minClipDuration'
 *      maxClipDuration: // value for 'maxClipDuration'
 *      pageNumber: // value for 'pageNumber'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useEditorGettyMediaQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorGettyMediaQuery, GqlClientEditorGettyMediaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorGettyMediaQuery, GqlClientEditorGettyMediaQueryVariables>(EditorGettyMediaDocument, options);
}
export function useEditorGettyMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorGettyMediaQuery, GqlClientEditorGettyMediaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorGettyMediaQuery, GqlClientEditorGettyMediaQueryVariables>(EditorGettyMediaDocument, options);
}
export type EditorGettyMediaQueryHookResult = ReturnType<typeof useEditorGettyMediaQuery>;
export type EditorGettyMediaLazyQueryHookResult = ReturnType<typeof useEditorGettyMediaLazyQuery>;
export type EditorGettyMediaQueryResult = Apollo.QueryResult<GqlClientEditorGettyMediaQuery, GqlClientEditorGettyMediaQueryVariables>;
export const LegacyAssetsBasePathDocument = gql`
    query legacyAssetsBasePath($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                legacyAssetLocationBasePath
            }
        }
    }
`;
export type GqlClientLegacyAssetsBasePathProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientLegacyAssetsBasePathQuery, GqlClientLegacyAssetsBasePathQueryVariables>;
} & TChildProps;
export function withLegacyAssetsBasePath<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientLegacyAssetsBasePathQuery,
        GqlClientLegacyAssetsBasePathQueryVariables,
        GqlClientLegacyAssetsBasePathProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientLegacyAssetsBasePathQuery, GqlClientLegacyAssetsBasePathQueryVariables, GqlClientLegacyAssetsBasePathProps<TChildProps, TDataName>>(
        LegacyAssetsBasePathDocument,
        {
            alias: "legacyAssetsBasePath",
            ...operationOptions
        }
    );
}

/**
 * __useLegacyAssetsBasePathQuery__
 *
 * To run a query within a React component, call `useLegacyAssetsBasePathQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyAssetsBasePathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyAssetsBasePathQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useLegacyAssetsBasePathQuery(baseOptions: Apollo.QueryHookOptions<GqlClientLegacyAssetsBasePathQuery, GqlClientLegacyAssetsBasePathQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientLegacyAssetsBasePathQuery, GqlClientLegacyAssetsBasePathQueryVariables>(LegacyAssetsBasePathDocument, options);
}
export function useLegacyAssetsBasePathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientLegacyAssetsBasePathQuery, GqlClientLegacyAssetsBasePathQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientLegacyAssetsBasePathQuery, GqlClientLegacyAssetsBasePathQueryVariables>(LegacyAssetsBasePathDocument, options);
}
export type LegacyAssetsBasePathQueryHookResult = ReturnType<typeof useLegacyAssetsBasePathQuery>;
export type LegacyAssetsBasePathLazyQueryHookResult = ReturnType<typeof useLegacyAssetsBasePathLazyQuery>;
export type LegacyAssetsBasePathQueryResult = Apollo.QueryResult<GqlClientLegacyAssetsBasePathQuery, GqlClientLegacyAssetsBasePathQueryVariables>;
export const EditorLibraryAnimatedWireframeDocument = gql`
    query editorLibraryAnimatedWireframe($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ccLibraryVersion {
                    id
                    wireframes {
                        id
                        animatedWireframes {
                            ...EditorAnimatedWireframe
                        }
                    }
                }
            }
        }
    }
    ${EditorAnimatedWireframeFragmentDoc}
`;
export type GqlClientEditorLibraryAnimatedWireframeProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorLibraryAnimatedWireframeQuery, GqlClientEditorLibraryAnimatedWireframeQueryVariables>;
} & TChildProps;
export function withEditorLibraryAnimatedWireframe<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorLibraryAnimatedWireframeQuery,
        GqlClientEditorLibraryAnimatedWireframeQueryVariables,
        GqlClientEditorLibraryAnimatedWireframeProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorLibraryAnimatedWireframeQuery,
        GqlClientEditorLibraryAnimatedWireframeQueryVariables,
        GqlClientEditorLibraryAnimatedWireframeProps<TChildProps, TDataName>
    >(EditorLibraryAnimatedWireframeDocument, {
        alias: "editorLibraryAnimatedWireframe",
        ...operationOptions
    });
}

/**
 * __useEditorLibraryAnimatedWireframeQuery__
 *
 * To run a query within a React component, call `useEditorLibraryAnimatedWireframeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorLibraryAnimatedWireframeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorLibraryAnimatedWireframeQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorLibraryAnimatedWireframeQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorLibraryAnimatedWireframeQuery, GqlClientEditorLibraryAnimatedWireframeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorLibraryAnimatedWireframeQuery, GqlClientEditorLibraryAnimatedWireframeQueryVariables>(EditorLibraryAnimatedWireframeDocument, options);
}
export function useEditorLibraryAnimatedWireframeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorLibraryAnimatedWireframeQuery, GqlClientEditorLibraryAnimatedWireframeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorLibraryAnimatedWireframeQuery, GqlClientEditorLibraryAnimatedWireframeQueryVariables>(EditorLibraryAnimatedWireframeDocument, options);
}
export type EditorLibraryAnimatedWireframeQueryHookResult = ReturnType<typeof useEditorLibraryAnimatedWireframeQuery>;
export type EditorLibraryAnimatedWireframeLazyQueryHookResult = ReturnType<typeof useEditorLibraryAnimatedWireframeLazyQuery>;
export type EditorLibraryAnimatedWireframeQueryResult = Apollo.QueryResult<GqlClientEditorLibraryAnimatedWireframeQuery, GqlClientEditorLibraryAnimatedWireframeQueryVariables>;
export const EditorLibraryAvatarsDocument = gql`
    query editorLibraryAvatars($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ccLibraryVersion {
                    id
                    avatars {
                        ...EditorLibraryAvatar
                    }
                }
            }
        }
    }
    ${EditorLibraryAvatarFragmentDoc}
`;
export type GqlClientEditorLibraryAvatarsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorLibraryAvatarsQuery, GqlClientEditorLibraryAvatarsQueryVariables>;
} & TChildProps;
export function withEditorLibraryAvatars<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorLibraryAvatarsQuery,
        GqlClientEditorLibraryAvatarsQueryVariables,
        GqlClientEditorLibraryAvatarsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorLibraryAvatarsQuery, GqlClientEditorLibraryAvatarsQueryVariables, GqlClientEditorLibraryAvatarsProps<TChildProps, TDataName>>(
        EditorLibraryAvatarsDocument,
        {
            alias: "editorLibraryAvatars",
            ...operationOptions
        }
    );
}

/**
 * __useEditorLibraryAvatarsQuery__
 *
 * To run a query within a React component, call `useEditorLibraryAvatarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorLibraryAvatarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorLibraryAvatarsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorLibraryAvatarsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorLibraryAvatarsQuery, GqlClientEditorLibraryAvatarsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorLibraryAvatarsQuery, GqlClientEditorLibraryAvatarsQueryVariables>(EditorLibraryAvatarsDocument, options);
}
export function useEditorLibraryAvatarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorLibraryAvatarsQuery, GqlClientEditorLibraryAvatarsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorLibraryAvatarsQuery, GqlClientEditorLibraryAvatarsQueryVariables>(EditorLibraryAvatarsDocument, options);
}
export type EditorLibraryAvatarsQueryHookResult = ReturnType<typeof useEditorLibraryAvatarsQuery>;
export type EditorLibraryAvatarsLazyQueryHookResult = ReturnType<typeof useEditorLibraryAvatarsLazyQuery>;
export type EditorLibraryAvatarsQueryResult = Apollo.QueryResult<GqlClientEditorLibraryAvatarsQuery, GqlClientEditorLibraryAvatarsQueryVariables>;
export const EditorLibraryDofsDocument = gql`
    query editorLibraryDofs($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ccLibraryVersion {
                    id
                    dofs {
                        ...EditorLibraryDof
                    }
                }
            }
        }
    }
    ${EditorLibraryDofFragmentDoc}
`;
export type GqlClientEditorLibraryDofsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorLibraryDofsQuery, GqlClientEditorLibraryDofsQueryVariables>;
} & TChildProps;
export function withEditorLibraryDofs<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientEditorLibraryDofsQuery, GqlClientEditorLibraryDofsQueryVariables, GqlClientEditorLibraryDofsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorLibraryDofsQuery, GqlClientEditorLibraryDofsQueryVariables, GqlClientEditorLibraryDofsProps<TChildProps, TDataName>>(
        EditorLibraryDofsDocument,
        {
            alias: "editorLibraryDofs",
            ...operationOptions
        }
    );
}

/**
 * __useEditorLibraryDofsQuery__
 *
 * To run a query within a React component, call `useEditorLibraryDofsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorLibraryDofsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorLibraryDofsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorLibraryDofsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorLibraryDofsQuery, GqlClientEditorLibraryDofsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorLibraryDofsQuery, GqlClientEditorLibraryDofsQueryVariables>(EditorLibraryDofsDocument, options);
}
export function useEditorLibraryDofsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorLibraryDofsQuery, GqlClientEditorLibraryDofsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorLibraryDofsQuery, GqlClientEditorLibraryDofsQueryVariables>(EditorLibraryDofsDocument, options);
}
export type EditorLibraryDofsQueryHookResult = ReturnType<typeof useEditorLibraryDofsQuery>;
export type EditorLibraryDofsLazyQueryHookResult = ReturnType<typeof useEditorLibraryDofsLazyQuery>;
export type EditorLibraryDofsQueryResult = Apollo.QueryResult<GqlClientEditorLibraryDofsQuery, GqlClientEditorLibraryDofsQueryVariables>;
export const EditorLibraryMusicDocument = gql`
    query editorLibraryMusic($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ccLibraryVersion {
                    id
                    music {
                        ...EditorLibraryMusic
                    }
                }
            }
        }
    }
    ${EditorLibraryMusicFragmentDoc}
`;
export type GqlClientEditorLibraryMusicProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorLibraryMusicQuery, GqlClientEditorLibraryMusicQueryVariables>;
} & TChildProps;
export function withEditorLibraryMusic<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientEditorLibraryMusicQuery, GqlClientEditorLibraryMusicQueryVariables, GqlClientEditorLibraryMusicProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorLibraryMusicQuery, GqlClientEditorLibraryMusicQueryVariables, GqlClientEditorLibraryMusicProps<TChildProps, TDataName>>(
        EditorLibraryMusicDocument,
        {
            alias: "editorLibraryMusic",
            ...operationOptions
        }
    );
}

/**
 * __useEditorLibraryMusicQuery__
 *
 * To run a query within a React component, call `useEditorLibraryMusicQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorLibraryMusicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorLibraryMusicQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorLibraryMusicQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorLibraryMusicQuery, GqlClientEditorLibraryMusicQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorLibraryMusicQuery, GqlClientEditorLibraryMusicQueryVariables>(EditorLibraryMusicDocument, options);
}
export function useEditorLibraryMusicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorLibraryMusicQuery, GqlClientEditorLibraryMusicQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorLibraryMusicQuery, GqlClientEditorLibraryMusicQueryVariables>(EditorLibraryMusicDocument, options);
}
export type EditorLibraryMusicQueryHookResult = ReturnType<typeof useEditorLibraryMusicQuery>;
export type EditorLibraryMusicLazyQueryHookResult = ReturnType<typeof useEditorLibraryMusicLazyQuery>;
export type EditorLibraryMusicQueryResult = Apollo.QueryResult<GqlClientEditorLibraryMusicQuery, GqlClientEditorLibraryMusicQueryVariables>;
export const EditorLibraryNarratorsDocument = gql`
    query editorLibraryNarrators($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ccLibraryVersion {
                    id
                    narrators {
                        ...EditorLibraryNarrator
                    }
                }
            }
        }
    }
    ${EditorLibraryNarratorFragmentDoc}
`;
export type GqlClientEditorLibraryNarratorsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorLibraryNarratorsQuery, GqlClientEditorLibraryNarratorsQueryVariables>;
} & TChildProps;
export function withEditorLibraryNarrators<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorLibraryNarratorsQuery,
        GqlClientEditorLibraryNarratorsQueryVariables,
        GqlClientEditorLibraryNarratorsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorLibraryNarratorsQuery, GqlClientEditorLibraryNarratorsQueryVariables, GqlClientEditorLibraryNarratorsProps<TChildProps, TDataName>>(
        EditorLibraryNarratorsDocument,
        {
            alias: "editorLibraryNarrators",
            ...operationOptions
        }
    );
}

/**
 * __useEditorLibraryNarratorsQuery__
 *
 * To run a query within a React component, call `useEditorLibraryNarratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorLibraryNarratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorLibraryNarratorsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorLibraryNarratorsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorLibraryNarratorsQuery, GqlClientEditorLibraryNarratorsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorLibraryNarratorsQuery, GqlClientEditorLibraryNarratorsQueryVariables>(EditorLibraryNarratorsDocument, options);
}
export function useEditorLibraryNarratorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorLibraryNarratorsQuery, GqlClientEditorLibraryNarratorsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorLibraryNarratorsQuery, GqlClientEditorLibraryNarratorsQueryVariables>(EditorLibraryNarratorsDocument, options);
}
export type EditorLibraryNarratorsQueryHookResult = ReturnType<typeof useEditorLibraryNarratorsQuery>;
export type EditorLibraryNarratorsLazyQueryHookResult = ReturnType<typeof useEditorLibraryNarratorsLazyQuery>;
export type EditorLibraryNarratorsQueryResult = Apollo.QueryResult<GqlClientEditorLibraryNarratorsQuery, GqlClientEditorLibraryNarratorsQueryVariables>;
export const EditorLibraryPlaceholderIntentsDocument = gql`
    query editorLibraryPlaceholderIntents($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ccLibraryVersion {
                    id
                    placeholderIntents {
                        ...EditorLibraryPlaceholderIntent
                    }
                }
            }
        }
    }
    ${EditorLibraryPlaceholderIntentFragmentDoc}
`;
export type GqlClientEditorLibraryPlaceholderIntentsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorLibraryPlaceholderIntentsQuery, GqlClientEditorLibraryPlaceholderIntentsQueryVariables>;
} & TChildProps;
export function withEditorLibraryPlaceholderIntents<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorLibraryPlaceholderIntentsQuery,
        GqlClientEditorLibraryPlaceholderIntentsQueryVariables,
        GqlClientEditorLibraryPlaceholderIntentsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorLibraryPlaceholderIntentsQuery,
        GqlClientEditorLibraryPlaceholderIntentsQueryVariables,
        GqlClientEditorLibraryPlaceholderIntentsProps<TChildProps, TDataName>
    >(EditorLibraryPlaceholderIntentsDocument, {
        alias: "editorLibraryPlaceholderIntents",
        ...operationOptions
    });
}

/**
 * __useEditorLibraryPlaceholderIntentsQuery__
 *
 * To run a query within a React component, call `useEditorLibraryPlaceholderIntentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorLibraryPlaceholderIntentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorLibraryPlaceholderIntentsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorLibraryPlaceholderIntentsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorLibraryPlaceholderIntentsQuery, GqlClientEditorLibraryPlaceholderIntentsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorLibraryPlaceholderIntentsQuery, GqlClientEditorLibraryPlaceholderIntentsQueryVariables>(EditorLibraryPlaceholderIntentsDocument, options);
}
export function useEditorLibraryPlaceholderIntentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorLibraryPlaceholderIntentsQuery, GqlClientEditorLibraryPlaceholderIntentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorLibraryPlaceholderIntentsQuery, GqlClientEditorLibraryPlaceholderIntentsQueryVariables>(EditorLibraryPlaceholderIntentsDocument, options);
}
export type EditorLibraryPlaceholderIntentsQueryHookResult = ReturnType<typeof useEditorLibraryPlaceholderIntentsQuery>;
export type EditorLibraryPlaceholderIntentsLazyQueryHookResult = ReturnType<typeof useEditorLibraryPlaceholderIntentsLazyQuery>;
export type EditorLibraryPlaceholderIntentsQueryResult = Apollo.QueryResult<GqlClientEditorLibraryPlaceholderIntentsQuery, GqlClientEditorLibraryPlaceholderIntentsQueryVariables>;
export const EditorLibraryTextStylesDocument = gql`
    query editorLibraryTextStyles($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ccLibraryVersion {
                    id
                    textStyles {
                        ...EditorLibraryTextStyle
                    }
                }
            }
        }
    }
    ${EditorLibraryTextStyleFragmentDoc}
`;
export type GqlClientEditorLibraryTextStylesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorLibraryTextStylesQuery, GqlClientEditorLibraryTextStylesQueryVariables>;
} & TChildProps;
export function withEditorLibraryTextStyles<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorLibraryTextStylesQuery,
        GqlClientEditorLibraryTextStylesQueryVariables,
        GqlClientEditorLibraryTextStylesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorLibraryTextStylesQuery, GqlClientEditorLibraryTextStylesQueryVariables, GqlClientEditorLibraryTextStylesProps<TChildProps, TDataName>>(
        EditorLibraryTextStylesDocument,
        {
            alias: "editorLibraryTextStyles",
            ...operationOptions
        }
    );
}

/**
 * __useEditorLibraryTextStylesQuery__
 *
 * To run a query within a React component, call `useEditorLibraryTextStylesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorLibraryTextStylesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorLibraryTextStylesQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorLibraryTextStylesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorLibraryTextStylesQuery, GqlClientEditorLibraryTextStylesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorLibraryTextStylesQuery, GqlClientEditorLibraryTextStylesQueryVariables>(EditorLibraryTextStylesDocument, options);
}
export function useEditorLibraryTextStylesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorLibraryTextStylesQuery, GqlClientEditorLibraryTextStylesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorLibraryTextStylesQuery, GqlClientEditorLibraryTextStylesQueryVariables>(EditorLibraryTextStylesDocument, options);
}
export type EditorLibraryTextStylesQueryHookResult = ReturnType<typeof useEditorLibraryTextStylesQuery>;
export type EditorLibraryTextStylesLazyQueryHookResult = ReturnType<typeof useEditorLibraryTextStylesLazyQuery>;
export type EditorLibraryTextStylesQueryResult = Apollo.QueryResult<GqlClientEditorLibraryTextStylesQuery, GqlClientEditorLibraryTextStylesQueryVariables>;
export const EditorLibraryThemesDocument = gql`
    query editorLibraryThemes($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ccLibraryVersion {
                    id
                    themes {
                        ...EditorLibraryTheme
                    }
                }
            }
        }
    }
    ${EditorLibraryThemeFragmentDoc}
`;
export type GqlClientEditorLibraryThemesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorLibraryThemesQuery, GqlClientEditorLibraryThemesQueryVariables>;
} & TChildProps;
export function withEditorLibraryThemes<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientEditorLibraryThemesQuery, GqlClientEditorLibraryThemesQueryVariables, GqlClientEditorLibraryThemesProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorLibraryThemesQuery, GqlClientEditorLibraryThemesQueryVariables, GqlClientEditorLibraryThemesProps<TChildProps, TDataName>>(
        EditorLibraryThemesDocument,
        {
            alias: "editorLibraryThemes",
            ...operationOptions
        }
    );
}

/**
 * __useEditorLibraryThemesQuery__
 *
 * To run a query within a React component, call `useEditorLibraryThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorLibraryThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorLibraryThemesQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorLibraryThemesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorLibraryThemesQuery, GqlClientEditorLibraryThemesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorLibraryThemesQuery, GqlClientEditorLibraryThemesQueryVariables>(EditorLibraryThemesDocument, options);
}
export function useEditorLibraryThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorLibraryThemesQuery, GqlClientEditorLibraryThemesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorLibraryThemesQuery, GqlClientEditorLibraryThemesQueryVariables>(EditorLibraryThemesDocument, options);
}
export type EditorLibraryThemesQueryHookResult = ReturnType<typeof useEditorLibraryThemesQuery>;
export type EditorLibraryThemesLazyQueryHookResult = ReturnType<typeof useEditorLibraryThemesLazyQuery>;
export type EditorLibraryThemesQueryResult = Apollo.QueryResult<GqlClientEditorLibraryThemesQuery, GqlClientEditorLibraryThemesQueryVariables>;
export const EditorLibraryWireframesDocument = gql`
    query editorLibraryWireframes($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ccLibraryVersion {
                    id
                    wireframes {
                        ...EditorLibraryWireframe
                    }
                }
            }
        }
    }
    ${EditorLibraryWireframeFragmentDoc}
`;
export type GqlClientEditorLibraryWireframesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorLibraryWireframesQuery, GqlClientEditorLibraryWireframesQueryVariables>;
} & TChildProps;
export function withEditorLibraryWireframes<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorLibraryWireframesQuery,
        GqlClientEditorLibraryWireframesQueryVariables,
        GqlClientEditorLibraryWireframesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorLibraryWireframesQuery, GqlClientEditorLibraryWireframesQueryVariables, GqlClientEditorLibraryWireframesProps<TChildProps, TDataName>>(
        EditorLibraryWireframesDocument,
        {
            alias: "editorLibraryWireframes",
            ...operationOptions
        }
    );
}

/**
 * __useEditorLibraryWireframesQuery__
 *
 * To run a query within a React component, call `useEditorLibraryWireframesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorLibraryWireframesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorLibraryWireframesQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorLibraryWireframesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorLibraryWireframesQuery, GqlClientEditorLibraryWireframesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorLibraryWireframesQuery, GqlClientEditorLibraryWireframesQueryVariables>(EditorLibraryWireframesDocument, options);
}
export function useEditorLibraryWireframesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorLibraryWireframesQuery, GqlClientEditorLibraryWireframesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorLibraryWireframesQuery, GqlClientEditorLibraryWireframesQueryVariables>(EditorLibraryWireframesDocument, options);
}
export type EditorLibraryWireframesQueryHookResult = ReturnType<typeof useEditorLibraryWireframesQuery>;
export type EditorLibraryWireframesLazyQueryHookResult = ReturnType<typeof useEditorLibraryWireframesLazyQuery>;
export type EditorLibraryWireframesQueryResult = Apollo.QueryResult<GqlClientEditorLibraryWireframesQuery, GqlClientEditorLibraryWireframesQueryVariables>;
export const EditorNarrationDurationDocument = gql`
    query editorNarrationDuration($text: String!, $narrator: String!, $service: String!, $rate: Float, $avatarInfo: AvatarInfo) {
        editorNarrationDuration(text: $text, narrator: $narrator, service: $service, rate: $rate, avatarInfo: $avatarInfo) {
            duration
            isExact
        }
    }
`;
export type GqlClientEditorNarrationDurationProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorNarrationDurationQuery, GqlClientEditorNarrationDurationQueryVariables>;
} & TChildProps;
export function withEditorNarrationDuration<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorNarrationDurationQuery,
        GqlClientEditorNarrationDurationQueryVariables,
        GqlClientEditorNarrationDurationProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorNarrationDurationQuery, GqlClientEditorNarrationDurationQueryVariables, GqlClientEditorNarrationDurationProps<TChildProps, TDataName>>(
        EditorNarrationDurationDocument,
        {
            alias: "editorNarrationDuration",
            ...operationOptions
        }
    );
}

/**
 * __useEditorNarrationDurationQuery__
 *
 * To run a query within a React component, call `useEditorNarrationDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorNarrationDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorNarrationDurationQuery({
 *   variables: {
 *      text: // value for 'text'
 *      narrator: // value for 'narrator'
 *      service: // value for 'service'
 *      rate: // value for 'rate'
 *      avatarInfo: // value for 'avatarInfo'
 *   },
 * });
 */
export function useEditorNarrationDurationQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorNarrationDurationQuery, GqlClientEditorNarrationDurationQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorNarrationDurationQuery, GqlClientEditorNarrationDurationQueryVariables>(EditorNarrationDurationDocument, options);
}
export function useEditorNarrationDurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorNarrationDurationQuery, GqlClientEditorNarrationDurationQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorNarrationDurationQuery, GqlClientEditorNarrationDurationQueryVariables>(EditorNarrationDurationDocument, options);
}
export type EditorNarrationDurationQueryHookResult = ReturnType<typeof useEditorNarrationDurationQuery>;
export type EditorNarrationDurationLazyQueryHookResult = ReturnType<typeof useEditorNarrationDurationLazyQuery>;
export type EditorNarrationDurationQueryResult = Apollo.QueryResult<GqlClientEditorNarrationDurationQuery, GqlClientEditorNarrationDurationQueryVariables>;
export const EditorProgramDocument = gql`
    query editorProgram($programId: ID!) {
        editorProgram(id: $programId) {
            ...EditorProgram
            lastArchived
            creationMethod
            originProgramId
            originalStoryTemplateProgramId
            originalStoryTemplateProgramVersionId
            folder {
                id
                name
            }
            programVersionDraft {
                id
                thumbnail {
                    url
                }
                selectedBrandConfiguration {
                    id
                    localId
                    updated
                    updatedBy
                }
                aspectRatio
                selectedNarrator {
                    id
                    narrationSystemName
                }
                isAvatarRendering
            }
        }
    }
    ${EditorProgramFragmentDoc}
`;
export type GqlClientEditorProgramProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramQuery, GqlClientEditorProgramQueryVariables>;
} & TChildProps;
export function withEditorProgram<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientEditorProgramQuery, GqlClientEditorProgramQueryVariables, GqlClientEditorProgramProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorProgramQuery, GqlClientEditorProgramQueryVariables, GqlClientEditorProgramProps<TChildProps, TDataName>>(EditorProgramDocument, {
        alias: "editorProgram",
        ...operationOptions
    });
}

/**
 * __useEditorProgramQuery__
 *
 * To run a query within a React component, call `useEditorProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useEditorProgramQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramQuery, GqlClientEditorProgramQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramQuery, GqlClientEditorProgramQueryVariables>(EditorProgramDocument, options);
}
export function useEditorProgramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramQuery, GqlClientEditorProgramQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramQuery, GqlClientEditorProgramQueryVariables>(EditorProgramDocument, options);
}
export type EditorProgramQueryHookResult = ReturnType<typeof useEditorProgramQuery>;
export type EditorProgramLazyQueryHookResult = ReturnType<typeof useEditorProgramLazyQuery>;
export type EditorProgramQueryResult = Apollo.QueryResult<GqlClientEditorProgramQuery, GqlClientEditorProgramQueryVariables>;
export const EditorProgramAspectRatioDocument = gql`
    query editorProgramAspectRatio($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                aspectRatio
            }
        }
    }
`;
export type GqlClientEditorProgramAspectRatioProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramAspectRatioQuery, GqlClientEditorProgramAspectRatioQueryVariables>;
} & TChildProps;
export function withEditorProgramAspectRatio<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramAspectRatioQuery,
        GqlClientEditorProgramAspectRatioQueryVariables,
        GqlClientEditorProgramAspectRatioProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorProgramAspectRatioQuery, GqlClientEditorProgramAspectRatioQueryVariables, GqlClientEditorProgramAspectRatioProps<TChildProps, TDataName>>(
        EditorProgramAspectRatioDocument,
        {
            alias: "editorProgramAspectRatio",
            ...operationOptions
        }
    );
}

/**
 * __useEditorProgramAspectRatioQuery__
 *
 * To run a query within a React component, call `useEditorProgramAspectRatioQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramAspectRatioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramAspectRatioQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramAspectRatioQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramAspectRatioQuery, GqlClientEditorProgramAspectRatioQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramAspectRatioQuery, GqlClientEditorProgramAspectRatioQueryVariables>(EditorProgramAspectRatioDocument, options);
}
export function useEditorProgramAspectRatioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramAspectRatioQuery, GqlClientEditorProgramAspectRatioQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramAspectRatioQuery, GqlClientEditorProgramAspectRatioQueryVariables>(EditorProgramAspectRatioDocument, options);
}
export type EditorProgramAspectRatioQueryHookResult = ReturnType<typeof useEditorProgramAspectRatioQuery>;
export type EditorProgramAspectRatioLazyQueryHookResult = ReturnType<typeof useEditorProgramAspectRatioLazyQuery>;
export type EditorProgramAspectRatioQueryResult = Apollo.QueryResult<GqlClientEditorProgramAspectRatioQuery, GqlClientEditorProgramAspectRatioQueryVariables>;
export const EditorProgramBackgroundMediaDocument = gql`
    query editorProgramBackgroundMedia($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                useCustomVideoBackground
                videoBackgroundMedia {
                    ...EditorAssetLibraryMedia
                }
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
`;
export type GqlClientEditorProgramBackgroundMediaProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramBackgroundMediaQuery, GqlClientEditorProgramBackgroundMediaQueryVariables>;
} & TChildProps;
export function withEditorProgramBackgroundMedia<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramBackgroundMediaQuery,
        GqlClientEditorProgramBackgroundMediaQueryVariables,
        GqlClientEditorProgramBackgroundMediaProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramBackgroundMediaQuery,
        GqlClientEditorProgramBackgroundMediaQueryVariables,
        GqlClientEditorProgramBackgroundMediaProps<TChildProps, TDataName>
    >(EditorProgramBackgroundMediaDocument, {
        alias: "editorProgramBackgroundMedia",
        ...operationOptions
    });
}

/**
 * __useEditorProgramBackgroundMediaQuery__
 *
 * To run a query within a React component, call `useEditorProgramBackgroundMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramBackgroundMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramBackgroundMediaQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramBackgroundMediaQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramBackgroundMediaQuery, GqlClientEditorProgramBackgroundMediaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramBackgroundMediaQuery, GqlClientEditorProgramBackgroundMediaQueryVariables>(EditorProgramBackgroundMediaDocument, options);
}
export function useEditorProgramBackgroundMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramBackgroundMediaQuery, GqlClientEditorProgramBackgroundMediaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramBackgroundMediaQuery, GqlClientEditorProgramBackgroundMediaQueryVariables>(EditorProgramBackgroundMediaDocument, options);
}
export type EditorProgramBackgroundMediaQueryHookResult = ReturnType<typeof useEditorProgramBackgroundMediaQuery>;
export type EditorProgramBackgroundMediaLazyQueryHookResult = ReturnType<typeof useEditorProgramBackgroundMediaLazyQuery>;
export type EditorProgramBackgroundMediaQueryResult = Apollo.QueryResult<GqlClientEditorProgramBackgroundMediaQuery, GqlClientEditorProgramBackgroundMediaQueryVariables>;
export const EditorProgramCustomBackgroundDataDocument = gql`
    query editorProgramCustomBackgroundData($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                useCustomVideoBackground
                videoBackgroundMedia {
                    ...EditorAssetLibraryMedia
                }
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
`;
export type GqlClientEditorProgramCustomBackgroundDataProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramCustomBackgroundDataQuery, GqlClientEditorProgramCustomBackgroundDataQueryVariables>;
} & TChildProps;
export function withEditorProgramCustomBackgroundData<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramCustomBackgroundDataQuery,
        GqlClientEditorProgramCustomBackgroundDataQueryVariables,
        GqlClientEditorProgramCustomBackgroundDataProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramCustomBackgroundDataQuery,
        GqlClientEditorProgramCustomBackgroundDataQueryVariables,
        GqlClientEditorProgramCustomBackgroundDataProps<TChildProps, TDataName>
    >(EditorProgramCustomBackgroundDataDocument, {
        alias: "editorProgramCustomBackgroundData",
        ...operationOptions
    });
}

/**
 * __useEditorProgramCustomBackgroundDataQuery__
 *
 * To run a query within a React component, call `useEditorProgramCustomBackgroundDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramCustomBackgroundDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramCustomBackgroundDataQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramCustomBackgroundDataQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramCustomBackgroundDataQuery, GqlClientEditorProgramCustomBackgroundDataQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramCustomBackgroundDataQuery, GqlClientEditorProgramCustomBackgroundDataQueryVariables>(EditorProgramCustomBackgroundDataDocument, options);
}
export function useEditorProgramCustomBackgroundDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramCustomBackgroundDataQuery, GqlClientEditorProgramCustomBackgroundDataQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramCustomBackgroundDataQuery, GqlClientEditorProgramCustomBackgroundDataQueryVariables>(EditorProgramCustomBackgroundDataDocument, options);
}
export type EditorProgramCustomBackgroundDataQueryHookResult = ReturnType<typeof useEditorProgramCustomBackgroundDataQuery>;
export type EditorProgramCustomBackgroundDataLazyQueryHookResult = ReturnType<typeof useEditorProgramCustomBackgroundDataLazyQuery>;
export type EditorProgramCustomBackgroundDataQueryResult = Apollo.QueryResult<GqlClientEditorProgramCustomBackgroundDataQuery, GqlClientEditorProgramCustomBackgroundDataQueryVariables>;
export const EditorProgramDataConnectorDocument = gql`
    query editorProgramDataConnector($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                accountDataLibraryVersion {
                    ...AccountDataLibraryVersion
                }
            }
        }
    }
    ${AccountDataLibraryVersionFragmentDoc}
`;
export type GqlClientEditorProgramDataConnectorProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramDataConnectorQuery, GqlClientEditorProgramDataConnectorQueryVariables>;
} & TChildProps;
export function withEditorProgramDataConnector<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramDataConnectorQuery,
        GqlClientEditorProgramDataConnectorQueryVariables,
        GqlClientEditorProgramDataConnectorProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramDataConnectorQuery,
        GqlClientEditorProgramDataConnectorQueryVariables,
        GqlClientEditorProgramDataConnectorProps<TChildProps, TDataName>
    >(EditorProgramDataConnectorDocument, {
        alias: "editorProgramDataConnector",
        ...operationOptions
    });
}

/**
 * __useEditorProgramDataConnectorQuery__
 *
 * To run a query within a React component, call `useEditorProgramDataConnectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramDataConnectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramDataConnectorQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramDataConnectorQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramDataConnectorQuery, GqlClientEditorProgramDataConnectorQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramDataConnectorQuery, GqlClientEditorProgramDataConnectorQueryVariables>(EditorProgramDataConnectorDocument, options);
}
export function useEditorProgramDataConnectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramDataConnectorQuery, GqlClientEditorProgramDataConnectorQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramDataConnectorQuery, GqlClientEditorProgramDataConnectorQueryVariables>(EditorProgramDataConnectorDocument, options);
}
export type EditorProgramDataConnectorQueryHookResult = ReturnType<typeof useEditorProgramDataConnectorQuery>;
export type EditorProgramDataConnectorLazyQueryHookResult = ReturnType<typeof useEditorProgramDataConnectorLazyQuery>;
export type EditorProgramDataConnectorQueryResult = Apollo.QueryResult<GqlClientEditorProgramDataConnectorQuery, GqlClientEditorProgramDataConnectorQueryVariables>;
export const EditorProgramForEmbedVideoBuilderDocument = gql`
    query editorProgramForEmbedVideoBuilder($programId: ID!) {
        editorProgram(id: $programId) {
            id
            name
            saleForceId
            programVersionDraft {
                id
                aspectRatio
                ccLibraryVersion {
                    id
                    fonts {
                        ...EditorLibraryFont
                    }
                }
                assetLibrary {
                    id
                    media {
                        ...EditorAssetLibraryMedia
                    }
                }
                selectedBrandConfiguration {
                    ...EditorBrand
                }
            }
            embedVideoCode {
                ...EmbedVideoCode
            }
        }
    }
    ${EditorLibraryFontFragmentDoc}
    ${EditorAssetLibraryMediaFragmentDoc}
    ${EditorBrandFragmentDoc}
    ${EmbedVideoCodeFragmentDoc}
`;
export type GqlClientEditorProgramForEmbedVideoBuilderProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramForEmbedVideoBuilderQuery, GqlClientEditorProgramForEmbedVideoBuilderQueryVariables>;
} & TChildProps;
export function withEditorProgramForEmbedVideoBuilder<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramForEmbedVideoBuilderQuery,
        GqlClientEditorProgramForEmbedVideoBuilderQueryVariables,
        GqlClientEditorProgramForEmbedVideoBuilderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramForEmbedVideoBuilderQuery,
        GqlClientEditorProgramForEmbedVideoBuilderQueryVariables,
        GqlClientEditorProgramForEmbedVideoBuilderProps<TChildProps, TDataName>
    >(EditorProgramForEmbedVideoBuilderDocument, {
        alias: "editorProgramForEmbedVideoBuilder",
        ...operationOptions
    });
}

/**
 * __useEditorProgramForEmbedVideoBuilderQuery__
 *
 * To run a query within a React component, call `useEditorProgramForEmbedVideoBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramForEmbedVideoBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramForEmbedVideoBuilderQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useEditorProgramForEmbedVideoBuilderQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramForEmbedVideoBuilderQuery, GqlClientEditorProgramForEmbedVideoBuilderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramForEmbedVideoBuilderQuery, GqlClientEditorProgramForEmbedVideoBuilderQueryVariables>(EditorProgramForEmbedVideoBuilderDocument, options);
}
export function useEditorProgramForEmbedVideoBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramForEmbedVideoBuilderQuery, GqlClientEditorProgramForEmbedVideoBuilderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramForEmbedVideoBuilderQuery, GqlClientEditorProgramForEmbedVideoBuilderQueryVariables>(EditorProgramForEmbedVideoBuilderDocument, options);
}
export type EditorProgramForEmbedVideoBuilderQueryHookResult = ReturnType<typeof useEditorProgramForEmbedVideoBuilderQuery>;
export type EditorProgramForEmbedVideoBuilderLazyQueryHookResult = ReturnType<typeof useEditorProgramForEmbedVideoBuilderLazyQuery>;
export type EditorProgramForEmbedVideoBuilderQueryResult = Apollo.QueryResult<GqlClientEditorProgramForEmbedVideoBuilderQuery, GqlClientEditorProgramForEmbedVideoBuilderQueryVariables>;
export const EditorProgramForLandingPageBuilderDocument = gql`
    query editorProgramForLandingPageBuilder($programId: ID!) {
        editorProgram(id: $programId) {
            id
            name
            saleForceId
            programVersionDraft {
                id
                aspectRatio
                ccLibraryVersion {
                    id
                    fonts {
                        ...EditorLibraryFont
                    }
                    textStyles {
                        ...EditorLibraryTextStyle
                    }
                }
                assetLibrary {
                    id
                    fonts {
                        ...EditorAssetLibraryFont
                    }
                    media {
                        ...EditorAssetLibraryMedia
                    }
                }
                selectedBrandConfiguration {
                    ...EditorBrand
                }
            }
            landingPage {
                ...LandingPage
            }
        }
    }
    ${EditorLibraryFontFragmentDoc}
    ${EditorLibraryTextStyleFragmentDoc}
    ${EditorAssetLibraryFontFragmentDoc}
    ${EditorAssetLibraryMediaFragmentDoc}
    ${EditorBrandFragmentDoc}
    ${LandingPageFragmentDoc}
`;
export type GqlClientEditorProgramForLandingPageBuilderProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramForLandingPageBuilderQuery, GqlClientEditorProgramForLandingPageBuilderQueryVariables>;
} & TChildProps;
export function withEditorProgramForLandingPageBuilder<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramForLandingPageBuilderQuery,
        GqlClientEditorProgramForLandingPageBuilderQueryVariables,
        GqlClientEditorProgramForLandingPageBuilderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramForLandingPageBuilderQuery,
        GqlClientEditorProgramForLandingPageBuilderQueryVariables,
        GqlClientEditorProgramForLandingPageBuilderProps<TChildProps, TDataName>
    >(EditorProgramForLandingPageBuilderDocument, {
        alias: "editorProgramForLandingPageBuilder",
        ...operationOptions
    });
}

/**
 * __useEditorProgramForLandingPageBuilderQuery__
 *
 * To run a query within a React component, call `useEditorProgramForLandingPageBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramForLandingPageBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramForLandingPageBuilderQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useEditorProgramForLandingPageBuilderQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramForLandingPageBuilderQuery, GqlClientEditorProgramForLandingPageBuilderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramForLandingPageBuilderQuery, GqlClientEditorProgramForLandingPageBuilderQueryVariables>(EditorProgramForLandingPageBuilderDocument, options);
}
export function useEditorProgramForLandingPageBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramForLandingPageBuilderQuery, GqlClientEditorProgramForLandingPageBuilderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramForLandingPageBuilderQuery, GqlClientEditorProgramForLandingPageBuilderQueryVariables>(EditorProgramForLandingPageBuilderDocument, options);
}
export type EditorProgramForLandingPageBuilderQueryHookResult = ReturnType<typeof useEditorProgramForLandingPageBuilderQuery>;
export type EditorProgramForLandingPageBuilderLazyQueryHookResult = ReturnType<typeof useEditorProgramForLandingPageBuilderLazyQuery>;
export type EditorProgramForLandingPageBuilderQueryResult = Apollo.QueryResult<GqlClientEditorProgramForLandingPageBuilderQuery, GqlClientEditorProgramForLandingPageBuilderQueryVariables>;
export const EditorProgramForReviewPageBuilderDocument = gql`
    query editorProgramForReviewPageBuilder($programId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersionDraft {
                id
                aspectRatio
                thumbnail {
                    url
                }
            }
        }
    }
`;
export type GqlClientEditorProgramForReviewPageBuilderProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramForReviewPageBuilderQuery, GqlClientEditorProgramForReviewPageBuilderQueryVariables>;
} & TChildProps;
export function withEditorProgramForReviewPageBuilder<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramForReviewPageBuilderQuery,
        GqlClientEditorProgramForReviewPageBuilderQueryVariables,
        GqlClientEditorProgramForReviewPageBuilderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramForReviewPageBuilderQuery,
        GqlClientEditorProgramForReviewPageBuilderQueryVariables,
        GqlClientEditorProgramForReviewPageBuilderProps<TChildProps, TDataName>
    >(EditorProgramForReviewPageBuilderDocument, {
        alias: "editorProgramForReviewPageBuilder",
        ...operationOptions
    });
}

/**
 * __useEditorProgramForReviewPageBuilderQuery__
 *
 * To run a query within a React component, call `useEditorProgramForReviewPageBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramForReviewPageBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramForReviewPageBuilderQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useEditorProgramForReviewPageBuilderQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramForReviewPageBuilderQuery, GqlClientEditorProgramForReviewPageBuilderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramForReviewPageBuilderQuery, GqlClientEditorProgramForReviewPageBuilderQueryVariables>(EditorProgramForReviewPageBuilderDocument, options);
}
export function useEditorProgramForReviewPageBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramForReviewPageBuilderQuery, GqlClientEditorProgramForReviewPageBuilderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramForReviewPageBuilderQuery, GqlClientEditorProgramForReviewPageBuilderQueryVariables>(EditorProgramForReviewPageBuilderDocument, options);
}
export type EditorProgramForReviewPageBuilderQueryHookResult = ReturnType<typeof useEditorProgramForReviewPageBuilderQuery>;
export type EditorProgramForReviewPageBuilderLazyQueryHookResult = ReturnType<typeof useEditorProgramForReviewPageBuilderLazyQuery>;
export type EditorProgramForReviewPageBuilderQueryResult = Apollo.QueryResult<GqlClientEditorProgramForReviewPageBuilderQuery, GqlClientEditorProgramForReviewPageBuilderQueryVariables>;
export const EditorProgramLandingPageUrlDocument = gql`
    query editorProgramLandingPageUrl($programId: ID!) {
        editorProgram(id: $programId) {
            id
            landingPageUrl
        }
    }
`;
export type GqlClientEditorProgramLandingPageUrlProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramLandingPageUrlQuery, GqlClientEditorProgramLandingPageUrlQueryVariables>;
} & TChildProps;
export function withEditorProgramLandingPageUrl<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramLandingPageUrlQuery,
        GqlClientEditorProgramLandingPageUrlQueryVariables,
        GqlClientEditorProgramLandingPageUrlProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramLandingPageUrlQuery,
        GqlClientEditorProgramLandingPageUrlQueryVariables,
        GqlClientEditorProgramLandingPageUrlProps<TChildProps, TDataName>
    >(EditorProgramLandingPageUrlDocument, {
        alias: "editorProgramLandingPageUrl",
        ...operationOptions
    });
}

/**
 * __useEditorProgramLandingPageUrlQuery__
 *
 * To run a query within a React component, call `useEditorProgramLandingPageUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramLandingPageUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramLandingPageUrlQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useEditorProgramLandingPageUrlQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramLandingPageUrlQuery, GqlClientEditorProgramLandingPageUrlQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramLandingPageUrlQuery, GqlClientEditorProgramLandingPageUrlQueryVariables>(EditorProgramLandingPageUrlDocument, options);
}
export function useEditorProgramLandingPageUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramLandingPageUrlQuery, GqlClientEditorProgramLandingPageUrlQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramLandingPageUrlQuery, GqlClientEditorProgramLandingPageUrlQueryVariables>(EditorProgramLandingPageUrlDocument, options);
}
export type EditorProgramLandingPageUrlQueryHookResult = ReturnType<typeof useEditorProgramLandingPageUrlQuery>;
export type EditorProgramLandingPageUrlLazyQueryHookResult = ReturnType<typeof useEditorProgramLandingPageUrlLazyQuery>;
export type EditorProgramLandingPageUrlQueryResult = Apollo.QueryResult<GqlClientEditorProgramLandingPageUrlQuery, GqlClientEditorProgramLandingPageUrlQueryVariables>;
export const EditorProgramThemeIdDocument = gql`
    query editorProgramThemeId($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ccTheme {
                    id
                }
            }
        }
    }
`;
export type GqlClientEditorProgramThemeIdProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramThemeIdQuery, GqlClientEditorProgramThemeIdQueryVariables>;
} & TChildProps;
export function withEditorProgramThemeId<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramThemeIdQuery,
        GqlClientEditorProgramThemeIdQueryVariables,
        GqlClientEditorProgramThemeIdProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorProgramThemeIdQuery, GqlClientEditorProgramThemeIdQueryVariables, GqlClientEditorProgramThemeIdProps<TChildProps, TDataName>>(
        EditorProgramThemeIdDocument,
        {
            alias: "editorProgramThemeId",
            ...operationOptions
        }
    );
}

/**
 * __useEditorProgramThemeIdQuery__
 *
 * To run a query within a React component, call `useEditorProgramThemeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramThemeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramThemeIdQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramThemeIdQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramThemeIdQuery, GqlClientEditorProgramThemeIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramThemeIdQuery, GqlClientEditorProgramThemeIdQueryVariables>(EditorProgramThemeIdDocument, options);
}
export function useEditorProgramThemeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramThemeIdQuery, GqlClientEditorProgramThemeIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramThemeIdQuery, GqlClientEditorProgramThemeIdQueryVariables>(EditorProgramThemeIdDocument, options);
}
export type EditorProgramThemeIdQueryHookResult = ReturnType<typeof useEditorProgramThemeIdQuery>;
export type EditorProgramThemeIdLazyQueryHookResult = ReturnType<typeof useEditorProgramThemeIdLazyQuery>;
export type EditorProgramThemeIdQueryResult = Apollo.QueryResult<GqlClientEditorProgramThemeIdQuery, GqlClientEditorProgramThemeIdQueryVariables>;
export const EditorProgramThemeNameDocument = gql`
    query editorProgramThemeName($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ccTheme {
                    id
                    name
                }
            }
        }
    }
`;
export type GqlClientEditorProgramThemeNameProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramThemeNameQuery, GqlClientEditorProgramThemeNameQueryVariables>;
} & TChildProps;
export function withEditorProgramThemeName<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramThemeNameQuery,
        GqlClientEditorProgramThemeNameQueryVariables,
        GqlClientEditorProgramThemeNameProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorProgramThemeNameQuery, GqlClientEditorProgramThemeNameQueryVariables, GqlClientEditorProgramThemeNameProps<TChildProps, TDataName>>(
        EditorProgramThemeNameDocument,
        {
            alias: "editorProgramThemeName",
            ...operationOptions
        }
    );
}

/**
 * __useEditorProgramThemeNameQuery__
 *
 * To run a query within a React component, call `useEditorProgramThemeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramThemeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramThemeNameQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramThemeNameQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramThemeNameQuery, GqlClientEditorProgramThemeNameQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramThemeNameQuery, GqlClientEditorProgramThemeNameQueryVariables>(EditorProgramThemeNameDocument, options);
}
export function useEditorProgramThemeNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramThemeNameQuery, GqlClientEditorProgramThemeNameQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramThemeNameQuery, GqlClientEditorProgramThemeNameQueryVariables>(EditorProgramThemeNameDocument, options);
}
export type EditorProgramThemeNameQueryHookResult = ReturnType<typeof useEditorProgramThemeNameQuery>;
export type EditorProgramThemeNameLazyQueryHookResult = ReturnType<typeof useEditorProgramThemeNameLazyQuery>;
export type EditorProgramThemeNameQueryResult = Apollo.QueryResult<GqlClientEditorProgramThemeNameQuery, GqlClientEditorProgramThemeNameQueryVariables>;
export const EditorProgramTotalViewsDocument = gql`
    query editorProgramTotalViews($programId: ID!) {
        editorProgram(id: $programId) {
            id
            totalViews
        }
    }
`;
export type GqlClientEditorProgramTotalViewsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramTotalViewsQuery, GqlClientEditorProgramTotalViewsQueryVariables>;
} & TChildProps;
export function withEditorProgramTotalViews<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramTotalViewsQuery,
        GqlClientEditorProgramTotalViewsQueryVariables,
        GqlClientEditorProgramTotalViewsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorProgramTotalViewsQuery, GqlClientEditorProgramTotalViewsQueryVariables, GqlClientEditorProgramTotalViewsProps<TChildProps, TDataName>>(
        EditorProgramTotalViewsDocument,
        {
            alias: "editorProgramTotalViews",
            ...operationOptions
        }
    );
}

/**
 * __useEditorProgramTotalViewsQuery__
 *
 * To run a query within a React component, call `useEditorProgramTotalViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramTotalViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramTotalViewsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useEditorProgramTotalViewsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramTotalViewsQuery, GqlClientEditorProgramTotalViewsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramTotalViewsQuery, GqlClientEditorProgramTotalViewsQueryVariables>(EditorProgramTotalViewsDocument, options);
}
export function useEditorProgramTotalViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramTotalViewsQuery, GqlClientEditorProgramTotalViewsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramTotalViewsQuery, GqlClientEditorProgramTotalViewsQueryVariables>(EditorProgramTotalViewsDocument, options);
}
export type EditorProgramTotalViewsQueryHookResult = ReturnType<typeof useEditorProgramTotalViewsQuery>;
export type EditorProgramTotalViewsLazyQueryHookResult = ReturnType<typeof useEditorProgramTotalViewsLazyQuery>;
export type EditorProgramTotalViewsQueryResult = Apollo.QueryResult<GqlClientEditorProgramTotalViewsQuery, GqlClientEditorProgramTotalViewsQueryVariables>;
export const EditorProgramVersionDocument = gql`
    query editorProgramVersion($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            name
            programVersion(id: $programVersionId) {
                id
                editorDataConnectorEnabled
                aspectRatio
                ccTheme {
                    id
                }
                assetLibrary {
                    id
                }
                sceneLineup
                scenes {
                    ...EditorSceneWithoutCcData
                }
                ...EditorProgramSelectedMusic
                audioSettings
                selectedNarrator {
                    id
                }
                studioElements {
                    ...StudioElement
                }
                brandConfigurationVersion {
                    id
                }
                selectedBrandConfiguration {
                    id
                    localId
                    name
                    updated
                    updatedBy
                }
                accountDataLibraryVersion {
                    ...AccountDataLibraryVersion
                }
                pronunciationLibraryVersion {
                    id
                    definitions {
                        ...AccountPronunciationDefinition
                    }
                    anyUpdated
                    anyUpdatedBy
                }
                legacyAssetLocationBasePath
                anyUpdated
                anyUpdatedBy
                editorTtsFullEnablement
                bcUseRules
                bcRules
                compatibility
                useCustomVideoBackground
                videoBackgroundMedia {
                    id
                }
                isAvatarRendering
            }
            viewerProfiles {
                ...EditorViewerProfile
            }
        }
    }
    ${EditorSceneWithoutCcDataFragmentDoc}
    ${EditorProgramSelectedMusicFragmentDoc}
    ${StudioElementFragmentDoc}
    ${AccountDataLibraryVersionFragmentDoc}
    ${AccountPronunciationDefinitionFragmentDoc}
    ${EditorViewerProfileFragmentDoc}
`;
export type GqlClientEditorProgramVersionProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramVersionQuery, GqlClientEditorProgramVersionQueryVariables>;
} & TChildProps;
export function withEditorProgramVersion<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramVersionQuery,
        GqlClientEditorProgramVersionQueryVariables,
        GqlClientEditorProgramVersionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorProgramVersionQuery, GqlClientEditorProgramVersionQueryVariables, GqlClientEditorProgramVersionProps<TChildProps, TDataName>>(
        EditorProgramVersionDocument,
        {
            alias: "editorProgramVersion",
            ...operationOptions
        }
    );
}

/**
 * __useEditorProgramVersionQuery__
 *
 * To run a query within a React component, call `useEditorProgramVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramVersionQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramVersionQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramVersionQuery, GqlClientEditorProgramVersionQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramVersionQuery, GqlClientEditorProgramVersionQueryVariables>(EditorProgramVersionDocument, options);
}
export function useEditorProgramVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramVersionQuery, GqlClientEditorProgramVersionQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramVersionQuery, GqlClientEditorProgramVersionQueryVariables>(EditorProgramVersionDocument, options);
}
export type EditorProgramVersionQueryHookResult = ReturnType<typeof useEditorProgramVersionQuery>;
export type EditorProgramVersionLazyQueryHookResult = ReturnType<typeof useEditorProgramVersionLazyQuery>;
export type EditorProgramVersionQueryResult = Apollo.QueryResult<GqlClientEditorProgramVersionQuery, GqlClientEditorProgramVersionQueryVariables>;
export const EditorProgramVersionBrandByAudienceDocument = gql`
    query editorProgramVersionBrandByAudience($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                brandConfigurationVersion {
                    id
                    defaultBrandConfigurationCollection {
                        id
                        localId
                    }
                }
                bcRules
                bcUseRules
            }
        }
    }
`;
export type GqlClientEditorProgramVersionBrandByAudienceProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramVersionBrandByAudienceQuery, GqlClientEditorProgramVersionBrandByAudienceQueryVariables>;
} & TChildProps;
export function withEditorProgramVersionBrandByAudience<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramVersionBrandByAudienceQuery,
        GqlClientEditorProgramVersionBrandByAudienceQueryVariables,
        GqlClientEditorProgramVersionBrandByAudienceProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramVersionBrandByAudienceQuery,
        GqlClientEditorProgramVersionBrandByAudienceQueryVariables,
        GqlClientEditorProgramVersionBrandByAudienceProps<TChildProps, TDataName>
    >(EditorProgramVersionBrandByAudienceDocument, {
        alias: "editorProgramVersionBrandByAudience",
        ...operationOptions
    });
}

/**
 * __useEditorProgramVersionBrandByAudienceQuery__
 *
 * To run a query within a React component, call `useEditorProgramVersionBrandByAudienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramVersionBrandByAudienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramVersionBrandByAudienceQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramVersionBrandByAudienceQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramVersionBrandByAudienceQuery, GqlClientEditorProgramVersionBrandByAudienceQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramVersionBrandByAudienceQuery, GqlClientEditorProgramVersionBrandByAudienceQueryVariables>(EditorProgramVersionBrandByAudienceDocument, options);
}
export function useEditorProgramVersionBrandByAudienceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramVersionBrandByAudienceQuery, GqlClientEditorProgramVersionBrandByAudienceQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramVersionBrandByAudienceQuery, GqlClientEditorProgramVersionBrandByAudienceQueryVariables>(EditorProgramVersionBrandByAudienceDocument, options);
}
export type EditorProgramVersionBrandByAudienceQueryHookResult = ReturnType<typeof useEditorProgramVersionBrandByAudienceQuery>;
export type EditorProgramVersionBrandByAudienceLazyQueryHookResult = ReturnType<typeof useEditorProgramVersionBrandByAudienceLazyQuery>;
export type EditorProgramVersionBrandByAudienceQueryResult = Apollo.QueryResult<GqlClientEditorProgramVersionBrandByAudienceQuery, GqlClientEditorProgramVersionBrandByAudienceQueryVariables>;
export const EditorProgramVersionGetCompatibilityDocument = gql`
    query editorProgramVersionGetCompatibility($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                compatibility
            }
        }
    }
`;
export type GqlClientEditorProgramVersionGetCompatibilityProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramVersionGetCompatibilityQuery, GqlClientEditorProgramVersionGetCompatibilityQueryVariables>;
} & TChildProps;
export function withEditorProgramVersionGetCompatibility<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramVersionGetCompatibilityQuery,
        GqlClientEditorProgramVersionGetCompatibilityQueryVariables,
        GqlClientEditorProgramVersionGetCompatibilityProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramVersionGetCompatibilityQuery,
        GqlClientEditorProgramVersionGetCompatibilityQueryVariables,
        GqlClientEditorProgramVersionGetCompatibilityProps<TChildProps, TDataName>
    >(EditorProgramVersionGetCompatibilityDocument, {
        alias: "editorProgramVersionGetCompatibility",
        ...operationOptions
    });
}

/**
 * __useEditorProgramVersionGetCompatibilityQuery__
 *
 * To run a query within a React component, call `useEditorProgramVersionGetCompatibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramVersionGetCompatibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramVersionGetCompatibilityQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramVersionGetCompatibilityQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramVersionGetCompatibilityQuery, GqlClientEditorProgramVersionGetCompatibilityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramVersionGetCompatibilityQuery, GqlClientEditorProgramVersionGetCompatibilityQueryVariables>(EditorProgramVersionGetCompatibilityDocument, options);
}
export function useEditorProgramVersionGetCompatibilityLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramVersionGetCompatibilityQuery, GqlClientEditorProgramVersionGetCompatibilityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramVersionGetCompatibilityQuery, GqlClientEditorProgramVersionGetCompatibilityQueryVariables>(EditorProgramVersionGetCompatibilityDocument, options);
}
export type EditorProgramVersionGetCompatibilityQueryHookResult = ReturnType<typeof useEditorProgramVersionGetCompatibilityQuery>;
export type EditorProgramVersionGetCompatibilityLazyQueryHookResult = ReturnType<typeof useEditorProgramVersionGetCompatibilityLazyQuery>;
export type EditorProgramVersionGetCompatibilityQueryResult = Apollo.QueryResult<GqlClientEditorProgramVersionGetCompatibilityQuery, GqlClientEditorProgramVersionGetCompatibilityQueryVariables>;
export const EditorProgramVersionLastEditedDocument = gql`
    query editorProgramVersionLastEdited($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                anyUpdated
                anyUpdatedBy
                accountDataLibraryVersion {
                    id
                    anyUpdatedBy
                    anyUpdated
                }
                selectedBrandConfiguration {
                    id
                    updated
                    updatedBy
                }
                pronunciationLibraryVersion {
                    id
                    anyUpdated
                    anyUpdatedBy
                }
            }
        }
    }
`;
export type GqlClientEditorProgramVersionLastEditedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramVersionLastEditedQuery, GqlClientEditorProgramVersionLastEditedQueryVariables>;
} & TChildProps;
export function withEditorProgramVersionLastEdited<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramVersionLastEditedQuery,
        GqlClientEditorProgramVersionLastEditedQueryVariables,
        GqlClientEditorProgramVersionLastEditedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramVersionLastEditedQuery,
        GqlClientEditorProgramVersionLastEditedQueryVariables,
        GqlClientEditorProgramVersionLastEditedProps<TChildProps, TDataName>
    >(EditorProgramVersionLastEditedDocument, {
        alias: "editorProgramVersionLastEdited",
        ...operationOptions
    });
}

/**
 * __useEditorProgramVersionLastEditedQuery__
 *
 * To run a query within a React component, call `useEditorProgramVersionLastEditedQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramVersionLastEditedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramVersionLastEditedQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramVersionLastEditedQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramVersionLastEditedQuery, GqlClientEditorProgramVersionLastEditedQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramVersionLastEditedQuery, GqlClientEditorProgramVersionLastEditedQueryVariables>(EditorProgramVersionLastEditedDocument, options);
}
export function useEditorProgramVersionLastEditedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramVersionLastEditedQuery, GqlClientEditorProgramVersionLastEditedQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramVersionLastEditedQuery, GqlClientEditorProgramVersionLastEditedQueryVariables>(EditorProgramVersionLastEditedDocument, options);
}
export type EditorProgramVersionLastEditedQueryHookResult = ReturnType<typeof useEditorProgramVersionLastEditedQuery>;
export type EditorProgramVersionLastEditedLazyQueryHookResult = ReturnType<typeof useEditorProgramVersionLastEditedLazyQuery>;
export type EditorProgramVersionLastEditedQueryResult = Apollo.QueryResult<GqlClientEditorProgramVersionLastEditedQuery, GqlClientEditorProgramVersionLastEditedQueryVariables>;
export const EditorProgramVersionSceneLineupAndScenesDocument = gql`
    query editorProgramVersionSceneLineupAndScenes($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                sceneLineup
                scenes {
                    id
                }
            }
        }
    }
`;
export type GqlClientEditorProgramVersionSceneLineupAndScenesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramVersionSceneLineupAndScenesQuery, GqlClientEditorProgramVersionSceneLineupAndScenesQueryVariables>;
} & TChildProps;
export function withEditorProgramVersionSceneLineupAndScenes<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramVersionSceneLineupAndScenesQuery,
        GqlClientEditorProgramVersionSceneLineupAndScenesQueryVariables,
        GqlClientEditorProgramVersionSceneLineupAndScenesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramVersionSceneLineupAndScenesQuery,
        GqlClientEditorProgramVersionSceneLineupAndScenesQueryVariables,
        GqlClientEditorProgramVersionSceneLineupAndScenesProps<TChildProps, TDataName>
    >(EditorProgramVersionSceneLineupAndScenesDocument, {
        alias: "editorProgramVersionSceneLineupAndScenes",
        ...operationOptions
    });
}

/**
 * __useEditorProgramVersionSceneLineupAndScenesQuery__
 *
 * To run a query within a React component, call `useEditorProgramVersionSceneLineupAndScenesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramVersionSceneLineupAndScenesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramVersionSceneLineupAndScenesQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramVersionSceneLineupAndScenesQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramVersionSceneLineupAndScenesQuery, GqlClientEditorProgramVersionSceneLineupAndScenesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramVersionSceneLineupAndScenesQuery, GqlClientEditorProgramVersionSceneLineupAndScenesQueryVariables>(
        EditorProgramVersionSceneLineupAndScenesDocument,
        options
    );
}
export function useEditorProgramVersionSceneLineupAndScenesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramVersionSceneLineupAndScenesQuery, GqlClientEditorProgramVersionSceneLineupAndScenesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramVersionSceneLineupAndScenesQuery, GqlClientEditorProgramVersionSceneLineupAndScenesQueryVariables>(
        EditorProgramVersionSceneLineupAndScenesDocument,
        options
    );
}
export type EditorProgramVersionSceneLineupAndScenesQueryHookResult = ReturnType<typeof useEditorProgramVersionSceneLineupAndScenesQuery>;
export type EditorProgramVersionSceneLineupAndScenesLazyQueryHookResult = ReturnType<typeof useEditorProgramVersionSceneLineupAndScenesLazyQuery>;
export type EditorProgramVersionSceneLineupAndScenesQueryResult = Apollo.QueryResult<
    GqlClientEditorProgramVersionSceneLineupAndScenesQuery,
    GqlClientEditorProgramVersionSceneLineupAndScenesQueryVariables
>;
export const EditorProgramAllowedToUpgradeLayoutsInfoDocument = gql`
    query editorProgramAllowedToUpgradeLayoutsInfo($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ccTheme {
                    id
                    name
                }
                scenes {
                    id
                    ccWireframe {
                        id
                        lifecycleStage
                    }
                }
            }
        }
    }
`;
export type GqlClientEditorProgramAllowedToUpgradeLayoutsInfoProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQuery, GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQueryVariables>;
} & TChildProps;
export function withEditorProgramAllowedToUpgradeLayoutsInfo<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQuery,
        GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQueryVariables,
        GqlClientEditorProgramAllowedToUpgradeLayoutsInfoProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQuery,
        GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQueryVariables,
        GqlClientEditorProgramAllowedToUpgradeLayoutsInfoProps<TChildProps, TDataName>
    >(EditorProgramAllowedToUpgradeLayoutsInfoDocument, {
        alias: "editorProgramAllowedToUpgradeLayoutsInfo",
        ...operationOptions
    });
}

/**
 * __useEditorProgramAllowedToUpgradeLayoutsInfoQuery__
 *
 * To run a query within a React component, call `useEditorProgramAllowedToUpgradeLayoutsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramAllowedToUpgradeLayoutsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramAllowedToUpgradeLayoutsInfoQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramAllowedToUpgradeLayoutsInfoQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQuery, GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQuery, GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQueryVariables>(
        EditorProgramAllowedToUpgradeLayoutsInfoDocument,
        options
    );
}
export function useEditorProgramAllowedToUpgradeLayoutsInfoLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQuery, GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQuery, GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQueryVariables>(
        EditorProgramAllowedToUpgradeLayoutsInfoDocument,
        options
    );
}
export type EditorProgramAllowedToUpgradeLayoutsInfoQueryHookResult = ReturnType<typeof useEditorProgramAllowedToUpgradeLayoutsInfoQuery>;
export type EditorProgramAllowedToUpgradeLayoutsInfoLazyQueryHookResult = ReturnType<typeof useEditorProgramAllowedToUpgradeLayoutsInfoLazyQuery>;
export type EditorProgramAllowedToUpgradeLayoutsInfoQueryResult = Apollo.QueryResult<
    GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQuery,
    GqlClientEditorProgramAllowedToUpgradeLayoutsInfoQueryVariables
>;
export const EditorProgramVersionThumbnailInformationDocument = gql`
    query editorProgramVersionThumbnailInformation($programId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersionDraft {
                id
                aspectRatio
                thumbnail {
                    url
                }
            }
        }
    }
`;
export type GqlClientEditorProgramVersionThumbnailInformationProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramVersionThumbnailInformationQuery, GqlClientEditorProgramVersionThumbnailInformationQueryVariables>;
} & TChildProps;
export function withEditorProgramVersionThumbnailInformation<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramVersionThumbnailInformationQuery,
        GqlClientEditorProgramVersionThumbnailInformationQueryVariables,
        GqlClientEditorProgramVersionThumbnailInformationProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramVersionThumbnailInformationQuery,
        GqlClientEditorProgramVersionThumbnailInformationQueryVariables,
        GqlClientEditorProgramVersionThumbnailInformationProps<TChildProps, TDataName>
    >(EditorProgramVersionThumbnailInformationDocument, {
        alias: "editorProgramVersionThumbnailInformation",
        ...operationOptions
    });
}

/**
 * __useEditorProgramVersionThumbnailInformationQuery__
 *
 * To run a query within a React component, call `useEditorProgramVersionThumbnailInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramVersionThumbnailInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramVersionThumbnailInformationQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useEditorProgramVersionThumbnailInformationQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramVersionThumbnailInformationQuery, GqlClientEditorProgramVersionThumbnailInformationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramVersionThumbnailInformationQuery, GqlClientEditorProgramVersionThumbnailInformationQueryVariables>(
        EditorProgramVersionThumbnailInformationDocument,
        options
    );
}
export function useEditorProgramVersionThumbnailInformationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramVersionThumbnailInformationQuery, GqlClientEditorProgramVersionThumbnailInformationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramVersionThumbnailInformationQuery, GqlClientEditorProgramVersionThumbnailInformationQueryVariables>(
        EditorProgramVersionThumbnailInformationDocument,
        options
    );
}
export type EditorProgramVersionThumbnailInformationQueryHookResult = ReturnType<typeof useEditorProgramVersionThumbnailInformationQuery>;
export type EditorProgramVersionThumbnailInformationLazyQueryHookResult = ReturnType<typeof useEditorProgramVersionThumbnailInformationLazyQuery>;
export type EditorProgramVersionThumbnailInformationQueryResult = Apollo.QueryResult<
    GqlClientEditorProgramVersionThumbnailInformationQuery,
    GqlClientEditorProgramVersionThumbnailInformationQueryVariables
>;
export const EditorProgramVersionUsedDataFieldsDocument = gql`
    query editorProgramVersionUsedDataFields($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                usedElements
            }
        }
    }
`;
export type GqlClientEditorProgramVersionUsedDataFieldsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramVersionUsedDataFieldsQuery, GqlClientEditorProgramVersionUsedDataFieldsQueryVariables>;
} & TChildProps;
export function withEditorProgramVersionUsedDataFields<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramVersionUsedDataFieldsQuery,
        GqlClientEditorProgramVersionUsedDataFieldsQueryVariables,
        GqlClientEditorProgramVersionUsedDataFieldsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorProgramVersionUsedDataFieldsQuery,
        GqlClientEditorProgramVersionUsedDataFieldsQueryVariables,
        GqlClientEditorProgramVersionUsedDataFieldsProps<TChildProps, TDataName>
    >(EditorProgramVersionUsedDataFieldsDocument, {
        alias: "editorProgramVersionUsedDataFields",
        ...operationOptions
    });
}

/**
 * __useEditorProgramVersionUsedDataFieldsQuery__
 *
 * To run a query within a React component, call `useEditorProgramVersionUsedDataFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramVersionUsedDataFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramVersionUsedDataFieldsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramVersionUsedDataFieldsQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientEditorProgramVersionUsedDataFieldsQuery, GqlClientEditorProgramVersionUsedDataFieldsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorProgramVersionUsedDataFieldsQuery, GqlClientEditorProgramVersionUsedDataFieldsQueryVariables>(EditorProgramVersionUsedDataFieldsDocument, options);
}
export function useEditorProgramVersionUsedDataFieldsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorProgramVersionUsedDataFieldsQuery, GqlClientEditorProgramVersionUsedDataFieldsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorProgramVersionUsedDataFieldsQuery, GqlClientEditorProgramVersionUsedDataFieldsQueryVariables>(EditorProgramVersionUsedDataFieldsDocument, options);
}
export type EditorProgramVersionUsedDataFieldsQueryHookResult = ReturnType<typeof useEditorProgramVersionUsedDataFieldsQuery>;
export type EditorProgramVersionUsedDataFieldsLazyQueryHookResult = ReturnType<typeof useEditorProgramVersionUsedDataFieldsLazyQuery>;
export type EditorProgramVersionUsedDataFieldsQueryResult = Apollo.QueryResult<GqlClientEditorProgramVersionUsedDataFieldsQuery, GqlClientEditorProgramVersionUsedDataFieldsQueryVariables>;
export const EditorPronunciationDefinitionsDocument = gql`
    query editorPronunciationDefinitions($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            name
            programVersion(id: $programVersionId) {
                id
                pronunciationLibraryVersion {
                    id
                    definitions {
                        ...AccountPronunciationDefinition
                    }
                }
            }
        }
    }
    ${AccountPronunciationDefinitionFragmentDoc}
`;
export type GqlClientEditorPronunciationDefinitionsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorPronunciationDefinitionsQuery, GqlClientEditorPronunciationDefinitionsQueryVariables>;
} & TChildProps;
export function withEditorPronunciationDefinitions<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorPronunciationDefinitionsQuery,
        GqlClientEditorPronunciationDefinitionsQueryVariables,
        GqlClientEditorPronunciationDefinitionsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorPronunciationDefinitionsQuery,
        GqlClientEditorPronunciationDefinitionsQueryVariables,
        GqlClientEditorPronunciationDefinitionsProps<TChildProps, TDataName>
    >(EditorPronunciationDefinitionsDocument, {
        alias: "editorPronunciationDefinitions",
        ...operationOptions
    });
}

/**
 * __useEditorPronunciationDefinitionsQuery__
 *
 * To run a query within a React component, call `useEditorPronunciationDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorPronunciationDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorPronunciationDefinitionsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorPronunciationDefinitionsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorPronunciationDefinitionsQuery, GqlClientEditorPronunciationDefinitionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorPronunciationDefinitionsQuery, GqlClientEditorPronunciationDefinitionsQueryVariables>(EditorPronunciationDefinitionsDocument, options);
}
export function useEditorPronunciationDefinitionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorPronunciationDefinitionsQuery, GqlClientEditorPronunciationDefinitionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorPronunciationDefinitionsQuery, GqlClientEditorPronunciationDefinitionsQueryVariables>(EditorPronunciationDefinitionsDocument, options);
}
export type EditorPronunciationDefinitionsQueryHookResult = ReturnType<typeof useEditorPronunciationDefinitionsQuery>;
export type EditorPronunciationDefinitionsLazyQueryHookResult = ReturnType<typeof useEditorPronunciationDefinitionsLazyQuery>;
export type EditorPronunciationDefinitionsQueryResult = Apollo.QueryResult<GqlClientEditorPronunciationDefinitionsQuery, GqlClientEditorPronunciationDefinitionsQueryVariables>;
export const EditorSceneLineupDocument = gql`
    query editorSceneLineup($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                sceneLineup
            }
        }
    }
`;
export type GqlClientEditorSceneLineupProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorSceneLineupQuery, GqlClientEditorSceneLineupQueryVariables>;
} & TChildProps;
export function withEditorSceneLineup<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientEditorSceneLineupQuery, GqlClientEditorSceneLineupQueryVariables, GqlClientEditorSceneLineupProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorSceneLineupQuery, GqlClientEditorSceneLineupQueryVariables, GqlClientEditorSceneLineupProps<TChildProps, TDataName>>(
        EditorSceneLineupDocument,
        {
            alias: "editorSceneLineup",
            ...operationOptions
        }
    );
}

/**
 * __useEditorSceneLineupQuery__
 *
 * To run a query within a React component, call `useEditorSceneLineupQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorSceneLineupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorSceneLineupQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorSceneLineupQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorSceneLineupQuery, GqlClientEditorSceneLineupQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorSceneLineupQuery, GqlClientEditorSceneLineupQueryVariables>(EditorSceneLineupDocument, options);
}
export function useEditorSceneLineupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorSceneLineupQuery, GqlClientEditorSceneLineupQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorSceneLineupQuery, GqlClientEditorSceneLineupQueryVariables>(EditorSceneLineupDocument, options);
}
export type EditorSceneLineupQueryHookResult = ReturnType<typeof useEditorSceneLineupQuery>;
export type EditorSceneLineupLazyQueryHookResult = ReturnType<typeof useEditorSceneLineupLazyQuery>;
export type EditorSceneLineupQueryResult = Apollo.QueryResult<GqlClientEditorSceneLineupQuery, GqlClientEditorSceneLineupQueryVariables>;
export const EditorScenesDocument = gql`
    query editorScenes($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                scenes {
                    ...EditorScene
                }
            }
        }
    }
    ${EditorSceneFragmentDoc}
`;
export type GqlClientEditorScenesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorScenesQuery, GqlClientEditorScenesQueryVariables>;
} & TChildProps;
export function withEditorScenes<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientEditorScenesQuery, GqlClientEditorScenesQueryVariables, GqlClientEditorScenesProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorScenesQuery, GqlClientEditorScenesQueryVariables, GqlClientEditorScenesProps<TChildProps, TDataName>>(EditorScenesDocument, {
        alias: "editorScenes",
        ...operationOptions
    });
}

/**
 * __useEditorScenesQuery__
 *
 * To run a query within a React component, call `useEditorScenesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorScenesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorScenesQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorScenesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorScenesQuery, GqlClientEditorScenesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorScenesQuery, GqlClientEditorScenesQueryVariables>(EditorScenesDocument, options);
}
export function useEditorScenesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorScenesQuery, GqlClientEditorScenesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorScenesQuery, GqlClientEditorScenesQueryVariables>(EditorScenesDocument, options);
}
export type EditorScenesQueryHookResult = ReturnType<typeof useEditorScenesQuery>;
export type EditorScenesLazyQueryHookResult = ReturnType<typeof useEditorScenesLazyQuery>;
export type EditorScenesQueryResult = Apollo.QueryResult<GqlClientEditorScenesQuery, GqlClientEditorScenesQueryVariables>;
export const EditorScenesAvatarRenderedStatusDocument = gql`
    query editorScenesAvatarRenderedStatus($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                scenes {
                    id
                    avatarVideoStatus
                    skipSceneMode
                }
            }
        }
    }
`;
export type GqlClientEditorScenesAvatarRenderedStatusProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorScenesAvatarRenderedStatusQuery, GqlClientEditorScenesAvatarRenderedStatusQueryVariables>;
} & TChildProps;
export function withEditorScenesAvatarRenderedStatus<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorScenesAvatarRenderedStatusQuery,
        GqlClientEditorScenesAvatarRenderedStatusQueryVariables,
        GqlClientEditorScenesAvatarRenderedStatusProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorScenesAvatarRenderedStatusQuery,
        GqlClientEditorScenesAvatarRenderedStatusQueryVariables,
        GqlClientEditorScenesAvatarRenderedStatusProps<TChildProps, TDataName>
    >(EditorScenesAvatarRenderedStatusDocument, {
        alias: "editorScenesAvatarRenderedStatus",
        ...operationOptions
    });
}

/**
 * __useEditorScenesAvatarRenderedStatusQuery__
 *
 * To run a query within a React component, call `useEditorScenesAvatarRenderedStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorScenesAvatarRenderedStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorScenesAvatarRenderedStatusQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorScenesAvatarRenderedStatusQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientEditorScenesAvatarRenderedStatusQuery, GqlClientEditorScenesAvatarRenderedStatusQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorScenesAvatarRenderedStatusQuery, GqlClientEditorScenesAvatarRenderedStatusQueryVariables>(EditorScenesAvatarRenderedStatusDocument, options);
}
export function useEditorScenesAvatarRenderedStatusLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorScenesAvatarRenderedStatusQuery, GqlClientEditorScenesAvatarRenderedStatusQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorScenesAvatarRenderedStatusQuery, GqlClientEditorScenesAvatarRenderedStatusQueryVariables>(EditorScenesAvatarRenderedStatusDocument, options);
}
export type EditorScenesAvatarRenderedStatusQueryHookResult = ReturnType<typeof useEditorScenesAvatarRenderedStatusQuery>;
export type EditorScenesAvatarRenderedStatusLazyQueryHookResult = ReturnType<typeof useEditorScenesAvatarRenderedStatusLazyQuery>;
export type EditorScenesAvatarRenderedStatusQueryResult = Apollo.QueryResult<GqlClientEditorScenesAvatarRenderedStatusQuery, GqlClientEditorScenesAvatarRenderedStatusQueryVariables>;
export const EditorSelectedBrandIdDocument = gql`
    query editorSelectedBrandId($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                selectedBrandConfiguration {
                    id
                }
            }
        }
    }
`;
export type GqlClientEditorSelectedBrandIdProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorSelectedBrandIdQuery, GqlClientEditorSelectedBrandIdQueryVariables>;
} & TChildProps;
export function withEditorSelectedBrandId<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorSelectedBrandIdQuery,
        GqlClientEditorSelectedBrandIdQueryVariables,
        GqlClientEditorSelectedBrandIdProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorSelectedBrandIdQuery, GqlClientEditorSelectedBrandIdQueryVariables, GqlClientEditorSelectedBrandIdProps<TChildProps, TDataName>>(
        EditorSelectedBrandIdDocument,
        {
            alias: "editorSelectedBrandId",
            ...operationOptions
        }
    );
}

/**
 * __useEditorSelectedBrandIdQuery__
 *
 * To run a query within a React component, call `useEditorSelectedBrandIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorSelectedBrandIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorSelectedBrandIdQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorSelectedBrandIdQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorSelectedBrandIdQuery, GqlClientEditorSelectedBrandIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorSelectedBrandIdQuery, GqlClientEditorSelectedBrandIdQueryVariables>(EditorSelectedBrandIdDocument, options);
}
export function useEditorSelectedBrandIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorSelectedBrandIdQuery, GqlClientEditorSelectedBrandIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorSelectedBrandIdQuery, GqlClientEditorSelectedBrandIdQueryVariables>(EditorSelectedBrandIdDocument, options);
}
export type EditorSelectedBrandIdQueryHookResult = ReturnType<typeof useEditorSelectedBrandIdQuery>;
export type EditorSelectedBrandIdLazyQueryHookResult = ReturnType<typeof useEditorSelectedBrandIdLazyQuery>;
export type EditorSelectedBrandIdQueryResult = Apollo.QueryResult<GqlClientEditorSelectedBrandIdQuery, GqlClientEditorSelectedBrandIdQueryVariables>;
export const EditorSelectedMusicIdDocument = gql`
    query editorSelectedMusicId($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                ...EditorProgramSelectedMusic
            }
        }
    }
    ${EditorProgramSelectedMusicFragmentDoc}
`;
export type GqlClientEditorSelectedMusicIdProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorSelectedMusicIdQuery, GqlClientEditorSelectedMusicIdQueryVariables>;
} & TChildProps;
export function withEditorSelectedMusicId<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorSelectedMusicIdQuery,
        GqlClientEditorSelectedMusicIdQueryVariables,
        GqlClientEditorSelectedMusicIdProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorSelectedMusicIdQuery, GqlClientEditorSelectedMusicIdQueryVariables, GqlClientEditorSelectedMusicIdProps<TChildProps, TDataName>>(
        EditorSelectedMusicIdDocument,
        {
            alias: "editorSelectedMusicId",
            ...operationOptions
        }
    );
}

/**
 * __useEditorSelectedMusicIdQuery__
 *
 * To run a query within a React component, call `useEditorSelectedMusicIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorSelectedMusicIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorSelectedMusicIdQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorSelectedMusicIdQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorSelectedMusicIdQuery, GqlClientEditorSelectedMusicIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorSelectedMusicIdQuery, GqlClientEditorSelectedMusicIdQueryVariables>(EditorSelectedMusicIdDocument, options);
}
export function useEditorSelectedMusicIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorSelectedMusicIdQuery, GqlClientEditorSelectedMusicIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorSelectedMusicIdQuery, GqlClientEditorSelectedMusicIdQueryVariables>(EditorSelectedMusicIdDocument, options);
}
export type EditorSelectedMusicIdQueryHookResult = ReturnType<typeof useEditorSelectedMusicIdQuery>;
export type EditorSelectedMusicIdLazyQueryHookResult = ReturnType<typeof useEditorSelectedMusicIdLazyQuery>;
export type EditorSelectedMusicIdQueryResult = Apollo.QueryResult<GqlClientEditorSelectedMusicIdQuery, GqlClientEditorSelectedMusicIdQueryVariables>;
export const EditorSelectedNarratorDocument = gql`
    query editorSelectedNarrator($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                selectedNarrator {
                    ...EditorLibraryNarrator
                }
            }
        }
    }
    ${EditorLibraryNarratorFragmentDoc}
`;
export type GqlClientEditorSelectedNarratorProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorSelectedNarratorQuery, GqlClientEditorSelectedNarratorQueryVariables>;
} & TChildProps;
export function withEditorSelectedNarrator<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorSelectedNarratorQuery,
        GqlClientEditorSelectedNarratorQueryVariables,
        GqlClientEditorSelectedNarratorProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorSelectedNarratorQuery, GqlClientEditorSelectedNarratorQueryVariables, GqlClientEditorSelectedNarratorProps<TChildProps, TDataName>>(
        EditorSelectedNarratorDocument,
        {
            alias: "editorSelectedNarrator",
            ...operationOptions
        }
    );
}

/**
 * __useEditorSelectedNarratorQuery__
 *
 * To run a query within a React component, call `useEditorSelectedNarratorQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorSelectedNarratorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorSelectedNarratorQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorSelectedNarratorQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorSelectedNarratorQuery, GqlClientEditorSelectedNarratorQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorSelectedNarratorQuery, GqlClientEditorSelectedNarratorQueryVariables>(EditorSelectedNarratorDocument, options);
}
export function useEditorSelectedNarratorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorSelectedNarratorQuery, GqlClientEditorSelectedNarratorQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorSelectedNarratorQuery, GqlClientEditorSelectedNarratorQueryVariables>(EditorSelectedNarratorDocument, options);
}
export type EditorSelectedNarratorQueryHookResult = ReturnType<typeof useEditorSelectedNarratorQuery>;
export type EditorSelectedNarratorLazyQueryHookResult = ReturnType<typeof useEditorSelectedNarratorLazyQuery>;
export type EditorSelectedNarratorQueryResult = Apollo.QueryResult<GqlClientEditorSelectedNarratorQuery, GqlClientEditorSelectedNarratorQueryVariables>;
export const EditorStockMusicSearchDocument = gql`
    query editorStockMusicSearch($searchTerm: String, $tag: String, $page: Int, $projectID: ID, $userID: ID) {
        editorStockMusicSearch(searchTerm: $searchTerm, tag: $tag, page: $page, projectID: $projectID, userID: $userID) {
            numberOfResults
            tracks {
                ...EditorStockLibraryMusic
            }
        }
    }
    ${EditorStockLibraryMusicFragmentDoc}
`;
export type GqlClientEditorStockMusicSearchProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorStockMusicSearchQuery, GqlClientEditorStockMusicSearchQueryVariables>;
} & TChildProps;
export function withEditorStockMusicSearch<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorStockMusicSearchQuery,
        GqlClientEditorStockMusicSearchQueryVariables,
        GqlClientEditorStockMusicSearchProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorStockMusicSearchQuery, GqlClientEditorStockMusicSearchQueryVariables, GqlClientEditorStockMusicSearchProps<TChildProps, TDataName>>(
        EditorStockMusicSearchDocument,
        {
            alias: "editorStockMusicSearch",
            ...operationOptions
        }
    );
}

/**
 * __useEditorStockMusicSearchQuery__
 *
 * To run a query within a React component, call `useEditorStockMusicSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorStockMusicSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorStockMusicSearchQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      tag: // value for 'tag'
 *      page: // value for 'page'
 *      projectID: // value for 'projectID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useEditorStockMusicSearchQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientEditorStockMusicSearchQuery, GqlClientEditorStockMusicSearchQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorStockMusicSearchQuery, GqlClientEditorStockMusicSearchQueryVariables>(EditorStockMusicSearchDocument, options);
}
export function useEditorStockMusicSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorStockMusicSearchQuery, GqlClientEditorStockMusicSearchQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorStockMusicSearchQuery, GqlClientEditorStockMusicSearchQueryVariables>(EditorStockMusicSearchDocument, options);
}
export type EditorStockMusicSearchQueryHookResult = ReturnType<typeof useEditorStockMusicSearchQuery>;
export type EditorStockMusicSearchLazyQueryHookResult = ReturnType<typeof useEditorStockMusicSearchLazyQuery>;
export type EditorStockMusicSearchQueryResult = Apollo.QueryResult<GqlClientEditorStockMusicSearchQuery, GqlClientEditorStockMusicSearchQueryVariables>;
export const EditorStockMusicAiRecommendationSearchDocument = gql`
    query editorStockMusicAIRecommendationSearch($projectID: ID, $userID: ID, $programVersionID: ID, $page: Int) {
        editorStockMusicAIRecommendationSearch(projectID: $projectID, userID: $userID, programVersionID: $programVersionID, page: $page) {
            numberOfResults
            tracks {
                ...EditorStockLibraryMusic
            }
        }
    }
    ${EditorStockLibraryMusicFragmentDoc}
`;
export type GqlClientEditorStockMusicAiRecommendationSearchProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorStockMusicAiRecommendationSearchQuery, GqlClientEditorStockMusicAiRecommendationSearchQueryVariables>;
} & TChildProps;
export function withEditorStockMusicAiRecommendationSearch<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorStockMusicAiRecommendationSearchQuery,
        GqlClientEditorStockMusicAiRecommendationSearchQueryVariables,
        GqlClientEditorStockMusicAiRecommendationSearchProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorStockMusicAiRecommendationSearchQuery,
        GqlClientEditorStockMusicAiRecommendationSearchQueryVariables,
        GqlClientEditorStockMusicAiRecommendationSearchProps<TChildProps, TDataName>
    >(EditorStockMusicAiRecommendationSearchDocument, {
        alias: "editorStockMusicAiRecommendationSearch",
        ...operationOptions
    });
}

/**
 * __useEditorStockMusicAiRecommendationSearchQuery__
 *
 * To run a query within a React component, call `useEditorStockMusicAiRecommendationSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorStockMusicAiRecommendationSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorStockMusicAiRecommendationSearchQuery({
 *   variables: {
 *      projectID: // value for 'projectID'
 *      userID: // value for 'userID'
 *      programVersionID: // value for 'programVersionID'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useEditorStockMusicAiRecommendationSearchQuery(
    baseOptions?: Apollo.QueryHookOptions<GqlClientEditorStockMusicAiRecommendationSearchQuery, GqlClientEditorStockMusicAiRecommendationSearchQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorStockMusicAiRecommendationSearchQuery, GqlClientEditorStockMusicAiRecommendationSearchQueryVariables>(
        EditorStockMusicAiRecommendationSearchDocument,
        options
    );
}
export function useEditorStockMusicAiRecommendationSearchLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorStockMusicAiRecommendationSearchQuery, GqlClientEditorStockMusicAiRecommendationSearchQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorStockMusicAiRecommendationSearchQuery, GqlClientEditorStockMusicAiRecommendationSearchQueryVariables>(
        EditorStockMusicAiRecommendationSearchDocument,
        options
    );
}
export type EditorStockMusicAiRecommendationSearchQueryHookResult = ReturnType<typeof useEditorStockMusicAiRecommendationSearchQuery>;
export type EditorStockMusicAiRecommendationSearchLazyQueryHookResult = ReturnType<typeof useEditorStockMusicAiRecommendationSearchLazyQuery>;
export type EditorStockMusicAiRecommendationSearchQueryResult = Apollo.QueryResult<GqlClientEditorStockMusicAiRecommendationSearchQuery, GqlClientEditorStockMusicAiRecommendationSearchQueryVariables>;
export const EditorStockMusicGetSingleTrackDetailsDocument = gql`
    query editorStockMusicGetSingleTrackDetails($trackID: String) {
        editorStockMusicGetSingleTrackDetails(trackID: $trackID) {
            ...EditorStockLibraryMusic
        }
    }
    ${EditorStockLibraryMusicFragmentDoc}
`;
export type GqlClientEditorStockMusicGetSingleTrackDetailsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorStockMusicGetSingleTrackDetailsQuery, GqlClientEditorStockMusicGetSingleTrackDetailsQueryVariables>;
} & TChildProps;
export function withEditorStockMusicGetSingleTrackDetails<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorStockMusicGetSingleTrackDetailsQuery,
        GqlClientEditorStockMusicGetSingleTrackDetailsQueryVariables,
        GqlClientEditorStockMusicGetSingleTrackDetailsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientEditorStockMusicGetSingleTrackDetailsQuery,
        GqlClientEditorStockMusicGetSingleTrackDetailsQueryVariables,
        GqlClientEditorStockMusicGetSingleTrackDetailsProps<TChildProps, TDataName>
    >(EditorStockMusicGetSingleTrackDetailsDocument, {
        alias: "editorStockMusicGetSingleTrackDetails",
        ...operationOptions
    });
}

/**
 * __useEditorStockMusicGetSingleTrackDetailsQuery__
 *
 * To run a query within a React component, call `useEditorStockMusicGetSingleTrackDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorStockMusicGetSingleTrackDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorStockMusicGetSingleTrackDetailsQuery({
 *   variables: {
 *      trackID: // value for 'trackID'
 *   },
 * });
 */
export function useEditorStockMusicGetSingleTrackDetailsQuery(
    baseOptions?: Apollo.QueryHookOptions<GqlClientEditorStockMusicGetSingleTrackDetailsQuery, GqlClientEditorStockMusicGetSingleTrackDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorStockMusicGetSingleTrackDetailsQuery, GqlClientEditorStockMusicGetSingleTrackDetailsQueryVariables>(EditorStockMusicGetSingleTrackDetailsDocument, options);
}
export function useEditorStockMusicGetSingleTrackDetailsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorStockMusicGetSingleTrackDetailsQuery, GqlClientEditorStockMusicGetSingleTrackDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorStockMusicGetSingleTrackDetailsQuery, GqlClientEditorStockMusicGetSingleTrackDetailsQueryVariables>(
        EditorStockMusicGetSingleTrackDetailsDocument,
        options
    );
}
export type EditorStockMusicGetSingleTrackDetailsQueryHookResult = ReturnType<typeof useEditorStockMusicGetSingleTrackDetailsQuery>;
export type EditorStockMusicGetSingleTrackDetailsLazyQueryHookResult = ReturnType<typeof useEditorStockMusicGetSingleTrackDetailsLazyQuery>;
export type EditorStockMusicGetSingleTrackDetailsQueryResult = Apollo.QueryResult<GqlClientEditorStockMusicGetSingleTrackDetailsQuery, GqlClientEditorStockMusicGetSingleTrackDetailsQueryVariables>;
export const EditorStudioElementsDocument = gql`
    query editorStudioElements($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                studioElements {
                    ...StudioElement
                }
            }
        }
    }
    ${StudioElementFragmentDoc}
`;
export type GqlClientEditorStudioElementsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorStudioElementsQuery, GqlClientEditorStudioElementsQueryVariables>;
} & TChildProps;
export function withEditorStudioElements<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorStudioElementsQuery,
        GqlClientEditorStudioElementsQueryVariables,
        GqlClientEditorStudioElementsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorStudioElementsQuery, GqlClientEditorStudioElementsQueryVariables, GqlClientEditorStudioElementsProps<TChildProps, TDataName>>(
        EditorStudioElementsDocument,
        {
            alias: "editorStudioElements",
            ...operationOptions
        }
    );
}

/**
 * __useEditorStudioElementsQuery__
 *
 * To run a query within a React component, call `useEditorStudioElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorStudioElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorStudioElementsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorStudioElementsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorStudioElementsQuery, GqlClientEditorStudioElementsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorStudioElementsQuery, GqlClientEditorStudioElementsQueryVariables>(EditorStudioElementsDocument, options);
}
export function useEditorStudioElementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorStudioElementsQuery, GqlClientEditorStudioElementsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorStudioElementsQuery, GqlClientEditorStudioElementsQueryVariables>(EditorStudioElementsDocument, options);
}
export type EditorStudioElementsQueryHookResult = ReturnType<typeof useEditorStudioElementsQuery>;
export type EditorStudioElementsLazyQueryHookResult = ReturnType<typeof useEditorStudioElementsLazyQuery>;
export type EditorStudioElementsQueryResult = Apollo.QueryResult<GqlClientEditorStudioElementsQuery, GqlClientEditorStudioElementsQueryVariables>;
export const EditorTtsFullEnablementDocument = gql`
    query editorTtsFullEnablement($programId: ID!, $programVersionId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                editorTtsFullEnablement
            }
        }
    }
`;
export type GqlClientEditorTtsFullEnablementProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorTtsFullEnablementQuery, GqlClientEditorTtsFullEnablementQueryVariables>;
} & TChildProps;
export function withEditorTtsFullEnablement<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorTtsFullEnablementQuery,
        GqlClientEditorTtsFullEnablementQueryVariables,
        GqlClientEditorTtsFullEnablementProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorTtsFullEnablementQuery, GqlClientEditorTtsFullEnablementQueryVariables, GqlClientEditorTtsFullEnablementProps<TChildProps, TDataName>>(
        EditorTtsFullEnablementDocument,
        {
            alias: "editorTtsFullEnablement",
            ...operationOptions
        }
    );
}

/**
 * __useEditorTtsFullEnablementQuery__
 *
 * To run a query within a React component, call `useEditorTtsFullEnablementQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorTtsFullEnablementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorTtsFullEnablementQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorTtsFullEnablementQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorTtsFullEnablementQuery, GqlClientEditorTtsFullEnablementQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorTtsFullEnablementQuery, GqlClientEditorTtsFullEnablementQueryVariables>(EditorTtsFullEnablementDocument, options);
}
export function useEditorTtsFullEnablementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorTtsFullEnablementQuery, GqlClientEditorTtsFullEnablementQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorTtsFullEnablementQuery, GqlClientEditorTtsFullEnablementQueryVariables>(EditorTtsFullEnablementDocument, options);
}
export type EditorTtsFullEnablementQueryHookResult = ReturnType<typeof useEditorTtsFullEnablementQuery>;
export type EditorTtsFullEnablementLazyQueryHookResult = ReturnType<typeof useEditorTtsFullEnablementLazyQuery>;
export type EditorTtsFullEnablementQueryResult = Apollo.QueryResult<GqlClientEditorTtsFullEnablementQuery, GqlClientEditorTtsFullEnablementQueryVariables>;
export const UserSettingsDocument = gql`
    query userSettings($id: ID!) {
        userSettings(id: $id) {
            id
            externalLibraries
            storyTemplates
            acceptedAiTerms
            mediaLibraryViewType
            mediaLibraryGridColumns
            acceptedGenerativeAiTerms
        }
    }
`;
export type GqlClientUserSettingsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientUserSettingsQuery, GqlClientUserSettingsQueryVariables>;
} & TChildProps;
export function withUserSettings<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUserSettingsQuery, GqlClientUserSettingsQueryVariables, GqlClientUserSettingsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientUserSettingsQuery, GqlClientUserSettingsQueryVariables, GqlClientUserSettingsProps<TChildProps, TDataName>>(UserSettingsDocument, {
        alias: "userSettings",
        ...operationOptions
    });
}

/**
 * __useUserSettingsQuery__
 *
 * To run a query within a React component, call `useUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSettingsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientUserSettingsQuery, GqlClientUserSettingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientUserSettingsQuery, GqlClientUserSettingsQueryVariables>(UserSettingsDocument, options);
}
export function useUserSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientUserSettingsQuery, GqlClientUserSettingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientUserSettingsQuery, GqlClientUserSettingsQueryVariables>(UserSettingsDocument, options);
}
export type UserSettingsQueryHookResult = ReturnType<typeof useUserSettingsQuery>;
export type UserSettingsLazyQueryHookResult = ReturnType<typeof useUserSettingsLazyQuery>;
export type UserSettingsQueryResult = Apollo.QueryResult<GqlClientUserSettingsQuery, GqlClientUserSettingsQueryVariables>;
export const EditorViewerProfilesDocument = gql`
    query editorViewerProfiles($programId: ID!) {
        editorProgram(id: $programId) {
            id
            viewerProfiles {
                ...EditorViewerProfile
            }
        }
    }
    ${EditorViewerProfileFragmentDoc}
`;
export type GqlClientEditorViewerProfilesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorViewerProfilesQuery, GqlClientEditorViewerProfilesQueryVariables>;
} & TChildProps;
export function withEditorViewerProfiles<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorViewerProfilesQuery,
        GqlClientEditorViewerProfilesQueryVariables,
        GqlClientEditorViewerProfilesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientEditorViewerProfilesQuery, GqlClientEditorViewerProfilesQueryVariables, GqlClientEditorViewerProfilesProps<TChildProps, TDataName>>(
        EditorViewerProfilesDocument,
        {
            alias: "editorViewerProfiles",
            ...operationOptions
        }
    );
}

/**
 * __useEditorViewerProfilesQuery__
 *
 * To run a query within a React component, call `useEditorViewerProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorViewerProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorViewerProfilesQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useEditorViewerProfilesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientEditorViewerProfilesQuery, GqlClientEditorViewerProfilesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientEditorViewerProfilesQuery, GqlClientEditorViewerProfilesQueryVariables>(EditorViewerProfilesDocument, options);
}
export function useEditorViewerProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientEditorViewerProfilesQuery, GqlClientEditorViewerProfilesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientEditorViewerProfilesQuery, GqlClientEditorViewerProfilesQueryVariables>(EditorViewerProfilesDocument, options);
}
export type EditorViewerProfilesQueryHookResult = ReturnType<typeof useEditorViewerProfilesQuery>;
export type EditorViewerProfilesLazyQueryHookResult = ReturnType<typeof useEditorViewerProfilesLazyQuery>;
export type EditorViewerProfilesQueryResult = Apollo.QueryResult<GqlClientEditorViewerProfilesQuery, GqlClientEditorViewerProfilesQueryVariables>;
export const GetGalleryStoryTemplatesDocument = gql`
    query getGalleryStoryTemplates($galleryType: StoryTemplatesGalleryType!, $includeChildren: Boolean) {
        galleryStoryTemplates(galleryType: $galleryType, includeChildren: $includeChildren) {
            ...GalleryStoryTemplate
        }
    }
    ${GalleryStoryTemplateFragmentDoc}
`;
export type GqlClientGetGalleryStoryTemplatesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetGalleryStoryTemplatesQuery, GqlClientGetGalleryStoryTemplatesQueryVariables>;
} & TChildProps;
export function withGetGalleryStoryTemplates<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetGalleryStoryTemplatesQuery,
        GqlClientGetGalleryStoryTemplatesQueryVariables,
        GqlClientGetGalleryStoryTemplatesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetGalleryStoryTemplatesQuery, GqlClientGetGalleryStoryTemplatesQueryVariables, GqlClientGetGalleryStoryTemplatesProps<TChildProps, TDataName>>(
        GetGalleryStoryTemplatesDocument,
        {
            alias: "getGalleryStoryTemplates",
            ...operationOptions
        }
    );
}

/**
 * __useGetGalleryStoryTemplatesQuery__
 *
 * To run a query within a React component, call `useGetGalleryStoryTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryStoryTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryStoryTemplatesQuery({
 *   variables: {
 *      galleryType: // value for 'galleryType'
 *      includeChildren: // value for 'includeChildren'
 *   },
 * });
 */
export function useGetGalleryStoryTemplatesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetGalleryStoryTemplatesQuery, GqlClientGetGalleryStoryTemplatesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetGalleryStoryTemplatesQuery, GqlClientGetGalleryStoryTemplatesQueryVariables>(GetGalleryStoryTemplatesDocument, options);
}
export function useGetGalleryStoryTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetGalleryStoryTemplatesQuery, GqlClientGetGalleryStoryTemplatesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetGalleryStoryTemplatesQuery, GqlClientGetGalleryStoryTemplatesQueryVariables>(GetGalleryStoryTemplatesDocument, options);
}
export type GetGalleryStoryTemplatesQueryHookResult = ReturnType<typeof useGetGalleryStoryTemplatesQuery>;
export type GetGalleryStoryTemplatesLazyQueryHookResult = ReturnType<typeof useGetGalleryStoryTemplatesLazyQuery>;
export type GetGalleryStoryTemplatesQueryResult = Apollo.QueryResult<GqlClientGetGalleryStoryTemplatesQuery, GqlClientGetGalleryStoryTemplatesQueryVariables>;
export const GenerateAiImageDocument = gql`
    query generateAiImage($input: AiTextToImageInput!) {
        generateAiImageFromText(input: $input) {
            error
            badPrompt
            images {
                url
                sessionId
                indexInBatch
            }
        }
    }
`;
export type GqlClientGenerateAiImageProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGenerateAiImageQuery, GqlClientGenerateAiImageQueryVariables>;
} & TChildProps;
export function withGenerateAiImage<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGenerateAiImageQuery, GqlClientGenerateAiImageQueryVariables, GqlClientGenerateAiImageProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGenerateAiImageQuery, GqlClientGenerateAiImageQueryVariables, GqlClientGenerateAiImageProps<TChildProps, TDataName>>(GenerateAiImageDocument, {
        alias: "generateAiImage",
        ...operationOptions
    });
}

/**
 * __useGenerateAiImageQuery__
 *
 * To run a query within a React component, call `useGenerateAiImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateAiImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateAiImageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateAiImageQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGenerateAiImageQuery, GqlClientGenerateAiImageQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGenerateAiImageQuery, GqlClientGenerateAiImageQueryVariables>(GenerateAiImageDocument, options);
}
export function useGenerateAiImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGenerateAiImageQuery, GqlClientGenerateAiImageQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGenerateAiImageQuery, GqlClientGenerateAiImageQueryVariables>(GenerateAiImageDocument, options);
}
export type GenerateAiImageQueryHookResult = ReturnType<typeof useGenerateAiImageQuery>;
export type GenerateAiImageLazyQueryHookResult = ReturnType<typeof useGenerateAiImageLazyQuery>;
export type GenerateAiImageQueryResult = Apollo.QueryResult<GqlClientGenerateAiImageQuery, GqlClientGenerateAiImageQueryVariables>;
export const HelpCenterEntriesDocument = gql`
    query helpCenterEntries {
        helpCenterEntries {
            id
            buttonId
            variant
            articleId
            sectionId
        }
    }
`;
export type GqlClientHelpCenterEntriesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientHelpCenterEntriesQuery, GqlClientHelpCenterEntriesQueryVariables>;
} & TChildProps;
export function withHelpCenterEntries<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientHelpCenterEntriesQuery, GqlClientHelpCenterEntriesQueryVariables, GqlClientHelpCenterEntriesProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientHelpCenterEntriesQuery, GqlClientHelpCenterEntriesQueryVariables, GqlClientHelpCenterEntriesProps<TChildProps, TDataName>>(
        HelpCenterEntriesDocument,
        {
            alias: "helpCenterEntries",
            ...operationOptions
        }
    );
}

/**
 * __useHelpCenterEntriesQuery__
 *
 * To run a query within a React component, call `useHelpCenterEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpCenterEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpCenterEntriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelpCenterEntriesQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientHelpCenterEntriesQuery, GqlClientHelpCenterEntriesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientHelpCenterEntriesQuery, GqlClientHelpCenterEntriesQueryVariables>(HelpCenterEntriesDocument, options);
}
export function useHelpCenterEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientHelpCenterEntriesQuery, GqlClientHelpCenterEntriesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientHelpCenterEntriesQuery, GqlClientHelpCenterEntriesQueryVariables>(HelpCenterEntriesDocument, options);
}
export type HelpCenterEntriesQueryHookResult = ReturnType<typeof useHelpCenterEntriesQuery>;
export type HelpCenterEntriesLazyQueryHookResult = ReturnType<typeof useHelpCenterEntriesLazyQuery>;
export type HelpCenterEntriesQueryResult = Apollo.QueryResult<GqlClientHelpCenterEntriesQuery, GqlClientHelpCenterEntriesQueryVariables>;
export const LocalEditorBrandDocument = gql`
    query localEditorBrand($brandId: ID!) {
        localEditorBrand(id: $brandId) @client {
            ...EditorBrand
        }
    }
    ${EditorBrandFragmentDoc}
`;
export type GqlClientLocalEditorBrandProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientLocalEditorBrandQuery, GqlClientLocalEditorBrandQueryVariables>;
} & TChildProps;
export function withLocalEditorBrand<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientLocalEditorBrandQuery, GqlClientLocalEditorBrandQueryVariables, GqlClientLocalEditorBrandProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientLocalEditorBrandQuery, GqlClientLocalEditorBrandQueryVariables, GqlClientLocalEditorBrandProps<TChildProps, TDataName>>(LocalEditorBrandDocument, {
        alias: "localEditorBrand",
        ...operationOptions
    });
}

/**
 * __useLocalEditorBrandQuery__
 *
 * To run a query within a React component, call `useLocalEditorBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalEditorBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalEditorBrandQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useLocalEditorBrandQuery(baseOptions: Apollo.QueryHookOptions<GqlClientLocalEditorBrandQuery, GqlClientLocalEditorBrandQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientLocalEditorBrandQuery, GqlClientLocalEditorBrandQueryVariables>(LocalEditorBrandDocument, options);
}
export function useLocalEditorBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientLocalEditorBrandQuery, GqlClientLocalEditorBrandQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientLocalEditorBrandQuery, GqlClientLocalEditorBrandQueryVariables>(LocalEditorBrandDocument, options);
}
export type LocalEditorBrandQueryHookResult = ReturnType<typeof useLocalEditorBrandQuery>;
export type LocalEditorBrandLazyQueryHookResult = ReturnType<typeof useLocalEditorBrandLazyQuery>;
export type LocalEditorBrandQueryResult = Apollo.QueryResult<GqlClientLocalEditorBrandQuery, GqlClientLocalEditorBrandQueryVariables>;
export const LocalEditorPlaceholderDocument = gql`
    query localEditorPlaceholder($placeholderId: ID!) {
        localEditorPlaceholder(id: $placeholderId) @client {
            ...EditorPlaceholder
        }
    }
    ${EditorPlaceholderFragmentDoc}
`;
export type GqlClientLocalEditorPlaceholderProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientLocalEditorPlaceholderQuery, GqlClientLocalEditorPlaceholderQueryVariables>;
} & TChildProps;
export function withLocalEditorPlaceholder<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientLocalEditorPlaceholderQuery,
        GqlClientLocalEditorPlaceholderQueryVariables,
        GqlClientLocalEditorPlaceholderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientLocalEditorPlaceholderQuery, GqlClientLocalEditorPlaceholderQueryVariables, GqlClientLocalEditorPlaceholderProps<TChildProps, TDataName>>(
        LocalEditorPlaceholderDocument,
        {
            alias: "localEditorPlaceholder",
            ...operationOptions
        }
    );
}

/**
 * __useLocalEditorPlaceholderQuery__
 *
 * To run a query within a React component, call `useLocalEditorPlaceholderQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalEditorPlaceholderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalEditorPlaceholderQuery({
 *   variables: {
 *      placeholderId: // value for 'placeholderId'
 *   },
 * });
 */
export function useLocalEditorPlaceholderQuery(baseOptions: Apollo.QueryHookOptions<GqlClientLocalEditorPlaceholderQuery, GqlClientLocalEditorPlaceholderQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientLocalEditorPlaceholderQuery, GqlClientLocalEditorPlaceholderQueryVariables>(LocalEditorPlaceholderDocument, options);
}
export function useLocalEditorPlaceholderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientLocalEditorPlaceholderQuery, GqlClientLocalEditorPlaceholderQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientLocalEditorPlaceholderQuery, GqlClientLocalEditorPlaceholderQueryVariables>(LocalEditorPlaceholderDocument, options);
}
export type LocalEditorPlaceholderQueryHookResult = ReturnType<typeof useLocalEditorPlaceholderQuery>;
export type LocalEditorPlaceholderLazyQueryHookResult = ReturnType<typeof useLocalEditorPlaceholderLazyQuery>;
export type LocalEditorPlaceholderQueryResult = Apollo.QueryResult<GqlClientLocalEditorPlaceholderQuery, GqlClientLocalEditorPlaceholderQueryVariables>;
export const LocalEditorSceneDocument = gql`
    query localEditorScene($sceneId: ID!) {
        localEditorScene(id: $sceneId) @client {
            ...EditorScene
        }
    }
    ${EditorSceneFragmentDoc}
`;
export type GqlClientLocalEditorSceneProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientLocalEditorSceneQuery, GqlClientLocalEditorSceneQueryVariables>;
} & TChildProps;
export function withLocalEditorScene<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientLocalEditorSceneQuery, GqlClientLocalEditorSceneQueryVariables, GqlClientLocalEditorSceneProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientLocalEditorSceneQuery, GqlClientLocalEditorSceneQueryVariables, GqlClientLocalEditorSceneProps<TChildProps, TDataName>>(LocalEditorSceneDocument, {
        alias: "localEditorScene",
        ...operationOptions
    });
}

/**
 * __useLocalEditorSceneQuery__
 *
 * To run a query within a React component, call `useLocalEditorSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalEditorSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalEditorSceneQuery({
 *   variables: {
 *      sceneId: // value for 'sceneId'
 *   },
 * });
 */
export function useLocalEditorSceneQuery(baseOptions: Apollo.QueryHookOptions<GqlClientLocalEditorSceneQuery, GqlClientLocalEditorSceneQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientLocalEditorSceneQuery, GqlClientLocalEditorSceneQueryVariables>(LocalEditorSceneDocument, options);
}
export function useLocalEditorSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientLocalEditorSceneQuery, GqlClientLocalEditorSceneQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientLocalEditorSceneQuery, GqlClientLocalEditorSceneQueryVariables>(LocalEditorSceneDocument, options);
}
export type LocalEditorSceneQueryHookResult = ReturnType<typeof useLocalEditorSceneQuery>;
export type LocalEditorSceneLazyQueryHookResult = ReturnType<typeof useLocalEditorSceneLazyQuery>;
export type LocalEditorSceneQueryResult = Apollo.QueryResult<GqlClientLocalEditorSceneQuery, GqlClientLocalEditorSceneQueryVariables>;
export const LocalEditorSceneWithoutCcDataDocument = gql`
    query localEditorSceneWithoutCcData($sceneId: ID!) {
        localEditorScene(id: $sceneId) @client {
            ...EditorSceneWithoutCcData
        }
    }
    ${EditorSceneWithoutCcDataFragmentDoc}
`;
export type GqlClientLocalEditorSceneWithoutCcDataProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientLocalEditorSceneWithoutCcDataQuery, GqlClientLocalEditorSceneWithoutCcDataQueryVariables>;
} & TChildProps;
export function withLocalEditorSceneWithoutCcData<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientLocalEditorSceneWithoutCcDataQuery,
        GqlClientLocalEditorSceneWithoutCcDataQueryVariables,
        GqlClientLocalEditorSceneWithoutCcDataProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientLocalEditorSceneWithoutCcDataQuery,
        GqlClientLocalEditorSceneWithoutCcDataQueryVariables,
        GqlClientLocalEditorSceneWithoutCcDataProps<TChildProps, TDataName>
    >(LocalEditorSceneWithoutCcDataDocument, {
        alias: "localEditorSceneWithoutCcData",
        ...operationOptions
    });
}

/**
 * __useLocalEditorSceneWithoutCcDataQuery__
 *
 * To run a query within a React component, call `useLocalEditorSceneWithoutCcDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalEditorSceneWithoutCcDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalEditorSceneWithoutCcDataQuery({
 *   variables: {
 *      sceneId: // value for 'sceneId'
 *   },
 * });
 */
export function useLocalEditorSceneWithoutCcDataQuery(baseOptions: Apollo.QueryHookOptions<GqlClientLocalEditorSceneWithoutCcDataQuery, GqlClientLocalEditorSceneWithoutCcDataQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientLocalEditorSceneWithoutCcDataQuery, GqlClientLocalEditorSceneWithoutCcDataQueryVariables>(LocalEditorSceneWithoutCcDataDocument, options);
}
export function useLocalEditorSceneWithoutCcDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientLocalEditorSceneWithoutCcDataQuery, GqlClientLocalEditorSceneWithoutCcDataQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientLocalEditorSceneWithoutCcDataQuery, GqlClientLocalEditorSceneWithoutCcDataQueryVariables>(LocalEditorSceneWithoutCcDataDocument, options);
}
export type LocalEditorSceneWithoutCcDataQueryHookResult = ReturnType<typeof useLocalEditorSceneWithoutCcDataQuery>;
export type LocalEditorSceneWithoutCcDataLazyQueryHookResult = ReturnType<typeof useLocalEditorSceneWithoutCcDataLazyQuery>;
export type LocalEditorSceneWithoutCcDataQueryResult = Apollo.QueryResult<GqlClientLocalEditorSceneWithoutCcDataQuery, GqlClientLocalEditorSceneWithoutCcDataQueryVariables>;
export const LocalEditorViewerProfileDocument = gql`
    query localEditorViewerProfile($viewerProfileId: ID!) {
        localEditorViewerProfile(id: $viewerProfileId) @client {
            ...EditorViewerProfile
        }
    }
    ${EditorViewerProfileFragmentDoc}
`;
export type GqlClientLocalEditorViewerProfileProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientLocalEditorViewerProfileQuery, GqlClientLocalEditorViewerProfileQueryVariables>;
} & TChildProps;
export function withLocalEditorViewerProfile<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientLocalEditorViewerProfileQuery,
        GqlClientLocalEditorViewerProfileQueryVariables,
        GqlClientLocalEditorViewerProfileProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientLocalEditorViewerProfileQuery, GqlClientLocalEditorViewerProfileQueryVariables, GqlClientLocalEditorViewerProfileProps<TChildProps, TDataName>>(
        LocalEditorViewerProfileDocument,
        {
            alias: "localEditorViewerProfile",
            ...operationOptions
        }
    );
}

/**
 * __useLocalEditorViewerProfileQuery__
 *
 * To run a query within a React component, call `useLocalEditorViewerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalEditorViewerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalEditorViewerProfileQuery({
 *   variables: {
 *      viewerProfileId: // value for 'viewerProfileId'
 *   },
 * });
 */
export function useLocalEditorViewerProfileQuery(baseOptions: Apollo.QueryHookOptions<GqlClientLocalEditorViewerProfileQuery, GqlClientLocalEditorViewerProfileQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientLocalEditorViewerProfileQuery, GqlClientLocalEditorViewerProfileQueryVariables>(LocalEditorViewerProfileDocument, options);
}
export function useLocalEditorViewerProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientLocalEditorViewerProfileQuery, GqlClientLocalEditorViewerProfileQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientLocalEditorViewerProfileQuery, GqlClientLocalEditorViewerProfileQueryVariables>(LocalEditorViewerProfileDocument, options);
}
export type LocalEditorViewerProfileQueryHookResult = ReturnType<typeof useLocalEditorViewerProfileQuery>;
export type LocalEditorViewerProfileLazyQueryHookResult = ReturnType<typeof useLocalEditorViewerProfileLazyQuery>;
export type LocalEditorViewerProfileQueryResult = Apollo.QueryResult<GqlClientLocalEditorViewerProfileQuery, GqlClientLocalEditorViewerProfileQueryVariables>;
export const LoggedInEditorAccountUserSocialNetworksDocument = gql`
    query LoggedInEditorAccountUserSocialNetworks {
        loggedInEditorAccountUser {
            id
            socialNetworks {
                statuses {
                    userImage
                    displayName
                    platform
                }
                manageSocialNetworksUrl
            }
        }
    }
`;
export type GqlClientLoggedInEditorAccountUserSocialNetworksProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientLoggedInEditorAccountUserSocialNetworksQuery, GqlClientLoggedInEditorAccountUserSocialNetworksQueryVariables>;
} & TChildProps;
export function withLoggedInEditorAccountUserSocialNetworks<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientLoggedInEditorAccountUserSocialNetworksQuery,
        GqlClientLoggedInEditorAccountUserSocialNetworksQueryVariables,
        GqlClientLoggedInEditorAccountUserSocialNetworksProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientLoggedInEditorAccountUserSocialNetworksQuery,
        GqlClientLoggedInEditorAccountUserSocialNetworksQueryVariables,
        GqlClientLoggedInEditorAccountUserSocialNetworksProps<TChildProps, TDataName>
    >(LoggedInEditorAccountUserSocialNetworksDocument, {
        alias: "loggedInEditorAccountUserSocialNetworks",
        ...operationOptions
    });
}

/**
 * __useLoggedInEditorAccountUserSocialNetworksQuery__
 *
 * To run a query within a React component, call `useLoggedInEditorAccountUserSocialNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInEditorAccountUserSocialNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInEditorAccountUserSocialNetworksQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInEditorAccountUserSocialNetworksQuery(
    baseOptions?: Apollo.QueryHookOptions<GqlClientLoggedInEditorAccountUserSocialNetworksQuery, GqlClientLoggedInEditorAccountUserSocialNetworksQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientLoggedInEditorAccountUserSocialNetworksQuery, GqlClientLoggedInEditorAccountUserSocialNetworksQueryVariables>(
        LoggedInEditorAccountUserSocialNetworksDocument,
        options
    );
}
export function useLoggedInEditorAccountUserSocialNetworksLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientLoggedInEditorAccountUserSocialNetworksQuery, GqlClientLoggedInEditorAccountUserSocialNetworksQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientLoggedInEditorAccountUserSocialNetworksQuery, GqlClientLoggedInEditorAccountUserSocialNetworksQueryVariables>(
        LoggedInEditorAccountUserSocialNetworksDocument,
        options
    );
}
export type LoggedInEditorAccountUserSocialNetworksQueryHookResult = ReturnType<typeof useLoggedInEditorAccountUserSocialNetworksQuery>;
export type LoggedInEditorAccountUserSocialNetworksLazyQueryHookResult = ReturnType<typeof useLoggedInEditorAccountUserSocialNetworksLazyQuery>;
export type LoggedInEditorAccountUserSocialNetworksQueryResult = Apollo.QueryResult<
    GqlClientLoggedInEditorAccountUserSocialNetworksQuery,
    GqlClientLoggedInEditorAccountUserSocialNetworksQueryVariables
>;
export const MediaCropHintsDocument = gql`
    query mediaCropHints($url: String!, $aspectRatio: Float!) {
        mediaCropHints(url: $url, aspectRatio: $aspectRatio) {
            ...MediaCropHints
        }
    }
    ${MediaCropHintsFragmentDoc}
`;
export type GqlClientMediaCropHintsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientMediaCropHintsQuery, GqlClientMediaCropHintsQueryVariables>;
} & TChildProps;
export function withMediaCropHints<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientMediaCropHintsQuery, GqlClientMediaCropHintsQueryVariables, GqlClientMediaCropHintsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientMediaCropHintsQuery, GqlClientMediaCropHintsQueryVariables, GqlClientMediaCropHintsProps<TChildProps, TDataName>>(MediaCropHintsDocument, {
        alias: "mediaCropHints",
        ...operationOptions
    });
}

/**
 * __useMediaCropHintsQuery__
 *
 * To run a query within a React component, call `useMediaCropHintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaCropHintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaCropHintsQuery({
 *   variables: {
 *      url: // value for 'url'
 *      aspectRatio: // value for 'aspectRatio'
 *   },
 * });
 */
export function useMediaCropHintsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientMediaCropHintsQuery, GqlClientMediaCropHintsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientMediaCropHintsQuery, GqlClientMediaCropHintsQueryVariables>(MediaCropHintsDocument, options);
}
export function useMediaCropHintsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientMediaCropHintsQuery, GqlClientMediaCropHintsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientMediaCropHintsQuery, GqlClientMediaCropHintsQueryVariables>(MediaCropHintsDocument, options);
}
export type MediaCropHintsQueryHookResult = ReturnType<typeof useMediaCropHintsQuery>;
export type MediaCropHintsLazyQueryHookResult = ReturnType<typeof useMediaCropHintsLazyQuery>;
export type MediaCropHintsQueryResult = Apollo.QueryResult<GqlClientMediaCropHintsQuery, GqlClientMediaCropHintsQueryVariables>;
export const RecentVideosAccountUsersDocument = gql`
    query recentVideosAccountUsers {
        currentAccount {
            id
            users {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;
export type GqlClientRecentVideosAccountUsersProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientRecentVideosAccountUsersQuery, GqlClientRecentVideosAccountUsersQueryVariables>;
} & TChildProps;
export function withRecentVideosAccountUsers<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientRecentVideosAccountUsersQuery,
        GqlClientRecentVideosAccountUsersQueryVariables,
        GqlClientRecentVideosAccountUsersProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientRecentVideosAccountUsersQuery, GqlClientRecentVideosAccountUsersQueryVariables, GqlClientRecentVideosAccountUsersProps<TChildProps, TDataName>>(
        RecentVideosAccountUsersDocument,
        {
            alias: "recentVideosAccountUsers",
            ...operationOptions
        }
    );
}

/**
 * __useRecentVideosAccountUsersQuery__
 *
 * To run a query within a React component, call `useRecentVideosAccountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentVideosAccountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentVideosAccountUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentVideosAccountUsersQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientRecentVideosAccountUsersQuery, GqlClientRecentVideosAccountUsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientRecentVideosAccountUsersQuery, GqlClientRecentVideosAccountUsersQueryVariables>(RecentVideosAccountUsersDocument, options);
}
export function useRecentVideosAccountUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientRecentVideosAccountUsersQuery, GqlClientRecentVideosAccountUsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientRecentVideosAccountUsersQuery, GqlClientRecentVideosAccountUsersQueryVariables>(RecentVideosAccountUsersDocument, options);
}
export type RecentVideosAccountUsersQueryHookResult = ReturnType<typeof useRecentVideosAccountUsersQuery>;
export type RecentVideosAccountUsersLazyQueryHookResult = ReturnType<typeof useRecentVideosAccountUsersLazyQuery>;
export type RecentVideosAccountUsersQueryResult = Apollo.QueryResult<GqlClientRecentVideosAccountUsersQuery, GqlClientRecentVideosAccountUsersQueryVariables>;
export const RptvUsersDocument = gql`
    query rptvUsers {
        rptvUsers {
            id
            tokens
        }
    }
`;
export type GqlClientRptvUsersProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientRptvUsersQuery, GqlClientRptvUsersQueryVariables>;
} & TChildProps;
export function withRptvUsers<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientRptvUsersQuery, GqlClientRptvUsersQueryVariables, GqlClientRptvUsersProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientRptvUsersQuery, GqlClientRptvUsersQueryVariables, GqlClientRptvUsersProps<TChildProps, TDataName>>(RptvUsersDocument, {
        alias: "rptvUsers",
        ...operationOptions
    });
}

/**
 * __useRptvUsersQuery__
 *
 * To run a query within a React component, call `useRptvUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRptvUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRptvUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useRptvUsersQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientRptvUsersQuery, GqlClientRptvUsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientRptvUsersQuery, GqlClientRptvUsersQueryVariables>(RptvUsersDocument, options);
}
export function useRptvUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientRptvUsersQuery, GqlClientRptvUsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientRptvUsersQuery, GqlClientRptvUsersQueryVariables>(RptvUsersDocument, options);
}
export type RptvUsersQueryHookResult = ReturnType<typeof useRptvUsersQuery>;
export type RptvUsersLazyQueryHookResult = ReturnType<typeof useRptvUsersLazyQuery>;
export type RptvUsersQueryResult = Apollo.QueryResult<GqlClientRptvUsersQuery, GqlClientRptvUsersQueryVariables>;
export const TrayAccountConnectionsDocument = gql`
    query trayAccountConnections {
        account {
            id
            accountConnections {
                ...TrayAccountConnection
            }
        }
    }
    ${TrayAccountConnectionFragmentDoc}
`;
export type GqlClientTrayAccountConnectionsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientTrayAccountConnectionsQuery, GqlClientTrayAccountConnectionsQueryVariables>;
} & TChildProps;
export function withTrayAccountConnections<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientTrayAccountConnectionsQuery,
        GqlClientTrayAccountConnectionsQueryVariables,
        GqlClientTrayAccountConnectionsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientTrayAccountConnectionsQuery, GqlClientTrayAccountConnectionsQueryVariables, GqlClientTrayAccountConnectionsProps<TChildProps, TDataName>>(
        TrayAccountConnectionsDocument,
        {
            alias: "trayAccountConnections",
            ...operationOptions
        }
    );
}

/**
 * __useTrayAccountConnectionsQuery__
 *
 * To run a query within a React component, call `useTrayAccountConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrayAccountConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrayAccountConnectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrayAccountConnectionsQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientTrayAccountConnectionsQuery, GqlClientTrayAccountConnectionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientTrayAccountConnectionsQuery, GqlClientTrayAccountConnectionsQueryVariables>(TrayAccountConnectionsDocument, options);
}
export function useTrayAccountConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientTrayAccountConnectionsQuery, GqlClientTrayAccountConnectionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientTrayAccountConnectionsQuery, GqlClientTrayAccountConnectionsQueryVariables>(TrayAccountConnectionsDocument, options);
}
export type TrayAccountConnectionsQueryHookResult = ReturnType<typeof useTrayAccountConnectionsQuery>;
export type TrayAccountConnectionsLazyQueryHookResult = ReturnType<typeof useTrayAccountConnectionsLazyQuery>;
export type TrayAccountConnectionsQueryResult = Apollo.QueryResult<GqlClientTrayAccountConnectionsQuery, GqlClientTrayAccountConnectionsQueryVariables>;
export const TrayGoogleSheetColumnsDocument = gql`
    query trayGoogleSheetColumns($spreadSheetId: ID!, $sheetName: String!) {
        trayGoogleSheetColumns(spreadSheetId: $spreadSheetId, sheetName: $sheetName) {
            id
            name
        }
    }
`;
export type GqlClientTrayGoogleSheetColumnsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientTrayGoogleSheetColumnsQuery, GqlClientTrayGoogleSheetColumnsQueryVariables>;
} & TChildProps;
export function withTrayGoogleSheetColumns<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientTrayGoogleSheetColumnsQuery,
        GqlClientTrayGoogleSheetColumnsQueryVariables,
        GqlClientTrayGoogleSheetColumnsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientTrayGoogleSheetColumnsQuery, GqlClientTrayGoogleSheetColumnsQueryVariables, GqlClientTrayGoogleSheetColumnsProps<TChildProps, TDataName>>(
        TrayGoogleSheetColumnsDocument,
        {
            alias: "trayGoogleSheetColumns",
            ...operationOptions
        }
    );
}

/**
 * __useTrayGoogleSheetColumnsQuery__
 *
 * To run a query within a React component, call `useTrayGoogleSheetColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrayGoogleSheetColumnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrayGoogleSheetColumnsQuery({
 *   variables: {
 *      spreadSheetId: // value for 'spreadSheetId'
 *      sheetName: // value for 'sheetName'
 *   },
 * });
 */
export function useTrayGoogleSheetColumnsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientTrayGoogleSheetColumnsQuery, GqlClientTrayGoogleSheetColumnsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientTrayGoogleSheetColumnsQuery, GqlClientTrayGoogleSheetColumnsQueryVariables>(TrayGoogleSheetColumnsDocument, options);
}
export function useTrayGoogleSheetColumnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientTrayGoogleSheetColumnsQuery, GqlClientTrayGoogleSheetColumnsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientTrayGoogleSheetColumnsQuery, GqlClientTrayGoogleSheetColumnsQueryVariables>(TrayGoogleSheetColumnsDocument, options);
}
export type TrayGoogleSheetColumnsQueryHookResult = ReturnType<typeof useTrayGoogleSheetColumnsQuery>;
export type TrayGoogleSheetColumnsLazyQueryHookResult = ReturnType<typeof useTrayGoogleSheetColumnsLazyQuery>;
export type TrayGoogleSheetColumnsQueryResult = Apollo.QueryResult<GqlClientTrayGoogleSheetColumnsQuery, GqlClientTrayGoogleSheetColumnsQueryVariables>;
export const TrayGoogleSheetsDocument = gql`
    query trayGoogleSheets($spreadSheetId: ID!) {
        trayGoogleSheets(spreadSheetId: $spreadSheetId) {
            ... on GoogleSheetResultOutputSuccess {
                result
                sheets {
                    id
                    name
                }
            }
            ... on GoogleSheetResultOutputError {
                error
                result
            }
        }
    }
`;
export type GqlClientTrayGoogleSheetsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientTrayGoogleSheetsQuery, GqlClientTrayGoogleSheetsQueryVariables>;
} & TChildProps;
export function withTrayGoogleSheets<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientTrayGoogleSheetsQuery, GqlClientTrayGoogleSheetsQueryVariables, GqlClientTrayGoogleSheetsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientTrayGoogleSheetsQuery, GqlClientTrayGoogleSheetsQueryVariables, GqlClientTrayGoogleSheetsProps<TChildProps, TDataName>>(TrayGoogleSheetsDocument, {
        alias: "trayGoogleSheets",
        ...operationOptions
    });
}

/**
 * __useTrayGoogleSheetsQuery__
 *
 * To run a query within a React component, call `useTrayGoogleSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrayGoogleSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrayGoogleSheetsQuery({
 *   variables: {
 *      spreadSheetId: // value for 'spreadSheetId'
 *   },
 * });
 */
export function useTrayGoogleSheetsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientTrayGoogleSheetsQuery, GqlClientTrayGoogleSheetsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientTrayGoogleSheetsQuery, GqlClientTrayGoogleSheetsQueryVariables>(TrayGoogleSheetsDocument, options);
}
export function useTrayGoogleSheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientTrayGoogleSheetsQuery, GqlClientTrayGoogleSheetsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientTrayGoogleSheetsQuery, GqlClientTrayGoogleSheetsQueryVariables>(TrayGoogleSheetsDocument, options);
}
export type TrayGoogleSheetsQueryHookResult = ReturnType<typeof useTrayGoogleSheetsQuery>;
export type TrayGoogleSheetsLazyQueryHookResult = ReturnType<typeof useTrayGoogleSheetsLazyQuery>;
export type TrayGoogleSheetsQueryResult = Apollo.QueryResult<GqlClientTrayGoogleSheetsQuery, GqlClientTrayGoogleSheetsQueryVariables>;
export const TrayServiceAuthUrlDocument = gql`
    query trayServiceAuthUrl($connectorType: String!) {
        trayServiceAuthUrl(connectorType: $connectorType) {
            authUrl
        }
    }
`;
export type GqlClientTrayServiceAuthUrlProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientTrayServiceAuthUrlQuery, GqlClientTrayServiceAuthUrlQueryVariables>;
} & TChildProps;
export function withTrayServiceAuthUrl<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientTrayServiceAuthUrlQuery, GqlClientTrayServiceAuthUrlQueryVariables, GqlClientTrayServiceAuthUrlProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientTrayServiceAuthUrlQuery, GqlClientTrayServiceAuthUrlQueryVariables, GqlClientTrayServiceAuthUrlProps<TChildProps, TDataName>>(
        TrayServiceAuthUrlDocument,
        {
            alias: "trayServiceAuthUrl",
            ...operationOptions
        }
    );
}

/**
 * __useTrayServiceAuthUrlQuery__
 *
 * To run a query within a React component, call `useTrayServiceAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrayServiceAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrayServiceAuthUrlQuery({
 *   variables: {
 *      connectorType: // value for 'connectorType'
 *   },
 * });
 */
export function useTrayServiceAuthUrlQuery(baseOptions: Apollo.QueryHookOptions<GqlClientTrayServiceAuthUrlQuery, GqlClientTrayServiceAuthUrlQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientTrayServiceAuthUrlQuery, GqlClientTrayServiceAuthUrlQueryVariables>(TrayServiceAuthUrlDocument, options);
}
export function useTrayServiceAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientTrayServiceAuthUrlQuery, GqlClientTrayServiceAuthUrlQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientTrayServiceAuthUrlQuery, GqlClientTrayServiceAuthUrlQueryVariables>(TrayServiceAuthUrlDocument, options);
}
export type TrayServiceAuthUrlQueryHookResult = ReturnType<typeof useTrayServiceAuthUrlQuery>;
export type TrayServiceAuthUrlLazyQueryHookResult = ReturnType<typeof useTrayServiceAuthUrlLazyQuery>;
export type TrayServiceAuthUrlQueryResult = Apollo.QueryResult<GqlClientTrayServiceAuthUrlQuery, GqlClientTrayServiceAuthUrlQueryVariables>;
export const TrayServiceConnectorEntitiesDocument = gql`
    query trayServiceConnectorEntities($dataLibraryId: String!, $connectorType: String!, $parentEntityId: String, $authLastUpdated: String) {
        trayServiceConnectorEntities(dataLibraryId: $dataLibraryId, connectorType: $connectorType, parentEntityId: $parentEntityId, authLastUpdated: $authLastUpdated) {
            id
            name
            isField
            sampleValue
        }
    }
`;
export type GqlClientTrayServiceConnectorEntitiesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientTrayServiceConnectorEntitiesQuery, GqlClientTrayServiceConnectorEntitiesQueryVariables>;
} & TChildProps;
export function withTrayServiceConnectorEntities<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientTrayServiceConnectorEntitiesQuery,
        GqlClientTrayServiceConnectorEntitiesQueryVariables,
        GqlClientTrayServiceConnectorEntitiesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientTrayServiceConnectorEntitiesQuery,
        GqlClientTrayServiceConnectorEntitiesQueryVariables,
        GqlClientTrayServiceConnectorEntitiesProps<TChildProps, TDataName>
    >(TrayServiceConnectorEntitiesDocument, {
        alias: "trayServiceConnectorEntities",
        ...operationOptions
    });
}

/**
 * __useTrayServiceConnectorEntitiesQuery__
 *
 * To run a query within a React component, call `useTrayServiceConnectorEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrayServiceConnectorEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrayServiceConnectorEntitiesQuery({
 *   variables: {
 *      dataLibraryId: // value for 'dataLibraryId'
 *      connectorType: // value for 'connectorType'
 *      parentEntityId: // value for 'parentEntityId'
 *      authLastUpdated: // value for 'authLastUpdated'
 *   },
 * });
 */
export function useTrayServiceConnectorEntitiesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientTrayServiceConnectorEntitiesQuery, GqlClientTrayServiceConnectorEntitiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientTrayServiceConnectorEntitiesQuery, GqlClientTrayServiceConnectorEntitiesQueryVariables>(TrayServiceConnectorEntitiesDocument, options);
}
export function useTrayServiceConnectorEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientTrayServiceConnectorEntitiesQuery, GqlClientTrayServiceConnectorEntitiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientTrayServiceConnectorEntitiesQuery, GqlClientTrayServiceConnectorEntitiesQueryVariables>(TrayServiceConnectorEntitiesDocument, options);
}
export type TrayServiceConnectorEntitiesQueryHookResult = ReturnType<typeof useTrayServiceConnectorEntitiesQuery>;
export type TrayServiceConnectorEntitiesLazyQueryHookResult = ReturnType<typeof useTrayServiceConnectorEntitiesLazyQuery>;
export type TrayServiceConnectorEntitiesQueryResult = Apollo.QueryResult<GqlClientTrayServiceConnectorEntitiesQuery, GqlClientTrayServiceConnectorEntitiesQueryVariables>;
export const TrayTestConnectorAuthenticationDocument = gql`
    query trayTestConnectorAuthentication($connectorType: String!) {
        trayTestConnectorAuthentication(connectorType: $connectorType) {
            authenticated
        }
    }
`;
export type GqlClientTrayTestConnectorAuthenticationProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientTrayTestConnectorAuthenticationQuery, GqlClientTrayTestConnectorAuthenticationQueryVariables>;
} & TChildProps;
export function withTrayTestConnectorAuthentication<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientTrayTestConnectorAuthenticationQuery,
        GqlClientTrayTestConnectorAuthenticationQueryVariables,
        GqlClientTrayTestConnectorAuthenticationProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientTrayTestConnectorAuthenticationQuery,
        GqlClientTrayTestConnectorAuthenticationQueryVariables,
        GqlClientTrayTestConnectorAuthenticationProps<TChildProps, TDataName>
    >(TrayTestConnectorAuthenticationDocument, {
        alias: "trayTestConnectorAuthentication",
        ...operationOptions
    });
}

/**
 * __useTrayTestConnectorAuthenticationQuery__
 *
 * To run a query within a React component, call `useTrayTestConnectorAuthenticationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrayTestConnectorAuthenticationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrayTestConnectorAuthenticationQuery({
 *   variables: {
 *      connectorType: // value for 'connectorType'
 *   },
 * });
 */
export function useTrayTestConnectorAuthenticationQuery(baseOptions: Apollo.QueryHookOptions<GqlClientTrayTestConnectorAuthenticationQuery, GqlClientTrayTestConnectorAuthenticationQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientTrayTestConnectorAuthenticationQuery, GqlClientTrayTestConnectorAuthenticationQueryVariables>(TrayTestConnectorAuthenticationDocument, options);
}
export function useTrayTestConnectorAuthenticationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientTrayTestConnectorAuthenticationQuery, GqlClientTrayTestConnectorAuthenticationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientTrayTestConnectorAuthenticationQuery, GqlClientTrayTestConnectorAuthenticationQueryVariables>(TrayTestConnectorAuthenticationDocument, options);
}
export type TrayTestConnectorAuthenticationQueryHookResult = ReturnType<typeof useTrayTestConnectorAuthenticationQuery>;
export type TrayTestConnectorAuthenticationLazyQueryHookResult = ReturnType<typeof useTrayTestConnectorAuthenticationLazyQuery>;
export type TrayTestConnectorAuthenticationQueryResult = Apollo.QueryResult<GqlClientTrayTestConnectorAuthenticationQuery, GqlClientTrayTestConnectorAuthenticationQueryVariables>;
export const UndoStacksDocument = gql`
    query undoStacks {
        undoStacks {
            ...UndoStacks
        }
    }
    ${UndoStacksFragmentDoc}
`;
export type GqlClientUndoStacksProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientUndoStacksQuery, GqlClientUndoStacksQueryVariables>;
} & TChildProps;
export function withUndoStacks<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUndoStacksQuery, GqlClientUndoStacksQueryVariables, GqlClientUndoStacksProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientUndoStacksQuery, GqlClientUndoStacksQueryVariables, GqlClientUndoStacksProps<TChildProps, TDataName>>(UndoStacksDocument, {
        alias: "undoStacks",
        ...operationOptions
    });
}

/**
 * __useUndoStacksQuery__
 *
 * To run a query within a React component, call `useUndoStacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUndoStacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUndoStacksQuery({
 *   variables: {
 *   },
 * });
 */
export function useUndoStacksQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientUndoStacksQuery, GqlClientUndoStacksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientUndoStacksQuery, GqlClientUndoStacksQueryVariables>(UndoStacksDocument, options);
}
export function useUndoStacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientUndoStacksQuery, GqlClientUndoStacksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientUndoStacksQuery, GqlClientUndoStacksQueryVariables>(UndoStacksDocument, options);
}
export type UndoStacksQueryHookResult = ReturnType<typeof useUndoStacksQuery>;
export type UndoStacksLazyQueryHookResult = ReturnType<typeof useUndoStacksLazyQuery>;
export type UndoStacksQueryResult = Apollo.QueryResult<GqlClientUndoStacksQuery, GqlClientUndoStacksQueryVariables>;
export const AccountAvatarCreatedDocument = gql`
    subscription accountAvatarCreated($mutationId: ID!) {
        customAvatarCreationStarted(mutationId: $mutationId) {
            id
            status
            avatarId
            mutationId
            __typename
            ... on CustomAvatarCreationStartedPayloadPending {
                progress
            }
            ... on CustomAvatarCreationStartedPayloadSuccess {
                data {
                    avatar {
                        id
                        name
                    }
                }
            }
            ... on CustomAvatarCreationStartedPayloadError {
                error
            }
        }
    }
`;
export type GqlClientAccountAvatarCreatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountAvatarCreatedSubscription, GqlClientAccountAvatarCreatedSubscriptionVariables>;
} & TChildProps;
export function withAccountAvatarCreated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountAvatarCreatedSubscription,
        GqlClientAccountAvatarCreatedSubscriptionVariables,
        GqlClientAccountAvatarCreatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountAvatarCreatedSubscription,
        GqlClientAccountAvatarCreatedSubscriptionVariables,
        GqlClientAccountAvatarCreatedProps<TChildProps, TDataName>
    >(AccountAvatarCreatedDocument, {
        alias: "accountAvatarCreated",
        ...operationOptions
    });
}

/**
 * __useAccountAvatarCreatedSubscription__
 *
 * To run a query within a React component, call `useAccountAvatarCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountAvatarCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAvatarCreatedSubscription({
 *   variables: {
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useAccountAvatarCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<GqlClientAccountAvatarCreatedSubscription, GqlClientAccountAvatarCreatedSubscriptionVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountAvatarCreatedSubscription, GqlClientAccountAvatarCreatedSubscriptionVariables>(AccountAvatarCreatedDocument, options);
}
export type AccountAvatarCreatedSubscriptionHookResult = ReturnType<typeof useAccountAvatarCreatedSubscription>;
export type AccountAvatarCreatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountAvatarCreatedSubscription>;
export const AccountAvatarLibraryChangedDocument = gql`
    subscription accountAvatarLibraryChanged {
        accountAvatarLibraryChanged {
            id
            avatars {
                ...EditorAssetLibraryAvatar
            }
        }
    }
    ${EditorAssetLibraryAvatarFragmentDoc}
`;
export type GqlClientAccountAvatarLibraryChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountAvatarLibraryChangedSubscription, GqlClientAccountAvatarLibraryChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountAvatarLibraryChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountAvatarLibraryChangedSubscription,
        GqlClientAccountAvatarLibraryChangedSubscriptionVariables,
        GqlClientAccountAvatarLibraryChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountAvatarLibraryChangedSubscription,
        GqlClientAccountAvatarLibraryChangedSubscriptionVariables,
        GqlClientAccountAvatarLibraryChangedProps<TChildProps, TDataName>
    >(AccountAvatarLibraryChangedDocument, {
        alias: "accountAvatarLibraryChanged",
        ...operationOptions
    });
}

/**
 * __useAccountAvatarLibraryChangedSubscription__
 *
 * To run a query within a React component, call `useAccountAvatarLibraryChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountAvatarLibraryChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAvatarLibraryChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountAvatarLibraryChangedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<GqlClientAccountAvatarLibraryChangedSubscription, GqlClientAccountAvatarLibraryChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountAvatarLibraryChangedSubscription, GqlClientAccountAvatarLibraryChangedSubscriptionVariables>(AccountAvatarLibraryChangedDocument, options);
}
export type AccountAvatarLibraryChangedSubscriptionHookResult = ReturnType<typeof useAccountAvatarLibraryChangedSubscription>;
export type AccountAvatarLibraryChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountAvatarLibraryChangedSubscription>;
export const AccountConnectionsChangedDocument = gql`
    subscription accountConnectionsChanged($accountId: ID!) {
        accountConnectionsChanged(account: $accountId) {
            id
            accountConnections {
                ...TrayAccountConnection
            }
        }
    }
    ${TrayAccountConnectionFragmentDoc}
`;
export type GqlClientAccountConnectionsChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountConnectionsChangedSubscription, GqlClientAccountConnectionsChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountConnectionsChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountConnectionsChangedSubscription,
        GqlClientAccountConnectionsChangedSubscriptionVariables,
        GqlClientAccountConnectionsChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountConnectionsChangedSubscription,
        GqlClientAccountConnectionsChangedSubscriptionVariables,
        GqlClientAccountConnectionsChangedProps<TChildProps, TDataName>
    >(AccountConnectionsChangedDocument, {
        alias: "accountConnectionsChanged",
        ...operationOptions
    });
}

/**
 * __useAccountConnectionsChangedSubscription__
 *
 * To run a query within a React component, call `useAccountConnectionsChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountConnectionsChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountConnectionsChangedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountConnectionsChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAccountConnectionsChangedSubscription, GqlClientAccountConnectionsChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountConnectionsChangedSubscription, GqlClientAccountConnectionsChangedSubscriptionVariables>(AccountConnectionsChangedDocument, options);
}
export type AccountConnectionsChangedSubscriptionHookResult = ReturnType<typeof useAccountConnectionsChangedSubscription>;
export type AccountConnectionsChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountConnectionsChangedSubscription>;
export const AccountCopilotBriefListChangedDocument = gql`
    subscription accountCopilotBriefListChanged($accountId: ID!) {
        accountCopilotBriefListChanged(account: $accountId) {
            id
            copilotOtherUsersBriefs {
                ...AccountCopilotBrief
            }
            copilotUserBriefs {
                ...AccountCopilotBrief
            }
        }
    }
    ${AccountCopilotBriefFragmentDoc}
`;
export type GqlClientAccountCopilotBriefListChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountCopilotBriefListChangedSubscription, GqlClientAccountCopilotBriefListChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountCopilotBriefListChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountCopilotBriefListChangedSubscription,
        GqlClientAccountCopilotBriefListChangedSubscriptionVariables,
        GqlClientAccountCopilotBriefListChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountCopilotBriefListChangedSubscription,
        GqlClientAccountCopilotBriefListChangedSubscriptionVariables,
        GqlClientAccountCopilotBriefListChangedProps<TChildProps, TDataName>
    >(AccountCopilotBriefListChangedDocument, {
        alias: "accountCopilotBriefListChanged",
        ...operationOptions
    });
}

/**
 * __useAccountCopilotBriefListChangedSubscription__
 *
 * To run a query within a React component, call `useAccountCopilotBriefListChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountCopilotBriefListChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCopilotBriefListChangedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountCopilotBriefListChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAccountCopilotBriefListChangedSubscription, GqlClientAccountCopilotBriefListChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountCopilotBriefListChangedSubscription, GqlClientAccountCopilotBriefListChangedSubscriptionVariables>(AccountCopilotBriefListChangedDocument, options);
}
export type AccountCopilotBriefListChangedSubscriptionHookResult = ReturnType<typeof useAccountCopilotBriefListChangedSubscription>;
export type AccountCopilotBriefListChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountCopilotBriefListChangedSubscription>;
export const AccountDataLibraryForImportDataChangedDocument = gql`
    subscription accountDataLibraryForImportDataChanged($dataLibraryId: ID!) {
        accountDataLibraryForImportDataChanged(dataLibraryId: $dataLibraryId) {
            ...AccountDataLibraryForImportData
        }
    }
    ${AccountDataLibraryForImportDataFragmentDoc}
`;
export type GqlClientAccountDataLibraryForImportDataChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountDataLibraryForImportDataChangedSubscription, GqlClientAccountDataLibraryForImportDataChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountDataLibraryForImportDataChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountDataLibraryForImportDataChangedSubscription,
        GqlClientAccountDataLibraryForImportDataChangedSubscriptionVariables,
        GqlClientAccountDataLibraryForImportDataChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountDataLibraryForImportDataChangedSubscription,
        GqlClientAccountDataLibraryForImportDataChangedSubscriptionVariables,
        GqlClientAccountDataLibraryForImportDataChangedProps<TChildProps, TDataName>
    >(AccountDataLibraryForImportDataChangedDocument, {
        alias: "accountDataLibraryForImportDataChanged",
        ...operationOptions
    });
}

/**
 * __useAccountDataLibraryForImportDataChangedSubscription__
 *
 * To run a query within a React component, call `useAccountDataLibraryForImportDataChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountDataLibraryForImportDataChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDataLibraryForImportDataChangedSubscription({
 *   variables: {
 *      dataLibraryId: // value for 'dataLibraryId'
 *   },
 * });
 */
export function useAccountDataLibraryForImportDataChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAccountDataLibraryForImportDataChangedSubscription, GqlClientAccountDataLibraryForImportDataChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountDataLibraryForImportDataChangedSubscription, GqlClientAccountDataLibraryForImportDataChangedSubscriptionVariables>(
        AccountDataLibraryForImportDataChangedDocument,
        options
    );
}
export type AccountDataLibraryForImportDataChangedSubscriptionHookResult = ReturnType<typeof useAccountDataLibraryForImportDataChangedSubscription>;
export type AccountDataLibraryForImportDataChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountDataLibraryForImportDataChangedSubscription>;
export const AccountDataLibraryListChangedDocument = gql`
    subscription accountDataLibraryListChanged($accountId: ID!) {
        accountDataLibraryListChanged(account: $accountId) {
            id
            dataLibraries {
                ...AccountDataLibrary
            }
        }
    }
    ${AccountDataLibraryFragmentDoc}
`;
export type GqlClientAccountDataLibraryListChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountDataLibraryListChangedSubscription, GqlClientAccountDataLibraryListChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountDataLibraryListChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountDataLibraryListChangedSubscription,
        GqlClientAccountDataLibraryListChangedSubscriptionVariables,
        GqlClientAccountDataLibraryListChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountDataLibraryListChangedSubscription,
        GqlClientAccountDataLibraryListChangedSubscriptionVariables,
        GqlClientAccountDataLibraryListChangedProps<TChildProps, TDataName>
    >(AccountDataLibraryListChangedDocument, {
        alias: "accountDataLibraryListChanged",
        ...operationOptions
    });
}

/**
 * __useAccountDataLibraryListChangedSubscription__
 *
 * To run a query within a React component, call `useAccountDataLibraryListChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountDataLibraryListChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDataLibraryListChangedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountDataLibraryListChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAccountDataLibraryListChangedSubscription, GqlClientAccountDataLibraryListChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountDataLibraryListChangedSubscription, GqlClientAccountDataLibraryListChangedSubscriptionVariables>(AccountDataLibraryListChangedDocument, options);
}
export type AccountDataLibraryListChangedSubscriptionHookResult = ReturnType<typeof useAccountDataLibraryListChangedSubscription>;
export type AccountDataLibraryListChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountDataLibraryListChangedSubscription>;
export const AccountDataLibraryProgramListChangedDocument = gql`
    subscription accountDataLibraryProgramListChanged($accountDataLibraryVersionId: ID!) {
        accountDataLibraryProgramListChanged(accountDataLibraryVersionId: $accountDataLibraryVersionId) {
            id
            accountDataLibrary {
                id
                draftAccountDataLibraryVersion {
                    id
                    usedInVideos {
                        id
                        name
                    }
                }
            }
        }
    }
`;
export type GqlClientAccountDataLibraryProgramListChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountDataLibraryProgramListChangedSubscription, GqlClientAccountDataLibraryProgramListChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountDataLibraryProgramListChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountDataLibraryProgramListChangedSubscription,
        GqlClientAccountDataLibraryProgramListChangedSubscriptionVariables,
        GqlClientAccountDataLibraryProgramListChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountDataLibraryProgramListChangedSubscription,
        GqlClientAccountDataLibraryProgramListChangedSubscriptionVariables,
        GqlClientAccountDataLibraryProgramListChangedProps<TChildProps, TDataName>
    >(AccountDataLibraryProgramListChangedDocument, {
        alias: "accountDataLibraryProgramListChanged",
        ...operationOptions
    });
}

/**
 * __useAccountDataLibraryProgramListChangedSubscription__
 *
 * To run a query within a React component, call `useAccountDataLibraryProgramListChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountDataLibraryProgramListChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDataLibraryProgramListChangedSubscription({
 *   variables: {
 *      accountDataLibraryVersionId: // value for 'accountDataLibraryVersionId'
 *   },
 * });
 */
export function useAccountDataLibraryProgramListChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAccountDataLibraryProgramListChangedSubscription, GqlClientAccountDataLibraryProgramListChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountDataLibraryProgramListChangedSubscription, GqlClientAccountDataLibraryProgramListChangedSubscriptionVariables>(
        AccountDataLibraryProgramListChangedDocument,
        options
    );
}
export type AccountDataLibraryProgramListChangedSubscriptionHookResult = ReturnType<typeof useAccountDataLibraryProgramListChangedSubscription>;
export type AccountDataLibraryProgramListChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountDataLibraryProgramListChangedSubscription>;
export const AccountDataLibraryProgramUsedInChangedDocument = gql`
    subscription accountDataLibraryProgramUsedInChanged($accountDataLibraryVersionId: ID!) {
        accountDataLibraryProgramUsedInChanged(accountDataLibraryVersionId: $accountDataLibraryVersionId) {
            id
            programVersionDraft {
                id
                usedElements
            }
        }
    }
`;
export type GqlClientAccountDataLibraryProgramUsedInChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountDataLibraryProgramUsedInChangedSubscription, GqlClientAccountDataLibraryProgramUsedInChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountDataLibraryProgramUsedInChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountDataLibraryProgramUsedInChangedSubscription,
        GqlClientAccountDataLibraryProgramUsedInChangedSubscriptionVariables,
        GqlClientAccountDataLibraryProgramUsedInChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountDataLibraryProgramUsedInChangedSubscription,
        GqlClientAccountDataLibraryProgramUsedInChangedSubscriptionVariables,
        GqlClientAccountDataLibraryProgramUsedInChangedProps<TChildProps, TDataName>
    >(AccountDataLibraryProgramUsedInChangedDocument, {
        alias: "accountDataLibraryProgramUsedInChanged",
        ...operationOptions
    });
}

/**
 * __useAccountDataLibraryProgramUsedInChangedSubscription__
 *
 * To run a query within a React component, call `useAccountDataLibraryProgramUsedInChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountDataLibraryProgramUsedInChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDataLibraryProgramUsedInChangedSubscription({
 *   variables: {
 *      accountDataLibraryVersionId: // value for 'accountDataLibraryVersionId'
 *   },
 * });
 */
export function useAccountDataLibraryProgramUsedInChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAccountDataLibraryProgramUsedInChangedSubscription, GqlClientAccountDataLibraryProgramUsedInChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountDataLibraryProgramUsedInChangedSubscription, GqlClientAccountDataLibraryProgramUsedInChangedSubscriptionVariables>(
        AccountDataLibraryProgramUsedInChangedDocument,
        options
    );
}
export type AccountDataLibraryProgramUsedInChangedSubscriptionHookResult = ReturnType<typeof useAccountDataLibraryProgramUsedInChangedSubscription>;
export type AccountDataLibraryProgramUsedInChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountDataLibraryProgramUsedInChangedSubscription>;
export const AccountDataLibraryVersionChangedDocument = gql`
    subscription accountDataLibraryVersionChanged($id: ID!) {
        accountDataLibraryVersionChanged(accountDataLibraryVersionId: $id) {
            ...AccountDataLibraryVersion
        }
    }
    ${AccountDataLibraryVersionFragmentDoc}
`;
export type GqlClientAccountDataLibraryVersionChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountDataLibraryVersionChangedSubscription, GqlClientAccountDataLibraryVersionChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountDataLibraryVersionChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountDataLibraryVersionChangedSubscription,
        GqlClientAccountDataLibraryVersionChangedSubscriptionVariables,
        GqlClientAccountDataLibraryVersionChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountDataLibraryVersionChangedSubscription,
        GqlClientAccountDataLibraryVersionChangedSubscriptionVariables,
        GqlClientAccountDataLibraryVersionChangedProps<TChildProps, TDataName>
    >(AccountDataLibraryVersionChangedDocument, {
        alias: "accountDataLibraryVersionChanged",
        ...operationOptions
    });
}

/**
 * __useAccountDataLibraryVersionChangedSubscription__
 *
 * To run a query within a React component, call `useAccountDataLibraryVersionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountDataLibraryVersionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDataLibraryVersionChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountDataLibraryVersionChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAccountDataLibraryVersionChangedSubscription, GqlClientAccountDataLibraryVersionChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountDataLibraryVersionChangedSubscription, GqlClientAccountDataLibraryVersionChangedSubscriptionVariables>(
        AccountDataLibraryVersionChangedDocument,
        options
    );
}
export type AccountDataLibraryVersionChangedSubscriptionHookResult = ReturnType<typeof useAccountDataLibraryVersionChangedSubscription>;
export type AccountDataLibraryVersionChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountDataLibraryVersionChangedSubscription>;
export const AccountEditorProgramDeletedDocument = gql`
    subscription accountEditorProgramDeleted {
        accountEditorProgramDeleted {
            id
            editorPrograms {
                id
            }
            editorProgramsIncludingRemoved {
                id
                lifecycleStage
            }
        }
    }
`;
export type GqlClientAccountEditorProgramDeletedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountEditorProgramDeletedSubscription, GqlClientAccountEditorProgramDeletedSubscriptionVariables>;
} & TChildProps;
export function withAccountEditorProgramDeleted<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountEditorProgramDeletedSubscription,
        GqlClientAccountEditorProgramDeletedSubscriptionVariables,
        GqlClientAccountEditorProgramDeletedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountEditorProgramDeletedSubscription,
        GqlClientAccountEditorProgramDeletedSubscriptionVariables,
        GqlClientAccountEditorProgramDeletedProps<TChildProps, TDataName>
    >(AccountEditorProgramDeletedDocument, {
        alias: "accountEditorProgramDeleted",
        ...operationOptions
    });
}

/**
 * __useAccountEditorProgramDeletedSubscription__
 *
 * To run a query within a React component, call `useAccountEditorProgramDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountEditorProgramDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountEditorProgramDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountEditorProgramDeletedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<GqlClientAccountEditorProgramDeletedSubscription, GqlClientAccountEditorProgramDeletedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountEditorProgramDeletedSubscription, GqlClientAccountEditorProgramDeletedSubscriptionVariables>(AccountEditorProgramDeletedDocument, options);
}
export type AccountEditorProgramDeletedSubscriptionHookResult = ReturnType<typeof useAccountEditorProgramDeletedSubscription>;
export type AccountEditorProgramDeletedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountEditorProgramDeletedSubscription>;
export const AccountEditorProgramUpdatedDocument = gql`
    subscription accountEditorProgramUpdated {
        accountEditorProgramUpdated {
            id
            name
            isArchive
            lastArchived
            lifecycleStage
            anyUpdated
            folder {
                id
            }
        }
    }
`;
export type GqlClientAccountEditorProgramUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountEditorProgramUpdatedSubscription, GqlClientAccountEditorProgramUpdatedSubscriptionVariables>;
} & TChildProps;
export function withAccountEditorProgramUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountEditorProgramUpdatedSubscription,
        GqlClientAccountEditorProgramUpdatedSubscriptionVariables,
        GqlClientAccountEditorProgramUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountEditorProgramUpdatedSubscription,
        GqlClientAccountEditorProgramUpdatedSubscriptionVariables,
        GqlClientAccountEditorProgramUpdatedProps<TChildProps, TDataName>
    >(AccountEditorProgramUpdatedDocument, {
        alias: "accountEditorProgramUpdated",
        ...operationOptions
    });
}

/**
 * __useAccountEditorProgramUpdatedSubscription__
 *
 * To run a query within a React component, call `useAccountEditorProgramUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountEditorProgramUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountEditorProgramUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountEditorProgramUpdatedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<GqlClientAccountEditorProgramUpdatedSubscription, GqlClientAccountEditorProgramUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountEditorProgramUpdatedSubscription, GqlClientAccountEditorProgramUpdatedSubscriptionVariables>(AccountEditorProgramUpdatedDocument, options);
}
export type AccountEditorProgramUpdatedSubscriptionHookResult = ReturnType<typeof useAccountEditorProgramUpdatedSubscription>;
export type AccountEditorProgramUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountEditorProgramUpdatedSubscription>;
export const AccountFeaturesDataChangesChangedDocument = gql`
    subscription accountFeaturesDataChangesChanged($accountId: ID!) {
        accountFeaturesDataChanged(account: $accountId) {
            id
            accountFeatureData {
                id
                featureSource
                featureRestrictionValue
                featureCurrentUsageInfo
            }
        }
    }
`;
export type GqlClientAccountFeaturesDataChangesChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountFeaturesDataChangesChangedSubscription, GqlClientAccountFeaturesDataChangesChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountFeaturesDataChangesChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountFeaturesDataChangesChangedSubscription,
        GqlClientAccountFeaturesDataChangesChangedSubscriptionVariables,
        GqlClientAccountFeaturesDataChangesChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountFeaturesDataChangesChangedSubscription,
        GqlClientAccountFeaturesDataChangesChangedSubscriptionVariables,
        GqlClientAccountFeaturesDataChangesChangedProps<TChildProps, TDataName>
    >(AccountFeaturesDataChangesChangedDocument, {
        alias: "accountFeaturesDataChangesChanged",
        ...operationOptions
    });
}

/**
 * __useAccountFeaturesDataChangesChangedSubscription__
 *
 * To run a query within a React component, call `useAccountFeaturesDataChangesChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountFeaturesDataChangesChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountFeaturesDataChangesChangedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountFeaturesDataChangesChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAccountFeaturesDataChangesChangedSubscription, GqlClientAccountFeaturesDataChangesChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountFeaturesDataChangesChangedSubscription, GqlClientAccountFeaturesDataChangesChangedSubscriptionVariables>(
        AccountFeaturesDataChangesChangedDocument,
        options
    );
}
export type AccountFeaturesDataChangesChangedSubscriptionHookResult = ReturnType<typeof useAccountFeaturesDataChangesChangedSubscription>;
export type AccountFeaturesDataChangesChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountFeaturesDataChangesChangedSubscription>;
export const AccountFontLibraryChangedDocument = gql`
    subscription accountFontLibraryChanged {
        accountFontLibraryChanged {
            id
            fonts {
                ...EditorAssetLibraryFont
            }
        }
    }
    ${EditorAssetLibraryFontFragmentDoc}
`;
export type GqlClientAccountFontLibraryChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountFontLibraryChangedSubscription, GqlClientAccountFontLibraryChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountFontLibraryChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountFontLibraryChangedSubscription,
        GqlClientAccountFontLibraryChangedSubscriptionVariables,
        GqlClientAccountFontLibraryChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountFontLibraryChangedSubscription,
        GqlClientAccountFontLibraryChangedSubscriptionVariables,
        GqlClientAccountFontLibraryChangedProps<TChildProps, TDataName>
    >(AccountFontLibraryChangedDocument, {
        alias: "accountFontLibraryChanged",
        ...operationOptions
    });
}

/**
 * __useAccountFontLibraryChangedSubscription__
 *
 * To run a query within a React component, call `useAccountFontLibraryChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountFontLibraryChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountFontLibraryChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountFontLibraryChangedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<GqlClientAccountFontLibraryChangedSubscription, GqlClientAccountFontLibraryChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountFontLibraryChangedSubscription, GqlClientAccountFontLibraryChangedSubscriptionVariables>(AccountFontLibraryChangedDocument, options);
}
export type AccountFontLibraryChangedSubscriptionHookResult = ReturnType<typeof useAccountFontLibraryChangedSubscription>;
export type AccountFontLibraryChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountFontLibraryChangedSubscription>;
export const AccountMediaAssetLibraryChangedDocument = gql`
    subscription accountMediaAssetLibraryChanged {
        accountMediaAssetLibraryChanged {
            id
            media {
                ...EditorAssetLibraryMedia
            }
            mediaFolders {
                ...EditorAssetFolder
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
    ${EditorAssetFolderFragmentDoc}
`;
export type GqlClientAccountMediaAssetLibraryChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountMediaAssetLibraryChangedSubscription, GqlClientAccountMediaAssetLibraryChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountMediaAssetLibraryChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountMediaAssetLibraryChangedSubscription,
        GqlClientAccountMediaAssetLibraryChangedSubscriptionVariables,
        GqlClientAccountMediaAssetLibraryChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountMediaAssetLibraryChangedSubscription,
        GqlClientAccountMediaAssetLibraryChangedSubscriptionVariables,
        GqlClientAccountMediaAssetLibraryChangedProps<TChildProps, TDataName>
    >(AccountMediaAssetLibraryChangedDocument, {
        alias: "accountMediaAssetLibraryChanged",
        ...operationOptions
    });
}

/**
 * __useAccountMediaAssetLibraryChangedSubscription__
 *
 * To run a query within a React component, call `useAccountMediaAssetLibraryChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountMediaAssetLibraryChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMediaAssetLibraryChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountMediaAssetLibraryChangedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<GqlClientAccountMediaAssetLibraryChangedSubscription, GqlClientAccountMediaAssetLibraryChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountMediaAssetLibraryChangedSubscription, GqlClientAccountMediaAssetLibraryChangedSubscriptionVariables>(
        AccountMediaAssetLibraryChangedDocument,
        options
    );
}
export type AccountMediaAssetLibraryChangedSubscriptionHookResult = ReturnType<typeof useAccountMediaAssetLibraryChangedSubscription>;
export type AccountMediaAssetLibraryChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountMediaAssetLibraryChangedSubscription>;
export const AccountMusicLibraryChangedDocument = gql`
    subscription accountMusicLibraryChanged {
        accountMusicLibraryChanged {
            id
            music {
                ...EditorAssetLibraryMusic
            }
        }
    }
    ${EditorAssetLibraryMusicFragmentDoc}
`;
export type GqlClientAccountMusicLibraryChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountMusicLibraryChangedSubscription, GqlClientAccountMusicLibraryChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountMusicLibraryChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountMusicLibraryChangedSubscription,
        GqlClientAccountMusicLibraryChangedSubscriptionVariables,
        GqlClientAccountMusicLibraryChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountMusicLibraryChangedSubscription,
        GqlClientAccountMusicLibraryChangedSubscriptionVariables,
        GqlClientAccountMusicLibraryChangedProps<TChildProps, TDataName>
    >(AccountMusicLibraryChangedDocument, {
        alias: "accountMusicLibraryChanged",
        ...operationOptions
    });
}

/**
 * __useAccountMusicLibraryChangedSubscription__
 *
 * To run a query within a React component, call `useAccountMusicLibraryChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountMusicLibraryChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMusicLibraryChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountMusicLibraryChangedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<GqlClientAccountMusicLibraryChangedSubscription, GqlClientAccountMusicLibraryChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountMusicLibraryChangedSubscription, GqlClientAccountMusicLibraryChangedSubscriptionVariables>(AccountMusicLibraryChangedDocument, options);
}
export type AccountMusicLibraryChangedSubscriptionHookResult = ReturnType<typeof useAccountMusicLibraryChangedSubscription>;
export type AccountMusicLibraryChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountMusicLibraryChangedSubscription>;
export const AccountProgramFolderChangedDocument = gql`
    subscription accountProgramFolderChanged {
        accountProgramFolderChanged {
            id
            name
            parentFolder {
                id
            }
        }
    }
`;
export type GqlClientAccountProgramFolderChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountProgramFolderChangedSubscription, GqlClientAccountProgramFolderChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountProgramFolderChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountProgramFolderChangedSubscription,
        GqlClientAccountProgramFolderChangedSubscriptionVariables,
        GqlClientAccountProgramFolderChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountProgramFolderChangedSubscription,
        GqlClientAccountProgramFolderChangedSubscriptionVariables,
        GqlClientAccountProgramFolderChangedProps<TChildProps, TDataName>
    >(AccountProgramFolderChangedDocument, {
        alias: "accountProgramFolderChanged",
        ...operationOptions
    });
}

/**
 * __useAccountProgramFolderChangedSubscription__
 *
 * To run a query within a React component, call `useAccountProgramFolderChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountProgramFolderChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountProgramFolderChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountProgramFolderChangedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<GqlClientAccountProgramFolderChangedSubscription, GqlClientAccountProgramFolderChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountProgramFolderChangedSubscription, GqlClientAccountProgramFolderChangedSubscriptionVariables>(AccountProgramFolderChangedDocument, options);
}
export type AccountProgramFolderChangedSubscriptionHookResult = ReturnType<typeof useAccountProgramFolderChangedSubscription>;
export type AccountProgramFolderChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountProgramFolderChangedSubscription>;
export const AccountProgramFolderDeletedDocument = gql`
    subscription accountProgramFolderDeleted {
        accountProgramFolderDeleted {
            id
            editorPrograms {
                id
                folder {
                    id
                }
            }
            folders {
                id
                parentFolder {
                    id
                }
            }
        }
    }
`;
export type GqlClientAccountProgramFolderDeletedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountProgramFolderDeletedSubscription, GqlClientAccountProgramFolderDeletedSubscriptionVariables>;
} & TChildProps;
export function withAccountProgramFolderDeleted<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountProgramFolderDeletedSubscription,
        GqlClientAccountProgramFolderDeletedSubscriptionVariables,
        GqlClientAccountProgramFolderDeletedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountProgramFolderDeletedSubscription,
        GqlClientAccountProgramFolderDeletedSubscriptionVariables,
        GqlClientAccountProgramFolderDeletedProps<TChildProps, TDataName>
    >(AccountProgramFolderDeletedDocument, {
        alias: "accountProgramFolderDeleted",
        ...operationOptions
    });
}

/**
 * __useAccountProgramFolderDeletedSubscription__
 *
 * To run a query within a React component, call `useAccountProgramFolderDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountProgramFolderDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountProgramFolderDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountProgramFolderDeletedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<GqlClientAccountProgramFolderDeletedSubscription, GqlClientAccountProgramFolderDeletedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountProgramFolderDeletedSubscription, GqlClientAccountProgramFolderDeletedSubscriptionVariables>(AccountProgramFolderDeletedDocument, options);
}
export type AccountProgramFolderDeletedSubscriptionHookResult = ReturnType<typeof useAccountProgramFolderDeletedSubscription>;
export type AccountProgramFolderDeletedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountProgramFolderDeletedSubscription>;
export const AccountProgramFoldersCountChangedDocument = gql`
    subscription accountProgramFoldersCountChanged {
        accountProgramFoldersCountChanged {
            id
            folders {
                ...ProgramFolder
            }
        }
    }
    ${ProgramFolderFragmentDoc}
`;
export type GqlClientAccountProgramFoldersCountChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountProgramFoldersCountChangedSubscription, GqlClientAccountProgramFoldersCountChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountProgramFoldersCountChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountProgramFoldersCountChangedSubscription,
        GqlClientAccountProgramFoldersCountChangedSubscriptionVariables,
        GqlClientAccountProgramFoldersCountChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountProgramFoldersCountChangedSubscription,
        GqlClientAccountProgramFoldersCountChangedSubscriptionVariables,
        GqlClientAccountProgramFoldersCountChangedProps<TChildProps, TDataName>
    >(AccountProgramFoldersCountChangedDocument, {
        alias: "accountProgramFoldersCountChanged",
        ...operationOptions
    });
}

/**
 * __useAccountProgramFoldersCountChangedSubscription__
 *
 * To run a query within a React component, call `useAccountProgramFoldersCountChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountProgramFoldersCountChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountProgramFoldersCountChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountProgramFoldersCountChangedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<GqlClientAccountProgramFoldersCountChangedSubscription, GqlClientAccountProgramFoldersCountChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountProgramFoldersCountChangedSubscription, GqlClientAccountProgramFoldersCountChangedSubscriptionVariables>(
        AccountProgramFoldersCountChangedDocument,
        options
    );
}
export type AccountProgramFoldersCountChangedSubscriptionHookResult = ReturnType<typeof useAccountProgramFoldersCountChangedSubscription>;
export type AccountProgramFoldersCountChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountProgramFoldersCountChangedSubscription>;
export const AccountPronunciationDefinitionChangedDocument = gql`
    subscription accountPronunciationDefinitionChanged($accountId: ID!) {
        accountPronunciationDefinitionChanged(accountId: $accountId) {
            ...AccountPronunciationDefinition
            pronunciationLibraryVersion {
                id
                anyUpdated
                anyUpdatedBy
            }
        }
    }
    ${AccountPronunciationDefinitionFragmentDoc}
`;
export type GqlClientAccountPronunciationDefinitionChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountPronunciationDefinitionChangedSubscription, GqlClientAccountPronunciationDefinitionChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountPronunciationDefinitionChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountPronunciationDefinitionChangedSubscription,
        GqlClientAccountPronunciationDefinitionChangedSubscriptionVariables,
        GqlClientAccountPronunciationDefinitionChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountPronunciationDefinitionChangedSubscription,
        GqlClientAccountPronunciationDefinitionChangedSubscriptionVariables,
        GqlClientAccountPronunciationDefinitionChangedProps<TChildProps, TDataName>
    >(AccountPronunciationDefinitionChangedDocument, {
        alias: "accountPronunciationDefinitionChanged",
        ...operationOptions
    });
}

/**
 * __useAccountPronunciationDefinitionChangedSubscription__
 *
 * To run a query within a React component, call `useAccountPronunciationDefinitionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountPronunciationDefinitionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPronunciationDefinitionChangedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountPronunciationDefinitionChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAccountPronunciationDefinitionChangedSubscription, GqlClientAccountPronunciationDefinitionChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountPronunciationDefinitionChangedSubscription, GqlClientAccountPronunciationDefinitionChangedSubscriptionVariables>(
        AccountPronunciationDefinitionChangedDocument,
        options
    );
}
export type AccountPronunciationDefinitionChangedSubscriptionHookResult = ReturnType<typeof useAccountPronunciationDefinitionChangedSubscription>;
export type AccountPronunciationDefinitionChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountPronunciationDefinitionChangedSubscription>;
export const AccountPronunciationLibraryVersionChangedDocument = gql`
    subscription accountPronunciationLibraryVersionChanged($accountId: ID!) {
        accountPronunciationLibraryVersionChanged(accountId: $accountId) {
            id
            anyUpdatedBy
            anyUpdated
            definitions {
                ...AccountPronunciationDefinition
            }
        }
    }
    ${AccountPronunciationDefinitionFragmentDoc}
`;
export type GqlClientAccountPronunciationLibraryVersionChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountPronunciationLibraryVersionChangedSubscription, GqlClientAccountPronunciationLibraryVersionChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountPronunciationLibraryVersionChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountPronunciationLibraryVersionChangedSubscription,
        GqlClientAccountPronunciationLibraryVersionChangedSubscriptionVariables,
        GqlClientAccountPronunciationLibraryVersionChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountPronunciationLibraryVersionChangedSubscription,
        GqlClientAccountPronunciationLibraryVersionChangedSubscriptionVariables,
        GqlClientAccountPronunciationLibraryVersionChangedProps<TChildProps, TDataName>
    >(AccountPronunciationLibraryVersionChangedDocument, {
        alias: "accountPronunciationLibraryVersionChanged",
        ...operationOptions
    });
}

/**
 * __useAccountPronunciationLibraryVersionChangedSubscription__
 *
 * To run a query within a React component, call `useAccountPronunciationLibraryVersionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountPronunciationLibraryVersionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPronunciationLibraryVersionChangedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountPronunciationLibraryVersionChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAccountPronunciationLibraryVersionChangedSubscription, GqlClientAccountPronunciationLibraryVersionChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountPronunciationLibraryVersionChangedSubscription, GqlClientAccountPronunciationLibraryVersionChangedSubscriptionVariables>(
        AccountPronunciationLibraryVersionChangedDocument,
        options
    );
}
export type AccountPronunciationLibraryVersionChangedSubscriptionHookResult = ReturnType<typeof useAccountPronunciationLibraryVersionChangedSubscription>;
export type AccountPronunciationLibraryVersionChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountPronunciationLibraryVersionChangedSubscription>;
export const AccountServiceConnectionsChangedDocument = gql`
    subscription accountServiceConnectionsChanged {
        accountServiceConnectionsChanged {
            id
            accountServiceConnections {
                id
                serviceType
            }
        }
    }
`;
export type GqlClientAccountServiceConnectionsChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountServiceConnectionsChangedSubscription, GqlClientAccountServiceConnectionsChangedSubscriptionVariables>;
} & TChildProps;
export function withAccountServiceConnectionsChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountServiceConnectionsChangedSubscription,
        GqlClientAccountServiceConnectionsChangedSubscriptionVariables,
        GqlClientAccountServiceConnectionsChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAccountServiceConnectionsChangedSubscription,
        GqlClientAccountServiceConnectionsChangedSubscriptionVariables,
        GqlClientAccountServiceConnectionsChangedProps<TChildProps, TDataName>
    >(AccountServiceConnectionsChangedDocument, {
        alias: "accountServiceConnectionsChanged",
        ...operationOptions
    });
}

/**
 * __useAccountServiceConnectionsChangedSubscription__
 *
 * To run a query within a React component, call `useAccountServiceConnectionsChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountServiceConnectionsChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountServiceConnectionsChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountServiceConnectionsChangedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<GqlClientAccountServiceConnectionsChangedSubscription, GqlClientAccountServiceConnectionsChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAccountServiceConnectionsChangedSubscription, GqlClientAccountServiceConnectionsChangedSubscriptionVariables>(
        AccountServiceConnectionsChangedDocument,
        options
    );
}
export type AccountServiceConnectionsChangedSubscriptionHookResult = ReturnType<typeof useAccountServiceConnectionsChangedSubscription>;
export type AccountServiceConnectionsChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAccountServiceConnectionsChangedSubscription>;
export const AiPreviewProgramCreatedDocument = gql`
    subscription aiPreviewProgramCreated($sessionId: ID!) {
        aiPreviewProgramCreated(sessionId: $sessionId) {
            id
            status
            sessionId
            mutationId
            ... on AiPreviewProgramCreatedPayloadPending {
                progress
            }
            ... on AiPreviewProgramCreatedPayloadSuccess {
                data {
                    playerToken
                    dimensions {
                        width
                        height
                    }
                    previewId
                }
            }
        }
    }
`;
export type GqlClientAiPreviewProgramCreatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAiPreviewProgramCreatedSubscription, GqlClientAiPreviewProgramCreatedSubscriptionVariables>;
} & TChildProps;
export function withAiPreviewProgramCreated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAiPreviewProgramCreatedSubscription,
        GqlClientAiPreviewProgramCreatedSubscriptionVariables,
        GqlClientAiPreviewProgramCreatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAiPreviewProgramCreatedSubscription,
        GqlClientAiPreviewProgramCreatedSubscriptionVariables,
        GqlClientAiPreviewProgramCreatedProps<TChildProps, TDataName>
    >(AiPreviewProgramCreatedDocument, {
        alias: "aiPreviewProgramCreated",
        ...operationOptions
    });
}

/**
 * __useAiPreviewProgramCreatedSubscription__
 *
 * To run a query within a React component, call `useAiPreviewProgramCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAiPreviewProgramCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiPreviewProgramCreatedSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAiPreviewProgramCreatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAiPreviewProgramCreatedSubscription, GqlClientAiPreviewProgramCreatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAiPreviewProgramCreatedSubscription, GqlClientAiPreviewProgramCreatedSubscriptionVariables>(AiPreviewProgramCreatedDocument, options);
}
export type AiPreviewProgramCreatedSubscriptionHookResult = ReturnType<typeof useAiPreviewProgramCreatedSubscription>;
export type AiPreviewProgramCreatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAiPreviewProgramCreatedSubscription>;
export const AiVideoFromImageGeneratedDocument = gql`
    subscription AiVideoFromImageGenerated($mutationId: ID!) {
        aiVideoFromImageGenerated(mutationId: $mutationId) {
            mutationId
            status
            ... on AiVideoFromImageGeneratedPayloadSuccess {
                data {
                    video {
                        url
                    }
                }
            }
            ... on AiVideoFromImageGeneratedPayloadError {
                error
            }
            ... on AiVideoFromImageGeneratedPayloadPending {
                progress
            }
        }
    }
`;
export type GqlClientAiVideoFromImageGeneratedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAiVideoFromImageGeneratedSubscription, GqlClientAiVideoFromImageGeneratedSubscriptionVariables>;
} & TChildProps;
export function withAiVideoFromImageGenerated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAiVideoFromImageGeneratedSubscription,
        GqlClientAiVideoFromImageGeneratedSubscriptionVariables,
        GqlClientAiVideoFromImageGeneratedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientAiVideoFromImageGeneratedSubscription,
        GqlClientAiVideoFromImageGeneratedSubscriptionVariables,
        GqlClientAiVideoFromImageGeneratedProps<TChildProps, TDataName>
    >(AiVideoFromImageGeneratedDocument, {
        alias: "aiVideoFromImageGenerated",
        ...operationOptions
    });
}

/**
 * __useAiVideoFromImageGeneratedSubscription__
 *
 * To run a query within a React component, call `useAiVideoFromImageGeneratedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAiVideoFromImageGeneratedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiVideoFromImageGeneratedSubscription({
 *   variables: {
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useAiVideoFromImageGeneratedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientAiVideoFromImageGeneratedSubscription, GqlClientAiVideoFromImageGeneratedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientAiVideoFromImageGeneratedSubscription, GqlClientAiVideoFromImageGeneratedSubscriptionVariables>(AiVideoFromImageGeneratedDocument, options);
}
export type AiVideoFromImageGeneratedSubscriptionHookResult = ReturnType<typeof useAiVideoFromImageGeneratedSubscription>;
export type AiVideoFromImageGeneratedSubscriptionResult = Apollo.SubscriptionResult<GqlClientAiVideoFromImageGeneratedSubscription>;
export const BrandConfigurationCollectionChangedDocument = gql`
    subscription brandConfigurationCollectionChanged {
        brandConfigurationCollectionChanged {
            ...EditorBrand
        }
    }
    ${EditorBrandFragmentDoc}
`;
export type GqlClientBrandConfigurationCollectionChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientBrandConfigurationCollectionChangedSubscription, GqlClientBrandConfigurationCollectionChangedSubscriptionVariables>;
} & TChildProps;
export function withBrandConfigurationCollectionChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientBrandConfigurationCollectionChangedSubscription,
        GqlClientBrandConfigurationCollectionChangedSubscriptionVariables,
        GqlClientBrandConfigurationCollectionChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientBrandConfigurationCollectionChangedSubscription,
        GqlClientBrandConfigurationCollectionChangedSubscriptionVariables,
        GqlClientBrandConfigurationCollectionChangedProps<TChildProps, TDataName>
    >(BrandConfigurationCollectionChangedDocument, {
        alias: "brandConfigurationCollectionChanged",
        ...operationOptions
    });
}

/**
 * __useBrandConfigurationCollectionChangedSubscription__
 *
 * To run a query within a React component, call `useBrandConfigurationCollectionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBrandConfigurationCollectionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandConfigurationCollectionChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useBrandConfigurationCollectionChangedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<GqlClientBrandConfigurationCollectionChangedSubscription, GqlClientBrandConfigurationCollectionChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientBrandConfigurationCollectionChangedSubscription, GqlClientBrandConfigurationCollectionChangedSubscriptionVariables>(
        BrandConfigurationCollectionChangedDocument,
        options
    );
}
export type BrandConfigurationCollectionChangedSubscriptionHookResult = ReturnType<typeof useBrandConfigurationCollectionChangedSubscription>;
export type BrandConfigurationCollectionChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientBrandConfigurationCollectionChangedSubscription>;
export const BrandConfigurationVersionChangedDocument = gql`
    subscription brandConfigurationVersionChanged($accountId: ID!) {
        brandConfigurationVersionChanged(accountId: $accountId) {
            id
            brandConfigurationCollections {
                ...EditorBrand
            }
            defaultBrandConfigurationCollection {
                id
            }
        }
    }
    ${EditorBrandFragmentDoc}
`;
export type GqlClientBrandConfigurationVersionChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientBrandConfigurationVersionChangedSubscription, GqlClientBrandConfigurationVersionChangedSubscriptionVariables>;
} & TChildProps;
export function withBrandConfigurationVersionChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientBrandConfigurationVersionChangedSubscription,
        GqlClientBrandConfigurationVersionChangedSubscriptionVariables,
        GqlClientBrandConfigurationVersionChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientBrandConfigurationVersionChangedSubscription,
        GqlClientBrandConfigurationVersionChangedSubscriptionVariables,
        GqlClientBrandConfigurationVersionChangedProps<TChildProps, TDataName>
    >(BrandConfigurationVersionChangedDocument, {
        alias: "brandConfigurationVersionChanged",
        ...operationOptions
    });
}

/**
 * __useBrandConfigurationVersionChangedSubscription__
 *
 * To run a query within a React component, call `useBrandConfigurationVersionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBrandConfigurationVersionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandConfigurationVersionChangedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useBrandConfigurationVersionChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientBrandConfigurationVersionChangedSubscription, GqlClientBrandConfigurationVersionChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientBrandConfigurationVersionChangedSubscription, GqlClientBrandConfigurationVersionChangedSubscriptionVariables>(
        BrandConfigurationVersionChangedDocument,
        options
    );
}
export type BrandConfigurationVersionChangedSubscriptionHookResult = ReturnType<typeof useBrandConfigurationVersionChangedSubscription>;
export type BrandConfigurationVersionChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientBrandConfigurationVersionChangedSubscription>;
export const DataLibraryImportDataUpdatedDocument = gql`
    subscription dataLibraryImportDataUpdated($dataLibraryId: ID!) {
        dataLibraryImportDataUpdated(dataLibraryId: $dataLibraryId) {
            __typename
            dataLibraryId
            status
            mutationId
            ... on DataLibraryImportDataUpdatedPayloadSuccess {
                data {
                    numFailedUsers
                    numSuccessfulUsers
                }
            }
            ... on DataLibraryImportDataUpdatedPayloadError {
                error
            }
        }
    }
`;
export type GqlClientDataLibraryImportDataUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientDataLibraryImportDataUpdatedSubscription, GqlClientDataLibraryImportDataUpdatedSubscriptionVariables>;
} & TChildProps;
export function withDataLibraryImportDataUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDataLibraryImportDataUpdatedSubscription,
        GqlClientDataLibraryImportDataUpdatedSubscriptionVariables,
        GqlClientDataLibraryImportDataUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientDataLibraryImportDataUpdatedSubscription,
        GqlClientDataLibraryImportDataUpdatedSubscriptionVariables,
        GqlClientDataLibraryImportDataUpdatedProps<TChildProps, TDataName>
    >(DataLibraryImportDataUpdatedDocument, {
        alias: "dataLibraryImportDataUpdated",
        ...operationOptions
    });
}

/**
 * __useDataLibraryImportDataUpdatedSubscription__
 *
 * To run a query within a React component, call `useDataLibraryImportDataUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDataLibraryImportDataUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataLibraryImportDataUpdatedSubscription({
 *   variables: {
 *      dataLibraryId: // value for 'dataLibraryId'
 *   },
 * });
 */
export function useDataLibraryImportDataUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientDataLibraryImportDataUpdatedSubscription, GqlClientDataLibraryImportDataUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientDataLibraryImportDataUpdatedSubscription, GqlClientDataLibraryImportDataUpdatedSubscriptionVariables>(DataLibraryImportDataUpdatedDocument, options);
}
export type DataLibraryImportDataUpdatedSubscriptionHookResult = ReturnType<typeof useDataLibraryImportDataUpdatedSubscription>;
export type DataLibraryImportDataUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientDataLibraryImportDataUpdatedSubscription>;
export const DataLibraryImportHistoryChangedDocument = gql`
    subscription dataLibraryImportHistoryChanged($dataLibraryId: ID!) {
        dataLibraryImportHistoryChanged(dataLibraryId: $dataLibraryId) {
            id
            dataLibraryId
            importStatusMetadataItems {
                id
                status
                user
                fileName
                date
                countFailures
                countSuccesses
            }
        }
    }
`;
export type GqlClientDataLibraryImportHistoryChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientDataLibraryImportHistoryChangedSubscription, GqlClientDataLibraryImportHistoryChangedSubscriptionVariables>;
} & TChildProps;
export function withDataLibraryImportHistoryChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDataLibraryImportHistoryChangedSubscription,
        GqlClientDataLibraryImportHistoryChangedSubscriptionVariables,
        GqlClientDataLibraryImportHistoryChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientDataLibraryImportHistoryChangedSubscription,
        GqlClientDataLibraryImportHistoryChangedSubscriptionVariables,
        GqlClientDataLibraryImportHistoryChangedProps<TChildProps, TDataName>
    >(DataLibraryImportHistoryChangedDocument, {
        alias: "dataLibraryImportHistoryChanged",
        ...operationOptions
    });
}

/**
 * __useDataLibraryImportHistoryChangedSubscription__
 *
 * To run a query within a React component, call `useDataLibraryImportHistoryChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDataLibraryImportHistoryChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataLibraryImportHistoryChangedSubscription({
 *   variables: {
 *      dataLibraryId: // value for 'dataLibraryId'
 *   },
 * });
 */
export function useDataLibraryImportHistoryChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientDataLibraryImportHistoryChangedSubscription, GqlClientDataLibraryImportHistoryChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientDataLibraryImportHistoryChangedSubscription, GqlClientDataLibraryImportHistoryChangedSubscriptionVariables>(
        DataLibraryImportHistoryChangedDocument,
        options
    );
}
export type DataLibraryImportHistoryChangedSubscriptionHookResult = ReturnType<typeof useDataLibraryImportHistoryChangedSubscription>;
export type DataLibraryImportHistoryChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientDataLibraryImportHistoryChangedSubscription>;
export const EditorAssetEditedDocument = gql`
    subscription editorAssetEdited($accountId: ID!) {
        editorAssetEdited(accountId: $accountId) {
            id
            status
            mutationId
            originalName
            __typename
            ... on EditorAssetEditedPayloadPending {
                progress
            }
            ... on EditorAssetEditedPayloadSuccess {
                data {
                    editorAsset {
                        editorAssetLibraryVersion {
                            id
                            media {
                                id
                            }
                        }
                        ...EditorAssetLibraryMedia
                    }
                    editorPlaceholder {
                        ...EditorPlaceholderConfiguration
                    }
                }
            }
            ... on EditorAssetEditedPayloadError {
                error
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
    ${EditorPlaceholderConfigurationFragmentDoc}
`;
export type GqlClientEditorAssetEditedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorAssetEditedSubscription, GqlClientEditorAssetEditedSubscriptionVariables>;
} & TChildProps;
export function withEditorAssetEdited<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorAssetEditedSubscription,
        GqlClientEditorAssetEditedSubscriptionVariables,
        GqlClientEditorAssetEditedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<TProps, GqlClientEditorAssetEditedSubscription, GqlClientEditorAssetEditedSubscriptionVariables, GqlClientEditorAssetEditedProps<TChildProps, TDataName>>(
        EditorAssetEditedDocument,
        {
            alias: "editorAssetEdited",
            ...operationOptions
        }
    );
}

/**
 * __useEditorAssetEditedSubscription__
 *
 * To run a query within a React component, call `useEditorAssetEditedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEditorAssetEditedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorAssetEditedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useEditorAssetEditedSubscription(baseOptions: Apollo.SubscriptionHookOptions<GqlClientEditorAssetEditedSubscription, GqlClientEditorAssetEditedSubscriptionVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientEditorAssetEditedSubscription, GqlClientEditorAssetEditedSubscriptionVariables>(EditorAssetEditedDocument, options);
}
export type EditorAssetEditedSubscriptionHookResult = ReturnType<typeof useEditorAssetEditedSubscription>;
export type EditorAssetEditedSubscriptionResult = Apollo.SubscriptionResult<GqlClientEditorAssetEditedSubscription>;
export const EditorAssetMediaUploadedDocument = gql`
    subscription editorAssetMediaUploaded($accountId: ID!) {
        editorAssetMediaUploaded(accountId: $accountId) {
            id
            status
            fileName
            mutationId
            __typename
            ... on EditorAssetMediaUploadedPayloadPending {
                progress
            }
            ... on EditorAssetMediaUploadedPayloadSuccess {
                data {
                    editorAsset {
                        id
                        editorAssetLibraryVersion {
                            id
                            media {
                                id
                            }
                        }
                        ...EditorAssetLibraryMedia
                    }
                }
            }
            ... on EditorAssetMediaUploadedPayloadError {
                error
            }
        }
    }
    ${EditorAssetLibraryMediaFragmentDoc}
`;
export type GqlClientEditorAssetMediaUploadedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorAssetMediaUploadedSubscription, GqlClientEditorAssetMediaUploadedSubscriptionVariables>;
} & TChildProps;
export function withEditorAssetMediaUploaded<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorAssetMediaUploadedSubscription,
        GqlClientEditorAssetMediaUploadedSubscriptionVariables,
        GqlClientEditorAssetMediaUploadedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientEditorAssetMediaUploadedSubscription,
        GqlClientEditorAssetMediaUploadedSubscriptionVariables,
        GqlClientEditorAssetMediaUploadedProps<TChildProps, TDataName>
    >(EditorAssetMediaUploadedDocument, {
        alias: "editorAssetMediaUploaded",
        ...operationOptions
    });
}

/**
 * __useEditorAssetMediaUploadedSubscription__
 *
 * To run a query within a React component, call `useEditorAssetMediaUploadedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEditorAssetMediaUploadedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorAssetMediaUploadedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useEditorAssetMediaUploadedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientEditorAssetMediaUploadedSubscription, GqlClientEditorAssetMediaUploadedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientEditorAssetMediaUploadedSubscription, GqlClientEditorAssetMediaUploadedSubscriptionVariables>(EditorAssetMediaUploadedDocument, options);
}
export type EditorAssetMediaUploadedSubscriptionHookResult = ReturnType<typeof useEditorAssetMediaUploadedSubscription>;
export type EditorAssetMediaUploadedSubscriptionResult = Apollo.SubscriptionResult<GqlClientEditorAssetMediaUploadedSubscription>;
export const EditorProgramVersionLogicRemovedDocument = gql`
    subscription editorProgramVersionLogicRemoved($programVersionId: ID!) {
        editorProgramVersionLogicRemoved(programVersionId: $programVersionId) {
            ...EditorProgramVersionAllLogic
        }
    }
    ${EditorProgramVersionAllLogicFragmentDoc}
`;
export type GqlClientEditorProgramVersionLogicRemovedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientEditorProgramVersionLogicRemovedSubscription, GqlClientEditorProgramVersionLogicRemovedSubscriptionVariables>;
} & TChildProps;
export function withEditorProgramVersionLogicRemoved<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientEditorProgramVersionLogicRemovedSubscription,
        GqlClientEditorProgramVersionLogicRemovedSubscriptionVariables,
        GqlClientEditorProgramVersionLogicRemovedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientEditorProgramVersionLogicRemovedSubscription,
        GqlClientEditorProgramVersionLogicRemovedSubscriptionVariables,
        GqlClientEditorProgramVersionLogicRemovedProps<TChildProps, TDataName>
    >(EditorProgramVersionLogicRemovedDocument, {
        alias: "editorProgramVersionLogicRemoved",
        ...operationOptions
    });
}

/**
 * __useEditorProgramVersionLogicRemovedSubscription__
 *
 * To run a query within a React component, call `useEditorProgramVersionLogicRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEditorProgramVersionLogicRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorProgramVersionLogicRemovedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useEditorProgramVersionLogicRemovedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientEditorProgramVersionLogicRemovedSubscription, GqlClientEditorProgramVersionLogicRemovedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientEditorProgramVersionLogicRemovedSubscription, GqlClientEditorProgramVersionLogicRemovedSubscriptionVariables>(
        EditorProgramVersionLogicRemovedDocument,
        options
    );
}
export type EditorProgramVersionLogicRemovedSubscriptionHookResult = ReturnType<typeof useEditorProgramVersionLogicRemovedSubscription>;
export type EditorProgramVersionLogicRemovedSubscriptionResult = Apollo.SubscriptionResult<GqlClientEditorProgramVersionLogicRemovedSubscription>;
export const ProgramEditorPublishedDocument = gql`
    subscription programEditorPublished($programId: ID!) {
        programEditorPublished(programId: $programId) {
            id
            status
            userName
            snapshotName
            __typename
            mutationId
            ... on ProgramEditorPublishedPayloadPending {
                progress
            }
            ... on ProgramEditorPublishedPayloadSuccess {
                data {
                    editorProgram {
                        ...EditorProgram
                    }
                }
            }
            ... on ProgramEditorPublishedPayloadError {
                error
            }
        }
    }
    ${EditorProgramFragmentDoc}
`;
export type GqlClientProgramEditorPublishedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramEditorPublishedSubscription, GqlClientProgramEditorPublishedSubscriptionVariables>;
} & TChildProps;
export function withProgramEditorPublished<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramEditorPublishedSubscription,
        GqlClientProgramEditorPublishedSubscriptionVariables,
        GqlClientProgramEditorPublishedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramEditorPublishedSubscription,
        GqlClientProgramEditorPublishedSubscriptionVariables,
        GqlClientProgramEditorPublishedProps<TChildProps, TDataName>
    >(ProgramEditorPublishedDocument, {
        alias: "programEditorPublished",
        ...operationOptions
    });
}

/**
 * __useProgramEditorPublishedSubscription__
 *
 * To run a query within a React component, call `useProgramEditorPublishedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramEditorPublishedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramEditorPublishedSubscription({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useProgramEditorPublishedSubscription(baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramEditorPublishedSubscription, GqlClientProgramEditorPublishedSubscriptionVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramEditorPublishedSubscription, GqlClientProgramEditorPublishedSubscriptionVariables>(ProgramEditorPublishedDocument, options);
}
export type ProgramEditorPublishedSubscriptionHookResult = ReturnType<typeof useProgramEditorPublishedSubscription>;
export type ProgramEditorPublishedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramEditorPublishedSubscription>;
export const ProgramEmbedVideoCodeUpdatedDocument = gql`
    subscription programEmbedVideoCodeUpdated($programId: ID!) {
        programEmbedVideoCodeUpdated(programId: $programId) {
            id
            editorProgram {
                id
                embedVideoCode {
                    ...EmbedVideoCode
                }
            }
        }
    }
    ${EmbedVideoCodeFragmentDoc}
`;
export type GqlClientProgramEmbedVideoCodeUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramEmbedVideoCodeUpdatedSubscription, GqlClientProgramEmbedVideoCodeUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramEmbedVideoCodeUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramEmbedVideoCodeUpdatedSubscription,
        GqlClientProgramEmbedVideoCodeUpdatedSubscriptionVariables,
        GqlClientProgramEmbedVideoCodeUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramEmbedVideoCodeUpdatedSubscription,
        GqlClientProgramEmbedVideoCodeUpdatedSubscriptionVariables,
        GqlClientProgramEmbedVideoCodeUpdatedProps<TChildProps, TDataName>
    >(ProgramEmbedVideoCodeUpdatedDocument, {
        alias: "programEmbedVideoCodeUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramEmbedVideoCodeUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramEmbedVideoCodeUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramEmbedVideoCodeUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramEmbedVideoCodeUpdatedSubscription({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useProgramEmbedVideoCodeUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramEmbedVideoCodeUpdatedSubscription, GqlClientProgramEmbedVideoCodeUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramEmbedVideoCodeUpdatedSubscription, GqlClientProgramEmbedVideoCodeUpdatedSubscriptionVariables>(ProgramEmbedVideoCodeUpdatedDocument, options);
}
export type ProgramEmbedVideoCodeUpdatedSubscriptionHookResult = ReturnType<typeof useProgramEmbedVideoCodeUpdatedSubscription>;
export type ProgramEmbedVideoCodeUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramEmbedVideoCodeUpdatedSubscription>;
export const ProgramLandingPageUpdatedDocument = gql`
    subscription programLandingPageUpdated($programId: ID!) {
        programLandingPageUpdated(programId: $programId) {
            id
            editorProgram {
                id
                landingPage {
                    ...LandingPage
                }
            }
        }
    }
    ${LandingPageFragmentDoc}
`;
export type GqlClientProgramLandingPageUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramLandingPageUpdatedSubscription, GqlClientProgramLandingPageUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramLandingPageUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramLandingPageUpdatedSubscription,
        GqlClientProgramLandingPageUpdatedSubscriptionVariables,
        GqlClientProgramLandingPageUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramLandingPageUpdatedSubscription,
        GqlClientProgramLandingPageUpdatedSubscriptionVariables,
        GqlClientProgramLandingPageUpdatedProps<TChildProps, TDataName>
    >(ProgramLandingPageUpdatedDocument, {
        alias: "programLandingPageUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramLandingPageUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramLandingPageUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramLandingPageUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramLandingPageUpdatedSubscription({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useProgramLandingPageUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramLandingPageUpdatedSubscription, GqlClientProgramLandingPageUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramLandingPageUpdatedSubscription, GqlClientProgramLandingPageUpdatedSubscriptionVariables>(ProgramLandingPageUpdatedDocument, options);
}
export type ProgramLandingPageUpdatedSubscriptionHookResult = ReturnType<typeof useProgramLandingPageUpdatedSubscription>;
export type ProgramLandingPageUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramLandingPageUpdatedSubscription>;
export const ProgramReviewCreatedDocument = gql`
    subscription programReviewCreated($programId: ID!) {
        programReviewCreated(programId: $programId) {
            id
            programReviews {
                id
                reviewers
                created
                name
                createdBy
                reviewLink
                videos {
                    id
                    videoData
                    viewerProfileName
                    videoThumbnailUrl
                    aspectRatio
                }
                description
            }
        }
    }
`;
export type GqlClientProgramReviewCreatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramReviewCreatedSubscription, GqlClientProgramReviewCreatedSubscriptionVariables>;
} & TChildProps;
export function withProgramReviewCreated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramReviewCreatedSubscription,
        GqlClientProgramReviewCreatedSubscriptionVariables,
        GqlClientProgramReviewCreatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramReviewCreatedSubscription,
        GqlClientProgramReviewCreatedSubscriptionVariables,
        GqlClientProgramReviewCreatedProps<TChildProps, TDataName>
    >(ProgramReviewCreatedDocument, {
        alias: "programReviewCreated",
        ...operationOptions
    });
}

/**
 * __useProgramReviewCreatedSubscription__
 *
 * To run a query within a React component, call `useProgramReviewCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramReviewCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramReviewCreatedSubscription({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useProgramReviewCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramReviewCreatedSubscription, GqlClientProgramReviewCreatedSubscriptionVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramReviewCreatedSubscription, GqlClientProgramReviewCreatedSubscriptionVariables>(ProgramReviewCreatedDocument, options);
}
export type ProgramReviewCreatedSubscriptionHookResult = ReturnType<typeof useProgramReviewCreatedSubscription>;
export type ProgramReviewCreatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramReviewCreatedSubscription>;
export const ProgramShareHistoryRecordCreatedDocument = gql`
    subscription programShareHistoryRecordCreated($programId: ID!) {
        programShareHistoryRecordCreated(programId: $programId) {
            id
            shareHistory {
                ...EditorProgramShareHistory
            }
        }
    }
    ${EditorProgramShareHistoryFragmentDoc}
`;
export type GqlClientProgramShareHistoryRecordCreatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramShareHistoryRecordCreatedSubscription, GqlClientProgramShareHistoryRecordCreatedSubscriptionVariables>;
} & TChildProps;
export function withProgramShareHistoryRecordCreated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramShareHistoryRecordCreatedSubscription,
        GqlClientProgramShareHistoryRecordCreatedSubscriptionVariables,
        GqlClientProgramShareHistoryRecordCreatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramShareHistoryRecordCreatedSubscription,
        GqlClientProgramShareHistoryRecordCreatedSubscriptionVariables,
        GqlClientProgramShareHistoryRecordCreatedProps<TChildProps, TDataName>
    >(ProgramShareHistoryRecordCreatedDocument, {
        alias: "programShareHistoryRecordCreated",
        ...operationOptions
    });
}

/**
 * __useProgramShareHistoryRecordCreatedSubscription__
 *
 * To run a query within a React component, call `useProgramShareHistoryRecordCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramShareHistoryRecordCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramShareHistoryRecordCreatedSubscription({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useProgramShareHistoryRecordCreatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramShareHistoryRecordCreatedSubscription, GqlClientProgramShareHistoryRecordCreatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramShareHistoryRecordCreatedSubscription, GqlClientProgramShareHistoryRecordCreatedSubscriptionVariables>(
        ProgramShareHistoryRecordCreatedDocument,
        options
    );
}
export type ProgramShareHistoryRecordCreatedSubscriptionHookResult = ReturnType<typeof useProgramShareHistoryRecordCreatedSubscription>;
export type ProgramShareHistoryRecordCreatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramShareHistoryRecordCreatedSubscription>;
export const ProgramVersionAnimatedWireframesCompatabilityChangedDocument = gql`
    subscription programVersionAnimatedWireframesCompatabilityChanged($programVersionId: ID!) {
        programVersionAnimatedWireframesCompatabilityChanged(programVersionId: $programVersionId) {
            id
            compatibility
            scenes {
                id
                ccAnimatedWireframe {
                    id
                }
            }
        }
    }
`;
export type GqlClientProgramVersionAnimatedWireframesCompatabilityChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<
        GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscription,
        GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscriptionVariables
    >;
} & TChildProps;
export function withProgramVersionAnimatedWireframesCompatabilityChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscription,
        GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscriptionVariables,
        GqlClientProgramVersionAnimatedWireframesCompatabilityChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscription,
        GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscriptionVariables,
        GqlClientProgramVersionAnimatedWireframesCompatabilityChangedProps<TChildProps, TDataName>
    >(ProgramVersionAnimatedWireframesCompatabilityChangedDocument, {
        alias: "programVersionAnimatedWireframesCompatabilityChanged",
        ...operationOptions
    });
}

/**
 * __useProgramVersionAnimatedWireframesCompatabilityChangedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionAnimatedWireframesCompatabilityChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionAnimatedWireframesCompatabilityChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionAnimatedWireframesCompatabilityChangedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionAnimatedWireframesCompatabilityChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscription,
        GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscription, GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscriptionVariables>(
        ProgramVersionAnimatedWireframesCompatabilityChangedDocument,
        options
    );
}
export type ProgramVersionAnimatedWireframesCompatabilityChangedSubscriptionHookResult = ReturnType<typeof useProgramVersionAnimatedWireframesCompatabilityChangedSubscription>;
export type ProgramVersionAnimatedWireframesCompatabilityChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionAnimatedWireframesCompatabilityChangedSubscription>;
export const ProgramVersionEditorAudioSettingsUpdatedDocument = gql`
    subscription programVersionEditorAudioSettingsUpdated($programVersionId: ID!) {
        programVersionEditorAudioSettingsUpdated(programVersionId: $programVersionId) {
            id
            audioSettings
        }
    }
`;
export type GqlClientProgramVersionEditorAudioSettingsUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorAudioSettingsUpdatedSubscription, GqlClientProgramVersionEditorAudioSettingsUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorAudioSettingsUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorAudioSettingsUpdatedSubscription,
        GqlClientProgramVersionEditorAudioSettingsUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorAudioSettingsUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorAudioSettingsUpdatedSubscription,
        GqlClientProgramVersionEditorAudioSettingsUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorAudioSettingsUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorAudioSettingsUpdatedDocument, {
        alias: "programVersionEditorAudioSettingsUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorAudioSettingsUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorAudioSettingsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorAudioSettingsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorAudioSettingsUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorAudioSettingsUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorAudioSettingsUpdatedSubscription, GqlClientProgramVersionEditorAudioSettingsUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorAudioSettingsUpdatedSubscription, GqlClientProgramVersionEditorAudioSettingsUpdatedSubscriptionVariables>(
        ProgramVersionEditorAudioSettingsUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorAudioSettingsUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorAudioSettingsUpdatedSubscription>;
export type ProgramVersionEditorAudioSettingsUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorAudioSettingsUpdatedSubscription>;
export const ProgramVersionEditorAccountDataLibraryChangedDocument = gql`
    subscription programVersionEditorAccountDataLibraryChanged($programVersionId: ID!) {
        programVersionEditorAccountDataLibraryChanged(programVersionId: $programVersionId) {
            id
            accountDataLibraryVersion {
                ...AccountDataLibraryVersion
                accountDataLibrary {
                    id
                    draftAccountDataLibraryVersion {
                        ...AccountDataLibraryVersion
                    }
                }
            }
        }
    }
    ${AccountDataLibraryVersionFragmentDoc}
`;
export type GqlClientProgramVersionEditorAccountDataLibraryChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorAccountDataLibraryChangedSubscription, GqlClientProgramVersionEditorAccountDataLibraryChangedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorAccountDataLibraryChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorAccountDataLibraryChangedSubscription,
        GqlClientProgramVersionEditorAccountDataLibraryChangedSubscriptionVariables,
        GqlClientProgramVersionEditorAccountDataLibraryChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorAccountDataLibraryChangedSubscription,
        GqlClientProgramVersionEditorAccountDataLibraryChangedSubscriptionVariables,
        GqlClientProgramVersionEditorAccountDataLibraryChangedProps<TChildProps, TDataName>
    >(ProgramVersionEditorAccountDataLibraryChangedDocument, {
        alias: "programVersionEditorAccountDataLibraryChanged",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorAccountDataLibraryChangedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorAccountDataLibraryChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorAccountDataLibraryChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorAccountDataLibraryChangedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorAccountDataLibraryChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorAccountDataLibraryChangedSubscription, GqlClientProgramVersionEditorAccountDataLibraryChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorAccountDataLibraryChangedSubscription, GqlClientProgramVersionEditorAccountDataLibraryChangedSubscriptionVariables>(
        ProgramVersionEditorAccountDataLibraryChangedDocument,
        options
    );
}
export type ProgramVersionEditorAccountDataLibraryChangedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorAccountDataLibraryChangedSubscription>;
export type ProgramVersionEditorAccountDataLibraryChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorAccountDataLibraryChangedSubscription>;
export const ProgramVersionEditorAspectRatioUpdatedDocument = gql`
    subscription programVersionEditorAspectRatioUpdated($programVersionId: ID!) {
        programVersionEditorAspectRatioUpdated(programVersionId: $programVersionId) {
            id
            aspectRatio
            scenes {
                id
                ccAnimatedWireframe {
                    ...EditorAnimatedWireframe
                }
            }
        }
    }
    ${EditorAnimatedWireframeFragmentDoc}
`;
export type GqlClientProgramVersionEditorAspectRatioUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorAspectRatioUpdatedSubscription, GqlClientProgramVersionEditorAspectRatioUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorAspectRatioUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorAspectRatioUpdatedSubscription,
        GqlClientProgramVersionEditorAspectRatioUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorAspectRatioUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorAspectRatioUpdatedSubscription,
        GqlClientProgramVersionEditorAspectRatioUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorAspectRatioUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorAspectRatioUpdatedDocument, {
        alias: "programVersionEditorAspectRatioUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorAspectRatioUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorAspectRatioUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorAspectRatioUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorAspectRatioUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorAspectRatioUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorAspectRatioUpdatedSubscription, GqlClientProgramVersionEditorAspectRatioUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorAspectRatioUpdatedSubscription, GqlClientProgramVersionEditorAspectRatioUpdatedSubscriptionVariables>(
        ProgramVersionEditorAspectRatioUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorAspectRatioUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorAspectRatioUpdatedSubscription>;
export type ProgramVersionEditorAspectRatioUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorAspectRatioUpdatedSubscription>;
export const ProgramVersionEditorAvatarRenderingUpdatedDocument = gql`
    subscription programVersionEditorAvatarRenderingUpdated($programVersionId: ID!) {
        programVersionEditorAvatarRenderingUpdated(programVersionId: $programVersionId) {
            id
            isAvatarRendering
        }
    }
`;
export type GqlClientProgramVersionEditorAvatarRenderingUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscription, GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorAvatarRenderingUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscription,
        GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorAvatarRenderingUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscription,
        GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorAvatarRenderingUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorAvatarRenderingUpdatedDocument, {
        alias: "programVersionEditorAvatarRenderingUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorAvatarRenderingUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorAvatarRenderingUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorAvatarRenderingUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorAvatarRenderingUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorAvatarRenderingUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscription, GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscription, GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscriptionVariables>(
        ProgramVersionEditorAvatarRenderingUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorAvatarRenderingUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorAvatarRenderingUpdatedSubscription>;
export type ProgramVersionEditorAvatarRenderingUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorAvatarRenderingUpdatedSubscription>;
export const ProgramVersionEditorBrandRulesUpdatedDocument = gql`
    subscription programVersionEditorBrandRulesUpdated($programVersionId: ID!) {
        programVersionEditorBrandRulesUpdated(programVersionId: $programVersionId) {
            id
            bcUseRules
            bcRules
            selectedBrandConfiguration {
                id
            }
        }
    }
`;
export type GqlClientProgramVersionEditorBrandRulesUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorBrandRulesUpdatedSubscription, GqlClientProgramVersionEditorBrandRulesUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorBrandRulesUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorBrandRulesUpdatedSubscription,
        GqlClientProgramVersionEditorBrandRulesUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorBrandRulesUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorBrandRulesUpdatedSubscription,
        GqlClientProgramVersionEditorBrandRulesUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorBrandRulesUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorBrandRulesUpdatedDocument, {
        alias: "programVersionEditorBrandRulesUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorBrandRulesUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorBrandRulesUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorBrandRulesUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorBrandRulesUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorBrandRulesUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorBrandRulesUpdatedSubscription, GqlClientProgramVersionEditorBrandRulesUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorBrandRulesUpdatedSubscription, GqlClientProgramVersionEditorBrandRulesUpdatedSubscriptionVariables>(
        ProgramVersionEditorBrandRulesUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorBrandRulesUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorBrandRulesUpdatedSubscription>;
export type ProgramVersionEditorBrandRulesUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorBrandRulesUpdatedSubscription>;
export const ProgramVersionEditorChangedDocument = gql`
    subscription programVersionEditorChanged($programVersionId: ID!) {
        programVersionEditorChanged(programVersionId: $programVersionId) {
            anyUpdated
            anyUpdatedBy
            id
        }
    }
`;
export type GqlClientProgramVersionEditorChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorChangedSubscription, GqlClientProgramVersionEditorChangedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorChangedSubscription,
        GqlClientProgramVersionEditorChangedSubscriptionVariables,
        GqlClientProgramVersionEditorChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorChangedSubscription,
        GqlClientProgramVersionEditorChangedSubscriptionVariables,
        GqlClientProgramVersionEditorChangedProps<TChildProps, TDataName>
    >(ProgramVersionEditorChangedDocument, {
        alias: "programVersionEditorChanged",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorChangedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorChangedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorChangedSubscription, GqlClientProgramVersionEditorChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorChangedSubscription, GqlClientProgramVersionEditorChangedSubscriptionVariables>(ProgramVersionEditorChangedDocument, options);
}
export type ProgramVersionEditorChangedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorChangedSubscription>;
export type ProgramVersionEditorChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorChangedSubscription>;
export const ProgramVersionEditorCustomBackgroundUpdatedDocument = gql`
    subscription programVersionEditorCustomBackgroundUpdated($programVersionId: ID!) {
        programVersionEditorCustomBackgroundUpdated(programVersionId: $programVersionId) {
            id
            useCustomVideoBackground
            videoBackgroundMedia {
                id
            }
        }
    }
`;
export type GqlClientProgramVersionEditorCustomBackgroundUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscription, GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorCustomBackgroundUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscription,
        GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorCustomBackgroundUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscription,
        GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorCustomBackgroundUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorCustomBackgroundUpdatedDocument, {
        alias: "programVersionEditorCustomBackgroundUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorCustomBackgroundUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorCustomBackgroundUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorCustomBackgroundUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorCustomBackgroundUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorCustomBackgroundUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscription, GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscription, GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscriptionVariables>(
        ProgramVersionEditorCustomBackgroundUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorCustomBackgroundUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorCustomBackgroundUpdatedSubscription>;
export type ProgramVersionEditorCustomBackgroundUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorCustomBackgroundUpdatedSubscription>;
export const ProgramVersionEditorDataLibraryChangedDocument = gql`
    subscription programVersionEditorDataLibraryChanged($programVersionId: ID!) {
        programVersionEditorDataLibraryChanged(programVersionId: $programVersionId) {
            id
            studioElements {
                ...StudioElement
            }
        }
    }
    ${StudioElementFragmentDoc}
`;
export type GqlClientProgramVersionEditorDataLibraryChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorDataLibraryChangedSubscription, GqlClientProgramVersionEditorDataLibraryChangedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorDataLibraryChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorDataLibraryChangedSubscription,
        GqlClientProgramVersionEditorDataLibraryChangedSubscriptionVariables,
        GqlClientProgramVersionEditorDataLibraryChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorDataLibraryChangedSubscription,
        GqlClientProgramVersionEditorDataLibraryChangedSubscriptionVariables,
        GqlClientProgramVersionEditorDataLibraryChangedProps<TChildProps, TDataName>
    >(ProgramVersionEditorDataLibraryChangedDocument, {
        alias: "programVersionEditorDataLibraryChanged",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorDataLibraryChangedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorDataLibraryChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorDataLibraryChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorDataLibraryChangedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorDataLibraryChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorDataLibraryChangedSubscription, GqlClientProgramVersionEditorDataLibraryChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorDataLibraryChangedSubscription, GqlClientProgramVersionEditorDataLibraryChangedSubscriptionVariables>(
        ProgramVersionEditorDataLibraryChangedDocument,
        options
    );
}
export type ProgramVersionEditorDataLibraryChangedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorDataLibraryChangedSubscription>;
export type ProgramVersionEditorDataLibraryChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorDataLibraryChangedSubscription>;
export const ProgramVersionEditorLineupUpdatedDocument = gql`
    subscription programVersionEditorLineupUpdated($programVersionId: ID!) {
        programVersionEditorLineupUpdated(programVersionId: $programVersionId) {
            id
            sceneLineup
        }
    }
`;
export type GqlClientProgramVersionEditorLineupUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorLineupUpdatedSubscription, GqlClientProgramVersionEditorLineupUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorLineupUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorLineupUpdatedSubscription,
        GqlClientProgramVersionEditorLineupUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorLineupUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorLineupUpdatedSubscription,
        GqlClientProgramVersionEditorLineupUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorLineupUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorLineupUpdatedDocument, {
        alias: "programVersionEditorLineupUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorLineupUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorLineupUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorLineupUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorLineupUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorLineupUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorLineupUpdatedSubscription, GqlClientProgramVersionEditorLineupUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorLineupUpdatedSubscription, GqlClientProgramVersionEditorLineupUpdatedSubscriptionVariables>(
        ProgramVersionEditorLineupUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorLineupUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorLineupUpdatedSubscription>;
export type ProgramVersionEditorLineupUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorLineupUpdatedSubscription>;
export const ProgramVersionEditorPlaceholderUpdatedDocument = gql`
    subscription programVersionEditorPlaceholderUpdated($programVersionId: ID!) {
        programVersionEditorPlaceholderUpdated(programVersionId: $programVersionId) {
            ...EditorPlaceholderConfiguration
        }
    }
    ${EditorPlaceholderConfigurationFragmentDoc}
`;
export type GqlClientProgramVersionEditorPlaceholderUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorPlaceholderUpdatedSubscription, GqlClientProgramVersionEditorPlaceholderUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorPlaceholderUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorPlaceholderUpdatedSubscription,
        GqlClientProgramVersionEditorPlaceholderUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorPlaceholderUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorPlaceholderUpdatedSubscription,
        GqlClientProgramVersionEditorPlaceholderUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorPlaceholderUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorPlaceholderUpdatedDocument, {
        alias: "programVersionEditorPlaceholderUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorPlaceholderUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorPlaceholderUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorPlaceholderUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorPlaceholderUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorPlaceholderUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorPlaceholderUpdatedSubscription, GqlClientProgramVersionEditorPlaceholderUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorPlaceholderUpdatedSubscription, GqlClientProgramVersionEditorPlaceholderUpdatedSubscriptionVariables>(
        ProgramVersionEditorPlaceholderUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorPlaceholderUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorPlaceholderUpdatedSubscription>;
export type ProgramVersionEditorPlaceholderUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorPlaceholderUpdatedSubscription>;
export const ProgramVersionEditorSceneCreatedDocument = gql`
    subscription programVersionEditorSceneCreated($programVersionId: ID!) {
        programVersionEditorSceneCreated(programVersionId: $programVersionId) {
            ...EditorScene
            programVersion {
                id
                sceneLineup
                scenes {
                    id
                }
            }
        }
    }
    ${EditorSceneFragmentDoc}
`;
export type GqlClientProgramVersionEditorSceneCreatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorSceneCreatedSubscription, GqlClientProgramVersionEditorSceneCreatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorSceneCreated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorSceneCreatedSubscription,
        GqlClientProgramVersionEditorSceneCreatedSubscriptionVariables,
        GqlClientProgramVersionEditorSceneCreatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorSceneCreatedSubscription,
        GqlClientProgramVersionEditorSceneCreatedSubscriptionVariables,
        GqlClientProgramVersionEditorSceneCreatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorSceneCreatedDocument, {
        alias: "programVersionEditorSceneCreated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorSceneCreatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorSceneCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorSceneCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorSceneCreatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorSceneCreatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorSceneCreatedSubscription, GqlClientProgramVersionEditorSceneCreatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorSceneCreatedSubscription, GqlClientProgramVersionEditorSceneCreatedSubscriptionVariables>(
        ProgramVersionEditorSceneCreatedDocument,
        options
    );
}
export type ProgramVersionEditorSceneCreatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorSceneCreatedSubscription>;
export type ProgramVersionEditorSceneCreatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorSceneCreatedSubscription>;
export const ProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedDocument = gql`
    subscription programVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdated($programVersionId: ID!) {
        programVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdated(programVersionId: $programVersionId) {
            ...EditorScenePlaceholdersSelectedAnimatedWireframeAllWireframesMapping
        }
    }
    ${EditorScenePlaceholdersSelectedAnimatedWireframeAllWireframesMappingFragmentDoc}
`;
export type GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<
        GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription,
        GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscriptionVariables
    >;
} & TChildProps;
export function withProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription,
        GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription,
        GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedDocument, {
        alias: "programVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription,
        GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription,
        GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscriptionVariables
    >(ProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedDocument, options);
}
export type ProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscriptionHookResult = ReturnType<
    typeof useProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription
>;
export type ProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<GqlClientProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription>;
export const ProgramVersionEditorScenePlaceholdersUpdatedDocument = gql`
    subscription programVersionEditorScenePlaceholdersUpdated($programVersionId: ID!) {
        programVersionEditorScenePlaceholdersUpdated(programVersionId: $programVersionId) {
            id
            placeholders {
                ...EditorPlaceholderConfiguration
                ccPlaceholder {
                    id
                }
            }
        }
    }
    ${EditorPlaceholderConfigurationFragmentDoc}
`;
export type GqlClientProgramVersionEditorScenePlaceholdersUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscription, GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorScenePlaceholdersUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscription,
        GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorScenePlaceholdersUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscription,
        GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorScenePlaceholdersUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorScenePlaceholdersUpdatedDocument, {
        alias: "programVersionEditorScenePlaceholdersUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorScenePlaceholdersUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorScenePlaceholdersUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorScenePlaceholdersUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorScenePlaceholdersUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorScenePlaceholdersUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscription, GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscription, GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscriptionVariables>(
        ProgramVersionEditorScenePlaceholdersUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorScenePlaceholdersUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorScenePlaceholdersUpdatedSubscription>;
export type ProgramVersionEditorScenePlaceholdersUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorScenePlaceholdersUpdatedSubscription>;
export const ProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedDocument = gql`
    subscription programVersionEditorSceneSelectedAnimatedWireframeUpdated($programVersionId: ID!) {
        programVersionEditorSceneSelectedAnimatedWireframeUpdated(programVersionId: $programVersionId) {
            ...EditorSceneAnimatedWireframe
        }
    }
    ${EditorSceneAnimatedWireframeFragmentDoc}
`;
export type GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<
        GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription,
        GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscriptionVariables
    >;
} & TChildProps;
export function withProgramVersionEditorSceneSelectedAnimatedWireframeUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription,
        GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription,
        GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedDocument, {
        alias: "programVersionEditorSceneSelectedAnimatedWireframeUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription,
        GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription,
        GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscriptionVariables
    >(ProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedDocument, options);
}
export type ProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription>;
export type ProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription>;
export const ProgramVersionEditorSceneUpdatedDocument = gql`
    subscription programVersionEditorSceneUpdated($programVersionId: ID!) {
        programVersionEditorSceneUpdated(programVersionId: $programVersionId) {
            ...EditorSceneConfiguration
        }
    }
    ${EditorSceneConfigurationFragmentDoc}
`;
export type GqlClientProgramVersionEditorSceneUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorSceneUpdatedSubscription, GqlClientProgramVersionEditorSceneUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorSceneUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorSceneUpdatedSubscription,
        GqlClientProgramVersionEditorSceneUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorSceneUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorSceneUpdatedSubscription,
        GqlClientProgramVersionEditorSceneUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorSceneUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorSceneUpdatedDocument, {
        alias: "programVersionEditorSceneUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorSceneUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorSceneUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorSceneUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorSceneUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorSceneUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorSceneUpdatedSubscription, GqlClientProgramVersionEditorSceneUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorSceneUpdatedSubscription, GqlClientProgramVersionEditorSceneUpdatedSubscriptionVariables>(
        ProgramVersionEditorSceneUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorSceneUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorSceneUpdatedSubscription>;
export type ProgramVersionEditorSceneUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorSceneUpdatedSubscription>;
export const ProgramVersionEditorScenesCreatedDocument = gql`
    subscription programVersionEditorScenesCreated($programVersionId: ID!) {
        programVersionEditorScenesCreated(programVersionId: $programVersionId) {
            editorScenes {
                ...EditorScene
            }
            editorProgramVersion {
                id
                scenes {
                    id
                }
                sceneLineup
            }
        }
    }
    ${EditorSceneFragmentDoc}
`;
export type GqlClientProgramVersionEditorScenesCreatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorScenesCreatedSubscription, GqlClientProgramVersionEditorScenesCreatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorScenesCreated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorScenesCreatedSubscription,
        GqlClientProgramVersionEditorScenesCreatedSubscriptionVariables,
        GqlClientProgramVersionEditorScenesCreatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorScenesCreatedSubscription,
        GqlClientProgramVersionEditorScenesCreatedSubscriptionVariables,
        GqlClientProgramVersionEditorScenesCreatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorScenesCreatedDocument, {
        alias: "programVersionEditorScenesCreated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorScenesCreatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorScenesCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorScenesCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorScenesCreatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorScenesCreatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorScenesCreatedSubscription, GqlClientProgramVersionEditorScenesCreatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorScenesCreatedSubscription, GqlClientProgramVersionEditorScenesCreatedSubscriptionVariables>(
        ProgramVersionEditorScenesCreatedDocument,
        options
    );
}
export type ProgramVersionEditorScenesCreatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorScenesCreatedSubscription>;
export type ProgramVersionEditorScenesCreatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorScenesCreatedSubscription>;
export const ProgramVersionEditorScenesUpdatedDocument = gql`
    subscription programVersionEditorScenesUpdated($programVersionId: ID!) {
        programVersionEditorScenesUpdated(programVersionId: $programVersionId) {
            editorScenes {
                ...EditorScene
            }
            editorProgramVersion {
                id
                scenes {
                    id
                }
            }
        }
    }
    ${EditorSceneFragmentDoc}
`;
export type GqlClientProgramVersionEditorScenesUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorScenesUpdatedSubscription, GqlClientProgramVersionEditorScenesUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorScenesUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorScenesUpdatedSubscription,
        GqlClientProgramVersionEditorScenesUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorScenesUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorScenesUpdatedSubscription,
        GqlClientProgramVersionEditorScenesUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorScenesUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorScenesUpdatedDocument, {
        alias: "programVersionEditorScenesUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorScenesUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorScenesUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorScenesUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorScenesUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorScenesUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorScenesUpdatedSubscription, GqlClientProgramVersionEditorScenesUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorScenesUpdatedSubscription, GqlClientProgramVersionEditorScenesUpdatedSubscriptionVariables>(
        ProgramVersionEditorScenesUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorScenesUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorScenesUpdatedSubscription>;
export type ProgramVersionEditorScenesUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorScenesUpdatedSubscription>;
export const ProgramVersionEditorSelectedBcCollectionUpdatedDocument = gql`
    subscription programVersionEditorSelectedBcCollectionUpdated($programVersionId: ID!) {
        programVersionEditorSelectedBcCollectionUpdated(programVersionId: $programVersionId) {
            id
            selectedBrandConfiguration {
                id
                updated
                updatedBy
            }
        }
    }
`;
export type GqlClientProgramVersionEditorSelectedBcCollectionUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscription, GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorSelectedBcCollectionUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscription,
        GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorSelectedBcCollectionUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscription,
        GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorSelectedBcCollectionUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorSelectedBcCollectionUpdatedDocument, {
        alias: "programVersionEditorSelectedBcCollectionUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorSelectedBcCollectionUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorSelectedBcCollectionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorSelectedBcCollectionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorSelectedBcCollectionUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorSelectedBcCollectionUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscription, GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscription, GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscriptionVariables>(
        ProgramVersionEditorSelectedBcCollectionUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorSelectedBcCollectionUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorSelectedBcCollectionUpdatedSubscription>;
export type ProgramVersionEditorSelectedBcCollectionUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorSelectedBcCollectionUpdatedSubscription>;
export const ProgramVersionEditorSelectedMusicUpdatedDocument = gql`
    subscription programVersionEditorSelectedMusicUpdated($programVersionId: ID!) {
        programVersionEditorSelectedMusicUpdated(programVersionId: $programVersionId) {
            id
            ...EditorProgramSelectedMusic
        }
    }
    ${EditorProgramSelectedMusicFragmentDoc}
`;
export type GqlClientProgramVersionEditorSelectedMusicUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorSelectedMusicUpdatedSubscription, GqlClientProgramVersionEditorSelectedMusicUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorSelectedMusicUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorSelectedMusicUpdatedSubscription,
        GqlClientProgramVersionEditorSelectedMusicUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorSelectedMusicUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorSelectedMusicUpdatedSubscription,
        GqlClientProgramVersionEditorSelectedMusicUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorSelectedMusicUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorSelectedMusicUpdatedDocument, {
        alias: "programVersionEditorSelectedMusicUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorSelectedMusicUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorSelectedMusicUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorSelectedMusicUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorSelectedMusicUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorSelectedMusicUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorSelectedMusicUpdatedSubscription, GqlClientProgramVersionEditorSelectedMusicUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorSelectedMusicUpdatedSubscription, GqlClientProgramVersionEditorSelectedMusicUpdatedSubscriptionVariables>(
        ProgramVersionEditorSelectedMusicUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorSelectedMusicUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorSelectedMusicUpdatedSubscription>;
export type ProgramVersionEditorSelectedMusicUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorSelectedMusicUpdatedSubscription>;
export const ProgramVersionEditorSelectedNarratorUpdatedDocument = gql`
    subscription programVersionEditorSelectedNarratorUpdated($programVersionId: ID!) {
        programVersionEditorSelectedNarratorUpdated(programVersionId: $programVersionId) {
            id
            selectedNarrator {
                id
            }
        }
    }
`;
export type GqlClientProgramVersionEditorSelectedNarratorUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscription, GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorSelectedNarratorUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscription,
        GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorSelectedNarratorUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscription,
        GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorSelectedNarratorUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorSelectedNarratorUpdatedDocument, {
        alias: "programVersionEditorSelectedNarratorUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorSelectedNarratorUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorSelectedNarratorUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorSelectedNarratorUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorSelectedNarratorUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorSelectedNarratorUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscription, GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscription, GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscriptionVariables>(
        ProgramVersionEditorSelectedNarratorUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorSelectedNarratorUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorSelectedNarratorUpdatedSubscription>;
export type ProgramVersionEditorSelectedNarratorUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorSelectedNarratorUpdatedSubscription>;
export const ProgramVersionEditorThemeUpdatedDocument = gql`
    subscription programVersionEditorThemeUpdated($programVersionId: ID!) {
        programVersionEditorThemeUpdated(programVersionId: $programVersionId) {
            id
            ccTheme {
                id
            }
            scenes {
                id
                ccAnimatedWireframe {
                    id
                }
            }
        }
    }
`;
export type GqlClientProgramVersionEditorThemeUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionEditorThemeUpdatedSubscription, GqlClientProgramVersionEditorThemeUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionEditorThemeUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionEditorThemeUpdatedSubscription,
        GqlClientProgramVersionEditorThemeUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorThemeUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionEditorThemeUpdatedSubscription,
        GqlClientProgramVersionEditorThemeUpdatedSubscriptionVariables,
        GqlClientProgramVersionEditorThemeUpdatedProps<TChildProps, TDataName>
    >(ProgramVersionEditorThemeUpdatedDocument, {
        alias: "programVersionEditorThemeUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionEditorThemeUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionEditorThemeUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionEditorThemeUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionEditorThemeUpdatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionEditorThemeUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionEditorThemeUpdatedSubscription, GqlClientProgramVersionEditorThemeUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionEditorThemeUpdatedSubscription, GqlClientProgramVersionEditorThemeUpdatedSubscriptionVariables>(
        ProgramVersionEditorThemeUpdatedDocument,
        options
    );
}
export type ProgramVersionEditorThemeUpdatedSubscriptionHookResult = ReturnType<typeof useProgramVersionEditorThemeUpdatedSubscription>;
export type ProgramVersionEditorThemeUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionEditorThemeUpdatedSubscription>;
export const ProgramVersionNarrationRecordingStateCalculatedDocument = gql`
    subscription programVersionNarrationRecordingStateCalculated($programVersionId: ID!) {
        programVersionNarrationRecordingStateCalculated(programVersionId: $programVersionId) {
            id
            status
            __typename
            mutationId
            ... on ProgramVersionNarrationRecordingStateCalculatedPayloadSuccess {
                data {
                    narrationRecordingsState
                }
            }
            ... on ProgramVersionNarrationRecordingStateCalculatedPayloadError {
                error
            }
        }
    }
`;
export type GqlClientProgramVersionNarrationRecordingStateCalculatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionNarrationRecordingStateCalculatedSubscription, GqlClientProgramVersionNarrationRecordingStateCalculatedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionNarrationRecordingStateCalculated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionNarrationRecordingStateCalculatedSubscription,
        GqlClientProgramVersionNarrationRecordingStateCalculatedSubscriptionVariables,
        GqlClientProgramVersionNarrationRecordingStateCalculatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionNarrationRecordingStateCalculatedSubscription,
        GqlClientProgramVersionNarrationRecordingStateCalculatedSubscriptionVariables,
        GqlClientProgramVersionNarrationRecordingStateCalculatedProps<TChildProps, TDataName>
    >(ProgramVersionNarrationRecordingStateCalculatedDocument, {
        alias: "programVersionNarrationRecordingStateCalculated",
        ...operationOptions
    });
}

/**
 * __useProgramVersionNarrationRecordingStateCalculatedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionNarrationRecordingStateCalculatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionNarrationRecordingStateCalculatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionNarrationRecordingStateCalculatedSubscription({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useProgramVersionNarrationRecordingStateCalculatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionNarrationRecordingStateCalculatedSubscription, GqlClientProgramVersionNarrationRecordingStateCalculatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionNarrationRecordingStateCalculatedSubscription, GqlClientProgramVersionNarrationRecordingStateCalculatedSubscriptionVariables>(
        ProgramVersionNarrationRecordingStateCalculatedDocument,
        options
    );
}
export type ProgramVersionNarrationRecordingStateCalculatedSubscriptionHookResult = ReturnType<typeof useProgramVersionNarrationRecordingStateCalculatedSubscription>;
export type ProgramVersionNarrationRecordingStateCalculatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionNarrationRecordingStateCalculatedSubscription>;
export const ProgramVersionThumbnailChangedDocument = gql`
    subscription programVersionThumbnailChanged($accountId: String!) {
        programVersionThumbnailChanged(accountId: $accountId) {
            id
            thumbnail {
                url
            }
        }
    }
`;
export type GqlClientProgramVersionThumbnailChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionThumbnailChangedSubscription, GqlClientProgramVersionThumbnailChangedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionThumbnailChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionThumbnailChangedSubscription,
        GqlClientProgramVersionThumbnailChangedSubscriptionVariables,
        GqlClientProgramVersionThumbnailChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionThumbnailChangedSubscription,
        GqlClientProgramVersionThumbnailChangedSubscriptionVariables,
        GqlClientProgramVersionThumbnailChangedProps<TChildProps, TDataName>
    >(ProgramVersionThumbnailChangedDocument, {
        alias: "programVersionThumbnailChanged",
        ...operationOptions
    });
}

/**
 * __useProgramVersionThumbnailChangedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionThumbnailChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionThumbnailChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionThumbnailChangedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useProgramVersionThumbnailChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionThumbnailChangedSubscription, GqlClientProgramVersionThumbnailChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionThumbnailChangedSubscription, GqlClientProgramVersionThumbnailChangedSubscriptionVariables>(ProgramVersionThumbnailChangedDocument, options);
}
export type ProgramVersionThumbnailChangedSubscriptionHookResult = ReturnType<typeof useProgramVersionThumbnailChangedSubscription>;
export type ProgramVersionThumbnailChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionThumbnailChangedSubscription>;
export const ProgramVersionsAndBcCollectionsEditorChangedDocument = gql`
    subscription programVersionsAndBcCollectionsEditorChanged($accountId: ID!) {
        programVersionsAndBcCollectionsEditorChanged(accountId: $accountId) {
            brandConfigurationVersion {
                id
                brandConfigurationCollections {
                    id
                }
                defaultBrandConfigurationCollection {
                    id
                }
            }
            editorProgramVersions {
                id
                bcUseRules
                bcRules
                selectedBrandConfiguration {
                    id
                }
                updated
                updatedBy
                anyUpdated
                anyUpdatedBy
            }
        }
    }
`;
export type GqlClientProgramVersionsAndBcCollectionsEditorChangedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscription, GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscriptionVariables>;
} & TChildProps;
export function withProgramVersionsAndBcCollectionsEditorChanged<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscription,
        GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscriptionVariables,
        GqlClientProgramVersionsAndBcCollectionsEditorChangedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscription,
        GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscriptionVariables,
        GqlClientProgramVersionsAndBcCollectionsEditorChangedProps<TChildProps, TDataName>
    >(ProgramVersionsAndBcCollectionsEditorChangedDocument, {
        alias: "programVersionsAndBcCollectionsEditorChanged",
        ...operationOptions
    });
}

/**
 * __useProgramVersionsAndBcCollectionsEditorChangedSubscription__
 *
 * To run a query within a React component, call `useProgramVersionsAndBcCollectionsEditorChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionsAndBcCollectionsEditorChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionsAndBcCollectionsEditorChangedSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useProgramVersionsAndBcCollectionsEditorChangedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscription, GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscription, GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscriptionVariables>(
        ProgramVersionsAndBcCollectionsEditorChangedDocument,
        options
    );
}
export type ProgramVersionsAndBcCollectionsEditorChangedSubscriptionHookResult = ReturnType<typeof useProgramVersionsAndBcCollectionsEditorChangedSubscription>;
export type ProgramVersionsAndBcCollectionsEditorChangedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramVersionsAndBcCollectionsEditorChangedSubscription>;
export const ProgramViewerProfilesUpdatedDocument = gql`
    subscription programViewerProfilesUpdated($programId: ID!) {
        programViewerProfilesUpdated(programId: $programId) {
            id
            viewerProfiles {
                id
                ...EditorViewerProfile
            }
        }
    }
    ${EditorViewerProfileFragmentDoc}
`;
export type GqlClientProgramViewerProfilesUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramViewerProfilesUpdatedSubscription, GqlClientProgramViewerProfilesUpdatedSubscriptionVariables>;
} & TChildProps;
export function withProgramViewerProfilesUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramViewerProfilesUpdatedSubscription,
        GqlClientProgramViewerProfilesUpdatedSubscriptionVariables,
        GqlClientProgramViewerProfilesUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientProgramViewerProfilesUpdatedSubscription,
        GqlClientProgramViewerProfilesUpdatedSubscriptionVariables,
        GqlClientProgramViewerProfilesUpdatedProps<TChildProps, TDataName>
    >(ProgramViewerProfilesUpdatedDocument, {
        alias: "programViewerProfilesUpdated",
        ...operationOptions
    });
}

/**
 * __useProgramViewerProfilesUpdatedSubscription__
 *
 * To run a query within a React component, call `useProgramViewerProfilesUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProgramViewerProfilesUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramViewerProfilesUpdatedSubscription({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useProgramViewerProfilesUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientProgramViewerProfilesUpdatedSubscription, GqlClientProgramViewerProfilesUpdatedSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientProgramViewerProfilesUpdatedSubscription, GqlClientProgramViewerProfilesUpdatedSubscriptionVariables>(ProgramViewerProfilesUpdatedDocument, options);
}
export type ProgramViewerProfilesUpdatedSubscriptionHookResult = ReturnType<typeof useProgramViewerProfilesUpdatedSubscription>;
export type ProgramViewerProfilesUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientProgramViewerProfilesUpdatedSubscription>;
export const RecorderMediaUploadedToS3Document = gql`
    subscription recorderMediaUploadedToS3($mutationId: ID!) {
        recorderMediaUploadedToS3(mutationId: $mutationId) {
            status
            mutationId
            __typename
            ... on RecorderMediaUploadedToS3PayloadSuccess {
                data {
                    assetURL
                }
            }
            ... on RecorderMediaUploadedToS3PayloadError {
                error
            }
            ... on RecorderMediaUploadedToS3PayloadPending {
                progress
            }
        }
    }
`;
export type GqlClientRecorderMediaUploadedToS3Props<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientRecorderMediaUploadedToS3Subscription, GqlClientRecorderMediaUploadedToS3SubscriptionVariables>;
} & TChildProps;
export function withRecorderMediaUploadedToS3<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientRecorderMediaUploadedToS3Subscription,
        GqlClientRecorderMediaUploadedToS3SubscriptionVariables,
        GqlClientRecorderMediaUploadedToS3Props<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientRecorderMediaUploadedToS3Subscription,
        GqlClientRecorderMediaUploadedToS3SubscriptionVariables,
        GqlClientRecorderMediaUploadedToS3Props<TChildProps, TDataName>
    >(RecorderMediaUploadedToS3Document, {
        alias: "recorderMediaUploadedToS3",
        ...operationOptions
    });
}

/**
 * __useRecorderMediaUploadedToS3Subscription__
 *
 * To run a query within a React component, call `useRecorderMediaUploadedToS3Subscription` and pass it any options that fit your needs.
 * When your component renders, `useRecorderMediaUploadedToS3Subscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecorderMediaUploadedToS3Subscription({
 *   variables: {
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useRecorderMediaUploadedToS3Subscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientRecorderMediaUploadedToS3Subscription, GqlClientRecorderMediaUploadedToS3SubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientRecorderMediaUploadedToS3Subscription, GqlClientRecorderMediaUploadedToS3SubscriptionVariables>(RecorderMediaUploadedToS3Document, options);
}
export type RecorderMediaUploadedToS3SubscriptionHookResult = ReturnType<typeof useRecorderMediaUploadedToS3Subscription>;
export type RecorderMediaUploadedToS3SubscriptionResult = Apollo.SubscriptionResult<GqlClientRecorderMediaUploadedToS3Subscription>;
export const RequestedAvatarAnimationStatusDocument = gql`
    subscription requestedAvatarAnimationStatus($videoId: ID!) {
        requestedAvatarAnimationStatus(videoId: $videoId) {
            status
            ... on RequestedAvatarAnimationStatusPayloadPending {
                url
                progress
            }
            ... on RequestedAvatarAnimationStatusPayloadReady {
                url
            }
        }
    }
`;
export type GqlClientRequestedAvatarAnimationStatusProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientRequestedAvatarAnimationStatusSubscription, GqlClientRequestedAvatarAnimationStatusSubscriptionVariables>;
} & TChildProps;
export function withRequestedAvatarAnimationStatus<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientRequestedAvatarAnimationStatusSubscription,
        GqlClientRequestedAvatarAnimationStatusSubscriptionVariables,
        GqlClientRequestedAvatarAnimationStatusProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientRequestedAvatarAnimationStatusSubscription,
        GqlClientRequestedAvatarAnimationStatusSubscriptionVariables,
        GqlClientRequestedAvatarAnimationStatusProps<TChildProps, TDataName>
    >(RequestedAvatarAnimationStatusDocument, {
        alias: "requestedAvatarAnimationStatus",
        ...operationOptions
    });
}

/**
 * __useRequestedAvatarAnimationStatusSubscription__
 *
 * To run a query within a React component, call `useRequestedAvatarAnimationStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRequestedAvatarAnimationStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestedAvatarAnimationStatusSubscription({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useRequestedAvatarAnimationStatusSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<GqlClientRequestedAvatarAnimationStatusSubscription, GqlClientRequestedAvatarAnimationStatusSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientRequestedAvatarAnimationStatusSubscription, GqlClientRequestedAvatarAnimationStatusSubscriptionVariables>(RequestedAvatarAnimationStatusDocument, options);
}
export type RequestedAvatarAnimationStatusSubscriptionHookResult = ReturnType<typeof useRequestedAvatarAnimationStatusSubscription>;
export type RequestedAvatarAnimationStatusSubscriptionResult = Apollo.SubscriptionResult<GqlClientRequestedAvatarAnimationStatusSubscription>;
export const SceneFromPromptCreatedDocument = gql`
    subscription sceneFromPromptCreated($mutationId: ID!) {
        sceneFromPromptCreated(mutationId: $mutationId) {
            mutationId
            status
            __typename
            ... on SceneFromPromptCreatedPayloadSuccess {
                data {
                    editorScenes {
                        id
                        ...EditorScene
                    }
                }
            }
            ... on SceneFromPromptCreatedPayloadPending {
                progress
            }
            ... on SceneFromPromptCreatedPayloadError {
                error
            }
        }
    }
    ${EditorSceneFragmentDoc}
`;
export type GqlClientSceneFromPromptCreatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientSceneFromPromptCreatedSubscription, GqlClientSceneFromPromptCreatedSubscriptionVariables>;
} & TChildProps;
export function withSceneFromPromptCreated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientSceneFromPromptCreatedSubscription,
        GqlClientSceneFromPromptCreatedSubscriptionVariables,
        GqlClientSceneFromPromptCreatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<
        TProps,
        GqlClientSceneFromPromptCreatedSubscription,
        GqlClientSceneFromPromptCreatedSubscriptionVariables,
        GqlClientSceneFromPromptCreatedProps<TChildProps, TDataName>
    >(SceneFromPromptCreatedDocument, {
        alias: "sceneFromPromptCreated",
        ...operationOptions
    });
}

/**
 * __useSceneFromPromptCreatedSubscription__
 *
 * To run a query within a React component, call `useSceneFromPromptCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSceneFromPromptCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSceneFromPromptCreatedSubscription({
 *   variables: {
 *      mutationId: // value for 'mutationId'
 *   },
 * });
 */
export function useSceneFromPromptCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<GqlClientSceneFromPromptCreatedSubscription, GqlClientSceneFromPromptCreatedSubscriptionVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientSceneFromPromptCreatedSubscription, GqlClientSceneFromPromptCreatedSubscriptionVariables>(SceneFromPromptCreatedDocument, options);
}
export type SceneFromPromptCreatedSubscriptionHookResult = ReturnType<typeof useSceneFromPromptCreatedSubscription>;
export type SceneFromPromptCreatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientSceneFromPromptCreatedSubscription>;
export const UndoStacksUpdatedDocument = gql`
    subscription undoStacksUpdated($userId: ID!) {
        undoStacksUpdated(userId: $userId) {
            ...UndoStacks
        }
    }
    ${UndoStacksFragmentDoc}
`;
export type GqlClientUndoStacksUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientUndoStacksUpdatedSubscription, GqlClientUndoStacksUpdatedSubscriptionVariables>;
} & TChildProps;
export function withUndoStacksUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUndoStacksUpdatedSubscription,
        GqlClientUndoStacksUpdatedSubscriptionVariables,
        GqlClientUndoStacksUpdatedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withSubscription<TProps, GqlClientUndoStacksUpdatedSubscription, GqlClientUndoStacksUpdatedSubscriptionVariables, GqlClientUndoStacksUpdatedProps<TChildProps, TDataName>>(
        UndoStacksUpdatedDocument,
        {
            alias: "undoStacksUpdated",
            ...operationOptions
        }
    );
}

/**
 * __useUndoStacksUpdatedSubscription__
 *
 * To run a query within a React component, call `useUndoStacksUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUndoStacksUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUndoStacksUpdatedSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUndoStacksUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<GqlClientUndoStacksUpdatedSubscription, GqlClientUndoStacksUpdatedSubscriptionVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<GqlClientUndoStacksUpdatedSubscription, GqlClientUndoStacksUpdatedSubscriptionVariables>(UndoStacksUpdatedDocument, options);
}
export type UndoStacksUpdatedSubscriptionHookResult = ReturnType<typeof useUndoStacksUpdatedSubscription>;
export type UndoStacksUpdatedSubscriptionResult = Apollo.SubscriptionResult<GqlClientUndoStacksUpdatedSubscription>;
export const GetReportNameDocument = gql`
    query getReportName($reportId: ID!) {
        accountReport(reportId: $reportId) {
            id
            name
        }
    }
`;
export type GqlClientGetReportNameProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetReportNameQuery, GqlClientGetReportNameQueryVariables>;
} & TChildProps;
export function withGetReportName<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetReportNameQuery, GqlClientGetReportNameQueryVariables, GqlClientGetReportNameProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetReportNameQuery, GqlClientGetReportNameQueryVariables, GqlClientGetReportNameProps<TChildProps, TDataName>>(GetReportNameDocument, {
        alias: "getReportName",
        ...operationOptions
    });
}

/**
 * __useGetReportNameQuery__
 *
 * To run a query within a React component, call `useGetReportNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportNameQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetReportNameQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetReportNameQuery, GqlClientGetReportNameQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetReportNameQuery, GqlClientGetReportNameQueryVariables>(GetReportNameDocument, options);
}
export function useGetReportNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetReportNameQuery, GqlClientGetReportNameQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetReportNameQuery, GqlClientGetReportNameQueryVariables>(GetReportNameDocument, options);
}
export type GetReportNameQueryHookResult = ReturnType<typeof useGetReportNameQuery>;
export type GetReportNameLazyQueryHookResult = ReturnType<typeof useGetReportNameLazyQuery>;
export type GetReportNameQueryResult = Apollo.QueryResult<GqlClientGetReportNameQuery, GqlClientGetReportNameQueryVariables>;
export const GetVideoNameDocument = gql`
    query getVideoName($videoId: ID!) {
        editorProgram(id: $videoId) {
            id
            name
            folder {
                id
                name
            }
        }
    }
`;
export type GqlClientGetVideoNameProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetVideoNameQuery, GqlClientGetVideoNameQueryVariables>;
} & TChildProps;
export function withGetVideoName<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetVideoNameQuery, GqlClientGetVideoNameQueryVariables, GqlClientGetVideoNameProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetVideoNameQuery, GqlClientGetVideoNameQueryVariables, GqlClientGetVideoNameProps<TChildProps, TDataName>>(GetVideoNameDocument, {
        alias: "getVideoName",
        ...operationOptions
    });
}

/**
 * __useGetVideoNameQuery__
 *
 * To run a query within a React component, call `useGetVideoNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoNameQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useGetVideoNameQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetVideoNameQuery, GqlClientGetVideoNameQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetVideoNameQuery, GqlClientGetVideoNameQueryVariables>(GetVideoNameDocument, options);
}
export function useGetVideoNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetVideoNameQuery, GqlClientGetVideoNameQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetVideoNameQuery, GqlClientGetVideoNameQueryVariables>(GetVideoNameDocument, options);
}
export type GetVideoNameQueryHookResult = ReturnType<typeof useGetVideoNameQuery>;
export type GetVideoNameLazyQueryHookResult = ReturnType<typeof useGetVideoNameLazyQuery>;
export type GetVideoNameQueryResult = Apollo.QueryResult<GqlClientGetVideoNameQuery, GqlClientGetVideoNameQueryVariables>;
export const ApproveAccountInvitationDocument = gql`
    mutation approveAccountInvitation($input: ApproveAccountInvitationInput!) {
        approveAccountInvitation(input: $input) {
            accountUser {
                id
                invitationStatus
            }
            result
        }
    }
`;
export type GqlClientApproveAccountInvitationMutationFn = Apollo.MutationFunction<GqlClientApproveAccountInvitationMutation, GqlClientApproveAccountInvitationMutationVariables>;
export type GqlClientApproveAccountInvitationProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientApproveAccountInvitationMutation, GqlClientApproveAccountInvitationMutationVariables>;
} & TChildProps;
export function withApproveAccountInvitation<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientApproveAccountInvitationMutation,
        GqlClientApproveAccountInvitationMutationVariables,
        GqlClientApproveAccountInvitationProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientApproveAccountInvitationMutation,
        GqlClientApproveAccountInvitationMutationVariables,
        GqlClientApproveAccountInvitationProps<TChildProps, TDataName>
    >(ApproveAccountInvitationDocument, {
        alias: "approveAccountInvitation",
        ...operationOptions
    });
}

/**
 * __useApproveAccountInvitationMutation__
 *
 * To run a mutation, you first call `useApproveAccountInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAccountInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAccountInvitationMutation, { data, loading, error }] = useApproveAccountInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveAccountInvitationMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientApproveAccountInvitationMutation, GqlClientApproveAccountInvitationMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientApproveAccountInvitationMutation, GqlClientApproveAccountInvitationMutationVariables>(ApproveAccountInvitationDocument, options);
}
export type ApproveAccountInvitationMutationHookResult = ReturnType<typeof useApproveAccountInvitationMutation>;
export type ApproveAccountInvitationMutationResult = Apollo.MutationResult<GqlClientApproveAccountInvitationMutation>;
export type ApproveAccountInvitationMutationOptions = Apollo.BaseMutationOptions<GqlClientApproveAccountInvitationMutation, GqlClientApproveAccountInvitationMutationVariables>;
export const DeleteAccountInvitationDocument = gql`
    mutation deleteAccountInvitation($input: DeleteAccountInvitationInput!) {
        deleteAccountInvitation(input: $input) {
            result
            loggedInEditorAccountUser {
                id
                accounts {
                    id
                    invitationStatus
                }
            }
        }
    }
`;
export type GqlClientDeleteAccountInvitationMutationFn = Apollo.MutationFunction<GqlClientDeleteAccountInvitationMutation, GqlClientDeleteAccountInvitationMutationVariables>;
export type GqlClientDeleteAccountInvitationProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteAccountInvitationMutation, GqlClientDeleteAccountInvitationMutationVariables>;
} & TChildProps;
export function withDeleteAccountInvitation<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteAccountInvitationMutation,
        GqlClientDeleteAccountInvitationMutationVariables,
        GqlClientDeleteAccountInvitationProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteAccountInvitationMutation,
        GqlClientDeleteAccountInvitationMutationVariables,
        GqlClientDeleteAccountInvitationProps<TChildProps, TDataName>
    >(DeleteAccountInvitationDocument, {
        alias: "deleteAccountInvitation",
        ...operationOptions
    });
}

/**
 * __useDeleteAccountInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteAccountInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountInvitationMutation, { data, loading, error }] = useDeleteAccountInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountInvitationMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteAccountInvitationMutation, GqlClientDeleteAccountInvitationMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteAccountInvitationMutation, GqlClientDeleteAccountInvitationMutationVariables>(DeleteAccountInvitationDocument, options);
}
export type DeleteAccountInvitationMutationHookResult = ReturnType<typeof useDeleteAccountInvitationMutation>;
export type DeleteAccountInvitationMutationResult = Apollo.MutationResult<GqlClientDeleteAccountInvitationMutation>;
export type DeleteAccountInvitationMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteAccountInvitationMutation, GqlClientDeleteAccountInvitationMutationVariables>;
export const SaveUserRecentSearchDocument = gql`
    mutation saveUserRecentSearch($input: SaveUserRecentSearchInput!) {
        saveUserRecentSearch(input: $input) {
            result
            userSettings {
                id
                recentSearches
            }
        }
    }
`;
export type GqlClientSaveUserRecentSearchMutationFn = Apollo.MutationFunction<GqlClientSaveUserRecentSearchMutation, GqlClientSaveUserRecentSearchMutationVariables>;
export type GqlClientSaveUserRecentSearchProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientSaveUserRecentSearchMutation, GqlClientSaveUserRecentSearchMutationVariables>;
} & TChildProps;
export function withSaveUserRecentSearch<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientSaveUserRecentSearchMutation,
        GqlClientSaveUserRecentSearchMutationVariables,
        GqlClientSaveUserRecentSearchProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientSaveUserRecentSearchMutation, GqlClientSaveUserRecentSearchMutationVariables, GqlClientSaveUserRecentSearchProps<TChildProps, TDataName>>(
        SaveUserRecentSearchDocument,
        {
            alias: "saveUserRecentSearch",
            ...operationOptions
        }
    );
}

/**
 * __useSaveUserRecentSearchMutation__
 *
 * To run a mutation, you first call `useSaveUserRecentSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserRecentSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserRecentSearchMutation, { data, loading, error }] = useSaveUserRecentSearchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserRecentSearchMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientSaveUserRecentSearchMutation, GqlClientSaveUserRecentSearchMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientSaveUserRecentSearchMutation, GqlClientSaveUserRecentSearchMutationVariables>(SaveUserRecentSearchDocument, options);
}
export type SaveUserRecentSearchMutationHookResult = ReturnType<typeof useSaveUserRecentSearchMutation>;
export type SaveUserRecentSearchMutationResult = Apollo.MutationResult<GqlClientSaveUserRecentSearchMutation>;
export type SaveUserRecentSearchMutationOptions = Apollo.BaseMutationOptions<GqlClientSaveUserRecentSearchMutation, GqlClientSaveUserRecentSearchMutationVariables>;
export const UpdateAccountUserControlledFeatureDataDocument = gql`
    mutation updateAccountUserControlledFeatureData($input: UpdateAccountUserControlledFeatureDataInput!) {
        updateAccountUserControlledFeatureData(input: $input) {
            result
            features {
                ...AccountFeatureData
            }
        }
    }
    ${AccountFeatureDataFragmentDoc}
`;
export type GqlClientUpdateAccountUserControlledFeatureDataMutationFn = Apollo.MutationFunction<
    GqlClientUpdateAccountUserControlledFeatureDataMutation,
    GqlClientUpdateAccountUserControlledFeatureDataMutationVariables
>;
export type GqlClientUpdateAccountUserControlledFeatureDataProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateAccountUserControlledFeatureDataMutation, GqlClientUpdateAccountUserControlledFeatureDataMutationVariables>;
} & TChildProps;
export function withUpdateAccountUserControlledFeatureData<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateAccountUserControlledFeatureDataMutation,
        GqlClientUpdateAccountUserControlledFeatureDataMutationVariables,
        GqlClientUpdateAccountUserControlledFeatureDataProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateAccountUserControlledFeatureDataMutation,
        GqlClientUpdateAccountUserControlledFeatureDataMutationVariables,
        GqlClientUpdateAccountUserControlledFeatureDataProps<TChildProps, TDataName>
    >(UpdateAccountUserControlledFeatureDataDocument, {
        alias: "updateAccountUserControlledFeatureData",
        ...operationOptions
    });
}

/**
 * __useUpdateAccountUserControlledFeatureDataMutation__
 *
 * To run a mutation, you first call `useUpdateAccountUserControlledFeatureDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountUserControlledFeatureDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountUserControlledFeatureDataMutation, { data, loading, error }] = useUpdateAccountUserControlledFeatureDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountUserControlledFeatureDataMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateAccountUserControlledFeatureDataMutation, GqlClientUpdateAccountUserControlledFeatureDataMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateAccountUserControlledFeatureDataMutation, GqlClientUpdateAccountUserControlledFeatureDataMutationVariables>(
        UpdateAccountUserControlledFeatureDataDocument,
        options
    );
}
export type UpdateAccountUserControlledFeatureDataMutationHookResult = ReturnType<typeof useUpdateAccountUserControlledFeatureDataMutation>;
export type UpdateAccountUserControlledFeatureDataMutationResult = Apollo.MutationResult<GqlClientUpdateAccountUserControlledFeatureDataMutation>;
export type UpdateAccountUserControlledFeatureDataMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateAccountUserControlledFeatureDataMutation,
    GqlClientUpdateAccountUserControlledFeatureDataMutationVariables
>;
export const UpdateAllAccountEditorProgramVersionThumbnailIfNeededDocument = gql`
    mutation updateAllAccountEditorProgramVersionThumbnailIfNeeded {
        updateAllAccountEditorProgramVersionThumbnailIfNeeded {
            result
        }
    }
`;
export type GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutationFn = Apollo.MutationFunction<
    GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation,
    GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutationVariables
>;
export type GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<
        GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation,
        GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutationVariables
    >;
} & TChildProps;
export function withUpdateAllAccountEditorProgramVersionThumbnailIfNeeded<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation,
        GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutationVariables,
        GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation,
        GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutationVariables,
        GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededProps<TChildProps, TDataName>
    >(UpdateAllAccountEditorProgramVersionThumbnailIfNeededDocument, {
        alias: "updateAllAccountEditorProgramVersionThumbnailIfNeeded",
        ...operationOptions
    });
}

/**
 * __useUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation__
 *
 * To run a mutation, you first call `useUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllAccountEditorProgramVersionThumbnailIfNeededMutation, { data, loading, error }] = useUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation, GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation, GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutationVariables>(
        UpdateAllAccountEditorProgramVersionThumbnailIfNeededDocument,
        options
    );
}
export type UpdateAllAccountEditorProgramVersionThumbnailIfNeededMutationHookResult = ReturnType<typeof useUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation>;
export type UpdateAllAccountEditorProgramVersionThumbnailIfNeededMutationResult = Apollo.MutationResult<GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation>;
export type UpdateAllAccountEditorProgramVersionThumbnailIfNeededMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutation,
    GqlClientUpdateAllAccountEditorProgramVersionThumbnailIfNeededMutationVariables
>;
export const UpdateEditorProgramVersionThumbnailIfNeededDocument = gql`
    mutation updateEditorProgramVersionThumbnailIfNeeded($input: UpdateEditorProgramVersionThumbnailIfNeededInput!) {
        updateEditorProgramVersionThumbnailIfNeeded(input: $input) {
            result
        }
    }
`;
export type GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutationFn = Apollo.MutationFunction<
    GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutation,
    GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutationVariables
>;
export type GqlClientUpdateEditorProgramVersionThumbnailIfNeededProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutation, GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutationVariables>;
} & TChildProps;
export function withUpdateEditorProgramVersionThumbnailIfNeeded<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutation,
        GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutationVariables,
        GqlClientUpdateEditorProgramVersionThumbnailIfNeededProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutation,
        GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutationVariables,
        GqlClientUpdateEditorProgramVersionThumbnailIfNeededProps<TChildProps, TDataName>
    >(UpdateEditorProgramVersionThumbnailIfNeededDocument, {
        alias: "updateEditorProgramVersionThumbnailIfNeeded",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorProgramVersionThumbnailIfNeededMutation__
 *
 * To run a mutation, you first call `useUpdateEditorProgramVersionThumbnailIfNeededMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorProgramVersionThumbnailIfNeededMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorProgramVersionThumbnailIfNeededMutation, { data, loading, error }] = useUpdateEditorProgramVersionThumbnailIfNeededMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorProgramVersionThumbnailIfNeededMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutation, GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutation, GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutationVariables>(
        UpdateEditorProgramVersionThumbnailIfNeededDocument,
        options
    );
}
export type UpdateEditorProgramVersionThumbnailIfNeededMutationHookResult = ReturnType<typeof useUpdateEditorProgramVersionThumbnailIfNeededMutation>;
export type UpdateEditorProgramVersionThumbnailIfNeededMutationResult = Apollo.MutationResult<GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutation>;
export type UpdateEditorProgramVersionThumbnailIfNeededMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutation,
    GqlClientUpdateEditorProgramVersionThumbnailIfNeededMutationVariables
>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateEditorAccountUserInput!) {
        updateEditorAccountUser(input: $input) {
            result
            ... on UpdateEditorAccountUserOutputSuccess {
                accountUser {
                    id
                    roles
                    user {
                        id
                        email
                        roles
                        lastLogin
                        created
                    }
                }
            }
        }
    }
`;
export type GqlClientUpdateUserMutationFn = Apollo.MutationFunction<GqlClientUpdateUserMutation, GqlClientUpdateUserMutationVariables>;
export type GqlClientUpdateUserProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateUserMutation, GqlClientUpdateUserMutationVariables>;
} & TChildProps;
export function withUpdateUser<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateUserMutation, GqlClientUpdateUserMutationVariables, GqlClientUpdateUserProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateUserMutation, GqlClientUpdateUserMutationVariables, GqlClientUpdateUserProps<TChildProps, TDataName>>(UpdateUserDocument, {
        alias: "updateUser",
        ...operationOptions
    });
}

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateUserMutation, GqlClientUpdateUserMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateUserMutation, GqlClientUpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<GqlClientUpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateUserMutation, GqlClientUpdateUserMutationVariables>;
export const UpsertYourVideosUserSettingsDocument = gql`
    mutation upsertYourVideosUserSettings($upsertUserSettingsInput: UpsertUserSettingsInput!) {
        upsertUserSettings(input: $upsertUserSettingsInput) {
            result
            userSettings {
                id
                yourVideos
                videosArchive
            }
        }
    }
`;
export type GqlClientUpsertYourVideosUserSettingsMutationFn = Apollo.MutationFunction<GqlClientUpsertYourVideosUserSettingsMutation, GqlClientUpsertYourVideosUserSettingsMutationVariables>;
export type GqlClientUpsertYourVideosUserSettingsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpsertYourVideosUserSettingsMutation, GqlClientUpsertYourVideosUserSettingsMutationVariables>;
} & TChildProps;
export function withUpsertYourVideosUserSettings<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpsertYourVideosUserSettingsMutation,
        GqlClientUpsertYourVideosUserSettingsMutationVariables,
        GqlClientUpsertYourVideosUserSettingsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpsertYourVideosUserSettingsMutation,
        GqlClientUpsertYourVideosUserSettingsMutationVariables,
        GqlClientUpsertYourVideosUserSettingsProps<TChildProps, TDataName>
    >(UpsertYourVideosUserSettingsDocument, {
        alias: "upsertYourVideosUserSettings",
        ...operationOptions
    });
}

/**
 * __useUpsertYourVideosUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertYourVideosUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertYourVideosUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertYourVideosUserSettingsMutation, { data, loading, error }] = useUpsertYourVideosUserSettingsMutation({
 *   variables: {
 *      upsertUserSettingsInput: // value for 'upsertUserSettingsInput'
 *   },
 * });
 */
export function useUpsertYourVideosUserSettingsMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpsertYourVideosUserSettingsMutation, GqlClientUpsertYourVideosUserSettingsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpsertYourVideosUserSettingsMutation, GqlClientUpsertYourVideosUserSettingsMutationVariables>(UpsertYourVideosUserSettingsDocument, options);
}
export type UpsertYourVideosUserSettingsMutationHookResult = ReturnType<typeof useUpsertYourVideosUserSettingsMutation>;
export type UpsertYourVideosUserSettingsMutationResult = Apollo.MutationResult<GqlClientUpsertYourVideosUserSettingsMutation>;
export type UpsertYourVideosUserSettingsMutationOptions = Apollo.BaseMutationOptions<GqlClientUpsertYourVideosUserSettingsMutation, GqlClientUpsertYourVideosUserSettingsMutationVariables>;
export const AccountFeatureFullDataDocument = gql`
    query accountFeatureFullData {
        account {
            id
            accountFeatureData {
                ...AccountFeatureFullData
            }
        }
    }
    ${AccountFeatureFullDataFragmentDoc}
`;
export type GqlClientAccountFeatureFullDataProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountFeatureFullDataQuery, GqlClientAccountFeatureFullDataQueryVariables>;
} & TChildProps;
export function withAccountFeatureFullData<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountFeatureFullDataQuery,
        GqlClientAccountFeatureFullDataQueryVariables,
        GqlClientAccountFeatureFullDataProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientAccountFeatureFullDataQuery, GqlClientAccountFeatureFullDataQueryVariables, GqlClientAccountFeatureFullDataProps<TChildProps, TDataName>>(
        AccountFeatureFullDataDocument,
        {
            alias: "accountFeatureFullData",
            ...operationOptions
        }
    );
}

/**
 * __useAccountFeatureFullDataQuery__
 *
 * To run a query within a React component, call `useAccountFeatureFullDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountFeatureFullDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountFeatureFullDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountFeatureFullDataQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientAccountFeatureFullDataQuery, GqlClientAccountFeatureFullDataQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAccountFeatureFullDataQuery, GqlClientAccountFeatureFullDataQueryVariables>(AccountFeatureFullDataDocument, options);
}
export function useAccountFeatureFullDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAccountFeatureFullDataQuery, GqlClientAccountFeatureFullDataQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAccountFeatureFullDataQuery, GqlClientAccountFeatureFullDataQueryVariables>(AccountFeatureFullDataDocument, options);
}
export type AccountFeatureFullDataQueryHookResult = ReturnType<typeof useAccountFeatureFullDataQuery>;
export type AccountFeatureFullDataLazyQueryHookResult = ReturnType<typeof useAccountFeatureFullDataLazyQuery>;
export type AccountFeatureFullDataQueryResult = Apollo.QueryResult<GqlClientAccountFeatureFullDataQuery, GqlClientAccountFeatureFullDataQueryVariables>;
export const AccountVideosAndFoldersNamesDocument = gql`
    query accountVideosAndFoldersNames {
        account {
            id
            folders {
                id
                isRoot
                name
                parentFolder {
                    id
                }
            }
            builderPrograms {
                id
                name
                anyUpdated
                isArchive
                folder {
                    id
                }
            }
            editorPrograms {
                id
                name
                isArchive
                programVersionDraft {
                    id
                    anyUpdated
                }
                folder {
                    id
                }
            }
        }
    }
`;
export type GqlClientAccountVideosAndFoldersNamesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountVideosAndFoldersNamesQuery, GqlClientAccountVideosAndFoldersNamesQueryVariables>;
} & TChildProps;
export function withAccountVideosAndFoldersNames<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAccountVideosAndFoldersNamesQuery,
        GqlClientAccountVideosAndFoldersNamesQueryVariables,
        GqlClientAccountVideosAndFoldersNamesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientAccountVideosAndFoldersNamesQuery,
        GqlClientAccountVideosAndFoldersNamesQueryVariables,
        GqlClientAccountVideosAndFoldersNamesProps<TChildProps, TDataName>
    >(AccountVideosAndFoldersNamesDocument, {
        alias: "accountVideosAndFoldersNames",
        ...operationOptions
    });
}

/**
 * __useAccountVideosAndFoldersNamesQuery__
 *
 * To run a query within a React component, call `useAccountVideosAndFoldersNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountVideosAndFoldersNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountVideosAndFoldersNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountVideosAndFoldersNamesQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientAccountVideosAndFoldersNamesQuery, GqlClientAccountVideosAndFoldersNamesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAccountVideosAndFoldersNamesQuery, GqlClientAccountVideosAndFoldersNamesQueryVariables>(AccountVideosAndFoldersNamesDocument, options);
}
export function useAccountVideosAndFoldersNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAccountVideosAndFoldersNamesQuery, GqlClientAccountVideosAndFoldersNamesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAccountVideosAndFoldersNamesQuery, GqlClientAccountVideosAndFoldersNamesQueryVariables>(AccountVideosAndFoldersNamesDocument, options);
}
export type AccountVideosAndFoldersNamesQueryHookResult = ReturnType<typeof useAccountVideosAndFoldersNamesQuery>;
export type AccountVideosAndFoldersNamesLazyQueryHookResult = ReturnType<typeof useAccountVideosAndFoldersNamesLazyQuery>;
export type AccountVideosAndFoldersNamesQueryResult = Apollo.QueryResult<GqlClientAccountVideosAndFoldersNamesQuery, GqlClientAccountVideosAndFoldersNamesQueryVariables>;
export const DataLibraryInfoForDeleteVideoDocument = gql`
    query dataLibraryInfoForDeleteVideo($programId: ID!) {
        editorProgram(id: $programId) {
            id
            programVersionDraft {
                id
                accountDataLibraryVersion {
                    id
                    usedInVideos {
                        id
                    }
                    data
                    dataFields {
                        id
                        data
                    }
                    accountDataLibrary {
                        id
                        name
                        description
                    }
                }
            }
        }
    }
`;
export type GqlClientDataLibraryInfoForDeleteVideoProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientDataLibraryInfoForDeleteVideoQuery, GqlClientDataLibraryInfoForDeleteVideoQueryVariables>;
} & TChildProps;
export function withDataLibraryInfoForDeleteVideo<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDataLibraryInfoForDeleteVideoQuery,
        GqlClientDataLibraryInfoForDeleteVideoQueryVariables,
        GqlClientDataLibraryInfoForDeleteVideoProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientDataLibraryInfoForDeleteVideoQuery,
        GqlClientDataLibraryInfoForDeleteVideoQueryVariables,
        GqlClientDataLibraryInfoForDeleteVideoProps<TChildProps, TDataName>
    >(DataLibraryInfoForDeleteVideoDocument, {
        alias: "dataLibraryInfoForDeleteVideo",
        ...operationOptions
    });
}

/**
 * __useDataLibraryInfoForDeleteVideoQuery__
 *
 * To run a query within a React component, call `useDataLibraryInfoForDeleteVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataLibraryInfoForDeleteVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataLibraryInfoForDeleteVideoQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useDataLibraryInfoForDeleteVideoQuery(baseOptions: Apollo.QueryHookOptions<GqlClientDataLibraryInfoForDeleteVideoQuery, GqlClientDataLibraryInfoForDeleteVideoQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientDataLibraryInfoForDeleteVideoQuery, GqlClientDataLibraryInfoForDeleteVideoQueryVariables>(DataLibraryInfoForDeleteVideoDocument, options);
}
export function useDataLibraryInfoForDeleteVideoLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientDataLibraryInfoForDeleteVideoQuery, GqlClientDataLibraryInfoForDeleteVideoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientDataLibraryInfoForDeleteVideoQuery, GqlClientDataLibraryInfoForDeleteVideoQueryVariables>(DataLibraryInfoForDeleteVideoDocument, options);
}
export type DataLibraryInfoForDeleteVideoQueryHookResult = ReturnType<typeof useDataLibraryInfoForDeleteVideoQuery>;
export type DataLibraryInfoForDeleteVideoLazyQueryHookResult = ReturnType<typeof useDataLibraryInfoForDeleteVideoLazyQuery>;
export type DataLibraryInfoForDeleteVideoQueryResult = Apollo.QueryResult<GqlClientDataLibraryInfoForDeleteVideoQuery, GqlClientDataLibraryInfoForDeleteVideoQueryVariables>;
export const AccountsInvitationDocument = gql`
    query AccountsInvitation {
        loggedInEditorAccountUser {
            id
            accounts {
                id
                invitationStatus
                account {
                    id
                    displayName
                }
            }
        }
    }
`;
export type GqlClientAccountsInvitationProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientAccountsInvitationQuery, GqlClientAccountsInvitationQueryVariables>;
} & TChildProps;
export function withAccountsInvitation<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientAccountsInvitationQuery, GqlClientAccountsInvitationQueryVariables, GqlClientAccountsInvitationProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientAccountsInvitationQuery, GqlClientAccountsInvitationQueryVariables, GqlClientAccountsInvitationProps<TChildProps, TDataName>>(
        AccountsInvitationDocument,
        {
            alias: "accountsInvitation",
            ...operationOptions
        }
    );
}

/**
 * __useAccountsInvitationQuery__
 *
 * To run a query within a React component, call `useAccountsInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsInvitationQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsInvitationQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientAccountsInvitationQuery, GqlClientAccountsInvitationQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientAccountsInvitationQuery, GqlClientAccountsInvitationQueryVariables>(AccountsInvitationDocument, options);
}
export function useAccountsInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientAccountsInvitationQuery, GqlClientAccountsInvitationQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientAccountsInvitationQuery, GqlClientAccountsInvitationQueryVariables>(AccountsInvitationDocument, options);
}
export type AccountsInvitationQueryHookResult = ReturnType<typeof useAccountsInvitationQuery>;
export type AccountsInvitationLazyQueryHookResult = ReturnType<typeof useAccountsInvitationLazyQuery>;
export type AccountsInvitationQueryResult = Apollo.QueryResult<GqlClientAccountsInvitationQuery, GqlClientAccountsInvitationQueryVariables>;
export const GetAccountVideosNamesDocument = gql`
    query getAccountVideosNames {
        account {
            id
            builderPrograms {
                id
                name
            }
            editorPrograms {
                id
                name
            }
        }
    }
`;
export type GqlClientGetAccountVideosNamesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAccountVideosNamesQuery, GqlClientGetAccountVideosNamesQueryVariables>;
} & TChildProps;
export function withGetAccountVideosNames<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetAccountVideosNamesQuery,
        GqlClientGetAccountVideosNamesQueryVariables,
        GqlClientGetAccountVideosNamesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetAccountVideosNamesQuery, GqlClientGetAccountVideosNamesQueryVariables, GqlClientGetAccountVideosNamesProps<TChildProps, TDataName>>(
        GetAccountVideosNamesDocument,
        {
            alias: "getAccountVideosNames",
            ...operationOptions
        }
    );
}

/**
 * __useGetAccountVideosNamesQuery__
 *
 * To run a query within a React component, call `useGetAccountVideosNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountVideosNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountVideosNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountVideosNamesQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientGetAccountVideosNamesQuery, GqlClientGetAccountVideosNamesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAccountVideosNamesQuery, GqlClientGetAccountVideosNamesQueryVariables>(GetAccountVideosNamesDocument, options);
}
export function useGetAccountVideosNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAccountVideosNamesQuery, GqlClientGetAccountVideosNamesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAccountVideosNamesQuery, GqlClientGetAccountVideosNamesQueryVariables>(GetAccountVideosNamesDocument, options);
}
export type GetAccountVideosNamesQueryHookResult = ReturnType<typeof useGetAccountVideosNamesQuery>;
export type GetAccountVideosNamesLazyQueryHookResult = ReturnType<typeof useGetAccountVideosNamesLazyQuery>;
export type GetAccountVideosNamesQueryResult = Apollo.QueryResult<GqlClientGetAccountVideosNamesQuery, GqlClientGetAccountVideosNamesQueryVariables>;
export const InternalUserNameAndRolesDocument = gql`
    query InternalUserNameAndRoles($email: String!) {
        internalUser(email: $email) {
            id
            accounts {
                account {
                    id
                }
                id
                roles
            }
        }
    }
`;
export type GqlClientInternalUserNameAndRolesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientInternalUserNameAndRolesQuery, GqlClientInternalUserNameAndRolesQueryVariables>;
} & TChildProps;
export function withInternalUserNameAndRoles<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientInternalUserNameAndRolesQuery,
        GqlClientInternalUserNameAndRolesQueryVariables,
        GqlClientInternalUserNameAndRolesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientInternalUserNameAndRolesQuery, GqlClientInternalUserNameAndRolesQueryVariables, GqlClientInternalUserNameAndRolesProps<TChildProps, TDataName>>(
        InternalUserNameAndRolesDocument,
        {
            alias: "internalUserNameAndRoles",
            ...operationOptions
        }
    );
}

/**
 * __useInternalUserNameAndRolesQuery__
 *
 * To run a query within a React component, call `useInternalUserNameAndRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalUserNameAndRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalUserNameAndRolesQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInternalUserNameAndRolesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientInternalUserNameAndRolesQuery, GqlClientInternalUserNameAndRolesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientInternalUserNameAndRolesQuery, GqlClientInternalUserNameAndRolesQueryVariables>(InternalUserNameAndRolesDocument, options);
}
export function useInternalUserNameAndRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientInternalUserNameAndRolesQuery, GqlClientInternalUserNameAndRolesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientInternalUserNameAndRolesQuery, GqlClientInternalUserNameAndRolesQueryVariables>(InternalUserNameAndRolesDocument, options);
}
export type InternalUserNameAndRolesQueryHookResult = ReturnType<typeof useInternalUserNameAndRolesQuery>;
export type InternalUserNameAndRolesLazyQueryHookResult = ReturnType<typeof useInternalUserNameAndRolesLazyQuery>;
export type InternalUserNameAndRolesQueryResult = Apollo.QueryResult<GqlClientInternalUserNameAndRolesQuery, GqlClientInternalUserNameAndRolesQueryVariables>;
export const GetProgramReviewsVideoSummaryDocument = gql`
    query getProgramReviewsVideoSummary($id: ID!) {
        editorProgram(id: $id) {
            id
            programReviews {
                id
                reviewers
                created
                name
                createdBy
                reviewLink
                videos {
                    id
                    videoData
                    viewerProfileName
                    videoThumbnailUrl
                    aspectRatio
                }
                description
            }
        }
    }
`;
export type GqlClientGetProgramReviewsVideoSummaryProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetProgramReviewsVideoSummaryQuery, GqlClientGetProgramReviewsVideoSummaryQueryVariables>;
} & TChildProps;
export function withGetProgramReviewsVideoSummary<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetProgramReviewsVideoSummaryQuery,
        GqlClientGetProgramReviewsVideoSummaryQueryVariables,
        GqlClientGetProgramReviewsVideoSummaryProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientGetProgramReviewsVideoSummaryQuery,
        GqlClientGetProgramReviewsVideoSummaryQueryVariables,
        GqlClientGetProgramReviewsVideoSummaryProps<TChildProps, TDataName>
    >(GetProgramReviewsVideoSummaryDocument, {
        alias: "getProgramReviewsVideoSummary",
        ...operationOptions
    });
}

/**
 * __useGetProgramReviewsVideoSummaryQuery__
 *
 * To run a query within a React component, call `useGetProgramReviewsVideoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramReviewsVideoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramReviewsVideoSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProgramReviewsVideoSummaryQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetProgramReviewsVideoSummaryQuery, GqlClientGetProgramReviewsVideoSummaryQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetProgramReviewsVideoSummaryQuery, GqlClientGetProgramReviewsVideoSummaryQueryVariables>(GetProgramReviewsVideoSummaryDocument, options);
}
export function useGetProgramReviewsVideoSummaryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetProgramReviewsVideoSummaryQuery, GqlClientGetProgramReviewsVideoSummaryQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetProgramReviewsVideoSummaryQuery, GqlClientGetProgramReviewsVideoSummaryQueryVariables>(GetProgramReviewsVideoSummaryDocument, options);
}
export type GetProgramReviewsVideoSummaryQueryHookResult = ReturnType<typeof useGetProgramReviewsVideoSummaryQuery>;
export type GetProgramReviewsVideoSummaryLazyQueryHookResult = ReturnType<typeof useGetProgramReviewsVideoSummaryLazyQuery>;
export type GetProgramReviewsVideoSummaryQueryResult = Apollo.QueryResult<GqlClientGetProgramReviewsVideoSummaryQuery, GqlClientGetProgramReviewsVideoSummaryQueryVariables>;
export const PromptSuggestionsDocument = gql`
    query PromptSuggestions {
        promptSuggestions
    }
`;
export type GqlClientPromptSuggestionsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientPromptSuggestionsQuery, GqlClientPromptSuggestionsQueryVariables>;
} & TChildProps;
export function withPromptSuggestions<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientPromptSuggestionsQuery, GqlClientPromptSuggestionsQueryVariables, GqlClientPromptSuggestionsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientPromptSuggestionsQuery, GqlClientPromptSuggestionsQueryVariables, GqlClientPromptSuggestionsProps<TChildProps, TDataName>>(
        PromptSuggestionsDocument,
        {
            alias: "promptSuggestions",
            ...operationOptions
        }
    );
}

/**
 * __usePromptSuggestionsQuery__
 *
 * To run a query within a React component, call `usePromptSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromptSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromptSuggestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePromptSuggestionsQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientPromptSuggestionsQuery, GqlClientPromptSuggestionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientPromptSuggestionsQuery, GqlClientPromptSuggestionsQueryVariables>(PromptSuggestionsDocument, options);
}
export function usePromptSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientPromptSuggestionsQuery, GqlClientPromptSuggestionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientPromptSuggestionsQuery, GqlClientPromptSuggestionsQueryVariables>(PromptSuggestionsDocument, options);
}
export type PromptSuggestionsQueryHookResult = ReturnType<typeof usePromptSuggestionsQuery>;
export type PromptSuggestionsLazyQueryHookResult = ReturnType<typeof usePromptSuggestionsLazyQuery>;
export type PromptSuggestionsQueryResult = Apollo.QueryResult<GqlClientPromptSuggestionsQuery, GqlClientPromptSuggestionsQueryVariables>;
export const GetVideoIdListDocument = gql`
    query getVideoIdList {
        account {
            id
            builderPrograms {
                id
                isArchive
            }
            editorPrograms {
                id
                isArchive
            }
        }
    }
`;
export type GqlClientGetVideoIdListProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetVideoIdListQuery, GqlClientGetVideoIdListQueryVariables>;
} & TChildProps;
export function withGetVideoIdList<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetVideoIdListQuery, GqlClientGetVideoIdListQueryVariables, GqlClientGetVideoIdListProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetVideoIdListQuery, GqlClientGetVideoIdListQueryVariables, GqlClientGetVideoIdListProps<TChildProps, TDataName>>(GetVideoIdListDocument, {
        alias: "getVideoIdList",
        ...operationOptions
    });
}

/**
 * __useGetVideoIdListQuery__
 *
 * To run a query within a React component, call `useGetVideoIdListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoIdListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoIdListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVideoIdListQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientGetVideoIdListQuery, GqlClientGetVideoIdListQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetVideoIdListQuery, GqlClientGetVideoIdListQueryVariables>(GetVideoIdListDocument, options);
}
export function useGetVideoIdListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetVideoIdListQuery, GqlClientGetVideoIdListQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetVideoIdListQuery, GqlClientGetVideoIdListQueryVariables>(GetVideoIdListDocument, options);
}
export type GetVideoIdListQueryHookResult = ReturnType<typeof useGetVideoIdListQuery>;
export type GetVideoIdListLazyQueryHookResult = ReturnType<typeof useGetVideoIdListLazyQuery>;
export type GetVideoIdListQueryResult = Apollo.QueryResult<GqlClientGetVideoIdListQuery, GqlClientGetVideoIdListQueryVariables>;
export const GetVideoListDocument = gql`
    query getVideoList {
        account {
            id
            folders {
                ...ProgramFolder
            }
            builderPrograms {
                id
                name
                anyUpdated
                anyUpdatedBy
                created
                createdBy
                isArchive
                legacyBuilderProgramId
                lastArchived
                folder {
                    id
                    name
                }
            }
            editorPrograms {
                ...EditorProgramVideo
            }
        }
    }
    ${ProgramFolderFragmentDoc}
    ${EditorProgramVideoFragmentDoc}
`;
export type GqlClientGetVideoListProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetVideoListQuery, GqlClientGetVideoListQueryVariables>;
} & TChildProps;
export function withGetVideoList<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetVideoListQuery, GqlClientGetVideoListQueryVariables, GqlClientGetVideoListProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetVideoListQuery, GqlClientGetVideoListQueryVariables, GqlClientGetVideoListProps<TChildProps, TDataName>>(GetVideoListDocument, {
        alias: "getVideoList",
        ...operationOptions
    });
}

/**
 * __useGetVideoListQuery__
 *
 * To run a query within a React component, call `useGetVideoListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVideoListQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientGetVideoListQuery, GqlClientGetVideoListQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetVideoListQuery, GqlClientGetVideoListQueryVariables>(GetVideoListDocument, options);
}
export function useGetVideoListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetVideoListQuery, GqlClientGetVideoListQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetVideoListQuery, GqlClientGetVideoListQueryVariables>(GetVideoListDocument, options);
}
export type GetVideoListQueryHookResult = ReturnType<typeof useGetVideoListQuery>;
export type GetVideoListLazyQueryHookResult = ReturnType<typeof useGetVideoListLazyQuery>;
export type GetVideoListQueryResult = Apollo.QueryResult<GqlClientGetVideoListQuery, GqlClientGetVideoListQueryVariables>;
export const GetVideoSummaryDocument = gql`
    query getVideoSummary($id: ID!) {
        editorProgram(id: $id) {
            id
            name
            isArchive
            programVersionDraft {
                id
                sceneLineup
            }
            programReviews {
                id
                reviewers
                created
                name
                createdBy
                reviewLink
                videos {
                    id
                    videoData
                    viewerProfileName
                    videoThumbnailUrl
                    aspectRatio
                }
                description
            }
        }
    }
`;
export type GqlClientGetVideoSummaryProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetVideoSummaryQuery, GqlClientGetVideoSummaryQueryVariables>;
} & TChildProps;
export function withGetVideoSummary<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetVideoSummaryQuery, GqlClientGetVideoSummaryQueryVariables, GqlClientGetVideoSummaryProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetVideoSummaryQuery, GqlClientGetVideoSummaryQueryVariables, GqlClientGetVideoSummaryProps<TChildProps, TDataName>>(GetVideoSummaryDocument, {
        alias: "getVideoSummary",
        ...operationOptions
    });
}

/**
 * __useGetVideoSummaryQuery__
 *
 * To run a query within a React component, call `useGetVideoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVideoSummaryQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetVideoSummaryQuery, GqlClientGetVideoSummaryQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetVideoSummaryQuery, GqlClientGetVideoSummaryQueryVariables>(GetVideoSummaryDocument, options);
}
export function useGetVideoSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetVideoSummaryQuery, GqlClientGetVideoSummaryQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetVideoSummaryQuery, GqlClientGetVideoSummaryQueryVariables>(GetVideoSummaryDocument, options);
}
export type GetVideoSummaryQueryHookResult = ReturnType<typeof useGetVideoSummaryQuery>;
export type GetVideoSummaryLazyQueryHookResult = ReturnType<typeof useGetVideoSummaryLazyQuery>;
export type GetVideoSummaryQueryResult = Apollo.QueryResult<GqlClientGetVideoSummaryQuery, GqlClientGetVideoSummaryQueryVariables>;
export const GetVideoUsedGettyPremiumDocument = gql`
    query getVideoUsedGettyPremium($id: ID!) {
        editorProgram(id: $id) {
            id
            programVersionDraft {
                id
                usedPremiumGettyMediaData {
                    assetMedia {
                        id
                        name
                        mediaUrl
                        mediaPreviewUrl
                        description
                        mediaMetadata
                    }
                    usedLocations
                }
            }
        }
    }
`;
export type GqlClientGetVideoUsedGettyPremiumProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetVideoUsedGettyPremiumQuery, GqlClientGetVideoUsedGettyPremiumQueryVariables>;
} & TChildProps;
export function withGetVideoUsedGettyPremium<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetVideoUsedGettyPremiumQuery,
        GqlClientGetVideoUsedGettyPremiumQueryVariables,
        GqlClientGetVideoUsedGettyPremiumProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetVideoUsedGettyPremiumQuery, GqlClientGetVideoUsedGettyPremiumQueryVariables, GqlClientGetVideoUsedGettyPremiumProps<TChildProps, TDataName>>(
        GetVideoUsedGettyPremiumDocument,
        {
            alias: "getVideoUsedGettyPremium",
            ...operationOptions
        }
    );
}

/**
 * __useGetVideoUsedGettyPremiumQuery__
 *
 * To run a query within a React component, call `useGetVideoUsedGettyPremiumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoUsedGettyPremiumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoUsedGettyPremiumQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVideoUsedGettyPremiumQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetVideoUsedGettyPremiumQuery, GqlClientGetVideoUsedGettyPremiumQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetVideoUsedGettyPremiumQuery, GqlClientGetVideoUsedGettyPremiumQueryVariables>(GetVideoUsedGettyPremiumDocument, options);
}
export function useGetVideoUsedGettyPremiumLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetVideoUsedGettyPremiumQuery, GqlClientGetVideoUsedGettyPremiumQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetVideoUsedGettyPremiumQuery, GqlClientGetVideoUsedGettyPremiumQueryVariables>(GetVideoUsedGettyPremiumDocument, options);
}
export type GetVideoUsedGettyPremiumQueryHookResult = ReturnType<typeof useGetVideoUsedGettyPremiumQuery>;
export type GetVideoUsedGettyPremiumLazyQueryHookResult = ReturnType<typeof useGetVideoUsedGettyPremiumLazyQuery>;
export type GetVideoUsedGettyPremiumQueryResult = Apollo.QueryResult<GqlClientGetVideoUsedGettyPremiumQuery, GqlClientGetVideoUsedGettyPremiumQueryVariables>;
export const YourVideosUserSettingsDocument = gql`
    query yourVideosUserSettings($id: ID!) {
        userSettings(id: $id) {
            id
            yourVideos
            videosArchive
        }
    }
`;
export type GqlClientYourVideosUserSettingsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientYourVideosUserSettingsQuery, GqlClientYourVideosUserSettingsQueryVariables>;
} & TChildProps;
export function withYourVideosUserSettings<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientYourVideosUserSettingsQuery,
        GqlClientYourVideosUserSettingsQueryVariables,
        GqlClientYourVideosUserSettingsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientYourVideosUserSettingsQuery, GqlClientYourVideosUserSettingsQueryVariables, GqlClientYourVideosUserSettingsProps<TChildProps, TDataName>>(
        YourVideosUserSettingsDocument,
        {
            alias: "yourVideosUserSettings",
            ...operationOptions
        }
    );
}

/**
 * __useYourVideosUserSettingsQuery__
 *
 * To run a query within a React component, call `useYourVideosUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useYourVideosUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYourVideosUserSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useYourVideosUserSettingsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientYourVideosUserSettingsQuery, GqlClientYourVideosUserSettingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientYourVideosUserSettingsQuery, GqlClientYourVideosUserSettingsQueryVariables>(YourVideosUserSettingsDocument, options);
}
export function useYourVideosUserSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientYourVideosUserSettingsQuery, GqlClientYourVideosUserSettingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientYourVideosUserSettingsQuery, GqlClientYourVideosUserSettingsQueryVariables>(YourVideosUserSettingsDocument, options);
}
export type YourVideosUserSettingsQueryHookResult = ReturnType<typeof useYourVideosUserSettingsQuery>;
export type YourVideosUserSettingsLazyQueryHookResult = ReturnType<typeof useYourVideosUserSettingsLazyQuery>;
export type YourVideosUserSettingsQueryResult = Apollo.QueryResult<GqlClientYourVideosUserSettingsQuery, GqlClientYourVideosUserSettingsQueryVariables>;
export const ProgramReviewsSummaryForUserDocument = gql`
    query programReviewsSummaryForUser($programId: ID!) {
        programReviewsSummaryForUser(programId: $programId) {
            id
            programReviews {
                id
                reviewers
                created
                name
                createdBy
                reviewLink
                videos {
                    id
                    videoData
                    viewerProfileName
                    videoThumbnailUrl
                    aspectRatio
                }
                description
            }
        }
    }
`;
export type GqlClientProgramReviewsSummaryForUserProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramReviewsSummaryForUserQuery, GqlClientProgramReviewsSummaryForUserQueryVariables>;
} & TChildProps;
export function withProgramReviewsSummaryForUser<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientProgramReviewsSummaryForUserQuery,
        GqlClientProgramReviewsSummaryForUserQueryVariables,
        GqlClientProgramReviewsSummaryForUserProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientProgramReviewsSummaryForUserQuery,
        GqlClientProgramReviewsSummaryForUserQueryVariables,
        GqlClientProgramReviewsSummaryForUserProps<TChildProps, TDataName>
    >(ProgramReviewsSummaryForUserDocument, {
        alias: "programReviewsSummaryForUser",
        ...operationOptions
    });
}

/**
 * __useProgramReviewsSummaryForUserQuery__
 *
 * To run a query within a React component, call `useProgramReviewsSummaryForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramReviewsSummaryForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramReviewsSummaryForUserQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useProgramReviewsSummaryForUserQuery(baseOptions: Apollo.QueryHookOptions<GqlClientProgramReviewsSummaryForUserQuery, GqlClientProgramReviewsSummaryForUserQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientProgramReviewsSummaryForUserQuery, GqlClientProgramReviewsSummaryForUserQueryVariables>(ProgramReviewsSummaryForUserDocument, options);
}
export function useProgramReviewsSummaryForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientProgramReviewsSummaryForUserQuery, GqlClientProgramReviewsSummaryForUserQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientProgramReviewsSummaryForUserQuery, GqlClientProgramReviewsSummaryForUserQueryVariables>(ProgramReviewsSummaryForUserDocument, options);
}
export type ProgramReviewsSummaryForUserQueryHookResult = ReturnType<typeof useProgramReviewsSummaryForUserQuery>;
export type ProgramReviewsSummaryForUserLazyQueryHookResult = ReturnType<typeof useProgramReviewsSummaryForUserLazyQuery>;
export type ProgramReviewsSummaryForUserQueryResult = Apollo.QueryResult<GqlClientProgramReviewsSummaryForUserQuery, GqlClientProgramReviewsSummaryForUserQueryVariables>;
export const UserSettingsRecentSearchesDocument = gql`
    query userSettingsRecentSearches($id: ID!) {
        userSettings(id: $id) {
            id
            recentSearches
        }
    }
`;
export type GqlClientUserSettingsRecentSearchesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientUserSettingsRecentSearchesQuery, GqlClientUserSettingsRecentSearchesQueryVariables>;
} & TChildProps;
export function withUserSettingsRecentSearches<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUserSettingsRecentSearchesQuery,
        GqlClientUserSettingsRecentSearchesQueryVariables,
        GqlClientUserSettingsRecentSearchesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientUserSettingsRecentSearchesQuery,
        GqlClientUserSettingsRecentSearchesQueryVariables,
        GqlClientUserSettingsRecentSearchesProps<TChildProps, TDataName>
    >(UserSettingsRecentSearchesDocument, {
        alias: "userSettingsRecentSearches",
        ...operationOptions
    });
}

/**
 * __useUserSettingsRecentSearchesQuery__
 *
 * To run a query within a React component, call `useUserSettingsRecentSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsRecentSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsRecentSearchesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSettingsRecentSearchesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientUserSettingsRecentSearchesQuery, GqlClientUserSettingsRecentSearchesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientUserSettingsRecentSearchesQuery, GqlClientUserSettingsRecentSearchesQueryVariables>(UserSettingsRecentSearchesDocument, options);
}
export function useUserSettingsRecentSearchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientUserSettingsRecentSearchesQuery, GqlClientUserSettingsRecentSearchesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientUserSettingsRecentSearchesQuery, GqlClientUserSettingsRecentSearchesQueryVariables>(UserSettingsRecentSearchesDocument, options);
}
export type UserSettingsRecentSearchesQueryHookResult = ReturnType<typeof useUserSettingsRecentSearchesQuery>;
export type UserSettingsRecentSearchesLazyQueryHookResult = ReturnType<typeof useUserSettingsRecentSearchesLazyQuery>;
export type UserSettingsRecentSearchesQueryResult = Apollo.QueryResult<GqlClientUserSettingsRecentSearchesQuery, GqlClientUserSettingsRecentSearchesQueryVariables>;
export const ProgramReviewDocument = gql`
    query ProgramReview($id: ID!) {
        programReview(id: $id) {
            id
            name
            createdBy
            reviewers
            videos {
                id
                playerToken
                videoData
                aspectRatio
                viewerProfileName
                videoThumbnailUrl
            }
            program {
                id
                name
            }
        }
    }
`;
export type GqlClientProgramReviewProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientProgramReviewQuery, GqlClientProgramReviewQueryVariables>;
} & TChildProps;
export function withProgramReview<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientProgramReviewQuery, GqlClientProgramReviewQueryVariables, GqlClientProgramReviewProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientProgramReviewQuery, GqlClientProgramReviewQueryVariables, GqlClientProgramReviewProps<TChildProps, TDataName>>(ProgramReviewDocument, {
        alias: "programReview",
        ...operationOptions
    });
}

/**
 * __useProgramReviewQuery__
 *
 * To run a query within a React component, call `useProgramReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProgramReviewQuery(baseOptions: Apollo.QueryHookOptions<GqlClientProgramReviewQuery, GqlClientProgramReviewQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientProgramReviewQuery, GqlClientProgramReviewQueryVariables>(ProgramReviewDocument, options);
}
export function useProgramReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientProgramReviewQuery, GqlClientProgramReviewQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientProgramReviewQuery, GqlClientProgramReviewQueryVariables>(ProgramReviewDocument, options);
}
export type ProgramReviewQueryHookResult = ReturnType<typeof useProgramReviewQuery>;
export type ProgramReviewLazyQueryHookResult = ReturnType<typeof useProgramReviewLazyQuery>;
export type ProgramReviewQueryResult = Apollo.QueryResult<GqlClientProgramReviewQuery, GqlClientProgramReviewQueryVariables>;
export const CompleteFirstLoginDocument = gql`
    mutation completeFirstLogin($input: CompleteFirstLoginInput!) {
        completeFirstLogin(input: $input) {
            result
            ... on CompleteFirstLoginOutputSuccess {
                acceptedAccountId
            }
        }
    }
`;
export type GqlClientCompleteFirstLoginMutationFn = Apollo.MutationFunction<GqlClientCompleteFirstLoginMutation, GqlClientCompleteFirstLoginMutationVariables>;
export type GqlClientCompleteFirstLoginProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCompleteFirstLoginMutation, GqlClientCompleteFirstLoginMutationVariables>;
} & TChildProps;
export function withCompleteFirstLogin<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCompleteFirstLoginMutation,
        GqlClientCompleteFirstLoginMutationVariables,
        GqlClientCompleteFirstLoginProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCompleteFirstLoginMutation, GqlClientCompleteFirstLoginMutationVariables, GqlClientCompleteFirstLoginProps<TChildProps, TDataName>>(
        CompleteFirstLoginDocument,
        {
            alias: "completeFirstLogin",
            ...operationOptions
        }
    );
}

/**
 * __useCompleteFirstLoginMutation__
 *
 * To run a mutation, you first call `useCompleteFirstLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteFirstLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeFirstLoginMutation, { data, loading, error }] = useCompleteFirstLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteFirstLoginMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCompleteFirstLoginMutation, GqlClientCompleteFirstLoginMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCompleteFirstLoginMutation, GqlClientCompleteFirstLoginMutationVariables>(CompleteFirstLoginDocument, options);
}
export type CompleteFirstLoginMutationHookResult = ReturnType<typeof useCompleteFirstLoginMutation>;
export type CompleteFirstLoginMutationResult = Apollo.MutationResult<GqlClientCompleteFirstLoginMutation>;
export type CompleteFirstLoginMutationOptions = Apollo.BaseMutationOptions<GqlClientCompleteFirstLoginMutation, GqlClientCompleteFirstLoginMutationVariables>;
export const CopyDraftEditorProgramBetweenAccountsDocument = gql`
    mutation CopyDraftEditorProgramBetweenAccounts($input: CopyDraftEditorProgramBetweenAccountsInput!) {
        copyDraftEditorProgramBetweenAccounts(input: $input) {
            result
            editorProgram {
                accountId
                id
                account {
                    ...Account
                }
            }
        }
    }
    ${AccountFragmentDoc}
`;
export type GqlClientCopyDraftEditorProgramBetweenAccountsMutationFn = Apollo.MutationFunction<
    GqlClientCopyDraftEditorProgramBetweenAccountsMutation,
    GqlClientCopyDraftEditorProgramBetweenAccountsMutationVariables
>;
export type GqlClientCopyDraftEditorProgramBetweenAccountsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCopyDraftEditorProgramBetweenAccountsMutation, GqlClientCopyDraftEditorProgramBetweenAccountsMutationVariables>;
} & TChildProps;
export function withCopyDraftEditorProgramBetweenAccounts<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCopyDraftEditorProgramBetweenAccountsMutation,
        GqlClientCopyDraftEditorProgramBetweenAccountsMutationVariables,
        GqlClientCopyDraftEditorProgramBetweenAccountsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCopyDraftEditorProgramBetweenAccountsMutation,
        GqlClientCopyDraftEditorProgramBetweenAccountsMutationVariables,
        GqlClientCopyDraftEditorProgramBetweenAccountsProps<TChildProps, TDataName>
    >(CopyDraftEditorProgramBetweenAccountsDocument, {
        alias: "copyDraftEditorProgramBetweenAccounts",
        ...operationOptions
    });
}

/**
 * __useCopyDraftEditorProgramBetweenAccountsMutation__
 *
 * To run a mutation, you first call `useCopyDraftEditorProgramBetweenAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyDraftEditorProgramBetweenAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyDraftEditorProgramBetweenAccountsMutation, { data, loading, error }] = useCopyDraftEditorProgramBetweenAccountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyDraftEditorProgramBetweenAccountsMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCopyDraftEditorProgramBetweenAccountsMutation, GqlClientCopyDraftEditorProgramBetweenAccountsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCopyDraftEditorProgramBetweenAccountsMutation, GqlClientCopyDraftEditorProgramBetweenAccountsMutationVariables>(
        CopyDraftEditorProgramBetweenAccountsDocument,
        options
    );
}
export type CopyDraftEditorProgramBetweenAccountsMutationHookResult = ReturnType<typeof useCopyDraftEditorProgramBetweenAccountsMutation>;
export type CopyDraftEditorProgramBetweenAccountsMutationResult = Apollo.MutationResult<GqlClientCopyDraftEditorProgramBetweenAccountsMutation>;
export type CopyDraftEditorProgramBetweenAccountsMutationOptions = Apollo.BaseMutationOptions<
    GqlClientCopyDraftEditorProgramBetweenAccountsMutation,
    GqlClientCopyDraftEditorProgramBetweenAccountsMutationVariables
>;
export const CopyEditorProgramDocument = gql`
    mutation CopyEditorProgram($input: CopyProgramInput!) {
        copyEditorProgram(input: $input) {
            result
            targetProgram {
                accountId
                id
                legacyBuilderProgramId
                account {
                    ...Account
                }
            }
        }
    }
    ${AccountFragmentDoc}
`;
export type GqlClientCopyEditorProgramMutationFn = Apollo.MutationFunction<GqlClientCopyEditorProgramMutation, GqlClientCopyEditorProgramMutationVariables>;
export type GqlClientCopyEditorProgramProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCopyEditorProgramMutation, GqlClientCopyEditorProgramMutationVariables>;
} & TChildProps;
export function withCopyEditorProgram<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCopyEditorProgramMutation, GqlClientCopyEditorProgramMutationVariables, GqlClientCopyEditorProgramProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCopyEditorProgramMutation, GqlClientCopyEditorProgramMutationVariables, GqlClientCopyEditorProgramProps<TChildProps, TDataName>>(
        CopyEditorProgramDocument,
        {
            alias: "copyEditorProgram",
            ...operationOptions
        }
    );
}

/**
 * __useCopyEditorProgramMutation__
 *
 * To run a mutation, you first call `useCopyEditorProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyEditorProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyEditorProgramMutation, { data, loading, error }] = useCopyEditorProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyEditorProgramMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCopyEditorProgramMutation, GqlClientCopyEditorProgramMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCopyEditorProgramMutation, GqlClientCopyEditorProgramMutationVariables>(CopyEditorProgramDocument, options);
}
export type CopyEditorProgramMutationHookResult = ReturnType<typeof useCopyEditorProgramMutation>;
export type CopyEditorProgramMutationResult = Apollo.MutationResult<GqlClientCopyEditorProgramMutation>;
export type CopyEditorProgramMutationOptions = Apollo.BaseMutationOptions<GqlClientCopyEditorProgramMutation, GqlClientCopyEditorProgramMutationVariables>;
export const CopyEditorProgramAsEditorDocument = gql`
    mutation CopyEditorProgramAsEditor($input: CopyProgramInput!) {
        copyEditorProgramAsEditor(input: $input) {
            result
            targetProgram {
                id
                name
                anyUpdated
                isArchive
                programVersions {
                    id
                }
                account {
                    id
                    editorPrograms {
                        id
                    }
                }
            }
        }
    }
`;
export type GqlClientCopyEditorProgramAsEditorMutationFn = Apollo.MutationFunction<GqlClientCopyEditorProgramAsEditorMutation, GqlClientCopyEditorProgramAsEditorMutationVariables>;
export type GqlClientCopyEditorProgramAsEditorProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCopyEditorProgramAsEditorMutation, GqlClientCopyEditorProgramAsEditorMutationVariables>;
} & TChildProps;
export function withCopyEditorProgramAsEditor<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCopyEditorProgramAsEditorMutation,
        GqlClientCopyEditorProgramAsEditorMutationVariables,
        GqlClientCopyEditorProgramAsEditorProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCopyEditorProgramAsEditorMutation,
        GqlClientCopyEditorProgramAsEditorMutationVariables,
        GqlClientCopyEditorProgramAsEditorProps<TChildProps, TDataName>
    >(CopyEditorProgramAsEditorDocument, {
        alias: "copyEditorProgramAsEditor",
        ...operationOptions
    });
}

/**
 * __useCopyEditorProgramAsEditorMutation__
 *
 * To run a mutation, you first call `useCopyEditorProgramAsEditorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyEditorProgramAsEditorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyEditorProgramAsEditorMutation, { data, loading, error }] = useCopyEditorProgramAsEditorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyEditorProgramAsEditorMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCopyEditorProgramAsEditorMutation, GqlClientCopyEditorProgramAsEditorMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCopyEditorProgramAsEditorMutation, GqlClientCopyEditorProgramAsEditorMutationVariables>(CopyEditorProgramAsEditorDocument, options);
}
export type CopyEditorProgramAsEditorMutationHookResult = ReturnType<typeof useCopyEditorProgramAsEditorMutation>;
export type CopyEditorProgramAsEditorMutationResult = Apollo.MutationResult<GqlClientCopyEditorProgramAsEditorMutation>;
export type CopyEditorProgramAsEditorMutationOptions = Apollo.BaseMutationOptions<GqlClientCopyEditorProgramAsEditorMutation, GqlClientCopyEditorProgramAsEditorMutationVariables>;
export const CopyProgramDocument = gql`
    mutation CopyProgram($input: CopyProgramInput!) {
        copyProgram(input: $input) {
            result
            targetProgram {
                accountId
                id
                legacyBuilderProgramId
                account {
                    ...Account
                }
            }
        }
    }
    ${AccountFragmentDoc}
`;
export type GqlClientCopyProgramMutationFn = Apollo.MutationFunction<GqlClientCopyProgramMutation, GqlClientCopyProgramMutationVariables>;
export type GqlClientCopyProgramProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCopyProgramMutation, GqlClientCopyProgramMutationVariables>;
} & TChildProps;
export function withCopyProgram<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCopyProgramMutation, GqlClientCopyProgramMutationVariables, GqlClientCopyProgramProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCopyProgramMutation, GqlClientCopyProgramMutationVariables, GqlClientCopyProgramProps<TChildProps, TDataName>>(CopyProgramDocument, {
        alias: "copyProgram",
        ...operationOptions
    });
}

/**
 * __useCopyProgramMutation__
 *
 * To run a mutation, you first call `useCopyProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyProgramMutation, { data, loading, error }] = useCopyProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyProgramMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCopyProgramMutation, GqlClientCopyProgramMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCopyProgramMutation, GqlClientCopyProgramMutationVariables>(CopyProgramDocument, options);
}
export type CopyProgramMutationHookResult = ReturnType<typeof useCopyProgramMutation>;
export type CopyProgramMutationResult = Apollo.MutationResult<GqlClientCopyProgramMutation>;
export type CopyProgramMutationOptions = Apollo.BaseMutationOptions<GqlClientCopyProgramMutation, GqlClientCopyProgramMutationVariables>;
export const CopySceneDocument = gql`
    mutation CopyScene($input: CopySceneInput!, $legacyId: String) {
        copyScene(input: $input, legacyId: $legacyId) {
            result
            targetScene {
                ...SceneForBuilder
            }
        }
    }
    ${SceneForBuilderFragmentDoc}
`;
export type GqlClientCopySceneMutationFn = Apollo.MutationFunction<GqlClientCopySceneMutation, GqlClientCopySceneMutationVariables>;
export type GqlClientCopySceneProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCopySceneMutation, GqlClientCopySceneMutationVariables>;
} & TChildProps;
export function withCopyScene<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCopySceneMutation, GqlClientCopySceneMutationVariables, GqlClientCopySceneProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCopySceneMutation, GqlClientCopySceneMutationVariables, GqlClientCopySceneProps<TChildProps, TDataName>>(CopySceneDocument, {
        alias: "copyScene",
        ...operationOptions
    });
}

/**
 * __useCopySceneMutation__
 *
 * To run a mutation, you first call `useCopySceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopySceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copySceneMutation, { data, loading, error }] = useCopySceneMutation({
 *   variables: {
 *      input: // value for 'input'
 *      legacyId: // value for 'legacyId'
 *   },
 * });
 */
export function useCopySceneMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCopySceneMutation, GqlClientCopySceneMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCopySceneMutation, GqlClientCopySceneMutationVariables>(CopySceneDocument, options);
}
export type CopySceneMutationHookResult = ReturnType<typeof useCopySceneMutation>;
export type CopySceneMutationResult = Apollo.MutationResult<GqlClientCopySceneMutation>;
export type CopySceneMutationOptions = Apollo.BaseMutationOptions<GqlClientCopySceneMutation, GqlClientCopySceneMutationVariables>;
export const CopyScenePartDocument = gql`
    mutation CopyScenePart($input: CopyScenePartInput!, $legacyId: String) {
        copyScenePart(input: $input, legacyId: $legacyId) {
            result
            sourceScenePart {
                id
            }
            targetScenePart {
                id
                updated
                ...ScenePartNarrations
            }
        }
    }
    ${ScenePartNarrationsFragmentDoc}
`;
export type GqlClientCopyScenePartMutationFn = Apollo.MutationFunction<GqlClientCopyScenePartMutation, GqlClientCopyScenePartMutationVariables>;
export type GqlClientCopyScenePartProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCopyScenePartMutation, GqlClientCopyScenePartMutationVariables>;
} & TChildProps;
export function withCopyScenePart<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCopyScenePartMutation, GqlClientCopyScenePartMutationVariables, GqlClientCopyScenePartProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCopyScenePartMutation, GqlClientCopyScenePartMutationVariables, GqlClientCopyScenePartProps<TChildProps, TDataName>>(CopyScenePartDocument, {
        alias: "copyScenePart",
        ...operationOptions
    });
}

/**
 * __useCopyScenePartMutation__
 *
 * To run a mutation, you first call `useCopyScenePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyScenePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyScenePartMutation, { data, loading, error }] = useCopyScenePartMutation({
 *   variables: {
 *      input: // value for 'input'
 *      legacyId: // value for 'legacyId'
 *   },
 * });
 */
export function useCopyScenePartMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCopyScenePartMutation, GqlClientCopyScenePartMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCopyScenePartMutation, GqlClientCopyScenePartMutationVariables>(CopyScenePartDocument, options);
}
export type CopyScenePartMutationHookResult = ReturnType<typeof useCopyScenePartMutation>;
export type CopyScenePartMutationResult = Apollo.MutationResult<GqlClientCopyScenePartMutation>;
export type CopyScenePartMutationOptions = Apollo.BaseMutationOptions<GqlClientCopyScenePartMutation, GqlClientCopyScenePartMutationVariables>;
export const CreateAssetRequestDocument = gql`
    mutation createAssetRequest($input: CreateAssetRequestInput!) {
        createAssetRequest(input: $input) {
            result
            asset {
                ...Asset
            }
        }
    }
    ${AssetFragmentDoc}
`;
export type GqlClientCreateAssetRequestMutationFn = Apollo.MutationFunction<GqlClientCreateAssetRequestMutation, GqlClientCreateAssetRequestMutationVariables>;
export type GqlClientCreateAssetRequestProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateAssetRequestMutation, GqlClientCreateAssetRequestMutationVariables>;
} & TChildProps;
export function withCreateAssetRequest<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateAssetRequestMutation,
        GqlClientCreateAssetRequestMutationVariables,
        GqlClientCreateAssetRequestProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateAssetRequestMutation, GqlClientCreateAssetRequestMutationVariables, GqlClientCreateAssetRequestProps<TChildProps, TDataName>>(
        CreateAssetRequestDocument,
        {
            alias: "createAssetRequest",
            ...operationOptions
        }
    );
}

/**
 * __useCreateAssetRequestMutation__
 *
 * To run a mutation, you first call `useCreateAssetRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetRequestMutation, { data, loading, error }] = useCreateAssetRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetRequestMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateAssetRequestMutation, GqlClientCreateAssetRequestMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateAssetRequestMutation, GqlClientCreateAssetRequestMutationVariables>(CreateAssetRequestDocument, options);
}
export type CreateAssetRequestMutationHookResult = ReturnType<typeof useCreateAssetRequestMutation>;
export type CreateAssetRequestMutationResult = Apollo.MutationResult<GqlClientCreateAssetRequestMutation>;
export type CreateAssetRequestMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateAssetRequestMutation, GqlClientCreateAssetRequestMutationVariables>;
export const CreateAssetVersionDocument = gql`
    mutation createAssetVersion($input: CreateAssetVersionInput!) {
        createAssetVersion(input: $input) {
            result
            assetVersion {
                id
                asset {
                    ...Asset
                    assetVersions {
                        ...AssetVersion
                    }
                }
            }
        }
    }
    ${AssetFragmentDoc}
    ${AssetVersionFragmentDoc}
`;
export type GqlClientCreateAssetVersionMutationFn = Apollo.MutationFunction<GqlClientCreateAssetVersionMutation, GqlClientCreateAssetVersionMutationVariables>;
export type GqlClientCreateAssetVersionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateAssetVersionMutation, GqlClientCreateAssetVersionMutationVariables>;
} & TChildProps;
export function withCreateAssetVersion<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateAssetVersionMutation,
        GqlClientCreateAssetVersionMutationVariables,
        GqlClientCreateAssetVersionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateAssetVersionMutation, GqlClientCreateAssetVersionMutationVariables, GqlClientCreateAssetVersionProps<TChildProps, TDataName>>(
        CreateAssetVersionDocument,
        {
            alias: "createAssetVersion",
            ...operationOptions
        }
    );
}

/**
 * __useCreateAssetVersionMutation__
 *
 * To run a mutation, you first call `useCreateAssetVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetVersionMutation, { data, loading, error }] = useCreateAssetVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetVersionMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateAssetVersionMutation, GqlClientCreateAssetVersionMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateAssetVersionMutation, GqlClientCreateAssetVersionMutationVariables>(CreateAssetVersionDocument, options);
}
export type CreateAssetVersionMutationHookResult = ReturnType<typeof useCreateAssetVersionMutation>;
export type CreateAssetVersionMutationResult = Apollo.MutationResult<GqlClientCreateAssetVersionMutation>;
export type CreateAssetVersionMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateAssetVersionMutation, GqlClientCreateAssetVersionMutationVariables>;
export const CreateAssetWithVersionDocument = gql`
    mutation createAssetWithVersion($input: CreateAssetWithVersionInput!) {
        createAssetWithVersion(input: $input) {
            result
            asset {
                ...Asset
            }
        }
    }
    ${AssetFragmentDoc}
`;
export type GqlClientCreateAssetWithVersionMutationFn = Apollo.MutationFunction<GqlClientCreateAssetWithVersionMutation, GqlClientCreateAssetWithVersionMutationVariables>;
export type GqlClientCreateAssetWithVersionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateAssetWithVersionMutation, GqlClientCreateAssetWithVersionMutationVariables>;
} & TChildProps;
export function withCreateAssetWithVersion<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateAssetWithVersionMutation,
        GqlClientCreateAssetWithVersionMutationVariables,
        GqlClientCreateAssetWithVersionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateAssetWithVersionMutation, GqlClientCreateAssetWithVersionMutationVariables, GqlClientCreateAssetWithVersionProps<TChildProps, TDataName>>(
        CreateAssetWithVersionDocument,
        {
            alias: "createAssetWithVersion",
            ...operationOptions
        }
    );
}

/**
 * __useCreateAssetWithVersionMutation__
 *
 * To run a mutation, you first call `useCreateAssetWithVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetWithVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetWithVersionMutation, { data, loading, error }] = useCreateAssetWithVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetWithVersionMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateAssetWithVersionMutation, GqlClientCreateAssetWithVersionMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateAssetWithVersionMutation, GqlClientCreateAssetWithVersionMutationVariables>(CreateAssetWithVersionDocument, options);
}
export type CreateAssetWithVersionMutationHookResult = ReturnType<typeof useCreateAssetWithVersionMutation>;
export type CreateAssetWithVersionMutationResult = Apollo.MutationResult<GqlClientCreateAssetWithVersionMutation>;
export type CreateAssetWithVersionMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateAssetWithVersionMutation, GqlClientCreateAssetWithVersionMutationVariables>;
export const CreateCustomAnalyticFieldDocument = gql`
    mutation CreateCustomAnalyticField($input: CreateCustomAnalyticFieldInput!) {
        createCustomAnalyticField(input: $input) {
            result
            customAnalyticField {
                ...CustomAnalyticField
            }
        }
    }
    ${CustomAnalyticFieldFragmentDoc}
`;
export type GqlClientCreateCustomAnalyticFieldMutationFn = Apollo.MutationFunction<GqlClientCreateCustomAnalyticFieldMutation, GqlClientCreateCustomAnalyticFieldMutationVariables>;
export type GqlClientCreateCustomAnalyticFieldProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateCustomAnalyticFieldMutation, GqlClientCreateCustomAnalyticFieldMutationVariables>;
} & TChildProps;
export function withCreateCustomAnalyticField<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateCustomAnalyticFieldMutation,
        GqlClientCreateCustomAnalyticFieldMutationVariables,
        GqlClientCreateCustomAnalyticFieldProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateCustomAnalyticFieldMutation,
        GqlClientCreateCustomAnalyticFieldMutationVariables,
        GqlClientCreateCustomAnalyticFieldProps<TChildProps, TDataName>
    >(CreateCustomAnalyticFieldDocument, {
        alias: "createCustomAnalyticField",
        ...operationOptions
    });
}

/**
 * __useCreateCustomAnalyticFieldMutation__
 *
 * To run a mutation, you first call `useCreateCustomAnalyticFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomAnalyticFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomAnalyticFieldMutation, { data, loading, error }] = useCreateCustomAnalyticFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomAnalyticFieldMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateCustomAnalyticFieldMutation, GqlClientCreateCustomAnalyticFieldMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateCustomAnalyticFieldMutation, GqlClientCreateCustomAnalyticFieldMutationVariables>(CreateCustomAnalyticFieldDocument, options);
}
export type CreateCustomAnalyticFieldMutationHookResult = ReturnType<typeof useCreateCustomAnalyticFieldMutation>;
export type CreateCustomAnalyticFieldMutationResult = Apollo.MutationResult<GqlClientCreateCustomAnalyticFieldMutation>;
export type CreateCustomAnalyticFieldMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateCustomAnalyticFieldMutation, GqlClientCreateCustomAnalyticFieldMutationVariables>;
export const CreateDataTableDocument = gql`
    mutation CreateDataTable($input: CreateDataTableInput!) {
        createDataTable(input: $input) {
            result
            dataTable {
                ...DataTable
            }
        }
    }
    ${DataTableFragmentDoc}
`;
export type GqlClientCreateDataTableMutationFn = Apollo.MutationFunction<GqlClientCreateDataTableMutation, GqlClientCreateDataTableMutationVariables>;
export type GqlClientCreateDataTableProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateDataTableMutation, GqlClientCreateDataTableMutationVariables>;
} & TChildProps;
export function withCreateDataTable<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateDataTableMutation, GqlClientCreateDataTableMutationVariables, GqlClientCreateDataTableProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateDataTableMutation, GqlClientCreateDataTableMutationVariables, GqlClientCreateDataTableProps<TChildProps, TDataName>>(
        CreateDataTableDocument,
        {
            alias: "createDataTable",
            ...operationOptions
        }
    );
}

/**
 * __useCreateDataTableMutation__
 *
 * To run a mutation, you first call `useCreateDataTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataTableMutation, { data, loading, error }] = useCreateDataTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataTableMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateDataTableMutation, GqlClientCreateDataTableMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateDataTableMutation, GqlClientCreateDataTableMutationVariables>(CreateDataTableDocument, options);
}
export type CreateDataTableMutationHookResult = ReturnType<typeof useCreateDataTableMutation>;
export type CreateDataTableMutationResult = Apollo.MutationResult<GqlClientCreateDataTableMutation>;
export type CreateDataTableMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateDataTableMutation, GqlClientCreateDataTableMutationVariables>;
export const CreateEditorProgramDocument = gql`
    mutation CreateEditorProgram($input: CreateEditorProgramInput!) {
        createEditorProgram(input: $input) {
            result
            editorProgram {
                ...NewlyCreatedEditorProgram
            }
        }
    }
    ${NewlyCreatedEditorProgramFragmentDoc}
`;
export type GqlClientCreateEditorProgramMutationFn = Apollo.MutationFunction<GqlClientCreateEditorProgramMutation, GqlClientCreateEditorProgramMutationVariables>;
export type GqlClientCreateEditorProgramProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateEditorProgramMutation, GqlClientCreateEditorProgramMutationVariables>;
} & TChildProps;
export function withCreateEditorProgram<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateEditorProgramMutation,
        GqlClientCreateEditorProgramMutationVariables,
        GqlClientCreateEditorProgramProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateEditorProgramMutation, GqlClientCreateEditorProgramMutationVariables, GqlClientCreateEditorProgramProps<TChildProps, TDataName>>(
        CreateEditorProgramDocument,
        {
            alias: "createEditorProgram",
            ...operationOptions
        }
    );
}

/**
 * __useCreateEditorProgramMutation__
 *
 * To run a mutation, you first call `useCreateEditorProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditorProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditorProgramMutation, { data, loading, error }] = useCreateEditorProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditorProgramMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateEditorProgramMutation, GqlClientCreateEditorProgramMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateEditorProgramMutation, GqlClientCreateEditorProgramMutationVariables>(CreateEditorProgramDocument, options);
}
export type CreateEditorProgramMutationHookResult = ReturnType<typeof useCreateEditorProgramMutation>;
export type CreateEditorProgramMutationResult = Apollo.MutationResult<GqlClientCreateEditorProgramMutation>;
export type CreateEditorProgramMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateEditorProgramMutation, GqlClientCreateEditorProgramMutationVariables>;
export const CreateEditorProgramFromStoryTemplateDocument = gql`
    mutation CreateEditorProgramFromStoryTemplate($input: CreateEditorProgramFromStoryTemplateInput!) {
        createEditorProgramFromStoryTemplate(input: $input) {
            result
            editorProgram {
                ...NewlyCreatedEditorProgram
            }
        }
    }
    ${NewlyCreatedEditorProgramFragmentDoc}
`;
export type GqlClientCreateEditorProgramFromStoryTemplateMutationFn = Apollo.MutationFunction<
    GqlClientCreateEditorProgramFromStoryTemplateMutation,
    GqlClientCreateEditorProgramFromStoryTemplateMutationVariables
>;
export type GqlClientCreateEditorProgramFromStoryTemplateProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateEditorProgramFromStoryTemplateMutation, GqlClientCreateEditorProgramFromStoryTemplateMutationVariables>;
} & TChildProps;
export function withCreateEditorProgramFromStoryTemplate<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateEditorProgramFromStoryTemplateMutation,
        GqlClientCreateEditorProgramFromStoryTemplateMutationVariables,
        GqlClientCreateEditorProgramFromStoryTemplateProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateEditorProgramFromStoryTemplateMutation,
        GqlClientCreateEditorProgramFromStoryTemplateMutationVariables,
        GqlClientCreateEditorProgramFromStoryTemplateProps<TChildProps, TDataName>
    >(CreateEditorProgramFromStoryTemplateDocument, {
        alias: "createEditorProgramFromStoryTemplate",
        ...operationOptions
    });
}

/**
 * __useCreateEditorProgramFromStoryTemplateMutation__
 *
 * To run a mutation, you first call `useCreateEditorProgramFromStoryTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditorProgramFromStoryTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditorProgramFromStoryTemplateMutation, { data, loading, error }] = useCreateEditorProgramFromStoryTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditorProgramFromStoryTemplateMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateEditorProgramFromStoryTemplateMutation, GqlClientCreateEditorProgramFromStoryTemplateMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateEditorProgramFromStoryTemplateMutation, GqlClientCreateEditorProgramFromStoryTemplateMutationVariables>(
        CreateEditorProgramFromStoryTemplateDocument,
        options
    );
}
export type CreateEditorProgramFromStoryTemplateMutationHookResult = ReturnType<typeof useCreateEditorProgramFromStoryTemplateMutation>;
export type CreateEditorProgramFromStoryTemplateMutationResult = Apollo.MutationResult<GqlClientCreateEditorProgramFromStoryTemplateMutation>;
export type CreateEditorProgramFromStoryTemplateMutationOptions = Apollo.BaseMutationOptions<
    GqlClientCreateEditorProgramFromStoryTemplateMutation,
    GqlClientCreateEditorProgramFromStoryTemplateMutationVariables
>;
export const CreateMasterAnimationGuidelineDocument = gql`
    mutation CreateMasterAnimationGuideline($input: CreateMasterAnimationGuidelineInput!) {
        createMasterAnimationGuideline(input: $input) {
            result
            masterAnimationGuideline {
                id
                updated
            }
        }
    }
`;
export type GqlClientCreateMasterAnimationGuidelineMutationFn = Apollo.MutationFunction<GqlClientCreateMasterAnimationGuidelineMutation, GqlClientCreateMasterAnimationGuidelineMutationVariables>;
export type GqlClientCreateMasterAnimationGuidelineProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateMasterAnimationGuidelineMutation, GqlClientCreateMasterAnimationGuidelineMutationVariables>;
} & TChildProps;
export function withCreateMasterAnimationGuideline<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateMasterAnimationGuidelineMutation,
        GqlClientCreateMasterAnimationGuidelineMutationVariables,
        GqlClientCreateMasterAnimationGuidelineProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateMasterAnimationGuidelineMutation,
        GqlClientCreateMasterAnimationGuidelineMutationVariables,
        GqlClientCreateMasterAnimationGuidelineProps<TChildProps, TDataName>
    >(CreateMasterAnimationGuidelineDocument, {
        alias: "createMasterAnimationGuideline",
        ...operationOptions
    });
}

/**
 * __useCreateMasterAnimationGuidelineMutation__
 *
 * To run a mutation, you first call `useCreateMasterAnimationGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMasterAnimationGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMasterAnimationGuidelineMutation, { data, loading, error }] = useCreateMasterAnimationGuidelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMasterAnimationGuidelineMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateMasterAnimationGuidelineMutation, GqlClientCreateMasterAnimationGuidelineMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateMasterAnimationGuidelineMutation, GqlClientCreateMasterAnimationGuidelineMutationVariables>(CreateMasterAnimationGuidelineDocument, options);
}
export type CreateMasterAnimationGuidelineMutationHookResult = ReturnType<typeof useCreateMasterAnimationGuidelineMutation>;
export type CreateMasterAnimationGuidelineMutationResult = Apollo.MutationResult<GqlClientCreateMasterAnimationGuidelineMutation>;
export type CreateMasterAnimationGuidelineMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateMasterAnimationGuidelineMutation, GqlClientCreateMasterAnimationGuidelineMutationVariables>;
export const CreateNarrationRecordingVersionDocument = gql`
    mutation CreateNarrationRecordingVersion($input: CreateNarrationRecordingVersionInput!) {
        createNarrationRecordingVersion(input: $input) {
            result
            narrationRecordingVersion {
                id
                narrationRecording {
                    ...NarrationRecording
                    narrationRecordingVersions {
                        ...NarrationRecordingVersion
                    }
                }
            }
        }
    }
    ${NarrationRecordingFragmentDoc}
    ${NarrationRecordingVersionFragmentDoc}
`;
export type GqlClientCreateNarrationRecordingVersionMutationFn = Apollo.MutationFunction<GqlClientCreateNarrationRecordingVersionMutation, GqlClientCreateNarrationRecordingVersionMutationVariables>;
export type GqlClientCreateNarrationRecordingVersionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateNarrationRecordingVersionMutation, GqlClientCreateNarrationRecordingVersionMutationVariables>;
} & TChildProps;
export function withCreateNarrationRecordingVersion<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateNarrationRecordingVersionMutation,
        GqlClientCreateNarrationRecordingVersionMutationVariables,
        GqlClientCreateNarrationRecordingVersionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateNarrationRecordingVersionMutation,
        GqlClientCreateNarrationRecordingVersionMutationVariables,
        GqlClientCreateNarrationRecordingVersionProps<TChildProps, TDataName>
    >(CreateNarrationRecordingVersionDocument, {
        alias: "createNarrationRecordingVersion",
        ...operationOptions
    });
}

/**
 * __useCreateNarrationRecordingVersionMutation__
 *
 * To run a mutation, you first call `useCreateNarrationRecordingVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNarrationRecordingVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNarrationRecordingVersionMutation, { data, loading, error }] = useCreateNarrationRecordingVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNarrationRecordingVersionMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateNarrationRecordingVersionMutation, GqlClientCreateNarrationRecordingVersionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateNarrationRecordingVersionMutation, GqlClientCreateNarrationRecordingVersionMutationVariables>(CreateNarrationRecordingVersionDocument, options);
}
export type CreateNarrationRecordingVersionMutationHookResult = ReturnType<typeof useCreateNarrationRecordingVersionMutation>;
export type CreateNarrationRecordingVersionMutationResult = Apollo.MutationResult<GqlClientCreateNarrationRecordingVersionMutation>;
export type CreateNarrationRecordingVersionMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateNarrationRecordingVersionMutation, GqlClientCreateNarrationRecordingVersionMutationVariables>;
export const CreateOrUpdateEditorApplicationDocument = gql`
    mutation CreateOrUpdateEditorApplication($input: CreateOrUpdateEditorApplicationInput!) {
        createOrUpdateEditorApplication(input: $input) {
            result
            ... on CreateOrUpdateEditorApplicationOutputSuccess {
                editorApplication {
                    id
                    name
                    metadata
                    accountId
                    industry {
                        id
                        name
                    }
                    useCases {
                        id
                        name
                    }
                    creativeStyleFits {
                        id
                        name
                    }
                    channels {
                        id
                        name
                    }
                    flavor {
                        id
                        name
                    }
                    isParent
                    parent {
                        id
                        name
                    }
                    updated
                }
            }
        }
    }
`;
export type GqlClientCreateOrUpdateEditorApplicationMutationFn = Apollo.MutationFunction<GqlClientCreateOrUpdateEditorApplicationMutation, GqlClientCreateOrUpdateEditorApplicationMutationVariables>;
export type GqlClientCreateOrUpdateEditorApplicationProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateOrUpdateEditorApplicationMutation, GqlClientCreateOrUpdateEditorApplicationMutationVariables>;
} & TChildProps;
export function withCreateOrUpdateEditorApplication<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateOrUpdateEditorApplicationMutation,
        GqlClientCreateOrUpdateEditorApplicationMutationVariables,
        GqlClientCreateOrUpdateEditorApplicationProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateOrUpdateEditorApplicationMutation,
        GqlClientCreateOrUpdateEditorApplicationMutationVariables,
        GqlClientCreateOrUpdateEditorApplicationProps<TChildProps, TDataName>
    >(CreateOrUpdateEditorApplicationDocument, {
        alias: "createOrUpdateEditorApplication",
        ...operationOptions
    });
}

/**
 * __useCreateOrUpdateEditorApplicationMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateEditorApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateEditorApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateEditorApplicationMutation, { data, loading, error }] = useCreateOrUpdateEditorApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateEditorApplicationMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateOrUpdateEditorApplicationMutation, GqlClientCreateOrUpdateEditorApplicationMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateOrUpdateEditorApplicationMutation, GqlClientCreateOrUpdateEditorApplicationMutationVariables>(CreateOrUpdateEditorApplicationDocument, options);
}
export type CreateOrUpdateEditorApplicationMutationHookResult = ReturnType<typeof useCreateOrUpdateEditorApplicationMutation>;
export type CreateOrUpdateEditorApplicationMutationResult = Apollo.MutationResult<GqlClientCreateOrUpdateEditorApplicationMutation>;
export type CreateOrUpdateEditorApplicationMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateOrUpdateEditorApplicationMutation, GqlClientCreateOrUpdateEditorApplicationMutationVariables>;
export const CreatePresetDocument = gql`
    mutation CreatePreset($programVersionId: ID!, $input: CreatePresetInput!, $legacyId: String) {
        createPreset(programVersionId: $programVersionId, input: $input, legacyId: $legacyId) {
            result
            preset {
                id
                updated
            }
        }
    }
`;
export type GqlClientCreatePresetMutationFn = Apollo.MutationFunction<GqlClientCreatePresetMutation, GqlClientCreatePresetMutationVariables>;
export type GqlClientCreatePresetProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreatePresetMutation, GqlClientCreatePresetMutationVariables>;
} & TChildProps;
export function withCreatePreset<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreatePresetMutation, GqlClientCreatePresetMutationVariables, GqlClientCreatePresetProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreatePresetMutation, GqlClientCreatePresetMutationVariables, GqlClientCreatePresetProps<TChildProps, TDataName>>(CreatePresetDocument, {
        alias: "createPreset",
        ...operationOptions
    });
}

/**
 * __useCreatePresetMutation__
 *
 * To run a mutation, you first call `useCreatePresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresetMutation, { data, loading, error }] = useCreatePresetMutation({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *      input: // value for 'input'
 *      legacyId: // value for 'legacyId'
 *   },
 * });
 */
export function useCreatePresetMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreatePresetMutation, GqlClientCreatePresetMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreatePresetMutation, GqlClientCreatePresetMutationVariables>(CreatePresetDocument, options);
}
export type CreatePresetMutationHookResult = ReturnType<typeof useCreatePresetMutation>;
export type CreatePresetMutationResult = Apollo.MutationResult<GqlClientCreatePresetMutation>;
export type CreatePresetMutationOptions = Apollo.BaseMutationOptions<GqlClientCreatePresetMutation, GqlClientCreatePresetMutationVariables>;
export const CreateProgramAndBuilderDraftDocument = gql`
    mutation CreateProgramAndBuilderDraft($input: CreateProgramAndBuilderDraftInput!, $legacyBuilderProgramId: String, $legacyAudienceDataElementId: String) {
        createProgramAndBuilderDraft(input: $input, legacyBuilderProgramId: $legacyBuilderProgramId, legacyAudienceDataElementId: $legacyAudienceDataElementId) {
            result
            program {
                id
                created
                createdBy
                name
                accountId
                legacyBuilderProgramId
                programVersions {
                    id
                }
                account {
                    ...Account
                }
            }
        }
    }
    ${AccountFragmentDoc}
`;
export type GqlClientCreateProgramAndBuilderDraftMutationFn = Apollo.MutationFunction<GqlClientCreateProgramAndBuilderDraftMutation, GqlClientCreateProgramAndBuilderDraftMutationVariables>;
export type GqlClientCreateProgramAndBuilderDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateProgramAndBuilderDraftMutation, GqlClientCreateProgramAndBuilderDraftMutationVariables>;
} & TChildProps;
export function withCreateProgramAndBuilderDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateProgramAndBuilderDraftMutation,
        GqlClientCreateProgramAndBuilderDraftMutationVariables,
        GqlClientCreateProgramAndBuilderDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateProgramAndBuilderDraftMutation,
        GqlClientCreateProgramAndBuilderDraftMutationVariables,
        GqlClientCreateProgramAndBuilderDraftProps<TChildProps, TDataName>
    >(CreateProgramAndBuilderDraftDocument, {
        alias: "createProgramAndBuilderDraft",
        ...operationOptions
    });
}

/**
 * __useCreateProgramAndBuilderDraftMutation__
 *
 * To run a mutation, you first call `useCreateProgramAndBuilderDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramAndBuilderDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramAndBuilderDraftMutation, { data, loading, error }] = useCreateProgramAndBuilderDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *      legacyBuilderProgramId: // value for 'legacyBuilderProgramId'
 *      legacyAudienceDataElementId: // value for 'legacyAudienceDataElementId'
 *   },
 * });
 */
export function useCreateProgramAndBuilderDraftMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateProgramAndBuilderDraftMutation, GqlClientCreateProgramAndBuilderDraftMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateProgramAndBuilderDraftMutation, GqlClientCreateProgramAndBuilderDraftMutationVariables>(CreateProgramAndBuilderDraftDocument, options);
}
export type CreateProgramAndBuilderDraftMutationHookResult = ReturnType<typeof useCreateProgramAndBuilderDraftMutation>;
export type CreateProgramAndBuilderDraftMutationResult = Apollo.MutationResult<GqlClientCreateProgramAndBuilderDraftMutation>;
export type CreateProgramAndBuilderDraftMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateProgramAndBuilderDraftMutation, GqlClientCreateProgramAndBuilderDraftMutationVariables>;
export const CreateProgramVersionDraftDocument = gql`
    mutation CreateProgramVersionDraft($programId: ID!, $input: CreateProgramVersionDraftInput!, $legacyBuilderProgramId: String, $legacyAudienceDataElementId: String) {
        createProgramVersionDraft(programId: $programId, input: $input, legacyBuilderProgramId: $legacyBuilderProgramId, legacyAudienceDataElementId: $legacyAudienceDataElementId) {
            result
            programVersion {
                ...ProgramVersion
                program {
                    ...Program
                    programVersionDrafts {
                        ...ProgramVersion
                    }
                }
            }
        }
    }
    ${ProgramVersionFragmentDoc}
    ${ProgramFragmentDoc}
`;
export type GqlClientCreateProgramVersionDraftMutationFn = Apollo.MutationFunction<GqlClientCreateProgramVersionDraftMutation, GqlClientCreateProgramVersionDraftMutationVariables>;
export type GqlClientCreateProgramVersionDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateProgramVersionDraftMutation, GqlClientCreateProgramVersionDraftMutationVariables>;
} & TChildProps;
export function withCreateProgramVersionDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateProgramVersionDraftMutation,
        GqlClientCreateProgramVersionDraftMutationVariables,
        GqlClientCreateProgramVersionDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateProgramVersionDraftMutation,
        GqlClientCreateProgramVersionDraftMutationVariables,
        GqlClientCreateProgramVersionDraftProps<TChildProps, TDataName>
    >(CreateProgramVersionDraftDocument, {
        alias: "createProgramVersionDraft",
        ...operationOptions
    });
}

/**
 * __useCreateProgramVersionDraftMutation__
 *
 * To run a mutation, you first call `useCreateProgramVersionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramVersionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramVersionDraftMutation, { data, loading, error }] = useCreateProgramVersionDraftMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      input: // value for 'input'
 *      legacyBuilderProgramId: // value for 'legacyBuilderProgramId'
 *      legacyAudienceDataElementId: // value for 'legacyAudienceDataElementId'
 *   },
 * });
 */
export function useCreateProgramVersionDraftMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateProgramVersionDraftMutation, GqlClientCreateProgramVersionDraftMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateProgramVersionDraftMutation, GqlClientCreateProgramVersionDraftMutationVariables>(CreateProgramVersionDraftDocument, options);
}
export type CreateProgramVersionDraftMutationHookResult = ReturnType<typeof useCreateProgramVersionDraftMutation>;
export type CreateProgramVersionDraftMutationResult = Apollo.MutationResult<GqlClientCreateProgramVersionDraftMutation>;
export type CreateProgramVersionDraftMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateProgramVersionDraftMutation, GqlClientCreateProgramVersionDraftMutationVariables>;
export const CreateProgramVersionDraftFromSnapshotDocument = gql`
    mutation createProgramVersionDraftFromSnapshot($input: CreateProgramVersionDraftFromSnapshotInput!) {
        createProgramVersionDraftFromSnapshot(input: $input) {
            result
        }
    }
`;
export type GqlClientCreateProgramVersionDraftFromSnapshotMutationFn = Apollo.MutationFunction<
    GqlClientCreateProgramVersionDraftFromSnapshotMutation,
    GqlClientCreateProgramVersionDraftFromSnapshotMutationVariables
>;
export type GqlClientCreateProgramVersionDraftFromSnapshotProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateProgramVersionDraftFromSnapshotMutation, GqlClientCreateProgramVersionDraftFromSnapshotMutationVariables>;
} & TChildProps;
export function withCreateProgramVersionDraftFromSnapshot<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateProgramVersionDraftFromSnapshotMutation,
        GqlClientCreateProgramVersionDraftFromSnapshotMutationVariables,
        GqlClientCreateProgramVersionDraftFromSnapshotProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateProgramVersionDraftFromSnapshotMutation,
        GqlClientCreateProgramVersionDraftFromSnapshotMutationVariables,
        GqlClientCreateProgramVersionDraftFromSnapshotProps<TChildProps, TDataName>
    >(CreateProgramVersionDraftFromSnapshotDocument, {
        alias: "createProgramVersionDraftFromSnapshot",
        ...operationOptions
    });
}

/**
 * __useCreateProgramVersionDraftFromSnapshotMutation__
 *
 * To run a mutation, you first call `useCreateProgramVersionDraftFromSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramVersionDraftFromSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramVersionDraftFromSnapshotMutation, { data, loading, error }] = useCreateProgramVersionDraftFromSnapshotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgramVersionDraftFromSnapshotMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateProgramVersionDraftFromSnapshotMutation, GqlClientCreateProgramVersionDraftFromSnapshotMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateProgramVersionDraftFromSnapshotMutation, GqlClientCreateProgramVersionDraftFromSnapshotMutationVariables>(
        CreateProgramVersionDraftFromSnapshotDocument,
        options
    );
}
export type CreateProgramVersionDraftFromSnapshotMutationHookResult = ReturnType<typeof useCreateProgramVersionDraftFromSnapshotMutation>;
export type CreateProgramVersionDraftFromSnapshotMutationResult = Apollo.MutationResult<GqlClientCreateProgramVersionDraftFromSnapshotMutation>;
export type CreateProgramVersionDraftFromSnapshotMutationOptions = Apollo.BaseMutationOptions<
    GqlClientCreateProgramVersionDraftFromSnapshotMutation,
    GqlClientCreateProgramVersionDraftFromSnapshotMutationVariables
>;
export const CreateProgramVersionSnapshotDocument = gql`
    mutation createProgramVersionSnapshot($input: CreateProgramVersionSnapshotInput!) {
        createProgramVersionSnapshot(input: $input) {
            result
            sourceProgramVersion {
                id
                parent1ProgramVersion {
                    id
                }
            }
            targetProgramVersion {
                id
            }
        }
    }
`;
export type GqlClientCreateProgramVersionSnapshotMutationFn = Apollo.MutationFunction<GqlClientCreateProgramVersionSnapshotMutation, GqlClientCreateProgramVersionSnapshotMutationVariables>;
export type GqlClientCreateProgramVersionSnapshotProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateProgramVersionSnapshotMutation, GqlClientCreateProgramVersionSnapshotMutationVariables>;
} & TChildProps;
export function withCreateProgramVersionSnapshot<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateProgramVersionSnapshotMutation,
        GqlClientCreateProgramVersionSnapshotMutationVariables,
        GqlClientCreateProgramVersionSnapshotProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateProgramVersionSnapshotMutation,
        GqlClientCreateProgramVersionSnapshotMutationVariables,
        GqlClientCreateProgramVersionSnapshotProps<TChildProps, TDataName>
    >(CreateProgramVersionSnapshotDocument, {
        alias: "createProgramVersionSnapshot",
        ...operationOptions
    });
}

/**
 * __useCreateProgramVersionSnapshotMutation__
 *
 * To run a mutation, you first call `useCreateProgramVersionSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramVersionSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramVersionSnapshotMutation, { data, loading, error }] = useCreateProgramVersionSnapshotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgramVersionSnapshotMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateProgramVersionSnapshotMutation, GqlClientCreateProgramVersionSnapshotMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateProgramVersionSnapshotMutation, GqlClientCreateProgramVersionSnapshotMutationVariables>(CreateProgramVersionSnapshotDocument, options);
}
export type CreateProgramVersionSnapshotMutationHookResult = ReturnType<typeof useCreateProgramVersionSnapshotMutation>;
export type CreateProgramVersionSnapshotMutationResult = Apollo.MutationResult<GqlClientCreateProgramVersionSnapshotMutation>;
export type CreateProgramVersionSnapshotMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateProgramVersionSnapshotMutation, GqlClientCreateProgramVersionSnapshotMutationVariables>;
export const CreateRfrVersionDocument = gql`
    mutation CreateRFRVersion($input: CreateRFRVersionInput!, $dryRun: Boolean) {
        createRFRVersion(input: $input, dryRun: $dryRun) {
            result
            ... on CreateRFRVersionOutputSuccess {
                programVersion {
                    id
                    updated
                    narrationRecordings {
                        id
                        key
                        narrationId
                        updated
                        lastVersionNumber
                    }
                }
            }
        }
    }
`;
export type GqlClientCreateRfrVersionMutationFn = Apollo.MutationFunction<GqlClientCreateRfrVersionMutation, GqlClientCreateRfrVersionMutationVariables>;
export type GqlClientCreateRfrVersionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateRfrVersionMutation, GqlClientCreateRfrVersionMutationVariables>;
} & TChildProps;
export function withCreateRfrVersion<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateRfrVersionMutation, GqlClientCreateRfrVersionMutationVariables, GqlClientCreateRfrVersionProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateRfrVersionMutation, GqlClientCreateRfrVersionMutationVariables, GqlClientCreateRfrVersionProps<TChildProps, TDataName>>(
        CreateRfrVersionDocument,
        {
            alias: "createRfrVersion",
            ...operationOptions
        }
    );
}

/**
 * __useCreateRfrVersionMutation__
 *
 * To run a mutation, you first call `useCreateRfrVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRfrVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRfrVersionMutation, { data, loading, error }] = useCreateRfrVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useCreateRfrVersionMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateRfrVersionMutation, GqlClientCreateRfrVersionMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateRfrVersionMutation, GqlClientCreateRfrVersionMutationVariables>(CreateRfrVersionDocument, options);
}
export type CreateRfrVersionMutationHookResult = ReturnType<typeof useCreateRfrVersionMutation>;
export type CreateRfrVersionMutationResult = Apollo.MutationResult<GqlClientCreateRfrVersionMutation>;
export type CreateRfrVersionMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateRfrVersionMutation, GqlClientCreateRfrVersionMutationVariables>;
export const CreateRecordingDocDocument = gql`
    mutation CreateRecordingDoc($input: CreateRecordingDocInput!) {
        createRecordingDoc(input: $input) {
            result
            recordingDoc {
                ...RecordingDoc
            }
        }
    }
    ${RecordingDocFragmentDoc}
`;
export type GqlClientCreateRecordingDocMutationFn = Apollo.MutationFunction<GqlClientCreateRecordingDocMutation, GqlClientCreateRecordingDocMutationVariables>;
export type GqlClientCreateRecordingDocProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateRecordingDocMutation, GqlClientCreateRecordingDocMutationVariables>;
} & TChildProps;
export function withCreateRecordingDoc<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateRecordingDocMutation,
        GqlClientCreateRecordingDocMutationVariables,
        GqlClientCreateRecordingDocProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateRecordingDocMutation, GqlClientCreateRecordingDocMutationVariables, GqlClientCreateRecordingDocProps<TChildProps, TDataName>>(
        CreateRecordingDocDocument,
        {
            alias: "createRecordingDoc",
            ...operationOptions
        }
    );
}

/**
 * __useCreateRecordingDocMutation__
 *
 * To run a mutation, you first call `useCreateRecordingDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecordingDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecordingDocMutation, { data, loading, error }] = useCreateRecordingDocMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecordingDocMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateRecordingDocMutation, GqlClientCreateRecordingDocMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateRecordingDocMutation, GqlClientCreateRecordingDocMutationVariables>(CreateRecordingDocDocument, options);
}
export type CreateRecordingDocMutationHookResult = ReturnType<typeof useCreateRecordingDocMutation>;
export type CreateRecordingDocMutationResult = Apollo.MutationResult<GqlClientCreateRecordingDocMutation>;
export type CreateRecordingDocMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateRecordingDocMutation, GqlClientCreateRecordingDocMutationVariables>;
export const CreateScenePartDocument = gql`
    mutation CreateScenePart($input: CreateScenePartInput!) {
        createScenePart(input: $input) {
            result
            scenePart {
                id
                updated
            }
        }
    }
`;
export type GqlClientCreateScenePartMutationFn = Apollo.MutationFunction<GqlClientCreateScenePartMutation, GqlClientCreateScenePartMutationVariables>;
export type GqlClientCreateScenePartProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateScenePartMutation, GqlClientCreateScenePartMutationVariables>;
} & TChildProps;
export function withCreateScenePart<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateScenePartMutation, GqlClientCreateScenePartMutationVariables, GqlClientCreateScenePartProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateScenePartMutation, GqlClientCreateScenePartMutationVariables, GqlClientCreateScenePartProps<TChildProps, TDataName>>(
        CreateScenePartDocument,
        {
            alias: "createScenePart",
            ...operationOptions
        }
    );
}

/**
 * __useCreateScenePartMutation__
 *
 * To run a mutation, you first call `useCreateScenePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenePartMutation, { data, loading, error }] = useCreateScenePartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScenePartMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateScenePartMutation, GqlClientCreateScenePartMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateScenePartMutation, GqlClientCreateScenePartMutationVariables>(CreateScenePartDocument, options);
}
export type CreateScenePartMutationHookResult = ReturnType<typeof useCreateScenePartMutation>;
export type CreateScenePartMutationResult = Apollo.MutationResult<GqlClientCreateScenePartMutation>;
export type CreateScenePartMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateScenePartMutation, GqlClientCreateScenePartMutationVariables>;
export const DeleteCustomAnalyticFieldDocument = gql`
    mutation DeleteCustomAnalyticField($input: DeleteCustomAnalyticFieldInput!) {
        deleteCustomAnalyticField(input: $input) {
            result
            program {
                ...Program
            }
        }
    }
    ${ProgramFragmentDoc}
`;
export type GqlClientDeleteCustomAnalyticFieldMutationFn = Apollo.MutationFunction<GqlClientDeleteCustomAnalyticFieldMutation, GqlClientDeleteCustomAnalyticFieldMutationVariables>;
export type GqlClientDeleteCustomAnalyticFieldProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteCustomAnalyticFieldMutation, GqlClientDeleteCustomAnalyticFieldMutationVariables>;
} & TChildProps;
export function withDeleteCustomAnalyticField<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteCustomAnalyticFieldMutation,
        GqlClientDeleteCustomAnalyticFieldMutationVariables,
        GqlClientDeleteCustomAnalyticFieldProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteCustomAnalyticFieldMutation,
        GqlClientDeleteCustomAnalyticFieldMutationVariables,
        GqlClientDeleteCustomAnalyticFieldProps<TChildProps, TDataName>
    >(DeleteCustomAnalyticFieldDocument, {
        alias: "deleteCustomAnalyticField",
        ...operationOptions
    });
}

/**
 * __useDeleteCustomAnalyticFieldMutation__
 *
 * To run a mutation, you first call `useDeleteCustomAnalyticFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomAnalyticFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomAnalyticFieldMutation, { data, loading, error }] = useDeleteCustomAnalyticFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomAnalyticFieldMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteCustomAnalyticFieldMutation, GqlClientDeleteCustomAnalyticFieldMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteCustomAnalyticFieldMutation, GqlClientDeleteCustomAnalyticFieldMutationVariables>(DeleteCustomAnalyticFieldDocument, options);
}
export type DeleteCustomAnalyticFieldMutationHookResult = ReturnType<typeof useDeleteCustomAnalyticFieldMutation>;
export type DeleteCustomAnalyticFieldMutationResult = Apollo.MutationResult<GqlClientDeleteCustomAnalyticFieldMutation>;
export type DeleteCustomAnalyticFieldMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteCustomAnalyticFieldMutation, GqlClientDeleteCustomAnalyticFieldMutationVariables>;
export const DeleteDataTableDocument = gql`
    mutation DeleteDataTable($input: DeleteDataTableInput!) {
        deleteDataTable(input: $input) {
            result
        }
    }
`;
export type GqlClientDeleteDataTableMutationFn = Apollo.MutationFunction<GqlClientDeleteDataTableMutation, GqlClientDeleteDataTableMutationVariables>;
export type GqlClientDeleteDataTableProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteDataTableMutation, GqlClientDeleteDataTableMutationVariables>;
} & TChildProps;
export function withDeleteDataTable<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteDataTableMutation, GqlClientDeleteDataTableMutationVariables, GqlClientDeleteDataTableProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteDataTableMutation, GqlClientDeleteDataTableMutationVariables, GqlClientDeleteDataTableProps<TChildProps, TDataName>>(
        DeleteDataTableDocument,
        {
            alias: "deleteDataTable",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteDataTableMutation__
 *
 * To run a mutation, you first call `useDeleteDataTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataTableMutation, { data, loading, error }] = useDeleteDataTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDataTableMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteDataTableMutation, GqlClientDeleteDataTableMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteDataTableMutation, GqlClientDeleteDataTableMutationVariables>(DeleteDataTableDocument, options);
}
export type DeleteDataTableMutationHookResult = ReturnType<typeof useDeleteDataTableMutation>;
export type DeleteDataTableMutationResult = Apollo.MutationResult<GqlClientDeleteDataTableMutation>;
export type DeleteDataTableMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteDataTableMutation, GqlClientDeleteDataTableMutationVariables>;
export const DeleteEditorProgramDocument = gql`
    mutation DeleteEditorProgram($input: DeleteEditorProgramInput!) {
        deleteEditorProgram(input: $input) {
            result
            account {
                id
                editorPrograms {
                    id
                }
            }
        }
    }
`;
export type GqlClientDeleteEditorProgramMutationFn = Apollo.MutationFunction<GqlClientDeleteEditorProgramMutation, GqlClientDeleteEditorProgramMutationVariables>;
export type GqlClientDeleteEditorProgramProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteEditorProgramMutation, GqlClientDeleteEditorProgramMutationVariables>;
} & TChildProps;
export function withDeleteEditorProgram<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteEditorProgramMutation,
        GqlClientDeleteEditorProgramMutationVariables,
        GqlClientDeleteEditorProgramProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteEditorProgramMutation, GqlClientDeleteEditorProgramMutationVariables, GqlClientDeleteEditorProgramProps<TChildProps, TDataName>>(
        DeleteEditorProgramDocument,
        {
            alias: "deleteEditorProgram",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteEditorProgramMutation__
 *
 * To run a mutation, you first call `useDeleteEditorProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEditorProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEditorProgramMutation, { data, loading, error }] = useDeleteEditorProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEditorProgramMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteEditorProgramMutation, GqlClientDeleteEditorProgramMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteEditorProgramMutation, GqlClientDeleteEditorProgramMutationVariables>(DeleteEditorProgramDocument, options);
}
export type DeleteEditorProgramMutationHookResult = ReturnType<typeof useDeleteEditorProgramMutation>;
export type DeleteEditorProgramMutationResult = Apollo.MutationResult<GqlClientDeleteEditorProgramMutation>;
export type DeleteEditorProgramMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteEditorProgramMutation, GqlClientDeleteEditorProgramMutationVariables>;
export const DeleteMasterAnimationGuidelineDocument = gql`
    mutation DeleteMasterAnimationGuideline($input: DeleteMasterAnimationGuidelineInput!) {
        deleteMasterAnimationGuideline(input: $input) {
            result
        }
    }
`;
export type GqlClientDeleteMasterAnimationGuidelineMutationFn = Apollo.MutationFunction<GqlClientDeleteMasterAnimationGuidelineMutation, GqlClientDeleteMasterAnimationGuidelineMutationVariables>;
export type GqlClientDeleteMasterAnimationGuidelineProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteMasterAnimationGuidelineMutation, GqlClientDeleteMasterAnimationGuidelineMutationVariables>;
} & TChildProps;
export function withDeleteMasterAnimationGuideline<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteMasterAnimationGuidelineMutation,
        GqlClientDeleteMasterAnimationGuidelineMutationVariables,
        GqlClientDeleteMasterAnimationGuidelineProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteMasterAnimationGuidelineMutation,
        GqlClientDeleteMasterAnimationGuidelineMutationVariables,
        GqlClientDeleteMasterAnimationGuidelineProps<TChildProps, TDataName>
    >(DeleteMasterAnimationGuidelineDocument, {
        alias: "deleteMasterAnimationGuideline",
        ...operationOptions
    });
}

/**
 * __useDeleteMasterAnimationGuidelineMutation__
 *
 * To run a mutation, you first call `useDeleteMasterAnimationGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMasterAnimationGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMasterAnimationGuidelineMutation, { data, loading, error }] = useDeleteMasterAnimationGuidelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMasterAnimationGuidelineMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteMasterAnimationGuidelineMutation, GqlClientDeleteMasterAnimationGuidelineMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteMasterAnimationGuidelineMutation, GqlClientDeleteMasterAnimationGuidelineMutationVariables>(DeleteMasterAnimationGuidelineDocument, options);
}
export type DeleteMasterAnimationGuidelineMutationHookResult = ReturnType<typeof useDeleteMasterAnimationGuidelineMutation>;
export type DeleteMasterAnimationGuidelineMutationResult = Apollo.MutationResult<GqlClientDeleteMasterAnimationGuidelineMutation>;
export type DeleteMasterAnimationGuidelineMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteMasterAnimationGuidelineMutation, GqlClientDeleteMasterAnimationGuidelineMutationVariables>;
export const DeletePlaceholderGroupLogicDocument = gql`
    mutation DeletePlaceholderGroupLogic($input: DeletePlaceholderGroupLogicInput!) {
        deletePlaceholderGroupLogic(input: $input) {
            result
        }
    }
`;
export type GqlClientDeletePlaceholderGroupLogicMutationFn = Apollo.MutationFunction<GqlClientDeletePlaceholderGroupLogicMutation, GqlClientDeletePlaceholderGroupLogicMutationVariables>;
export type GqlClientDeletePlaceholderGroupLogicProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeletePlaceholderGroupLogicMutation, GqlClientDeletePlaceholderGroupLogicMutationVariables>;
} & TChildProps;
export function withDeletePlaceholderGroupLogic<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeletePlaceholderGroupLogicMutation,
        GqlClientDeletePlaceholderGroupLogicMutationVariables,
        GqlClientDeletePlaceholderGroupLogicProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeletePlaceholderGroupLogicMutation,
        GqlClientDeletePlaceholderGroupLogicMutationVariables,
        GqlClientDeletePlaceholderGroupLogicProps<TChildProps, TDataName>
    >(DeletePlaceholderGroupLogicDocument, {
        alias: "deletePlaceholderGroupLogic",
        ...operationOptions
    });
}

/**
 * __useDeletePlaceholderGroupLogicMutation__
 *
 * To run a mutation, you first call `useDeletePlaceholderGroupLogicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceholderGroupLogicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceholderGroupLogicMutation, { data, loading, error }] = useDeletePlaceholderGroupLogicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlaceholderGroupLogicMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeletePlaceholderGroupLogicMutation, GqlClientDeletePlaceholderGroupLogicMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeletePlaceholderGroupLogicMutation, GqlClientDeletePlaceholderGroupLogicMutationVariables>(DeletePlaceholderGroupLogicDocument, options);
}
export type DeletePlaceholderGroupLogicMutationHookResult = ReturnType<typeof useDeletePlaceholderGroupLogicMutation>;
export type DeletePlaceholderGroupLogicMutationResult = Apollo.MutationResult<GqlClientDeletePlaceholderGroupLogicMutation>;
export type DeletePlaceholderGroupLogicMutationOptions = Apollo.BaseMutationOptions<GqlClientDeletePlaceholderGroupLogicMutation, GqlClientDeletePlaceholderGroupLogicMutationVariables>;
export const DeletePresetDocument = gql`
    mutation DeletePreset($input: DeletePresetInput!) {
        deletePreset(input: $input) {
            result
        }
    }
`;
export type GqlClientDeletePresetMutationFn = Apollo.MutationFunction<GqlClientDeletePresetMutation, GqlClientDeletePresetMutationVariables>;
export type GqlClientDeletePresetProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeletePresetMutation, GqlClientDeletePresetMutationVariables>;
} & TChildProps;
export function withDeletePreset<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeletePresetMutation, GqlClientDeletePresetMutationVariables, GqlClientDeletePresetProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeletePresetMutation, GqlClientDeletePresetMutationVariables, GqlClientDeletePresetProps<TChildProps, TDataName>>(DeletePresetDocument, {
        alias: "deletePreset",
        ...operationOptions
    });
}

/**
 * __useDeletePresetMutation__
 *
 * To run a mutation, you first call `useDeletePresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePresetMutation, { data, loading, error }] = useDeletePresetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePresetMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeletePresetMutation, GqlClientDeletePresetMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeletePresetMutation, GqlClientDeletePresetMutationVariables>(DeletePresetDocument, options);
}
export type DeletePresetMutationHookResult = ReturnType<typeof useDeletePresetMutation>;
export type DeletePresetMutationResult = Apollo.MutationResult<GqlClientDeletePresetMutation>;
export type DeletePresetMutationOptions = Apollo.BaseMutationOptions<GqlClientDeletePresetMutation, GqlClientDeletePresetMutationVariables>;
export const DeletePrioritizedListLogicDocument = gql`
    mutation DeletePrioritizedListLogic($input: DeletePrioritizedListLogicInput!) {
        deletePrioritizedListLogic(input: $input) {
            result
        }
    }
`;
export type GqlClientDeletePrioritizedListLogicMutationFn = Apollo.MutationFunction<GqlClientDeletePrioritizedListLogicMutation, GqlClientDeletePrioritizedListLogicMutationVariables>;
export type GqlClientDeletePrioritizedListLogicProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeletePrioritizedListLogicMutation, GqlClientDeletePrioritizedListLogicMutationVariables>;
} & TChildProps;
export function withDeletePrioritizedListLogic<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeletePrioritizedListLogicMutation,
        GqlClientDeletePrioritizedListLogicMutationVariables,
        GqlClientDeletePrioritizedListLogicProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeletePrioritizedListLogicMutation,
        GqlClientDeletePrioritizedListLogicMutationVariables,
        GqlClientDeletePrioritizedListLogicProps<TChildProps, TDataName>
    >(DeletePrioritizedListLogicDocument, {
        alias: "deletePrioritizedListLogic",
        ...operationOptions
    });
}

/**
 * __useDeletePrioritizedListLogicMutation__
 *
 * To run a mutation, you first call `useDeletePrioritizedListLogicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrioritizedListLogicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrioritizedListLogicMutation, { data, loading, error }] = useDeletePrioritizedListLogicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePrioritizedListLogicMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeletePrioritizedListLogicMutation, GqlClientDeletePrioritizedListLogicMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeletePrioritizedListLogicMutation, GqlClientDeletePrioritizedListLogicMutationVariables>(DeletePrioritizedListLogicDocument, options);
}
export type DeletePrioritizedListLogicMutationHookResult = ReturnType<typeof useDeletePrioritizedListLogicMutation>;
export type DeletePrioritizedListLogicMutationResult = Apollo.MutationResult<GqlClientDeletePrioritizedListLogicMutation>;
export type DeletePrioritizedListLogicMutationOptions = Apollo.BaseMutationOptions<GqlClientDeletePrioritizedListLogicMutation, GqlClientDeletePrioritizedListLogicMutationVariables>;
export const DeleteSceneDocument = gql`
    mutation DeleteScene($input: DeleteSceneInput!) {
        deleteScene(input: $input) {
            result
            programVersion {
                ...ProgramVersion
            }
        }
    }
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientDeleteSceneMutationFn = Apollo.MutationFunction<GqlClientDeleteSceneMutation, GqlClientDeleteSceneMutationVariables>;
export type GqlClientDeleteSceneProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteSceneMutation, GqlClientDeleteSceneMutationVariables>;
} & TChildProps;
export function withDeleteScene<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteSceneMutation, GqlClientDeleteSceneMutationVariables, GqlClientDeleteSceneProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteSceneMutation, GqlClientDeleteSceneMutationVariables, GqlClientDeleteSceneProps<TChildProps, TDataName>>(DeleteSceneDocument, {
        alias: "deleteScene",
        ...operationOptions
    });
}

/**
 * __useDeleteSceneMutation__
 *
 * To run a mutation, you first call `useDeleteSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSceneMutation, { data, loading, error }] = useDeleteSceneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSceneMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteSceneMutation, GqlClientDeleteSceneMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteSceneMutation, GqlClientDeleteSceneMutationVariables>(DeleteSceneDocument, options);
}
export type DeleteSceneMutationHookResult = ReturnType<typeof useDeleteSceneMutation>;
export type DeleteSceneMutationResult = Apollo.MutationResult<GqlClientDeleteSceneMutation>;
export type DeleteSceneMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteSceneMutation, GqlClientDeleteSceneMutationVariables>;
export const DeleteSceneEntityLogicDocument = gql`
    mutation DeleteSceneEntityLogic($input: DeleteSceneEntityLogicInput!) {
        deleteSceneEntityLogic(input: $input) {
            result
        }
    }
`;
export type GqlClientDeleteSceneEntityLogicMutationFn = Apollo.MutationFunction<GqlClientDeleteSceneEntityLogicMutation, GqlClientDeleteSceneEntityLogicMutationVariables>;
export type GqlClientDeleteSceneEntityLogicProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteSceneEntityLogicMutation, GqlClientDeleteSceneEntityLogicMutationVariables>;
} & TChildProps;
export function withDeleteSceneEntityLogic<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteSceneEntityLogicMutation,
        GqlClientDeleteSceneEntityLogicMutationVariables,
        GqlClientDeleteSceneEntityLogicProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteSceneEntityLogicMutation, GqlClientDeleteSceneEntityLogicMutationVariables, GqlClientDeleteSceneEntityLogicProps<TChildProps, TDataName>>(
        DeleteSceneEntityLogicDocument,
        {
            alias: "deleteSceneEntityLogic",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteSceneEntityLogicMutation__
 *
 * To run a mutation, you first call `useDeleteSceneEntityLogicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSceneEntityLogicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSceneEntityLogicMutation, { data, loading, error }] = useDeleteSceneEntityLogicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSceneEntityLogicMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteSceneEntityLogicMutation, GqlClientDeleteSceneEntityLogicMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteSceneEntityLogicMutation, GqlClientDeleteSceneEntityLogicMutationVariables>(DeleteSceneEntityLogicDocument, options);
}
export type DeleteSceneEntityLogicMutationHookResult = ReturnType<typeof useDeleteSceneEntityLogicMutation>;
export type DeleteSceneEntityLogicMutationResult = Apollo.MutationResult<GqlClientDeleteSceneEntityLogicMutation>;
export type DeleteSceneEntityLogicMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteSceneEntityLogicMutation, GqlClientDeleteSceneEntityLogicMutationVariables>;
export const DeleteScenePartDocument = gql`
    mutation DeleteScenePart($input: DeleteScenePartInput!) {
        deleteScenePart(input: $input) {
            result
            scene {
                ...Scene
            }
        }
    }
    ${SceneFragmentDoc}
`;
export type GqlClientDeleteScenePartMutationFn = Apollo.MutationFunction<GqlClientDeleteScenePartMutation, GqlClientDeleteScenePartMutationVariables>;
export type GqlClientDeleteScenePartProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteScenePartMutation, GqlClientDeleteScenePartMutationVariables>;
} & TChildProps;
export function withDeleteScenePart<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteScenePartMutation, GqlClientDeleteScenePartMutationVariables, GqlClientDeleteScenePartProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteScenePartMutation, GqlClientDeleteScenePartMutationVariables, GqlClientDeleteScenePartProps<TChildProps, TDataName>>(
        DeleteScenePartDocument,
        {
            alias: "deleteScenePart",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteScenePartMutation__
 *
 * To run a mutation, you first call `useDeleteScenePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScenePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScenePartMutation, { data, loading, error }] = useDeleteScenePartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteScenePartMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteScenePartMutation, GqlClientDeleteScenePartMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteScenePartMutation, GqlClientDeleteScenePartMutationVariables>(DeleteScenePartDocument, options);
}
export type DeleteScenePartMutationHookResult = ReturnType<typeof useDeleteScenePartMutation>;
export type DeleteScenePartMutationResult = Apollo.MutationResult<GqlClientDeleteScenePartMutation>;
export type DeleteScenePartMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteScenePartMutation, GqlClientDeleteScenePartMutationVariables>;
export const UpdateAnimationDocument = gql`
    mutation updateAnimation($input: UpdateAnimationInput!, $dryRun: Boolean) {
        updateAnimation(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateAnimationOutputSuccess {
                animation {
                    ...Animation
                }
            }
        }
    }
    ${AnimationFragmentDoc}
`;
export type GqlClientUpdateAnimationMutationFn = Apollo.MutationFunction<GqlClientUpdateAnimationMutation, GqlClientUpdateAnimationMutationVariables>;
export type GqlClientUpdateAnimationProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateAnimationMutation, GqlClientUpdateAnimationMutationVariables>;
} & TChildProps;
export function withUpdateAnimation<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateAnimationMutation, GqlClientUpdateAnimationMutationVariables, GqlClientUpdateAnimationProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateAnimationMutation, GqlClientUpdateAnimationMutationVariables, GqlClientUpdateAnimationProps<TChildProps, TDataName>>(
        UpdateAnimationDocument,
        {
            alias: "updateAnimation",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateAnimationMutation__
 *
 * To run a mutation, you first call `useUpdateAnimationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnimationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnimationMutation, { data, loading, error }] = useUpdateAnimationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateAnimationMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateAnimationMutation, GqlClientUpdateAnimationMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateAnimationMutation, GqlClientUpdateAnimationMutationVariables>(UpdateAnimationDocument, options);
}
export type UpdateAnimationMutationHookResult = ReturnType<typeof useUpdateAnimationMutation>;
export type UpdateAnimationMutationResult = Apollo.MutationResult<GqlClientUpdateAnimationMutation>;
export type UpdateAnimationMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateAnimationMutation, GqlClientUpdateAnimationMutationVariables>;
export const UpdateAssetDocument = gql`
    mutation updateAsset($input: UpdateAssetInput!, $dryRun: Boolean) {
        updateAsset(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateAssetOutputSuccess {
                asset {
                    ...Asset
                }
            }
        }
    }
    ${AssetFragmentDoc}
`;
export type GqlClientUpdateAssetMutationFn = Apollo.MutationFunction<GqlClientUpdateAssetMutation, GqlClientUpdateAssetMutationVariables>;
export type GqlClientUpdateAssetProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateAssetMutation, GqlClientUpdateAssetMutationVariables>;
} & TChildProps;
export function withUpdateAsset<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateAssetMutation, GqlClientUpdateAssetMutationVariables, GqlClientUpdateAssetProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateAssetMutation, GqlClientUpdateAssetMutationVariables, GqlClientUpdateAssetProps<TChildProps, TDataName>>(UpdateAssetDocument, {
        alias: "updateAsset",
        ...operationOptions
    });
}

/**
 * __useUpdateAssetMutation__
 *
 * To run a mutation, you first call `useUpdateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetMutation, { data, loading, error }] = useUpdateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateAssetMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateAssetMutation, GqlClientUpdateAssetMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateAssetMutation, GqlClientUpdateAssetMutationVariables>(UpdateAssetDocument, options);
}
export type UpdateAssetMutationHookResult = ReturnType<typeof useUpdateAssetMutation>;
export type UpdateAssetMutationResult = Apollo.MutationResult<GqlClientUpdateAssetMutation>;
export type UpdateAssetMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateAssetMutation, GqlClientUpdateAssetMutationVariables>;
export const UpdateBuildersSceneDocument = gql`
    mutation UpdateBuildersScene($input: UpdateSceneInput!, $dryRun: Boolean) {
        updateScene(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateSceneOutputSuccess {
                scene {
                    ...Scene
                    programVersion {
                        ...ProgramVersion
                    }
                }
            }
        }
    }
    ${SceneFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientUpdateBuildersSceneMutationFn = Apollo.MutationFunction<GqlClientUpdateBuildersSceneMutation, GqlClientUpdateBuildersSceneMutationVariables>;
export type GqlClientUpdateBuildersSceneProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateBuildersSceneMutation, GqlClientUpdateBuildersSceneMutationVariables>;
} & TChildProps;
export function withUpdateBuildersScene<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateBuildersSceneMutation,
        GqlClientUpdateBuildersSceneMutationVariables,
        GqlClientUpdateBuildersSceneProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateBuildersSceneMutation, GqlClientUpdateBuildersSceneMutationVariables, GqlClientUpdateBuildersSceneProps<TChildProps, TDataName>>(
        UpdateBuildersSceneDocument,
        {
            alias: "updateBuildersScene",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateBuildersSceneMutation__
 *
 * To run a mutation, you first call `useUpdateBuildersSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildersSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildersSceneMutation, { data, loading, error }] = useUpdateBuildersSceneMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateBuildersSceneMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateBuildersSceneMutation, GqlClientUpdateBuildersSceneMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateBuildersSceneMutation, GqlClientUpdateBuildersSceneMutationVariables>(UpdateBuildersSceneDocument, options);
}
export type UpdateBuildersSceneMutationHookResult = ReturnType<typeof useUpdateBuildersSceneMutation>;
export type UpdateBuildersSceneMutationResult = Apollo.MutationResult<GqlClientUpdateBuildersSceneMutation>;
export type UpdateBuildersSceneMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateBuildersSceneMutation, GqlClientUpdateBuildersSceneMutationVariables>;
export const UpdateCustomAnalyticFieldDocument = gql`
    mutation UpdateCustomAnalyticField($input: UpdateCustomAnalyticFieldInput!) {
        updateCustomAnalyticField(input: $input) {
            result
            ... on UpdateCustomAnalyticFieldOutputSuccess {
                customAnalyticField {
                    ...CustomAnalyticField
                }
            }
        }
    }
    ${CustomAnalyticFieldFragmentDoc}
`;
export type GqlClientUpdateCustomAnalyticFieldMutationFn = Apollo.MutationFunction<GqlClientUpdateCustomAnalyticFieldMutation, GqlClientUpdateCustomAnalyticFieldMutationVariables>;
export type GqlClientUpdateCustomAnalyticFieldProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCustomAnalyticFieldMutation, GqlClientUpdateCustomAnalyticFieldMutationVariables>;
} & TChildProps;
export function withUpdateCustomAnalyticField<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateCustomAnalyticFieldMutation,
        GqlClientUpdateCustomAnalyticFieldMutationVariables,
        GqlClientUpdateCustomAnalyticFieldProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateCustomAnalyticFieldMutation,
        GqlClientUpdateCustomAnalyticFieldMutationVariables,
        GqlClientUpdateCustomAnalyticFieldProps<TChildProps, TDataName>
    >(UpdateCustomAnalyticFieldDocument, {
        alias: "updateCustomAnalyticField",
        ...operationOptions
    });
}

/**
 * __useUpdateCustomAnalyticFieldMutation__
 *
 * To run a mutation, you first call `useUpdateCustomAnalyticFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomAnalyticFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomAnalyticFieldMutation, { data, loading, error }] = useUpdateCustomAnalyticFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomAnalyticFieldMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCustomAnalyticFieldMutation, GqlClientUpdateCustomAnalyticFieldMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCustomAnalyticFieldMutation, GqlClientUpdateCustomAnalyticFieldMutationVariables>(UpdateCustomAnalyticFieldDocument, options);
}
export type UpdateCustomAnalyticFieldMutationHookResult = ReturnType<typeof useUpdateCustomAnalyticFieldMutation>;
export type UpdateCustomAnalyticFieldMutationResult = Apollo.MutationResult<GqlClientUpdateCustomAnalyticFieldMutation>;
export type UpdateCustomAnalyticFieldMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateCustomAnalyticFieldMutation, GqlClientUpdateCustomAnalyticFieldMutationVariables>;
export const UpdateDataTableDocument = gql`
    mutation UpdateDataTable($input: UpdateDataTableInput!) {
        updateDataTable(input: $input) {
            result
            ... on UpdateDataTableOutputSuccess {
                dataTable {
                    ...DataTable
                }
            }
        }
    }
    ${DataTableFragmentDoc}
`;
export type GqlClientUpdateDataTableMutationFn = Apollo.MutationFunction<GqlClientUpdateDataTableMutation, GqlClientUpdateDataTableMutationVariables>;
export type GqlClientUpdateDataTableProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateDataTableMutation, GqlClientUpdateDataTableMutationVariables>;
} & TChildProps;
export function withUpdateDataTable<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateDataTableMutation, GqlClientUpdateDataTableMutationVariables, GqlClientUpdateDataTableProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateDataTableMutation, GqlClientUpdateDataTableMutationVariables, GqlClientUpdateDataTableProps<TChildProps, TDataName>>(
        UpdateDataTableDocument,
        {
            alias: "updateDataTable",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateDataTableMutation__
 *
 * To run a mutation, you first call `useUpdateDataTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataTableMutation, { data, loading, error }] = useUpdateDataTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataTableMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateDataTableMutation, GqlClientUpdateDataTableMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateDataTableMutation, GqlClientUpdateDataTableMutationVariables>(UpdateDataTableDocument, options);
}
export type UpdateDataTableMutationHookResult = ReturnType<typeof useUpdateDataTableMutation>;
export type UpdateDataTableMutationResult = Apollo.MutationResult<GqlClientUpdateDataTableMutation>;
export type UpdateDataTableMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateDataTableMutation, GqlClientUpdateDataTableMutationVariables>;
export const UpdateEditorAssetMediaDocument = gql`
    mutation UpdateEditorAssetMedia($input: UpdateEditorAssetMediaInput!) {
        updateEditorAssetMedia(input: $input) {
            result
            asset {
                id
                name
                description
            }
        }
    }
`;
export type GqlClientUpdateEditorAssetMediaMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorAssetMediaMutation, GqlClientUpdateEditorAssetMediaMutationVariables>;
export type GqlClientUpdateEditorAssetMediaProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorAssetMediaMutation, GqlClientUpdateEditorAssetMediaMutationVariables>;
} & TChildProps;
export function withUpdateEditorAssetMedia<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorAssetMediaMutation,
        GqlClientUpdateEditorAssetMediaMutationVariables,
        GqlClientUpdateEditorAssetMediaProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateEditorAssetMediaMutation, GqlClientUpdateEditorAssetMediaMutationVariables, GqlClientUpdateEditorAssetMediaProps<TChildProps, TDataName>>(
        UpdateEditorAssetMediaDocument,
        {
            alias: "updateEditorAssetMedia",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateEditorAssetMediaMutation__
 *
 * To run a mutation, you first call `useUpdateEditorAssetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorAssetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorAssetMediaMutation, { data, loading, error }] = useUpdateEditorAssetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorAssetMediaMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorAssetMediaMutation, GqlClientUpdateEditorAssetMediaMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorAssetMediaMutation, GqlClientUpdateEditorAssetMediaMutationVariables>(UpdateEditorAssetMediaDocument, options);
}
export type UpdateEditorAssetMediaMutationHookResult = ReturnType<typeof useUpdateEditorAssetMediaMutation>;
export type UpdateEditorAssetMediaMutationResult = Apollo.MutationResult<GqlClientUpdateEditorAssetMediaMutation>;
export type UpdateEditorAssetMediaMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorAssetMediaMutation, GqlClientUpdateEditorAssetMediaMutationVariables>;
export const UpdateEditorIsArchivedDocument = gql`
    mutation UpdateEditorIsArchived($input: UpdateEditorIsArchivedInput!) {
        updateEditorIsArchived(input: $input) {
            result
            ... on UpdateEditorIsArchivedOutputSuccess {
                editorProgram {
                    id
                    updated
                    updatedBy
                    anyUpdated
                    anyUpdatedBy
                    isArchive
                    lastArchived
                    landingPage {
                        id
                        isActive
                    }
                }
            }
        }
    }
`;
export type GqlClientUpdateEditorIsArchivedMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorIsArchivedMutation, GqlClientUpdateEditorIsArchivedMutationVariables>;
export type GqlClientUpdateEditorIsArchivedProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorIsArchivedMutation, GqlClientUpdateEditorIsArchivedMutationVariables>;
} & TChildProps;
export function withUpdateEditorIsArchived<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorIsArchivedMutation,
        GqlClientUpdateEditorIsArchivedMutationVariables,
        GqlClientUpdateEditorIsArchivedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateEditorIsArchivedMutation, GqlClientUpdateEditorIsArchivedMutationVariables, GqlClientUpdateEditorIsArchivedProps<TChildProps, TDataName>>(
        UpdateEditorIsArchivedDocument,
        {
            alias: "updateEditorIsArchived",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateEditorIsArchivedMutation__
 *
 * To run a mutation, you first call `useUpdateEditorIsArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorIsArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorIsArchivedMutation, { data, loading, error }] = useUpdateEditorIsArchivedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorIsArchivedMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorIsArchivedMutation, GqlClientUpdateEditorIsArchivedMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorIsArchivedMutation, GqlClientUpdateEditorIsArchivedMutationVariables>(UpdateEditorIsArchivedDocument, options);
}
export type UpdateEditorIsArchivedMutationHookResult = ReturnType<typeof useUpdateEditorIsArchivedMutation>;
export type UpdateEditorIsArchivedMutationResult = Apollo.MutationResult<GqlClientUpdateEditorIsArchivedMutation>;
export type UpdateEditorIsArchivedMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorIsArchivedMutation, GqlClientUpdateEditorIsArchivedMutationVariables>;
export const UpdateEditorIsLiveDocument = gql`
    mutation UpdateEditorIsLive($input: UpdateEditorIsLiveInput!) {
        updateEditorIsLive(input: $input) {
            result
            editorProgram {
                id
                updated
                updatedBy
                anyUpdated
                anyUpdatedBy
                isLive
            }
        }
    }
`;
export type GqlClientUpdateEditorIsLiveMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorIsLiveMutation, GqlClientUpdateEditorIsLiveMutationVariables>;
export type GqlClientUpdateEditorIsLiveProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorIsLiveMutation, GqlClientUpdateEditorIsLiveMutationVariables>;
} & TChildProps;
export function withUpdateEditorIsLive<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorIsLiveMutation,
        GqlClientUpdateEditorIsLiveMutationVariables,
        GqlClientUpdateEditorIsLiveProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateEditorIsLiveMutation, GqlClientUpdateEditorIsLiveMutationVariables, GqlClientUpdateEditorIsLiveProps<TChildProps, TDataName>>(
        UpdateEditorIsLiveDocument,
        {
            alias: "updateEditorIsLive",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateEditorIsLiveMutation__
 *
 * To run a mutation, you first call `useUpdateEditorIsLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorIsLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorIsLiveMutation, { data, loading, error }] = useUpdateEditorIsLiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorIsLiveMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorIsLiveMutation, GqlClientUpdateEditorIsLiveMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorIsLiveMutation, GqlClientUpdateEditorIsLiveMutationVariables>(UpdateEditorIsLiveDocument, options);
}
export type UpdateEditorIsLiveMutationHookResult = ReturnType<typeof useUpdateEditorIsLiveMutation>;
export type UpdateEditorIsLiveMutationResult = Apollo.MutationResult<GqlClientUpdateEditorIsLiveMutation>;
export type UpdateEditorIsLiveMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorIsLiveMutation, GqlClientUpdateEditorIsLiveMutationVariables>;
export const UpdateEditorProgramLifecycleStageDocument = gql`
    mutation UpdateEditorProgramLifecycleStage($input: UpdateEditorProgramLifecycleStageInput!) {
        updateEditorProgramLifecycleStage(input: $input) {
            result
            ... on UpdateEditorProgramLifecycleStageOutputSuccess {
                editorProgram {
                    id
                    lifecycleStage
                }
            }
        }
    }
`;
export type GqlClientUpdateEditorProgramLifecycleStageMutationFn = Apollo.MutationFunction<
    GqlClientUpdateEditorProgramLifecycleStageMutation,
    GqlClientUpdateEditorProgramLifecycleStageMutationVariables
>;
export type GqlClientUpdateEditorProgramLifecycleStageProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorProgramLifecycleStageMutation, GqlClientUpdateEditorProgramLifecycleStageMutationVariables>;
} & TChildProps;
export function withUpdateEditorProgramLifecycleStage<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorProgramLifecycleStageMutation,
        GqlClientUpdateEditorProgramLifecycleStageMutationVariables,
        GqlClientUpdateEditorProgramLifecycleStageProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorProgramLifecycleStageMutation,
        GqlClientUpdateEditorProgramLifecycleStageMutationVariables,
        GqlClientUpdateEditorProgramLifecycleStageProps<TChildProps, TDataName>
    >(UpdateEditorProgramLifecycleStageDocument, {
        alias: "updateEditorProgramLifecycleStage",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorProgramLifecycleStageMutation__
 *
 * To run a mutation, you first call `useUpdateEditorProgramLifecycleStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorProgramLifecycleStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorProgramLifecycleStageMutation, { data, loading, error }] = useUpdateEditorProgramLifecycleStageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorProgramLifecycleStageMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorProgramLifecycleStageMutation, GqlClientUpdateEditorProgramLifecycleStageMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorProgramLifecycleStageMutation, GqlClientUpdateEditorProgramLifecycleStageMutationVariables>(UpdateEditorProgramLifecycleStageDocument, options);
}
export type UpdateEditorProgramLifecycleStageMutationHookResult = ReturnType<typeof useUpdateEditorProgramLifecycleStageMutation>;
export type UpdateEditorProgramLifecycleStageMutationResult = Apollo.MutationResult<GqlClientUpdateEditorProgramLifecycleStageMutation>;
export type UpdateEditorProgramLifecycleStageMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateEditorProgramLifecycleStageMutation,
    GqlClientUpdateEditorProgramLifecycleStageMutationVariables
>;
export const UpdateEditorProgramNameDocument = gql`
    mutation UpdateEditorProgramName($input: UpdateEditorProgramNameInput!) {
        updateEditorProgramName(input: $input) {
            result
            ... on UpdateEditorProgramNameOutputSuccess {
                editorProgram {
                    id
                    name
                }
            }
        }
    }
`;
export type GqlClientUpdateEditorProgramNameMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorProgramNameMutation, GqlClientUpdateEditorProgramNameMutationVariables>;
export type GqlClientUpdateEditorProgramNameProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorProgramNameMutation, GqlClientUpdateEditorProgramNameMutationVariables>;
} & TChildProps;
export function withUpdateEditorProgramName<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorProgramNameMutation,
        GqlClientUpdateEditorProgramNameMutationVariables,
        GqlClientUpdateEditorProgramNameProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorProgramNameMutation,
        GqlClientUpdateEditorProgramNameMutationVariables,
        GqlClientUpdateEditorProgramNameProps<TChildProps, TDataName>
    >(UpdateEditorProgramNameDocument, {
        alias: "updateEditorProgramName",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorProgramNameMutation__
 *
 * To run a mutation, you first call `useUpdateEditorProgramNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorProgramNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorProgramNameMutation, { data, loading, error }] = useUpdateEditorProgramNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorProgramNameMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorProgramNameMutation, GqlClientUpdateEditorProgramNameMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorProgramNameMutation, GqlClientUpdateEditorProgramNameMutationVariables>(UpdateEditorProgramNameDocument, options);
}
export type UpdateEditorProgramNameMutationHookResult = ReturnType<typeof useUpdateEditorProgramNameMutation>;
export type UpdateEditorProgramNameMutationResult = Apollo.MutationResult<GqlClientUpdateEditorProgramNameMutation>;
export type UpdateEditorProgramNameMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorProgramNameMutation, GqlClientUpdateEditorProgramNameMutationVariables>;
export const UpdateEditorProgramThemeDocument = gql`
    mutation UpdateEditorProgramTheme($input: UpdateEditorProgramThemeInput!) {
        updateEditorProgramTheme(input: $input) {
            result
            ... on UpdateEditorProgramThemeOutputSuccess {
                editorProgramVersion {
                    id
                    ccTheme {
                        id
                    }
                    scenes {
                        id
                        ccAnimatedWireframe {
                            id
                        }
                    }
                }
            }
        }
    }
`;
export type GqlClientUpdateEditorProgramThemeMutationFn = Apollo.MutationFunction<GqlClientUpdateEditorProgramThemeMutation, GqlClientUpdateEditorProgramThemeMutationVariables>;
export type GqlClientUpdateEditorProgramThemeProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateEditorProgramThemeMutation, GqlClientUpdateEditorProgramThemeMutationVariables>;
} & TChildProps;
export function withUpdateEditorProgramTheme<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateEditorProgramThemeMutation,
        GqlClientUpdateEditorProgramThemeMutationVariables,
        GqlClientUpdateEditorProgramThemeProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateEditorProgramThemeMutation,
        GqlClientUpdateEditorProgramThemeMutationVariables,
        GqlClientUpdateEditorProgramThemeProps<TChildProps, TDataName>
    >(UpdateEditorProgramThemeDocument, {
        alias: "updateEditorProgramTheme",
        ...operationOptions
    });
}

/**
 * __useUpdateEditorProgramThemeMutation__
 *
 * To run a mutation, you first call `useUpdateEditorProgramThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorProgramThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorProgramThemeMutation, { data, loading, error }] = useUpdateEditorProgramThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditorProgramThemeMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateEditorProgramThemeMutation, GqlClientUpdateEditorProgramThemeMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateEditorProgramThemeMutation, GqlClientUpdateEditorProgramThemeMutationVariables>(UpdateEditorProgramThemeDocument, options);
}
export type UpdateEditorProgramThemeMutationHookResult = ReturnType<typeof useUpdateEditorProgramThemeMutation>;
export type UpdateEditorProgramThemeMutationResult = Apollo.MutationResult<GqlClientUpdateEditorProgramThemeMutation>;
export type UpdateEditorProgramThemeMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateEditorProgramThemeMutation, GqlClientUpdateEditorProgramThemeMutationVariables>;
export const UpdateMasterAnimationGuidelineDocument = gql`
    mutation UpdateMasterAnimationGuideline($input: UpdateMasterAnimationGuidelineInput!, $dryRun: Boolean) {
        updateMasterAnimationGuideline(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateMasterAnimationGuidelineOutputSuccess {
                masterAnimationGuideline {
                    id
                    updated
                    name
                    type
                    logic
                }
            }
        }
    }
`;
export type GqlClientUpdateMasterAnimationGuidelineMutationFn = Apollo.MutationFunction<GqlClientUpdateMasterAnimationGuidelineMutation, GqlClientUpdateMasterAnimationGuidelineMutationVariables>;
export type GqlClientUpdateMasterAnimationGuidelineProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateMasterAnimationGuidelineMutation, GqlClientUpdateMasterAnimationGuidelineMutationVariables>;
} & TChildProps;
export function withUpdateMasterAnimationGuideline<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateMasterAnimationGuidelineMutation,
        GqlClientUpdateMasterAnimationGuidelineMutationVariables,
        GqlClientUpdateMasterAnimationGuidelineProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateMasterAnimationGuidelineMutation,
        GqlClientUpdateMasterAnimationGuidelineMutationVariables,
        GqlClientUpdateMasterAnimationGuidelineProps<TChildProps, TDataName>
    >(UpdateMasterAnimationGuidelineDocument, {
        alias: "updateMasterAnimationGuideline",
        ...operationOptions
    });
}

/**
 * __useUpdateMasterAnimationGuidelineMutation__
 *
 * To run a mutation, you first call `useUpdateMasterAnimationGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasterAnimationGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasterAnimationGuidelineMutation, { data, loading, error }] = useUpdateMasterAnimationGuidelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateMasterAnimationGuidelineMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateMasterAnimationGuidelineMutation, GqlClientUpdateMasterAnimationGuidelineMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateMasterAnimationGuidelineMutation, GqlClientUpdateMasterAnimationGuidelineMutationVariables>(UpdateMasterAnimationGuidelineDocument, options);
}
export type UpdateMasterAnimationGuidelineMutationHookResult = ReturnType<typeof useUpdateMasterAnimationGuidelineMutation>;
export type UpdateMasterAnimationGuidelineMutationResult = Apollo.MutationResult<GqlClientUpdateMasterAnimationGuidelineMutation>;
export type UpdateMasterAnimationGuidelineMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateMasterAnimationGuidelineMutation, GqlClientUpdateMasterAnimationGuidelineMutationVariables>;
export const UpdateMasterDataDocument = gql`
    mutation UpdateMasterData($input: UpdateMasterDataInput!, $dryRun: Boolean) {
        updateMasterData(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateMasterDataOutputSuccess {
                masterAnimationGuidelines {
                    id
                    name
                    type
                    logic
                }
            }
        }
    }
`;
export type GqlClientUpdateMasterDataMutationFn = Apollo.MutationFunction<GqlClientUpdateMasterDataMutation, GqlClientUpdateMasterDataMutationVariables>;
export type GqlClientUpdateMasterDataProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateMasterDataMutation, GqlClientUpdateMasterDataMutationVariables>;
} & TChildProps;
export function withUpdateMasterData<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateMasterDataMutation, GqlClientUpdateMasterDataMutationVariables, GqlClientUpdateMasterDataProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateMasterDataMutation, GqlClientUpdateMasterDataMutationVariables, GqlClientUpdateMasterDataProps<TChildProps, TDataName>>(
        UpdateMasterDataDocument,
        {
            alias: "updateMasterData",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateMasterDataMutation__
 *
 * To run a mutation, you first call `useUpdateMasterDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasterDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasterDataMutation, { data, loading, error }] = useUpdateMasterDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateMasterDataMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateMasterDataMutation, GqlClientUpdateMasterDataMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateMasterDataMutation, GqlClientUpdateMasterDataMutationVariables>(UpdateMasterDataDocument, options);
}
export type UpdateMasterDataMutationHookResult = ReturnType<typeof useUpdateMasterDataMutation>;
export type UpdateMasterDataMutationResult = Apollo.MutationResult<GqlClientUpdateMasterDataMutation>;
export type UpdateMasterDataMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateMasterDataMutation, GqlClientUpdateMasterDataMutationVariables>;
export const UpdateNarrationOrderDocument = gql`
    mutation UpdateNarrationOrder($input: UpdateNarrationOrderInput!, $dryRun: Boolean) {
        updateNarrationOrder(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateNarrationOrderSuccess {
                originalScenePart {
                    id
                    updated
                }
                targetScenePart {
                    id
                    updated
                }
            }
        }
    }
`;
export type GqlClientUpdateNarrationOrderMutationFn = Apollo.MutationFunction<GqlClientUpdateNarrationOrderMutation, GqlClientUpdateNarrationOrderMutationVariables>;
export type GqlClientUpdateNarrationOrderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateNarrationOrderMutation, GqlClientUpdateNarrationOrderMutationVariables>;
} & TChildProps;
export function withUpdateNarrationOrder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateNarrationOrderMutation,
        GqlClientUpdateNarrationOrderMutationVariables,
        GqlClientUpdateNarrationOrderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateNarrationOrderMutation, GqlClientUpdateNarrationOrderMutationVariables, GqlClientUpdateNarrationOrderProps<TChildProps, TDataName>>(
        UpdateNarrationOrderDocument,
        {
            alias: "updateNarrationOrder",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateNarrationOrderMutation__
 *
 * To run a mutation, you first call `useUpdateNarrationOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNarrationOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNarrationOrderMutation, { data, loading, error }] = useUpdateNarrationOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateNarrationOrderMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateNarrationOrderMutation, GqlClientUpdateNarrationOrderMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateNarrationOrderMutation, GqlClientUpdateNarrationOrderMutationVariables>(UpdateNarrationOrderDocument, options);
}
export type UpdateNarrationOrderMutationHookResult = ReturnType<typeof useUpdateNarrationOrderMutation>;
export type UpdateNarrationOrderMutationResult = Apollo.MutationResult<GqlClientUpdateNarrationOrderMutation>;
export type UpdateNarrationOrderMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateNarrationOrderMutation, GqlClientUpdateNarrationOrderMutationVariables>;
export const UpdateNarrationRecordingDocument = gql`
    mutation updateNarrationRecording($input: UpdateNarrationRecordingInput!, $dryRun: Boolean) {
        updateNarrationRecording(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateNarrationRecordingOutputSuccess {
                narrationRecording {
                    ...NarrationRecording
                    narrationRecordingVersions {
                        ...NarrationRecordingVersion
                    }
                }
            }
        }
    }
    ${NarrationRecordingFragmentDoc}
    ${NarrationRecordingVersionFragmentDoc}
`;
export type GqlClientUpdateNarrationRecordingMutationFn = Apollo.MutationFunction<GqlClientUpdateNarrationRecordingMutation, GqlClientUpdateNarrationRecordingMutationVariables>;
export type GqlClientUpdateNarrationRecordingProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateNarrationRecordingMutation, GqlClientUpdateNarrationRecordingMutationVariables>;
} & TChildProps;
export function withUpdateNarrationRecording<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateNarrationRecordingMutation,
        GqlClientUpdateNarrationRecordingMutationVariables,
        GqlClientUpdateNarrationRecordingProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateNarrationRecordingMutation,
        GqlClientUpdateNarrationRecordingMutationVariables,
        GqlClientUpdateNarrationRecordingProps<TChildProps, TDataName>
    >(UpdateNarrationRecordingDocument, {
        alias: "updateNarrationRecording",
        ...operationOptions
    });
}

/**
 * __useUpdateNarrationRecordingMutation__
 *
 * To run a mutation, you first call `useUpdateNarrationRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNarrationRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNarrationRecordingMutation, { data, loading, error }] = useUpdateNarrationRecordingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateNarrationRecordingMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateNarrationRecordingMutation, GqlClientUpdateNarrationRecordingMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateNarrationRecordingMutation, GqlClientUpdateNarrationRecordingMutationVariables>(UpdateNarrationRecordingDocument, options);
}
export type UpdateNarrationRecordingMutationHookResult = ReturnType<typeof useUpdateNarrationRecordingMutation>;
export type UpdateNarrationRecordingMutationResult = Apollo.MutationResult<GqlClientUpdateNarrationRecordingMutation>;
export type UpdateNarrationRecordingMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateNarrationRecordingMutation, GqlClientUpdateNarrationRecordingMutationVariables>;
export const UpdateNarrationRecordingVersionDocument = gql`
    mutation UpdateNarrationRecordingVersion($input: UpdateNarrationRecordingVersionInput!, $dryRun: Boolean) {
        updateNarrationRecordingVersion(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateNarrationRecordingVersionOutputSuccess {
                result
            }
        }
    }
`;
export type GqlClientUpdateNarrationRecordingVersionMutationFn = Apollo.MutationFunction<GqlClientUpdateNarrationRecordingVersionMutation, GqlClientUpdateNarrationRecordingVersionMutationVariables>;
export type GqlClientUpdateNarrationRecordingVersionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateNarrationRecordingVersionMutation, GqlClientUpdateNarrationRecordingVersionMutationVariables>;
} & TChildProps;
export function withUpdateNarrationRecordingVersion<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateNarrationRecordingVersionMutation,
        GqlClientUpdateNarrationRecordingVersionMutationVariables,
        GqlClientUpdateNarrationRecordingVersionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateNarrationRecordingVersionMutation,
        GqlClientUpdateNarrationRecordingVersionMutationVariables,
        GqlClientUpdateNarrationRecordingVersionProps<TChildProps, TDataName>
    >(UpdateNarrationRecordingVersionDocument, {
        alias: "updateNarrationRecordingVersion",
        ...operationOptions
    });
}

/**
 * __useUpdateNarrationRecordingVersionMutation__
 *
 * To run a mutation, you first call `useUpdateNarrationRecordingVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNarrationRecordingVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNarrationRecordingVersionMutation, { data, loading, error }] = useUpdateNarrationRecordingVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateNarrationRecordingVersionMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateNarrationRecordingVersionMutation, GqlClientUpdateNarrationRecordingVersionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateNarrationRecordingVersionMutation, GqlClientUpdateNarrationRecordingVersionMutationVariables>(UpdateNarrationRecordingVersionDocument, options);
}
export type UpdateNarrationRecordingVersionMutationHookResult = ReturnType<typeof useUpdateNarrationRecordingVersionMutation>;
export type UpdateNarrationRecordingVersionMutationResult = Apollo.MutationResult<GqlClientUpdateNarrationRecordingVersionMutation>;
export type UpdateNarrationRecordingVersionMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateNarrationRecordingVersionMutation, GqlClientUpdateNarrationRecordingVersionMutationVariables>;
export const UpdateNarrationsDtaasPrefixKeyDocument = gql`
    mutation updateNarrationsDtaasPrefixKey($input: UpdateNarrationsDtaasPrefixKeyInput!, $dryRun: Boolean) {
        updateNarrationsDtaasPrefixKey(input: $input, dryRun: $dryRun) {
            result
        }
    }
`;
export type GqlClientUpdateNarrationsDtaasPrefixKeyMutationFn = Apollo.MutationFunction<GqlClientUpdateNarrationsDtaasPrefixKeyMutation, GqlClientUpdateNarrationsDtaasPrefixKeyMutationVariables>;
export type GqlClientUpdateNarrationsDtaasPrefixKeyProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateNarrationsDtaasPrefixKeyMutation, GqlClientUpdateNarrationsDtaasPrefixKeyMutationVariables>;
} & TChildProps;
export function withUpdateNarrationsDtaasPrefixKey<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateNarrationsDtaasPrefixKeyMutation,
        GqlClientUpdateNarrationsDtaasPrefixKeyMutationVariables,
        GqlClientUpdateNarrationsDtaasPrefixKeyProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateNarrationsDtaasPrefixKeyMutation,
        GqlClientUpdateNarrationsDtaasPrefixKeyMutationVariables,
        GqlClientUpdateNarrationsDtaasPrefixKeyProps<TChildProps, TDataName>
    >(UpdateNarrationsDtaasPrefixKeyDocument, {
        alias: "updateNarrationsDtaasPrefixKey",
        ...operationOptions
    });
}

/**
 * __useUpdateNarrationsDtaasPrefixKeyMutation__
 *
 * To run a mutation, you first call `useUpdateNarrationsDtaasPrefixKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNarrationsDtaasPrefixKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNarrationsDtaasPrefixKeyMutation, { data, loading, error }] = useUpdateNarrationsDtaasPrefixKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateNarrationsDtaasPrefixKeyMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateNarrationsDtaasPrefixKeyMutation, GqlClientUpdateNarrationsDtaasPrefixKeyMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateNarrationsDtaasPrefixKeyMutation, GqlClientUpdateNarrationsDtaasPrefixKeyMutationVariables>(UpdateNarrationsDtaasPrefixKeyDocument, options);
}
export type UpdateNarrationsDtaasPrefixKeyMutationHookResult = ReturnType<typeof useUpdateNarrationsDtaasPrefixKeyMutation>;
export type UpdateNarrationsDtaasPrefixKeyMutationResult = Apollo.MutationResult<GqlClientUpdateNarrationsDtaasPrefixKeyMutation>;
export type UpdateNarrationsDtaasPrefixKeyMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateNarrationsDtaasPrefixKeyMutation, GqlClientUpdateNarrationsDtaasPrefixKeyMutationVariables>;
export const UpdatePlaceholderGroupLogicDocument = gql`
    mutation UpdatePlaceholderGroupLogic($input: UpdatePlaceholderGroupLogicInput!, $dryRun: Boolean) {
        updatePlaceholderGroupLogic(input: $input, dryRun: $dryRun) {
            result
        }
    }
`;
export type GqlClientUpdatePlaceholderGroupLogicMutationFn = Apollo.MutationFunction<GqlClientUpdatePlaceholderGroupLogicMutation, GqlClientUpdatePlaceholderGroupLogicMutationVariables>;
export type GqlClientUpdatePlaceholderGroupLogicProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdatePlaceholderGroupLogicMutation, GqlClientUpdatePlaceholderGroupLogicMutationVariables>;
} & TChildProps;
export function withUpdatePlaceholderGroupLogic<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdatePlaceholderGroupLogicMutation,
        GqlClientUpdatePlaceholderGroupLogicMutationVariables,
        GqlClientUpdatePlaceholderGroupLogicProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdatePlaceholderGroupLogicMutation,
        GqlClientUpdatePlaceholderGroupLogicMutationVariables,
        GqlClientUpdatePlaceholderGroupLogicProps<TChildProps, TDataName>
    >(UpdatePlaceholderGroupLogicDocument, {
        alias: "updatePlaceholderGroupLogic",
        ...operationOptions
    });
}

/**
 * __useUpdatePlaceholderGroupLogicMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceholderGroupLogicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceholderGroupLogicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceholderGroupLogicMutation, { data, loading, error }] = useUpdatePlaceholderGroupLogicMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdatePlaceholderGroupLogicMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdatePlaceholderGroupLogicMutation, GqlClientUpdatePlaceholderGroupLogicMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdatePlaceholderGroupLogicMutation, GqlClientUpdatePlaceholderGroupLogicMutationVariables>(UpdatePlaceholderGroupLogicDocument, options);
}
export type UpdatePlaceholderGroupLogicMutationHookResult = ReturnType<typeof useUpdatePlaceholderGroupLogicMutation>;
export type UpdatePlaceholderGroupLogicMutationResult = Apollo.MutationResult<GqlClientUpdatePlaceholderGroupLogicMutation>;
export type UpdatePlaceholderGroupLogicMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdatePlaceholderGroupLogicMutation, GqlClientUpdatePlaceholderGroupLogicMutationVariables>;
export const UpdatePresetDocument = gql`
    mutation UpdatePreset($input: UpdatePresetInput!, $dryRun: Boolean) {
        updatePreset(input: $input, dryRun: $dryRun) {
            result
            ... on UpdatePresetOutputSuccess {
                preset {
                    id
                    updated
                }
            }
        }
    }
`;
export type GqlClientUpdatePresetMutationFn = Apollo.MutationFunction<GqlClientUpdatePresetMutation, GqlClientUpdatePresetMutationVariables>;
export type GqlClientUpdatePresetProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdatePresetMutation, GqlClientUpdatePresetMutationVariables>;
} & TChildProps;
export function withUpdatePreset<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdatePresetMutation, GqlClientUpdatePresetMutationVariables, GqlClientUpdatePresetProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdatePresetMutation, GqlClientUpdatePresetMutationVariables, GqlClientUpdatePresetProps<TChildProps, TDataName>>(UpdatePresetDocument, {
        alias: "updatePreset",
        ...operationOptions
    });
}

/**
 * __useUpdatePresetMutation__
 *
 * To run a mutation, you first call `useUpdatePresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePresetMutation, { data, loading, error }] = useUpdatePresetMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdatePresetMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdatePresetMutation, GqlClientUpdatePresetMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdatePresetMutation, GqlClientUpdatePresetMutationVariables>(UpdatePresetDocument, options);
}
export type UpdatePresetMutationHookResult = ReturnType<typeof useUpdatePresetMutation>;
export type UpdatePresetMutationResult = Apollo.MutationResult<GqlClientUpdatePresetMutation>;
export type UpdatePresetMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdatePresetMutation, GqlClientUpdatePresetMutationVariables>;
export const UpdatePrioritizedListLogicDocument = gql`
    mutation UpdatePrioritizedListLogic($input: UpdatePrioritizedListLogicInput!, $dryRun: Boolean) {
        updatePrioritizedListLogic(input: $input, dryRun: $dryRun) {
            result
        }
    }
`;
export type GqlClientUpdatePrioritizedListLogicMutationFn = Apollo.MutationFunction<GqlClientUpdatePrioritizedListLogicMutation, GqlClientUpdatePrioritizedListLogicMutationVariables>;
export type GqlClientUpdatePrioritizedListLogicProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdatePrioritizedListLogicMutation, GqlClientUpdatePrioritizedListLogicMutationVariables>;
} & TChildProps;
export function withUpdatePrioritizedListLogic<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdatePrioritizedListLogicMutation,
        GqlClientUpdatePrioritizedListLogicMutationVariables,
        GqlClientUpdatePrioritizedListLogicProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdatePrioritizedListLogicMutation,
        GqlClientUpdatePrioritizedListLogicMutationVariables,
        GqlClientUpdatePrioritizedListLogicProps<TChildProps, TDataName>
    >(UpdatePrioritizedListLogicDocument, {
        alias: "updatePrioritizedListLogic",
        ...operationOptions
    });
}

/**
 * __useUpdatePrioritizedListLogicMutation__
 *
 * To run a mutation, you first call `useUpdatePrioritizedListLogicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrioritizedListLogicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrioritizedListLogicMutation, { data, loading, error }] = useUpdatePrioritizedListLogicMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdatePrioritizedListLogicMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdatePrioritizedListLogicMutation, GqlClientUpdatePrioritizedListLogicMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdatePrioritizedListLogicMutation, GqlClientUpdatePrioritizedListLogicMutationVariables>(UpdatePrioritizedListLogicDocument, options);
}
export type UpdatePrioritizedListLogicMutationHookResult = ReturnType<typeof useUpdatePrioritizedListLogicMutation>;
export type UpdatePrioritizedListLogicMutationResult = Apollo.MutationResult<GqlClientUpdatePrioritizedListLogicMutation>;
export type UpdatePrioritizedListLogicMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdatePrioritizedListLogicMutation, GqlClientUpdatePrioritizedListLogicMutationVariables>;
export const UpdateProgramDocument = gql`
    mutation UpdateProgram($input: UpdateProgramInput!, $dryRun: Boolean) {
        updateProgram(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateProgramOutputSuccess {
                program {
                    id
                    account {
                        ...Account
                    }
                    updated
                    updatedBy
                    anyUpdated
                    anyUpdatedBy
                    isArchive
                    lastArchived
                }
            }
        }
    }
    ${AccountFragmentDoc}
`;
export type GqlClientUpdateProgramMutationFn = Apollo.MutationFunction<GqlClientUpdateProgramMutation, GqlClientUpdateProgramMutationVariables>;
export type GqlClientUpdateProgramProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateProgramMutation, GqlClientUpdateProgramMutationVariables>;
} & TChildProps;
export function withUpdateProgram<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateProgramMutation, GqlClientUpdateProgramMutationVariables, GqlClientUpdateProgramProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateProgramMutation, GqlClientUpdateProgramMutationVariables, GqlClientUpdateProgramProps<TChildProps, TDataName>>(UpdateProgramDocument, {
        alias: "updateProgram",
        ...operationOptions
    });
}

/**
 * __useUpdateProgramMutation__
 *
 * To run a mutation, you first call `useUpdateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramMutation, { data, loading, error }] = useUpdateProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateProgramMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateProgramMutation, GqlClientUpdateProgramMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateProgramMutation, GqlClientUpdateProgramMutationVariables>(UpdateProgramDocument, options);
}
export type UpdateProgramMutationHookResult = ReturnType<typeof useUpdateProgramMutation>;
export type UpdateProgramMutationResult = Apollo.MutationResult<GqlClientUpdateProgramMutation>;
export type UpdateProgramMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateProgramMutation, GqlClientUpdateProgramMutationVariables>;
export const UpdateProgramAndDraftDocument = gql`
    mutation UpdateProgramAndDraft($input: UpdateProgramAndDraftInput!, $dryRun: Boolean) {
        updateProgramAndDraft(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateProgramAndDraftOutputSuccess {
                program {
                    ...Program
                    programVersions {
                        ...ProgramVersion
                    }
                }
            }
        }
    }
    ${ProgramFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientUpdateProgramAndDraftMutationFn = Apollo.MutationFunction<GqlClientUpdateProgramAndDraftMutation, GqlClientUpdateProgramAndDraftMutationVariables>;
export type GqlClientUpdateProgramAndDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateProgramAndDraftMutation, GqlClientUpdateProgramAndDraftMutationVariables>;
} & TChildProps;
export function withUpdateProgramAndDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateProgramAndDraftMutation,
        GqlClientUpdateProgramAndDraftMutationVariables,
        GqlClientUpdateProgramAndDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateProgramAndDraftMutation, GqlClientUpdateProgramAndDraftMutationVariables, GqlClientUpdateProgramAndDraftProps<TChildProps, TDataName>>(
        UpdateProgramAndDraftDocument,
        {
            alias: "updateProgramAndDraft",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateProgramAndDraftMutation__
 *
 * To run a mutation, you first call `useUpdateProgramAndDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramAndDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramAndDraftMutation, { data, loading, error }] = useUpdateProgramAndDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateProgramAndDraftMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateProgramAndDraftMutation, GqlClientUpdateProgramAndDraftMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateProgramAndDraftMutation, GqlClientUpdateProgramAndDraftMutationVariables>(UpdateProgramAndDraftDocument, options);
}
export type UpdateProgramAndDraftMutationHookResult = ReturnType<typeof useUpdateProgramAndDraftMutation>;
export type UpdateProgramAndDraftMutationResult = Apollo.MutationResult<GqlClientUpdateProgramAndDraftMutation>;
export type UpdateProgramAndDraftMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateProgramAndDraftMutation, GqlClientUpdateProgramAndDraftMutationVariables>;
export const UpdateProgramLastSnapshotNumberDocument = gql`
    mutation UpdateProgramLastSnapshotNumber($input: UpdateProgramLastSnapshotNumberInput!) {
        updateProgramLastSnapshotNumber(input: $input) {
            result
            snapshotNumber
        }
    }
`;
export type GqlClientUpdateProgramLastSnapshotNumberMutationFn = Apollo.MutationFunction<GqlClientUpdateProgramLastSnapshotNumberMutation, GqlClientUpdateProgramLastSnapshotNumberMutationVariables>;
export type GqlClientUpdateProgramLastSnapshotNumberProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateProgramLastSnapshotNumberMutation, GqlClientUpdateProgramLastSnapshotNumberMutationVariables>;
} & TChildProps;
export function withUpdateProgramLastSnapshotNumber<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateProgramLastSnapshotNumberMutation,
        GqlClientUpdateProgramLastSnapshotNumberMutationVariables,
        GqlClientUpdateProgramLastSnapshotNumberProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateProgramLastSnapshotNumberMutation,
        GqlClientUpdateProgramLastSnapshotNumberMutationVariables,
        GqlClientUpdateProgramLastSnapshotNumberProps<TChildProps, TDataName>
    >(UpdateProgramLastSnapshotNumberDocument, {
        alias: "updateProgramLastSnapshotNumber",
        ...operationOptions
    });
}

/**
 * __useUpdateProgramLastSnapshotNumberMutation__
 *
 * To run a mutation, you first call `useUpdateProgramLastSnapshotNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramLastSnapshotNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramLastSnapshotNumberMutation, { data, loading, error }] = useUpdateProgramLastSnapshotNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProgramLastSnapshotNumberMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateProgramLastSnapshotNumberMutation, GqlClientUpdateProgramLastSnapshotNumberMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateProgramLastSnapshotNumberMutation, GqlClientUpdateProgramLastSnapshotNumberMutationVariables>(UpdateProgramLastSnapshotNumberDocument, options);
}
export type UpdateProgramLastSnapshotNumberMutationHookResult = ReturnType<typeof useUpdateProgramLastSnapshotNumberMutation>;
export type UpdateProgramLastSnapshotNumberMutationResult = Apollo.MutationResult<GqlClientUpdateProgramLastSnapshotNumberMutation>;
export type UpdateProgramLastSnapshotNumberMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateProgramLastSnapshotNumberMutation, GqlClientUpdateProgramLastSnapshotNumberMutationVariables>;
export const UpdateProgramVersionDocument = gql`
    mutation UpdateProgramVersion($input: UpdateProgramVersionDraftInput!, $dryRun: Boolean) {
        updateProgramVersionDraft(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateProgramVersionDraftOutputSuccess {
                programVersion {
                    id
                    updated
                    updatedBy
                    program {
                        id
                        updated
                        updatedBy
                    }
                }
            }
        }
    }
`;
export type GqlClientUpdateProgramVersionMutationFn = Apollo.MutationFunction<GqlClientUpdateProgramVersionMutation, GqlClientUpdateProgramVersionMutationVariables>;
export type GqlClientUpdateProgramVersionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateProgramVersionMutation, GqlClientUpdateProgramVersionMutationVariables>;
} & TChildProps;
export function withUpdateProgramVersion<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateProgramVersionMutation,
        GqlClientUpdateProgramVersionMutationVariables,
        GqlClientUpdateProgramVersionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateProgramVersionMutation, GqlClientUpdateProgramVersionMutationVariables, GqlClientUpdateProgramVersionProps<TChildProps, TDataName>>(
        UpdateProgramVersionDocument,
        {
            alias: "updateProgramVersion",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateProgramVersionMutation__
 *
 * To run a mutation, you first call `useUpdateProgramVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramVersionMutation, { data, loading, error }] = useUpdateProgramVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateProgramVersionMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateProgramVersionMutation, GqlClientUpdateProgramVersionMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateProgramVersionMutation, GqlClientUpdateProgramVersionMutationVariables>(UpdateProgramVersionDocument, options);
}
export type UpdateProgramVersionMutationHookResult = ReturnType<typeof useUpdateProgramVersionMutation>;
export type UpdateProgramVersionMutationResult = Apollo.MutationResult<GqlClientUpdateProgramVersionMutation>;
export type UpdateProgramVersionMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateProgramVersionMutation, GqlClientUpdateProgramVersionMutationVariables>;
export const UpdateProgramVersionSnapshotDocument = gql`
    mutation UpdateProgramVersionSnapshot($input: UpdateProgramVersionSnapshotInput!, $dryRun: Boolean) {
        updateProgramVersionSnapshot(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateProgramVersionSnapshotOutputSuccess {
                programVersion {
                    id
                    updated
                }
            }
        }
    }
`;
export type GqlClientUpdateProgramVersionSnapshotMutationFn = Apollo.MutationFunction<GqlClientUpdateProgramVersionSnapshotMutation, GqlClientUpdateProgramVersionSnapshotMutationVariables>;
export type GqlClientUpdateProgramVersionSnapshotProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateProgramVersionSnapshotMutation, GqlClientUpdateProgramVersionSnapshotMutationVariables>;
} & TChildProps;
export function withUpdateProgramVersionSnapshot<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateProgramVersionSnapshotMutation,
        GqlClientUpdateProgramVersionSnapshotMutationVariables,
        GqlClientUpdateProgramVersionSnapshotProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateProgramVersionSnapshotMutation,
        GqlClientUpdateProgramVersionSnapshotMutationVariables,
        GqlClientUpdateProgramVersionSnapshotProps<TChildProps, TDataName>
    >(UpdateProgramVersionSnapshotDocument, {
        alias: "updateProgramVersionSnapshot",
        ...operationOptions
    });
}

/**
 * __useUpdateProgramVersionSnapshotMutation__
 *
 * To run a mutation, you first call `useUpdateProgramVersionSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramVersionSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramVersionSnapshotMutation, { data, loading, error }] = useUpdateProgramVersionSnapshotMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateProgramVersionSnapshotMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateProgramVersionSnapshotMutation, GqlClientUpdateProgramVersionSnapshotMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateProgramVersionSnapshotMutation, GqlClientUpdateProgramVersionSnapshotMutationVariables>(UpdateProgramVersionSnapshotDocument, options);
}
export type UpdateProgramVersionSnapshotMutationHookResult = ReturnType<typeof useUpdateProgramVersionSnapshotMutation>;
export type UpdateProgramVersionSnapshotMutationResult = Apollo.MutationResult<GqlClientUpdateProgramVersionSnapshotMutation>;
export type UpdateProgramVersionSnapshotMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateProgramVersionSnapshotMutation, GqlClientUpdateProgramVersionSnapshotMutationVariables>;
export const UpdateSceneEntityLogicDocument = gql`
    mutation UpdateSceneEntityLogic($input: UpdateSceneEntityLogicInput!, $dryRun: Boolean) {
        updateSceneEntityLogic(input: $input, dryRun: $dryRun) {
            result
        }
    }
`;
export type GqlClientUpdateSceneEntityLogicMutationFn = Apollo.MutationFunction<GqlClientUpdateSceneEntityLogicMutation, GqlClientUpdateSceneEntityLogicMutationVariables>;
export type GqlClientUpdateSceneEntityLogicProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateSceneEntityLogicMutation, GqlClientUpdateSceneEntityLogicMutationVariables>;
} & TChildProps;
export function withUpdateSceneEntityLogic<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateSceneEntityLogicMutation,
        GqlClientUpdateSceneEntityLogicMutationVariables,
        GqlClientUpdateSceneEntityLogicProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateSceneEntityLogicMutation, GqlClientUpdateSceneEntityLogicMutationVariables, GqlClientUpdateSceneEntityLogicProps<TChildProps, TDataName>>(
        UpdateSceneEntityLogicDocument,
        {
            alias: "updateSceneEntityLogic",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateSceneEntityLogicMutation__
 *
 * To run a mutation, you first call `useUpdateSceneEntityLogicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSceneEntityLogicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSceneEntityLogicMutation, { data, loading, error }] = useUpdateSceneEntityLogicMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateSceneEntityLogicMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateSceneEntityLogicMutation, GqlClientUpdateSceneEntityLogicMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateSceneEntityLogicMutation, GqlClientUpdateSceneEntityLogicMutationVariables>(UpdateSceneEntityLogicDocument, options);
}
export type UpdateSceneEntityLogicMutationHookResult = ReturnType<typeof useUpdateSceneEntityLogicMutation>;
export type UpdateSceneEntityLogicMutationResult = Apollo.MutationResult<GqlClientUpdateSceneEntityLogicMutation>;
export type UpdateSceneEntityLogicMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateSceneEntityLogicMutation, GqlClientUpdateSceneEntityLogicMutationVariables>;
export const UpdateScenePartDocument = gql`
    mutation UpdateScenePart($input: UpdateScenePartInput!, $dryRun: Boolean) {
        updateScenePart(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateScenePartOutputSuccess {
                scenePart {
                    id
                    updated
                }
            }
        }
    }
`;
export type GqlClientUpdateScenePartMutationFn = Apollo.MutationFunction<GqlClientUpdateScenePartMutation, GqlClientUpdateScenePartMutationVariables>;
export type GqlClientUpdateScenePartProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateScenePartMutation, GqlClientUpdateScenePartMutationVariables>;
} & TChildProps;
export function withUpdateScenePart<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateScenePartMutation, GqlClientUpdateScenePartMutationVariables, GqlClientUpdateScenePartProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateScenePartMutation, GqlClientUpdateScenePartMutationVariables, GqlClientUpdateScenePartProps<TChildProps, TDataName>>(
        UpdateScenePartDocument,
        {
            alias: "updateScenePart",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateScenePartMutation__
 *
 * To run a mutation, you first call `useUpdateScenePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenePartMutation, { data, loading, error }] = useUpdateScenePartMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateScenePartMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateScenePartMutation, GqlClientUpdateScenePartMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateScenePartMutation, GqlClientUpdateScenePartMutationVariables>(UpdateScenePartDocument, options);
}
export type UpdateScenePartMutationHookResult = ReturnType<typeof useUpdateScenePartMutation>;
export type UpdateScenePartMutationResult = Apollo.MutationResult<GqlClientUpdateScenePartMutation>;
export type UpdateScenePartMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateScenePartMutation, GqlClientUpdateScenePartMutationVariables>;
export const UpdateScenePartWithAllPlaceholdersDocument = gql`
    mutation UpdateScenePartWithAllPlaceholders($input: UpdateScenePartWithAllPlaceholdersInput!, $dryRun: Boolean) {
        updateScenePartWithAllPlaceholders(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateScenePartWithAllPlaceholdersOutputSuccess {
                scenePart {
                    id
                    updated
                }
            }
        }
    }
`;
export type GqlClientUpdateScenePartWithAllPlaceholdersMutationFn = Apollo.MutationFunction<
    GqlClientUpdateScenePartWithAllPlaceholdersMutation,
    GqlClientUpdateScenePartWithAllPlaceholdersMutationVariables
>;
export type GqlClientUpdateScenePartWithAllPlaceholdersProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateScenePartWithAllPlaceholdersMutation, GqlClientUpdateScenePartWithAllPlaceholdersMutationVariables>;
} & TChildProps;
export function withUpdateScenePartWithAllPlaceholders<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateScenePartWithAllPlaceholdersMutation,
        GqlClientUpdateScenePartWithAllPlaceholdersMutationVariables,
        GqlClientUpdateScenePartWithAllPlaceholdersProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateScenePartWithAllPlaceholdersMutation,
        GqlClientUpdateScenePartWithAllPlaceholdersMutationVariables,
        GqlClientUpdateScenePartWithAllPlaceholdersProps<TChildProps, TDataName>
    >(UpdateScenePartWithAllPlaceholdersDocument, {
        alias: "updateScenePartWithAllPlaceholders",
        ...operationOptions
    });
}

/**
 * __useUpdateScenePartWithAllPlaceholdersMutation__
 *
 * To run a mutation, you first call `useUpdateScenePartWithAllPlaceholdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenePartWithAllPlaceholdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenePartWithAllPlaceholdersMutation, { data, loading, error }] = useUpdateScenePartWithAllPlaceholdersMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateScenePartWithAllPlaceholdersMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateScenePartWithAllPlaceholdersMutation, GqlClientUpdateScenePartWithAllPlaceholdersMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateScenePartWithAllPlaceholdersMutation, GqlClientUpdateScenePartWithAllPlaceholdersMutationVariables>(UpdateScenePartWithAllPlaceholdersDocument, options);
}
export type UpdateScenePartWithAllPlaceholdersMutationHookResult = ReturnType<typeof useUpdateScenePartWithAllPlaceholdersMutation>;
export type UpdateScenePartWithAllPlaceholdersMutationResult = Apollo.MutationResult<GqlClientUpdateScenePartWithAllPlaceholdersMutation>;
export type UpdateScenePartWithAllPlaceholdersMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateScenePartWithAllPlaceholdersMutation,
    GqlClientUpdateScenePartWithAllPlaceholdersMutationVariables
>;
export const GetAllDraftsDocument = gql`
    query getAllDrafts {
        accounts {
            id
            name
            displayName
            programs {
                id
                name
                programVersionDrafts {
                    id
                    name
                }
            }
        }
    }
`;
export type GqlClientGetAllDraftsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAllDraftsQuery, GqlClientGetAllDraftsQueryVariables>;
} & TChildProps;
export function withGetAllDrafts<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetAllDraftsQuery, GqlClientGetAllDraftsQueryVariables, GqlClientGetAllDraftsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetAllDraftsQuery, GqlClientGetAllDraftsQueryVariables, GqlClientGetAllDraftsProps<TChildProps, TDataName>>(GetAllDraftsDocument, {
        alias: "getAllDrafts",
        ...operationOptions
    });
}

/**
 * __useGetAllDraftsQuery__
 *
 * To run a query within a React component, call `useGetAllDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDraftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDraftsQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientGetAllDraftsQuery, GqlClientGetAllDraftsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAllDraftsQuery, GqlClientGetAllDraftsQueryVariables>(GetAllDraftsDocument, options);
}
export function useGetAllDraftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAllDraftsQuery, GqlClientGetAllDraftsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAllDraftsQuery, GqlClientGetAllDraftsQueryVariables>(GetAllDraftsDocument, options);
}
export type GetAllDraftsQueryHookResult = ReturnType<typeof useGetAllDraftsQuery>;
export type GetAllDraftsLazyQueryHookResult = ReturnType<typeof useGetAllDraftsLazyQuery>;
export type GetAllDraftsQueryResult = Apollo.QueryResult<GqlClientGetAllDraftsQuery, GqlClientGetAllDraftsQueryVariables>;
export const GetAllProgramsDocument = gql`
    query getAllPrograms {
        accounts {
            id
            name
            displayName
            type
            programs {
                id
                name
                legacyBuilderProgramId
                isEditor
            }
        }
    }
`;
export type GqlClientGetAllProgramsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAllProgramsQuery, GqlClientGetAllProgramsQueryVariables>;
} & TChildProps;
export function withGetAllPrograms<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetAllProgramsQuery, GqlClientGetAllProgramsQueryVariables, GqlClientGetAllProgramsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetAllProgramsQuery, GqlClientGetAllProgramsQueryVariables, GqlClientGetAllProgramsProps<TChildProps, TDataName>>(GetAllProgramsDocument, {
        alias: "getAllPrograms",
        ...operationOptions
    });
}

/**
 * __useGetAllProgramsQuery__
 *
 * To run a query within a React component, call `useGetAllProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProgramsQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientGetAllProgramsQuery, GqlClientGetAllProgramsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAllProgramsQuery, GqlClientGetAllProgramsQueryVariables>(GetAllProgramsDocument, options);
}
export function useGetAllProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAllProgramsQuery, GqlClientGetAllProgramsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAllProgramsQuery, GqlClientGetAllProgramsQueryVariables>(GetAllProgramsDocument, options);
}
export type GetAllProgramsQueryHookResult = ReturnType<typeof useGetAllProgramsQuery>;
export type GetAllProgramsLazyQueryHookResult = ReturnType<typeof useGetAllProgramsLazyQuery>;
export type GetAllProgramsQueryResult = Apollo.QueryResult<GqlClientGetAllProgramsQuery, GqlClientGetAllProgramsQueryVariables>;
export const GetAnimationVersionsDocument = gql`
    query getAnimationVersions($programId: ID!, $programVersionId: ID!, $animationId: ID!) {
        program(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                animation(id: $animationId) {
                    ...Animation
                    animationVersions {
                        ...AnimationVersion
                    }
                }
            }
        }
    }
    ${AnimationFragmentDoc}
    ${AnimationVersionFragmentDoc}
`;
export type GqlClientGetAnimationVersionsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAnimationVersionsQuery, GqlClientGetAnimationVersionsQueryVariables>;
} & TChildProps;
export function withGetAnimationVersions<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetAnimationVersionsQuery,
        GqlClientGetAnimationVersionsQueryVariables,
        GqlClientGetAnimationVersionsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetAnimationVersionsQuery, GqlClientGetAnimationVersionsQueryVariables, GqlClientGetAnimationVersionsProps<TChildProps, TDataName>>(
        GetAnimationVersionsDocument,
        {
            alias: "getAnimationVersions",
            ...operationOptions
        }
    );
}

/**
 * __useGetAnimationVersionsQuery__
 *
 * To run a query within a React component, call `useGetAnimationVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnimationVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnimationVersionsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *      animationId: // value for 'animationId'
 *   },
 * });
 */
export function useGetAnimationVersionsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetAnimationVersionsQuery, GqlClientGetAnimationVersionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAnimationVersionsQuery, GqlClientGetAnimationVersionsQueryVariables>(GetAnimationVersionsDocument, options);
}
export function useGetAnimationVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAnimationVersionsQuery, GqlClientGetAnimationVersionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAnimationVersionsQuery, GqlClientGetAnimationVersionsQueryVariables>(GetAnimationVersionsDocument, options);
}
export type GetAnimationVersionsQueryHookResult = ReturnType<typeof useGetAnimationVersionsQuery>;
export type GetAnimationVersionsLazyQueryHookResult = ReturnType<typeof useGetAnimationVersionsLazyQuery>;
export type GetAnimationVersionsQueryResult = Apollo.QueryResult<GqlClientGetAnimationVersionsQuery, GqlClientGetAnimationVersionsQueryVariables>;
export const GetAssetVersionsDocument = gql`
    query getAssetVersions($programId: ID!, $programVersionId: ID!, $assetId: ID!) {
        program(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                asset(id: $assetId) {
                    ...Asset
                    assetVersions {
                        ...AssetVersion
                    }
                }
            }
        }
    }
    ${AssetFragmentDoc}
    ${AssetVersionFragmentDoc}
`;
export type GqlClientGetAssetVersionsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAssetVersionsQuery, GqlClientGetAssetVersionsQueryVariables>;
} & TChildProps;
export function withGetAssetVersions<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetAssetVersionsQuery, GqlClientGetAssetVersionsQueryVariables, GqlClientGetAssetVersionsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetAssetVersionsQuery, GqlClientGetAssetVersionsQueryVariables, GqlClientGetAssetVersionsProps<TChildProps, TDataName>>(GetAssetVersionsDocument, {
        alias: "getAssetVersions",
        ...operationOptions
    });
}

/**
 * __useGetAssetVersionsQuery__
 *
 * To run a query within a React component, call `useGetAssetVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetVersionsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetAssetVersionsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetAssetVersionsQuery, GqlClientGetAssetVersionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAssetVersionsQuery, GqlClientGetAssetVersionsQueryVariables>(GetAssetVersionsDocument, options);
}
export function useGetAssetVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAssetVersionsQuery, GqlClientGetAssetVersionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAssetVersionsQuery, GqlClientGetAssetVersionsQueryVariables>(GetAssetVersionsDocument, options);
}
export type GetAssetVersionsQueryHookResult = ReturnType<typeof useGetAssetVersionsQuery>;
export type GetAssetVersionsLazyQueryHookResult = ReturnType<typeof useGetAssetVersionsLazyQuery>;
export type GetAssetVersionsQueryResult = Apollo.QueryResult<GqlClientGetAssetVersionsQuery, GqlClientGetAssetVersionsQueryVariables>;
export const GetBuilderProgramDocument = gql`
    query getBuilderProgram($id: ID!, $versionId: ID!) {
        program(id: $id) {
            accountId
            id
            name
            description
            updated
            updatedBy
            isLegacy
            isEditor
            saleForceId
            customAnalyticsOrder
            legacyBuilderProgramId
            publishTarget
            customAnalyticFields {
                id
                name
                logic
                updated
            }
            folder {
                id
                name
            }
            programVersion(id: $versionId) {
                id
                transition
                storySelectionLogic
                programType
                rfr
                rfrUpdateTime
                narrationsVersion
                creativeVersion
                creativeDfde
                creativeNameLabel
                parent1ProgramVersion {
                    id
                }
                scenes {
                    ...SceneForBuilder
                }
                stories {
                    id
                    updated
                    name
                    description
                    activateCaching
                    initialDecisionPoint {
                        id
                    }
                    displaySceneOrder
                    backgroundAssetType
                    decisionPoints {
                        id
                        logic
                    }
                    backgroundVideoLogic
                    backgroundImageLogic
                    backgroundAudioLogic
                    ratioAndQualityLogic
                    numberOfProducts
                    durationOptions
                    ratioAndQualityOptions {
                        ratio
                        quality
                    }
                    narratorName
                }
                masterAnimationGuidelines {
                    id
                    updated
                    type
                    name
                    logic
                }
                feedDataElements {
                    id
                    updated
                    created
                    valueSet
                    name
                    useValueSet
                    description
                    dataType
                    status
                    pii
                }
                derivedDataElements {
                    id
                    updated
                    created
                    name
                    description
                    dataType
                    pii
                    logic
                }
                creativeDataElements {
                    id
                    updated
                    created
                    name
                    description
                    dataType
                    pii
                    valueSet
                    useValueSet
                    assignedToAllStories
                    stories {
                        id
                    }
                }
            }
        }
    }
    ${SceneForBuilderFragmentDoc}
`;
export type GqlClientGetBuilderProgramProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetBuilderProgramQuery, GqlClientGetBuilderProgramQueryVariables>;
} & TChildProps;
export function withGetBuilderProgram<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetBuilderProgramQuery, GqlClientGetBuilderProgramQueryVariables, GqlClientGetBuilderProgramProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetBuilderProgramQuery, GqlClientGetBuilderProgramQueryVariables, GqlClientGetBuilderProgramProps<TChildProps, TDataName>>(
        GetBuilderProgramDocument,
        {
            alias: "getBuilderProgram",
            ...operationOptions
        }
    );
}

/**
 * __useGetBuilderProgramQuery__
 *
 * To run a query within a React component, call `useGetBuilderProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuilderProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuilderProgramQuery({
 *   variables: {
 *      id: // value for 'id'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useGetBuilderProgramQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetBuilderProgramQuery, GqlClientGetBuilderProgramQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetBuilderProgramQuery, GqlClientGetBuilderProgramQueryVariables>(GetBuilderProgramDocument, options);
}
export function useGetBuilderProgramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetBuilderProgramQuery, GqlClientGetBuilderProgramQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetBuilderProgramQuery, GqlClientGetBuilderProgramQueryVariables>(GetBuilderProgramDocument, options);
}
export type GetBuilderProgramQueryHookResult = ReturnType<typeof useGetBuilderProgramQuery>;
export type GetBuilderProgramLazyQueryHookResult = ReturnType<typeof useGetBuilderProgramLazyQuery>;
export type GetBuilderProgramQueryResult = Apollo.QueryResult<GqlClientGetBuilderProgramQuery, GqlClientGetBuilderProgramQueryVariables>;
export const GetNarrationRecordingVersionsDocument = gql`
    query getNarrationRecordingVersions($programId: ID!, $programVersionId: ID!, $narrationRecordingId: ID!) {
        program(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                narrationRecording(id: $narrationRecordingId) {
                    ...NarrationRecording
                    narrationRecordingVersions {
                        ...NarrationRecordingVersion
                    }
                }
            }
        }
    }
    ${NarrationRecordingFragmentDoc}
    ${NarrationRecordingVersionFragmentDoc}
`;
export type GqlClientGetNarrationRecordingVersionsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetNarrationRecordingVersionsQuery, GqlClientGetNarrationRecordingVersionsQueryVariables>;
} & TChildProps;
export function withGetNarrationRecordingVersions<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetNarrationRecordingVersionsQuery,
        GqlClientGetNarrationRecordingVersionsQueryVariables,
        GqlClientGetNarrationRecordingVersionsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientGetNarrationRecordingVersionsQuery,
        GqlClientGetNarrationRecordingVersionsQueryVariables,
        GqlClientGetNarrationRecordingVersionsProps<TChildProps, TDataName>
    >(GetNarrationRecordingVersionsDocument, {
        alias: "getNarrationRecordingVersions",
        ...operationOptions
    });
}

/**
 * __useGetNarrationRecordingVersionsQuery__
 *
 * To run a query within a React component, call `useGetNarrationRecordingVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNarrationRecordingVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNarrationRecordingVersionsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *      narrationRecordingId: // value for 'narrationRecordingId'
 *   },
 * });
 */
export function useGetNarrationRecordingVersionsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetNarrationRecordingVersionsQuery, GqlClientGetNarrationRecordingVersionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetNarrationRecordingVersionsQuery, GqlClientGetNarrationRecordingVersionsQueryVariables>(GetNarrationRecordingVersionsDocument, options);
}
export function useGetNarrationRecordingVersionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetNarrationRecordingVersionsQuery, GqlClientGetNarrationRecordingVersionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetNarrationRecordingVersionsQuery, GqlClientGetNarrationRecordingVersionsQueryVariables>(GetNarrationRecordingVersionsDocument, options);
}
export type GetNarrationRecordingVersionsQueryHookResult = ReturnType<typeof useGetNarrationRecordingVersionsQuery>;
export type GetNarrationRecordingVersionsLazyQueryHookResult = ReturnType<typeof useGetNarrationRecordingVersionsLazyQuery>;
export type GetNarrationRecordingVersionsQueryResult = Apollo.QueryResult<GqlClientGetNarrationRecordingVersionsQuery, GqlClientGetNarrationRecordingVersionsQueryVariables>;
export const GetNarrationRecordingsDocument = gql`
    query getNarrationRecordings($programId: ID!, $programVersionId: ID!) {
        program(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                id
                narrationRecordings {
                    ...NarrationRecording
                }
            }
        }
    }
    ${NarrationRecordingFragmentDoc}
`;
export type GqlClientGetNarrationRecordingsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetNarrationRecordingsQuery, GqlClientGetNarrationRecordingsQueryVariables>;
} & TChildProps;
export function withGetNarrationRecordings<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetNarrationRecordingsQuery,
        GqlClientGetNarrationRecordingsQueryVariables,
        GqlClientGetNarrationRecordingsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetNarrationRecordingsQuery, GqlClientGetNarrationRecordingsQueryVariables, GqlClientGetNarrationRecordingsProps<TChildProps, TDataName>>(
        GetNarrationRecordingsDocument,
        {
            alias: "getNarrationRecordings",
            ...operationOptions
        }
    );
}

/**
 * __useGetNarrationRecordingsQuery__
 *
 * To run a query within a React component, call `useGetNarrationRecordingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNarrationRecordingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNarrationRecordingsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useGetNarrationRecordingsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetNarrationRecordingsQuery, GqlClientGetNarrationRecordingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetNarrationRecordingsQuery, GqlClientGetNarrationRecordingsQueryVariables>(GetNarrationRecordingsDocument, options);
}
export function useGetNarrationRecordingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetNarrationRecordingsQuery, GqlClientGetNarrationRecordingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetNarrationRecordingsQuery, GqlClientGetNarrationRecordingsQueryVariables>(GetNarrationRecordingsDocument, options);
}
export type GetNarrationRecordingsQueryHookResult = ReturnType<typeof useGetNarrationRecordingsQuery>;
export type GetNarrationRecordingsLazyQueryHookResult = ReturnType<typeof useGetNarrationRecordingsLazyQuery>;
export type GetNarrationRecordingsQueryResult = Apollo.QueryResult<GqlClientGetNarrationRecordingsQuery, GqlClientGetNarrationRecordingsQueryVariables>;
export const GetProgramLifecycleHistoryDocument = gql`
    query getProgramLifecycleHistory($programId: ID!) {
        program(id: $programId) {
            accountId
            id
            name
            lifecycleHistory {
                id
                lifecycleStage
                snapshotNumber
                created
                createdBy
            }
        }
    }
`;
export type GqlClientGetProgramLifecycleHistoryProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetProgramLifecycleHistoryQuery, GqlClientGetProgramLifecycleHistoryQueryVariables>;
} & TChildProps;
export function withGetProgramLifecycleHistory<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetProgramLifecycleHistoryQuery,
        GqlClientGetProgramLifecycleHistoryQueryVariables,
        GqlClientGetProgramLifecycleHistoryProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientGetProgramLifecycleHistoryQuery,
        GqlClientGetProgramLifecycleHistoryQueryVariables,
        GqlClientGetProgramLifecycleHistoryProps<TChildProps, TDataName>
    >(GetProgramLifecycleHistoryDocument, {
        alias: "getProgramLifecycleHistory",
        ...operationOptions
    });
}

/**
 * __useGetProgramLifecycleHistoryQuery__
 *
 * To run a query within a React component, call `useGetProgramLifecycleHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramLifecycleHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramLifecycleHistoryQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetProgramLifecycleHistoryQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetProgramLifecycleHistoryQuery, GqlClientGetProgramLifecycleHistoryQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetProgramLifecycleHistoryQuery, GqlClientGetProgramLifecycleHistoryQueryVariables>(GetProgramLifecycleHistoryDocument, options);
}
export function useGetProgramLifecycleHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetProgramLifecycleHistoryQuery, GqlClientGetProgramLifecycleHistoryQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetProgramLifecycleHistoryQuery, GqlClientGetProgramLifecycleHistoryQueryVariables>(GetProgramLifecycleHistoryDocument, options);
}
export type GetProgramLifecycleHistoryQueryHookResult = ReturnType<typeof useGetProgramLifecycleHistoryQuery>;
export type GetProgramLifecycleHistoryLazyQueryHookResult = ReturnType<typeof useGetProgramLifecycleHistoryLazyQuery>;
export type GetProgramLifecycleHistoryQueryResult = Apollo.QueryResult<GqlClientGetProgramLifecycleHistoryQuery, GqlClientGetProgramLifecycleHistoryQueryVariables>;
export const GetProgramPresetValuesDocument = gql`
    query getProgramPresetValues($programId: ID!, $programVersionId: ID!, $presetId: ID!) {
        program(id: $programId) {
            accountId
            id
            name
            programVersion(id: $programVersionId) {
                id
                preset(id: $presetId) {
                    ...Preset
                }
            }
        }
    }
    ${PresetFragmentDoc}
`;
export type GqlClientGetProgramPresetValuesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetProgramPresetValuesQuery, GqlClientGetProgramPresetValuesQueryVariables>;
} & TChildProps;
export function withGetProgramPresetValues<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetProgramPresetValuesQuery,
        GqlClientGetProgramPresetValuesQueryVariables,
        GqlClientGetProgramPresetValuesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetProgramPresetValuesQuery, GqlClientGetProgramPresetValuesQueryVariables, GqlClientGetProgramPresetValuesProps<TChildProps, TDataName>>(
        GetProgramPresetValuesDocument,
        {
            alias: "getProgramPresetValues",
            ...operationOptions
        }
    );
}

/**
 * __useGetProgramPresetValuesQuery__
 *
 * To run a query within a React component, call `useGetProgramPresetValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramPresetValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramPresetValuesQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *      presetId: // value for 'presetId'
 *   },
 * });
 */
export function useGetProgramPresetValuesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetProgramPresetValuesQuery, GqlClientGetProgramPresetValuesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetProgramPresetValuesQuery, GqlClientGetProgramPresetValuesQueryVariables>(GetProgramPresetValuesDocument, options);
}
export function useGetProgramPresetValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetProgramPresetValuesQuery, GqlClientGetProgramPresetValuesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetProgramPresetValuesQuery, GqlClientGetProgramPresetValuesQueryVariables>(GetProgramPresetValuesDocument, options);
}
export type GetProgramPresetValuesQueryHookResult = ReturnType<typeof useGetProgramPresetValuesQuery>;
export type GetProgramPresetValuesLazyQueryHookResult = ReturnType<typeof useGetProgramPresetValuesLazyQuery>;
export type GetProgramPresetValuesQueryResult = Apollo.QueryResult<GqlClientGetProgramPresetValuesQuery, GqlClientGetProgramPresetValuesQueryVariables>;
export const GetProgramPresetsDocument = gql`
    query getProgramPresets($programId: ID!, $programVersionId: ID!) {
        program(id: $programId) {
            accountId
            id
            name
            programVersion(id: $programVersionId) {
                id
                presets {
                    ...Preset
                }
            }
        }
    }
    ${PresetFragmentDoc}
`;
export type GqlClientGetProgramPresetsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetProgramPresetsQuery, GqlClientGetProgramPresetsQueryVariables>;
} & TChildProps;
export function withGetProgramPresets<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetProgramPresetsQuery, GqlClientGetProgramPresetsQueryVariables, GqlClientGetProgramPresetsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetProgramPresetsQuery, GqlClientGetProgramPresetsQueryVariables, GqlClientGetProgramPresetsProps<TChildProps, TDataName>>(
        GetProgramPresetsDocument,
        {
            alias: "getProgramPresets",
            ...operationOptions
        }
    );
}

/**
 * __useGetProgramPresetsQuery__
 *
 * To run a query within a React component, call `useGetProgramPresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramPresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramPresetsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useGetProgramPresetsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetProgramPresetsQuery, GqlClientGetProgramPresetsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetProgramPresetsQuery, GqlClientGetProgramPresetsQueryVariables>(GetProgramPresetsDocument, options);
}
export function useGetProgramPresetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetProgramPresetsQuery, GqlClientGetProgramPresetsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetProgramPresetsQuery, GqlClientGetProgramPresetsQueryVariables>(GetProgramPresetsDocument, options);
}
export type GetProgramPresetsQueryHookResult = ReturnType<typeof useGetProgramPresetsQuery>;
export type GetProgramPresetsLazyQueryHookResult = ReturnType<typeof useGetProgramPresetsLazyQuery>;
export type GetProgramPresetsQueryResult = Apollo.QueryResult<GqlClientGetProgramPresetsQuery, GqlClientGetProgramPresetsQueryVariables>;
export const GetProgramPresetsWithoutValuesDocument = gql`
    query getProgramPresetsWithoutValues($programId: ID!, $programVersionId: ID!) {
        program(id: $programId) {
            accountId
            id
            name
            programVersion(id: $programVersionId) {
                id
                presets {
                    ...PresetWithoutValues
                }
            }
        }
    }
    ${PresetWithoutValuesFragmentDoc}
`;
export type GqlClientGetProgramPresetsWithoutValuesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetProgramPresetsWithoutValuesQuery, GqlClientGetProgramPresetsWithoutValuesQueryVariables>;
} & TChildProps;
export function withGetProgramPresetsWithoutValues<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetProgramPresetsWithoutValuesQuery,
        GqlClientGetProgramPresetsWithoutValuesQueryVariables,
        GqlClientGetProgramPresetsWithoutValuesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientGetProgramPresetsWithoutValuesQuery,
        GqlClientGetProgramPresetsWithoutValuesQueryVariables,
        GqlClientGetProgramPresetsWithoutValuesProps<TChildProps, TDataName>
    >(GetProgramPresetsWithoutValuesDocument, {
        alias: "getProgramPresetsWithoutValues",
        ...operationOptions
    });
}

/**
 * __useGetProgramPresetsWithoutValuesQuery__
 *
 * To run a query within a React component, call `useGetProgramPresetsWithoutValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramPresetsWithoutValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramPresetsWithoutValuesQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useGetProgramPresetsWithoutValuesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetProgramPresetsWithoutValuesQuery, GqlClientGetProgramPresetsWithoutValuesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetProgramPresetsWithoutValuesQuery, GqlClientGetProgramPresetsWithoutValuesQueryVariables>(GetProgramPresetsWithoutValuesDocument, options);
}
export function useGetProgramPresetsWithoutValuesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetProgramPresetsWithoutValuesQuery, GqlClientGetProgramPresetsWithoutValuesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetProgramPresetsWithoutValuesQuery, GqlClientGetProgramPresetsWithoutValuesQueryVariables>(GetProgramPresetsWithoutValuesDocument, options);
}
export type GetProgramPresetsWithoutValuesQueryHookResult = ReturnType<typeof useGetProgramPresetsWithoutValuesQuery>;
export type GetProgramPresetsWithoutValuesLazyQueryHookResult = ReturnType<typeof useGetProgramPresetsWithoutValuesLazyQuery>;
export type GetProgramPresetsWithoutValuesQueryResult = Apollo.QueryResult<GqlClientGetProgramPresetsWithoutValuesQuery, GqlClientGetProgramPresetsWithoutValuesQueryVariables>;
export const GetProgramVersionSnapshotWithAssetsDocument = gql`
    query getProgramVersionSnapshotWithAssets($programId: ID!, $programVersionId: ID!) {
        program(id: $programId) {
            id
            programVersion(id: $programVersionId) {
                ...ProgramVersionSnapshotDetails
                assets {
                    ...Asset
                }
                narrators {
                    ...Narrator
                }
                animations {
                    ...Animation
                }
                dataTables {
                    ...DataTable
                }
                narrationRecordings {
                    ...NarrationRecording
                }
            }
        }
    }
    ${ProgramVersionSnapshotDetailsFragmentDoc}
    ${AssetFragmentDoc}
    ${NarratorFragmentDoc}
    ${AnimationFragmentDoc}
    ${DataTableFragmentDoc}
    ${NarrationRecordingFragmentDoc}
`;
export type GqlClientGetProgramVersionSnapshotWithAssetsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetProgramVersionSnapshotWithAssetsQuery, GqlClientGetProgramVersionSnapshotWithAssetsQueryVariables>;
} & TChildProps;
export function withGetProgramVersionSnapshotWithAssets<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetProgramVersionSnapshotWithAssetsQuery,
        GqlClientGetProgramVersionSnapshotWithAssetsQueryVariables,
        GqlClientGetProgramVersionSnapshotWithAssetsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientGetProgramVersionSnapshotWithAssetsQuery,
        GqlClientGetProgramVersionSnapshotWithAssetsQueryVariables,
        GqlClientGetProgramVersionSnapshotWithAssetsProps<TChildProps, TDataName>
    >(GetProgramVersionSnapshotWithAssetsDocument, {
        alias: "getProgramVersionSnapshotWithAssets",
        ...operationOptions
    });
}

/**
 * __useGetProgramVersionSnapshotWithAssetsQuery__
 *
 * To run a query within a React component, call `useGetProgramVersionSnapshotWithAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramVersionSnapshotWithAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramVersionSnapshotWithAssetsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useGetProgramVersionSnapshotWithAssetsQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientGetProgramVersionSnapshotWithAssetsQuery, GqlClientGetProgramVersionSnapshotWithAssetsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetProgramVersionSnapshotWithAssetsQuery, GqlClientGetProgramVersionSnapshotWithAssetsQueryVariables>(GetProgramVersionSnapshotWithAssetsDocument, options);
}
export function useGetProgramVersionSnapshotWithAssetsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetProgramVersionSnapshotWithAssetsQuery, GqlClientGetProgramVersionSnapshotWithAssetsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetProgramVersionSnapshotWithAssetsQuery, GqlClientGetProgramVersionSnapshotWithAssetsQueryVariables>(GetProgramVersionSnapshotWithAssetsDocument, options);
}
export type GetProgramVersionSnapshotWithAssetsQueryHookResult = ReturnType<typeof useGetProgramVersionSnapshotWithAssetsQuery>;
export type GetProgramVersionSnapshotWithAssetsLazyQueryHookResult = ReturnType<typeof useGetProgramVersionSnapshotWithAssetsLazyQuery>;
export type GetProgramVersionSnapshotWithAssetsQueryResult = Apollo.QueryResult<GqlClientGetProgramVersionSnapshotWithAssetsQuery, GqlClientGetProgramVersionSnapshotWithAssetsQueryVariables>;
export const GetProgramVersionSnapshotsDocument = gql`
    query getProgramVersionSnapshots($programId: ID!) {
        program(id: $programId) {
            id
            programVersionSnapshots {
                ...ProgramVersionSnapshotDetails
            }
        }
    }
    ${ProgramVersionSnapshotDetailsFragmentDoc}
`;
export type GqlClientGetProgramVersionSnapshotsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetProgramVersionSnapshotsQuery, GqlClientGetProgramVersionSnapshotsQueryVariables>;
} & TChildProps;
export function withGetProgramVersionSnapshots<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetProgramVersionSnapshotsQuery,
        GqlClientGetProgramVersionSnapshotsQueryVariables,
        GqlClientGetProgramVersionSnapshotsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientGetProgramVersionSnapshotsQuery,
        GqlClientGetProgramVersionSnapshotsQueryVariables,
        GqlClientGetProgramVersionSnapshotsProps<TChildProps, TDataName>
    >(GetProgramVersionSnapshotsDocument, {
        alias: "getProgramVersionSnapshots",
        ...operationOptions
    });
}

/**
 * __useGetProgramVersionSnapshotsQuery__
 *
 * To run a query within a React component, call `useGetProgramVersionSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramVersionSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramVersionSnapshotsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetProgramVersionSnapshotsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetProgramVersionSnapshotsQuery, GqlClientGetProgramVersionSnapshotsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetProgramVersionSnapshotsQuery, GqlClientGetProgramVersionSnapshotsQueryVariables>(GetProgramVersionSnapshotsDocument, options);
}
export function useGetProgramVersionSnapshotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetProgramVersionSnapshotsQuery, GqlClientGetProgramVersionSnapshotsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetProgramVersionSnapshotsQuery, GqlClientGetProgramVersionSnapshotsQueryVariables>(GetProgramVersionSnapshotsDocument, options);
}
export type GetProgramVersionSnapshotsQueryHookResult = ReturnType<typeof useGetProgramVersionSnapshotsQuery>;
export type GetProgramVersionSnapshotsLazyQueryHookResult = ReturnType<typeof useGetProgramVersionSnapshotsLazyQuery>;
export type GetProgramVersionSnapshotsQueryResult = Apollo.QueryResult<GqlClientGetProgramVersionSnapshotsQuery, GqlClientGetProgramVersionSnapshotsQueryVariables>;
export const GetProgramWithAssetsDocument = gql`
    query getProgramWithAssets($programId: ID!, $programVersionId: ID!) {
        program(id: $programId) {
            accountId
            id
            name
            description
            updated
            updatedBy
            isLegacy
            isEditor
            saleForceId
            customAnalyticsOrder
            legacyBuilderProgramId
            pii
            publishTarget
            folder {
                id
                name
            }
            programVersion(id: $programVersionId) {
                id
                snapshotNumber
                snapshotName
                snapshotComment
                snapshotValidationData
                snapshotted
                snapshottedBy
                transition
                storySelectionLogic
                programType
                rfr
                rfrUpdateTime
                narrationsVersion
                creativeVersion
                creativeDfde
                creativeNameLabel
                editorDataConnectorEnabled
                editorDataConnectorType
                chapteringEnabled
                assets {
                    ...Asset
                }
                narrators {
                    ...Narrator
                }
                animations {
                    ...Animation
                }
                dataTables {
                    ...DataTable
                }
                recordingDocs {
                    ...RecordingDoc
                }
                narrationRecordings {
                    ...NarrationRecording
                }
            }
        }
    }
    ${AssetFragmentDoc}
    ${NarratorFragmentDoc}
    ${AnimationFragmentDoc}
    ${DataTableFragmentDoc}
    ${RecordingDocFragmentDoc}
    ${NarrationRecordingFragmentDoc}
`;
export type GqlClientGetProgramWithAssetsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetProgramWithAssetsQuery, GqlClientGetProgramWithAssetsQueryVariables>;
} & TChildProps;
export function withGetProgramWithAssets<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetProgramWithAssetsQuery,
        GqlClientGetProgramWithAssetsQueryVariables,
        GqlClientGetProgramWithAssetsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetProgramWithAssetsQuery, GqlClientGetProgramWithAssetsQueryVariables, GqlClientGetProgramWithAssetsProps<TChildProps, TDataName>>(
        GetProgramWithAssetsDocument,
        {
            alias: "getProgramWithAssets",
            ...operationOptions
        }
    );
}

/**
 * __useGetProgramWithAssetsQuery__
 *
 * To run a query within a React component, call `useGetProgramWithAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramWithAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramWithAssetsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programVersionId: // value for 'programVersionId'
 *   },
 * });
 */
export function useGetProgramWithAssetsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetProgramWithAssetsQuery, GqlClientGetProgramWithAssetsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetProgramWithAssetsQuery, GqlClientGetProgramWithAssetsQueryVariables>(GetProgramWithAssetsDocument, options);
}
export function useGetProgramWithAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetProgramWithAssetsQuery, GqlClientGetProgramWithAssetsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetProgramWithAssetsQuery, GqlClientGetProgramWithAssetsQueryVariables>(GetProgramWithAssetsDocument, options);
}
export type GetProgramWithAssetsQueryHookResult = ReturnType<typeof useGetProgramWithAssetsQuery>;
export type GetProgramWithAssetsLazyQueryHookResult = ReturnType<typeof useGetProgramWithAssetsLazyQuery>;
export type GetProgramWithAssetsQueryResult = Apollo.QueryResult<GqlClientGetProgramWithAssetsQuery, GqlClientGetProgramWithAssetsQueryVariables>;
export const GetProgramsDynamoMigrationStatusDocument = gql`
    query getProgramsDynamoMigrationStatus {
        programsDynamoMigrationStatus {
            id
            programId
            accountId
            updated
            created
            mostEntities
            assets
            animations
            recordingDocs
            narrators
            lifecycleHistory
            dataTables
        }
    }
`;
export type GqlClientGetProgramsDynamoMigrationStatusProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetProgramsDynamoMigrationStatusQuery, GqlClientGetProgramsDynamoMigrationStatusQueryVariables>;
} & TChildProps;
export function withGetProgramsDynamoMigrationStatus<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetProgramsDynamoMigrationStatusQuery,
        GqlClientGetProgramsDynamoMigrationStatusQueryVariables,
        GqlClientGetProgramsDynamoMigrationStatusProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientGetProgramsDynamoMigrationStatusQuery,
        GqlClientGetProgramsDynamoMigrationStatusQueryVariables,
        GqlClientGetProgramsDynamoMigrationStatusProps<TChildProps, TDataName>
    >(GetProgramsDynamoMigrationStatusDocument, {
        alias: "getProgramsDynamoMigrationStatus",
        ...operationOptions
    });
}

/**
 * __useGetProgramsDynamoMigrationStatusQuery__
 *
 * To run a query within a React component, call `useGetProgramsDynamoMigrationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramsDynamoMigrationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramsDynamoMigrationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProgramsDynamoMigrationStatusQuery(
    baseOptions?: Apollo.QueryHookOptions<GqlClientGetProgramsDynamoMigrationStatusQuery, GqlClientGetProgramsDynamoMigrationStatusQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetProgramsDynamoMigrationStatusQuery, GqlClientGetProgramsDynamoMigrationStatusQueryVariables>(GetProgramsDynamoMigrationStatusDocument, options);
}
export function useGetProgramsDynamoMigrationStatusLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetProgramsDynamoMigrationStatusQuery, GqlClientGetProgramsDynamoMigrationStatusQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetProgramsDynamoMigrationStatusQuery, GqlClientGetProgramsDynamoMigrationStatusQueryVariables>(GetProgramsDynamoMigrationStatusDocument, options);
}
export type GetProgramsDynamoMigrationStatusQueryHookResult = ReturnType<typeof useGetProgramsDynamoMigrationStatusQuery>;
export type GetProgramsDynamoMigrationStatusLazyQueryHookResult = ReturnType<typeof useGetProgramsDynamoMigrationStatusLazyQuery>;
export type GetProgramsDynamoMigrationStatusQueryResult = Apollo.QueryResult<GqlClientGetProgramsDynamoMigrationStatusQuery, GqlClientGetProgramsDynamoMigrationStatusQueryVariables>;
export const AddLifecycleHistoryEntryDocument = gql`
    mutation AddLifecycleHistoryEntry($input: AddLifecycleHistoryEntryInput!) {
        addLifecycleHistoryEntry(input: $input) {
            result
            lifecycleHistoryEntry {
                id
                created
            }
        }
    }
`;
export type GqlClientAddLifecycleHistoryEntryMutationFn = Apollo.MutationFunction<GqlClientAddLifecycleHistoryEntryMutation, GqlClientAddLifecycleHistoryEntryMutationVariables>;
export type GqlClientAddLifecycleHistoryEntryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientAddLifecycleHistoryEntryMutation, GqlClientAddLifecycleHistoryEntryMutationVariables>;
} & TChildProps;
export function withAddLifecycleHistoryEntry<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAddLifecycleHistoryEntryMutation,
        GqlClientAddLifecycleHistoryEntryMutationVariables,
        GqlClientAddLifecycleHistoryEntryProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientAddLifecycleHistoryEntryMutation,
        GqlClientAddLifecycleHistoryEntryMutationVariables,
        GqlClientAddLifecycleHistoryEntryProps<TChildProps, TDataName>
    >(AddLifecycleHistoryEntryDocument, {
        alias: "addLifecycleHistoryEntry",
        ...operationOptions
    });
}

/**
 * __useAddLifecycleHistoryEntryMutation__
 *
 * To run a mutation, you first call `useAddLifecycleHistoryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLifecycleHistoryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLifecycleHistoryEntryMutation, { data, loading, error }] = useAddLifecycleHistoryEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLifecycleHistoryEntryMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientAddLifecycleHistoryEntryMutation, GqlClientAddLifecycleHistoryEntryMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientAddLifecycleHistoryEntryMutation, GqlClientAddLifecycleHistoryEntryMutationVariables>(AddLifecycleHistoryEntryDocument, options);
}
export type AddLifecycleHistoryEntryMutationHookResult = ReturnType<typeof useAddLifecycleHistoryEntryMutation>;
export type AddLifecycleHistoryEntryMutationResult = Apollo.MutationResult<GqlClientAddLifecycleHistoryEntryMutation>;
export type AddLifecycleHistoryEntryMutationOptions = Apollo.BaseMutationOptions<GqlClientAddLifecycleHistoryEntryMutation, GqlClientAddLifecycleHistoryEntryMutationVariables>;
export const ConvertFeedDataElementToDerivedDocument = gql`
    mutation ConvertFeedDataElementToDerived($input: ConvertFeedDataElementToDerivedInput!, $dryRun: Boolean) {
        convertFeedDataElementToDerived(input: $input, dryRun: $dryRun) {
            result
            ... on ConvertFeedDataElementToDerivedOutputSuccess {
                derivedDataElement {
                    id
                    updated
                    updatedBy
                }
            }
        }
    }
`;
export type GqlClientConvertFeedDataElementToDerivedMutationFn = Apollo.MutationFunction<GqlClientConvertFeedDataElementToDerivedMutation, GqlClientConvertFeedDataElementToDerivedMutationVariables>;
export type GqlClientConvertFeedDataElementToDerivedProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientConvertFeedDataElementToDerivedMutation, GqlClientConvertFeedDataElementToDerivedMutationVariables>;
} & TChildProps;
export function withConvertFeedDataElementToDerived<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientConvertFeedDataElementToDerivedMutation,
        GqlClientConvertFeedDataElementToDerivedMutationVariables,
        GqlClientConvertFeedDataElementToDerivedProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientConvertFeedDataElementToDerivedMutation,
        GqlClientConvertFeedDataElementToDerivedMutationVariables,
        GqlClientConvertFeedDataElementToDerivedProps<TChildProps, TDataName>
    >(ConvertFeedDataElementToDerivedDocument, {
        alias: "convertFeedDataElementToDerived",
        ...operationOptions
    });
}

/**
 * __useConvertFeedDataElementToDerivedMutation__
 *
 * To run a mutation, you first call `useConvertFeedDataElementToDerivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertFeedDataElementToDerivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertFeedDataElementToDerivedMutation, { data, loading, error }] = useConvertFeedDataElementToDerivedMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useConvertFeedDataElementToDerivedMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientConvertFeedDataElementToDerivedMutation, GqlClientConvertFeedDataElementToDerivedMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientConvertFeedDataElementToDerivedMutation, GqlClientConvertFeedDataElementToDerivedMutationVariables>(ConvertFeedDataElementToDerivedDocument, options);
}
export type ConvertFeedDataElementToDerivedMutationHookResult = ReturnType<typeof useConvertFeedDataElementToDerivedMutation>;
export type ConvertFeedDataElementToDerivedMutationResult = Apollo.MutationResult<GqlClientConvertFeedDataElementToDerivedMutation>;
export type ConvertFeedDataElementToDerivedMutationOptions = Apollo.BaseMutationOptions<GqlClientConvertFeedDataElementToDerivedMutation, GqlClientConvertFeedDataElementToDerivedMutationVariables>;
export const CopyNarrationDocument = gql`
    mutation CopyNarration($input: CopyNarrationInput!, $legacyId: String) {
        copyNarration(input: $input, legacyId: $legacyId) {
            result
            targetNarration {
                id
                updated
            }
        }
    }
`;
export type GqlClientCopyNarrationMutationFn = Apollo.MutationFunction<GqlClientCopyNarrationMutation, GqlClientCopyNarrationMutationVariables>;
export type GqlClientCopyNarrationProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCopyNarrationMutation, GqlClientCopyNarrationMutationVariables>;
} & TChildProps;
export function withCopyNarration<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCopyNarrationMutation, GqlClientCopyNarrationMutationVariables, GqlClientCopyNarrationProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCopyNarrationMutation, GqlClientCopyNarrationMutationVariables, GqlClientCopyNarrationProps<TChildProps, TDataName>>(CopyNarrationDocument, {
        alias: "copyNarration",
        ...operationOptions
    });
}

/**
 * __useCopyNarrationMutation__
 *
 * To run a mutation, you first call `useCopyNarrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyNarrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyNarrationMutation, { data, loading, error }] = useCopyNarrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      legacyId: // value for 'legacyId'
 *   },
 * });
 */
export function useCopyNarrationMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCopyNarrationMutation, GqlClientCopyNarrationMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCopyNarrationMutation, GqlClientCopyNarrationMutationVariables>(CopyNarrationDocument, options);
}
export type CopyNarrationMutationHookResult = ReturnType<typeof useCopyNarrationMutation>;
export type CopyNarrationMutationResult = Apollo.MutationResult<GqlClientCopyNarrationMutation>;
export type CopyNarrationMutationOptions = Apollo.BaseMutationOptions<GqlClientCopyNarrationMutation, GqlClientCopyNarrationMutationVariables>;
export const CopyStoryDocument = gql`
    mutation CopyStory($input: CopyStoryInput!, $legacyId: String) {
        copyStory(input: $input, legacyId: $legacyId) {
            result
            sourceStory {
                id
                name
            }
            targetStory {
                id
                updated
                name
            }
        }
    }
`;
export type GqlClientCopyStoryMutationFn = Apollo.MutationFunction<GqlClientCopyStoryMutation, GqlClientCopyStoryMutationVariables>;
export type GqlClientCopyStoryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCopyStoryMutation, GqlClientCopyStoryMutationVariables>;
} & TChildProps;
export function withCopyStory<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCopyStoryMutation, GqlClientCopyStoryMutationVariables, GqlClientCopyStoryProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCopyStoryMutation, GqlClientCopyStoryMutationVariables, GqlClientCopyStoryProps<TChildProps, TDataName>>(CopyStoryDocument, {
        alias: "copyStory",
        ...operationOptions
    });
}

/**
 * __useCopyStoryMutation__
 *
 * To run a mutation, you first call `useCopyStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyStoryMutation, { data, loading, error }] = useCopyStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      legacyId: // value for 'legacyId'
 *   },
 * });
 */
export function useCopyStoryMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCopyStoryMutation, GqlClientCopyStoryMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCopyStoryMutation, GqlClientCopyStoryMutationVariables>(CopyStoryDocument, options);
}
export type CopyStoryMutationHookResult = ReturnType<typeof useCopyStoryMutation>;
export type CopyStoryMutationResult = Apollo.MutationResult<GqlClientCopyStoryMutation>;
export type CopyStoryMutationOptions = Apollo.BaseMutationOptions<GqlClientCopyStoryMutation, GqlClientCopyStoryMutationVariables>;
export const CreateCreativeDataElementDocument = gql`
    mutation CreateCreativeDataElement($programVersionId: ID!, $input: CreateCreativeDataElementInput!, $legacyId: String) {
        createCreativeDataElement(programVersionId: $programVersionId, input: $input, legacyId: $legacyId) {
            result
            creativeDataElement {
                id
                updated
                updatedBy
            }
        }
    }
`;
export type GqlClientCreateCreativeDataElementMutationFn = Apollo.MutationFunction<GqlClientCreateCreativeDataElementMutation, GqlClientCreateCreativeDataElementMutationVariables>;
export type GqlClientCreateCreativeDataElementProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateCreativeDataElementMutation, GqlClientCreateCreativeDataElementMutationVariables>;
} & TChildProps;
export function withCreateCreativeDataElement<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateCreativeDataElementMutation,
        GqlClientCreateCreativeDataElementMutationVariables,
        GqlClientCreateCreativeDataElementProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateCreativeDataElementMutation,
        GqlClientCreateCreativeDataElementMutationVariables,
        GqlClientCreateCreativeDataElementProps<TChildProps, TDataName>
    >(CreateCreativeDataElementDocument, {
        alias: "createCreativeDataElement",
        ...operationOptions
    });
}

/**
 * __useCreateCreativeDataElementMutation__
 *
 * To run a mutation, you first call `useCreateCreativeDataElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreativeDataElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreativeDataElementMutation, { data, loading, error }] = useCreateCreativeDataElementMutation({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *      input: // value for 'input'
 *      legacyId: // value for 'legacyId'
 *   },
 * });
 */
export function useCreateCreativeDataElementMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateCreativeDataElementMutation, GqlClientCreateCreativeDataElementMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateCreativeDataElementMutation, GqlClientCreateCreativeDataElementMutationVariables>(CreateCreativeDataElementDocument, options);
}
export type CreateCreativeDataElementMutationHookResult = ReturnType<typeof useCreateCreativeDataElementMutation>;
export type CreateCreativeDataElementMutationResult = Apollo.MutationResult<GqlClientCreateCreativeDataElementMutation>;
export type CreateCreativeDataElementMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateCreativeDataElementMutation, GqlClientCreateCreativeDataElementMutationVariables>;
export const CreateDerivedDataElementDocument = gql`
    mutation CreateDerivedDataElement($programVersionId: ID!, $input: CreateDerivedDataElementInput!, $legacyId: String) {
        createDerivedDataElement(programVersionId: $programVersionId, input: $input, legacyId: $legacyId) {
            result
            derivedDataElement {
                id
                updated
                updatedBy
            }
        }
    }
`;
export type GqlClientCreateDerivedDataElementMutationFn = Apollo.MutationFunction<GqlClientCreateDerivedDataElementMutation, GqlClientCreateDerivedDataElementMutationVariables>;
export type GqlClientCreateDerivedDataElementProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateDerivedDataElementMutation, GqlClientCreateDerivedDataElementMutationVariables>;
} & TChildProps;
export function withCreateDerivedDataElement<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateDerivedDataElementMutation,
        GqlClientCreateDerivedDataElementMutationVariables,
        GqlClientCreateDerivedDataElementProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateDerivedDataElementMutation,
        GqlClientCreateDerivedDataElementMutationVariables,
        GqlClientCreateDerivedDataElementProps<TChildProps, TDataName>
    >(CreateDerivedDataElementDocument, {
        alias: "createDerivedDataElement",
        ...operationOptions
    });
}

/**
 * __useCreateDerivedDataElementMutation__
 *
 * To run a mutation, you first call `useCreateDerivedDataElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDerivedDataElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDerivedDataElementMutation, { data, loading, error }] = useCreateDerivedDataElementMutation({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *      input: // value for 'input'
 *      legacyId: // value for 'legacyId'
 *   },
 * });
 */
export function useCreateDerivedDataElementMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateDerivedDataElementMutation, GqlClientCreateDerivedDataElementMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateDerivedDataElementMutation, GqlClientCreateDerivedDataElementMutationVariables>(CreateDerivedDataElementDocument, options);
}
export type CreateDerivedDataElementMutationHookResult = ReturnType<typeof useCreateDerivedDataElementMutation>;
export type CreateDerivedDataElementMutationResult = Apollo.MutationResult<GqlClientCreateDerivedDataElementMutation>;
export type CreateDerivedDataElementMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateDerivedDataElementMutation, GqlClientCreateDerivedDataElementMutationVariables>;
export const CreateEditorLifecycleEntryDocument = gql`
    mutation CreateEditorLifecycleEntry($input: CreateEditorLifecycleEntryInput!) {
        createEditorLifecycleEntry(input: $input) {
            result
            lifecycleHistoryEntry {
                id
                created
            }
        }
    }
`;
export type GqlClientCreateEditorLifecycleEntryMutationFn = Apollo.MutationFunction<GqlClientCreateEditorLifecycleEntryMutation, GqlClientCreateEditorLifecycleEntryMutationVariables>;
export type GqlClientCreateEditorLifecycleEntryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateEditorLifecycleEntryMutation, GqlClientCreateEditorLifecycleEntryMutationVariables>;
} & TChildProps;
export function withCreateEditorLifecycleEntry<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateEditorLifecycleEntryMutation,
        GqlClientCreateEditorLifecycleEntryMutationVariables,
        GqlClientCreateEditorLifecycleEntryProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateEditorLifecycleEntryMutation,
        GqlClientCreateEditorLifecycleEntryMutationVariables,
        GqlClientCreateEditorLifecycleEntryProps<TChildProps, TDataName>
    >(CreateEditorLifecycleEntryDocument, {
        alias: "createEditorLifecycleEntry",
        ...operationOptions
    });
}

/**
 * __useCreateEditorLifecycleEntryMutation__
 *
 * To run a mutation, you first call `useCreateEditorLifecycleEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditorLifecycleEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditorLifecycleEntryMutation, { data, loading, error }] = useCreateEditorLifecycleEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditorLifecycleEntryMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateEditorLifecycleEntryMutation, GqlClientCreateEditorLifecycleEntryMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateEditorLifecycleEntryMutation, GqlClientCreateEditorLifecycleEntryMutationVariables>(CreateEditorLifecycleEntryDocument, options);
}
export type CreateEditorLifecycleEntryMutationHookResult = ReturnType<typeof useCreateEditorLifecycleEntryMutation>;
export type CreateEditorLifecycleEntryMutationResult = Apollo.MutationResult<GqlClientCreateEditorLifecycleEntryMutation>;
export type CreateEditorLifecycleEntryMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateEditorLifecycleEntryMutation, GqlClientCreateEditorLifecycleEntryMutationVariables>;
export const CreateFeedDataElementDocument = gql`
    mutation CreateFeedDataElement($programVersionId: ID!, $input: CreateFeedDataElementInput!, $legacyId: String) {
        createFeedDataElement(programVersionId: $programVersionId, input: $input, legacyId: $legacyId) {
            result
            feedDataElement {
                id
                updated
                updatedBy
            }
        }
    }
`;
export type GqlClientCreateFeedDataElementMutationFn = Apollo.MutationFunction<GqlClientCreateFeedDataElementMutation, GqlClientCreateFeedDataElementMutationVariables>;
export type GqlClientCreateFeedDataElementProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateFeedDataElementMutation, GqlClientCreateFeedDataElementMutationVariables>;
} & TChildProps;
export function withCreateFeedDataElement<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateFeedDataElementMutation,
        GqlClientCreateFeedDataElementMutationVariables,
        GqlClientCreateFeedDataElementProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateFeedDataElementMutation, GqlClientCreateFeedDataElementMutationVariables, GqlClientCreateFeedDataElementProps<TChildProps, TDataName>>(
        CreateFeedDataElementDocument,
        {
            alias: "createFeedDataElement",
            ...operationOptions
        }
    );
}

/**
 * __useCreateFeedDataElementMutation__
 *
 * To run a mutation, you first call `useCreateFeedDataElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedDataElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedDataElementMutation, { data, loading, error }] = useCreateFeedDataElementMutation({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *      input: // value for 'input'
 *      legacyId: // value for 'legacyId'
 *   },
 * });
 */
export function useCreateFeedDataElementMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateFeedDataElementMutation, GqlClientCreateFeedDataElementMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateFeedDataElementMutation, GqlClientCreateFeedDataElementMutationVariables>(CreateFeedDataElementDocument, options);
}
export type CreateFeedDataElementMutationHookResult = ReturnType<typeof useCreateFeedDataElementMutation>;
export type CreateFeedDataElementMutationResult = Apollo.MutationResult<GqlClientCreateFeedDataElementMutation>;
export type CreateFeedDataElementMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateFeedDataElementMutation, GqlClientCreateFeedDataElementMutationVariables>;
export const CreateNarrationDocument = gql`
    mutation CreateNarration($input: CreateNarrationInput!, $legacyId: String) {
        createNarration(input: $input, legacyId: $legacyId) {
            result
            narration {
                id
                updated
            }
        }
    }
`;
export type GqlClientCreateNarrationMutationFn = Apollo.MutationFunction<GqlClientCreateNarrationMutation, GqlClientCreateNarrationMutationVariables>;
export type GqlClientCreateNarrationProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateNarrationMutation, GqlClientCreateNarrationMutationVariables>;
} & TChildProps;
export function withCreateNarration<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateNarrationMutation, GqlClientCreateNarrationMutationVariables, GqlClientCreateNarrationProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateNarrationMutation, GqlClientCreateNarrationMutationVariables, GqlClientCreateNarrationProps<TChildProps, TDataName>>(
        CreateNarrationDocument,
        {
            alias: "createNarration",
            ...operationOptions
        }
    );
}

/**
 * __useCreateNarrationMutation__
 *
 * To run a mutation, you first call `useCreateNarrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNarrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNarrationMutation, { data, loading, error }] = useCreateNarrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      legacyId: // value for 'legacyId'
 *   },
 * });
 */
export function useCreateNarrationMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateNarrationMutation, GqlClientCreateNarrationMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateNarrationMutation, GqlClientCreateNarrationMutationVariables>(CreateNarrationDocument, options);
}
export type CreateNarrationMutationHookResult = ReturnType<typeof useCreateNarrationMutation>;
export type CreateNarrationMutationResult = Apollo.MutationResult<GqlClientCreateNarrationMutation>;
export type CreateNarrationMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateNarrationMutation, GqlClientCreateNarrationMutationVariables>;
export const CreateSceneWithScenePartDocument = gql`
    mutation CreateSceneWithScenePart($input: CreateSceneWithScenePartInput!) {
        createSceneWithScenePart(input: $input) {
            result
            scene {
                id
                updated
                name
                created
                sceneParts {
                    id
                    updated
                }
            }
        }
    }
`;
export type GqlClientCreateSceneWithScenePartMutationFn = Apollo.MutationFunction<GqlClientCreateSceneWithScenePartMutation, GqlClientCreateSceneWithScenePartMutationVariables>;
export type GqlClientCreateSceneWithScenePartProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateSceneWithScenePartMutation, GqlClientCreateSceneWithScenePartMutationVariables>;
} & TChildProps;
export function withCreateSceneWithScenePart<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateSceneWithScenePartMutation,
        GqlClientCreateSceneWithScenePartMutationVariables,
        GqlClientCreateSceneWithScenePartProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateSceneWithScenePartMutation,
        GqlClientCreateSceneWithScenePartMutationVariables,
        GqlClientCreateSceneWithScenePartProps<TChildProps, TDataName>
    >(CreateSceneWithScenePartDocument, {
        alias: "createSceneWithScenePart",
        ...operationOptions
    });
}

/**
 * __useCreateSceneWithScenePartMutation__
 *
 * To run a mutation, you first call `useCreateSceneWithScenePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSceneWithScenePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSceneWithScenePartMutation, { data, loading, error }] = useCreateSceneWithScenePartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSceneWithScenePartMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateSceneWithScenePartMutation, GqlClientCreateSceneWithScenePartMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateSceneWithScenePartMutation, GqlClientCreateSceneWithScenePartMutationVariables>(CreateSceneWithScenePartDocument, options);
}
export type CreateSceneWithScenePartMutationHookResult = ReturnType<typeof useCreateSceneWithScenePartMutation>;
export type CreateSceneWithScenePartMutationResult = Apollo.MutationResult<GqlClientCreateSceneWithScenePartMutation>;
export type CreateSceneWithScenePartMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateSceneWithScenePartMutation, GqlClientCreateSceneWithScenePartMutationVariables>;
export const CreateStoryWithDecisionPointAndBackgroundAssetsDocument = gql`
    mutation CreateStoryWithDecisionPointAndBackgroundAssets($input: CreateStoryWithDecisionPointAndBackgroundAssetsInput!, $legacyId: String) {
        createStoryWithDecisionPointAndBackgroundAssets(input: $input, legacyId: $legacyId) {
            result
            story {
                id
                updated
            }
        }
    }
`;
export type GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutationFn = Apollo.MutationFunction<
    GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutation,
    GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutationVariables
>;
export type GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutation, GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutationVariables>;
} & TChildProps;
export function withCreateStoryWithDecisionPointAndBackgroundAssets<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutation,
        GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutationVariables,
        GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutation,
        GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutationVariables,
        GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsProps<TChildProps, TDataName>
    >(CreateStoryWithDecisionPointAndBackgroundAssetsDocument, {
        alias: "createStoryWithDecisionPointAndBackgroundAssets",
        ...operationOptions
    });
}

/**
 * __useCreateStoryWithDecisionPointAndBackgroundAssetsMutation__
 *
 * To run a mutation, you first call `useCreateStoryWithDecisionPointAndBackgroundAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryWithDecisionPointAndBackgroundAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryWithDecisionPointAndBackgroundAssetsMutation, { data, loading, error }] = useCreateStoryWithDecisionPointAndBackgroundAssetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      legacyId: // value for 'legacyId'
 *   },
 * });
 */
export function useCreateStoryWithDecisionPointAndBackgroundAssetsMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutation, GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutation, GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutationVariables>(
        CreateStoryWithDecisionPointAndBackgroundAssetsDocument,
        options
    );
}
export type CreateStoryWithDecisionPointAndBackgroundAssetsMutationHookResult = ReturnType<typeof useCreateStoryWithDecisionPointAndBackgroundAssetsMutation>;
export type CreateStoryWithDecisionPointAndBackgroundAssetsMutationResult = Apollo.MutationResult<GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutation>;
export type CreateStoryWithDecisionPointAndBackgroundAssetsMutationOptions = Apollo.BaseMutationOptions<
    GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutation,
    GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutationVariables
>;
export const DeleteNarrationDocument = gql`
    mutation DeleteNarration($input: DeleteNarrationInput!) {
        deleteNarration(input: $input) {
            result
        }
    }
`;
export type GqlClientDeleteNarrationMutationFn = Apollo.MutationFunction<GqlClientDeleteNarrationMutation, GqlClientDeleteNarrationMutationVariables>;
export type GqlClientDeleteNarrationProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteNarrationMutation, GqlClientDeleteNarrationMutationVariables>;
} & TChildProps;
export function withDeleteNarration<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteNarrationMutation, GqlClientDeleteNarrationMutationVariables, GqlClientDeleteNarrationProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteNarrationMutation, GqlClientDeleteNarrationMutationVariables, GqlClientDeleteNarrationProps<TChildProps, TDataName>>(
        DeleteNarrationDocument,
        {
            alias: "deleteNarration",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteNarrationMutation__
 *
 * To run a mutation, you first call `useDeleteNarrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNarrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNarrationMutation, { data, loading, error }] = useDeleteNarrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNarrationMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteNarrationMutation, GqlClientDeleteNarrationMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteNarrationMutation, GqlClientDeleteNarrationMutationVariables>(DeleteNarrationDocument, options);
}
export type DeleteNarrationMutationHookResult = ReturnType<typeof useDeleteNarrationMutation>;
export type DeleteNarrationMutationResult = Apollo.MutationResult<GqlClientDeleteNarrationMutation>;
export type DeleteNarrationMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteNarrationMutation, GqlClientDeleteNarrationMutationVariables>;
export const UpdateCreativeDataElementDocument = gql`
    mutation UpdateCreativeDataElement($input: UpdateCreativeDataElementInput!, $dryRun: Boolean) {
        updateCreativeDataElement(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateCreativeDataElementOutputSuccess {
                creativeDataElement {
                    id
                    updated
                    updatedBy
                }
            }
        }
    }
`;
export type GqlClientUpdateCreativeDataElementMutationFn = Apollo.MutationFunction<GqlClientUpdateCreativeDataElementMutation, GqlClientUpdateCreativeDataElementMutationVariables>;
export type GqlClientUpdateCreativeDataElementProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCreativeDataElementMutation, GqlClientUpdateCreativeDataElementMutationVariables>;
} & TChildProps;
export function withUpdateCreativeDataElement<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateCreativeDataElementMutation,
        GqlClientUpdateCreativeDataElementMutationVariables,
        GqlClientUpdateCreativeDataElementProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateCreativeDataElementMutation,
        GqlClientUpdateCreativeDataElementMutationVariables,
        GqlClientUpdateCreativeDataElementProps<TChildProps, TDataName>
    >(UpdateCreativeDataElementDocument, {
        alias: "updateCreativeDataElement",
        ...operationOptions
    });
}

/**
 * __useUpdateCreativeDataElementMutation__
 *
 * To run a mutation, you first call `useUpdateCreativeDataElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreativeDataElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreativeDataElementMutation, { data, loading, error }] = useUpdateCreativeDataElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateCreativeDataElementMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCreativeDataElementMutation, GqlClientUpdateCreativeDataElementMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCreativeDataElementMutation, GqlClientUpdateCreativeDataElementMutationVariables>(UpdateCreativeDataElementDocument, options);
}
export type UpdateCreativeDataElementMutationHookResult = ReturnType<typeof useUpdateCreativeDataElementMutation>;
export type UpdateCreativeDataElementMutationResult = Apollo.MutationResult<GqlClientUpdateCreativeDataElementMutation>;
export type UpdateCreativeDataElementMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateCreativeDataElementMutation, GqlClientUpdateCreativeDataElementMutationVariables>;
export const UpdateDerivedDataElementDocument = gql`
    mutation UpdateDerivedDataElement($input: UpdateDerivedDataElementInput!, $dryRun: Boolean) {
        updateDerivedDataElement(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateDerivedDataElementOutputSuccess {
                derivedDataElement {
                    id
                    updated
                    updatedBy
                }
            }
        }
    }
`;
export type GqlClientUpdateDerivedDataElementMutationFn = Apollo.MutationFunction<GqlClientUpdateDerivedDataElementMutation, GqlClientUpdateDerivedDataElementMutationVariables>;
export type GqlClientUpdateDerivedDataElementProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateDerivedDataElementMutation, GqlClientUpdateDerivedDataElementMutationVariables>;
} & TChildProps;
export function withUpdateDerivedDataElement<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateDerivedDataElementMutation,
        GqlClientUpdateDerivedDataElementMutationVariables,
        GqlClientUpdateDerivedDataElementProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateDerivedDataElementMutation,
        GqlClientUpdateDerivedDataElementMutationVariables,
        GqlClientUpdateDerivedDataElementProps<TChildProps, TDataName>
    >(UpdateDerivedDataElementDocument, {
        alias: "updateDerivedDataElement",
        ...operationOptions
    });
}

/**
 * __useUpdateDerivedDataElementMutation__
 *
 * To run a mutation, you first call `useUpdateDerivedDataElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDerivedDataElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDerivedDataElementMutation, { data, loading, error }] = useUpdateDerivedDataElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateDerivedDataElementMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateDerivedDataElementMutation, GqlClientUpdateDerivedDataElementMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateDerivedDataElementMutation, GqlClientUpdateDerivedDataElementMutationVariables>(UpdateDerivedDataElementDocument, options);
}
export type UpdateDerivedDataElementMutationHookResult = ReturnType<typeof useUpdateDerivedDataElementMutation>;
export type UpdateDerivedDataElementMutationResult = Apollo.MutationResult<GqlClientUpdateDerivedDataElementMutation>;
export type UpdateDerivedDataElementMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateDerivedDataElementMutation, GqlClientUpdateDerivedDataElementMutationVariables>;
export const UpdateFeedDataElementDocument = gql`
    mutation UpdateFeedDataElement($input: UpdateFeedDataElementInput!, $dryRun: Boolean) {
        updateFeedDataElement(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateFeedDataElementOutputSuccess {
                feedDataElement {
                    id
                    updated
                    updatedBy
                }
            }
        }
    }
`;
export type GqlClientUpdateFeedDataElementMutationFn = Apollo.MutationFunction<GqlClientUpdateFeedDataElementMutation, GqlClientUpdateFeedDataElementMutationVariables>;
export type GqlClientUpdateFeedDataElementProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateFeedDataElementMutation, GqlClientUpdateFeedDataElementMutationVariables>;
} & TChildProps;
export function withUpdateFeedDataElement<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateFeedDataElementMutation,
        GqlClientUpdateFeedDataElementMutationVariables,
        GqlClientUpdateFeedDataElementProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateFeedDataElementMutation, GqlClientUpdateFeedDataElementMutationVariables, GqlClientUpdateFeedDataElementProps<TChildProps, TDataName>>(
        UpdateFeedDataElementDocument,
        {
            alias: "updateFeedDataElement",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateFeedDataElementMutation__
 *
 * To run a mutation, you first call `useUpdateFeedDataElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedDataElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedDataElementMutation, { data, loading, error }] = useUpdateFeedDataElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateFeedDataElementMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateFeedDataElementMutation, GqlClientUpdateFeedDataElementMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateFeedDataElementMutation, GqlClientUpdateFeedDataElementMutationVariables>(UpdateFeedDataElementDocument, options);
}
export type UpdateFeedDataElementMutationHookResult = ReturnType<typeof useUpdateFeedDataElementMutation>;
export type UpdateFeedDataElementMutationResult = Apollo.MutationResult<GqlClientUpdateFeedDataElementMutation>;
export type UpdateFeedDataElementMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateFeedDataElementMutation, GqlClientUpdateFeedDataElementMutationVariables>;
export const UpdateNarrationDocument = gql`
    mutation UpdateNarration($input: UpdateNarrationInput!, $dryRun: Boolean) {
        updateNarration(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateNarrationOutputSuccess {
                narration {
                    id
                    updated
                }
            }
        }
    }
`;
export type GqlClientUpdateNarrationMutationFn = Apollo.MutationFunction<GqlClientUpdateNarrationMutation, GqlClientUpdateNarrationMutationVariables>;
export type GqlClientUpdateNarrationProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateNarrationMutation, GqlClientUpdateNarrationMutationVariables>;
} & TChildProps;
export function withUpdateNarration<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateNarrationMutation, GqlClientUpdateNarrationMutationVariables, GqlClientUpdateNarrationProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateNarrationMutation, GqlClientUpdateNarrationMutationVariables, GqlClientUpdateNarrationProps<TChildProps, TDataName>>(
        UpdateNarrationDocument,
        {
            alias: "updateNarration",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateNarrationMutation__
 *
 * To run a mutation, you first call `useUpdateNarrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNarrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNarrationMutation, { data, loading, error }] = useUpdateNarrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateNarrationMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateNarrationMutation, GqlClientUpdateNarrationMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateNarrationMutation, GqlClientUpdateNarrationMutationVariables>(UpdateNarrationDocument, options);
}
export type UpdateNarrationMutationHookResult = ReturnType<typeof useUpdateNarrationMutation>;
export type UpdateNarrationMutationResult = Apollo.MutationResult<GqlClientUpdateNarrationMutation>;
export type UpdateNarrationMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateNarrationMutation, GqlClientUpdateNarrationMutationVariables>;
export const UpdateStoryForBuilderDocument = gql`
    mutation UpdateStoryForBuilder($input: UpdateStoryForBuilderInput!, $dryRun: Boolean) {
        updateStoryForBuilder(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateStoryForBuilderOutputSuccess {
                story {
                    id
                    updated
                }
            }
        }
    }
`;
export type GqlClientUpdateStoryForBuilderMutationFn = Apollo.MutationFunction<GqlClientUpdateStoryForBuilderMutation, GqlClientUpdateStoryForBuilderMutationVariables>;
export type GqlClientUpdateStoryForBuilderProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateStoryForBuilderMutation, GqlClientUpdateStoryForBuilderMutationVariables>;
} & TChildProps;
export function withUpdateStoryForBuilder<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateStoryForBuilderMutation,
        GqlClientUpdateStoryForBuilderMutationVariables,
        GqlClientUpdateStoryForBuilderProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateStoryForBuilderMutation, GqlClientUpdateStoryForBuilderMutationVariables, GqlClientUpdateStoryForBuilderProps<TChildProps, TDataName>>(
        UpdateStoryForBuilderDocument,
        {
            alias: "updateStoryForBuilder",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateStoryForBuilderMutation__
 *
 * To run a mutation, you first call `useUpdateStoryForBuilderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryForBuilderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryForBuilderMutation, { data, loading, error }] = useUpdateStoryForBuilderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateStoryForBuilderMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateStoryForBuilderMutation, GqlClientUpdateStoryForBuilderMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateStoryForBuilderMutation, GqlClientUpdateStoryForBuilderMutationVariables>(UpdateStoryForBuilderDocument, options);
}
export type UpdateStoryForBuilderMutationHookResult = ReturnType<typeof useUpdateStoryForBuilderMutation>;
export type UpdateStoryForBuilderMutationResult = Apollo.MutationResult<GqlClientUpdateStoryForBuilderMutation>;
export type UpdateStoryForBuilderMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateStoryForBuilderMutation, GqlClientUpdateStoryForBuilderMutationVariables>;
export const UpdateStoryWithDecisionPointsDocument = gql`
    mutation UpdateStoryWithDecisionPoints($input: UpdateStoryWithDecisionPointsInput!, $dryRun: Boolean) {
        updateStoryWithDecisionPoints(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateStoryWithDecisionPointsOutputSuccess {
                story {
                    id
                    updated
                }
            }
        }
    }
`;
export type GqlClientUpdateStoryWithDecisionPointsMutationFn = Apollo.MutationFunction<GqlClientUpdateStoryWithDecisionPointsMutation, GqlClientUpdateStoryWithDecisionPointsMutationVariables>;
export type GqlClientUpdateStoryWithDecisionPointsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateStoryWithDecisionPointsMutation, GqlClientUpdateStoryWithDecisionPointsMutationVariables>;
} & TChildProps;
export function withUpdateStoryWithDecisionPoints<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateStoryWithDecisionPointsMutation,
        GqlClientUpdateStoryWithDecisionPointsMutationVariables,
        GqlClientUpdateStoryWithDecisionPointsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateStoryWithDecisionPointsMutation,
        GqlClientUpdateStoryWithDecisionPointsMutationVariables,
        GqlClientUpdateStoryWithDecisionPointsProps<TChildProps, TDataName>
    >(UpdateStoryWithDecisionPointsDocument, {
        alias: "updateStoryWithDecisionPoints",
        ...operationOptions
    });
}

/**
 * __useUpdateStoryWithDecisionPointsMutation__
 *
 * To run a mutation, you first call `useUpdateStoryWithDecisionPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryWithDecisionPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryWithDecisionPointsMutation, { data, loading, error }] = useUpdateStoryWithDecisionPointsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateStoryWithDecisionPointsMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateStoryWithDecisionPointsMutation, GqlClientUpdateStoryWithDecisionPointsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateStoryWithDecisionPointsMutation, GqlClientUpdateStoryWithDecisionPointsMutationVariables>(UpdateStoryWithDecisionPointsDocument, options);
}
export type UpdateStoryWithDecisionPointsMutationHookResult = ReturnType<typeof useUpdateStoryWithDecisionPointsMutation>;
export type UpdateStoryWithDecisionPointsMutationResult = Apollo.MutationResult<GqlClientUpdateStoryWithDecisionPointsMutation>;
export type UpdateStoryWithDecisionPointsMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateStoryWithDecisionPointsMutation, GqlClientUpdateStoryWithDecisionPointsMutationVariables>;
export const UpdateStoryWithNewSceneDocument = gql`
    mutation UpdateStoryWithNewScene($input: UpdateStoryWithNewSceneInput!, $dryRun: Boolean, $sceneLegacyId: String, $scenePartLegacyId: String) {
        updateStoryWithNewScene(input: $input, dryRun: $dryRun, sceneLegacyId: $sceneLegacyId, scenePartLegacyId: $scenePartLegacyId) {
            result
            ... on UpdateStoryWithNewSceneOutputSuccess {
                story {
                    id
                    updated
                }
                scene {
                    id
                    created
                    updated
                    sceneParts {
                        id
                        updated
                    }
                }
            }
        }
    }
`;
export type GqlClientUpdateStoryWithNewSceneMutationFn = Apollo.MutationFunction<GqlClientUpdateStoryWithNewSceneMutation, GqlClientUpdateStoryWithNewSceneMutationVariables>;
export type GqlClientUpdateStoryWithNewSceneProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateStoryWithNewSceneMutation, GqlClientUpdateStoryWithNewSceneMutationVariables>;
} & TChildProps;
export function withUpdateStoryWithNewScene<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateStoryWithNewSceneMutation,
        GqlClientUpdateStoryWithNewSceneMutationVariables,
        GqlClientUpdateStoryWithNewSceneProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateStoryWithNewSceneMutation,
        GqlClientUpdateStoryWithNewSceneMutationVariables,
        GqlClientUpdateStoryWithNewSceneProps<TChildProps, TDataName>
    >(UpdateStoryWithNewSceneDocument, {
        alias: "updateStoryWithNewScene",
        ...operationOptions
    });
}

/**
 * __useUpdateStoryWithNewSceneMutation__
 *
 * To run a mutation, you first call `useUpdateStoryWithNewSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryWithNewSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryWithNewSceneMutation, { data, loading, error }] = useUpdateStoryWithNewSceneMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *      sceneLegacyId: // value for 'sceneLegacyId'
 *      scenePartLegacyId: // value for 'scenePartLegacyId'
 *   },
 * });
 */
export function useUpdateStoryWithNewSceneMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateStoryWithNewSceneMutation, GqlClientUpdateStoryWithNewSceneMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateStoryWithNewSceneMutation, GqlClientUpdateStoryWithNewSceneMutationVariables>(UpdateStoryWithNewSceneDocument, options);
}
export type UpdateStoryWithNewSceneMutationHookResult = ReturnType<typeof useUpdateStoryWithNewSceneMutation>;
export type UpdateStoryWithNewSceneMutationResult = Apollo.MutationResult<GqlClientUpdateStoryWithNewSceneMutation>;
export type UpdateStoryWithNewSceneMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateStoryWithNewSceneMutation, GqlClientUpdateStoryWithNewSceneMutationVariables>;
export const AddCreativeLifecycleHistoryEntryDocument = gql`
    mutation AddCreativeLifecycleHistoryEntry($input: AddCreativeLifecycleHistoryEntryInput!) {
        addCreativeLifecycleHistoryEntry(input: $input) {
            result
            creativeLifecycleHistoryEntry {
                id
                creative {
                    id
                    creativeLifecycleHistory {
                        ...CreativeLifecycleHistory
                    }
                }
            }
        }
    }
    ${CreativeLifecycleHistoryFragmentDoc}
`;
export type GqlClientAddCreativeLifecycleHistoryEntryMutationFn = Apollo.MutationFunction<
    GqlClientAddCreativeLifecycleHistoryEntryMutation,
    GqlClientAddCreativeLifecycleHistoryEntryMutationVariables
>;
export type GqlClientAddCreativeLifecycleHistoryEntryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientAddCreativeLifecycleHistoryEntryMutation, GqlClientAddCreativeLifecycleHistoryEntryMutationVariables>;
} & TChildProps;
export function withAddCreativeLifecycleHistoryEntry<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientAddCreativeLifecycleHistoryEntryMutation,
        GqlClientAddCreativeLifecycleHistoryEntryMutationVariables,
        GqlClientAddCreativeLifecycleHistoryEntryProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientAddCreativeLifecycleHistoryEntryMutation,
        GqlClientAddCreativeLifecycleHistoryEntryMutationVariables,
        GqlClientAddCreativeLifecycleHistoryEntryProps<TChildProps, TDataName>
    >(AddCreativeLifecycleHistoryEntryDocument, {
        alias: "addCreativeLifecycleHistoryEntry",
        ...operationOptions
    });
}

/**
 * __useAddCreativeLifecycleHistoryEntryMutation__
 *
 * To run a mutation, you first call `useAddCreativeLifecycleHistoryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCreativeLifecycleHistoryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCreativeLifecycleHistoryEntryMutation, { data, loading, error }] = useAddCreativeLifecycleHistoryEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCreativeLifecycleHistoryEntryMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientAddCreativeLifecycleHistoryEntryMutation, GqlClientAddCreativeLifecycleHistoryEntryMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientAddCreativeLifecycleHistoryEntryMutation, GqlClientAddCreativeLifecycleHistoryEntryMutationVariables>(AddCreativeLifecycleHistoryEntryDocument, options);
}
export type AddCreativeLifecycleHistoryEntryMutationHookResult = ReturnType<typeof useAddCreativeLifecycleHistoryEntryMutation>;
export type AddCreativeLifecycleHistoryEntryMutationResult = Apollo.MutationResult<GqlClientAddCreativeLifecycleHistoryEntryMutation>;
export type AddCreativeLifecycleHistoryEntryMutationOptions = Apollo.BaseMutationOptions<GqlClientAddCreativeLifecycleHistoryEntryMutation, GqlClientAddCreativeLifecycleHistoryEntryMutationVariables>;
export const CopyCreativeWithDraftDocument = gql`
    mutation CopyCreativeWithDraft($input: CopyCreativeWithDraftInput!) {
        copyCreativeWithDraft(input: $input) {
            result
            targetCreative {
                id
                program {
                    id
                    creatives {
                        ...CreativeInfo
                    }
                }
            }
        }
    }
    ${CreativeInfoFragmentDoc}
`;
export type GqlClientCopyCreativeWithDraftMutationFn = Apollo.MutationFunction<GqlClientCopyCreativeWithDraftMutation, GqlClientCopyCreativeWithDraftMutationVariables>;
export type GqlClientCopyCreativeWithDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCopyCreativeWithDraftMutation, GqlClientCopyCreativeWithDraftMutationVariables>;
} & TChildProps;
export function withCopyCreativeWithDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCopyCreativeWithDraftMutation,
        GqlClientCopyCreativeWithDraftMutationVariables,
        GqlClientCopyCreativeWithDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCopyCreativeWithDraftMutation, GqlClientCopyCreativeWithDraftMutationVariables, GqlClientCopyCreativeWithDraftProps<TChildProps, TDataName>>(
        CopyCreativeWithDraftDocument,
        {
            alias: "copyCreativeWithDraft",
            ...operationOptions
        }
    );
}

/**
 * __useCopyCreativeWithDraftMutation__
 *
 * To run a mutation, you first call `useCopyCreativeWithDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyCreativeWithDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyCreativeWithDraftMutation, { data, loading, error }] = useCopyCreativeWithDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyCreativeWithDraftMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCopyCreativeWithDraftMutation, GqlClientCopyCreativeWithDraftMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCopyCreativeWithDraftMutation, GqlClientCopyCreativeWithDraftMutationVariables>(CopyCreativeWithDraftDocument, options);
}
export type CopyCreativeWithDraftMutationHookResult = ReturnType<typeof useCopyCreativeWithDraftMutation>;
export type CopyCreativeWithDraftMutationResult = Apollo.MutationResult<GqlClientCopyCreativeWithDraftMutation>;
export type CopyCreativeWithDraftMutationOptions = Apollo.BaseMutationOptions<GqlClientCopyCreativeWithDraftMutation, GqlClientCopyCreativeWithDraftMutationVariables>;
export const CreateCreativeAndDraftDocument = gql`
    mutation CreateCreativeAndDraft($input: CreateCreativeAndDraftInput!) {
        createCreativeAndDraft(input: $input) {
            result
            creative {
                id
                program {
                    id
                    creatives {
                        ...CreativeInfo
                    }
                }
            }
        }
    }
    ${CreativeInfoFragmentDoc}
`;
export type GqlClientCreateCreativeAndDraftMutationFn = Apollo.MutationFunction<GqlClientCreateCreativeAndDraftMutation, GqlClientCreateCreativeAndDraftMutationVariables>;
export type GqlClientCreateCreativeAndDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateCreativeAndDraftMutation, GqlClientCreateCreativeAndDraftMutationVariables>;
} & TChildProps;
export function withCreateCreativeAndDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateCreativeAndDraftMutation,
        GqlClientCreateCreativeAndDraftMutationVariables,
        GqlClientCreateCreativeAndDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateCreativeAndDraftMutation, GqlClientCreateCreativeAndDraftMutationVariables, GqlClientCreateCreativeAndDraftProps<TChildProps, TDataName>>(
        CreateCreativeAndDraftDocument,
        {
            alias: "createCreativeAndDraft",
            ...operationOptions
        }
    );
}

/**
 * __useCreateCreativeAndDraftMutation__
 *
 * To run a mutation, you first call `useCreateCreativeAndDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreativeAndDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreativeAndDraftMutation, { data, loading, error }] = useCreateCreativeAndDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreativeAndDraftMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateCreativeAndDraftMutation, GqlClientCreateCreativeAndDraftMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateCreativeAndDraftMutation, GqlClientCreateCreativeAndDraftMutationVariables>(CreateCreativeAndDraftDocument, options);
}
export type CreateCreativeAndDraftMutationHookResult = ReturnType<typeof useCreateCreativeAndDraftMutation>;
export type CreateCreativeAndDraftMutationResult = Apollo.MutationResult<GqlClientCreateCreativeAndDraftMutation>;
export type CreateCreativeAndDraftMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateCreativeAndDraftMutation, GqlClientCreateCreativeAndDraftMutationVariables>;
export const DeleteCreativesDocument = gql`
    mutation DeleteCreatives($input: DeleteCreativesInput!) {
        deleteCreatives(input: $input) {
            result
            program {
                id
                creatives {
                    ...CreativeInfo
                }
            }
        }
    }
    ${CreativeInfoFragmentDoc}
`;
export type GqlClientDeleteCreativesMutationFn = Apollo.MutationFunction<GqlClientDeleteCreativesMutation, GqlClientDeleteCreativesMutationVariables>;
export type GqlClientDeleteCreativesProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteCreativesMutation, GqlClientDeleteCreativesMutationVariables>;
} & TChildProps;
export function withDeleteCreatives<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteCreativesMutation, GqlClientDeleteCreativesMutationVariables, GqlClientDeleteCreativesProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteCreativesMutation, GqlClientDeleteCreativesMutationVariables, GqlClientDeleteCreativesProps<TChildProps, TDataName>>(
        DeleteCreativesDocument,
        {
            alias: "deleteCreatives",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteCreativesMutation__
 *
 * To run a mutation, you first call `useDeleteCreativesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreativesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreativesMutation, { data, loading, error }] = useDeleteCreativesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCreativesMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteCreativesMutation, GqlClientDeleteCreativesMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteCreativesMutation, GqlClientDeleteCreativesMutationVariables>(DeleteCreativesDocument, options);
}
export type DeleteCreativesMutationHookResult = ReturnType<typeof useDeleteCreativesMutation>;
export type DeleteCreativesMutationResult = Apollo.MutationResult<GqlClientDeleteCreativesMutation>;
export type DeleteCreativesMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteCreativesMutation, GqlClientDeleteCreativesMutationVariables>;
export const GetAllProgramCreativesDocument = gql`
    query GetAllProgramCreatives($programId: ID!) {
        program(id: $programId) {
            id
            creatives {
                ...CreativeInfo
            }
        }
    }
    ${CreativeInfoFragmentDoc}
`;
export type GqlClientGetAllProgramCreativesProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAllProgramCreativesQuery, GqlClientGetAllProgramCreativesQueryVariables>;
} & TChildProps;
export function withGetAllProgramCreatives<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetAllProgramCreativesQuery,
        GqlClientGetAllProgramCreativesQueryVariables,
        GqlClientGetAllProgramCreativesProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetAllProgramCreativesQuery, GqlClientGetAllProgramCreativesQueryVariables, GqlClientGetAllProgramCreativesProps<TChildProps, TDataName>>(
        GetAllProgramCreativesDocument,
        {
            alias: "getAllProgramCreatives",
            ...operationOptions
        }
    );
}

/**
 * __useGetAllProgramCreativesQuery__
 *
 * To run a query within a React component, call `useGetAllProgramCreativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProgramCreativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProgramCreativesQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetAllProgramCreativesQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetAllProgramCreativesQuery, GqlClientGetAllProgramCreativesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAllProgramCreativesQuery, GqlClientGetAllProgramCreativesQueryVariables>(GetAllProgramCreativesDocument, options);
}
export function useGetAllProgramCreativesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAllProgramCreativesQuery, GqlClientGetAllProgramCreativesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAllProgramCreativesQuery, GqlClientGetAllProgramCreativesQueryVariables>(GetAllProgramCreativesDocument, options);
}
export type GetAllProgramCreativesQueryHookResult = ReturnType<typeof useGetAllProgramCreativesQuery>;
export type GetAllProgramCreativesLazyQueryHookResult = ReturnType<typeof useGetAllProgramCreativesLazyQuery>;
export type GetAllProgramCreativesQueryResult = Apollo.QueryResult<GqlClientGetAllProgramCreativesQuery, GqlClientGetAllProgramCreativesQueryVariables>;
export const GetCreativeSnapshotsAndLifecycleDocument = gql`
    query GetCreativeSnapshotsAndLifecycle($programId: ID!, $creativeId: ID!) {
        program(id: $programId) {
            id
            creative(id: $creativeId) {
                id
                creativeVersions {
                    ...CreativeVersionInfo
                }
                creativeLifecycleHistory {
                    ...CreativeLifecycleHistory
                }
            }
        }
    }
    ${CreativeVersionInfoFragmentDoc}
    ${CreativeLifecycleHistoryFragmentDoc}
`;
export type GqlClientGetCreativeSnapshotsAndLifecycleProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetCreativeSnapshotsAndLifecycleQuery, GqlClientGetCreativeSnapshotsAndLifecycleQueryVariables>;
} & TChildProps;
export function withGetCreativeSnapshotsAndLifecycle<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetCreativeSnapshotsAndLifecycleQuery,
        GqlClientGetCreativeSnapshotsAndLifecycleQueryVariables,
        GqlClientGetCreativeSnapshotsAndLifecycleProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientGetCreativeSnapshotsAndLifecycleQuery,
        GqlClientGetCreativeSnapshotsAndLifecycleQueryVariables,
        GqlClientGetCreativeSnapshotsAndLifecycleProps<TChildProps, TDataName>
    >(GetCreativeSnapshotsAndLifecycleDocument, {
        alias: "getCreativeSnapshotsAndLifecycle",
        ...operationOptions
    });
}

/**
 * __useGetCreativeSnapshotsAndLifecycleQuery__
 *
 * To run a query within a React component, call `useGetCreativeSnapshotsAndLifecycleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreativeSnapshotsAndLifecycleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreativeSnapshotsAndLifecycleQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      creativeId: // value for 'creativeId'
 *   },
 * });
 */
export function useGetCreativeSnapshotsAndLifecycleQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientGetCreativeSnapshotsAndLifecycleQuery, GqlClientGetCreativeSnapshotsAndLifecycleQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetCreativeSnapshotsAndLifecycleQuery, GqlClientGetCreativeSnapshotsAndLifecycleQueryVariables>(GetCreativeSnapshotsAndLifecycleDocument, options);
}
export function useGetCreativeSnapshotsAndLifecycleLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetCreativeSnapshotsAndLifecycleQuery, GqlClientGetCreativeSnapshotsAndLifecycleQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetCreativeSnapshotsAndLifecycleQuery, GqlClientGetCreativeSnapshotsAndLifecycleQueryVariables>(GetCreativeSnapshotsAndLifecycleDocument, options);
}
export type GetCreativeSnapshotsAndLifecycleQueryHookResult = ReturnType<typeof useGetCreativeSnapshotsAndLifecycleQuery>;
export type GetCreativeSnapshotsAndLifecycleLazyQueryHookResult = ReturnType<typeof useGetCreativeSnapshotsAndLifecycleLazyQuery>;
export type GetCreativeSnapshotsAndLifecycleQueryResult = Apollo.QueryResult<GqlClientGetCreativeSnapshotsAndLifecycleQuery, GqlClientGetCreativeSnapshotsAndLifecycleQueryVariables>;
export const UpdateCreativeDocument = gql`
    mutation UpdateCreative($input: UpdateCreativeInput!) {
        updateCreative(input: $input) {
            result
            ... on UpdateCreativeOutputSuccess {
                creative {
                    ...CreativeInfo
                }
            }
        }
    }
    ${CreativeInfoFragmentDoc}
`;
export type GqlClientUpdateCreativeMutationFn = Apollo.MutationFunction<GqlClientUpdateCreativeMutation, GqlClientUpdateCreativeMutationVariables>;
export type GqlClientUpdateCreativeProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCreativeMutation, GqlClientUpdateCreativeMutationVariables>;
} & TChildProps;
export function withUpdateCreative<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateCreativeMutation, GqlClientUpdateCreativeMutationVariables, GqlClientUpdateCreativeProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateCreativeMutation, GqlClientUpdateCreativeMutationVariables, GqlClientUpdateCreativeProps<TChildProps, TDataName>>(
        UpdateCreativeDocument,
        {
            alias: "updateCreative",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateCreativeMutation__
 *
 * To run a mutation, you first call `useUpdateCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreativeMutation, { data, loading, error }] = useUpdateCreativeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreativeMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCreativeMutation, GqlClientUpdateCreativeMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCreativeMutation, GqlClientUpdateCreativeMutationVariables>(UpdateCreativeDocument, options);
}
export type UpdateCreativeMutationHookResult = ReturnType<typeof useUpdateCreativeMutation>;
export type UpdateCreativeMutationResult = Apollo.MutationResult<GqlClientUpdateCreativeMutation>;
export type UpdateCreativeMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateCreativeMutation, GqlClientUpdateCreativeMutationVariables>;
export const CreateCreativeAssetWithVersionDocument = gql`
    mutation CreateCreativeAssetWithVersion($input: CreateCreativeAssetWithVersionInput!) {
        createCreativeAssetWithVersion(input: $input) {
            result
            creativeAsset {
                ...CreativeAsset
                creativeVersion {
                    ...CreativeVersion
                }
            }
        }
    }
    ${CreativeAssetFragmentDoc}
    ${CreativeVersionFragmentDoc}
`;
export type GqlClientCreateCreativeAssetWithVersionMutationFn = Apollo.MutationFunction<GqlClientCreateCreativeAssetWithVersionMutation, GqlClientCreateCreativeAssetWithVersionMutationVariables>;
export type GqlClientCreateCreativeAssetWithVersionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateCreativeAssetWithVersionMutation, GqlClientCreateCreativeAssetWithVersionMutationVariables>;
} & TChildProps;
export function withCreateCreativeAssetWithVersion<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateCreativeAssetWithVersionMutation,
        GqlClientCreateCreativeAssetWithVersionMutationVariables,
        GqlClientCreateCreativeAssetWithVersionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateCreativeAssetWithVersionMutation,
        GqlClientCreateCreativeAssetWithVersionMutationVariables,
        GqlClientCreateCreativeAssetWithVersionProps<TChildProps, TDataName>
    >(CreateCreativeAssetWithVersionDocument, {
        alias: "createCreativeAssetWithVersion",
        ...operationOptions
    });
}

/**
 * __useCreateCreativeAssetWithVersionMutation__
 *
 * To run a mutation, you first call `useCreateCreativeAssetWithVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreativeAssetWithVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreativeAssetWithVersionMutation, { data, loading, error }] = useCreateCreativeAssetWithVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreativeAssetWithVersionMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateCreativeAssetWithVersionMutation, GqlClientCreateCreativeAssetWithVersionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateCreativeAssetWithVersionMutation, GqlClientCreateCreativeAssetWithVersionMutationVariables>(CreateCreativeAssetWithVersionDocument, options);
}
export type CreateCreativeAssetWithVersionMutationHookResult = ReturnType<typeof useCreateCreativeAssetWithVersionMutation>;
export type CreateCreativeAssetWithVersionMutationResult = Apollo.MutationResult<GqlClientCreateCreativeAssetWithVersionMutation>;
export type CreateCreativeAssetWithVersionMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateCreativeAssetWithVersionMutation, GqlClientCreateCreativeAssetWithVersionMutationVariables>;
export const CreateCreativeAssetVersionDocument = gql`
    mutation CreateCreativeAssetVersion($input: CreateCreativeAssetVersionInput!) {
        createCreativeAssetVersion(input: $input) {
            result
            creativeAssetVersion {
                id
                creativeAsset {
                    ...CreativeAsset
                }
            }
        }
    }
    ${CreativeAssetFragmentDoc}
`;
export type GqlClientCreateCreativeAssetVersionMutationFn = Apollo.MutationFunction<GqlClientCreateCreativeAssetVersionMutation, GqlClientCreateCreativeAssetVersionMutationVariables>;
export type GqlClientCreateCreativeAssetVersionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateCreativeAssetVersionMutation, GqlClientCreateCreativeAssetVersionMutationVariables>;
} & TChildProps;
export function withCreateCreativeAssetVersion<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateCreativeAssetVersionMutation,
        GqlClientCreateCreativeAssetVersionMutationVariables,
        GqlClientCreateCreativeAssetVersionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateCreativeAssetVersionMutation,
        GqlClientCreateCreativeAssetVersionMutationVariables,
        GqlClientCreateCreativeAssetVersionProps<TChildProps, TDataName>
    >(CreateCreativeAssetVersionDocument, {
        alias: "createCreativeAssetVersion",
        ...operationOptions
    });
}

/**
 * __useCreateCreativeAssetVersionMutation__
 *
 * To run a mutation, you first call `useCreateCreativeAssetVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreativeAssetVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreativeAssetVersionMutation, { data, loading, error }] = useCreateCreativeAssetVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreativeAssetVersionMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateCreativeAssetVersionMutation, GqlClientCreateCreativeAssetVersionMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateCreativeAssetVersionMutation, GqlClientCreateCreativeAssetVersionMutationVariables>(CreateCreativeAssetVersionDocument, options);
}
export type CreateCreativeAssetVersionMutationHookResult = ReturnType<typeof useCreateCreativeAssetVersionMutation>;
export type CreateCreativeAssetVersionMutationResult = Apollo.MutationResult<GqlClientCreateCreativeAssetVersionMutation>;
export type CreateCreativeAssetVersionMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateCreativeAssetVersionMutation, GqlClientCreateCreativeAssetVersionMutationVariables>;
export const GetAllCreativesNarrationRecordingsDocument = gql`
    query GetAllCreativesNarrationRecordings($id: ID!) {
        program(id: $id) {
            id
            creatives {
                id
                name
                updated
                createdBy
                creativeVersionDraft {
                    id
                    rfr
                    storyId
                    creativeNarrationRecordings {
                        ...CreativeNarrationRecording
                    }
                }
            }
        }
    }
    ${CreativeNarrationRecordingFragmentDoc}
`;
export type GqlClientGetAllCreativesNarrationRecordingsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAllCreativesNarrationRecordingsQuery, GqlClientGetAllCreativesNarrationRecordingsQueryVariables>;
} & TChildProps;
export function withGetAllCreativesNarrationRecordings<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetAllCreativesNarrationRecordingsQuery,
        GqlClientGetAllCreativesNarrationRecordingsQueryVariables,
        GqlClientGetAllCreativesNarrationRecordingsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientGetAllCreativesNarrationRecordingsQuery,
        GqlClientGetAllCreativesNarrationRecordingsQueryVariables,
        GqlClientGetAllCreativesNarrationRecordingsProps<TChildProps, TDataName>
    >(GetAllCreativesNarrationRecordingsDocument, {
        alias: "getAllCreativesNarrationRecordings",
        ...operationOptions
    });
}

/**
 * __useGetAllCreativesNarrationRecordingsQuery__
 *
 * To run a query within a React component, call `useGetAllCreativesNarrationRecordingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCreativesNarrationRecordingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCreativesNarrationRecordingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllCreativesNarrationRecordingsQuery(
    baseOptions: Apollo.QueryHookOptions<GqlClientGetAllCreativesNarrationRecordingsQuery, GqlClientGetAllCreativesNarrationRecordingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAllCreativesNarrationRecordingsQuery, GqlClientGetAllCreativesNarrationRecordingsQueryVariables>(GetAllCreativesNarrationRecordingsDocument, options);
}
export function useGetAllCreativesNarrationRecordingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAllCreativesNarrationRecordingsQuery, GqlClientGetAllCreativesNarrationRecordingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAllCreativesNarrationRecordingsQuery, GqlClientGetAllCreativesNarrationRecordingsQueryVariables>(GetAllCreativesNarrationRecordingsDocument, options);
}
export type GetAllCreativesNarrationRecordingsQueryHookResult = ReturnType<typeof useGetAllCreativesNarrationRecordingsQuery>;
export type GetAllCreativesNarrationRecordingsLazyQueryHookResult = ReturnType<typeof useGetAllCreativesNarrationRecordingsLazyQuery>;
export type GetAllCreativesNarrationRecordingsQueryResult = Apollo.QueryResult<GqlClientGetAllCreativesNarrationRecordingsQuery, GqlClientGetAllCreativesNarrationRecordingsQueryVariables>;
export const GetCreativeNarrationRecordingsDocument = gql`
    query GetCreativeNarrationRecordings($id: ID!, $creativeId: ID!) {
        program(id: $id) {
            id
            creative(id: $creativeId) {
                id
                name
                creativeVersionDraft {
                    id
                    rfr
                    creativeNarrationRecordings {
                        ...CreativeNarrationRecording
                    }
                }
            }
        }
    }
    ${CreativeNarrationRecordingFragmentDoc}
`;
export type GqlClientGetCreativeNarrationRecordingsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetCreativeNarrationRecordingsQuery, GqlClientGetCreativeNarrationRecordingsQueryVariables>;
} & TChildProps;
export function withGetCreativeNarrationRecordings<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetCreativeNarrationRecordingsQuery,
        GqlClientGetCreativeNarrationRecordingsQueryVariables,
        GqlClientGetCreativeNarrationRecordingsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientGetCreativeNarrationRecordingsQuery,
        GqlClientGetCreativeNarrationRecordingsQueryVariables,
        GqlClientGetCreativeNarrationRecordingsProps<TChildProps, TDataName>
    >(GetCreativeNarrationRecordingsDocument, {
        alias: "getCreativeNarrationRecordings",
        ...operationOptions
    });
}

/**
 * __useGetCreativeNarrationRecordingsQuery__
 *
 * To run a query within a React component, call `useGetCreativeNarrationRecordingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreativeNarrationRecordingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreativeNarrationRecordingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      creativeId: // value for 'creativeId'
 *   },
 * });
 */
export function useGetCreativeNarrationRecordingsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetCreativeNarrationRecordingsQuery, GqlClientGetCreativeNarrationRecordingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetCreativeNarrationRecordingsQuery, GqlClientGetCreativeNarrationRecordingsQueryVariables>(GetCreativeNarrationRecordingsDocument, options);
}
export function useGetCreativeNarrationRecordingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetCreativeNarrationRecordingsQuery, GqlClientGetCreativeNarrationRecordingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetCreativeNarrationRecordingsQuery, GqlClientGetCreativeNarrationRecordingsQueryVariables>(GetCreativeNarrationRecordingsDocument, options);
}
export type GetCreativeNarrationRecordingsQueryHookResult = ReturnType<typeof useGetCreativeNarrationRecordingsQuery>;
export type GetCreativeNarrationRecordingsLazyQueryHookResult = ReturnType<typeof useGetCreativeNarrationRecordingsLazyQuery>;
export type GetCreativeNarrationRecordingsQueryResult = Apollo.QueryResult<GqlClientGetCreativeNarrationRecordingsQuery, GqlClientGetCreativeNarrationRecordingsQueryVariables>;
export const UpdateCreativeNarrationRecordingDocument = gql`
    mutation UpdateCreativeNarrationRecording($input: UpdateCreativeNarrationRecordingInput!, $dryRun: Boolean) {
        updateCreativeNarrationRecording(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateCreativeNarrationRecordingOutputSuccess {
                creativeNarrationRecording {
                    ...CreativeNarrationRecording
                }
            }
        }
    }
    ${CreativeNarrationRecordingFragmentDoc}
`;
export type GqlClientUpdateCreativeNarrationRecordingMutationFn = Apollo.MutationFunction<
    GqlClientUpdateCreativeNarrationRecordingMutation,
    GqlClientUpdateCreativeNarrationRecordingMutationVariables
>;
export type GqlClientUpdateCreativeNarrationRecordingProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCreativeNarrationRecordingMutation, GqlClientUpdateCreativeNarrationRecordingMutationVariables>;
} & TChildProps;
export function withUpdateCreativeNarrationRecording<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateCreativeNarrationRecordingMutation,
        GqlClientUpdateCreativeNarrationRecordingMutationVariables,
        GqlClientUpdateCreativeNarrationRecordingProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateCreativeNarrationRecordingMutation,
        GqlClientUpdateCreativeNarrationRecordingMutationVariables,
        GqlClientUpdateCreativeNarrationRecordingProps<TChildProps, TDataName>
    >(UpdateCreativeNarrationRecordingDocument, {
        alias: "updateCreativeNarrationRecording",
        ...operationOptions
    });
}

/**
 * __useUpdateCreativeNarrationRecordingMutation__
 *
 * To run a mutation, you first call `useUpdateCreativeNarrationRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreativeNarrationRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreativeNarrationRecordingMutation, { data, loading, error }] = useUpdateCreativeNarrationRecordingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateCreativeNarrationRecordingMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCreativeNarrationRecordingMutation, GqlClientUpdateCreativeNarrationRecordingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCreativeNarrationRecordingMutation, GqlClientUpdateCreativeNarrationRecordingMutationVariables>(UpdateCreativeNarrationRecordingDocument, options);
}
export type UpdateCreativeNarrationRecordingMutationHookResult = ReturnType<typeof useUpdateCreativeNarrationRecordingMutation>;
export type UpdateCreativeNarrationRecordingMutationResult = Apollo.MutationResult<GqlClientUpdateCreativeNarrationRecordingMutation>;
export type UpdateCreativeNarrationRecordingMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateCreativeNarrationRecordingMutation, GqlClientUpdateCreativeNarrationRecordingMutationVariables>;
export const CreateCreativeNarrationRecordingVersionDocument = gql`
    mutation CreateCreativeNarrationRecordingVersion($input: CreateCreativeNarrationRecordingVersionInput!) {
        createCreativeNarrationRecordingVersion(input: $input) {
            result
            creativeNarrationRecordingVersion {
                id
                creativeNarrationRecording {
                    ...CreativeNarrationRecording
                }
            }
        }
    }
    ${CreativeNarrationRecordingFragmentDoc}
`;
export type GqlClientCreateCreativeNarrationRecordingVersionMutationFn = Apollo.MutationFunction<
    GqlClientCreateCreativeNarrationRecordingVersionMutation,
    GqlClientCreateCreativeNarrationRecordingVersionMutationVariables
>;
export type GqlClientCreateCreativeNarrationRecordingVersionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateCreativeNarrationRecordingVersionMutation, GqlClientCreateCreativeNarrationRecordingVersionMutationVariables>;
} & TChildProps;
export function withCreateCreativeNarrationRecordingVersion<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateCreativeNarrationRecordingVersionMutation,
        GqlClientCreateCreativeNarrationRecordingVersionMutationVariables,
        GqlClientCreateCreativeNarrationRecordingVersionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateCreativeNarrationRecordingVersionMutation,
        GqlClientCreateCreativeNarrationRecordingVersionMutationVariables,
        GqlClientCreateCreativeNarrationRecordingVersionProps<TChildProps, TDataName>
    >(CreateCreativeNarrationRecordingVersionDocument, {
        alias: "createCreativeNarrationRecordingVersion",
        ...operationOptions
    });
}

/**
 * __useCreateCreativeNarrationRecordingVersionMutation__
 *
 * To run a mutation, you first call `useCreateCreativeNarrationRecordingVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreativeNarrationRecordingVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreativeNarrationRecordingVersionMutation, { data, loading, error }] = useCreateCreativeNarrationRecordingVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreativeNarrationRecordingVersionMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientCreateCreativeNarrationRecordingVersionMutation, GqlClientCreateCreativeNarrationRecordingVersionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateCreativeNarrationRecordingVersionMutation, GqlClientCreateCreativeNarrationRecordingVersionMutationVariables>(
        CreateCreativeNarrationRecordingVersionDocument,
        options
    );
}
export type CreateCreativeNarrationRecordingVersionMutationHookResult = ReturnType<typeof useCreateCreativeNarrationRecordingVersionMutation>;
export type CreateCreativeNarrationRecordingVersionMutationResult = Apollo.MutationResult<GqlClientCreateCreativeNarrationRecordingVersionMutation>;
export type CreateCreativeNarrationRecordingVersionMutationOptions = Apollo.BaseMutationOptions<
    GqlClientCreateCreativeNarrationRecordingVersionMutation,
    GqlClientCreateCreativeNarrationRecordingVersionMutationVariables
>;
export const UpdateCreativeNarrationRecordingVersionDocument = gql`
    mutation UpdateCreativeNarrationRecordingVersion($input: UpdateCreativeNarrationRecordingVersionInput!, $dryRun: Boolean) {
        updateCreativeNarrationRecordingVersion(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateCreativeNarrationRecordingVersionOutputSuccess {
                creativeNarrationRecordingVersion {
                    id
                    creativeNarrationRecording {
                        ...CreativeNarrationRecording
                    }
                }
            }
        }
    }
    ${CreativeNarrationRecordingFragmentDoc}
`;
export type GqlClientUpdateCreativeNarrationRecordingVersionMutationFn = Apollo.MutationFunction<
    GqlClientUpdateCreativeNarrationRecordingVersionMutation,
    GqlClientUpdateCreativeNarrationRecordingVersionMutationVariables
>;
export type GqlClientUpdateCreativeNarrationRecordingVersionProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCreativeNarrationRecordingVersionMutation, GqlClientUpdateCreativeNarrationRecordingVersionMutationVariables>;
} & TChildProps;
export function withUpdateCreativeNarrationRecordingVersion<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateCreativeNarrationRecordingVersionMutation,
        GqlClientUpdateCreativeNarrationRecordingVersionMutationVariables,
        GqlClientUpdateCreativeNarrationRecordingVersionProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateCreativeNarrationRecordingVersionMutation,
        GqlClientUpdateCreativeNarrationRecordingVersionMutationVariables,
        GqlClientUpdateCreativeNarrationRecordingVersionProps<TChildProps, TDataName>
    >(UpdateCreativeNarrationRecordingVersionDocument, {
        alias: "updateCreativeNarrationRecordingVersion",
        ...operationOptions
    });
}

/**
 * __useUpdateCreativeNarrationRecordingVersionMutation__
 *
 * To run a mutation, you first call `useUpdateCreativeNarrationRecordingVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreativeNarrationRecordingVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreativeNarrationRecordingVersionMutation, { data, loading, error }] = useUpdateCreativeNarrationRecordingVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateCreativeNarrationRecordingVersionMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCreativeNarrationRecordingVersionMutation, GqlClientUpdateCreativeNarrationRecordingVersionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCreativeNarrationRecordingVersionMutation, GqlClientUpdateCreativeNarrationRecordingVersionMutationVariables>(
        UpdateCreativeNarrationRecordingVersionDocument,
        options
    );
}
export type UpdateCreativeNarrationRecordingVersionMutationHookResult = ReturnType<typeof useUpdateCreativeNarrationRecordingVersionMutation>;
export type UpdateCreativeNarrationRecordingVersionMutationResult = Apollo.MutationResult<GqlClientUpdateCreativeNarrationRecordingVersionMutation>;
export type UpdateCreativeNarrationRecordingVersionMutationOptions = Apollo.BaseMutationOptions<
    GqlClientUpdateCreativeNarrationRecordingVersionMutation,
    GqlClientUpdateCreativeNarrationRecordingVersionMutationVariables
>;
export const CopyCreativeVersionDraftDocument = gql`
    mutation CopyCreativeVersionDraft($input: CopyCreativeVersionDraftInput!) {
        copyCreativeVersionDraft(input: $input) {
            result
            targetCreativeVersion {
                id
                snapshotNumber
                creative {
                    ...CreativeInfo
                    creativeVersions {
                        ...CreativeVersionInfo
                    }
                }
            }
        }
    }
    ${CreativeInfoFragmentDoc}
    ${CreativeVersionInfoFragmentDoc}
`;
export type GqlClientCopyCreativeVersionDraftMutationFn = Apollo.MutationFunction<GqlClientCopyCreativeVersionDraftMutation, GqlClientCopyCreativeVersionDraftMutationVariables>;
export type GqlClientCopyCreativeVersionDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCopyCreativeVersionDraftMutation, GqlClientCopyCreativeVersionDraftMutationVariables>;
} & TChildProps;
export function withCopyCreativeVersionDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCopyCreativeVersionDraftMutation,
        GqlClientCopyCreativeVersionDraftMutationVariables,
        GqlClientCopyCreativeVersionDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCopyCreativeVersionDraftMutation,
        GqlClientCopyCreativeVersionDraftMutationVariables,
        GqlClientCopyCreativeVersionDraftProps<TChildProps, TDataName>
    >(CopyCreativeVersionDraftDocument, {
        alias: "copyCreativeVersionDraft",
        ...operationOptions
    });
}

/**
 * __useCopyCreativeVersionDraftMutation__
 *
 * To run a mutation, you first call `useCopyCreativeVersionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyCreativeVersionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyCreativeVersionDraftMutation, { data, loading, error }] = useCopyCreativeVersionDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyCreativeVersionDraftMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCopyCreativeVersionDraftMutation, GqlClientCopyCreativeVersionDraftMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCopyCreativeVersionDraftMutation, GqlClientCopyCreativeVersionDraftMutationVariables>(CopyCreativeVersionDraftDocument, options);
}
export type CopyCreativeVersionDraftMutationHookResult = ReturnType<typeof useCopyCreativeVersionDraftMutation>;
export type CopyCreativeVersionDraftMutationResult = Apollo.MutationResult<GqlClientCopyCreativeVersionDraftMutation>;
export type CopyCreativeVersionDraftMutationOptions = Apollo.BaseMutationOptions<GqlClientCopyCreativeVersionDraftMutation, GqlClientCopyCreativeVersionDraftMutationVariables>;
export const GetCreativeVersionDocument = gql`
    query GetCreativeVersion($programId: ID!, $creativeId: ID!, $creativeVersionId: ID!) {
        program(id: $programId) {
            id
            creative(id: $creativeId) {
                name
                id
                updated
                creativeVersion(id: $creativeVersionId) {
                    ...CreativeVersion
                }
            }
        }
    }
    ${CreativeVersionFragmentDoc}
`;
export type GqlClientGetCreativeVersionProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetCreativeVersionQuery, GqlClientGetCreativeVersionQueryVariables>;
} & TChildProps;
export function withGetCreativeVersion<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetCreativeVersionQuery, GqlClientGetCreativeVersionQueryVariables, GqlClientGetCreativeVersionProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetCreativeVersionQuery, GqlClientGetCreativeVersionQueryVariables, GqlClientGetCreativeVersionProps<TChildProps, TDataName>>(
        GetCreativeVersionDocument,
        {
            alias: "getCreativeVersion",
            ...operationOptions
        }
    );
}

/**
 * __useGetCreativeVersionQuery__
 *
 * To run a query within a React component, call `useGetCreativeVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreativeVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreativeVersionQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      creativeId: // value for 'creativeId'
 *      creativeVersionId: // value for 'creativeVersionId'
 *   },
 * });
 */
export function useGetCreativeVersionQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetCreativeVersionQuery, GqlClientGetCreativeVersionQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetCreativeVersionQuery, GqlClientGetCreativeVersionQueryVariables>(GetCreativeVersionDocument, options);
}
export function useGetCreativeVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetCreativeVersionQuery, GqlClientGetCreativeVersionQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetCreativeVersionQuery, GqlClientGetCreativeVersionQueryVariables>(GetCreativeVersionDocument, options);
}
export type GetCreativeVersionQueryHookResult = ReturnType<typeof useGetCreativeVersionQuery>;
export type GetCreativeVersionLazyQueryHookResult = ReturnType<typeof useGetCreativeVersionLazyQuery>;
export type GetCreativeVersionQueryResult = Apollo.QueryResult<GqlClientGetCreativeVersionQuery, GqlClientGetCreativeVersionQueryVariables>;
export const UpdateCreativeDraftDocument = gql`
    mutation UpdateCreativeDraft($input: UpdateCreativeDraftInput!) {
        updateCreativeDraft(input: $input) {
            result
            ... on UpdateCreativeDraftOutputSuccess {
                creativeDraft {
                    ...CreativeVersion
                }
            }
        }
    }
    ${CreativeVersionFragmentDoc}
`;
export type GqlClientUpdateCreativeDraftMutationFn = Apollo.MutationFunction<GqlClientUpdateCreativeDraftMutation, GqlClientUpdateCreativeDraftMutationVariables>;
export type GqlClientUpdateCreativeDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCreativeDraftMutation, GqlClientUpdateCreativeDraftMutationVariables>;
} & TChildProps;
export function withUpdateCreativeDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateCreativeDraftMutation,
        GqlClientUpdateCreativeDraftMutationVariables,
        GqlClientUpdateCreativeDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateCreativeDraftMutation, GqlClientUpdateCreativeDraftMutationVariables, GqlClientUpdateCreativeDraftProps<TChildProps, TDataName>>(
        UpdateCreativeDraftDocument,
        {
            alias: "updateCreativeDraft",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateCreativeDraftMutation__
 *
 * To run a mutation, you first call `useUpdateCreativeDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreativeDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreativeDraftMutation, { data, loading, error }] = useUpdateCreativeDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreativeDraftMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCreativeDraftMutation, GqlClientUpdateCreativeDraftMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCreativeDraftMutation, GqlClientUpdateCreativeDraftMutationVariables>(UpdateCreativeDraftDocument, options);
}
export type UpdateCreativeDraftMutationHookResult = ReturnType<typeof useUpdateCreativeDraftMutation>;
export type UpdateCreativeDraftMutationResult = Apollo.MutationResult<GqlClientUpdateCreativeDraftMutation>;
export type UpdateCreativeDraftMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateCreativeDraftMutation, GqlClientUpdateCreativeDraftMutationVariables>;
export const UpdateCreativeDraftDefinitionsDocument = gql`
    mutation UpdateCreativeDraftDefinitions($input: UpdateCreativeDraftDefinitionsInput!) {
        updateCreativeDraftDefinitions(input: $input) {
            result
            ... on UpdateCreativeDraftDefinitionsOutputSuccess {
                creativeDraft {
                    ...CreativeDraftInfo
                }
            }
        }
    }
    ${CreativeDraftInfoFragmentDoc}
`;
export type GqlClientUpdateCreativeDraftDefinitionsMutationFn = Apollo.MutationFunction<GqlClientUpdateCreativeDraftDefinitionsMutation, GqlClientUpdateCreativeDraftDefinitionsMutationVariables>;
export type GqlClientUpdateCreativeDraftDefinitionsProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateCreativeDraftDefinitionsMutation, GqlClientUpdateCreativeDraftDefinitionsMutationVariables>;
} & TChildProps;
export function withUpdateCreativeDraftDefinitions<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateCreativeDraftDefinitionsMutation,
        GqlClientUpdateCreativeDraftDefinitionsMutationVariables,
        GqlClientUpdateCreativeDraftDefinitionsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateCreativeDraftDefinitionsMutation,
        GqlClientUpdateCreativeDraftDefinitionsMutationVariables,
        GqlClientUpdateCreativeDraftDefinitionsProps<TChildProps, TDataName>
    >(UpdateCreativeDraftDefinitionsDocument, {
        alias: "updateCreativeDraftDefinitions",
        ...operationOptions
    });
}

/**
 * __useUpdateCreativeDraftDefinitionsMutation__
 *
 * To run a mutation, you first call `useUpdateCreativeDraftDefinitionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreativeDraftDefinitionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreativeDraftDefinitionsMutation, { data, loading, error }] = useUpdateCreativeDraftDefinitionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreativeDraftDefinitionsMutation(
    baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateCreativeDraftDefinitionsMutation, GqlClientUpdateCreativeDraftDefinitionsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateCreativeDraftDefinitionsMutation, GqlClientUpdateCreativeDraftDefinitionsMutationVariables>(UpdateCreativeDraftDefinitionsDocument, options);
}
export type UpdateCreativeDraftDefinitionsMutationHookResult = ReturnType<typeof useUpdateCreativeDraftDefinitionsMutation>;
export type UpdateCreativeDraftDefinitionsMutationResult = Apollo.MutationResult<GqlClientUpdateCreativeDraftDefinitionsMutation>;
export type UpdateCreativeDraftDefinitionsMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateCreativeDraftDefinitionsMutation, GqlClientUpdateCreativeDraftDefinitionsMutationVariables>;
export const CreateExperimentDocument = gql`
    mutation CreateExperiment($input: CreateExperimentInput!) {
        createExperiment(input: $input) {
            result
            program {
                id
                experiments {
                    ...Experiment
                }
            }
        }
    }
    ${ExperimentFragmentDoc}
`;
export type GqlClientCreateExperimentMutationFn = Apollo.MutationFunction<GqlClientCreateExperimentMutation, GqlClientCreateExperimentMutationVariables>;
export type GqlClientCreateExperimentProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateExperimentMutation, GqlClientCreateExperimentMutationVariables>;
} & TChildProps;
export function withCreateExperiment<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateExperimentMutation, GqlClientCreateExperimentMutationVariables, GqlClientCreateExperimentProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateExperimentMutation, GqlClientCreateExperimentMutationVariables, GqlClientCreateExperimentProps<TChildProps, TDataName>>(
        CreateExperimentDocument,
        {
            alias: "createExperiment",
            ...operationOptions
        }
    );
}

/**
 * __useCreateExperimentMutation__
 *
 * To run a mutation, you first call `useCreateExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExperimentMutation, { data, loading, error }] = useCreateExperimentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExperimentMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateExperimentMutation, GqlClientCreateExperimentMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateExperimentMutation, GqlClientCreateExperimentMutationVariables>(CreateExperimentDocument, options);
}
export type CreateExperimentMutationHookResult = ReturnType<typeof useCreateExperimentMutation>;
export type CreateExperimentMutationResult = Apollo.MutationResult<GqlClientCreateExperimentMutation>;
export type CreateExperimentMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateExperimentMutation, GqlClientCreateExperimentMutationVariables>;
export const GetExperimentsDocument = gql`
    query GetExperiments($programId: ID!) {
        program(id: $programId) {
            id
            experiments {
                ...Experiment
            }
        }
    }
    ${ExperimentFragmentDoc}
`;
export type GqlClientGetExperimentsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetExperimentsQuery, GqlClientGetExperimentsQueryVariables>;
} & TChildProps;
export function withGetExperiments<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetExperimentsQuery, GqlClientGetExperimentsQueryVariables, GqlClientGetExperimentsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetExperimentsQuery, GqlClientGetExperimentsQueryVariables, GqlClientGetExperimentsProps<TChildProps, TDataName>>(GetExperimentsDocument, {
        alias: "getExperiments",
        ...operationOptions
    });
}

/**
 * __useGetExperimentsQuery__
 *
 * To run a query within a React component, call `useGetExperimentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperimentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperimentsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetExperimentsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetExperimentsQuery, GqlClientGetExperimentsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetExperimentsQuery, GqlClientGetExperimentsQueryVariables>(GetExperimentsDocument, options);
}
export function useGetExperimentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetExperimentsQuery, GqlClientGetExperimentsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetExperimentsQuery, GqlClientGetExperimentsQueryVariables>(GetExperimentsDocument, options);
}
export type GetExperimentsQueryHookResult = ReturnType<typeof useGetExperimentsQuery>;
export type GetExperimentsLazyQueryHookResult = ReturnType<typeof useGetExperimentsLazyQuery>;
export type GetExperimentsQueryResult = Apollo.QueryResult<GqlClientGetExperimentsQuery, GqlClientGetExperimentsQueryVariables>;
export const UpdateExperimentDocument = gql`
    mutation UpdateExperiment($input: UpdateExperimentInput!) {
        updateExperiment(input: $input) {
            result
            experiment {
                ...Experiment
            }
        }
    }
    ${ExperimentFragmentDoc}
`;
export type GqlClientUpdateExperimentMutationFn = Apollo.MutationFunction<GqlClientUpdateExperimentMutation, GqlClientUpdateExperimentMutationVariables>;
export type GqlClientUpdateExperimentProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateExperimentMutation, GqlClientUpdateExperimentMutationVariables>;
} & TChildProps;
export function withUpdateExperiment<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateExperimentMutation, GqlClientUpdateExperimentMutationVariables, GqlClientUpdateExperimentProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateExperimentMutation, GqlClientUpdateExperimentMutationVariables, GqlClientUpdateExperimentProps<TChildProps, TDataName>>(
        UpdateExperimentDocument,
        {
            alias: "updateExperiment",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateExperimentMutation__
 *
 * To run a mutation, you first call `useUpdateExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExperimentMutation, { data, loading, error }] = useUpdateExperimentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExperimentMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateExperimentMutation, GqlClientUpdateExperimentMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateExperimentMutation, GqlClientUpdateExperimentMutationVariables>(UpdateExperimentDocument, options);
}
export type UpdateExperimentMutationHookResult = ReturnType<typeof useUpdateExperimentMutation>;
export type UpdateExperimentMutationResult = Apollo.MutationResult<GqlClientUpdateExperimentMutation>;
export type UpdateExperimentMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateExperimentMutation, GqlClientUpdateExperimentMutationVariables>;
export const CreateAudienceDocument = gql`
    mutation CreateAudience($programVersionId: ID!, $input: CreateAudienceWithDataElementsInput!) {
        createAudienceWithDataElements(programVersionId: $programVersionId, input: $input) {
            result
            audience {
                ...Audience
                programVersion {
                    ...ProgramVersion
                }
            }
        }
    }
    ${AudienceFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientCreateAudienceMutationFn = Apollo.MutationFunction<GqlClientCreateAudienceMutation, GqlClientCreateAudienceMutationVariables>;
export type GqlClientCreateAudienceProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateAudienceMutation, GqlClientCreateAudienceMutationVariables>;
} & TChildProps;
export function withCreateAudience<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateAudienceMutation, GqlClientCreateAudienceMutationVariables, GqlClientCreateAudienceProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateAudienceMutation, GqlClientCreateAudienceMutationVariables, GqlClientCreateAudienceProps<TChildProps, TDataName>>(
        CreateAudienceDocument,
        {
            alias: "createAudience",
            ...operationOptions
        }
    );
}

/**
 * __useCreateAudienceMutation__
 *
 * To run a mutation, you first call `useCreateAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAudienceMutation, { data, loading, error }] = useCreateAudienceMutation({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAudienceMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateAudienceMutation, GqlClientCreateAudienceMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateAudienceMutation, GqlClientCreateAudienceMutationVariables>(CreateAudienceDocument, options);
}
export type CreateAudienceMutationHookResult = ReturnType<typeof useCreateAudienceMutation>;
export type CreateAudienceMutationResult = Apollo.MutationResult<GqlClientCreateAudienceMutation>;
export type CreateAudienceMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateAudienceMutation, GqlClientCreateAudienceMutationVariables>;
export const DeleteAudienceDocument = gql`
    mutation DeleteAudience($input: DeleteAudienceInput!) {
        deleteAudience(input: $input) {
            result
            programVersion {
                ...ProgramVersion
            }
        }
    }
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientDeleteAudienceMutationFn = Apollo.MutationFunction<GqlClientDeleteAudienceMutation, GqlClientDeleteAudienceMutationVariables>;
export type GqlClientDeleteAudienceProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteAudienceMutation, GqlClientDeleteAudienceMutationVariables>;
} & TChildProps;
export function withDeleteAudience<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteAudienceMutation, GqlClientDeleteAudienceMutationVariables, GqlClientDeleteAudienceProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteAudienceMutation, GqlClientDeleteAudienceMutationVariables, GqlClientDeleteAudienceProps<TChildProps, TDataName>>(
        DeleteAudienceDocument,
        {
            alias: "deleteAudience",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteAudienceMutation__
 *
 * To run a mutation, you first call `useDeleteAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAudienceMutation, { data, loading, error }] = useDeleteAudienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAudienceMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteAudienceMutation, GqlClientDeleteAudienceMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteAudienceMutation, GqlClientDeleteAudienceMutationVariables>(DeleteAudienceDocument, options);
}
export type DeleteAudienceMutationHookResult = ReturnType<typeof useDeleteAudienceMutation>;
export type DeleteAudienceMutationResult = Apollo.MutationResult<GqlClientDeleteAudienceMutation>;
export type DeleteAudienceMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteAudienceMutation, GqlClientDeleteAudienceMutationVariables>;
export const UpdateAudienceDocument = gql`
    mutation UpdateAudience($input: UpdateAudienceWithDataElementsInput!, $dryRun: Boolean) {
        updateAudienceWithDataElements(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateAudienceWithDataElementsOutputSuccess {
                audience {
                    ...Audience
                    programVersion {
                        ...ProgramVersion
                    }
                }
            }
        }
    }
    ${AudienceFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientUpdateAudienceMutationFn = Apollo.MutationFunction<GqlClientUpdateAudienceMutation, GqlClientUpdateAudienceMutationVariables>;
export type GqlClientUpdateAudienceProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateAudienceMutation, GqlClientUpdateAudienceMutationVariables>;
} & TChildProps;
export function withUpdateAudience<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateAudienceMutation, GqlClientUpdateAudienceMutationVariables, GqlClientUpdateAudienceProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateAudienceMutation, GqlClientUpdateAudienceMutationVariables, GqlClientUpdateAudienceProps<TChildProps, TDataName>>(
        UpdateAudienceDocument,
        {
            alias: "updateAudience",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateAudienceMutation__
 *
 * To run a mutation, you first call `useUpdateAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAudienceMutation, { data, loading, error }] = useUpdateAudienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateAudienceMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateAudienceMutation, GqlClientUpdateAudienceMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateAudienceMutation, GqlClientUpdateAudienceMutationVariables>(UpdateAudienceDocument, options);
}
export type UpdateAudienceMutationHookResult = ReturnType<typeof useUpdateAudienceMutation>;
export type UpdateAudienceMutationResult = Apollo.MutationResult<GqlClientUpdateAudienceMutation>;
export type UpdateAudienceMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateAudienceMutation, GqlClientUpdateAudienceMutationVariables>;
export const CreateChannelDocument = gql`
    mutation CreateChannel($programVersionId: ID!, $input: CreateChannelInput!) {
        createChannel(programVersionId: $programVersionId, input: $input) {
            result
            channel {
                ...Channel
                programVersion {
                    ...ProgramVersion
                }
            }
        }
    }
    ${ChannelFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientCreateChannelMutationFn = Apollo.MutationFunction<GqlClientCreateChannelMutation, GqlClientCreateChannelMutationVariables>;
export type GqlClientCreateChannelProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateChannelMutation, GqlClientCreateChannelMutationVariables>;
} & TChildProps;
export function withCreateChannel<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateChannelMutation, GqlClientCreateChannelMutationVariables, GqlClientCreateChannelProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateChannelMutation, GqlClientCreateChannelMutationVariables, GqlClientCreateChannelProps<TChildProps, TDataName>>(CreateChannelDocument, {
        alias: "createChannel",
        ...operationOptions
    });
}

/**
 * __useCreateChannelMutation__
 *
 * To run a mutation, you first call `useCreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelMutation, { data, loading, error }] = useCreateChannelMutation({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChannelMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateChannelMutation, GqlClientCreateChannelMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateChannelMutation, GqlClientCreateChannelMutationVariables>(CreateChannelDocument, options);
}
export type CreateChannelMutationHookResult = ReturnType<typeof useCreateChannelMutation>;
export type CreateChannelMutationResult = Apollo.MutationResult<GqlClientCreateChannelMutation>;
export type CreateChannelMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateChannelMutation, GqlClientCreateChannelMutationVariables>;
export const DeleteChannelDocument = gql`
    mutation DeleteChannel($input: DeleteChannelInput!) {
        deleteChannel(input: $input) {
            result
            programVersion {
                ...ProgramVersion
            }
        }
    }
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientDeleteChannelMutationFn = Apollo.MutationFunction<GqlClientDeleteChannelMutation, GqlClientDeleteChannelMutationVariables>;
export type GqlClientDeleteChannelProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteChannelMutation, GqlClientDeleteChannelMutationVariables>;
} & TChildProps;
export function withDeleteChannel<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteChannelMutation, GqlClientDeleteChannelMutationVariables, GqlClientDeleteChannelProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteChannelMutation, GqlClientDeleteChannelMutationVariables, GqlClientDeleteChannelProps<TChildProps, TDataName>>(DeleteChannelDocument, {
        alias: "deleteChannel",
        ...operationOptions
    });
}

/**
 * __useDeleteChannelMutation__
 *
 * To run a mutation, you first call `useDeleteChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChannelMutation, { data, loading, error }] = useDeleteChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteChannelMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteChannelMutation, GqlClientDeleteChannelMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteChannelMutation, GqlClientDeleteChannelMutationVariables>(DeleteChannelDocument, options);
}
export type DeleteChannelMutationHookResult = ReturnType<typeof useDeleteChannelMutation>;
export type DeleteChannelMutationResult = Apollo.MutationResult<GqlClientDeleteChannelMutation>;
export type DeleteChannelMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteChannelMutation, GqlClientDeleteChannelMutationVariables>;
export const UpdateChannelDocument = gql`
    mutation UpdateChannel($input: UpdateChannelInput!, $dryRun: Boolean) {
        updateChannel(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateChannelOutputSuccess {
                channel {
                    ...Channel
                    programVersion {
                        ...ProgramVersion
                    }
                }
            }
        }
    }
    ${ChannelFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientUpdateChannelMutationFn = Apollo.MutationFunction<GqlClientUpdateChannelMutation, GqlClientUpdateChannelMutationVariables>;
export type GqlClientUpdateChannelProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateChannelMutation, GqlClientUpdateChannelMutationVariables>;
} & TChildProps;
export function withUpdateChannel<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateChannelMutation, GqlClientUpdateChannelMutationVariables, GqlClientUpdateChannelProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateChannelMutation, GqlClientUpdateChannelMutationVariables, GqlClientUpdateChannelProps<TChildProps, TDataName>>(UpdateChannelDocument, {
        alias: "updateChannel",
        ...operationOptions
    });
}

/**
 * __useUpdateChannelMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMutation, { data, loading, error }] = useUpdateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateChannelMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateChannelMutation, GqlClientUpdateChannelMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateChannelMutation, GqlClientUpdateChannelMutationVariables>(UpdateChannelDocument, options);
}
export type UpdateChannelMutationHookResult = ReturnType<typeof useUpdateChannelMutation>;
export type UpdateChannelMutationResult = Apollo.MutationResult<GqlClientUpdateChannelMutation>;
export type UpdateChannelMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateChannelMutation, GqlClientUpdateChannelMutationVariables>;
export const CreateBasicFeedDataElementDocument = gql`
    mutation CreateBasicFeedDataElement($programVersionId: ID!, $input: CreateBasicFeedDataElementInput!) {
        createBasicFeedDataElement(programVersionId: $programVersionId, input: $input) {
            result
            feedDataElement {
                ...DataElement
                programVersion {
                    ...ProgramVersion
                }
            }
        }
    }
    ${DataElementFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientCreateBasicFeedDataElementMutationFn = Apollo.MutationFunction<GqlClientCreateBasicFeedDataElementMutation, GqlClientCreateBasicFeedDataElementMutationVariables>;
export type GqlClientCreateBasicFeedDataElementProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateBasicFeedDataElementMutation, GqlClientCreateBasicFeedDataElementMutationVariables>;
} & TChildProps;
export function withCreateBasicFeedDataElement<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateBasicFeedDataElementMutation,
        GqlClientCreateBasicFeedDataElementMutationVariables,
        GqlClientCreateBasicFeedDataElementProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCreateBasicFeedDataElementMutation,
        GqlClientCreateBasicFeedDataElementMutationVariables,
        GqlClientCreateBasicFeedDataElementProps<TChildProps, TDataName>
    >(CreateBasicFeedDataElementDocument, {
        alias: "createBasicFeedDataElement",
        ...operationOptions
    });
}

/**
 * __useCreateBasicFeedDataElementMutation__
 *
 * To run a mutation, you first call `useCreateBasicFeedDataElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBasicFeedDataElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBasicFeedDataElementMutation, { data, loading, error }] = useCreateBasicFeedDataElementMutation({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBasicFeedDataElementMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateBasicFeedDataElementMutation, GqlClientCreateBasicFeedDataElementMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateBasicFeedDataElementMutation, GqlClientCreateBasicFeedDataElementMutationVariables>(CreateBasicFeedDataElementDocument, options);
}
export type CreateBasicFeedDataElementMutationHookResult = ReturnType<typeof useCreateBasicFeedDataElementMutation>;
export type CreateBasicFeedDataElementMutationResult = Apollo.MutationResult<GqlClientCreateBasicFeedDataElementMutation>;
export type CreateBasicFeedDataElementMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateBasicFeedDataElementMutation, GqlClientCreateBasicFeedDataElementMutationVariables>;
export const DeleteDataElementDocument = gql`
    mutation DeleteDataElement($input: DeleteDataElementInput!) {
        deleteDataElement(input: $input) {
            result
            ... on DeleteDataElementOutputSuccess {
                programVersion {
                    ...ProgramVersion
                }
            }
        }
    }
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientDeleteDataElementMutationFn = Apollo.MutationFunction<GqlClientDeleteDataElementMutation, GqlClientDeleteDataElementMutationVariables>;
export type GqlClientDeleteDataElementProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteDataElementMutation, GqlClientDeleteDataElementMutationVariables>;
} & TChildProps;
export function withDeleteDataElement<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteDataElementMutation, GqlClientDeleteDataElementMutationVariables, GqlClientDeleteDataElementProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteDataElementMutation, GqlClientDeleteDataElementMutationVariables, GqlClientDeleteDataElementProps<TChildProps, TDataName>>(
        DeleteDataElementDocument,
        {
            alias: "deleteDataElement",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteDataElementMutation__
 *
 * To run a mutation, you first call `useDeleteDataElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataElementMutation, { data, loading, error }] = useDeleteDataElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDataElementMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteDataElementMutation, GqlClientDeleteDataElementMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteDataElementMutation, GqlClientDeleteDataElementMutationVariables>(DeleteDataElementDocument, options);
}
export type DeleteDataElementMutationHookResult = ReturnType<typeof useDeleteDataElementMutation>;
export type DeleteDataElementMutationResult = Apollo.MutationResult<GqlClientDeleteDataElementMutation>;
export type DeleteDataElementMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteDataElementMutation, GqlClientDeleteDataElementMutationVariables>;
export const UpdateBasicFeedDataElementDocument = gql`
    mutation UpdateBasicFeedDataElement($input: UpdateBasicFeedDataElementInput!, $dryRun: Boolean) {
        updateBasicFeedDataElement(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateBasicFeedDataElementOutputSuccess {
                feedDataElement {
                    ...DataElement
                    programVersion {
                        ...ProgramVersion
                    }
                }
            }
        }
    }
    ${DataElementFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientUpdateBasicFeedDataElementMutationFn = Apollo.MutationFunction<GqlClientUpdateBasicFeedDataElementMutation, GqlClientUpdateBasicFeedDataElementMutationVariables>;
export type GqlClientUpdateBasicFeedDataElementProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateBasicFeedDataElementMutation, GqlClientUpdateBasicFeedDataElementMutationVariables>;
} & TChildProps;
export function withUpdateBasicFeedDataElement<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateBasicFeedDataElementMutation,
        GqlClientUpdateBasicFeedDataElementMutationVariables,
        GqlClientUpdateBasicFeedDataElementProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateBasicFeedDataElementMutation,
        GqlClientUpdateBasicFeedDataElementMutationVariables,
        GqlClientUpdateBasicFeedDataElementProps<TChildProps, TDataName>
    >(UpdateBasicFeedDataElementDocument, {
        alias: "updateBasicFeedDataElement",
        ...operationOptions
    });
}

/**
 * __useUpdateBasicFeedDataElementMutation__
 *
 * To run a mutation, you first call `useUpdateBasicFeedDataElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBasicFeedDataElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBasicFeedDataElementMutation, { data, loading, error }] = useUpdateBasicFeedDataElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateBasicFeedDataElementMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateBasicFeedDataElementMutation, GqlClientUpdateBasicFeedDataElementMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateBasicFeedDataElementMutation, GqlClientUpdateBasicFeedDataElementMutationVariables>(UpdateBasicFeedDataElementDocument, options);
}
export type UpdateBasicFeedDataElementMutationHookResult = ReturnType<typeof useUpdateBasicFeedDataElementMutation>;
export type UpdateBasicFeedDataElementMutationResult = Apollo.MutationResult<GqlClientUpdateBasicFeedDataElementMutation>;
export type UpdateBasicFeedDataElementMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateBasicFeedDataElementMutation, GqlClientUpdateBasicFeedDataElementMutationVariables>;
export const CreateGoalDocument = gql`
    mutation CreateGoal($programVersionId: ID!, $input: CreateGoalWithKPIsInput!) {
        createGoalWithKPIs(programVersionId: $programVersionId, input: $input) {
            result
            goal {
                ...Goal
                programVersion {
                    ...ProgramVersion
                }
            }
        }
    }
    ${GoalFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientCreateGoalMutationFn = Apollo.MutationFunction<GqlClientCreateGoalMutation, GqlClientCreateGoalMutationVariables>;
export type GqlClientCreateGoalProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateGoalMutation, GqlClientCreateGoalMutationVariables>;
} & TChildProps;
export function withCreateGoal<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateGoalMutation, GqlClientCreateGoalMutationVariables, GqlClientCreateGoalProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateGoalMutation, GqlClientCreateGoalMutationVariables, GqlClientCreateGoalProps<TChildProps, TDataName>>(CreateGoalDocument, {
        alias: "createGoal",
        ...operationOptions
    });
}

/**
 * __useCreateGoalMutation__
 *
 * To run a mutation, you first call `useCreateGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoalMutation, { data, loading, error }] = useCreateGoalMutation({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGoalMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateGoalMutation, GqlClientCreateGoalMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateGoalMutation, GqlClientCreateGoalMutationVariables>(CreateGoalDocument, options);
}
export type CreateGoalMutationHookResult = ReturnType<typeof useCreateGoalMutation>;
export type CreateGoalMutationResult = Apollo.MutationResult<GqlClientCreateGoalMutation>;
export type CreateGoalMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateGoalMutation, GqlClientCreateGoalMutationVariables>;
export const DeleteGoalDocument = gql`
    mutation DeleteGoal($input: DeleteGoalInput!) {
        deleteGoal(input: $input) {
            result
            programVersion {
                ...ProgramVersion
            }
        }
    }
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientDeleteGoalMutationFn = Apollo.MutationFunction<GqlClientDeleteGoalMutation, GqlClientDeleteGoalMutationVariables>;
export type GqlClientDeleteGoalProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteGoalMutation, GqlClientDeleteGoalMutationVariables>;
} & TChildProps;
export function withDeleteGoal<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteGoalMutation, GqlClientDeleteGoalMutationVariables, GqlClientDeleteGoalProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteGoalMutation, GqlClientDeleteGoalMutationVariables, GqlClientDeleteGoalProps<TChildProps, TDataName>>(DeleteGoalDocument, {
        alias: "deleteGoal",
        ...operationOptions
    });
}

/**
 * __useDeleteGoalMutation__
 *
 * To run a mutation, you first call `useDeleteGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGoalMutation, { data, loading, error }] = useDeleteGoalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGoalMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteGoalMutation, GqlClientDeleteGoalMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteGoalMutation, GqlClientDeleteGoalMutationVariables>(DeleteGoalDocument, options);
}
export type DeleteGoalMutationHookResult = ReturnType<typeof useDeleteGoalMutation>;
export type DeleteGoalMutationResult = Apollo.MutationResult<GqlClientDeleteGoalMutation>;
export type DeleteGoalMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteGoalMutation, GqlClientDeleteGoalMutationVariables>;
export const UpdateGoalDocument = gql`
    mutation UpdateGoal($input: UpdateGoalWithKPIsInput!, $dryRun: Boolean) {
        updateGoalWithKPIs(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateGoalWithKPIsOutputSuccess {
                goal {
                    ...Goal
                    programVersion {
                        ...ProgramVersion
                    }
                }
            }
        }
    }
    ${GoalFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientUpdateGoalMutationFn = Apollo.MutationFunction<GqlClientUpdateGoalMutation, GqlClientUpdateGoalMutationVariables>;
export type GqlClientUpdateGoalProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateGoalMutation, GqlClientUpdateGoalMutationVariables>;
} & TChildProps;
export function withUpdateGoal<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateGoalMutation, GqlClientUpdateGoalMutationVariables, GqlClientUpdateGoalProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateGoalMutation, GqlClientUpdateGoalMutationVariables, GqlClientUpdateGoalProps<TChildProps, TDataName>>(UpdateGoalDocument, {
        alias: "updateGoal",
        ...operationOptions
    });
}

/**
 * __useUpdateGoalMutation__
 *
 * To run a mutation, you first call `useUpdateGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoalMutation, { data, loading, error }] = useUpdateGoalMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateGoalMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateGoalMutation, GqlClientUpdateGoalMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateGoalMutation, GqlClientUpdateGoalMutationVariables>(UpdateGoalDocument, options);
}
export type UpdateGoalMutationHookResult = ReturnType<typeof useUpdateGoalMutation>;
export type UpdateGoalMutationResult = Apollo.MutationResult<GqlClientUpdateGoalMutation>;
export type UpdateGoalMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateGoalMutation, GqlClientUpdateGoalMutationVariables>;
export const CreateProgramDocument = gql`
    mutation CreateProgram($input: CreateProgramInput!) {
        createProgram(input: $input) {
            result
            program {
                ...Program
            }
        }
    }
    ${ProgramFragmentDoc}
`;
export type GqlClientCreateProgramMutationFn = Apollo.MutationFunction<GqlClientCreateProgramMutation, GqlClientCreateProgramMutationVariables>;
export type GqlClientCreateProgramProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateProgramMutation, GqlClientCreateProgramMutationVariables>;
} & TChildProps;
export function withCreateProgram<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateProgramMutation, GqlClientCreateProgramMutationVariables, GqlClientCreateProgramProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateProgramMutation, GqlClientCreateProgramMutationVariables, GqlClientCreateProgramProps<TChildProps, TDataName>>(CreateProgramDocument, {
        alias: "createProgram",
        ...operationOptions
    });
}

/**
 * __useCreateProgramMutation__
 *
 * To run a mutation, you first call `useCreateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramMutation, { data, loading, error }] = useCreateProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgramMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateProgramMutation, GqlClientCreateProgramMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateProgramMutation, GqlClientCreateProgramMutationVariables>(CreateProgramDocument, options);
}
export type CreateProgramMutationHookResult = ReturnType<typeof useCreateProgramMutation>;
export type CreateProgramMutationResult = Apollo.MutationResult<GqlClientCreateProgramMutation>;
export type CreateProgramMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateProgramMutation, GqlClientCreateProgramMutationVariables>;
export const CreateProgramAndDraftDocument = gql`
    mutation CreateProgramAndDraft($input: CreateProgramAndDraftInput!) {
        createProgramAndDraft(input: $input) {
            result
            program {
                ...Program
                programVersions {
                    ...ProgramVersion
                }
            }
        }
    }
    ${ProgramFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientCreateProgramAndDraftMutationFn = Apollo.MutationFunction<GqlClientCreateProgramAndDraftMutation, GqlClientCreateProgramAndDraftMutationVariables>;
export type GqlClientCreateProgramAndDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateProgramAndDraftMutation, GqlClientCreateProgramAndDraftMutationVariables>;
} & TChildProps;
export function withCreateProgramAndDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateProgramAndDraftMutation,
        GqlClientCreateProgramAndDraftMutationVariables,
        GqlClientCreateProgramAndDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateProgramAndDraftMutation, GqlClientCreateProgramAndDraftMutationVariables, GqlClientCreateProgramAndDraftProps<TChildProps, TDataName>>(
        CreateProgramAndDraftDocument,
        {
            alias: "createProgramAndDraft",
            ...operationOptions
        }
    );
}

/**
 * __useCreateProgramAndDraftMutation__
 *
 * To run a mutation, you first call `useCreateProgramAndDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramAndDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramAndDraftMutation, { data, loading, error }] = useCreateProgramAndDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgramAndDraftMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateProgramAndDraftMutation, GqlClientCreateProgramAndDraftMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateProgramAndDraftMutation, GqlClientCreateProgramAndDraftMutationVariables>(CreateProgramAndDraftDocument, options);
}
export type CreateProgramAndDraftMutationHookResult = ReturnType<typeof useCreateProgramAndDraftMutation>;
export type CreateProgramAndDraftMutationResult = Apollo.MutationResult<GqlClientCreateProgramAndDraftMutation>;
export type CreateProgramAndDraftMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateProgramAndDraftMutation, GqlClientCreateProgramAndDraftMutationVariables>;
export const DeleteProgramDocument = gql`
    mutation DeleteProgram($input: DeleteProgramInput!) {
        deleteProgram(input: $input) {
            result
        }
    }
`;
export type GqlClientDeleteProgramMutationFn = Apollo.MutationFunction<GqlClientDeleteProgramMutation, GqlClientDeleteProgramMutationVariables>;
export type GqlClientDeleteProgramProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteProgramMutation, GqlClientDeleteProgramMutationVariables>;
} & TChildProps;
export function withDeleteProgram<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteProgramMutation, GqlClientDeleteProgramMutationVariables, GqlClientDeleteProgramProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteProgramMutation, GqlClientDeleteProgramMutationVariables, GqlClientDeleteProgramProps<TChildProps, TDataName>>(DeleteProgramDocument, {
        alias: "deleteProgram",
        ...operationOptions
    });
}

/**
 * __useDeleteProgramMutation__
 *
 * To run a mutation, you first call `useDeleteProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProgramMutation, { data, loading, error }] = useDeleteProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProgramMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteProgramMutation, GqlClientDeleteProgramMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteProgramMutation, GqlClientDeleteProgramMutationVariables>(DeleteProgramDocument, options);
}
export type DeleteProgramMutationHookResult = ReturnType<typeof useDeleteProgramMutation>;
export type DeleteProgramMutationResult = Apollo.MutationResult<GqlClientDeleteProgramMutation>;
export type DeleteProgramMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteProgramMutation, GqlClientDeleteProgramMutationVariables>;
export const GetProgramDocument = gql`
    query getProgram($id: ID!) {
        program(id: $id) {
            ...Program
            programVersionDrafts {
                ...ProgramVersion
            }
        }
    }
    ${ProgramFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientGetProgramProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetProgramQuery, GqlClientGetProgramQueryVariables>;
} & TChildProps;
export function withGetProgram<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetProgramQuery, GqlClientGetProgramQueryVariables, GqlClientGetProgramProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetProgramQuery, GqlClientGetProgramQueryVariables, GqlClientGetProgramProps<TChildProps, TDataName>>(GetProgramDocument, {
        alias: "getProgram",
        ...operationOptions
    });
}

/**
 * __useGetProgramQuery__
 *
 * To run a query within a React component, call `useGetProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProgramQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetProgramQuery, GqlClientGetProgramQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetProgramQuery, GqlClientGetProgramQueryVariables>(GetProgramDocument, options);
}
export function useGetProgramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetProgramQuery, GqlClientGetProgramQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetProgramQuery, GqlClientGetProgramQueryVariables>(GetProgramDocument, options);
}
export type GetProgramQueryHookResult = ReturnType<typeof useGetProgramQuery>;
export type GetProgramLazyQueryHookResult = ReturnType<typeof useGetProgramLazyQuery>;
export type GetProgramQueryResult = Apollo.QueryResult<GqlClientGetProgramQuery, GqlClientGetProgramQueryVariables>;
export const GetProgramMetadataDocument = gql`
    query getProgramMetadata($id: ID!) {
        program(id: $id) {
            ...Program
            programVersionDrafts {
                ...ProgramVersionMetadata
            }
        }
    }
    ${ProgramFragmentDoc}
    ${ProgramVersionMetadataFragmentDoc}
`;
export type GqlClientGetProgramMetadataProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetProgramMetadataQuery, GqlClientGetProgramMetadataQueryVariables>;
} & TChildProps;
export function withGetProgramMetadata<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetProgramMetadataQuery, GqlClientGetProgramMetadataQueryVariables, GqlClientGetProgramMetadataProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetProgramMetadataQuery, GqlClientGetProgramMetadataQueryVariables, GqlClientGetProgramMetadataProps<TChildProps, TDataName>>(
        GetProgramMetadataDocument,
        {
            alias: "getProgramMetadata",
            ...operationOptions
        }
    );
}

/**
 * __useGetProgramMetadataQuery__
 *
 * To run a query within a React component, call `useGetProgramMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramMetadataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProgramMetadataQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetProgramMetadataQuery, GqlClientGetProgramMetadataQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetProgramMetadataQuery, GqlClientGetProgramMetadataQueryVariables>(GetProgramMetadataDocument, options);
}
export function useGetProgramMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetProgramMetadataQuery, GqlClientGetProgramMetadataQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetProgramMetadataQuery, GqlClientGetProgramMetadataQueryVariables>(GetProgramMetadataDocument, options);
}
export type GetProgramMetadataQueryHookResult = ReturnType<typeof useGetProgramMetadataQuery>;
export type GetProgramMetadataLazyQueryHookResult = ReturnType<typeof useGetProgramMetadataLazyQuery>;
export type GetProgramMetadataQueryResult = Apollo.QueryResult<GqlClientGetProgramMetadataQuery, GqlClientGetProgramMetadataQueryVariables>;
export const UpdateFrameworkProgramDocument = gql`
    mutation UpdateFrameworkProgram($input: UpdateProgramInput!, $dryRun: Boolean) {
        updateProgram(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateProgramOutputSuccess {
                program {
                    ...Program
                    account {
                        ...Account
                    }
                }
            }
        }
    }
    ${ProgramFragmentDoc}
    ${AccountFragmentDoc}
`;
export type GqlClientUpdateFrameworkProgramMutationFn = Apollo.MutationFunction<GqlClientUpdateFrameworkProgramMutation, GqlClientUpdateFrameworkProgramMutationVariables>;
export type GqlClientUpdateFrameworkProgramProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateFrameworkProgramMutation, GqlClientUpdateFrameworkProgramMutationVariables>;
} & TChildProps;
export function withUpdateFrameworkProgram<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateFrameworkProgramMutation,
        GqlClientUpdateFrameworkProgramMutationVariables,
        GqlClientUpdateFrameworkProgramProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateFrameworkProgramMutation, GqlClientUpdateFrameworkProgramMutationVariables, GqlClientUpdateFrameworkProgramProps<TChildProps, TDataName>>(
        UpdateFrameworkProgramDocument,
        {
            alias: "updateFrameworkProgram",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateFrameworkProgramMutation__
 *
 * To run a mutation, you first call `useUpdateFrameworkProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFrameworkProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFrameworkProgramMutation, { data, loading, error }] = useUpdateFrameworkProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateFrameworkProgramMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateFrameworkProgramMutation, GqlClientUpdateFrameworkProgramMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateFrameworkProgramMutation, GqlClientUpdateFrameworkProgramMutationVariables>(UpdateFrameworkProgramDocument, options);
}
export type UpdateFrameworkProgramMutationHookResult = ReturnType<typeof useUpdateFrameworkProgramMutation>;
export type UpdateFrameworkProgramMutationResult = Apollo.MutationResult<GqlClientUpdateFrameworkProgramMutation>;
export type UpdateFrameworkProgramMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateFrameworkProgramMutation, GqlClientUpdateFrameworkProgramMutationVariables>;
export const CopyProgramVersionDraftDocument = gql`
    mutation CopyProgramVersionDraft($input: CopyProgramVersionDraftInput!) {
        copyProgramVersionDraft(input: $input) {
            result
            targetProgramVersion {
                ...ProgramVersion
                program {
                    ...Program
                    account {
                        ...Account
                    }
                    programVersionDrafts {
                        ...ProgramVersion
                    }
                }
            }
        }
    }
    ${ProgramVersionFragmentDoc}
    ${ProgramFragmentDoc}
    ${AccountFragmentDoc}
`;
export type GqlClientCopyProgramVersionDraftMutationFn = Apollo.MutationFunction<GqlClientCopyProgramVersionDraftMutation, GqlClientCopyProgramVersionDraftMutationVariables>;
export type GqlClientCopyProgramVersionDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCopyProgramVersionDraftMutation, GqlClientCopyProgramVersionDraftMutationVariables>;
} & TChildProps;
export function withCopyProgramVersionDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCopyProgramVersionDraftMutation,
        GqlClientCopyProgramVersionDraftMutationVariables,
        GqlClientCopyProgramVersionDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientCopyProgramVersionDraftMutation,
        GqlClientCopyProgramVersionDraftMutationVariables,
        GqlClientCopyProgramVersionDraftProps<TChildProps, TDataName>
    >(CopyProgramVersionDraftDocument, {
        alias: "copyProgramVersionDraft",
        ...operationOptions
    });
}

/**
 * __useCopyProgramVersionDraftMutation__
 *
 * To run a mutation, you first call `useCopyProgramVersionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyProgramVersionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyProgramVersionDraftMutation, { data, loading, error }] = useCopyProgramVersionDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyProgramVersionDraftMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCopyProgramVersionDraftMutation, GqlClientCopyProgramVersionDraftMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCopyProgramVersionDraftMutation, GqlClientCopyProgramVersionDraftMutationVariables>(CopyProgramVersionDraftDocument, options);
}
export type CopyProgramVersionDraftMutationHookResult = ReturnType<typeof useCopyProgramVersionDraftMutation>;
export type CopyProgramVersionDraftMutationResult = Apollo.MutationResult<GqlClientCopyProgramVersionDraftMutation>;
export type CopyProgramVersionDraftMutationOptions = Apollo.BaseMutationOptions<GqlClientCopyProgramVersionDraftMutation, GqlClientCopyProgramVersionDraftMutationVariables>;
export const DeleteProgramVersionDraftDocument = gql`
    mutation DeleteProgramVersionDraft($input: DeleteProgramVersionDraftInput!) {
        deleteProgramVersionDraft(input: $input) {
            result
            program {
                ...Program
                programVersionDrafts {
                    ...ProgramVersion
                }
            }
        }
    }
    ${ProgramFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientDeleteProgramVersionDraftMutationFn = Apollo.MutationFunction<GqlClientDeleteProgramVersionDraftMutation, GqlClientDeleteProgramVersionDraftMutationVariables>;
export type GqlClientDeleteProgramVersionDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteProgramVersionDraftMutation, GqlClientDeleteProgramVersionDraftMutationVariables>;
} & TChildProps;
export function withDeleteProgramVersionDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteProgramVersionDraftMutation,
        GqlClientDeleteProgramVersionDraftMutationVariables,
        GqlClientDeleteProgramVersionDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientDeleteProgramVersionDraftMutation,
        GqlClientDeleteProgramVersionDraftMutationVariables,
        GqlClientDeleteProgramVersionDraftProps<TChildProps, TDataName>
    >(DeleteProgramVersionDraftDocument, {
        alias: "deleteProgramVersionDraft",
        ...operationOptions
    });
}

/**
 * __useDeleteProgramVersionDraftMutation__
 *
 * To run a mutation, you first call `useDeleteProgramVersionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProgramVersionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProgramVersionDraftMutation, { data, loading, error }] = useDeleteProgramVersionDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProgramVersionDraftMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteProgramVersionDraftMutation, GqlClientDeleteProgramVersionDraftMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteProgramVersionDraftMutation, GqlClientDeleteProgramVersionDraftMutationVariables>(DeleteProgramVersionDraftDocument, options);
}
export type DeleteProgramVersionDraftMutationHookResult = ReturnType<typeof useDeleteProgramVersionDraftMutation>;
export type DeleteProgramVersionDraftMutationResult = Apollo.MutationResult<GqlClientDeleteProgramVersionDraftMutation>;
export type DeleteProgramVersionDraftMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteProgramVersionDraftMutation, GqlClientDeleteProgramVersionDraftMutationVariables>;
export const MovePorgramVersionDraftDocument = gql`
    mutation MovePorgramVersionDraft($input: MoveProgramVersionDraftInput!) {
        moveProgramVersionDraft(input: $input) {
            result
            programVersion {
                ...ProgramVersion
                program {
                    ...Program
                    programVersionDrafts {
                        ...ProgramVersion
                    }
                }
            }
            sourceProgram {
                ...Program
                programVersionDrafts {
                    ...ProgramVersion
                }
            }
        }
    }
    ${ProgramVersionFragmentDoc}
    ${ProgramFragmentDoc}
`;
export type GqlClientMovePorgramVersionDraftMutationFn = Apollo.MutationFunction<GqlClientMovePorgramVersionDraftMutation, GqlClientMovePorgramVersionDraftMutationVariables>;
export type GqlClientMovePorgramVersionDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientMovePorgramVersionDraftMutation, GqlClientMovePorgramVersionDraftMutationVariables>;
} & TChildProps;
export function withMovePorgramVersionDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientMovePorgramVersionDraftMutation,
        GqlClientMovePorgramVersionDraftMutationVariables,
        GqlClientMovePorgramVersionDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientMovePorgramVersionDraftMutation,
        GqlClientMovePorgramVersionDraftMutationVariables,
        GqlClientMovePorgramVersionDraftProps<TChildProps, TDataName>
    >(MovePorgramVersionDraftDocument, {
        alias: "movePorgramVersionDraft",
        ...operationOptions
    });
}

/**
 * __useMovePorgramVersionDraftMutation__
 *
 * To run a mutation, you first call `useMovePorgramVersionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovePorgramVersionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movePorgramVersionDraftMutation, { data, loading, error }] = useMovePorgramVersionDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMovePorgramVersionDraftMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientMovePorgramVersionDraftMutation, GqlClientMovePorgramVersionDraftMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientMovePorgramVersionDraftMutation, GqlClientMovePorgramVersionDraftMutationVariables>(MovePorgramVersionDraftDocument, options);
}
export type MovePorgramVersionDraftMutationHookResult = ReturnType<typeof useMovePorgramVersionDraftMutation>;
export type MovePorgramVersionDraftMutationResult = Apollo.MutationResult<GqlClientMovePorgramVersionDraftMutation>;
export type MovePorgramVersionDraftMutationOptions = Apollo.BaseMutationOptions<GqlClientMovePorgramVersionDraftMutation, GqlClientMovePorgramVersionDraftMutationVariables>;
export const UpdateProgramVersionDraftDocument = gql`
    mutation UpdateProgramVersionDraft($input: UpdateProgramVersionDraftInput!, $dryRun: Boolean) {
        updateProgramVersionDraft(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateProgramVersionDraftOutputSuccess {
                programVersion {
                    ...ProgramVersion
                    program {
                        ...Program
                        programVersionDrafts {
                            ...ProgramVersion
                        }
                    }
                }
            }
        }
    }
    ${ProgramVersionFragmentDoc}
    ${ProgramFragmentDoc}
`;
export type GqlClientUpdateProgramVersionDraftMutationFn = Apollo.MutationFunction<GqlClientUpdateProgramVersionDraftMutation, GqlClientUpdateProgramVersionDraftMutationVariables>;
export type GqlClientUpdateProgramVersionDraftProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateProgramVersionDraftMutation, GqlClientUpdateProgramVersionDraftMutationVariables>;
} & TChildProps;
export function withUpdateProgramVersionDraft<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateProgramVersionDraftMutation,
        GqlClientUpdateProgramVersionDraftMutationVariables,
        GqlClientUpdateProgramVersionDraftProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateProgramVersionDraftMutation,
        GqlClientUpdateProgramVersionDraftMutationVariables,
        GqlClientUpdateProgramVersionDraftProps<TChildProps, TDataName>
    >(UpdateProgramVersionDraftDocument, {
        alias: "updateProgramVersionDraft",
        ...operationOptions
    });
}

/**
 * __useUpdateProgramVersionDraftMutation__
 *
 * To run a mutation, you first call `useUpdateProgramVersionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramVersionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramVersionDraftMutation, { data, loading, error }] = useUpdateProgramVersionDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateProgramVersionDraftMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateProgramVersionDraftMutation, GqlClientUpdateProgramVersionDraftMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateProgramVersionDraftMutation, GqlClientUpdateProgramVersionDraftMutationVariables>(UpdateProgramVersionDraftDocument, options);
}
export type UpdateProgramVersionDraftMutationHookResult = ReturnType<typeof useUpdateProgramVersionDraftMutation>;
export type UpdateProgramVersionDraftMutationResult = Apollo.MutationResult<GqlClientUpdateProgramVersionDraftMutation>;
export type UpdateProgramVersionDraftMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateProgramVersionDraftMutation, GqlClientUpdateProgramVersionDraftMutationVariables>;
export const CreateSceneDocument = gql`
    mutation CreateScene($programVersionId: ID!, $input: CreateSceneWithKPIsPersonalizationsAndDataElementsInput!) {
        createSceneWithKPIsPersonalizationsAndDataElements(programVersionId: $programVersionId, input: $input) {
            result
            scene {
                ...Scene
                programVersion {
                    ...ProgramVersion
                }
            }
        }
    }
    ${SceneFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientCreateSceneMutationFn = Apollo.MutationFunction<GqlClientCreateSceneMutation, GqlClientCreateSceneMutationVariables>;
export type GqlClientCreateSceneProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateSceneMutation, GqlClientCreateSceneMutationVariables>;
} & TChildProps;
export function withCreateScene<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateSceneMutation, GqlClientCreateSceneMutationVariables, GqlClientCreateSceneProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateSceneMutation, GqlClientCreateSceneMutationVariables, GqlClientCreateSceneProps<TChildProps, TDataName>>(CreateSceneDocument, {
        alias: "createScene",
        ...operationOptions
    });
}

/**
 * __useCreateSceneMutation__
 *
 * To run a mutation, you first call `useCreateSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSceneMutation, { data, loading, error }] = useCreateSceneMutation({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSceneMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateSceneMutation, GqlClientCreateSceneMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateSceneMutation, GqlClientCreateSceneMutationVariables>(CreateSceneDocument, options);
}
export type CreateSceneMutationHookResult = ReturnType<typeof useCreateSceneMutation>;
export type CreateSceneMutationResult = Apollo.MutationResult<GqlClientCreateSceneMutation>;
export type CreateSceneMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateSceneMutation, GqlClientCreateSceneMutationVariables>;
export const UpdateSceneDocument = gql`
    mutation UpdateScene($input: UpdateSceneWithKPIsPersonalizationsAndDataElementsInput!, $dryRun: Boolean) {
        updateSceneWithKPIsPersonalizationsAndDataElements(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateSceneWithKPIsPersonalizationsAndDataElementsOutputSuccess {
                scene {
                    ...Scene
                    programVersion {
                        ...ProgramVersion
                    }
                }
            }
        }
    }
    ${SceneFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientUpdateSceneMutationFn = Apollo.MutationFunction<GqlClientUpdateSceneMutation, GqlClientUpdateSceneMutationVariables>;
export type GqlClientUpdateSceneProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateSceneMutation, GqlClientUpdateSceneMutationVariables>;
} & TChildProps;
export function withUpdateScene<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateSceneMutation, GqlClientUpdateSceneMutationVariables, GqlClientUpdateSceneProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateSceneMutation, GqlClientUpdateSceneMutationVariables, GqlClientUpdateSceneProps<TChildProps, TDataName>>(UpdateSceneDocument, {
        alias: "updateScene",
        ...operationOptions
    });
}

/**
 * __useUpdateSceneMutation__
 *
 * To run a mutation, you first call `useUpdateSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSceneMutation, { data, loading, error }] = useUpdateSceneMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateSceneMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateSceneMutation, GqlClientUpdateSceneMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateSceneMutation, GqlClientUpdateSceneMutationVariables>(UpdateSceneDocument, options);
}
export type UpdateSceneMutationHookResult = ReturnType<typeof useUpdateSceneMutation>;
export type UpdateSceneMutationResult = Apollo.MutationResult<GqlClientUpdateSceneMutation>;
export type UpdateSceneMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateSceneMutation, GqlClientUpdateSceneMutationVariables>;
export const CreateStoryDocument = gql`
    mutation CreateStory($programVersionId: ID!, $input: CreateStoryWithReachAndScenesInput!) {
        createStoryWithReachAndScenes(programVersionId: $programVersionId, input: $input) {
            result
            story {
                ...Story
                programVersion {
                    ...ProgramVersion
                }
            }
        }
    }
    ${StoryFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientCreateStoryMutationFn = Apollo.MutationFunction<GqlClientCreateStoryMutation, GqlClientCreateStoryMutationVariables>;
export type GqlClientCreateStoryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateStoryMutation, GqlClientCreateStoryMutationVariables>;
} & TChildProps;
export function withCreateStory<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateStoryMutation, GqlClientCreateStoryMutationVariables, GqlClientCreateStoryProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateStoryMutation, GqlClientCreateStoryMutationVariables, GqlClientCreateStoryProps<TChildProps, TDataName>>(CreateStoryDocument, {
        alias: "createStory",
        ...operationOptions
    });
}

/**
 * __useCreateStoryMutation__
 *
 * To run a mutation, you first call `useCreateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryMutation, { data, loading, error }] = useCreateStoryMutation({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStoryMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateStoryMutation, GqlClientCreateStoryMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateStoryMutation, GqlClientCreateStoryMutationVariables>(CreateStoryDocument, options);
}
export type CreateStoryMutationHookResult = ReturnType<typeof useCreateStoryMutation>;
export type CreateStoryMutationResult = Apollo.MutationResult<GqlClientCreateStoryMutation>;
export type CreateStoryMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateStoryMutation, GqlClientCreateStoryMutationVariables>;
export const DeleteStoryDocument = gql`
    mutation DeleteStory($input: DeleteStoryInput!) {
        deleteStory(input: $input) {
            result
            programVersion {
                ...ProgramVersion
            }
        }
    }
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientDeleteStoryMutationFn = Apollo.MutationFunction<GqlClientDeleteStoryMutation, GqlClientDeleteStoryMutationVariables>;
export type GqlClientDeleteStoryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteStoryMutation, GqlClientDeleteStoryMutationVariables>;
} & TChildProps;
export function withDeleteStory<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteStoryMutation, GqlClientDeleteStoryMutationVariables, GqlClientDeleteStoryProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteStoryMutation, GqlClientDeleteStoryMutationVariables, GqlClientDeleteStoryProps<TChildProps, TDataName>>(DeleteStoryDocument, {
        alias: "deleteStory",
        ...operationOptions
    });
}

/**
 * __useDeleteStoryMutation__
 *
 * To run a mutation, you first call `useDeleteStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryMutation, { data, loading, error }] = useDeleteStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStoryMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteStoryMutation, GqlClientDeleteStoryMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteStoryMutation, GqlClientDeleteStoryMutationVariables>(DeleteStoryDocument, options);
}
export type DeleteStoryMutationHookResult = ReturnType<typeof useDeleteStoryMutation>;
export type DeleteStoryMutationResult = Apollo.MutationResult<GqlClientDeleteStoryMutation>;
export type DeleteStoryMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteStoryMutation, GqlClientDeleteStoryMutationVariables>;
export const UpdateStoryDocument = gql`
    mutation UpdateStory($input: UpdateStoryWithReachAndScenesInput!, $dryRun: Boolean) {
        updateStoryWithReachAndScenes(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateStoryWithReachAndScenesOutputSuccess {
                story {
                    ...Story
                    programVersion {
                        ...ProgramVersion
                    }
                }
            }
        }
    }
    ${StoryFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientUpdateStoryMutationFn = Apollo.MutationFunction<GqlClientUpdateStoryMutation, GqlClientUpdateStoryMutationVariables>;
export type GqlClientUpdateStoryProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateStoryMutation, GqlClientUpdateStoryMutationVariables>;
} & TChildProps;
export function withUpdateStory<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateStoryMutation, GqlClientUpdateStoryMutationVariables, GqlClientUpdateStoryProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateStoryMutation, GqlClientUpdateStoryMutationVariables, GqlClientUpdateStoryProps<TChildProps, TDataName>>(UpdateStoryDocument, {
        alias: "updateStory",
        ...operationOptions
    });
}

/**
 * __useUpdateStoryMutation__
 *
 * To run a mutation, you first call `useUpdateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryMutation, { data, loading, error }] = useUpdateStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateStoryMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateStoryMutation, GqlClientUpdateStoryMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateStoryMutation, GqlClientUpdateStoryMutationVariables>(UpdateStoryDocument, options);
}
export type UpdateStoryMutationHookResult = ReturnType<typeof useUpdateStoryMutation>;
export type UpdateStoryMutationResult = Apollo.MutationResult<GqlClientUpdateStoryMutation>;
export type UpdateStoryMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateStoryMutation, GqlClientUpdateStoryMutationVariables>;
export const UpdateStoryForFrameworkDocument = gql`
    mutation UpdateStoryForFramework($input: UpdateStoryForFrameworkInput!, $dryRun: Boolean) {
        updateStoryForFramework(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateStoryForFrameworkOutputSuccess {
                story {
                    ...Story
                    programVersion {
                        ...ProgramVersion
                    }
                }
            }
        }
    }
    ${StoryFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientUpdateStoryForFrameworkMutationFn = Apollo.MutationFunction<GqlClientUpdateStoryForFrameworkMutation, GqlClientUpdateStoryForFrameworkMutationVariables>;
export type GqlClientUpdateStoryForFrameworkProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateStoryForFrameworkMutation, GqlClientUpdateStoryForFrameworkMutationVariables>;
} & TChildProps;
export function withUpdateStoryForFramework<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateStoryForFrameworkMutation,
        GqlClientUpdateStoryForFrameworkMutationVariables,
        GqlClientUpdateStoryForFrameworkProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<
        TProps,
        GqlClientUpdateStoryForFrameworkMutation,
        GqlClientUpdateStoryForFrameworkMutationVariables,
        GqlClientUpdateStoryForFrameworkProps<TChildProps, TDataName>
    >(UpdateStoryForFrameworkDocument, {
        alias: "updateStoryForFramework",
        ...operationOptions
    });
}

/**
 * __useUpdateStoryForFrameworkMutation__
 *
 * To run a mutation, you first call `useUpdateStoryForFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryForFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryForFrameworkMutation, { data, loading, error }] = useUpdateStoryForFrameworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateStoryForFrameworkMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateStoryForFrameworkMutation, GqlClientUpdateStoryForFrameworkMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateStoryForFrameworkMutation, GqlClientUpdateStoryForFrameworkMutationVariables>(UpdateStoryForFrameworkDocument, options);
}
export type UpdateStoryForFrameworkMutationHookResult = ReturnType<typeof useUpdateStoryForFrameworkMutation>;
export type UpdateStoryForFrameworkMutationResult = Apollo.MutationResult<GqlClientUpdateStoryForFrameworkMutation>;
export type UpdateStoryForFrameworkMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateStoryForFrameworkMutation, GqlClientUpdateStoryForFrameworkMutationVariables>;
export const CreateTouchpointDocument = gql`
    mutation CreateTouchpoint($programVersionId: ID!, $input: CreateTouchpointInput!) {
        createTouchpoint(programVersionId: $programVersionId, input: $input) {
            result
            touchpoint {
                ...Touchpoint
                programVersion {
                    ...ProgramVersion
                }
            }
        }
    }
    ${TouchpointFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientCreateTouchpointMutationFn = Apollo.MutationFunction<GqlClientCreateTouchpointMutation, GqlClientCreateTouchpointMutationVariables>;
export type GqlClientCreateTouchpointProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateTouchpointMutation, GqlClientCreateTouchpointMutationVariables>;
} & TChildProps;
export function withCreateTouchpoint<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateTouchpointMutation, GqlClientCreateTouchpointMutationVariables, GqlClientCreateTouchpointProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateTouchpointMutation, GqlClientCreateTouchpointMutationVariables, GqlClientCreateTouchpointProps<TChildProps, TDataName>>(
        CreateTouchpointDocument,
        {
            alias: "createTouchpoint",
            ...operationOptions
        }
    );
}

/**
 * __useCreateTouchpointMutation__
 *
 * To run a mutation, you first call `useCreateTouchpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTouchpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTouchpointMutation, { data, loading, error }] = useCreateTouchpointMutation({
 *   variables: {
 *      programVersionId: // value for 'programVersionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTouchpointMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateTouchpointMutation, GqlClientCreateTouchpointMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateTouchpointMutation, GqlClientCreateTouchpointMutationVariables>(CreateTouchpointDocument, options);
}
export type CreateTouchpointMutationHookResult = ReturnType<typeof useCreateTouchpointMutation>;
export type CreateTouchpointMutationResult = Apollo.MutationResult<GqlClientCreateTouchpointMutation>;
export type CreateTouchpointMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateTouchpointMutation, GqlClientCreateTouchpointMutationVariables>;
export const DeleteTouchpointDocument = gql`
    mutation DeleteTouchpoint($input: DeleteTouchpointInput!) {
        deleteTouchpoint(input: $input) {
            result
            programVersion {
                ...ProgramVersion
            }
        }
    }
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientDeleteTouchpointMutationFn = Apollo.MutationFunction<GqlClientDeleteTouchpointMutation, GqlClientDeleteTouchpointMutationVariables>;
export type GqlClientDeleteTouchpointProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteTouchpointMutation, GqlClientDeleteTouchpointMutationVariables>;
} & TChildProps;
export function withDeleteTouchpoint<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteTouchpointMutation, GqlClientDeleteTouchpointMutationVariables, GqlClientDeleteTouchpointProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteTouchpointMutation, GqlClientDeleteTouchpointMutationVariables, GqlClientDeleteTouchpointProps<TChildProps, TDataName>>(
        DeleteTouchpointDocument,
        {
            alias: "deleteTouchpoint",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteTouchpointMutation__
 *
 * To run a mutation, you first call `useDeleteTouchpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTouchpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTouchpointMutation, { data, loading, error }] = useDeleteTouchpointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTouchpointMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteTouchpointMutation, GqlClientDeleteTouchpointMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteTouchpointMutation, GqlClientDeleteTouchpointMutationVariables>(DeleteTouchpointDocument, options);
}
export type DeleteTouchpointMutationHookResult = ReturnType<typeof useDeleteTouchpointMutation>;
export type DeleteTouchpointMutationResult = Apollo.MutationResult<GqlClientDeleteTouchpointMutation>;
export type DeleteTouchpointMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteTouchpointMutation, GqlClientDeleteTouchpointMutationVariables>;
export const UpdateTouchpointDocument = gql`
    mutation UpdateTouchpoint($input: UpdateTouchpointInput!, $dryRun: Boolean) {
        updateTouchpoint(input: $input, dryRun: $dryRun) {
            result
            ... on UpdateTouchpointOutputSuccess {
                touchpoint {
                    ...Touchpoint
                    programVersion {
                        ...ProgramVersion
                    }
                }
            }
        }
    }
    ${TouchpointFragmentDoc}
    ${ProgramVersionFragmentDoc}
`;
export type GqlClientUpdateTouchpointMutationFn = Apollo.MutationFunction<GqlClientUpdateTouchpointMutation, GqlClientUpdateTouchpointMutationVariables>;
export type GqlClientUpdateTouchpointProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateTouchpointMutation, GqlClientUpdateTouchpointMutationVariables>;
} & TChildProps;
export function withUpdateTouchpoint<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateTouchpointMutation, GqlClientUpdateTouchpointMutationVariables, GqlClientUpdateTouchpointProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateTouchpointMutation, GqlClientUpdateTouchpointMutationVariables, GqlClientUpdateTouchpointProps<TChildProps, TDataName>>(
        UpdateTouchpointDocument,
        {
            alias: "updateTouchpoint",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateTouchpointMutation__
 *
 * To run a mutation, you first call `useUpdateTouchpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTouchpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTouchpointMutation, { data, loading, error }] = useUpdateTouchpointMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useUpdateTouchpointMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateTouchpointMutation, GqlClientUpdateTouchpointMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateTouchpointMutation, GqlClientUpdateTouchpointMutationVariables>(UpdateTouchpointDocument, options);
}
export type UpdateTouchpointMutationHookResult = ReturnType<typeof useUpdateTouchpointMutation>;
export type UpdateTouchpointMutationResult = Apollo.MutationResult<GqlClientUpdateTouchpointMutation>;
export type UpdateTouchpointMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateTouchpointMutation, GqlClientUpdateTouchpointMutationVariables>;
export const CopyAccountReportDocument = gql`
    mutation CopyAccountReport($input: CopyAccountReportInput!) {
        copyAccountReport(input: $input) {
            result
            targetReport {
                ...AccountReport
                account {
                    ...Account
                }
            }
        }
    }
    ${AccountReportFragmentDoc}
    ${AccountFragmentDoc}
`;
export type GqlClientCopyAccountReportMutationFn = Apollo.MutationFunction<GqlClientCopyAccountReportMutation, GqlClientCopyAccountReportMutationVariables>;
export type GqlClientCopyAccountReportProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCopyAccountReportMutation, GqlClientCopyAccountReportMutationVariables>;
} & TChildProps;
export function withCopyAccountReport<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCopyAccountReportMutation, GqlClientCopyAccountReportMutationVariables, GqlClientCopyAccountReportProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCopyAccountReportMutation, GqlClientCopyAccountReportMutationVariables, GqlClientCopyAccountReportProps<TChildProps, TDataName>>(
        CopyAccountReportDocument,
        {
            alias: "copyAccountReport",
            ...operationOptions
        }
    );
}

/**
 * __useCopyAccountReportMutation__
 *
 * To run a mutation, you first call `useCopyAccountReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyAccountReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyAccountReportMutation, { data, loading, error }] = useCopyAccountReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyAccountReportMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCopyAccountReportMutation, GqlClientCopyAccountReportMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCopyAccountReportMutation, GqlClientCopyAccountReportMutationVariables>(CopyAccountReportDocument, options);
}
export type CopyAccountReportMutationHookResult = ReturnType<typeof useCopyAccountReportMutation>;
export type CopyAccountReportMutationResult = Apollo.MutationResult<GqlClientCopyAccountReportMutation>;
export type CopyAccountReportMutationOptions = Apollo.BaseMutationOptions<GqlClientCopyAccountReportMutation, GqlClientCopyAccountReportMutationVariables>;
export const CreateAccountReportDocument = gql`
    mutation CreateAccountReport($input: CreateAccountReportInput!) {
        createAccountReport(input: $input) {
            result
            accountReport {
                ...AccountReport
                account {
                    ...Account
                }
            }
        }
    }
    ${AccountReportFragmentDoc}
    ${AccountFragmentDoc}
`;
export type GqlClientCreateAccountReportMutationFn = Apollo.MutationFunction<GqlClientCreateAccountReportMutation, GqlClientCreateAccountReportMutationVariables>;
export type GqlClientCreateAccountReportProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateAccountReportMutation, GqlClientCreateAccountReportMutationVariables>;
} & TChildProps;
export function withCreateAccountReport<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientCreateAccountReportMutation,
        GqlClientCreateAccountReportMutationVariables,
        GqlClientCreateAccountReportProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateAccountReportMutation, GqlClientCreateAccountReportMutationVariables, GqlClientCreateAccountReportProps<TChildProps, TDataName>>(
        CreateAccountReportDocument,
        {
            alias: "createAccountReport",
            ...operationOptions
        }
    );
}

/**
 * __useCreateAccountReportMutation__
 *
 * To run a mutation, you first call `useCreateAccountReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountReportMutation, { data, loading, error }] = useCreateAccountReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountReportMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateAccountReportMutation, GqlClientCreateAccountReportMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateAccountReportMutation, GqlClientCreateAccountReportMutationVariables>(CreateAccountReportDocument, options);
}
export type CreateAccountReportMutationHookResult = ReturnType<typeof useCreateAccountReportMutation>;
export type CreateAccountReportMutationResult = Apollo.MutationResult<GqlClientCreateAccountReportMutation>;
export type CreateAccountReportMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateAccountReportMutation, GqlClientCreateAccountReportMutationVariables>;
export const DeleteAccountReportDocument = gql`
    mutation DeleteAccountReport($input: DeleteAccountReportInput!) {
        deleteAccountReport(input: $input) {
            result
            account {
                ...Account
            }
        }
    }
    ${AccountFragmentDoc}
`;
export type GqlClientDeleteAccountReportMutationFn = Apollo.MutationFunction<GqlClientDeleteAccountReportMutation, GqlClientDeleteAccountReportMutationVariables>;
export type GqlClientDeleteAccountReportProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteAccountReportMutation, GqlClientDeleteAccountReportMutationVariables>;
} & TChildProps;
export function withDeleteAccountReport<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientDeleteAccountReportMutation,
        GqlClientDeleteAccountReportMutationVariables,
        GqlClientDeleteAccountReportProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteAccountReportMutation, GqlClientDeleteAccountReportMutationVariables, GqlClientDeleteAccountReportProps<TChildProps, TDataName>>(
        DeleteAccountReportDocument,
        {
            alias: "deleteAccountReport",
            ...operationOptions
        }
    );
}

/**
 * __useDeleteAccountReportMutation__
 *
 * To run a mutation, you first call `useDeleteAccountReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountReportMutation, { data, loading, error }] = useDeleteAccountReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountReportMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteAccountReportMutation, GqlClientDeleteAccountReportMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteAccountReportMutation, GqlClientDeleteAccountReportMutationVariables>(DeleteAccountReportDocument, options);
}
export type DeleteAccountReportMutationHookResult = ReturnType<typeof useDeleteAccountReportMutation>;
export type DeleteAccountReportMutationResult = Apollo.MutationResult<GqlClientDeleteAccountReportMutation>;
export type DeleteAccountReportMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteAccountReportMutation, GqlClientDeleteAccountReportMutationVariables>;
export const GetAccountAnalyticsDocument = gql`
    query GetAccountAnalytics($startDate: String!, $endDate: String!, $filters: [AnalyticsFilterInput]) {
        account {
            id
            accountAnalytics {
                videoOverview(startDate: $startDate, endDate: $endDate, filters: $filters) {
                    videoName
                    videoId
                    isEditor
                    views
                    clicks
                    minutesViewed
                    avv
                    minutesPerViewer
                    clickRate
                    lastPublished
                    viewsWithCtaDisplayed
                    viewsWithPlayer
                }
                dailyTrend(startDate: $startDate, endDate: $endDate, filters: $filters) {
                    date
                    views
                    clicks
                    minutesViewed
                    avv
                }
                accountOverview(startDate: $startDate, endDate: $endDate, filters: $filters) {
                    views
                    viewsWithClient
                    viewsWithSurveyDisplayed
                    viewsWithSurveySubmitted
                    minutesViewed
                    avv
                    hasPublishedVideos
                    clickRate
                }
                analyticsFilters(startDate: $startDate, endDate: $endDate) {
                    filterName
                    filterId
                    filterValue
                    totalCount
                    percentage
                }
            }
            editorProgramsIncludingRemoved {
                name
                id
                isArchive
                isLive
                lifecycleStage
                prodVersion {
                    id
                    created
                    editorProgramVersion {
                        id
                        thumbnail {
                            url
                        }
                    }
                }
                uatVersion {
                    id
                    created
                }
                shareHistory {
                    id
                    created
                    shareType
                    isExternal
                    accountId
                }
            }
        }
    }
`;
export type GqlClientGetAccountAnalyticsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAccountAnalyticsQuery, GqlClientGetAccountAnalyticsQueryVariables>;
} & TChildProps;
export function withGetAccountAnalytics<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetAccountAnalyticsQuery, GqlClientGetAccountAnalyticsQueryVariables, GqlClientGetAccountAnalyticsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetAccountAnalyticsQuery, GqlClientGetAccountAnalyticsQueryVariables, GqlClientGetAccountAnalyticsProps<TChildProps, TDataName>>(
        GetAccountAnalyticsDocument,
        {
            alias: "getAccountAnalytics",
            ...operationOptions
        }
    );
}

/**
 * __useGetAccountAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetAccountAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountAnalyticsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAccountAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetAccountAnalyticsQuery, GqlClientGetAccountAnalyticsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAccountAnalyticsQuery, GqlClientGetAccountAnalyticsQueryVariables>(GetAccountAnalyticsDocument, options);
}
export function useGetAccountAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAccountAnalyticsQuery, GqlClientGetAccountAnalyticsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAccountAnalyticsQuery, GqlClientGetAccountAnalyticsQueryVariables>(GetAccountAnalyticsDocument, options);
}
export type GetAccountAnalyticsQueryHookResult = ReturnType<typeof useGetAccountAnalyticsQuery>;
export type GetAccountAnalyticsLazyQueryHookResult = ReturnType<typeof useGetAccountAnalyticsLazyQuery>;
export type GetAccountAnalyticsQueryResult = Apollo.QueryResult<GqlClientGetAccountAnalyticsQuery, GqlClientGetAccountAnalyticsQueryVariables>;
export const GetAccountReportsDocument = gql`
    query GetAccountReports {
        account {
            ...Account
        }
    }
    ${AccountFragmentDoc}
`;
export type GqlClientGetAccountReportsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAccountReportsQuery, GqlClientGetAccountReportsQueryVariables>;
} & TChildProps;
export function withGetAccountReports<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetAccountReportsQuery, GqlClientGetAccountReportsQueryVariables, GqlClientGetAccountReportsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetAccountReportsQuery, GqlClientGetAccountReportsQueryVariables, GqlClientGetAccountReportsProps<TChildProps, TDataName>>(
        GetAccountReportsDocument,
        {
            alias: "getAccountReports",
            ...operationOptions
        }
    );
}

/**
 * __useGetAccountReportsQuery__
 *
 * To run a query within a React component, call `useGetAccountReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountReportsQuery(baseOptions?: Apollo.QueryHookOptions<GqlClientGetAccountReportsQuery, GqlClientGetAccountReportsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAccountReportsQuery, GqlClientGetAccountReportsQueryVariables>(GetAccountReportsDocument, options);
}
export function useGetAccountReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAccountReportsQuery, GqlClientGetAccountReportsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAccountReportsQuery, GqlClientGetAccountReportsQueryVariables>(GetAccountReportsDocument, options);
}
export type GetAccountReportsQueryHookResult = ReturnType<typeof useGetAccountReportsQuery>;
export type GetAccountReportsLazyQueryHookResult = ReturnType<typeof useGetAccountReportsLazyQuery>;
export type GetAccountReportsQueryResult = Apollo.QueryResult<GqlClientGetAccountReportsQuery, GqlClientGetAccountReportsQueryVariables>;
export const SendAccountReportDocument = gql`
    mutation SendAccountReport($input: SendAccountReportInput!) {
        sendAccountReport(input: $input) {
            result
        }
    }
`;
export type GqlClientSendAccountReportMutationFn = Apollo.MutationFunction<GqlClientSendAccountReportMutation, GqlClientSendAccountReportMutationVariables>;
export type GqlClientSendAccountReportProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientSendAccountReportMutation, GqlClientSendAccountReportMutationVariables>;
} & TChildProps;
export function withSendAccountReport<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientSendAccountReportMutation, GqlClientSendAccountReportMutationVariables, GqlClientSendAccountReportProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientSendAccountReportMutation, GqlClientSendAccountReportMutationVariables, GqlClientSendAccountReportProps<TChildProps, TDataName>>(
        SendAccountReportDocument,
        {
            alias: "sendAccountReport",
            ...operationOptions
        }
    );
}

/**
 * __useSendAccountReportMutation__
 *
 * To run a mutation, you first call `useSendAccountReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAccountReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAccountReportMutation, { data, loading, error }] = useSendAccountReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAccountReportMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientSendAccountReportMutation, GqlClientSendAccountReportMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientSendAccountReportMutation, GqlClientSendAccountReportMutationVariables>(SendAccountReportDocument, options);
}
export type SendAccountReportMutationHookResult = ReturnType<typeof useSendAccountReportMutation>;
export type SendAccountReportMutationResult = Apollo.MutationResult<GqlClientSendAccountReportMutation>;
export type SendAccountReportMutationOptions = Apollo.BaseMutationOptions<GqlClientSendAccountReportMutation, GqlClientSendAccountReportMutationVariables>;
export const UpdateAccountReportDocument = gql`
    mutation UpdateAccountReport($input: UpdateAccountReportInput!) {
        updateAccountReport(input: $input) {
            result
            ... on UpdateAccountReportOutputSuccess {
                accountReport {
                    ...AccountReport
                }
            }
        }
    }
    ${AccountReportFragmentDoc}
`;
export type GqlClientUpdateAccountReportMutationFn = Apollo.MutationFunction<GqlClientUpdateAccountReportMutation, GqlClientUpdateAccountReportMutationVariables>;
export type GqlClientUpdateAccountReportProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateAccountReportMutation, GqlClientUpdateAccountReportMutationVariables>;
} & TChildProps;
export function withUpdateAccountReport<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientUpdateAccountReportMutation,
        GqlClientUpdateAccountReportMutationVariables,
        GqlClientUpdateAccountReportProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateAccountReportMutation, GqlClientUpdateAccountReportMutationVariables, GqlClientUpdateAccountReportProps<TChildProps, TDataName>>(
        UpdateAccountReportDocument,
        {
            alias: "updateAccountReport",
            ...operationOptions
        }
    );
}

/**
 * __useUpdateAccountReportMutation__
 *
 * To run a mutation, you first call `useUpdateAccountReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountReportMutation, { data, loading, error }] = useUpdateAccountReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountReportMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateAccountReportMutation, GqlClientUpdateAccountReportMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateAccountReportMutation, GqlClientUpdateAccountReportMutationVariables>(UpdateAccountReportDocument, options);
}
export type UpdateAccountReportMutationHookResult = ReturnType<typeof useUpdateAccountReportMutation>;
export type UpdateAccountReportMutationResult = Apollo.MutationResult<GqlClientUpdateAccountReportMutation>;
export type UpdateAccountReportMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateAccountReportMutation, GqlClientUpdateAccountReportMutationVariables>;
export const GetAllAccountReportElementsDocument = gql`
    query getAllAccountReportElements($programIds: [ID!]!) {
        accountReportLibraryDataElements(programIds: $programIds) {
            ...ReportLibraryElement
        }
        accountReportLibraryGeneralElements {
            ...ReportLibraryElement
        }
        accountReportLibraryHotSpots(programIds: $programIds) {
            ...ReportLibraryElement
            subElements {
                ...ReportLibraryElement
            }
        }
        accountReportLibrarySurveys(programIds: $programIds) {
            id
            ...ReportLibraryElement
            subElements {
                id
                ...ReportLibraryElement
            }
        }
        accountReportLibraryTrackingParameters(programIds: $programIds) {
            id
            ...ReportLibraryElement
        }
        accountReportLibraryPaidMediaElements {
            ...ReportLibraryElement
        }
    }
    ${ReportLibraryElementFragmentDoc}
`;
export type GqlClientGetAllAccountReportElementsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAllAccountReportElementsQuery, GqlClientGetAllAccountReportElementsQueryVariables>;
} & TChildProps;
export function withGetAllAccountReportElements<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetAllAccountReportElementsQuery,
        GqlClientGetAllAccountReportElementsQueryVariables,
        GqlClientGetAllAccountReportElementsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<
        TProps,
        GqlClientGetAllAccountReportElementsQuery,
        GqlClientGetAllAccountReportElementsQueryVariables,
        GqlClientGetAllAccountReportElementsProps<TChildProps, TDataName>
    >(GetAllAccountReportElementsDocument, {
        alias: "getAllAccountReportElements",
        ...operationOptions
    });
}

/**
 * __useGetAllAccountReportElementsQuery__
 *
 * To run a query within a React component, call `useGetAllAccountReportElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountReportElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountReportElementsQuery({
 *   variables: {
 *      programIds: // value for 'programIds'
 *   },
 * });
 */
export function useGetAllAccountReportElementsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetAllAccountReportElementsQuery, GqlClientGetAllAccountReportElementsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAllAccountReportElementsQuery, GqlClientGetAllAccountReportElementsQueryVariables>(GetAllAccountReportElementsDocument, options);
}
export function useGetAllAccountReportElementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAllAccountReportElementsQuery, GqlClientGetAllAccountReportElementsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAllAccountReportElementsQuery, GqlClientGetAllAccountReportElementsQueryVariables>(GetAllAccountReportElementsDocument, options);
}
export type GetAllAccountReportElementsQueryHookResult = ReturnType<typeof useGetAllAccountReportElementsQuery>;
export type GetAllAccountReportElementsLazyQueryHookResult = ReturnType<typeof useGetAllAccountReportElementsLazyQuery>;
export type GetAllAccountReportElementsQueryResult = Apollo.QueryResult<GqlClientGetAllAccountReportElementsQuery, GqlClientGetAllAccountReportElementsQueryVariables>;
export const GetAllReportElementsDocument = gql`
    query getAllReportElements($programId: ID!) {
        reportLibraryDataElements(programId: $programId) {
            ...ReportLibraryElement
        }
        reportLibraryGeneralElements {
            ...ReportLibraryElement
        }
        reportLibraryHotSpots(programId: $programId) {
            ...ReportLibraryElement
            subElements {
                ...ReportLibraryElement
            }
        }
        reportLibrarySurveys(programId: $programId) {
            id
            ...ReportLibraryElement
            subElements {
                id
                ...ReportLibraryElement
            }
        }
        reportLibraryTrackingParameters(programId: $programId) {
            id
            ...ReportLibraryElement
        }
    }
    ${ReportLibraryElementFragmentDoc}
`;
export type GqlClientGetAllReportElementsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetAllReportElementsQuery, GqlClientGetAllReportElementsQueryVariables>;
} & TChildProps;
export function withGetAllReportElements<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<
        TProps,
        GqlClientGetAllReportElementsQuery,
        GqlClientGetAllReportElementsQueryVariables,
        GqlClientGetAllReportElementsProps<TChildProps, TDataName>
    >
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetAllReportElementsQuery, GqlClientGetAllReportElementsQueryVariables, GqlClientGetAllReportElementsProps<TChildProps, TDataName>>(
        GetAllReportElementsDocument,
        {
            alias: "getAllReportElements",
            ...operationOptions
        }
    );
}

/**
 * __useGetAllReportElementsQuery__
 *
 * To run a query within a React component, call `useGetAllReportElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllReportElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllReportElementsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetAllReportElementsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetAllReportElementsQuery, GqlClientGetAllReportElementsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetAllReportElementsQuery, GqlClientGetAllReportElementsQueryVariables>(GetAllReportElementsDocument, options);
}
export function useGetAllReportElementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetAllReportElementsQuery, GqlClientGetAllReportElementsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetAllReportElementsQuery, GqlClientGetAllReportElementsQueryVariables>(GetAllReportElementsDocument, options);
}
export type GetAllReportElementsQueryHookResult = ReturnType<typeof useGetAllReportElementsQuery>;
export type GetAllReportElementsLazyQueryHookResult = ReturnType<typeof useGetAllReportElementsLazyQuery>;
export type GetAllReportElementsQueryResult = Apollo.QueryResult<GqlClientGetAllReportElementsQuery, GqlClientGetAllReportElementsQueryVariables>;
export const CreateSurveyDocument = gql`
    mutation CreateSurvey($input: CreateSurveyInput!) {
        createSurvey(input: $input) {
            result
            survey {
                id
                program {
                    ...Program
                }
            }
        }
    }
    ${ProgramFragmentDoc}
`;
export type GqlClientCreateSurveyMutationFn = Apollo.MutationFunction<GqlClientCreateSurveyMutation, GqlClientCreateSurveyMutationVariables>;
export type GqlClientCreateSurveyProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientCreateSurveyMutation, GqlClientCreateSurveyMutationVariables>;
} & TChildProps;
export function withCreateSurvey<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientCreateSurveyMutation, GqlClientCreateSurveyMutationVariables, GqlClientCreateSurveyProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientCreateSurveyMutation, GqlClientCreateSurveyMutationVariables, GqlClientCreateSurveyProps<TChildProps, TDataName>>(CreateSurveyDocument, {
        alias: "createSurvey",
        ...operationOptions
    });
}

/**
 * __useCreateSurveyMutation__
 *
 * To run a mutation, you first call `useCreateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyMutation, { data, loading, error }] = useCreateSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientCreateSurveyMutation, GqlClientCreateSurveyMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientCreateSurveyMutation, GqlClientCreateSurveyMutationVariables>(CreateSurveyDocument, options);
}
export type CreateSurveyMutationHookResult = ReturnType<typeof useCreateSurveyMutation>;
export type CreateSurveyMutationResult = Apollo.MutationResult<GqlClientCreateSurveyMutation>;
export type CreateSurveyMutationOptions = Apollo.BaseMutationOptions<GqlClientCreateSurveyMutation, GqlClientCreateSurveyMutationVariables>;
export const DeleteSurveyDocument = gql`
    mutation DeleteSurvey($input: DeleteSurveyInput!) {
        deleteSurvey(input: $input) {
            result
            program {
                ...Program
            }
        }
    }
    ${ProgramFragmentDoc}
`;
export type GqlClientDeleteSurveyMutationFn = Apollo.MutationFunction<GqlClientDeleteSurveyMutation, GqlClientDeleteSurveyMutationVariables>;
export type GqlClientDeleteSurveyProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientDeleteSurveyMutation, GqlClientDeleteSurveyMutationVariables>;
} & TChildProps;
export function withDeleteSurvey<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientDeleteSurveyMutation, GqlClientDeleteSurveyMutationVariables, GqlClientDeleteSurveyProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientDeleteSurveyMutation, GqlClientDeleteSurveyMutationVariables, GqlClientDeleteSurveyProps<TChildProps, TDataName>>(DeleteSurveyDocument, {
        alias: "deleteSurvey",
        ...operationOptions
    });
}

/**
 * __useDeleteSurveyMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyMutation, { data, loading, error }] = useDeleteSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSurveyMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientDeleteSurveyMutation, GqlClientDeleteSurveyMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientDeleteSurveyMutation, GqlClientDeleteSurveyMutationVariables>(DeleteSurveyDocument, options);
}
export type DeleteSurveyMutationHookResult = ReturnType<typeof useDeleteSurveyMutation>;
export type DeleteSurveyMutationResult = Apollo.MutationResult<GqlClientDeleteSurveyMutation>;
export type DeleteSurveyMutationOptions = Apollo.BaseMutationOptions<GqlClientDeleteSurveyMutation, GqlClientDeleteSurveyMutationVariables>;
export const GetProgramAnalyticsDocument = gql`
    query GetProgramAnalytics($programId: ID!, $startDate: String!, $endDate: String!, $filters: [AnalyticsFilterInput]) {
        program(id: $programId) {
            id
            programAnalytics {
                surveyAnalytics(startDate: $startDate, endDate: $endDate, filters: $filters) {
                    surveyId
                    displayedAmount
                    submittedAmount
                    videoViewsAmount
                    questions {
                        text
                        answers {
                            answerText
                            responseCount
                        }
                        type
                        answersTotal
                    }
                    npsAnalytics {
                        surveyDetractorsAmount
                        surveyNeutralAmount
                        surveyPromotersAmount
                    }
                }
                clicksAnalytics(startDate: $startDate, endDate: $endDate, filters: $filters) {
                    viewsWithCTADisplayed
                    viewsWithCTAClicked
                    totalCTAViews
                    totalCTAClicks
                    buttons {
                        operation
                        screenText
                        landingPage
                        ctaViews
                        ctaClicks
                    }
                    videoViewsAmount
                }
                viewsAnalytics(startDate: $startDate, endDate: $endDate, filters: $filters) {
                    views
                    viewsWithClient
                    videoExposed
                    minutesViewed
                    avv
                    viewsWithUserId
                    newViewsCount
                    returningViewsCount
                    activeViewsCount
                    viewsDailyData {
                        date
                        views
                        viewsWithClient
                        videoExposed
                        minutesViewed
                        avv
                        viewsWithUserId
                        newViewsCount
                        returningViewsCount
                        activeViewsCount
                    }
                }
                sceneAnalytics(startDate: $startDate, endDate: $endDate, filters: $filters) {
                    programSceneAnalyticsArray {
                        sceneId
                        scenePotentialViews
                        sceneSequentialViews
                        sceneSkippedViews
                        sceneRewatchViews
                        data_use
                    }
                    versionPublishedDate
                }
                analyticsFilters(startDate: $startDate, endDate: $endDate) {
                    filterName
                    filterId
                    filterValue
                    totalCount
                    percentage
                }
            }
        }
    }
`;
export type GqlClientGetProgramAnalyticsProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetProgramAnalyticsQuery, GqlClientGetProgramAnalyticsQueryVariables>;
} & TChildProps;
export function withGetProgramAnalytics<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetProgramAnalyticsQuery, GqlClientGetProgramAnalyticsQueryVariables, GqlClientGetProgramAnalyticsProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetProgramAnalyticsQuery, GqlClientGetProgramAnalyticsQueryVariables, GqlClientGetProgramAnalyticsProps<TChildProps, TDataName>>(
        GetProgramAnalyticsDocument,
        {
            alias: "getProgramAnalytics",
            ...operationOptions
        }
    );
}

/**
 * __useGetProgramAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetProgramAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramAnalyticsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProgramAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetProgramAnalyticsQuery, GqlClientGetProgramAnalyticsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetProgramAnalyticsQuery, GqlClientGetProgramAnalyticsQueryVariables>(GetProgramAnalyticsDocument, options);
}
export function useGetProgramAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetProgramAnalyticsQuery, GqlClientGetProgramAnalyticsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetProgramAnalyticsQuery, GqlClientGetProgramAnalyticsQueryVariables>(GetProgramAnalyticsDocument, options);
}
export type GetProgramAnalyticsQueryHookResult = ReturnType<typeof useGetProgramAnalyticsQuery>;
export type GetProgramAnalyticsLazyQueryHookResult = ReturnType<typeof useGetProgramAnalyticsLazyQuery>;
export type GetProgramAnalyticsQueryResult = Apollo.QueryResult<GqlClientGetProgramAnalyticsQuery, GqlClientGetProgramAnalyticsQueryVariables>;
export const GetSurveysDocument = gql`
    query GetSurveys($programId: ID!) {
        program(id: $programId) {
            id
            surveys {
                ...Survey
            }
        }
    }
    ${SurveyFragmentDoc}
`;
export type GqlClientGetSurveysProps<TChildProps = {}, TDataName extends string = "data"> = {
    [key in TDataName]: ApolloReactHoc.DataValue<GqlClientGetSurveysQuery, GqlClientGetSurveysQueryVariables>;
} & TChildProps;
export function withGetSurveys<TProps, TChildProps = {}, TDataName extends string = "data">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientGetSurveysQuery, GqlClientGetSurveysQueryVariables, GqlClientGetSurveysProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withQuery<TProps, GqlClientGetSurveysQuery, GqlClientGetSurveysQueryVariables, GqlClientGetSurveysProps<TChildProps, TDataName>>(GetSurveysDocument, {
        alias: "getSurveys",
        ...operationOptions
    });
}

/**
 * __useGetSurveysQuery__
 *
 * To run a query within a React component, call `useGetSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveysQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetSurveysQuery(baseOptions: Apollo.QueryHookOptions<GqlClientGetSurveysQuery, GqlClientGetSurveysQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GqlClientGetSurveysQuery, GqlClientGetSurveysQueryVariables>(GetSurveysDocument, options);
}
export function useGetSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClientGetSurveysQuery, GqlClientGetSurveysQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GqlClientGetSurveysQuery, GqlClientGetSurveysQueryVariables>(GetSurveysDocument, options);
}
export type GetSurveysQueryHookResult = ReturnType<typeof useGetSurveysQuery>;
export type GetSurveysLazyQueryHookResult = ReturnType<typeof useGetSurveysLazyQuery>;
export type GetSurveysQueryResult = Apollo.QueryResult<GqlClientGetSurveysQuery, GqlClientGetSurveysQueryVariables>;
export const UpdateSurveyDocument = gql`
    mutation UpdateSurvey($input: UpdateSurveyInput!) {
        updateSurvey(input: $input) {
            result
            ... on UpdateSurveyOutputSuccess {
                survey {
                    id
                    program {
                        ...Program
                    }
                }
            }
        }
    }
    ${ProgramFragmentDoc}
`;
export type GqlClientUpdateSurveyMutationFn = Apollo.MutationFunction<GqlClientUpdateSurveyMutation, GqlClientUpdateSurveyMutationVariables>;
export type GqlClientUpdateSurveyProps<TChildProps = {}, TDataName extends string = "mutate"> = {
    [key in TDataName]: Apollo.MutationFunction<GqlClientUpdateSurveyMutation, GqlClientUpdateSurveyMutationVariables>;
} & TChildProps;
export function withUpdateSurvey<TProps, TChildProps = {}, TDataName extends string = "mutate">(
    operationOptions?: ApolloReactHoc.OperationOption<TProps, GqlClientUpdateSurveyMutation, GqlClientUpdateSurveyMutationVariables, GqlClientUpdateSurveyProps<TChildProps, TDataName>>
) {
    return ApolloReactHoc.withMutation<TProps, GqlClientUpdateSurveyMutation, GqlClientUpdateSurveyMutationVariables, GqlClientUpdateSurveyProps<TChildProps, TDataName>>(UpdateSurveyDocument, {
        alias: "updateSurvey",
        ...operationOptions
    });
}

/**
 * __useUpdateSurveyMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyMutation, { data, loading, error }] = useUpdateSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<GqlClientUpdateSurveyMutation, GqlClientUpdateSurveyMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GqlClientUpdateSurveyMutation, GqlClientUpdateSurveyMutationVariables>(UpdateSurveyDocument, options);
}
export type UpdateSurveyMutationHookResult = ReturnType<typeof useUpdateSurveyMutation>;
export type UpdateSurveyMutationResult = Apollo.MutationResult<GqlClientUpdateSurveyMutation>;
export type UpdateSurveyMutationOptions = Apollo.BaseMutationOptions<GqlClientUpdateSurveyMutation, GqlClientUpdateSurveyMutationVariables>;
